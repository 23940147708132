import React from 'react';

export const Img = () => (
    <svg width="264" height="220" viewBox="0 0 264 220" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5_21514)">
            <path d="M139.776 137.752H113.443V218.731H139.776V137.752Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.984 137.29H140.234V219.192H112.984V137.29ZM113.901 138.213V218.269H139.317V138.213H113.901Z"
                fill="#425276"
            />
            <path
                d="M69.8242 43.9128H179.231C187.956 43.9128 196.325 47.4249 202.495 53.6765C208.665 59.9281 212.131 68.4071 212.131 77.2481V127.61C212.131 130.919 210.834 134.092 208.525 136.431C206.216 138.771 203.084 140.085 199.819 140.085H54.6901C50.6158 140.085 46.7085 138.445 43.8275 135.526C40.9466 132.607 39.3281 128.648 39.3281 124.52V74.8121C39.3281 66.6171 42.5411 58.7578 48.2602 52.963C53.9793 47.1683 61.7362 43.9128 69.8242 43.9128Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.8245 44.3745C61.8589 44.3745 54.219 47.5806 48.5856 53.2885C42.9521 58.9964 39.7868 66.7386 39.7868 74.812V124.52C39.7868 128.527 41.3576 132.369 44.1529 135.201C46.9481 138.033 50.7386 139.623 54.6904 139.623H199.819C202.962 139.623 205.977 138.358 208.2 136.106C210.423 133.853 211.673 130.797 211.673 127.61V77.2481C211.673 68.5286 208.254 60.1667 202.17 54.0019C196.086 47.8372 187.834 44.3745 179.231 44.3745H69.8245ZM47.9355 52.6375C53.7404 46.7559 61.614 43.4512 69.8245 43.4512H179.231C188.079 43.4512 196.564 47.0126 202.82 53.351C209.076 59.6894 212.59 68.2855 212.59 77.2481V127.61C212.59 131.04 211.245 134.33 208.85 136.757C206.455 139.183 203.207 140.547 199.819 140.547H54.6904C50.4937 140.547 46.4695 138.858 43.5028 135.852C40.5362 132.846 38.8701 128.77 38.8701 124.52V74.812C38.8701 66.4955 42.1307 58.519 47.9355 52.6375Z"
                fill="#425276"
            />
            <path
                d="M69.5079 43.5282H71.3807C79.3692 43.5282 87.0307 46.7427 92.6807 52.4649C98.3306 58.1872 101.506 65.9486 101.51 74.0428V138.867C101.51 139.19 101.383 139.5 101.158 139.728C100.932 139.957 100.626 140.085 100.308 140.085H42.5929C41.727 140.085 40.8966 139.737 40.2843 139.116C39.6721 138.496 39.3281 137.655 39.3281 136.777V74.1069C39.3315 65.998 42.5122 58.2221 48.1713 52.4882C53.8303 46.7544 61.5047 43.5316 69.5079 43.5282Z"
                fill="#425276"
            />
            <path
                d="M54.9187 86.6204C54.9187 86.6204 53.1725 89.3257 52.7296 89.8001L52.1348 88.659L54.9187 86.6204Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M164.464 0.358826C164.97 0.358826 165.38 0.772194 165.38 1.28211V74.7095C165.38 75.2194 164.97 75.6328 164.464 75.6328C163.957 75.6328 163.547 75.2194 163.547 74.7095V1.28211C163.547 0.772194 163.957 0.358826 164.464 0.358826Z"
                fill="#425276"
            />
            <path
                d="M196.593 37.4381L165 36.9313L165 4.61643L207.968 3.84631C208.455 3.84886 208.935 3.96676 209.369 4.19048C209.803 4.41419 210.18 4.73755 210.469 5.13486C210.758 5.53216 210.951 5.99241 211.033 6.4788C211.115 6.9652 211.084 7.46434 210.942 7.93638L203.502 32.2968C203.042 33.7887 202.124 35.0927 200.881 36.0178C199.638 36.9429 198.135 37.4406 196.593 37.4381Z"
                fill="#0EBE72"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M164.083 3.69312H208.174C208.735 3.69601 209.287 3.82995 209.786 4.0839C210.285 4.33786 210.718 4.70482 211.05 5.15541C211.382 5.60599 211.604 6.12773 211.698 6.67889C211.793 7.2301 211.757 7.79571 211.594 8.33066L211.593 8.33254L204.131 32.4434L204.131 32.4443C203.642 34.0136 202.663 35.3857 201.337 36.3594C200.011 37.3331 198.408 37.8572 196.762 37.8546H164.083V3.69312ZM165.002 36.9408H196.763C198.211 36.943 199.623 36.4819 200.791 35.6244C201.958 34.767 202.821 33.558 203.253 32.1742L210.714 8.06571L211.154 8.19817L210.715 8.06384C210.836 7.66473 210.862 7.2434 210.792 6.8322C210.721 6.42054 210.556 6.03122 210.308 5.69533C210.061 5.35945 209.739 5.08631 209.367 4.89743C208.996 4.70871 208.586 4.60926 208.17 4.60694H208.172V4.15036L208.169 4.60693L208.17 4.60694H165.002V4.15003H164.543V4.60694H165.002V36.9408ZM165.002 36.9408H164.543V37.3977H165.002V36.9408Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M153.196 74.7095C153.196 74.1996 153.607 73.7862 154.113 73.7862H174.638C175.144 73.7862 175.554 74.1996 175.554 74.7095C175.554 75.2194 175.144 75.6328 174.638 75.6328H154.113C153.607 75.6328 153.196 75.2194 153.196 74.7095Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M142.964 94.8257C143.977 94.8257 144.798 95.6525 144.798 96.6723V122.648C144.798 123.668 143.977 124.495 142.964 124.495C141.952 124.495 141.131 123.668 141.131 122.648V96.6723C141.131 95.6525 141.952 94.8257 142.964 94.8257Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M164.375 94.8257C165.388 94.8257 166.209 95.6525 166.209 96.6723V122.648C166.209 123.668 165.388 124.495 164.375 124.495C163.363 124.495 162.542 123.668 162.542 122.648V96.6723C162.542 95.6525 163.363 94.8257 164.375 94.8257Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M185.976 94.8257C186.988 94.8257 187.809 95.6525 187.809 96.6723V122.648C187.809 123.668 186.988 124.495 185.976 124.495C184.963 124.495 184.143 123.668 184.143 122.648V96.6723C184.143 95.6525 184.963 94.8257 185.976 94.8257Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.35759 218.086C1.36217 218.087 1.36859 218.087 1.37715 218.088C1.4107 218.091 1.46148 218.093 1.53164 218.096C1.67104 218.102 1.87273 218.108 2.13647 218.114C2.66318 218.126 3.42751 218.137 4.41469 218.147C6.38854 218.167 9.24744 218.184 12.8652 218.198C20.1005 218.227 30.368 218.245 42.6539 218.255C67.2255 218.275 99.8694 218.262 132.474 218.236C165.078 218.209 197.643 218.169 222.057 218.136C234.264 218.12 244.433 218.105 251.551 218.094C255.109 218.089 257.905 218.084 259.811 218.081L262.731 218.077C262.733 218.077 262.734 218.077 262.734 218.539C262.735 219.001 262.734 219.001 262.732 219.001L259.812 219.005C257.906 219.008 255.111 219.013 251.552 219.018C244.434 219.029 234.265 219.044 222.058 219.06C197.644 219.093 165.079 219.133 132.475 219.16C99.87 219.186 67.2256 219.199 42.6531 219.179C30.367 219.169 20.0984 219.151 12.8616 219.122C9.24334 219.108 6.38216 219.091 4.40541 219.071C3.41729 219.06 2.64869 219.05 2.11652 219.038C1.85082 219.032 1.64159 219.026 1.49253 219.02C1.41846 219.016 1.35564 219.013 1.30669 219.009C1.28253 219.007 1.25761 219.005 1.23443 219.002C1.23399 219.002 1.2335 219.002 1.23296 219.002C1.21911 219 1.17111 218.994 1.1207 218.977C0.880457 218.897 0.750356 218.635 0.830107 218.393C0.904138 218.168 1.13298 218.039 1.35759 218.086ZM1.35086 218.086C1.35008 218.085 1.34949 218.085 1.34907 218.085L1.35086 218.086ZM263.193 218.538C263.193 218.793 262.988 219 262.735 219.001L262.734 218.539L262.734 218.077C262.987 218.076 263.192 218.283 263.193 218.538Z"
                fill="#425276"
            />
            <path
                d="M33.7417 83.7357H101.314V140.518H33.7417C33.0537 140.518 32.3725 140.379 31.7369 140.11C31.1013 139.842 30.5238 139.448 30.0373 138.951C29.5509 138.454 29.165 137.865 28.9017 137.216C28.6384 136.567 28.5029 135.871 28.5029 135.169V89.0844C28.5029 88.382 28.6384 87.6865 28.9017 87.0375C29.165 86.3886 29.5509 85.7989 30.0373 85.3023C30.5238 84.8056 31.1013 84.4116 31.7369 84.1428C32.3725 83.874 33.0537 83.7357 33.7417 83.7357Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.397 84.6589H33.7417C33.1758 84.6589 32.6152 84.7727 32.0918 84.9941C31.5683 85.2155 31.0918 85.5403 30.6899 85.9507C30.288 86.3611 29.9685 86.8489 29.7503 87.3868C29.5321 87.9246 29.4196 88.5015 29.4196 89.0844V135.169C29.4196 135.752 29.5321 136.329 29.7503 136.866C29.9685 137.404 30.288 137.892 30.6899 138.303C31.0918 138.713 31.5683 139.038 32.0918 139.259C32.6152 139.481 33.1758 139.594 33.7417 139.594H100.397V84.6589ZM33.7417 83.7357C33.0537 83.7357 32.3725 83.874 31.7369 84.1428C31.1013 84.4116 30.5238 84.8056 30.0373 85.3023C29.5509 85.7989 29.165 86.3886 28.9017 87.0375C28.6384 87.6865 28.5029 88.382 28.5029 89.0844V135.169C28.5029 135.871 28.6384 136.567 28.9017 137.216C29.165 137.865 29.5509 138.454 30.0373 138.951C30.5238 139.448 31.1013 139.842 31.7369 140.11C32.3725 140.379 33.0537 140.518 33.7417 140.518H101.314V83.7357H33.7417Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.9692 90.2014C29.1229 89.9988 29.4106 89.9601 29.6117 90.115L64.1017 116.668C65.0293 117.381 66.1661 117.75 67.3254 117.716C68.4847 117.683 69.5991 117.247 70.4853 116.481L100.535 90.5892C100.728 90.4234 101.017 90.4461 101.182 90.6399C101.346 90.8336 101.324 91.125 101.131 91.2908L71.0825 117.181C71.0824 117.181 71.0826 117.181 71.0825 117.181C70.038 118.084 68.7223 118.599 67.352 118.639C65.9815 118.679 64.6392 118.242 63.5455 117.402L29.055 90.8485C28.8539 90.6937 28.8155 90.404 28.9692 90.2014Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.333 114.131C60.5284 114.293 60.5562 114.584 60.3952 114.781L39.5357 140.271C39.3747 140.468 39.0858 140.496 38.8905 140.334C38.6951 140.172 38.6673 139.881 38.8283 139.684L59.6878 114.193C59.8488 113.997 60.1377 113.969 60.333 114.131Z"
                fill="#425276"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.7643 113.882C73.9591 113.719 74.248 113.746 74.4097 113.943L95.7063 139.79C95.868 139.986 95.8411 140.278 95.6463 140.44C95.4515 140.603 95.1626 140.576 95.0009 140.38L73.7043 114.532C73.5426 114.336 73.5695 114.045 73.7643 113.882Z"
                fill="#425276"
            />
            <path d="M139.776 172.459L113.443 159.791V140.316H139.776V172.459Z" fill="#425276" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M186.083 29.5451C191.145 29.5451 195.249 25.4114 195.249 20.3122C195.249 15.2131 191.145 11.0794 186.083 11.0794C181.02 11.0794 176.916 15.2131 176.916 20.3122C176.916 25.4114 181.02 29.5451 186.083 29.5451ZM190.29 18.0415C190.564 17.7407 190.543 17.2735 190.245 16.9979C189.946 16.7222 189.482 16.7425 189.209 17.0432L184.685 22.0143L182.935 20.2516C182.648 19.9631 182.184 19.9631 181.897 20.2516C181.611 20.54 181.611 21.0077 181.897 21.2961L184.189 23.6043C184.331 23.7468 184.524 23.8249 184.724 23.8205C184.924 23.8161 185.113 23.7297 185.248 23.5812L190.29 18.0415Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5_21514">
                <rect width="264" height="220" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
