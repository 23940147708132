import React, { FC } from 'react';

import { Dic } from 'services/dictionary';
import { WtPopup3 } from 'components/wt-popup3';

interface Props {
    onConfirm: () => void;
    onCancel: () => void;
    popupWidth?: number;
}

const DEFAULT_POPUP_WIDTH = 384;

export const ConfirmationPopup: FC<Props> = ({ onConfirm, onCancel, popupWidth }) => (
    <WtPopup3
        width={popupWidth ?? DEFAULT_POPUP_WIDTH}
        title={Dic.word('wt_feed__widget_confirm_popup__dismiss_title')}
        className="autotest__feed-page__dismiss-confirmation-popup"
        actionButtons={{
            direction: 'horizontal',
            align: 'right',
            items: [
                {
                    key: 1,
                    kind: 'secondary',
                    content: Dic.word('wt_feed__widget_confirm_popup__dismiss_cancel'),
                    onClick: onCancel,
                    className: 'autotest__feed-page__dismiss-confirmation-popup-cancel-button',
                },
                {
                    key: 2,
                    content: Dic.word('wt_feed__widget_confirm_popup__dismiss_delete_and_close'),
                    onClick: onConfirm,
                    className: 'autotest__feed-page__dismiss-confirmation-popup-ok-button',
                },
            ],
        }}
    >
        {Dic.word('wt_feed__widget_confirm_popup__dismiss_message')}
    </WtPopup3>
);
