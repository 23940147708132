import { UpdateCopyTradingStrategyRequestBody } from '../steps/types';
import { Step2FormValues, Step3FormValues, Step4FormValues } from '../steps/copy-trading-strategies';
import { toData as step2ToData } from '../steps/copy-trading-strategies/step2/toData';
import { toData as step3ToData } from '../steps/copy-trading-strategies/step3/toData';
import { toData as step4ToData } from '../steps/copy-trading-strategies/step4/toData';
import { featureTitles } from '../wizard/constants';

export function getUpdateCopyTradingStrategyData(
    step2FormValues: Step2FormValues,
    step3FormValues: Step3FormValues,
    step4FormValues: Step4FormValues,
): UpdateCopyTradingStrategyRequestBody {
    const { minSum, riskLevel, recommendedHoldingPeriod } = step2ToData(step2FormValues);
    const { name, description, generalTradingIdea } = step3ToData(step3FormValues);
    const { tradingMethod, riskManagement, cashAndLeverage, additionalInformation } = step4ToData(step4FormValues);
    return {
        minSum,
        riskLevel,
        name,
        description,
        keyFeatures: [
            { title: featureTitles.recommendedHoldingPeriod, description: recommendedHoldingPeriod },
            { title: featureTitles.generalTradingIdea, description: generalTradingIdea },
            { title: featureTitles.tradingMethod, description: tradingMethod },
            { title: featureTitles.cashAndLeverage, description: cashAndLeverage },
            { title: featureTitles.riskManagement, description: riskManagement },
            { title: featureTitles.additionalInformation, description: additionalInformation },
        ].filter((feature) => feature.description),
    };
}
