import styled, { css } from 'styled-components';

import { matchBreakpoints } from 'app/styled';

import { SizeMapRibbon } from '../types';
import { ArrowComponentProps } from '../types/ArrowComponent.types';

const arrowMapWrapper: SizeMapRibbon = {
    large: css`
        height: 32px;
        width: 16px;
    `,
    big: css`
        height: 28px;
        width: 14px;
    `,
    medium: css`
        height: 24px;
        width: 12px;
    `,
    small: css`
        height: 20px;
        width: 10px;
    `,
};

export const ArrowWrapper = styled.div<Required<Pick<ArrowComponentProps, 'type' | 'size'>>>`
    flex-shrink: 0;
    flex-grow: 0;
    ${({ type }) => {
        switch (type) {
            case 'right':
                return css`
                    transform: rotate(180deg);
                    order: 2;
                    margin-left: -1px;
                `;
            // left
            default:
                return css`
                    order: 1;
                `;
        }
    }};

    svg {
        fill: ${({ theme }) => theme.fillIn.primary.mid};

        ${({ size }) => matchBreakpoints(size, arrowMapWrapper)};
    }
`;
