import { toCamelCase } from 'services/utils/to-camel-case';
import {
    ProductWithImageNotificationExporter,
    UserActionExporter,
    ProductDonationForTheAuthorNotificationExporter,
    ProductNewSignalNotificationExporter,
    YouBecameAnCreatorNotificationExporter,
} from 'services/api-services/generated_limex';

import { NotificationsState, NotificationsPulledState, NotificationItem } from './types';
import {
    ADD_IN_STORE_NOTIFICATION_ACTION,
    DELETE_NOTIFICATION_ACTIONS_TYPES,
    FETCH_NOTIFICATIONS_ACTIONS_TYPES,
    NotificationsActions,
    READ_IN_STORE_NOTIFICATIONS_ACTION,
    READ_NOTIFICATIONS_ACTIONS_TYPES,
    SET_IS_OPENED_ACTION,
    UPDATE_UNREAD_COUNT_ACTION,
} from './actions';

const initialState: NotificationsState = {
    isError: false,
    unreadCount: null,
    totalCount: null,
    notifications: null,
    nextCursor: null,
    readDate: '',
    isLoading: false,
    isOpened: false,
    isWasFetchNotifications: false,
};

const [FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE] =
    FETCH_NOTIFICATIONS_ACTIONS_TYPES;

const [READ_NOTIFICATIONS_REQUEST, READ_NOTIFICATIONS_SUCCESS, READ_NOTIFICATIONS_FAILURE] =
    READ_NOTIFICATIONS_ACTIONS_TYPES;

const [DELETE_NOTIFICATION_REQUEST, DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_FAILURE] =
    DELETE_NOTIFICATION_ACTIONS_TYPES;

const prepare = (
    notification:
        | UserActionExporter
        | ProductWithImageNotificationExporter
        | ProductDonationForTheAuthorNotificationExporter
        | ProductNewSignalNotificationExporter
        | YouBecameAnCreatorNotificationExporter
        | NotificationItem,
) => ({
    ...notification,
    type: toCamelCase(notification.type),
});

export default (pulledState: NotificationsPulledState = {}) =>
    (state: NotificationsState = { ...initialState, ...pulledState }, action: NotificationsActions) => {
        switch (action.type) {
            // FETCH
            case FETCH_NOTIFICATIONS_REQUEST: {
                const {
                    payload: { pass },
                } = action;
                const combineNotifications = pass?.combineNotifications || false;

                return {
                    ...state,
                    notifications: combineNotifications ? state.notifications : initialState.notifications,
                    isLoading: true,
                    isError: false,
                };
            }
            case FETCH_NOTIFICATIONS_SUCCESS: {
                const {
                    payload: {
                        data: { notifications, nextCursor, readDate, unreadCount },
                        pass,
                    },
                } = action;
                const totalCount = action?.payload?.data?.totalCount;
                const combineNotifications = pass?.combineNotifications || false;
                const resultNotifications = (
                    combineNotifications ? [...(state?.notifications || []), ...(notifications || [])] : notifications
                )?.map(prepare);

                return {
                    ...state,
                    nextCursor,
                    notifications: resultNotifications,
                    readDate: readDate || state.readDate,
                    unreadCount: unreadCount || state.unreadCount,
                    totalCount: totalCount || state.totalCount,
                    isError: false,
                    isLoading: false,
                    isWasFetchNotifications: true,
                };
            }
            case FETCH_NOTIFICATIONS_FAILURE: {
                return {
                    ...state,
                    isError: true,
                    isWasFetchNotifications: false,
                };
            }

            // DELETE
            case DELETE_NOTIFICATION_REQUEST: {
                const {
                    payload: {
                        pass: { id: requestNotificationId },
                    },
                } = action;

                const totalCountValue = state?.totalCount || 0;

                return {
                    ...state,
                    notifications: state?.notifications?.filter((item) => item.id !== requestNotificationId) || [],
                    totalCount: totalCountValue > 0 ? totalCountValue - 1 : 0,
                    isError: false,
                };
            }
            case DELETE_NOTIFICATION_SUCCESS: {
                return {
                    ...state,
                };
            }
            case DELETE_NOTIFICATION_FAILURE: {
                return {
                    ...state,
                    isError: true,
                };
            }
            // READ
            case READ_NOTIFICATIONS_REQUEST: {
                return {
                    ...state,
                };
            }
            case READ_NOTIFICATIONS_SUCCESS: {
                return {
                    ...state,
                    isError: false,
                };
            }
            case READ_NOTIFICATIONS_FAILURE: {
                return {
                    ...state,
                    isError: true,
                };
            }
            // BLOCK WITHOUT REQUEST IN API
            case SET_IS_OPENED_ACTION: {
                const { payload } = action;

                return {
                    ...state,
                    isOpened: payload,
                };
            }
            case UPDATE_UNREAD_COUNT_ACTION: {
                const { payload } = action;

                return {
                    ...state,
                    unreadCount: payload,
                };
            }
            case ADD_IN_STORE_NOTIFICATION_ACTION: {
                const { payload: notification } = action;

                return {
                    ...state,
                    notifications: [
                        prepare(notification),
                        ...(state?.notifications || []).filter((item) => item.uniqueKey !== notification.uniqueKey),
                    ],
                    unreadCount: (state?.unreadCount || 0) + 1,
                    readDate: notification?.timestamp || state.readDate,
                };
            }
            case READ_IN_STORE_NOTIFICATIONS_ACTION: {
                const notifications = state?.notifications;
                const isReadNotifications = notifications
                    ? notifications.map((item) => {
                          if (!item.readDate) {
                              return { ...item, readDate: Date.now() };
                          }
                          return item;
                      })
                    : initialState.notifications;

                return {
                    ...state,
                    unreadCount: 0,
                    notifications: isReadNotifications,
                };
            }

            default:
                return state;
        }
    };
