import React from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { PencilIcon } from 'components/icons2/PencilIcon';

import { useEditRecord } from '../../feed/hooks/useEditRecord';

const ContextActionEdit = () => {
    const { setIsEditing } = useEditRecord();
    const handleClick = () => {
        setIsEditing(true);
    };

    return (
        <MenuItem
            onClick={handleClick}
            icon={{ component: PencilIcon }}
            text={Dic.word('wt_feed__widget_author__context_action_edit_post')}
            autotestClass="autotest__feed_widget_author__context_action_edit_post"
        />
    );
};

export default ContextActionEdit;
