import styled, { css } from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { h3MediumFontStyle } from 'services/typography';

import { CardsSectionProps } from './types';

export const GridList = styled.div<Pick<CardsSectionProps, 'colsCount' | 'rowHeight'>>`
    display: grid;
    ${({ colsCount }) =>
        `grid-template-columns: repeat(${
            (typeof colsCount === 'object' && colsCount?.default) || colsCount || 4
        }, 1fr);`}
    ${({ rowHeight }) => {
        if (typeof rowHeight === 'number') {
            return css`
                grid-auto-rows: ${rowHeight}px;
            `;
        }
        if (typeof rowHeight === 'string') {
            return css`
                grid-auto-rows: ${rowHeight};
            `;
        }
        return undefined;
    }};
    gap: 24px;

    ${mediaQuery.lt1400} {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    ${mediaQuery.lt1200} {
        gap: 16px;
    }

    ${mediaQuery.lt960} {
        grid-template-columns: 1fr 1fr;
    }

    ${mediaQuery.lt768} {
        display: flex;
        gap: 16px;
        flex-direction: column;

        .card-item {
            max-width: 100%;
        }
    }
    ${({ colsCount }) => {
        if (colsCount) {
            return matchBreakpoints(colsCount, {
                1: css`
                    grid-template-columns: 1fr;
                `,
                2: css`
                    grid-template-columns: repeat(2, 1fr);
                `,
                3: css`
                    grid-template-columns: repeat(3, 1fr);
                `,
                4: css`
                    grid-template-columns: repeat(4, 1fr);
                `,
                5: css`
                    grid-template-columns: repeat(5, 1fr);
                `,
                6: css`
                    grid-template-columns: repeat(6, 1fr);
                `,
            });
        }
        return '';
    }}
`;

export const InfiniteGridListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InfiniteGridListLoadMoreSection = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    ${mediaQuery.lt960} {
        margin-top: 16px;
    }
`;

export const TitleWrapper = styled.div`
    margin: 0 0 24px 0;

    ${mediaQuery.lt1400} {
        margin-bottom: 20px;
    }

    ${mediaQuery.lt1200} {
        margin-bottom: 16px;
    }

    ${mediaQuery.lt480} {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h3`
    ${h3MediumFontStyle};
    margin: 0;

    ${mediaQuery.lt480} {
        font-size: 20px;
    }
`;
