import React, { useLayoutEffect, useRef, useState, useCallback } from 'react';
import throttle from 'lodash.throttle';

import { FeedRecordInstrument } from 'types/feed-record';
import { mediaQuery } from 'app/styled';
import Dic from 'services/dictionary';
import { useWindowResize } from 'hooks/useWindowResize';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { useLinks } from 'hooks/useLinks';
import { useBecomesVisible } from 'hooks/useBecomesVisible';
import Instrument, { INSTRUMENT_STYLE } from 'widgets/instrument2';

import { Wrapper, More, InstrumentWrapper } from './styled';

interface Props {
    instruments: FeedRecordInstrument[];
    autoUpdate: boolean;
}

export const InstrumentsList = (props: Props) => {
    const { instruments, autoUpdate } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const moreRef = useRef<HTMLDivElement>(null);

    const [lastVisibleInstrument, setLastVisibleInstrument] = useState<number | null>(null);
    const [truncateReady, setTruncateReady] = useState<boolean>(false);

    const isMobile = useMatchMedia(mediaQuery.lt480);

    const [showAllInstruments, setShowAllInstruments] = useState<boolean>(false);
    const [multilineable, setMultilineable] = useState<boolean>(false);
    const [bodyWidth, storeBodyWidth] = useState<number>(document.body.offsetWidth);

    useWindowResize(
        throttle(() => {
            if (showAllInstruments) return;
            // не делаем сброс если это изменние по высоте
            if (bodyWidth !== document.body.offsetWidth) {
                setLastVisibleInstrument(null);
                setTruncateReady(false);
                setMultilineable(false);
                storeBodyWidth(document.body.offsetWidth);
            }
        }, 1000),
    );
    const [ref, isVisible] = useBecomesVisible<HTMLDivElement>();

    useLayoutEffect(() => {
        if (!instruments?.length || !isVisible) return;
        // в соответстии с дизайном нам необходимо делать truncate инструментов, а именно
        // показывать инструменты в одну линию до тех пор пока они в неё помещаются + отображаеть "и еще N"
        // код ниже производит вычисления для такого транкейта
        if (!showAllInstruments && !isMobile && wrapperRef?.current && moreRef?.current) {
            const wrapperRect = wrapperRef.current.getBoundingClientRect();

            const { paddingLeft, paddingRight } = window.getComputedStyle(wrapperRef.current);
            const wrapperRight = wrapperRect.right;
            const moreWidth = moreRef.current.getBoundingClientRect().width;
            const instrumentsNodes = wrapperRef.current.querySelectorAll('.__instrument');
            if (
                instrumentsNodes?.[0] &&
                instrumentsNodes[0].getBoundingClientRect().width + moreWidth < wrapperRect.width
            ) {
                // находим первый инструмент, который не помещается
                const lastVisibleNodeIndex = Array.from(instrumentsNodes).findIndex(
                    (node) => node.getBoundingClientRect().right > wrapperRight - moreWidth - parseInt(paddingLeft, 10),
                    -parseInt(paddingRight, 10),
                );
                setLastVisibleInstrument(lastVisibleNodeIndex === -1 ? instrumentsNodes.length : lastVisibleNodeIndex);
                // случаи когда экран настолько узкий, что 1 инструмент с надписью "и еще N" не помещется по ширине
            } else if (instrumentsNodes?.[0]) {
                setLastVisibleInstrument(1);
                setMultilineable(true);
            }
        }
        setTruncateReady(true);
    }, [bodyWidth, isVisible]);

    const handleMoreClick = useCallback(() => setShowAllInstruments(true), []);

    const links = useLinks();

    if (!instruments?.length) return null;
    const instrumentsForRender =
        !showAllInstruments && !isMobile && lastVisibleInstrument && lastVisibleInstrument > 0
            ? instruments.slice(0, lastVisibleInstrument)
            : instruments;
    const hiddenCount = instruments.length - instrumentsForRender.length;
    // '00' - закладываемся под наибильшую ширину, вряд ли инструментов будет 100+
    return (
        <Wrapper
            ref={wrapperRef}
            showAllInstruments={showAllInstruments && !isMobile}
            multilineable={multilineable && !isMobile}
        >
            {instrumentsForRender.map(({ id, priceChange, price, dcpl, ticker, finamId, currency }) => (
                <InstrumentWrapper key={id} className="__instrument" ref={ref}>
                    {isVisible ? (
                        <Instrument
                            style={INSTRUMENT_STYLE.PAINTED3}
                            finamId={finamId}
                            currency={currency}
                            initChangePercent={typeof priceChange === 'number' ? priceChange : parseFloat(priceChange)}
                            initPrice={typeof price === 'number' ? price : parseFloat(price)}
                            initDcpl={dcpl}
                            initTicker={ticker}
                            url={links.instrument(ticker, finamId)}
                            tooltipEnabled={false}
                            autoUpdate={autoUpdate}
                        />
                    ) : null}
                </InstrumentWrapper>
            ))}
            {!showAllInstruments && !isMobile && Boolean(!truncateReady || hiddenCount) && (
                <More ref={moreRef} onClick={handleMoreClick}>
                    {Dic.word('wt_feed__widget_instruments__and_more', { count: hiddenCount || '00' })}
                </More>
            )}
        </Wrapper>
    );
};
