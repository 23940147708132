import React from 'react';

import { Person } from 'types';
import Dic from 'services/dictionary';
import { CameraIcon } from 'components/icons2/CameraIcon';

import { useIsOwner } from '../../../hooks';
import { ContextActionsContainer } from '../../context-actions';

import * as Styled from './styled';

type Props = {
    user: Person;
    toggleUserForm: () => void;
};

export const Cover = ({ user, toggleUserForm }: Props) => {
    const { cover } = user;
    const isOwner = useIsOwner();
    const handleClick = () => {
        if (!cover) toggleUserForm();
    };

    return (
        <Styled.CoverWrapper isOwner={isOwner} cover={cover} onClick={handleClick}>
            <Styled.ContextActionsWrapper>
                <ContextActionsContainer />
            </Styled.ContextActionsWrapper>
            {!cover && (
                <Styled.AddCoverWrapper>
                    <Styled.AddCover
                        text={
                            <Styled.AddCoverText>{Dic.word('wt_profile__widget_cover__add_cover')}</Styled.AddCoverText>
                        }
                        icon={{ component: CameraIcon }}
                    />
                </Styled.AddCoverWrapper>
            )}
        </Styled.CoverWrapper>
    );
};
