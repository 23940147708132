import produce from 'immer';

import { Id, FeedRecordStatsLikes, UserChoice, isPublishStatusRecord } from 'types';

import { FeedState } from '../types';
import { index } from '../helpers';
import { calculateLikes } from '../_shared/_sub/like/reducerUtils';

import { SEND_RATING_POST_ACTION_TYPES, SendPostRatingReactionActions } from './actions';

const [SEND_RATING_POST_REQUEST, SEND_RATING_COMMENT_SUCCESS, SEND_RATING_POST_FAILURE] = SEND_RATING_POST_ACTION_TYPES;

const updateLikes = (state: FeedState, recordId: Id, choice: UserChoice | null) =>
    produce(state, (draft) => {
        const record = draft.records[index(recordId)];
        if (isPublishStatusRecord(record)) {
            const { obj } = record;
            obj.stats.likes = calculateLikes(obj.stats.likes, choice);
        }
    });

const revertLikes = (state: FeedState, recordId: Id, prevLikes: FeedRecordStatsLikes) =>
    produce(state, (draft) => {
        const record = draft.records[index(recordId)];
        if (isPublishStatusRecord(record)) {
            const { obj } = record;
            obj.stats.likes = prevLikes;
        }
    });

export default (state: FeedState, action: SendPostRatingReactionActions) => {
    switch (action.type) {
        case SEND_RATING_POST_REQUEST: {
            const {
                payload: { pass },
            } = action;
            const { newChoice, recordId } = pass;
            return updateLikes(state, recordId, newChoice);
        }
        case SEND_RATING_COMMENT_SUCCESS: {
            const {
                payload: { pass, data },
            } = action;
            // message значит произошла ошибка но бэк вернул 200
            if ('message' in data) {
                const { prevSpecialState, recordId } = pass;
                const prevLikes = prevSpecialState?.obj?.stats.likes;
                return prevLikes ? revertLikes(state, recordId, prevLikes) : state;
            }
            return state;
        }
        case SEND_RATING_POST_FAILURE: {
            const {
                payload: { pass },
            } = action;
            const { prevSpecialState, recordId } = pass;
            const prevLikes = prevSpecialState?.obj?.stats.likes;
            return prevLikes ? revertLikes(state, recordId, prevLikes) : state;
        }
        default:
            return state;
    }
};
