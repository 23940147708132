/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedbackUninterestingExporter } from '../models/FeedbackService/FeedbackUninterestingExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackService {
    /**
     * Пометить контент, как неинтересный
     * @returns any Успешное применение незаинтересованности
     * @throws ApiError
     */
    public static createFeedbackUninteresting({
        xCsrfToken,
        objectType,
        objectId,
        requestBody,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        /**
         * Тип неинтересного объекта (3 - пост)
         */
        objectType: 3;
        /**
         * ИД неинтересного объекта. Недопустимо для собственных постов.
         */
        objectId: number;
        requestBody?: {
            /**
             * Тип незаинтересованности (0 - без параметров, по умолчанию. 1 - публикация. 2 - тикеры публикации. 3 - автор)
             */
            disinterestType?: 0 | 1 | 2 | 3;
        };
    }): CancelablePromise<{
        uninteresting?: FeedbackUninterestingExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/feedback/uninteresting/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Отменить отметку контента, как неинтересный
     * @returns any Успешная отмена в незаинтересованности контента
     * @throws ApiError
     */
    public static deleteFeedbackUninteresting({
        xCsrfToken,
        objectType,
        objectId,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        /**
         * Тип неинтересного объекта (3 - пост)
         */
        objectType: 3;
        /**
         * ИД неинтересного объекта
         */
        objectId: number;
    }): CancelablePromise<{
        uninteresting?: FeedbackUninterestingExporter;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/feedback/uninteresting/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Отправить форму обратной связи
     * @returns void
     * @throws ApiError
     */
    public static createFeedbackForm({
        recipient = 'social',
        requestBody,
    }: {
        /**
         * Получатель (social - Социальная сеть. marketplace - Маркетплейс)
         */
        recipient?: 'social' | 'marketplace';
        requestBody?: {
            /**
             * Имя пользователя
             */
            name: string;
            /**
             * Email пользователя
             */
            email: string;
            /**
             * Сообщение пользователя
             */
            text: string;
        };
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/feedback/form/',
            query: {
                recipient: recipient,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
            },
        });
    }
}
