import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LanguageIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.10118 1.84961C9.54301 1.84961 9.90118 2.20778 9.90118 2.64961V4.05008H16.3051C16.747 4.05008 17.1051 4.40825 17.1051 4.85008C17.1051 5.29191 16.747 5.65008 16.3051 5.65008H14.0939C13.7991 7.10428 12.8118 9.5206 10.243 12.3887C10.9192 13.0934 11.698 13.8512 12.5814 14.6508C12.909 14.9473 12.9342 15.4532 12.6377 15.7808C12.3412 16.1084 11.8353 16.1336 11.5077 15.8371C10.6325 15.0449 9.8451 14.2818 9.14854 13.559C7.24055 15.5665 6.37373 16.4057 5.51094 17.241C5.18493 17.5566 4.8595 17.8716 4.4787 18.2489C4.16485 18.5599 3.65832 18.5576 3.34734 18.2437C3.03637 17.9299 3.0387 17.4233 3.35256 17.1124C3.7443 16.7242 4.075 16.4041 4.40373 16.0858C5.27282 15.2444 6.12811 14.4164 8.061 12.3807C6.87029 11.0285 6.05184 9.8633 5.62984 8.97508C5.44023 8.57601 5.61004 8.09879 6.00912 7.90918C6.40819 7.71958 6.88541 7.88938 7.07502 8.28846C7.40831 8.98996 8.09805 9.98784 9.15644 11.2029C11.295 8.78297 12.1485 6.80867 12.4516 5.65008H1.90156C1.45973 5.65008 1.10156 5.29191 1.10156 4.85008C1.10156 4.40825 1.45973 4.05008 1.90156 4.05008H8.30118V2.64961C8.30118 2.20778 8.65935 1.84961 9.10118 1.84961ZM17.6012 12.0455L15.6429 17.0501H19.5595L17.6012 12.0455ZM20.1856 18.6501L21.3562 21.6416C21.5172 22.053 21.9812 22.2561 22.3927 22.0951C22.8041 21.9341 23.0072 21.47 22.8462 21.0586L18.7187 10.5105C18.3207 9.49354 16.8816 9.49354 16.4837 10.5105L12.3562 21.0586C12.1952 21.47 12.3982 21.9341 12.8097 22.0951C13.2211 22.2561 13.6852 22.053 13.8462 21.6416L15.0168 18.6501H20.1856Z"
        />
    </IconSvg>
);
