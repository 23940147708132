import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ExclamationMarkCircleFilledIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.0103 13.6113C12.4922 13.6113 12.769 13.3345 12.7896 12.8115L12.9229 7.83838C12.9434 7.32568 12.5332 6.93604 12 6.93604C11.4463 6.93604 11.0566 7.31543 11.0771 7.83838L11.2104 12.8115C11.2207 13.3242 11.4976 13.6113 12.0103 13.6113ZM12.0103 16.7798C12.5845 16.7798 13.0869 16.3389 13.0869 15.7544C13.0869 15.1699 12.5947 14.729 12.0103 14.729C11.4155 14.729 10.9233 15.1802 10.9233 15.7544C10.9233 16.3286 11.4258 16.7798 12.0103 16.7798Z"
        />
    </IconSvg>
);
