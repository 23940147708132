/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkDispatch } from 'redux-thunk';
import { enablePatches } from 'immer';
import stringify from 'fast-json-stable-stringify';
import { AnyAction, combineReducers } from 'redux';

import type { AnyObject } from 'types';
import { injectReducer, listenerMiddleware } from 'app/store2';
import { createThunkActions } from 'services/create-action-types/api-services';
import { reduceReducers } from 'services/utils/reduce-reducers';
import { RequestTypes } from 'services/create-action-types/shared/types';

import { createSelectPaginatedRequestRawData } from './selectors/selectPaginatedRequestRawData';
import { deleteData } from './actions/deleteData';
import { createSelectMutationState } from './selectors/selectMutationState';
import { createSelectPaginatedRequest } from './selectors/selectPaginatedRequest';
import { createSelectRequest } from './selectors/selectRequest';
import { createSelectResource } from './selectors/selectResource';
import { createMutation } from './actions/mutation';
import { nameToAction } from './utils/nameToAction';
import {
    NamedPaginatedSelector,
    NamedRawPaginatedSelector,
    NamedRequestStateSelector,
    NamedSelector,
    PaginatedSelector,
    RawPaginatedSelector,
    RequestStateSelector,
    ResourceSelector,
    Selector,
} from './selectors/types';
import {
    NamedRequestMoreThunk,
    NamedRequestThunk,
    OnErrorCallback,
    OnFulfilledCallback,
    OnRequestCallback,
    RequestMoreThunk,
    RequestThunk,
    RequestUpdateThunk,
    ResourceDeleteThunk,
    ResourceUpdateThunk,
} from './actions/types';
import { createFetchMore } from './actions/fetchMore';
import { createFetch } from './actions/fetch';
import { createArgsReducer } from './reducers/argsReducer';
import { createRequestsReducer } from './reducers/requestsReducer';
import { createUpdateResourceThunk } from './actions/updateResourceThunk';
import { createResourcesReducer } from './reducers/resourcesReducer';
import { capitalize } from './utils/capitalize';
import {
    AsyncMethod,
    ComputedFieldsConfig,
    GetTypeAtPath,
    GlobalStateForCache,
    PaginationConfig,
    Path,
    ResourceListener,
    ResourcesMap,
} from './types';
import { createUpdateRequestThunk } from './actions/updateRequestThunk';

enablePatches();

export class ApiBuilder<
    // eslint-disable-next-line @typescript-eslint/ban-types
    TYPES_MAP extends object = {},
    // eslint-disable-next-line @typescript-eslint/ban-types
    API_MAP extends object = {},
    METHODS_MAP extends Record<
        string,
        {
            method: AsyncMethod;
            dataPath?: string;
            pagination?: PaginationConfig<AsyncMethod, any>;
            isMutation?: boolean;
        }
        // eslint-disable-next-line @typescript-eslint/ban-types
    > = {},
> {
    private resourceReducers: unknown[] = [];

    private requestReducers: unknown[] = [];

    private argsReducers: unknown[] = [];

    private api: API_MAP = {} as API_MAP;

    private resourcesMap: ResourcesMap<TYPES_MAP> = {} as ResourcesMap<TYPES_MAP>;

    private methodsMap: METHODS_MAP = {} as METHODS_MAP;

    public addResource = <RESOURCE_NAME extends string, RESOURCE_TYPE>({
        name,
        getId,
        nestedResources,
        computedFields,
        listener,
    }: {
        name: RESOURCE_NAME;
        getId: (item: RESOURCE_TYPE) => unknown;
        nestedResources?: Partial<Record<Path<RESOURCE_TYPE>, keyof TYPES_MAP>>;
        computedFields?: ComputedFieldsConfig<TYPES_MAP, RESOURCE_TYPE>;
        listener?: ResourceListener<RESOURCE_TYPE>;
    }) => {
        (this.resourcesMap as any)[name] = { getId, resources: nestedResources, computedFields };

        (this.api as any)[`update${capitalize(name)}`] = createUpdateResourceThunk(this.resourcesMap, name);
        (this.api as any)[`delete${capitalize(name)}`] = (id: unknown) => deleteData('resource', name, id);
        (this.api as any)[`select${capitalize(name)}`] = createSelectResource(this.resourcesMap, name);

        const resourcesReducer = createResourcesReducer({
            resourceName: name,
            resourcesMap: this.resourcesMap,
        });

        this.resourceReducers.push(resourcesReducer);

        if (listener) {
            listenerMiddleware.startListening({
                predicate: (_, currentState, previousState) =>
                    (previousState as GlobalStateForCache).cache.data.resources[name] !==
                    (currentState as GlobalStateForCache).cache.data.resources[name],
                effect: async (_, listenerApi) => {
                    const newItems: RESOURCE_TYPE[] = [];
                    const updatedItems: { previous: RESOURCE_TYPE; current: RESOURCE_TYPE }[] = [];
                    const deletedItems: RESOURCE_TYPE[] = [];
                    const prevState = (listenerApi.getOriginalState() as GlobalStateForCache).cache.data.resources[
                        name
                    ];
                    const currentState = (listenerApi.getState() as GlobalStateForCache).cache.data.resources[name];
                    if (currentState) {
                        Object.entries(currentState).forEach(([key, value]) => {
                            if (prevState?.[key] === undefined && value !== undefined) {
                                newItems.push(value);
                            }
                            if (prevState?.[key] !== undefined && value !== undefined && prevState?.[key] !== value) {
                                updatedItems.push({ previous: prevState?.[key], current: value });
                            }
                        });
                        if (prevState) {
                            Object.entries(prevState).forEach(([key, value]) => {
                                if (currentState?.[key] === undefined) {
                                    deletedItems.push(value);
                                }
                            });
                        }
                    }
                    if (newItems.length > 0 || updatedItems.length > 0 || deletedItems.length > 0) {
                        listener({
                            dispatch: listenerApi.dispatch as ThunkDispatch<GlobalStateForCache, AnyObject, AnyAction>,
                            getState: listenerApi.getState as () => GlobalStateForCache,
                            getOriginalState: listenerApi.getOriginalState as () => GlobalStateForCache,
                            newItems,
                            updatedItems,
                            deletedItems,
                        });
                    }
                },
            });
        }

        return this as unknown as ApiBuilder<
            TYPES_MAP & Record<RESOURCE_NAME, RESOURCE_TYPE>,
            API_MAP &
                Record<`update${Capitalize<RESOURCE_NAME>}`, ResourceUpdateThunk<RESOURCE_TYPE>> &
                Record<`delete${Capitalize<RESOURCE_NAME>}`, ResourceDeleteThunk> &
                Record<`select${Capitalize<RESOURCE_NAME>}`, ResourceSelector<RESOURCE_TYPE>>,
            METHODS_MAP
        >;
    };

    public addQuery<
        NAME extends string,
        API_METHOD extends AsyncMethod,
        DATA_PATH extends Path<Awaited<ReturnType<API_METHOD>>> | '',
    >(options: {
        endpointName: NAME;
        apiMethod: API_METHOD;
        dataPath: DATA_PATH;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
    }): ApiBuilder<
        TYPES_MAP,
        API_MAP &
            Record<`fetch${Capitalize<NAME>}`, RequestThunk<API_METHOD>> &
            Record<
                `select${Capitalize<NAME>}`,
                Selector<NAME, API_METHOD, GetTypeAtPath<Awaited<ReturnType<API_METHOD>>, DATA_PATH>>
            > &
            Record<
                `update${Capitalize<NAME>}`,
                RequestUpdateThunk<{ args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> }>
            >,
        METHODS_MAP & Record<NAME, { method: API_METHOD; dataPath: DATA_PATH; pagination: undefined }>
    >;

    public addQuery<
        NAME extends string,
        API_METHOD extends AsyncMethod,
        DATA_PATH extends Path<Awaited<ReturnType<API_METHOD>>> | '',
        PAGE_DATA,
    >(options: {
        endpointName: NAME;
        apiMethod: API_METHOD;
        dataPath: DATA_PATH;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        pagination: PaginationConfig<API_METHOD, PAGE_DATA>;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
    }): ApiBuilder<
        TYPES_MAP,
        API_MAP &
            Record<`fetchFirst${Capitalize<NAME>}`, RequestThunk<API_METHOD>> &
            Record<`fetchMore${Capitalize<NAME>}`, RequestMoreThunk<API_METHOD>> &
            Record<
                `select${Capitalize<NAME>}`,
                PaginatedSelector<NAME, API_METHOD, GetTypeAtPath<Awaited<ReturnType<API_METHOD>>, DATA_PATH>>
            > &
            Record<`select${Capitalize<NAME>}RawData`, RawPaginatedSelector<NAME, API_METHOD>> &
            Record<
                `update${Capitalize<NAME>}`,
                RequestUpdateThunk<{ args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> }>
            >,
        METHODS_MAP &
            Record<
                NAME,
                { method: API_METHOD; dataPath: DATA_PATH; pagination: PaginationConfig<API_METHOD, PAGE_DATA> }
            >
    >;

    public addQuery<NAME extends keyof TYPES_MAP & string, API_METHOD extends AsyncMethod, PAGE_DATA>({
        endpointName,
        apiMethod,
        dataPath,
        nestedResources = {},
        pagination,
        onFulfilled,
    }: {
        endpointName: NAME;
        apiMethod: API_METHOD;
        dataPath: Path<Awaited<ReturnType<API_METHOD>>>;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        pagination?: PaginationConfig<API_METHOD, PAGE_DATA>;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
    }) {
        type RequestResource = { args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> };
        (this.resourcesMap as any)[endpointName] = {
            getId: ({ args }: RequestResource) => stringify(args),
            resources: nestedResources,
        };

        const { ACTION_TYPES, thunk } = this.createThunk(endpointName, apiMethod, 'GET_');
        this.createReducers(endpointName, ACTION_TYPES, pagination);

        if (pagination) {
            (this.api as any)[`select${capitalize(endpointName)}`] = createSelectPaginatedRequest({
                resourcesMap: this.resourcesMap,
                endpointName,
                dataPath,
                pagination,
            });

            (this.api as any)[`select${capitalize(endpointName)}RawData`] = createSelectPaginatedRequestRawData({
                resourcesMap: this.resourcesMap,
                endpointName,
                pagination,
            });

            (this.api as any)[`fetchFirst${capitalize(endpointName)}`] = createFetch({
                resourcesMap: this.resourcesMap,
                endpointName,
                thunk,
                pagination,
                onFulfilled,
                paramsCount: apiMethod.length,
            });

            (this.api as any)[`fetchMore${capitalize(endpointName)}`] = createFetchMore({
                resourcesMap: this.resourcesMap,
                endpointName,
                thunk,
                paginationConfig: pagination,
                onFulfilled,
            });

            (this.methodsMap as any)[endpointName] = { method: apiMethod, dataPath, pagination };
        } else {
            (this.api as any)[`select${capitalize(endpointName)}`] = createSelectRequest({
                resourcesMap: this.resourcesMap,
                endpointName,
                dataPath,
            });

            (this.api as any)[`fetch${capitalize(endpointName)}`] = createFetch({
                resourcesMap: this.resourcesMap,
                endpointName,
                thunk,
                pagination,
                onFulfilled,
                paramsCount: apiMethod.length,
            });

            (this.methodsMap as any)[endpointName] = { method: apiMethod, dataPath };
        }

        (this.api as any)[`update${capitalize(endpointName)}`] = createUpdateRequestThunk(
            this.resourcesMap,
            endpointName,
        );

        return this as unknown;
    }

    public addMutation<NAME extends string, API_METHOD extends AsyncMethod>(options: {
        mutationName: NAME;
        apiMethod: API_METHOD;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        onRequest?: never;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
        onError?: OnErrorCallback<API_METHOD>;
    }): ApiBuilder<
        TYPES_MAP,
        API_MAP &
            Record<NAME, RequestThunk<API_METHOD>> &
            Record<`select${Capitalize<NAME>}State`, RequestStateSelector<NAME, API_METHOD>>,
        METHODS_MAP & Record<NAME, { method: API_METHOD; pagination: undefined; isMutation: true }>
    >;

    public addMutation<
        NAME extends string,
        API_METHOD extends AsyncMethod,
        DATA_PATH extends Path<Awaited<ReturnType<API_METHOD>>> | '' = '',
    >(options: {
        mutationName: NAME;
        apiMethod: API_METHOD;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        onRequest: OnRequestCallback<API_METHOD>;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
        onError?: OnErrorCallback<API_METHOD>;
    }): ApiBuilder<
        TYPES_MAP,
        API_MAP &
            Record<NAME, RequestThunk<API_METHOD, GlobalStateForCache, undefined>> &
            Record<`select${Capitalize<NAME>}State`, RequestStateSelector<NAME, API_METHOD>>,
        METHODS_MAP & Record<NAME, { method: API_METHOD; dataPath: DATA_PATH; pagination: undefined; isMutation: true }>
    >;

    public addMutation<
        NAME extends string,
        API_METHOD extends AsyncMethod,
        DATA_PATH extends Path<Awaited<ReturnType<API_METHOD>>> | '',
    >({
        mutationName,
        apiMethod,
        dataPath,
        nestedResources,
        onRequest,
        onFulfilled,
        onError,
    }: {
        mutationName: NAME;
        apiMethod: API_METHOD;
        dataPath?: DATA_PATH;
        nestedResources?: Partial<Record<Path<{ result: Awaited<ReturnType<API_METHOD>> }>, keyof TYPES_MAP>>;
        onRequest?: OnRequestCallback<API_METHOD>;
        onFulfilled?: OnFulfilledCallback<API_METHOD>;
        onError?: OnErrorCallback<API_METHOD>;
    }) {
        type RequestResource = { args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> };
        (this.resourcesMap as any)[mutationName] = {
            getId: ({ args }: RequestResource) => stringify(args),
            resources: nestedResources,
        };

        const { ACTION_TYPES, thunk } = this.createThunk(mutationName, apiMethod);

        this.createReducers(mutationName, ACTION_TYPES);

        (this.api as any)[mutationName] = createMutation({
            thunk,
            onRequest,
            onFulfilled,
            onError,
        });

        (this.api as any)[`select${capitalize(mutationName)}State`] = createSelectMutationState({
            endpointName: mutationName,
        });

        (this.methodsMap as any)[mutationName] = { method: apiMethod, dataPath, isMutation: true };

        return this as unknown;
    }

    public registerNamedRequest<NAME extends string, REQUEST_NAME extends keyof METHODS_MAP>({
        name,
        endpointName,
    }: {
        name: NAME;
        endpointName: REQUEST_NAME;
    }): METHODS_MAP[REQUEST_NAME]['pagination'] extends undefined
        ? METHODS_MAP[REQUEST_NAME]['isMutation'] extends true
            ? ApiBuilder<
                  TYPES_MAP,
                  API_MAP &
                      Record<
                          NAME,
                          REQUEST_NAME extends keyof METHODS_MAP
                              ? NamedRequestThunk<METHODS_MAP[REQUEST_NAME]['method']>
                              : never
                      > &
                      Record<
                          `select${Capitalize<NAME>}State`,
                          REQUEST_NAME extends keyof METHODS_MAP ? NamedRequestStateSelector<NAME> : never
                      >,
                  METHODS_MAP
              >
            : ApiBuilder<
                  TYPES_MAP,
                  API_MAP &
                      Record<
                          `fetch${Capitalize<NAME>}`,
                          REQUEST_NAME extends keyof METHODS_MAP
                              ? NamedRequestThunk<METHODS_MAP[REQUEST_NAME]['method']>
                              : never
                      > &
                      Record<
                          `select${Capitalize<NAME>}`,
                          REQUEST_NAME extends keyof METHODS_MAP
                              ? NamedSelector<
                                    NAME,
                                    GetTypeAtPath<
                                        Awaited<ReturnType<METHODS_MAP[REQUEST_NAME]['method']>>,
                                        METHODS_MAP[REQUEST_NAME]['dataPath']
                                    >
                                >
                              : never
                      >,
                  METHODS_MAP
              >
        : ApiBuilder<
              TYPES_MAP,
              API_MAP &
                  Record<
                      `fetchFirst${Capitalize<NAME>}`,
                      REQUEST_NAME extends keyof METHODS_MAP
                          ? NamedRequestThunk<METHODS_MAP[REQUEST_NAME]['method']>
                          : never
                  > &
                  Record<
                      `fetchMore${Capitalize<NAME>}`,
                      REQUEST_NAME extends keyof METHODS_MAP
                          ? NamedRequestMoreThunk<METHODS_MAP[REQUEST_NAME]['method']>
                          : never
                  > &
                  Record<
                      `select${Capitalize<NAME>}`,
                      REQUEST_NAME extends keyof METHODS_MAP
                          ? NamedPaginatedSelector<
                                NAME,
                                GetTypeAtPath<
                                    Awaited<ReturnType<METHODS_MAP[REQUEST_NAME]['method']>>,
                                    METHODS_MAP[REQUEST_NAME]['dataPath']
                                >
                            >
                          : never
                  > &
                  Record<
                      `select${Capitalize<NAME>}RawData`,
                      REQUEST_NAME extends keyof METHODS_MAP
                          ? NamedRawPaginatedSelector<NAME, METHODS_MAP[REQUEST_NAME]['method']>
                          : never
                  >,
              METHODS_MAP
          >;

    public registerNamedRequest<NAME extends string, REQUEST_NAME extends string>({
        name,
        endpointName,
    }: {
        name: NAME;
        endpointName: REQUEST_NAME;
    }) {
        const { pagination, isMutation, method } = this.methodsMap[endpointName];
        const hasArg = method.length === 1;

        if (pagination) {
            (this.api as any)[`fetchFirst${capitalize(name)}`] = (...args: any[]) => {
                const options = { requestName: name, ...(args[1] ?? {}) };
                const originalFetchFirst = (this.api as any)[`fetchFirst${capitalize(endpointName)}`];
                return hasArg ? originalFetchFirst(args[0], options) : originalFetchFirst(options);
            };

            (this.api as any)[`fetchMore${capitalize(name)}`] = (...args: any[]) =>
                (this.api as any)[`fetchMore${capitalize(endpointName)}`](...args, name);

            const selector = createSelectPaginatedRequest({
                resourcesMap: this.resourcesMap,
                endpointName,
                dataPath: this.methodsMap[endpointName].dataPath,
                pagination,
                customPrefix: name,
            });
            (this.api as any)[`select${capitalize(name)}`] = (arg: any) => selector(arg, name);

            (this.api as any)[`select${capitalize(name)}RawData`] = (arg: any) =>
                createSelectPaginatedRequestRawData({
                    resourcesMap: this.resourcesMap,
                    endpointName,
                    pagination,
                    customPrefix: name,
                })(arg, name);
        } else if (isMutation) {
            (this.api as any)[name] = (...args: any[]) => {
                const originalMutation = (this.api as any)[endpointName];
                const options = { requestName: name, ...(args[1] ?? {}) };
                return hasArg ? originalMutation(args[0], options) : originalMutation(options);
            };
            const selector = createSelectMutationState({
                endpointName,
                customPrefix: name,
            });
            (this.api as any)[`select${capitalize(name)}State`] = (arg: any) => selector(arg, name);
        } else {
            (this.api as any)[`fetch${capitalize(name)}`] = (...args: any[]) => {
                const originalFetch = (this.api as any)[`fetch${capitalize(endpointName)}`];
                const options = { requestName: name, ...(args[1] ?? {}) };
                return hasArg ? originalFetch(args[0], options) : originalFetch(options);
            };
            const selector = createSelectRequest({
                resourcesMap: this.resourcesMap,
                endpointName,
                dataPath: this.methodsMap[endpointName].dataPath,
                customPrefix: name,
            });
            (this.api as any)[`select${capitalize(name)}`] = (arg: any) => selector(arg, name);
        }

        return this as unknown;
    }

    // eslint-disable-next-line class-methods-use-this
    private createThunk = <NAME extends string, API_METHOD extends AsyncMethod>(
        endpointName: NAME,
        apiMethod: API_METHOD,
        prefix = '',
    ) => {
        const [ACTION_TYPES, thunk] = createThunkActions<'cache', GlobalStateForCache>('cache')(
            `${prefix}${nameToAction(endpointName)}`,
            apiMethod,
        )<{ requestName?: string }>();
        return { ACTION_TYPES, thunk };
    };

    private createReducers = <NAME extends string, API_METHOD extends AsyncMethod, PAGE_DATA>(
        endpointName: NAME,
        ACTION_TYPES: RequestTypes<string, 'cache'>,
        pagination?: PaginationConfig<API_METHOD, PAGE_DATA>,
    ) => {
        this.resourceReducers.push(
            createResourcesReducer({
                ACTION_TYPES,
                resourceName: endpointName,
                resourcesMap: this.resourcesMap,
            }),
        );
        this.requestReducers.push(createRequestsReducer(endpointName, ACTION_TYPES));
        this.argsReducers.push(createArgsReducer(endpointName, ACTION_TYPES, pagination));
    };

    private getReducers = () =>
        combineReducers({
            data: reduceReducers(...this.resourceReducers),
            requests: reduceReducers(...this.requestReducers),
            args: reduceReducers(...this.argsReducers),
        });

    private injectReducers = () => {
        injectReducer('cache', this.getReducers());
    };

    public getApi = () => ({
        api: { ...this.api },
        injectReducers: this.injectReducers,
        cacheReducer: this.getReducers(),
        resourcesMap: this.resourcesMap,
    });
}
