import { backendClient } from 'services/backend-client';

const staticUrl = process.env.STATIC_URL;
const baseUrl = process.env.BASE_URL_COM;
const PROCESS_BUID_METRICS_API = `${baseUrl}/ng/api/v1/internal/user/process-buid-metrics/`;

let loaded = false;
let loading = false;
const onLoadedCallbacks: (() => void)[] = [];

const initOrUpdateModule = () => {
    window.WT.userMetrics?.setProject('limex');
};

const loadModule = () => {
    if (loading) {
        return;
    }

    loading = true;

    const script = document.createElement('script');
    script.src = `${staticUrl}/js/external/wt-user-metrics-module.min.js`;
    script.setAttribute('async', '');
    script.onerror = () => {
        loading = false;
        onLoadedCallbacks.splice(0, onLoadedCallbacks.length);
    };
    script.onload = () => {
        loaded = true;
        loading = false;
        onLoadedCallbacks.forEach((callback) => callback());
        onLoadedCallbacks.splice(0, onLoadedCallbacks.length);
    };
    document.head.appendChild(script);
};

/**
 * @return {Promise<WT.userMetrics>}
 */
export async function wtUserMetricsGet(): Promise<typeof window.WT.userMetrics> {
    if (loaded) {
        return window.WT.userMetrics;
    }

    return new Promise((resolve) => {
        onLoadedCallbacks.push(() => {
            resolve(window.WT.userMetrics);
        });
    });
}

export function wtUserMetricsLoadOrUpdate() {
    if (loaded) {
        initOrUpdateModule();
        return;
    }

    onLoadedCallbacks.push(() => initOrUpdateModule());
    loadModule();
}

export async function processBuidMetrics(): Promise<void> {
    const userMetrics = await wtUserMetricsGet();
    const buid = await userMetrics?.getBuid();

    await backendClient.post(PROCESS_BUID_METRICS_API, {
        currentUserUrl: window.location.href,
        referrer: document.referrer,
        activeProject: 'limex',
        buid,
    });
}

export async function linkUser(userId: number): Promise<void> {
    const userMetrics = await wtUserMetricsGet();
    userMetrics?.linkUser(userId, 'whotrades');
}
