import styled from 'styled-components';

import { ChartProps } from './types';

type ChartWrapperProps = Pick<ChartProps, 'withoutBgColor'>;

export const ChartWrapper = styled.div<ChartWrapperProps>`
    background: ${({ theme, withoutBgColor }) => (withoutBgColor ? 'transparent' : theme.bg.primary)};
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.006em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${({ theme }) => theme.label.secondary};
    width: 100%;
    height: 100%;
    transition: background-color 360ms;

    .recharts-yAxis > .recharts-cartesian-axis-ticks {
        text-align: right !important;
    }
`;
