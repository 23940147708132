export interface StatsResult {
    id: number;
    ic: number;
    rc: number;
    it: number;
    rt: number;
}

export enum UserChoice {
    LIKE = 1,
    DISLIKE = -1,
}
