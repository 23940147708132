import React, { ChangeEvent, forwardRef, memo, useCallback } from 'react';

import { ErrorMessage, InlineLabel, Input, LeftGroup, Wrapper } from './styled';
import type { TextInputProps } from './types';
import { RightGroup } from './RightGroup';

export const TextInput = memo(
    forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
        const {
            kind = 'fill',
            size = 'large',
            label,
            placeholder,
            rightLabel,
            icon: Icon,
            readonly = false,
            disabled = false,
            error,
            errorHighlight = false,
            value,
            onChange,
            onBlur,
            onLabelClick,
            onIconClick,
            className,
            wrapperRef,
            type,
            pattern,
            iconButtonTabIndex,
        } = props;

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onChange(e.currentTarget.value);
            },
            [onChange],
        );

        return (
            <div>
                <Wrapper
                    kind={kind}
                    size={size}
                    error={!!error || errorHighlight}
                    disabled={disabled}
                    hasLabel={Boolean(label)}
                    hasText={value.length > 0}
                    className={className}
                    ref={wrapperRef}
                >
                    <LeftGroup>
                        <>
                            {label ? <InlineLabel>{label}</InlineLabel> : null}
                            <Input
                                ref={ref}
                                placeholder={placeholder}
                                value={value}
                                onChange={handleChange}
                                onBlur={onBlur}
                                disabled={disabled || readonly}
                                type={type}
                                pattern={pattern}
                            />
                        </>
                    </LeftGroup>
                    <RightGroup
                        readonly={readonly}
                        rightLabel={rightLabel}
                        disabled={disabled}
                        onLabelClick={onLabelClick}
                        icon={Icon}
                        onIconClick={onIconClick}
                        iconButtonTabIndex={iconButtonTabIndex}
                    />
                </Wrapper>
                {error && !disabled && !readonly ? <ErrorMessage>{error}</ErrorMessage> : null}
            </div>
        );
    }),
);

export type { TextInputProps } from './types';
