import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { FormTextArea, FormTextInput } from 'components/form/react-hook-form';
import { VerticalFormInputGroup } from 'components/form';

import { STRATEGY_FIELD_PATTERN } from '../../../../hooks/constants';
import { StepProps } from '../../types';
import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { Label } from '../../styled';
import { KEY_FEATURE_DESCRIPTION_MAX_LENGTH, useKeyFeatureValidation } from '../../../../hooks/useKeyFeatureValidation';

import { toData } from './toData';
import {
    CHECK_FIELDS,
    defaultValues,
    DESCRIPTION_MAX_LENGTH,
    DESCRIPTION_MIN_LENGTH,
    NAME_MAX_LENGTH,
    NAME_MIN_LENGTH,
} from './constants';
import { Step3FormValues } from './types';

export const Step3: FC<StepProps<Step3FormValues> & { descriptionLabel?: string }> = memo((props) => {
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step3_');
    const form = useForm<Step3FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    useFormStateHandler({
        form,
        props,
        toData,
        title: dic.word('wt_all__widget_product_wizard__copy_trading_step2_title'),
        checkFields: CHECK_FIELDS,
    });

    const required = dic.word('wt_all__widget_product_wizard__field_required');
    const keyFeatureRules = useKeyFeatureValidation();

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                <Label>{dicWord('label')}</Label>
                <FormTextInput
                    label={dicWord('name')}
                    name="name"
                    kind="outline"
                    size="medium"
                    maxLength={NAME_MAX_LENGTH}
                    rules={{
                        required,
                        maxLength: {
                            value: NAME_MAX_LENGTH,
                            message: dic.word('wt_all__widget_product_wizard__text_block_title_too_long_msg'),
                        },
                        minLength: {
                            value: NAME_MIN_LENGTH,
                            message: dic.word('wt_all__widget_product_wizard__step2_field_description_min_message', {
                                min: NAME_MIN_LENGTH,
                            }),
                        },
                        pattern: {
                            value: STRATEGY_FIELD_PATTERN,
                            message: dic.word('wt_all__widget_product_wizard__field_special_characters_message'),
                        },
                    }}
                    className="autotest__marketplace__product_wizard__name"
                />
                <FormTextArea
                    label={dicWord('idea')}
                    name="generalTradingIdea"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__idea"
                />
                <FormTextArea
                    label={dicWord(props.descriptionLabel ?? 'description')}
                    name="description"
                    kind="outline"
                    size="medium"
                    rows={6}
                    maxLength={DESCRIPTION_MAX_LENGTH}
                    rules={{
                        required,
                        maxLength: {
                            value: DESCRIPTION_MAX_LENGTH,
                            message: dic.word('wt_all__widget_product_wizard__text_block_title_too_long_msg'),
                        },
                        minLength: {
                            value: DESCRIPTION_MIN_LENGTH,
                            message: dic.word('wt_all__widget_product_wizard__step2_field_description_min_message', {
                                min: DESCRIPTION_MIN_LENGTH,
                            }),
                        },
                        pattern: {
                            value: STRATEGY_FIELD_PATTERN,
                            message: dic.word('wt_all__widget_product_wizard__field_special_characters_message'),
                        },
                    }}
                    className="autotest__marketplace__product_wizard__description"
                />
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
