import { MutableRefObject, useLayoutEffect } from 'react';

import { useIncDec } from './useIncDec';

export function useNumberKeys(
    ref: MutableRefObject<HTMLInputElement | null>,
    value: string,
    onChange: (value: string) => void,
) {
    const [increment, decrement] = useIncDec(value, onChange);

    useLayoutEffect(() => {
        const el = ref.current;
        if (el) {
            const handleKeyDown = (e: KeyboardEvent) => {
                if (e.key === 'ArrowUp') {
                    increment();
                    e.preventDefault();
                }
                if (e.key === 'ArrowDown') {
                    decrement();
                    e.preventDefault();
                }
            };
            el.addEventListener('keydown', handleKeyDown);
            return () => el.removeEventListener('keydown', handleKeyDown);
        }
        return undefined;
    }, [decrement, increment, ref]);
}
