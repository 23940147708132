import React, { ReactNode } from 'react';
import cc from 'classcat';

import { ActionButtonsProps, ActionButtonsItemsProps, ActionButtonsChildrenProps } from './types';
import * as Styled from './styled';

const isActionButtonsReactElementType = (
    actionButtonsProps: ActionButtonsProps,
): actionButtonsProps is { children: ReactNode } => {
    if ('children' in actionButtonsProps && React.isValidElement(actionButtonsProps.children)) {
        return true;
    }

    return false;
};

export const ActionButtons = (props: ActionButtonsProps) => {
    const { className: classNameProp } = props;

    const className = cc([classNameProp, 'autotest__action-buttons']);

    const renderContent = () => {
        if (isActionButtonsReactElementType(props)) {
            const { children } = props;

            return <>{children}</>;
        }
        // TODO: разобраться зачем эта проверка
        if (typeof props === 'object' && !('items' in props)) {
            return null;
        }

        const { items } = props;

        if (items?.length < 1) {
            return null;
        }
        // TODO: разобраться зачем тут была добавлена проверка и зачем item? когда оно не может быть null или undefined
        return items.map((item) => (
            <Styled.Button size="small" {...item} isGreedy={item?.isGreedy || false}>
                {item?.content}
            </Styled.Button>
        ));
    };
    // TODO: разобраться зачем эта проверка
    if (!props || (typeof props === 'object' && Object.keys(props)?.length < 1)) {
        return null;
    }

    return <Styled.ActionButtonsWrapper className={className}>{renderContent()}</Styled.ActionButtonsWrapper>;
};

export type { ActionButtonsProps, ActionButtonsItemsProps, ActionButtonsChildrenProps };
