import { ProfileService, UsLimexCopyService } from 'services/api-services/generated_marketplace';

export async function registerLimexCopyUser() {
    const profile = await ProfileService.getProfile();
    if (profile.error) {
        throw new Error('Unable to get user profile');
    }
    if (!profile.data?.limexCopyMetadata) {
        const registration = await UsLimexCopyService.startRegistration();
        if (registration.error || !registration.data?.confirmationId) {
            throw new Error('Unable to start registration');
        }
        const confirmation = await UsLimexCopyService.confirmRegistration({
            requestBody: { confirmationId: registration.data.confirmationId },
        });
        if (confirmation.error || !confirmation.data?.Id) {
            throw new Error('Unable to complete registration');
        }
    }
}
