import React from 'react';
import cc from 'classcat';

import { isPublishStatusRecord } from 'types';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { useIsPremiumExtend } from '../feed/hooks/useIsPremiumExtend';
import { useIsOwner } from '../feed/hooks/useIsOwner';
import { Author } from '../author';
import { Instruments } from '../instruments';
import { ActivityContent } from '../activity-content';
import { Footer } from '../footer';

import { ActivityProps } from './types';
import { Title, ActivityWrapper, CommentsFallback } from './styled';

const Comments = React.lazy(() =>
    import(/* webpackChunkName: "comments" */ '../comments').then((module) => ({ default: module.Comments })),
);

// Это компонент-конструктор.
// В нем должно быть минимальное количество логики
// если надо скрыть какую-либо часть, то это надо делать снаружи через пропсы
export const Activity = (props: ActivityProps) => {
    const { className, children, record, author, title, instruments, htmlParsedContent, footer, comments } = props;

    const wrapperClassName = cc([className, 'autotest__feed-item']);
    const onlyChildren = <ActivityWrapper className={wrapperClassName}>{children}</ActivityWrapper>;

    const isOwner = useIsOwner(record);

    const { isPremium, isPremiumGranted } = useIsPremiumExtend(
        record && isPublishStatusRecord(record) ? record : undefined,
    );

    if (!record || !isPublishStatusRecord(record)) {
        return onlyChildren;
    }

    const { id, data, obj, stats, generator, options, context } = record || {};

    if (!obj) {
        return onlyChildren;
    }

    return (
        <ActivityWrapper className={wrapperClassName}>
            {author && generator && options && (
                <Author
                    obj={obj}
                    isPremium={isPremium}
                    generator={generator}
                    options={options}
                    onDelete={author.onDelete}
                    context={context}
                />
            )}
            {title && obj.title && <Title>{obj.title}</Title>}
            {htmlParsedContent && id && (
                <ActivityContent
                    obj={obj}
                    onClickAnyImgInPost={htmlParsedContent.onClickAnyImgInPost}
                    showComments={footer?.onClickComments}
                    isPremiumGranted={isPremiumGranted}
                    isPremium={isPremium}
                    isOwner={isOwner}
                    onPremiumSubscribe={htmlParsedContent.onPremiumSubscribe}
                />
            )}

            {instruments && <Instruments instruments={obj.instruments} />}
            {footer && stats && id && (
                <Footer
                    recordId={id}
                    recordData={data}
                    obj={obj}
                    stats={stats}
                    onClickComments={footer.onClickComments}
                />
            )}
            {record && stats && id && comments && (
                <React.Suspense
                    fallback={
                        <CommentsFallback>
                            <ProcessingIcon />
                        </CommentsFallback>
                    }
                >
                    <Comments recordId={id} showComments={comments} />
                </React.Suspense>
            )}
            {children}
        </ActivityWrapper>
    );
};

export { ActivityWrapper };
