import { RefObject, useEffect, useRef, useState } from 'react';

export function useBecomesVisible<T extends Element>(
    options?: IntersectionObserverInit,
): readonly [RefObject<T>, boolean] {
    const ref = useRef<T>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            if (entries.some((entry) => entry.isIntersecting)) {
                setIsVisible(true);
            }
        }, options);

        if (ref.current) {
            intersectionObserver.observe(ref.current);
        }
        return () => intersectionObserver.disconnect();
    }, [options]);

    return [ref, isVisible] as const;
}
