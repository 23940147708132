import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useNonClosableRef } from 'hooks/useUnclosureRef';

export function useOnSearchParameterChange(
    paramName: string,
    callback: () => void,
    compare: (current: string | undefined, prev: string | undefined) => boolean = (current, prev) => prev === current,
) {
    const callbackRef = useNonClosableRef(callback);
    const compareRef = useNonClosableRef(compare);
    const [params] = useSearchParams();
    const currentValue = params.get(paramName) ?? undefined;
    const previousRef = useRef<string | undefined>(currentValue);

    useEffect(() => {
        if (!compareRef.current(previousRef.current, currentValue)) {
            previousRef.current = currentValue;
            callbackRef.current();
        }
    }, [callbackRef, compareRef, currentValue]);

    return currentValue;
}
