import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const BlacklistContent = styled.div`
    margin-top: 8px;
    ${mediaQuery.lt768} {
        color: ${({ theme }) => theme.label.secondary};
    }
`;
