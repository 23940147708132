import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import { Action } from 'redux';

import { AnyObject } from 'types';
import { backendClient } from 'services/backend-client';

import { createRequestActionTypes } from '../shared/createRequestActionTypes';

import {
    BackendFailureAction,
    BackendRequestAction,
    BackendSuccessAction,
    BackendThunkAction,
    CancelationData,
    Request,
} from './types';

export const createThunkActions =
    <PREFIX extends string = 'widgets', STATE = AnyObject>(defaultPrefix: PREFIX = 'widgets' as PREFIX) =>
    <TYPE extends string>(type: TYPE, prefix: PREFIX = defaultPrefix) =>
    <REQUEST_DATA, RESPONSE_DATA, PASS extends AnyObject = AnyObject, EXTRA_ACTIONS extends Action = never>() => {
        const types = createRequestActionTypes(type, prefix);

        const getRequestAction = (
            request: Request<REQUEST_DATA>,
            pass: PASS,
        ): BackendRequestAction<REQUEST_DATA, TYPE, PREFIX, PASS> => ({
            type: types[0],
            payload: {
                request,
                pass,
            },
        });

        const getSuccessAction = (
            requestAction: BackendRequestAction<REQUEST_DATA, TYPE, PREFIX, PASS>,
            data: RESPONSE_DATA,
            pass: PASS,
        ): BackendSuccessAction<REQUEST_DATA, RESPONSE_DATA, TYPE, PREFIX, PASS> => ({
            type: types[1],
            payload: {
                data,
                pass: pass ?? ({} as PASS),
            },
            meta: {
                requestAction,
            },
        });

        const getFailureAction = (
            requestAction: BackendRequestAction<REQUEST_DATA, TYPE, PREFIX, PASS>,
            error: AxiosError,
            pass: PASS,
        ): BackendFailureAction<REQUEST_DATA, TYPE, PREFIX, PASS> => ({
            type: types[2],
            payload: {
                error,
                pass: pass ?? ({} as PASS),
            },
            meta: { requestAction },
        });

        function thunk({
            request,
            pass = {} as PASS,
            cancelation,
        }: {
            request: Request<REQUEST_DATA>;
            pass?: PASS;
            cancelation?: CancelationData;
        }): BackendThunkAction<REQUEST_DATA, RESPONSE_DATA, TYPE, PREFIX, PASS, STATE, EXTRA_ACTIONS> {
            return (dispatch) => {
                const requestAction = getRequestAction(request, pass);
                dispatch(requestAction);

                return backendClient({
                    ...request,
                    ...(cancelation ? { cancelToken: cancelation.source.token } : {}),
                })
                    .then((response: AxiosResponse<RESPONSE_DATA>) => {
                        const successAction = getSuccessAction(requestAction, response.data, pass);
                        dispatch(successAction);
                        return successAction;
                    })
                    .catch((error) => {
                        Sentry.captureException(error);
                        const failureAction = getFailureAction(requestAction, error, pass);
                        if (!axios.isCancel(error)) {
                            dispatch(failureAction);
                        }
                        throw failureAction;
                    });
            };
        }

        return [types, thunk, [getRequestAction, getSuccessAction, getFailureAction] as const] as const;
    };

export const thunkActions = createThunkActions();

export const Cancelation = () => ({
    source: axios.CancelToken.source(),
    outdated: false,
});

export type { Actions } from '../shared/types';
