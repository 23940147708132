import { useCallback, useEffect } from 'react';

import { FeedOwner } from 'context/feed';
import { sendGA } from 'services/analytics-outside/google-analytics';
import { sentryClient, SentryError } from 'services/sentry';
import { useLocaleId } from 'hooks/useLocaleId';
import { useLinks } from 'hooks/useLinks';
import { useOnSearchParameterChange } from 'hooks/useOnSearchParameterChange';
import { useOnRouteParameterChange } from 'hooks/useOnRouteParameterChange';
import { useNonClosableRef } from 'hooks/useUnclosureRef';

import { FeedProps } from '../../container';

import { useSendGAPacksCount } from './useSendGaPacksCount';
import { useCheckFeedLength } from './useCheckFeedLength';
import { useOnTagsChange } from './useSearchTagIds';

type UseFetchRecordsParameters = {
    source: 'feed' | 'timeline';
    owner: FeedOwner;
    props: FeedProps;
};

export const useFetchRecords = ({ source, owner, props }: UseFetchRecordsParameters) => {
    const { fetchRecords, getSocialActivityList, records, instanceId, boundary, isLoading, showRecommendedUsers } =
        props;
    const lcid = useLocaleId();
    const { feedPacksCount } = useSendGAPacksCount(records.length);
    const links = useLinks();

    const tagId = useOnRouteParameterChange({ path: links.tag(':tagId'), end: false }, 'tagId', fetchFirstPageFn);

    const communityId = useOnRouteParameterChange(
        { path: links.community(':communityId'), end: false },
        'communityId',
        fetchFirstPageFn,
    );

    const filter = useOnSearchParameterChange('filter', fetchFirstPageFn);

    const pinnedPostId = useOnRouteParameterChange(
        [
            { path: links.pinnedPostById(':userId', ':pinnedPostId'), end: true },
            { path: links.pinnedPremiumPostById(':userId', ':pinnedPostId'), end: true },
            { path: links.pinnedPostFullById(':userId', ':pinnedPostId'), end: true },
            { path: links.pinnedPremiumPostFullById(':userId', ':pinnedPostId'), end: true },
            { path: links.pinnedPostByAlias(':alias', ':pinnedPostId'), end: true, params: { alias: /@(.*)/ } },
            { path: links.pinnedPremiumPostByAlias(':alias', ':pinnedPostId'), end: true, params: { alias: /@(.*)/ } },
            { path: links.pinnedPostFullByAlias(':alias', ':pinnedPostId'), end: true, params: { alias: /@(.*)/ } },
            {
                path: links.pinnedPremiumPostFullByAlias(':alias', ':pinnedPostId'),
                end: true,
                params: { alias: /@(.*)/ },
            },
            { path: links.instrumentPinned(':cashtag', ':pinnedPostId'), end: true },
            { path: links.instrumentPinnedFull(':cashtag', ':pinnedPostId'), end: true },
        ],
        'pinnedPostId',
        fetchFirstPageFn,
    );
    const pinned = pinnedPostId === undefined ? undefined : parseInt(pinnedPostId, 10);

    const searchTagIds = useOnTagsChange(fetchFirstPageFn);

    const { isFeedLengthError } = useCheckFeedLength({ boundary, records, isLoading });

    function fetchFirstPageFn() {
        if (instanceId === 'socialActivity') {
            getSocialActivityList({
                instanceId: 'socialActivity',
                socialTypes: ['likes', 'comments'],
                reset: true,
            }).then(() => {
                window.scrollTo({ top: 0, behavior: 'auto' });
            });
        } else {
            fetchRecords(instanceId, {
                source,
                owner,
                lcid,
                // для поиска в общей ленте
                tagId,
                // для поиска в профиле
                searchTagIds,
                filter: filter ?? 'rank',
                reset: true,
                withRecommendedUsers: showRecommendedUsers,
                pinned,
            })
                .then(() => {
                    // если мы уже на бандле ленты, то надо отскроллить в самый верх
                    if (filter || tagId || communityId) {
                        window.scrollTo({ top: 0, behavior: 'auto' });
                    }
                })
                .catch((e) => {
                    sentryClient.captureException(new SentryError('fetchFirstPageFn error', 'FetchFirstPageFn'));
                    if (process.env.DLD === 'dev') {
                        console.error('fetchFirstPageFn', e);
                    }
                });
        }
    }

    const fetchFirstPageFnRef = useNonClosableRef(fetchFirstPageFn);

    useEffect(() => {
        fetchFirstPageFnRef.current();
    }, [fetchFirstPageFnRef]);

    const handleLoadMore = useCallback(() => {
        if (instanceId === 'socialActivity') {
            getSocialActivityList({
                instanceId: 'socialActivity',
            });
        } else {
            sendGA('feedRecords', ['fetch-by-intersection', feedPacksCount + 1]);
            fetchRecords(instanceId, {
                source,
                owner,
                lcid,
                // для поиска в общей ленте
                tagId,
                // для поиска в профиле
                searchTagIds,
                filter: filter ?? 'rank',
                withRecommendedUsers: showRecommendedUsers,
                pinned,
            });
        }
    }, [
        feedPacksCount,
        fetchRecords,
        filter,
        instanceId,
        lcid,
        owner,
        pinned,
        searchTagIds,
        source,
        tagId,
        getSocialActivityList,
    ]);

    const handleReloadPremiumPosts = useCallback(() => {
        fetchRecords('premiumPosts', {
            reset: true,
            owner,
            source: 'timeline',
            filter: 'rank',
            lcid,
        });
    }, [fetchRecords, lcid, owner]);

    return { handleLoadMore, isFeedLengthError, handleReloadPremiumPosts };
};
