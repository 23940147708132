import React, { FC, forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import { useMultipleRefs } from 'hooks/useMultipleRefs';
import { LabeledSwitch, Switch } from 'components/switch';
import { ErrorMessage } from 'components/text-input/styled';

import { rulesComparer } from '../utils/rulesComparer';

import { FormLabeledSwitchProps, FormSwitchProps } from './types';

export const FormSwitch = memo(
    forwardRef<HTMLInputElement, FormSwitchProps>(({ name, rules, ...switchProps }, forwardedRef) => {
        const {
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
        } = useController<{ [key: string]: boolean }>({ name, rules });

        const refFunction = useMultipleRefs(forwardedRef, ref);

        return (
            <div>
                <Switch isChecked={!!value} onChange={onChange} onBlur={onBlur} ref={refFunction} {...switchProps} />
                {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            </div>
        );
    }),
    rulesComparer,
);

export const FormLabeledSwitch: FC<FormLabeledSwitchProps> = memo(({ name, rules, ...switchProps }) => {
    const {
        field: { value, onChange, onBlur },
        fieldState: { error },
    } = useController<{ [key: string]: boolean }>({ name, rules });

    return (
        <div>
            <LabeledSwitch isChecked={!!value} onChange={onChange} onBlur={onBlur} {...switchProps} />
            {error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
        </div>
    );
}, rulesComparer);
