import { useEffect, useRef, useCallback } from 'react';
import throttle from 'lodash/throttle';

import { FeedRecord, InstrumentData, isPublishStatusRecord } from 'types';
import { useWebsocketListener } from 'hooks/useWebsocketListener';
import { useLocaleId } from 'hooks/useLocaleId';

import { FetchTickerNews } from '../actions';

const AUTO_FETCH_LIMIT = 10;

const getPos = (records: FeedRecord[], record: FeedRecord) => records.findIndex((_record) => _record.id === record.id);

export const useTickerNews = (fetchTickerNews: FetchTickerNews, instanceId: string, showTickerNews?: boolean) => {
    const lastViewed = useRef<FeedRecord | null>(null);
    const finamIds = useRef<number[]>([]);
    const autoFetchCounter = useRef(0);
    const lcid = useLocaleId();

    const fetchAndInsertTickerNews = useCallback(
        throttle(() => {
            if (lastViewed.current) {
                fetchTickerNews(
                    finamIds.current,
                    lcid,
                    (records, insertingRecords) => {
                        if (
                            insertingRecords.filter(
                                (insertingRecord) =>
                                    isPublishStatusRecord(insertingRecord) &&
                                    insertingRecord.tickerNews?.tickerNewsId &&
                                    !records.find((record) => record.id === insertingRecord.id),
                            ).length === 0
                        ) {
                            return -1;
                        }
                        const lastNewsRecord = records
                            .reverse()
                            .find((record) => isPublishStatusRecord(record) && record.tickerNews?.tickerNewsId);
                        // если в ленте нет новостей - вставляем после последней просмотренной
                        const prevRecord = lastNewsRecord || lastViewed.current || records[0];
                        // если новости есть - вставляем через 10 после последней новости
                        const offset = lastNewsRecord ? 10 : 0;
                        records.reverse();
                        let resultPos = getPos(records, prevRecord) + offset;
                        const lastViewedPos = lastViewed.current ? getPos(records, lastViewed.current) : 0;
                        // если даже с учетом offset последняя новость далеко от текущей - вставляем таки после текущей
                        if (lastViewedPos > resultPos) resultPos = lastViewedPos;
                        return resultPos > records.length - 1 ? -1 : resultPos;
                    },
                    instanceId,
                ).then(() => {
                    finamIds.current = [];
                });
            }
        }, 10000),
        [],
    );

    const onItemViewedChange = useCallback(
        (record: FeedRecord) => {
            if (!showTickerNews) return;

            lastViewed.current = record;
            autoFetchCounter.current++;
            if (autoFetchCounter.current >= AUTO_FETCH_LIMIT) {
                fetchAndInsertTickerNews();
                autoFetchCounter.current = 0;
            }
        },
        [fetchAndInsertTickerNews, showTickerNews],
    );

    const handleSocketMessage = useCallback((data?: { isNews: boolean; instrument: InstrumentData }) => {
        const { instrument, isNews } = data ?? {};
        if (isNews && instrument?.finamId) {
            finamIds.current.push(instrument.finamId);
            fetchAndInsertTickerNews();
        }
    }, []);

    useWebsocketListener({
        eventName: 'new_blogpost',
        callback: handleSocketMessage,
    });

    useEffect(() => {
        // для тестирования
        document.addEventListener('showNews', ((e: CustomEvent<number[]>) => {
            finamIds.current.push(...e.detail);
            fetchAndInsertTickerNews();
        }) as EventListener);
    }, []);

    return { onItemViewedChange };
};
