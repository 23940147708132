import { css } from 'styled-components';

import { matchBreakpoints } from 'app/styled';
import {
    body14RegularFontStyle,
    body16RegularFontStyle,
    caption11RegularFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';

import type { WrapperProps } from '../types';

import { InlineLabel, Input, LeftGroup, RightGroupWrapper, RightLabel, TextareaAutosizeWrapper } from './base';

export const buildSizes = ({ size, hasLabel, hasText, hasRows }: WrapperProps) =>
    matchBreakpoints(size, {
        large: css`
            padding: 16px 8px;

            ${InlineLabel}, ${RightLabel}, ${Input}, ${TextareaAutosizeWrapper}::after {
                ${body16RegularFontStyle};
            }

            ${hasText || hasRows ? '&' : '&:focus-within'} {
                padding: ${hasLabel ? '8px' : '16px 8px'};

                ${RightGroupWrapper} {
                    margin-top: ${hasLabel ? '8px' : '0'};
                }
            }

            ${hasText ? '&' : '&:focus-within'} {
                ${InlineLabel} {
                    ${caption12RegularFontStyle};
                }
            }
        `,
        medium: css`
            padding: 12px 8px;

            ${InlineLabel}, ${RightLabel}, ${Input}, ${TextareaAutosizeWrapper}::after {
                ${body14RegularFontStyle};
            }

            ${LeftGroup} {
                padding: 2px 8px;
            }

            ${hasText || hasRows ? '&' : '&:focus-within'} {
                padding: ${hasLabel ? '6px 8px' : '12px 8px'};

                ${LeftGroup} {
                    padding: ${hasLabel ? '0' : '2px'} 8px;
                }

                ${RightGroupWrapper} {
                    margin-top: ${hasLabel ? '6px' : '0'};
                }
            }

            ${hasText ? '&' : '&:focus-within'} {
                ${InlineLabel} {
                    ${caption11RegularFontStyle};
                }
            }
        `,
    });
