import React, { useCallback } from 'react';

import { Id } from 'types';
import Dic from 'services/dictionary';
import { ActionBlacklistContainer } from 'widgets/action-blacklist';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';

import { useToggleBlacklist } from '../../../page/hooks';

type Props = {
    userId: Id;
};

export const UnignoreButton = (props: Props) => {
    const { userId } = props;
    const handleToggleBlacklist = useToggleBlacklist(true);
    const customRender = useCallback(
        ({ toggleBlacklist }) => (
            <ButtonIcon
                onClick={() => handleToggleBlacklist(toggleBlacklist)}
                kind={ButtonIconKinds.FillPrimary}
                text={Dic.word('wt_profile__widget_user_form__deignore_button')}
            />
        ),
        [handleToggleBlacklist],
    );
    return <ActionBlacklistContainer personId={userId} isInBlacklist customRender={customRender} />;
};
