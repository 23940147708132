import React from 'react';

import { IconSvg, IconComponent } from '.';

export const MagnifierIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3202 14.906C18.7627 11.7675 18.5416 7.22787 15.6569 4.34315C12.5327 1.21895 7.46734 1.21895 4.34315 4.34315C1.21895 7.46734 1.21895 12.5327 4.34315 15.6569C7.22787 18.5416 11.7676 18.7627 14.906 16.3202L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L16.3202 14.906ZM14.5255 5.47452C17.0248 7.97387 17.0248 12.0261 14.5255 14.5255C12.0261 17.0248 7.97387 17.0248 5.47452 14.5255C2.97516 12.0261 2.97516 7.97387 5.47452 5.47452C7.97387 2.97516 12.0261 2.97516 14.5255 5.47452Z"
        />
    </IconSvg>
);
