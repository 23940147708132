/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Finam_AspNetCore_Extensions_WebResponse } from '../models/shared/Finam_AspNetCore_Extensions_WebResponse';
import type { PagedWebResponse_ReviewDto } from '../models/ReviewsService/PagedWebResponse_ReviewDto';
import type { WebResponse_GetRatingCountsWebResponse } from '../models/ReviewsService/WebResponse_GetRatingCountsWebResponse';
import type { WebResponse_Int64 } from '../models/ReviewsService/WebResponse_Int64';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReviewsService {
    /**
     * Получить отзывы
     * @returns PagedWebResponse_ReviewDto Success
     * @throws ApiError
     */
    public static getReviewsPrivate({
        productId,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
        /**
         * Сортировка
         */
        orderBy?: Array<string>;
    }): CancelablePromise<PagedWebResponse_ReviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/reviews',
            query: {
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Получить рейтинги по всем продуктам
     * @returns WebResponse_Int64 Success
     * @throws ApiError
     */
    public static getReviewsRatings({
        productId,
    }: {
        /**
         * Получить рейтинг конкретного продукта, пустота, если его нет
         */
        productId?: number;
    }): CancelablePromise<WebResponse_Int64> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/reviews/ratings',
            query: {
                productId: productId,
            },
        });
    }

    /**
     * Получить количество отзывов
     * @returns WebResponse_GetRatingCountsWebResponse Success
     * @throws ApiError
     */
    public static getReviewsRatingsCounters({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number;
    }): CancelablePromise<WebResponse_GetRatingCountsWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/reviews/ratings/counters',
            query: {
                productId: productId,
            },
        });
    }

    /**
     * Удалить отзыв
     * @returns Finam_AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static deleteReview({
        id,
    }: {
        /**
         * Идентификатор отзыва
         */
        id: number;
    }): CancelablePromise<Finam_AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/private/api/v1/reviews/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * Получить отзывы
     * @returns PagedWebResponse_ReviewDto Success
     * @throws ApiError
     */
    public static getReviews({
        productId,
        page,
        pageSize,
        orderBy,
        showMyFirst,
        authorIds,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
        /**
         * Сортировка
         */
        orderBy?: Array<string>;
        /**
         * Сперва выводить отзывы пользователя, совершающего запрос
         */
        showMyFirst?: boolean;
        /**
         * Фильтр по ИД авторов отзывов
         */
        authorIds?: Array<string>;
    }): CancelablePromise<PagedWebResponse_ReviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reviews',
            query: {
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
                ShowMyFirst: showMyFirst,
                AuthorIds: authorIds,
            },
        });
    }

    /**
     * Поставить лайк отзыву
     * @returns Finam_AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static updateApiVReviewLike({
        reviewId,
    }: {
        /**
         * Идентификатор отзыва
         */
        reviewId: number;
    }): CancelablePromise<Finam_AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/reviews/{reviewId}/like',
            path: {
                reviewId: reviewId,
            },
        });
    }
}
