import styled from 'styled-components';

import { AvatarLinkProps } from '../types';

export const AvatarLink = styled.a<Partial<Pick<AvatarLinkProps, 'isLoading' | 'href'>>>`
    display: inline-block;
    cursor: ${({ isLoading, href }) => {
        if (isLoading) return 'progress';
        if (href) return 'pointer';
        return 'inherit';
    }};
`;
