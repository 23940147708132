import produce from 'immer';

import { ChatEventsState } from 'widgets/chat/types';

import {
    CREATE_CHAT_EVENT_ACTION_TYPES,
    FETCH_CHAT_HISTORY_ACTION_TYPES,
    UPDATE_CHAT_HISTORY,
    ChatActions,
} from './actions';

const [, FETCH_CHAT_HISTORY_SUCCESS] = FETCH_CHAT_HISTORY_ACTION_TYPES;
const [, CREATE_CHAT_EVENT_SUCCESS] = CREATE_CHAT_EVENT_ACTION_TYPES;

const initialState: ChatEventsState = {
    events: [],
};

export default (state: ChatEventsState = initialState, action: ChatActions) => {
    switch (action.type) {
        case FETCH_CHAT_HISTORY_SUCCESS: {
            const {
                payload: {
                    data: { events },
                },
            } = action;

            return produce(state, (draft) => {
                draft.events = [...events];
            });
        }
        case CREATE_CHAT_EVENT_SUCCESS: {
            const {
                payload: {
                    data: { events },
                },
            } = action;
            return produce(state, (draft) => {
                draft.events.push(...events);
            });
        }
        case UPDATE_CHAT_HISTORY: {
            const {
                payload: { eventId, type, text, origin },
            } = action;
            return produce(state, (draft) => {
                draft.events.push({
                    eventId,
                    type,
                    params: { text },
                    origin,
                });
            });
        }
        default:
            return state;
    }
};
