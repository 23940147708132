/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';
import { getTokens } from 'services/wt-page-helper';
import { provideJWT } from 'services/global-auth-provider';

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
type Headers = Record<string, string>;

export type OpenAPIConfig = {
    BASE: string;
    VERSION: string;
    WITH_CREDENTIALS: boolean;
    CREDENTIALS: 'include' | 'omit' | 'same-origin';
    TOKEN?: string | Resolver<string>;
    USERNAME?: string | Resolver<string>;
    PASSWORD?: string | Resolver<string>;
    HEADERS?: Headers | Resolver<Headers>;
    ENCODE_PATH?: (path: string) => string;
};

export const OpenAPI: OpenAPIConfig = {
    BASE: process.env.FINAM_MARKETPLACE_API_URL,
    VERSION: 'current',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: async ({ method }: ApiRequestOptions) => {
        const tokens = await getTokens?.();
        const { csrf } = tokens || {};
        const { token } = await provideJWT();
        const headers: Headers = {};
        if (csrf && method !== 'GET' && method !== 'OPTIONS' && method !== 'HEAD') {
            headers['X-CSRF-Token'] = csrf;
        }
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    },
    ENCODE_PATH: undefined,
};
