import { AvatarSize } from 'components/avatar/types';

export const AVATAR_SIZE: AvatarSize = {
    default: 'L',
    lt480: 'M',
};

export const ACTIVE_AVATAR_PROPS = {
    size: AVATAR_SIZE,
};
