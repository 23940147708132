import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ContentWrapper = styled.div`
    height: 430px;
    padding: 0 0 12px;
    display: flex;
    flex-direction: column;

    ${mediaQuery.lt768} {
        height: inherit;
    }
`;

export const EmphasizedSection = styled.div`
    h4 {
        margin-bottom: 16px;
    }
`;
