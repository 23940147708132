import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ClockOutlineIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667ZM7.46659 8.33337C7.46659 8.54414 7.59071 8.73514 7.78331 8.82074L10.7833 10.1541C11.0525 10.2737 11.3677 10.1525 11.4873 9.88332C11.6069 9.61415 11.4857 9.29897 11.2165 9.17934L8.53325 7.98678V4.00004C8.53325 3.70549 8.29447 3.46671 7.99992 3.46671C7.70537 3.46671 7.46659 3.70549 7.46659 4.00004V8.33337Z"
        />
    </IconSvg>
);
