/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marketplace_Web_Private_V1_DocuSign_UpdateSettingsWebRequest } from '../models/DocuSignService/Marketplace_Web_Private_V1_DocuSign_UpdateSettingsWebRequest';
import type { Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateAuthorAgreementWebRequest } from '../models/DocuSignService/Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateAuthorAgreementWebRequest';
import type { Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateUserAgreementWebRequest } from '../models/DocuSignService/Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateUserAgreementWebRequest';
import type { Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAgreementWebRequest } from '../models/DocuSignService/Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAgreementWebRequest';
import type { Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAuthorAgreementWebRequest } from '../models/DocuSignService/Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAuthorAgreementWebRequest';
import type { PagedWebResponse_DigitalAgreementWebDto } from '../models/DocuSignService/PagedWebResponse_DigitalAgreementWebDto';
import type { WebResponse_Array_GetDocumentTemplateDto } from '../models/DocuSignService/WebResponse_Array_GetDocumentTemplateDto';
import type { WebResponse_CreateAuthorAgreementWebResponse } from '../models/DocuSignService/WebResponse_CreateAuthorAgreementWebResponse';
import type { WebResponse_CreateUserAgreementWebResponse } from '../models/DocuSignService/WebResponse_CreateUserAgreementWebResponse';
import type { WebResponse_DocuSignAgreementSettings } from '../models/DocuSignService/WebResponse_DocuSignAgreementSettings';
import type { WebResponse_Object } from '../models/DocuSignService/WebResponse_Object';
import type { WebResponse_String } from '../models/shared/WebResponse_String';
import type { WebResponse_UpdateAgreementWebResponse } from '../models/DocuSignService/WebResponse_UpdateAgreementWebResponse';
import type { WebResponse_UpdateAuthorAgreementWebResponse } from '../models/DocuSignService/WebResponse_UpdateAuthorAgreementWebResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocuSignService {
    /**
     * Получить текущие настройки DocuSign
     * @returns WebResponse_DocuSignAgreementSettings Success
     * @throws ApiError
     */
    public static getDocuSignSettings(): CancelablePromise<WebResponse_DocuSignAgreementSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/docu-sign/settings',
        });
    }

    /**
     * Обновить настройки DocuSign
     * @returns WebResponse_DocuSignAgreementSettings Success
     * @throws ApiError
     */
    public static updateDocuSignSettings({
        requestBody,
    }: {
        /**
         * Параметры обновления
         */
        requestBody?: Marketplace_Web_Private_V1_DocuSign_UpdateSettingsWebRequest;
    }): CancelablePromise<WebResponse_DocuSignAgreementSettings> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/docu-sign/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Получить текущие активные шаблоны документов из DocuSign
     * @returns WebResponse_Array_GetDocumentTemplateDto Success
     * @throws ApiError
     */
    public static getDocuSignTemplates(): CancelablePromise<WebResponse_Array_GetDocumentTemplateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/docu-sign/templates',
        });
    }

    /**
     * Формирует URL для получения consent от администратора организации на выполнение API запросов
     * @returns WebResponse_String Success
     * @throws ApiError
     */
    public static getDocuSignConsentUrl(): CancelablePromise<WebResponse_String> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/docu-sign/consent-url',
        });
    }

    /**
     * @deprecated
     * Обновить templateId и agreementType у существующих DigitalAgreements. SR-10753: Удалить после миграции
     * @returns WebResponse_Object Success
     * @throws ApiError
     */
    public static createDocuSign(): CancelablePromise<WebResponse_Object> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/docu-sign',
        });
    }

    /**
     * Создать электронное соглашение в DocuSign на автора продуктов
     * @returns WebResponse_CreateAuthorAgreementWebResponse Success
     * @throws ApiError
     */
    public static createAgreement({
        requestBody,
    }: {
        /**
         * Параметры создания
         */
        requestBody?: Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateAuthorAgreementWebRequest;
    }): CancelablePromise<WebResponse_CreateAuthorAgreementWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/digital-agreements/docu-sign/author',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @deprecated
     * Обновить данные электронного соглашения автора в DocuSign (статус)
     * SR-10752: Использовать общий эндпоинт
     * @returns WebResponse_UpdateAuthorAgreementWebResponse Success
     * @throws ApiError
     */
    public static updateApiVMyDigitalAgreementsDocuSignAuthor({
        requestBody,
    }: {
        /**
         * Параметры обновления
         */
        requestBody?: Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAuthorAgreementWebRequest;
    }): CancelablePromise<WebResponse_UpdateAuthorAgreementWebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/digital-agreements/docu-sign/author',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @deprecated
     * Получить список авторских соглашений
     * SR-10752: Использовать общий эндпоинт
     * @returns PagedWebResponse_DigitalAgreementWebDto Success
     * @throws ApiError
     */
    public static getApiVMyDigitalAgreementsDocuSignAuthorAgreements({
        productType,
        status,
        page,
        pageSize,
    }: {
        /**
         * Тип продукта
         */
        productType?: string | null;
        /**
         * Статус соглашения
         */
        status?: string | null;
        /**
         * Номер страницы
         */
        page?: number;
        /**
         * Количество элементов на странице
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_DigitalAgreementWebDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/digital-agreements/docu-sign/author/agreements',
            query: {
                ProductType: productType,
                Status: status,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Обновить данные электронного соглашения в DocuSign (статус)
     * @returns WebResponse_UpdateAgreementWebResponse Success
     * @throws ApiError
     */
    public static updateAgreementStatus({
        requestBody,
    }: {
        /**
         * Параметры обновления
         */
        requestBody?: Marketplace_Web_Public_V1_My_DigitalAgreements_Update_UpdateAgreementWebRequest;
    }): CancelablePromise<WebResponse_UpdateAgreementWebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/digital-agreements/docu-sign',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Получить список авторских соглашений
     * @returns PagedWebResponse_DigitalAgreementWebDto Success
     * @throws ApiError
     */
    public static getAgreements({
        productType,
        status,
        agreementType,
        productId,
        page,
        pageSize,
    }: {
        /**
         * Тип продукта
         */
        productType?: string | null;
        /**
         * Статус соглашения
         */
        status?: string | null;
        /**
         * Тип соглашения
         */
        agreementType?: 'Author' | 'User' | null;
        /**
         * Идентификатор продукта. Только для пользовательских соглашений
         */
        productId?: number | null;
        /**
         * Номер страницы
         */
        page?: number;
        /**
         * Количество элементов на странице
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_DigitalAgreementWebDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/digital-agreements/docu-sign/agreements',
            query: {
                ProductType: productType,
                Status: status,
                AgreementType: agreementType,
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Создать электронное соглашение в DocuSign на подписку на продукт
     * @returns WebResponse_CreateUserAgreementWebResponse Success
     * @throws ApiError
     */
    public static createSubscriberAgreement({
        requestBody,
    }: {
        /**
         * Параметры создания
         */
        requestBody?: Marketplace_Web_Public_V1_My_DigitalAgreements_Create_CreateUserAgreementWebRequest;
    }): CancelablePromise<WebResponse_CreateUserAgreementWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/digital-agreements/docu-sign/user',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }
}
