import React, { FC, memo } from 'react';
import { useController } from 'react-hook-form';

import { SelectionControl } from 'components/selection-control';

import { rulesComparer } from '../utils/rulesComparer';

import { FormSelectionControlProps } from './types';

export const FormSelectionControl: FC<FormSelectionControlProps> = memo(({ name, options, ...listBoxProps }) => {
    const {
        field: { value, onChange },
    } = useController<{ [key: string]: string }>({ name });

    return <SelectionControl options={options} onSelect={onChange} selectedOptionId={value} {...listBoxProps} />;
}, rulesComparer);
