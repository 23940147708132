import React from 'react';

export const Img = () => (
    <svg viewBox="0 0 400 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_33036)">
            <rect width="400" height="128" rx="12" fill="#A7BDF1" fillOpacity="0.08" />
            <path
                d="M0 28H32C36.4183 28 40 31.5817 40 36V92C40 96.4183 36.4183 100 32 100H0V28Z"
                fill="#A7BDF1"
                fillOpacity="0.24"
            />
            <rect x="64" y="28" width="72" height="72" rx="8" fill="#5F7095" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M95.2921 63.5911L88.6665 68.6667L87.3338 67.0008L94.2246 61.7132C94.8872 61.1831 95.8362 61.2106 96.4669 61.7783L102.009 66.7665L106.196 63.1034C106.844 62.536 107.811 62.5311 108.465 63.0918L112.694 66.7161L111.305 68.3358L107.34 64.937L103.147 68.6058C102.487 69.1837 101.498 69.1768 100.846 68.5897L95.2921 63.5911Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.8 55.4667H90.6665C89.6356 55.4667 88.7998 56.3024 88.7998 57.3333V70.1333C88.7998 71.1643 89.6356 72 90.6665 72H108.8C109.831 72 110.667 71.1643 110.667 70.1333V57.3333C110.667 56.3024 109.831 55.4667 108.8 55.4667ZM90.6665 53.3333C88.4574 53.3333 86.6665 55.1242 86.6665 57.3333V70.1333C86.6665 72.3425 88.4574 74.1333 90.6665 74.1333H108.8C111.009 74.1333 112.8 72.3425 112.8 70.1333V57.3333C112.8 55.1242 111.009 53.3333 108.8 53.3333H90.6665Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M102.333 60.9333C103.033 60.9333 103.6 60.3662 103.6 59.6667C103.6 58.9671 103.033 58.4 102.333 58.4C101.634 58.4 101.067 58.9671 101.067 59.6667C101.067 60.3662 101.634 60.9333 102.333 60.9333ZM102.333 62.6667C103.99 62.6667 105.333 61.3235 105.333 59.6667C105.333 58.0098 103.99 56.6667 102.333 56.6667C100.676 56.6667 99.3332 58.0098 99.3332 59.6667C99.3332 61.3235 100.676 62.6667 102.333 62.6667Z"
                fill="white"
            />
            <g filter="url(#filter0_dd_1_33036)">
                <rect x="160" y="24" width="80" height="80" rx="8" fill="#5F7095" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M195.292 63.5911L188.667 68.6667L187.334 67.0008L194.225 61.7132C194.887 61.1831 195.836 61.2106 196.467 61.7783L202.009 66.7665L206.196 63.1034C206.844 62.536 207.811 62.5311 208.465 63.0918L212.694 66.7161L211.305 68.3358L207.34 64.937L203.147 68.6058C202.487 69.1837 201.498 69.1768 200.846 68.5897L195.292 63.5911Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M208.8 55.4667H190.667C189.636 55.4667 188.8 56.3024 188.8 57.3333V70.1333C188.8 71.1643 189.636 72 190.667 72H208.8C209.831 72 210.667 71.1643 210.667 70.1333V57.3333C210.667 56.3024 209.831 55.4667 208.8 55.4667ZM190.667 53.3333C188.457 53.3333 186.667 55.1242 186.667 57.3333V70.1333C186.667 72.3425 188.457 74.1333 190.667 74.1333H208.8C211.009 74.1333 212.8 72.3425 212.8 70.1333V57.3333C212.8 55.1242 211.009 53.3333 208.8 53.3333H190.667Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M202.333 60.9333C203.033 60.9333 203.6 60.3662 203.6 59.6667C203.6 58.9671 203.033 58.4 202.333 58.4C201.634 58.4 201.067 58.9671 201.067 59.6667C201.067 60.3662 201.634 60.9333 202.333 60.9333ZM202.333 62.6667C203.99 62.6667 205.333 61.3235 205.333 59.6667C205.333 58.0098 203.99 56.6667 202.333 56.6667C200.676 56.6667 199.333 58.0098 199.333 59.6667C199.333 61.3235 200.676 62.6667 202.333 62.6667Z"
                    fill="white"
                />
            </g>
            <rect x="264" y="28" width="72" height="72" rx="8" fill="#5F7095" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M295.292 63.5911L288.667 68.6667L287.334 67.0008L294.225 61.7132C294.887 61.1831 295.836 61.2106 296.467 61.7783L302.009 66.7665L306.196 63.1034C306.844 62.536 307.811 62.5311 308.465 63.0918L312.694 66.7161L311.305 68.3358L307.34 64.937L303.147 68.6058C302.487 69.1837 301.498 69.1768 300.846 68.5897L295.292 63.5911Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M308.8 55.4667H290.667C289.636 55.4667 288.8 56.3024 288.8 57.3333V70.1333C288.8 71.1643 289.636 72 290.667 72H308.8C309.831 72 310.667 71.1643 310.667 70.1333V57.3333C310.667 56.3024 309.831 55.4667 308.8 55.4667ZM290.667 53.3333C288.457 53.3333 286.667 55.1242 286.667 57.3333V70.1333C286.667 72.3425 288.457 74.1333 290.667 74.1333H308.8C311.009 74.1333 312.8 72.3425 312.8 70.1333V57.3333C312.8 55.1242 311.009 53.3333 308.8 53.3333H290.667Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M302.333 60.9333C303.033 60.9333 303.6 60.3662 303.6 59.6667C303.6 58.9671 303.033 58.4 302.333 58.4C301.634 58.4 301.067 58.9671 301.067 59.6667C301.067 60.3662 301.634 60.9333 302.333 60.9333ZM302.333 62.6667C303.99 62.6667 305.333 61.3235 305.333 59.6667C305.333 58.0098 303.99 56.6667 302.333 56.6667C300.676 56.6667 299.333 58.0098 299.333 59.6667C299.333 61.3235 300.676 62.6667 302.333 62.6667Z"
                fill="white"
            />
            <path
                d="M360 36C360 31.5817 363.582 28 368 28H400V100H368C363.582 100 360 96.4183 360 92V36Z"
                fill="#A7BDF1"
                fillOpacity="0.24"
            />
            <rect x="372" y="52" width="24" height="24" rx="12" fill="#A7BDF1" fillOpacity="0.32" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M380.958 56.9122C381.282 56.6125 381.788 56.6327 382.088 56.9574L388.088 63.4574C388.371 63.7638 388.371 64.2362 388.088 64.5426L382.088 71.0426C381.788 71.3673 381.282 71.3875 380.958 71.0878C380.633 70.7882 380.613 70.282 380.912 69.9574L386.411 64L380.912 58.0426C380.613 57.718 380.633 57.2118 380.958 56.9122Z"
                fill="white"
            />
            <rect x="4" y="52" width="24" height="24" rx="12" fill="#A7BDF1" fillOpacity="0.32" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0428 56.9122C19.3675 57.2118 19.3877 57.718 19.088 58.0426L13.5889 64L19.088 69.9574C19.3877 70.282 19.3675 70.7882 19.0428 71.0878C18.7182 71.3875 18.212 71.3673 17.9124 71.0426L11.9124 64.5426C11.6295 64.2362 11.6295 63.7638 11.9124 63.4574L17.9124 56.9574C18.212 56.6327 18.7182 56.6125 19.0428 56.9122Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_dd_1_33036"
                x="120"
                y="0"
                width="160"
                height="160"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="16" />
                <feGaussianBlur stdDeviation="20" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.372549 0 0 0 0 0.439216 0 0 0 0 0.584314 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_33036" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.372549 0 0 0 0 0.439216 0 0 0 0 0.584314 0 0 0 0.32 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_1_33036" result="effect2_dropShadow_1_33036" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_33036" result="shape" />
            </filter>
            <clipPath id="clip0_1_33036">
                <rect width="400" height="128" rx="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
