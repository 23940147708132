import React, { FC, memo } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import * as Styled from './styled';

export interface Props {
    onCancel: () => void;
    onClose: () => void;
}

export const Confirmation: FC<Props> = memo(({ onCancel, onClose }) => {
    const dic = useDictionary();

    return (
        <WtPopup3
            title={dic.word('wt_all__support_popup__confirmation_header')}
            size={SizeWtPopup3Enum.SMALL}
            close={onCancel}
            width={598}
            actionButtons={{
                align: 'right',
                direction: 'horizontal',
                items: [
                    {
                        key: 1,
                        kind: 'primary',
                        content: dic.word('wt_all__support_popup__buttons_cancel'),
                        onClick: onCancel,
                    },
                    {
                        key: 2,
                        kind: 'secondary',
                        content: dic.word('wt_all__support_popup__buttons_close'),
                        onClick: onClose,
                    },
                ],
            }}
        >
            <Styled.Header>{dic.word('wt_all__support_popup__confirmation_subheader')}</Styled.Header>
        </WtPopup3>
    );
});
