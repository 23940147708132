import { StorageProvider } from '../storageProvider';

const storageKey = 'wtLogger';

const forceEnableActions = ['error'];

type Storage = {
    get(key: string): string;
    set(key: string, value: string | boolean): void;
};

type ActionName = 'log' | 'info' | 'warn' | 'debug' | 'error' | 'trace';
type LogParameters = Parameters<typeof console.log>;
type WrapMessage = (...message: LogParameters) => LogParameters;
type LoggerSettings = {
    name: string;
    color?: string;
    storage?: Storage;
    wrapMessage?: WrapMessage;
};

class WtLogger {
    public name: string;

    public color: string;

    public storage: Storage;

    public wrapMessage: WrapMessage;

    private _isEnabled: boolean;

    constructor({
        name,
        color = 'black',
        storage = StorageProvider(window.sessionStorage),
        wrapMessage = (...message: LogParameters) => message,
    }: LoggerSettings) {
        this.name = name;
        this.color = color;
        this.storage = storage;
        this.wrapMessage = wrapMessage;
        this._isEnabled = Boolean(this.storage.get(this._enabledKey()));
    }

    private _enabledKey() {
        return `${storageKey}:${this.name}:enabled`;
    }

    public toggle(value: boolean) {
        this._isEnabled = value;
        this.storage.set(this._enabledKey(), value);
    }

    private _action(actionName: ActionName, ...message: LogParameters) {
        if (this.isEnabled() || forceEnableActions.includes(actionName)) {
            window.console[actionName](
                `%c[${this.name}]`,
                `font-weight: bold; color: ${this.color};`,
                ...this.wrapMessage(...message),
            );
        }
    }

    public isEnabled() {
        return this._isEnabled;
    }

    public enable() {
        this.toggle(true);
    }

    public disable() {
        this.toggle(false);
    }

    public log(...message: LogParameters) {
        this._action('log', ...message);
    }

    public info(...message: LogParameters) {
        this._action('info', ...message);
    }

    public warn(...message: LogParameters) {
        this._action('warn', ...message);
    }

    public debug(...message: LogParameters) {
        this._action('debug', ...message);
    }

    public error(...message: LogParameters) {
        this._action('error', ...message);
    }

    public trace() {
        this._action('trace');
    }

    public static register(params: LoggerSettings) {
        const logger = new WtLogger(params);

        if (typeof window !== 'undefined') {
            if (!window.WT) window.WT = {};
            // We ignore this, and don't add global typings for loggers,
            // because we only want window.someLogger to be used from the browser console, not from the source code.
            // Use import to use logger from code
            // @ts-ignore
            if (!window.WT.loggers) window.WT.loggers = {};
            // @ts-ignore
            window.WT.loggers[params.name] = logger;
        }

        return logger;
    }
}

export default WtLogger;
