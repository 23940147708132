import React, { useMemo } from 'react';

import { Id, Person } from 'types';
import Dic from 'services/dictionary';
import { useLocaleId } from 'hooks/useLocaleId';
import { ActiveAvatar } from 'widgets/user-form';
import { PremiumPostBanner } from 'widgets/premium-post-banner';
import { GlobeIcon } from 'components/icons2/GlobeIcon';
import { LinkRoundButton } from 'components/round-button';
import { TelegramPlainIcon } from 'components/icons2/TelegramPlainIcon';
import { FacebookIcon } from 'components/icons2/FacebookIcon';
import { InstagramIcon } from 'components/icons2/InstagramIcon';
import { VKIcon } from 'components/icons2/VKIcon';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';

import { ContextActionsContainer } from '../../context-actions';
import { useIsOwner } from '../../../hooks';

import { Followers } from './Followers';
import { SubscribeButton } from './SubscribeButton';
import { UnignoreButton } from './UnignoreButton';
import {
    Actions,
    Wrapper,
    ContextActionsWrapper,
    Description,
    AddDescriptionButton,
    Networks,
    Site,
    ActiveAvatarWrapper,
    StickyContainer,
    BannerMonetizationWrapper,
    StyledUserName,
    UserDataWrapper,
} from './styled';

type Props = {
    user: Person;
    toggleUserForm: () => void;
    onUnsubscribe: (id: Id) => void;
};

const DESCRIPTION_MAX_DISPLAY_LENGTH = 150;

export const HeaderUser = (props: Props) => {
    const {
        user: {
            id,
            displayName = '',
            isVerified,
            isVerifiedAuthor,
            counters,
            description,
            images,
            cover,
            data,
            isBlockedByCurrentUser,
            isBlockedByGivenUser,
            socialNets,
        },
        toggleUserForm,
        onUnsubscribe,
    } = props;
    const { subscribed, premiumSubscribed } = data || {};
    const { personalSite, instagram, telegram, vkontakte, facebook } = socialNets || {};
    const { big, base } = images || {};
    const image = big && base ? `${base}${big}` : undefined;
    const { followers = 0, following = 0 } = counters || {};
    const isOwner = useIsOwner();
    const lcid = useLocaleId();

    const slicedDescription = useMemo(() => {
        if (!description || description.length < DESCRIPTION_MAX_DISPLAY_LENGTH) return description;
        return `${description.slice(0, DESCRIPTION_MAX_DISPLAY_LENGTH)}...`;
    }, [description]);

    return (
        <StickyContainer isOwner={isOwner} hasCover={Boolean(cover)}>
            <Wrapper>
                <UserDataWrapper>
                    <ActiveAvatarWrapper>
                        <ActiveAvatar
                            alt="avatar"
                            src={image || ''}
                            size={{ default: 120, lt768: 80 }}
                            isActiveOnHover={isOwner}
                            isActive={{ default: 'no', lt1200: isOwner && !image ? 'yes' : 'no' }}
                            onSubstrateClick={toggleUserForm}
                            hasOffset={isOwner || Boolean(cover)}
                        />
                    </ActiveAvatarWrapper>
                    <ContextActionsWrapper>
                        <ContextActionsContainer />
                    </ContextActionsWrapper>
                    <StyledUserName
                        displayName={displayName}
                        isVerified={isVerified}
                        isVerifiedAuthor={isVerifiedAuthor}
                        isOneLine={false}
                    />
                    <Followers isBlocked={Boolean(isBlockedByGivenUser)} followers={followers} following={following} />
                    {(slicedDescription || isOwner) && (
                        <Description>
                            {slicedDescription ||
                                (isOwner && (
                                    <div>
                                        <AddDescriptionButton
                                            text={Dic.word('wt_profile__widget_header_user__add_description')}
                                            onClick={toggleUserForm}
                                        />
                                    </div>
                                ))}
                        </Description>
                    )}
                    {personalSite && (
                        <Site>
                            <GlobeIcon size={16} />
                            <a
                                href={personalSite.match(/^https?/) ? personalSite : `http://${personalSite}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {personalSite}
                            </a>
                        </Site>
                    )}
                    <Networks>
                        {telegram && (
                            <LinkRoundButton href={telegram} target="_blank">
                                <TelegramPlainIcon />
                            </LinkRoundButton>
                        )}
                        {lcid !== 'ru' && (
                            <>
                                {facebook && (
                                    <LinkRoundButton href={facebook} target="_blank">
                                        <FacebookIcon />
                                    </LinkRoundButton>
                                )}
                                {instagram && (
                                    <LinkRoundButton href={instagram} target="_blank">
                                        <InstagramIcon />
                                    </LinkRoundButton>
                                )}
                            </>
                        )}
                        {vkontakte && (
                            <LinkRoundButton href={vkontakte} target="_blank">
                                <VKIcon />
                            </LinkRoundButton>
                        )}
                    </Networks>
                    <Actions>
                        {!isBlockedByGivenUser && (
                            <>
                                {!isOwner && !isBlockedByCurrentUser && (
                                    <SubscribeButton
                                        displayName={displayName}
                                        id={id}
                                        subscribed={Boolean(subscribed)}
                                        premiumSubscribed={premiumSubscribed ?? false}
                                        onUnsubscribe={onUnsubscribe}
                                    />
                                )}
                                {!isOwner && isBlockedByCurrentUser && <UnignoreButton userId={id} />}
                                {isOwner && (
                                    <ButtonIcon
                                        kind={ButtonIconKinds.FillPrimary}
                                        text={Dic.word('wt_profile__widget_header_user__edit_btn')}
                                        onClick={toggleUserForm}
                                        pressed
                                        className="autotest__profile-page__header-edit"
                                    />
                                )}
                            </>
                        )}
                    </Actions>
                </UserDataWrapper>
                {isOwner && !isVerifiedAuthor && (
                    <BannerMonetizationWrapper>
                        <PremiumPostBanner />
                    </BannerMonetizationWrapper>
                )}
            </Wrapper>
        </StickyContainer>
    );
};
