import { useState, useEffect } from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import parseISO from 'date-fns/parseISO';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import { Lcid } from 'types';

const locales = {
    ru: ruLocale,
    en: enLocale,
};

type UseAgoSettings = {
    lcid: Lcid;
    timeout?: number;
};

/**
 * Returns a formatted "time ago" string, that updates every `timeout` milliseconds.
 * `timeString` must be in the ISO format.
 *
 * @example
 * const timeAgo = useAgo("2021-12-10T14:21:19.964Z", {
 *   lcid: "en",
 *   timeout: 60000 // default
 * });
 *
 * return (
 *     <>Published {timeAgo}</>
 * );
 */
export const useAgo = (timeString: string, { lcid, timeout = 60000 }: UseAgoSettings) => {
    const date = parseISO(timeString);

    const initialAgoString = formatToAgoString(date, lcid);
    const [currentAgoString, setCurrentAgoString] = useState(initialAgoString);

    useEffect(() => {
        setCurrentAgoString(formatToAgoString(date, lcid));
    }, [date, lcid]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAgoString(formatToAgoString(date, lcid));
        }, timeout);

        return () => clearInterval(interval);
    }, [date, lcid, timeout]);

    return {
        timeAgo: currentAgoString,
        parsedISO: date,
        localeTimeString: date.toLocaleDateString(lcid, { hour: 'numeric', minute: 'numeric' }),
    };
};

export function formatToAgoString(date: Date, lcid: Lcid) {
    const now = Date.now();
    const config = {
        locale: locales[lcid],
        addSuffix: true,
    };

    return differenceInMinutes(now, date) < 1
        ? formatDistanceToNow(date, config)
        : formatDistanceToNowStrict(date, config);
}
