import styled from 'styled-components';

import { body14RegularFontStyle, h5BoldFontStyle } from 'services/typography';
import { LinkButton } from 'components/button2';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    background: ${({ theme }) => theme.fillIn.secondary.muted};
    border-radius: 12px;
    width: 100%;
`;

export const ButtonWrapper = styled.div`
    margin-top: 59px;
`;

export const Button = styled(LinkButton)`
    display: inline-block;
`;

export const Title = styled.div`
    ${h5BoldFontStyle};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.012em;
    margin-bottom: 8px;
}
`;

export const Text = styled.div`
    ${body14RegularFontStyle};
    flex-grow: 1;
`;

export const Content = styled.div`
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
`;

export const LocksSvgWrapper = styled.div`
    position: absolute;
    bottom: -40px;
    right: -41.5px;
`;

export const ShadowSvgWrapper = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0;
`;
