import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LockOutlineIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.69995 7.0002C7.69995 4.62537 9.62513 2.7002 12 2.7002C14.3748 2.7002 16.3 4.62537 16.3 7.0002V10.2002H17C17.9941 10.2002 18.8 11.0061 18.8 12.0002V20.0002C18.8 20.9943 17.9941 21.8002 17 21.8002H6.99995C6.00584 21.8002 5.19995 20.9943 5.19995 20.0002V12.0002C5.19995 11.0061 6.00584 10.2002 6.99995 10.2002H7.69995V7.0002ZM9.29995 10.2002H14.7V7.0002C14.7 5.50903 13.4911 4.3002 12 4.3002C10.5088 4.3002 9.29995 5.50903 9.29995 7.0002V10.2002ZM6.99995 11.8002C6.88949 11.8002 6.79995 11.8897 6.79995 12.0002V20.0002C6.79995 20.1107 6.88949 20.2002 6.99995 20.2002H17C17.1104 20.2002 17.2 20.1107 17.2 20.0002V12.0002C17.2 11.8897 17.1104 11.8002 17 11.8002H6.99995Z"
        />
    </IconSvg>
);
