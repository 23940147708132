import React, { ReactNode } from 'react';

import { CloseIcon } from 'components/icons2/CloseIcon';

import { CancelButton, TopRightCorner, CancelableWrapper } from './styled';

type CancelableProps = {
    isShown?: boolean;
    children: ReactNode | ReactNode[];
    cancelFn?: () => void;
};
export const Cancelable = ({ isShown, children, cancelFn }: CancelableProps) => (
    <CancelableWrapper>
        {children}
        {isShown && (
            <TopRightCorner>
                <CancelButton
                    onClick={(e) => {
                        e.preventDefault();
                        cancelFn?.();
                    }}
                >
                    <CloseIcon size={16} />
                </CancelButton>
            </TopRightCorner>
        )}
    </CancelableWrapper>
);
