import React, { useState, useCallback, memo, useMemo } from 'react';

import { TagData } from 'types';
import { useDictionary } from 'hooks/useDictionary';
import { WtPopup3, WtPopup3Props } from 'components/wt-popup3';
import { Tags, TagsProps, SelectedTagsIds } from 'components/tags';

import { SubTitle, TagsWrapper } from './styled/TagsPopup';

type Props = Pick<WtPopup3Props, 'close'> & {
    tags: TagData[];
    initSelectedIds: SelectedTagsIds;
    onSubmit: (selectedIds: number[]) => void;
};

const LIMIT = 3;

export const TagsPopup = memo((props: Props) => {
    const { close, tags, onSubmit, initSelectedIds } = props;
    const dic = useDictionary();
    const [selectedIds, setSelectedIds] = useState<number[]>(initSelectedIds);

    const handleSelectTag = useCallback<NonNullable<TagsProps['onSelectTag']>>(({ selectedIdsCandidate }) => {
        setSelectedIds(selectedIdsCandidate);
    }, []);

    const handleSubmit = useCallback(() => {
        onSubmit(selectedIds);
        close?.();
    }, [onSubmit, selectedIds, close]);

    const actionButtons = useMemo<WtPopup3Props['actionButtons']>(
        () => ({
            align: 'right',
            items: [
                {
                    key: 0,
                    content: dic.word('wt_all__support_popup__buttons_cancel'),
                    onClick: close,
                    kind: 'ghost-secondary',
                },
                {
                    key: 1,
                    content: dic.word('wt_feed__editor__localization__add_placeholder'),
                    onClick: handleSubmit,
                    kind: 'primary',
                    isDisabled: !selectedIds.length,
                },
            ],
        }),
        [dic, selectedIds, close, handleSubmit],
    );

    return (
        <WtPopup3
            title={dic.word('wt_feed__timeline_status__toolbar_add_tag_btn')}
            close={close}
            width={464}
            actionButtons={actionButtons}
            portalTo="popupRoot"
        >
            <SubTitle>{dic.word('wt_feed__timeline_status__tags_popup_subtitle')}</SubTitle>
            <TagsWrapper>
                <Tags
                    tags={tags}
                    size="medium"
                    kind="outline"
                    limit={LIMIT}
                    selectedIds={selectedIds}
                    onSelectTag={handleSelectTag}
                />
            </TagsWrapper>
        </WtPopup3>
    );
});
