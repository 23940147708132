import { connect } from 'react-redux';

import { api } from 'services/api-builder/api';
import { GlobalStateForCache } from 'services/api-builder/types';

import { MyProducts } from './MyProducts';

export const mapStateToProps = (state: GlobalStateForCache) => ({
    ...api.selectMyProducts(state, { archived: false }),
    ...api.selectProfile(state),
});

export const mapDispatchToProps = {
    getMyProducts: api.fetchFirstMyProducts,
    createMyConnectedAccount: api.createMyConnectedAccount,
    createMyConnectedOnboarding: api.createMyConnectedOnboarding,
    getProfile: api.fetchProfile,
};

export const MyProductsContainer = connect(mapStateToProps, mapDispatchToProps)(MyProducts);
