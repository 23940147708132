import { useCallback } from 'react';

import { Action, Id } from 'types';
import { ensureNumber } from 'services/utils/ensure-number';
import { api } from 'services/api-builder/api';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';

type ReturnFn = (cardId: Id | null | undefined, isInFavorites: boolean) => void;
export const useFavoritesClick = (actions: {
    addProductToFavorites?: Action<typeof api.addProductToFavorites>;
    removeProductFromFavorites?: Action<typeof api.removeProductFromFavorites>;
}) => {
    const { addProductToFavorites, removeProductFromFavorites } = actions;
    const handleFavoritesClick = useCallback<ReturnFn>(
        (cardId, isInFavorites) => {
            if (!cardId) return;
            const id = ensureNumber(cardId);
            if (isInFavorites) {
                removeProductFromFavorites?.({ productId: id });
            } else {
                addProductToFavorites?.({ productId: id });
            }
        },
        [removeProductFromFavorites, addProductToFavorites],
    );

    return TODOuseActionAfterAuthentication<void, Parameters<ReturnFn>>(handleFavoritesClick);
};
