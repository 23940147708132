import { useDicMemo } from 'hooks/useDictionary';

export type ValidationErrorsTypes = 'tooLong' | 'tooBig' | 'tooSmall' | 'wrongType' | 'required' | 'pattern';

const FORM_KEYS: Record<string, ValidationErrorsTypes[]> = {
    shared: ['tooLong'],
    cover: ['tooBig', 'tooSmall', 'wrongType'],
    image: ['tooBig', 'tooSmall', 'wrongType'],
    name: ['required'],
    lastname: ['required'],
    instagram: ['pattern'],
    facebook: ['pattern'],
    telegram: ['pattern'],
    vkontakte: ['pattern'],
};

export const useValidationErrors = (): Record<string, Record<ValidationErrorsTypes, string>> =>
    useDicMemo((dic) => {
        const validationErrors: Record<string, Record<ValidationErrorsTypes, string>> = {};

        Object.keys(FORM_KEYS).forEach((fieldName) => {
            validationErrors[fieldName] = FORM_KEYS[fieldName].reduce(
                (previous: Record<string, string>, errorKey: string) => ({
                    ...previous,
                    [errorKey]: dic.word(`wt_all__widget_user_form__validation_errors_${fieldName}_${errorKey}`),
                }),
                {} as Record<string, string>,
            );
        });
        return validationErrors;
    });
