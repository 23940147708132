import styled, { css } from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14MediumFontStyle, body17MediumFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';
import { TooltipV2 } from 'components/tooltip-v2';
import { VerifiedIcon } from 'components/icons2/verified-icon';

import { UserNameKind } from './types';

export const UserNameWrapper = styled.div<{ isLoading: boolean; isOneLine: boolean }>`
    flex: 1 1 auto;
    float: initial;
    display: flex;
    pointer-events: auto;
    padding: 0;

    ${({ isOneLine }) =>
        isOneLine
            ? css`
                  overflow: hidden;
                  white-space: nowrap;
              `
            : ''}

    ${({ isLoading }) =>
        isLoading
            ? css`
                  min-height: 16px;
                  margin: 4px 0;
                  width: 72px;
                  min-width: 72px;
                  border-radius: ${tokens.radius.rounded};
                  background-color: ${tokens.colors.opacity.base['16']};
              `
            : css`
                  min-height: auto;
                  min-width: auto;
                  width: auto;
              `}

    ${mediaQuery.lt480} {
        ${({ isLoading }) =>
            isLoading
                ? css`
                      min-height: 12px;
                      margin: 4px 0;
                  `
                : ''}
    }
`;

export const NameText = styled.span<{ $kind: UserNameKind; isOneLine: boolean; isLoading: boolean }>`
    max-width: 100%;
    float: left;
    text-decoration: none;
    outline: none;
    transition: color 600ms;
    ${body17MediumFontStyle};
    ${mediaQuery.lt480} {
        ${body14MediumFontStyle};
    }

    ${({ $kind, isLoading, theme: { label } }) => {
        let color = label.primary;

        if (isLoading) {
            color = 'transparent';
        }

        if ($kind === 'accent') {
            color = label.accent;
        }

        return css`
            color: ${color};
        `;
    }};

    ${({ isOneLine }) =>
        isOneLine
            ? css`
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
              `
            : css`
                  word-break: break-word;
              `}
`;

export const NameTextWrapper = styled.div`
    overflow: hidden;
`;

export const NameTextLinkWrapper = styled.a<{ isLoading: boolean }>`
    overflow: hidden;
    ${({ isLoading }) =>
        isLoading
            ? css`
                  pointer-events: none;
              `
            : ''};
    ${NameText} {
        &:hover {
            color: ${({ theme }) => theme.label.accent};
        }
    }
`;

export const Tooltip = styled(TooltipV2)`
    display: inline-flex;
    flex-shrink: 1;
    overflow: hidden;
`;

export const VerifiedIcons = styled(VerifiedIcon)<{ isOneLine: boolean }>`
    ${({ isOneLine }) =>
        isOneLine
            ? ''
            : css`
                  position: relative;
                  bottom: -2px;
              `}
`;
