import React from 'react';

import { ButtonSize } from 'components/button2/types';

import { DEFAULT_SIZE_WT_POPUP_3, SizeWtPopup3Enum } from '../constants';

import * as Styled from './styled';
import { ActionButtonsDirectionType, ActionButtonItemType, FooterWtPopup3Props } from './types';

const DEFAULT_BUTTONS_DIRECTION: ActionButtonsDirectionType = 'horizontal';

export const Footer = (props: FooterWtPopup3Props) => {
    const { children, size = DEFAULT_SIZE_WT_POPUP_3, actionButtons, className } = props;

    const buttonSizeActionButton: ButtonSize = size === SizeWtPopup3Enum.LARGE ? 'medium' : 'small';
    const directionActionButtons: ActionButtonsDirectionType = actionButtons?.direction || DEFAULT_BUTTONS_DIRECTION;

    const isEmpty = !children && !actionButtons;

    return (
        <Styled.FooterWrapper isEmpty={isEmpty} className={className} size={size}>
            {actionButtons ? (
                <Styled.ActionButtons direction={directionActionButtons} align={actionButtons?.align}>
                    {actionButtons.items.map((btnProps: ActionButtonItemType) => btnProps.href ? (
                            <Styled.ActionLinkButton
                                size={buttonSizeActionButton}
                                direction={directionActionButtons}
                                {...btnProps}
                                href={btnProps.href}
                            >
                                {btnProps?.content}
                            </Styled.ActionLinkButton>
                        ) : (
                            <Styled.ActionButton
                                size={buttonSizeActionButton}
                                direction={directionActionButtons}
                                {...btnProps}
                            >
                                {btnProps?.content}
                            </Styled.ActionButton>
                        ))}
                </Styled.ActionButtons>
            ) : (
                children
            )}
        </Styled.FooterWrapper>
    );
};

export * from './types';
