import { Step5FormValues } from './types';

export const MAX_ITEMS = 10;
export const MAX_LENGTH_TITLE = 90;
export const MAX_LENGTH_DESCRIPTION = 500;

export const MIN_LENGTH_TITLE = 3;
export const MIN_LENGTH_DESCRIPTION = 3;

export const defaultValues: Step5FormValues = {
    keyFeatures: [],
};
