import { createSelector } from 'reselect';
import stringify from 'fast-json-stable-stringify';

import { notEmpty } from 'services/utils/not-empty';

import { AsyncMethod, GlobalStateForCache, PaginationConfig } from '../types';
import { selectorsCache } from '../cache/selectorsCache';

import { PAGINATED_EMPTY } from './constants';
import { PaginatedRequestStateSelectorReturn } from './types';

export function createSelectPaginatedRequestState<
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    PAGE_INFO,
>({ endpointName, pagination }: { endpointName: ENDPOINT_NAME; pagination: PaginationConfig<API_METHOD, PAGE_INFO> }) {
    return createSelector(
        (state: GlobalStateForCache) => state.cache.requests[endpointName],
        (state: GlobalStateForCache) => state.cache.args[endpointName],
        (_: unknown, argsOrRequestName: Parameters<API_METHOD>[0] | string) => argsOrRequestName,
        (requestsMap, argsMap, argsOrRequestName): PaginatedRequestStateSelectorReturn => {
            const args = typeof argsOrRequestName === 'string' ? argsMap?.named[argsOrRequestName] : argsOrRequestName;
            if (!args || !requestsMap) {
                return PAGINATED_EMPTY;
            }
            const pagesArgs = argsMap?.pagination[stringify(pagination?.getCommonArgs(args))];
            if (!pagesArgs) {
                return PAGINATED_EMPTY;
            }
            const requests = pagesArgs.map((pageArgs) => requestsMap[pageArgs.key]).filter(notEmpty);

            const memoizedValue = selectorsCache.get([...requests, ...pagesArgs]);
            if (memoizedValue) {
                return memoizedValue;
            }
            const { error } = requests.find(({ error: pageError }) => pageError) ?? {};
            let { status = 'empty' } = requests.find(({ status: pageStatus }) => pageStatus !== 'ready') ?? {};
            if (requests.length > 0 && requests.every(({ status: pageStatus }) => pageStatus === 'ready')) {
                status = 'ready';
            }
            const pagesInfo = pagesArgs.map(({ paginationInfo }) => paginationInfo);
            const newValue = {
                error,
                isEmpty: status === 'empty',
                isLoading: status === 'loading' && pagesArgs.length === 1,
                isReloading: status === 'reloading',
                isError: status === 'error',
                isReady: status === 'ready',
                hasMorePages: !!pagination.getNextPage(pagesInfo),
                totalItems: pagination.getTotalItems?.(pagesInfo),
                isLoadingMore: status === 'loading' && pagesArgs.length > 1,
            };
            selectorsCache.set([...requests, ...pagesArgs], newValue);
            return newValue;
        },
    );
}
