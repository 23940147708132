import type { ArrayElement } from 'types';
import type { ResponsiveMap } from 'app/styled';
import type { IconComponent } from 'components/icons2';

const avatarShortSizeValue = ['S', 'M', 'L', 'XL'] as const;
export type AvatarShortSizeValue = ArrayElement<typeof avatarShortSizeValue>;

const avatarFullSizeValue = ['small', 'medium', 'large', 'extra-large'] as const;
export type AvatarFullSizeValue = ArrayElement<typeof avatarFullSizeValue>;

export type AvatarCustomSizeValue = number;
export type AvatarSizeValue = AvatarShortSizeValue | AvatarFullSizeValue | AvatarCustomSizeValue;
export type AvatarSize = ResponsiveMap<AvatarSizeValue> | AvatarSizeValue;

export type AvatarIcon = null | IconComponent;

export type AvatarBg = string;

export type AvatarInitials = {
    text: string;
    bg: AvatarBg;
    textColor: string;
};

export type AvatarAllProps = {
    src?: string | null;
    alt?: string;
    size?: AvatarSize;
    // borderRadius желательно не использовать, а чтобы использовались по стандарту от размеров
    borderRadius?: number;
    // иконка, которая показывается если не переданы инициалы и src, AvatarIcon по умолчанию
    userIcon?: AvatarIcon;
    // выравниванивать ли иконку userIcon в высоту по центру, по умолчанию выравниваем по низу
    centerIcon?: boolean;
    // цвет фона для иконки userIcon
    iconBg?: string;
    // цвет фона
    bg?: AvatarBg;
    // оставлять ли после загрузки цвет фона по умолчанию
    isStayOverlayBg?: boolean;
    initials?: AvatarInitials;
    onClick?: () => void;
    isLoading?: boolean;
    className?: string;
    dataAttributes?: { [key: string]: string };
    isExcludeSrcStaticImgPlug?: boolean;
    href?: string;
    rel?: HTMLAnchorElement['rel'];
    target?: HTMLAnchorElement['target'] | '_blank' | '_self' | '_parent' | '_top';
    // использовать ли белую подложку для выравнивания прозрачных цветов сверху
    useWhiteSubstrate?: boolean;
};

export type AvatarImgProps = Required<Pick<AvatarAllProps, 'src' | 'alt'>> &
    Partial<Omit<AvatarAllProps, 'src' | 'alt'>>;

export type AvatarInitialsProps = Required<Pick<AvatarAllProps, 'initials'>> &
    Partial<Omit<AvatarAllProps, 'initials'>>;

export type AvatarLoadingProps = Required<Pick<AvatarAllProps, 'isLoading'>> &
    Partial<Omit<AvatarAllProps, 'isLoading'>>;

export type AvatarImplementationProps = (AvatarInitialsProps | AvatarLoadingProps | AvatarImgProps) & {
    tabIndex?: number;
};

export type AvatarLinkProps = Required<Pick<AvatarImplementationProps, 'href'>> &
    Omit<AvatarImplementationProps, 'href' | 'isLoading'> &
    Partial<Pick<AvatarImplementationProps, 'isLoading'>>;

export type AvatarProps = Omit<AvatarImplementationProps, 'href' | 'target' | 'rel'>;

export type AvatarSmartBlockLinkProps = AvatarLinkProps | AvatarProps;

export function isAvatarCustomSizeValue(size: AvatarSizeValue): size is AvatarCustomSizeValue {
    return typeof size === 'number';
}

export function isAvatarShortSizeValue(size: AvatarSizeValue): size is AvatarShortSizeValue {
    return typeof size === 'string' && (avatarShortSizeValue as ReadonlyArray<string>).includes(size);
}

export function isAvatarFullSizeValue(size: AvatarSizeValue): size is AvatarFullSizeValue {
    return typeof size === 'string' && (avatarFullSizeValue as ReadonlyArray<string>).includes(size);
}
