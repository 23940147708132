import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LogoutIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.71158 11.9105C8.71158 11.4686 9.06976 11.1105 9.51158 11.1105H19.5116C19.9534 11.1105 20.3116 11.4686 20.3116 11.9105C20.3116 12.3523 19.9534 12.7105 19.5116 12.7105H9.51158C9.06976 12.7105 8.71158 12.3523 8.71158 11.9105Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8596 11.9105L16.2 9.25085C15.8875 8.93843 15.8875 8.4319 16.1999 8.11948C16.5124 7.80706 17.0189 7.80705 17.3313 8.11947L20.486 11.2741C20.8374 11.6255 20.8374 12.1954 20.486 12.5469L17.1523 15.8805C16.8399 16.1929 16.3334 16.1929 16.0209 15.8805C15.7085 15.5681 15.7085 15.0616 16.0209 14.7491L18.8596 11.9105Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1213 4.5C13.1213 4.05817 12.7631 3.7 12.3213 3.7H4.15049C3.65344 3.7 3.25049 4.10294 3.25049 4.6V19.4C3.25049 19.897 3.65343 20.3 4.15049 20.3H12.3213C12.7631 20.3 13.1213 19.9418 13.1213 19.5C13.1213 19.0582 12.7631 18.7 12.3213 18.7H4.85049V5.3H12.3213C12.7631 5.3 13.1213 4.94182 13.1213 4.5Z"
        />
    </IconSvg>
);
