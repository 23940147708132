import React from 'react';

import { IconSvg, IconComponent } from '.';

export const StarSmallIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3 5C12.6201 5 12.9093 5.19073 13.0354 5.48489L14.6524 9.25845L18.8621 9.586C19.1867 9.61126 19.4637 9.83078 19.5624 10.1411C19.6611 10.4514 19.5619 10.7906 19.3115 10.9988L16.1214 13.651L17.0941 17.6091C17.171 17.922 17.0527 18.2505 16.7939 18.4425C16.5352 18.6345 16.1865 18.6525 15.9093 18.4882L12.3 16.3485L8.69078 18.4882C8.4136 18.6525 8.0649 18.6345 7.80613 18.4425C7.54736 18.2505 7.42903 17.922 7.50593 17.6091L8.47869 13.651L5.28859 10.9988C5.0382 10.7906 4.93897 10.4514 5.03768 10.1411C5.13638 9.83078 5.41333 9.61126 5.73798 9.586L9.94763 9.25845L11.5647 5.48489C11.6908 5.19073 11.98 5 12.3 5ZM12.3 7.83104L11.2276 10.3336C11.1104 10.6071 10.851 10.793 10.5543 10.8161L7.82684 11.0283L9.88648 12.7407C10.1219 12.9365 10.225 13.2494 10.1519 13.5468L9.51467 16.1397L11.8921 14.7303C12.1436 14.5812 12.4565 14.5812 12.708 14.7303L15.0854 16.1397L14.4482 13.5468C14.3751 13.2494 14.4781 12.9365 14.7136 12.7407L16.7732 11.0283L14.0457 10.8161C13.749 10.793 13.4897 10.6071 13.3725 10.3336L12.3 7.83104Z"
        />
    </IconSvg>
);
