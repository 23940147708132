import { useCallback } from 'react';

function numberAdd(value: string, diff: number): string {
    const num = parseInt(value, 10);
    const checkedNum = Number.isNaN(num) ? 0 : num;
    return `${checkedNum + diff}`;
}

export function useIncDec(value: string, onChange: (value: string) => void): [() => void, () => void] {
    const increment = useCallback(() => {
        onChange(numberAdd(value, 1));
    }, [onChange, value]);

    const decrement = useCallback(() => {
        onChange(numberAdd(value, -1));
    }, [onChange, value]);

    return [increment, decrement];
}
