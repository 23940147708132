/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserExporter } from '../models/shared/UserExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchService {
    /**
     * Получить список упоминаемых пользователей
     * @returns any Результат получения списка упоминаемых пользователей
     * @throws ApiError
     */
    public static getMentionList({
        postId = 43091190627,
        query,
    }: {
        /**
         * Идентификатор поста для получения списка упоминаемых пользователей
         */
        postId?: number;
        /**
         * Строка поиска для получения конкретных пользователей по имени
         */
        query?: string;
    }): CancelablePromise<{
        'mention-list'?: Array<UserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/mention-list/{postId}/',
            path: {
                postId: postId,
            },
            query: {
                query: query,
            },
            errors: {
                400: `Пост не найден`,
            },
        });
    }
}
