import React, { FC, memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Action } from 'types';
import { BackofficeAccount } from 'services/mp-share-resources/types';
import { api } from 'services/api-builder/api';
import { useDictionary } from 'hooks/useDictionary';
import { useLocaleId } from 'hooks/useLocaleId';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { VerticalFormInputGroup } from 'components/form';
import { FormListBox, FormNumberInput, FormTextInput } from 'components/form/react-hook-form';
import { ExclamationMarkCircleFilledIcon } from 'components/icons2/ExclamationMarkCircleFilledIcon';
import { Button } from 'components/button2';

import { Label } from '../../styled';
import { StepProps } from '../../types';
import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { KEY_FEATURE_DESCRIPTION_MAX_LENGTH, useKeyFeatureValidation } from '../../../../hooks/useKeyFeatureValidation';

import { CreateAccount, NoAccounts } from './styled';
import { toData } from './toData';
import { defaultValues, MAX_AMOUNT, MIN_AMOUNT, minSumHighBorder, minSumLowBorder } from './constants';
import { Step2FormValues } from './types';

export const Step2: FC<
    StepProps<Step2FormValues> & {
        backOfficeAccounts?: BackofficeAccount[];
        hidePrice?: boolean;
        accountsForNewStrategyIsReady: boolean;
        accountsForNewStrategyIsLoading: boolean;
        getBackOfficeAccounts?: Action<typeof api.fetchAccountsForNewStrategy>;
    }
> = memo((props) => {
    const { getBackOfficeAccounts, backOfficeAccounts, hidePrice, accountsForNewStrategyIsLoading } = props;
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step2_');
    const lcid = useLocaleId();

    const form = useForm<Step2FormValues>({
        mode: 'onChange',
        defaultValues,
    });
    const { watch, setValue } = form;
    useFormStateHandler({
        form,
        props,
        toData,
        title: dicWord('title'),
    });

    useEffect(() => {
        getBackOfficeAccounts?.();
    }, [getBackOfficeAccounts]);

    const accounts = useMemo(
        () =>
            backOfficeAccounts && backOfficeAccounts.length > 0
                ? backOfficeAccounts.map((account) => ({
                      id: `${account.accountId}`,
                      text: `${account.tradeCode}`,
                  }))
                : [
                      {
                          id: 'none',
                          text: (
                              <NoAccounts>
                                  <ExclamationMarkCircleFilledIcon />
                                  <span>{dicWord('no_accounts')}</span>
                              </NoAccounts>
                          ),
                          readonly: true,
                      },
                      {
                          id: 'create',
                          text: <CreateAccount>{dicWord('create_account')}</CreateAccount>,
                      },
                  ],
        [backOfficeAccounts, dicWord],
    );

    const risk = useMemo(
        () => [
            { id: 'Conservative', text: dicWord('risk_conservative') },
            { id: 'Moderate', text: dicWord('risk_moderate') },
            { id: 'Aggressive', text: dicWord('risk_aggressive') },
        ],
        [dicWord],
    );

    const required = dic.word('wt_all__widget_product_wizard__field_required');
    const keyFeatureRules = useKeyFeatureValidation();
    const accountId = watch('accountId');
    useEffect(() => {
        if (accountId === 'create') {
            setValue('accountId', '');
            window.open('https://open.lime.co/register', '_blank');
        }
    }, [accountId, setValue]);

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                {accountsForNewStrategyIsLoading && <Button isProcessing kind="ghost-secondary" />}
                {getBackOfficeAccounts && !accountsForNewStrategyIsLoading ? (
                    <div>
                        <FormListBox
                            name="accountId"
                            placeholder={dicWord('account')}
                            kind="inline-label-outline"
                            size="medium"
                            className="autotest__marketplace__product_wizard__account"
                            options={accounts}
                            autotestOptionPrefix="autotest__marketplace__product_wizard__account_"
                            rules={{ required }}
                        />
                    </div>
                ) : null}
                <FormNumberInput
                    label={dicWord('min_sum')}
                    name="minSum"
                    locale={lcid}
                    kind="outline"
                    size="medium"
                    currencySign="$"
                    min={0}
                    max={minSumHighBorder}
                    rules={{
                        required,
                        min: {
                            value: minSumLowBorder,
                            message: dicWord('min_sum_validation', { amount: minSumLowBorder }),
                        },
                    }}
                    className="autotest__marketplace__product_wizard__min_sum"
                />
                <div>
                    <FormListBox
                        name="riskLevel"
                        placeholder={dicWord('risk')}
                        kind="inline-label-outline"
                        size="medium"
                        className="autotest__marketplace__product_wizard__risk"
                        options={risk}
                        autotestOptionPrefix="autotest__marketplace__product_wizard__risk_"
                        rules={{ required }}
                    />
                </div>
                <FormTextInput
                    label={dicWord('period')}
                    name="recommendedHoldingPeriod"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__period"
                />
                {hidePrice ? null : (
                    <>
                        <Label>{dicWord('contribution')}</Label>
                        <FormNumberInput
                            label={dicWord('amount')}
                            name="price"
                            locale={lcid}
                            kind="outline"
                            size="medium"
                            currencySign="$"
                            min={MIN_AMOUNT}
                            max={MAX_AMOUNT}
                            rules={{ required }}
                            className="autotest__marketplace__product_wizard__contribution"
                        />
                    </>
                )}
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
