import React, { useMemo } from 'react';
import cn from 'classcat';

import { ComplexItemProps } from './types';
import * as Styled from './styled';

const DEFAULT_AVATAR_SIZE = 40;
const DEFAULT_COMPLEX_ITEM_SIZE: ComplexItemProps['size'] = {
    default: 'regular',
    lt960: 'small',
};

const DEFAULT_CLASSNAME_COMPONENT = 'autotest__complex-item';

export const ComplexItem = React.memo((props: ComplexItemProps) => {
    const {
        avatarProps,
        className: classNameProp,
        size = DEFAULT_COMPLEX_ITEM_SIZE,
        title,
        secondLine,
        levelRiskValue,
        levelRiskColor,
        isLoading = false,
        isShowLevelRiskValue,
        onClick: onClickProp,
    } = props;

    const className = cn([DEFAULT_CLASSNAME_COMPONENT, classNameProp]);
    const detailsClassname = `${DEFAULT_CLASSNAME_COMPONENT}__details`;
    const riskClassname = `${DEFAULT_CLASSNAME_COMPONENT}__risk`;

    const renderStringOrElement = (content: string | React.ReactElement) =>
        typeof content === 'string' ? <Styled.Text>{content}</Styled.Text> : content;

    const avatarComputedProps: ComplexItemProps['avatarProps'] = {
        ...avatarProps,
        size: avatarProps?.size || DEFAULT_AVATAR_SIZE,
    };

    const secondLineBlock = useMemo(() => {
        const content = isLoading
            ? null
            : [secondLine].flat().map((item, index) => {
                  const key = typeof item === 'string' ? item : index;

                  return (
                      <React.Fragment key={key}>
                          {index !== 0 && (
                              <Styled.BullWrapper>
                                  <Styled.BullSymbol />
                              </Styled.BullWrapper>
                          )}
                          {renderStringOrElement(item as string | React.ReactElement)}
                      </React.Fragment>
                  );
              });

        if (secondLine || isLoading) {
            return <Styled.SecondLine isLoading={isLoading}>{content}</Styled.SecondLine>;
        }

        return null;
    }, [isLoading, secondLine]);

    const onClick = useMemo(() => {
        if (!onClickProp) {
            return undefined;
        }

        return onClickProp;
    }, [onClickProp]);

    return (
        <Styled.ComplexItemWrapper
            className={className}
            size={size}
            avatarProps={avatarComputedProps}
            isLoading={isLoading}
            onClick={onClick}
        >
            <>
                <Styled.AvatarWrapper levelRiskValue={levelRiskValue} isLoading={isLoading}>
                    <Styled.Avatar {...avatarComputedProps} isLoading={isLoading} />
                    {!!levelRiskValue && (
                        <Styled.LevelRisk
                            value={levelRiskValue}
                            isLoading={isLoading}
                            color={levelRiskColor}
                            className={riskClassname}
                            isShowLevelNumberValue={isShowLevelRiskValue}
                        />
                    )}
                </Styled.AvatarWrapper>

                <Styled.Details isLoading={isLoading} className={detailsClassname}>
                    <>
                        <Styled.Title isLoading={isLoading}>
                            {!isLoading && title && renderStringOrElement(title)}
                        </Styled.Title>
                        {secondLineBlock}
                    </>
                </Styled.Details>
            </>
        </Styled.ComplexItemWrapper>
    );
});

export type { ComplexItemProps };
