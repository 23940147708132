import React from 'react';

import { Tags } from 'components/tags';

import * as StyledDefaultLayout from '../../partials/default-layout/styled';
import { CardContextActions } from '../../partials/context-actions';
import { FavoriteButton } from '../../partials/favorite-button';
import { CardTitleBlock } from '../../partials/title-block';
import { CardAuthor } from '../../partials/author';
import { CardContainer } from '../../partials/container';
import { ParametersTable } from '../../partials/parameters-table';
import { CardPriceInfo } from '../../partials/price-info';
import { ActionButtons } from '../../partials/action-buttons';

import { InstrumentCardProps } from './types';
import DefaultGraphicSkeletonSVG from './graphicSkeleton.svg';
import TopGroupSkeletonSVG from './topGroupSkeleton.svg';
import * as Styled from './styled';

export const InstrumentCard = (props: InstrumentCardProps) => {
    const {
        title,
        thumbnailSrc,
        author,
        authorDesc,
        authorAvatarSrc,
        price,
        freePriceWord,
        onClick,
        className: classNameProp,
        isLoading = false,
        isHeightGrow = false,
        isRoundedAuthorAvatar = false,
        withoutBoxShadow = false,
        parameters,
        url,
        chart,
        skeletons,
        tagsData,
        actionButtons,
        formattedPricePeriod,
        favoriteBtn,
        contextActions,
        ...restProps
    } = props;

    const className = [
        classNameProp,
        'card-item',
        'autotest__card',
        'autotest__card-instrument',
        isLoading && 'autotest__card-loading',
    ]
        .filter((item) => item)
        .join(' ');

    const GraphicSkeleton = skeletons?.GraphicSkeleton || DefaultGraphicSkeletonSVG;

    const amount = price?.price || undefined;
    const period = price?.period || undefined;
    const currency = price?.currency || undefined;
    const priceWord = price?.word || undefined;

    const renderChartThumbnail = () => {
        let chartContent = null;

        if (isLoading) {
            return (
                <Styled.GraphicSkeletonWrapper>
                    <GraphicSkeleton />
                </Styled.GraphicSkeletonWrapper>
            );
        }

        if (chart) {
            chartContent = (
                <Styled.Chart
                    staticColorProfitability="positive"
                    showGrid={false}
                    showXAxis={false}
                    showYAxis={false}
                    {...chart}
                />
            );
        } else if (thumbnailSrc) {
            chartContent = <Styled.CardThumbnail alt={title} src={thumbnailSrc} />;
        }

        if (chartContent) {
            return <Styled.ChartThumbnailWrapper>{chartContent}</Styled.ChartThumbnailWrapper>;
        }

        return null;
    };

    const renderParametersTable = () => (
        <Styled.ParametersTableWrapper>
            <ParametersTable parameters={parameters} isLoading={isLoading} />
        </Styled.ParametersTableWrapper>
    );

    const renderHeader = () => {
        let headerContent = null;

        const hasFavoriteButton =
            favoriteBtn && typeof favoriteBtn === 'object' && Object.keys(favoriteBtn)?.length > 0;

        if (isLoading) {
            headerContent = (
                <StyledDefaultLayout.SkeletonWrapper>
                    <TopGroupSkeletonSVG />{' '}
                </StyledDefaultLayout.SkeletonWrapper>
            );
        } else if (title || hasFavoriteButton || author) {
            headerContent = (
                <>
                    <Styled.HeaderLine>
                        <Styled.CardTitleBlockWrapper>
                            {title && <CardTitleBlock title={title} />}
                        </Styled.CardTitleBlockWrapper>
                        {hasFavoriteButton && <FavoriteButton {...favoriteBtn} />}
                        {contextActions ? <CardContextActions {...contextActions} /> : null}
                    </Styled.HeaderLine>
                    {author && (
                        <Styled.HeaderLine>
                            <CardAuthor
                                name={author}
                                isAvatarRounded={isRoundedAuthorAvatar}
                                description={authorDesc}
                                avatarSrc={authorAvatarSrc || undefined}
                            />
                        </Styled.HeaderLine>
                    )}
                </>
            );
        }

        if (!headerContent) {
            return null;
        }

        return <Styled.HeaderWrapper>{headerContent}</Styled.HeaderWrapper>;
    };

    return (
        <CardContainer
            onClick={onClick}
            className={className}
            isLoading={isLoading}
            isHeightGrow={isHeightGrow}
            withoutBoxShadow={withoutBoxShadow}
            url={url}
            {...restProps}
        >
            <>
                {renderHeader()}
                {renderChartThumbnail()}
                {!isLoading && tagsData && (
                    <Styled.TagsWrapper>
                        <Tags tags={tagsData} kind="secondary" size="small" />
                    </Styled.TagsWrapper>
                )}
                {renderParametersTable()}
                {!isLoading && (price || formattedPricePeriod) && (
                    <Styled.PriceContainer>
                        <CardPriceInfo
                            amount={amount}
                            period={period}
                            currency={currency}
                            freePriceWord={freePriceWord}
                            priceWord={priceWord}
                            formattedPricePeriod={formattedPricePeriod}
                        />
                    </Styled.PriceContainer>
                )}
                {!isLoading && actionButtons && (
                    <Styled.ActionButtonsWrapper>
                        <ActionButtons {...actionButtons} />
                    </Styled.ActionButtonsWrapper>
                )}
            </>
        </CardContainer>
    );
};
