import React from 'react';

import { IconSvg, IconComponent } from '.';

export const DocTextIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M8.22485 17.5165C8.22485 17.0747 8.58303 16.7165 9.02485 16.7165H15.0713C15.5131 16.7165 15.8713 17.0747 15.8713 17.5165C15.8713 17.9583 15.5131 18.3165 15.0713 18.3165H9.02485C8.58303 18.3165 8.22485 17.9583 8.22485 17.5165Z" />
        <path d="M9.02485 13.6933C8.58303 13.6933 8.22485 14.0515 8.22485 14.4933C8.22485 14.9351 8.58303 15.2933 9.02485 15.2933H15.0713C15.5131 15.2933 15.8713 14.9351 15.8713 14.4933C15.8713 14.0515 15.5131 13.6933 15.0713 13.6933H9.02485Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5809 2.70392L6.01167 2.70393C5.01755 2.70393 4.21167 3.50982 4.21167 4.50393V20.4947C4.21167 21.4888 5.01756 22.2947 6.01167 22.2947H17.9984C18.9925 22.2947 19.7984 21.4888 19.7984 20.4947V9.98313C19.7984 9.50927 19.6115 9.05453 19.2784 8.71756L13.8609 3.23835C13.5227 2.89637 13.0618 2.70392 12.5809 2.70392ZM11.2092 4.30392L6.01167 4.30393C5.90121 4.30393 5.81167 4.39347 5.81167 4.50393V20.4947C5.81167 20.6051 5.90121 20.6947 6.01167 20.6947H17.9984C18.1089 20.6947 18.1984 20.6051 18.1984 20.4947V11.2995H13.0092C12.0151 11.2995 11.2092 10.4936 11.2092 9.49946V4.30392ZM17.9992 9.69946L12.8092 4.4504V9.49946C12.8092 9.60991 12.8988 9.69946 13.0092 9.69946H17.9992Z"
        />
    </IconSvg>
);
