import React from 'react';

import { Id } from 'types';
import { useDictionary } from 'hooks/useDictionary';

import { BrokerageAccountPopupContainer } from './container';

type Props = {
    close: () => void;
    strategyId: number;
    onLoading?: (isLoading: boolean) => void;
};

export const BrokerageAccountPopupWidget = (props: Props) => {
    const dic = useDictionary();
    return (
        <BrokerageAccountPopupContainer
            title={dic.word('wt_mplc2__strategy_wizard__connect_brokerage_account')}
            {...props}
        />
    );
};
