/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlogPostExporter } from '../models/shared/BlogPostExporter';
import type { ConstraintViolationExporter } from '../models/BlogService/ConstraintViolationExporter';
import type { InstrumentExporter } from '../models/shared/InstrumentExporter';
import type { RecordExporter } from '../models/shared/RecordExporter';
import type { UserExporter } from '../models/shared/UserExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlogService {
    /**
     * Создать пост
     * @returns any Результат создания поста
     * @throws ApiError
     */
    public static createPostCreate({
        requestBody,
    }: {
        requestBody?: {
            title?: string | null;
            json: string;
            html: string;
            feedId?: number | null;
            feedType?: number | null;
            groupId?: number | null;
            csrf: string;
            /**
             * Идентификаторы тегов поста
             */
            tags?: Array<number>;
        };
    }): CancelablePromise<{
        post: BlogPostExporter;
        community?: UserExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/post/create/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибки создания поста`,
            },
        });
    }

    /**
     * Редактировать пост
     * @returns any Результат обновления поста
     * @throws ApiError
     */
    public static createPost({
        postId = 43091190627,
        requestBody,
    }: {
        /**
         * Идентификатор поста для редактирования
         */
        postId?: number;
        requestBody?: {
            title: string;
            json: string;
            html?: string;
            csrf: string;
            /**
             * Идентификаторы тегов поста
             */
            tags?: Array<number>;
        };
    }): CancelablePromise<{
        post?: BlogPostExporter;
        errors?: Array<ConstraintViolationExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/post/{postId}/',
            path: {
                postId: postId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибки редактирования поста`,
            },
        });
    }

    /**
     * Удалить пост
     * @returns any Результат удаления поста
     * @throws ApiError
     */
    public static deletePost({
        postId = 43091190627,
        requestBody,
    }: {
        /**
         * Идентификатор поста для удаления
         */
        postId?: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf?: string;
        };
    }): CancelablePromise<{
        post?: BlogPostExporter;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/post/{postId}/',
            path: {
                postId: postId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Пост не удалён`,
            },
        });
    }

    /**
     * Восстановить пост после удаления
     * Восстановить пост можно только в течении 15 минут после удаления. Только удаленные и собственные.
     * @returns any Результат восстановления поста
     * @throws ApiError
     */
    public static restorePost({
        postId = 43091190627,
    }: {
        /**
         * Идентификатор поста для восстановления
         */
        postId?: number;
    }): CancelablePromise<{
        post?: BlogPostExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/post/{postId}/restore/',
            path: {
                postId: postId,
            },
            errors: {
                400: `Пост не восстановлен`,
            },
        });
    }

    /**
     * Редактировать пост
     * @returns any Результат обновления поста
     * @throws ApiError
     */
    public static createPostUpdate({
        postId = 43091190627,
        requestBody,
    }: {
        /**
         * Идентификатор поста для редактирования
         */
        postId?: number;
        requestBody?: {
            title: string;
            json: string;
            html?: string;
            csrf: string;
            /**
             * Идентификаторы тегов поста
             */
            tags?: Array<number>;
        };
    }): CancelablePromise<{
        post?: BlogPostExporter;
        errors?: Array<ConstraintViolationExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/post/{postId}/update/',
            path: {
                postId: postId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибки редактирования поста`,
            },
        });
    }

    /**
     * Новости по инструменту
     * @returns any Результат обновления поста
     * @throws ApiError
     */
    public static getPostInstrumentNews({
        instruments,
        lcid,
        limit,
    }: {
        instruments?: Array<string>;
        lcid?: string;
        limit?: number;
    }): CancelablePromise<{
        records?: Array<{
            instrument?: InstrumentExporter;
            records?: Array<RecordExporter>;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/post/instrument/news/',
            query: {
                instruments: instruments,
                lcid: lcid,
                limit: limit,
            },
        });
    }

    /**
     * Отправка события о шаринге поста
     * @returns any Пост расшарен
     * @throws ApiError
     */
    public static sendShareMetric({
        xCsrfToken,
        postId = 43091190627,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        /**
         * Идентификатор расшаренного поста
         */
        postId?: number;
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/post/{postId}/share/',
            path: {
                postId: postId,
            },
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }
}
