import React, { DragEvent, MouseEventHandler, SyntheticEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { chooseFile } from 'services/utils/choose-file';
import { PlusIcon } from 'components/icons2/PlusIcon';
import { Button } from 'components/button2';
import { Cancelable } from 'components/cancelable';
import { RoundButton } from 'components/round-button';
import { PencilIcon } from 'components/icons2/PencilIcon';
import { TrashIcon } from 'components/icons2/TrashIcon';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';

import {
    AddButton,
    ImageUploadButtons,
    ImageUploadImagePreview,
    ImageUploadText,
    ImageUploadWrapper,
    ImageUploadWrapperCenter,
} from './styled';
import { ImageUploadProps } from './types';

const preventDefault = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
};

export const ImageUpload = ({
    state,
    actions,
    infoText,
    accept = 'image/jpeg, image/png',
    kind = 'dark',
    hideDelete,
    isMobile,
    buttonContainer,
    editButtonText = '',
}: ImageUploadProps) => {
    // MARK: - State

    const [imageIsLoading, setImageIsLoading] = useState(false);

    const { data, error } = state;
    const showInfoText = !data && infoText;
    const isLoading = state.isLoading || imageIsLoading;

    // MARK: - Actions

    const chooseFileAndUpload = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isLoading) {
            chooseFile(accept)()
                .then((files) => actions.upload(files[0]))
                .catch(() => undefined);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        preventDefault(event);
        const file = event.dataTransfer.files[0];
        if (!isLoading) {
            actions.upload(file).catch(() => undefined);
        }
    };

    // MARK: - Effects

    useEffect(() => {
        if (!data) {
            return;
        }

        setImageIsLoading(true);
        const img = new Image();
        img.onload = () => setTimeout(() => setImageIsLoading(false), 100);
        img.onerror = () => setImageIsLoading(false);
        img.src = data.sizes.original?.url || '';
    }, [data]);

    // MARK: - Rendering

    const renderUploadUI = () => (
        <ImageUploadWrapperCenter isShown={!!data} onClick={chooseFileAndUpload} isMobile={isMobile}>
            <ImageUploadButtons>
                <AddButton kind="staticColor" tagName="div">
                    <PlusIcon />
                </AddButton>
            </ImageUploadButtons>
            {showInfoText && <ImageUploadText>{infoText}</ImageUploadText>}
        </ImageUploadWrapperCenter>
    );

    const renderEditUI = () => (
        <ImageUploadWrapperCenter isShown={!!data} isMobile={isMobile}>
            <ImageUploadButtons>
                <RoundButton kind="staticColor" onClick={chooseFileAndUpload}>
                    <PencilIcon />
                </RoundButton>
                {hideDelete ? null : (
                    <RoundButton kind="staticColor" onClick={actions.remove}>
                        <TrashIcon />
                    </RoundButton>
                )}
            </ImageUploadButtons>
        </ImageUploadWrapperCenter>
    );

    return (
        <>
            <Cancelable isShown={state.isLoading || !!error} cancelFn={actions.cancel}>
                <ImageUploadWrapper
                    isLoading={isLoading}
                    isInErrorState={!!error}
                    onClick={(e) => !data && chooseFileAndUpload(e)}
                    onDragOver={preventDefault}
                    onDrop={handleDrop}
                    kind={kind}
                >
                    {isLoading ? (
                        <Button kind="ghost-primary" isProcessing />
                    ) : (
                        <>
                            {data && <ImageUploadImagePreview src={data.sizes.original?.url} alt="Cover" />}
                            {data ? renderEditUI() : renderUploadUI()}
                        </>
                    )}
                </ImageUploadWrapper>
            </Cancelable>
            {buttonContainer?.current &&
                createPortal(
                    <ButtonIcon
                        text={editButtonText}
                        kind={ButtonIconKinds.FillPrimary}
                        size="L"
                        pressed
                        onClick={chooseFileAndUpload}
                    />,

                    buttonContainer?.current,
                )}
        </>
    );
};
