import React, { FC } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';

import { ProductsProps } from './types';
import { MyProductsContainer } from './my-products';
import { UserProductsContainer } from './user-products';
import { NoProducts } from './user-products/no-products/NoProducts';

export const Products: FC<ProductsProps> = ({ ownerId, kratosId }) => {
    const user = useCurrentUser();

    if (user.id === ownerId) {
        return <MyProductsContainer />;
    }

    if (!kratosId) {
        return (
            <NoProducts />
        );
    }

    return <UserProductsContainer authorId={kratosId} />;
};
