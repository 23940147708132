import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { cookiePrivacySettings } from 'services/cookie-privacy-settings';
import { createTask, taskQ } from 'services/task-q';

import { SettingsValues } from '../types/CookiePrivacySettingsPopupUI.types';
import { isShowCookiePopupUrlParam } from '../utils';

export const useGetControlDataCookiePopupWidget = () => {
    const [settingsState, setSettingsState] = useState<SettingsValues | null>(null);
    const [isShow, setIsShow] = useState(false);
    const taskRef = useRef(createTask('CookiePrivacySettingsPopup', () => setIsShow(true)));

    const disabledKeys = useMemo(() => cookiePrivacySettings.getRequiredSettings(), []);

    useEffect(() => {
        // начальная инициализация, установка параметров в состояния, в local storage, очистка local storage
        const LSData = cookiePrivacySettings.getSettingsFromLocalStorage();
        if (LSData) {
            setSettingsState(LSData);

            // если понадобится попап, то можно в url через get параметр добавить cookie-popup=1 и обновить страницу
            const isShowPopupCookieByUrlGetParam = isShowCookiePopupUrlParam();
            if (isShowPopupCookieByUrlGetParam) {
                taskQ.add(taskRef.current);
            }
        } else {
            taskQ.add(taskRef.current);
            const defaultSettings = cookiePrivacySettings.getDefaultSettings();
            setSettingsState(defaultSettings);
        }
    }, []);

    const setLS = useCallback((_settings: SettingsValues) => {
        const isSuccessSetSettingsToLocalStorage = cookiePrivacySettings.setSettingsToLocalStorage({
            settings: _settings,
        });

        if (isSuccessSetSettingsToLocalStorage) {
            setSettingsState(_settings);
            setIsShow(false);
            taskRef.current.finish();
        }
    }, []);

    const setLSAndClearCookiesUncheckedSettings = useCallback(
        (_settings: SettingsValues) => {
            setLS(_settings);
            setTimeout(() => {
                cookiePrivacySettings.clearUncheckedSettingsCookies();
            }, 100);
        },
        [setLS],
    );

    const rejectAll = useCallback(() => {
        const rejectedAllSettings = cookiePrivacySettings.getRejectedAllSettings();
        setLSAndClearCookiesUncheckedSettings(rejectedAllSettings);
    }, [setLSAndClearCookiesUncheckedSettings]);

    const acceptAll = useCallback(() => {
        const acceptedAllSettings = cookiePrivacySettings.getAcceptedAllSettings();
        setLS(acceptedAllSettings);
    }, [setLS]);

    const saveSettings = useCallback(() => {
        if (settingsState) {
            setLSAndClearCookiesUncheckedSettings(settingsState);
        }
    }, [settingsState, setLSAndClearCookiesUncheckedSettings]);

    return {
        settings: settingsState || undefined,
        setSettings: setSettingsState,
        rejectAll,
        acceptAll,
        saveSettings,
        isShow,
        disabledKeys,
    };
};
