import { lightTheme } from './light';
import { Theme } from './types';

const { focusOutline, shadow, staticColors, risk } = lightTheme;

export const darkTheme: Theme = {
    name: 'dark',
    bg: {
        primary: '#252628',
        secondary: '#2F3237',
        tertiary: '#272D35',
        dialogOverlay: 'rgba(18, 20, 23, 0.64)',
        popover: 'rgba(47, 50, 55, 1)',
        negative: '#372538',
        accent: '#1E363D',
    },
    label: {
        accent: '#0BBC6F',
        accentHigh: '#0BA863',
        accentHighest: '#069858',
        primary: '#DBDDE1',
        secondary: '#848B9A',
        secondaryHigh: '#CDD2DB',
        tertiary: '#5B5F67',
        tertiaryActive: '#929AAA',
        placeholder: '#5E646E',
        inactive: 'rgba(147, 151, 159, 0.4)',
        positive: '#45BA8D',
        negative: '#FF6164',
        negativeHigh: '#FF6164',
        negativeHighest: '#FF6164',
        warning: '#F7CC1E',
        inverse: '#0A0A0A',
    },
    fillIn: {
        primary: {
            highest: '#04B367',
            high: '#04CD76',
            mid: '#0DE386',
            low: '#05FA90',
            muted: '#464947',
            overlay8: 'rgba(10, 158, 86, 0.08)',
            overlay16: 'rgba(10, 158, 86, 0.16)',
            overlay24: 'rgba(10, 158, 86, 0.24)',
            overlay32: 'rgba(10, 158, 86, 0.32)',
        },
        secondary: {
            highest: '#DBDDE1',
            high: '#656D7B',
            mid: '#484D56',
            low: '#42464D',
            muted: '#45474A',
            overlay8: 'rgba(129, 140, 156, 0.08)',
            overlay16: 'rgba(129, 140, 156, 0.16)',
            overlay24: 'rgba(129, 140, 156, 0.24)',
            overlay32: 'rgba(129, 140, 156, 0.32)',
        },
        positive: {
            highest: '#68D4A5',
            high: '#45BA8D',
            mid: '#288F6B',
            low: '#2D735B',
            muted: '#305C4F',
            overlay8: 'rgba(45, 115, 91, 0.08)',
            overlay16: 'rgba(45, 115, 91, 0.16)',
            overlay24: 'rgba(45, 115, 91, 0.24)',
            overlay32: 'rgba(45, 115, 91, 0.32)',
        },
        negative: {
            highest: '#FF8082',
            high: '#FF6164',
            mid: '#E84144',
            low: '#BD393D',
            muted: '#823436',
            overlay8: 'rgba(189, 57, 61, 0.08)',
            overlay16: 'rgba(189, 57, 61, 0.16)',
            overlay24: 'rgba(189, 57, 61, 0.24)',
            overlay32: 'rgba(189, 57, 61, 0.32)',
        },
        note: {
            highest: '#FCD356',
            high: '#FAC832',
            mid: '#EDAF11',
            low: '#BF8C14',
            muted: '#906A11',
            overlay8: 'rgba(191, 140, 20, 0.08)',
            overlay16: 'rgba(191, 140, 20, 0.16)',
            overlay24: 'rgba(191, 140, 20, 0.24)',
            overlay32: 'rgba(191, 140, 20, 0.32)',
        },
    },
    divider: {
        primary: '#26282C',
        secondary: '#2A2C32',
        altPrimary: '#5A6272',
        altSecondary: '#3F4550',
    },
    staticColors,
    risk,
    focusOutline,
    shadow,
};
