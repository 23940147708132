import { connect } from 'react-redux';

import { DispatchType } from 'types';
import { isProd } from 'services/utils/env';

import { GlobalStateHeaderNotifications } from '../types';
import { fetchNotifications, readInStoreNotifications, readNotifications, addInStoreNotification } from '../actions';
import { SocketConnectionAndToggleViewTransmitter } from '../components/SocketConnectionAndToggleViewTransmitter';

const mapStateToProps = (state: GlobalStateHeaderNotifications) => {
    if (!isProd() && !state?.widgets?.notifications) {
        throw new Error('Не добавлен редьюсер уведомлений');
    }
    return {
        readDate: state?.widgets?.notifications?.readDate,
        unreadCount: state?.widgets?.notifications?.unreadCount,
        isOpened: state?.widgets?.notifications?.isOpened,
        isError: state?.widgets?.notifications?.isError,
        isWasFetchNotifications: state?.widgets?.notifications?.isWasFetchNotifications,
    };
};

const mapDispatchToProps = {
    fetchNotifications,
    readNotifications,
    readInStoreNotifications,
    addInStoreNotification,
};

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;
export type MapDispatchToPropsType = DispatchType<typeof mapDispatchToProps>;

export const SocketConnectionAndToggleViewTransmitterContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SocketConnectionAndToggleViewTransmitter);
