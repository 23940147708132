import React from 'react';

import { IconSvg, IconComponent } from '.';

export const HeartOutlineIcon: IconComponent = (props) => {
    const { fill } = props;
    return (
        <IconSvg {...props} viewBox="0 0 24 24">
            <path
                d="M18.7663 6.2377C18.3753 5.84531 17.9111 5.53404 17.4002 5.32168C16.8893 5.10931 16.3417 5 15.7887 5C15.2357 5 14.6881 5.10931 14.1772 5.32168C13.6663 5.53404 13.2021 5.84531 12.8112 6.2377L11.9998 7.05166L11.1884 6.2377C10.3987 5.44548 9.32768 5.00041 8.21089 5.00041C7.09409 5.00041 6.02303 5.44548 5.23334 6.2377C4.44365 7.02993 4 8.10441 4 9.22479C4 10.3452 4.44365 11.4196 5.23334 12.2119L6.0447 13.0258L11.9998 19L17.9549 13.0258L18.7663 12.2119C19.1574 11.8197 19.4677 11.354 19.6794 10.8415C19.891 10.3289 20 9.77958 20 9.22479C20 8.67 19.891 8.12064 19.6794 7.60811C19.4677 7.09558 19.1574 6.6299 18.7663 6.2377V6.2377Z"
                stroke={fill || 'currentColor'}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="transparent"
            />
        </IconSvg>
    );
};
