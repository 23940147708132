/* eslint-disable camelcase */
import { Action } from 'types';
import { provideJWT } from 'services/global-auth-provider';
import { ApiError } from 'services/api-services/generated_marketplace/core/ApiError';
import { loadScript } from 'services/utils/scriptLoader';
import { api } from 'services/api-builder/api';
import { Marketplace_Web_Public_V1_My_DigitalAgreements_DigitalAgreementWebDto } from 'services/api-services/generated_marketplace';
import { useAsyncFn } from 'hooks/useAsyncFn';

type AgreementType = NonNullable<
    Marketplace_Web_Public_V1_My_DigitalAgreements_DigitalAgreementWebDto['agreementType']
>;

const loadDocusign = () =>
    new Promise<void>((resolve, reject) => {
        if (!document.getElementById('ds-clickwrap')) {
            const clickwrap = document.createElement('div');
            clickwrap.setAttribute('id', 'ds-clickwrap');
            document.body.append(clickwrap);
        }
        if (window.docuSignClick) {
            resolve();
        } else {
            loadScript('https://na4.docusign.net/clickapi/sdk/latest/docusign-click.js')
                .then(() => resolve())
                .catch(reject);
        }
    });

const sign = (agreementUrl: string) =>
    new Promise<void>((resolve, reject) => {
        loadDocusign()
            .then(() => {
                window.docuSignClick?.Clickwrap.render(
                    {
                        agreementUrl,
                        onAgreed: resolve,
                        onDeclined: reject,
                        onError: reject,
                    },
                    '#ds-clickwrap',
                );
            })
            .catch(() => reject());
    });

export function useSignAgreement(
    getAgreements: Action<typeof api.fetchAgreements>,
    createAgreement: Action<typeof api.createAgreement>,
    createSubscriberAgreement: Action<typeof api.createSubscriberAgreement>,
    updateAgreementStatus: Action<typeof api.updateAgreementStatus>,
) {
    const [{ isLoading, error, isSuccess }, handleGetAgreements] = useAsyncFn(
        async (agreementType: AgreementType, productId?: number): Promise<void> => {
            const agreements = await getAgreements({
                productType: 'UsStrategy',
                status: 'agreed',
                agreementType,
                productId,
            });
            if (agreements.data && agreements.data.length === 0) {
                const { session } = await provideJWT();
                const { name, lastname, email } = session?.person ?? {};
                const fullName = name + (lastname ? ` ${lastname}` : '');
                if (fullName && email) {
                    try {
                        const { data } =
                            agreementType === 'Author'
                                ? await createAgreement({
                                      requestBody: {
                                          productType: 'UsStrategy',
                                          fullName,
                                          email,
                                          companyName: '-',
                                          title: '-',
                                      },
                                  })
                                : await createSubscriberAgreement({
                                      requestBody: {
                                          productId: parseInt(`${productId}`, 10),
                                          fullName,
                                          email,
                                          companyName: '-',
                                          title: '-',
                                      },
                                  });

                        if (data?.agreementUrl && data?.digitalAgreementId) {
                            await sign(data.agreementUrl);
                            await updateAgreementStatus({
                                requestBody: { digitalAgreementId: data.digitalAgreementId, status: 'agreed' },
                            });
                        } else {
                            throw new Error('Unable to create agreement');
                        }
                    } catch (e) {
                        if (e instanceof ApiError) {
                            const message = e.body.error?.message;
                            if (message === 'User already has an agreement') {
                                return;
                            }
                        }
                        throw e;
                    }
                } else {
                    throw new Error('Unable to create agreement');
                }
            }
        },
    );

    return [{ isLoading, error, isSuccess }, handleGetAgreements] as const;
}
