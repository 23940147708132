import React, { useMemo } from 'react';

import { ProductItemPriceData } from 'types/product';
import Dic from 'services/dictionary';
import { DefaultCard } from 'components/card';

import { DefaultProductCardProps } from '../types';

export const getBadgeTextByPrice = (price: ProductItemPriceData): string | undefined =>
    (price?.trialPeriod?.type &&
        price?.trialPeriod?.count &&
        Dic.word(`wt_all__product_card__default__trial_short_${price?.trialPeriod?.type}`, [
            price?.trialPeriod?.count,
        ])) ||
    undefined;

export const DefaultProductCard = (props: DefaultProductCardProps) => {
    const {
        item,
        onClick: onClickProp,
        title: titleProp,
        summary: summaryProp,
        price: priceProp,
        author: authorProp,
        thumbnailSrc: thumbnailSrcProp,
        url: urlProp,
        freePriceWord: freePriceWordProp,
        badgeText: badgeTextProp,
        className: classNameProp,
        isDisableAutoDetectUrl = false,
        ...restProps
    } = props;

    const { title: titleItem, summary: summaryItem, price: priceItem, user, image, url: urlItem } = item;

    const title = titleProp || titleItem;
    const summary = summaryProp || summaryItem;
    const price = useMemo(
        () => ({
            ...(priceProp || priceItem),
            word: Dic.word('wt_all__product_card_widget__default__price_word'),
        }),
        [priceProp, priceItem],
    );
    const author = authorProp || user?.displayName;
    const thumbnailSrc = thumbnailSrcProp || image?.huge;
    const freePriceWord = freePriceWordProp || Dic.word('wt_all__product_card__default__free_price_word');
    const badgeText = badgeTextProp || (price ? getBadgeTextByPrice(price) : undefined);

    const url = useMemo(() => {
        if (urlProp) {
            return urlProp;
        }

        if (isDisableAutoDetectUrl) {
            return undefined;
        }

        return urlItem;
    }, [urlProp, urlItem, isDisableAutoDetectUrl]);

    const className = [classNameProp, 'autotest__product-card__default'].filter((i) => i).join(' ');

    return (
        <DefaultCard
            title={title}
            summary={summary}
            price={price}
            thumbnailSrc={thumbnailSrc}
            author={author}
            url={url}
            badgeText={badgeText}
            freePriceWord={freePriceWord}
            onClick={(e) => onClickProp?.(e)}
            className={className}
            {...restProps}
        />
    );
};
