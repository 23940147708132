import React, { useState, useEffect, useCallback } from 'react';

import { TagData, SelectedTagsIds } from 'types';
import { TagProps } from 'components/tag/Tag';

import { MapIdToEmoji } from './map-id-to-emoji';
import { TagStyled, Wrapper } from './styled';

type Props = Pick<TagProps, 'size' | 'kind'> & {
    tags: TagData[];
    selectedIds?: SelectedTagsIds;
    limit?: number;
    className?: string;
    // selectedIdsCandidate - компонент предполагает, что при клике на активный тег его надо сделать неактивным,
    // и наоборот, поэтому пледлагает готовый массив, но не заставляет его использовать
    onSelectTag?: ({ tag, selectedIdsCandidate }: { tag: TagData; selectedIdsCandidate: SelectedTagsIds }) => void;
    // аналогично для закрытия
    onCloseTag?: ({
        tag,
        nonClosedCandidate,
        nonClosedCandidateIds,
    }: {
        tag: TagData;
        nonClosedCandidate: TagData[];
        nonClosedCandidateIds: SelectedTagsIds;
    }) => void;
    useWrapper?: boolean;
    children?: (arg: { onSelect: (isActive: boolean, tag: TagData) => void }) => React.ReactElement;
    isReadonly?: boolean;
};

export const Tags = (props: Props) => {
    const {
        tags,
        selectedIds = [],
        onSelectTag,
        onCloseTag,
        size,
        kind,
        className,
        limit,
        useWrapper = true,
        isReadonly = false,
        children,
    } = props;

    const calcIsLimit = useCallback(() => Boolean(limit && selectedIds.length >= limit), [selectedIds, limit]);

    const [isLimitReached, setIsLimitReached] = useState<boolean>(calcIsLimit());

    useEffect(() => {
        if (!limit) return;
        setIsLimitReached(calcIsLimit());
    }, [limit, selectedIds, calcIsLimit]);

    const handleSelectTag = (tagData: TagData, isActive: boolean) => {
        if (!onSelectTag) return;
        let selectedIdsCandidate;
        if (isActive) {
            selectedIdsCandidate = selectedIds.filter((id) => id !== tagData.id);
        } else {
            selectedIdsCandidate = [...selectedIds, tagData.id];
        }
        onSelectTag({ tag: tagData, selectedIdsCandidate });
    };

    const handleCloseTag = (tagData: TagData) => {
        if (!onCloseTag) return;
        const nonClosedCandidate = tags.filter((_tagData) => _tagData.id !== tagData.id);
        onCloseTag({
            tag: tagData,
            nonClosedCandidate,
            nonClosedCandidateIds: nonClosedCandidate.map((_tagData) => _tagData.id),
        });
    };

    const renderedTags = tags.map((tag) => {
        const img = MapIdToEmoji[tag.id];
        const isActive = !!selectedIds.find((id) => id === tag.id);
        return (
            <TagStyled
                key={tag.id}
                size={size}
                Icon={img}
                text={tag.name}
                kind={kind}
                isActive={isActive}
                onClick={(_isActive) => handleSelectTag(tag, _isActive)}
                close={onCloseTag ? () => handleCloseTag(tag) : undefined}
                isDisable={isLimitReached && !isActive}
                isReadonly={isReadonly}
            />
        );
    });

    if (children) {
        return children({
            onSelect: (_isActive, tag) => handleSelectTag(tag, _isActive),
        });
    }

    const FinalWrapper = useWrapper ? Wrapper : React.Fragment;

    return <FinalWrapper {...(useWrapper ? { className } : {})}>{renderedTags}</FinalWrapper>;
};

export { MapIdToEmoji };
export type { MapIdToEmojiType } from './map-id-to-emoji';
export type { TagData, SelectedTagsIds, Props as TagsProps };
