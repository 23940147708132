import styled, { css } from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';

export const ContextMenuItemStyled = styled.button<{ $mobile: boolean }>`
    flex: none;
    color: ${({ theme }) => theme.label.primary};
    background-color: ${({ theme }) => theme.bg.primary};
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 4px 0 16px;
    cursor: pointer;
    text-align: left;
    &:hover {
        background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};
        color: ${({ theme }) => theme.label.primary};
    }

    display: flex;

    ${({ $mobile }) =>
        $mobile
            ? css`
                  display: none;
                  ${mediaQuery.lt768} {
                      display: flex;
                  }
              `
            : ''}

    align-items: center;

    & > * {
        display: flex;
    }

    & > .icon {
        flex: none;
        width: 24px;

        svg {
            fill: ${({ theme }) => theme.label.secondary};
        }
    }

    & > .text {
        flex: 1 1 100%;
        margin-left: 12px;
        min-width: 0;
    }

    & > .chevron {
        flex: none;
        width: 20px;
    }
`;

export const MenuItemText = styled.div`
    ${body14RegularFontStyle};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Separator = styled.div`
    flex: none;
    width: 100%;
    height: 1px;
    margin: 7px 0 8px 0;
    background-color: ${({ theme }) => theme.divider.primary};
`;
