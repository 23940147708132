import { MutableRefObject, useLayoutEffect, useMemo } from 'react';

import { Lcid } from 'types';

import { NumberInputFormatter } from './NumberInputFormatter';

export function useNumberInputFormatter(
    ref: MutableRefObject<HTMLInputElement | null>,
    locale: Lcid,
    value: string,
    onChange: (value: string) => void,
    currencySign?: string,
): string | null {
    const formatter = useMemo(() => new NumberInputFormatter(locale, currencySign), [currencySign, locale]);

    useLayoutEffect(() => {
        const el = ref.current;

        if (el) {
            const handleBeforeInput = (e: InputEvent) => {
                e.preventDefault();
                const [newValue, newPosition] =
                    formatter.handleEvents(e.inputType, e.data, el.value, el.selectionStart, el.selectionEnd) ?? [];

                if (newValue !== undefined && newPosition !== undefined && newValue !== el.value) {
                    onChange(formatter.parse(newValue));
                    setTimeout(() => {
                        el?.setSelectionRange(newPosition, newPosition);
                    }, 0);
                }
            };
            el.addEventListener('beforeinput', handleBeforeInput);

            return () => el.removeEventListener('beforeinput', handleBeforeInput);
        }
        return undefined;
    }, [formatter, onChange, ref]);

    return formatter.format(value);
}
