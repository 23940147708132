import { createElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

type UseInfiniteScrollSettings = {
    onListEndReached: () => void;
    scrollRootElement?: HTMLElement | null;
    threshold?: number;
};

/**
 * Executes a callback every time the viewport reaches the sentinel element.
 * Returned `checkIfListEndReached` function can be used to check if the sentinel element is visible imperatively.
 *
 * @example
 * const loadMoreItems = () => { ... }
 *
 * const {
 *     sentinelElement,
 *     checkIfListEndReached
 * } = useInfiniteScroll({
 *     onListEndReached: () => loadMoreItems(),
 * });
 *
 * return (
 *    <>
 *         <ul>
 *             {items.map(...)}
 *             {sentinelElement}
 *         </ul>
 *         <button onClick={checkIfListEndReached}>Check manually</button>
 *     </>
 * )
 */

export function useInfiniteScroll({
    onListEndReached,
    scrollRootElement = null,
    threshold = 0,
}: UseInfiniteScrollSettings) {
    const onListEndReachedRef = useRef(onListEndReached);
    useLayoutEffect(() => {
        onListEndReachedRef.current = onListEndReached;
    });

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                ([sentinelEntry]) => {
                    const { isIntersecting } = sentinelEntry;
                    if (isIntersecting) {
                        onListEndReachedRef.current();
                    }
                },
                {
                    root: scrollRootElement,
                    rootMargin: `0px 0px ${threshold}px 0px`,
                },
            ),
        [scrollRootElement, threshold],
    );

    useEffect(() => () => intersectionObserver.disconnect(), [intersectionObserver]);

    const [sentinelNode, setSentinelNode] = useState(null);

    const sentinelElement = useMemo(
        () =>
            createElement('div', {
                ref: setSentinelNode,
            }),
        [],
    );

    useEffect(() => {
        if (!sentinelNode) return undefined;
        intersectionObserver.observe(sentinelNode);
        return () => intersectionObserver.unobserve(sentinelNode);
    }, [intersectionObserver, sentinelNode]);

    const checkIfListEndReached = useCallback(() => {
        if (!sentinelNode) return;
        intersectionObserver.unobserve(sentinelNode);
        intersectionObserver.observe(sentinelNode);
    }, [intersectionObserver, sentinelNode]);

    return {
        checkIfListEndReached,
        sentinelElement,
    };
}
