import React, { useCallback, useState } from 'react';

import { Id } from 'types';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { useDicMemo, useDictionary } from 'hooks/useDictionary';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { Textarea } from 'components/form/textarea';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { Complain, ComplainActions, REPORT_SPAM_ACTION_TYPES } from '../page/actions';
import { usePageRoot } from '../hooks';

import {
    ComplaintContent,
    StyledMenuItem,
    Reason,
    Subtitle,
    ComplaintText,
    DescriptionLabel,
} from './styled/ComplaintPopup';

const types = ['lies', 'illegal', 'fraud', 'shock', 'hate', 'bulling', 'nude', 'spam', 'copyright', 'other'] as const;

type Props = {
    complain: Complain;
    suspectPersonId: Id;
    closePopup: () => void;
};

export const ComplaintPopup = (props: Props) => {
    const { complain, closePopup, suspectPersonId } = props;

    const pageRoot = usePageRoot();

    const [reasons, texts] = useDicMemo((dic) => [
        Object.fromEntries(types.map((type) => [type, dic.word(`wt_profile__popup_complaint__reason_${type}`)])),
        Object.fromEntries(
            types.map((type) => {
                if (type === 'other') return [];
                return [type, dic.word(`wt_profile__popup_complaint__text_${type}`)];
            }),
        ),
    ]);

    const [currentType, setReasonType] = useState<typeof types[number] | 'main'>('main');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const csrf = useCSRFToken();
    const dic = useDictionary();
    const handleSubmit = useCallback(() => {
        setIsSubmitting(true);
        complain(suspectPersonId, { reason: reasons[currentType], description, csrf })
            .then(() => {
                closePopup();
                notify.success(Dic.word('wt_profile__popup_complaint__notification_success'));
            })
            .catch((errorAction: Extract<ComplainActions, { type: typeof REPORT_SPAM_ACTION_TYPES[2] }>) => {
                notify.error(
                    (errorAction.payload.error.response?.data as { error?: string }).error ||
                        Dic.word('wt_profile__notification__common_error'),
                );
                throw errorAction;
            })
            .finally(() => setIsSubmitting(false));
    }, [closePopup, complain, csrf, currentType, description, reasons, suspectPersonId]);

    return (
        <WtPopup3
            size={SizeWtPopup3Enum.LARGE}
            close={closePopup}
            portalTo={pageRoot}
            bgCloseable
            width={464}
            title={dic.word('wt_profile__popup_complaint__title_text')}
            actionButtons={
                currentType === 'main'
                    ? undefined
                    : {
                          direction: 'vertical',
                          items: [
                              {
                                  key: 1,
                                  content: dic.word('wt_profile__popup_complaint__submit_btn'),
                                  onClick: handleSubmit,
                                  isProcessing: isSubmitting,
                              },
                          ],
                      }
            }
        >
            <ComplaintContent currentType={currentType}>
                {currentType === 'main' ? (
                    <>
                        <Reason>{dic.word('wt_profile__popup_complaint__select_reason')}</Reason>
                        {types.map((type) => (
                            <StyledMenuItem key={type} text={reasons[type]} onClick={() => setReasonType(type)} />
                        ))}
                    </>
                ) : (
                    <>
                        <Subtitle>{reasons[currentType]}</Subtitle>
                        {texts[currentType] ? (
                            <ComplaintText dangerouslySetInnerHTML={{ __html: texts[currentType] }} />
                        ) : null}
                        <DescriptionLabel>
                            {dic.word('wt_profile__popup_complaint__description_label')}
                        </DescriptionLabel>
                        <Textarea
                            fieldProps={{
                                placeholder: dic.word('wt_profile__popup_complaint__description_placeholder'),
                                onChange: (event) => setDescription((event.target as HTMLTextAreaElement).value),
                            }}
                            size="medium"
                        />
                    </>
                )}
            </ComplaintContent>
        </WtPopup3>
    );
};
