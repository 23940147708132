import { customEqual } from 'services/utils/custom-equal';

export const rulesComparer = <T extends Record<string, unknown>>(prev: T, next: T) =>
    customEqual(prev, next, {
        rules: {
            required: undefined,
            min: undefined,
            max: undefined,
            maxLength: undefined,
            minLength: undefined,
            pattern: undefined,
        },
    });
