import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import CatLiesSVG from 'components/assets/CatLies.svg';

import * as Styled from './styled';

export const NoProducts: FC = () => {
    const dic = useDictionary();

    return (
        <Styled.NoProducts>
            <CatLiesSVG />
            <Styled.Header>{dic.word('wt_profile__widget_no_products_header')}</Styled.Header>
            <Styled.Text>{dic.word('wt_profile__widget_no_products_text')}</Styled.Text>
        </Styled.NoProducts>
    );
};
