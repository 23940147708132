import React from 'react';

import { ListBoxComplex } from 'components/listbox/ListBoxComplex';

import { Option } from '../types';

import { Option as InnerOption } from './Option';
import * as Styled from './styled';

type AccountListBoxProps = {
    options: Array<Option>;
    onChange: (value: Option) => void;
};

export const AccountListBox = (props: AccountListBoxProps) => {
    const { options, onChange } = props;

    const renderTitleComponent = (selectedOption: Option) =>
        selectedOption && (
            <Styled.CheckedTitleWrapper>
                <Styled.CheckedTitleAuthor>{selectedOption?.title}</Styled.CheckedTitleAuthor>
                <Styled.CheckedTitleNumber>{selectedOption?.number}</Styled.CheckedTitleNumber>
            </Styled.CheckedTitleWrapper>
        );

    return (
        <Styled.AccountListBoxWrapper>
            <ListBoxComplex<Option>
                renderCheckedOptionElement={(_selectedOption) => {
                    const titleComponent = renderTitleComponent(_selectedOption);
                    return titleComponent ? <Styled.ComplexItem title={titleComponent} size="small" /> : null;
                }}
                pageBlockWhenSwitchOpen={false}
                options={options}
                renderInnerOption={({ title, number }) => <InnerOption title={title} number={number} />}
                onChange={onChange}
            />
        </Styled.AccountListBoxWrapper>
    );
};
