/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marketplace_Web_Private_V1_Subscriptions_SubscribeWebRequest } from '../models/UsSubscriptionsService/Marketplace_Web_Private_V1_Subscriptions_SubscribeWebRequest';
import type { Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeStripelessWebRequest } from '../models/UsSubscriptionsService/Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeStripelessWebRequest';
import type { Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeUsStrategyWebRequest } from '../models/UsSubscriptionsService/Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeUsStrategyWebRequest';
import type { Marketplace_Web_Public_V1_My_Subscriptions_Us_UnsubscribeStripelessWebRequest } from '../models/UsSubscriptionsService/Marketplace_Web_Public_V1_My_Subscriptions_Us_UnsubscribeStripelessWebRequest';
import type { WebResponse_Array_SubscriptionAccountCandidate } from '../models/UsSubscriptionsService/WebResponse_Array_SubscriptionAccountCandidate';
import type { WebResponse_CreateSessionWebResponse } from '../models/shared/WebResponse_CreateSessionWebResponse';
import type { WebResponse_MySubscriptionDto } from '../models/shared/WebResponse_MySubscriptionDto';
import type { WebResponse_SubscribeStripelessWebResponse } from '../models/UsSubscriptionsService/WebResponse_SubscribeStripelessWebResponse';
import type { WebResponse_SubscribeWebResponse } from '../models/UsSubscriptionsService/WebResponse_SubscribeWebResponse';
import type { WebResponse_UnsubscribeStripelessWebResponse } from '../models/UsSubscriptionsService/WebResponse_UnsubscribeStripelessWebResponse';
import type { WebResponse_UnsubscribeWebResponse } from '../models/UsSubscriptionsService/WebResponse_UnsubscribeWebResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsSubscriptionsService {
    /**
     * Подписать пользователя на продукты без Stripe интеграции
     * @returns WebResponse_SubscribeWebResponse Success
     * @throws ApiError
     */
    public static createSubscriptions({
        requestBody,
    }: {
        requestBody?: Marketplace_Web_Private_V1_Subscriptions_SubscribeWebRequest;
    }): CancelablePromise<WebResponse_SubscribeWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/subscriptions',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Удалить подписки пользователя
     * @returns WebResponse_UnsubscribeWebResponse Success
     * @throws ApiError
     */
    public static deleteSubscriptions({
        userId,
        productIds,
    }: {
        /**
         * Kratos Id
         */
        userId?: string;
        /**
         * Id продуктов
         */
        productIds?: Array<number>;
    }): CancelablePromise<WebResponse_UnsubscribeWebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/private/api/v1/subscriptions',
            query: {
                UserId: userId,
                ProductIds: productIds,
            },
        });
    }

    /**
     * @deprecated
     * Подписать пользователя на продукты без Stripe интеграции
     * @returns WebResponse_SubscribeStripelessWebResponse Success
     * @throws ApiError
     */
    public static createApiVMyUsSubscriptionsStripeless({
        requestBody,
    }: {
        requestBody?: Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeStripelessWebRequest;
    }): CancelablePromise<WebResponse_SubscribeStripelessWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/us/subscriptions/stripeless',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @deprecated
     * Удалить подписку пользователя без Stripe интеграции
     * @returns WebResponse_UnsubscribeStripelessWebResponse Success
     * @throws ApiError
     */
    public static deleteApiVMyUsSubscriptionsStripeless({
        requestBody,
    }: {
        requestBody?: Marketplace_Web_Public_V1_My_Subscriptions_Us_UnsubscribeStripelessWebRequest;
    }): CancelablePromise<WebResponse_UnsubscribeStripelessWebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/us/subscriptions/stripeless',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Запрос доступных счетов на создание подписки на автоследование
     * @returns WebResponse_Array_SubscriptionAccountCandidate Success
     * @throws ApiError
     */
    public static getAccountsForAutofollow({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
    }): CancelablePromise<WebResponse_Array_SubscriptionAccountCandidate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/us/subscriptions/autofollowing/{productId}',
            path: {
                productId: productId,
            },
        });
    }

    /**
     * Выбор опций подписки на автоследование и формирование ссылки на оплату
     * @returns WebResponse_CreateSessionWebResponse Success
     * @throws ApiError
     */
    public static createSubscriptionAutofollowing({
        requestBody,
    }: {
        /**
         * Данные подписки на автоследование
         */
        requestBody?: Marketplace_Web_Public_V1_My_Subscriptions_Us_SubscribeUsStrategyWebRequest;
    }): CancelablePromise<WebResponse_CreateSessionWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/us/subscriptions/autofollowing',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Запрос на удаление подписки на автоследование
     * @returns WebResponse_MySubscriptionDto Success
     * @throws ApiError
     */
    public static deleteSubscriptionAutofollowing({
        subscriptionId,
    }: {
        /**
         * Идентификатор подписки
         */
        subscriptionId: number;
    }): CancelablePromise<WebResponse_MySubscriptionDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/us/subscriptions/autofollowing/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
        });
    }
}
