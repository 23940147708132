import { connect } from 'react-redux';

import { Like as LikeComponent } from '../_shared/_sub/like';

import { sendPostRatingReaction } from './actions';

const mapDispatchToProps = {
    onRatingReaction: sendPostRatingReaction,
};

export const LikePost = connect(null, mapDispatchToProps)(LikeComponent);
