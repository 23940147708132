/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductDonationForTheAuthorNotificationExporter } from '../models/NotificationService/ProductDonationForTheAuthorNotificationExporter';
import type { ProductNewSignalNotificationExporter } from '../models/NotificationService/ProductNewSignalNotificationExporter';
import type { ProductWithImageNotificationExporter } from '../models/NotificationService/ProductWithImageNotificationExporter';
import type { UserActionExporter } from '../models/NotificationService/UserActionExporter';
import type { YouBecameAnCreatorNotificationExporter } from '../models/NotificationService/YouBecameAnCreatorNotificationExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {
    /**
     * Список уведомлений пользователя
     * @returns any Список уведомлений
     * @throws ApiError
     */
    public static getNotificationList({
        cursor,
        limit,
    }: {
        /**
         * Курсор берется из предыдущего запроса из поля nextCursor
         */
        cursor?: string;
        /**
         * Количество загружаемых записей. Допустимо значение от 1 до 100. По-умолчанию 10
         */
        limit?: number;
    }): CancelablePromise<{
        /**
         * Курсор передается в следующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor?: string | null;
        /**
         * Количество непрочитанных записей
         */
        unreadCount?: number | null;
        /**
         * Общее количество записей
         */
        totalCount?: number | null;
        /**
         * Значение используется для передачи в контроллер установки прочитанности записей
         */
        readDate?: string | null;
        notifications?: Array<
            | UserActionExporter
            | ProductWithImageNotificationExporter
            | ProductDonationForTheAuthorNotificationExporter
            | ProductNewSignalNotificationExporter
            | YouBecameAnCreatorNotificationExporter
        >;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notification/list/',
            query: {
                cursor: cursor,
                limit: limit,
            },
        });
    }

    /**
     * Установление отметки о прочтении уведомлений
     * @returns any Количество прочитанных записей
     * @throws ApiError
     */
    public static readNotifications({
        requestBody,
    }: {
        requestBody?: {
            csrf: string;
            /**
             * Значение берется из readDate из ответа на запрос получения спсика уведомлений
             */
            readDate: string;
        };
    }): CancelablePromise<{
        readCount?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification/read/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Удаление уведомления
     * @returns any Результат удаления
     * @throws ApiError
     */
    public static deleteNotification({
        notificationId,
        requestBody,
    }: {
        /**
         * ID уведомления которое необходимо удалить
         */
        notificationId: number;
        requestBody?: {
            csrf: string;
        };
    }): CancelablePromise<{
        deleted?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/notification/{notificationId}/',
            path: {
                notificationId: notificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить список подписок/отписок на уведомления
     * @returns any Успешное получение подписок
     * @throws ApiError
     */
    public static getNotificationSubscription(): CancelablePromise<{
        /**
         * Подписки/Отписки пользователя
         */
        subscriptions?: {
            global?: Array<{
                /**
                 * Идентификатор подписки
                 */
                id?: string;
                /**
                 * Имя подписки
                 */
                label?: string;
                /**
                 * Флаг подписки
                 */
                isSubscribed?: boolean;
            }>;
            persons?: Array<{
                /**
                 * ИД пользователя
                 */
                id?: number;
                /**
                 * Имя пользователя
                 */
                label?: string;
                /**
                 * Флаг подписки
                 */
                isSubscribed?: boolean;
            }> | null;
            instruments?: Array<{
                /**
                 * ИД инструмента
                 */
                id?: number;
                /**
                 * Имя инструмента
                 */
                label?: string;
                /**
                 * Флаг подписки
                 */
                isSubscribed?: boolean;
            }> | null;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/notification/subscription/',
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Подписаться на уведомление
     * @returns void
     * @throws ApiError
     */
    public static switchOnNotificationSetting({
        group,
        identifier,
    }: {
        /**
         * Группа уведомлений
         */
        group: 'global' | 'persons' | 'instruments';
        /**
         * Идентификатор уведомления
         */
        identifier: string;
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification/subscription/{group}/{identifier}/subscribe/',
            path: {
                group: group,
                identifier: identifier,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Отписаться от уведомления
     * @returns void
     * @throws ApiError
     */
    public static switchOffNotificationSetting({
        group,
        identifier,
    }: {
        /**
         * Группа уведомлений
         */
        group: 'global' | 'persons' | 'instruments';
        /**
         * Идентификатор уведомления
         */
        identifier: string;
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/notification/subscription/{group}/{identifier}/unsubscribe/',
            path: {
                group: group,
                identifier: identifier,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }
}
