import styled, { css, keyframes } from 'styled-components';
import { ifProp, theme } from 'styled-tools';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, body15MediumFontStyle, body17RegularFontStyle } from 'services/typography';
import { MenuItem } from 'components/menu-item';
import IconedText from 'components/iconed-text2';
import { Icon, Image } from 'components/iconed-text2/styled';
import { IconedText as IconedTextMenuItem } from 'components/menu-item/styled';
import { ButtonIconWrapper } from 'components/context-actions/styled';
import { ContextActions } from 'components/context-actions';
import { AvatarUser } from 'components/avatar-user';
import { UserBlock as UserBlockComponent } from 'components/user-block';

export const AddFeedStatusContainer = styled.div`
    ${mediaQuery.lt480} {
        margin-bottom: 12px;
    }
`;

export const AddFeedStatusEditorContainer = styled.div<{ isActive: boolean }>`
    ${body17RegularFontStyle};

    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme('bg.primary')};
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 0;
    transition: padding 240ms, border 600ms;

    ${ifProp(
        'isActive',
        css`
            border: 1px solid ${theme('fillIn.primary.mid')};
            padding: 24px;
        `,
        css`
            border: 1px solid ${theme('bg.secondary')};
            padding: 16px 24px;
        `,
    )}

    ${mediaQuery.lt480} {
        ${body14RegularFontStyle};
        padding: 8px 16px;
        margin-bottom: 8px;
    }
`;

export const AddFeedStatusAvatar = styled(AvatarUser)<{ isHidden?: boolean }>`
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`;

export const UserBlock = styled(UserBlockComponent)<{ isHidden?: boolean }>`
    display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`;

export const AddFeedStatusTopSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const manifestation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }`;

export const AddFeedStatusBottomSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: -8px;
    animation-name: ${manifestation};
    animation-duration: 420ms;
    transition: all 420ms;
`;

export const AddFeedStatusToolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const AddFeedStatusPlaceholder = styled.div<{ isHidden: boolean }>`
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    font-size: 17px;
    line-height: 24px;
    margin: 0 16px;
    color: ${theme('label.placeholder')};
`;

export const PostTypeWrapper = styled.div`
    ${body14RegularFontStyle};
`;

export const PostTypeSelectorDesktopWrapper = styled.div`
    ${body14RegularFontStyle};
    cursor: pointer;
    min-width: 0;
`;
export const PostTypeSelectorMobileWrapper = styled.div`
    ${body14RegularFontStyle};
    cursor: pointer;
    display: inline-flex;
    min-width: 0;
`;

export const CommunitiesHeader = styled.div`
    height: 42px;
    box-sizing: border-box;
    padding: 12px 0 8px 16px;
    ${body15MediumFontStyle};
    color: ${theme('label.primary')};
`;

export const TextWithIcon = styled(IconedText)`
    display: inline-flex;
    align-items: center;
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 1;

    & ${Icon} {
        flex: none;
    }

    & ${Image} {
        flex: none;
    }
`;

export const TruncatedText = styled.div`
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MenuItemStyled = styled(MenuItem)`
    & ${IconedTextMenuItem} {
        min-width: 0;
        flex-grow: 1;
        flex-shrink: 1;
    }
`;

export const ContextActionsStyled = styled(ContextActions)`
    & ${ButtonIconWrapper} {
        min-width: 0;
        display: flex;
    }
`;
