import React, { FC, useMemo } from 'react';

import { Account } from 'services/mp-share-resources/types';
import { ComplexItemProps } from 'components/complex-item/types';
import { Value as ValueListBoxComplex } from 'components/listbox/ListBoxComplex';

import { AccountListBox } from './components';

export type ProfitabilityOptionType = 'neutral' | 'negative' | 'positive';

export type Option = {
    value: ValueListBoxComplex;
    title: ComplexItemProps['title'];
    number: ComplexItemProps['secondLine'];
};

interface Props {
    accounts: Account[];
    onChange: (value: Option) => void;
}

export const AccountListBoxWidget: FC<Props> = ({ accounts, onChange }) => {
    const options: Option[] = useMemo(
        () =>
            accounts.map(({ accountId, tradeCode }) => ({
                value: accountId ?? '',
                title: `${accountId}`,
                number: tradeCode ?? '',
            })),
        [accounts],
    );

    return <AccountListBox options={options} onChange={onChange} />;
};
