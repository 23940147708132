import React from 'react';

import { DicWordParams } from 'types';
import { useDictionary } from 'hooks/useDictionary';
import { Notify } from 'components/notify';
import { NotifyProps } from 'components/notify/types';

export type NotificationNotifyProps = Omit<NotifyProps, 'text'> & {
    text: string | { key: string; params?: DicWordParams };
};

export const NotificationNotify = (props: NotificationNotifyProps) => {
    const { text } = props;
    const dic = useDictionary();

    const formattedText =
        typeof text === 'string' ? dic.word(text, { ignoreMissing: true }) : dic.word(text.key, text.params);

    return <Notify {...props} text={formattedText} />;
};
