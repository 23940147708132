import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { usePopupRoot } from 'context/popup-root';

import type { WtPopup3Props } from './types';
import { DEFAULT_SIZE_WT_POPUP_3, SizeWtPopup3Enum } from './constants';
import { PopupWindow3 } from './popup-window';
import { Content } from './content';
import { Footer } from './footer';
import { Steps } from './steps';
import { Header } from './header';

export const WtPopup3 = (props: WtPopup3Props) => {
    const {
        children,
        bgCloseable = false,
        actionButtons,
        footerChildren,
        size = DEFAULT_SIZE_WT_POPUP_3,
        isMobileFullscreen = false,
        className: classNameProp = '',
        title,
        close,
        back = undefined,
        width,
        isVisible = true,
        portalTo = undefined,
        counter,
        steps,
        contentWithoutPadding = false,
        closeButtonClassName,
        hideHeader = false,
        hideFooter = false,
        zIndex,
    } = props;

    const onBackSteps = steps?.onBack;
    const textBtnBack = steps?.textBtnBack;
    const textStatusSteps = steps?.textStatusSteps;
    const backBtnIsDisabled = steps?.backBtnIsDisabled;

    const popupRoot = usePopupRoot();

    useEffect(() => {
        const handleEscKeydown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                close?.();
            }
        };
        document.addEventListener('keydown', handleEscKeydown);
        return () => document.removeEventListener('keydown', handleEscKeydown);
    }, [close]);

    const className = [classNameProp, 'autotest__wt-popup3'].filter((cn) => cn).join(' ');

    const result = (
        <PopupWindow3
            className={className}
            bgCloseable={bgCloseable}
            close={close}
            isVisible={isVisible}
            isMobileFullscreen={isMobileFullscreen}
            width={width}
            zIndex={zIndex}
        >
            <>
                {!hideHeader && (
                    <Header
                        onClickCloseButton={close}
                        onClickBackButton={back}
                        title={title}
                        size={size}
                        counter={counter}
                        closeButtonClassName={closeButtonClassName}
                    />
                )}
                {onBackSteps && textBtnBack && textStatusSteps && (
                    <Steps
                        size={size}
                        onBack={onBackSteps}
                        textBtnBack={textBtnBack}
                        textStatusSteps={textStatusSteps}
                        backBtnIsDisabled={backBtnIsDisabled}
                        // для textBtnBack и textStatusSteps созданы дикворды подробнее в /steps или Steps.stories.tsx
                    />
                )}
                <Content size={size} withoutPadding={contentWithoutPadding}>
                    {children}
                </Content>
                {!hideFooter && (
                    <Footer size={size} actionButtons={actionButtons}>
                        {footerChildren}
                    </Footer>
                )}
            </>
        </PopupWindow3>
    );

    if (portalTo === 'popupRoot') {
        if (!popupRoot) {
            throw new Error(
                'When using wt-popup3 with portalTo="popupRoot" component must be wrapped in PopupRootContextProvider',
            );
        }
        return ReactDOM.createPortal(result, popupRoot);
    }

    if (portalTo) {
        return ReactDOM.createPortal(result, portalTo);
    }

    return result;
};

export { SizeWtPopup3Enum, WtPopup3Props };
