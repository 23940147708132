import React from 'react';

import { IconSvg, IconComponent } from '.';

export const UserTimesIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M9.625 15.3C9.325 15 8.825 15 8.525 15.3L6.925 16.9L5.325 15.3C5.025 15 4.525 15 4.225 15.3C3.925 15.6 3.925 16.1 4.225 16.4L5.825 18L4.225 19.6C3.925 19.9 3.925 20.4 4.225 20.7C4.525 21 5.025 21 5.325 20.7L6.925 19.1L8.525 20.7C8.825 21 9.325 21 9.625 20.7C9.925 20.4 9.925 19.9 9.625 19.6L8.125 18L9.725 16.4C9.925 16.1 9.925 15.6 9.625 15.3Z" />
        <path d="M20.425 20.2C19.925 18.3 17.225 14.1 12.325 14.1C11.925 14.1 11.525 14.5 11.525 14.9C11.525 15.3 11.925 15.7 12.325 15.7C16.025 15.7 18.125 18.7 18.825 20.3H11.525C11.125 20.3 10.725 20.7 10.725 21.1C10.725 21.5 11.125 21.9 11.525 21.9H19.225C19.625 21.9 20.025 21.7 20.225 21.4C20.425 21 20.525 20.6 20.425 20.2Z" />
        <path d="M12.325 13.3C15.125 13.3 17.425 11 17.425 8.2C17.425 5.4 15.125 3 12.325 3C9.525 3 7.125 5.3 7.125 8.1C7.125 10.9 9.425 13.3 12.325 13.3ZM12.325 4.6C14.325 4.6 15.825 6.2 15.825 8.1C15.825 10.1 14.225 11.6 12.325 11.6C10.325 11.6 8.825 10 8.825 8.1C8.725 6.2 10.325 4.6 12.325 4.6Z" />
    </IconSvg>
);
