import { InstagramIcon } from 'components/icons2/InstagramIcon';
import { TikTokIcon } from 'components/icons2/TikTokIcon';
import { LinkedInIcon } from 'components/icons2/LinkedInIcon';
import { TelegramPlaneIcon } from 'components/icons2/TelegramPlaneIcon';
import { VKIcon } from 'components/icons2/VKIcon';
import { DzenIcon } from 'components/icons2/DzenIcon';
import { YoutubeIcon } from 'components/icons2/YoutubeIcon';

import { SocialIconsData } from './interfaces';

export const footerSocialIcons: SocialIconsData = {
    en: [
        {
            href: 'https://www.instagram.com/limex.me/',
            description: 'Instagram',
            Icon: InstagramIcon,
            className: 'autotest__footer__social-icon-link__en__instagram',
        },
        {
            href: 'https://www.youtube.com/@Limexcom',
            description: 'Youtube',
            Icon: YoutubeIcon,
            className: 'autotest__footer__social-icon-link__en__youtube',
        },
        {
            href: 'https://www.tiktok.com/@limex.me',
            description: 'TikTok',
            Icon: TikTokIcon,
            className: 'autotest__footer__social-icon-link__en__tiktok',
        },
        {
            href: 'https://www.linkedin.com/company/limexme/',
            description: 'LinkedIn',
            Icon: LinkedInIcon,
            className: 'autotest__footer__social-icon-link__en__linkedIn',
        },
    ],
    ru: [
        {
            href: 'https://t.me/limexru',
            description: 'Телеграм',
            Icon: TelegramPlaneIcon,
            className: 'autotest__footer__social-icon-link__ru__telegram',
        },
        {
            href: 'https://vk.com/limexme',
            description: 'ВК',
            Icon: VKIcon,
            className: 'autotest__footer__social-icon-link__ru__vk',
        },
        {
            href: 'https://www.tiktok.com/@limexru',
            description: 'Тик ток',
            Icon: TikTokIcon,
            className: 'autotest__footer__social-icon-link__ru__tiktok',
        },
        {
            href: 'https://zen.yandex.ru/id/62398923bb7c5c3f0148befc',
            description: 'Яндекс дзен',
            Icon: DzenIcon,
            className: 'autotest__footer__social-icon-link__ru__dzen',
        },
    ],
};
