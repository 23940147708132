import styled from 'styled-components';

import { reduceBreakpoints } from 'app/styled';
import { body14RegularFontStyle, body16RegularFontStyle } from 'services/typography';

import { RightGroupProps } from '../types/RightGroup.types';

export const RightGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 4px;
`;

export const RightGroupTextWrapper = styled.div<Required<Pick<RightGroupProps, 'isDisabled' | 'size' | 'isError'>>>`
    overflow: hidden;
    margin: 0 4px;
    max-width: 106px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`;

export const RightGroupText = styled.span<Required<Pick<RightGroupProps, 'isDisabled' | 'size' | 'isError'>>>`
    ${({ size }) =>
        reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'large':
                    return body16RegularFontStyle;
                default:
                    return body14RegularFontStyle;
            }
        })};
    display: inline-block;
    color: ${({ theme, isDisabled, isError }) => {
        if (isDisabled) {
            return theme.label.inactive;
        }

        if (isError) {
            return theme.label.negative;
        }

        return theme.label.secondary;
    }};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const RightGroupIcon = styled.div<Pick<RightGroupProps, 'isDisabled' | 'size' | 'isError'>>`
    display: flex;

    svg {
        fill: ${({ theme, isDisabled, isError }) => {
            if (isDisabled) {
                return theme.label.inactive;
            }

            if (isError) {
                return theme.label.negative;
            }

            return theme.label.secondary;
        }};
    }
`;
