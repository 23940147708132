/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { WebResponse_IReadOnlyList_AuthorDailyStatisticDto } from '../models/ProductStatisticsService/WebResponse_IReadOnlyList_AuthorDailyStatisticDto';
import type { WebResponse_IReadOnlyList_ProductFavoritesDto } from '../models/ProductStatisticsService/WebResponse_IReadOnlyList_ProductFavoritesDto';
import type { WebResponse_IReadOnlyList_ProductSubscriptionsDailyStatisticItem } from '../models/ProductStatisticsService/WebResponse_IReadOnlyList_ProductSubscriptionsDailyStatisticItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductStatisticsService {
    /**
     * Выполнить расчёт обобщенной статистики для авторов продуктов
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static createProductsStatisticsAuthors({
        withReset,
    }: {
        /**
         * Сбросить уже имеющуюся статистику, чтобы рассчитать заново
         */
        withReset?: boolean;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/products/statistics/authors',
            query: {
                withReset: withReset,
            },
        });
    }

    /**
     * Выполнить расчёт детальной статистики по продуктам
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static createProductsStatistics({
        withReset,
    }: {
        /**
         * Сбросить уже имеющуюся статистику, чтобы рассчитать заново (WARNING: статистика по добавлению продуктов в избранное будет потеряна)
         */
        withReset?: boolean;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/products/statistics',
            query: {
                withReset: withReset,
            },
        });
    }

    /**
     * Получить обобщенную ежедневную статистику по всем продуктам автора
     * @returns WebResponse_IReadOnlyList_AuthorDailyStatisticDto Success
     * @throws ApiError
     */
    public static getApiVMyProductsStatistics({
        from,
        to,
    }: {
        /**
         * Начало периода, за который необходимо получить статистику
         */
        from?: string | null;
        /**
         * Конец периода, за который необходимо получить статистику
         */
        to?: string | null;
    }): CancelablePromise<WebResponse_IReadOnlyList_AuthorDailyStatisticDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products/statistics',
            query: {
                From: from,
                To: to,
            },
        });
    }

    /**
     * Получить ежедневную статистику по подпискам на продукт
     * @returns WebResponse_IReadOnlyList_ProductSubscriptionsDailyStatisticItem Success
     * @throws ApiError
     */
    public static getApiVMyProductStatisticsSubscriptions({
        productId,
        from,
        to,
    }: {
        /**
         * ИД продукта
         */
        productId: number;
        /**
         * Начало периода, за который необходимо получить статистику
         */
        from?: string | null;
        /**
         * Конец периода, за который необходимо получить статистику
         */
        to?: string | null;
    }): CancelablePromise<WebResponse_IReadOnlyList_ProductSubscriptionsDailyStatisticItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products/{productId}/statistics/subscriptions',
            path: {
                productId: productId,
            },
            query: {
                From: from,
                To: to,
            },
        });
    }

    /**
     * Получить ежедневную статистику по добавлению продукта в избранное
     * @returns WebResponse_IReadOnlyList_ProductFavoritesDto Success
     * @throws ApiError
     */
    public static getApiVMyProductStatisticsFavorites({
        productId,
        from,
        to,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
        /**
         * Начало периода, за который необходимо получить статистику
         */
        from?: string | null;
        /**
         * Конец периода, за который необходимо получить статистику
         */
        to?: string | null;
    }): CancelablePromise<WebResponse_IReadOnlyList_ProductFavoritesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products/{productId}/statistics/favorites',
            path: {
                productId: productId,
            },
            query: {
                From: from,
                To: to,
            },
        });
    }
}
