import { ForwardedRef, useCallback } from 'react';

export function useMultipleRefs<T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> {
    return useCallback((element: T | null) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(element);
            } else if (ref) {
                ref.current = element;
            }
        });
    }, refs);
}
