import styled from 'styled-components';

import { ImageProps } from '../types';
import { TRANSITION_ALL_CSS } from '../constants';

export const Image = styled.img<
    Pick<ImageProps, 'width' | 'height' | 'isUserDragActive' | 'isActiveManifestation'> & {
        loaded?: boolean;
        isErrorImg: boolean;
    }
>`
    opacity: ${({ loaded, isActiveManifestation }) => {
        if (!isActiveManifestation) {
            return '1';
        }

        return loaded ? '1' : '0';
    }};
    width: ${({ width }) => (width ? `${width}px` : 'inherit')};
    height: ${({ height }) => (height ? `${height}px` : 'inherit')};
    display: ${({ isErrorImg }) => (isErrorImg ? 'none' : 'block')};

    ${({ isUserDragActive }) =>
        isUserDragActive
            ? ''
            : `
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            `};

    ${TRANSITION_ALL_CSS};
`;
