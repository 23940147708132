import styled from 'styled-components';

export const ButtonBadgeWrapper = styled.div`
    position: relative;
    width: fit-content;
`;

export const Content = styled.div`
    display: flex;
`;

const minWidthHeight = 0;
const maxWidthHeight = 8;
const transitionDuration = 360;

export const BadgeWrapper = styled.div`
    position: absolute;
    width: ${maxWidthHeight}px;
    height: ${maxWidthHeight}px;
    border-radius: 50%;
    top: 4px;
    right: 4px;
    pointer-events: none;
`;

export const Badge = styled.div<{ isShowBadge: boolean }>`
    display: block;
    box-sizing: border-box;
    background-color: ${({ theme, isShowBadge }) => (isShowBadge ? theme.fillIn.primary.mid : 'transparent')};
    border-radius: 50%;
    ${({ isShowBadge }) => {
        const widthHeight = isShowBadge ? maxWidthHeight : minWidthHeight;
        const marginTopLeft = isShowBadge ? 0 : maxWidthHeight / 2;
        return `
            width: ${widthHeight}px;
            height: ${widthHeight}px;
            margin-top: ${marginTopLeft}px;
            margin-left: ${marginTopLeft}px;
        `;
    }};
    transition: margin-left ${transitionDuration}ms, margin-top ${transitionDuration}ms, width ${transitionDuration}ms,
        height ${transitionDuration}ms, top ${transitionDuration}ms, right ${transitionDuration}ms,
        background-color ${transitionDuration}ms;
`;
