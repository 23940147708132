import React, { ReactNode, useLayoutEffect, useMemo, useRef, useState, useCallback } from 'react';

import { useWindowResize } from 'hooks/useWindowResize';

import { ListBoxOptions } from './Options';
import { ListBoxContext } from './context';
import { ListBoxButton } from './Button';
import { ListBoxOption } from './Option';
import { ListBoxContainer } from './styled';

export type ListBoxProps<ValueType> = {
    value?: ValueType;
    onChange: (value: ValueType) => void;
    onChangeIsOpen?: (value: boolean) => void;
    children: ReactNode;
    // eslint-disable-next-line react/no-unused-prop-types
    kind?: 'inline-label-outline'; // TODO:
    // eslint-disable-next-line react/no-unused-prop-types
    size?: 'medium'; // TODO:
    noSelectedText?: string;
    className?: string;
    visualState?: 'normal' | 'emergency';
};

export function ListBox<ValueType>({
    value,
    onChange,
    kind = 'inline-label-outline',
    size = 'medium',
    children,
    noSelectedText = '\u00A0',
    visualState = 'normal',
    onChangeIsOpen,
    className: classNameProp,
}: ListBoxProps<ValueType>) {
    const [isOpened, setIsOpened] = useState(false);

    const onChangeRef = useRef(onChange);
    useLayoutEffect(() => {
        onChangeRef.current = onChange;
    });

    const resizeWindow = useCallback(() => {
        setIsOpened(false);
    }, []);

    useWindowResize(resizeWindow);

    const contextValue = useMemo(
        () => ({
            isOpened,
            setIsOpened,
            currentValue: value,
            setCurrentValue: (v: ValueType) => onChangeRef.current(v),
            noSelectedText,
            visualState,
            onChangeIsOpen,
        }),
        [isOpened, noSelectedText, value, visualState, onChangeIsOpen],
    );

    const className = useMemo(
        () => ['autotest__list-box', classNameProp].filter((cn) => cn).join(' '),
        [classNameProp],
    );

    return (
        <ListBoxContext.Provider value={contextValue}>
            <ListBoxContainer className={className}>{children}</ListBoxContainer>
        </ListBoxContext.Provider>
    );
}

ListBox.Button = ListBoxButton;
ListBox.Options = ListBoxOptions;
ListBox.Option = ListBoxOption;
