import type { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import stringify from 'fast-json-stable-stringify';

import type { AnyObject } from 'types';

import { AsyncMethod, GlobalStateForCache, PaginationConfig, ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

import { OnFulfilledCallback, RequestMoreThunk, Thunk } from './types';

export type ArrayElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : ArrType;

export function createFetchMore<
    TYPES_MAP extends object,
    NAME extends string,
    API_METHOD extends AsyncMethod,
    PAGE_DATA,
>({
    resourcesMap,
    endpointName,
    thunk,
    paginationConfig,
    onFulfilled,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: NAME;
    thunk: Thunk<API_METHOD>;
    paginationConfig: PaginationConfig<API_METHOD, PAGE_DATA>;
    onFulfilled?: OnFulfilledCallback<API_METHOD>;
}): RequestMoreThunk<API_METHOD> {
    return (
            argsOrRequestName: Parameters<API_METHOD>[0] | string = {},
        ): ThunkAction<
            Promise<Awaited<ReturnType<API_METHOD>> | undefined>,
            GlobalStateForCache,
            AnyObject,
            AnyAction
        > =>
        (dispatch, getState, extraArgument) => {
            const state = getState();
            const { named, pagination } = getState().cache.args[endpointName] ?? {};
            const args = typeof argsOrRequestName === 'string' ? named?.[argsOrRequestName] : argsOrRequestName;
            const commonArgs = paginationConfig.getCommonArgs(args);
            const existingPages = pagination[stringify(commonArgs)]?.map(({ paginationInfo }) => paginationInfo);

            const nextPage = existingPages ? paginationConfig.getNextPage(existingPages) : null;
            if (!nextPage) {
                return Promise.resolve(undefined);
            }
            const argsWithPage = { ...paginationConfig.getCommonArgs(args), ...nextPage };
            const request = state.cache.requests?.[stringify(argsWithPage)];
            if (request) {
                return Promise.resolve(
                    cacheRead({
                        kind: 'request',
                        cache: state.cache.data,
                        resourcesMap,
                        typename: endpointName,
                        id: stringify(argsWithPage),
                    })?.result as Awaited<ReturnType<API_METHOD>>,
                );
            }
            return thunk({
                params: argsWithPage,
                pass: {
                    endpoint: endpointName,
                },
            })(dispatch, getState, extraArgument).then((result) => {
                onFulfilled?.({ args, result, dispatch, getState });
                return result;
            });
        };
}
