import type { AxiosError } from 'axios';

import type { Dictionary } from 'services/dictionary';
import { isCancelRequestError } from 'services/backend-client';
import { ValidationError } from 'services/validation-error';

import { isAttachmentError } from './api';

export function getErrorMessage(dic: Dictionary, error: unknown): string | undefined {
    if (!error || isCancelRequestError(error)) {
        return undefined;
    }

    if (isAttachmentError(error)) {
        return error.message;
    }

    if (error instanceof ValidationError) {
        return error.errors[0].message;
    }

    if (typeof error === 'object' && (error as AxiosError)?.response?.status === 413) {
        return dic.word('wt_all__file_error__too_big');
    }

    return dic.word('wt_all__shared_message__unknown_error');
}
