import styled from 'styled-components';

import { reduceBreakpoints } from 'app/styled';

import { TextOneLineTooltipProps } from './index';

export const TextOneLineTooltipWrapper = styled.div<{ onClick?: TextOneLineTooltipProps['onClick'] }>`
    display: flex;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;

export const Text = styled.span<Pick<TextOneLineTooltipProps, 'typographyStyle'>>`
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${({ typographyStyle }) =>
        typographyStyle ? reduceBreakpoints(typographyStyle, (_typographyStyle) => _typographyStyle) : ''}
`;
