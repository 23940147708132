import styled from 'styled-components';

import { body17MediumFontStyle, caption12MediumFontStyle } from 'services/typography';

export const Wrapper = styled.div``;

export const Title = styled.div`
    ${body17MediumFontStyle};
    color: ${({ theme }) => theme.label.placeholder};
    margin-bottom: 20px;
    cursor: default;
`;

export const Link = styled.a`
    ${caption12MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    &:hover {
        color: ${({ theme }) => theme.label.secondaryHigh};
        cursor: pointer;
    }

    &:active {
        color: ${({ theme }) => theme.label.accentHighest};
    }
`;
