import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { Button } from 'components/button2';

import * as Styled from './styled';

type AddProductProps = {
    onClick: () => void;
};

export const AddProduct: FC<AddProductProps> = ({ onClick }) => {
    const dic = useDictionary();

    return (
        <Styled.AddProduct>
            <Styled.Header>{dic.word('wt_profile__widget_add_product_header')}</Styled.Header>
            <Styled.Text>{dic.word('wt_profile__widget_add_product_text')}</Styled.Text>
            <Button size="small" onClick={onClick}>
                {dic.word('wt_profile__widget_add_product_button')}
            </Button>
        </Styled.AddProduct>
    );
};
