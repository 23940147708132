import React, { useCallback, useState } from 'react';

import { FeedRecordObj } from 'types';
import { GalleryInitFunction } from 'components/gallery/Gallery';

import { Popup } from '../content-premium-not-granted/Popup';
import { ContentHtmlParsed } from '../content-html-parsed';
import { ContentPremiumNotGranted } from '../content-premium-not-granted';

import { useActivityContentType } from './useActivityContentType';
import { Wrapper } from './styled';

interface Props {
    obj: FeedRecordObj;
    showComments?: (force?: boolean) => void;
    onClickAnyImgInPost?: GalleryInitFunction;
    isPremiumGranted?: boolean;
    isPremium?: boolean;
    isOwner?: boolean;
    onPremiumSubscribe?: () => void;
}
export const ActivityContent = (props: Props) => {
    const {
        obj,
        onClickAnyImgInPost,
        showComments,
        isPremiumGranted = false,
        isPremium = false,
        isOwner = false,
        onPremiumSubscribe,
    } = props;

    const activityContentType = useActivityContentType(obj);

    const getContentComponentByType = () => {
        switch (activityContentType) {
            default:
                return (
                    <ContentHtmlParsed
                        obj={obj}
                        onClickAnyImgInPost={onClickAnyImgInPost}
                        showComments={showComments}
                    />
                );
        }
    };

    const [isRenderPopupWindow, setIsRenderPopupWindow] = useState(false);
    const closePopup = useCallback(() => {
        setIsRenderPopupWindow(false);
    }, []);
    const openPopup = useCallback(() => {
        setIsRenderPopupWindow(true);
    }, []);

    return (
        <>
            <Wrapper>
                {!isOwner && isPremium && !isPremiumGranted ? (
                    <ContentPremiumNotGranted obj={obj} onPremiumSubscribe={onPremiumSubscribe} openPopup={openPopup} />
                ) : (
                    getContentComponentByType()
                )}
            </Wrapper>
            {isRenderPopupWindow && <Popup closePopup={closePopup} />}
        </>
    );
};
