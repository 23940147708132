import React, { useCallback } from 'react';

import { FinamProductItem } from 'types/finam-product';
import { isStrategy, isUserProduct, isUsSignal } from 'services/finam-product';

import { UsSignalCard } from './us-signal';
import { StrategyProductCard } from './strategy';
import { ProductCardFinamPropsType } from './types';
import { UserProductCard } from './user-product';

export const ProductCardFinam = React.memo((props: ProductCardFinamPropsType) => {
    const { className: classNameProp, item, onClick: onClickProp, isDisable, ...restProps } = props;
    const className = [classNameProp, 'autotest__product-card'].filter((i) => i).join(' ');

    const onClickItem = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (item) {
                const onClickItemHandler =
                    (event: React.MouseEvent<HTMLElement>) => (productItem: FinamProductItem) => {
                        onClickProp?.(event, productItem);
                    };

                onClickItemHandler(e)(item);
            }
        },
        [item, onClickProp],
    );
    const onClick = onClickProp && item && !isDisable ? onClickItem : undefined;

    const sharedProps = { className, isDisable, item, onClick, ...restProps };

    if (item && isStrategy(item)) {
        return <StrategyProductCard {...sharedProps} />;
    }

    if (item && isUserProduct(item)) {
        return <UserProductCard {...sharedProps} />;
    }

    if (item && isUsSignal(item)) {
        return <UsSignalCard {...sharedProps} />;
    }

    return null;
});
