import type { Step2FormValues } from './types';

export const defaultValues: Step2FormValues = {
    accountId: '',
    minSum: undefined,
    riskLevel: '',
    recommendedHoldingPeriod: '',
    price: undefined,
};

export const minSumLowBorder = 1000;
export const minSumHighBorder = 100000;

export const MIN_AMOUNT = 0.5;
export const MAX_AMOUNT = 100000;
