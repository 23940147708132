import produce from 'immer';

import { CacheData } from '../types';

import { memoizationCache } from './memoization';

export function cacheDelete({
    cache,
    kind,
    typename,
    id,
}: {
    cache: CacheData;
    kind: 'request' | 'resource';
    typename: string;
    id: string;
}) {
    return produce(cache, (draft) => {
        memoizationCache.invalidate(`${typename}:${id}`);
        if (kind === 'request') {
            delete draft.requests[typename]?.[id];
        }
        if (kind === 'resource') {
            delete draft.resources[typename]?.[id];
        }
    });
}
