import { useTheme } from 'styled-components';

import { PublishStatusRecord } from 'types';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { isLimexApiError } from 'services/guards/isLimexApiError';
import { recordStoreIndex } from 'services/utils/map-records-by-id';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { TrashIcon } from 'components/icons2/TrashIcon';

import { DeleteFeedRecord, RestoreFeedRecord } from '../actions';

export const useDeleteRecord = (
    instanceId: string,
    record: PublishStatusRecord,
    deletedIds: string[],
    deleteFeedRecord?: DeleteFeedRecord,
    restoreFeedRecord?: RestoreFeedRecord,
) => {
    const csrf = useCSRFToken();

    const theme = useTheme();

    const isDeleted = deletedIds.includes(recordStoreIndex(record.id));

    const onDelete = () => {
        if (record.obj?.id && deleteFeedRecord) {
            deleteFeedRecord(record, csrf, instanceId)
                .then(() => {
                    notify.success({
                        icon: {
                            fill: theme.staticColors.white,
                            component: TrashIcon,
                        },
                        text: Dic.word('wt_feed__delete_post__success_message'),
                    });
                })
                .catch((error) => {
                    if (isLimexApiError(error)) {
                        const { message } = error.body.violations?.[0] || {};
                        notify.error(message || Dic.word('wt_feed__error_message__delete__post'));
                    } else {
                        throw error;
                    }
                });
        }
    };

    const onRestore = () => {
        if (record.obj?.id && restoreFeedRecord) {
            restoreFeedRecord(record, instanceId);
        }
    };

    return { isDeleted, onDelete, onRestore };
};
