import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CreditCardIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.16807 4.2H17.8323C18.365 4.19999 18.8126 4.19998 19.1789 4.22991C19.5619 4.2612 19.9259 4.32915 20.2714 4.50518C20.7982 4.77363 21.2266 5.20197 21.495 5.72883C21.671 6.07431 21.739 6.43828 21.7703 6.82126C21.8002 7.18754 21.8002 7.63513 21.8002 8.1678V15.8322C21.8002 16.3649 21.8002 16.8125 21.7703 17.1787C21.739 17.5617 21.671 17.9257 21.495 18.2712C21.2266 18.798 20.7982 19.2264 20.2714 19.4948C19.9259 19.6709 19.5619 19.7388 19.1789 19.7701C18.8126 19.8 18.365 19.8 17.8323 19.8H6.16809C5.63538 19.8 5.18776 19.8 4.82145 19.7701C4.43848 19.7388 4.07451 19.6709 3.72902 19.4948C3.20217 19.2264 2.77382 18.798 2.50538 18.2712C2.32934 17.9257 2.26139 17.5617 2.2301 17.1787C2.20017 16.8124 2.20018 16.3648 2.2002 15.8321V8.16787C2.20018 7.63518 2.20017 7.18756 2.2301 6.82126C2.26139 6.43828 2.32934 6.07431 2.50538 5.72883C2.77382 5.20197 3.20217 4.77363 3.72902 4.50518C4.07451 4.32915 4.43848 4.2612 4.82145 4.22991C5.18775 4.19998 5.63537 4.19999 6.16807 4.2ZM4.95175 5.82459C4.66771 5.8478 4.53774 5.88884 4.45541 5.93079C4.22961 6.04584 4.04604 6.22942 3.93099 6.45521C3.88904 6.53755 3.84799 6.66752 3.82479 6.95155C3.80082 7.24492 3.8002 7.62675 3.8002 8.2H20.2002C20.2002 7.62675 20.1996 7.24492 20.1756 6.95155C20.1524 6.66752 20.1114 6.53755 20.0694 6.45521C19.9544 6.22942 19.7708 6.04584 19.545 5.93079C19.4626 5.88884 19.3327 5.8478 19.0486 5.82459C18.7553 5.80062 18.3734 5.8 17.8002 5.8H6.2002C5.62694 5.8 5.24512 5.80062 4.95175 5.82459ZM20.2002 9.8H3.8002V15.8C3.8002 16.3733 3.80082 16.7551 3.82479 17.0485C3.84799 17.3325 3.88904 17.4625 3.93099 17.5448C4.04604 17.7706 4.22961 17.9542 4.45541 18.0692C4.53774 18.1112 4.66771 18.1522 4.95175 18.1754C5.24512 18.1994 5.62694 18.2 6.2002 18.2H17.8002C18.3734 18.2 18.7553 18.1994 19.0486 18.1754C19.3327 18.1522 19.4626 18.1112 19.545 18.0692C19.7708 17.9542 19.9544 17.7706 20.0694 17.5448C20.1114 17.4625 20.1524 17.3325 20.1756 17.0485C20.1996 16.7551 20.2002 16.3733 20.2002 15.8V9.8Z"
        />
    </IconSvg>
);
