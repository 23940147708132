import React from 'react';

import { ValidationError } from 'services/validation-error';
import Dic from 'services/dictionary';
import { FormFieldProps } from 'components/form';
import { RoundButton } from 'components/round-button';
import { CameraIcon } from 'components/icons2/CameraIcon';
import { TimesIcon } from 'components/icons2/TimesIcon';

import { useImgField } from '../hooks/useImgField';

import { Wrapper, Actions, Buttons, Tip } from './styled/CoverInput.styled';

type Props = {
    field: FormFieldProps<string>;
    onValidation: {
        applyError: (validationError: ValidationError) => void;
        removeError: (fieldName: string) => void;
    };
    hasError: boolean;
};

const fieldName = 'cover';
export const CoverInput = (props: Props) => {
    const { field, onValidation, hasError } = props;
    const { isLoading, handleChange } = useImgField(fieldName, field, {
        minHeight: 300,
        minWidth: 600,
        maxSize: 20,
        types: ['image/jpeg', 'image/jpg', 'image/png'],
        onValidation,
    });

    const handleSkipImg = () => {
        onValidation.removeError(fieldName);
        field.onRawChange('');
    };

    return (
        <Wrapper bgSrc={field.value} isLoading={isLoading} hasError={hasError}>
            <Actions>
                <Buttons>
                    <RoundButton kind="staticColor" onClick={handleChange} isProcessing={isLoading}>
                        <CameraIcon />
                    </RoundButton>
                    {!isLoading && field.value && (
                        <RoundButton kind="staticColor" onClick={handleSkipImg}>
                            <TimesIcon />
                        </RoundButton>
                    )}
                </Buttons>
                {!isLoading && !field.value && <Tip>{Dic.word('wt_all__widget_user_form__cover_input_tip')}</Tip>}
            </Actions>
        </Wrapper>
    );
};
