import { injectReducer } from 'app/store2';

import { TagsState } from './types';
import reducer from './reducer';

export const injectTagsReducer = (rawInitialState: TagsState) => {
    injectReducer('widgets.tags', reducer(rawInitialState));
};

export { TagsContainer } from './container';
export type { TagsState };
