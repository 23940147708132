import React from 'react';

import { AnyIcon } from 'components/icons2';

import { MenuItemProps, RightGroupProps } from '../types';
import * as Styled from '../styled/RightGroup.styled';

export type { MenuItemProps };

const DEFAULT_ICON_SIZE = 24;

export const RightGroup = (props: RightGroupProps) => {
    const { icon, text, disabled } = props;

    return (
        <Styled.RightGroupWrapper>
            {text && <Styled.RightGroupText disabled={disabled}>{text}</Styled.RightGroupText>}
            {icon?.component && (
                <Styled.RightGroupIconWrapper icon={icon} disabled={disabled}>
                    <AnyIcon {...icon} size={icon?.size || DEFAULT_ICON_SIZE} />
                </Styled.RightGroupIconWrapper>
            )}
        </Styled.RightGroupWrapper>
    );
};
