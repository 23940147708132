import React from 'react';

import * as Styled from './styled';

type CardSummaryTextProps = {
    children: string;
};

export const CardSummaryText = ({ children }: CardSummaryTextProps) => (
    <Styled.CardSummaryText className="autotest__product-card_summary">{children}</Styled.CardSummaryText>
);
