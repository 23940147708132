import React, { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import { useTrackFocus } from 'hooks/useTrackFocus';
import { useMultipleRefs } from 'hooks/useMultipleRefs';
import { TextArea } from 'components/text-area';

import { rulesComparer } from '../utils/rulesComparer';

import { FormTextAreaProps } from './types';

export const FormTextArea = memo(
    forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
        ({ name, rules, maxLength, label, hideErrors = false, ...textAreaProps }, forwardedRef) => {
            const {
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
            } = useController<{ [key: string]: string }>({ name, rules });

            const [focusRef, isFocus] = useTrackFocus<HTMLTextAreaElement>();
            const refFunction = useMultipleRefs(forwardedRef, ref, focusRef);

            return (
                <TextArea
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={refFunction}
                    error={hideErrors ? undefined : error?.message}
                    errorHighlight={!hideErrors && error !== undefined}
                    label={
                        isFocus && label && maxLength !== undefined ? `${label} (${value.length}/${maxLength})` : label
                    }
                    {...textAreaProps}
                />
            );
        },
    ),
    rulesComparer,
);
