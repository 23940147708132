export const initOpts = process.env.GLOBAL_AUTH_INIT_OPTS;

export const LOCAL_STORAGE_JUST_REG_KEY = 'limex__just_registered_user';

export const isJustRegistered = () => {
    //  сообщает была ли только что регистрация (до перезагрузки)
    const userJustRegistered = localStorage.getItem(LOCAL_STORAGE_JUST_REG_KEY);
    return userJustRegistered === 'true';
};

export const GUEST_PROVIDER = ['DEVICE_ID', 'DEVICE_ID_LIMEX', 'LIMEXAPP_ANONYMOUS_ID'];
