import React from 'react';

import * as Styled from './styled/LinksBlocks';
import { BlockLayout } from './BlockLayout';

export type LinkBlockItemType = {
    key: string;
    title: string;
    items: Array<{
        label: string;
        href: string;
        className?: string;
        isRender?: boolean;
    }>;
};

export type LinksBlocksDataType = Array<LinkBlockItemType>;

export type LinksBlocksPropsType = {
    data: LinksBlocksDataType;
};

export const LinksBlocks = (props: LinksBlocksPropsType) => {
    const { data } = props;

    return (
        <>
            {data?.length > 0 &&
                data.map((blockItemData) => {
                    const { title, items, key } = blockItemData;

                    return (
                        items?.length > 0 &&
                        title && (
                            <BlockLayout title={title} key={key}>
                                <Styled.LinksWrapper>
                                    {items.map((linkProps) => {
                                        const { label, href, className, isRender = true } = linkProps;

                                        return (
                                            isRender && (
                                                <Styled.Link
                                                    key={JSON.stringify(linkProps)}
                                                    href={href}
                                                    className={['autotest__footer__source-link', className]
                                                        .filter((i) => i)
                                                        .join(' ')}
                                                >
                                                    {label}
                                                </Styled.Link>
                                            )
                                        );
                                    })}
                                </Styled.LinksWrapper>
                            </BlockLayout>
                        )
                    );
                })}
        </>
    );
};
