import { useState } from 'react';
import {
    autoUpdate,
    offset,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useTransitionStyles,
} from '@floating-ui/react';

import { usePopupRoot } from 'context/popup-root';

export const useFloatingParams = () => {
    const root = usePopupRoot() ?? document.body;

    const [open, onOpenChange] = useState(false);

    const { refs, floatingStyles, context } = useFloating<Element>({
        placement: 'top-end',
        strategy: 'fixed',
        middleware: [offset(8)],
        whileElementsMounted: autoUpdate,
        open,
        onOpenChange,
    });

    const click = useClick(context, { toggle: true });
    const dismiss = useDismiss(context, { outsidePress: false });
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);
    const { isMounted, styles } = useTransitionStyles(context, {
        duration: 360,
    });
    return {
        root,
        refs,
        floatingStyles,
        getReferenceProps,
        getFloatingProps,
        isMounted,
        styles,
    };
};
