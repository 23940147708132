import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import produce from 'immer';

import { FeedOwnerType, FeedRecordType, Person, PostType, SelectedTagsIds } from 'types';
import { FeedContextValue } from 'context/feed';
import Dic from 'services/dictionary';
import FeedContainer from 'widgets/feed';
import { NoData, NoDataPropsType } from 'widgets/feed/no-data';
import { FeedContainerMapStateOwnPropsType, FeedProps } from 'widgets/feed/container';
import { useSearchTagIds } from 'widgets/feed/feed/hooks/useSearchTagIds';
import { ANIMATION_DURATION as ANIMATION_DURATION_NO_DATA } from 'widgets/feed/no-data/styled';
import CatLiesSvg from 'components/assets/CatLies.svg';

import { useProfilePageContext } from '../../context';
import { TabIds } from '../tabs';
import { TagsSliderContainer } from '../tags-slider';
import { ProductsContainer } from '../products';

import { FeedWrapper } from './styled';
import CatStandingSvg from './CatStanding.svg';
import { SocialTabsContainer } from './SocialTabs';

type Props = Pick<FeedProps, 'renderNoData'> & {
    feedContext: FeedContextValue;
    currentTab: TabIds;
    user?: Person;
    pinnedRecordShowMore?: boolean;
};

export const Feed = (props: Props) => {
    const { feedContext, currentTab, pinnedRecordShowMore } = props;

    const isFirstLoadedNoData = useRef(true);

    const [profilePageContext, setContextSlice] = useProfilePageContext();

    const { isOwner, isAddFeedStatusActive } = profilePageContext;

    const handleActiveChange = useCallback(
        (isActive: boolean) => {
            setContextSlice({ isAddFeedStatusActive: isActive || undefined });
        },
        [setContextSlice],
    );

    const [tags, setTags] = useSearchTagIds();
    const [selectedIds, setSelectedIds] = useState<Record<TabIds, SelectedTagsIds>>({
        feed: currentTab === 'feed' ? tags : [],
        premiumPosts: currentTab === 'premiumPosts' ? tags : [],
        likesAndComments: [],
        authorsProducts: [],
    });

    const handleSetSelectedIds = useCallback(
        (newSelectedIds: SelectedTagsIds, tabId: TabIds) => {
            setSelectedIds({ ...selectedIds, [tabId]: newSelectedIds });
        },
        [setSelectedIds, selectedIds],
    );

    useEffect(() => {
        setTags(selectedIds[currentTab]);
    }, [currentTab, setTags, selectedIds]);

    const noDataProps = useMemo((): NoDataPropsType | null => {
        switch (currentTab) {
            case 'feed':
                return {
                    cover: <CatLiesSvg />,
                    title: isOwner
                        ? Dic.word('wt_profile__widget_feed__no_data_for_owner_title')
                        : Dic.word('wt_profile__widget_no_products_header'),
                    secondaryText: isOwner
                        ? Dic.word('wt_profile__widget_feed__no_data_for_owner_secondary_title')
                        : Dic.word('wt_profile__widget_feed__no_data_feed_secondary_text'),
                    button: isOwner
                        ? {
                              kind: 'primary',
                              children: Dic.word('wt_profile__widget_feed__no_data_for_owner_add_new_post'),
                              onClick: () => handleActiveChange(true),
                          }
                        : undefined,
                };
            // case TabIds.subscriptions:
            //     return ({
            //         cover: (<CatStanding />),
            //         title: Dic.word('wt_profile__widget_feed__no_data_subscriptions_title'),
            //         secondaryText: Dic.word('wt_profile__widget_feed__no_data_subscriptions_secondary_text'),
            //     });
            case 'premiumPosts':
                return {
                    cover: <CatLiesSvg />,
                    title: Dic.word('wt_profile__widget_feed__no_data_premium_title'),
                    secondaryText: Dic.word('wt_profile__widget_feed__no_data_premium_secondary_text'),
                    button: {
                        kind: 'primary',
                        onClick: () => handleActiveChange(true),
                        children: Dic.word('wt_profile__widget_feed__no_data_premium_add_new_post'),
                    },
                };
            case 'likesAndComments':
                return {
                    cover: <CatStandingSvg />,
                    title: Dic.word('wt_profile__widget_feed__no_data_subscriptions_title'),
                    secondaryText: isOwner
                        ? Dic.word('wt_profile__widget_feed__no_data_subscriptions_for_owner_secondary_text')
                        : Dic.word('wt_profile__widget_feed__no_data_subscriptions_secondary_text'),
                };
            default:
                return null;
        }
    }, [currentTab, isOwner, handleActiveChange]);

    const feedContainerProps: Pick<
        FeedContainerMapStateOwnPropsType,
        | 'showAddFeedStatus'
        | 'showTickerNews'
        | 'showRecommendedUsers'
        | 'isAddFeedStatusActive'
        | 'onAddFeedStatusActiveChange'
        | 'renderNoData'
        | 'showAdds'
        | 'pinnedRecordShowMore'
    > &
        Pick<FeedContainerMapStateOwnPropsType, 'filterRecords'> = {
        showAddFeedStatus: isOwner,
        showAdds: false,
        showTickerNews: false,
        showRecommendedUsers: true,
        isAddFeedStatusActive,
        onAddFeedStatusActiveChange: handleActiveChange,
        pinnedRecordShowMore,
        renderNoData: (propsRenderNoData) => {
            const { records = [], boundary, isLoading = false } = propsRenderNoData;

            const isEmpty = records.length === 0;
            const isOnlyRecomends = records.length === 1 && records[0].type === FeedRecordType.RECOMMENDATIONS;
            const hasMorePages = typeof boundary === 'string' && boundary !== '';
            const firstLoading = boundary === undefined || boundary === '';

            if (
                firstLoading ||
                (!isEmpty && !isOnlyRecomends) ||
                isLoading ||
                hasMorePages ||
                (isOwner && isAddFeedStatusActive)
            ) {
                return null;
            }

            const timeoutDelay = ANIMATION_DURATION_NO_DATA;

            setTimeout(() => {
                // для того, чтобы после первого рендера не включать анимацию проявления
                isFirstLoadedNoData.current = false;
            }, timeoutDelay);

            return (
                <NoData
                    cover={noDataProps?.cover}
                    title={noDataProps?.title}
                    secondaryText={noDataProps?.secondaryText}
                    button={noDataProps?.button}
                    withManifestationAnimate={isFirstLoadedNoData.current}
                />
            );
        },
    };

    const getFeedContextWithCustomOwnerType = (ownerType: FeedOwnerType): FeedContextValue =>
        produce(feedContext, (draft) => {
            draft.owner.type = ownerType;
        });

    if (!feedContext) {
        return null;
    }

    return (
        <>
            {currentTab === 'feed' && (
                <>
                    <TagsSliderContainer
                        tabId={currentTab}
                        selectedIds={selectedIds[currentTab]}
                        setSelectedIds={handleSetSelectedIds}
                    />
                    <FeedWrapper>
                        <FeedContainer
                            feedContext={getFeedContextWithCustomOwnerType(FeedOwnerType.PERSON)}
                            instanceId="feed"
                            {...feedContainerProps}
                            addFeedStatusPostType={PostType.ORDINARY}
                        />
                    </FeedWrapper>
                </>
            )}
            {currentTab === 'premiumPosts' && (
                <>
                    <TagsSliderContainer
                        tabId={currentTab}
                        selectedIds={selectedIds[currentTab]}
                        setSelectedIds={handleSetSelectedIds}
                    />
                    <FeedWrapper>
                        <FeedContainer
                            feedContext={getFeedContextWithCustomOwnerType(FeedOwnerType.PREMIUM)}
                            instanceId="premiumPosts"
                            addFeedStatusStub={Dic.word('wt_feed__timeline_status__placeholder_premium')}
                            addFeedStatusPostType={PostType.PREMIUM}
                            {...feedContainerProps}
                        />
                    </FeedWrapper>
                </>
            )}
            {currentTab === 'likesAndComments' ? (
                <>
                    <SocialTabsContainer />
                    <FeedContainer
                        feedContext={getFeedContextWithCustomOwnerType(FeedOwnerType.PERSON)}
                        instanceId="socialActivity"
                        {...feedContainerProps}
                        showAddFeedStatus={false}
                    />
                </>
            ) : null}
            {currentTab === 'authorsProducts' && <ProductsContainer />}
        </>
    );
};
