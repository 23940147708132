import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import pull from 'lodash/pull';

import { FinamProductItem, KratosUser } from 'types/finam-product';
import {
    ApiKeysService,
    CatalogService,
    DocuSignService,
    FavoritesService,
    PaymentsService,
    MyReviewsService,
    MyStrategiesService,
    ProductsService,
    ProductStatisticsService,
    ProfileService,
    ReviewsService,
    StatsService,
    SubscriptionsService,
    UsConnectedAccountService,
    UserProductsService,
    UsLimexCopyService,
    UsSignalMeService,
    UsSubscriptionsService,
    UsUserProductsService,
    AiPortfoliosService,
    CreditsService,
} from 'services/api-services/generated_marketplace';
import {
    Account,
    AiPortfolio,
    BackofficeAccount,
    BrokerageAccount,
    MarketplaceApiPaginatedMethod,
    MarketplaceApiReturn,
    Payment,
    ProductPayment,
    Review,
    SignalData,
    Stock,
    StrategySubscriptionInfo,
    SubscriptionItemData,
    UserCreditsEntity,
} from 'services/mp-share-resources/types';
import { sendGAv4 } from 'services/analytics-outside/google-analytics';
import {
    FeedbackService,
    InstrumentService,
    StatisticsService,
    UserService,
} from 'services/api-services/generated_limex';
import { registerLimexCopyUser } from 'services/mp-share-resources/utils';
import { notEmpty } from 'services/utils/not-empty';
import { DefaultService, HistoryEvent } from 'services/api-services/generated_chat';
import notify from 'services/notify';
import { enrichJWT } from 'services/global-auth-provider';
import { createDemoAccount } from 'services/global-auth-provider/operations/provide-tx-ga';

import { AsyncMethod, ComputedFieldsConfig, PaginationConfig, ResourceListener } from './types';
import { ApiBuilder } from './apiBuilder';
import { OnFulfilledCallback } from './actions/types';

export function createApi() {
    return (
        new ApiBuilder()
            /** USERS */

            .addResource({ name: 'KratosUser', getId: ({ kratosId }: KratosUser) => kratosId })
            .addQuery({
                endpointName: 'kratosUsers',
                apiMethod: async (args: Parameters<typeof UserService.createMarketplaceGetUsersInfoByKratosIds>[0]) =>
                    Object.entries(await UserService.createMarketplaceGetUsersInfoByKratosIds(args)).map(
                        ([kratosId, user]) => ({ ...user, kratosId }),
                    ),
                dataPath: '',
                nestedResources: { result: 'KratosUser' },
            })

            /** PRODUCTS */

            .addResource({
                name: 'FinamProductItem',
                getId: (item: FinamProductItem) => item.id,
                ...createAuthorConfig(),
            })
            .addQuery({
                endpointName: 'products',
                apiMethod: (args: Parameters<typeof CatalogService.getProductsV2>[0]) =>
                    CatalogService.getProductsV2(args) as MarketplaceApiReturn<FinamProductItem[]>,
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'UsStrategies',
                endpointName: 'products',
            })
            .registerNamedRequest({
                name: 'UsSignals',
                endpointName: 'products',
            })
            .registerNamedRequest({
                name: 'UsUserProducts',
                endpointName: 'products',
            })
            .addQuery({
                endpointName: 'catalogProduct',
                apiMethod: (args: Parameters<typeof CatalogService.getProduct>[0]) =>
                    CatalogService.getProduct(args) as MarketplaceApiReturn<FinamProductItem>,
                dataPath: 'data',
                nestedResources: {
                    'result.data': 'FinamProductItem',
                },
            })

            /** FAVORITES */

            .addQuery({
                endpointName: 'favorites',
                apiMethod: (args: Parameters<typeof FavoritesService.getFavorites>[0]) =>
                    FavoritesService.getFavorites(args) as MarketplaceApiReturn<FinamProductItem[]>,
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'allFavorites',
                endpointName: 'favorites',
            })
            .addMutation({
                mutationName: 'addProductToFavorites',
                apiMethod: FavoritesService.addProductToFavorites,
                onRequest: ({ args, dispatch, getState, mutationId }) => {
                    const product = api.selectFinamProductItem(getState(), args.productId);
                    dispatch(
                        api.updateFavorites(
                            ({ productType }) => !productType || productType === product?.productType,
                            (draft) => {
                                draft.result.data?.push(product);
                            },
                            mutationId,
                        ),
                    );
                },
                onFulfilled: () => {
                    sendGAv4({ category: 'shop', action: 'add_to_wishlist', label: 'limex_shop_add_to_wishlist' });
                },
                onError: () => {
                    notify.error('server_error');
                },
            })
            .addMutation({
                mutationName: 'removeProductFromFavorites',
                apiMethod: FavoritesService.removeProductFromFavorites,
                onRequest: ({ args, dispatch, mutationId }) => {
                    dispatch(
                        api.updateFavorites(
                            () => true,
                            (draft) => {
                                remove(draft.result.data ?? [], (item) => item.id === args.productId);
                            },
                            mutationId,
                        ),
                    );
                },
                onError: () => {
                    notify.error('server_error');
                },
            })

            /** MY PRODUCTS */

            .addQuery({
                endpointName: 'myProducts',
                apiMethod: async (args: Parameters<typeof ProductsService.getMyProductsV2>[0]) => {
                    const result = await ProductsService.getMyProductsV2(args);
                    return {
                        paging: result.paging,
                        error: result.error,
                        data: result.data?.map(({ product, ...restData }) => ({
                            ...product,
                            ...restData,
                        })) as FinamProductItem[],
                    };
                },
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'allMyProducts',
                endpointName: 'myProducts',
            })
            .registerNamedRequest({
                name: 'publishedMyProducts',
                endpointName: 'myProducts',
            })
            .registerNamedRequest({
                name: 'myProductsByType',
                endpointName: 'myProducts',
            })
            .addQuery({
                endpointName: 'myProduct',
                apiMethod: async (args: Parameters<typeof ProductsService.getApiVMyProduct>[0]) => {
                    const result = await ProductsService.getApiVMyProduct(args);
                    if (result.data) {
                        const { product, productStatus, archived, archivedAt } = result.data ?? {};
                        return {
                            data: {
                                ...product,
                                productStatus,
                                archived,
                                archivedAt,
                            } as FinamProductItem,
                        };
                    }
                    return { data: undefined };
                },
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
            })
            .addQuery({
                endpointName: 'myProductRating',
                apiMethod: async (args: Parameters<typeof ProductsService.getApiVMyProductRatingsCounters>[0]) => {
                    const result = await ProductsService.getApiVMyProductRatingsCounters(args);
                    if (result.data) {
                        const { totalReviewsAmount, grade5, grade4, grade3, grade2, grade1 } = result.data ?? {};
                        return {
                            data: {
                                id: args.productId,
                                totalReviewsAmount,
                                grade5: grade5 || 0,
                                grade4: grade4 || 0,
                                grade3: grade3 || 0,
                                grade2: grade2 || 0,
                                grade1: grade1 || 0,
                            },
                        };
                    }
                    return { data: undefined };
                },
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
            })
            .addQuery({
                endpointName: 'myProductFavoritesStats',
                apiMethod: async (
                    args: Parameters<typeof ProductStatisticsService.getApiVMyProductStatisticsFavorites>[0],
                ) => {
                    const result = await ProductStatisticsService.getApiVMyProductStatisticsFavorites(args);
                    if (result.data) {
                        const favoritesStats = result.data ?? {};
                        return {
                            data: {
                                id: args.productId,
                                favoritesStats,
                            },
                        };
                    }
                    return { data: undefined };
                },
                dataPath: 'data',
                nestedResources: { 'result.data': 'FinamProductItem' },
            })

            /** MY PRODUCTS CREATION */

            .addMutation({
                mutationName: 'createProduct',
                apiMethod: UsUserProductsService.createProduct,
                nestedResources: { 'result.data': 'FinamProductItem' },
                onFulfilled: ({ result, dispatch, getState }) => {
                    const product = api.selectFinamProductItem(getState(), result.data?.id);
                    dispatch(
                        api.updateMyProducts(
                            ({ productTypes }) => !productTypes || productTypes.includes(product?.productType),
                            (draft) => {
                                draft.result.data?.push({ productStatus: 'Created', ...product });
                            },
                        ),
                    );
                },
            })
            .registerNamedRequest({
                name: 'productWizardCreateProduct',
                endpointName: 'createProduct',
            })
            .addMutation({
                mutationName: 'createCopyTradingStrategy',
                apiMethod: async (args: Parameters<typeof UsLimexCopyService.createCopyTradingStrategy>[0]) => {
                    await registerLimexCopyUser();
                    return UsLimexCopyService.createCopyTradingStrategy(args);
                },
                nestedResources: { 'result.data': 'FinamProductItem' },
                onFulfilled: ({ result, dispatch, getState }) => {
                    const product = api.selectFinamProductItem(getState(), result.data?.id);
                    dispatch(
                        api.updateMyProducts(
                            ({ productTypes }) => !productTypes || productTypes.includes(product?.productType),
                            (draft) => {
                                draft.result.data?.push({ productStatus: 'Created', ...product });
                            },
                        ),
                    );
                },
            })
            .registerNamedRequest({
                name: 'productWizardCreateCopyTradingStrategy',
                endpointName: 'createCopyTradingStrategy',
            })
            .addMutation({
                mutationName: 'createSignalStrategy',
                apiMethod: UsSignalMeService.createSignalStrategy,
                nestedResources: { 'result.data': 'FinamProductItem' },
                onFulfilled: ({ result, dispatch, getState }) => {
                    const product = api.selectFinamProductItem(getState(), result.data?.id);
                    dispatch(
                        api.updateMyProducts(
                            ({ productTypes }) => !productTypes || productTypes.includes(product?.productType),
                            (draft) => {
                                draft.result.data?.push({ productStatus: 'Created', ...product });
                            },
                        ),
                    );
                },
            })
            .registerNamedRequest({
                name: 'productWizardCreateSignalStrategy',
                endpointName: 'createSignalStrategy',
            })
            .addResource({ name: 'BackofficeAccount', getId: ({ accountId }: BackofficeAccount) => accountId })
            .addResource({ name: 'SubscriptionAccountCandidate', getId: ({ id }: BrokerageAccount) => id })
            .addQuery({
                endpointName: 'accountsForNewStrategy',
                apiMethod: MyStrategiesService.getAccountsForNewStrategy,
                dataPath: 'data.accounts',
                nestedResources: { 'result.data.accounts': 'BackofficeAccount' },
            })
            .addQuery({
                endpointName: 'checkProfanity',
                apiMethod: UserProductsService.checkProfanity,
                dataPath: 'data.detectedProfanity',
            })
            .addQuery({
                endpointName: 'agreements',
                apiMethod: DocuSignService.getAgreements,
                dataPath: 'data',
            })
            .addQuery({
                endpointName: 'autofollowAccounts',
                apiMethod: async (
                    args: Parameters<typeof UsSubscriptionsService.getAccountsForAutofollow>[0] & {
                        firstName: string;
                        lastName: string;
                    },
                ) => {
                    const { firstName, lastName } = args;
                    let hasLimeAuthorization = await enrichJWT();
                    if (!hasLimeAuthorization) {
                        hasLimeAuthorization = Boolean(await createDemoAccount({ firstName, lastName }));
                    }
                    if (hasLimeAuthorization) {
                        await registerLimexCopyUser();
                    }
                    return UsSubscriptionsService.getAccountsForAutofollow(args);
                },
                dataPath: 'data',
                nestedResources: { 'result.data': 'SubscriptionAccountCandidate' },
            })
            .registerNamedRequest({
                name: 'selectedStrategyAutofollowAccounts',
                endpointName: 'autofollowAccounts',
            })
            .addMutation({
                mutationName: 'createAgreement',
                apiMethod: DocuSignService.createAgreement,
            })
            .addMutation({
                mutationName: 'createSubscriberAgreement',
                apiMethod: DocuSignService.createSubscriberAgreement,
            })
            .addMutation({
                mutationName: 'updateAgreementStatus',
                apiMethod: DocuSignService.updateAgreementStatus,
            })

            /** MY PRODUCTS EDITING * */

            .addMutation({
                mutationName: 'updateSignalStrategy',
                apiMethod: UsSignalMeService.updateApiVMyUsSignalMeStrategy,
                nestedResources: { 'result.data': 'FinamProductItem' },
            })
            .registerNamedRequest({
                name: 'updateMySpaceSignalStrategy',
                endpointName: 'updateSignalStrategy',
            })
            .addMutation({
                mutationName: 'updateCopyTradingStrategy',
                apiMethod: UsLimexCopyService.updateApiVMyLimexCopyStrategy,
                nestedResources: { 'result.data': 'FinamProductItem' },
            })
            .registerNamedRequest({
                name: 'updateMySpaceCopyTradingStrategy',
                endpointName: 'updateCopyTradingStrategy',
            })
            .addMutation({
                mutationName: 'updateProduct',
                apiMethod: UsUserProductsService.updateProduct,
                nestedResources: { 'result.data': 'FinamProductItem' },
            })
            .registerNamedRequest({
                name: 'updateMySpaceProduct',
                endpointName: 'updateProduct',
            })

            /** MY PRODUCTS DELETION */

            .addMutation({
                mutationName: 'deleteUserProduct',
                apiMethod: UserProductsService.deleteProduct,
                onFulfilled: ({ dispatch, args }) => {
                    dispatch(api.deleteFinamProductItem(args.id));
                },
            })
            .registerNamedRequest({
                name: 'deleteMySpaceUserProduct',
                endpointName: 'deleteUserProduct',
            })
            .addMutation({
                mutationName: 'deleteSignalStrategy',
                apiMethod: UsSignalMeService.deleteSignalStrategy,
                onFulfilled: ({ dispatch, args }) => {
                    dispatch(api.deleteFinamProductItem(args.productId));
                },
            })
            .registerNamedRequest({
                name: 'deleteMySpaceSignalStrategy',
                endpointName: 'deleteSignalStrategy',
            })
            .addMutation({
                mutationName: 'deleteLimexCopyStrategy',
                apiMethod: UsLimexCopyService.deleteApiVMyLimexCopyStrategy,
                onFulfilled: ({ dispatch, args }) => {
                    dispatch(api.deleteFinamProductItem(args.productId));
                },
            })
            .registerNamedRequest({
                name: 'deleteMySpaceLimexCopyStrategy',
                endpointName: 'deleteLimexCopyStrategy',
            })

            /** SUBSCRIPTIONS */

            .addResource({
                name: 'StrategySubscriptionInfo',
                getId: (item: StrategySubscriptionInfo) => item.strategySubscriptionId,
            })
            .addResource({
                name: 'SubscriptionItemData',
                getId: (item: SubscriptionItemData) => item.id,
                nestedResources: {
                    product: 'FinamProductItem',
                    strategySubscriptionInfo: 'StrategySubscriptionInfo',
                },
            })
            .addQuery({
                endpointName: 'subscriptions',
                apiMethod: (args: Parameters<typeof SubscriptionsService.getSubscriptionsV2>[0]) =>
                    SubscriptionsService.getSubscriptionsV2(args) as MarketplaceApiReturn<SubscriptionItemData[]>,
                dataPath: 'data',
                nestedResources: { 'result.data': 'SubscriptionItemData' },
                pagination: createMarketplacePagination(true),
            })
            .registerNamedRequest({
                name: 'UsStrategySubscriptions',
                endpointName: 'subscriptions',
            })
            .registerNamedRequest({
                name: 'UsSignalSubscriptions',
                endpointName: 'subscriptions',
            })
            .registerNamedRequest({
                name: 'UsUserProductSubscriptions',
                endpointName: 'subscriptions',
            })
            .registerNamedRequest({
                name: 'sliderSubscriptions',
                endpointName: 'subscriptions',
            })

            /** SUBSCRIPTIONS CREATION */

            .addMutation({
                mutationName: 'checkout',
                apiMethod: PaymentsService.checkout,
            })

            .addMutation({
                mutationName: 'subscribeAutofollow',
                apiMethod: UsSubscriptionsService.createSubscriptionAutofollowing,
            })
            .addMutation({
                mutationName: 'createToken',
                apiMethod: ApiKeysService.createTokenByProductId,
                onFulfilled: ({ args, result, dispatch }) => {
                    dispatch(
                        api.updateFinamProductItem(args.productId, (draft) => {
                            draft.token = result.data ?? undefined;
                        }),
                    );
                },
            })
            .registerNamedRequest({
                name: 'tokenPopupCreateToken',
                endpointName: 'createToken',
            })

            /** SUBSCRIPTIONS DELETION */

            .addMutation({
                mutationName: 'unsubscribeStrategy',
                apiMethod: UsSubscriptionsService.deleteSubscriptionAutofollowing,
                nestedResources: { 'result.data': 'SubscriptionItemData' },
                onFulfilled: createDeleteSubscription('UsStrategy'),
            })
            .addMutation({
                mutationName: 'unsubscribeSignalStrategy',
                apiMethod: UsSignalMeService.deleteSubscriptionSignalStrategy,
                nestedResources: { 'result.data': 'SubscriptionItemData' },
                onFulfilled: createDeleteSubscription('UsSignal'),
            })
            .addMutation({
                mutationName: 'contactSupport',
                apiMethod: FeedbackService.createFeedbackForm,
            })

            /** STRATEGIES */

            .addQuery({
                endpointName: 'strategyStats',
                apiMethod: StatsService.getStrategyStats,
                dataPath: 'data',
            })

            /** STRIPE REGISTRATION  */

            .addMutation({
                mutationName: 'createMyConnectedAccount',
                apiMethod: UsConnectedAccountService.createApiVMyConnectedAccount,
            })
            .addMutation({
                mutationName: 'createMyConnectedOnboarding',
                apiMethod: UsConnectedAccountService.createApiVMyConnectedAccountOnboarding,
            })

            /** SOCIAL STATS  */
            .addQuery({
                endpointName: 'followersStats',
                dataPath: '',
                apiMethod: StatisticsService.getStatisticsFollowers,
            })
            .registerNamedRequest({
                name: 'currentFollowersStats',
                endpointName: 'followersStats',
            })
            .addQuery({
                endpointName: 'likesStats',
                dataPath: '',
                apiMethod: StatisticsService.getStatisticsLikes,
            })
            .registerNamedRequest({
                name: 'currentLikesStats',
                endpointName: 'likesStats',
            })
            .addQuery({
                endpointName: 'totalSocialStats',
                dataPath: '',
                apiMethod: StatisticsService.getStatisticsTotal,
            })
            .registerNamedRequest({
                name: 'currentTotalSocialStats',
                endpointName: 'totalSocialStats',
            })

            /** ACCOUNTS AND ACCOUNT DATA */

            .addResource({ name: 'Account', getId: ({ accountId }: Account) => accountId })
            .addQuery({
                endpointName: 'accounts',
                apiMethod: ProfileService.getAccounts,
                dataPath: 'data',
                nestedResources: { 'result.data': 'Account' },
            })
            .addQuery({
                endpointName: 'portfolio',
                apiMethod: ProfileService.getApiVMyProfileInfoPortfolio,
                dataPath: 'data',
            })
            .registerNamedRequest({
                name: 'currentAccountPortfolio',
                endpointName: 'portfolio',
            })
            .addQuery({
                endpointName: 'profile',
                apiMethod: ProfileService.getProfile,
                dataPath: 'data',
            })
            .addQuery({
                endpointName: 'statistics',
                apiMethod: ProfileService.getApiVMyProfileStatistic,
                dataPath: 'data',
            })
            .registerNamedRequest({
                name: 'currentAccountStatistics',
                endpointName: 'statistics',
            })
            .addQuery({
                endpointName: 'tradeQuality',
                apiMethod: ProfileService.getApiVMyProfileInfoTradeQuality,
                dataPath: 'data',
            })
            .registerNamedRequest({
                name: 'currentAccountTradeQuality',
                endpointName: 'tradeQuality',
            })
            .addQuery({
                endpointName: 'instrumentProfitability',
                apiMethod: InstrumentService.getInstrumentProfitability,
                dataPath: 'profitability',
            })
            .registerNamedRequest({
                name: 'currentAccountInstrumentProfitability',
                endpointName: 'instrumentProfitability',
            })

            /** SIGNALS */

            .addResource({ name: 'Stock', getId: ({ finamId }: Stock) => finamId })
            .addResource({ name: 'SignalData', getId: ({ id }: SignalData) => id, nestedResources: { stock: 'Stock' } })
            .addQuery({
                endpointName: 'productSignals',
                apiMethod: (args: Parameters<typeof UsSignalMeService.getProductSignals>[0]) =>
                    UsSignalMeService.getProductSignals(args) as MarketplaceApiReturn<FinamProductItem[]>,
                dataPath: 'data',
                nestedResources: { 'result.data': 'SignalData' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'mySpaceProductSignals',
                endpointName: 'productSignals',
            })
            .addQuery({
                endpointName: 'subscriptionsSignals',
                apiMethod: UsSignalMeService.getApiVMyUsSignalMe,
                dataPath: 'data',
                nestedResources: { 'result.data': 'SignalData' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'mySpaceSubscriptionsSignals',
                endpointName: 'subscriptionsSignals',
            })

            .addQuery({
                endpointName: 'disableDemoAccountNotification',
                apiMethod: UserService.disableDemoAccountNotification,
                dataPath: '',
            })

            /** REVIEWS */

            .addResource({
                name: 'Review',
                getId: ({ id }: Review) => id,
                ...createAuthorConfig(),
            })
            .addQuery({
                endpointName: 'myProductReviews',
                apiMethod: ProductsService.getApiVMyProductReviews,
                dataPath: 'data',
                pagination: createMarketplacePagination(true),
                nestedResources: { 'result.data': 'Review' },
            })
            .addQuery({
                endpointName: 'reviews',
                apiMethod: ReviewsService.getReviews as MarketplaceApiPaginatedMethod<
                    typeof ReviewsService.getReviews,
                    Review[]
                >,
                dataPath: 'data',
                nestedResources: { 'result.data': 'Review' },
                pagination: createMarketplacePagination(),
            })
            .registerNamedRequest({
                name: 'currentProductReviews',
                endpointName: 'reviews',
            })
            .addMutation({
                mutationName: 'createReview',
                apiMethod: MyReviewsService.createReview,
                nestedResources: { 'result.data': 'Review' },
                onFulfilled: ({ result, args, dispatch, getState }) => {
                    dispatch(
                        api.updateReviews(
                            ({ productId }) => productId === args.requestBody?.productId,
                            (draft) => {
                                draft.result.data?.unshift(api.selectReview(getState(), result.data?.id));
                            },
                        ),
                    );
                },
            })
            .registerNamedRequest({
                name: 'createReviewForCurrentProduct',
                endpointName: 'createReview',
            })

            /** AI-PORTFOLIO */

            .addResource({
                name: 'AiPortfolio',
                getId: ({ id }: AiPortfolio) => id,
            })
            .addQuery({
                endpointName: 'aiPortfolios',
                dataPath: 'data',
                apiMethod: AiPortfoliosService.fetchMyAiPortfolios,
                nestedResources: { 'result.data': 'AiPortfolio' },
            })
            .addMutation({
                mutationName: 'validatePreferencesAiPortfolioDraft',
                apiMethod: AiPortfoliosService.validatePreferencesAiPortfolioDraft,
            })
            .addMutation({
                mutationName: 'validateOptimizationAiPortfolioDraft',
                apiMethod: AiPortfoliosService.validateOptimizationAiPortfolioDraft,
            })
            .addMutation({
                mutationName: 'createAiPortfolioDraft',
                apiMethod: AiPortfoliosService.createAiPortfolioDraft,
                nestedResources: { 'result.data': 'AiPortfolio' },
            })
            .addMutation({
                mutationName: 'updateAiPortfolioDraft',
                apiMethod: AiPortfoliosService.updateAiPortfolioDraft,
                nestedResources: { 'result.data': 'AiPortfolio' },
            })
            .addMutation({
                mutationName: 'deleteAiPortfolio',
                apiMethod: AiPortfoliosService.deleteAiPortfolio,
                nestedResources: { 'result.data': 'AiPortfolio' },
            })
            .registerNamedRequest({
                name: 'deleteAiPortfolioCard',
                endpointName: 'deleteAiPortfolio',
            })
            .addMutation({
                mutationName: 'generateAiPortfolio',
                apiMethod: AiPortfoliosService.generateAiPortfolio,
                nestedResources: { 'result.data': 'AiPortfolio' },
                onFulfilled: ({ result, dispatch, getState }) => {
                    dispatch(
                        api.updateAiPortfolios(
                            () => true,
                            (draft) => {
                                const id = result.data?.id;
                                const { data } = draft.result;
                                if (data && id !== undefined) {
                                    if (!data.some(({ id: existingId }) => existingId === id)) {
                                        data.push(api.selectAiPortfolio(getState(), id));
                                    }
                                }
                            },
                        ),
                    );
                    dispatch(
                        api.updateMyCredits(
                            () => true,
                            (draft) => {
                                const { data } = draft.result;
                                if (data?.count !== undefined) {
                                    data.count--;
                                }
                            },
                        ),
                    );
                },
            })
            .addResource({
                name: 'UserCreditsEntity',
                getId: ({ id }: UserCreditsEntity) => id,
            })
            .addQuery({
                endpointName: 'myCredits',
                apiMethod: CreditsService.getMyCredits,
                nestedResources: { 'result.data': 'UserCreditsEntity' },
                dataPath: 'data',
            })

            .addResource({
                name: 'ChatEvent',
                getId: ({ eventId }: HistoryEvent) => eventId,
            })
            .addQuery({
                endpointName: 'chatEvents',
                dataPath: 'events',
                apiMethod: DefaultService.getHistory,
                nestedResources: { 'result.events': 'ChatEvent' },
            })
            .registerNamedRequest({
                name: 'allChatEvents',
                endpointName: 'chatEvents',
            })
            .addMutation({
                mutationName: 'createChatEvent',
                apiMethod: DefaultService.createEvent,
                nestedResources: { 'result.events': 'ChatEvent' },
                onFulfilled: ({ result, args, dispatch, getState }) => {
                    dispatch(
                        api.updateChatEvents(
                            () => true,
                            (draft) => {
                                result.events.forEach((event) => {
                                    draft.result.events?.push(api.selectChatEvent(getState(), event.eventId));
                                });
                            },
                        ),
                    );
                },
            })

            /** Author payments */

            .addQuery({
                endpointName: 'myPaymentsStatistics',
                apiMethod: PaymentsService.getMyPaymentsStatistics,
                dataPath: 'data',
            })
            .registerNamedRequest({
                name: 'overviewMyPaymentsStatistics',
                endpointName: 'myPaymentsStatistics',
            })
            .addMutation({
                mutationName: 'createDonation',
                apiMethod: PaymentsService.createApiVMyPaymentDonation,
            })
            .registerNamedRequest({
                name: 'createSubscriptionDonation',
                endpointName: 'createDonation',
            })

            /** PAYMENTS HISTORY */

            .addQuery({
                endpointName: 'myPayments',
                apiMethod: PaymentsService.getMyPayments,
                dataPath: 'data',
                pagination: createMarketplacePagination(),
            })

            .getApi()
    );
}

export const { api, injectReducers, resourcesMap } = createApi();

type MarketplacePagination<ARRAY_TYPE, PAGE_DATA> = PaginationConfig<
    (args: { page?: number }) => Promise<{
        paging?: { page?: number | null; totalPages?: number | null; totalItems?: number | null };
        data?: ARRAY_TYPE[] | null;
    }>,
    PAGE_DATA
>;

export function createMarketplacePagination<DATA_TYPE>(
    includeTotalItems?: boolean,
): MarketplacePagination<
    DATA_TYPE,
    { page?: number | null; totalPages?: number | null; totalItems?: number | null } | undefined
> {
    return {
        getCommonArgs: ({ page, ...rest }) => rest,
        firstPage: { page: 1 },
        getPageInfo: (data) => data.paging,
        getTotalItems: includeTotalItems
            ? (pages) => Math.max(...pages.filter(notEmpty).map(({ totalItems }) => totalItems ?? 0))
            : undefined,
        getNextPage: (pages) => {
            const maxPage = Math.max(...pages.filter(notEmpty).map(({ page }) => page ?? 0));
            const maxTotalPages = Math.max(...pages.filter(notEmpty).map(({ totalPages }) => totalPages ?? 0));
            if (maxPage >= maxTotalPages) {
                return null;
            }
            return { page: maxPage + 1 };
        },
    };
}

export function createAuthorConfig<
    TYPES_MAP extends Record<'KratosUser', KratosUser>,
    RESOURCE_TYPE extends { authorId?: string | null },
>(): {
    computedFields: ComputedFieldsConfig<TYPES_MAP, RESOURCE_TYPE>;
    listener: ResourceListener<RESOURCE_TYPE>;
} {
    return {
        computedFields: {
            author: (item, read) => read('KratosUser', item.authorId),
        },
        listener: ({ dispatch, newItems, getState }) => {
            const authors = uniq(newItems.map(({ authorId }) => authorId).filter(notEmpty));
            pull(authors, ...Object.keys(getState().cache.data.resources.KratosUser ?? {}));
            if (authors.length > 0) {
                dispatch(api.fetchKratosUsers({ requestBody: { kratosIds: authors } }));
            }
        },
    };
}

export function createDeleteSubscription<API_METHOD extends AsyncMethod>(
    type: string,
): OnFulfilledCallback<API_METHOD> {
    return ({ result, dispatch, getState }) => {
        dispatch(
            api.updateSubscriptions(
                () => true,
                (draft) => {
                    if (draft.result.data) {
                        const { length } = draft.result.data;
                        remove(draft.result.data, ({ id }) => id === result.data?.id);
                        const newLength = draft.result.data.length;
                        if (draft.result.paging && draft.result.paging.totalItems !== undefined) {
                            draft.result.paging.totalItems -= length - newLength;
                        }
                    }
                },
            ),
        );
        dispatch(
            api.updateSubscriptions(
                ({ productTypes, archived, page }) =>
                    (!productTypes || productTypes.includes(type)) && !!archived && page === 1,
                (draft) => {
                    if (result.data) {
                        draft.result.data?.unshift({
                            ...result.data,
                            product: api.selectFinamProductItem(getState(), result.data.productId),
                        });
                        if (draft.result.paging && draft.result.paging.totalItems !== undefined) {
                            draft.result.paging.totalItems++;
                        }
                    }
                },
            ),
        );
    };
}
