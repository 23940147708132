/* eslint no-bitwise: "off" */

import type { FeedRecord, PublishStatusRecord, TickerNewsRecord } from './index';
import { RecommendedUsersRecord } from './index';

// значение аттрибута type с бэка для идентификации в ленте
export enum FeedRecordType {
    PUBLISH_STATUS = 1,
    TICKER_NEWS = 101,
    RECOMMENDATIONS = 25,
    ADS = 103,
}

export enum FeedRecordObjTypes {
    POST = 3,
    COMMENT = 6,
}

export enum FeedRecordObjBlogpostMask {
    POST = 1 << 0, // 0b000000000001; // 1
    PREMIUM = 1 << 7, // 0b000010000000; // 128
    ANY = (1 << 14) - 1, // 0b111111111111; // 16383
}

export enum UserBias {
    none,
    agree,
    disagree = -1,
}

export enum FeedOwnerType {
    UNDEFINED = 0,
    PERSON = 1,
    INSTRUMENT = 5,
    TAG = 20,
    PREMIUM = 36,
    COMMUNITY = 37,
}

export enum PostType {
    ORDINARY = 'ordinary',
    PREMIUM = 'premium',
    COMMUNITY = 'community',
}

export function isPremiumPost(blogpostType?: number): boolean {
    return ((blogpostType ?? 0) & FeedRecordObjBlogpostMask.PREMIUM) === FeedRecordObjBlogpostMask.PREMIUM;
}

export function isPublishStatusRecord(record: FeedRecord): record is PublishStatusRecord {
    return record.type === FeedRecordType.PUBLISH_STATUS;
}

export function isPremiumPostRecord(record: FeedRecord): boolean {
    return isPublishStatusRecord(record) && isPremiumPost(record.obj.blogpostType);
}

export function isRecommendationRecord(record: FeedRecord): record is RecommendedUsersRecord {
    return record.type === FeedRecordType.RECOMMENDATIONS;
}

export function isTickerNewsRecord(record: FeedRecord): record is TickerNewsRecord {
    return record.type === FeedRecordType.TICKER_NEWS;
}
