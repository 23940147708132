import styled from 'styled-components';

import { body14MediumTNumFontStyle, body14RegularFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';

export const ParametersTableWrapper = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ isLoading }) => (isLoading ? '16px' : '8px')};
`;

export const ParamName = styled.div`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    flex-shrink: 0;
`;

export const ParamValue = styled.div`
    ${body14MediumTNumFontStyle};
    flex-shrink: 0;
    display: flex;
    align-items: center;
    text-align: right;
    font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const ParameterItemRow = styled.div<{ isLoading: boolean }>`
    display: flex;
    justify-content: space-between;
    gap: 8px;

    width: ${({ isLoading }) => (isLoading ? '100%' : 'auto')};
    height: ${({ isLoading }) => (isLoading ? '12px' : 'auto')};
    background-color: ${({ isLoading }) => (isLoading ? tokens.colors.opacity.base['16'] : 'transparent')};
    border-radius: ${({ isLoading }) => (isLoading ? '2px' : '0')};
`;
