import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CrossCircleFilled: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM9.56564 8.43427C9.25322 8.12185 8.74669 8.12185 8.43427 8.43427C8.12185 8.74669 8.12185 9.25322 8.43427 9.56564L10.8686 12L8.43427 14.4343C8.12185 14.7467 8.12185 15.2532 8.43427 15.5656C8.74669 15.8781 9.25322 15.8781 9.56564 15.5656L12 13.1313L14.4343 15.5656C14.7467 15.8781 15.2532 15.8781 15.5656 15.5656C15.8781 15.2532 15.8781 14.7467 15.5656 14.4343L13.1313 12L15.5656 9.56564C15.8781 9.25322 15.8781 8.74669 15.5656 8.43427C15.2532 8.12185 14.7467 8.12185 14.4343 8.43427L12 10.8686L9.56564 8.43427Z"
        />
    </IconSvg>
);
