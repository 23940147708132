import { ForexIcon } from 'components/icons2/emoji/ForexIcon';
import { FireIcon } from 'components/icons2/emoji/FireIcon';
import { ChartIcon } from 'components/icons2/emoji/ChartIcon';
import { BitcoinIcon } from 'components/icons2/emoji/BitcoinIcon';
import { RUSFlagIcon } from 'components/icons2/emoji/RUSFlagIcon';
import { USAFlagIcon } from 'components/icons2/emoji/USAFlagIcon';
import { ThinkIcon } from 'components/icons2/emoji/ThinkIcon';
import { RocketIcon } from 'components/icons2/emoji/RocketIcon';
import { SheetTopCornIcon } from 'components/icons2/emoji/SheetTopCornIcon';
import { SheetBottomCornIcon } from 'components/icons2/emoji/SheetBottomCornIcon';
import { UmbrelaIcon } from 'components/icons2/emoji/UmbrelaIcon';
import { ArtIcon } from 'components/icons2/emoji/ArtIcon';
import { LightningIcon } from 'components/icons2/emoji/LightningIcon';
import { EarthIcon } from 'components/icons2/emoji/EarthIcon';
import { IconComponent } from 'components/icons2';

export type MapIdToEmojiType = { [key: number]: IconComponent | undefined };

export const MapIdToEmoji: MapIdToEmojiType = {
    2: ForexIcon,
    3: FireIcon,
    4: ChartIcon,
    5: BitcoinIcon,
    6: RUSFlagIcon,
    7: USAFlagIcon,
    8: ThinkIcon,
    9: RocketIcon,
    10: SheetTopCornIcon,
    11: SheetBottomCornIcon,
    12: UmbrelaIcon,
    13: ArtIcon,
    14: LightningIcon,
    34: EarthIcon,
};
