import React, { forwardRef, memo } from 'react';
import { useController } from 'react-hook-form';

import { useTrackFocus } from 'hooks/useTrackFocus';
import { useMultipleRefs } from 'hooks/useMultipleRefs';
import { TextInput } from 'components/text-input';

import { rulesComparer } from '../utils/rulesComparer';

import { FormTextInputProps } from './types';

export const FormTextInput = memo(
    forwardRef<HTMLInputElement, FormTextInputProps>(
        ({ name, rules, maxLength, label, hideErrors = false, ...textInputProps }, forwardedRef) => {
            const {
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
            } = useController<{ [key: string]: string }>({ name, rules });

            const [focusRef, isFocus] = useTrackFocus<HTMLInputElement>();

            const refFunction = useMultipleRefs(forwardedRef, ref, focusRef);

            return (
                <TextInput
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={refFunction}
                    error={hideErrors ? undefined : error?.message}
                    errorHighlight={!hideErrors && error !== undefined}
                    label={
                        isFocus && label && maxLength !== undefined ? `${label} (${value.length}/${maxLength})` : label
                    }
                    {...textInputProps}
                />
            );
        },
    ),
    rulesComparer,
);
