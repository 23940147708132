/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { WebResponse_Array_ILeanProductDto } from '../models/shared/WebResponse_Array_ILeanProductDto';
import type { WebResponse_ProfanityCheckResponse } from '../models/UserProductsService/WebResponse_ProfanityCheckResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserProductsService {
    /**
     * Получить все программные продукты пользователя
     * @returns WebResponse_Array_ILeanProductDto Success
     * @throws ApiError
     */
    public static getMyProducts(): CancelablePromise<WebResponse_Array_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/user-products',
        });
    }

    /**
     * Удалить программный продукт пользователя
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static deleteProduct({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/user-products/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * Проверяет содержимое JSON-объекта на неприемлемые выражения
     * @returns WebResponse_ProfanityCheckResponse Success
     * @throws ApiError
     */
    public static checkProfanity({
        requestBody,
    }: {
        /**
         * JSON-объект
         */
        requestBody?: Record<string, string>;
    }): CancelablePromise<WebResponse_ProfanityCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/user-products/profanity',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }
}
