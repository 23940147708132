import { useEffect, useLayoutEffect, useRef } from 'react';

type Size = {
    width: number;
    height: number;
};

/**
 * Executes a callback every time the window size changes
 *
 * @deprecated Use CSS for window-size related logic
 *
 * @example
 * const [isShown, setIsShown] = useState(false);
 *
 * useWindowSize((size) => {
 *   if (size.width < breakpoint) {
 *      setIsShown(false);
 *   }
 * })
 *
 * return (
 *   <>
 *     { ... }
 *     {isShown && <OnlyMobileContent />}
 *   </>
 * )
 */
export function useWindowResize(callback: (size: Size) => void, depsEffect: Array<unknown> = []) {
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        function handleResize() {
            callbackRef.current({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, depsEffect);
}
