import { css } from 'styled-components';

import { InlineLabel, Input, RightGroupWrapper, RightLabel } from '../base';

const errorStyles = css`
    & ${InlineLabel}, & ${RightLabel}, & ${Input} {
        color: ${({ theme }) => theme.label.negative};
    }

    & ${RightGroupWrapper} svg {
        fill: ${({ theme }) => theme.label.negative};
    }

    & ${Input}::placeholder {
        color: ${({ theme }) => theme.label.negative};
    }

    &:focus-within {
        background-color: ${({ theme }) => theme.bg.primary};
        box-shadow: ${({ theme }) => theme.focusOutline.negative},
            inset 0 0 0 1px ${({ theme }) => theme.fillIn.negative.mid};

        & ${Input} {
            color: ${({ theme }) => theme.label.primary};
        }
    }
`;

export const errorFillStyles = css`
    ${errorStyles};
    background-color: ${({ theme }) => theme.fillIn.negative.overlay16};

    &:hover:not(:focus-within) {
        background-color: ${({ theme }) => theme.fillIn.negative.overlay24};
    }
`;

export const errorOutlineStyles = css`
    ${errorStyles};
    background-color: transparent;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.negative.mid};

    &:hover:not(:focus-within) {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.negative.highest};
    }
`;
