import styled from 'styled-components';

import { ContextActions as ContextActionsComponent } from 'components/context-actions';
import { ButtonIconWrapper } from 'components/context-actions/styled';

export const ContextActions = styled(ContextActionsComponent)`
    ${ButtonIconWrapper} {
        width: 100%;
    }
`;
