import React from 'react';

import { useLinks } from 'hooks/useLinks';

import * as Styled from './styled';

export function Logo(props: { logoElement?: React.ReactElement }) {
    const { logoElement } = props;
    const links = useLinks();

    return (
        <Styled.LogoLink href={links.homeRoot()} className="autotest__menu__logo">
            {logoElement || (
                <Styled.LimexLogoIconWrapper>
                    <Styled.LimexLogoIcon />
                </Styled.LimexLogoIconWrapper>
            )}
        </Styled.LogoLink>
    );
}
