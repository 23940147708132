import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';
import Tippy from '@tippy.js/react';

import { boxShadowLikeBorder } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';

export const TickerWrapperLink = styled.a`
    font-weight: 400;
    text-decoration: none;
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 12px;
    ${({ theme: { label, fillIn } }) => `
        color: ${label.primary};
        background-color: ${fillIn.secondary.overlay16};
        &:hover {
            color: ${label.primary};
            background-color: ${fillIn.secondary.overlay24};
        }
        &:active {
            color: ${label.primary};
            background-color: ${fillIn.secondary.muted};
        }
        &:focus {
            box-shadow: ${boxShadowLikeBorder('3px', fillIn.primary.overlay24)};
            outline: none;
        }
        &:focus-visible {
            outline: none;
        }
    `}}
`;

export const Ticker = styled.span`
    font-weight: 500;
`;

export const Price = styled.span`
    margin-left: 8px;
`;

export const PriceDiff = styled.span`
    display: inline-flex;
    margin-left: 2.5px;
    align-items: center;
    ${ifNotProp('isZero', css`
        color: ${({ theme: { label }, isGrow }) => isGrow ? label.positive : label.negative};
    `)}
    & > svg {
        margin-left: 4px;
        transform: rotate(${ifNotProp('isGrow', 18)}0deg);
        display: ${ifProp('isZero', 'none', 'inline-block')};
    }
`;

export const DelayStyled = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 8px;

    & > svg {
        color: ${({ theme }) => theme.label.secondary};
    }

    &:hover > svg {
        color: ${({ theme }) => theme.label.secondaryHigh};
    }
`;
