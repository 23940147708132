import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const ForexIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path
            d="M13.9063 16.4313C13.9063 16.4313 13.9048 17.0188 13.9048 17.0906C13.9048 17.1625 14.2251 17.3156 14.8048 17.3219C15.3845 17.3281 15.7173 17.1813 15.7173 17.1297C15.7173 17.0781 15.7126 16.4406 15.7126 16.4406C15.7126 16.4406 15.2923 16.0328 14.9126 16.0234C14.5329 16.0141 13.9063 16.4313 13.9063 16.4313Z"
            fill="#464C4F"
        />
        <path
            d="M4.04219 0.489066C3.87969 0.490629 3.79688 0.506254 3.79688 0.875003C3.79688 1.24375 3.83125 12.5703 3.83125 12.6938C3.83125 12.8172 3.95469 12.8688 4.16563 12.8688C4.37656 12.8688 4.88594 12.8688 5.02656 12.8688C5.16719 12.8688 5.22031 12.7813 5.20156 12.5531C5.18281 12.325 5.18125 0.893754 5.18125 0.753129C5.18125 0.612504 5.11719 0.503129 4.89375 0.495316C4.71094 0.489066 4.23438 0.487504 4.04219 0.489066Z"
            fill="#606265"
        />
        <path
            d="M2.56406 4.625C2.56406 4.625 2.53906 3.98125 2.99219 3.56562C3.41094 3.18281 3.8 3.21094 3.8 3.21094L3.80312 3.84844C3.80312 3.84844 4.04062 3.76562 4.44375 3.78281C4.84687 3.8 5.17812 3.93906 5.17812 3.93906V3.27031C5.17812 3.27031 5.40156 3.26094 5.7375 3.65625C5.90625 3.85469 6.04531 4.26719 6.09062 4.48281C6.15 4.75938 6.25156 4.88906 6.51875 4.90781C6.78594 4.92656 6.99062 4.925 7.15469 4.91875C7.39062 4.91094 6.65312 3.62969 6.65312 3.62969C6.65312 3.62969 5.82969 2.38594 5.56094 2.37812C5.29219 2.37031 3.62812 2.4125 3.46875 2.49531C3.30937 2.57969 2.26719 3.31094 2.26719 3.31094L2.125 4.44531C2.125 4.44531 2.52031 4.90781 2.52031 4.87344C2.52344 4.84375 2.56406 4.625 2.56406 4.625Z"
            fill="#464C4F"
        />
        <path
            d="M1.76572 4.625L0.981344 4.00781C0.981344 4.00781 0.814156 5.27969 1.44853 6.04531C1.91259 6.60625 2.45634 6.83594 2.93603 7.04531C3.38291 7.24219 3.81572 7.37656 3.81572 7.37656L3.81728 8.12812L5.18916 8.85781L5.18759 7.87187C5.18759 7.87187 5.70791 8.15469 5.96885 8.43281C6.22978 8.70937 6.25478 9.05469 6.2626 9.27344C6.27041 9.49219 6.16103 9.83594 6.16103 9.83594L6.00947 10.5078L7.75791 8.74375L3.63291 6.00469L1.76572 4.625Z"
            fill="#464C4F"
        />
        <path
            d="M1.07509 8.57031C1.07509 8.57031 0.86571 8.75 0.926648 9.2375C0.982898 9.69063 1.23759 10.3969 2.00165 11.0188C2.76571 11.6406 3.82977 11.7594 3.82977 11.7594V12.2781L5.1954 12.275L5.19384 11.7594C5.19384 11.7594 6.00946 11.725 6.69852 11.2875C7.41884 10.8297 7.94696 9.89688 7.91571 9.02188C7.90477 8.71406 7.70946 8.7625 7.70946 8.7625C7.70946 8.7625 5.20321 10.9359 5.03602 10.9531C4.86727 10.9703 3.23759 10.6844 3.23759 10.6844L1.71727 9.02969L1.07509 8.57031Z"
            fill="#464C4F"
        />
        <path
            d="M1.71556 8.38906C1.20462 8.43906 1.0265 8.54062 1.01868 8.69219C1.01087 8.84375 1.45618 11.4844 4.564 11.4406C7.54681 11.3984 7.89994 9.58437 7.92494 8.87812C7.95462 8.02969 7.45462 7.32344 6.32806 6.65937C5.489 6.16562 3.46243 5.42344 3.1265 5.21406C2.81712 5.02031 2.38743 4.59219 2.44525 3.95312C2.50462 3.31406 3.2015 2.68438 4.48743 2.69219C5.77337 2.7 6.27962 3.59531 6.37806 3.86094C6.53587 4.2875 6.50619 4.84219 6.739 4.91562C6.84837 4.95 7.16712 4.93594 7.3515 4.91094C7.53587 4.88594 7.79837 4.74375 7.814 4.37344C7.83119 3.94531 7.38587 1.55 4.41087 1.575C1.57025 1.59844 0.871808 3.34063 0.981183 4.34062C1.08431 5.28906 1.85618 6.17969 3.09993 6.68437C4.34368 7.18906 6.54525 7.80156 6.52025 8.93594C6.49837 9.95312 5.31712 10.3141 4.34368 10.2641C3.36087 10.2141 2.82337 9.64219 2.62181 8.97031C2.42962 8.325 2.06087 8.35625 1.71556 8.38906Z"
            fill="#606265"
        />
        <path
            d="M2.18744 8.71093C2.08276 8.51562 1.38276 8.54531 1.29213 8.87656C1.20151 9.20781 1.52182 9.72187 1.73744 9.67656C2.03119 9.61562 1.78276 9.26093 1.89682 9.12656C2.01244 8.99062 2.31713 8.95312 2.18744 8.71093Z"
            fill="#9A9A9B"
        />
        <path
            d="M4.43742 0.664059C4.23273 0.659372 4.00305 0.656247 3.99211 0.93281C3.98117 1.19843 3.99836 1.40312 4.10305 1.41406C4.22023 1.42656 4.38586 1.17343 4.45773 1.06406C4.5468 0.924997 4.65773 0.67031 4.43742 0.664059Z"
            fill="#9A9A9B"
        />
        <path
            d="M2.86714 2.28438C2.78902 2.17032 2.34995 2.07969 1.7437 2.82813C1.13745 3.57657 1.36089 3.97344 1.49995 3.99063C1.68902 4.01407 1.7937 3.85938 1.90933 3.60469C2.07495 3.24219 2.27183 3.02188 2.57964 2.78907C2.84214 2.59063 3.00152 2.48125 2.86714 2.28438Z"
            fill="#9A9A9B"
        />
        <path
            d="M11.564 13.1984C11.564 13.1984 11.564 13.3859 11.564 13.4781C11.564 13.5719 11.6437 13.6484 11.8343 13.6562C12.0249 13.6641 17.864 13.6719 17.8983 13.6687C17.9577 13.6625 17.9593 13.5922 17.9593 13.5484C17.964 13.3766 17.9624 13.2 17.9624 13.2L12.4843 12.9125L11.564 13.1984Z"
            fill="#464C4F"
        />
        <path
            d="M11.5657 14.7984C11.5657 14.7984 11.5657 15.2031 11.5657 15.2453C11.5672 15.3281 11.6313 15.3437 11.7485 15.3453C11.8672 15.3453 17.6579 15.3453 17.7422 15.3453C17.8266 15.3453 17.8938 15.2969 17.8969 15.2031C17.9016 15.075 17.8985 14.7984 17.8985 14.7984L14.7407 14.4656L11.5657 14.7984Z"
            fill="#464C4F"
        />
        <path d="M17.9625 12.4766H11.5625V13.2047H17.9625V12.4766Z" fill="#606265" />
        <path d="M17.8999 14.1234H11.564V14.8031H17.8999V14.1234Z" fill="#606265" />
        <path
            d="M13.9063 16.4313L13.9876 12.4734C13.9876 12.4734 10.3579 7.03281 10.3985 6.95C10.4407 6.86719 12.5392 6.90625 12.6438 6.96875C12.7485 7.03125 14.8173 10.2984 14.9079 10.2969C15.0017 10.2953 16.9735 7.08438 17.0563 7.02188C17.1392 6.95938 19.2892 6.94063 19.2892 7.025C19.2892 7.10938 15.6985 12.4828 15.6985 12.4828L15.7126 16.4531C15.7126 16.4531 15.1626 16.4891 14.8095 16.4891C14.4563 16.4891 13.9063 16.4313 13.9063 16.4313Z"
            fill="#606265"
        />
        <path
            d="M12.3641 7.31719C12.3688 7.42813 11.736 8.03906 11.611 8.03906C11.486 8.03906 11.1688 7.6125 11.1 7.36406C11.0532 7.19531 11.5938 7.16563 11.8 7.16563C12.0078 7.16719 12.3578 7.17344 12.3641 7.31719Z"
            fill="#9A9A9B"
        />
        <path
            d="M17.2735 7.35625C17.2063 7.39688 16.6219 8.05781 16.8797 8.26406C17.0626 8.41094 17.4297 8.05313 17.711 7.92813C17.9922 7.80313 18.4563 7.7 18.4516 7.46875C18.4454 7.18437 17.5188 7.20625 17.2735 7.35625Z"
            fill="#9A9A9B"
        />
        <path
            d="M8.54224 2.6C8.70318 2.82032 9.3313 2.49688 9.86099 2.41875C10.3907 2.34063 11.2579 2.19844 12.5376 2.80625C13.8172 3.41407 14.5672 4.29375 14.5547 4.35782C14.5422 4.42188 13.3657 4.72813 13.0297 4.89688C12.7485 5.0375 12.6313 5.23438 12.6797 5.47032C12.7251 5.69375 12.9016 5.81407 13.1594 5.87813C13.6094 5.99063 16.711 6.29219 17.1766 5.85157C17.761 5.3 17.6126 2.38594 17.5766 2.04219C17.5235 1.51563 17.3766 1.3375 17.111 1.29375C16.8782 1.25469 16.6454 1.35782 16.5032 1.71094C16.3422 2.10782 16.0657 3.11563 15.9876 3.11563C15.9094 3.11563 15.0719 1.85 13.5844 1.33282C12.0969 0.815628 10.7797 1.04844 9.99068 1.34532C9.14693 1.66407 8.33599 2.31563 8.54224 2.6Z"
            fill="#02AB47"
        />
        <path
            d="M6.61569 16.1078C6.56413 16.1766 7.27038 17.3891 8.80788 17.8437C10.3454 18.2984 12.1407 17.4891 12.3376 17.8719C12.5938 18.3703 10.4079 20.0359 7.71256 19.1391C5.45006 18.3859 5.07975 16.9328 5.00944 16.9469C4.93913 16.9609 4.38756 17.6766 4.24069 17.8437C3.89694 18.2344 3.08756 18 3.30163 17.2609C3.59069 16.2578 4.35631 13.9812 4.76725 13.8312C5.31725 13.6297 8.04069 14.9828 8.23913 15.0984C8.60944 15.3125 8.69381 15.9812 8.02506 16.0234C7.61256 16.0484 6.65944 16.0516 6.61569 16.1078Z"
            fill="#02AB47"
        />
    </EmojiIconSvg>
);
