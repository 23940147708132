import styled from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { body14MediumFontStyle } from 'services/typography';
import { Button } from 'components/button2';

import { SizeWtPopup3Enum } from '../constants';

import { StepsWtPopup3PropsType } from './types';

const buildStepsBySize = ({ size }: Required<Pick<StepsWtPopup3PropsType, 'size'>>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: 'padding: 0 16px 8px 0;',
        [SizeWtPopup3Enum.SMALL]: 'padding: 0 32px 12px 12px;',
        [SizeWtPopup3Enum.MEDIUM]: 'padding: 0 32px 12px 12px;',
        [SizeWtPopup3Enum.LARGE]: 'padding: 0 40px 16px 28px;',
    });

export const StepsWrapper = styled.div<Required<Pick<StepsWtPopup3PropsType, 'size'>>>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${buildStepsBySize};

    ${mediaQuery.lt768} {
        ${() => buildStepsBySize({ size: SizeWtPopup3Enum.TINY })};
    }
`;

export const Text = styled.span`
    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    margin: 6px 0;
`;
export const ButtonArrowLeft = styled(Button)``;
