import { connect } from 'react-redux';

import { revokePremium } from 'widgets/feed/feed/actions/revokePremium';

import { State } from '../../types';

import { HeaderUser } from './components';

const mapStateToProps = (state: State) => {
    const {
        page: { user },
    } = state;
    return {
        user,
    };
};

const mapDispatchToProps = {
    onUnsubscribe: revokePremium,
};

export const HeaderUserContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderUser);
