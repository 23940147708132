import React, { MouseEventHandler, ReactElement } from 'react';

import { CardContainer } from '../container';

import { SkeletonIcon } from './SkeletonIcon';
import * as Styled from './styled';

export type CardLayoutRequestedPropsType = {
    isLoading?: boolean;
};
export type CardLayoutPropsType = CardLayoutRequestedPropsType & {
    onClick?: MouseEventHandler;
    className?: string;
    width?: number;
    isWidthLimits?: boolean;
    url?: string;
    withoutBoxShadow?: boolean;
    isHeightGrow?: boolean;
    targetCardLink?: '_blank' | '_self' | '_parent' | '_top';
    dataAttributes?: { [key: string]: string };
    thumbnailRender: (args: CardLayoutRequestedPropsType) => React.ReactElement;
    contentRender: (args: CardLayoutRequestedPropsType) => React.ReactElement;
    isDisable?: boolean;
    customSkeleton?: ReactElement;
};

export const DefaultLayout = (props: CardLayoutPropsType) => {
    const {
        onClick,
        className,
        contentRender,
        thumbnailRender,
        withoutBoxShadow,
        width,
        isWidthLimits,
        url,
        isDisable = false,
        isHeightGrow = false,
        targetCardLink,
        dataAttributes,
        customSkeleton,
        ...argsForRenderFuncs
    } = props;

    const { isLoading = false } = argsForRenderFuncs;

    return (
        <CardContainer
            onClick={onClick}
            className={className}
            isLoading={isLoading}
            width={width}
            isWidthLimits={isWidthLimits}
            withoutBoxShadow={withoutBoxShadow}
            isHeightGrow={isHeightGrow}
            url={url}
            dataAttributes={dataAttributes}
            target={targetCardLink}
            isDisable={isDisable}
        >
            <Styled.CardThumbnailBlock>{thumbnailRender(argsForRenderFuncs)}</Styled.CardThumbnailBlock>
            <Styled.CardContentBlock>
                {isLoading ? (
                    <Styled.SkeletonWrapper>{customSkeleton ?? <SkeletonIcon />}</Styled.SkeletonWrapper>
                ) : (
                    contentRender(argsForRenderFuncs)
                )}
            </Styled.CardContentBlock>
        </CardContainer>
    );
};
