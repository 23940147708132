import React, { ReactNode } from 'react';

import { ButtonProps } from 'components/button2';

import * as Styled from './styled';

export type NoDataPropsType = {
    cover: ReactNode;
    title?: string;
    secondaryText?: string;
    button?: ButtonProps;
    withManifestationAnimate?: boolean;
};

export const NoData = (props: NoDataPropsType) => {
    const { cover, title, secondaryText, button, withManifestationAnimate = true } = props;

    return (
        <Styled.NoDataWrapper withManifestationAnimate={withManifestationAnimate}>
            <Styled.Cover>{cover}</Styled.Cover>
            <Styled.MainTitle>{title}</Styled.MainTitle>
            <Styled.SecondaryTitle>{secondaryText}</Styled.SecondaryTitle>
            {button && <Styled.Button size="small" kind="primary" {...button} />}
        </Styled.NoDataWrapper>
    );
};
