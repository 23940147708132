/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BeginSubscribeResponseWebResponse } from '../models/SubscriptionsService/BeginSubscribeResponseWebResponse';
import type { ConfirmSignalSubscribeRequest } from '../models/SubscriptionsService/ConfirmSignalSubscribeRequest';
import type { ConfirmSignalSubscribeResponseWebResponse } from '../models/SubscriptionsService/ConfirmSignalSubscribeResponseWebResponse';
import type { ConfirmSubscribeRequest } from '../models/SubscriptionsService/ConfirmSubscribeRequest';
import type { ConfirmSubscribeResponseWebResponse } from '../models/SubscriptionsService/ConfirmSubscribeResponseWebResponse';
import type { DeliverBy } from '../models/shared/DeliverBy';
import type { DryRunSyncChangeDtoIReadOnlyCollectionWebResponse } from '../models/SubscriptionsService/DryRunSyncChangeDtoIReadOnlyCollectionWebResponse';
import type { IirDtoPagedWebResponse } from '../models/SubscriptionsService/IirDtoPagedWebResponse';
import type { OrderByProperty } from '../models/shared/OrderByProperty';
import type { PagedWebResponse_GetSubscriptionsWebResponse } from '../models/SubscriptionsService/PagedWebResponse_GetSubscriptionsWebResponse';
import type { PagedWebResponse_MySubscriptionDto } from '../models/SubscriptionsService/PagedWebResponse_MySubscriptionDto';
import type { PagedWebResponse_MySubscriptionV2Dto_ILeanProductDto } from '../models/SubscriptionsService/PagedWebResponse_MySubscriptionV2Dto_ILeanProductDto';
import type { SignalSubscribeResponseWebResponse } from '../models/SubscriptionsService/SignalSubscribeResponseWebResponse';
import type { SyncAttemptDtoPagedWebResponse } from '../models/SubscriptionsService/SyncAttemptDtoPagedWebResponse';
import type { UsSignalSubscriptionDtoIReadOnlyListWebResponse } from '../models/SubscriptionsService/UsSignalSubscriptionDtoIReadOnlyListWebResponse';
import type { UsSubscriptionDtoIReadOnlyCollectionWebResponse } from '../models/SubscriptionsService/UsSubscriptionDtoIReadOnlyCollectionWebResponse';
import type { UsSubscriptionDtoWebResponse } from '../models/shared/UsSubscriptionDtoWebResponse';
import type { WebResponse } from '../models/shared/WebResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {
    /**
     * @returns PagedWebResponse_GetSubscriptionsWebResponse Success
     * @throws ApiError
     */
    public static getSubscriptionsPrivate({
        productType,
        productIds,
        page,
        pageSize,
    }: {
        productType?: string | null;
        productIds?: Array<number> | null;
        page?: number;
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_GetSubscriptionsWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/subscriptions',
            query: {
                ProductType: productType,
                ProductIds: productIds,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Подписки пользователя
     * @returns PagedWebResponse_MySubscriptionV2Dto_ILeanProductDto Success
     * @throws ApiError
     */
    public static getSubscriptionsV2({
        productId,
        page,
        pageSize,
        productTypes,
        archived,
        showOnlyLastArchived,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number | null;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
        /**
         * Типы продуктов
         */
        productTypes?: Array<string> | null;
        /**
         * Архивная ли подписка
         */
        archived?: boolean | null;
        /**
         * Возвращать только последнюю архивную подписку по каждому продукту
         */
        showOnlyLastArchived?: boolean;
    }): CancelablePromise<PagedWebResponse_MySubscriptionV2Dto_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/my/subscriptions',
            query: {
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
                ProductTypes: productTypes,
                Archived: archived,
                ShowOnlyLastArchived: showOnlyLastArchived,
            },
        });
    }

    /**
     * Подписки пользователя
     * @returns PagedWebResponse_MySubscriptionDto Success
     * @throws ApiError
     */
    public static getSubscriptions({
        productId,
        page,
        pageSize,
        productTypes,
        archived,
        showOnlyLastArchived,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number | null;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
        /**
         * Типы продуктов
         */
        productTypes?: Array<string> | null;
        /**
         * Архивная ли подписка
         */
        archived?: boolean | null;
        /**
         * Возвращать только последнюю архивную подписку по каждому продукту
         */
        showOnlyLastArchived?: boolean;
    }): CancelablePromise<PagedWebResponse_MySubscriptionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/subscriptions',
            query: {
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
                ProductTypes: productTypes,
                Archived: archived,
                ShowOnlyLastArchived: showOnlyLastArchived,
            },
        });
    }

    /**
     * История подписок пользователя, включает только подписки которые были удалены
     * @returns PagedWebResponse_MySubscriptionDto Success
     * @throws ApiError
     */
    public static getArchivedSubscriptions({
        productId,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_MySubscriptionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/subscriptions/history',
            query: {
                ProductId: productId,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Получить информацию о подписке пользователя
     * @returns UsSubscriptionDtoWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionAf({
        id,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
    }): CancelablePromise<UsSubscriptionDtoWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Архивировать подписку
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static deleteApiVSubscriptionAf({
        id,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
    }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/subscriptions/af/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Получить подписки пользователя
     * @returns UsSubscriptionDtoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionsAf(): CancelablePromise<UsSubscriptionDtoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Начать подписку на стратегию
     * @returns BeginSubscribeResponseWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionsAfBeginSubscribe({
        strategyId,
    }: {
        /**
         * Идентификатор стратегии
         */
        strategyId?: number;
    }): CancelablePromise<BeginSubscribeResponseWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af/begin-subscribe',
            query: {
                StrategyId: strategyId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Подтвердить подписку на стратегию
     * @returns ConfirmSubscribeResponseWebResponse Success
     * @throws ApiError
     */
    public static createApiVSubscriptionsAfSubscribe({
        requestBody,
    }: {
        /**
         * Даные для подписки
         */
        requestBody?: ConfirmSubscribeRequest;
    }): CancelablePromise<ConfirmSubscribeResponseWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/subscriptions/af/subscribe',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Начать подписку на сигналы
     * @returns SignalSubscribeResponseWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionsSignalSubscribe({
        strategyId,
        deliverBy,
        endpoint,
    }: {
        /**
         * Идентификатор стратегии
         */
        strategyId?: number;
        /**
         * Доставка по email или sms
         */
        deliverBy?: DeliverBy;
        /**
         * Куда пользователь хочет получать оповещения о сигналах - телефон или email
         */
        endpoint?: string;
    }): CancelablePromise<SignalSubscribeResponseWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/signal/subscribe',
            query: {
                StrategyId: strategyId,
                DeliverBy: deliverBy,
                Endpoint: endpoint,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Подтвердить подписку на сигналы
     * @returns ConfirmSignalSubscribeResponseWebResponse Success
     * @throws ApiError
     */
    public static createApiVSubscriptionsSignalConfirm({
        requestBody,
    }: {
        /**
         * Данные запроса
         */
        requestBody?: ConfirmSignalSubscribeRequest;
    }): CancelablePromise<ConfirmSignalSubscribeResponseWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/subscriptions/signal/confirm',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Пробная синхронизация
     * @returns DryRunSyncChangeDtoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionAfDryRunSync({
        id,
        ratio,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
        /**
         * Коэффициент синхронизации
         */
        ratio?: number;
    }): CancelablePromise<DryRunSyncChangeDtoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af/dry-run-sync/{id}',
            path: {
                id: id,
            },
            query: {
                ratio: ratio,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Синхронизация
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static createApiVSubscriptionAfSync({
        id,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
    }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/subscriptions/af/sync/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Получить историю синхронизаций по подписке
     * @returns SyncAttemptDtoPagedWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionAfSyncHistory({
        id,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<SyncAttemptDtoPagedWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af/{id}/sync-history',
            path: {
                id: id,
            },
            query: {
                Page: page,
                PageSize: pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Возвращает историю сигналов подписки
     * @returns IirDtoPagedWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionAfSignalsHistory({
        id,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Id подписки
         */
        id: number;
        page?: number;
        pageSize?: number;
        orderBy?: Array<OrderByProperty>;
    }): CancelablePromise<IirDtoPagedWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/af/{id}/signals-history',
            path: {
                id: id,
            },
            query: {
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Удалить подписку по сигналам
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static deleteApiVSubscriptionSignal({
        id,
    }: {
        /**
         * Идентификатор подписки
         */
        id: number;
    }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/subscriptions/signal/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Получить подписки пользователя на сигналы
     * @returns UsSignalSubscriptionDtoIReadOnlyListWebResponse Success
     * @throws ApiError
     */
    public static getApiVSubscriptionsSignal(): CancelablePromise<UsSignalSubscriptionDtoIReadOnlyListWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/subscriptions/signal',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
