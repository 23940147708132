import produce from 'immer';

import { Id, PublishStatusRecord, isPublishStatusRecord, UserChoice } from 'types';
import { CommentExporter } from 'services/api-services/generated_limex';

import { FeedState } from '../types';
import { getCommentsAndPath, index } from '../helpers';
import { calculateLikes } from '../_shared/_sub/like/reducerUtils';

import { SEND_RATING_COMMENT_ACTION_TYPES, SendCommentRatingReactionActions } from './actions';

const [SEND_RATING_COMMENT_REQUEST, SEND_RATING_COMMENT_SUCCESS, SEND_RATING_COMMENT_FAILURE] =
    SEND_RATING_COMMENT_ACTION_TYPES;

function getComment(record: PublishStatusRecord, commentId: Id): CommentExporter | undefined {
    const { comments } = getCommentsAndPath(record);
    const commentIndex = comments.findIndex((_comment) => _comment.id === commentId);
    if (commentIndex === -1) {
        return undefined;
    }
    return comments[commentIndex];
}

const updateLikes = (state: FeedState, recordId: Id, commentId: Id, choice: UserChoice | null) =>
    produce(state, (draft) => {
        const record = draft.records[index(recordId)];
        if (isPublishStatusRecord(record)) {
            const comment = getComment(record, commentId);

            if (comment) {
                comment.realLikeCount = (comment.realLikeCount ?? 0) + ((choice ?? 0) * 2 - 1);
                comment.stats.likes = calculateLikes(comment.stats.likes, choice);
            }
        }
    });

function revertLikes(state: FeedState, recordId: Id, commentId: Id, prevRecordState: PublishStatusRecord) {
    const prevComment = getComment(prevRecordState, commentId);
    if (!prevComment) {
        return state;
    }

    const {
        stats: { likes },
        realLikeCount,
    } = prevComment;

    return produce(state, (draft) => {
        const record = draft.records[index(recordId)];
        if (isPublishStatusRecord(record)) {
            const comment = getComment(record, commentId);
            if (comment) {
                comment.stats.likes = likes;
                comment.realLikeCount = realLikeCount;
            }
        }
    });
}

export default (state: FeedState, action: SendCommentRatingReactionActions) => {
    switch (action.type) {
        case SEND_RATING_COMMENT_REQUEST: {
            const {
                payload: {
                    pass,
                    params: { requestBody },
                },
            } = action;
            const { objectId: commentId } = requestBody;
            const { newChoice, recordId } = pass;
            return updateLikes(state, recordId, commentId, newChoice);
        }
        case SEND_RATING_COMMENT_SUCCESS: {
            const {
                payload: { pass, data },
                meta: {
                    requestAction: {
                        payload: {
                            params: { requestBody },
                        },
                    },
                },
            } = action;
            if ('message' in data) {
                const { objectId: commentId } = requestBody;
                const { prevSpecialState, recordId } = pass;
                if (prevSpecialState) {
                    return revertLikes(state, recordId, commentId, prevSpecialState);
                }
            }
            return state;
        }
        case SEND_RATING_COMMENT_FAILURE: {
            const {
                payload: { pass },
                meta: {
                    requestAction: {
                        payload: {
                            params: { requestBody },
                        },
                    },
                },
            } = action;
            const { objectId: commentId } = requestBody;
            const { prevSpecialState, recordId } = pass;
            return prevSpecialState ? revertLikes(state, recordId, commentId, prevSpecialState) : state;
        }
        default:
            return state;
    }
};
