import { Action, FeedRecord } from 'types';

export type InsertRecordPayloadData = {
    records: FeedRecord[];
    pos: number;
};
export const INSERT_FEED_RECORDS_ACTION = 'widgets/INSERT_FEED_RECORDS' as const;
export function insertFeedRecord(records: FeedRecord[], pos: number, instanceId: string) {
    return {
        type: INSERT_FEED_RECORDS_ACTION,
        payload: {
            records,
            pos,
        },
        pass: {
            instanceId,
        },
    };
}
export type InsertFeedRecord = Action<typeof insertFeedRecord>;
export type InsertFeedRecordActions = ReturnType<typeof insertFeedRecord>;
