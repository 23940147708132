import { css } from 'styled-components';

import { InlineLabel, Input, RightGroupWrapper } from '../base';

export const baseStyles = css`
    & ${Input} {
        color: ${({ theme }) => theme.label.primary};
    }

    & ${Input}::placeholder, & ${InlineLabel}, & ${RightGroupWrapper} {
        color: ${({ theme }) => theme.label.secondary};
    }

    &:focus-within {
        & ${InlineLabel} {
            color: ${({ theme }) => theme.label.accent};
        }

        & ${Input}::placeholder {
            color: ${({ theme }) => theme.label.placeholder};
        }
    }
`;
