import styled from 'styled-components';

import { ImageLinkProps } from '../types';

import { ImageBlock } from './ImageBlock.styled';

export const ImageLink = styled.a<Pick<ImageLinkProps, 'href'>>`
    display: inline-block;

    ${ImageBlock} {
        display: block;
        cursor: ${({ href }) => (href ? 'pointer' : 'inherit')};
    } ;
`;
