import { RequestTypes } from 'services/create-action-types/shared/types';

import { FailureAction, SuccessAction } from '../actions/types';
import { AsyncMethod, Cache, CacheData, ResourcesMap } from '../types';
import { DeleteResourceAction } from '../actions/deleteData';
import { UpdateResourceAction } from '../actions/updateResource';
import { cacheCommit } from '../cache/cacheCommit';
import { cacheRevert } from '../cache/cacheRevert';
import { cacheDelete } from '../cache/cacheDelete';
import { nameToAction } from '../utils/nameToAction';
import { cacheWrite } from '../cache/cacheWrite';

export function createResourcesReducer<
    TYPES_MAP extends object,
    RESOURCE_NAME extends string,
    RESOURCE_TYPE,
    API_METHOD extends AsyncMethod,
>({
    ACTION_TYPES,
    resourceName,
    resourcesMap,
}: {
    ACTION_TYPES?: RequestTypes<string, 'cache'>;
    resourceName: RESOURCE_NAME;
    resourcesMap: ResourcesMap<TYPES_MAP>;
}) {
    const [, SUCCESS, FAILURE] = ACTION_TYPES ?? ['', ''];
    return (
        state: Cache['data'] = { resources: {}, requests: {}, patches: {} },
        action:
            | SuccessAction<API_METHOD>
            | FailureAction<API_METHOD>
            | UpdateResourceAction<RESOURCE_NAME, RESOURCE_TYPE>
            | DeleteResourceAction,
    ): Cache['data'] => {
        switch (action.type) {
            case SUCCESS: {
                const {
                    payload: {
                        data,
                        pass: { operationId },
                    },
                    meta: {
                        requestAction: {
                            payload: { params },
                        },
                    },
                } = action as SuccessAction<API_METHOD>;

                const updatedCache: CacheData = cacheWrite({
                    kind: 'request',
                    cache: state,
                    obj: { args: params, result: data },
                    typename: resourceName,
                    resourcesMap,
                });
                return cacheCommit({ cache: updatedCache, operationId });
            }
            case FAILURE: {
                const {
                    payload: {
                        pass: { operationId },
                    },
                } = action as FailureAction<API_METHOD>;
                if (operationId) {
                    return cacheRevert({ cache: state, operationId });
                }
                return state;
            }
            case `cache/UPDATE_${nameToAction(resourceName)}`: {
                const { payload } = action as UpdateResourceAction<RESOURCE_NAME, RESOURCE_TYPE>;
                return cacheWrite({
                    kind: payload.kind,
                    cache: state,
                    obj: payload.value,
                    typename: resourceName,
                    resourcesMap,
                    operationId: payload.operationId,
                });
            }
            case `cache/DELETE_${nameToAction(resourceName)}`: {
                const {
                    payload: { kind, id },
                } = action as DeleteResourceAction;
                if (Array.isArray(id)) {
                    let updatedState = state;
                    id.forEach((element) => {
                        updatedState = cacheDelete({
                            cache: updatedState,
                            kind,
                            typename: resourceName,
                            id: `${element}`,
                        });
                    });
                    return updatedState;
                }
                return cacheDelete({ cache: state, kind, typename: resourceName, id: `${id}` });
            }
            default:
                return state;
        }
    };
}
