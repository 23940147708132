import React, { useCallback } from 'react';

import { Tags, MapIdToEmoji, SelectedTagsIds, TagData } from 'components/tags';
import { Slider as SliderComponent } from 'components/slider';

import { TabIds } from '../../tabs';

import { Tag } from './styled';

export const TagsSlider = (props: {
    tabId: TabIds;
    tagsForSlider: TagData[];
    selectedIds: SelectedTagsIds;
    setSelectedIds: (selectedIds: SelectedTagsIds, tabId: TabIds) => void;
}) => {
    const { tagsForSlider, selectedIds, setSelectedIds, tabId } = props;

    const handleSelectTag = useCallback<(arg: { selectedIdsCandidate: SelectedTagsIds }) => void>(
        ({ selectedIdsCandidate }) => {
            setSelectedIds(selectedIdsCandidate, tabId);
        },
        [setSelectedIds, tabId],
    );

    if (!tagsForSlider.length) return null;

    return (
        <Tags tags={tagsForSlider} selectedIds={selectedIds} onSelectTag={handleSelectTag}>
            {({ onSelect }) => (
                <SliderComponent
                    clearDefaultProps="item"
                    arrows={false}
                    dots={false}
                    item={{
                        indent: 8,
                        paddingTop: 12,
                    }}
                >
                    {tagsForSlider.map((tag) => {
                        const { id, name } = tag;
                        const isActive = !!selectedIds.find((selectedId) => selectedId === id);
                        return (
                            <Tag
                                key={id}
                                Icon={MapIdToEmoji[id]}
                                text={name}
                                size="small"
                                draggable={false}
                                isActive={isActive}
                                onClick={() => onSelect(isActive, tag)}
                            />
                        );
                    })}
                </SliderComponent>
            )}
        </Tags>
    );
};
