import styled from 'styled-components';
import { prop } from 'styled-tools';

import { animations, mediaQuery } from 'app/styled';
import { LoadingRingIcon } from 'components/icons2/LoadingRingIcon';

export const Overlay = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
    z-index: 999;
    flex-direction: column;
`;

export const ProcessingIcon = styled(LoadingRingIcon)`
    animation: ${animations.spin} 0.5s infinite ease-in-out;
`;

export const Wrapper = styled.div`
    display: block;
    height: 90%;
    ${mediaQuery.lt960} {
        height: 75%;
    }
    ${mediaQuery.lt480} {
        height: 90%;
    }
`;

export const Counter = styled.div`
    display: none;
    color: ${({ theme }) => theme.label.inverse};
    margin-left: 20px;
    ${mediaQuery.lt480} {
        display: inline-block;
    }
`;

export const CloseBox = styled.div`
    justify-content: flex-end;
    width: 100%;
    height: 10%;
    display: flex;
    position: relative;
    z-index: 999999;
    ${mediaQuery.lt960} {
        height: 0;
    }
    ${mediaQuery.lt480} {
        height: 10%;
        background: ${({ theme }) => theme.bg.dialogOverlay};
        align-items: center;
        justify-content: space-between;
    }
`;

export const CloseButtonContainer = styled.div`
    margin-right: 40px;
    margin-top: 40px;
    ${mediaQuery.lt960} {
        margin-right: 25px;
        margin-top: -10px;
    }
    ${mediaQuery.lt480} {
        margin-top: 0;
    }
`;

export const ActiveImageBox = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    height: 80%;
    ${mediaQuery.lt768} {
        height: 72%;
    }
    ${mediaQuery.lt480} {
        height: 80%;
    }
`;

export const ActiveImg = styled.img`
    max-height: 100%;
    max-width: 100%;
`;

export const ThumbnailsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ThumbnailsContainer = styled.div<{ size: number }>`
    display: flex;
    max-width: ${prop('size')}px;
    justify-content: flex-start;
    overflow: hidden;
    margin: 0 16px;
`;

export const ArrowContainer = styled.div`
    margin: 0 20px;
    width: 40px;
    height: 40px;
    ${mediaQuery.lt960} {
        display: none;
    }
`;

export const Thumbnails = styled.div<{ shift: number }>`
    display: flex;
    justify-content: flex-start;
    transform: translateX(${prop('shift')}px);
    transition-duration: 400ms;
`;

export const Thumbnail = styled.div<{ bg: string; size: number }>`
    display: block;
    border-radius: 4px;
    width: ${prop('size')}px;
    min-width: ${prop('size')}px;
    height: ${prop('size')}px;
    margin-right: 8px;
    cursor: pointer;
    background: url('${prop('bg')}') no-repeat 100% 100%;
    background-size: cover;
    &:hover {
        box-shadow: ${({ theme }) => theme.focusOutline.accent};
    }
    &:last-child {
        margin-right: 0;
    }
`;
