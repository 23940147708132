/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventsIncoming } from '../models/DefaultService/EventsIncoming';
import type { EventsOutgoing_Input } from '../models/DefaultService/EventsOutgoing_Input';
import type { HealthCheckResponse } from '../models/DefaultService/HealthCheckResponse';
import type { HistoryEvents_Input } from '../models/DefaultService/HistoryEvents_Input';
import type { User } from '../models/DefaultService/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {
    /**
     * Health Check
     * Run health check
     * @returns HealthCheckResponse Health check is succeed
     * @throws ApiError
     */
    public static getHealthcheck(): CancelablePromise<HealthCheckResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/healthcheck',
            errors: {
                500: `Health check is failed`,
            },
        });
    }

    /**
     * Get message history
     * Get message history
     * @returns HistoryEvents_Input Succeed get message history
     * @throws ApiError
     */
    public static getHistory({ requestBody }: { requestBody: User }): CancelablePromise<HistoryEvents_Input> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process/get_history',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
                500: `Failed get message history`,
            },
        });
    }

    /**
     * Process incoming events
     * Process incoming events
     * @returns EventsOutgoing_Input Events processing is succeed
     * @throws ApiError
     */
    public static createEvent({
        requestBody,
    }: {
        requestBody: EventsIncoming;
    }): CancelablePromise<EventsOutgoing_Input> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/process/event',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
                500: `Events processing is failed`,
            },
        });
    }
}
