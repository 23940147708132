import React, { useMemo } from 'react';

import { ContextActionCopyLink } from 'widgets/context-action-copy-link';
import { ButtonIconKinds, ContextActions } from 'components/context-actions';

import ContextActionEdit from './ContextActionEdit';
import ContextActionDelete from './ContextActionDelete';
import {useHandleShare} from "widgets/feed/useHandleShare";

type Props = {
    shareUrl: string;
    canEdit: boolean;
    canDelete: boolean;
    onDelete: () => void;
    recordId: string | number;
};

export const ContextActionsRecord = (props: Props) => {
    const { shareUrl, canEdit, canDelete, onDelete, recordId } = props;
    const handleShare = useHandleShare(recordId);

    const tippyContent = useMemo(
        () => (
            <div>
                <ContextActionCopyLink url={shareUrl} onClick={handleShare} />
                {canEdit && <ContextActionEdit />}
                {canDelete && <ContextActionDelete onClick={onDelete} />}
            </div>
        ),
        [canEdit, canDelete, onDelete],
    );

    return <ContextActions tippyContent={tippyContent} kind={ButtonIconKinds.GhostSecondary} />;
};
