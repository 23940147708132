import { createContext, createElement, ReactElement } from 'react';

import { defaultPerson } from 'types/constants';
import { CurrentUser } from 'types';
import { User } from 'services/user';

export const guestUser = defaultPerson;

export const CurrentUserContext = createContext<User>(new User(guestUser));
export const createCurrentUserContext = (reactElement: ReactElement, user: CurrentUser) =>
    createElement(CurrentUserContext.Provider, { value: new User(user) }, reactElement);
