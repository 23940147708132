import React, { useState, useEffect, useMemo } from 'react';

import { useKeyPress } from 'hooks/useKeyPress';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { RoundButton } from 'components/round-button';
import { ArrowLeftMiniIcon } from 'components/icons2/ArrowLeftMiniIcon';
import { ArrowRightMiniIcon } from 'components/icons2/ArrowRightMiniIcon';
import { CloseIcon } from 'components/icons2/CloseIcon';

import {
    ActiveImageBox,
    ActiveImg,
    CloseBox,
    CloseButtonContainer,
    Counter,
    Overlay,
    ProcessingIcon,
    Thumbnail,
    Thumbnails,
    ThumbnailsContainer,
    ThumbnailsWrapper,
    Wrapper,
    ArrowContainer,
} from './styled';

export type GalleryModel = {
    index: number;
    src: string;
};

export type GalleryInitFunction = (index: number, imgList: GalleryModel[]) => void;

const Gallery = ({
    imgList,
    numSlide = 0,
    onClose,
}: {
    imgList: GalleryModel[];
    numSlide: number;
    onClose: () => void;
}) => {
    let touchstartX = 0;
    let touchendX = 0;

    const [swipeAction, setSwipeAction] = useState('');
    const [loading, setLoading] = useState(false);
    const [shift, setShift] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(numSlide);
    const [activeImg, setActiveImg] = useState({
        index: 0,
        src: '',
    });

    const isDesktop = useMatchMedia('@media (min-width: 1181px)');
    const isLarge = useMatchMedia('@media (min-width: 800px)');
    const isMedium = useMatchMedia('@media (min-width: 600px)');

    const thumbnailsSizes = useMemo(() => {
        if (isLarge) {
            return {
                thumbnailSize: 80,
                thumbnailsCount: 8,
                thumbnailsContainer: 696,
            };
        }
        if (isMedium) {
            return {
                thumbnailSize: 80,
                thumbnailsCount: 6,
                thumbnailsContainer: 520,
            };
        }
        return {
            thumbnailSize: 60,
            thumbnailsCount: 3,
            thumbnailsContainer: 198,
        };
    }, [isLarge, isMedium]);

    useEffect(() => {
        setShift(0);
    }, [thumbnailsSizes]);

    useEffect(() => {
        setActiveImg(imgList[numSlide]);

        const swipeStartCallback = (e: TouchEvent) => {
            touchstartX = e.changedTouches[0].screenX;
        };

        const swipeEndCallback = (e: TouchEvent) => {
            touchendX = e.changedTouches[0].screenX;
            if (touchendX < touchstartX) setSwipeAction('left');
            if (touchendX > touchstartX) setSwipeAction('right');
        };

        window.addEventListener('touchstart', swipeStartCallback);
        window.addEventListener('touchend', swipeEndCallback);
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'pan-x';

        return () => {
            window.removeEventListener('touchstart', swipeStartCallback);
            window.removeEventListener('touchend', swipeEndCallback);
            document.body.style.overflow = 'auto';
            document.body.style.touchAction = 'auto';
        };
    }, []);

    useEffect(() => {
        if (swipeAction === 'left') nextImg();
        if (swipeAction === 'right') prevImg();
        setSwipeAction('');
    }, [swipeAction]);

    useKeyPress({ key: 'ArrowLeft', onKeyDown: () => prevImg() });
    useKeyPress({ key: 'ArrowRight', onKeyDown: () => nextImg() });
    useKeyPress({ key: 'Escape', onKeyDown: () => onClose() });

    const isChangeLastImg = currentIndex < imgList.length - 1;
    const isChangeFirstImg = currentIndex > 0;

    const nextImg = () => {
        if (isChangeLastImg) {
            const nextIndex = currentIndex + 1;

            setCurrentIndex(nextIndex);
            setActiveImg(imgList[nextIndex]);
            if (nextIndex >= thumbnailsSizes.thumbnailsCount) shiftThumbnailsToRight();
        }
    };

    const prevImg = () => {
        if (isChangeFirstImg) {
            const prevIndex = currentIndex - 1;

            setCurrentIndex(prevIndex);
            setActiveImg(imgList[prevIndex]);
            if (currentIndex + 1 >= thumbnailsSizes.thumbnailsCount) shiftThumbnailsToLeft();
        }
    };

    const changeImg = (index: number) => {
        setCurrentIndex(index);
        setActiveImg(imgList[index]);
    };

    const shiftThumbnailsToLeft = () => {
        if (shift < 0) {
            setShift(shift + (thumbnailsSizes.thumbnailSize + 8));
        }
    };

    const shiftThumbnailsToRight = () => {
        const movingPartWidth = imgList.length * (thumbnailsSizes.thumbnailSize + 8);
        const invisibleThumbnails = imgList.length - thumbnailsSizes.thumbnailsCount;
        const shearDifference = invisibleThumbnails - -shift / thumbnailsSizes.thumbnailSize;

        if (movingPartWidth > thumbnailsSizes.thumbnailsContainer) {
            if (shearDifference > 0) setShift(shift - (thumbnailsSizes.thumbnailSize + 8));
        }
    };

    const loadImage = () => {
        setLoading(true);
    };

    const onClickOverlay = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { target } = event;
        if (target && target instanceof HTMLElement && target.id === 'gallery-wrapper' && isDesktop) {
            onClose();
        }
    };

    const arrowsShown = imgList.length > thumbnailsSizes.thumbnailsCount;

    return (
        <Overlay>
            <CloseBox>
                <Counter>{`${currentIndex + 1} / ${imgList.length}`}</Counter>
                <CloseButtonContainer>
                    <RoundButton kind="staticColor" size="compact" onClick={() => onClose()}>
                        <CloseIcon />
                    </RoundButton>
                </CloseButtonContainer>
            </CloseBox>
            <Wrapper>
                <ActiveImageBox id="gallery-wrapper" onClick={(e) => onClickOverlay(e)}>
                    <ArrowContainer>
                        {isChangeFirstImg && (
                            <RoundButton kind="staticColor" size="regular" onClick={() => prevImg()}>
                                <ArrowLeftMiniIcon />
                            </RoundButton>
                        )}
                    </ArrowContainer>
                    <ActiveImg style={{ display: loading ? 'block' : 'none' }} src={activeImg.src} onLoad={loadImage} />
                    <div style={{ display: loading ? 'none' : 'block' }}>
                        <ProcessingIcon />
                    </div>
                    <ArrowContainer>
                        {isChangeLastImg && (
                            <RoundButton kind="staticColor" size="regular" onClick={() => nextImg()}>
                                <ArrowRightMiniIcon />
                            </RoundButton>
                        )}
                    </ArrowContainer>
                </ActiveImageBox>
                <ThumbnailsWrapper>
                    {arrowsShown ? (
                        <RoundButton kind="staticColor" size="regular" onClick={() => shiftThumbnailsToLeft()}>
                            <ArrowLeftMiniIcon />
                        </RoundButton>
                    ) : null}
                    <ThumbnailsContainer size={thumbnailsSizes.thumbnailsContainer}>
                        <Thumbnails shift={shift}>
                            {imgList.map((item) => (
                                <Thumbnail
                                    size={thumbnailsSizes.thumbnailSize}
                                    bg={item.src}
                                    key={item.index}
                                    onClick={() => changeImg(item.index)}
                                />
                            ))}
                        </Thumbnails>
                    </ThumbnailsContainer>
                    {arrowsShown ? (
                        <RoundButton kind="staticColor" size="regular" onClick={() => shiftThumbnailsToRight()}>
                            <ArrowRightMiniIcon />
                        </RoundButton>
                    ) : null}
                </ThumbnailsWrapper>
            </Wrapper>
        </Overlay>
    );
};

export default Gallery;
