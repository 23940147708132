import { Draft } from 'immer';

import { Action } from 'types';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';
import {
    DefaultService,
    HistoryEvent,
    HistoryEvents_Input as HistoryEventsInput,
} from 'services/api-services/generated_chat';

import { ChatEventsState, GlobalStateForChat } from './types';

const [FETCH_CHAT_HISTORY_ACTION_TYPES, historyThunk, historyActions] = createThunkActions<
    'widgets',
    GlobalStateForChat
>('widgets')('FETCH_CHAT_HISTORY', DefaultService.getHistory)();

const fetchHistory = (sessionId: string) =>
    historyThunk({
        params: {
            requestBody: {
                session_id: sessionId,
            },
        },
    });
type FetchHistory = Action<typeof fetchHistory>;
type FetchHistoryActions = Actions<typeof historyActions>;
export { FETCH_CHAT_HISTORY_ACTION_TYPES, fetchHistory, FetchHistory, FetchHistoryActions };

const [CREATE_CHAT_EVENT_ACTION_TYPES, createThunk, createActions] = createThunkActions<'widgets', GlobalStateForChat>(
    'widgets',
)('CREATE_CHAT_EVENT', DefaultService.createEvent)<never, UpdateChatHistoryAction>();

export const UPDATE_CHAT_HISTORY = 'widgets/UPDATE_CHAT_HISTORY';
type UpdateChatHistoryProps = Omit<HistoryEvent, 'params'> & { text: string };
export type UpdateChatHistoryAction = {
    type: typeof UPDATE_CHAT_HISTORY;
    payload: UpdateChatHistoryProps;
};

const updateChatHistory = ({ eventId, type, text, origin }: UpdateChatHistoryProps): UpdateChatHistoryAction => ({
    type: UPDATE_CHAT_HISTORY,
    payload: { eventId, type, text, origin },
});

function createEvent(
    sessionId: string,
    eventId: string,
    type: 'text',
    text: string,
    origin: 'human' | 'ai',
): ReturnType<typeof createThunk> {
    return (dispatch, getState, extraArgument) => {
        dispatch(updateChatHistory({ eventId, type, origin, text }));
        return createThunk({
            params: {
                requestBody: {
                    user: { session_id: sessionId },
                    events: [
                        {
                            eventId,
                            type,
                            params: {
                                text,
                            },
                            origin,
                        },
                    ],
                },
            },
        })(dispatch, getState, extraArgument);
    };
}

type CreateChatEvent = Action<typeof createEvent>;
type CreateChatEventActions = Actions<typeof createActions>;

export { CREATE_CHAT_EVENT_ACTION_TYPES, createEvent, CreateChatEvent, CreateChatEventActions };

export type ChatActions = CreateChatEventActions | FetchHistoryActions | UpdateChatHistoryAction;
