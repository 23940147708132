import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { body14RegularFontStyle } from 'services/typography';

import { ListBoxOptions } from '../Options';
import { ListBoxButton as ListBoxButtonComponent } from '../Button';
import { ListBoxOptionsContainerInner } from '../styled';

import { OptionsPositionParamsType } from './types';

export const ListBoxButton = styled(ListBoxButtonComponent)`
    overflow: hidden;
`;

export const Placeholder = styled.span`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const Options = styled(ListBoxOptions)<{
    optionsPositionParams: OptionsPositionParamsType;
    minimumIndentFromOptionsToEdgeScreen: number;
    isOpen: boolean;
    isRounded: boolean;
}>`
    position: fixed;
    z-index: 10002;
    transition: opacity 260ms, height 200ms;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    ${({ optionsPositionParams, minimumIndentFromOptionsToEdgeScreen }) => css`
        top: ${optionsPositionParams?.top || 0}px;
        right: ${optionsPositionParams?.right || 0}px;
        bottom: ${optionsPositionParams?.bottom || 0}px;
        left: ${optionsPositionParams?.left || 0}px;
        width: ${optionsPositionParams?.width || 0}px;
        height: ${optionsPositionParams.height === 'auto'
            ? 'auto'
            : `${optionsPositionParams?.height - minimumIndentFromOptionsToEdgeScreen}px`};
    `};

    ${ListBoxOptionsContainerInner} {
        overflow: auto;
        ${({ optionsPositionParams, minimumIndentFromOptionsToEdgeScreen }) => css`
            height: ${optionsPositionParams.height === 'auto'
                ? 'auto'
                : `${optionsPositionParams?.height - minimumIndentFromOptionsToEdgeScreen}px`};
        `};
    }

    ${ifProp(
        'isRounded',
        css`
            ${ListBoxOptionsContainerInner} {
                border-radius: 24px;
            }
        `,
    )};
`;

export const FooterOptions = styled.div``;
