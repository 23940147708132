import React, { useState, useRef } from 'react';

import { Person } from 'types';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { List } from 'components/list';
import { Button } from 'components/button2';

import { PersonItem } from './PersonItem';
import { Wrapper } from './styled/PeopleList';

type Props = {
    nextCursor: number;
    people: Person[];
    receivePeople: () => Promise<void>;
    togglePopup: () => void;
};

function getScrollParent(node: HTMLElement | null, isInitial = true): HTMLElement | null {
    if (node === null) {
        return null;
    }

    if (window.getComputedStyle(node).overflowY === 'auto' && !isInitial) {
        return node;
    }
    return getScrollParent(node.parentElement, false);
}

export const PeopleList = (props: Props) => {
    const { people, receivePeople, nextCursor, togglePopup } = props;
    const [loading, setLoading] = useState(false);

    const handleReceivePeople = () => {
        setLoading(true);
        return receivePeople().finally(() => {
            setLoading(false);
        });
    };

    const listRef = useRef(null);
    const { sentinelElement } = useInfiniteScroll({
        scrollRootElement: getScrollParent(listRef.current),
        onListEndReached: () => {
            if (nextCursor && !loading) handleReceivePeople();
        },
    });

    return (
        <Wrapper>
            <List
                wrapperRef={listRef}
                items={people.map((person) => ({ key: person.id, ...person }))}
                renderItem={(person) => <PersonItem person={person} togglePopup={togglePopup} />}
            >
                {loading && <Button kind="ghost-secondary" isProcessing />}
                {sentinelElement}
            </List>
        </Wrapper>
    );
};
