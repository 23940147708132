import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { caption11MediumFontStyle, h5MediumFontStyle } from 'services/typography';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 4px 12px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.divider.primary};
    margin-top: -4px;

    ${mediaQuery.lt768} {
        margin-top: 0;
        padding: 16px 4px 12px 16px;
    }
`;

export const LeftPart = styled.div`
    display: flex;
    align-items: center;
    cursor: default;
`;

export const BeforeWrapper = styled.div`
    margin-right: 8px;
`;

export const Title = styled.span`
    ${h5MediumFontStyle};
    cursor: default;
`;

export const CounterBadgeWrapper = styled.div`
    background-color: ${({ theme }) => theme.fillIn.primary.mid};
    border-radius: 4px;
    margin-left: 8px;
    min-width: 18px;
    height: 18px;
    text-align: center;
    padding: 1px 5px;
    box-sizing: border-box;
    line-height: 1;
    display: flex;
`;

export const CounterBadgeText = styled.span`
    ${caption11MediumFontStyle};
    color: ${({ theme }) => theme.bg.primary};
`;
