import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const CommentIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 24 24">
        <g clipPath="url(#clip0_3243_37326)">
            <path
                d="M10.6777 12.7079C10.0393 13.6525 9.13379 14.1561 8.65502 13.8321C8.17625 13.5082 8.30627 12.4809 8.94464 11.5364C9.58301 10.5919 10.4885 10.0883 10.9673 10.4122C11.4461 10.7361 11.316 11.7634 10.6777 12.7079Z"
                fill="url(#paint0_linear_3243_37326)"
            />
            <path
                d="M10.6777 12.7079C10.0393 13.6525 9.13379 14.1561 8.65502 13.8321C8.17625 13.5082 8.30627 12.4809 8.94464 11.5364C9.58301 10.5919 10.4885 10.0883 10.9673 10.4122C11.4461 10.7361 11.316 11.7634 10.6777 12.7079Z"
                fill="url(#paint1_linear_3243_37326)"
            />
            <path
                d="M18.4243 18.1982C17.1476 18.1982 17.0932 19.1758 15.5717 19.1758C14.0503 19.1758 13.2358 18.5504 11.8232 17.7088C10.6445 17.0065 9.26025 17.152 8.5332 17.0054L9.50967 17.3919C9.50967 17.3919 10.0913 18.6852 11.6246 19.456C13.847 20.5743 16.4797 19.2869 17.624 19.3649C19.0485 19.4631 20.8111 19.774 21.1385 19.7491C20.9766 19.6203 20.8418 18.1982 18.4243 18.1982Z"
                fill="url(#paint2_linear_3243_37326)"
            />
            <path
                d="M18.4243 18.1982C17.1476 18.1982 17.0932 19.1758 15.5717 19.1758C14.0503 19.1758 13.2358 18.5504 11.8232 17.7088C10.6445 17.0065 9.26025 17.152 8.5332 17.0054L9.50967 17.8258C9.50967 17.8258 10.0913 18.684 11.6246 19.456C13.847 20.5743 16.4797 19.2869 17.624 19.3649C19.0485 19.4631 20.8111 19.774 21.1385 19.7491C20.9766 19.6203 20.8418 18.1982 18.4243 18.1982Z"
                fill="url(#paint3_linear_3243_37326)"
            />
            <path
                d="M18.4243 18.1977C17.1476 18.1977 17.0932 19.1754 15.5717 19.1754C14.0503 19.1754 13.2358 18.55 11.8232 17.7083C10.6445 17.0061 9.26025 17.1515 8.5332 17.4399L9.50967 17.8265C9.50967 17.8265 9.85722 18.3336 10.7119 18.9176C10.8148 18.8408 10.8644 19.1257 10.8384 18.8775C10.6138 16.7224 12.8575 19.4413 14.8672 19.3869C17.1239 19.329 18.4243 18.1977 18.4243 18.1977Z"
                fill="url(#paint4_linear_3243_37326)"
            />
            <path
                d="M5.39665 15.1836L3.02761 18.6036C2.82193 18.9003 2.20603 20.4383 1.81946 21.4254C1.72725 21.6618 1.97906 21.8356 2.16702 21.6654C2.94251 20.9691 4.14832 19.8803 4.43674 19.5789L6.95356 15.9473C6.23479 15.9331 5.6248 15.7475 5.39665 15.1836Z"
                fill="url(#paint5_linear_3243_37326)"
            />
            <path
                d="M1.84082 21.6501C1.9082 21.7435 2.05007 21.7707 2.1671 21.6655C2.9426 20.9692 4.1484 19.8804 4.43685 19.579L6.95364 15.9474C6.73257 15.9427 6.52569 15.919 6.333 15.8765L4.15077 19.021C4.00416 19.1664 3.114 20.4159 1.84082 21.6501Z"
                fill="url(#paint6_linear_3243_37326)"
            />
            <path
                d="M5.60852 15.5335C5.44421 15.42 5.4773 15.3834 5.3969 15.1836L3.02789 18.6036C2.82219 18.9003 2.20628 20.4383 1.81971 21.4254C1.72751 21.6618 3.152 19.0776 3.33287 18.8152L5.60852 15.5335Z"
                fill="url(#paint7_linear_3243_37326)"
            />
            <path
                d="M8.52713 18.8775C9.46931 19.9285 10.3015 20.1992 11.0806 20.1992C11.8596 20.1992 12.0228 19.8186 11.8596 19.5384C11.6965 19.2582 11.226 18.6967 10.4836 18.5879C9.7412 18.4792 9.10757 16.7946 9.10757 16.7946L8.18432 16.7769L7.31543 17.8633L8.52713 18.8775Z"
                fill="url(#paint8_linear_3243_37326)"
            />
            <path
                d="M8.52713 18.8775C9.46931 19.9285 10.3015 20.1992 11.0806 20.1992C11.8596 20.1992 12.0228 19.8186 11.8596 19.5384C11.6965 19.2582 11.226 18.6967 10.4836 18.5879C9.7412 18.4792 9.10757 16.7946 9.10757 16.7946L8.18432 16.7769L7.31543 17.8633L8.52713 18.8775Z"
                fill="url(#paint9_linear_3243_37326)"
            />
            <path
                d="M8.93492 19.1412C8.61218 18.5347 9.09095 18.0725 9.73997 17.998C9.36284 17.4743 9.10634 16.7946 9.10634 16.7946L8.18306 16.7769L7.70903 17.3691L7.6499 17.5382C7.6499 17.5382 9.30611 19.8387 8.93492 19.1412Z"
                fill="url(#paint10_linear_3243_37326)"
            />
            <path
                d="M9.10645 16.7949C9.28258 17.159 9.45871 17.5219 9.68923 17.8518C9.91741 18.1674 10.181 18.5185 10.5818 18.5516C10.9908 18.6344 11.3608 18.8661 11.6339 19.1722C11.7592 19.3318 11.9129 19.4843 11.9412 19.7077C11.9543 19.9418 11.7415 20.0908 11.5535 20.1369C11.7403 20.0742 11.92 19.9099 11.888 19.7125C11.8573 19.5281 11.7001 19.3732 11.5724 19.2302C11.297 18.9476 10.9471 18.7289 10.5605 18.658C10.3583 18.6379 10.1467 18.5233 10.0037 18.379C9.85357 18.2348 9.72706 18.0717 9.61948 17.8967C9.51193 17.7229 9.41617 17.5432 9.32986 17.36C9.24829 17.1744 9.18091 16.9817 9.10645 16.7949Z"
                fill="#804B24"
            />
            <path
                d="M6.91484 19.0407C7.18676 19.9829 8.30978 20.652 9.08765 20.5799C9.5747 20.5349 9.70355 19.7831 9.12431 19.2038C8.54504 18.6246 8.18093 17.6245 8.18093 17.6245L7.65725 15.999L6.84275 18.082L6.31787 19.205L6.91484 19.0407Z"
                fill="url(#paint11_linear_3243_37326)"
            />
            <path
                d="M6.91484 19.0407C7.18676 19.9829 8.30978 20.652 9.08765 20.5799C9.5747 20.5349 9.70355 19.7831 9.12431 19.2038C8.54504 18.6246 8.18093 17.6245 8.18093 17.6245L7.65725 15.999L6.84275 18.082L6.31787 19.205L6.91484 19.0407Z"
                fill="url(#paint12_linear_3243_37326)"
            />
            <path
                d="M8.61488 18.5193C8.34179 18.0571 8.18102 17.6232 8.18102 17.6232L7.91504 16.7969L7.32515 16.8477L6.84284 18.0807L6.56738 18.6694L6.75062 19.0855L6.91496 19.0406C7.03907 19.4697 7.34288 19.8374 7.70582 20.1081C7.32398 19.413 7.92215 18.6162 8.61488 18.5193Z"
                fill="url(#paint13_linear_3243_37326)"
            />
            <path
                d="M9.50607 20.0917C9.50016 19.776 9.33939 19.4947 9.13014 19.2784C8.90319 19.0573 8.72823 18.7854 8.57571 18.5123C8.27073 17.9626 8.02128 17.3857 7.82031 16.7935C8.08986 17.3573 8.35821 17.9212 8.66556 18.4603C8.82633 18.7251 8.97885 18.9946 9.19872 19.2133C9.41505 19.4509 9.55335 19.7772 9.50607 20.0917Z"
                fill="#804B24"
            />
            <path
                d="M4.96436 18.7845C4.96436 19.6262 6.02711 20.5802 6.66191 20.5802C7.29674 20.5802 7.31447 19.729 7.20572 19.4028C7.09694 19.0765 6.93737 18.3589 6.93737 18.3589L6.39122 18.0078L4.96436 18.7845Z"
                fill="url(#paint14_linear_3243_37326)"
            />
            <path
                d="M4.96436 18.7845C4.96436 19.6262 6.02711 20.5802 6.66191 20.5802C7.29674 20.5802 7.31447 19.729 7.20572 19.4028C7.09694 19.0765 6.93737 18.3589 6.93737 18.3589L6.39122 18.0078L4.96436 18.7845Z"
                fill="url(#paint15_linear_3243_37326)"
            />
            <path
                d="M7.04495 18.8199C6.98465 18.5729 6.93617 18.3589 6.93617 18.3589L6.39002 18.0078L4.96436 18.7845C4.96436 19.2999 5.36273 19.8555 5.81786 20.2102C5.69018 19.4961 6.56381 19.0221 7.04495 18.8199Z"
                fill="url(#paint16_linear_3243_37326)"
            />
            <path
                d="M7.17134 20.229C7.22927 20.0186 7.22456 19.8034 7.19735 19.5966C7.16309 19.3991 7.07321 19.1852 7.03421 18.9748C6.93017 18.5539 6.84626 18.1283 6.78125 17.6992C6.91127 18.113 7.02359 18.5314 7.13234 18.9499C7.18082 19.1639 7.26119 19.353 7.29194 19.5847C7.31204 19.807 7.27892 20.0375 7.17134 20.229Z"
                fill="#804B24"
            />
            <path
                d="M3.85409 17.5015C3.87653 18.4567 4.75961 18.8504 5.46536 18.8504C6.17111 18.8504 7.66415 18.0039 8.17958 17.6233C8.69618 17.2426 10.4091 16.8667 11.8218 17.7084C13.2345 18.5501 14.049 19.1755 15.5704 19.1755C17.0919 19.1755 17.1462 18.1978 18.423 18.1978C21.1123 18.1978 20.9764 19.964 21.1939 19.7464C21.4114 19.5289 22.1988 17.7096 22.1988 15.9706C22.1988 14.8204 21.9245 14.2104 21.7401 13.6371C21.6585 13.3841 21.4103 13.2351 21.1466 13.2671C19.2552 13.4952 16.4535 11.1711 14.5384 10.2384C12.4743 9.23353 10.7898 8.17432 9.54023 8.17432C8.2907 8.17432 4.24301 10.4287 3.72641 10.9453C3.20981 11.4619 2.99347 12.0589 2.99347 12.793C2.99347 13.5271 2.53125 15.2649 2.53125 16.7591C2.53125 17.9543 2.82679 18.4011 3.25001 18.1446C3.35639 18.0796 3.47105 17.9708 3.59045 17.8184C3.62828 17.7746 3.85526 17.5724 3.85409 17.5015C3.85409 16.9305 4.2974 16.1941 5.06342 15.5333C5.17337 15.4387 5.26322 15.1727 5.33888 14.9658C5.33888 14.9658 5.55638 13.9338 5.77391 13.4621C5.99141 12.9916 6.31769 12.702 6.82484 12.5932C8.26943 12.2835 9.36056 10.4015 10.0852 10.8365C10.8099 11.2716 10.0486 12.9396 8.59925 13.4467C7.14992 13.9539 6.38981 14.5308 5.41217 15.2554C4.43453 15.9801 3.85409 16.849 3.85409 17.5015Z"
                fill="url(#paint17_linear_3243_37326)"
            />
            <path
                d="M21.1471 13.2673C19.2556 13.4955 16.4539 11.1714 14.5388 10.2386C12.9902 9.48443 11.6555 8.70065 10.5585 8.35547L10.0845 10.8368C10.2311 10.9243 10.3103 11.0437 10.3351 11.1808C10.3564 11.2588 10.3611 11.3451 10.3576 11.4362C10.3576 11.4456 10.3564 11.4551 10.3564 11.4645C10.3505 11.5567 10.3327 11.6537 10.3032 11.7542C11.9073 10.0057 12.9169 9.85325 13.8437 10.2315C15.4231 10.8758 17.1857 13.5191 19.3313 13.5191C21.4674 13.5191 22.0325 14.7816 22.0372 14.7899C21.9462 14.3206 21.8327 13.9222 21.7417 13.6385C21.7003 13.512 21.6188 13.4115 21.5135 13.3465C21.4107 13.2839 21.2854 13.2555 21.1565 13.2685C21.153 13.2685 21.1506 13.2673 21.1471 13.2673Z"
                fill="url(#paint18_radial_3243_37326)"
            />
            <path
                d="M14.5395 10.2385C13.3704 9.6699 12.3242 9.08358 11.408 8.68164L10.4209 10.8757C10.7756 10.5707 11.5014 10.3697 12.9909 10.7551C16.0338 11.5424 17.7443 15.7533 19.293 15.6906C20.6891 15.6339 20.6335 14.6763 22.1018 15.1646C22.0048 14.5321 21.8559 13.9942 21.7412 13.6372C21.701 13.5107 21.6183 13.4102 21.5131 13.3452C21.4079 13.2802 21.2802 13.2518 21.1478 13.2672C19.2551 13.4954 16.4534 11.1713 14.5395 10.2385Z"
                fill="url(#paint19_linear_3243_37326)"
            />
            <path
                d="M10.0838 10.8368C10.8085 11.2718 10.0472 12.9398 8.59787 13.447C7.5044 13.83 6.80219 14.2532 6.10352 14.7485C7.04216 14.1882 8.11199 14.1149 8.92532 13.7177C10.8711 12.7684 10.8901 10.6299 10.8901 10.6299L10.0838 10.8368Z"
                fill="url(#paint20_linear_3243_37326)"
            />
            <path
                d="M9.54023 8.17432C8.2907 8.17432 4.24301 10.4287 3.72641 10.9453C3.20981 11.4619 2.99347 12.0589 2.99347 12.793C2.99347 13.5271 2.53125 15.2649 2.53125 16.7591C2.53125 16.9388 2.53834 17.0996 2.55135 17.2462C2.85871 15.6763 3.14006 11.9099 3.99239 11.1829C5.04215 10.2868 8.68319 8.16724 10.285 8.41195C10.4434 8.43559 10.5652 8.42377 10.6704 8.39422C10.2625 8.25589 9.88424 8.17432 9.54023 8.17432Z"
                fill="url(#paint21_linear_3243_37326)"
            />
            <path
                d="M21.7415 13.6373C21.66 13.3843 21.4117 13.2354 21.1481 13.2673C19.5356 13.4612 17.2635 11.6844 15.4418 10.7056C15.4359 11.8523 15.5376 13.0249 15.365 14.0617C14.6084 16.5206 12.782 17.1684 10.5654 17.2594C10.9863 17.3292 11.4166 17.4675 11.8233 17.7098C13.2359 18.5515 14.0504 19.1769 15.5719 19.1769C17.0933 19.1769 17.1477 18.1992 18.4244 18.1992C21.1138 18.1992 20.9779 19.9654 21.1954 19.7479C21.4129 19.5303 22.2002 17.711 22.2002 15.9721C22.2002 14.8206 21.9259 14.2106 21.7415 13.6373Z"
                fill="url(#paint22_radial_3243_37326)"
            />
            <path
                d="M10.2778 11.0261C10.2944 11.0533 10.305 11.0829 10.3145 11.1124L10.4209 10.876C10.3653 10.9245 10.3168 10.9741 10.2778 11.0261Z"
                fill="url(#paint23_linear_3243_37326)"
            />
            <path
                d="M5.33886 14.9657C5.33886 14.9657 5.55636 13.9337 5.77389 13.462C5.99139 12.9915 6.31767 12.7019 6.82482 12.5932C7.0719 12.5399 7.30713 12.4395 7.53294 12.3142C8.54367 11.3058 9.81921 8.95329 9.42321 9.37533C6.90756 12.0517 6.91701 11.4535 5.8578 12.5128C4.79859 13.572 5.11068 14.7541 4.55391 15.3109C4.06923 15.7956 3.14121 16.9506 3.25116 18.1422C3.35757 18.0783 3.47223 17.9708 3.5928 17.8183C3.62946 17.7745 3.85761 17.5735 3.85524 17.5015C3.85524 17.5015 3.81387 17.1373 4.14843 16.5912C4.1508 16.5877 4.15434 16.5841 4.15671 16.5805C4.36359 16.233 4.67094 15.8736 5.0646 15.5344C5.10123 15.4835 5.13672 15.4291 5.17335 15.3759C5.23482 15.2542 5.29038 15.0993 5.33886 14.9657Z"
                fill="url(#paint24_linear_3243_37326)"
            />
            <path
                d="M21.1955 19.7464C21.2795 19.6625 21.4485 19.3374 21.6246 18.8728C19.8869 16.687 17.7921 17.2521 17.1656 17.7912C16.513 18.3527 15.4089 19.0041 14.0506 18.4437C12.6923 17.8822 12.2218 15.999 10.3563 15.999C8.4909 15.999 7.15035 17.167 6.28146 17.602C5.52132 17.9827 4.77303 17.9578 3.8793 17.193L3.85449 17.5027C3.87813 18.5123 4.81557 18.8515 5.46576 18.8515C6.17151 18.8515 7.66458 18.0051 8.17998 17.6245C8.69658 17.2438 10.4095 16.8679 11.8222 17.7096C13.2349 18.5513 14.0494 19.1767 15.5708 19.1767C17.0923 19.1767 17.1466 18.199 18.4234 18.199C21.1139 18.1978 20.978 19.964 21.1955 19.7464Z"
                fill="url(#paint25_radial_3243_37326)"
            />
            <path
                d="M21.1954 19.7468C21.2675 19.6747 21.4023 19.4193 21.5501 19.0564C21.3633 18.7124 21.0157 18.4263 20.4448 18.2443C17.3747 17.2667 17.3747 18.8968 15.066 18.963C12.7572 19.0304 12.0231 16.5869 10.7192 16.5869C7.84301 16.5869 6.38657 18.7006 4.36865 18.5067C4.6961 18.7479 5.11931 18.8484 5.46569 18.8484C6.17144 18.8484 7.66448 18.002 8.17991 17.6213C8.69651 17.2406 10.4095 16.8647 11.8221 17.7064C13.2348 18.5481 14.0493 19.1735 15.5707 19.1735C17.0922 19.1735 17.1466 18.1958 18.4233 18.1958C21.1139 18.1982 20.9779 19.9643 21.1954 19.7468Z"
                fill="url(#paint26_linear_3243_37326)"
            />
            <path
                d="M5.06371 15.5332C5.0625 15.5344 5.06127 15.5356 5.06006 15.5356C5.06247 15.5344 5.0625 15.5344 5.06371 15.5332Z"
                fill="url(#paint27_linear_3243_37326)"
            />
            <path
                d="M11.0076 12.5817C11.6873 12.4552 12.036 12.1561 12.5869 12.0544C13.1378 11.9528 15.6192 11.7187 15.8296 12.9103C16.04 14.1019 14.3046 14.7829 13.2891 15.2864C12.2736 15.79 9.66581 15.53 9.33245 14.5843C8.99909 13.6385 9.9389 12.7803 11.0076 12.5817Z"
                fill="url(#paint28_radial_3243_37326)"
            />
            <path
                d="M6.53881 11.9607C6.20662 12.3284 5.66638 12.2704 5.39803 12.5672C5.12968 12.8639 4.21351 15.7791 3.57514 15.1833C2.93678 14.5875 3.53968 12.5116 3.89788 11.7822C4.25608 11.0528 7.31194 9.09756 7.91365 9.49713C8.51419 9.8967 7.06015 11.3838 6.53881 11.9607Z"
                fill="url(#paint29_radial_3243_37326)"
            />
            <path
                d="M15.7855 2.86315C15.6968 2.71183 15.5455 2.50377 15.3244 2.35009C15.1046 2.19759 14.8622 2.13257 14.692 2.1042C14.5655 2.08292 14.4307 2.14558 14.3492 2.26379L5.39551 15.1835C5.40379 15.2048 5.41678 15.2214 5.42623 15.2415C5.48299 15.1989 5.53501 15.1528 5.5894 15.1091L5.55982 15.1445C6.46774 14.4719 7.22431 13.9269 8.59798 13.4458C8.64172 13.4304 8.68189 13.408 8.72446 13.3914L9.27061 12.6029L15.7571 3.24262C15.841 3.12439 15.8517 2.97427 15.7855 2.86315Z"
                fill="url(#paint30_linear_3243_37326)"
            />
            <path
                d="M6.41952 14.362L14.4795 2.71656C14.7041 2.34536 14.8022 2.12193 14.6934 2.1042C14.567 2.08292 14.4322 2.14558 14.3506 2.26379L5.39697 15.1835C5.40762 15.2096 5.41233 15.2273 5.42061 15.2486C5.77881 14.9826 6.09327 14.6729 6.41952 14.362Z"
                fill="url(#paint31_radial_3243_37326)"
            />
            <path
                d="M15.7584 3.24255C15.7679 3.22836 15.7738 3.2118 15.7821 3.19644C15.8069 3.14916 15.8246 3.09951 15.8282 3.04986C15.8329 2.98366 15.8187 2.91864 15.7856 2.86308C15.7844 2.86071 15.7832 2.85835 15.7821 2.85716C15.7324 2.77205 15.6615 2.67157 15.5716 2.57227L7.80371 13.7649C8.04722 13.6562 8.30729 13.5497 8.59928 13.4481C8.64302 13.4327 8.68322 13.4103 8.72579 13.3937L9.27194 12.6052L15.7584 3.24255Z"
                fill="url(#paint32_linear_3243_37326)"
            />
            <path
                d="M18.424 18.198C18.0233 18.198 17.632 18.328 17.3069 18.5585C16.9818 18.7819 16.6484 19.0243 16.2524 19.1212C15.4639 19.2962 14.634 19.1839 13.8869 18.8954C13.1398 18.5952 12.4754 18.14 11.7886 17.7417C11.1088 17.3409 10.2943 17.2014 9.51294 17.2747C9.12285 17.3102 8.72919 17.3929 8.37927 17.5632C8.05299 17.7618 7.71252 18.0029 7.36143 18.185C7.01034 18.3765 6.64977 18.5526 6.27504 18.6957C5.90619 18.8399 5.48772 18.9439 5.08698 18.8482C4.69212 18.7713 4.30083 18.5774 4.06086 18.2405C3.82206 17.9072 3.76179 17.4556 3.89892 17.0737C4.17198 16.3042 4.7796 15.7261 5.41206 15.2544C4.79025 15.7391 4.19799 16.3337 3.94974 17.0891C3.82206 17.4615 3.88353 17.8871 4.11168 18.2027C4.34103 18.5207 4.71813 18.7051 5.09997 18.776C5.48889 18.8647 5.87784 18.7642 6.24549 18.6188C6.61431 18.4758 6.97014 18.2973 7.31769 18.1046C7.67232 17.9166 7.98324 17.6896 8.33433 17.4733C8.70198 17.2924 9.10155 17.2038 9.50349 17.166C10.305 17.0962 11.1384 17.2452 11.8347 17.6625C12.5215 18.0679 13.18 18.5254 13.9141 18.8269C14.6447 19.1177 15.4686 19.2312 16.2394 19.068C16.6283 18.9817 16.9605 18.7524 17.2939 18.5337C17.6249 18.3115 18.0233 18.1885 18.424 18.198Z"
                fill="#804B24"
            />
            <path
                d="M10.2815 11.1465C10.3134 11.2411 10.33 11.3404 10.3253 11.4409C10.2614 12.0521 9.84766 12.5828 9.37363 12.9564C8.90431 13.3631 8.26594 13.4813 7.70443 13.765C7.13344 14.0263 6.60148 14.3655 6.10498 14.7474C6.62512 14.401 7.16653 14.0865 7.73398 13.8312C8.01886 13.7047 8.30968 13.5924 8.60404 13.4919C8.90431 13.3891 9.18919 13.2377 9.43981 13.0415C9.92449 12.6431 10.3395 12.0946 10.3962 11.4444C10.4009 11.3191 10.3832 11.1903 10.3312 11.0732L10.2815 11.1465Z"
                fill="#804B24"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_3243_37326"
                x1="11.0234"
                y1="13.3942"
                x2="4.6823"
                y2="6.75565"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3243_37326"
                x1="9.29471"
                y1="19.7607"
                x2="10.0689"
                y2="8.3104"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3243_37326"
                x1="14.8919"
                y1="19.7551"
                x2="14.9633"
                y2="21.4693"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_3243_37326"
                x1="15.7479"
                y1="23.9693"
                x2="14.5504"
                y2="16.6397"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_3243_37326"
                x1="13.8893"
                y1="24.0083"
                x2="13.4534"
                y2="17.4456"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_3243_37326"
                x1="0.886742"
                y1="15.6378"
                x2="4.50304"
                y2="18.8963"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#0097D4" />
                <stop offset="0.2368" stopColor="#0294D6" />
                <stop offset="0.4442" stopColor="#0989DB" />
                <stop offset="0.6411" stopColor="#1477E4" />
                <stop offset="0.8304" stopColor="#245EF0" />
                <stop offset="1" stopColor="#3640FF" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_3243_37326"
                x1="1.67257"
                y1="16.1022"
                x2="4.299"
                y2="18.7024"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#0097D4" />
                <stop offset="0.2368" stopColor="#0294D6" />
                <stop offset="0.4442" stopColor="#0989DB" />
                <stop offset="0.6411" stopColor="#1477E4" />
                <stop offset="0.8304" stopColor="#245EF0" />
                <stop offset="1" stopColor="#3640FF" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_3243_37326"
                x1="2.73507"
                y1="17.7278"
                x2="4.41548"
                y2="18.8521"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0097D4" stopOpacity="0" />
                <stop offset="0.3342" stopColor="#029AD7" stopOpacity="0.3342" />
                <stop offset="0.6287" stopColor="#08A3E2" stopOpacity="0.6287" />
                <stop offset="0.9072" stopColor="#13B2F4" stopOpacity="0.9072" />
                <stop offset="1" stopColor="#17B9FC" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_3243_37326"
                x1="9.67574"
                y1="20.2642"
                x2="9.74819"
                y2="22.0028"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_3243_37326"
                x1="9.82292"
                y1="25.88"
                x2="9.53315"
                y2="16.0279"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_3243_37326"
                x1="9.20459"
                y1="20.8882"
                x2="8.74277"
                y2="18.063"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_3243_37326"
                x1="7.94327"
                y1="20.3364"
                x2="8.01572"
                y2="22.075"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_3243_37326"
                x1="8.7467"
                y1="23.9375"
                x2="7.18919"
                y2="15.7878"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_3243_37326"
                x1="7.72532"
                y1="20.8003"
                x2="7.58948"
                y2="18.5728"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_3243_37326"
                x1="6.15743"
                y1="20.4111"
                x2="6.22985"
                y2="22.1497"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_3243_37326"
                x1="6.79586"
                y1="22.1467"
                x2="5.16593"
                y2="15.2829"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_3243_37326"
                x1="6.23813"
                y1="22.7496"
                x2="6.00269"
                y2="18.7834"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint17_linear_3243_37326"
                x1="22.9973"
                y1="23.4308"
                x2="3.2207"
                y2="9.37708"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <radialGradient
                id="paint18_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(21.0138 2.89776) scale(30.435)"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FF8900" />
            </radialGradient>
            <linearGradient
                id="paint19_linear_3243_37326"
                x1="11.6567"
                y1="10.4206"
                x2="18.9733"
                y2="13.8978"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBB47" stopOpacity="0.9686" />
                <stop offset="0.0026513" stopColor="#FFBA47" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint20_linear_3243_37326"
                x1="8.43956"
                y1="11.9754"
                x2="8.8199"
                y2="16.6479"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBB47" stopOpacity="0.9686" />
                <stop offset="0.0026513" stopColor="#FFBA47" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint21_linear_3243_37326"
                x1="7.4462"
                y1="13.83"
                x2="2.80087"
                y2="8.20672"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBB47" stopOpacity="0.9686" />
                <stop offset="0.0026513" stopColor="#FFBA47" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </linearGradient>
            <radialGradient
                id="paint22_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17.798 15.342) rotate(-72.5489) scale(9.39969 6.68226)"
            >
                <stop stopColor="#FFBB47" stopOpacity="0.9686" />
                <stop offset="0.0026513" stopColor="#FFBA47" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint23_linear_3243_37326"
                x1="15.1524"
                y1="16.211"
                x2="11.2405"
                y2="11.9461"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBB47" stopOpacity="0.9686" />
                <stop offset="0.0026513" stopColor="#FFBA47" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_3243_37326"
                x1="4.04481"
                y1="0.964815"
                x2="7.92042"
                y2="22.2265"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FF8900" />
            </linearGradient>
            <radialGradient
                id="paint25_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(14.5428 8.22978) scale(33.4425 33.4425)"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FF8900" />
            </radialGradient>
            <linearGradient
                id="paint26_linear_3243_37326"
                x1="16.5549"
                y1="9.02733"
                x2="11.3391"
                y2="23.1806"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FF8900" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_3243_37326"
                x1="5.06315"
                y1="15.5347"
                x2="5.06226"
                y2="15.5341"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <radialGradient
                id="paint28_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(12.1615 13.4332) rotate(169.5) scale(2.38796 1.21307)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="0.2654" stopColor="#FCDF73" stopOpacity="0.7346" />
                <stop offset="0.6548" stopColor="#FDDB5F" stopOpacity="0.3452" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <radialGradient
                id="paint29_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(5.35642 11.4559) rotate(-47.8592) scale(2.39471 1.22584)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="0.2654" stopColor="#FCDF73" stopOpacity="0.7346" />
                <stop offset="0.6548" stopColor="#FDDB5F" stopOpacity="0.3452" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint30_linear_3243_37326"
                x1="7.0252"
                y1="6.55525"
                x2="13.0922"
                y2="10.612"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#0097D4" />
                <stop offset="0.2368" stopColor="#0294D6" />
                <stop offset="0.4442" stopColor="#0989DB" />
                <stop offset="0.6411" stopColor="#1477E4" />
                <stop offset="0.8304" stopColor="#245EF0" />
                <stop offset="1" stopColor="#3640FF" />
            </linearGradient>
            <radialGradient
                id="paint31_radial_3243_37326"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(11.7309 6.32092) rotate(-55.4788) scale(8.36391 0.179145)"
            >
                <stop stopColor="white" stopOpacity="0.5" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint32_linear_3243_37326"
                x1="6.44843"
                y1="4.59711"
                x2="13.3304"
                y2="9.2877"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#0097D4" />
                <stop offset="0.2368" stopColor="#0294D6" />
                <stop offset="0.4442" stopColor="#0989DB" />
                <stop offset="0.6411" stopColor="#1477E4" />
                <stop offset="0.8304" stopColor="#245EF0" />
                <stop offset="1" stopColor="#3640FF" />
            </linearGradient>
            <clipPath id="clip0_3243_37326">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </EmojiIconSvg>
);
