import React from 'react';
import cc from 'classcat';

import { AvatarSmartBlockLinkProps } from '../types';

import { AvatarBlock } from './AvatarBlock';
import { AvatarLink } from './AvatarLink';

export const AvatarSmartLinkBlock = React.memo((props: AvatarSmartBlockLinkProps) => {
    const { className, ...restAvatarProps } = props;

    const avatarClassName = cc(['autotest__avatar-smart-link-block', className]);

    if ('href' in props) {
        const { target, rel, href, ...restAvatarLinkProps } = props;
        return (
            <AvatarLink href={href} target={target} rel={rel} {...restAvatarLinkProps} className={avatarClassName} />
        );
    }

    return <AvatarBlock {...restAvatarProps} className={avatarClassName} />;
});
