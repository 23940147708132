/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marketplace_Web_Public_V1_My_Payments_CreateSessionWebRequest } from '../models/PaymentsService/Marketplace_Web_Public_V1_My_Payments_CreateSessionWebRequest';
import type { PagedWebResponse_PaymentDto } from '../models/PaymentsService/PagedWebResponse_PaymentDto';
import type { WebResponse_CreateDonationSessionWebResponse } from '../models/PaymentsService/WebResponse_CreateDonationSessionWebResponse';
import type { WebResponse_CreateSessionWebResponse } from '../models/shared/WebResponse_CreateSessionWebResponse';
import type { WebResponse_IPaymentStatisticsDto } from '../models/PaymentsService/WebResponse_IPaymentStatisticsDto';
import type { WebResponse_SyncPaymentsResponse } from '../models/PaymentsService/WebResponse_SyncPaymentsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {
    /**
     * Загрузить из Stripe недостающую информацию о платежах
     * @returns WebResponse_SyncPaymentsResponse Success
     * @throws ApiError
     */
    public static createPaymentsSync({
        from,
        to,
    }: {
        /**
         * Начало периода, за который нужно загрузить информацию о платежах
         */
        from?: string | null;
        /**
         * Конец периода, за который нужно загрузить информацию о платежах
         */
        to?: string | null;
    }): CancelablePromise<WebResponse_SyncPaymentsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/payments/sync',
            query: {
                From: from,
                To: to,
            },
        });
    }

    /**
     * Создать платежную сессию в Stripe
     * @returns WebResponse_CreateSessionWebResponse Success
     * @throws ApiError
     */
    public static checkout({
        requestBody,
    }: {
        /**
         * Идентификаторы покупаемых товаров
         */
        requestBody?: Marketplace_Web_Public_V1_My_Payments_CreateSessionWebRequest;
    }): CancelablePromise<WebResponse_CreateSessionWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/payments/checkout',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Возвращает платежи и платежные сессии пользователя
     * @returns PagedWebResponse_PaymentDto Success
     * @throws ApiError
     */
    public static getMyPayments({
        status,
        productIds,
        subscriptionIds,
        page,
        pageSize,
    }: {
        /**
         * Статус платежа сохраненный
         */
        status?: 'Active' | 'Success' | 'Expired' | 'Failed' | null;
        /**
         * Идентификаторы продуктов
         */
        productIds?: Array<number> | null;
        /**
         * Идентификаторы подписки
         */
        subscriptionIds?: Array<number> | null;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_PaymentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/payments',
            query: {
                Status: status,
                ProductIds: productIds,
                SubscriptionIds: subscriptionIds,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Создать платежную сессию для условно бесплатных продуктов (пожертвования)
     * @returns WebResponse_CreateDonationSessionWebResponse Success
     * @throws ApiError
     */
    public static createApiVMyPaymentDonation({
        productId,
        redirectSuccessUrl,
        redirectFailUrl,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
        /**
         * URL для редиректа при успешной оплате в Stripe
         * Stripe подменяет протокол и хост из этого адреса на протокол и хост инициатора чекаута.
         * Иными словами, если платеж запущен на сайте https://foo.com,
         * а данному параметру присвоено значение https://bar.baz/?param=42, то редирект произойдет по ссылке
         * https://foo.com/?param=42
         */
        redirectSuccessUrl?: string | null;
        /**
         * URL для редиректа при непрошедшей оплате в Stripe
         * Stripe подменяет протокол и хост из этого адреса на протокол и хост инициатора чекаута.
         * Иными словами, если платеж запущен на сайте https://foo.com,
         * а данному параметру присвоено значение https://bar.baz/?param=42, то редирект произойдет по ссылке
         * https://foo.com/?param=42
         */
        redirectFailUrl?: string | null;
    }): CancelablePromise<WebResponse_CreateDonationSessionWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/payments/donation/{productId}',
            path: {
                productId: productId,
            },
            query: {
                RedirectSuccessUrl: redirectSuccessUrl,
                RedirectFailUrl: redirectFailUrl,
            },
        });
    }

    /**
     * Получить статистику по оплате продуктов автора
     * @returns WebResponse_IPaymentStatisticsDto Success
     * @throws ApiError
     */
    public static getMyPaymentsStatistics({
        productId,
        from,
        to,
        pointFrequency = 'Daily',
        maxPeriodForDailyPointFrequency = 90,
    }: {
        /**
         * ИД конкретного продукта, по которому нужно получить статистику.
         * Если не указан, то будет получена обобщённая статистика по всем продуктам автора
         */
        productId?: number | null;
        /**
         * Начало периода, за который необходимо получить статистику
         */
        from?: string | null;
        /**
         * Конец периода, за который необходимо получить статистику
         */
        to?: string | null;
        /**
         * Частота точек статистики
         */
        pointFrequency?: 'Daily' | 'Monthly' | null;
        /**
         * Максимальный период времени (в днях),
         * при превышении которого точки начинают отображаться с ежемесячной частотой, если частота точек не указана явно
         */
        maxPeriodForDailyPointFrequency?: number | null;
    }): CancelablePromise<WebResponse_IPaymentStatisticsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/payments/author/statistics',
            query: {
                ProductId: productId,
                From: from,
                To: to,
                PointFrequency: pointFrequency,
                MaxPeriodForDailyPointFrequency: maxPeriodForDailyPointFrequency,
            },
        });
    }
}
