import { PrefixObjectKeys } from '../types';

import { capitalize } from './capitalize';

export function prefixObjectKeys<PREFIX extends string, OBJECT extends object>(
    prefix: PREFIX,
    obj: OBJECT,
): PrefixObjectKeys<PREFIX, OBJECT> {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[`${prefix}${capitalize(key)}` as keyof PrefixObjectKeys<PREFIX, OBJECT>] = value;
        return acc;
    }, {} as PrefixObjectKeys<PREFIX, OBJECT>);
}
