import React, { ReactNode } from 'react';
import { Placement } from 'tippy.js';

import { useDictionary } from 'hooks/useDictionary';
import { Tooltip } from 'components/tooltip';

type Props = {
    isPossible: boolean;
    children: ReactNode | (({ isPossible }: { isPossible: boolean }) => ReactNode);
    tooltipPlacement?: Placement;
};

export const ActionPossibility = (props: Props) => {
    const { children, isPossible, tooltipPlacement = 'bottom' } = props;
    const child = <span>{typeof children === 'function' ? children({ isPossible }) : children}</span>;
    const dic = useDictionary();
    return isPossible ? (
        child
    ) : (
        <Tooltip
            content={dic.word('wt_all__widget_action_possibility__content')}
            trigger="mouseenter"
            placement={tooltipPlacement}
        >
            {child}
        </Tooltip>
    );
};
