import { MyProductPopupState } from './types';
import {
    PopupActions,
    OPEN_EDIT_POPUP,
    CLOSE_EDIT_POPUP,
    OPEN_DELETE_POPUP,
    CLOSE_DELETE_POPUP,
    OPEN_TOKEN_POPUP,
    CLOSE_TOKEN_POPUP,
} from './actions/popupActions';

export const myProductPopupReducer = (
    state: MyProductPopupState = {
        updating: false,
        deleting: false,
    },
    action: PopupActions,
): MyProductPopupState => {
    switch (action.type) {
        case OPEN_EDIT_POPUP:
            return { ...state, editProductId: action.payload };
        case CLOSE_EDIT_POPUP:
            return { ...state, editProductId: undefined };
        case OPEN_DELETE_POPUP:
            return { ...state, deleteProductId: action.payload };
        case CLOSE_DELETE_POPUP:
            return { ...state, deleteProductId: undefined };
        case OPEN_TOKEN_POPUP:
            return { ...state, tokenProductId: action.payload };
        case CLOSE_TOKEN_POPUP:
            return { ...state, tokenProductId: undefined };

        default:
            return state;
    }
};
