import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ExclamationMarkCircleIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.4 12C20.4 16.6392 16.6392 20.4 12 20.4C7.36081 20.4 3.6 16.6392 3.6 12C3.6 7.36081 7.36081 3.6 12 3.6C16.6392 3.6 20.4 7.36081 20.4 12ZM12.7896 12.8115C12.769 13.3345 12.4922 13.6113 12.0103 13.6113C11.4976 13.6113 11.2207 13.3242 11.2104 12.8115L11.0771 7.83838C11.0566 7.31543 11.4463 6.93604 12 6.93604C12.5332 6.93604 12.9434 7.32568 12.9229 7.83838L12.7896 12.8115ZM13.0869 15.7544C13.0869 16.3389 12.5845 16.7798 12.0103 16.7798C11.4258 16.7798 10.9233 16.3286 10.9233 15.7544C10.9233 15.1802 11.4155 14.729 12.0103 14.729C12.5947 14.729 13.0869 15.1699 13.0869 15.7544Z"
        />
    </IconSvg>
);
