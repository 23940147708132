import { Action, Person } from 'types';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';
import { ActivityService } from 'services/api-services/generated_limex';
import { CustomThunkReturnType } from 'services/create-action-types/shared/types';
import { areArraysEqual } from 'services/utils/are-arrays-equal';

import { GlobalStateForFeed, SocialTypes } from '../../types';

const [GET_SOCIAL_ACTIVITY_LIST_ACTION_TYPES, thunk, actions] = createThunkActions<
    'widgets',
    GlobalStateForFeed & {
        page: {
            user: Person;
        };
    }
>('widgets')('GET_SOCIAL_ACTIVITY_LIST', ActivityService.getSocialActivityList)<{
    instanceId: string;
    socialTypes?: SocialTypes[];
    reset?: boolean;
}>();

type GetSocialActivityListActions = Actions<typeof actions>;
type GetSocialActivityList = Action<typeof getSocialActivityList>;

const cancelMap: Record<string, () => void> = {};

const getSocialActivityList =
    ({
        instanceId,
        socialTypes,
        reset,
    }: {
        instanceId: string;
        socialTypes?: SocialTypes[];
        reset?: boolean;
    }): CustomThunkReturnType<typeof thunk> =>
    (dispatch, getState, extraArgument) => {
        const {
            widgets: {
                feed: { instances },
            },
            page: {
                user: { id },
            },
        } = getState();
        const instance = instances[instanceId] ?? {};
        const { boundary, isLoading = false, socialTypes: socialTypesState = [] } = instance;

        if (!reset && (isLoading || boundary === null)) {
            return Promise.resolve();
        }

        if (reset && socialTypes && !areArraysEqual(socialTypes, socialTypesState)) {
            cancelMap[instanceId]?.();
            return thunk({
                params: { userId: `${id}`, types: socialTypes, limit: 10 },
                pass: { instanceId, socialTypes, reset },
            })(dispatch, getState, extraArgument);
        }

        return thunk({
            params: {
                userId: `${id}`,
                types: socialTypesState,
                limit: 10,
                cursor: reset ? undefined : boundary ?? undefined,
            },
            pass: { instanceId, reset },
            onCancel: (cancelHandler) => {
                cancelMap[instanceId] = cancelHandler;
            },
        })(dispatch, getState, extraArgument);
    };
export {
    GET_SOCIAL_ACTIVITY_LIST_ACTION_TYPES,
    getSocialActivityList,
    GetSocialActivityList,
    GetSocialActivityListActions,
};
