import React from 'react';

import { FeedRecordObj } from 'types';

import { PlugBlock } from './PlugBlock';
import * as Styled from './styled';

interface Props {
    obj: FeedRecordObj;
    onPremiumSubscribe?: () => void;
    openPopup: () => void;
}

export const ContentPremiumNotGranted = ({
    obj: { shortContent, author, isPremiumGranted },
    onPremiumSubscribe,
    openPopup,
}: Props) => {
    const secureShortContent = shortContent.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');
    const { id, isBlockedByCurrentUser } = author;
    return (
        <>
            <Styled.Content dangerouslySetInnerHTML={{ __html: secureShortContent }} />
            <PlugBlock
                authorId={id}
                subscribed={isPremiumGranted ?? false}
                isBlockedByCurrentUser={isBlockedByCurrentUser}
                onPremiumSubscribe={onPremiumSubscribe}
                openPopup={openPopup}
            />
        </>
    );
};
