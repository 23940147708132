import React from 'react';

import { FeedRecordOptions, VisibilityLevels } from 'types';
import Dic from 'services/dictionary';
import { Tooltip } from 'components/tooltip';

import * as Styled from './styled';

export type LockBlockPropsType = FeedRecordOptions & {
    isPremium: boolean;
    visLevel: VisibilityLevels;
};

export const LockBlock = (props: LockBlockPropsType) => {
    const { isPremium, visLevel } = props;

    const lockIcon = <Styled.VisibilityLevelLockIcon size={16} />;

    const content = isPremium ? (
        <Styled.LockBlockTooltipWrapper>
            <Tooltip
                trigger="mouseenter"
                placement="bottom"
                offset={20}
                content={
                    <Styled.LockBlockContent>
                        {lockIcon}
                        <Styled.LockBlockText>
                            {Dic.word('wt_feed__widget_author__tooltip_premium_post')}
                        </Styled.LockBlockText>
                    </Styled.LockBlockContent>
                }
            >
                <div>{lockIcon}</div>
            </Tooltip>
        </Styled.LockBlockTooltipWrapper>
    ) : (
        lockIcon
    );

    return visLevel !== VisibilityLevels.ANY || isPremium ? content : <></>;
};
