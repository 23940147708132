import React from 'react';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import { TagData } from 'types';
import { Dic } from 'services/dictionary';
import { Button } from 'components/button2';

import { sanitizeHTML } from '../hooks/usePublishStatus';

type Props = {
    isLoading: boolean;
    isImageLoading: boolean;
    editorState: string;
    isEditorStateUnchanged: boolean;
    initSelectedTags: TagData[];
    selectedTagsIds: number[];
    publishStatus: () => void;
};

export const SubmitButton = (props: Props) => {
    const {
        isLoading,
        isImageLoading,
        editorState,
        isEditorStateUnchanged,
        initSelectedTags,
        selectedTagsIds,
        publishStatus,
    } = props;
    const isEditorEmpty = !sanitizeHTML(editorState).length;
    const isSelectedTagsChanged = !isEqual(sortBy(map(initSelectedTags, 'id')), sortBy(selectedTagsIds));

    return (
        <Button
            kind="primary"
            size="small"
            isProcessing={isLoading}
            isDisabled={
                isLoading || isImageLoading || isEditorEmpty || (isEditorStateUnchanged && !isSelectedTagsChanged)
            }
            onClick={publishStatus}
            className="autotest__add-feed-status__submit"
        >
            {Dic.word('wt_feed__timeline_status__add_post')}
        </Button>
    );
};
