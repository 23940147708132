import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CheckOutlineIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.5897 7.54054C14.8882 7.21484 14.8662 6.70879 14.5405 6.41023C14.2148 6.11168 13.7088 6.13368 13.4102 6.45938L8.47482 11.8435L6.56564 9.93427C6.25322 9.62185 5.74669 9.62185 5.43427 9.93427C5.12185 10.2467 5.12185 10.7532 5.43427 11.0656L7.93427 13.5656C8.08856 13.7199 8.29918 13.8045 8.51733 13.7998C8.73548 13.795 8.94223 13.7014 9.08967 13.5405L14.5897 7.54054Z"
        />
    </IconSvg>
);
