import styled from 'styled-components';

import { FormLabel as FormLabelBase } from 'components/form';

export const FormLabel = styled(FormLabelBase)`
    margin-bottom: 0;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    & > *:first-child {
        flex: 1;
    }
`;
