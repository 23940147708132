import styled, { createGlobalStyle } from 'styled-components';
import { MainContainer } from '@chatscope/chat-ui-kit-react';

import { tokens } from 'services/tokens';
import { body17SemiboldFontStyle } from 'services/typography';
import { Z_INDEX_DIALOG } from 'components/wt-popup3/constants';

const windowHeight = 469;
const headerHeight = 40;
const headerBorderWidth = 1;

export const GlobalChatStyles = createGlobalStyle`
    .cs-message__avatar {
        width: auto;
    }
    .cs-chat-container {
        height: ${windowHeight - headerHeight - headerBorderWidth}px;
    }

    .cs-message-input__content-editor-wrapper {
        border-radius: ${tokens.radius.input};
        padding: ${tokens.spacing.inputM.paddingVert} ${tokens.spacing.inputM.paddingRight} ${
    tokens.spacing.inputM.paddingVert
} ${tokens.spacing.inputM.paddingLeft};
        background-color: white;
        border: 1px ${tokens.colors.input.border.default.default.default} solid;
    }
    .cs-message-input__content-editor-wrapper:hover {
        border: 1px ${tokens.colors.input.border.default.default.hover} solid;
    }
    .cs-message-input__content-editor {
        display: flex;
        align-items: center;
        background-color: white;
    }
    .cs-message-input__content-editor:before {
        color: ${tokens.colors.input.placeholder.default};
    }

    .cs-typing-indicator__text {
        color: ${tokens.colors.text.brand.default.default};
    }
    .cs-typing-indicator__dot {
        background-color: ${tokens.colors.icon.brand.default.default};
    }

    .cs-button.cs-button--send {
        color: ${tokens.colors.button.label.ghost.default.default};
    }
    .cs-button.cs-button--send:hover {
        color: ${tokens.colors.button.label.ghost.default.hover};
    }
    .cs-button.cs-button--send:focus {
        color: ${tokens.colors.button.label.ghost.default.pressed};
    }
    .cs-button.cs-button--send:disabled {
        color: ${tokens.colors.button.label.default.default.disabled};
    }

    .cs-message--outgoing .cs-message__content, .cs-message--incoming .cs-message__content {
        background-color: ${tokens.colors.bg.base.secondary.default};
        padding: 8px ${tokens.spacing.xs};
    }
    .cs-message--outgoing .cs-message__content {
        border-radius: 16px 16px 2px 16px;
    }
    .cs-message--incoming .cs-message__content {
        border-radius: 16px 16px 16px 2px;
    }
`;

export const Launcher = styled.div`
    position: fixed;
    width: 50px;
    height: 50px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${tokens.colors.bg.base.tooltip.default};
    color: ${tokens.colors.icon.onbrand.default.default};
    right: 1vw;
    bottom: 3vh;
    border-radius: 100%;
    cursor: pointer;
`;

export const Floating = styled.div`
    z-index: ${Z_INDEX_DIALOG};
    outline: none;
    display: flex;
    flex-direction: column;
    min-height: 56px;
`;

export const ChatWrapper = styled.div`
    width: 286px;
    height: ${windowHeight}px;
    background-color: ${tokens.colors.bg.base.default.default};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const pullScrollStyles = {
    boxShadow: `0 0 12px 8px ${tokens.colors.shadow['5x']}`,
    borderRadius: '15px',
};

export const Header = styled.div`
    border-bottom: ${headerBorderWidth}px ${tokens.colors.border.base.secondary.default} solid;
    height: ${headerHeight}px;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    ${body17SemiboldFontStyle};
`;

export const HeaderSecondary = styled.span`
    color: ${tokens.colors.text.base.tertiary.default};
`;

export const StyledMainContainer = styled(MainContainer)`
    border: none;
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
}
`;
