import React, { useMemo, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import noop from 'lodash/noop';

import { FeedRecordObj, TagData } from 'types';
import { sendGA } from 'services/analytics-outside/google-analytics';
import Dic from 'services/dictionary';
import { GalleryInitFunction } from 'components/gallery/Gallery';
import { Tags } from 'components/tags';

import { useNavigateToTag } from '../feed/hooks/useNavigateToTag';
import { useShowFull } from '../feed/hooks/useShowFull';

import { useThumbnail } from './useThumbnail';
import { useClickAnyImgInPost } from './useClickAnyImgInPost';
import { useTiktok } from './useTiktok';
import { ShowFull, Wrapper, ThumbnailImage, Source } from './styled';

interface Props {
    obj: FeedRecordObj;
    onClickAnyImgInPost?: GalleryInitFunction;
    showComments?: (x?: boolean) => void;
}

export const ContentHtmlParsed = ({
    obj: { content, shortContent, media, externalLink, tagsList },
    onClickAnyImgInPost,
    showComments,
}: Props) => {
    const { showFull, setShowFull } = useShowFull();
    const handleTiktok = useTiktok({ content });
    const [finalContent, firstImg] = useMemo(() => {
        const secureContent = content.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');
        handleTiktok();
        return [showFull ? secureContent : shortContent, (media || []).filter((item) => item.height && item.width)[0]];
    }, [showFull, handleTiktok, shortContent, content, media]);

    const handleClickShowFull = useCallback(() => {
        setShowFull(true);
        showComments?.(true);
        sendGA('feedRecord', ['show-full-click']);
    }, [setShowFull]);

    const contentWrapper = useRef<HTMLDivElement>(null);
    const thumbnail = useRef<HTMLImageElement>(null);

    const { widthThumbnail, heightThumbnail, showIfSizeSatisfies } = useThumbnail(firstImg);

    useClickAnyImgInPost(showFull, contentWrapper, thumbnail, onClickAnyImgInPost);

    const navigateToTag = useNavigateToTag();

    return (
        <>
            <Wrapper dangerouslySetInnerHTML={{ __html: finalContent }} ref={contentWrapper} />
            {!showFull && (
                <ShowFull onClick={handleClickShowFull} className="autotest__show-full-button">
                    {Dic.word('wt_feed__widget_content_html__see_full')}
                </ShowFull>
            )}
            <Tags tags={tagsList} kind="outline" size="small" onSelectTag={navigateToTag} />
            {!showFull && firstImg && showIfSizeSatisfies && (
                <div>
                    <ThumbnailImage
                        src={firstImg.source}
                        width={widthThumbnail}
                        height={heightThumbnail}
                        alt="Thumbnail image"
                        ref={thumbnail}
                    />
                </div>
            )}
            {showFull && externalLink && externalLink.includes('finam.ru') ? (
                <Source href={externalLink}>{Dic.word('wt_feed__widget_content_html__external_link')}</Source>
            ) : null}
        </>
    );
};
ContentHtmlParsed.defaultProps = {
    onShowFull: noop,
};
