import styled from 'styled-components';

import { mediaQuery, scrollWidth } from 'app/styled';
import { body17RegularFontStyle, caption10RegularFontStyle, caption9RegularFontStyle } from 'services/typography';
import detectDevice from 'services/detectDevice';
import { FormField } from 'components/form';
import { Textarea } from 'components/form/textarea';

import { AVATAR_SIZE } from '../constants';

export const Wrapper = styled.div`
    margin-bottom: 16px;
    ${mediaQuery.lt768} {
        height: auto;
        margin: 0 -16px;
        padding: 0 16px;
        ${detectDevice.isFirefox() ? '' : `padding-right: ${16 - scrollWidth}px;`}
        overflow-x: hidden;
    }
    ${FormField} + ${FormField} {
        margin-top: 12px;
    }
`;

export const AvatarAndErrorsMsgWrapper = styled.div`
    display: grid;
    grid-template-columns: ${AVATAR_SIZE + 16}px 1fr;
    grid-template-rows: ${AVATAR_SIZE / 2}px;
    grid-gap: 20px;
    position: relative;

    ${mediaQuery.lt768} {
        grid-template-columns: ${AVATAR_SIZE}px 1fr;
        grid-gap: 12px;
    }
`;

export const AvatarWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: end;
`;

export const ErrorsWrapper = styled.div`
    ${caption10RegularFontStyle};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.label.negative};
    ${mediaQuery.lt768} {
        ${caption9RegularFontStyle};
    }
`;

export const Subtitle = styled.div`
    ${body17RegularFontStyle};
    margin-top: 20px;
    margin-bottom: 12px;
    ${mediaQuery.lt768} {
        margin-top: 16px;
    }
`;

export const StyledPopupTextarea = styled(Textarea)`
    min-height: 400px;
`;
