import styled from 'styled-components';

import { buttonResetStyle } from 'app/styled';

export const CancelButton = styled.button`
    position: absolute;
    ${buttonResetStyle};

    width: 20px;
    height: 20px;

    border: 2px solid ${({ theme }) => theme.bg.primary};
    background-color: ${({ theme }) => theme.fillIn.secondary.mid};
    color: ${({ theme }) => theme.staticColors.white};
    box-sizing: content-box;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.9;
    }
`;

export const TopRightCorner = styled.div`
    position: absolute;
    top: -11px;
    right: 11px;
    width: 0;
    height: 0;
`;

export const CancelableWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;
