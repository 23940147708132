import styled from 'styled-components';

import { caption12MediumFontStyle } from 'services/typography';

export const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Link = styled.a`
    ${caption12MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    width: 100%;
    margin-bottom: 16px;

    &:hover {
        color: ${({ theme }) => theme.label.secondaryHigh};
        cursor: pointer;
    }

    &:active {
        color: ${({ theme }) => theme.label.accentHighest};
    }

    &:last-child {
        margin-bottom: 0;
    }
`;
