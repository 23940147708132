import styled from 'styled-components';

import { body14MediumFontStyle, caption12RegularFontStyle } from 'services/typography';
import { UserBlock as UserBlockComponent } from 'components/user-block';
import { UserName } from 'components/user-block/styled/UserBlock.styled';
import { SecondLine } from 'components/user-block/styled/SecondLine.styled';
import { ButtonIcon } from 'components/button-icon';

export const UserBlock = styled(UserBlockComponent)`
    padding-top: 2px;
    padding-bottom: 2px;

    ${UserName} {
        ${body14MediumFontStyle};
    }
    ${SecondLine} {
        ${caption12RegularFontStyle};
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

export const SubscribeButton = styled(ButtonIcon)`
    // если имя длинное, а экран узкий - то сжимать имя, а не кнопку
    flex-shrink: 0;
`;
