import styled from 'styled-components';

import { body14MediumFontStyle } from 'services/typography';

export const CardPriceInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${body14MediumFontStyle};
    margin: 20px 0 12px 0;
`;
