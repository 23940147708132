import React from 'react';

import { ValidationError } from 'services/validation-error';
import { FormFieldProps } from 'components/form';

import { useImgField } from '../hooks/useImgField';

import { AVATAR_SIZE } from './constants';
import { ActiveAvatar } from './ActiveAvatar';
import * as Styled from './styled/AvatarInput.styled';

type Props = {
    field: FormFieldProps<string>;
    onValidation: {
        applyError: (validationError: ValidationError) => void;
        removeError: (fieldName: string) => void;
    };
    hasError: boolean;
    className?: string;
};

const fieldName = 'image';

export const AvatarInput = (props: Props) => {
    const { field, onValidation, hasError, className } = props;

    const { isLoading, handleChange } = useImgField(fieldName, field, {
        minHeight: 180,
        minWidth: 180,
        maxSize: 20,
        types: ['image/jpeg', 'image/jpg', 'image/png'],
        onValidation,
    });

    return (
        <Styled.AvatarInput hasError={hasError} size={AVATAR_SIZE}>
            <ActiveAvatar
                src={field.value}
                alt="avatar"
                size={AVATAR_SIZE}
                isActive={field.value && !isLoading ? 'no' : 'yes'}
                onSubstrateClick={handleChange}
                isProcessing={isLoading}
                isActiveOnHover
                className={className}
            />
        </Styled.AvatarInput>
    );
};
