import { Id } from 'types';

export const recordStoreIndex = (id: Id) => `_${id}`;
export const index = recordStoreIndex;

export function mapRecordsById<Resource extends { id: Id }>(records: Resource[]): Record<Id, Resource>;
export function mapRecordsById<PropertyName extends string, Resource extends { [P in PropertyName]: Id }>(
    records: Resource[],
    propertyName: PropertyName,
): Record<Id, Resource>;
export function mapRecordsById<PropertyName extends string, Resource extends { [P in PropertyName]: Id }>(
    records: Resource[],
    propertyName?: PropertyName,
): Record<Id, Resource> {
    return records.reduce((acc, record) => ({ ...acc, [recordStoreIndex(record[propertyName ?? 'id'])]: record }), {});
}
