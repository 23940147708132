import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import map from 'lodash/map';
import type { OutputData } from '@editorjs/editorjs';

import { FeedOwnerType, Id, PostType } from 'types';
import { mediaQuery } from 'app/styled';
import { useFeedContext } from 'context/feed';
import Dic from 'services/dictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { Button } from 'components/button2';
import { PictureIcon } from 'components/icons2/PictureIcon';
import { DollarIcon } from 'components/icons2/DollarIcon';
import { ArrowPentagonRight } from 'components/icons2/ArrowPentagonRight';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { AddFeedStatusProps, EditorRefImperative } from '../types';
import { usePublishStatus } from '../hooks/usePublishStatus';

import { EditorFallback, EditorWrapper } from './editor/EditorFallback.styled';
import { ACTIVE_AVATAR_PROPS, AVATAR_SIZE } from './constants';
import { SubmitButton } from './SubmitButton';
import { FinalActions } from './FinalActions';
import { isEditorStateUnchanged } from './utils';
import { PostTypeSelector } from './PostTypeSelector';
import { TagsPopup } from './TagsPopup';
import { SelectedTagsLine } from './SelectedTagsLine';
import * as Styled from './styled';

const Editor = React.lazy(() => import(/* webpackChunkName: "editor" */ './editor'));

export const AddFeedStatus = ({
    publisherFn,
    owner,
    allTags,
    initSelectedTags = [],
    stub,
    placeholder,
    isActive: isActiveProp,
    onActiveChange,
    postType: postTypeProp = PostType.ORDINARY,
    premiumPostsAllowed = false,
    initRawJson,
    initHtml = '',
    objId,
    autofocus = true,
    communities,
    isEditing,
    instanceId,
}: AddFeedStatusProps) => {
    const [{ source }] = useFeedContext();
    const isFeed = source === 'feed';
    const defaultEditorStateJson = initRawJson ? JSON.parse(initRawJson) : null;

    // MARK: - Stateful Variables

    const currentUser = useCurrentUser();
    const [isActiveState, setIsActiveState] = useState(false);
    const isActive = useMemo(() => isActiveProp ?? isActiveState, [isActiveProp, isActiveState]);
    const [editorState, setEditorState] = useState(initHtml);
    const [postType, setPostType] = useState(postTypeProp);
    const [editorStateJson, setEditorStateJson] = useState<OutputData | null>(defaultEditorStateJson);
    const [communityId, setCommunityId] = useState<Id | undefined>(owner?.id);
    const [isShowTagsPopup, setIsShowTagsPopup] = useState(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(map(initSelectedTags, 'id'));
    const [isImageLoading, setImageLoading] = useState(false);

    // MARK: - Handlers

    const activate = () => {
        setIsActiveState(true);
        onActiveChange?.(true);
    };
    const deactivate = () => {
        setIsActiveState(false);
        onActiveChange?.(false);
    };

    const resetAppearanceRef = useRef(() => {
        setEditorState(initHtml);
        setEditorStateJson(null);
        setSelectedTagsIds([]);
        deactivate();
    });

    // MARK: - Publishing State

    const {
        publishStatusState: { isLoading },
        publishStatus,
    } = usePublishStatus({
        publisherFn,
        owner,
        editorState,
        editorStateJson,
        postType,
        objId,
        selectedTagsIds,
        resetAppearanceRef,
        communityId: postType === PostType.COMMUNITY ? communityId : undefined,
        instanceId,
    });

    const editorRef = useRef<EditorRefImperative>(null);

    const [editorSaving, setEditorSaving] = useState(false);

    const handlePublishStatus = useCallback(() => {
        if (editorRef.current) {
            setEditorSaving(true);
            editorRef.current
                .save()
                .then(() => {
                    publishStatus();
                })
                .finally(() => {
                    setEditorSaving(false);
                });
        }
    }, [publishStatus]);

    const finalActions = useMemo(
        () => (
            <FinalActions
                isLoading={isLoading || editorSaving}
                isImageLoading={isImageLoading}
                isDirty={!isEditorStateUnchanged(defaultEditorStateJson, editorStateJson)}
                resetAppearanceRef={resetAppearanceRef}
                submitBtn={
                    <SubmitButton
                        isLoading={isLoading || editorSaving}
                        isImageLoading={isImageLoading}
                        editorState={editorState}
                        isEditorStateUnchanged={isEditorStateUnchanged(defaultEditorStateJson, editorStateJson)}
                        initSelectedTags={initSelectedTags}
                        selectedTagsIds={selectedTagsIds}
                        publishStatus={handlePublishStatus}
                    />
                }
            />
        ),
        [
            isLoading,
            isImageLoading,
            editorState,
            defaultEditorStateJson,
            editorStateJson,
            initSelectedTags,
            selectedTagsIds,
            handlePublishStatus,
            editorSaving,
        ],
    );

    const finalStub = stub || placeholder || Dic.word('wt_feed__timeline_status__placeholder');
    const finalPlaceholder = placeholder || finalStub;

    const isMobile = useMatchMedia(mediaQuery.lt480);
    const isLt960 = useMatchMedia(mediaQuery.lt960);

    return (
        <Styled.AddFeedStatusContainer>
            <Styled.AddFeedStatusEditorContainer isActive={isActive} className="autotest__feed_status__container">
                <Styled.AddFeedStatusTopSection>
                    <Styled.AddFeedStatusAvatar
                        className="autotest_profile_feed_status_avatar"
                        user={currentUser}
                        size={AVATAR_SIZE}
                        isHidden={isActive}
                    />
                    <div
                        style={{
                            flex: 'auto',
                            position: 'relative',
                            outline: 'none',
                            maxWidth: '100%',
                        }}
                        tabIndex={-1}
                        onFocus={activate}
                        className="autotest__add-status"
                    >
                        <Styled.AddFeedStatusPlaceholder isHidden={isActive}>
                            {finalStub}
                        </Styled.AddFeedStatusPlaceholder>
                        <Styled.UserBlock
                            user={currentUser}
                            avatar={ACTIVE_AVATAR_PROPS}
                            className="autotest_profile_feed_status_avatar__active"
                            secondLine={
                                <PostTypeSelector
                                    isFeed={isFeed}
                                    isCommunity={owner?.type === FeedOwnerType.COMMUNITY}
                                    isEditing={isEditing}
                                    premiumPostsAllowed={premiumPostsAllowed}
                                    communities={communities}
                                    type={postType}
                                    onChangeType={setPostType}
                                    communityId={communityId}
                                    onChangeCommunityId={setCommunityId}
                                />
                            }
                            isHidden={!(isActive && currentUser?.id)}
                        />
                        {isActive && (
                            <EditorWrapper>
                                <Suspense
                                    fallback={
                                        <EditorFallback>
                                            <ProcessingIcon />
                                        </EditorFallback>
                                    }
                                >
                                    <Editor
                                        ref={editorRef}
                                        updateState={setEditorState}
                                        editorStateJson={editorStateJson}
                                        setImgLoading={setImageLoading}
                                        updateJson={setEditorStateJson}
                                        autofocus={autofocus}
                                        placeholder={finalPlaceholder}
                                    />
                                </Suspense>
                            </EditorWrapper>
                        )}
                        {isActive && (
                            <SelectedTagsLine
                                allTags={allTags}
                                selectedIds={selectedTagsIds}
                                setSelectedIds={setSelectedTagsIds}
                            />
                        )}
                    </div>
                </Styled.AddFeedStatusTopSection>
                {isActive && (
                    <Styled.AddFeedStatusBottomSection>
                        <Styled.AddFeedStatusToolbar>
                            <Button
                                kind="ghost-secondary"
                                size="small"
                                icon={PictureIcon}
                                onClick={() => editorRef.current?.showAddImageDialog()}
                            />
                            <Button
                                kind="ghost-secondary"
                                size="small"
                                icon={DollarIcon}
                                onClick={() => editorRef.current?.showAddCashtagDialog()}
                            />
                            {allTags.length ? (
                                <Button
                                    kind="ghost-secondary"
                                    size="small"
                                    icon={ArrowPentagonRight}
                                    onClick={() => setIsShowTagsPopup(true)}
                                >
                                    {isLt960 ? '' : Dic.word('wt_feed__timeline_status__toolbar_add_tag_btn')}
                                </Button>
                            ) : null}
                        </Styled.AddFeedStatusToolbar>

                        {!isMobile && <div>{finalActions}</div>}
                    </Styled.AddFeedStatusBottomSection>
                )}
            </Styled.AddFeedStatusEditorContainer>
            {isMobile && <div>{isActive && finalActions}</div>}
            {Boolean(allTags.length && isShowTagsPopup) && (
                <TagsPopup
                    close={() => setIsShowTagsPopup(false)}
                    tags={allTags}
                    onSubmit={setSelectedTagsIds}
                    initSelectedIds={selectedTagsIds}
                />
            )}
        </Styled.AddFeedStatusContainer>
    );
};
