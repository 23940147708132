import React, { ForwardedRef, forwardRef, ReactNode, FocusEventHandler } from 'react';

import { LabeledSwitchWrapper, SwitchInput, SwitchLabel } from './styled';

export type SwitchProps = {
    isChecked: boolean;
    disabled?: boolean;
    className?: string;
    onChange: (isChecked: boolean) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
};

export const Switch = forwardRef(
    ({ isChecked, onChange, disabled, className, onBlur }: SwitchProps, ref: ForwardedRef<HTMLInputElement>) => (
        <SwitchInput
            ref={ref}
            type="checkbox"
            checked={isChecked}
            disabled={disabled}
            className={className}
            onChange={(e) => {
                onChange(e.target.checked);
            }}
            onBlur={onBlur}
        />
    ),
);

export const LabeledSwitch = ({ label, title, ...restProps }: SwitchProps & { label: ReactNode; title?: string }) => (
    <LabeledSwitchWrapper>
        <Switch {...restProps} />
        <SwitchLabel title={title}>{label}</SwitchLabel>
    </LabeledSwitchWrapper>
);
