import { TabsSize } from './props';

export const ICON_SIZE_MAP: { [key in TabsSize]: number } = {
    small: 16,
    medium: 24,
    large: 24,
};

export const DEFAULT_ICON_SIZE = ICON_SIZE_MAP.medium;

export const DEFAULT_SIZE_TABS = 'large';
