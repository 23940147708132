import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ClockDelayIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C15.7963 22 19.0986 19.8845 20.7922 16.7681L20.7905 16.7673L20.7938 16.7612L19.3868 15.9994C19.2981 16.1632 19.2038 16.3244 19.1039 16.4827L19.1048 16.4832C17.6165 18.837 14.9908 20.4 12 20.4C7.36081 20.4 3.6 16.6392 3.6 12C3.6 7.36081 7.36081 3.6 12 3.6C12.1871 3.6 12.3738 3.60625 12.5597 3.61866L12.6663 2.02222C12.4449 2.00744 12.2227 2 12 2Z" />
        <path d="M14.7489 4.06252C14.3959 3.94028 14.0352 3.84172 13.6691 3.7675L13.987 2.1994C14.4229 2.28776 14.8523 2.4051 15.2725 2.55062L14.7489 4.06252Z" />
        <path d="M16.2713 4.76702C16.1102 4.67188 15.9463 4.58235 15.7799 4.49849L16.4999 3.06964C16.698 3.16947 16.8931 3.27606 17.0849 3.3893C17.2766 3.50255 17.4642 3.62195 17.6473 3.74724L16.7437 5.06768C16.5899 4.96244 16.4324 4.86215 16.2713 4.76702Z" />
        <path d="M18.4031 6.56306C18.1613 6.27833 17.9008 6.01003 17.6233 5.75996L18.6945 4.57139C19.0248 4.86908 19.3349 5.18848 19.6227 5.52745L18.4031 6.56306Z" />
        <path d="M19.3558 7.9438C19.2654 7.77996 19.1698 7.61952 19.0692 7.4627L20.4157 6.59846C20.5355 6.78516 20.6493 6.97615 20.7569 7.17119C20.8644 7.36622 20.9652 7.5644 21.0591 7.76537L19.6097 8.44292C19.5308 8.2741 19.4461 8.10763 19.3558 7.9438Z" />
        <path d="M20.2781 10.5743C20.2147 10.2062 20.1268 9.84271 20.0151 9.48629L21.5417 9.0075C21.6748 9.4318 21.7794 9.86452 21.8549 10.3027L20.2781 10.5743Z" />
        <path d="M20.3963 12.2476C20.4019 12.0606 20.4011 11.8739 20.3942 11.6876L21.9931 11.6282C22.0013 11.8498 22.0022 12.0722 21.9957 12.2948C21.9891 12.5174 21.9751 12.7393 21.9538 12.9601L20.3612 12.8065C20.3791 12.621 20.3908 12.4346 20.3963 12.2476Z" />
        <path d="M19.853 14.9817C19.9856 14.6325 20.0947 14.2748 20.1797 13.9111L21.7378 14.2751C21.6366 14.7081 21.5066 15.1339 21.3488 15.5496L19.853 14.9817Z" />
        <path d="M11.2 12.5001C11.2 12.8162 11.3861 13.1027 11.675 13.2311L16.175 15.2311C16.5788 15.4106 17.0516 15.2288 17.231 14.825C17.4104 14.4213 17.2286 13.9485 16.8249 13.769L12.8 11.9802V6.00009C12.8 5.55827 12.4418 5.20009 12 5.20009C11.5581 5.20009 11.2 5.55827 11.2 6.00009V12.5001Z" />
    </IconSvg>
);
