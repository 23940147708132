import { useState } from 'react';

import { ValidationError } from 'services/validation-error';
import { chooseImage } from 'services/utils/choose-file';
import { readFile } from 'services/utils/read-file';
import { FormFieldProps } from 'components/form';

import { useValidationErrors, ValidationErrorsTypes } from './useValidationErrors';

type ValidateParams = {
    maxSize?: number;
    minWidth?: number;
    minHeight?: number;
    types?: string[];
    onValidation: {
        applyError: (validationError: ValidationError) => void;
        removeError: (fieldName: string) => void;
    };
};

export const useImgField = (fieldName: string, field: FormFieldProps<string>, validateParams: ValidateParams) => {
    const [isLoading, setLoading] = useState(false);

    const { maxSize, minWidth, minHeight, types, onValidation } = validateParams;

    const validate = (file: File): Promise<ValidationErrorsTypes | null> =>
        new Promise((resolve) => {
            const maxSizeMB = maxSize && maxSize * window.FileAPI.MB;

            if (maxSizeMB && file.size > maxSizeMB) {
                return resolve('tooBig');
            }

            if (types && !types.includes(file.type)) {
                return resolve('wrongType');
            }

            const img = new Image();
            const _URL = window.URL || window.webkitURL;

            img.onload = () => {
                if ((minWidth && img.width < minWidth) || (minHeight && img.height < 180)) resolve('tooSmall');
                resolve(null);
            };

            img.src = _URL.createObjectURL(file);

            return undefined;
        });

    const validationErrors = useValidationErrors();

    const handleChange = async () => {
        try {
            const files = await chooseImage();

            setLoading(true);

            const _imgSrc = await readFile(files);
            const errorType = await validate(files[0]);

            if (errorType) {
                const message = validationErrors[fieldName]?.[errorType];
                onValidation.applyError(new ValidationError([{ field: fieldName, message }]));
            } else {
                onValidation.removeError(fieldName);
            }

            field.onValueChange(!errorType && _imgSrc ? _imgSrc : '');
        } finally {
            setLoading(false);
        }
    };

    return {
        isLoading,
        handleChange,
    };
};
