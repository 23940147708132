import styled, { css } from 'styled-components';

import { CSSLiteral, matchBreakpoints } from 'app/styled';
import { caption10RegularFontStyle, caption12RegularFontStyle, caption9RegularFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';

import { CounterShortNameSize, CounterShortNameSizeValue } from './types';

type CounterWrapperProps = { size: CounterShortNameSize };
type CounterTextProps = { size: CounterShortNameSize };

type SizeMap = Record<CounterShortNameSizeValue, CSSLiteral>;

const sizeMapWrapper: SizeMap = {
    XL: css`
        padding: 2px 6px;
        min-height: 20px;
        min-width: 20px;
    `,
    L: css`
        padding: 0 ${tokens.spacing['2xs']};
        min-height: ${tokens.spacing.m};
        min-width: ${tokens.spacing.m};
    `,
    M: css`
        padding: 0 3.5px;
        min-height: 14px;
        min-width: 14px;
    `,
    S: css`
        padding: 0 3px;
        min-height: 12px;
        min-width: 12px;
    `,
};

const sizeMapText: SizeMap = {
    XL: css`
        ${caption12RegularFontStyle};
    `,
    L: css`
        ${caption12RegularFontStyle};
    `,
    M: css`
        ${caption10RegularFontStyle};
    `,
    S: css`
        ${caption9RegularFontStyle};
    `,
};

function setWrapperStylesBySize({ size }: CounterWrapperProps) {
    return matchBreakpoints(size, sizeMapWrapper);
}

function setTextStylesBySize({ size }: CounterTextProps) {
    return matchBreakpoints(size, sizeMapText);
}

export const CounterWrapper = styled.div<CounterWrapperProps>`
    display: inline-block;
    background-color: ${tokens.colors.bg.brand.default.default};
    border-radius: ${tokens.radius.rounded};
    ${setWrapperStylesBySize};
    box-sizing: border-box;
`;

export const Text = styled.span<CounterTextProps>`
    display: block;
    color: ${({ theme }) => theme.staticColors.white};
    ${setTextStylesBySize};
`;
