import { createGlobalStyle } from 'styled-components';
import React, { FC, useLayoutEffect, useState } from 'react';

let _scrollWidth = 0;

const ScrollBlockStyle = createGlobalStyle`body { overflow: hidden; padding-right: ${() => _scrollWidth}px; }`;

export const ScrollBlocker: FC = () => {
    const [block, setBlock] = useState(false);

    useLayoutEffect(() => {
        /** Measuring width of scroll BEFORE hiding it */
        const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
        /** If it's zero, scroll was already hidden by a different instance of this component,
         * and scrollWidth was already measured and stored */
        if (scrollWidth > 0) {
            _scrollWidth = scrollWidth;
        }
        setBlock(true);
    }, []);

    return block ? <ScrollBlockStyle /> : null;
};
