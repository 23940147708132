import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { caption12MediumFontStyle, body17MediumFontStyle, body14MediumFontStyle } from 'services/typography';
import { MenuItem } from 'components/context-actions';

export const ComplaintContent = styled.div<{ currentType: string }>`
    ${({ currentType }) =>
        currentType === 'main'
            ? `
        margin: 0 -24px;
        ${mediaQuery.lt768} {
            margin: 0 -16px;
        }
    `
            : ''}
`;

const menuItemCss = `
    padding-left: 24px;
    ${mediaQuery.lt768} {
        padding-left: 16px;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    ${menuItemCss};
`;

export const Reason = styled.div`
    ${menuItemCss};
    ${caption12MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    margin-top: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    ${mediaQuery.lt768} {
        height: auto;
        margin-bottom: 8px;
    }
`;

export const Subtitle = styled.div`
    ${body17MediumFontStyle};
    margin-top: 8px;
    padding-top: 8px;
    padding-bottom: 4px;
    ${mediaQuery.lt768} {
        ${body14MediumFontStyle};
    }
`;

export const ComplaintText = styled.div`
    margin-top: 4px;
    ul {
        padding-left: 22px;
    }
`;

export const DescriptionLabel = styled.div`
    color: ${({ theme }) => theme.label.secondary};
    margin: 16px 0 8px;
`;
