import React, { FC, MouseEvent, ReactElement, useCallback, useRef, useState } from 'react';

import { isProductFinamCardByItem } from 'services/finam-product';
import { useSafeUnmount } from 'hooks/useSafeUnmount';
import { ProductCardPropsType } from 'widgets/product-card/types';
import { ProductCardFinamPropsType } from 'widgets/product-card-finam/types';
import DefaultCard from 'components/card';

import { SliderLimexCardProps, SliderFinamCardProps } from '../types';
import * as Styled from '../styled/Card.styled';
import { CardWrapper } from '../styled/Card.styled';

function useDragAndClick<N>(onClick: N) {
    const [clientXInitialDragCurrentCard, setClientXInitialDragCurrentCard] = useState(0);
    const [isChangedPositionCurrentCard, setIsChangedPositionCurrentCard] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    const onMouseDown = useCallback((event: MouseEvent) => {
        setClientXInitialDragCurrentCard(event.clientX);
    }, []);

    const withSafeUnmount = useSafeUnmount();

    const onMouseUp = useCallback(
        (event: MouseEvent) => {
            if (clientXInitialDragCurrentCard !== event.clientX) {
                setIsChangedPositionCurrentCard(true);
            }

            setTimeout(() => {
                withSafeUnmount(() => {
                    setIsChangedPositionCurrentCard(false);
                });
            }, 100);
        },
        [clientXInitialDragCurrentCard, withSafeUnmount],
    );

    const handleClick = isChangedPositionCurrentCard ? undefined : onClick;
    return {
        ref,
        isChangedPositionCurrentCard,
        onMouseUp,
        onMouseDown,
        handleClick,
    };
}

function SliderCardImplementation<T extends ProductCardPropsType | ProductCardFinamPropsType>(props: {
    cardProps?: Omit<T, 'item'>;
    children(props: T): ReactElement;
}) {
    const { cardProps, children } = props;
    const { onClick, ...otherCardProps } = cardProps || {};

    const { ref, onMouseUp, isChangedPositionCurrentCard, onMouseDown, handleClick } =
        useDragAndClick<T['onClick']>(onClick);

    return (
        <Styled.CardWrapper ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
            {children({
                withoutBoxShadow: true,
                isHeightGrow: true,
                ...otherCardProps,
                onClick: handleClick,
                isDisable: isChangedPositionCurrentCard,
            } as T)}
        </Styled.CardWrapper>
    );
}

export const LimexSliderCard: FC<SliderLimexCardProps> = (props) => {
    const { item, getCardProps, cardProps: cardPropsProp } = props;

    const cardPropsFromFn = getCardProps?.(item) || {};
    const cardProps = { ...cardPropsFromFn, ...cardPropsProp };

    return (
        <SliderCardImplementation<ProductCardPropsType> cardProps={cardProps}>
            {(implementationProps) => <Styled.LimexProductSliderCard {...implementationProps} item={item} />}
        </SliderCardImplementation>
    );
};

export const FinamSliderCard: FC<SliderFinamCardProps> = (props) => {
    const { item, cardProps: cardPropsProp, getCardProps, buildActionButtons } = props;

    if (!item) {
        return (
            <CardWrapper>
                <DefaultCard isHeightGrow isLoading />
            </CardWrapper>
        );
    }

    if (!isProductFinamCardByItem(item)) {
        if (process.env.DLD !== 'prod') {
            console.error('Check the finam FinamSliderCard item', item);
        }

        return null;
    }
    const cardPropsFromFn = getCardProps?.(item) || {};
    const cardProps = { ...cardPropsFromFn, ...cardPropsProp };
    const actionButtons = Object.assign({}, cardProps?.actionButtons, buildActionButtons?.(item)); // eslint-disable-line
    return (
        <SliderCardImplementation<ProductCardFinamPropsType> cardProps={cardProps}>
            {(implementationProps) => (
                <Styled.FinamProductSliderCard {...implementationProps} actionButtons={actionButtons} item={item} />
            )}
        </SliderCardImplementation>
    );
};
