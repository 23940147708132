/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marketplace_Web_Public_V1_My_UserProducts_CreateUsUserProductWebRequest } from '../models/UsUserProductsService/Marketplace_Web_Public_V1_My_UserProducts_CreateUsUserProductWebRequest';
import type { Marketplace_Web_Public_V1_My_UserProducts_UpdateUsUserProductWebRequest } from '../models/UsUserProductsService/Marketplace_Web_Public_V1_My_UserProducts_UpdateUsUserProductWebRequest';
import type { WebResponse_RichUserProductDto } from '../models/UsUserProductsService/WebResponse_RichUserProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsUserProductsService {
    /**
     * Добавить программный продукт пользователя
     * @returns WebResponse_RichUserProductDto Success
     * @throws ApiError
     */
    public static createProduct({
        requestBody,
    }: {
        /**
         * Данные продукта
         */
        requestBody?: Marketplace_Web_Public_V1_My_UserProducts_CreateUsUserProductWebRequest;
    }): CancelablePromise<WebResponse_RichUserProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/us/user-products',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Обновить программный продукт пользователя
     * @returns WebResponse_RichUserProductDto Success
     * @throws ApiError
     */
    public static updateProduct({
        requestBody,
    }: {
        /**
         * Данные продукта
         */
        requestBody?: Marketplace_Web_Public_V1_My_UserProducts_UpdateUsUserProductWebRequest;
    }): CancelablePromise<WebResponse_RichUserProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/us/user-products',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }
}
