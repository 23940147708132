import React, { ChangeEvent, FocusEventHandler, ForwardedRef, forwardRef, RefObject, useCallback } from 'react';

import { MagnifierIcon } from 'components/icons2/MagnifierIcon';
import { CrossCircleFilled } from 'components/icons2/CrossCircleFilled';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import * as Styled from './styled';

export interface SearchInputProps {
    placeholder?: string;
    loading?: boolean;
    value: string;
    onChange: (newValue: string) => void;
    className?: string;
    autoFocus?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    wrapperRef?: ForwardedRef<HTMLLabelElement>;
    autotestClass?: string;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
    const {
        placeholder,
        value,
        onChange,
        className,
        loading = false,
        autoFocus = false,
        onFocus,
        onBlur,
        wrapperRef,
        autotestClass,
    } = props;

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value: newValue } = event.target;
            onChange(newValue);
        },
        [onChange],
    );

    const handleClear = useCallback(() => {
        onChange('');
    }, [onChange]);

    return (
        <Styled.SearchInputWrapper className={className} ref={wrapperRef}>
            {loading ? <ProcessingIcon size={24} /> : <MagnifierIcon size={24} />}
            <Styled.SearchInputStyled
                ref={ref}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onFocus={onFocus}
                onBlur={onBlur}
                className={autotestClass}
            />
            {/* Предотвращает автозаполнение поля поиска email-ом пользователя при открытии формы смены пароля в chrome */}
            <input
                tabIndex={-1}
                style={{
                    position: 'fixed',
                    top: '-100px',
                    left: '-100px',
                    width: '5px',
                }}
            />
            {value.length > 0 && (
                <Styled.ClearButton
                    kind="ghost-secondary"
                    size="small"
                    icon={CrossCircleFilled}
                    onClick={handleClear}
                />
            )}
        </Styled.SearchInputWrapper>
    );
});
