import { useLayoutEffect, useRef, useState } from 'react';

export function useTrackFocus<T extends HTMLElement>() {
    const ref = useRef<T | null>(null);

    const [isFocus, setIsFocus] = useState(false);

    useLayoutEffect(() => {
        const input = ref.current;

        const focus = () => {
            setIsFocus(true);
        };

        const blur = () => {
            setIsFocus(false);
        };

        if (input) {
            input.addEventListener('focus', focus);
            input.addEventListener('blur', blur);

            return () => {
                input.removeEventListener('focus', focus);
                input.removeEventListener('blur', blur);
            };
        }
        return undefined;
    }, []);

    return [ref, isFocus] as const;
}
