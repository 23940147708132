import React, { FC, useEffect, useRef, memo, DragEvent, SyntheticEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { MarketplaceService } from 'services/api-services/generated_limex';
import { chooseFile } from 'services/utils/choose-file';
import { ApiError } from 'services/api-services/generated_limex/core/ApiError';
import { useAsyncFn } from 'hooks/useAsyncFn';
import { useDictionary } from 'hooks/useDictionary';
import { Button } from 'components/button2';
import { ErrorMessage } from 'components/text-input/styled';
import { DocTextIcon } from 'components/icons2/DocTextIcon';
import { TrashIcon } from 'components/icons2/TrashIcon';

import type { FileFieldProps } from './types';
import { fileTypes } from './constants';
import * as Styled from './styled';
import { File } from './styled';

const preventDefault = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
};

export const FileField: FC<FileFieldProps> = memo(({ name, rules, className }) => {
    const {
        field: { value, onChange },
        fieldState: { error: fieldError },
    } = useController({
        name,
        rules,
    });

    const dic = useDictionary();
    const { setError, clearErrors } = useFormContext();

    const fileNameRef = useRef('');

    const [{ isLoading, isSuccess, error }, sendBrokerReport] = useAsyncFn<
        void,
        ApiError<{ violations: { message: string }[] }>,
        File[]
    >((file: File) => {
        fileNameRef.current = file.name;
        return MarketplaceService.sendBrokerReport({ formData: { brokerReport: file } });
    });

    useEffect(() => {
        if (isSuccess) {
            onChange(fileNameRef.current);
            clearErrors(name);
        }
    }, [clearErrors, isSuccess, name, onChange]);

    useEffect(() => {
        if (error) {
            const message = error?.body?.violations?.[0]?.message;
            if (message) {
                setError(name, { message });
            } else if (error.status === 413) {
                setError(name, {
                    message: dic.word('wt_app__marketplace_broker_report__error_broker_report_file_is_too_large'),
                });
            }
        }
    }, [error, name, setError, dic]);

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        preventDefault(event);
        const file = event.dataTransfer.files[0];
        if (!isLoading) {
            sendBrokerReport(file);
        }
    };

    const chooseFileAndUpload = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isLoading) {
            chooseFile(fileTypes)()
                .then((files) => sendBrokerReport(files[0]))
                .catch(() => undefined);
        }
    };

    return (
        <div className={className}>
            <Styled.Wrapper onDrop={handleDrop} onDragOver={preventDefault}>
                {value ? (
                    <Styled.File>
                        <DocTextIcon />
                        <span>{value}</span>
                        <Button
                            kind="ghost-secondary"
                            icon={TrashIcon}
                            onClick={() => {
                                onChange('');
                                clearErrors(name);
                            }}
                        />
                    </Styled.File>
                ) : (
                    <Button kind="secondary" size="medium" onClick={chooseFileAndUpload} isProcessing={isLoading}>
                        {dic.word('wt_all__widget_product_wizard__upload')}
                    </Button>
                )}
            </Styled.Wrapper>
            {fieldError ? <ErrorMessage>{fieldError.message}</ErrorMessage> : null}
        </div>
    );
});
