import React, { forwardRef, useMemo } from 'react';

import { ChevronUpSmallIcon } from 'components/icons2/ChevronUpSmallIcon';
import { ChevronDownSmallIcon } from 'components/icons2/ChevronDownSmallIcon';

import { ListBoxButtonProps } from '../types/Button.types';
import { DEFAULT_KIND_LIST_BOX, DEFAULT_SIZE_LIST_BOX, DEFAULT_WIDTH_LIST_BOX } from '../constants';
import * as Styled from '../styled/Button.styled';

import { RightGroup } from './RightGroup';

export const ListBoxButton = forwardRef<HTMLButtonElement, ListBoxButtonProps>(
    (
        {
            className: classNameProp,
            children,
            label,
            onClick,
            rightGroup,
            hasChevronIcon = true,
            placeholder,
            isError = false,
            isSkeleton = false,
            isOpen = false,
            isDisabled = false,
            width = DEFAULT_WIDTH_LIST_BOX,
            kind = DEFAULT_KIND_LIST_BOX,
            size = DEFAULT_SIZE_LIST_BOX,
        },
        forwardedRef,
    ) => {
        const className = useMemo(
            () => ['autotest__list-box__btn', classNameProp].filter((cn) => cn).join(' '),
            [classNameProp],
        );

        const textEl = useMemo(() => {
            if (!children) {
                return null;
            }

            if (typeof children === 'string') {
                return (
                    <Styled.Text hasText={!!children} size={size}>
                        {children}
                    </Styled.Text>
                );
            }

            if (React.isValidElement(children)) {
                return children;
            }

            return null;
        }, [children, size]);

        const hasTextEl = !!textEl;
        const hasLabel = !!label;

        const renderRightGroup = () => {
            const hasRightGroup = rightGroup && Object.keys(rightGroup).length > 0;

            if (!hasRightGroup || isSkeleton) {
                return null;
            }

            const { text: textRightGroup = undefined, icon: iconRightGroup = undefined } = rightGroup;

            return (
                <RightGroup
                    text={textRightGroup}
                    size={size}
                    icon={iconRightGroup}
                    isDisabled={isDisabled}
                    isError={isError}
                />
            );
        };

        const textContentAndPlaceholderEl = useMemo(() => {
            if (hasTextEl) {
                return textEl;
            }

            if (placeholder) {
                return (
                    <Styled.Placeholder hasLabel={hasLabel} size={size}>
                        {placeholder}
                    </Styled.Placeholder>
                );
            }

            return null;
        }, [hasLabel, hasTextEl, placeholder, size, textEl]);

        const hasTextOrPlaceholder = !!textContentAndPlaceholderEl;

        const renderContent = () => {
            if (isSkeleton) {
                return null;
            }

            return (
                <>
                    <Styled.Content hasTextOrPlaceholder={hasTextOrPlaceholder}>
                        <Styled.LabelWrapper>
                            <Styled.Label
                                hasTextEl={hasTextEl}
                                hasLabel={hasLabel}
                                hasTextOrPlaceholder={hasTextOrPlaceholder}
                                isDisabled={isDisabled}
                                size={size}
                                isOpen={isOpen}
                                isError={isError}
                            >
                                {label}
                            </Styled.Label>
                        </Styled.LabelWrapper>
                        <Styled.TextAndPlaceholderWrapper
                            isDisabled={isDisabled}
                            hasTextOrPlaceholder={hasTextOrPlaceholder}
                            size={size}
                        >
                            {textContentAndPlaceholderEl}
                        </Styled.TextAndPlaceholderWrapper>
                    </Styled.Content>
                    <Styled.Controls>
                        {renderRightGroup()}
                        {hasChevronIcon && (
                            <Styled.IconChevronWrapper isDisabled={isDisabled} isError={isError}>
                                {isOpen ? <ChevronUpSmallIcon /> : <ChevronDownSmallIcon />}
                            </Styled.IconChevronWrapper>
                        )}
                    </Styled.Controls>
                </>
            );
        };

        return (
            <Styled.Button
                onClick={onClick}
                ref={forwardedRef}
                className={className}
                width={width}
                kind={kind}
                disabled={isDisabled}
                size={size}
                isError={isError}
                isOpen={isOpen}
                isSkeleton={isSkeleton}
            >
                <Styled.ButtonContentWrapper>{renderContent()}</Styled.ButtonContentWrapper>
            </Styled.Button>
        );
    },
);
