import React, { useMemo } from 'react';

import { useLocaleId } from 'hooks/useLocaleId';
import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { usePageContext } from 'hooks/usePageContext';
import { footerSocialIcons } from 'components/social-icons/data';

import { FooterLayout } from './components/FooterLayout';
import { LinksBlocksDataType } from './components/LinksBlocks';

export type FooterPropsType = {
    withPageLayout?: boolean; // use that when you insert footer without layout on page. That insert default layout for footer
};

export const Footer = (props: FooterPropsType) => {
    const { withPageLayout = false } = props;

    const lang = useLocaleId();
    const dic = useDictionary();
    const links = useLinks();
    const [{ domainLcid }] = usePageContext();

    const linksBlocksData = useMemo((): LinksBlocksDataType => {
        const isRuLang = lang === 'ru';

        return [
            {
                key: 'menu',
                title: dic.word('wt_all__footer_widget__menu_title'),
                items: [
                    {
                        label: dic.word('wt_all__footer_widget__menu__feed'),
                        href: links.feed(),
                        className: 'autotest__footer__source-link__feed',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__menu__shop'),
                        href: links.shop(),
                        className: 'autotest__footer__source-link__shop',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__menu__monetization'),
                        href:
                            domainLcid === 'ru'
                                ? links.tildaPartnersLimexMeRoot()
                                : links.tildaPromoLimexComMonetization(),
                        className: 'autotest__footer__source-link__monetization',
                    },
                ],
            },
            {
                key: 'company',
                title: dic.word('wt_all__footer_widget__company_title'),
                items: [
                    {
                        label: dic.word('wt_all__footer_widget__company__about'),
                        href: links.tildaInfoLimexMeRoot({ isRuLang }),
                        className: 'autotest__footer__source-link__about',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__company__disclaimer'),
                        href: links.tildaInfoLimexMeDisclaimer(),
                        className: 'autotest__footer__source-link__disclaimer',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__company__privacy_policy'),
                        href: links.tildaInfoLimexMePrivacyPolicy(),
                        className: 'autotest__footer__source-link__privacy-policy',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__company__cookies_policy'),
                        href: links.tildaInfoLimexMeCookiesAndTrackers(),
                        className: 'autotest__footer__source-link__cookies-and-trackers',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__company__terms_and_conditions'),
                        href: links.tildaInfoLimexMeTermsAndConditions(),
                        className: 'autotest__footer__source-link__terms-and-conditions',
                    },
                    {
                        label: dic.word('wt_all__footer_widget__company__community_rules'),
                        href: links.tildaInfoLimexMeCommunityGuidelinesRu(),
                        className: 'autotest__footer__source-link__community-rules',
                        isRender: isRuLang,
                    },
                    {
                        label: 'F.A.Q',
                        href: links.tildaInfoLimexMeFaq(),
                        className: 'autotest__footer__source-link__faq',
                    },
                ],
            },
        ];
    }, [lang, dic, links, domainLcid]);

    return (
        <FooterLayout
            withPageLayout={withPageLayout}
            linksBlocksData={linksBlocksData}
            socialIconsData={footerSocialIcons[lang]}
            contactsTitle={dic.word('wt_all__footer_widget__contacts_title')}
        />
    );
};
