import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';
import { FormItemMessage } from 'components/form';

export const ProductCoverUploadContainer = styled.div`
    height: 208px;
`;

export const CoverTip = styled(FormItemMessage)`
    ${body14RegularFontStyle};
    margin-top: 0;
    margin-bottom: 12px;
`;
