import React from 'react';

import Dic from 'services/dictionary';
import { usePageContext } from 'hooks/usePageContext';
import { useLinks } from 'hooks/useLinks';

import * as Styled from './styled';
import LocksSvg from './locks.svg';
import ShadowSvg from './shadow.svg';

type Props = {
    className?: string;
    title?: string;
    subtitle?: string;
};

export const PremiumPostBanner = ({ className, title, subtitle }: Props) => {
    const links = useLinks();
    const [{ domainLcid }] = usePageContext();
    const submitBtnHref =
        domainLcid === 'en' ? links.tildaPromoLimexComMonetization() : links.tildaPartnersLimexMeRoot();

    return (
        <Styled.Wrapper className={className}>
            <Styled.ShadowSvgWrapper>
                <ShadowSvg />
            </Styled.ShadowSvgWrapper>
            <Styled.LocksSvgWrapper>
                <LocksSvg />
            </Styled.LocksSvgWrapper>
            <Styled.Content>
                <Styled.Title>{title || Dic.word('wt_all__widget_premium_post_banner__title_text')}</Styled.Title>
                <Styled.Text>{subtitle || Dic.word('wt_all__widget_premium_post_banner__subtitle')}</Styled.Text>
                <Styled.ButtonWrapper>
                    <Styled.Button href={submitBtnHref} size="small">
                        {Dic.word('wt_all__widget_premium_post_banner__submit_btn')}
                    </Styled.Button>
                </Styled.ButtonWrapper>
            </Styled.Content>
        </Styled.Wrapper>
    );
};
