import React, { useCallback, useEffect, useRef } from 'react';
import type { OutputData } from '@editorjs/editorjs';

import { Id, PostType } from 'types';
import { FeedOwner } from 'context/feed';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useAsyncFn, UseAsyncFnState } from 'hooks/useAsyncFn';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';

import { PublisherFn } from '../types';

import { cashtagRegexBase } from './useCashtags';

// MARK: - Types

type UsePublishStatus = (settings: {
    publisherFn: PublisherFn;
    owner: FeedOwner;
    resetAppearanceRef: React.MutableRefObject<() => void>;
    editorState: string;
    editorStateJson: OutputData | null;
    postType?: PostType | null;
    objId?: Id;
    communityId?: Id;
    selectedTagsIds?: number[];
    instanceId: string;
}) => UsePublishStatusResult;

type UsePublishStatusResult = {
    publishStatusState: PublishStatusState;
    publishStatus: () => void;
};

type PublishStatusState = UseAsyncFnState<unknown, unknown>;

export const sanitizeHTML = (html: string) => html.replace(new RegExp(/<p><\/p>/gi), '');

// MARK: - Hook

/** Handles the logic of publishing user status to the feed page */
export const usePublishStatus: UsePublishStatus = (opts) => {
    const {
        publisherFn,
        owner,
        postType,
        objId,
        resetAppearanceRef,
        editorState,
        editorStateJson,
        selectedTagsIds,
        communityId,
        instanceId,
    } = opts;
    const csrf = useCSRFToken();
    const generator = useCurrentUser();

    const editorStateRef = useRef(editorState);
    const editorStateJsonRef = useRef(editorStateJson);

    useEffect(() => {
        editorStateRef.current = editorState;
        editorStateJsonRef.current = editorStateJson;
    }, [editorState, editorStateJson]);

    const wrappedPublisherFn = useCallback(
        (statusText: string, statusJson: string) => {
            const publishSettings = {
                csrf,
                owner,
                postType: postType || PostType.ORDINARY,
                generator,
                id: objId,
                tags: selectedTagsIds,
                communityId,
            };
            return publisherFn(instanceId, statusText, statusJson, publishSettings);
        },
        [csrf, owner, postType, generator, objId, selectedTagsIds, communityId, publisherFn, instanceId],
    );

    const [publishStatusState, publishStatus] = useAsyncFn(wrappedPublisherFn);

    const { isSuccess } = publishStatusState;

    useEffect(() => {
        if (isSuccess) {
            resetAppearanceRef.current();
        }
    }, [isSuccess, resetAppearanceRef]);

    const validateAndSend = (
        json: OutputData,
        html: string,
        callback: (statusText: string, statusJson: string) => Promise<unknown>,
    ) => {
        if (
            json.blocks.every((block) => {
                if (block.type === 'paragraph') {
                    return !block.data.text
                        .split(new RegExp(`\\$${cashtagRegexBase}`))
                        .filter((group: string) => group && !group.match(/^\s+$/)).length;
                }
                return false;
            })
        ) {
            notify.error(Dic.word('wt_feed__editor__error__chasTag'));
            return;
        }
        callback(html, JSON.stringify(json));
    };

    const publishStatusAfterAuthentication = TODOuseActionAfterAuthentication(() => {
        setTimeout(() => {
            if (editorStateJsonRef.current && editorStateRef.current) {
                validateAndSend(editorStateJsonRef.current, editorStateRef.current, publishStatus);
            }
        }, 500);
    });

    return {
        publishStatusState,
        publishStatus: publishStatusAfterAuthentication,
    };
};
