import styled from 'styled-components';

import { tokens } from 'services/tokens';
import DefaultCardBase from 'components/card';
import { CardThumbnailBlock } from 'components/card/partials/default-layout/styled';

export const DefaultCard = styled(DefaultCardBase)`
    ${CardThumbnailBlock} {
        background-color: ${tokens.colors.opacity.base['16']};
    }
`;
