import React from 'react';

type Props = {
    namePart: 'support' | 'feedback' | 'partners';
};

export const LimexEmailLink = (props: Props) => {
    const { namePart } = props;
    const content = `${namePart}@limex.com`;
    return <a href={`mailto:${content}`}>{content}</a>;
};
