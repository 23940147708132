import styled from 'styled-components';

import { body14RegularFontStyle, body16MediumFontStyle, caption12MediumFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import { BoltIcon as BoltIconComponent } from 'components/icons2/BoltIcon';

import { LevelRiskNumber, LevelRiskSize } from './types';

const HEIGHT_BALLOON = 6;
const TRANSITION_DURATION = 360;

const getBgColorLevel = (theme: Theme, count?: LevelRiskNumber) => {
    switch (count) {
        case 1 || '1':
            return `${theme?.risk?.levelRisk1}`;

        case 2 || '2':
            return `${theme?.risk?.levelRisk2}`;

        case 3 || '3':
            return `${theme?.risk?.levelRisk3}`;

        case 4 || '4':
            return `${theme?.risk?.levelRisk4}`;

        case 5 || '5':
            return `${theme?.risk?.levelRisk5}`;

        case 6 || '6':
            return `${theme?.risk?.levelRisk6}`;

        case 7 || '7':
            return `${theme?.risk?.levelRisk7}`;

        case 8 || '8':
            return `${theme?.risk?.levelRisk8}`;

        case 9 || '9':
            return `${theme?.risk?.levelRisk9}`;

        case 10 || '10':
            return `${theme?.risk?.levelRisk10}`;

        default:
            return `${theme?.fillIn.secondary.highest}`;
    }
};

export const LevelRiskWrapper = styled.div`
    display: inline-block;
    box-sizing: content-box;
`;

export const Content = styled.div`
    position: relative;
`;

export const BalloonArrow = styled.div<{ count?: LevelRiskNumber; balloon: boolean; color?: string }>`
    height: ${({ balloon }) => (balloon ? '6' : '0')}px;
    position: relative;
    overflow: hidden;
    transition: height ${TRANSITION_DURATION}ms;
    &:after {
        content: '';
        position: absolute;
        left: calc(50% - 6px);
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        transition: border-top-color ${TRANSITION_DURATION / 2}ms, top ${TRANSITION_DURATION}ms,
            opacity ${TRANSITION_DURATION / 2}ms;
        clear: both;
        ${({ balloon, theme, count, color }) =>
            balloon
                ? `
        border-top: ${HEIGHT_BALLOON}px solid ${color || getBgColorLevel(theme, count)};
        opacity: 1;
        top: 0;
    `
                : `
        border-top: ${HEIGHT_BALLOON}px solid transparent;
        opacity: 0;
        top: -6px;
    `};
    }
`;

export const LineWrapper = styled.div<{
    count?: number;
    hasTooltipText: boolean;
    isShowLevelNumberValue: boolean;
    size: LevelRiskSize;
    color?: string;
}>`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    padding: ${({ size, isShowLevelNumberValue }) => {
        switch (size) {
            case 'small':
                if (isShowLevelNumberValue) {
                    return '0 8px 0 6px';
                }
                return '0 4px 0 4px';
            case 'medium':
            default:
                if (isShowLevelNumberValue) {
                    return '2px 8px 2px 6px';
                }
                return '2px 6px 2px 6px';
        }
    }};
    background-color: ${({ theme, count, color }) => color || getBgColorLevel(theme, count as LevelRiskNumber)};
    border-radius: 24px;
    line-height: 20px;
    height: ${({ size }) => (size === 'small' ? 20 : 23)}px;
    transition: background-color ${TRANSITION_DURATION / 2}ms;
    ${({ hasTooltipText }) => (hasTooltipText ? 'cursor: pointer' : '')};
`;

export const Count = styled.span<{ size: LevelRiskSize }>`
    ${({ size }) => (size === 'small' ? caption12MediumFontStyle : body16MediumFontStyle)};
    color: ${({ theme }) => theme.staticColors.white};
`;

export const BoltIcon = styled(BoltIconComponent)`
    display: block;
    fill: ${({ theme }) => theme.staticColors.white};
`;

export const TooltipText = styled.span`
    ${body14RegularFontStyle};
    padding: 4px 8px;
    border-radius: 8px;
    display: block;
`;
