import React, { MouseEvent, useMemo, useRef } from 'react';

import { useClickOutside } from 'hooks/useClickOutside';
import { Snackbar } from 'components/snackbar';
import { SnackbarButtonProps, SnackbarType } from 'components/snackbar/types';

import { NotifyProps } from './types';
import * as Styled from './styled';

export const Notify = (props: NotifyProps) => {
    const {
        icon,
        text,
        button,
        type,
        size,
        className: classNameProp,
        onMouseLeave,
        onMouseEnter,
        close,
        closeOnClickOutside = true,
        // если добавляете новые параметры не забудьте добавить обработку в comon/static/www/react/sources/services/notify/processor.tsx
    } = props;

    const className = useMemo(() => [classNameProp, 'autotest__notify'].filter((i) => i).join(' '), [classNameProp]);

    const buttonProps = useMemo((): SnackbarButtonProps => {
        const btnClick = (e: MouseEvent<HTMLButtonElement>) => {
            if (close) {
                button?.onClick?.(e, close);
            }
        };

        return {
            onClick: btnClick,
            content: button?.content,
        };
    }, [button, close]);

    const snackbarType = useMemo((): SnackbarType => {
        switch (type) {
            case 'success':
                return 'positive';
            case 'warning':
                return 'warning';
            case 'error':
                return 'negative';
            default:
                return 'default';
        }
    }, [type]);

    const rootElRef = useRef(null);

    useClickOutside(rootElRef, (e) => {
        if (closeOnClickOutside) {
            close();
        }
    });

    return (
        <Styled.Notify className={className} ref={rootElRef}>
            <Snackbar
                text={text}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                button={buttonProps}
                icon={icon}
                type={snackbarType}
                size={size}
            />
        </Styled.Notify>
    );
};
