import { useEffect, useState } from 'react';
import parseISO from 'date-fns/parseISO';

import { Person } from 'types';

import { useIsOwner } from '../../feed/hooks/useIsOwner';

const editTime = 60;

export const useAllowedActions = (date: { created: string }, author: Person) => {
    const diff = () => Math.abs(parseISO(date.created).getTime() - new Date().getTime());
    const getDiffMinute = () => Math.round(diff() / 1000 / 60);
    const isOwner = useIsOwner(author);

    const [diffMinute, setDiffMinute] = useState(getDiffMinute());

    useEffect(() => {
        if (!isOwner) {
            return () => {
                // nothing
            };
        }
        let interval: ReturnType<typeof setInterval> | null = null;
        if (getDiffMinute() < editTime) {
            interval = setInterval(() => setDiffMinute(getDiffMinute()), 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isOwner]);

    return { isAllowedActions: isOwner && diffMinute < editTime };
};
