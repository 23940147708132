import { FeedRecord, FeedRecordObj, FeedRecordObjBlogpostMask, FeedRecordObjTypes, isPublishStatusRecord } from 'types';
import { Cancelation } from 'services/create-action-types/backend-client';
import { CancelationData } from 'services/create-action-types/backend-client/types';
import { recordStoreIndex } from 'services/utils/map-records-by-id';

export { recordStoreIndex as index };

export const getCommentsAndPath = (record: FeedRecord) => {
    if (!isPublishStatusRecord(record)) {
        throw new Error('Неверное использование getCommentsAndPath');
    }
    if (record.obj.type === FeedRecordObjTypes.POST) {
        return {
            comments: record.obj.comments,
            path: 'obj.comments',
        };
    }
    return {
        comments: record.comments ?? [],
        path: 'comments',
    };
};

type CancelItem<Prev> = {
    cancelation: CancelationData;
    prevSpecialState: Prev;
};
export class CancelActionProcessor<Prev> {
    private items: {
        [actionId: string]: CancelItem<Prev> | null;
    } = {};

    public cancelOrBuild(actionId: string, prevSpecialState: Prev): CancelItem<Prev> {
        let item = this.items[actionId];
        if (item) {
            if (!item.cancelation.outdated) Promise.all([item.cancelation.source.cancel()]);
            item.cancelation = Cancelation();
        } else {
            item = {
                cancelation: Cancelation(),
                prevSpecialState,
            };
            this.items[actionId] = item;
        }
        return item;
    }

    public clear(actionId: string) {
        this.items[actionId] = null;
    }
}

export const isPremiumPost = (post: Partial<FeedRecordObj> | undefined) => {
    if (!post?.blogpostType && post?.blogpostType !== 0) return false;
    // eslint-disable-next-line no-bitwise
    return (post.blogpostType & FeedRecordObjBlogpostMask.PREMIUM) === FeedRecordObjBlogpostMask.PREMIUM;
};
