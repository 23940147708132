import React, { ReactElement, useMemo, useRef, useState, useCallback } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';

import { ValidationError } from 'services/validation-error';

// MARK: - Types

export type FormFieldValue =
    | string
    | number
    | boolean
    | undefined
    | null
    | { [key: string]: FormFieldValue }
    | FormFieldValue[];

export type FormState<Data extends Record<string, FormFieldValue>> = {
    [Key in keyof Data]: FormFieldState<Data[Key]>;
};

export type InitialStateArg<CurrentFormState> = {
    [Key in keyof CurrentFormState]: (CurrentFormState[Key] & { value: FormFieldValue })['value'];
};

export type FormFieldState<Value extends FormFieldValue> = {
    value: Value;
    errors: string[] | undefined;
    isDirty: boolean;
};

type FormFieldDomProps<Value extends FormFieldValue> = {
    get value(): Value;
    onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus: () => void;
};

export type FormFieldProps<Value extends FormFieldValue> = {
    /**
     * Props that **must** be supplied to a native input DOM Node
     * @example
     * <input type="text" {...domProps} />
     */
    domProps: FormFieldDomProps<Value>;

    /** An array of errors for this field */
    get errors(): string[] | undefined;

    /** check if value of a field is equal initial value */
    isDirty(): boolean;

    /** @deprecated An alias for `onValueChange` */
    onRawChange: (value: Value) => void;

    /** A callback for changing a custom input's value */
    onValueChange: (value: Value) => void;

    /** A callback for changing an error state (set undefined to remove errors) */
    onErrorsChange: (errors: string[] | undefined) => void;

    /** set isDirty to false for field handler */
    setPristine: () => void;
} & FormFieldDomProps<Value>;

export type FormFieldsProps<Data extends Record<string, FormFieldValue>> = {
    [Key in keyof Data]: FormFieldProps<Data[Key]>;
};

export type FormHandlers = {
    formData(): ReturnType<typeof getFormData>;
    isValid(): boolean;
    isDirty(): boolean;
    setPristine(): void;
    appendFields: (data: Record<string, FormFieldValue>) => void;
    removeFields: (fieldList: string[]) => void;
};

export type FormManagerProps<Data extends Record<string, FormFieldValue>> = {
    state: FormState<Data>;
    onChange: FormChangeHandler<FormState<Data>>;
    children: (fields: FormFieldsProps<Data>, formHandlers: FormHandlers) => ReactElement;
};

export type FormChangeHandler<State> = (reducer: (oldState: State) => State) => void;

export type FormFieldComponentProps<Value extends FormFieldValue> = {
    field: FormFieldProps<Value>;
    /** Return an array of string in case validation fails */
    validate?: (value: Value) => string[] | undefined;
};

export type FormFieldComponent<Value extends FormFieldValue> = (props: FormFieldComponentProps<Value>) => ReactElement;

// MARK: - Main Component

/**
 * Manages a form with several fields, with an optional error state associated with each field.
 * It's up to you to provide all the necessary layout for the form.
 *
 * Each field object and in `fieldsProps` and event handlers have stable references.
 *
 * @example
 * const initialState = createInitialFormState({
 *     name: '',
 *     lastName: '',
 *     bio: ''
 * });
 *
 * const [formState, setFormState] = useState(initialState);
 *
 * <FormManager state={formState} onChange={setFormState}>
 *     {(fieldsProps, formHandlers) => (
 *         <FancyForm>
 *             <FancyField>
 *                 <FancyLabel htmlFor="name">Name</FancyLabel>
 *                 <FancyInput name="name" type="text" {...fieldsProps.name.domProps} />
 *                 <FancyInputError error={formState.name.error}/>
 *             </FancyField>
 *
 *             <FancyField>
 *                 <FancyLabel htmlFor="lastName">Last Name</FancyLabel>
 *                 <FancyInput name="lastName" type="text" {...fieldsProps.lastName.domProps} />
 *                 <FancyInputError error={formState.lastName.error}/>
 *             </FancyField>
 *
 *             <FancyField>
 *                 <FancyLabel htmlFor="lastName">Bio</FancyLabel>
 *                 <FancyTextArea name="bio" {...fieldsProps.bio.domProps} />
 *                 <FancyInputError error={formState.bio.error}/>
 *             </FancyField>
 *
 *              <FancyField>
 *                 <FancyLabel htmlFor="emoji">Emoji</FancyLabel>
 *                 <FancyNonDomInput
 *                   name="emoji"
 *                   value={fieldsProps.emoji.value}
 *                   onChange={fieldsProps.emoji.onValueChange} />
 *                 <FancyInputError error={formState.emoji.error}/>
 *             </FancyField>
 *
 *             <button disabled={!formHandlers.isValid()}>Submit</button>
 *         <FancyForm>
 *     )}
 * </FormManager>
 */
export function FormManager<Data extends Record<string, FormFieldValue>>({
    state,
    onChange,
    children,
}: FormManagerProps<Data>) {
    const [fieldsKeys, setFieldsKeys] = useState(Object.keys(state));

    const stateRef = useRef(state);
    stateRef.current = state;
    const initialState = useRef<FormState<Data>>(state);

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    const fieldHandlers = useMemo(() => {
        const _fieldHandlers = {} as FormFieldsProps<Data>;

        fieldsKeys.forEach((key) => {
            const typedKey = key as keyof Data;

            const domProps: FormFieldDomProps<Data[keyof Data]> = {
                // We use stateRef.current to avoid adding state to the useMemo dependency array,
                // and thus - recreating event handlers on every state change.
                // This is just a dynamic reference to a value already stored in the state.
                get value() {
                    return stateRef.current[typedKey].value;
                },
                onChange: (e) => {
                    const { value: newValue } = e.target as HTMLInputElement;

                    onChangeRef.current((oldState) => {
                        if (newValue === oldState[typedKey].value) {
                            return oldState;
                        }

                        const newFieldState = {
                            ...oldState[typedKey],
                            isDirty: newValue !== initialState.current[typedKey].value,
                            value: newValue,
                        };

                        return { ...oldState, [key]: newFieldState };
                    });
                },
                onFocus: () => {
                    onChangeRef.current((oldState) => {
                        if (!oldState[typedKey].errors) {
                            return oldState;
                        }

                        const newFieldState = {
                            ...oldState[typedKey],
                            errors: undefined,
                        };

                        return { ...oldState, [key]: newFieldState };
                    });
                },
            };

            _fieldHandlers[typedKey] = {
                domProps,
                onValueChange: (newValue) => {
                    onChangeRef.current((oldState) => {
                        if (newValue === oldState[typedKey].value) {
                            return oldState;
                        }

                        const newFieldState = {
                            ...oldState[typedKey],
                            isDirty: newValue !== initialState.current[typedKey].value,
                            value: newValue,
                        };

                        return { ...oldState, [key]: newFieldState };
                    });
                },
                onErrorsChange: (errors) => {
                    onChangeRef.current((oldState) => {
                        if (JSON.stringify(errors) === JSON.stringify(oldState[typedKey].errors)) {
                            return oldState;
                        }

                        const newFieldState = {
                            ...oldState[typedKey],
                            errors,
                        };

                        return { ...oldState, [key]: newFieldState };
                    });
                },
                setPristine: () => {
                    onChangeRef.current((oldState) => {
                        const newFieldState = {
                            ...oldState[typedKey],
                            isDirty: false,
                        };
                        return { ...oldState, [key]: newFieldState };
                    });
                },
                onRawChange: (value) => _fieldHandlers[typedKey].onValueChange(value),
                ...domProps, // Spread DOM props for backwards compatibility
                get value() {
                    return domProps.value;
                },
                get errors() {
                    return stateRef.current[typedKey].errors;
                },
                isDirty() {
                    const result = stateRef.current[typedKey].value !== initialState.current[typedKey].value;
                    if (result !== stateRef.current[typedKey].isDirty) {
                        console.error(
                            `FormManager: ${String(typedKey)}: Рассинхронизация начального значения с реальным isDirty`,
                        );
                    }
                    return result;
                },
            };
        });

        return _fieldHandlers;
    }, [fieldsKeys]);

    const formHandlers = useMemo<FormHandlers>(
        () => ({
            formData: () => getFormData(stateRef.current),
            isValid: () => isFormValid(stateRef.current),
            isDirty: () => fieldsKeys.some((key) => fieldHandlers[key].isDirty()),
            setPristine: () => {
                initialState.current = stateRef.current;
                fieldsKeys.forEach((key) => fieldHandlers[key].setPristine());
            },
            appendFields: (data: Record<string, FormFieldValue>) => {
                const newKeys = Object.keys(data);
                setFieldsKeys([...new Set([...fieldsKeys, ...newKeys])]);
                const initStateOfNewFields = createInitialFormState(data);
                initialState.current = {
                    ...initialState.current,
                    ...initStateOfNewFields,
                };
                onChange((oldState) => ({
                    ...oldState,
                    ...initStateOfNewFields,
                }));
            },
            removeFields: (fieldList: string[]) => {
                setFieldsKeys(fieldsKeys.filter((fieldKey) => !fieldList.includes(fieldKey)));
                fieldList.forEach((fieldName) => {
                    delete initialState.current[fieldName];
                });
                onChange((oldState) =>
                    Object.keys(oldState).reduce((acc, key) => {
                        if (fieldList.includes(key)) return acc;
                        return { ...acc, [key]: oldState[key] };
                    }, {} as typeof oldState),
                );
            },
        }),
        [fieldHandlers],
    );

    return children(fieldHandlers, formHandlers);
}

// MARK: - Initial State

/**
 * Creates a form state from the initial key-value object
 *
 * @example
 * const initialState = createInitialFormState({
 *     name: '',
 *     lastName: '',
 *     bio: ''
 * });
 */
export function createInitialFormState<Data extends Record<string, FormFieldValue>>(initialData: Data) {
    const state = {} as FormState<Data>;

    Object.entries(initialData).forEach(([key, value]) => {
        Reflect.set(state, key, { value, errors: undefined, isDirty: false });
    });

    return state;
}

/**
 * Recursively extracts a key-value data object from a form state
 *
 * @example
 * const formState = {
 *   name: { value: 'Alex', errors: 'Too short' },
 *   items: {
 *     errors: undefined,
 *     value: [{
 *        title: { value: 'Some', errors: undefined }
 *     }]
 *   }
 * };
 * const formData = getFormData(formState); // { name: 'Alex', items: { title: 'Some' } }
 */
export function getFormData<Data extends Record<string, FormFieldValue>>(formState: FormState<Data>): Data {
    const formData = { ...formState };

    Object.keys(formData).forEach((key) => {
        let { value } = formData[key as keyof Data];
        const { isDirty } = formData[key as keyof Data];

        if (isForm(value)) {
            // dmh: проигранная битва с дженериками
            // @ts-ignore
            value = getFormData(value);
        } else if (Array.isArray(value)) {
            // @ts-ignore
            value = value
                .map((el) => {
                    if (isForm(el)) {
                        // @ts-ignore
                        return getFormData(el);
                    }

                    return el;
                })
                .filter(Boolean);
        } else if (value === undefined) {
            // @ts-ignore
            value = formData[key];
        }

        const json = JSON.stringify(value);

        if ((value === '' || json === '{}' || json === '[]') && !isDirty) {
            Reflect.deleteProperty(formData, key);
        } else {
            Reflect.set(formData, key, value);
        }
    });

    return formData as unknown as Data;
}

/**
 * Returns form validation from a form state by all fields
 *
 * @example
 * const formState = { name: { value: 'Alex', errors: ['Too short'] } };
 * const formData = isFormValid(formState); // false
 */
export function isFormValid<Data extends Record<string, FormFieldValue>>(formState: FormState<Data>): boolean {
    return Object.keys(formState).every((key) => !formState[key].errors);
}

export function isFormInvalid<Data extends Record<string, FormFieldValue>>(formState: FormState<Data>): boolean {
    return !isFormValid(formState);
}

/**
 * Returns a function that applies a validation error to the whole form state.
 *
 * @example
 * const [formState, setFormState] = useState(() => createInitialFormState({ name: '' }));
 *
 * setFormState(applyValidationError(new ValidationError([...])));
 */
export function applyValidationError<Data extends Record<string, FormFieldValue>>(
    error: ValidationError,
    cb?: (wasApplied: boolean) => void,
) {
    return (oldFormState: FormState<Data>) => {
        const concatenatedErrors = error.errors.reduce<Record<string, string[]>>((result, { field, message }) => {
            if (result[field] === undefined) {
                result[field] = [];
            }

            result[field].push(message);
            return result;
        }, {});

        let wasApplied = false;

        const newFormState = { ...oldFormState };
        Object.entries(concatenatedErrors).forEach(([dataPath, messages]) => {
            const statePath = getStatePath(dataPath);
            const fieldState = get(oldFormState, statePath);

            if (messages?.length && fieldState) {
                wasApplied = true;
                set(newFormState, statePath, {
                    ...fieldState,
                    errors: messages,
                });
            }
        });

        cb?.(wasApplied);

        return newFormState;
    };
}

/**
 * Returns a function that adds a validation error to the form state.
 *
 * @example
 * const [formState, setFormState] = useState(() => createInitialFormState({ name: '' }));
 *
 * setFormState(addValidationError(new ValidationError([...])));
 */
export function addValidationError<Data extends Record<string, FormFieldValue>>(error: ValidationError) {
    return (oldFormState: FormState<Data>) => {
        const concatenatedErrors = error.errors.reduce<Record<string, string[]>>((result, { field, message }) => {
            if (result[field] === undefined) {
                result[field] = [];
            }

            result[field].push(message);
            return result;
        }, {});

        const newFormState = { ...oldFormState };
        Object.entries(concatenatedErrors).forEach(([dataPath, messages]) => {
            const statePath = getStatePath(dataPath);
            const fieldState = get(newFormState, statePath);

            if (messages?.length && fieldState) {
                const oldMessages = fieldState.errors ? [...fieldState.errors] : [];

                set(newFormState, statePath, {
                    ...fieldState,
                    errors: [...oldMessages, messages],
                });
            }
        });

        return newFormState;
    };
}

/**
 * Returns a function that removes errors from the form state
 * @example
 * const [formState, setFormState] = useState(() => createInitialFormState({ name: '' }));
 *
 * setFormState(removeErrors()); // remove all errors
 * setFormState(removeErrors("name")); // only for name field
 */
export function removeErrors<Data extends Record<string, FormFieldValue>>(...fields: string[]) {
    return (oldFormState: FormState<Data>) => {
        let stateChanged = false;

        const newFormState = { ...oldFormState };
        const keys = fields.length ? fields : Object.keys(oldFormState);
        keys.forEach((field) => {
            if (newFormState[field].errors) {
                stateChanged = true;
                delete newFormState[field].errors;
            }
        });

        return stateChanged ? newFormState : oldFormState;
    };
}

/** A type-predicate for `ValidationError` */
export function isValidationError(error: unknown): error is ValidationError {
    return error instanceof ValidationError;
}

/** A type-predicate for form field */
export function isForm<Value extends FormFieldValue>(form: unknown): form is FormFieldState<Value> {
    if (!form || typeof form !== 'object') {
        return false;
    }

    return Object.values(form).every((field) => field && typeof field === 'object' && 'value' in field);
}

/** Returns a path for a field in a form state for a given path in the data object */
function getStatePath(dataPath: string) {
    return dataPath
        .replace(/\[(\d+)\]/g, '.$1')
        .split('.')
        .map((token) => `${token}.value`)
        .join('.')
        .replace(/\.(\d+)\.value/g, '[$1]')
        .replace(/\.value$/, '');
}

export type Validator = (
    value: FormFieldValue,
    fieldName: string,
    validate: (value: FormFieldValue) => ValidationError | null,
) => void;
export function useHandleValidation<State extends FormState<Record<string, FormFieldValue>>>(
    formState: State,
    setFormState: (state: State) => void,
) {
    const handleValidation = useMemo(
        () => ({
            applyError: (validationError: ValidationError) => {
                const stateWithErrors = applyValidationError(validationError)(formState);
                setFormState(stateWithErrors as State);
            },
            removeError: (fieldName: string) => {
                const stateWithoutErrors = removeErrors(fieldName)(formState);
                setFormState(stateWithoutErrors as State);
            },
        }),
        [formState, setFormState],
    );
    const validator = useCallback<Validator>(
        (value, fieldName, validate) => {
            const validationError = validate(value);
            if (validationError) {
                handleValidation.applyError(validationError);
            } else {
                handleValidation.removeError(fieldName);
            }
        },
        [handleValidation],
    );
    return {
        handleValidation,
        validator,
    };
}
