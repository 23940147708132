/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserExporter } from '../models/shared/UserExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VoteService {
    /**
     * Принять оценку пользователя
     * @returns any Статистика голосов пользователя
     * @throws ApiError
     */
    public static updateRating({
        requestBody,
    }: {
        requestBody: {
            type: number;
            action: 'like' | 'dislike' | 'unlike';
            objectId: number;
        };
    }): CancelablePromise<
        | {
              score: number;
              plus: number;
              minus: number;
          }
        | {
              message: string;
          }
    > {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/vote/rating/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить список пользователей оценивших объект
     * @returns any Пользователи, поставиившие оценки
     * @throws ApiError
     */
    public static getVoteRating({
        objectType,
        objectId,
        type = 'all',
        offsetPosition,
    }: {
        /**
         * Тип объекта
         */
        objectType: number;
        /**
         * Идентификатор объекта
         */
        objectId: number;
        /**
         * Тип оценок
         */
        type?: 'all' | 'positive' | 'negative';
        /**
         * Смещение выборки
         */
        offsetPosition?: number;
    }): CancelablePromise<{
        votes?: {
            positive?: Array<UserExporter>;
            negative?: Array<UserExporter>;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/vote/rating/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            query: {
                type: type,
                offset_position: offsetPosition,
            },
        });
    }
}
