export const OPEN_EDIT_POPUP = 'widgets/OPEN_EDIT_POPUP' as const;
export const openEditPopup = (id: number) => ({ type: OPEN_EDIT_POPUP, payload: id });
export type OpenEditPopup = typeof openEditPopup;
export type OpenEditPopupActions = ReturnType<typeof openEditPopup>;

export const CLOSE_EDIT_POPUP = 'widgets/CLOSE_EDIT_POPUP' as const;
export const closeEditPopup = () => ({ type: CLOSE_EDIT_POPUP });
export type CloseEditPopup = typeof closeEditPopup;
export type CloseEditPopupActions = ReturnType<typeof closeEditPopup>;

export const OPEN_DELETE_POPUP = 'widgets/OPEN_DELETE_POPUP' as const;
export const openDeletePopup = (id: number) => ({ type: OPEN_DELETE_POPUP, payload: id });
export type OpenDeletePopup = typeof openDeletePopup;
export type OpenDeletePopupActions = ReturnType<typeof openDeletePopup>;

export const CLOSE_DELETE_POPUP = 'widgets/CLOSE_DELETE_POPUP' as const;
export const closeDeletePopup = () => ({ type: CLOSE_DELETE_POPUP });
export type CloseDeletePopup = typeof closeDeletePopup;
export type CloseDeletePopupActions = ReturnType<typeof closeDeletePopup>;

export const OPEN_TOKEN_POPUP = 'widgets/OPEN_TOKEN_POPUP' as const;
export const openTokenPopup = (id: number) => ({ type: OPEN_TOKEN_POPUP, payload: id });
export type OpenTokenPopup = typeof openTokenPopup;
export type OpenTokenPopupActions = ReturnType<typeof openTokenPopup>;

export const CLOSE_TOKEN_POPUP = 'widgets/CLOSE_TOKEN_POPUP' as const;
export const closeTokenPopup = () => ({ type: CLOSE_TOKEN_POPUP });
export type CloseTokenPopup = typeof closeTokenPopup;
export type CloseTokenPopupActions = ReturnType<typeof closeTokenPopup>;

export type PopupActions =
    | OpenEditPopupActions
    | CloseEditPopupActions
    | OpenDeletePopupActions
    | CloseDeletePopupActions
    | OpenTokenPopupActions
    | CloseTokenPopupActions;
