import { OptionsPositionParamsType } from './types';

export const MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN = 24;

export const DEFAULT_OPTIONS_POSITION_PARAMS: OptionsPositionParamsType = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 'auto',
    direction: 'down',
};
