import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { Button } from 'components/button2';

export const AddFeedStatusActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const AddFeedCancelButton = styled(Button)`
    margin-right: 12px;

    ${mediaQuery.lt480} {
        margin-right: 8px;
    }
`;
