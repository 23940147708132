import { useState, createElement } from 'react';

import Gallery, { GalleryInitFunction } from 'components/gallery/Gallery';

export const useGallery = () => {
    const [numSlide, setNumSlide] = useState(0);
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [imgList, setImgList] = useState([
        {
            index: 0,
            src: '',
        },
    ]);

    const handlerOpenGallery: GalleryInitFunction = (index, imageList) => {
        setImgList(imageList);
        setNumSlide(index);
        setIsShowGallery(true);
    };

    const galleryElement = isShowGallery
        ? createElement(Gallery, {
              imgList,
              numSlide,
              onClose: () => setIsShowGallery(false),
          })
        : null;

    return { handlerOpenGallery, galleryElement };
};
