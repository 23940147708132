import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { el } from 'date-fns/locale';

import { ChevronRightIcon } from 'components/icons2/ChevronRightIcon';

import { MenuItemData } from '../../interfaces';

import * as Styled from './styled';

type MenuItemDisplayData = Omit<MenuItemData, 'text'> & { text: string };

interface MenuItemProps {
    item: MenuItemDisplayData;
    onClick: (item: MenuItemDisplayData) => void;
    mobile: boolean;
    menuName: string;
    isMobile: boolean;
}

export const MenuItem: FC<MenuItemProps> = memo(({ item, onClick, mobile, menuName, isMobile }) => {
    const { icon, text, href, subMenu, separator = false } = item;
    const theme = useTheme();

    const handleClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    const iconElMemoized = useMemo(() => {
        if (React.isValidElement(icon)) {
            return icon;
        }

        if (icon) {
            const Icon = icon;

            return <Icon size={24} />;
        }

        return null;
    }, [icon]);

    const className = `autotest__navigation__context_menu_${isMobile ? 'mobile_' : ''}${menuName}_${item.id}`;

    return (
        <>
            <Styled.ContextMenuItemStyled
                as={href ? 'a' : 'button'}
                href={href}
                onClick={handleClick}
                $mobile={mobile}
                className={className}
            >
                {iconElMemoized !== null && <div className="icon">{iconElMemoized}</div>}
                <div className="text">
                    <Styled.MenuItemText>{text}</Styled.MenuItemText>
                </div>
                <div className="chevron">{!!subMenu && <ChevronRightIcon size={20} fill={theme.label.tertiary} />}</div>
            </Styled.ContextMenuItemStyled>
            {separator && <Styled.Separator />}
        </>
    );
});
