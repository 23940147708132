import { ReactElementLike } from 'prop-types';
import React from 'react';

import { ResponsiveMap } from 'app/styled';
import { IconedTextProps } from 'components/iconed-text2/types';

export type ButtonIconIconProp = IconedTextProps['icon'] & { position?: IconedTextProps['iconPos'] };

export type SizeValueButtonIcon = 'L' | 'M' | 'S';

export enum ButtonIconKinds {
    FillPrimary = 1,
    FillSecondary,
    GhostPrimary,
    GhostSecondary,
}

export type SizeButtonIcon = SizeValueButtonIcon | ResponsiveMap<SizeValueButtonIcon>;

export type ButtonIconProps = {
    className?: string;
    text?: string | number | ReactElementLike;
    icon?: ButtonIconIconProp;
    size?: SizeButtonIcon;
    kind?: ButtonIconKinds;
    pressed?: boolean;
    disabled?: boolean;
    title?: string;
    onClick?: (e: React.SyntheticEvent) => void;
    onMouseDown?: (e: React.SyntheticEvent) => void;
    href?: string;
    isProcessing?: boolean;
};
