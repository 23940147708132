import type { InitialPageSettings } from 'services/init-page';

import { createMutableContext } from './create-mutable-context';

export type PageContextValueType = InitialPageSettings<unknown, Record<string, never>>['pageContext'];

export const pageContextDefaultValue: PageContextValueType = {
    countryCode: 'US',
    countryName: 'USA',
    channel: {
        user: '',
    },
    tokens: {
        csrf: '',
        token: '',
    },
    ads: {
        ip: '',
        ua: '',
    },
    googleAnalytics: {
        trackingId: '',
        gtagId: '',
        googleAnalyticsId: '',
    },
    charts: {
        server: {
            security_quote_hosts: [],
            security_quote_send_not_work_after: [],
            security_quote_token: '',
            security_quote_ignore_screener_quotes: false,
            security_quote_screener_hosts: [],
            login: '',
            password: '',
            clientType: 0,
            txProtocolUrl: '',
        },
        serverSlow: {},
    },
    domainLcid: 'en',
};

const { Provider, hook, Context } = createMutableContext<PageContextValueType>(pageContextDefaultValue);

export { Provider as PageContextProvider, hook as usePageContext, Context as PageContext };
