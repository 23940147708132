import { createAction } from '@reduxjs/toolkit';

import { Action, Id } from 'types';
import { CommentService, SearchService } from 'services/api-services/generated_limex';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';

import { GlobalStateForFeed } from '../types';

import { CommentsDirection, CommentsFilter, EObjectTypes, ParentObject, RootObject } from './types';

const DEFAULT_PAGE = 1;
const DEFAULT_COMMENTS_PAGE_SIZE = 20;
const DEFAULT_REPLIES_PAGE_SIZE = 10;

export const commentsFilters: Record<string, CommentsFilter> = {
    default: 'showEarliestComments',
    recent: 'showLatestComments',
    earliest: 'showEarliestComments',
    rated: 'showMostLikedComments',
};

export type CommentsFilters = (typeof commentsFilters)[keyof typeof commentsFilters];

export const commentsDirections: Record<string, CommentsDirection> = {
    before: 'before',
    after: 'after',
};

export type CommentsDirections = (typeof commentsDirections)[keyof typeof commentsDirections];

export const PUBLISHED_COMMENT_MARK_OR_UNMARK = 'widgets/PUBLISHED_COMMENT_MARK_OR_UNMARK' as const;
const markOrUnmarkComment = createAction<
    { recordId?: Id; objectId?: Id; commentId?: Id },
    typeof PUBLISHED_COMMENT_MARK_OR_UNMARK
>(PUBLISHED_COMMENT_MARK_OR_UNMARK);
export type MarkOrUnmarkCommentActions = ReturnType<typeof markOrUnmarkComment>;

export const CHANGE_COMMENTS_FILTER = 'widgets/CHANGE_COMMENTS_FILTER' as const;
export function changeCommentsFilter(recordId: Id) {
    return {
        type: CHANGE_COMMENTS_FILTER,
        payload: {
            data: { recordId },
        },
    };
}
export type ChangeCommentsFilter = Action<typeof changeCommentsFilter>;
export type ChangeCommentsFilterActions = ReturnType<typeof changeCommentsFilter>;

export type FetchCommentsPayloadPass = {
    recordId: Id;
    pinnedCommentId?: Id;
    direction?: CommentsDirections;
};

const [FETCH_COMMENTS_ACTION_TYPES, fetchCommentsThunk, fetchCommentsActions] = createThunkActions<
    'widgets',
    GlobalStateForFeed
>('widgets')('FEED_RECORD_FETCH_COMMENTS', CommentService.getCommentsWithAnswers)<FetchCommentsPayloadPass>();
function fetchComments(
    options: {
        objectType: EObjectTypes;
        objectTypeString: string;
        objectId: Id;
        page?: number;
        pageSize?: number;
        filter?: CommentsFilters;
        reverse?: boolean;
        pinnedCommentId?: Id;
        commentId?: Id;
        prevCommentId?: Id;
        filterPersonId?: Id;
        startDate?: string | number;
        direction?: CommentsDirection;
    },
    pass: FetchCommentsPayloadPass,
) {
    const { objectType, objectId, page, pageSize, filter, direction, pinnedCommentId, commentId } = options;

    return fetchCommentsThunk({
        params: {
            objectType,
            objectId: +objectId,
            page,
            pageSize:
                pageSize || objectType === EObjectTypes.RECORD ? DEFAULT_COMMENTS_PAGE_SIZE : DEFAULT_REPLIES_PAGE_SIZE,
            filter,
            pinnedCommentId: pinnedCommentId ? +pinnedCommentId : undefined,
            commentId: commentId ? +commentId : undefined,
            direction,
        },
        pass,
    });
}
type FetchComments = Action<typeof fetchComments>;
type FetchCommentsActions = Actions<typeof fetchCommentsActions>;

export { FETCH_COMMENTS_ACTION_TYPES, fetchComments, FetchComments, FetchCommentsActions };

export type CreateEditCommentPayloadPass = FetchCommentsPayloadPass & { commentsFilter?: CommentsFilters };
export type CreateEditCommentRequestData = {
    comment: {
        text: string;
        image: string;
        replyUserId: Id | null;
        replyUserName: string | null;
        id?: number;
        rootObjectId?: RootObject['rootObjectId'];
        rootObjectType?: RootObject['rootObjectType'];
        parentObjectId?: ParentObject['parentObjectId'];
        parentObjectType?: ParentObject['parentObjectType'];
    };
};

const [CREATE_COMMENT_ACTION_TYPES, createThunk, createActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('FEED_RECORD_CREATE_COMMENT', CommentService.createComment)<
    CreateEditCommentPayloadPass,
    MarkOrUnmarkCommentActions
>();

function createComment(
    comment: CreateEditCommentRequestData['comment'],
    csrf: string,
    pass: CreateEditCommentPayloadPass,
): ReturnType<typeof createThunk> {
    return async (dispatch, getState, extraArgument) => {
        const isCommentReply = comment?.parentObjectType === 'comment';

        const res = await createThunk({
            params: {
                objectType: isCommentReply ? EObjectTypes.COMMENT : EObjectTypes.RECORD,
                objectId: isCommentReply ? +(comment.parentObjectId ?? 0) : +(comment.rootObjectId ?? 0),
                requestBody: {
                    comment,
                    csrf,
                },
            },
            pass,
        })(dispatch, getState, extraArgument);

        if (res?.comment) {
            setTimeout(
                () =>
                    dispatch(
                        markOrUnmarkComment({
                            recordId: +pass.recordId,
                        }),
                    ),
                3000,
            );
        }

        return res;
    };
}
type CreateComment = Action<typeof createComment>;
type CreateCommentActions = Actions<typeof createActions>;

export { CREATE_COMMENT_ACTION_TYPES, createComment, CreateComment, CreateCommentActions };

const [EDIT_COMMENT_ACTION_TYPES, editThunk, editActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('FEED_RECORD_EDIT_COMMENT', CommentService.editComment)<CreateEditCommentPayloadPass, MarkOrUnmarkCommentActions>();

function editComment(
    comment: CreateEditCommentRequestData['comment'],
    csrf: string,
    pass: CreateEditCommentPayloadPass,
): ReturnType<typeof createThunk> {
    return async (dispatch, getState, extraArgument) => {
        const res = await editThunk({
            params: {
                commentId: comment.id ? +comment.id : 0,
                requestBody: {
                    comment,
                    csrf,
                },
            },
            pass,
        })(dispatch, getState, extraArgument);

        if (res?.comment) {
            setTimeout(
                () =>
                    dispatch(
                        markOrUnmarkComment({
                            recordId: +pass.recordId,
                        }),
                    ),
                3000,
            );
        }

        return res;
    };
}
type EditComment = Action<typeof editComment>;
type EditCommentActions = Actions<typeof editActions>;

export { EDIT_COMMENT_ACTION_TYPES, editComment, EditComment, EditCommentActions };

export type DeleteCommentPayloadPass = { commentId: Id; recordId: Id };

const [DELETE_COMMENT_ACTION_TYPES, deleteThunk, deleteActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('FEED_RECORD_DELETE_COMMENT', CommentService.deleteComment)<DeleteCommentPayloadPass>();

function deleteComment(id: Id, csrf: string, pass: DeleteCommentPayloadPass) {
    return id
        ? deleteThunk({
              params: {
                  commentId: +id,
                  requestBody: {
                      csrf,
                  },
              },
              pass,
          })
        : null;
}
type DeleteComment = Action<typeof deleteComment>;
type DeleteCommentActions = Actions<typeof deleteActions>;
export { DELETE_COMMENT_ACTION_TYPES, deleteComment, DeleteComment, DeleteCommentActions };

export type RestoreCommentPayloadPass = { commentId: Id; recordId: Id };

const [RESTORE_COMMENT_ACTION_TYPES, restoreThunk, restoreActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('FEED_RECORD_RESTORE_COMMENT', CommentService.restoreComment)<RestoreCommentPayloadPass>();
function restoreComment(id: Id, pass: RestoreCommentPayloadPass) {
    return id
        ? restoreThunk({
              params: {
                  commentId: +id,
              },
              pass,
          })
        : null;
}
type RestoreComment = Action<typeof restoreComment>;
type RestoreCommentActions = Actions<typeof restoreActions>;
export { RESTORE_COMMENT_ACTION_TYPES, restoreComment, RestoreComment, RestoreCommentActions };

const [GET_MENTION_LIST_ACTION_TYPES, mentionThunk, mentionActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('GET_MENTION_LIST', SearchService.getMentionList)<{ recordId: Id }>();

const getMentionList = (postId: number, recordId: Id, query?: string) =>
    mentionThunk({
        params: { postId, query },
        pass: { recordId },
    });
type GetMentionList = Action<typeof getMentionList>;
type GetMentionListActions = Actions<typeof mentionActions>;
export { GET_MENTION_LIST_ACTION_TYPES, getMentionList, GetMentionList, GetMentionListActions };

export type CommentsActions =
    | FetchCommentsActions
    | CreateCommentActions
    | EditCommentActions
    | DeleteCommentActions
    | RestoreCommentActions
    | GetMentionListActions
    | ChangeCommentsFilterActions
    | MarkOrUnmarkCommentActions;
