import produce from 'immer';
import stringify from 'fast-json-stable-stringify';

import { RequestTypes } from 'services/create-action-types/shared/types';

import { FailureAction, RequestAction, RequestActions, SuccessAction } from '../actions/types';
import { AsyncMethod, Cache } from '../types';
import { DeleteRequestAction } from '../actions/deleteRequest';
import { nameToAction } from '../utils/nameToAction';

export function createRequestsReducer<NAME extends string, API_METHOD extends AsyncMethod>(
    endpointName: NAME,
    ACTION_TYPES: RequestTypes<string, 'cache'>,
) {
    const [REQUEST, SUCCESS, FAILURE] = ACTION_TYPES;
    return (
        state: Cache['requests'] = {},
        action: RequestActions<API_METHOD> | DeleteRequestAction,
    ): Cache['requests'] => {
        switch (action.type) {
            case REQUEST: {
                const {
                    payload: {
                        params,
                        pass: { operationId, force },
                    },
                } = action as RequestAction<API_METHOD>;
                const key = stringify(params);
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = {};
                    }
                    if (!draft[endpointName][key]) {
                        draft[endpointName][key] = {
                            status: 'empty',
                        };
                    }
                    draft[endpointName][key].status = force ? 'reloading' : 'loading';
                    draft[endpointName][key].error = undefined;
                    draft[endpointName][key].operationId = operationId;
                });
            }
            case SUCCESS: {
                const {
                    payload: { data },
                    meta: {
                        requestAction: {
                            payload: { params },
                        },
                    },
                } = action as SuccessAction<API_METHOD>;
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = {};
                    }
                    const key = stringify(params);
                    if (!draft[endpointName][key]) {
                        draft[endpointName][key] = {
                            status: 'empty',
                        };
                    }
                    if (data) {
                        draft[endpointName][key].status = 'ready';
                        draft[endpointName][key].error = undefined;
                    }
                });
            }
            case FAILURE: {
                const {
                    payload: { error },
                    meta: {
                        requestAction: {
                            payload: { params },
                        },
                    },
                } = action as FailureAction<API_METHOD>;
                const key = stringify(params);
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = {};
                    }
                    if (!draft[endpointName][key]) {
                        draft[endpointName][key] = {
                            status: 'empty',
                        };
                    }
                    draft[endpointName][key].status = 'error';
                    draft[endpointName][key].error = { code: `${error.status}`, message: error.message };
                });
            }
            case `cache/DELETE_REQUEST_${nameToAction(endpointName)}`: {
                const {
                    payload: { id },
                } = action as DeleteRequestAction;
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = {};
                    }
                    if (Array.isArray(id)) {
                        id.forEach((element) => {
                            delete draft[endpointName][element];
                        });
                    } else {
                        delete draft[endpointName][id];
                    }
                });
            }
            default:
                return state;
        }
    };
}
