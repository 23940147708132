import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';
import { size as polishedSize } from 'polished';

import { boxShadowLikeBorder, buttonResetStyle, reduceBreakpoints } from 'app/styled';
import { Theme } from 'services/theme';
import IconedText from 'components/iconed-text2';

import { ButtonIconKinds, ButtonIconProps, SizeButtonIcon } from './types';

const buttonColorMap = (
    theme: Theme,
    kind: ButtonIconKinds,
): {
    [key in '_default' | 'hover' | 'pressed' | 'focus' | 'activeFocus' | 'disabled']: Record<string, string>;
} => {
    const { fillIn, label } = theme;
    return {
        [ButtonIconKinds.FillPrimary]: {
            _default: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay16,
                color: label.secondaryHigh,
            },
            pressed: {
                bg: fillIn.primary.overlay32,
                color: label.accent,
            },
            focus: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: fillIn.primary.overlay32,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: fillIn.secondary.overlay16,
                color: label.inactive,
            },
        },
        [ButtonIconKinds.FillSecondary]: {
            _default: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay16,
                color: label.secondaryHigh,
            },
            pressed: {
                bg: fillIn.secondary.muted,
                color: label.primary,
            },
            focus: {
                bg: fillIn.secondary.overlay16,
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: fillIn.secondary.muted,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: fillIn.secondary.overlay16,
                color: label.inactive,
            },
        },
        [ButtonIconKinds.GhostPrimary]: {
            _default: {
                bg: 'none',
                color: label.secondary,
            },
            hover: {
                bg: 'none',
                color: label.secondaryHigh,
            },
            pressed: {
                bg: 'none',
                color: label.accent,
            },
            focus: {
                bg: 'none',
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                bg: 'none',
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: 'none',
                color: label.inactive,
            },
        },
        [ButtonIconKinds.GhostSecondary]: {
            _default: {
                bg: 'none',
                color: label.secondary,
            },
            hover: {
                bg: fillIn.secondary.overlay24,
                color: label.secondaryHigh,
            },
            pressed: {
                // active
                bg: fillIn.secondary.muted,
                color: label.primary,
            },
            focus: {
                // focused
                bg: 'none',
                color: label.secondary,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            activeFocus: {
                // active focused
                bg: fillIn.secondary.muted,
                boxShadow: boxShadowLikeBorder('3px', fillIn.primary.overlay24),
            },
            disabled: {
                bg: 'none',
                color: label.inactive,
            },
        },
    }[kind];
};

const buttonIconSizeMap = {
    L: {
        height: 40,
        indent: 8,
        iconSize: 24,
        textPadding: 12,
        fontSize: 17,
        lineHeight: 24,
    },
    M: {
        height: 32,
        indent: 4,
        iconSize: 24,
        textPadding: 8,
        fontSize: 14,
        lineHeight: 20,
    },
    S: {
        height: 24,
        indent: 4,
        iconSize: 16,
        textPadding: 8,
        fontSize: 12,
        lineHeight: 16,
    },
};
const colorFill = (color: string) => `
    color: ${color};
    fill: ${color};
`;

const dependantOfSize = (
    { height, indent, iconSize, textPadding, fontSize, lineHeight }: typeof buttonIconSizeMap.L,
    textIsGiven: boolean,
    icon: ButtonIconProps['icon'],
) => {
    const { position: iconPosition } = icon || {};

    const getPaddingRight = () => {
        if (icon) {
            if (iconPosition === 'left') {
                if (textIsGiven) {
                    return textPadding;
                }
            }

            if (iconPosition === 'right') {
                return 0;
            }

            return 0;
        }

        if (!icon && textIsGiven) {
            return textPadding;
        }

        return 0;
    };

    const getPaddingLeft = () => {
        if (icon && iconPosition === 'right' && textIsGiven) {
            return textPadding;
        }

        if (!icon && textIsGiven) {
            return textPadding;
        }

        return 0;
    };

    return css`
        height: ${height}px;
        min-width: ${height}px;
        > div {
            padding-top: ${indent}px;
            padding-bottom: ${indent}px;
            padding-right: ${getPaddingRight()}px;
            padding-left: ${getPaddingLeft()}px;
            font-size: ${fontSize}px;
            line-height: ${lineHeight}px;
            height: 100%;
        }
        svg {
            ${polishedSize(iconSize)};
            margin-left: ${indent}px;
            margin-right: ${indent}px;
        }
    `;
};

const buildSizes = ({
    text,
    size,
    icon,
}: {
    size: SizeButtonIcon;
    text: ButtonIconProps['text'];
    icon?: ButtonIconProps['icon'];
}) => {
    const textIsGiven = Boolean(text) || text === 0;
    return reduceBreakpoints(size, (_size) => dependantOfSize(buttonIconSizeMap[_size], textIsGiven, icon));
};

export const buttonIconKindStyle = () => css`
    ${({
        kind = ButtonIconKinds.FillPrimary,
        theme,
    }: {
        size: SizeButtonIcon;
        kind: ButtonIconKinds;
        theme: Theme;
    }) => {
        const { _default, hover, pressed, focus, activeFocus, disabled } = buttonColorMap(theme, kind);
        const pressedCss = `
            background: ${pressed.bg};
            ${colorFill(pressed.color)};
            border-radius: 8px;
        `;
        const disabledCss = `
            pointer-events: none;
            cursor: default;
            > div {
                background: ${disabled.bg};
                ${colorFill(disabled.color)};
            }
        `;
        return css`
            > div {
                ${colorFill(_default.color)};
                background: ${_default.bg};
            }
            ${ifNotProp(
                'pressed',
                `
                @media (hover: hover) {
                    &:hover {
                        > div {
                            background: ${hover.bg};
                            ${colorFill(hover.color)};
                        }

                    }
                }
            `,
            )}
            &:focus {
                > div {
                    ${ifNotProp(
                        'pressed',
                        css`
                            background: ${focus.bg};
                        `,
                    )}
                    ${colorFill(focus.color)};
                    box-shadow: ${focus.boxShadow};
                    border-radius: 8px;
                }
            }
            &:active {
                > div {
                    ${pressedCss}
                }
            }
            > div {
                ${ifProp('pressed', pressedCss)}
            }
            &:focus:active {
                > div {
                    background: ${activeFocus.bg};
                    box-shadow: ${activeFocus.boxShadow};
                    border-radius: 8px;
                }
            }
            &:disabled {
                ${disabledCss}
            }
            ${ifProp('isProcessing', disabledCss)}
        `;
    }}
`;

export const ButtonIconWrapper = styled.button<
    Required<Pick<ButtonIconProps, 'text' | 'kind' | 'size' | 'isProcessing' | 'disabled' | 'pressed'>> &
        Partial<Pick<ButtonIconProps, 'icon'>>
>`
    ${buttonResetStyle};
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    ${buttonIconKindStyle};
    ${buildSizes};
    > div {
        display: flex;
        flex-grow: 1;
        outline: none;
        box-sizing: border-box;
    }
`;

export const StyledIconedText = styled(IconedText)<{ isProcessing: boolean }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    visibility: ${({ isProcessing }) => (isProcessing ? 'hidden' : 'visible')};
`;
