import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { VerticalFormInputGroup } from 'components/form';
import { FormTextArea } from 'components/form/react-hook-form';

import { StepProps } from '../../types';
import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { KEY_FEATURE_DESCRIPTION_MAX_LENGTH, useKeyFeatureValidation } from '../../../../hooks/useKeyFeatureValidation';

import { toData } from './toData';
import { defaultValues } from './constants';
import { Step4FormValues } from './types';

export const Step4: FC<StepProps<Step4FormValues>> = memo((props) => {
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step4_');
    const form = useForm<Step4FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    useFormStateHandler({
        form,
        props,
        toData,
        title: dic.word('wt_all__widget_product_wizard__copy_trading_step2_title'),
    });

    const keyFeatureRules = useKeyFeatureValidation();

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                <FormTextArea
                    label={dicWord('trading_method')}
                    name="tradingMethod"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__trading_method"
                />
                <FormTextArea
                    label={dicWord('leverage')}
                    name="cashAndLeverage"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__leverage"
                />
                <FormTextArea
                    label={dicWord('risk')}
                    name="riskManagement"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__risk_management"
                />
                <FormTextArea
                    label={dicWord('additional')}
                    name="additionalInformation"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__additional"
                />
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
