import { LanguageIcon } from 'components/icons2/LanguageIcon';
import { GlobeIcon } from 'components/icons2/GlobeIcon';
import { QuestionMarkCircleIcon } from 'components/icons2/QuestionMarkCircleIcon';
import { ICircleIcon } from 'components/icons2/ICircleIcon';
import { LoginIcon } from 'components/icons2/LoginIcon';
import { EditIcon } from 'components/icons2/EditIcon';

import { MenuContext, MenuData } from '../interfaces';

import { getExtrasMenu } from './extrasMenu';
import { getLanguagesMenu } from './languagesMenu';
import { getCountriesMenu } from './countriesMenu';

export async function getGuestMenu(context: MenuContext): Promise<MenuData> {
    const { dic } = context;
    return {
        name: 'guest',
        headerContent: dic.word('wt_all__widget_menu__main_guest_header'),
        showCloseButton: true,
        onClick: 'close',
        mobileOnlyHeader: true,
        items: [
            {
                id: 'languages',
                icon: LanguageIcon,
                text: '',
                subMenu: () => getLanguagesMenu(context),
            },
            {
                id: 'countries',
                icon: GlobeIcon,
                text: '',
                subMenu: () => getCountriesMenu(context),
            },
            {
                id: 'support',
                icon: QuestionMarkCircleIcon,
                text: dic.word('wt_all__widget_menu__main_support'),
            },
            {
                id: 'extras',
                icon: ICircleIcon,
                text: dic.word('wt_all__widget_menu__main_extras'),
                separator: true,
                subMenu: () => getExtrasMenu(context),
            },
            {
                id: 'login',
                icon: LoginIcon,
                text: dic.word('wt_all__widget_menu__main_login'),
            },
            {
                id: 'register',
                icon: EditIcon,
                text: dic.word('wt_all__widget_menu__main_register'),
                mobile: true,
            },
        ],
    };
}
