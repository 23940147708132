import styled from 'styled-components';

import {
    body14MediumFontStyle,
    body14SemiboldFontStyle,
    caption12RegularFontStyle,
    caption12RegularTNumFontStyle,
} from 'services/typography';
import {
    Title as TitleComplexItem,
    SecondLine as SecondLineComplexItem,
    Avatar as StyledAvatarComplexItem,
} from 'components/complex-item/styled';
import { ComplexItem as ComplexItemComponent } from 'components/complex-item';

import { ProfitabilityOptionType } from '../../types';

export const InnerOptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ComplexItem = styled(ComplexItemComponent)`
    flex-shrink: 1;
    overflow: hidden;
    ${TitleComplexItem} {
        ${body14MediumFontStyle};
        color: ${({ theme }) => theme.label.primary};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline;
    }

    ${SecondLineComplexItem} {
        ${caption12RegularFontStyle};
        color: ${({ theme }) => theme.label.secondary};
        display: inline;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    ${StyledAvatarComplexItem} {
        margin-top: 4px;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2px 0 0 8px;
`;

export const DetailsSum = styled.span`
    ${body14SemiboldFontStyle};
    color: ${({ theme }) => theme.label.primary};
    white-space: nowrap;
`;

export const DetailsSecondLine = styled.span<{ profitability?: ProfitabilityOptionType }>`
    ${caption12RegularTNumFontStyle};
    color: ${({ theme, profitability }) => {
        switch (profitability) {
            case 'positive':
                return theme.label.accent;
            case 'negative':
                return theme.label.negative;
            case 'neutral':
            default:
                return theme.label.secondary;
        }
    }};
    align-self: flex-end;
`;
