/* eslint-disable @typescript-eslint/no-explicit-any */
import { Path } from 'services/api-builder/types';

/** Вызывает callback на элементах структуры данных, соответствующих данному пути. Их может быть несколько,
 * т.к. путь может проходить через массивы. Например, traverseByPath({ a: { b: [ { c: 1 }, { c: 2 }, { c: 3 } ] } }, 'a.b.c', callback)
 * вызовет callback для 1, 2 и 3 */
export function traverseByPath<T>(
    obj: T,
    path: Path<T>,
    callback: (item: any, parent: any, keyOrIndex: string | number) => void,
): void {
    const parts = path.split('.').filter(Boolean);

    function visit(current: any, idx: number, parent: any, keyOrIndex: string | number) {
        if (idx === parts.length) {
            callback(current, parent, keyOrIndex);
            return;
        }

        const key = parts[idx];
        const next = current[key];

        if (next !== undefined) {
            if (Array.isArray(next) && idx < parts.length - 1) {
                next.forEach((item, index) => visit(item, idx + 1, next, index));
            } else {
                visit(next, idx + 1, current, key);
            }
        }
    }

    visit(obj, 0, null, '');
}
