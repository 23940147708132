import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 556px;

    ${mediaQuery.lt768} {
        height: 100%;
    }
`;
