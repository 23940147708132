import styled from 'styled-components';

export const Tickers = styled.div`
    display: flex;
    margin-top: 20px;
    padding-left: 8px;
    & > img {
        width: 32px;
        height: 32px;
        margin-left: -8px;
    }
`;
