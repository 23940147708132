import React from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { GearIcon } from 'components/icons2/GearIcon';

type Props = {
    settingsUrl: string;
};

export const ActionSettings = (props: Props) => {
    const { settingsUrl } = props;

    return (
        <MenuItem
            text={Dic.word('wt_profile__widget_context_actions__settings_item')}
            icon={{ component: GearIcon }}
            onClick={() => window.open(settingsUrl)}
        />
    );
};
