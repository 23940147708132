import isEqual from 'lodash/isEqual';

import { OnCancel } from './types';

export class CancelActionProcessor<T> {
    private items: {
        params: T;
        cancelHandler?: () => void;
    }[] = [];

    public cancelOrBuild(params: T): OnCancel {
        let item = this.items.find((i) => isEqual(i.params, params));
        if (item) {
            item.cancelHandler?.();
        } else {
            item = { params };
            this.items.push(item);
        }
        return (cancelHandler) => {
            if (item) {
                item.cancelHandler = cancelHandler;
            }
        };
    }

    public remove(params: T) {
        const index = this.items.findIndex((i) => isEqual(i.params, params));
        if (index !== -1) {
            this.items.splice(index, 1);
        }
    }
}
