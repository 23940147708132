import { createElement, SyntheticEvent, useCallback } from 'react';

import { notEmpty } from 'services/utils/not-empty';
import { useDictionary } from 'hooks/useDictionary';
import { RequiredCardProps, UseCardPropsParams } from 'hooks/mp-share/types';
import { SharedFinamProps } from 'widgets/products-slider/types';
import { useProductPagesNavigation } from 'widgets/mp-share/product-pages/useProductPagesNavigation';
import { EllipsisVIcon } from 'components/icons2/EllipsisVIcon';
import { RoundButton } from 'components/round-button';

import { useFavoritesClick } from './useFavoritesClick';

export function useCardProps(props: UseCardPropsParams) {
    const {
        draggingRef,
        favorites,
        removeProductFromFavorites,
        addProductToFavorites,
        contextMenu,
        productPagePath,
        isMyProduct,
    } = props;

    const dic = useDictionary();

    const { updateUrlAndRedirectForProduct, goToProductSignals } = useProductPagesNavigation(
        undefined,
        productPagePath,
    );

    const onClickCard = useCallback<NonNullable<RequiredCardProps['onClick']>>(
        (e, item) => {
            const { productType, id, archivedAt } = item;
            if (!draggingRef?.current && productType && id && !archivedAt) {
                if (isMyProduct && item.productType === 'UsSignal') {
                    goToProductSignals(id);
                } else {
                    updateUrlAndRedirectForProduct(item.productType, id);
                }
            }
        },
        [draggingRef, goToProductSignals, isMyProduct, updateUrlAndRedirectForProduct],
    );

    const handleFavoritesClick = useFavoritesClick({ addProductToFavorites, removeProductFromFavorites });

    const getCardProps = useCallback<NonNullable<SharedFinamProps['getCardProps']>>(
        (item) => {
            const { id: idItem, author, productStatus, archivedAt } = item || {};
            const editForbidden =
                archivedAt || productStatus === 'ApprovedByModerator' || productStatus === 'Published';
            // попап удаления сам управляет показывать ли ему Contact или Delete
            const deleteForbidden = archivedAt;
            const contextActions = contextMenu
                ? {
                      buttonContent: createElement(
                          RoundButton,
                          {
                              kind: 'staticColor',
                              onClick: (e: SyntheticEvent) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                              },
                          },
                          createElement(EllipsisVIcon),
                      ),
                      items: [
                          editForbidden
                              ? null
                              : {
                                    // EDIT PRODUCT
                                    id: 1,
                                    onClick: (e: SyntheticEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (idItem !== undefined) {
                                            contextMenu.editProduct(idItem);
                                        }
                                    },
                                    text: dic.word('wt_all__widget_products__button_edit'),
                                },
                          deleteForbidden
                              ? null
                              : {
                                    // DELETE PRODUCT
                                    id: 2,
                                    onClick: (e: SyntheticEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (idItem !== undefined) {
                                            contextMenu.deleteProduct(idItem);
                                        }
                                    },
                                    text: dic.word('wt_all__widget_products__button_delete'),
                                },
                      ].filter(notEmpty),
                  }
                : undefined;
            const isChecked = favorites?.includes(`${idItem}` ?? false);
            return {
                user: author,
                isWidthLimits: false,
                onClick: onClickCard,
                favoriteBtn:
                    contextMenu || !(addProductToFavorites && removeProductFromFavorites)
                        ? undefined
                        : {
                              onClick: () => {
                                  handleFavoritesClick(idItem, isChecked ?? false);
                              },
                              isChecked,
                              className: 'autotest__product-card_button_favorite',
                          },
                contextActions: contextActions?.items.length ? contextActions : undefined,
            };
        },
        [
            favorites,
            onClickCard,
            contextMenu,
            addProductToFavorites,
            removeProductFromFavorites,
            dic,
            handleFavoritesClick,
        ],
    );
    return { getCardProps };
}
