import React from 'react';

import { Action } from 'types';
import { api } from 'services/api-builder/api';
import { useDictionary } from 'hooks/useDictionary';
import { useSwitch } from 'hooks/useSwitch';
import { GradientBanner } from 'components/gradient-banner';

import { CreateMyConnectedAccountPopup } from './СreateMyConnectedAccountPopup';

type Props = {
    createMyConnectedAccount: Action<typeof api.createMyConnectedAccount>;
    onMyConnectedOnboarding: () => void;
};

export const SignUpBanner = (props: Props) => {
    const { createMyConnectedAccount, onMyConnectedOnboarding } = props;
    const dic = useDictionary();
    const {
        switchState: isShownRegisterPopup,
        switchOn: showRegisterPopup,
        switchOff: hideRegisterPopup,
    } = useSwitch();

    return (
        <>
            <GradientBanner
                headingText={dic.word('wt_myspace__widget_products__empty_header_text')}
                secondaryText={dic.word('wt_myspace__widget_products__empty_secondary_text')}
                buttonText={dic.word('wt_myspace__widget_products__empty_add_product_btn')}
                onButtonClick={showRegisterPopup}
            />
            {isShownRegisterPopup && (
                <CreateMyConnectedAccountPopup
                    createMyConnectedAccount={createMyConnectedAccount}
                    onMyConnectedOnboarding={onMyConnectedOnboarding}
                    close={hideRegisterPopup}
                />
            )}
        </>
    );
};
