import styled, { css } from 'styled-components';

import { matchBreakpoints, listResetStyle, mediaQuery, hiddenScroll } from 'app/styled';
import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';

import { DEFAULT_SIZE_TABS } from '../constants';
import type { TabsProps, TabsType } from '../props';

import { TabsListItem, kitByType, TabsListItemAnchor } from './Item';
import { SizeMap, WideMap } from './index';

type TabsWrapperProps = Required<Pick<TabsProps, 'type' | 'size' | 'direction' | 'wide'>>;

/** Tabs size-dependent CSS */
const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --divider-width: 3px;
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --divider-width: 3px;
    `,
    small: css`
        ${buttonSmallFontStyle};
        --divider-width: 2px;
    `,
};

function setSizes({ size = DEFAULT_SIZE_TABS }: Pick<TabsProps, 'size'>) {
    return matchBreakpoints(size, sizeMap);
}

const wideMap: WideMap = {
    none: css`
        --wrapper-display: inline-flex;
        --item-anchor-width: auto;
        --item-anchor-justify-content: normal;
        --item-flex: none;
    `,
    grow: css`
        --wrapper-display: flex;
        --item-anchor-width: 100%;
        --item-anchor-justify-content: center;
        --item-flex: 1;
    `,
};

function setWide({ wide }: Pick<TabsWrapperProps, 'wide'>) {
    return matchBreakpoints(wide, wideMap);
}

function typeSpecificContainerStyles({ type }: { type: TabsType }) {
    const { tabsContainerStyles } = kitByType(type);
    return tabsContainerStyles;
}

export const TabsWrapper = styled.div``;

export const TabsListWrapper = styled.ul<TabsWrapperProps>`
    ${listResetStyle};
    ${setSizes};
    ${setWide};
    ${typeSpecificContainerStyles};

    ${hiddenScroll};
    position: relative;
    display: ${({ direction }) => (direction === 'vertical' ? 'flex' : 'var(--wrapper-display)')};
    user-select: none;
    flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
    gap: ${({ direction }) => (direction === 'vertical' ? '12px' : 'unset')};

    ${mediaQuery.lt768} {
        ${hiddenScroll}
    }

    & ${TabsListItem}:first-child {
        margin-left: 0;
    }

    & ${TabsListItem}:last-child {
        margin-right: 0;
    }

    color: ${({ theme }) => theme.label.tertiary};

    & ${TabsListItem} {
        position: relative;

        ${({ direction }) => (direction === 'vertical' ? 'margin: 0; gap: 8px;' : '')};
    }

    ${TabsListItemAnchor} {
        ${({ direction }) => (direction === 'vertical' ? 'flex-grow: 1' : '')};
    }
`;

export const Divider = styled.div<Pick<TabsProps, 'size' | 'isDisabled'>>`
    ${setSizes};
    background-color: ${({ theme, isDisabled }) =>
        isDisabled ? theme.fillIn.secondary.overlay24 : theme.fillIn.secondary.overlay32};
    border-radius: calc(var(--divider-width) / 2);
    z-index: 0;
    height: var(--divider-width);
    // тут надо писать именно 0px, а не просто 0
    margin-top: calc(0px - var(--divider-width)); ;
`;
