import { useCallback } from 'react';

import notify from 'services/notify';
import Dic from 'services/dictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { BlacklistRenderProps } from 'widgets/action-blacklist';

export const useToggleBlacklist = (isBlockedByCurrentUser: boolean) => {
    const currentUser = useCurrentUser();
    return useCallback(
        (actionCreator: BlacklistRenderProps['toggleBlacklist']) => {
            if (!currentUser.isAuth) return Promise.resolve();
            const settingsAction = isBlockedByCurrentUser ? 'remove' : 'add';
            return actionCreator()
                .then(() => {
                    notify.success(Dic.word(`wt_profile__widget_header_user__blacklist_${settingsAction}_notify`));
                })
                .catch(() => notify.error(Dic.word('error_request')));
        },
        [currentUser.isAuth, isBlockedByCurrentUser],
    );
};
