import styled from 'styled-components';

import { AnyIcon } from 'components/icons2';

const getGap = ({ gap, iconPos }: { gap: string; iconPos: 'left' | 'right' }) =>
    `margin-${iconPos === 'left' ? 'right' : 'left'}: ${gap}`;

export const IconedTextWrapper = styled.div`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    overflow: hidden;
`;

export const IconWrapper = styled.div`
    display: flex;
`;

export const Icon = styled(AnyIcon)<{ gap: string; iconPos: 'left' | 'right' }>`
    ${getGap};
    position: relative;
`;

export const Image = styled.img<{ gap: string; iconPos: 'left' | 'right' }>`
    ${getGap};
    position: relative;
`;

export const TextWrapper = styled.div``;

export const Text = styled.span`
    white-space: nowrap;
`;
