import { mediaQuery } from 'app/styled';

export function getHeight() {
    return 64;
}

export function getMobileHeight() {
    return 56;
}

export function getCurrentHeight() {
    return matchMedia(mediaQuery.lt768.replace('@media ', '')).matches ? getMobileHeight() : getHeight();
}

export function getCurrentMargin() {
    return matchMedia(mediaQuery.lt768.replace('@media ', '')).matches ? getMobileMargin() : getMargin();
}

export function getCurrentMenuOffset() {
    return getCurrentHeight() + getCurrentMargin();
}

export function getMargin() {
    return 24;
}

export function getTabletMargin() {
    return 12;
}

export function getMobileMargin() {
    return 12;
}

export function getDividerHeight() {
    return 2;
}
