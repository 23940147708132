import { ILoginResponse, TypeTxGlobalAuth } from 'types';
import Deferred from 'services/utils/deferred';

export function provideTxGA(): Deferred<TypeTxGlobalAuth>['promise'] {
    if (!window.__txGlobalAuthProvider) {
        window.__txGlobalAuthProvider = {};
    }
    const provider = window.__txGlobalAuthProvider;
    if (!provider.txGlobalAuthLoadingDefer) {
        provider.txGlobalAuthLoadingDefer = new Deferred<TypeTxGlobalAuth>();
    }
    return provider.txGlobalAuthLoadingDefer.promise;
}

export const resolveTxGA = (txGlobalAuth: TypeTxGlobalAuth) => {
    window.__txGlobalAuthProvider?.txGlobalAuthLoadingDefer?.resolve(txGlobalAuth);
};

type JWTInfo = Partial<ILoginResponse>;

export function provideJWT(): Deferred<JWTInfo>['promise'] {
    if (!window.__txGlobalAuthProvider) {
        window.__txGlobalAuthProvider = {};
    }
    const provider = window.__txGlobalAuthProvider;
    if (!provider.txGlobalAuthJWTDefer) {
        provider.txGlobalAuthJWTDefer = new Deferred<JWTInfo>();
    }
    return provider.txGlobalAuthJWTDefer.promise;
}

export const resolveJWT = (jwtObj: Partial<ILoginResponse>) => {
    provideJWT();
    const provider = window.__txGlobalAuthProvider;
    if (provider) {
        provider.txGlobalAuthJWTDefer?.resolve(jwtObj);
        provider.txGlobalAuthJWTDefer = new Deferred<JWTInfo>();
        provider.txGlobalAuthJWTDefer.resolve(jwtObj);
    }
};

export const enrichJWT = async () => {
    try {
        const txGlobalAuth = await provideTxGA();
        const response = await txGlobalAuth.requireLimeAuthorization();
        resolveJWT(response);
        return true;
    } catch {
        return false;
    }
};

export const createDemoAccount = async ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    try {
        const txGlobalAuth = await provideTxGA();
        return await txGlobalAuth.createClient({
            initialData: {
                firstName,
                lastName,
            },
        });
    } catch {
        return null;
    }
};
