import { connect } from 'react-redux';

import { State } from '../../types';

import { Products } from './Products';

export const mapStateToProps = (state: State) => ({
    ownerId: state.page.user.id,
    kratosId: state.page.user.kratosId,
});

export const ProductsContainer = connect(mapStateToProps)(Products);
