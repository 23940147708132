import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CommentFilledIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 7.14286C3 5.68325 4.18325 4.5 5.64286 4.5H18.8571C20.3168 4.5 21.5 5.68325 21.5 7.14286V16.5816C21.5 18.0412 20.3168 19.2245 18.8571 19.2245H12.0881C11.7877 19.2245 11.4996 19.3438 11.2872 19.5562L8.38681 22.4566C7.79219 23.0512 6.77551 22.63 6.77551 21.7892V19.2245H5.64286C4.18325 19.2245 3 18.0412 3 16.5816V7.14286Z"
        />
    </IconSvg>
);
