import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { notEmpty } from 'services/utils/not-empty';
import { formatToPercent, formatToPercentString } from 'services/utils/format-to-percent';
import { ChartDataEntry, DiagnosticsChartItem, PartialChartData } from 'components/chart/types';

type NotEmptyChartDataItem = { date: string; value: number };
type NotEmptyChartData = NotEmptyChartDataItem[];

export const notEmptyChartData = (chartData: PartialChartData): NotEmptyChartData =>
    chartData
        .map((entry) => {
            const { date, value } = entry;
            return (!value && value !== 0) || !date
                ? null
                : {
                      date,
                      value,
                  };
        })
        .filter(notEmpty);

export const formatChartYieldToPercent = (
    chartData: PartialChartData | null | undefined,
): NotEmptyChartData | undefined => {
    if (!chartData) return undefined;
    const notEmptyData = notEmptyChartData(chartData);
    return notEmptyData.map(({ date, value }) => ({
        date,
        value: formatToPercent(value),
    }));
};

export const getMaxYieldValue = (chartData: PartialChartData | null | undefined): number => {
    if (!chartData || !chartData.length) {
        return 0;
    }

    return Math.max(...notEmptyChartData(chartData).map((item) => item.value));
};

export const getFormattedMaxYield = (maxYield: number): string => {
    if (!maxYield || isNaN(maxYield)) {
        return '0%';
    }

    return maxYield ? `${maxYield > 0 ? '+' : ''}${formatToPercentString(maxYield)}` : '0%';
};

export const getGradientOffsetByChartData = (data: PartialChartData | null | undefined) => {
    const formattedData = formatChartYieldToPercent(data);

    if (!formattedData || !formattedData.length) {
        return 0;
    }

    const values = formattedData.map((dataItem) => dataItem.value);
    const max = Math.max(...values);
    const min = Math.min(...values);

    if (min >= 0) return 0;
    if (max < 0) return 100;

    return (Math.abs(min) * 100) / (Math.abs(min) + max);
};

export function calcProfitDataInPercent(profitData: ChartDataEntry[]): ChartDataEntry[] {
    let prevMappedValue = 1;

    return profitData.map((item, index) => {
        const rValue = index > 0 ? item.value : 0;
        const pickedValue = index === 0 ? 1 : prevMappedValue * (1 + rValue);
        const value = pickedValue - 1;
        prevMappedValue = pickedValue;

        return {
            date: item.date,
            value,
        };
    });
}

const parseDate = (dateStr: string): Date => parse(dateStr, 'yyyy-MM-dd', new Date());

const MS_IN_DAY = 1000 * 3600 * 24;

/** Если в данных есть пропущенные дни, добавляет значения на эти даты используя последнее известное значение перед пропуском */
export function interpolateDataPoints(dataPoints: ChartDataEntry[]): ChartDataEntry[] {
    const result: ChartDataEntry[] = [];

    for (let i = 0; i < dataPoints.length - 1; i++) {
        const current = dataPoints[i];
        const next = dataPoints[i + 1];

        const currentDate = parseDate(current.date);
        const nextDate = parseDate(next.date);

        result.push(current);

        const daysDiff = (nextDate.getTime() - currentDate.getTime()) / MS_IN_DAY;

        for (let j = 1; j < daysDiff; j++) {
            const interpolatedDate = new Date(currentDate.getTime() + j * MS_IN_DAY);
            const interpolatedValue = current.value;

            result.push({
                date: format(interpolatedDate, 'yyyy-MM-dd'),
                value: interpolatedValue,
            });
        }
    }

    result.push(dataPoints[dataPoints.length - 1]);

    return result;
}

export function diagnosticsChartItemsToChartData(data: DiagnosticsChartItem[]): ChartDataEntry[] {
    const filtered = data.filter(({ date, value }) => date !== undefined && value !== undefined) as ChartDataEntry[];
    const convertedDates = filtered.map(({ date, value }) => ({ date: date.split('T')[0], value }));
    return convertedDates.length > 0 ? interpolateDataPoints(convertedDates) : [];
}

export function absolutePriceToProfit(values: ChartDataEntry[]) {
    // отсеиваем первую серию нулей
    const firstNonZeroIndex = values.findIndex((item) => item.value !== 0);
    const nonZeroValues = values.slice(firstNonZeroIndex);
    return nonZeroValues.map(({ date, value }, i) => {
        let resultValue;
        if (i === 0) {
            resultValue = 0;
        } else {
            let nonZeroValue = nonZeroValues[i - 1].value;
            if (nonZeroValue === 0) {
                // отыскиваем первое ненулевое значение. поиск ведем назад
                for (let j = i - 1; i > 0; j--) {
                    if (nonZeroValues[j].value !== 0) {
                        nonZeroValue = nonZeroValues[j].value;
                        break;
                    }
                }
            }
            resultValue = (value - nonZeroValue) / nonZeroValue;
        }

        return {
            date,
            value: resultValue,
        };
    });
}
