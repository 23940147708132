import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CommunityIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2107 19.1579C14.2107 19.623 14.5877 20 15.0528 20H21.7482C22.2132 20 22.5903 19.623 22.5903 19.1579C22.5903 15.8578 19.9145 13.182 16.6144 13.182C16.0976 13.182 15.5658 13.2149 15.0823 13.3105C14.6137 13.4031 14.1048 13.5706 13.7097 13.9012C13.2692 14.2698 13.0212 14.8021 13.0785 15.4405C13.1277 15.9894 13.3959 16.5503 13.7997 17.1191C14.0642 17.7448 14.2107 18.433 14.2107 19.1579ZM15.8442 18.3158C15.7608 17.6287 15.5767 16.9717 15.3065 16.3605C15.2821 16.3054 15.2519 16.253 15.2163 16.2042C14.8572 15.7127 14.7677 15.4217 14.7559 15.2901C14.7502 15.2263 14.7554 15.222 14.7852 15.1973C14.7869 15.1959 14.7886 15.1945 14.7905 15.1929C14.8703 15.1261 15.0573 15.0322 15.4089 14.9627C15.7457 14.8961 16.1586 14.8662 16.6144 14.8662C18.6962 14.8662 20.432 16.3489 20.8234 18.3158H15.8442Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2051 9.09317C13.2051 10.9759 14.7314 12.5022 16.6141 12.5022C18.4969 12.5022 20.0231 10.9759 20.0231 9.09317C20.0231 7.21042 18.4969 5.68414 16.6141 5.68414C14.7314 5.68414 13.2051 7.21042 13.2051 9.09317ZM16.6141 10.818C15.6615 10.818 14.8893 10.0457 14.8893 9.09317C14.8893 8.14059 15.6615 7.36835 16.6141 7.36835C17.5667 7.36835 18.3389 8.14059 18.3389 9.09317C18.3389 10.0457 17.5667 10.818 16.6141 10.818Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 19.1579C2 19.623 2.37702 20 2.84211 20H15.0107C15.4758 20 15.8528 19.623 15.8528 19.1579C15.8528 18.1642 15.6432 17.2173 15.2644 16.3605C14.1903 13.93 11.7575 12.2317 8.92618 12.2317C5.10122 12.2317 2 15.3324 2 19.1579ZM3.7515 18.3158C4.15437 15.821 6.31794 13.9159 8.92618 13.9159C11.0669 13.9159 12.9098 15.1988 13.724 17.0414L13.724 17.0415C13.9011 17.4421 14.0295 17.8692 14.1015 18.3158H3.7515Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.04224 7.88408C5.04224 10.0293 6.78125 11.7683 8.92647 11.7683C11.0717 11.7683 12.8107 10.0293 12.8107 7.88408C12.8107 5.73886 11.0717 3.99984 8.92647 3.99984C6.78125 3.99984 5.04224 5.73886 5.04224 7.88408ZM8.92647 10.0841C7.71142 10.0841 6.72645 9.09913 6.72645 7.88408C6.72645 6.66903 7.71142 5.68406 8.92647 5.68406C10.1415 5.68406 11.1265 6.66903 11.1265 7.88408C11.1265 9.09913 10.1415 10.0841 8.92647 10.0841Z"
        />
    </IconSvg>
);
