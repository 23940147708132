import styled, { css } from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { Button, LinkButton } from 'components/button2';

import { SizeWtPopup3Enum } from '../constants';

import {
    ActionButtonsAlignType,
    ActionButtonsDirectionType,
    FooterActionButtonsType,
    FooterWtPopup3Props,
} from './types';

const buildSizesWrapper = ({ size, isEmpty }: Required<Pick<FooterWtPopup3Props, 'size'> & { isEmpty: boolean }>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: `padding: ${isEmpty ? '0 16px 24px' : '24px 16px'};`,
        [SizeWtPopup3Enum.SMALL]: `padding: ${isEmpty ? '0 32px 24px' : '24px 32px'};`,
        [SizeWtPopup3Enum.MEDIUM]: `padding: ${isEmpty ? '0 32px 24px' : '24px 32px'};`,
        [SizeWtPopup3Enum.LARGE]: `padding: ${isEmpty ? '0 48px 24px' : '24px 48px'};`,
    });

export const FooterWrapper = styled.div<Required<Pick<FooterWtPopup3Props, 'size'>> & { isEmpty: boolean }>`
    ${buildSizesWrapper};

    ${mediaQuery.lt768} {
        ${({ isEmpty }) => buildSizesWrapper({ size: SizeWtPopup3Enum.TINY, isEmpty })};
    } ;
`;

export const ActionButtons = styled.div<
    Required<Pick<FooterActionButtonsType, 'direction'>> & { align?: ActionButtonsAlignType }
>`
    display: flex;
    ${({ direction }) =>
        matchBreakpoints(direction, {
            horizontal: css`
                flex-direction: row;
            `,
            vertical: css`
                flex-direction: column;
            `,
        })}

    ${({ align }) => {
        if (!align) {
            return '';
        }
        return `justify-content: ${align === 'left' ? 'flex-start' : 'flex-end'};`;
    }}
`;

const buttonCss = css<{ direction: ActionButtonsDirectionType }>`
    margin-right: 8px;
    ${({ direction }) =>
        matchBreakpoints(direction, {
            horizontal: css`
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            `,
            vertical: css`
                margin-bottom: 12px;
                margin-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            `,
        })};
`;

export const ActionButton = styled(Button)<{ direction: ActionButtonsDirectionType }>`
    ${buttonCss}
`;

export const ActionLinkButton = styled(LinkButton)<{ direction: ActionButtonsDirectionType }>`
    ${buttonCss}
`;
