import { useCallback } from 'react';
import { ReactElementLike } from 'prop-types';

import { Id } from 'types';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCSRFToken } from 'hooks/useCSRFToken';

import { BlacklistRenderProps } from '../types';
import { GetFollowers, ReduceFollowingCount, AddBlacklist, RemoveBlacklist } from '../actions';

type DispatchProps = {
    addBlacklist: AddBlacklist;
    removeBlacklist: RemoveBlacklist;
    getFollowers: GetFollowers;
    reduceFollowingCount: ReduceFollowingCount;
};

type Props = DispatchProps & {
    isInBlacklist: boolean;
    personId: Id;
    customRender: ((props: BlacklistRenderProps) => ReactElementLike) | null;
};

export const ActionBlacklist = (props: Props) => {
    const currentUser = useCurrentUser();
    const csrf = useCSRFToken();
    const { customRender, addBlacklist, removeBlacklist, isInBlacklist, personId, getFollowers, reduceFollowingCount } =
        props;
    const toggleBlacklist = useCallback(() => {
        const actionCreator = isInBlacklist ? removeBlacklist : addBlacklist;
        return actionCreator(personId, currentUser.id, csrf);
    }, [isInBlacklist, removeBlacklist, addBlacklist, personId, currentUser, csrf]);

    return customRender?.({ toggleBlacklist, getFollowers, reduceFollowingCount }) || null;
};
