import React, { FC } from 'react';

import { IconButton } from 'components/text-input/styled';
import { ChevronRightIcon } from 'components/icons2/ChevronRightIcon';

import { useClickAndHold } from './hooks/useClickAndHold';
import { useIncDec } from './hooks/useIncDec';
import { StepperProps } from './types';
import { StepperStyled } from './styled';

const DELAY = 400;
const INTERVAL = 50;

export const Stepper: FC<StepperProps> = ({ value, onChange, disabled }) => {
    const [increment, decrement] = useIncDec(value, onChange);

    const [holdInc, releaseInc] = useClickAndHold(increment, DELAY, INTERVAL);
    const [holdDec, releaseDec] = useClickAndHold(decrement, DELAY, INTERVAL);

    return (
        <StepperStyled>
            <IconButton disabled={disabled} onMouseDown={holdInc} onMouseUp={releaseInc} onMouseLeave={releaseInc}>
                <ChevronRightIcon />
            </IconButton>
            <IconButton disabled={disabled} onMouseDown={holdDec} onMouseUp={releaseDec} onMouseLeave={releaseDec}>
                <ChevronRightIcon />
            </IconButton>
        </StepperStyled>
    );
};
