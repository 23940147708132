import React, { SyntheticEvent, useRef, useEffect } from 'react';

import * as Styled from './styled/Item';
import { TabProps } from './props';

export const Tab = (props: TabProps) => {
    const {
        title,
        type,
        kind,
        autotestClass,
        isActive = false,
        icon: Icon,
        onClick: onClickProp,
        count,
        size = 'large',
        onMount,
        iconPos = 'left',
        isDisabled,
        // TODO: href
        // href = '',
    } = props;

    const onClick = isActive ? preventDefaultHandler : onClickProp;

    const ref = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        onMount?.(ref, props);
    }, []);

    return (
        <Styled.TabsListItem type={type} kind={kind} isActive={isActive} size={size} ref={ref} isDisabled={isDisabled}>
            <Styled.TabsListItemAnchor
                tabIndex={0}
                type={type}
                kind={kind}
                isActive={isActive}
                onClick={onClick}
                icon={Icon}
                title={title}
                className={autotestClass}
                iconPos={iconPos}
                count={count}
                isDisabled={isDisabled}
            >
                {Icon && (
                    <Styled.IconWrapper size={size}>
                        <Icon />
                    </Styled.IconWrapper>
                )}
                {title && <Styled.TabTitleContainer>{title}</Styled.TabTitleContainer>}
                {count && <Styled.Counter size={size}>{count}</Styled.Counter>}
            </Styled.TabsListItemAnchor>
        </Styled.TabsListItem>
    );
};

// MARK: - Helpers

function preventDefaultHandler(e: SyntheticEvent) {
    e.preventDefault();
}
