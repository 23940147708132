import React, { useRef } from 'react';

import { useClickOutside } from 'hooks/useClickOutside';
import { ScrollBlocker } from 'components/scroll-blocker';

import * as Styled from './styled';
import { Props } from './types';

export const PopupWindow3 = (props: Props) => {
    const {
        children,
        close,
        className,
        isMobileFullscreen = false,
        isVisible = true,
        bgCloseable,
        width,
        zIndex,
    } = props;

    const dialogRef = useRef<HTMLDivElement>(null);
    useClickOutside(dialogRef, () => bgCloseable && close?.());

    return (
        <>
            {isVisible && <ScrollBlocker />}
            <Styled.PopupWindow3Wrapper isVisible={isVisible} className={className}>
                <>
                    <Styled.Overlay zIndex={zIndex} />
                    <Styled.DialogWrapper zIndex={zIndex} isMobileFullscreen={isMobileFullscreen}>
                        <Styled.Dialog width={width} isMobileFullscreen={isMobileFullscreen} ref={dialogRef}>
                            {children}
                        </Styled.Dialog>
                    </Styled.DialogWrapper>
                </>
            </Styled.PopupWindow3Wrapper>
        </>
    );
};
