import styled, { css } from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import {
    body14MediumFontStyle,
    body17MediumFontStyle,
    body17SemiboldFontStyle,
    h4SemiboldFontStyle,
} from 'services/typography';
import { NameText } from 'components/user-name2/styled';

import { UserName as UserNameComponent } from '../../user-name2';
import { UserBlockSize } from '../types';

export const Details = styled.div<{ isNoRenderAvatar: boolean; size: UserBlockSize }>`
    display: flex;
    flex-direction: column;
    ${({ isNoRenderAvatar }) => (isNoRenderAvatar ? '' : 'margin-left: 12px;')}
    justify-content: center;
    overflow: hidden;
    ${({ size }) =>
        matchBreakpoints(size, {
            large: css`
                margin-left: 16px;
            `,
            regular: css`
                margin-left: 12px;
            `,
            small: css`
                margin-left: 12px;
            `,
            xsmall: css`
                margin-left: 8px;
            `,
        })};
`;

export const ANIMATION_DURATION = 360;

export type GetLoadingCssArgs = {
    minHeight?: number;
    marginBottom?: number;
    marginTop?: number;
    minWidth?: number;
};
export const getLoadingCss = ({ minHeight, marginBottom = 0, marginTop = 0, minWidth }: GetLoadingCssArgs) =>
    css`
        ${minHeight ? `min-height: ${minHeight}px;` : ''};
        margin: ${marginTop}px 0 ${marginBottom}px 0;
        ${minWidth ? `min-width: ${minWidth}px;` : ''};
    `;

export const UserName = styled(UserNameComponent)<{ isLoading: boolean; size: UserBlockSize }>`
    text-decoration: none;
    flex: initial;
    text-overflow: ellipsis;
    padding: 0;
    transition: all ${ANIMATION_DURATION}ms;

    ${({ isLoading }) =>
        isLoading
            ? css`
                  min-width: 72px;
              `
            : ''};

    ${({ size, isLoading }) => {
        const getLoadingCssHandler = (args: GetLoadingCssArgs) =>
            css`
                ${isLoading ? getLoadingCss(args) : ''}
            `;

        return matchBreakpoints(size, {
            large: css`
                min-height: 20px;

                ${getLoadingCssHandler({ marginBottom: 4, marginTop: 4 })};

                ${mediaQuery.lt960} {
                    min-height: 16px;
                }

                ${NameText} {
                    ${h4SemiboldFontStyle};
                    ${mediaQuery.lt960} {
                        ${body17SemiboldFontStyle};
                        ${getLoadingCssHandler({ marginBottom: 4, marginTop: 4 })};
                    }
                }
            `,
            regular: css`
                min-height: 16px;

                ${NameText} {
                    ${body17MediumFontStyle};
                }

                ${getLoadingCssHandler({ marginBottom: 4, marginTop: 4 })};
            `,
            small: css`
                min-height: 12px;

                ${NameText} {
                    ${body14MediumFontStyle};
                }

                ${getLoadingCssHandler({ marginBottom: 4, marginTop: 4 })};
            `,
            xsmall: css`
                min-height: 12px;

                ${NameText} {
                    ${body14MediumFontStyle};
                }

                ${getLoadingCssHandler({ marginBottom: 4, marginTop: 4 })};
            `,
        });
    }}
`;

export const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UserBlockWrapper = styled.div<{ isLoading: boolean }>`
    display: flex;
    overflow: hidden;
    cursor: ${({ isLoading }) => (isLoading ? 'progress' : 'auto')};
`;
