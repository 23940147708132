import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const LikeIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 24 24">
        <g clipPath="url(#clip0_3243_18952)">
            <path
                d="M11.9376 2.3999C11.1944 2.3999 10.9118 2.99484 10.9693 4.1474C11.0075 4.90718 11.2634 7.02539 10.191 8.09876C9.19094 9.09938 8.90006 9.01049 7.76048 10.3026C6.62138 11.5942 5.67191 11.3285 4.34273 11.2143C2.67296 11.0711 3.24182 20.3414 4.41866 20.1807C5.25401 20.0665 7.15298 19.8386 7.45673 19.8386C7.76048 19.8386 9.84356 21.453 11.6721 21.4724C14.6338 21.5032 16.2672 20.9783 16.2672 20.9783L17.1352 19.0084L17.4256 13.0019L16.0389 10.1124C16.0389 10.1124 14.1781 10.4163 13.6465 9.58055C13.115 8.74481 14.0644 7.71887 14.0644 5.32529C14.0644 2.93175 12.3174 2.3999 11.9376 2.3999Z"
                fill="url(#paint0_linear_3243_18952)"
            />
            <path
                d="M16.3706 20.9607L16.2579 20.5494C15.9242 20.514 15.4948 20.666 14.9085 20.6613C12.1079 20.6397 9.43062 19.8353 8.28186 18.9254C7.52937 18.3295 7.19937 17.5366 6.6678 17.7645C6.01194 18.0458 4.15119 18.4833 3.4668 17.6167C3.65826 19.0994 3.99975 20.2377 4.41858 20.1806C5.25393 20.0664 7.1529 19.8385 7.45665 19.8385C7.7604 19.8385 9.78549 21.4105 11.672 21.4722C14.1127 21.5519 16.2804 21.1315 16.2804 21.1315L16.3706 20.9607Z"
                fill="url(#paint1_linear_3243_18952)"
            />
            <path
                d="M5.52882 11.7996C6.63018 12.1035 7.32975 11.6007 7.89264 10.9164C8.45184 10.2368 8.93691 9.63398 10.3421 8.72225C11.7472 7.81049 11.5667 5.30276 11.4526 4.46699C11.3385 3.63122 11.4144 2.3999 11.9377 2.3999C11.1944 2.3999 10.9118 2.99484 10.9694 4.1474C11.0076 4.90721 11.2634 7.02539 10.1911 8.09876C9.19098 9.09938 8.9001 9.01049 7.76055 10.3026C6.62145 11.5942 5.67195 11.3285 4.34277 11.2144C4.34325 11.5026 4.42746 11.4957 5.52882 11.7996Z"
                fill="url(#paint2_linear_3243_18952)"
            />
            <path
                d="M13.6466 9.58058C13.1151 8.74481 14.0645 7.71887 14.0645 5.32529C14.0645 2.93175 12.3179 2.3999 11.9378 2.3999C11.8379 2.3999 11.7491 2.41095 11.6694 2.43213C13.2186 2.5293 14.1515 4.484 13.6177 6.49952C13.1846 8.13512 12.9687 9.44657 13.6761 10.2156C14.1336 10.7134 15.0435 10.6286 16.0394 10.1124C16.039 10.1124 14.1782 10.4163 13.6466 9.58058Z"
                fill="url(#paint3_linear_3243_18952)"
            />
            <path
                d="M13.7511 10.4336C12.8398 10.7375 10.5381 11.4526 8.79473 13.0344C4.37639 17.0428 11.9198 17.5452 13.4777 20.9734C13.7755 21.6287 15.5161 17.5369 15.5161 17.5369L16.079 16.5892L14.4525 12.2557L13.7511 10.4336Z"
                fill="url(#paint4_linear_3243_18952)"
            />
            <path
                d="M13.8852 10.7808C12.9739 11.0847 9.25098 13.4709 9.25098 14.6492C9.25098 18.0499 11.8293 16.3714 13.2229 20.384C13.4157 20.9398 14.0974 21.4072 14.0974 21.4072C14.6621 20.9463 15.5167 17.5369 15.5167 17.5369L16.0796 16.5892L14.4531 12.2557L13.8852 10.7808Z"
                fill="url(#paint5_linear_3243_18952)"
            />
            <path
                d="M15.9886 21.2036L17.733 19.2084L18.1173 10.8733L13.7509 10.4331C12.7485 10.9728 12.6325 11.7109 13.0049 12.8962C13.1614 13.3949 12.5248 13.5376 12.4839 14.9904C12.4554 15.9975 13.1144 16.0021 13.1144 16.3014C13.1144 16.6007 12.7863 16.574 12.6772 17.4411C12.5216 18.6816 13.5599 18.7056 13.5599 18.9708C13.5599 19.236 12.9832 19.3935 13.0187 20.4047C13.0638 21.6872 10.6834 21.4279 9.07394 20.261C8.41304 19.7817 7.83635 19.7324 7.38623 19.8411C7.41431 19.8397 7.43825 19.8388 7.45619 19.8388C7.75994 19.8388 9.80021 21.4726 11.6716 21.4726C13.86 21.4726 15.3733 21.3446 15.9886 21.2036Z"
                fill="url(#paint6_linear_3243_18952)"
            />
            <path
                d="M16.3895 10.9829C15.0414 10.9829 14.3009 12.3008 14.8136 13.3134C15.3263 14.326 13.8641 15.6664 14.016 16.3263C14.1679 16.9862 14.4339 18.6563 14.6424 18.9326C14.8514 19.2088 14.3004 19.8356 14.3004 19.8356L16.0093 20.0446L17.6041 19.341L17.547 14.5166L17.7177 11.4959L16.3895 10.9829Z"
                fill="url(#paint7_linear_3243_18952)"
            />
            <path
                d="M7.76051 10.3028C7.02044 11.1418 6.36044 11.3237 5.62451 11.3168C5.8367 11.6594 5.98442 12.0338 5.96924 12.3998C5.92919 13.3595 6.14045 14.5424 6.302 13.3121C6.42488 12.375 7.06001 11.2169 7.82402 10.2314C7.80332 10.2549 7.78259 10.2779 7.76051 10.3028Z"
                fill="url(#paint8_linear_3243_18952)"
            />
            <path
                d="M17.4108 10.075C15.817 10.0142 12.9515 9.92395 12.8875 11.5946C12.8415 12.8061 13.7003 13.2035 15.0286 13.2541C16.3568 13.3048 17.021 13.3301 17.571 13.3509C18.1209 13.3716 19.8515 13.3614 19.8685 11.8985C19.8897 10.0709 17.4108 10.075 17.4108 10.075Z"
                fill="url(#paint9_linear_3243_18952)"
            />
            <path
                d="M14.7895 16.1141C14.3623 16.1017 13.0934 16.4217 12.9747 17.228C12.8813 17.8621 13.2048 18.6813 14.3089 18.8747C15.7076 19.1196 18.0383 19.2481 18.9091 18.9221C19.298 18.7766 19.6372 18.3691 19.6551 17.7516C19.6731 17.1341 19.2147 16.302 18.2182 16.273C17.2218 16.2444 16.7027 16.2596 16.1347 16.1924C15.4517 16.1123 15.1309 16.1242 14.7895 16.1141Z"
                fill="url(#paint10_linear_3243_18952)"
            />
            <path
                d="M14.3442 18.9321C13.86 18.9321 13.2617 19.3595 13.2617 20.0718C13.2617 20.7842 13.4468 21.3114 14.4864 21.5108C15.5261 21.7102 17.1922 21.5394 17.8186 21.34C18.3069 21.1843 18.4878 20.7275 18.4878 19.9724C18.4878 19.2172 18.0183 19.085 17.5056 19.085C16.9929 19.085 16.3707 19.1785 16.0154 19.1601C15.0231 19.1076 14.8284 18.9321 14.3442 18.9321Z"
                fill="url(#paint11_linear_3243_18952)"
            />
            <path
                d="M17.2751 10.412C15.8834 10.359 13.381 10.2784 13.3309 11.5991C13.2945 12.5569 14.0456 12.8737 15.2054 12.9179C16.3652 12.9621 19.4167 13.0178 19.4268 11.8616C19.4392 10.4166 17.2751 10.412 17.2751 10.412Z"
                fill="url(#paint12_linear_3243_18952)"
            />
            <path
                d="M14.9182 16.4544C14.5463 16.4438 13.5052 16.5322 13.4053 17.3026C13.3377 17.8239 13.6078 18.4934 14.5693 18.6532C15.7876 18.8558 17.8163 18.9645 18.5743 18.6988C18.9126 18.58 19.2076 18.247 19.2219 17.741C19.2366 17.2349 18.7704 16.6146 17.9033 16.5893C17.0362 16.564 16.5746 16.6446 16.0798 16.5884C15.4852 16.5216 15.2155 16.4627 14.9182 16.4544Z"
                fill="url(#paint13_linear_3243_18952)"
            />
            <path
                d="M14.5514 19.1919C14.1325 19.1919 13.6152 19.5358 13.6152 20.1096C13.6152 20.6834 13.7754 21.1079 14.6747 21.2682C15.574 21.4284 17.015 21.2912 17.5572 21.1305C17.9792 21.0052 18.1362 20.6373 18.1362 20.029C18.1362 19.4207 17.7298 19.3148 17.2861 19.3148C16.8425 19.3148 16.3044 19.3903 15.997 19.3752C15.1382 19.3333 14.9697 19.1919 14.5514 19.1919Z"
                fill="url(#paint14_linear_3243_18952)"
            />
            <path
                d="M14.2963 11.5995C14.3308 10.6896 15.0562 10.2535 15.9725 10.0527C14.5098 10.0868 12.9349 10.3603 12.8875 11.594C12.8415 12.8055 13.7003 13.2029 15.0285 13.2535C15.1749 13.259 15.3125 13.2646 15.4432 13.2692C14.6976 13.0509 14.2599 12.5586 14.2963 11.5995Z"
                fill="url(#paint15_linear_3243_18952)"
            />
            <path
                d="M14.421 10.2178C13.57 10.4038 12.9174 10.8063 12.8875 11.5941C12.8493 12.6012 13.437 13.0456 14.3994 13.1961C12.4471 12.4166 13.1475 10.4964 14.421 10.2178Z"
                fill="url(#paint16_linear_3243_18952)"
            />
            <path
                d="M12.155 19.7897C11.9299 20.3068 10.8589 20.3395 9.76261 19.862C8.66632 19.3849 7.9603 18.5786 8.18536 18.0611C8.41042 17.5439 9.48139 17.5113 10.5777 17.9888C11.6736 18.4663 12.38 19.2726 12.155 19.7897Z"
                fill="url(#paint17_radial_3243_18952)"
            />
            <path
                d="M18.6047 11.844C18.5936 12.543 17.6593 12.8745 16.6371 12.9367C15.8013 12.9873 14.8735 12.3956 14.8974 11.7127C14.9214 11.0299 15.0429 10.4248 16.7798 10.4851C17.8034 10.5206 18.6153 11.1611 18.6047 11.844Z"
                fill="url(#paint18_radial_3243_18952)"
            />
            <path
                d="M18.7905 17.9887C18.7846 18.9566 17.721 18.7711 16.6987 18.8383C15.4027 18.9239 14.5434 18.5201 14.543 17.8367C14.542 17.1534 15.035 16.6556 16.7733 16.6528C17.7973 16.6515 18.7979 16.7454 18.7905 17.9887Z"
                fill="url(#paint19_radial_3243_18952)"
            />
            <path
                d="M17.8536 20.4288C17.8485 21.254 16.882 21.3664 15.9661 21.3668C15.0502 21.3677 14.0561 20.8819 14.0557 20.2994C14.0552 19.7169 14.4956 19.2924 16.0499 19.2901C16.9653 19.2887 17.86 19.3688 17.8536 20.4288Z"
                fill="url(#paint20_radial_3243_18952)"
            />
            <path
                d="M14.1633 17.424C14.3088 16.322 15.1538 16.3865 16.1226 16.2723C15.0074 16.189 14.5393 16.177 14.5393 16.177C14.4353 16.1668 14.3189 16.1664 14.1979 16.1788C13.8356 16.2585 13.4251 16.4233 13.1812 16.7328C13.0983 16.8507 13.0311 16.9897 12.9869 17.1546C12.9823 17.1785 12.9777 17.2029 12.975 17.2278C12.8963 17.8642 13.2051 18.6811 14.3092 18.8745C14.4685 18.9025 14.6401 18.9288 14.8201 18.9532C14.9062 18.9643 15.0001 18.9758 15.1008 18.9887C15.128 18.9919 15.1552 18.9951 15.1828 18.9983C14.5734 18.7105 14.0561 18.2339 14.1633 17.424Z"
                fill="url(#paint21_linear_3243_18952)"
            />
            <path
                d="M14.4329 16.1702C14.3579 16.1679 14.2782 16.1707 14.1968 16.1789C13.8314 16.2595 13.4172 16.4267 13.1746 16.7412C13.0945 16.8572 13.0301 16.9945 12.9868 17.1552C12.9822 17.1791 12.979 17.2031 12.9748 17.2279C12.863 17.8546 13.1732 18.5933 14.1379 18.8378C12.492 17.9297 13.1944 16.1711 14.4329 16.1702Z"
                fill="url(#paint22_linear_3243_18952)"
            />
            <path
                d="M15.3908 19.1002C14.9043 19.0288 14.7046 18.9316 14.3447 18.9316C13.8605 18.9316 13.2622 19.359 13.2622 20.0713C13.2622 20.3301 13.2871 20.564 13.3676 20.7671C13.4495 20.9347 13.5687 21.0917 13.7321 21.2244C13.9116 21.3496 14.1555 21.4467 14.4864 21.5103C14.7087 21.5531 14.9601 21.5785 15.2233 21.5909C14.7152 21.2957 13.8546 21.1369 14.0349 20.1487C14.2342 19.0573 14.8855 19.2047 15.3908 19.1002Z"
                fill="url(#paint23_linear_3243_18952)"
            />
            <path
                d="M14.3231 18.9326C13.843 18.945 13.2622 19.3701 13.2622 20.0718C13.2622 20.7243 13.4182 21.2216 14.2439 21.4537C12.9543 20.6249 13.3681 19.2264 14.3231 18.9326Z"
                fill="url(#paint24_linear_3243_18952)"
            />
            <path
                d="M19.8685 11.8982C19.8704 11.7343 19.852 11.5855 19.8174 11.4497C19.9458 12.0207 19.4373 12.588 18.0892 12.8634C17.1959 13.0457 14.8233 13.2737 13.1816 12.6216C13.536 13.0499 14.1882 13.2212 15.0286 13.2534C16.3569 13.3041 17.021 13.3294 17.571 13.3501C18.121 13.3717 19.851 13.3612 19.8685 11.8982Z"
                fill="url(#paint25_linear_3243_18952)"
            />
            <path
                d="M19.6554 17.7125C19.6535 17.0992 19.1938 16.3012 18.218 16.2731C17.2216 16.2445 16.7025 16.2597 16.1345 16.1925C15.452 16.1119 15.1312 16.1239 14.7897 16.1142C14.5315 16.1069 13.8945 16.1847 13.4614 16.4541C13.9484 16.6231 14.7418 16.8772 15.8584 16.8059C18.5338 16.6337 19.295 16.9574 19.6554 17.7125Z"
                fill="url(#paint26_linear_3243_18952)"
            />
            <path
                d="M14.2158 13.3499C13.6465 13.3384 12.7421 13.6736 12.7187 14.8566C12.6952 16.0391 13.7293 16.3209 14.185 16.3301C14.6406 16.3393 16.2335 16.448 18.1983 16.4871C20.1631 16.5262 20.4263 15.4713 20.4374 14.9187C20.4484 14.3657 20.0982 13.366 18.9163 13.3421C15.499 13.2735 14.2158 13.3499 14.2158 13.3499Z"
                fill="url(#paint27_linear_3243_18952)"
            />
            <path
                d="M14.5213 13.6933C14.0261 13.6836 13.2409 13.9406 13.2229 14.8527C13.2045 15.7654 14.1043 15.9846 14.5001 15.9924C14.8964 16.0003 16.2813 16.0873 17.9892 16.1213C19.6972 16.1554 19.9241 15.3422 19.9324 14.9154C19.9411 14.489 19.8822 13.7172 18.8545 13.6965C15.8845 13.6376 14.5213 13.6933 14.5213 13.6933Z"
                fill="url(#paint28_linear_3243_18952)"
            />
            <path
                d="M13.9406 14.7424C13.9976 13.5282 14.845 13.5245 15.8014 13.3149C14.683 13.3218 14.2158 13.3495 14.2158 13.3495C13.6465 13.338 12.7421 13.6732 12.7187 14.8562C12.6952 16.0386 13.7293 16.3205 14.185 16.3297C14.3493 16.3329 14.6618 16.349 15.0852 16.3702C14.4538 16.11 13.8987 15.6357 13.9406 14.7424Z"
                fill="url(#paint29_linear_3243_18952)"
            />
            <path
                d="M19.437 14.8683C19.4765 15.8353 18.4028 16.0172 17.3797 16.066C16.3566 16.1148 15.2193 15.5977 15.1866 14.9152C15.1539 14.2328 15.6229 13.7125 17.359 13.6282C18.3821 13.5789 19.3863 13.6259 19.437 14.8683Z"
                fill="url(#paint30_radial_3243_18952)"
            />
            <path
                d="M14.1095 13.3521C13.5407 13.3824 12.7408 13.7453 12.7187 14.8564C12.6961 15.9809 13.6295 16.2908 14.1132 16.3263C12.2414 15.5706 12.8549 13.4635 14.1095 13.3521Z"
                fill="url(#paint31_linear_3243_18952)"
            />
            <path
                d="M20.4339 14.7993C20.1872 16.5491 15.5631 16.2286 13.0645 15.8529C13.4018 16.2143 13.9035 16.3239 14.1847 16.3299C14.6403 16.3391 16.2332 16.4478 18.198 16.4869C20.1628 16.5261 20.4261 15.4711 20.4371 14.9186C20.4376 14.8808 20.4366 14.8407 20.4339 14.7993Z"
                fill="url(#paint32_linear_3243_18952)"
            />
            <path
                d="M18.1177 18.6959C17.2221 18.7355 14.8523 19.0302 13.1245 18.1042C13.3174 18.4624 13.6906 18.7668 14.3087 18.875C15.7074 19.12 18.0381 19.2485 18.9088 18.9225C19.2978 18.777 19.637 18.3694 19.6549 17.752C19.5974 18.2889 19.0861 18.6531 18.1177 18.6959Z"
                fill="url(#paint33_linear_3243_18952)"
            />
            <path
                d="M18.4198 20.6401C17.833 21.0868 16.8186 21.3507 15.355 21.1034C14.4511 20.9505 13.8316 20.8985 13.4243 20.8874C13.5872 21.1821 13.9016 21.3985 14.487 21.5109C15.5267 21.7103 17.1928 21.5394 17.8192 21.34C18.1409 21.2369 18.3287 21.003 18.4198 20.6401Z"
                fill="url(#paint34_linear_3243_18952)"
            />
            <path
                d="M13.4541 19.4308C14.5384 19.0357 15.07 19.6219 16.2303 19.6219C17.4352 19.6219 18.0096 19.1352 18.4376 19.598C18.3032 19.1711 17.9185 19.085 17.5056 19.085C16.9929 19.085 16.3707 19.1785 16.0149 19.1601C15.0226 19.108 14.8284 18.9321 14.3442 18.9321C14.0308 18.9321 13.67 19.1113 13.4541 19.4308Z"
                fill="url(#paint35_linear_3243_18952)"
            />
            <path
                d="M4.41943 20.1809C5.25475 20.0667 7.15372 19.8388 7.45747 19.8388C4.70338 17.8693 5.92811 13.6804 6.94385 11.5617C7.08653 11.2642 7.17442 11.0202 7.22185 10.813C6.3055 11.512 5.45773 11.3103 4.34302 11.2145C2.67326 11.0713 3.24256 20.3416 4.41943 20.1809Z"
                fill="url(#paint36_linear_3243_18952)"
            />
            <path
                d="M6.15603 18.2778C5.87115 17.8979 6.11784 18.7148 6.34611 19.3037C6.53988 19.8047 5.05377 19.5086 3.9165 19.6316C4.06515 19.9986 4.23498 20.2063 4.41954 20.1809C5.25489 20.0668 7.15386 19.8388 7.45761 19.8388C7.02912 19.4746 6.44046 18.6577 6.15603 18.2778Z"
                fill="url(#paint37_linear_3243_18952)"
            />
            <path
                d="M7.45681 19.8392C7.22071 19.638 6.93583 19.2991 6.67945 18.9717C6.67945 18.9717 5.2969 19.6794 3.81445 19.3465C3.98521 19.888 4.19047 20.2122 4.41874 20.1809C5.25454 20.0671 7.15306 19.8392 7.45681 19.8392Z"
                fill="url(#paint38_linear_3243_18952)"
            />
            <path
                d="M12.694 5.96717C12.1528 6.08414 11.7142 5.33633 11.4666 4.46372C11.2641 3.74999 11.5393 2.841 12.0709 2.73877C12.6025 2.63609 13.0931 2.63148 13.3862 4.14416C13.5584 5.03516 13.2233 5.85296 12.694 5.96717Z"
                fill="url(#paint39_radial_3243_18952)"
            />
            <path
                d="M14.3232 18.9323C14.0254 18.9636 13.7557 19.1234 13.5702 19.3431C13.382 19.5646 13.2941 19.8537 13.3134 20.1443C13.3153 20.4367 13.3595 20.7356 13.5252 20.9723C13.6895 21.2112 13.9656 21.3535 14.244 21.4535C13.9569 21.3885 13.6563 21.2697 13.4639 21.0178C13.2688 20.7687 13.2237 20.4441 13.214 20.1461C13.2011 19.8413 13.2982 19.5171 13.5132 19.2924C13.7217 19.0654 14.0227 18.9263 14.3232 18.9323Z"
                fill="#804B24"
            />
            <path
                d="M13.8455 16.2822C13.6495 16.3674 13.4686 16.4839 13.319 16.629C13.1667 16.7722 13.0857 16.9684 13.03 17.1673L13.0304 17.164C12.9586 17.5269 13.0194 17.9132 13.2325 18.2149C13.4405 18.5206 13.7783 18.7205 14.1373 18.8374C13.7677 18.766 13.3954 18.586 13.1616 18.265C13.0461 18.1071 12.966 17.9224 12.9273 17.7295C12.8882 17.5366 12.9062 17.3349 12.9421 17.1466L12.943 17.1433C13.0014 16.9343 13.1055 16.7248 13.2771 16.5834C13.4414 16.4374 13.643 16.3476 13.8455 16.2822Z"
                fill="#804B24"
            />
            <path
                d="M14.1095 13.3516C13.7574 13.3916 13.4168 13.538 13.1743 13.7844C12.9308 14.0308 12.7991 14.3701 12.778 14.7159C12.7425 15.0604 12.7982 15.42 12.9952 15.706C13.1894 15.9928 13.507 16.1853 13.8467 16.2848C13.4987 16.2212 13.1526 16.0495 12.9326 15.7506C12.708 15.4541 12.6487 15.0659 12.6786 14.7077C12.708 14.3476 12.8493 13.9801 13.1209 13.7301C13.3896 13.4773 13.7546 13.3553 14.1095 13.3516Z"
                fill="#804B24"
            />
            <path
                d="M14.4213 10.2178C14.0448 10.3209 13.6706 10.4632 13.3737 10.7077C13.0746 10.9509 12.9126 11.3262 12.9375 11.7106C12.9324 12.0924 13.0571 12.4819 13.347 12.7347C13.6329 12.9908 14.0185 13.1151 14.3992 13.1961C14.013 13.1506 13.6112 13.0539 13.2969 12.7923C12.9752 12.5344 12.8385 12.1071 12.838 11.7116C12.8173 11.3096 12.9982 10.8887 13.3259 10.6483C13.6471 10.402 14.037 10.2859 14.4213 10.2178Z"
                fill="#804B24"
            />
            <path
                d="M13.1245 18.1035C13.3836 18.5525 13.8816 18.785 14.3736 18.8513C14.8744 18.9287 15.3788 18.9816 15.8846 19.0139C16.3895 19.0567 16.8962 19.0834 17.4029 19.0816C17.656 19.0783 17.9096 19.0719 18.1614 19.047C18.4132 19.0268 18.6672 19.001 18.9093 18.9213C18.4302 19.1014 17.9106 19.1299 17.4043 19.1479C16.8953 19.1608 16.3858 19.1465 15.8777 19.1133C15.3705 19.0696 14.8647 19.0056 14.363 18.9167C14.1108 18.8753 13.8595 18.797 13.6386 18.6625C13.4186 18.5267 13.2382 18.331 13.1245 18.1035Z"
                fill="#804B24"
            />
            <path
                d="M13.0645 15.853C13.2587 16.0464 13.5086 16.174 13.7695 16.2389C14.0291 16.3167 14.3021 16.2997 14.5764 16.3121L16.2144 16.3595L17.8515 16.4401C18.3946 16.4733 18.9473 16.4807 19.4678 16.3025C18.9607 16.5189 18.3909 16.5364 17.8501 16.5064L16.2121 16.459L14.575 16.378C14.3057 16.3595 14.0227 16.3724 13.7562 16.2854C13.4929 16.203 13.2467 16.0579 13.0645 15.853Z"
                fill="#804B24"
            />
            <path
                d="M18.9159 13.3422C18.5242 13.3431 18.1321 13.3542 17.7404 13.3505L16.5654 13.3528L15.3909 13.3689C14.9997 13.3712 14.6075 13.3735 14.2177 13.3892H14.2145C13.8293 13.3827 13.4303 13.5144 13.1527 13.7903C12.8697 14.0633 12.7362 14.4607 12.7183 14.8567C12.7215 14.6583 12.7427 14.4579 12.8034 14.2659C12.8651 14.0744 12.9705 13.8953 13.1136 13.7511C13.399 13.4578 13.8104 13.3123 14.2163 13.3114H14.2131C14.9978 13.2639 15.7816 13.2635 16.5654 13.2759C17.3497 13.2847 18.133 13.2994 18.9159 13.3422Z"
                fill="#804B24"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_3243_18952"
                x1="16.1006"
                y1="11.7781"
                x2="10.3878"
                y2="12.0074"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3243_18952"
                x1="9.93435"
                y1="19.6996"
                x2="9.81948"
                y2="16.6955"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3243_18952"
                x1="9.20214"
                y1="8.39069"
                x2="13.5057"
                y2="14.5436"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_3243_18952"
                x1="6.08787"
                y1="5.93924"
                x2="17.11"
                y2="6.76475"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FF8900" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_3243_18952"
                x1="5.87795"
                y1="15.7392"
                x2="20.3519"
                y2="15.7392"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FFA754" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_3243_18952"
                x1="3.54429"
                y1="15.761"
                x2="32.1391"
                y2="16.6719"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_3243_18952"
                x1="8.0879"
                y1="19.4977"
                x2="12.8168"
                y2="16.9352"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_3243_18952"
                x1="13.5046"
                y1="15.8594"
                x2="17.4479"
                y2="15.3415"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#643800" stopOpacity="0" />
                <stop offset="1" stopColor="#643800" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_3243_18952"
                x1="6.50624"
                y1="7.15381"
                x2="7.36154"
                y2="21.316"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#FFA754" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_3243_18952"
                x1="19.5983"
                y1="11.702"
                x2="14.0027"
                y2="11.702"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_3243_18952"
                x1="19.5985"
                y1="17.6157"
                x2="13.9998"
                y2="17.6157"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_3243_18952"
                x1="19.5983"
                y1="20.2658"
                x2="14.0027"
                y2="20.2658"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_3243_18952"
                x1="13.2484"
                y1="11.7296"
                x2="16.163"
                y2="11.6632"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint13_linear_3243_18952"
                x1="18.3457"
                y1="17.6549"
                x2="15.3747"
                y2="17.6549"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint14_linear_3243_18952"
                x1="18.3452"
                y1="20.2659"
                x2="15.3743"
                y2="20.2659"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint15_linear_3243_18952"
                x1="14.719"
                y1="11.6609"
                x2="13.1339"
                y2="11.6609"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FBA23B" stopOpacity="0" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint16_linear_3243_18952"
                x1="14.4338"
                y1="11.7071"
                x2="12.6108"
                y2="11.7071"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#E89043" />
            </linearGradient>
            <radialGradient
                id="paint17_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(10.0209 18.527) rotate(23.5251) scale(2.22542 1.03324)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <radialGradient
                id="paint18_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16.7537 11.7157) rotate(2.02718) scale(1.90411 1.24973)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" />
            </radialGradient>
            <radialGradient
                id="paint19_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(16.662 17.8518) rotate(-0.0544055) scale(2.0449 1.10236)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <radialGradient
                id="paint20_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(15.9546 20.3298) rotate(-0.0539601) scale(1.87227 1.17181)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint21_linear_3243_18952"
                x1="14.9089"
                y1="17.5838"
                x2="12.6115"
                y2="17.5838"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FBA23B" stopOpacity="0" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint22_linear_3243_18952"
                x1="14.4205"
                y1="17.5036"
                x2="13.1829"
                y2="17.5036"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint23_linear_3243_18952"
                x1="14.826"
                y1="20.2809"
                x2="12.1392"
                y2="19.7875"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FBA23B" stopOpacity="0" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint24_linear_3243_18952"
                x1="14.5482"
                y1="20.1931"
                x2="13.4125"
                y2="20.1931"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint25_linear_3243_18952"
                x1="16.3584"
                y1="11.3494"
                x2="17.0807"
                y2="14.8447"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint26_linear_3243_18952"
                x1="16.9075"
                y1="18.2552"
                x2="16.1195"
                y2="15.4121"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint27_linear_3243_18952"
                x1="19.5988"
                y1="14.9005"
                x2="14.0032"
                y2="14.9005"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FFD748" />
            </linearGradient>
            <linearGradient
                id="paint28_linear_3243_18952"
                x1="13.7773"
                y1="14.7447"
                x2="18.0637"
                y2="14.9012"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFCB4B" />
                <stop offset="0.7382" stopColor="#FFD748" />
                <stop offset="1" stopColor="#FFCB4B" />
            </linearGradient>
            <linearGradient
                id="paint29_linear_3243_18952"
                x1="15.4501"
                y1="14.8427"
                x2="12.2477"
                y2="14.8427"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FBA23B" stopOpacity="0" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <radialGradient
                id="paint30_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(17.3096 14.8492) rotate(-2.73606) scale(2.09385 1.37433)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <linearGradient
                id="paint31_linear_3243_18952"
                x1="13.9616"
                y1="14.8391"
                x2="12.9505"
                y2="14.8391"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.00132565" stopColor="#FFBC47" />
                <stop offset="1" stopColor="#FBA23B" />
            </linearGradient>
            <linearGradient
                id="paint32_linear_3243_18952"
                x1="16.6242"
                y1="14.5308"
                x2="17.2324"
                y2="18.3966"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint33_linear_3243_18952"
                x1="16.2029"
                y1="17.5215"
                x2="17.7612"
                y2="20.9024"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint34_linear_3243_18952"
                x1="15.8928"
                y1="20.4871"
                x2="16.1208"
                y2="22.6242"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint35_linear_3243_18952"
                x1="15.946"
                y1="20.1632"
                x2="15.946"
                y2="18.6173"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint36_linear_3243_18952"
                x1="5.5093"
                y1="15.8953"
                x2="0.664146"
                y2="23.2163"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#C86F34" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint37_linear_3243_18952"
                x1="5.18955"
                y1="17.5147"
                x2="5.98779"
                y2="21.0764"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <linearGradient
                id="paint38_linear_3243_18952"
                x1="5.67259"
                y1="18.1828"
                x2="5.55853"
                y2="22.5425"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFBC47" stopOpacity="0" />
                <stop offset="1" stopColor="#C86F34" />
            </linearGradient>
            <radialGradient
                id="paint39_radial_3243_18952"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(12.4335 4.34336) rotate(79.0714) scale(2.00769 1.17776)"
            >
                <stop stopColor="#FBE07A" />
                <stop offset="1" stopColor="#FFD748" stopOpacity="0" />
            </radialGradient>
            <clipPath id="clip0_3243_18952">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </EmojiIconSvg>
);
