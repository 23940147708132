import React, { FC, lazy, memo, Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLinks } from 'hooks/useLinks';
import { Search } from 'widgets/search';
import { ContextMenuButton } from 'widgets/context-menu';
import { HeaderNotificationsButtonTooltip } from 'widgets/header-notifications';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { HamburgerIcon } from 'components/icons2/HamburgerIcon';
import { HeartOutlineIcon } from 'components/icons2/HeartOutlineIcon';

import { NavigationMenuUIProps } from '../types';
import { getCountries } from '../utils';
import * as Styled from '../styled';

import { Logo } from './logo';
import { DesktopLinks } from './desktop-links';
import { Overlay } from './overlay';
import { MobileLinks } from './mobile-links';

const Support = lazy(() =>
    import(/* webpackChunkName: "support" */ './support').then((module) => ({ default: module.Support })),
);

const Success = lazy(() =>
    import(/* webpackChunkName: "success" */ './support/Success').then((module) => ({ default: module.Success })),
);

export const NavigationMenuUI: FC<NavigationMenuUIProps> = memo((props) => {
    const {
        registration,
        handleContextMenuClick,
        contactSupport,
        logoElement,
        texts,
        currentUser,
        withSearch = true,
        onCloseSupport,
        onSuccessSupport,
        onClickMobileHamburger,
        onCloseSuccess,
        email,
        onCloseMobileLinks,
        isShowSupport = false,
        isMobile = false,
        withBgColorMobile = false,
        withBottomIndentDividerMobile = true,
        isShowFavorites = false,
        isShowSuccess = false,
        isShowMobileMenu = false,
        contextMenuButtonIconComponent,
        isShowShopV2 = false,
        isHomePage,
    } = props;

    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const [isFocusedSearch, setIsFocusedSearch] = useState(false);

    const handleRegister = useCallback(() => {
        registration?.();
    }, [registration]);

    const isAuthUser = currentUser?.isAuth || false;

    const isRenderDesktopNotifications = isAuthUser && !isMobile;

    const isShowFavoritesBtn = isShowFavorites && isAuthUser;

    const isShowRegisterBtn = !isMobile && !isAuthUser;

    const supportName = currentUser?.displayName ?? '';

    const registerButtonText = texts?.registerButton || 'Register';

    const navigate = useNavigate();
    const links = useLinks();

    const handleFavoritesClicked = useCallback(() => {
        if (isHomePage) {
            navigate(links.homeFavorites());
        } else {
            window.location.href = links.homeFavorites.withBaseUrl();
        }
    }, [isHomePage, links, navigate]);

    return (
        <>
            <Styled.NavigationMenuWrapper id="navigation-menu" withBgColorMobile={withBgColorMobile}>
                <Styled.ContentBox>
                    <Logo logoElement={logoElement} />

                    {isMobile ? (
                        <Styled.MobileMenuButtonWrapper>
                            <ButtonIcon
                                kind={ButtonIconKinds.GhostSecondary}
                                icon={{ component: HamburgerIcon }}
                                onClick={onClickMobileHamburger}
                                className="autotest__navigation__buttons_links_mobile"
                            />
                        </Styled.MobileMenuButtonWrapper>
                    ) : (
                        <DesktopLinks isShowShopV2={isShowShopV2} />
                    )}

                    {withSearch && (
                        <Styled.SearchWrapper>
                            <Search
                                visible={isSearchVisible}
                                onVisibleChanged={setIsSearchVisible}
                                onFocusChanged={setIsFocusedSearch}
                            />
                        </Styled.SearchWrapper>
                    )}

                    {isRenderDesktopNotifications && (
                        <Styled.NotificationsWrapper>
                            <HeaderNotificationsButtonTooltip />
                        </Styled.NotificationsWrapper>
                    )}

                    {isShowFavoritesBtn && (
                        <Styled.FavoritesButtonStyled
                            size="L"
                            kind={ButtonIconKinds.GhostSecondary}
                            icon={{ component: HeartOutlineIcon }}
                            onClick={handleFavoritesClicked}
                        />
                    )}

                    {handleContextMenuClick && (
                        <ContextMenuButton
                            getCountries={getCountries}
                            onClick={handleContextMenuClick}
                            iconComponent={contextMenuButtonIconComponent}
                        />
                    )}

                    {isShowRegisterBtn && (
                        <Styled.RegisterButton
                            kind="primary"
                            size="small"
                            onClick={handleRegister}
                            className="autotest__navigation__buttons_join"
                        >
                            {registerButtonText}
                        </Styled.RegisterButton>
                    )}
                </Styled.ContentBox>
            </Styled.NavigationMenuWrapper>

            <Overlay show={isSearchVisible || isFocusedSearch} />

            <Styled.Divider withBottomIndentDividerMobile={withBottomIndentDividerMobile} />

            <Styled.DividerCover />

            {isMobile && isShowMobileMenu && <MobileLinks onClose={onCloseMobileLinks} isShowShopV2={isShowShopV2} />}

            {isShowSupport && contactSupport && (
                <Suspense fallback={null}>
                    <Support
                        contactSupport={contactSupport}
                        name={supportName}
                        email={email}
                        onClose={onCloseSupport}
                        onSuccess={onSuccessSupport}
                    />
                </Suspense>
            )}

            {isShowSuccess ? (
                <Suspense fallback={null}>
                    <Success email={email} onClose={onCloseSuccess} />
                </Suspense>
            ) : null}
        </>
    );
});
