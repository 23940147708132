import styled, { createGlobalStyle, css } from 'styled-components';
import Tippy from '@tippy.js/react';

import { animations, mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';
import { ItemHrefWrapper } from 'components/list/styled';
import { List } from 'components/list';
import { LoadingRingIcon } from 'components/icons2/LoadingRingIcon';
import { TimesIcon } from 'components/icons2/TimesIcon';
import { Box } from 'components/popover/styled';

export const containerWrapperStyle = css`
    margin: 0;
    padding: 0;

    list-style: none;
    a {
        text-decoration: none;
    }
`;

export const avatarStyle = css`
    display: flex;
    flex-shrink: 0;
    margin-right: 12px;

    width: 32px;
    height: 32px;
    border-radius: 6px;

    background-color: ${({ theme }) => theme.fillIn.secondary.muted};

    img {
        width: 32px;
        height: 32px;
        border-radius: 6px;
    }
`;

export const StyledList: typeof List = styled(List)`
    max-height: 512px;

    ${mediaQuery.lt768} {
        max-height: calc(100vh - 104px);
    }

    & ${ItemHrefWrapper} {
        padding: 0;
    }
`;

export const LoadingIcon = styled(LoadingRingIcon)`
    animation: ${animations.spin} 0.5s infinite ease-in-out;
`;

export const SearchWrapper = styled.div`
    display: flex;
    position: relative;
    flex-grow: 1;
`;

export const SearchMagnifierBox = styled.div`
    display: flex;
    color: ${({ theme }) => theme.label.placeholder};
`;

export const SearchMagnifierBoxMobile = styled.div`
    display: flex;
    color: ${({ theme }) => theme.label.placeholder};
`;

export const CloseIcon = styled(TimesIcon)`
    display: flex;
    cursor: pointer;
    margin-left: auto;

    color: ${({ theme }) => theme.label.secondary};
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;

export const SearchInput = styled.input`
    width: 100%;
    background-color: transparent;
    outline: none;
    border: 0;
    box-shadow: none;
    padding: 0;
    height: 20px;
    box-sizing: border-box;

    color: ${({ theme }) => theme.label.primary};

    ${body14RegularFontStyle}
`;

export const ResultsContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;

    ${Box} {
        top: calc(100% + 22px);
        z-index: 999;
        overflow: hidden;

        ${mediaQuery.lt768} {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            border-radius: 0;
            box-shadow: none;
        }
    }
`;

export const StyledTippy = styled(Tippy)`
    background-color: transparent;
    padding: 0;
    text-align: left;
    border-radius: 0;
`;

export const DesktopSearchInputWrapper = styled.div`
    & > label {
        width: 320px;
    }

    & > button {
        float: right;
    }
`;

export const GlobalStyle = createGlobalStyle`
    ${mediaQuery.lt768} {
        body {
            overflow: hidden;
        }
    }
`;
