import React from 'react';

import Dic from 'services/dictionary';
import { useLinks } from 'hooks/useLinks';
import { Button, LinkButton } from 'components/button2';

import { Wrapper, Title, Text } from './styled/NoData';

type Props = {
    title: string;
    type?: 'showFeed' | 'feedStatus';
    action?: () => void;
};

export const NoData = (props: Props) => {
    const { title, action, type = 'showFeed' } = props;
    const handleAction = () => action?.();
    const links = useLinks();
    return (
        <Wrapper>
            <Title>{title}</Title>
            {type === 'showFeed' && (
                <>
                    <Text>{Dic.word('wt_profile__widget_subscribers__no_data_show_feed_text')}</Text>
                    <LinkButton href={links.feed()} size="small">
                        {Dic.word('wt_profile__widget_subscribers__no_data_show_feed_link')}
                    </LinkButton>
                </>
            )}
            {type === 'feedStatus' && (
                <>
                    <Text>{Dic.word('wt_profile__widget_subscribers__no_data_feed_status_text')}</Text>
                    <Button size="small" onClick={handleAction}>
                        {Dic.word('wt_profile__widget_subscribers__no_data_feed_status_btn')}
                    </Button>
                </>
            )}
        </Wrapper>
    );
};
