import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';

const axiosClientConfig: AxiosRequestConfig = {
    baseURL: '/',
    responseType: 'json',
};

/** A unified HTTP client */
export const backendClient = axios.create(axiosClientConfig);

export type Response<T> = AxiosResponse<T>;

// MARK: - Errors

export const isCancelRequestError = axios.isCancel;
export const isHTTPError = axios.isAxiosError;

// MARK: - Cancellation

export type CancelCallback = (cancel: () => void) => void;
export type CancelationWrapper = { signal?: AbortSignal; cancelToken?: CancelToken };

/**
 * Helper for getting an abort signal and a cancel function.
 *
 * @example
 * let cancel;
 *
 * const ac = withAbortController((cancelFn) => (cancel = cancelFn));
 * backendClient.post('...', data, { signal: ac.signal });
 *
 * cancel(); // cancels the request
 *
 */
export function withAbortController(cancelCb?: CancelCallback): CancelationWrapper {
    if (window.AbortController) {
        const abortController = new AbortController();
        cancelCb?.(() => abortController.abort());
        return { signal: abortController.signal };
    }

    const cancelTokenSource = axios.CancelToken.source();
    cancelCb?.(() => cancelTokenSource.cancel());
    return { cancelToken: cancelTokenSource.token };
}

// MARK: - Interceptors

backendClient.interceptors.request.use(async (config) => {
    if (process.env.IS_STORYBOOK_BUILD) {
        return config;
    }

    if (window.WT.Page) {
        const tokens = await window.WT.Page.getTokens?.();

        if (tokens?.token) {
            if (!config.headers) config.headers = {};
            config.headers['X-User-Token'] = tokens.token;
        }
    }
    const matchedUrl = window.preFetches
        ? Object.keys(window.preFetches).find((url) => {
              const { protocol, host, pathname, search } = new URL(url, window.location.href);
              if (`${protocol}//${host}${pathname}` !== config.url) {
                  return false;
              }
              const cachedUrlParams = fromPairs(
                  Array.from(new URLSearchParams(search).entries()).filter(([key]) => key !== 'jsonpCallback'),
              );
              const requestParams = fromPairs(
                  Object.entries(config.params)
                      .filter(([, value]) => value !== undefined)
                      .map(([key, value]) => [key, `${value}`]),
              );
              return isEqual(cachedUrlParams, requestParams);
          })
        : null;

    if (matchedUrl) {
        try {
            const response = await window.preFetches[matchedUrl];
            if (response) {
                const cached = typeof response.json === 'function' ? await response.json() : await response.text();
                config.data = cached;
                config.adapter = () =>
                    Promise.resolve({
                        data: cached,
                        status: 200,
                        statusText: 'OK',
                        headers: {},
                        config,
                        request: config,
                    });
            }
        } catch {
            // ignore
        }
        delete window.preFetches[matchedUrl];
    }

    return config;
});

backendClient.interceptors.response.use((response: AxiosResponse<{ result?: Record<string, unknown> }>) => {
    if (response.data?.result && response.data?.result?.ok) {
        response.data = response.data?.result;
    }

    return response;
});
