import styled from 'styled-components';

import { ActivityWrapper } from '../../../activity';

export const PublishStatusContainer = styled.div<{ highlighted?: boolean }>`
    ${({ highlighted, theme }) => highlighted && `box-shadow: 0px 0px 0px 2px ${theme.fillIn.primary.mid};`}
    border-radius: 8px;

    transition: all 0.3s ease-in-out;
    transition-property: box-shadow;
`;

export const DeletedRecordWrapper = styled(ActivityWrapper)`
    padding-left: 24px;
    padding-right: 24px;
`;

export const DeletedRecordRestore = styled.span`
    cursor: pointer;
    ${({ theme: { label } }) => `
        color: ${label.accent};
        &:hover {
            color: ${label.accentHigh};
        }
    `}
`;
