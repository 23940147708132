import { useCallback, useState } from 'react';

/** Работает со cтеком как с immutable структурой данных.
 * @param initial - начальное содержимое стека.
 * @return [
 *              current - текущий элемент на верхушке стека или null если стек пустой.
 *              stack - весь массив элементов как readonly.
 *              push - добавляет элемент в стек
 *              pop - извлекает элемент из стека
 *              reset - сбрасывает содержимое стека на переданный массив
 *         ]
 * */
export function useStack<T>(
    initial: T[],
): [T | null, ReadonlyArray<T>, (item: T) => void, () => T | null, (newItems: T[]) => void] {
    const [stack, setStack] = useState<T[]>(initial);

    const current = stack[stack.length - 1] ?? null;

    const push = useCallback((item: T) => {
        setStack((prev) => [...prev, item]);
    }, []);

    const pop = useCallback(() => {
        setStack((prev) => {
            if (prev.length > 0) {
                return prev.slice(0, prev.length - 1);
            }
            return prev;
        });
        return current;
    }, [current]);

    const reset = useCallback((newItems: T[]) => {
        setStack(newItems);
    }, []);

    return [current, stack, push, pop, reset];
}
