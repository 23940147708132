import { TypeProductItem } from 'types/product';

export const defaultProductTypeMapByProductTypeFromBackend: Record<string, TypeProductItem> = {
    product: 'application',
    course: 'course',
    service: 'service',
} as const;

// This is temporarily hard-coded from #WTT-9909
export const COMON_ENTITY = 'Comon';
