import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { size } from 'polished';

import { mediaQuery } from 'app/styled';
import { h4SemiboldFontStyle, body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';
import { getHeight, getMargin, getTabletMargin } from 'widgets/navigation-menu/getHeight';
import { UserName } from 'components/user-name2';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { EditIcon } from 'components/icons2/EditIcon';
import { NameText } from 'components/user-name2/styled';

import { Wrapper as FollowersWrapper } from './Followers';

export const ActiveAvatarWrapper = styled.div``;

export const ContextActionsWrapper = styled.div`
    height: 40px;
`;

export const StyledUserName = styled(UserName)`
    display: inline-flex;
    margin-top: 12px;

    ${NameText} {
        ${h4SemiboldFontStyle};
    }

    ${mediaQuery.lt768} {
        margin-top: 8px;
    }
`;

export const Description = styled.div`
    margin-top: 8px;
    word-break: break-word;
    white-space: pre-line;

    ${mediaQuery.lt768} {
        margin-top: 4px;
    }
`;

// особое указание от дизайнеров убрать отступ
export const AddDescriptionButton = styled(ButtonIcon).attrs({
    icon: { component: EditIcon },
    kind: ButtonIconKinds.GhostPrimary,
    size: { default: 'S', lt768: 'M' },
})`
    & svg {
        margin-left: 0;
    }
`;

export const Site = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    svg {
        margin-right: 4px;
        min-width: 16px;
        color: ${({ theme }) => theme.fillIn.secondary.high};
    }
    a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    margin-top: 8px;
    ${mediaQuery.lt768} {
        margin-top: 12px;
    }
`;

export const Networks = styled.div`
    margin-top: 8px;
    margin-left: -8px;
    ${mediaQuery.lt768} {
        margin-top: 12px;
    }
`;

export const Actions = styled.div`
    button,
    span {
        display: block;
        width: 100%;
    }
    margin-top: 16px;
    ${mediaQuery.lt768} {
        margin-top: 20px;
    }
`;

export const Wrapper = styled.div`
    ${mediaQuery.lt768} {
        border-radius: 12px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: ${({ theme }) => theme.bg.primary};
    }
`;

export const UserDataWrapper = styled.div`
    border-radius: 12px;
    background-color: ${({ theme }) => theme.bg.primary};
    padding: 16px;
    box-sizing: border-box;
    ${body14RegularFontStyle};

    ${size('100%')};
    top: var(--relative-top);
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas:
        'avatar contextmenu'
        'username username'
        'followers followers'
        'description description'
        'site site'
        'networks networks'
        'actions actions';
    & > ${ActiveAvatarWrapper} {
        grid-area: avatar;
    }
    & > ${ContextActionsWrapper} {
        grid-area: contextmenu;
    }
    & > ${StyledUserName} {
        grid-area: username;
    }
    & > ${FollowersWrapper} {
        grid-area: followers;
    }
    & > ${Description} {
        grid-area: description;
    }
    & > ${Site} {
        grid-area: site;
    }
    & > ${Networks} {
        grid-area: networks;
    }
    & > ${Actions} {
        grid-area: actions;
    }
    ${mediaQuery.lt768} {
        ${caption12RegularFontStyle};
        width: 100%;
        padding: 20px 16px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        align-items: end;
        grid-template-areas:
            'avatar contextmenu'
            'avatar followers'
            'username username'
            'description description'
            'site site'
            'networks networks'
            'actions actions';
        background-color: initial;
    }
`;

export const BannerMonetizationWrapper = styled.div`
    top: var(--relative-top);
    position: relative;
    margin-top: 16px;
    ${mediaQuery.lt768} {
        margin-top: 0;
        padding: 0 16px 20px;
    }
`;

export const StickyContainer = styled.div<{ isOwner: boolean; hasCover: boolean }>`
    top: calc(${getHeight() + getMargin()}px);

    ${mediaQuery.lt960} {
        top: calc(${getHeight() + getTabletMargin()}px);
    }

    position: sticky;
    // TODO из-за этого все дочерние компоненты не смогут использовать z-index выше 3, а значит popup не перекроют wt-menu
    z-index: 3;
    // не убирать, sticky работает только с >1 доч. элементами
    &:after {
        content: '';
    }
    ${mediaQuery.lt768} {
        position: static;
        ${ifProp(
            { isOwner: false, hasCover: false },
            css`
                ${ActiveAvatarWrapper} {
                    position: static;
                }
                ${ContextActionsWrapper} {
                    display: flex;
                    justify-content: end;
                    align-items: flex-start;
                    height: 100%;
                }
            `,
            css`
                ${ContextActionsWrapper} {
                    display: none;
                }
            `,
        )};
    }
`;
