import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AnyObject } from 'types';
import { injectReducer } from 'app/store2';

import {
    getSearchResults,
    reset,
    searchResultClickLog,
    updateSearchResult,
    setActiveTab,
    GetSearchResults,
    Reset,
    UpdateSearchResult,
    SearchResultClickLog,
} from './actions';
import { GlobalStateSearch, SearchTabs } from './interfaces';
import reducer from './reducer';
import { SearchComponent } from './components';

injectReducer('widgets.search', reducer('search'));

const mapStateToProps = (state: GlobalStateSearch) => ({
    searchResult: state.widgets.search.searchResult,
    loadingTabs: state.widgets.search.loadingTabs,
    activeTab: state.widgets.search.activeTab,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalStateSearch, AnyObject, AnyAction>) => ({
    getSearchResults: (...args: Parameters<GetSearchResults>) => dispatch(getSearchResults(...args)),
    updateSearchResult: (...args: Parameters<UpdateSearchResult>) => dispatch(updateSearchResult(...args)),
    searchResultClickLog: (...args: Parameters<SearchResultClickLog>) => dispatch(searchResultClickLog(...args)),
    reset: (...args: Parameters<Reset>) => dispatch(reset(...args)),
    setActiveTab: (tab: SearchTabs) => dispatch(setActiveTab(tab, 'search')),
});

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
