import { FinamRichSignalsProduct } from 'types/finam-product';

import { Step3FormValues, Step4FormValues } from '../steps/copy-trading-strategies';
import { Step1FormValues } from '../steps/signals-trading-strategy';
import { toFormValues as step1ToFormValues } from '../steps/signals-trading-strategy/step1/toFormValues';
import { toFormValues as step3ToFormValues } from '../steps/copy-trading-strategies/step3/toFormValues';
import { toFormValues as step4ToFormValues } from '../steps/copy-trading-strategies/step4/toFormValues';
import { featureTitles } from '../wizard/constants';

export function getUpdateSignalStrategyFormValues(data: FinamRichSignalsProduct): {
    step1FormValues: Step1FormValues;
    step3FormValues: Step3FormValues;
    step4FormValues: Step4FormValues;
} {
    function getFeature(featureName: keyof typeof featureTitles) {
        return data.keyFeatures?.find(({ title }) => title === featureTitles[featureName])?.description ?? '';
    }

    const step1FormValues = step1ToFormValues({
        riskLevel: data.riskLevel,
        targetReturnAnnualized: data.targetReturnAnnualized,
        recommendedHoldingPeriod: getFeature('recommendedHoldingPeriod'),
    });
    const step3FormValues = step3ToFormValues({
        name: data.name ?? '',
        description: data.description ?? '',
        generalTradingIdea: getFeature('generalTradingIdea'),
    });
    const step4FormValues = step4ToFormValues({
        tradingMethod: getFeature('tradingMethod'),
        cashAndLeverage: getFeature('cashAndLeverage'),
        riskManagement: getFeature('riskManagement'),
        additionalInformation: getFeature('additionalInformation'),
    });
    return {
        step1FormValues,
        step3FormValues,
        step4FormValues,
    };
}
