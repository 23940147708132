import React, { FC, memo } from 'react';

import { RightGroupProps } from './types';
import { IconButton, Lock, RightGroupWrapper, RightLabel } from './styled';

export const RightGroup: FC<RightGroupProps> = memo(
    ({ rightLabel, icon: Icon, readonly = false, disabled = false, onLabelClick, onIconClick, iconButtonTabIndex }) => (
        <RightGroupWrapper readonly={readonly}>
            <>
                {rightLabel ? (
                    <RightLabel disabled={disabled} onClick={onLabelClick}>
                        {rightLabel}
                    </RightLabel>
                ) : null}
                {Icon ? (
                    <IconButton disabled={disabled} onClick={onIconClick} tabIndex={iconButtonTabIndex}>
                        <Icon />
                    </IconButton>
                ) : null}
                {readonly ? <Lock /> : null}
            </>
        </RightGroupWrapper>
    ),
);
