import { FeedRecord, isPublishStatusRecord, Person } from 'types';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const useIsOwner = (recordOrAuthor?: FeedRecord | Person) => {
    const user = useCurrentUser();
    if (!recordOrAuthor) return false;
    if (!('displayName' in recordOrAuthor) && !isPublishStatusRecord(recordOrAuthor)) return false;
    const author = 'displayName' in recordOrAuthor ? recordOrAuthor : recordOrAuthor.obj.author;
    return author ? user.isSame(author) : false;
};
