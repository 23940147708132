import React from 'react';
import cc from 'classcat';

import { getUrlByUser } from 'components/avatar-user/utils';
import { AvatarUser } from 'components/avatar-user';

import { SecondLine } from './components/SecondLine';
import { UserBlockProps } from './types';
import * as Styled from './styled/UserBlock.styled';

export const UserBlock = React.memo((props: UserBlockProps) => {
    const {
        user,
        avatar,
        secondLine,
        className: classNameProp,
        size = 'regular',
        buildUrl,
        showTooltip = true,
        userNameProps = { kind: 'default' },
        isLoading = false,
    } = props;

    const url = getUrlByUser({ user, buildUrl });

    const className = cc(['autotest__user-block', classNameProp]);

    const avatarClassName = cc(['autotest__user-block__avatar-user', avatar?.className]);

    const isShowInitials = avatar?.isShowInitials || false;
    const isAvatarHiddenOnEmptySrc = avatar?.isHiddenOnEmptySrc || false;
    const isNoRenderAvatar = isAvatarHiddenOnEmptySrc && !avatar?.src && !user?.image;

    return (
        <Styled.UserBlockWrapper className={className} isLoading={isLoading}>
            {!isNoRenderAvatar && (
                <Styled.AvatarWrapper>
                    <AvatarUser
                        target="_blank"
                        rel="noreferrer"
                        href={url}
                        {...avatar}
                        isLoading={isLoading}
                        isShowInitials={isShowInitials}
                        user={user}
                        className={avatarClassName}
                        useWhiteSubstrate={false}
                    />
                </Styled.AvatarWrapper>
            )}
            <Styled.Details size={size} isNoRenderAvatar={isNoRenderAvatar}>
                <Styled.UserName
                    url={url}
                    isVerified={user?.isVerified}
                    isVerifiedAuthor={user?.isVerifiedAuthor}
                    displayName={user?.displayName || ''}
                    useDct={showTooltip}
                    className="autotest__user-block__user-name"
                    size={size}
                    {...userNameProps}
                    isLoading={isLoading}
                />
                <SecondLine secondLine={secondLine} isLoading={isLoading} size={size} />
            </Styled.Details>
        </Styled.UserBlockWrapper>
    );
});
