import React, { ReactNode } from 'react';

import { ResponsiveMap } from 'app/styled';
import { RoundButton } from 'components/round-button';
import { CameraIcon } from 'components/icons2/CameraIcon';
import { AvatarImplementationProps } from 'components/avatar/types';

import * as Styled from './styled/ActiveAvatar.styled';

type Props = Required<Pick<AvatarImplementationProps, 'src' | 'size' | 'alt'>> & {
    isActive?: 'yes' | 'no' | ResponsiveMap<'yes' | 'no'>;
    isActiveOnHover?: boolean;
    icon?: ReactNode;
    onSubstrateClick?: () => void;
    isProcessing?: boolean;
    className?: string;
    hasOffset?: boolean;
};

export const ActiveAvatar = (props: Props) => {
    const {
        alt,
        src,
        size,
        isActive = 'no',
        isActiveOnHover = false,
        icon = <CameraIcon />,
        onSubstrateClick = () => undefined,
        isProcessing = false,
        className = '',
        hasOffset = true,
    } = props;
    return (
        <Styled.ActiveAvatarWrapper
            isActive={isActive}
            isActiveOnHover={isActiveOnHover}
            className={className}
            hasOffset={hasOffset}
        >
            <Styled.AvatarBlock src={src} alt={alt || 'avatar'} size={size} />
            <Styled.Substrate onClick={onSubstrateClick}>
                <RoundButton kind="staticColor" isProcessing={isProcessing} pressed>
                    {icon}
                </RoundButton>
            </Styled.Substrate>
        </Styled.ActiveAvatarWrapper>
    );
};
