import { createContext, createElement, useMemo, useState, ReactNode, useContext } from 'react';

// MARK: - Types

type MutableContextType<Value extends Record<string, unknown>> = [
    contextValue: Value,
    setContextSlice: (contextSlice: Partial<Value>) => void,
];

type MutableContextProviderProps<ContextValue> = {
    initialValue: ContextValue;
    setContextSliceSetter?: (setContextSlice: (contextSlice: Partial<ContextValue>) => void) => void;
    children?: ReactNode;
};

// MARK: - Main Function

export function createMutableContext<ContextValue extends Record<string, unknown>>(defaultValue: ContextValue) {
    type ContextType = MutableContextType<ContextValue>;

    const Context = createContext<ContextType>([defaultValue, () => undefined]);

    const Provider = ({ initialValue, setContextSliceSetter, children }: MutableContextProviderProps<ContextValue>) => {
        const [contextValue, setContextValue] = useState(initialValue);

        const memoizedContextValue: ContextType = useMemo(
            () => [
                contextValue,
                (contextSlice: Partial<ContextValue>) =>
                    setContextValue((prev) => ({
                        ...prev,
                        ...contextSlice,
                    })),
            ],
            [contextValue],
        );

        if (setContextSliceSetter) setContextSliceSetter(memoizedContextValue[1]);

        return createElement(
            Context.Provider,
            {
                value: memoizedContextValue,
            },
            children,
        );
    };

    const useMutableContextHook = () => useContext(Context);

    return {
        Context,
        Provider,
        hook: useMutableContextHook,
    };
}
