import React from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { ShareIcon } from 'components/icons2/ShareIcon';

type Props = {
    onClick: () => void;
};

export const ActionShare = (props: Props) => {
    const { onClick } = props;
    return (
        <MenuItem
            text={Dic.word('wt_profile__widget_header_user__share_context_item')}
            icon={{ component: ShareIcon }}
            onClick={onClick}
            className="autotest__profile-page__header-context-actions-share"
        />
    );
};
