import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Action } from 'types';
import { productTypesList } from 'types/finam-product';
import notify from 'services/notify';
import { api } from 'services/api-builder/api';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { useDictionary } from 'hooks/useDictionary';
import { VerticalFormInputGroup } from 'components/form';
import { FormListBox } from 'components/form/react-hook-form';

import { useFormStateHandler } from '../../../hooks/useFormStateHandler';
import { StepProps } from '../types';
import { Label } from '../styled';

import { useSignAgreement } from './useSignAgreement';
import { defaultValues } from './constants';
import { toData } from './toData';
import { Step0FormValues } from './types';

const mapToAutoclass = {
    UsStrategy: 'LimexCopyTrading',
    UsUserProduct: 'ToolsForTradersAndInvestors',
    UsSignal: 'SignalsTradingStrategy',
};

export const Step0: FC<
    StepProps<Step0FormValues> & {
        getAgreements: Action<typeof api.fetchAgreements>;
        createAgreement: Action<typeof api.createAgreement>;
        createSubscriberAgreement: Action<typeof api.createSubscriberAgreement>;
        updateAgreementStatus: Action<typeof api.updateAgreementStatus>;
    }
> = memo((props) => {
    const { getAgreements, createAgreement, createSubscriberAgreement, updateAgreementStatus } = props;
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step0_');

    const types = useMemo(
        () =>
            productTypesList.map((typeName) => ({
                id: typeName,
                text: dic.word(`wt_all__widget_myspace_product_wizard__step1_field_type_value_${typeName}`),
                autotestSuffix: mapToAutoclass[typeName],
            })),
        [dic],
    );

    const form = useForm<Step0FormValues>({
        mode: 'onTouched',
        defaultValues,
    });

    const [{ error }, handleGetAgreements] = useSignAgreement(
        getAgreements,
        createAgreement,
        createSubscriberAgreement,
        updateAgreementStatus,
    );

    const customValidation = useCallback(
        async (values: Step0FormValues) => {
            if (values.productType === 'UsStrategy') {
                await handleGetAgreements('Author');
            }
        },
        [handleGetAgreements],
    );

    useFormStateHandler({
        form,
        props,
        toData,
        title: dicWord('title'),
        customValidation,
    });

    useEffect(() => {
        if (error) {
            notify.error(dic.word('wt_all__widget_product_wizard__agreement_error'));
        }
    }, [dic, error]);

    const required = dic.word('wt_all__widget_product_wizard__field_required');

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                <Label>{dicWord('label')}</Label>
                <div>
                    <FormListBox
                        name="productType"
                        placeholder={dicWord('type')}
                        kind="inline-label-outline"
                        size="medium"
                        className="autotest__marketplace__product_wizard__product_type"
                        options={types}
                        autotestOptionPrefix="autotest__marketplace__product_wizard__product_type_"
                        rules={{ required }}
                    />
                </div>
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
