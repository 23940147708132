import { connect } from 'react-redux';

import { api } from 'services/api-builder/api';
import { GlobalStateForCache } from 'services/api-builder/types';
import { contactSupport } from 'services/mp-share-resources/subscriptions/actions/contactSupport';

import { BrokerageAccountPopupComponent } from './components';

export const mapStateToProps = (state: GlobalStateForCache) => ({
    ...api.selectSelectedStrategyAutofollowAccounts(state),
});

export const mapDispatchToProps = {
    getAutofollowAccounts: api.fetchSelectedStrategyAutofollowAccounts,
    subscribeAutofollow: api.subscribeAutofollow,
    getAgreements: api.fetchAgreements,
    createAgreement: api.createAgreement,
    createSubscriberAgreement: api.createSubscriberAgreement,
    updateAgreementStatus: api.updateAgreementStatus,
    contactSupport,
};

export const BrokerageAccountPopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrokerageAccountPopupComponent);
