import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const ArtIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path
            d="M19.1656 19.375H0.834375C0.71875 19.375 0.625 19.2812 0.625 19.1656V0.834375C0.625 0.71875 0.71875 0.625 0.834375 0.625H19.1656C19.2812 0.625 19.375 0.71875 19.375 0.834375V19.1656C19.375 19.2812 19.2812 19.375 19.1656 19.375Z"
            fill="#F2A600"
        />
        <path d="M16.6765 3.32188H3.32178V16.6766H16.6765V3.32188Z" fill="white" />
        <path
            opacity="0.5"
            d="M19.1657 0.625H0.834473C0.77666 0.625 0.725098 0.648437 0.686035 0.685937L3.32197 3.32188H16.6767L19.3126 0.685937C19.2751 0.648437 19.2235 0.625 19.1657 0.625Z"
            fill="#D1762C"
        />
        <path
            d="M3.32188 3.32187L0.685937 0.685936C0.648437 0.724998 0.625 0.776561 0.625 0.834374V19.1656C0.625 19.2234 0.648437 19.2766 0.685937 19.3141L3.32188 16.6781V3.32187Z"
            fill="#DE7340"
        />
        <path
            d="M17.8906 2.10938V17.8906H2.10938V2.10938H17.8906ZM18.8281 1.17188H1.17188V18.8281H18.8281V1.17188Z"
            fill="#FFCD40"
        />
        <path d="M2.10938 17.8906V2.10938L1.17188 1.17188V18.8281L2.10938 17.8906Z" fill="#A65F3E" />
        <path d="M17.8797 2.10938L18.8172 1.17188H1.17188L2.10938 2.10938H17.8797Z" fill="#D1762C" />
        <path
            d="M18.2031 18.2031H1.79688V1.79688H18.2031V18.2031ZM2.10938 17.8906H17.8906V2.10938H2.10938V17.8906Z"
            fill="#D1762C"
        />
        <path d="M2.10938 17.8906V2.10938L1.79688 1.79688V18.2031L2.10938 17.8906Z" fill="#824A34" />
        <path d="M17.8906 2.10938L18.2031 1.79688H1.79688L2.10938 2.10938H17.8906Z" fill="#A65F3E" />
        <path d="M15.7031 4.29688H4.29688V15.7031H15.7031V4.29688Z" fill="url(#paint0_linear_125_13664)" />
        <path
            d="M15.7032 9.78438C15.3595 9.88282 15.0579 10.1172 14.8751 10.425C14.847 10.4734 14.822 10.5281 14.772 10.5578C14.611 10.6172 14.4267 10.6297 14.3017 10.7703C14.2485 10.4391 13.847 10.2547 13.5392 10.3578C13.2782 10.4234 13.1485 10.6984 13.1251 10.9484C12.8501 10.8672 12.547 11.0781 12.5282 11.3641C12.5251 11.3906 12.5251 11.4203 12.511 11.4422C12.4423 11.5125 12.3032 11.5047 12.2188 11.5891C12.0517 11.7203 11.9735 11.9469 11.7954 12.0625C12.8017 11.4953 14.0188 11.575 15.1157 11.3C15.3329 11.2469 15.5751 11.1797 15.7032 10.9891V9.78438Z"
            fill="#1B5E20"
        />
        <path
            d="M15.7031 14.7797C15.7031 14.7797 13.1109 14.9141 10.2812 14.0422C7.45151 13.1719 6.15308 12.2812 6.15308 12.2812C6.15308 12.2812 8.04526 12.6484 10.5718 12.2359C12.2265 11.9656 13.4531 11.0297 15.7 10.9859L15.7031 14.7797Z"
            fill="#689F38"
        />
        <path
            d="M8.98426 12.4531C9.00614 12.4234 9.0327 12.3953 9.04207 12.3594C9.05301 12.3156 9.03582 12.2703 9.02332 12.2281C8.98895 12.1047 8.99989 11.9688 9.05145 11.85C9.07801 11.7875 9.11707 11.7313 9.13582 11.6656C9.16864 11.5531 9.13739 11.4328 9.13114 11.3156C9.12176 11.1563 9.1577 10.9953 9.2327 10.8547C9.24364 10.8344 9.25614 10.8141 9.27801 10.8031C9.33739 10.7766 9.38739 10.8594 9.39364 10.9234C9.41239 11.0859 9.37801 11.2531 9.41239 11.4141C9.46083 11.6391 9.64208 11.8344 9.62957 12.0656C9.62489 12.1484 9.5952 12.2313 9.61082 12.3109C9.62332 12.3719 9.66239 12.425 9.6952 12.4781C9.77957 12.6156 9.83114 12.7703 9.84676 12.9313C9.86551 13.1234 9.8077 13.3531 9.62801 13.4203C9.4327 13.4953 9.2327 13.3359 9.07957 13.2313C8.93895 13.1344 8.87176 12.9344 8.87645 12.7703C8.87957 12.6578 8.91551 12.5438 8.98426 12.4531Z"
            fill="#2E7D32"
        />
        <path
            d="M13.5657 8.89375C12.9453 8.93906 12.325 8.98437 11.7032 9.02969C11.3953 9.05156 11.0828 9.075 10.7797 9.01562C10.636 8.9875 10.4953 8.94062 10.3485 8.93281C10.0813 8.91875 9.8266 9.03281 9.56722 9.09844C9.28285 9.17031 8.98441 9.18437 8.69535 9.13906C8.49379 9.10781 8.28754 9.04219 8.14066 8.9C8.06722 8.82969 8.01097 8.74062 7.93285 8.67344C7.83754 8.58906 7.7141 8.54219 7.60785 8.47344C7.15785 8.18594 7.0516 7.48125 7.39691 7.07344C7.45316 7.00781 7.51879 6.94844 7.56879 6.87656C7.66566 6.73906 7.69691 6.56719 7.75472 6.40937C7.93754 5.90156 8.4141 5.51563 8.94691 5.42813C9.47972 5.34063 10.0485 5.55 10.4078 5.95312C10.525 6.08437 10.6297 6.23906 10.7922 6.30469C10.8875 6.34219 10.9938 6.34531 11.0953 6.35781C11.675 6.43125 12.161 6.87656 12.4078 7.40625C12.5172 7.64219 12.5938 7.90312 12.7766 8.08594C12.8516 8.16094 12.9422 8.22031 13.0328 8.27656C13.4391 8.52969 13.8641 8.75156 14.3032 8.94062C14.1907 8.93281 14.0797 8.92656 13.9672 8.91875"
            fill="white"
        />
        <path
            d="M11.7032 9.02969C11.3954 9.05156 11.0829 9.075 10.7797 9.01563C10.636 8.9875 10.4954 8.94063 10.3485 8.93281C10.0813 8.91875 9.82661 9.03281 9.56724 9.09844C9.28286 9.17031 8.98442 9.18438 8.69536 9.13907C8.4938 9.10782 8.28755 9.04219 8.14067 8.9C8.06724 8.82969 8.01099 8.74063 7.93286 8.67344C7.83755 8.58906 7.71411 8.54219 7.60786 8.47344C7.44536 8.36875 7.35317 8.22344 7.2688 8.05469C7.24849 8.01563 7.24849 7.97969 7.24536 7.93594C7.3813 8.14532 7.74849 8.37032 7.89536 8.20625C7.9438 8.0625 7.77192 7.8125 7.71411 7.61407C7.60474 7.23907 7.5938 6.85 7.72817 6.48282C7.7438 6.43907 7.78286 6.29688 7.83599 6.22188C7.87661 6.52657 7.98286 6.98907 8.20005 7.20625C8.41724 7.42344 8.77974 7.51563 9.04067 7.35313C9.20474 7.25 9.30161 7.07032 9.42036 6.91719C9.77036 6.46875 10.3813 6.23907 10.9407 6.34688C10.6516 6.51563 10.3704 6.72032 10.2 7.00782C10.0297 7.29532 9.98911 7.68125 10.1797 7.95782C10.3329 8.17969 10.611 8.29375 10.8797 8.28906C11.0282 8.28594 11.1954 8.23438 11.2563 8.09844C11.2907 8.02032 11.2829 7.92657 11.3219 7.85C11.3954 7.70313 11.611 7.68438 11.7563 7.75938C11.9032 7.83594 12.0032 7.975 12.1157 8.09375C12.5375 8.5375 13.161 8.71563 13.7516 8.875C13.7344 8.87031 13.6985 8.88438 13.6797 8.8875C13.6516 8.89219 13.6235 8.89688 13.5954 8.90157C13.5516 8.90938 13.5079 8.92031 13.4626 8.91406C13.3688 8.9 13.2735 8.91563 13.1797 8.92188C12.9594 8.9375 12.7375 8.95469 12.5172 8.97031C12.2469 8.98906 11.975 9.00938 11.7032 9.02969Z"
            fill="#C9E3E6"
        />
        <path
            d="M15.7031 13.4422C10.2234 14.7188 7.61875 11.5828 4.29688 11.2297V15.7031H15.7031V13.4422Z"
            fill="#8BC34A"
        />
        <path
            d="M15.7031 13.4422C10.2234 14.7188 7.61875 11.5828 4.29688 11.2297V15.7031H15.7031V13.4422Z"
            fill="url(#paint1_radial_125_13664)"
        />
        <path
            d="M5.675 12.0547C5.5125 12.1031 5.37812 11.9781 5.24219 11.9125C4.88125 11.6453 4.88125 11.1578 4.90937 10.75C4.90156 10.6531 4.97031 10.4938 5.08437 10.5625C5.0375 10.3281 5.03281 10.0859 5.06719 9.85001C5.09062 9.66719 5.15469 9.54063 5.30312 9.44688C5.3125 9.42813 5.30625 9.40625 5.30156 9.38594C5.23281 9.11719 5.35781 8.83907 5.37031 8.56094C5.38125 8.34375 5.32344 8.11875 5.39844 7.91563C5.42969 7.825 5.54375 7.775 5.56406 7.88907C5.67344 8.31719 5.8625 8.74375 5.82656 9.19375C5.81406 9.25469 5.8375 9.30469 5.8875 9.34063C6.20625 9.625 6.06406 10.1203 6.11562 10.5063C6.26562 10.8609 6.2625 11.2578 6.08437 11.6031C6.00312 11.7938 5.87187 11.9703 5.675 12.0547Z"
            fill="#2E7D32"
        />
        <path
            d="M7.83587 12.2922C7.14524 12.1266 7.11711 12.3094 6.88899 12.2563C6.10461 12.0203 6.58743 11.2563 6.81399 11.0703C6.71868 10.7063 6.96711 10.5578 7.26086 10.1984C7.30774 9.4625 8.01399 9.67657 7.92336 10.3219C8.11086 10.5656 8.17961 10.8328 8.11868 11.1375C8.13743 11.3188 8.31399 11.4375 8.3968 11.6C8.55774 11.9172 8.28743 12.4 7.83587 12.2922Z"
            fill="#689F38"
        />
        <path
            d="M7.57354 11.0953C7.49073 11.2672 7.25323 11.3031 7.06729 11.2562C6.98136 11.2344 6.89073 11.1875 6.80792 11.2187C6.61417 11.2906 6.55323 11.4469 6.54698 11.6031C6.54698 11.8125 6.75636 11.9766 6.96573 11.9859C7.17511 11.9953 7.37198 11.8859 7.52979 11.7516C7.57667 12.0328 8.01261 12.025 8.20323 11.8641C8.38448 11.7109 8.30635 11.4656 8.30635 11.4656C8.52354 11.7078 8.5126 11.8984 8.4876 12.1C8.46417 12.2937 8.29698 12.5094 8.39698 12.6594C8.05011 12.7484 7.87823 12.45 7.60167 12.3266C7.50011 12.2984 7.39386 12.3281 7.29073 12.3484C6.86573 12.4594 6.38917 12.2094 6.38604 11.7391C6.38292 11.5125 6.50792 11.2891 6.69854 11.1656C6.72042 11.1516 6.74386 11.1375 6.75323 11.1125C6.76261 11.0859 6.75323 11.0578 6.74386 11.0312C6.55011 10.5125 7.05636 10.5172 7.27511 10.1812C7.36261 10.2781 7.50636 10.3187 7.63136 10.2828C7.53292 10.5109 7.25636 10.4016 7.08136 10.4937C6.75636 10.7609 7.29073 11.1359 7.57354 11.0953Z"
            fill="#2E7D32"
        />
        <path
            d="M5.23438 10.0469C5.34688 10.2516 5.64375 10.4172 5.57656 10.6844C5.475 10.6172 5.35156 10.5312 5.22813 10.5359C5.125 10.6734 5.16563 10.9141 5.24688 11.0562C5.625 11.5625 6.03281 10.7984 6.06406 10.4406C6.52344 10.9781 6.10313 12.4687 5.27188 11.95C5.16094 11.8609 4.8375 11.5031 4.85625 10.9062C4.86719 10.5437 5.0125 10.5203 5.09688 10.5312C4.97344 10.3406 4.95 10.0703 4.99844 9.875C5.04063 9.70781 5.11406 9.57343 5.22656 9.46406C5.34063 9.35156 5.25781 9.25156 5.26094 9.07968C5.26406 8.82968 5.32969 8.68593 5.38125 8.54375C5.4 8.80937 5.49844 9.08281 5.45625 9.34687C5.43125 9.45937 5.30625 9.45781 5.25 9.53906C5.15781 9.69062 5.15 9.89062 5.23438 10.0469Z"
            fill="#1B5E20"
        />
        <path
            d="M5.79072 12.8406C5.8501 13.0328 5.14697 12.8922 5.24541 12.6453C5.31416 12.4734 5.34541 12.2938 5.37979 12.1172C5.41416 11.8375 5.33135 11.5875 5.20166 11.3484C5.23135 11.3219 5.26572 11.2984 5.30166 11.2813C5.58291 11.8031 5.59229 11.6188 5.82354 11.2109C5.86729 11.2063 5.91103 11.2094 5.95322 11.2203C5.67353 11.6641 5.60635 12.3281 5.79072 12.8406Z"
            fill="url(#paint2_radial_125_13664)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_125_13664"
                x1="9.09928"
                y1="13.2125"
                x2="10.4057"
                y2="8.55295"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.1167" stopColor="#AFE4FE" />
                <stop offset="0.6082" stopColor="#84C9ED" />
                <stop offset="1" stopColor="#5FB2DE" />
            </linearGradient>
            <radialGradient
                id="paint1_radial_125_13664"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(12.404 13.5619) rotate(8.61977) scale(5.61627 2.8559)"
            >
                <stop stopColor="#D4E157" />
                <stop offset="1" stopColor="#D4E157" stopOpacity="0" />
            </radialGradient>
            <radialGradient
                id="paint2_radial_125_13664"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(5.8775 12.0077) rotate(15.9186) scale(0.743407 0.926316)"
            >
                <stop offset="0.4413" stopColor="#A06841" />
                <stop offset="0.9229" stopColor="#A06841" stopOpacity="0.138" />
                <stop offset="1" stopColor="#A06841" stopOpacity="0" />
            </radialGradient>
        </defs>
    </EmojiIconSvg>
);
