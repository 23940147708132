import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { h5MediumFontStyle } from 'services/typography';
import { Button } from 'components/button2';
import { UserBlock } from 'components/user-block';
import { SearchInput } from 'components/search-input';

export const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const BackButton = styled(Button)`
    flex: none;
    width: 24px;
    margin-left: 16px;
    padding: 0;
`;

export const CloseButton = styled(Button)`
    flex: none;
    width: 24px;
    margin-right: 16px;
    margin-left: auto;
    padding: 0;
    display: none;

    ${mediaQuery.lt768} {
        display: flex;
    }
`;

export const MenuHeaderText = styled.div`
    ${h5MediumFontStyle};
    flex: 1 1 100%;
    margin-left: 12px;
    margin-right: 16px;
    color: ${({ theme }) => theme.label.primary};
`;

export const UserBlockStyled = styled(UserBlock)`
    margin-left: 16px;
    margin-right: 16px;
`;

export const SearchInputStyled = styled(SearchInput)`
    margin-left: 8px;
    margin-right: 16px;
`;
