import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';

export const FooterStyled = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px 0;
`;

export const ContextMenuFooterRow = styled.div`
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
`;

export const Email = styled.div`
    ${body14RegularFontStyle};
    & > span {
        color: ${({ theme }) => theme.label.secondary};
    }
`;
