import React, { ReactNode } from 'react';

import * as Styled from './styled/BlockLayout';

export type BlockLayoutPropsType = {
    title: string;
    children: ReactNode;
};

export const BlockLayout = (props: BlockLayoutPropsType) => {
    const { title, children } = props;

    return (
        <Styled.Wrapper>
            <Styled.Title>{title}</Styled.Title>
            <div>{children}</div>
        </Styled.Wrapper>
    );
};
