import { useCallback } from 'react';

import { useQueryParameter } from 'hooks/useQueryParameter';
import { useOnSearchParameterChange } from 'hooks/useOnSearchParameterChange';

const idsSeparator = '-';
const searchParamName = 'tags';

export const useSearchTagIds = () => {
    const { value, setValue } = useQueryParameter({ paramName: searchParamName });

    const tags =
        value
            ?.split(idsSeparator)
            .map((tagId) => parseInt(tagId, 10))
            .sort((a, b) => a - b) ?? [];

    const setTags = useCallback(
        (newTags: number[]) => {
            setValue([...newTags].sort((a, b) => a - b).join(idsSeparator) || null);
        },
        [setValue],
    );

    return [tags, setTags] as const;
};

export function useOnTagsChange(callback: () => void) {
    return useOnSearchParameterChange(
        searchParamName,
        callback,
        (current, prev) =>
            current?.split(idsSeparator).sort().join(idsSeparator) ===
            prev?.split(idsSeparator).sort().join(idsSeparator),
    )
        ?.split(idsSeparator)
        .map((tagId) => parseInt(tagId, 10))
        .sort((a, b) => a - b);
}
