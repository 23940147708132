import React from 'react';

import { IconSvg, IconComponent } from './index';

export const OKColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
            fill="#F7931E"
        />
        <path
            d="M13.8028 15.3971L16.2935 17.8267C16.8037 18.3234 16.8037 19.13 16.2935 19.6271C15.7838 20.1243 14.9576 20.1243 14.4484 19.6271L11.9995 17.2396L9.55279 19.6271C9.29766 19.8754 8.96336 19.9997 8.62906 19.9997C8.29529 19.9997 7.96152 19.8754 7.70639 19.6271C7.19668 19.13 7.19668 18.3239 7.70586 17.8267L10.1969 15.3971C9.28993 15.1955 8.41525 14.845 7.60962 14.352C6.99993 13.9771 6.81679 13.1915 7.20094 12.5963C7.58403 12.0004 8.38966 11.8209 9.00042 12.1959C10.8244 13.315 13.1741 13.3153 14.9992 12.1959C15.61 11.8209 16.4153 12.0004 16.7992 12.5963C17.1834 13.191 16.9997 13.9771 16.39 14.352C15.5844 14.8455 14.7097 15.1955 13.8028 15.3971Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.94028 8.12905C7.94028 10.4053 9.85869 12.2569 12.2176 12.2569C14.5769 12.2569 16.4948 10.4053 16.4948 8.12905C16.4948 5.85207 14.5769 4 12.2176 4C9.85869 4 7.94028 5.85207 7.94028 8.12905ZM13.9886 8.12905C13.9886 7.18634 13.1942 6.41977 12.2176 6.41977C11.2417 6.41977 10.4466 7.18634 10.4466 8.12905C10.4466 9.07102 11.2417 9.83808 12.2176 9.83808C13.1942 9.83808 13.9886 9.07102 13.9886 8.12905Z"
            fill="white"
        />
    </IconSvg>
);
