import React from 'react';

import { IconSvg, IconComponent } from '.';

export const VKIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.2653 4.2653C3 5.5306 3 7.56705 3 11.64V12.36C3 16.433 3 18.4694 4.2653 19.7347C5.5306 21 7.56705 21 11.64 21H12.36C16.433 21 18.4694 21 19.7347 19.7347C21 18.4694 21 16.433 21 12.36V11.64C21 7.56705 21 5.5306 19.7347 4.2653C18.4694 3 16.433 3 12.36 3H11.64C7.56705 3 5.5306 3 4.2653 4.2653ZM5.88 8.4C5.97939 13.1222 8.36466 15.96 12.5466 15.96H12.7837V13.2584C14.3204 13.4097 15.4823 14.5222 15.9487 15.96H18.12C17.5237 13.8108 15.9563 12.6227 14.9777 12.1686C15.9563 11.6086 17.3325 10.2465 17.6612 8.4H15.6887C15.2606 9.89838 13.9916 11.2605 12.7837 11.3892V8.4H10.8111V13.6368C9.58789 13.3341 8.0436 11.8659 7.97479 8.4H5.88Z"
        />
    </IconSvg>
);
