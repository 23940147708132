import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { ProductCard as ProductCardComponent } from 'widgets/product-card';
import { ProductCardFinam as ProductCardFinamComponent } from 'widgets/product-card-finam';

export const CardWrapper = styled.div`
    padding-top: 16px;
    padding-bottom: 24px;
    height: 100%;
    box-sizing: border-box;

    ${mediaQuery.lt1400} {
        padding-bottom: 20px;
    }

    ${mediaQuery.lt1200} {
        padding-bottom: 16px;
    }

    ${mediaQuery.lt960} {
        padding-top: 12px;
    }
`;

export const LimexProductSliderCard = styled(ProductCardComponent)`
    min-width: 264px;

    ${mediaQuery.lt960} {
        width: 272px;
    }
`;
export const FinamProductSliderCard = styled(ProductCardFinamComponent)``;
