import { PaginatedRequestStateSelectorReturn, RequestStateSelectorReturn } from './types';

export const EMPTY: RequestStateSelectorReturn = {
    error: undefined,
    isEmpty: true,
    isLoading: false,
    isReloading: false,
    isError: false,
    isReady: false,
};

export const PAGINATED_EMPTY: PaginatedRequestStateSelectorReturn = {
    ...EMPTY,
    hasMorePages: false,
    isLoadingMore: false,
};
