import { RefObject, useEffect } from 'react';

/**
 * useHorizontalScroll - хук для добавления горизонтального скроллинга
 *
 * Этот хук добавляет поддержку горизонтального скроллинга (вешает обработчик события wheel)
 * содержимого для элемента, на который передана ссылка (ref).
 *
 * @param {RefObject<HTMLElement>} scrollContainerRef - ссылка (ref) на элемент, к которому нужно добавить горизонтальный скроллинг
 */
export const useHorizontalScroll = (scrollContainerRef: RefObject<HTMLElement>) => {
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const horizontalScrollHandler = (e: WheelEvent) => {
            if (!scrollContainer) return;

            // Проверка, есть ли горизонтальный скролл
            const hasHorizontalScroll = scrollContainer.scrollWidth > scrollContainer.clientWidth;

            if (hasHorizontalScroll) {
                scrollContainer.scrollLeft += e.deltaY;
                e.preventDefault(); // Предотвращаем поведение по умолчанию при скролле
            }
        };

        scrollContainer?.addEventListener('wheel', horizontalScrollHandler);

        return () => {
            scrollContainer?.removeEventListener('wheel', horizontalScrollHandler);
        };
    }, [scrollContainerRef]);
};
