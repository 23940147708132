import { Action, Id, Person, CurrentUser } from 'types';
import { Actions, thunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';
import { getCurrentUser } from 'services/wt-page-helper';
import { updateUser as updateFeedUser, UpdateUserActions } from 'widgets/feed/actions';

export type SaveUserPass = {
    id: Id;
};

// бекенд принимает base64 только без заголовка
const getClearBase64 = (field: string, str: string | undefined) => (str ? { [field]: str.split(',')[1] } : {});

interface UserData {
    cover?: string;
    image?: string;
    name?: string;
    lastname?: string;
    description?: string;
    instagram?: string;
    facebook?: string;
    telegram?: string;
    vkontakte?: string;
    personalSite?: string;
}

interface SaveUserRequestData extends UserData {
    csrf: string;
    image?: string;
    cover?: string;
}

const [SAVE_USER_ACTION_TYPES, thunk, actions] = thunkActions('SAVE_USER')<
    SaveUserRequestData,
    {
        user: Person;
        errors?: { field: string; message: string }[];
    },
    SaveUserPass,
    UpdateUserActions
>();
type SaveUser = Action<typeof saveUser>;
type SaveUserActions = Actions<typeof actions>;

function saveUser(csrf: string, userData: UserData, pass: SaveUserPass): ReturnType<typeof thunk> {
    return (dispatch, getState, extraArgument) => {
        const resultPromise = thunk({
            request: {
                method: 'POST',
                url: UrlProcessor.page('limex_cabinet_update_v2_json').path(),
                data: {
                    csrf,
                    ...userData,
                    ...getClearBase64('image', userData.image),
                    ...getClearBase64('cover', userData.cover),
                },
                withCredentials: true,
            },
            pass,
        })(dispatch, getState, extraArgument);

        resultPromise.then((action) => {
            const { data } = action.payload;
            if ('errors' in data) return;
            const { user } = data;
            if (!user) return;
            const { firstName, lastName, displayName, image, lcid } = user;
            const { id } = pass;

            dispatch(
                updateFeedUser({
                    id,
                    firstName,
                    lastName,
                    displayName,
                    image,
                }),
            );
            getCurrentUser()?.then((_user) => {
                const newData: CurrentUser = {
                    ..._user,
                    firstName,
                    lastName,
                    displayName,
                    image,
                    lcid,
                    type: 1,
                };
                window.WT.Page?.setCurrentUser?.(newData);
            });
        });
        return resultPromise;
    };
}

export { SAVE_USER_ACTION_TYPES, saveUser, SaveUser, SaveUserActions };
