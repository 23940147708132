import styled from 'styled-components';

import { body14RegularFontStyle, body17MediumFontStyle } from 'services/typography';
import { taggedTemplateNoop } from 'services/utils/tagged-template-literal';

import { CardTitleBlockProps } from './types';

const lineHeightTitle = 24;

type CardTitleBlockWrapperStyledParams = { titleHeight?: number | null } & Pick<CardTitleBlockProps, 'marginBottom'>;

export const CardTitleBlockWrapper = styled.div<CardTitleBlockWrapperStyledParams>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ titleHeight, marginBottom }) => {
        if (marginBottom) {
            return marginBottom;
        }

        return titleHeight && titleHeight > lineHeightTitle ? 4 : 8;
    }}px;
`;

const css = taggedTemplateNoop;

export const Title = styled.h5<{ lines: 1 | 2 }>`
    ${body17MediumFontStyle};
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;

    ${({ lines }) =>
        lines === 2
            ? css`
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow-wrap: break-word;
              `
            : css`
                  white-space: nowrap;
                  text-overflow: ellipsis;
              `}
`;

export const SecondaryText = styled.span`
    ${body14RegularFontStyle}
    color:${({ theme }) => theme.label.secondary};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
