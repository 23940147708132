import produce from 'immer';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { GeneratorType } from 'types';
import { SubscribeActions, SUBSCRIBE_ACTION_TYPES } from 'widgets/action-subscribe/actions';
import {
    ADD_BLACKLIST_ACTION_TYPES,
    AddBlacklistActions,
    REMOVE_BLACKLIST_ACTION_TYPES,
    RemoveBlacklistActions,
} from 'widgets/action-blacklist/actions';

import { initialSearchResultState, initialState } from './constants';
import { SearchState } from './interfaces';
import {
    GET_SEARCH_RESULTS_ACTION_TYPES,
    RESET_SEARCH_RESULT,
    SearchActions,
    UPDATE_SEARCH_RESULT,
    UPDATE_SEARCH_TAB,
} from './actions';

const [GET_SEARCH_RESULTS_REQUEST, GET_SEARCH_RESULTS_SUCCESS, GET_SEARCH_RESULTS_FAILURE] =
    GET_SEARCH_RESULTS_ACTION_TYPES;
const [, UPDATE_SUBSCRIPTION_SUCCESS] = SUBSCRIBE_ACTION_TYPES;
const [, ADD_BLACKLIST_SUCCESS] = ADD_BLACKLIST_ACTION_TYPES;
const [, REMOVE_BLACKLIST_SUCCESS] = REMOVE_BLACKLIST_ACTION_TYPES;

export default (widgetSliceName: string) =>
    (
        state: SearchState = initialState,
        action: SearchActions | SubscribeActions | RemoveBlacklistActions | AddBlacklistActions,
    ): SearchState => {
        switch (action.type) {
            case GET_SEARCH_RESULTS_REQUEST:
                return action.payload.pass.widgetName === widgetSliceName
                    ? {
                          ...state,
                          searchResult: initialSearchResultState,
                          loadingTabs: uniq([...state.loadingTabs, ...action.payload.pass.tabs]),
                      }
                    : state;
            case GET_SEARCH_RESULTS_SUCCESS: {
                const {
                    data,
                    pass: { tabs, widgetName },
                } = action.payload;
                if (widgetName !== widgetSliceName) {
                    return state;
                }
                if (data.ok) {
                    return produce(state, (draft) => {
                        if (tabs.includes('instruments')) {
                            draft.searchResult.instruments = data.result.instruments;
                        }
                        if (tabs.includes('people')) {
                            draft.searchResult.people = data.result.people;
                        }
                        if (tabs.includes('posts')) {
                            draft.searchResult.posts = data.result.posts;
                        }
                        if (tabs.includes('products')) {
                            draft.searchResult.products = data.result.products;
                        }
                        if (tabs.includes('communities')) {
                            draft.searchResult.communities = data.result.communities;
                        }
                        draft.searchResult.log = data.result.log;
                        draft.loadingTabs = without(draft.loadingTabs, ...action.payload.pass.tabs);
                    });
                }
                return { ...state, loadingTabs: without(state.loadingTabs, ...action.payload.pass.tabs) };
            }
            case GET_SEARCH_RESULTS_FAILURE:
                if (action.payload.pass.widgetName !== widgetSliceName) {
                    return state;
                }
                return { ...state, loadingTabs: without(state.loadingTabs, ...action.payload.pass.tabs) };
            case UPDATE_SUBSCRIPTION_SUCCESS: {
                const {
                    payload: {
                        data: {
                            status: { subscription },
                        },
                        pass: { generatorType, generatorId },
                    },
                } = action;
                const searchResult = produce(state.searchResult, (draft) => {
                    if (generatorType === GeneratorType.USER) {
                        const user = draft.people.find(({ id }) => id === generatorId);
                        if (user) {
                            user.subscribersCount += subscription ? 1 : -1;
                            user.counters.followers += subscription ? 1 : -1;
                            user.subscribed = subscription;
                        }
                    }
                    if (generatorType === GeneratorType.INSTRUMENT) {
                        const instrument = draft.instruments.find(({ id }) => id === generatorId);
                        if (instrument) {
                            instrument.subscribed = subscription;
                        }
                    }
                });
                return { ...state, searchResult };
            }
            case UPDATE_SEARCH_RESULT:
                if (action.payload.pass.widgetName !== widgetSliceName) {
                    return state;
                }
                return { ...state, searchResult: action.payload.data.searchResult };
            case RESET_SEARCH_RESULT:
                if (action.payload.pass.widgetName !== widgetSliceName) {
                    return state;
                }
                return initialState;
            case REMOVE_BLACKLIST_SUCCESS:
            case ADD_BLACKLIST_SUCCESS: {
                const { payload } = action;
                const { userId } = payload.pass;
                return produce(state, (draft) => {
                    const person = draft.searchResult.people.find(({ id }) => id === userId);
                    if (person) {
                        person.isBlockedByCurrentUser = action.type === ADD_BLACKLIST_SUCCESS;
                    }
                });
            }
            case UPDATE_SEARCH_TAB: {
                if (action.payload.pass.widgetName !== widgetSliceName) {
                    return state;
                }
                return { ...state, activeTab: action.payload.data.tab };
            }
            default:
                return state;
        }
    };
