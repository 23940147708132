import React, { ReactNode, useContext, useMemo } from 'react';

import { ListBoxContext, ListBoxContextType } from './context';
import { ListBoxOptionContainer } from './styled';

export function ListBoxOption<ValueType>({
    children,
    value,
    className: classNameProp,
    readonly,
}: {
    children: ReactNode;
    value: ValueType;
    className?: string;
    readonly?: boolean;
}) {
    const { setCurrentValue, setIsOpened } = useContext<ListBoxContextType<ValueType>>(ListBoxContext);

    const className = useMemo(
        () => ['autotest__list-box__options__item', classNameProp].filter((cn) => cn).join(' '),
        [classNameProp],
    );

    return (
        <ListBoxOptionContainer
            readonly={readonly}
            className={className}
            onClick={(e) => {
                e.preventDefault();
                if (!readonly) {
                    setCurrentValue(value);
                    setIsOpened(false);
                }
            }}
        >
            {typeof children === 'string' ? <span>{children}</span> : children}
        </ListBoxOptionContainer>
    );
}
