import React, { FC } from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { TrashIcon } from 'components/icons2/TrashIcon';

type Props = {
    onClick: () => void;
};

const ContextActionDelete: FC<Props> = ({ onClick }) => (
    <MenuItem
        onClick={onClick}
        icon={{ component: TrashIcon }}
        text={Dic.word('wt_feed__widget_author__context_action_trash_post')}
        autotestClass="autotest__feed_widget_author__context_action_trash_post"
    />
);

export default ContextActionDelete;
