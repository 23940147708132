import styled, { css } from 'styled-components';

import { WrapperProps } from '../types';

import { Input } from './base';
import { buildColors } from './colors';
import { buildSizes } from './sizes';

// Если есть inline label прячем таким образом input чтобы label показывался на его месте когда он пустой/нет фокуса.
// display: none; не подходит т.к. тогда input не может принять фокус.
export const hideInputStyles = ({ hasText, hasRows, hasLabel }: WrapperProps) => css`
    & ${Input} {
        ${hasLabel
            ? css`
                  max-height: 0;
                  opacity: 0;
              `
            : undefined}
    }

    ${hasText || hasRows ? '&' : '&:focus-within'} {
        & ${Input} {
            max-height: unset;
            opacity: 1;
        }
    }
`;

export const Wrapper = styled.label<WrapperProps>`
    cursor: text;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    border-radius: 8px;

    ${buildColors};
    ${buildSizes};

    ${hideInputStyles};
`;

export {
    ErrorMessage,
    InlineLabel,
    Input,
    LeftGroup,
    Lock,
    RightGroupWrapper,
    RightLabel,
    TextareaAutosizeWrapper,
    IconButton,
} from './base';
