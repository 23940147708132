import React, { memo } from 'react';

import { getIsShowBadge } from 'widgets/header-notifications/utils';
import { HeaderNotificationsUnreadCountCustomRender } from 'widgets/header-notifications';
import { BellIcon } from 'components/icons2/BellIcon';
import { BellActiveIcon } from 'components/icons2/BellActiveIcon';

const customRender = ({ unreadCount }: { unreadCount: number | null | undefined }) => {
    const isShowBadge = getIsShowBadge({ isOpened: false, unreadCount });

    return isShowBadge ? <BellActiveIcon /> : <BellIcon />;
};

export const MobileNotificationsButtonIcon = memo(() => (
    <HeaderNotificationsUnreadCountCustomRender customRender={customRender} />
));
