import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Person } from 'types';
import { ValidationError } from 'services/validation-error';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { useSafeUnmount } from 'hooks/useSafeUnmount';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { FormManager, createInitialFormState, getFormData, useHandleValidation } from 'components/form';
import { WtPopup3 } from 'components/wt-popup3';
import { CheckOutlineIcon } from 'components/icons2/CheckOutlineIcon';

import { SaveUser } from '../actions';

import { CoverInput } from './CoverInput';
import { AvatarInput } from './AvatarInput';
import { TextInput } from './TextInput';
import { Wrapper, AvatarAndErrorsMsgWrapper, AvatarWrapper, ErrorsWrapper, Subtitle } from './styled';

type Props = {
    user: Person;
    closePopup: () => void;
    isVisible: boolean;
    saveUser: SaveUser;
};

const getInitialFormState = (user: Person) => {
    const { image, cover, firstName, lastName, description, socialNets, images } = user;
    const { instagram, personalSite, vkontakte, telegram, facebook } = socialNets || {};
    return createInitialFormState({
        cover: cover || '',
        image: images?.medium || image || '',
        name: firstName || '',
        lastname: lastName || '',
        description: description || '',
        instagram: instagram || '',
        facebook: facebook || '',
        telegram: telegram || '',
        vkontakte: vkontakte || '',
        personalSite: personalSite || '',
    });
};

const UserFormInternal = (props: Props) => {
    const { user, closePopup, saveUser, isVisible } = props;
    const { id } = user;
    const csrf = useCSRFToken();
    const initialFormState = useMemo(() => getInitialFormState(user), [user]);
    const [formState, setFormState] = useState(initialFormState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const safeUnmount = useSafeUnmount();

    const { handleValidation, validator } = useHandleValidation(formState, setFormState);

    const handleSubmit = () => {
        setIsSubmitting(true);
        saveUser(csrf, getFormData(formState), { id })
            .then((action) => {
                if (action.payload.data.errors) {
                    handleValidation.applyError(new ValidationError(action.payload.data.errors));
                    notify.error(Dic.word('wt_all__notification__common_error'));
                } else {
                    closePopup();
                    notify.success({
                        icon: {
                            component: CheckOutlineIcon,
                        },
                        text: Dic.word('wt_all__widget_user_form__notification_success'),
                    });
                }
            })
            .catch((error) => {
                notify.error(Dic.word('wt_all__notification__common_error'));
                throw error;
            })
            .finally(() => {
                safeUnmount(() => setIsSubmitting(false));
            });
    };

    const handleClose = useCallback(() => {
        if (isVisible) {
            closePopup();
        }
    }, [closePopup, isVisible]);

    return (
        <FormManager state={formState} onChange={setFormState}>
            {(fields, { isValid, isDirty }) => (
                <WtPopup3
                    close={handleClose}
                    isVisible={isVisible}
                    isMobileFullscreen
                    width={464}
                    title={Dic.word('wt_all__widget_user_form__title_text')}
                    closeButtonClassName="autotest__user-form__buttons_close"
                    actionButtons={{
                        direction: 'vertical',
                        items: [
                            {
                                key: 1,
                                content: Dic.word('wt_all__widget_user_form__submit_btn'),
                                onClick: handleSubmit,
                                isDisabled: isSubmitting || !isValid() || !isDirty(),
                                isProcessing: isSubmitting,
                                className: 'autotest__user-form__buttons_save',
                            },
                        ],
                    }}
                >
                    <Wrapper>
                        <CoverInput
                            field={fields.cover}
                            onValidation={handleValidation}
                            hasError={Boolean(formState.cover.errors?.[0])}
                        />
                        <AvatarAndErrorsMsgWrapper>
                            <AvatarWrapper>
                                <AvatarInput
                                    field={fields.image}
                                    onValidation={handleValidation}
                                    hasError={Boolean(formState.image.errors?.[0])}
                                    className="autotest__user-form__avatar-input"
                                />
                            </AvatarWrapper>
                            <ErrorsWrapper>{formState.cover.errors?.[0] || formState.image.errors?.[0]}</ErrorsWrapper>
                        </AvatarAndErrorsMsgWrapper>
                        <Subtitle>{Dic.word('wt_all__widget_user_form__about_you')}</Subtitle>
                        <TextInput
                            field={fields.name}
                            fieldName="name"
                            validator={validator}
                            maxLength={32}
                            required
                            className="autotest__user-form__name"
                        />
                        <TextInput
                            field={fields.lastname}
                            fieldName="lastname"
                            validator={validator}
                            maxLength={32}
                            className="autotest__user-form__lastname"
                        />
                        <TextInput
                            field={fields.description}
                            fieldName="description"
                            type="textarea"
                            validator={validator}
                            maxLength={150}
                            isVisible={isVisible}
                            className="autotest__user-form__description"
                        />
                        <Subtitle>{Dic.word('wt_all__widget_user_form__social_networks')}</Subtitle>
                        <TextInput
                            field={fields.instagram}
                            fieldName="instagram"
                            validator={validator}
                            className="autotest__user-form__instagram"
                        />
                        <TextInput
                            field={fields.facebook}
                            fieldName="facebook"
                            validator={validator}
                            className="autotest__user-form__facebook"
                        />
                        <TextInput
                            field={fields.telegram}
                            fieldName="telegram"
                            validator={validator}
                            className="autotest__user-form__telegram"
                        />
                        <TextInput
                            field={fields.vkontakte}
                            fieldName="vkontakte"
                            validator={validator}
                            className="autotest__user-form__vkontakte"
                        />
                        <TextInput
                            field={fields.personalSite}
                            fieldName="personalSite"
                            validator={validator}
                            className="autotest__user-form__personalSite"
                        />
                    </Wrapper>
                </WtPopup3>
            )}
        </FormManager>
    );
};

export const UserForm = (props: Props) => {
    const [countKey, setCountKey] = useState(0);
    const { user } = props;

    useEffect(() => {
        setCountKey((currentValue) => currentValue + 1);
    }, [user]);

    return <UserFormInternal key={countKey} {...props} />;
};
