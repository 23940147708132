import styled, { css } from 'styled-components';

import { caption12RegularFontStyle } from 'services/typography';
import { LockSmallIcon } from 'components/icons2/LockSmallIcon';

export const Input = styled.input`
    box-sizing: border-box;
    width: 100%;
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;
    resize: none;
`;

export const LeftGroup = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 8px;
`;

export const InlineLabel = styled.div``;

export const RightGroupWrapper = styled.div<{ readonly: boolean }>`
    margin-right: ${({ readonly }) => (readonly ? 0 : 4)}px;
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`;

const buttonReset = css`
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    cursor: inherit;
`;

export const RightLabel = styled.button`
    ${buttonReset};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;

export const IconButton = styled.button`
    ${buttonReset};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
    & svg {
        display: block;
    }
`;

export const Lock = styled(LockSmallIcon)``;

export const ErrorMessage = styled.div`
    margin-top: 4px;
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.negative};
`;

export const TextareaAutosizeWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-areas:
        'label'
        'content';

    &:after {
        content: attr(data-replicated-value);
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        visibility: hidden;
        grid-area: content;
        min-width: 0;
    }

    & > textarea {
        resize: none;
        overflow: hidden;
        grid-area: content;
        min-width: 0;
        overflow-wrap: anywhere;
        font-variant-numeric: tabular-nums;
    }
    & > div {
        grid-area: label;
    }
`;
