import React, { useRef } from 'react';
import cn from 'classcat';

import { ResponsiveMap } from 'app/styled';
import { useIsTextOverflowEllipsis } from 'hooks/useIsTextOverflowEllipsis';
import { TooltipV2Props } from 'components/tooltip-v2/types';
import { TooltipV2 } from 'components/tooltip-v2';

import * as Styled from './styled';

export type TypographyStyleValueTextOneLineTooltip = string;
export type TypographyStyleTextOneLineTooltip =
    | TypographyStyleValueTextOneLineTooltip
    | ResponsiveMap<TypographyStyleValueTextOneLineTooltip>;

export type TextOneLineTooltipProps = {
    children: string;
    className?: string;
    tooltipPlacement?: TooltipV2Props['placement'];
    link?: string;
    targetLink?: string;
    onClick?: () => void;
    typographyStyle?: TypographyStyleTextOneLineTooltip;
};

export const TextOneLineTooltip = (props: TextOneLineTooltipProps) => {
    const {
        typographyStyle,
        onClick,
        className: classNameProp,
        tooltipPlacement = 'top',
        targetLink,
        link,
        children: text,
    } = props;

    const className = cn(['autotest__text-one-line-tooltip', classNameProp]);

    const textRef = useRef<HTMLSpanElement | null>(null);

    const isTextOverflowEllipsis = useIsTextOverflowEllipsis({ ref: textRef });

    const renderContent = () => {
        const textOverflowEllipsisEl = (
            <Styled.Text typographyStyle={typographyStyle} ref={textRef}>
                {text}
            </Styled.Text>
        );

        if (isTextOverflowEllipsis) {
            return (
                <TooltipV2 placement={tooltipPlacement} content={text}>
                    {link ? (
                        <a href={link} target={targetLink}>
                            {textOverflowEllipsisEl}
                        </a>
                    ) : (
                        textOverflowEllipsisEl
                    )}
                </TooltipV2>
            );
        }

        return textOverflowEllipsisEl;
    };

    return (
        <Styled.TextOneLineTooltipWrapper className={className} onClick={onClick}>
            {renderContent()}
        </Styled.TextOneLineTooltipWrapper>
    );
};
