import React, {
    FocusEventHandler,
    forwardRef,
    ReactNode,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from 'react';

import { ChevronUpSmallIcon } from 'components/icons2/ChevronUpSmallIcon';
import { ChevronDownSmallIcon } from 'components/icons2/ChevronDownSmallIcon';

import { ListBoxContext, ListBoxContextType } from './context';
import {
    ListBoxButtonContainer,
    ListBoxButtonContent,
    ListBoxButtonIconContainer,
    ListBoxPlaceholderContainer,
} from './styled';

export type ListBoxButtonProps = {
    children: ReactNode;
    placeholder?: ReactNode;
    className?: string;
    onBlur?: FocusEventHandler<HTMLButtonElement>;
    hasChevronIcon?: boolean;
};
export const ListBoxButton = forwardRef(
    (listBoxButtonProps: ListBoxButtonProps, listBoxButtonContainerForwardedRef) => {
        const { children, placeholder, className: classNameProp, onBlur, hasChevronIcon = true } = listBoxButtonProps;

        const { isOpened, setIsOpened, noSelectedText, visualState, onChangeIsOpen } =
            useContext<ListBoxContextType<unknown>>(ListBoxContext);

        useEffect(() => {
            onChangeIsOpen?.(isOpened);
        }, [isOpened, onChangeIsOpen]);

        const listBoxButtonContainerRef = useRef(null);

        useImperativeHandle(listBoxButtonContainerForwardedRef, () => listBoxButtonContainerRef?.current, []);

        const className = useMemo(
            () => ['autotest__list-box__btn', classNameProp].filter((cn) => cn).join(' '),
            [classNameProp],
        );
        const hasChildren = Boolean(children || noSelectedText !== '\u00A0');
        return (
            <ListBoxButtonContainer
                onClick={() => {
                    setIsOpened((v) => !v);
                }}
                onBlur={onBlur}
                ref={listBoxButtonContainerRef}
                visualState={visualState}
                className={className}
            >
                <ListBoxButtonContent hasChildren={hasChildren}>
                    {placeholder && (
                        <ListBoxPlaceholderContainer hasChildren={hasChildren}>
                            {placeholder}
                        </ListBoxPlaceholderContainer>
                    )}
                    {children || noSelectedText}
                </ListBoxButtonContent>
                {hasChevronIcon && (
                    <ListBoxButtonIconContainer>
                        {isOpened ? <ChevronUpSmallIcon /> : <ChevronDownSmallIcon />}
                    </ListBoxButtonIconContainer>
                )}
            </ListBoxButtonContainer>
        );
    },
);
