import React, { useCallback } from 'react';

import { FeedOwnerType, Person } from 'types';
import { correctedHumanString } from 'services/dictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useDictionary } from 'hooks/useDictionary';
import { ActionSubscribe, ActionSubscribeRenderProps } from 'widgets/action-subscribe';
import { ActionPossibility } from 'widgets/action-possibility';

import * as Styled from './styled/PersonItem';

type Props = {
    person: Person;
    togglePopup: () => void;
};

export const PersonItem = (props: Props) => {
    const dic = useDictionary();
    const { person, togglePopup } = props;
    const { data, id, type, counters, isBlockedByCurrentUser } = person;
    const { subscribed = false } = data || {};
    const currentUser = useCurrentUser();
    const subscribeRender = useCallback(
        (subscribeProps: ActionSubscribeRenderProps) => {
            const { isSubscribed, onClick, isLoading } = subscribeProps;
            const handleClick = () => {
                if (!currentUser.isAuth) togglePopup();
                onClick();
            };
            return (
                <Styled.SubscribeButton
                    text={dic.word(`wt_profile__widget_subscribers__subscribe${isSubscribed ? 'd' : ''}_btn`)}
                    onClick={handleClick}
                    size={{ default: 'M', lt768: 'S' }}
                    pressed={!isSubscribed}
                    disabled={isLoading || isBlockedByCurrentUser}
                    className={`autotest__followers_popup_button_subscribe${
                        isSubscribed ? 'd' : ''
                    } autotest__followers_popup_person_id_${id}`}
                />
            );
        },
        [currentUser],
    );
    return (
        <Styled.ItemWrapper key={id}>
            <Styled.UserBlock
                user={person}
                secondLine={
                    <span title={`${counters?.followers || 0}`}>
                        {type === FeedOwnerType.PERSON
                            ? correctedHumanString('wt_profile__widget_header_user__followers', [
                                  counters?.followers || 0,
                              ])
                            : null}
                        {type === FeedOwnerType.COMMUNITY
                            ? dic.word('wt_menu__search_limex__communities_subtitle')
                            : null}
                    </span>
                }
                avatar={{ size: 32 }}
            />

            <ActionPossibility isPossible={!isBlockedByCurrentUser} tooltipPlacement="top">
                {person.isFollowable && (
                    <ActionSubscribe
                        isSubscribed={subscribed ?? false}
                        data={{
                            generatorId: id,
                            generatorType: type,
                        }}
                        customRender={subscribeRender}
                        className={undefined}
                    />
                )}
            </ActionPossibility>
        </Styled.ItemWrapper>
    );
};
