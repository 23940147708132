/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentExporter } from '../models/shared/CommentExporter';
import type { CommentsView } from '../models/CommentService/CommentsView';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentService {
    /**
     * Получить комментарии сущности
     * @returns any Успешное получение комментариев
     * @throws ApiError
     */
    public static getComments({
        objectType,
        objectId,
        filter = 'showDefaultComments',
        lastReadTime,
        commentId,
        prevCommentId,
        page = 1,
        reverse = 'false',
        filterPersonId,
        pageSize = 3000,
        startDate,
        pinnedCommentId,
    }: {
        /**
         * Тип объекта для которого получаем комментарии (3 - пост)
         */
        objectType: 3;
        /**
         * ИД объекта для которого получаем комментарии
         */
        objectId: number;
        /**
         * Тип фильтрации комментариев
         */
        filter?:
            | 'showRecentComments'
            | 'showMostLikedComments'
            | 'showRatedComments'
            | 'showLatestComments'
            | 'showOwnersComments'
            | 'showUnreadComments'
            | 'fromComment'
            | 'betweenComments'
            | 'fromCommentAll'
            | 'latest_by_date'
            | 'showDefaultComments';
        /**
         * Параметр используется совместно с фильтром "showUnreadComments"
         */
        lastReadTime?: string;
        /**
         * Параметр используется совместно с фильтрами "fromComment", "betweenComments", "fromCommentAll"
         */
        commentId?: number;
        /**
         * Параметр используется совместно с фильтром "betweenComments"
         */
        prevCommentId?: number;
        /**
         * Курсор пиганации
         */
        page?: number;
        /**
         * Направление сортироки "false" - ASC, "true" - DESC.
         * Используется совместно с фильтрами "showOwnersComments", "showUnreadComments", "fromComment", "fromCommentAll"
         */
        reverse?: string;
        /**
         * Параметр используется совместно с фильтром "showOwnersComments"
         */
        filterPersonId?: number;
        /**
         * Количество комментариев в запросе
         */
        pageSize?: number;
        /**
         * Параметр используется совместно с фильтром "latest_by_date"
         */
        startDate?: string;
        /**
         * ИД закрепленного комментария
         */
        pinnedCommentId?: number;
    }): CancelablePromise<{
        /**
         * Список комментариев
         */
        comments?: Array<CommentExporter>;
        commentsCount?: number;
        generationDate?: number;
        groupCounts?: Array<number>;
        /**
         * Список комментариев в свернутом состоянии
         */
        initialCollapsedComments?: Array<CommentExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/comments/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            query: {
                filter: filter,
                lastReadTime: lastReadTime,
                commentId: commentId,
                prevCommentId: prevCommentId,
                page: page,
                reverse: reverse,
                filterPersonId: filterPersonId,
                pageSize: pageSize,
                startDate: startDate,
                pinnedCommentId: pinnedCommentId,
            },
            errors: {
                503: `Ошибка сервера`,
            },
        });
    }

    /**
     * Добавить новый комментарий
     * @returns any Успешное добавление комментария
     * @throws ApiError
     */
    public static createComment({
        objectType,
        objectId,
        requestBody,
    }: {
        /**
         * Тип комментируемого объекта (3 - пост, 6 - комментарий)
         */
        objectType: 3 | 6;
        /**
         * ИД комментируемого объекта
         */
        objectId: number;
        requestBody?: {
            /**
             * Данные комментария
             */
            comment: {
                /**
                 * Текст комментария
                 */
                text?: string;
                /**
                 * Путь к прикрепленной картинке
                 */
                image?: string | null;
            };
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<{
        comment?: CommentExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comments/{objectType}/{objectId}/add/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Редактировать комментарий
     * @returns any Успешное изменение комментария
     * @throws ApiError
     */
    public static editComment({
        commentId,
        requestBody,
    }: {
        /**
         * ИД комментария
         */
        commentId: number;
        requestBody?: {
            /**
             * Данные комментария
             */
            comment: {
                /**
                 * Текст комментария
                 */
                text?: string;
                /**
                 * Путь к прикрепленной картинке
                 */
                image?: string | null;
            };
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<{
        comment?: CommentExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comments/{commentId}/',
            path: {
                commentId: commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Удалить комментарий
     * @returns void
     * @throws ApiError
     */
    public static deleteComment({
        commentId,
        requestBody,
    }: {
        /**
         * ИД удаляемого комментария
         */
        commentId: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/comments/{commentId}/',
            path: {
                commentId: commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Восстановить удаленный комментарий
     * @returns any Успешное восстановление комментария
     * @throws ApiError
     */
    public static restoreComment({
        commentId,
        requestBody,
    }: {
        /**
         * ИД восстанавливаемого комментария
         */
        commentId: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comments/{commentId}/restore/',
            path: {
                commentId: commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить комментарии сущности
     *
     * Особенности получения комментариев
     *
     * 1. Максимальное количество корневых комментариев до 20;
     * 2. Максимальное количество ответов:
     * 2.2. При objectType = 3 до 5 для каждого корневого комментария;
     * 2.3. При objectType = 6 до 10;
     * 3. Имеется три фильтра сортировки комментариев:
     * 3.1. showMostLikedComments - сначала интересные;
     * 3.2. showLatestComments - сначала новые;
     * 3.3. showEarliestComments - сначала старые;
     * 4. Ответы сортируются от старых к новым;
     * 5. Если корневой комментарий удален и имеет активные ответы, то он появится в списке, в том числе в счетчиках;
     * 6. Закрепленные комментарии выводятся через параметр pinnedCommentId с обязательным указанием параметра filter;
     * 6.1. Если закрепленный комментарий является корневым, то выводится этот комментарий и до 5 ответов к нему;
     * 6.2. Если закрепленный комментарий является ответом, то выводится корневой комментарий этого ответа и сам ответ;
     * 7. Вывод пачки ответов для корневых комментариев осуществляется через тип объекта = 6 и параметров direction, commentId
     * 7.1. direction = before - выводит пачку до заданного ответа-комментария в commentId
     * 7.2. direction = after - выводит пачку после заданного ответа-комментария в commentId
     * 8. commentsAmount - количество комментариев до/после/всего от полученной пачки;
     * 8.1. Если тип объекта 3, то получаем количество только корневых комментариев;
     * 8.2. Если тип объекта 6, то получаем количество только ответов в рамках текущего корневого комментария;
     * 9. repliesAmount - Количество ответов для каждого полученного корневого комментария в пачке. Используется только для типа объекта 3;
     * 10. pinnedRepliesAmount - Количество ответов корневого комментария для закрепленного ответа. Используется только для типа объекта 3,
     * только для закрепленного комментария через параметр pinnedCommentId и только если закрепленный комментарий является не корневым;
     *
     * @returns CommentsView Успешное получение комментариев
     * @throws ApiError
     */
    public static getCommentsWithAnswers({
        objectType,
        objectId,
        filter,
        page = 1,
        pageSize = 1,
        pinnedCommentId,
        direction,
        commentId,
    }: {
        /**
         * Тип объекта для которого получаем комментарии (3 - пост, 6 - корневой комментарий)
         */
        objectType: 3 | 6;
        /**
         * ИД объекта для которого получаем комментарии
         */
        objectId: number;
        /**
         * Тип сортировки комментариев. Доступен только для objectType = 3.
         */
        filter?: 'showMostLikedComments' | 'showLatestComments' | 'showEarliestComments';
        /**
         * Курсор пиганации. Не используется для получения закрепленных комментариев.
         */
        page?: number;
        /**
         *
         * Количество комментариев в запросе. Не используется для получения закрепленных комментариев.
         * - Для objectType = 3 - до 20 корневых комментариев. Каждый корневой комментарий может иметь до 5 ответов;
         * - Для objectType = 6 - до 10 ответов;
         *
         */
        pageSize?: number;
        /**
         *
         * ИД закрепленного комментария. (Доступно только для objectType = 3)
         * - Если закрепленный комментарий является корневым, то выводится этот комментарий и до 5 ответов к нему;
         * - Если закрепленный комментарий является ответом, то выводится корневой комментарий этого ответа и сам ответ;
         *
         */
        pinnedCommentId?: number;
        /**
         *
         * Направление получения ответов. Используется вместе с параметром commentId. (Доступно только для objectType = 6)
         * - before - до текущей пачки ответов;
         * - after - после текущей пачки ответов;
         *
         */
        direction?: 'before' | 'after';
        /**
         * ИД ответа, от которого получаем пачку остальных ответов. Используется вместе с параметром direction. (Доступно только для objectType = 6)
         */
        commentId?: number;
    }): CancelablePromise<CommentsView> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/comments/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            query: {
                filter: filter,
                page: page,
                pageSize: pageSize,
                pinnedCommentId: pinnedCommentId,
                direction: direction,
                commentId: commentId,
            },
            errors: {
                400: `Неверно переданны параметры`,
            },
        });
    }
}
