import styled, { createGlobalStyle } from 'styled-components';
import Tippy from '@tippy.js/react';

import { mediaQuery } from 'app/styled';

export const StyledTippy = styled(Tippy)`
    border-radius: 0;
    background: transparent;
    padding: 0;
    text-align: left;
`;

export const ButtonWrapper = styled.div`
    width: 40px;
`;

export const DesktopWrapper = styled.div`
    display: block;
    ${mediaQuery.lt768} {
        display: none;
    }
`;

export const MobileWrapper = styled.div<{ isShow: boolean }>`
    z-index: 1;
    display: none;
    ${mediaQuery.lt768} {
        display: ${({ isShow }) => (isShow ? 'block' : 'none')};
    }
`;

export const IconWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > *:nth-child(1) {
        display: block;
    }

    & > *:nth-child(2) {
        display: none;
    }

    ${mediaQuery.lt768} {
        & > *:nth-child(1) {
            display: none;
        }

        & > *:nth-child(2) {
            display: block;
        }
    }
`;

export const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
    ${mediaQuery.lt768} {
        body {
            ${({ isShow }) => (isShow ? 'overflow: hidden' : '')};
        }
    }
`;
