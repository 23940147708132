import React, { ReactNode } from 'react';

import * as Styled from './styled/Header.styled';

export type HeaderPropsType = {
    title: string;
    unreadCount?: number | null;
    after?: ReactNode;
    before?: ReactNode;
    className?: string;
};

export const Header = (props: HeaderPropsType) => {
    const { title, className, unreadCount, before, after } = props;
    const hasBadge = unreadCount && unreadCount !== 0;

    return (
        <Styled.HeaderWrapper className={className}>
            <Styled.LeftPart>
                {before && <Styled.BeforeWrapper>{before}</Styled.BeforeWrapper>}
                <Styled.Title>{title}</Styled.Title>
                {hasBadge ? (
                    <Styled.CounterBadgeWrapper>
                        <Styled.CounterBadgeText>{unreadCount}</Styled.CounterBadgeText>
                    </Styled.CounterBadgeWrapper>
                ) : null}
            </Styled.LeftPart>
            {after}
        </Styled.HeaderWrapper>
    );
};
