import React, { useCallback, useMemo } from 'react';

import { IconComponent } from 'components/icons2';
import { ContextActionsProps } from 'components/context-actions';

import { ButtonContextActionsComponent } from '../components/ButtonContextActionsComponent';
import { getIsShowBadge } from '../utils';
import { MapDispatchToPropsType, MapStateToPropsType } from '../containers/ButtonContextActionsContainer';

type HeaderNotificationsTooltipProps = MapStateToPropsType &
    MapDispatchToPropsType & {
        btnIcon?: IconComponent;
    };

export const HeaderNotificationsButtonContextActionsConnector = (props: HeaderNotificationsTooltipProps) => {
    const { btnIcon, unreadCount, isOpened, setIsOpened } = props;

    const isShowBadge = useMemo(() => getIsShowBadge({ unreadCount, isOpened }), [unreadCount, isOpened]);

    const onVisibleChange = useCallback<NonNullable<ContextActionsProps['onVisibleChange']>>(
        ({ isVisible }) => {
            if (isOpened !== isVisible) {
                setIsOpened(isVisible);
            }
        },
        [isOpened, setIsOpened],
    );

    return (
        <ButtonContextActionsComponent
            isShowBadge={isShowBadge}
            btnIcon={btnIcon}
            onVisibleChange={onVisibleChange}
            isOpened={isOpened}
        />
    );
};
