import React, { useMemo, useState } from 'react';
import cc from 'classcat';

import { AvatarImplementationProps } from '../types';
import { DEFAULT_SIZE, EXCLUDE_IMG_ARR_SRC } from '../constants';
import * as Styled from '../styled/Avatar.styled';

export const Avatar: React.FC<AvatarImplementationProps> = React.memo((props: AvatarImplementationProps) => {
    const {
        size = DEFAULT_SIZE,
        borderRadius,
        src: srcProp,
        alt,
        initials,
        userIcon,
        centerIcon = false,
        bg,
        iconBg,
        isLoading = false,
        className: classNameProp,
        isExcludeSrcStaticImgPlug = true,
        tabIndex: tabIndexProp,
        useWhiteSubstrate = true,
        isStayOverlayBg = false,
    } = props;

    const [isErrorImg, setIsErrorImg] = useState(false);
    const [isSuccessImg, setIsSuccessImg] = useState(false);

    const bgColor = bg || initials?.bg;
    const initialsText = initials?.text;
    const tabIndex = isLoading ? -1 : tabIndexProp;

    const className = cc([
        classNameProp,
        'autotest__avatar',
        {
            'autotest__avatar-loading': isLoading,
            'autotest__avatar-initials-mode': initialsText,
            autotest__avatar__error: isErrorImg,
        },
    ]);

    const src = useMemo(() => {
        if (!srcProp) {
            return undefined;
        }

        const isExcludeSrcImg = isExcludeSrcStaticImgPlug
            ? EXCLUDE_IMG_ARR_SRC.some((str) => srcProp.includes(str))
            : false;

        if (isExcludeSrcImg) {
            return undefined;
        }

        return srcProp;
    }, [srcProp, isExcludeSrcStaticImgPlug]);

    const isShowAvatarIcon = !initialsText && (!src || isErrorImg);

    const renderPlug = () => {
        const initialsTextColor = initials?.textColor;

        const UserIcon = userIcon || Styled.UserIcon;

        return (
            <>
                {initialsText && (
                    <Styled.InitialsTextWrapper>
                        <Styled.InitialsText color={initialsTextColor}>{initialsText}</Styled.InitialsText>
                    </Styled.InitialsTextWrapper>
                )}
                {isShowAvatarIcon && (
                    <Styled.AvatarIconWrapper centerIcon={centerIcon} bg={iconBg}>
                        <UserIcon />
                    </Styled.AvatarIconWrapper>
                )}
            </>
        );
    };

    const imgAlt = alt || 'avatar';
    const imgSrc = src || undefined;

    const renderContent = () =>
        isLoading ? undefined : (
            <>
                {src && (
                    <Styled.AvatarImage
                        src={imgSrc}
                        alt={imgAlt}
                        onError={() => {
                            setIsErrorImg(true);
                        }}
                        onLoad={() => {
                            setIsSuccessImg(true);
                        }}
                        isDisableDefaultBg
                    />
                )}
                {renderPlug()}
            </>
        );

    return (
        <Styled.Avatar
            size={size}
            className={className}
            isLoading={isLoading}
            tabIndex={tabIndex}
            borderRadius={borderRadius}
            useWhiteSubstrate={useWhiteSubstrate}
        >
            <Styled.AvatarColorWrapper
                bg={bgColor}
                isShowAvatarIcon={isShowAvatarIcon}
                isStayOverlayBg={isStayOverlayBg}
                isSuccessImg={isSuccessImg}
                isLoading={isLoading}
            >
                {renderContent()}
            </Styled.AvatarColorWrapper>
        </Styled.Avatar>
    );
});
