import { mergeByPath } from 'services/utils/merge';

import { SaveUserActions, SAVE_USER_ACTION_TYPES } from './actions';

export const saveSuccess = <State>(
    state: State,
    payload: Extract<SaveUserActions, { type: typeof SAVE_USER_ACTION_TYPES[1] }>['payload'],
) => {
    const { data } = payload;
    if ('errors' in data) return state;
    const { user } = data;
    if (!user) return state;
    const { firstName, lastName, displayName, description, image, images, cover, socialNets } = user;
    const { facebook, instagram, personalSite, telegram, vkontakte } = socialNets || {};
    return mergeByPath(state, 'user', {
        firstName,
        lastName,
        displayName,
        description,
        socialNets: {
            facebook,
            instagram,
            personalSite,
            telegram,
            vkontakte,
        },
        image,
        images,
        cover,
    });
};
