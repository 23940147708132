import { createElement } from 'react';

import { Footer } from '../components/footer';
import { MenuContext, MenuData } from '../interfaces';

export async function getExtrasMenu({ dic, lcid }: MenuContext): Promise<MenuData> {
    return {
        name: 'extras',
        showCloseButton: true,
        onClick: 'close',
        headerContent: dic.word('wt_all__widget_menu__main_extras'),
        footerContent: createElement(Footer),
        items: [
            {
                id: 'about',
                text: dic.word('wt_all__widget_menu__extras_about'),
                href: lcid === 'ru' ? 'https://info.limex.me/ru' : 'https://info.limex.me/about-limex-en',
            },
            {
                id: 'waiver',
                text: dic.word('wt_all__widget_menu__extras_waiver'),
                href:
                    lcid === 'ru'
                        ? 'https://www.info.limex.me/ru/disclaimer'
                        : 'https://www.info.limex.me/en/disclaimer',
            },
            {
                id: 'privacy_policy',
                text: dic.word('wt_all__widget_menu__extras_privacy_policy'),
                href:
                    lcid === 'ru'
                        ? 'https://www.info.limex.me/ru/privacy-policy'
                        : 'https://www.info.limex.me/en/privacy-policy/',
            },
            {
                id: 'cookie_policy',
                text: dic.word('wt_all__widget_menu__extras_cookies'),
                href:
                    lcid === 'ru'
                        ? 'https://www.info.limex.me/ru/cookies-and-trackers'
                        : 'https://www.info.limex.me/en/cookies-and-trackers/',
            },
            {
                id: 'usage_policy',
                text: dic.word('wt_all__widget_menu__extras_usage_policy'),
                href:
                    lcid === 'ru'
                        ? 'https://www.info.limex.me/ru/terms-and-conditions'
                        : 'https://www.info.limex.me/en/terms-and-conditions/',
            },
            ...(lcid === 'ru'
                ? [
                      {
                          id: 'community_rules',
                          text: dic.word('wt_all__widget_menu__extras_community_rules'),
                          href: 'https://www.info.limex.me/ru/community-guidelines',
                      },
                  ]
                : []),
            {
                id: 'faq',
                text: dic.word('wt_all__widget_menu__extras_faq'),
                href: lcid === 'ru' ? 'https://www.info.limex.me/ru/faq' : 'https://www.info.limex.me/en/faq',
            },
        ],
    };
}
