const PREFIXES = {
    24: 'Y',
    21: 'Z',
    18: 'E',
    15: 'P',
    12: 'T',
    9: 'B',
    6: 'M',
    3: 'K',
    0: '',
    '-3': 'm',
    '-6': 'µ',
    '-9': 'n',
    '-12': 'p',
    '-15': 'f',
    '-18': 'a',
    '-21': 'z',
    '-24': 'y',
};

const ROUND_DELTA = 0.0001;

function getExponent(number) {
    if (number === 0) {
        return 0;
    }
    const exp = Math.log10(Math.abs(number));
    const rounded = Math.round(exp);
    const floored = Math.floor(exp);
    return Math.abs(rounded - exp) < ROUND_DELTA ? rounded : floored;
}

function precise(number, decimalPrecise) {
    return Number.parseFloat(number.toFixed(decimalPrecise));
}

export function numberToHumanString(inputNumber, decimalPrecise = 2, formatter = (x) => x) {
    if (isNaN(inputNumber) || inputNumber === null) {
        return '-';
    }
    const roundedNumber = precise(Number.parseFloat(inputNumber), decimalPrecise);

    const divisionOrder = Math.max(Math.min(3 * Math.floor(getExponent(roundedNumber) / 3), 24), -24);

    // eslint-disable-next-line no-restricted-properties
    const result = formatter(precise(roundedNumber / Math.pow(10, divisionOrder), decimalPrecise)).toString();
    return result + PREFIXES[divisionOrder];
}
