import React from 'react';

import { MenuItemProps } from '../types';
import * as Styled from '../styled';

import { RightGroup } from './RightGroup';
import { MenuItemContainer } from './MenuItemContainer';

export type { MenuItemProps };

export const MenuItem = React.memo((props: MenuItemProps) => {
    const {
        disabled = false,
        isSelected = false,
        isHovered = false,
        className = '',
        kind = 'primary',
        href,
        rightGroup,
        tabIndex,
        width,
        size = 'regular',
        onClick,
        // IconedTextProps
        icon,
        autotestClass,
        ...restIconedTextProps
    } = props;

    return (
        <MenuItemContainer
            disabled={disabled}
            isSelected={isSelected}
            isHovered={isHovered}
            className={className}
            onClick={disabled ? undefined : onClick}
            kind={kind}
            href={href}
            tabIndex={tabIndex || 0}
            width={width}
            size={size}
            autotestClass={autotestClass}
        >
            <>
                <Styled.IconedText
                    size={size}
                    isSelected={isSelected}
                    kind={kind}
                    isHovered={isHovered}
                    rightGroup={rightGroup}
                    icon={
                        icon
                            ? {
                                  size: 24,
                                  gap: '12px',
                                  ...icon,
                              }
                            : undefined
                    }
                    {...restIconedTextProps}
                />
                {rightGroup && <RightGroup {...rightGroup} disabled={disabled} />}
            </>
        </MenuItemContainer>
    );
});
