import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { reduceBreakpoints, ResponsiveMap } from 'app/styled';
import { body14RegularFontStyle, body17RegularFontStyle, caption12MediumFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import { CircleXMarkIcon } from 'components/icons2/CircleXMarkIcon';
import { IconComponent } from 'components/icons2';

import { Icon, TagProps, TagSize } from './types';

const getPaddingContainer = ({
    Icon: IconLeft,
    IconRight,
    hasClose,
    onlyIcon,
    size,
}: {
    Icon?: IconComponent;
    IconRight?: IconComponent;
    hasClose?: boolean;
    onlyIcon?: boolean;
    size: TagSize;
}) => {
    let topBottom = 4;
    let right = 8;
    let left = 8;

    switch (size) {
        case 'small':
            topBottom = 4;
            if (onlyIcon) {
                left = 4;
                right = 4;
            } else {
                left = IconLeft ? 4 : 8;
                right = IconRight || hasClose ? 4 : 8;
            }
            break;
        case 'medium':
            topBottom = 4;

            if (onlyIcon) {
                left = 4;
                right = 4;
            } else {
                left = IconLeft ? 8 : 12;
                right = IconRight || hasClose ? 8 : 12;
            }

            break;
        case 'large':
            topBottom = 8;
            if (onlyIcon) {
                left = 8;
                right = 8;
            } else {
                left = IconLeft ? 12 : 16;
                right = IconRight || hasClose ? 12 : 16;
            }
            break;
        default:
            break;
    }

    return `padding: ${topBottom}px ${right}px ${topBottom}px ${left}px;`;
};

const getColorContainer = ({
    isActive,
    theme,
    kind,
    isDisable,
}: Pick<TagProps, 'isDisable' | 'kind' | 'isActive'> & { theme: Theme }) => {
    const getSecondaryColor = () => {
        if (isDisable) return theme.fillIn.secondary.overlay8;
        if (isActive) return theme.fillIn.secondary.highest;

        return theme.fillIn.secondary.overlay24;
    };

    const getOutlineBGColor = () => {
        if (isDisable) return 'transparent';
        if (isActive) return theme.fillIn.secondary.overlay16;

        return 'transparent';
    };

    const getOutlineBorderColor = () => {
        if (isDisable) return theme.fillIn.secondary.overlay24;
        if (isActive) return theme.fillIn.secondary.high;

        return theme.fillIn.secondary.low;
    };

    const getPrimaryColor = () => {
        if (isDisable) return theme.staticColors.white;
        if (isActive) return theme.fillIn.primary.overlay16;

        return theme.staticColors.white;
    };

    const getFocusOutlineColor = () => (isActive ? theme.fillIn.primary.overlay24 : theme.fillIn.primary.overlay16);

    const getPrimaryHoverColor = () => (isActive ? theme.fillIn.primary.overlay24 : theme.fillIn.secondary.overlay16);
    const getPrimaryActiveColor = () => (isActive ? theme.fillIn.primary.overlay24 : theme.fillIn.secondary.overlay24);

    const getSecondaryHoverColor = () => (isActive ? theme.fillIn.secondary.highest : theme.fillIn.secondary.overlay32);
    const getSecondaryActiveColor = () => (isActive ? theme.fillIn.secondary.highest : theme.fillIn.secondary.low);

    const getOutlineHoverBGColor = () =>
        isActive ? theme.fillIn.secondary.overlay24 : theme.fillIn.secondary.overlay8;
    const getOutlineHoverBorderColor = () => (isActive ? theme.fillIn.secondary.highest : theme.fillIn.secondary.mid);
    const getOutlineActiveBGColor = () => theme.fillIn.secondary.overlay24;
    const getOutlineActiveBorderColor = () => theme.fillIn.secondary.highest;

    switch (kind) {
        case 'secondary':
            return `
                background-color: ${getSecondaryColor()};

                &:hover {
                    background-color: ${getSecondaryHoverColor()};
                }

                &:active {
                    background-color: ${getSecondaryActiveColor()};
                }

                &:focus-visible {
                    outline: 3px solid ${getFocusOutlineColor()};
                }
            `;
        case 'outline':
            return `
                background-color: ${getOutlineBGColor()};
                box-shadow: 0 0 0 1px ${getOutlineBorderColor()};

                &:hover {
                    background-color: ${getOutlineHoverBGColor()};
                    box-shadow: 0 0 0 1px solid ${getOutlineHoverBorderColor()};
                }

                &:active {
                    background-color: ${getOutlineActiveBGColor()};
                    box-shadow: 0 0 0 1px solid ${getOutlineActiveBorderColor()};
                }

                &:focus-visible {
                    outline: 3px solid ${getFocusOutlineColor()};
                }

            `;
        default:
            // primary
            return `
                background-color: ${getPrimaryColor()};

                &:hover {
                    background-color: ${getPrimaryHoverColor()};
                }

                &:active {
                    background-color: ${getPrimaryActiveColor()};
                }

                &:focus-visible {
                    outline: 3px solid ${getFocusOutlineColor()};
                }
            `;
    }
};

export const TagWrapper = styled.div<
    Required<Pick<TagProps, 'size'>> &
        Pick<TagProps, 'IconRight' | 'Icon' | 'isActive' | 'isDisable' | 'kind'> & {
            hasClose: boolean;
            onlyIcon: boolean;
        }
>`
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 360ms;
    ${({ size, Icon: IconLeft, IconRight, hasClose, onlyIcon }) =>
        reduceBreakpoints(size, (_size) =>
            getPaddingContainer({
                Icon: IconLeft,
                IconRight,
                hasClose,
                onlyIcon,
                size: _size,
            }),
        )};
    ${getColorContainer};
    ${ifProp(
        'isDisable',
        css`
            pointer-events: none;
        `,
    )};
`;

const getIconWrapperSvgCssByTagSize = (size: TagSize): string => {
    let result = 24;

    if (size === 'small') {
        result = 16;
    }

    return `
        width: ${result}px;
        height: ${result}px;
    `;
};

const getColorText = ({
    isActive,
    isDisable,
    theme,
    kind,
}: Pick<TagProps, 'isActive' | 'isDisable' | 'kind'> & { theme: Theme }) => {
    const getSecondaryColorText = () => {
        if (isDisable) {
            return theme.label.inactive;
        }
        if (isActive) {
            return theme.label.inverse;
        }

        return theme.label.primary;
    };

    const getOutlineColorText = () => {
        if (isDisable) {
            return theme.label.inactive;
        }
        if (isActive) {
            return theme.label.secondaryHigh;
        }

        return theme.label.secondary;
    };

    const getPrimaryColorText = () => {
        if (isDisable) {
            return theme.label.inactive;
        }
        if (isActive) {
            return theme.label.accent;
        }

        return theme.label.primary;
    };

    switch (kind) {
        case 'secondary':
            return `
                color: ${getSecondaryColorText()};
            `;
        case 'outline':
            return `
                color: ${getOutlineColorText()};
            `;
        case 'primary':
            return `
                color: ${getPrimaryColorText()};
            `;
        default:
            return `
                color: ${getPrimaryColorText};
            `;
    }
};

export const IconWrapper = styled.div<
    Required<Pick<TagProps, 'size' | 'isActive' | 'isDisable' | 'kind'>> &
        Pick<TagProps, 'size'> & {
            pos: 'left' | 'right';
            onlyIcon: boolean;
        }
>`
    display: inline-block;

    & svg {
        display: block;
        ${({ size }) => reduceBreakpoints(size, (_size) => getIconWrapperSvgCssByTagSize(_size))};
        ${getColorText};
        opacity: ${({ isDisable }) => (isDisable ? 0.16 : 1)};
    }
`;

const textCssHandler = ({
    Icon: IconLeft,
    IconRight,
    hasClose,
    size,
}: {
    Icon?: IconComponent;
    IconRight?: IconComponent;
    hasClose?: boolean;
    size?: TagSize;
}) => {
    let topBottom = 0;
    let right = 8;
    let left = 8;

    let font = body17RegularFontStyle;

    switch (size) {
        case 'small':
            topBottom = 0;
            left = IconLeft ? 4 : 0;
            right = IconRight || hasClose ? 4 : 0;
            font = caption12MediumFontStyle;
            break;
        case 'medium':
            topBottom = 2;
            left = IconLeft ? 4 : 0;
            right = IconRight || hasClose ? 4 : 0;
            font = body14RegularFontStyle;
            break;
        case 'large':
            topBottom = 0;
            left = IconLeft ? 8 : 0;
            right = IconRight || hasClose ? 8 : 0;
            font = body17RegularFontStyle;
            break;
        default:
            break;
    }
    return `
        ${font};
        padding: ${topBottom}px ${right}px ${topBottom}px ${left}px;
    `;
};

export const Text = styled.span<
    Required<Pick<TagProps, 'size'>> &
        Pick<TagProps, 'Icon' | 'IconRight' | 'kind' | 'isActive' | 'isDisable'> & {
            hasClose?: boolean;
            onlyIcon?: boolean;
        }
>`
    transition: all 360ms;
    white-space: nowrap;
    ${getColorText};
    ${({ size, IconRight, Icon: IconLeft, hasClose }) =>
        reduceBreakpoints(size, (_size) =>
            textCssHandler({
                size: _size,
                Icon: IconLeft,
                IconRight,
                hasClose,
            }),
        )};
`;

const iconCloseCssHandler = (sizeTag: TagSize, IconRight?: Icon): string => {
    let result = 0;
    if (sizeTag === 'medium' && IconRight) {
        result = 2;
    }
    if (sizeTag === 'large' && IconRight) {
        result = 4;
    }

    return `margin-left: ${result}px`;
};

export const IconClose = styled(CircleXMarkIcon)<
    Pick<TagProps, 'IconRight' | 'isActive' | 'isDisable' | 'kind'> & {
        sizeTag: TagSize | ResponsiveMap<TagSize>;
    }
>`
    cursor: pointer;
    ${({ isActive, isDisable, theme, kind }) => {
        if (kind === 'primary' || kind === 'secondary') {
            if (isDisable) {
                return `color: ${theme.label.inactive}`;
            }
            if (isActive) {
                return `color: ${kind === 'primary' ? theme.label.primary : theme.label.inverse}`;
            }

            return `color: ${theme.label.secondary}`;
        }
        return getColorText({
            isActive,
            isDisable,
            theme,
            kind,
        });
    }};
    ${({ sizeTag, IconRight }) => reduceBreakpoints(sizeTag, (_size) => iconCloseCssHandler(_size, IconRight))};
`;

export const CloseButton = styled.div`
    display: inline-flex;
`;
