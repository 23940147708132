import styled from 'styled-components';

import { body14MediumFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const File = styled.div`
    height: 48px;
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    ${body14MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    & svg {
        fill: ${({ theme }) => theme.label.secondary};
    }

    & > span {
        margin-left: 6px;
    }

    & > button {
        margin-left: auto;
    }
`;
