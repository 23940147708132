import produce, { Draft } from 'immer';

import { ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

import { UpdateThunkAction } from './types';
import { updateResource } from './updateResource';

export function createUpdateResourceThunk<TYPES_MAP extends object, RESOURCE_NAME extends string, RESOURCE_TYPE>(
    resourcesMap: ResourcesMap<TYPES_MAP>,
    name: RESOURCE_NAME,
) {
    return (id: unknown, updater: (resource: Draft<RESOURCE_TYPE>) => void): UpdateThunkAction =>
        (dispatch, getState) => {
            const {
                cache: { data },
            } = getState();
            const resource: RESOURCE_TYPE | undefined = cacheRead({
                kind: 'resource',
                cache: data,
                resourcesMap,
                typename: name,
                id: `${id}`,
            });
            if (resource) {
                const newValue = produce(resource, (draft: Draft<RESOURCE_TYPE>) => updater(draft));
                dispatch(updateResource<RESOURCE_NAME, RESOURCE_TYPE>('resource', name, newValue));
            }
        };
}
