import React from 'react';

import { Option as OptionType } from '../types';

import * as Styled from './styled/Option';

export const Option = (props: Partial<OptionType>) => {
    const {
        // value,
        title,
        number,
        // sum,
        // profitabilityValue,
        // profitability = 'neutral',
    } = props;

    return (
        <Styled.InnerOptionWrapper>
            <Styled.ComplexItem title={title || ''} secondLine={number || ''} size="small" />
            {/* <Styled.Details> */}
            {/*    <Styled.DetailsSum>{sum}</Styled.DetailsSum> */}
            {/*    <Styled.DetailsSecondLine profitability={profitability}>{profitabilityValue}</Styled.DetailsSecondLine> */}
            {/* </Styled.Details> */}
        </Styled.InnerOptionWrapper>
    );
};
