import styled from 'styled-components';

import { Header as HeaderComponent } from 'widgets/header-notifications/components/Header';

export const Header = styled(HeaderComponent)`
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 0 4px 0 8px;
    border-bottom: none;
`;
