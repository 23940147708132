import React, { ReactNode } from 'react';

import { CurrencyCode } from 'types/currency';
import { PaymentPeriod } from 'types/payment';
import { useDictionary } from 'hooks/useDictionary';

import { PriceFormatter } from './price-formatter';

export type PricePeriodFormatterResult = {
    localizedPrice: string;
    localizedPeriod: string;
};

export type PricePeriodFormatterHandler = (result: PricePeriodFormatterResult) => ReactNode;

export type PricePeriodFormatterProps = {
    price: number;
    currency: CurrencyCode | null;
    period?: PaymentPeriod;
    children: PricePeriodFormatterHandler;
};

/**
 * Provides a locale-formatted price period information via children render prop.
 *
 * @example
 * <PricePeriodFormatter amount={31} currency="USD" period="month">
 *     {({ localizedPrice, localePeriod }) => (
 *         <div>Price: {localizedPrice} / {localePeriod}</div>
 *     )}
 * </PricePeriodFormatter>
 */
export const PricePeriodFormatter = ({ price, period = 'fixed', currency, children }: PricePeriodFormatterProps) => {
    const dic = useDictionary();
    return (
        <PriceFormatter price={price} currency={currency}>
            {(localizedPrice) =>
                children({
                    localizedPrice,
                    localizedPeriod: period === 'fixed' ? '' : dic.word(`wt_all__widget_formatting__${period}_word`),
                })
            }
        </PriceFormatter>
    );
};
