import React from 'react';

import { Props as WtFieldProps } from 'components/wt-field';

import { FormField } from '..';

import { PopupFormTextInput, ErrorMessage } from './styled';

export type Props = WtFieldProps & {
    errorMessage?: string;
};

export const Textfield = (props: Props) => {
    const { className, errorMessage, fieldProps, size, kind, floatingLabel, tag, visualState, recalculateHeight } =
        props;
    return (
        <FormField className={className}>
            <PopupFormTextInput
                fieldProps={fieldProps}
                size={size}
                kind={kind}
                floatingLabel={floatingLabel}
                tag={tag}
                visualState={visualState}
                recalculateHeight={recalculateHeight}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </FormField>
    );
};
