import React, { useMemo } from 'react';

import { ResponsiveMap } from 'app/styled';
import { ButtonProps } from 'components/button2';
import { ButtonSize } from 'components/button2/types';

import { SnackbarProps, SnackbarSize, SnackbarSizeName } from './types';
import { SnackbarIcon } from './SnackbarIcon';
import * as Styled from './styled';

const DEFAULT_SIZE: SnackbarSize = {
    lt768: 'small',
    default: 'regular',
};

const convertSnackbarSizeNameToButtonSize = (sizeItem: SnackbarSizeName): ButtonSize => {
    if (sizeItem === 'regular') {
        return 'medium';
    }
    return 'small';
};

const convertSnackbarSizeToButtonSize = (snackbarSize: SnackbarSize): ButtonProps['size'] => {
    if (typeof snackbarSize === 'string') {
        return convertSnackbarSizeNameToButtonSize(snackbarSize);
    }

    if (typeof snackbarSize === 'object' && Object.entries(snackbarSize).length > 0) {
        const responsiveSize = Object.entries(snackbarSize).reduce(
            (prev, [key, value]) => ({
                ...prev,
                [key]: convertSnackbarSizeNameToButtonSize(value),
            }),
            {} as ResponsiveMap<ButtonSize>,
        );

        return responsiveSize;
    }

    return 'medium';
};

export const Snackbar = (props: SnackbarProps) => {
    const {
        text,
        onMouseEnter,
        onMouseLeave,
        size = DEFAULT_SIZE,
        button,
        icon,
        className: classNameProp,
        type = 'default',
    } = props;

    const hasIcon = !!icon?.component;
    const hasText = !!text;
    const hasButton = !!button?.content && !!button?.onClick;
    const hasOnlyButton = !hasText && !hasIcon && hasButton;
    const hasOnlyIcon = !hasText && !hasButton && hasIcon;
    const hasDivider = (hasIcon || hasText) && hasButton;
    const sizeBtn = useMemo(() => convertSnackbarSizeToButtonSize(size), [size]);

    const className = useMemo(() => [classNameProp, 'autotest__snackbar'].filter((i) => i).join(' '), [classNameProp]);

    if (!hasIcon && !hasText && !hasButton) {
        return null;
    }

    return (
        <Styled.SnackbarWrapper
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            size={size}
            type={type}
            hasButton={hasButton}
            hasOnlyButton={hasOnlyButton}
            hasOnlyIcon={hasOnlyIcon}
            hasIcon={hasIcon}
            className={className}
        >
            <Styled.Content>
                {hasIcon && <SnackbarIcon hasIndent={!hasOnlyIcon} type={type} size={size} {...icon} />}
                {hasText && <Styled.Text type={type}>{text}</Styled.Text>}
            </Styled.Content>
            {hasDivider && <Styled.Divider />}
            {hasButton && (
                <Styled.Button kind="ghost-secondary" size={sizeBtn} onClick={button?.onClick}>
                    {button?.content}
                </Styled.Button>
            )}
        </Styled.SnackbarWrapper>
    );
};
