import React, { FocusEvent, forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import noop from 'lodash/noop';

import { ErrorMessage, InlineLabel, LeftGroup } from 'components/text-input/styled';
import { RightGroup } from 'components/text-input/RightGroup';

import { useNumberInputFormatter } from './hooks/useNumberInputFormatter';
import { NumberInputStyled, NumberWrapper } from './styled';
import { NumberInputProps } from './types';
import { Stepper } from './Stepper';
import { useNumberKeys } from './hooks/useNumberKeys';
import { isFullNumber } from './isFullNumber';

export const NumberInput = memo(
    forwardRef<HTMLInputElement, NumberInputProps>(
        (
            {
                kind = 'fill',
                size = 'large',
                label,
                placeholder,
                readonly = false,
                disabled = false,
                error,
                errorHighlight = false,
                value,
                onChange,
                onBlur,
                className,
                wrapperRef,
                locale,
                currencySign,
                min,
                max,
                iconButtonTabIndex,
            },
            forwardedRef,
        ) => {
            const [stringValue, setStringValue] = useState(value.toString());

            useEffect(() => {
                setStringValue(value.toString());
            }, [value]);

            const handleChange = useCallback(
                (newValue: string) => {
                    let parsedValue = newValue;
                    if (!Number.isNaN(parseFloat(parsedValue))) {
                        const numValue = parseFloat(parsedValue);
                        if (min !== undefined && numValue < min) {
                            parsedValue = min.toString();
                        }
                        if (max !== undefined && numValue > max) {
                            parsedValue = max.toString();
                        }
                    }

                    setStringValue(parsedValue);
                    const numberValue = parseFloat(parsedValue);
                    const numberOrEmpty = Number.isNaN(numberValue) ? '' : numberValue;
                    onChange(numberOrEmpty);
                },
                [max, min, onChange],
            );

            const handleBlur = useCallback(
                (e: FocusEvent<HTMLInputElement>) => {
                    let clampedValue = value;
                    if (min !== undefined && value < min) {
                        clampedValue = min;
                    }
                    if (max !== undefined && value > max) {
                        clampedValue = max;
                    }
                    setStringValue(clampedValue.toString());
                    onChange(clampedValue);
                    onBlur?.(e);
                },
                [max, min, onBlur, onChange, value],
            );

            const handleKeyChange = useCallback(
                (newValue: string) => {
                    const numberValue = parseFloat(newValue);
                    let numberOrEmpty = Number.isNaN(numberValue) ? ('' as const) : numberValue;
                    if (numberOrEmpty !== '') {
                        if (min !== undefined && numberOrEmpty < min) {
                            numberOrEmpty = min;
                        }
                        if (max !== undefined && numberOrEmpty > max) {
                            numberOrEmpty = max;
                        }
                    }
                    onChange(numberOrEmpty);
                    setStringValue(numberOrEmpty.toString());
                },
                [max, min, onChange],
            );

            const ref = useRef<HTMLInputElement | null>(null);

            const formattedValue = useNumberInputFormatter(ref, locale, stringValue, handleChange, currencySign);

            useNumberKeys(ref, stringValue, handleKeyChange);

            return (
                <div>
                    <NumberWrapper
                        kind={kind}
                        size={size}
                        error={!!error || errorHighlight}
                        disabled={disabled}
                        hasLabel={!!label}
                        hasText={stringValue.length > 0}
                        className={className}
                        ref={wrapperRef}
                    >
                        <LeftGroup>
                            {label ? <InlineLabel>{label}</InlineLabel> : null}
                            <NumberInputStyled
                                ref={(element) => {
                                    if (typeof forwardedRef === 'function') {
                                        forwardedRef(element);
                                    } else if (forwardedRef) {
                                        forwardedRef.current = element;
                                    }
                                    ref.current = element;
                                }}
                                placeholder={placeholder || ' '}
                                value={formattedValue ?? ''}
                                onChange={noop}
                                onBlur={handleBlur}
                                pattern="\d*"
                                title=""
                                disabled={disabled || readonly}
                            />
                        </LeftGroup>
                        <RightGroup readonly={readonly} iconButtonTabIndex={iconButtonTabIndex} />
                        {readonly ? null : (
                            <Stepper value={stringValue} onChange={handleKeyChange} disabled={disabled} />
                        )}
                    </NumberWrapper>
                    {error && !disabled && !readonly ? <ErrorMessage>{error}</ErrorMessage> : null}
                </div>
            );
        },
    ),
);
