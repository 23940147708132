import { connect } from 'react-redux';

import { TagData } from 'types';
import { notEmpty } from 'services/utils/not-empty';

import { State } from '../../types';
import { TabIds } from '../tabs';

import { TagsSlider } from './components';
import { TagsSliderState } from './types';

const mapTabIdToTagsType: Record<TabIds, keyof TagsSliderState> = {
    feed: 'default',
    premiumPosts: 'premium',
    authorsProducts: 'authorsProducts',
    likesAndComments: 'likesAndComments',
};

const mapStateToProps = (state: State, ownProps: { tabId: TabIds }) => {
    const {
        widgets: { tags, tagsSlider },
    } = state;
    const { tabId } = ownProps;
    const tagsForSlider: TagData[] = tagsSlider[mapTabIdToTagsType[tabId]]
        .map((tagSliderId) => tags.tags.find((tag) => tag.id === tagSliderId))
        .filter(notEmpty);
    return {
        tagsForSlider,
    };
};

export const TagsSliderContainer = connect(mapStateToProps)(TagsSlider);
