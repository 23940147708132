/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Finam_AspNetCore_Extensions_WebResponse } from '../models/shared/Finam_AspNetCore_Extensions_WebResponse';
import type { Marketplace_Social_Web_Public_V1_My_Reviews_PublishReviewWebRequest } from '../models/MyReviewsService/Marketplace_Social_Web_Public_V1_My_Reviews_PublishReviewWebRequest';
import type { Marketplace_Social_Web_Public_V1_My_Reviews_UpdateReviewWebRequest } from '../models/MyReviewsService/Marketplace_Social_Web_Public_V1_My_Reviews_UpdateReviewWebRequest';
import type { WebResponse_ReviewDto } from '../models/MyReviewsService/WebResponse_ReviewDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyReviewsService {
    /**
     * Опубликовать отзыв
     * @returns WebResponse_ReviewDto Success
     * @throws ApiError
     */
    public static createReview({
        requestBody,
    }: {
        /**
         * Данные отзыва
         */
        requestBody?: Marketplace_Social_Web_Public_V1_My_Reviews_PublishReviewWebRequest;
    }): CancelablePromise<WebResponse_ReviewDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/reviews',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Обновить отзыв
     * @returns WebResponse_ReviewDto Success
     * @throws ApiError
     */
    public static updateReview({
        requestBody,
    }: {
        /**
         * Данные отзыва
         */
        requestBody?: Marketplace_Social_Web_Public_V1_My_Reviews_UpdateReviewWebRequest;
    }): CancelablePromise<WebResponse_ReviewDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/reviews',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Удалить отзыв
     * @returns Finam_AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static deleteReview({
        id,
    }: {
        /**
         * Идентификатор отзыва
         */
        id: number;
    }): CancelablePromise<Finam_AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/reviews/{id}',
            path: {
                id: id,
            },
        });
    }
}
