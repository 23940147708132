import { useEffect, useCallback } from 'react';

import { useKeyPress } from './useKeyPress';
import { useNonClosableRef, useNonClosableRefState } from './useUnclosureRef';

type Params<T extends { key: string | number }> = {
    element?: EventTarget | null;
    onUp?: (index: number | null) => void;
    onDown?: (index: number | null) => void;
    onSelect?: (item: T, index?: number, event?: KeyboardEvent) => void;
    focusFirst?: boolean;
};

/**
 * Hook for scrolling through the list and selecting items by keyboard in uncontrolled way.
 * element - element to listen events, window by default. Events are not listened if element === null.
 * Focus on first element after items.length change, if focusFirst = true and current element = null.
 *
 * @example
 * const textareaRef = useRef(null);
 * const items = [{ key: 1 }, { key: 1 }, { key: 1 }];
 *
 * const [current, cursor] = useListCursor(items, {
 *   element: textareaRef.current,
 *   onUp: (i) => console.log('up', i),
 *   onDown: (i) => console.log('down', i),
 *   onSelect: (item) => console.log('select', item),
 * };
 *
 * return (
 *     <>
 *         <textarea ref={textareaRef} value={cursor} />
 *         <ListWrapper>
 *             {items.map((item) => <Item isSelected={item.key === current?.key} />)}
 *         </ListWrapper>
 *     </>
 * )
 */

export const useListCursor = <T extends { key: string | number }>(
    items: T[],
    { onUp, onDown, onSelect, element = window, focusFirst }: Params<T>,
): [T | null, number | null] => {
    const [cursorRef, cursor, setCursor] = useNonClosableRefState<null | number>(null);
    const itemsRef = useNonClosableRef(items);

    const downCallback = useCallback(
        (e?: KeyboardEvent) => {
            e?.preventDefault();

            if (items.length) {
                setCursor(
                    (state) => (state === null || state + 1 >= items.length ? 0 : state + 1),
                    (state) => onDown?.(state),
                );
            }
        },
        [onDown, items],
    );

    const upCallback = useCallback(
        (e?: KeyboardEvent) => {
            e?.preventDefault();

            if (items.length) {
                setCursor(
                    (state) => (state === null || state === 0 ? items.length - 1 : state - 1),
                    (state) => onUp?.(state),
                );
            }
        },
        [onUp, items],
    );

    const enterCallback = (e?: KeyboardEvent) => {
        e?.preventDefault();
        if (cursorRef.current !== null && itemsRef.current[cursorRef.current]) {
            onSelect?.(itemsRef.current[cursorRef.current], cursorRef.current, e);
        }
    };

    useKeyPress({
        key: 'ArrowDown',
        refElement: { current: element },
        onKeyDown: downCallback,
    });
    useKeyPress({
        key: 'ArrowUp',
        refElement: { current: element },
        onKeyDown: upCallback,
    });
    useKeyPress({
        key: 'Enter',
        refElement: { current: element },
        onKeyDown: enterCallback,
        onKeyDownOpts: { capture: true, once: true },
    });

    useEffect(() => {
        if (cursor !== null && cursor >= items.length) {
            setCursor(null);
        }
    }, [items.length, cursor]);

    useEffect(() => {
        if (focusFirst && cursor === null && items.length > 0) {
            setCursor(0);
        }
    }, [items.length, focusFirst, cursor]);

    const item = cursor === null ? null : items[cursor] ?? null;

    return [item, cursor];
};
