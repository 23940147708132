import {
    DefaultProductItem,
    ProductItem,
    StrategyProductItem,
    StrategyUsProductItem,
    TypeProductItem,
} from 'types/product';
import Dic from 'services/dictionary';
import { InstrumentCardDefaultParameters } from 'components/card/types';

import { defaultProductTypeMapByProductTypeFromBackend } from './constants';

export const getServiceTypeText = (item: DefaultProductItem): string | undefined => {
    const { serviceType = null, serviceTypeCustom = null } = item;

    if (!serviceType || (serviceType === 'custom' && !serviceTypeCustom)) {
        return undefined;
    }

    const dicwords = {
        analytics: 'wt_all__product_card_widget__default__service_type__analytics',
        closedChat: 'wt_all__product_card_widget__default__service_type__closedChat',
        custom: undefined,
    };

    const serviceTypeDicwordKey = dicwords[serviceType];

    return serviceTypeDicwordKey ? Dic.word(serviceTypeDicwordKey) : serviceTypeCustom || undefined;
};

export function makeStrategyParameters({
    riskLevel,
    minSumForAutofollowing,
    yield365Days,
    minSumForAutofollowingCurrencySign = '₽',
}: Pick<StrategyProductItem, 'riskLevel' | 'minSumForAutofollowing' | 'yield365Days'> & {
    minSumForAutofollowingCurrencySign?: string;
}): InstrumentCardDefaultParameters {
    const arrParamsWithDic: Array<{
        name: string;
        value: string | number | undefined;
    }> = [
        {
            name: Dic.word('wt_all__product_card_widget__strategy__params_min_sum'),
            value:
                (minSumForAutofollowing &&
                    `${formatWithThousandsSeparator(minSumForAutofollowing)} ${minSumForAutofollowingCurrencySign}`) ||
                undefined,
        },
        {
            name: Dic.word('wt_all__product_card_widget__strategy__params_profit'),
            value: (yield365Days && `${signChar(yield365Days)}${round2Digits(yield365Days)} %`) || undefined,
        },
        {
            name: Dic.word('wt_all__product_card_widget__strategy__params_risk'),
            value: riskLevel
                ? Dic.word(
                      `wt_all__product_card_widget__strategy__params_risk_value_${
                          [1, 2, 3].includes(riskLevel) ? riskLevel : 1
                      }`,
                  )
                : 1,
        },
    ];

    return arrParamsWithDic
        .filter((paramObj) => !!paramObj.value)
        .map((paramObj) => ({
            name: paramObj.name,
            value: String(paramObj.value),
        }));
}

export function round2Digits(value: number) {
    return Math.round(value * 100) / 100;
}

export function signChar(value: number) {
    return value > 0 ? '+' : '';
}

function formatWithThousandsSeparator(amount: number) {
    const result: string[] = [];
    const digits = Array.from(amount.toString());

    let countIdx = 0;
    for (let i = digits.length - 1; i >= 0; i--) {
        result.push(digits[i]);

        if (++countIdx === 3 && i !== 0) {
            countIdx = 0;
            result.push(' ');
        }
    }

    result.reverse();

    return result.join('');
}

export const getProductTypeByItem = (item: ProductItem): TypeProductItem => {
    if ('graphUrl' in item) {
        return 'strategy';
    }

    if ('stats' in item) {
        return 'strategyUS';
    }

    if ('productType' in item) {
        const productType = defaultProductTypeMapByProductTypeFromBackend[item?.productType];
        if (productType) {
            return productType;
        }
    }

    return defaultProductTypeMapByProductTypeFromBackend.product;
};

export const isDefaultProductType = (typeProduct: TypeProductItem): boolean => {
    const arr = Object.values(defaultProductTypeMapByProductTypeFromBackend);

    return arr.includes(typeProduct);
};

export const isStrategyProductItem = (item: ProductItem): item is StrategyProductItem =>
    getProductTypeByItem(item) === 'strategy';

export const isStrategyUsProductItem = (item: ProductItem): item is StrategyUsProductItem =>
    getProductTypeByItem(item) === 'strategyUS';

export const isDefaultProductItem = (item: ProductItem): item is DefaultProductItem =>
    isDefaultProductType(getProductTypeByItem(item));
