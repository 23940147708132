import { Action, PublishStatusRecord } from 'types';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';
import { BlogService } from 'services/api-services/generated_limex';

import { GlobalStateForFeed } from '../../types';

const [RESTORE_FEED_RECORD_ACTION_TYPES, restoreThunk, restoreActions] = createThunkActions<
    'widgets',
    GlobalStateForFeed
>('widgets')('RESTORE_FEED_RECORD', BlogService.restorePost)<{ instanceId: string; recordId: string }>();

function restoreFeedRecord(record: PublishStatusRecord, instanceId: string) {
    const {
        id,
        obj: { id: postId },
    } = record;
    return restoreThunk({
        params: {
            postId: +postId,
        },
        pass: { instanceId, recordId: id.toString() },
    });
}

type RestoreFeedRecord = Action<typeof restoreFeedRecord>;
type RestoreFeedRecordActions = Actions<typeof restoreActions>;
export { RESTORE_FEED_RECORD_ACTION_TYPES, restoreFeedRecord, RestoreFeedRecord, RestoreFeedRecordActions };
