export const readFile = (files: FileList): Promise<string | null> =>
    new Promise((resolve) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            resolve(reader.result?.toString() ?? null);
        });

        reader.readAsDataURL(files[0]);
    });
