import React from 'react';
import cc from 'classcat';

import { AvatarLinkProps } from '../types';
import * as Styled from '../styled/AvatarLink.styled';

import { Avatar } from './Avatar';

export const AvatarLink = (props: AvatarLinkProps) => {
    const { href: hrefProp, rel, target, isLoading = false, className: classNameProp, ...restProps } = props;

    const href = isLoading ? undefined : hrefProp;

    const className = cc([classNameProp, 'autotest__avatar-link-wrapper']);

    return (
        <Styled.AvatarLink
            href={href}
            rel={rel}
            target={target}
            className={className}
            tabIndex={-1}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && href) {
                    window.location.href = href;
                }
            }}
        >
            <Avatar {...restProps} isLoading={isLoading} tabIndex={href ? 0 : -1} />
        </Styled.AvatarLink>
    );
};
