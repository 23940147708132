import Deferred from 'services/utils/deferred';
import WtLogger from 'services/utils/wt-logger';

export type Task = {
    name: TaskName;
    defer: Deferred<unknown>;
    finish: (value?: unknown) => void;
    starter: () => void;
};

type TaskName =
    | 'CookiePrivacySettingsPopup'
    | 'DemoAccountSuccessPopup'
    | 'GaWindow'
    | 'InterestsPopup'
    | 'GAUserIdentifiers'
    | 'GAAgreements';

const taskQLogger = WtLogger.register({
    name: 'taskQ',
});

class TaskQ {
    private q: Task[];

    private running: boolean;

    constructor() {
        this.q = [];
        this.running = false;
    }

    public add(task: Task) {
        this.q.push(task);
        taskQLogger.log(`Task ${task.name} added`);
        taskQLogger.log('Current q:', this.displayQ());
        if (!this.running) {
            this.run();
        }
    }

    private async run() {
        this.running = true;
        while (this.q.length > 0) {
            const task = this.q[0];
            task.starter();
            taskQLogger.log(`Task ${task.name} started`);
            // eslint-disable-next-line no-await-in-loop
            await task.defer.promise;
            this.q.shift();
            taskQLogger.log(`Task ${task.name} finished`);
            taskQLogger.log('Current q:', this.displayQ());
        }
        this.running = false;
    }

    private displayQ() {
        return JSON.stringify(this.q.map((task) => task.name));
    }
}

export const taskQ = new TaskQ();

// Функция создания задачи
export function createTask(name: TaskName, starter: () => void): Task {
    const defer = new Deferred();
    return {
        name,
        defer,
        finish: defer.resolve.bind(defer),
        starter,
    };
}
