import type { Step1FormValues } from './types';

export const MIN_LENGTH = 3;
export const MAX_LENGTH = 200;

export const MIN_URL_LENGTH = 5;
export const MAX_URL_LENGTH = 200;

export const MIN_PRICE = 0.5;
export const MAX_PRICE = 10000;

export const defaultValues: Step1FormValues = {
    name: '',
    markets: ['Any'],
    programTypes: ['Desktop'],
    accessUrl: '',
    cost: undefined,
    trial: false,
};
