import { format, parse } from 'date-fns';

import { StatsYieldDataKey, StrategyUsStats } from 'types/product';
import { ArrayElement } from 'types';

import { ChartData } from './types';

export const DEFAULT_STATS_DATA_KEY: StatsYieldDataKey = 'rt';

export const parseDateStringForChart = (dateString: string) => parse(dateString, 'yyyy-MM-dd', new Date());

const getValue = (item: ArrayElement<StrategyUsStats>, dataKey?: StatsYieldDataKey): number => {
    const defaultValue = item[DEFAULT_STATS_DATA_KEY] ?? 0;
    if (!dataKey) {
        return defaultValue;
    }
    return item[dataKey] ?? defaultValue;
};

export const formatChartDataByStrategyUsStats = (data: StrategyUsStats, dataKey?: StatsYieldDataKey): ChartData =>
    data.map((item) => ({
        date: format(parseDateStringForChart(item?.date ? item.date.toString() : ''), 'T'),
        value: getValue(item, dataKey),
    }));

export const chartDataByStrategyUsStats = (data: StrategyUsStats, dataKey?: StatsYieldDataKey): ChartData =>
    data.map((item) => ({
        date: item?.date ? item.date.toString() : '',
        value: getValue(item, dataKey),
    }));
