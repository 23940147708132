import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { useDictionary } from 'hooks/useDictionary';
import { FormLabel, VerticalFormInputGroup } from 'components/form';
import { FormTextArea, FormTextInput } from 'components/form/react-hook-form';
import { Button } from 'components/button2';
import { TrashIcon } from 'components/icons2/TrashIcon';

import * as Styled from './styled';
import { InfoBlockItemFormProps } from './types';

export const InfoBlockForm = ({
    name,
    maxItems,
    maxLengthTitle,
    maxLengthDescription,
    minLengthTitle,
    minLengthDescription,
    className,
    canRemove = false,
    minItems = 0,
}: InfoBlockItemFormProps) => {
    const dic = useDictionary();
    const dicStep5 = useDicWordPrefix('wt_all__widget_product_wizard__step5_');
    const dicWordBlock = useDicWordPrefix('wt_all__widget_product_wizard__text_block_');

    const { fields, append, remove } = useFieldArray({
        name,
    });

    return (
        <>
            <FormLabel>{dic.word('wt_myspace__widget_product_wizard__step5_field_features_group_label')}</FormLabel>
            {fields.map(({ id }, index) => (
                <VerticalFormInputGroup key={id}>
                    <Styled.FormLabel>
                        {dic.word(`wt_myspace__widget_product_wizard__text_block_title_${index + 1}`)}
                    </Styled.FormLabel>
                    <FormTextInput
                        name={`${name}.${index}.title`}
                        kind="outline"
                        size="medium"
                        label={dicWordBlock('title_label')}
                        placeholder={dicWordBlock('title_example')}
                        className={`${className}_${index}_title`}
                        maxLength={maxLengthTitle}
                        rules={{
                            minLength: {
                                value: minLengthTitle,
                                message: dic.word(
                                    'wt_all__widget_product_wizard__step2_field_description_min_message',
                                    { min: minLengthTitle },
                                ),
                            },
                            maxLength: {
                                value: maxLengthTitle,
                                message: dicWordBlock('title_too_long_msg'),
                            },
                        }}
                    />
                    <FormTextArea
                        name={`${name}.${index}.description`}
                        kind="outline"
                        size="medium"
                        label={dic.word('wt_myspace__widget_product_wizard__text_block_description_label')}
                        placeholder={dicWordBlock('description_example')}
                        className={`${className}_${index}_description`}
                        rows={2}
                        infiniteHeight
                        maxLength={maxLengthDescription}
                        rules={{
                            minLength: {
                                value: minLengthDescription,
                                message: dic.word(
                                    'wt_all__widget_product_wizard__step2_field_description_min_message',
                                    { min: minLengthDescription },
                                ),
                            },
                            maxLength: {
                                value: maxLengthDescription,
                                message: dicWordBlock('description_too_long_msg'),
                            },
                        }}
                    />
                </VerticalFormInputGroup>
            ))}
            <Styled.Buttons>
                {fields.length < maxItems ? (
                    <Button
                        onClick={() => append({ title: '', description: '' }, { shouldFocus: false })}
                        size="small"
                        kind="secondary"
                        className="autotest__marketplace__product_wizard__create_info_block1"
                        isPressed
                    >
                        {dicStep5('add_block_btn')}
                    </Button>
                ) : null}
                {canRemove ? (
                    <Button
                        onClick={() => remove(fields.length - 1)}
                        size="small"
                        kind="cancel"
                        className="autotest__marketplace__product_wizard__remove_info_block1"
                        isPressed
                        icon={TrashIcon}
                        isDisabled={fields.length <= minItems}
                    >
                        {dicStep5('remove_block_btn')}
                    </Button>
                ) : null}
            </Styled.Buttons>
        </>
    );
};
