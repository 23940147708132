import { css } from 'styled-components';

import { baseStyles } from './base';

export const outlineStyles = css`
    ${baseStyles};

    background-color: transparent;

    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.low};

    &:hover:not(:focus-within) {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.mid};
    }

    &:focus-within {
        background-color: ${({ theme }) => theme.bg.primary};
        box-shadow: ${({ theme }) => theme.focusOutline.accent},
            inset 0 0 0 1px ${({ theme }) => theme.fillIn.primary.mid};
    }
`;
