import { connect } from 'react-redux';

import { ResourcesState } from 'services/mp-share-resources/types';
import { api } from 'services/api-builder/api';

import { UserProducts } from './UserProducts';

export const mapStateToProps = (state: ResourcesState, { authorId }: { authorId?: string }) => ({
    ...api.selectProducts(state, { authorId }),
    ...api.selectAllFavorites(state),
});

export const mapDispatchToProps = {
    getProducts: api.fetchFirstProducts,
    addProductToFavorites: api.addProductToFavorites,
    removeProductFromFavorites: api.removeProductFromFavorites,
    getMoreProducts: api.fetchMoreProducts,
    getFavorites: api.fetchFirstAllFavorites,
};

export const UserProductsContainer = connect(mapStateToProps, mapDispatchToProps)(UserProducts);
