import React, { useEffect, useRef, useState } from 'react';
import cc from 'classcat';

import { FeedRecord, FeedRecordType, Id, isPublishStatusRecord, isTickerNewsRecord, TagData } from 'types';
import { FeedItemContextProvider } from 'context/feed-item';
import { useScrollToPosition } from 'hooks/useScrollToPosition';
import { useViewed } from 'hooks/useViewed';
import { useNonClosableRef } from 'hooks/useUnclosureRef';
import { GalleryInitFunction } from 'components/gallery/Gallery';

import { DeleteFeedRecord, RestoreFeedRecord, UpdateFeedRecord } from '../actions';
import { useShowFullChange } from '../hooks/useShowFullChange';
import { getFeedItemContextDefaultValue } from '../services/feed-item-context';

import * as Styled from './styled';
import { PublishStatus } from './items/PublishStatus';
import { TickerNews } from './items/ticker-news';
import { RecommendationsItem } from './items/recommendations';
import { AdsItem } from './items/ads';
import { performanceCheck } from './performance-check';

export interface FeedItemProps {
    record: FeedRecord;
    onItemViewedChange: (entry: IntersectionObserverEntry, record: FeedRecord) => void;
    onRecordShowFull: (recordId: Id) => void;
    allTags?: TagData[];
    updateFeedRecord?: UpdateFeedRecord;
    deleteFeedRecord?: DeleteFeedRecord;
    restoreFeedRecord?: RestoreFeedRecord;
    className?: string;
    onClickAnyImgInPost?: GalleryInitFunction;
    pinned?: boolean;
    instanceId: string;
    deletedIds?: string[];
    onPremiumSubscribe?: () => void;
}

const FeedItemComponent = (props: FeedItemProps) => {
    const {
        record,
        deletedIds,
        onItemViewedChange,
        onRecordShowFull,
        onClickAnyImgInPost,
        className,
        pinned,
        deleteFeedRecord,
        restoreFeedRecord,
        updateFeedRecord,
        allTags,
        instanceId,
        onPremiumSubscribe,
    } = props;
    const itemRef = useRef<HTMLDivElement>(null);

    const [highlighted, setHighlighted] = useState(pinned);

    // dg: Управление подсветкой закрепленной записи
    useEffect(() => {
        if (pinned) {
            // dg: Время активной подсветки записи. После чего подсветка выключится
            const highlightTime = 12000;
            const timeout = setTimeout(() => setHighlighted(false), highlightTime);
            return () => clearTimeout(timeout);
        }
        return () => 0;
    }, [pinned]);

    const hasPinnedComment = useNonClosableRef(isPublishStatusRecord(record) ? !!record.publishedId : false);

    const scrollToPosition = useScrollToPosition();
    // Проскролливаемся к закрепленной записи
    useEffect(() => {
        if (pinned && itemRef.current && !hasPinnedComment.current) {
            scrollToPosition(itemRef.current?.offsetTop || null);
        }
    }, [pinned, scrollToPosition, hasPinnedComment]);

    useShowFullChange(onRecordShowFull);

    const [isShowRecommendations, setIsShowRecommendations] = useState(true);

    const selectByType = () => {
        switch (record.type) {
            case FeedRecordType.PUBLISH_STATUS:
                return (
                    <PublishStatus
                        record={record}
                        updateFeedRecord={updateFeedRecord}
                        deleteFeedRecord={deleteFeedRecord}
                        restoreFeedRecord={restoreFeedRecord}
                        onClickAnyImgInPost={onClickAnyImgInPost}
                        highlighted={highlighted}
                        pinned={pinned}
                        allTags={allTags || []}
                        deletedIds={deletedIds || []}
                        instanceId={instanceId}
                        onPremiumSubscribe={onPremiumSubscribe}
                    />
                );
            case FeedRecordType.TICKER_NEWS: {
                const { record: tickerRecord, ...rest } = props;
                return isTickerNewsRecord(tickerRecord) ? (
                    <TickerNews record={tickerRecord} {...rest} FeedItemComponent={FeedItemComponent} />
                ) : null;
            }
            case FeedRecordType.RECOMMENDATIONS:
                return (
                    isShowRecommendations && (
                        <RecommendationsItem record={record} hide={() => setIsShowRecommendations(false)} />
                    )
                );
            case FeedRecordType.ADS:
                return <AdsItem />;
            default:
                return null;
        }
    };

    useViewed(
        itemRef,
        (entry: IntersectionObserverEntry) => {
            onItemViewedChange(entry, record);
        },
        (observerRef) => !observerRef.current,
    );

    const itemWrapperClassName = cc([className, 'autocheck__feed__item']);

    const item = selectByType();

    if (!item) return null;

    // Измерение скорости загрузки ленты
    if (process.env.DLD !== 'prod') {
        performanceCheck();
    }
    return (
        <Styled.FeedItemWrapper ref={itemRef} className={itemWrapperClassName} data-record-id={record.id}>
            {item}
        </Styled.FeedItemWrapper>
    );
};

export const FeedItem = (props: FeedItemProps & { showFull?: boolean }) => {
    const { record, showFull } = props;
    return (
        <FeedItemContextProvider initialValue={getFeedItemContextDefaultValue(record, showFull)} key={record.id}>
            <FeedItemComponent {...props} />
        </FeedItemContextProvider>
    );
};
