export const OFFSET = 10;

export const OPEN_DELAY = 1000;

export const CLOSE_DELAY = 0;

export const ANIMATION_DURATION = 300;

export const ANIMATION_OFFSET = 8;

export const ARROW_OFFSET = 16;

export const ARROW_WIDTH = 8;

export const ARROW_HEIGHT = 4;

export const SCREEN_EDGE_OFFSET = 32;
