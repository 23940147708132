import React from 'react';

import { RecommendedUsersRecord } from 'types';
import Dic from 'services/dictionary';
import { Slider } from 'components/slider';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { TimesIcon } from 'components/icons2/TimesIcon';

import { User } from './User';
import * as Styled from './styled';

type Props = {
    record: RecommendedUsersRecord;
    hide: () => void;
};

export const RecommendationsItem = (props: Props) => {
    const { record, hide } = props;

    const recommendations = record?.obj?.recommendations || [];

    if (!recommendations.length) return null;

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Styled.Title>{Dic.word('wt_feed__item_recommendations__title_word')}</Styled.Title>
                <ButtonIcon
                    icon={{ component: TimesIcon }}
                    kind={ButtonIconKinds.GhostPrimary}
                    size="S"
                    onClick={hide}
                />
            </Styled.Header>
            <Slider
                item={{
                    indent: 12,
                    paddingTop: 12,
                }}
                clearDefaultProps="item"
                responsive={[
                    {
                        breakpoint: 960,
                        settings: {
                            arrows: false,
                        },
                    },
                ]}
            >
                {recommendations.map((user) => (
                    <User key={user.id} user={user} />
                ))}
            </Slider>
        </Styled.Wrapper>
    );
};
