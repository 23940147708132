import styled, { css } from 'styled-components';

import { matchBreakpoints } from 'app/styled';
import { body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';
import { TextOneLineTooltip as TextOneLineTooltipComponent } from 'components/text-one-line-tooltip';

import { UserBlockSize } from '../types';

import { ANIMATION_DURATION, getLoadingCss, GetLoadingCssArgs } from './UserBlock.styled';

export const TextOneLineTooltip = styled(TextOneLineTooltipComponent)<{ size: UserBlockSize }>`
    ${({ size }) =>
        matchBreakpoints(size, {
            large: css`
                ${body14RegularFontStyle};
            `,
            regular: css`
                ${body14RegularFontStyle};
            `,
            small: css`
                ${caption12RegularFontStyle};
            `,
            xsmall: css`
                ${caption12RegularFontStyle};
            `,
        })};
`;

export const SecondLine = styled.div<{ isLoading: boolean; size: UserBlockSize }>`
    display: flex;
    align-items: center;
    color: ${({ theme, isLoading }) => (isLoading ? 'transparent' : theme.label.secondary)};
    transition: all ${ANIMATION_DURATION}ms;

    ${({ isLoading }) =>
        isLoading
            ? css`
                  background-color: ${tokens.colors.opacity.base['16']};
                  border-radius: ${tokens.radius.rounded};
                  width: 48px;
              `
            : ''};

    ${({ size, isLoading }) => {
        const getLoadingCssHandler = (args: GetLoadingCssArgs) =>
            css`
                ${isLoading ? getLoadingCss(args) : ''}
            `;

        return matchBreakpoints(size, {
            large: css`
                ${body14RegularFontStyle};
                ${getLoadingCssHandler({ minHeight: 12, marginTop: 4, marginBottom: 4 })};
            `,
            regular: css`
                ${body14RegularFontStyle};
                ${getLoadingCssHandler({ minHeight: 12, marginTop: 4, marginBottom: 4 })};
            `,
            small: css`
                ${caption12RegularFontStyle};
                ${getLoadingCssHandler({ minHeight: 8, marginTop: 4, marginBottom: 4 })};
            `,
            xsmall: css`
                ${caption12RegularFontStyle};
                ${getLoadingCssHandler({ minHeight: 8, marginTop: 4, marginBottom: 4 })};
            `,
        });
    }};
`;
