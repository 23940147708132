import React from 'react';

import { IconSvg, IconComponent } from './index';

export const YandexMessengerColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
            fill="#38DBDA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.24596 4.7385C7.22182 4.19528 7.92196 3.79692 8.38068 4.11078C12.4608 6.39228 16.5289 8.69794 20.6211 10.9674C20.8867 11.1122 21.1764 11.2692 21.2851 11.5709C16.5893 10.2069 11.9055 8.83072 7.22182 7.40629C7.28217 6.52507 7.22182 5.63178 7.24596 4.7385Z"
            fill="#FEFEFE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.37489 7.59944C5.50767 7.30973 5.80946 7.04415 6.14746 7.10451C6.5096 7.16487 6.85968 7.29765 7.20975 7.4063C11.8814 8.83073 16.5772 10.1948 21.273 11.571C21.2971 11.6313 21.3213 11.74 21.3213 11.8003C16.6255 11.7882 11.9418 11.8003 7.24596 11.8003C6.65446 10.6897 6.12332 9.55502 5.54389 8.44444C5.42317 8.19094 5.25417 7.88916 5.37489 7.59944Z"
            fill="#BBF5F6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.25803 11.8003C11.9538 11.8003 16.6376 11.7882 21.3334 11.8003C21.3213 11.8848 21.2971 12.0538 21.2488 12.1383C20.3918 12.2952 19.5709 12.6332 18.7259 12.8384C15.5028 13.78 12.2798 14.7457 9.05667 15.6994C8.46517 15.8684 7.88574 16.0615 7.28217 16.1943C6.78724 16.2667 6.31645 16.5806 5.80945 16.472C5.41109 16.315 5.19381 15.7839 5.43524 15.4097C6.02674 14.2025 6.63031 12.9954 7.25803 11.8003Z"
            fill="#FEFEFE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7138 12.8385C19.5588 12.6332 20.3797 12.2952 21.2368 12.1383C21.104 12.3797 20.8384 12.5004 20.6091 12.6212C16.5168 14.8906 12.4487 17.2083 8.36859 19.4778C7.92194 19.7554 7.20973 19.4053 7.24594 18.85C7.24594 17.9688 7.20973 17.0755 7.27009 16.1943C7.87366 16.0615 8.45309 15.8684 9.04459 15.6994C12.2797 14.7578 15.4908 13.7921 18.7138 12.8385Z"
            fill="#BBF5F6"
        />
    </IconSvg>
);
