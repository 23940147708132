import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ChartPriceUp: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.15516 5.51576C7.62196 4.82953 8.37692 4.82664 8.8458 5.51576L11.2939 9.11376C11.7284 9.75233 11.4539 10.27 10.6779 10.27H5.32382C4.54916 10.27 4.26896 9.75872 4.70768 9.11376L7.15516 5.51576Z"
        />
    </IconSvg>
);
