import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const SearchResultsFallback = styled.div`
    background-color: ${({ theme }) => theme.bg.primary};
    width: 320px;
    height: 584px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
        fill: ${({ theme }) => theme.label.secondary};
    }

    ${mediaQuery.lt768} {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: unset;
        z-index: 1;
        border-radius: 0;
        height: unset;
    }
`;
