import { CheckIcon } from 'components/icons2/CheckIcon';

import { MenuContext, MenuData } from '../interfaces';

export async function getLanguagesMenu({ dic }: MenuContext): Promise<MenuData> {
    return {
        name: 'languages',
        showCloseButton: true,
        onClick: 'close',
        headerContent: dic.word('wt_all__widget_menu__languages_header'),
        selectedItemIcon: CheckIcon,
        items: [
            {
                id: 'en',
                text: 'English (US)',
            },
            {
                id: 'ru',
                text: 'Русский',
            },
        ],
    };
}
