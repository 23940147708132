import React, { useCallback } from 'react';

import { StrategyProductItem } from 'types/product';
import { InstrumentCard } from 'components/card';

import { StrategyProductCardProps } from '../types';
import { COMON_ENTITY } from '../constants';
import { makeStrategyParameters } from '../utils';

export const StrategyProductCard = (props: StrategyProductCardProps) => {
    const {
        item,
        onClick: onClickProp,
        className: classNameProp,
        withoutBoxShadow,
        isHeightGrow,
        isLoading,
        isDisableAutoDetectUrl = false,
    } = props;
    const { name, graphUrl, url, riskLevel, minSumForAutofollowing, yield365Days } = item as StrategyProductItem;

    const parameters = makeStrategyParameters({
        riskLevel,
        minSumForAutofollowing,
        yield365Days,
    });

    const className = [classNameProp, 'autotest__product-card__strategy'].filter((i) => i).join(' ');

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (onClickProp) {
                onClickProp(e);
            } else if (!isDisableAutoDetectUrl && url) {
                open(url, '_blank')?.focus();
            }
        },
        [isDisableAutoDetectUrl, url, onClickProp],
    );

    return (
        <InstrumentCard
            title={name}
            author={COMON_ENTITY}
            parameters={parameters}
            className={className}
            onClick={onClick}
            withoutBoxShadow={withoutBoxShadow}
            isHeightGrow={isHeightGrow}
            isLoading={isLoading}
            thumbnailSrc={graphUrl}
        />
    );
};
