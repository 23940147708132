import styled, { css } from 'styled-components';
import { theme } from 'styled-tools';
import { size as polishedSize } from 'polished';

import { matchBreakpoints, anchorResetStyle, reduceBreakpoints } from 'app/styled';
import { Theme } from 'services/theme';
import { Counter as CounterComponent } from 'components/indicator/counter';

import { DEFAULT_ICON_SIZE, ICON_SIZE_MAP } from '../constants';
import type { TabsType } from '../props';
import { TabProps } from '../props';

import { itemStandard } from './itemStandard';
import { itemButtons } from './itemButtons';
import { paddingLeftRightStyle, TabsListItemProps } from './index';
import { horizontalMargin } from './const';

export const kitByType = (type: TabsType) =>
    ({
        standard: itemStandard,
        buttons: itemButtons,
    }[type]);

function typeSpecificItemStyles({ type, isActive, theme, isDisabled }: TabsListItemProps & { theme: Theme }) {
    const styleMap = kitByType(type).tabItemStyles;

    if (isDisabled) {
        return css`
            ${styleMap.common};
            color: ${theme.label.inactive};
        `;
    }

    if (isActive) {
        return css`
            ${styleMap.common};
            color: ${theme.label.primary};
            ${styleMap.active}
        `;
    }

    return styleMap.common;
}

function typeSpecificAnchorStyles({ type, isActive, theme }: TabsListItemProps & { theme: Theme }) {
    const styleMap = kitByType(type).anchorStyles(theme);

    if (isActive) {
        return css`
            ${styleMap.common}
            color: ${theme.label.primary};
            ${styleMap.active}
            &:focus {
                outline: none;
            }
        `;
        // ${styleMap.activeFocused}
    }

    return css`
        ${styleMap.common};
        @media (hover: hover) {
            &:hover {
                ${styleMap.hover};
            }
        }
        &:focus {
            outline: none;
        }
    `;
    // ${styleMap.inactiveFocused}
}

function gridSettings({ icon, title, iconPos, count }: TabsListItemProps) {
    const hasIconAndText = !!(icon && title);
    const hasOnlyIcon = !!(icon && !title);
    const hasOnlyText = !!(!icon && title);
    const hasIconTextCounter = !!(icon && title && count);
    const hasTextCounter = !!(title && count && !icon);
    const contentTemplateAreas = `content${count || count === 0 ? ' counter' : ''}`;

    if (hasTextCounter) {
        return css`
            grid-template-columns: min-content min-content;
            grid-template-areas: '${contentTemplateAreas}';
        `;
    }

    if (hasIconTextCounter) {
        return css`
            grid-template-columns: min-content min-content min-content;
            grid-template-areas: '${iconPos === 'left'
                ? `icon ${contentTemplateAreas}`
                : `${contentTemplateAreas} icon`}';
        `;
    }

    if (hasIconAndText) {
        return css`
            grid-template-columns: min-content min-content;
            grid-template-areas: '${iconPos === 'left'
                ? `icon ${contentTemplateAreas}`
                : `${contentTemplateAreas} icon`}';
        `;
    }

    if (hasOnlyIcon) {
        return css`
            grid-template-columns: min-content;
            grid-template-areas: 'icon';
        `;
    }

    if (hasOnlyText) {
        return css`
            grid-template-columns: min-content;
            grid-template-areas: '${contentTemplateAreas}';
        `;
    }

    return undefined;
}

function setVariables({ size, type }: TabsListItemProps) {
    const { sizeMap } = kitByType(type);

    return matchBreakpoints(size || 'large', sizeMap);
}

export const TabsListItem = styled.li<TabsListItemProps>`
    ${setVariables}
    ${typeSpecificItemStyles};

    display: flex;
    justify-content: center;
    flex: var(--item-flex);
    margin: 0 ${horizontalMargin}px;
    transition-property: transform;
    transition-duration: 100ms;
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`;

export const TabsListItemAnchor = styled.div<TabsListItemProps>`
    ${anchorResetStyle}
    ${typeSpecificAnchorStyles}
    ${gridSettings}

    position: relative;
    ${paddingLeftRightStyle};
    display: grid;
    grid-template-rows: 1fr;
    // TODO проверить везде
    grid-auto-flow: column;
    gap: 8px;
    align-content: center;
    justify-items: stretch;
    align-items: center;
    white-space: nowrap;
    justify-content: var(--item-anchor-justify-content);
    width: var(--item-anchor-width);
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'unset')};

    border-radius: 6px;

    svg {
        grid-area: icon;
    }
`;

export const TabTitleContainer = styled.span`
    grid-area: content;
`;

export const Counter = styled(CounterComponent)`
    grid-area: counter;
`;

const getCssIcon = ({ size: tabSize }: { size: TabProps['size'] }) => {
    const getCssByIconSize = (_iconSize: number) => polishedSize(_iconSize);

    if (tabSize) {
        return reduceBreakpoints(tabSize, (_size) => {
            const sizeValue: number = ICON_SIZE_MAP[_size];

            return getCssByIconSize(sizeValue);
        });
    }

    return getCssByIconSize(DEFAULT_ICON_SIZE);
};

export const IconWrapper = styled.div<{ size: TabProps['size'] }>`
    display: flex;
    svg {
        ${getCssIcon};
    }
`;
