import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ChevronUpSmallIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0977 14.0315C16.8042 14.3618 16.2985 14.3915 15.9683 14.098L11.9998 10.5704L8.03129 14.098C7.70106 14.3915 7.1954 14.3618 6.90187 14.0315C6.60833 13.7013 6.63808 13.1956 6.9683 12.9021L11.4683 8.90211C11.7714 8.63268 12.2282 8.63268 12.5313 8.90211L17.0313 12.9021C17.3615 13.1956 17.3913 13.7013 17.0977 14.0315Z"
        />
    </IconSvg>
);
