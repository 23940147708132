/* eslint-disable camelcase */
import { RequiredNotNull } from 'types/index';
import {
    AuthorProductDto_ILeanProductDto,
    Marketplace_Mappings_PriceDto,
    Marketplace_Mappings_Products_LeanSignalsProductDto,
    Marketplace_Mappings_Products_LeanStrategyProductDto,
    Marketplace_Mappings_Products_LeanUserProductDto,
    Marketplace_Mappings_Products_LeanUsStrategyProductDto,
    Marketplace_Mappings_Products_RichUserProductDto,
    Marketplace_Mappings_Products_RichUsSignalsProductDto,
    Marketplace_Mappings_Products_RichUsStrategyProductDto,
    Marketplace_Statistics_Product_ProductFavoritesDto,
    Marketplace_Web_Public_V1_My_Products_GetRatingCountersWebResponse,
} from 'services/api-services/generated_marketplace';
import { MarketplaceUserExporter } from 'services/api-services/generated_limex';

export type FinamPrice = Marketplace_Mappings_PriceDto;

export type MarketplaceWebPublicV1CatalogLeanProductDto =
    | Marketplace_Mappings_Products_LeanStrategyProductDto
    | Marketplace_Mappings_Products_LeanUserProductDto;

type FinamProductExtension = Pick<
    AuthorProductDto_ILeanProductDto,
    'productStatus' | 'archived' | 'archivedAt' | 'rating'
> &
    RequiredNotNull<
        Pick<
            Marketplace_Web_Public_V1_My_Products_GetRatingCountersWebResponse,
            'totalReviewsAmount' | 'grade5' | 'grade4' | 'grade3' | 'grade2' | 'grade1'
        >
    > & {
        favoritesStats?: Array<Marketplace_Statistics_Product_ProductFavoritesDto>;
    };

export type FinamSignalsProduct = Marketplace_Mappings_Products_LeanSignalsProductDto &
    FinamProductExtension & {
        productType: 'UsSignal';
        token?: string;
    };
export type FinamRichSignalsProduct = Marketplace_Mappings_Products_RichUsSignalsProductDto &
    FinamProductExtension & {
        productType: 'UsSignal';
        author?: KratosUser;
    };
export type FinamStrategyProduct = Marketplace_Mappings_Products_LeanUsStrategyProductDto &
    FinamProductExtension & {
        productType: 'UsStrategy';
    };
export type FinamUserProduct = Marketplace_Mappings_Products_LeanUserProductDto &
    FinamProductExtension & { productType: 'UsUserProduct' };

export const productTypesList = ['UsStrategy', 'UsSignal', 'UsUserProduct'] as const;

export const finamProductTypes: Record<string, FinamProductItemProductType> = {
    Strategy: 'UsStrategy',
    UsUserProduct: 'UsUserProduct',
    UsSignal: 'UsSignal',
};

export const finamProductStatuses = ['Created', 'ApprovedByModerator', 'Published'] as const;

export type FinamProductStatus = (typeof finamProductStatuses)[number];

export type FinamProductItemProductType = (typeof productTypesList)[number];

export type KratosUser = MarketplaceUserExporter & { kratosId: string };
export type FinamProductItem = FinamProductExtension &
    (FinamStrategyProduct | FinamUserProduct | FinamSignalsProduct) & { author?: KratosUser; token?: string };

export type FinamRichStrategyProduct = Marketplace_Mappings_Products_RichUsStrategyProductDto &
    FinamProductExtension & {
        productType: 'UsStrategy';
        author?: KratosUser;
    };
export type FinamRichUserProduct = Marketplace_Mappings_Products_RichUserProductDto &
    FinamProductExtension & {
        productType: 'UsUserProduct';
        author?: KratosUser;
    };

export function isFinamProductType(productType: string): productType is FinamProductItemProductType {
    return productTypesList.includes(productType as FinamProductItemProductType);
}

export function isFinamStrategyProduct(product: FinamProductItem): product is FinamStrategyProduct {
    return product.productType === 'UsStrategy';
}

export function isFinamRichStrategyProduct(product: FinamProductItem): product is FinamRichStrategyProduct {
    return product.productType === 'UsStrategy' && Object.prototype.hasOwnProperty.call(product, 'keyFeatures');
}

export function isFinamUserProduct(product: FinamProductItem): product is FinamUserProduct {
    return product.productType === 'UsUserProduct';
}

export function isFinamRichUserProduct(product: FinamProductItem): product is FinamRichUserProduct {
    return product.productType === 'UsUserProduct' && Object.prototype.hasOwnProperty.call(product, 'tags');
}

export function isFinamSignalsProduct(product: FinamProductItem): product is FinamSignalsProduct {
    return product.productType === 'UsSignal';
}

export function isFinamRichSignalsProduct(product: FinamProductItem): product is FinamRichSignalsProduct {
    return product.productType === 'UsSignal' && Object.prototype.hasOwnProperty.call(product, 'keyFeatures');
}
