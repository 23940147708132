import type { Step2FormValues, Step2Data } from './types';

export function toFormValues({
    accountId,
    minSum,
    riskLevel,
    price,
    recommendedHoldingPeriod,
}: Step2Data): Step2FormValues {
    return {
        accountId: `${accountId}`,
        minSum,
        riskLevel: riskLevel ?? '',
        price,
        recommendedHoldingPeriod,
    };
}
