import styled from 'styled-components';

import { body17RegularFontStyle } from 'services/typography';

export const CountryNotFoundStyled = styled.div`
    flex: 1 1 100%;
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.label.placeholder};
    ${body17RegularFontStyle};

    & span {
        color: ${({ theme }) => theme.label.primary};
        word-break: break-word;
    }
`;
