import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ChevronDownSmallIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.90209 9.96851C7.19563 9.63829 7.70129 9.60854 8.03151 9.90208L12 13.4296L15.9685 9.90208C16.2988 9.60854 16.8044 9.63829 17.0979 9.96851C17.3915 10.2987 17.3617 10.8044 17.0315 11.0979L12.5315 15.0979C12.2284 15.3674 11.7716 15.3674 11.4685 15.0979L6.96853 11.0979C6.6383 10.8044 6.60856 10.2987 6.90209 9.96851Z"
        />
    </IconSvg>
);
