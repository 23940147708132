import React from 'react';

import {
    UserBlockProps,
    UserBlockAvatarProp,
    UserBlockSizeValue,
    UserBlockUserProp,
} from 'components/user-block/types';

import { StyledUserBlock } from './styled';

type CardAuthorProps = {
    name: UserBlockUserProp['displayName'];
    avatarSrc?: UserBlockAvatarProp['src'];
    isAvatarRounded?: boolean;
    description?: string | null;
};

const USER_NAME_PROPS: UserBlockProps['userNameProps'] = { kind: 'accent' };
const AVATAR_SIZE_CARD_AUTHOR: UserBlockSizeValue = 'small';

export const CardAuthor = (props: CardAuthorProps) => {
    const { name, avatarSrc, description, isAvatarRounded } = props;

    const avatar: UserBlockAvatarProp = {
        src: avatarSrc,
        isHiddenOnEmptySrc: true,
        size: 'medium',
        borderRadius: isAvatarRounded ? 1000 : undefined,
    };

    const user: UserBlockUserProp = {
        displayName: name,
    };

    return (
        <StyledUserBlock
            user={user}
            avatar={avatar}
            secondLine={description || ''}
            size={AVATAR_SIZE_CARD_AUTHOR}
            userNameProps={USER_NAME_PROPS}
            className="autotest__product-card_author"
        />
    );
};
