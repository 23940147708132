import styled from 'styled-components';

import { caption10RegularFontStyle } from 'services/typography';

import { FormItemMessage, FormTextInput } from '..';

export const PopupFormTextInput = styled(FormTextInput)``;

export const ErrorMessage = styled(FormItemMessage).attrs({ visualState: 'emergency' })`
    ${caption10RegularFontStyle}
`;
