import { useEffect } from 'react';

import { useWebsocketChannel } from 'hooks/useWebsocketChannel';

type PropsType<T> = {
    channelName?: string;
    eventName: string;
    callback: (args?: T) => void;
};

export const useWebsocketListener = <T>({ channelName, eventName, callback }: PropsType<T>) => {
    const socketChannel = useWebsocketChannel(channelName);

    useEffect(() => {
        socketChannel.on(eventName, callback);
        return () => {
            socketChannel.off(eventName, callback);
        };
    }, [socketChannel, eventName, callback]);
};
