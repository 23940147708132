/* eslint-disable @typescript-eslint/no-explicit-any */

export class MemoizationCache {
    private objects = new Map<string, any>();

    private dependents = new Map<string, Set<string>>();

    public set(key: string, value: any, dependencies: string[]) {
        this.objects.set(key, value);
        for (let i = 0; i < dependencies.length; i++) {
            const dep = dependencies[i];

            if (!this.dependents.has(dep)) {
                this.dependents.set(dep, new Set());
            }
            this.dependents.get(dep)?.add(key);
        }
    }

    public get(key: string) {
        return this.objects.get(key);
    }

    public invalidate(key: string) {
        this.objects.delete(key);
        this.dependents.get(key)?.forEach((dependent) => this.invalidate(dependent));
    }
}

export const memoizationCache = new MemoizationCache();
