/* eslint-disable @typescript-eslint/no-explicit-any */

import { notEmpty } from 'services/utils/not-empty';

import { AsyncMethod, GlobalStateForCache, PaginationConfig, ResourcesMap } from '../types';
import { selectorsCache } from '../cache/selectorsCache';
import { prefixObjectKeys } from '../utils/prefixObjectKeys';

import { createSelectPaginatedRequestData } from './selectPaginatedRequestData';

export function createSelectPaginatedRequestRawData<
    TYPES_MAP extends object,
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    PAGE_INFO,
    CUSTOM_PREFIX extends string,
>({
    resourcesMap,
    endpointName,
    pagination,
    customPrefix,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: ENDPOINT_NAME;
    pagination: PaginationConfig<API_METHOD, PAGE_INFO>;
    customPrefix?: CUSTOM_PREFIX;
}) {
    const typeKey = { endpointName };
    const selectPaginatedRequestData = createSelectPaginatedRequestData({
        resourcesMap,
        endpointName,
        pagination,
    });
    return (state: GlobalStateForCache, argsOrRequestName: Parameters<API_METHOD>[0] | string) => {
        const pages = selectPaginatedRequestData(state, argsOrRequestName).filter(notEmpty);

        const cached = selectorsCache.get([typeKey, ...pages]);

        if (cached) {
            return cached;
        }

        const newValue = prefixObjectKeys(customPrefix ?? endpointName, {
            rawData: pages,
        });

        selectorsCache.set([typeKey, ...pages], newValue);
        return newValue;
    };
}
