/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstrumentService {
    /**
     * Карта акронимов по именам бирж
     * @returns any Карта акронимов по именам бирж
     * @throws ApiError
     */
    public static getInstrumentExchangeAcronymMap(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instrument/exchange-acronym-map/',
        });
    }

    /**
     * Получить доходность по инстурменту за последние 365 дней
     * @returns any Успешное получение доходности
     * @throws ApiError
     */
    public static getInstrumentProfitability({
        instrumentId,
    }: {
        /**
         * ИД инструмента
         */
        instrumentId: number;
    }): CancelablePromise<{
        profitability: Array<{
            /**
             * Торговый день
             */
            date: string;
            /**
             * Абсолютное значение на время закрытия торгового дня
             */
            value: number;
            /**
             * Доходность на торговый день
             */
            rValue: number;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instrument/profitability/{instrumentId}/',
            path: {
                instrumentId: instrumentId,
            },
        });
    }
}
