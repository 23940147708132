import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Id } from 'types';
import { useLinks } from 'hooks/useLinks';

/** Воспроизводит логику редиректов на странице профиля с бэка на фронте */
export function useProfileRedirects({
    pinnedRecordShowMore: initialPinnedRecordShowMore,
    profileUserId,
    personalAlias,
}: {
    pinnedRecordShowMore?: boolean;
    profileUserId: Id;
    personalAlias?: string | null;
}) {
    const [searchParams] = useSearchParams();
    const showMore = searchParams.get('showMore') === '1';
    const links = useLinks();
    const { pathname, search, hash } = useLocation();
    const navigate = useNavigate();
    const [pinnedRecordShowMore, setPinnedRecordShowMore] = useState(initialPinnedRecordShowMore);

    /** Если у пользователя есть personalAlias, то редиректим на url с алиасом */
    useEffect(() => {
        if (pathname && personalAlias && profileUserId && pathname.includes(`profile/${profileUserId}`)) {
            const newPathname = pathname.replace(`profile/${profileUserId}`, `@${personalAlias}`);
            navigate({ pathname: newPathname, search, hash }, { replace: true });
        }
    }, [personalAlias, profileUserId, pathname, navigate, search, hash]);

    /** Если есть параметр showMore=1, то убираем его, редиректим на /full/ версию роута и возвращаем флаг для разворота поста */
    useEffect(() => {
        if (showMore) {
            setPinnedRecordShowMore(true);
            const pinnedPostById = matchPath(
                { path: links.pinnedPostById(':userId', ':pinnedPostId'), end: true },
                pathname,
            );
            if (pinnedPostById) {
                const {
                    params: { userId, pinnedPostId },
                } = pinnedPostById;
                if (userId && pinnedPostId) {
                    navigate(
                        { pathname: links.pinnedPostFullById(userId, pinnedPostId), search: '' },
                        { replace: true },
                    );
                    return;
                }
            }

            const pinnedPremiumPostById = matchPath(
                { path: links.pinnedPremiumPostById(':userId', ':pinnedPostId'), end: true },
                pathname,
            );
            if (pinnedPremiumPostById) {
                const {
                    params: { userId, pinnedPostId },
                } = pinnedPremiumPostById;
                if (userId && pinnedPostId) {
                    navigate(
                        { pathname: links.pinnedPremiumPostFullById(userId, pinnedPostId), search: '' },
                        { replace: true },
                    );
                    return;
                }
            }

            const pinnedPostByAlias = matchPath(
                { path: links.pinnedPostByAlias(':alias', ':pinnedPostId'), end: true },
                pathname,
            );

            if (pinnedPostByAlias) {
                const {
                    params: { alias, pinnedPostId },
                } = pinnedPostByAlias;
                if (alias && pinnedPostId) {
                    navigate(
                        { pathname: links.pinnedPostFullByAlias(alias, pinnedPostId), search: '' },
                        { replace: true },
                    );
                    return;
                }
            }

            const pinnedPremiumPostByAlias = matchPath(
                { path: links.pinnedPremiumPostByAlias(':alias', ':pinnedPostId'), end: true },
                pathname,
            );
            if (pinnedPremiumPostByAlias) {
                const {
                    params: { alias, pinnedPostId },
                } = pinnedPremiumPostByAlias;
                if (alias && pinnedPostId) {
                    navigate(
                        { pathname: links.pinnedPremiumPostFullByAlias(alias, pinnedPostId), search: '' },
                        { replace: true },
                    );
                }
            }
        }
    }, [links, navigate, pathname, showMore]);

    return pinnedRecordShowMore;
}
