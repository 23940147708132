import React from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { ExclamationTriangleIcon } from 'components/icons2/ExclamationTriangleIcon';

type Props = {
    onClick: () => void;
};

export const ActionComplaint = (props: Props) => {
    const { onClick } = props;
    return (
        <MenuItem
            text={Dic.word('wt_profile__widget_context_actions__complaint_item')}
            icon={{ component: ExclamationTriangleIcon }}
            onClick={onClick}
            className="autotest__profile-page__header-context-actions-complaint"
        />
    );
};
