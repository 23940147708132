import React, { ReactNode, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';

import { useClickOutside } from 'hooks/useClickOutside';

import { ListBoxOptionsContainer, ListBoxOptionsContainerInner } from './styled';
import { ListBoxContext, ListBoxContextType } from './context';

export type ListBoxOptionsProps = {
    children: ReactNode;
    className?: string;
    portalTo?: HTMLElement;
};
export const ListBoxOptions = React.forwardRef((props: ListBoxOptionsProps, forwardedRef) => {
    const { children, className: classNameProp, portalTo } = props;

    const { isOpened, setIsOpened } = useContext<ListBoxContextType<unknown>>(ListBoxContext);

    const optionsInnerContainerRef = useRef<HTMLDivElement>(null);
    const optionsOuterContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(forwardedRef, () => optionsOuterContainerRef.current, [isOpened]);

    useClickOutside(optionsInnerContainerRef, () => setIsOpened(false));

    useEffect(() => {
        if (isOpened && optionsOuterContainerRef.current) {
            const parent = getScrollableParent(optionsOuterContainerRef.current);
            const { bottom } = optionsOuterContainerRef.current.getBoundingClientRect();

            if (parent) {
                const { bottom: parentBorder } = parent.getBoundingClientRect();
                if (bottom > parentBorder) {
                    parent.scrollBy({ top: bottom - parentBorder, behavior: 'smooth' });
                }
            } else if (bottom > window.innerHeight) {
                window.scrollBy({ top: bottom - window.innerHeight, behavior: 'smooth' });
            }
        }
    }, [isOpened]);

    const className = useMemo(
        () => ['autotest__list-box__options', classNameProp].filter((cn) => cn).join(' '),
        [classNameProp],
    );

    const result = isOpened ? (
        <ListBoxOptionsContainer ref={optionsOuterContainerRef} className={className}>
            <ListBoxOptionsContainerInner ref={optionsInnerContainerRef}>{children}</ListBoxOptionsContainerInner>
        </ListBoxOptionsContainer>
    ) : null;

    if (portalTo) return ReactDOM.createPortal(result, portalTo);
    return result;
});

const isScrollable = (ele: HTMLElement) => {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

    const overflowYStyle = window.getComputedStyle(ele).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
    const isOverflowVisible = overflowYStyle.indexOf('visible') !== -1;

    return hasScrollableContent && !isOverflowHidden && !isOverflowVisible;
};

const getScrollableParent = (ele: HTMLElement | null): HTMLElement | null => {
    if (!ele || ele === document.body) {
        return null;
    }
    return isScrollable(ele) ? ele : getScrollableParent(ele.parentElement);
};
