import styled from 'styled-components';

import { LinkButton } from 'components/button2';

export const Wrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const IconButton = styled(LinkButton)`
    padding: 0;

    &:active {
        svg {
            fill: ${({ theme }) => theme.label.accentHighest};
        }
    }
`;
