import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import cc from 'classcat';

import { ButtonBadge } from 'components/button-badge';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { BellIcon } from 'components/icons2/BellIcon';
import { IconComponent } from 'components/icons2';
import { ContextActionsProps, OnVisibleChangeArgs } from 'components/context-actions';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { NotificationsListFallback } from './styled/NotificationsListFallback.styled';
import * as Styled from './styled/ButtonContextActionsComponent.styled';

const NotificationsList = lazy(() =>
    import(/* webpackChunkName: "notifications" */ '../containers/NotificationsListContainer').then((module) => ({
        default: module.HeaderNotificationsListContainer,
    })),
);

export type ButtonContextActionsComponentProps = {
    isShowBadge: boolean;
    isOpened: boolean;
    btnIcon?: IconComponent;
    onVisibleChange: ContextActionsProps['onVisibleChange'];
    tippyContent?: ContextActionsProps['tippyContent'];
};

export const ButtonContextActionsComponent = (props: ButtonContextActionsComponentProps) => {
    const { isShowBadge, tippyContent: tippyContentProp, onVisibleChange, isOpened, btnIcon = BellIcon } = props;

    const classNameButtonBadge = cc({
        'autotest__header-notifications__button-badge': true,
        'autotest__header-notifications__button-badge__badge-active': isShowBadge,
    });

    const buttonContentMemoized = useMemo(
        () => (
            <ButtonBadge isShowBadge={isShowBadge} className={classNameButtonBadge}>
                <ButtonIcon
                    kind={ButtonIconKinds.GhostSecondary}
                    size="L"
                    icon={{
                        component: btnIcon,
                    }}
                    pressed={isOpened}
                />
            </ButtonBadge>
        ),
        [isShowBadge, isOpened, classNameButtonBadge, btnIcon],
    );

    const [tippyContentMounted, setTippyContentMounted] = useState(false);

    const handleVisibleChange = useCallback(
        (partialState: OnVisibleChangeArgs) => {
            const { isVisible, inTransition } = partialState;
            if (!isVisible && inTransition) {
                setTippyContentMounted(true);
            }
            if (!isVisible && !inTransition) {
                setTippyContentMounted(false);
            }
            onVisibleChange?.(partialState);
        },
        [onVisibleChange],
    );

    const tippyContent = useMemo(
        () =>
            tippyContentProp || tippyContentMounted ? (
                <Suspense
                    fallback={
                        <NotificationsListFallback>
                            <ProcessingIcon />
                        </NotificationsListFallback>
                    }
                >
                    <NotificationsList isWillOpenedWithMount={false} />
                </Suspense>
            ) : (
                <></>
            ),
        [tippyContentProp, tippyContentMounted],
    );

    return (
        <>
            <Styled.ContextActionsGlobalStyle isOpened={isOpened} />
            <Styled.ContextActions
                appendTo="parent"
                hideOnScroll={false}
                tippyContent={tippyContent}
                buttonContent={buttonContentMemoized}
                onVisibleChange={handleVisibleChange}
                className="autotest__header-notifications__context-actions"
                buttonClassName="autotest__header-notifications__context-actions-btn"
                interactive
            />
        </>
    );
};
