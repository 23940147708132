import type { ProductRisk } from 'services/mp-share-resources/types';

import type { Step1FormValues, Step1Data } from './types';

export function toData({ riskLevel, recommendedHoldingPeriod, targetReturnAnnualized }: Step1FormValues): Step1Data {
    return {
        riskLevel: riskLevel as ProductRisk,
        recommendedHoldingPeriod,
        targetReturnAnnualized: targetReturnAnnualized || undefined,
    };
}
