import { Id } from 'types/shared';

export enum FileType {
    PERSON_PORTRAIT = 69000004156,
    ATTACH_BLOGPOST = 69000002263,
    GROUP_GENERIC = 69000002260,
    CHART_POST = 69000002261,
    EXPERT_OPINION = 69100000000,
    PERSON_PROFILE_COVER = 69100000001,
    SHOP_PRODUCT_COVER = 7,
    SHOP_PRODUCT_IMAGE = 8,
}

export type LimexFile = {
    id: Id;
    sizes: {
        original?: {
            url?: string;
        };
    };
    type: FileType;
};
