import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { Button } from 'components/button2';
import { CloseIcon } from 'components/icons2/CloseIcon';
import { MenuItem } from 'components/menu-item';
import { LockIcon } from 'components/icons2/LockIcon';
import { CreditCardIcon } from 'components/icons2/CreditCardIcon';
import { ShoppingCartIcon } from 'components/icons2/ShoppingCartIcon';
import { CommunityIcon } from 'components/icons2/CommunityIcon';

import { Wrapper, HeaderText, Header, List, GlobalStyle } from './styled';

interface Props {
    onClose: () => void;
    isShowShopV2: boolean;
}

export const MobileLinks: FC<Props> = (props) => {
    const { onClose, isShowShopV2 } = props;

    const dic = useDictionary();
    const links = useLinks();
    return (
        <>
            <GlobalStyle />
            <Wrapper>
                <Header>
                    <HeaderText>{dic.word('wt_all__limex__mobile_group_title__navigation')}</HeaderText>
                    <Button
                        kind="ghost-secondary"
                        size="small"
                        icon={CloseIcon}
                        onClick={onClose}
                        className="autotest__navigation__buttons_links_mobile_close"
                    />
                </Header>
                <List>
                    <MenuItem
                        text={dic.word('wt_menu__menu_limex__myspace_text')}
                        icon={{ component: LockIcon }}
                        href={links.homeRoot()}
                    />
                    <MenuItem
                        text={dic.word('wt_menu__menu_limex__feed_text')}
                        icon={{ component: CreditCardIcon }}
                        href={links.feed()}
                    />
                    <MenuItem
                        text={dic.word('wt_menu__menu_limex__communities_text')}
                        icon={{ component: CommunityIcon }}
                        href={links.communities()}
                    />
                    <MenuItem
                        text={dic.word('wt_menu__menu_limex__shop_text')}
                        icon={{ component: ShoppingCartIcon }}
                        href={isShowShopV2 ? links.shop2() : links.shop()}
                    />
                </List>
            </Wrapper>
        </>
    );
};
