import styled, { css } from 'styled-components';
import TippyComponent from '@tippy.js/react';

import { body14RegularFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';
import { DEFAULT_VARIANT_TOOLTIP } from 'components/tooltip/constants';

import { TooltipProps, VariantTooltip } from './types';

const textColorsTooltipKind: Record<
    VariantTooltip,
    {
        text: string;
        bg: string;
    }
> = {
    light: {
        text: tokens.colors.text.base.default.default,
        bg: tokens.colors.bg.base.default.default,
    },
    dark: {
        text: tokens.colors.text.ondark.default.default,
        bg: tokens.colors.bg.base.tooltip.default,
    },
};

export const Tippy = styled(TippyComponent)<{ $kind: TooltipProps['kind']; $variant: TooltipProps['variant'] }>`
    ${({ $kind, theme, $variant = DEFAULT_VARIANT_TOOLTIP }) => {
        if ($kind === 'tooltip') {
            const colorsCurrentVariant = textColorsTooltipKind[$variant];
            return css`
                ${body14RegularFontStyle};
                background: ${colorsCurrentVariant.bg};
                color: ${colorsCurrentVariant.text};
                & svg {
                    fill: ${colorsCurrentVariant.text};
                }
                &[x-placement^='left'] {
                    .tippy-arrow {
                        border-left-color: ${colorsCurrentVariant.bg};
                    }
                }
                &[x-placement^='bottom'] {
                    .tippy-arrow {
                        border-bottom-color: ${colorsCurrentVariant.bg};
                    }
                }
                &[x-placement^='top'] {
                    .tippy-arrow {
                        border-top-color: ${colorsCurrentVariant.bg};
                    }
                }
                padding: 4px 8px;
                border-radius: 4px;
                min-width: max-content;
                box-shadow: ${theme.shadow.popover};
            `;
        }
        if ($kind === 'popover') {
            return css`
                background: ${theme.bg.primary};
                color: ${theme.label.primary};
                box-shadow: ${theme.shadow.popover};
                padding: 0;
                text-align: left;
                border-radius: 8px;
            `;
        }
        return '';
    }}
`;
