import { connect } from 'react-redux';

import { DispatchType } from 'types';
import { contactSupport } from 'services/mp-share-resources/subscriptions/actions/contactSupport';
import { GlobalStateHeaderNotifications } from 'widgets/header-notifications/types';
import { updateUnreadCount } from 'widgets/header-notifications/actions';

import { NavigationMenuConnector } from './NavigationMenuConnector';
import { updateCountry, updateLocale } from './actions';

const mapStateToProps = (state: GlobalStateHeaderNotifications) => ({
    unreadCountNotifications: state?.widgets?.notifications?.unreadCount,
    isOpenedNotifications: state?.widgets?.notifications?.isOpened,
});

const mapDispatchToProps = {
    updateLocale,
    updateCountry,
    contactSupport,
    updateUnreadCount,
};

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;
export type MapDispatchToPropsType = DispatchType<typeof mapDispatchToProps>;

export const NavigationMenu = connect(mapStateToProps, mapDispatchToProps)(NavigationMenuConnector);
