export const initGoogleTagManagerScript = () => {
    const commentStart = document.createComment('Google Tag Manager');
    document.head.appendChild(commentStart);

    const googleTagManagerScriptEl = document.createElement('script');
    googleTagManagerScriptEl.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MHNC6LX');`;

    document.head.appendChild(googleTagManagerScriptEl);

    const commentFinish = document.createComment('End Google Tag Manager');
    document.head.appendChild(commentFinish);
};
