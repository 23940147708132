import { FeedRecordType, GeneratorType, isPublishStatusRecord } from 'types';
import { reduceReducers } from 'services/utils/reduce-reducers';
import merge, { mergeByPath } from 'services/utils/merge';

import { createInitialFeedState } from './feed/utils';
import { socialActivityReducer } from './feed/social-activity-reducer';
import likePostReducer from './like-post/reducer';
import likeCommentReducer from './like-comment/reducer';
import commentsReducer from './comments/reducer';
import subscribeReducer from './action-subscribe/reducer';
import feedReducer from './feed/feed-reducer';
import { FeedState } from './types';
import { FetchRecordsPayloadData } from './feed/actions';
import { UPDATE_USER, UpdateUserActions } from './actions';
import { getCommentsAndPath } from './helpers';

const commonFeedReducer = (state: FeedState, action: UpdateUserActions) => {
    switch (action.type) {
        case UPDATE_USER: {
            const {
                payload: { id: userId, ...userData },
            } = action;
            const newRecords = Object.keys(state.records).reduce((acc, key) => {
                let newRecord = state.records[key];
                if (newRecord.type === FeedRecordType.PUBLISH_STATUS) {
                    // обновляем юзеров в генераторах и авторах
                    if (newRecord.generator?.type === GeneratorType.USER && newRecord.generator?.id === userId) {
                        newRecord = mergeByPath(newRecord, 'generator', userData);
                        newRecord = mergeByPath(newRecord, 'obj.author', userData);
                    }
                    const record = state.records[key];
                    if (isPublishStatusRecord(record)) {
                        const { comments, path } = getCommentsAndPath(record);
                        // обновляем юзеров в комментариях
                        const newComments = comments?.map((comment) => {
                            if (!(comment.author?.id === userId)) return comment;
                            let newComment = comment;
                            if (userData.displayName) {
                                const replacedContent = comment.content?.replace(
                                    new RegExp(comment.author.displayName ?? '', 'g'),
                                    userData.displayName,
                                );
                                newComment = merge(newComment, { content: replacedContent });
                            }
                            newComment = mergeByPath(newComment, 'author', userData);
                            return newComment;
                        });
                        newRecord = mergeByPath(newRecord, path, newComments);
                    }
                }
                return { ...acc, [key]: newRecord };
            }, {});
            return {
                ...state,
                records: newRecords,
            };
        }
        default:
            return state;
    }
};

export default (rawInitialData: FetchRecordsPayloadData) => {
    const initialState: FeedState = createInitialFeedState(rawInitialData);
    return reduceReducers(
        initialState,
        feedReducer,
        socialActivityReducer,
        likePostReducer,
        likeCommentReducer,
        commentsReducer,
        subscribeReducer,
        commonFeedReducer,
    );
};
