import { connect } from 'react-redux';

import { State } from '../../types';

import { receiveSubscriptions, receiveSubscribers } from './actions';
import { SubscribersComponent } from './components';

const mapStateToProps = (state: State) => {
    const {
        page: { user },
        widgets: {
            subscribers: { subscribers, subscriptions },
        },
    } = state;
    return {
        user,
        subscribers,
        subscriptions,
    };
};

const mapDispatchToProps = {
    receiveSubscriptions,
    receiveSubscribers,
};

export const SubscribersContainer = connect(mapStateToProps, mapDispatchToProps)(SubscribersComponent);
