import React, { ReactElement } from 'react';

import * as Styled from './styled';

export type ButtonBadgeProps = {
    isShowBadge?: boolean;
    className?: string;
    children: ReactElement;
};

export const ButtonBadge = (props: ButtonBadgeProps) => {
    const { isShowBadge = false, className, children } = props;

    return (
        <Styled.ButtonBadgeWrapper className={className}>
            <Styled.Content>
                {children}
            </Styled.Content>
            <Styled.BadgeWrapper>
                <Styled.Badge isShowBadge={isShowBadge} />
            </Styled.BadgeWrapper>
        </Styled.ButtonBadgeWrapper>
    );
};
