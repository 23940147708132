import styled from 'styled-components';

import { labelPrimary, labelSecondary, mediaQuery } from 'app/styled';
import {
    body17MediumFontStyle,
    body14MediumFontStyle,
    body14RegularFontStyle,
    caption12RegularFontStyle,
    body17SemiboldTNumFontStyle,
    body14SemiboldTNumFontStyle,
    body14MediumTNumFontStyle,
    caption12MediumTNumFontStyle,
} from 'services/typography';

export const Side = styled.div`
    display: flex;
`;

const sharedLeftCss = `
    margin-left: 16px;
    ${mediaQuery.lt960} {
        margin-left: 12px;
    }
`;

export const Logo = styled.div`
    height: 40px;
    ${sharedLeftCss}
    & img {
        height: 100%;
    }
`;

export const TickerLink = styled.a`
    color: ${labelPrimary};
    cursor: pointer;
    ${body17MediumFontStyle}
    ${mediaQuery.lt960} {
        ${body14MediumFontStyle}
    }
`;

export const NameLink = styled.a`
    color: ${labelSecondary};
    cursor: pointer;
    ${body14RegularFontStyle}
    ${mediaQuery.lt960} {
        ${caption12RegularFontStyle}
    }
`;

export const TickerSuggestion = styled.span`
    color: ${labelPrimary};
    text-align: left;
    ${body17MediumFontStyle}
    ${mediaQuery.lt960} {
        ${body14MediumFontStyle}
    }
`;

export const NameSuggestion = styled.span`
    color: ${labelSecondary};
    text-align: left;
    ${body14RegularFontStyle}
    ${mediaQuery.lt960} {
        ${caption12RegularFontStyle}
    }
`;

export const Names = styled.div<{ hasLogo: boolean }>`
    ${({ hasLogo }) => (hasLogo && sharedLeftCss) || ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${mediaQuery.lt480} {
        display: grid;
        grid-template-rows: min-content min-content;
        & > ${TickerLink}, & > ${NameLink} {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
`;

export const Price = styled.div`
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
`;

export const PriceValue = styled.div`
    ${body17SemiboldTNumFontStyle};
    color: ${labelPrimary};
    ${mediaQuery.lt960} {
        ${body14SemiboldTNumFontStyle}
    }
    && {
        text-align: right;
    }
`;

export const PriceDiff = styled.div`
    ${body14MediumTNumFontStyle};
    white-space: pre;
    ${mediaQuery.lt960} {
        ${caption12MediumTNumFontStyle}
    }
    && {
        text-align: right;
    }
`;

export const Actions = styled.div``;

export const Painted4InstrumentWrapper = styled.div<{ isGrow: boolean }>`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    ${({ isGrow, theme: { label } }) => {
        const color = isGrow ? label.positive : label.negative;
        return `
            ${PriceDiff} {
                color: ${color}
            }
        `;
    }}
`;
