import { useCallback } from 'react';

import { Id } from 'types';
import { BlogService } from 'services/api-services/generated_limex';
import { ensureNumber } from 'services/utils/ensure-number';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useCurrentUser } from 'hooks/useCurrentUser';

export const useHandleShare = (objId: Id) => {
    const xCsrfToken = useCSRFToken();
    const currentUser = useCurrentUser();
    return useCallback(() => {
        if (currentUser.isAuth) {
            const postId = ensureNumber(objId);
            // от бекенда при шаринге не нужны никакие данные, его просто надо оповестить
            BlogService.sendShareMetric({ xCsrfToken, postId });
        }
    }, [xCsrfToken, objId, currentUser]);
};
