import type { SearchLog, SearchResult, SearchState, SearchTabs } from './interfaces';

export const BUSINESS_METRICS_SEARCH_CLICK = `${process.env.BASE_URL_COM}/ng/api/v1/internal/search/click/`;
export const instrumentExchangeAcronymsMap = process.env.INSTRUMENT_EXCHANGE_ACRONYMS_MAP;

export const SEARCH_TABS: SearchTabs[] = ['instruments', 'people', 'posts', 'products', 'communities'];

export const DEFAULT_LOG_VALUE: SearchLog = { log_id: 0 };

export const initialSearchResultState: SearchResult = {
    instruments: [],
    people: [],
    posts: [],
    products: [],
    communities: [],
    log: DEFAULT_LOG_VALUE,
};

export const initialState: SearchState = {
    searchResult: initialSearchResultState,
    loadingTabs: [],
    activeTab: SEARCH_TABS[0],
};
