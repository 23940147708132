import React, { FC, useEffect } from 'react';

import { notEmpty } from 'services/utils/not-empty';
import { useCardProps } from 'hooks/mp-share/useCardProps';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLocaleId } from 'hooks/useLocaleId';
import { FinamSliderCard } from 'widgets/products-slider/components/Card';
import { InfiniteScroll } from 'components/infinite-scroll';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import * as Styled from '../styled';

import { UserProductsProps } from './types';
import { NoProducts } from './no-products/NoProducts';

export const UserProducts: FC<UserProductsProps> = (props) => {
    const {
        products = [],
        productsIsLoading,
        productsIsReady,
        allFavorites,
        addProductToFavorites,
        removeProductFromFavorites,
        getMoreProducts,
        getProducts,
        getFavorites,
        authorId,
    } = props;

    const user = useCurrentUser();
    const lcid = useLocaleId();

    const { getCardProps } = useCardProps({
        favorites: allFavorites?.map(({ id }) => id).filter(notEmpty),
        removeProductFromFavorites,
        addProductToFavorites,
        productPagePath: `/${lcid}/shopv2`,
    });

    useEffect(() => {
        getProducts({ authorId });
        if (user.isAuth) {
            getFavorites({});
        }
    }, [getFavorites, getProducts, user.isAuth, authorId]);

    if (productsIsLoading) {
        return (
            <Styled.Loading>
                <ProcessingIcon />
            </Styled.Loading>
        );
    }

    if (productsIsReady && products.length === 0) {
        return <NoProducts />;
    }

    return (
        <InfiniteScroll
            onLoadMore={() => {
                getMoreProducts({ authorId });
            }}
            isLoading={productsIsLoading}
        >
            <Styled.Catalog>
                {products.map((item) => (
                    <FinamSliderCard item={item} key={item.id} getCardProps={getCardProps} />
                ))}
            </Styled.Catalog>
            {productsIsLoading && (
                <Styled.Loading>
                    <ProcessingIcon />
                </Styled.Loading>
            )}
        </InfiniteScroll>
    );
};
