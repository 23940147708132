import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CloseIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.43353 5.43427C5.74595 5.12185 6.25248 5.12185 6.5649 5.43427L11.9992 10.8686L17.4335 5.43427C17.746 5.12185 18.2525 5.12185 18.5649 5.43427C18.8773 5.74669 18.8773 6.25322 18.5649 6.56564L13.1306 12L18.5649 17.4343C18.8773 17.7467 18.8773 18.2532 18.5649 18.5656C18.2525 18.8781 17.746 18.8781 17.4335 18.5656L11.9992 13.1313L6.5649 18.5656C6.25248 18.8781 5.74595 18.8781 5.43353 18.5656C5.12111 18.2532 5.12111 17.7467 5.43353 17.4343L10.8678 12L5.43353 6.56564C5.12111 6.25322 5.12111 5.74669 5.43353 5.43427Z"
        />
    </IconSvg>
);
