import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const OverlayStyled = styled.div<{ $visible: boolean }>`
    position: fixed;
    z-index: 4;
    width: 100%;
    height: calc(100% - 64px);
    ${mediaQuery.lt768} {
        display: none;
    }
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
    transition-property: opacity;
    transition-duration: 300ms;
`;
