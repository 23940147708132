import { useLayoutEffect, useRef, useState } from 'react';

import { FeedRecord } from 'types';
import { sentryClient, SentryError } from 'services/sentry';
import { useNonClosableRef } from 'hooks/useUnclosureRef';

export const useCheckFeedLength = <
    Props extends {
        boundary?: string | null;
        records: FeedRecord[];
    },
>(
    props: Props & {
        isLoading: boolean;
    },
) => {
    const { records = [], boundary, isLoading } = props;
    const recordsLength = records.length;
    const [isFeedLengthError, setFeedLengthError] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const propsRef = useNonClosableRef(props);

    useLayoutEffect(() => {
        if (recordsLength && timeout.current) {
            clearTimeout(timeout.current);
            return undefined;
        }
        timeout.current = setTimeout(() => {
            if (!recordsLength && boundary !== null && !isLoading) {
                setFeedLengthError(true);
                sentryClient.captureException(
                    new SentryError('Подозрительно пустая лента', 'CheckFeedLength'),
                    (scope) => {
                        scope.setContext('props', propsRef.current);
                        return scope;
                    },
                );
            }
        }, 10000);
        return () => {
            if (timeout.current) clearTimeout(timeout.current);
        };
    }, [recordsLength, boundary, isLoading, propsRef]);

    return {
        isFeedLengthError,
    };
};
