import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { mediaQuery, ResponsiveMap, matchBreakpoints } from 'app/styled';
import { Avatar as AvatarComponent } from 'components/avatar';
import { Avatar as AvatarStyled } from 'components/avatar/styled/Avatar.styled';

export const Substrate = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
    opacity: 0;
    border-radius: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 360ms;
`;

const onActive = css`
    ${Substrate} {
        opacity: 1;
    }
`;

export const AvatarBlock = styled(AvatarComponent)`
    display: block;

    ${AvatarStyled} {
        ${({ theme: { bg } }) => `
            outline: 2px solid ${bg.secondary};
    `};
    }
`;

export const ActiveAvatarWrapper = styled.div<{
    isActiveOnHover?: boolean;
    isActive: 'yes' | 'no' | ResponsiveMap<'yes' | 'no'>;
    hasOffset: boolean;
}>`
    position: relative;
    display: inline-block;
    ${({ isActive }) =>
        matchBreakpoints(isActive, {
            yes: onActive,
            no: '',
        })}
    ${ifProp(
        'isActiveOnHover',
        css`
            &:hover {
                ${onActive}
            }
        `,
        `
        pointer-events: none;
        `,
    )}
    ${ifProp(
        'hasOffset',
        css`
            ${mediaQuery.lt768} {
                position: absolute;
                top: -40px;
            }
        `,
    )}
`;
