import styled from 'styled-components';

import { tokens } from 'services/tokens';
import { body14RegularFontStyle } from 'services/typography';
import { taggedTemplateNoop } from 'services/utils/tagged-template-literal';

export const Tooltip = styled.div`
    display: contents;
`;

export const Floating = styled.div<{ $zIndex: number }>`
    outline: none;
    z-index: ${({ $zIndex }) => $zIndex};
`;

const css = taggedTemplateNoop;

const Primary = css`
    background-color: ${tokens.colors.bg.base.tooltip.default};
    color: ${tokens.colors.text.ondark.default.default};
`;

const Secondary = css`
    background-color: ${tokens.colors.bg.base.default.default};
    color: ${tokens.colors.text.base.default.default};
`;

export const Transition = styled.div<{ kind: 'primary' | 'secondary' }>`
    outline: none;
    border-radius: 4px;
    padding: 4px 8px;
    ${body14RegularFontStyle};
    box-shadow: ${tokens.shadow.menuPopover};
    ${({ kind }) => kind === 'primary' && Primary};
    ${({ kind }) => kind === 'secondary' && Secondary};
    overflow-wrap: anywhere;
`;
