import { Dic } from 'services/dictionary';

export const featureTitles = {
    recommendedHoldingPeriod: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_period'),
    generalTradingIdea: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_idea'),
    tradingMethod: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_method'),
    cashAndLeverage: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_cash'),
    riskManagement: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_risk'),
    additionalInformation: Dic.word('wt_all__widget_product_wizard__copy_trading_feature_other'),
};
