import React from 'react';

import { useWebsockets } from '../hooks';
import { useManageToggleViewNotifications } from '../hooks/useManageToggleViewNotifications';
import {
    MapDispatchToPropsType,
    MapStateToPropsType,
} from '../containers/SocketConnectionAndToggleViewTransmitterContainer';

export type SocketConnectionAndToggleViewTransmitterProps = MapStateToPropsType & MapDispatchToPropsType;

export const SocketConnectionAndToggleViewTransmitter = (props: SocketConnectionAndToggleViewTransmitterProps) => {
    const {
        // state
        isOpened,
        isError,
        isWasFetchNotifications,
        readDate,
        unreadCount,
        // dispatch
        fetchNotifications,
        readNotifications,
        readInStoreNotifications,
        addInStoreNotification,
    } = props;

    useManageToggleViewNotifications({
        readInStoreNotifications,
        fetchNotifications,
        readNotifications,
        readDate,
        unreadCount,
        isOpened,
        isError,
        isWasFetchNotifications,
    });

    useWebsockets({
        isOpened,
        addInStoreNotification,
        readInStoreNotifications,
        readNotifications,
        unreadCount,
    });

    return null;
};
