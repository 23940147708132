import React from 'react';

import { IconSvg, IconComponent } from '.';

export const StarFillSmallIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M12.1088 5.8L13.8634 10.0185L18.4176 10.3836L14.9478 13.3559L16.0078 17.8L12.1088 15.4185L8.20977 17.8L9.26986 13.3559L5.80003 10.3836L10.3542 10.0185L12.1088 5.8Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1088 5C12.432 5 12.7234 5.19441 12.8475 5.49278L14.4144 9.26009L18.4815 9.58615C18.8036 9.61197 19.0786 9.82905 19.1784 10.1364C19.2783 10.4437 19.1834 10.7809 18.938 10.9912L15.8393 13.6455L16.786 17.6144C16.861 17.9287 16.7395 18.2573 16.4781 18.4472C16.2166 18.6372 15.8666 18.6512 15.5908 18.4827L12.1088 16.3559L8.62678 18.4827C8.351 18.6512 8.00097 18.6372 7.73954 18.4472C7.47811 18.2573 7.35663 17.9287 7.4316 17.6144L8.37832 13.6455L5.27959 10.9912C5.03417 10.7809 4.93933 10.4437 5.03919 10.1364C5.13905 9.82905 5.41399 9.61197 5.7361 9.58615L9.80324 9.26009L11.3702 5.49278C11.4942 5.19441 11.7857 5 12.1088 5ZM12.1088 7.88317L11.0929 10.3257C10.9777 10.6027 10.7172 10.792 10.4182 10.8159L7.78124 11.0273L9.79031 12.7483C10.0181 12.9435 10.1176 13.2497 10.048 13.5415L9.43423 16.1147L11.6918 14.7358C11.9478 14.5794 12.2698 14.5794 12.5258 14.7358L14.7834 16.1147L14.1696 13.5415C14.1 13.2497 14.1995 12.9435 14.4273 12.7483L16.4364 11.0273L13.7994 10.8159C13.5004 10.792 13.2399 10.6027 13.1247 10.3257L12.1088 7.88317Z"
        />
    </IconSvg>
);
