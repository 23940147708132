import { CreateProductRequestBody } from '../../types';

export enum TypeNames {
    Desktop,
    Mobile,
    Browser,
    Professional,
    Automatic,
    Dma,
    Scalping,
    Analysis,
}

export enum MarketNames {
    Any,
    SharesRu,
    FutOptRu,
    CurrencyRu,
    Fx,
    SharesFutOptWorld,
}

export type Step1Data = Pick<
    CreateProductRequestBody,
    'name' | 'accessUrl' | 'cost' | 'trial' | 'markets' | 'programTypes'
>;

export type Step1FormValues = {
    name: string;
    markets: (keyof typeof MarketNames)[];
    programTypes: (keyof typeof TypeNames)[];
    accessUrl: string;
    cost: number | undefined;
    trial: boolean;
};
