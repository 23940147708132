import React from 'react';
import { useTheme } from 'styled-components';

import { useDictionary } from 'hooks/useDictionary';
import { GradientBanner } from 'components/gradient-banner';

type Props = {
    onMyConnectedOnboarding: () => void;
};

export const CompleteBanner = (props: Props) => {
    const dic = useDictionary();
    const theme = useTheme();
    const { onMyConnectedOnboarding } = props;
    return (
        <GradientBanner
            headingText={dic.word('wt_myspace__widget_myproducts__complete_banner_text')}
            buttonText={dic.word('wt_myspace__widget_myproducts__complete_banner_btn')}
            gradientColor={theme.staticColors.note}
            onButtonClick={onMyConnectedOnboarding}
        />
    );
};
