import produce from 'immer';

import { SUBSCRIBE_ACTION_TYPES, SubscribeActions } from 'widgets/action-subscribe/actions';

import { WidgetSubscribersState } from './types';
import {
    SUBSCRIPTIONS_RECEIVE_ACTION_TYPES,
    SUBSCRIBERS_RECEIVE_ACTION_TYPES,
    ReceiveSubscriptionsActions,
    ReceiveSubscribersActions,
} from './actions';

const [, SUBSCRIBERS_RECEIVE_SUCCESS] = SUBSCRIBERS_RECEIVE_ACTION_TYPES;
const [, SUBSCRIPTIONS_RECEIVE_SUCCESS] = SUBSCRIPTIONS_RECEIVE_ACTION_TYPES;
const [, SUBSCRIBE_ACTION_SUCCESS] = SUBSCRIBE_ACTION_TYPES;

type Action = ReceiveSubscriptionsActions | ReceiveSubscribersActions | SubscribeActions;

const peopleSuccess = (
    type: 'subscribers' | 'subscriptions',
    state: WidgetSubscribersState,
    action: Extract<Action, { type: typeof SUBSCRIBERS_RECEIVE_SUCCESS | typeof SUBSCRIPTIONS_RECEIVE_SUCCESS }>,
) => {
    const substate = state[type];
    const { list, nextCursor: oldNextCursor } = substate;
    const {
        data: { items: nextPack, nextCursor },
    } = action.payload;
    if (nextCursor && oldNextCursor >= nextCursor) return state;
    return produce(state, (draft) => {
        draft[type] = {
            list: [...list, ...nextPack],
            nextCursor,
        };
    });
};

export default (
    state: WidgetSubscribersState = {
        subscribers: {
            list: [],
            nextCursor: 1,
        },
        subscriptions: {
            list: [],
            nextCursor: 1,
        },
    },
    action: Action,
) => {
    switch (action.type) {
        case SUBSCRIBERS_RECEIVE_SUCCESS: {
            return peopleSuccess('subscribers', state, action);
        }
        case SUBSCRIPTIONS_RECEIVE_SUCCESS: {
            return peopleSuccess('subscriptions', state, action);
        }
        case SUBSCRIBE_ACTION_SUCCESS: {
            const {
                data,
                pass: { generatorId, generatorType },
            } = action.payload;
            const { status } = data;
            let newState = state;
            const handleNewState = (
                currentState: WidgetSubscribersState,
                type: 'subscribers' | 'subscriptions',
            ): WidgetSubscribersState => {
                const index = state[type].list.findIndex(
                    (user) => user.id === generatorId && generatorType === user.type,
                );
                if (index !== -1) {
                    return produce(currentState, (draft) => {
                        const person = draft[type].list[index];
                        Object.assign(person.counters, {
                            followers: (person.counters?.followers || 0) + (status.subscription ? 1 : -1),
                        });
                        Object.assign(person.data, { subscribed: status.subscription });
                    });
                }
                return currentState;
            };
            newState = handleNewState(newState, 'subscribers');
            newState = handleNewState(newState, 'subscriptions');
            return newState;
        }
        default: {
            return state;
        }
    }
};
