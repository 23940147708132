import React from 'react';

import { FeedContextProvider, FeedContextValue } from 'context/feed';
import { useIncrement } from 'hooks/useIncrement';

import { Feed } from './components';

export type Props = React.ComponentProps<typeof Feed> & {
    feedContext: FeedContextValue;
};

export const FeedWithContext = (props: Props) => {
    const { feedContext, ...restProps } = props;
    const [feedKey, incFeedKey] = useIncrement();
    return (
        <FeedContextProvider initialValue={{ ...feedContext, remountFeed: incFeedKey }}>
            <Feed {...restProps} key={feedKey} />
        </FeedContextProvider>
    );
};
