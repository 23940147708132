import type { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import stringify from 'fast-json-stable-stringify';
import isEqual from 'lodash/isEqual';

import type { AnyObject } from 'types';

import { AsyncMethod, GlobalStateForCache, PaginationConfig, ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

import { deleteRequest } from './deleteRequest';
import { deleteData } from './deleteData';
import { updateArgs } from './updateArgs';
import { OnFulfilledCallback, RequestOptions, RequestThunk, Thunk } from './types';

export function createFetch<TYPES_MAP extends object, NAME extends string, API_METHOD extends AsyncMethod, PAGE_DATA>({
    resourcesMap,
    endpointName,
    thunk,
    pagination,
    onFulfilled,
    paramsCount,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: NAME;
    thunk: Thunk<API_METHOD>;
    pagination?: PaginationConfig<API_METHOD, PAGE_DATA>;
    onFulfilled?: OnFulfilledCallback<API_METHOD>;
    paramsCount: number;
}): RequestThunk<API_METHOD> {
    return (
            _args: Parameters<API_METHOD>[0] = {},
            _options?: RequestOptions,
        ): ThunkAction<Promise<Awaited<ReturnType<API_METHOD>>>, GlobalStateForCache, AnyObject, AnyAction> =>
        (dispatch, getState, extraArgument) => {
            const args = paramsCount === 0 ? {} : _args;
            const options = paramsCount === 0 ? (_args as RequestOptions | undefined) : _options;
            const state = getState();
            const argsWithPage = pagination ? { ...args, ...pagination.firstPage } : args;
            const request = state.cache.requests[endpointName]?.[stringify(argsWithPage)];

            if (request && !options?.force) {
                if (options?.requestName) {
                    if (!isEqual(state.cache.args[endpointName].named[options.requestName], args)) {
                        dispatch(updateArgs(endpointName, options.requestName, args));
                    }
                }

                return Promise.resolve(
                    cacheRead({
                        kind: 'request',
                        cache: state.cache.data,
                        resourcesMap,
                        typename: endpointName,
                        id: stringify(argsWithPage),
                    })?.result as Awaited<ReturnType<API_METHOD>>,
                );
            }

            return thunk({
                params: argsWithPage,
                pass: {
                    requestName: options?.requestName,
                    force: Boolean(request && options?.force),
                },
            })(dispatch, getState, extraArgument).then((result) => {
                if (pagination && request && options?.force) {
                    const keys = state.cache.args[endpointName].pagination[stringify(args)]
                        .map(({ key }) => key)
                        .slice(1);
                    dispatch(deleteData('request', endpointName, keys));
                    dispatch(deleteRequest(endpointName, keys));
                }
                onFulfilled?.({ args, result, dispatch, getState });
                return result;
            });
        };
}
