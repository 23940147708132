import produce from 'immer';

import { GeneratorType } from 'types';
import { mergeByPath } from 'services/utils/merge';
import { BlogPostExporter } from 'services/api-services/generated_limex';
import { SUBSCRIBE_ACTION_TYPES, SubscribeActions } from 'widgets/action-subscribe/actions';
import { SAVE_USER_ACTION_TYPES, SaveUserActions } from 'widgets/user-form/actions';
import { saveSuccess } from 'widgets/user-form/reduceParts';
import {
    ADD_BLACKLIST_ACTION_TYPES,
    REMOVE_BLACKLIST_ACTION_TYPES,
    REDUCE_FOLLOWING_COUNT,
    AddBlacklistActions,
    RemoveBlacklistActions,
    ReduceFollowingCountActions,
} from 'widgets/action-blacklist/actions';
import {
    PUBLISH_FEED_RECORD_ACTION_TYPES,
    PublishFeedRecordActions,
    RESTORE_FEED_RECORD_ACTION_TYPES,
    RestoreFeedRecordActions,
    DELETE_FEED_RECORD_ACTION_TYPES,
    DeleteFeedRecordActions,
} from 'widgets/feed/feed/actions';
import { isPremiumPost } from 'widgets/feed/helpers';

import { State } from '../types';

const [, SUBSCRIBE_ACTION_SUCCESS] = SUBSCRIBE_ACTION_TYPES;
const [, ADD_BLACKLIST_SUCCESS] = ADD_BLACKLIST_ACTION_TYPES;
const [, REMOVE_BLACKLIST_SUCCESS] = REMOVE_BLACKLIST_ACTION_TYPES;
const [, SAVE_USER_SUCCESS] = SAVE_USER_ACTION_TYPES;
const [, PUBLISH_FEED_RECORD_SUCCESS] = PUBLISH_FEED_RECORD_ACTION_TYPES;
const [, RESTORE_FEED_RECORD_SUCCESS] = RESTORE_FEED_RECORD_ACTION_TYPES;
const [, DELETE_FEED_RECORD_SUCCESS] = DELETE_FEED_RECORD_ACTION_TYPES;
const getNewDataForSubscribeSuccess = (genType: GeneratorType, status: boolean) => {
    switch (genType) {
        case GeneratorType.PREMIUM_POST:
            return {
                subscribed: status,
                premiumSubscribed: status,
            };
        default:
            return {
                subscribed: status,
            };
    }
};

const changeTabsBlogpostsCount = (
    state: State['page'],
    post: Pick<BlogPostExporter, 'blogpostType'> | undefined,
    delta: number,
) =>
    produce(state, (draft) => {
        if (isPremiumPost(post)) {
            draft.tabs.premiumBlogpostsCount += delta;
        } else {
            draft.tabs.blogpostsCount += delta;
        }
    });

export default (rawInitialState: State['page']) =>
    (
        state = rawInitialState,
        action:
            | SubscribeActions
            | AddBlacklistActions
            | RemoveBlacklistActions
            | ReduceFollowingCountActions
            | SaveUserActions
            | PublishFeedRecordActions
            | RestoreFeedRecordActions
            | DeleteFeedRecordActions,
    ) => {
        switch (action.type) {
            case SUBSCRIBE_ACTION_SUCCESS: {
                const {
                    data,
                    pass: { generatorId, generatorType, currentUserId },
                } = action.payload;
                const { status } = data;
                const { user } = state;
                if (status) {
                    const getNewCount = (type: 'following' | 'followers') =>
                        Math.max((user.counters?.[type] || 0) + (status.subscription ? 1 : -1), 0);
                    // если это страница текущего юзера то надо менять счетчик подписок
                    if (user.id === currentUserId) {
                        const newFollowingCount = getNewCount('following');
                        return mergeByPath(state, 'user', {
                            counters: { following: newFollowingCount },
                        });
                    }
                    if (
                        user.id !== generatorId ||
                        (generatorType !== GeneratorType.USER && generatorType !== GeneratorType.PREMIUM_POST)
                    ) {
                        return state;
                    }
                    // если новый статус подписки совпадает со старым, то значение счетчика менять не надо
                    // надо помнить, что user.data.subscribed - подписан текущий (currentUser) юзер на данного
                    const newFollowersCount =
                        Boolean(user.data?.subscribed) === Boolean(status.subscription)
                            ? user.counters?.followers || 0
                            : getNewCount('followers');

                    return mergeByPath(state, 'user', {
                        data: getNewDataForSubscribeSuccess(generatorType, status.subscription),
                        counters: { followers: newFollowersCount },
                    });
                }
                return state;
            }
            case ADD_BLACKLIST_SUCCESS: {
                return mergeByPath(state, 'user.isBlockedByCurrentUser', true);
            }
            case REMOVE_BLACKLIST_SUCCESS: {
                return mergeByPath(state, 'user.isBlockedByCurrentUser', false);
            }
            case SAVE_USER_SUCCESS: {
                return saveSuccess(state, action.payload);
            }
            case RESTORE_FEED_RECORD_SUCCESS:
            case PUBLISH_FEED_RECORD_SUCCESS: {
                const {
                    data: { post },
                } = action.payload;
                return changeTabsBlogpostsCount(state, post, 1);
            }
            case DELETE_FEED_RECORD_SUCCESS: {
                const {
                    data: { post },
                } = action.payload;
                return changeTabsBlogpostsCount(state, post, -1);
            }
            case REDUCE_FOLLOWING_COUNT:
                return produce(state, (draft) => {
                    if (draft.user.counters) {
                        draft.user.counters.following--;
                    }
                });
            default:
                return state;
        }
    };
