import React, { ReactElement, useCallback } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { useSwitch } from 'hooks/useSwitch';
import { ConfirmationPopup } from 'widgets/confirmation-popup';
import { Tooltip } from 'components/tooltip';

import { AddFeedCancelButton, AddFeedStatusActions } from './styled/FinalActions';

type Props = {
    isLoading: boolean;
    isImageLoading: boolean;
    isDirty: boolean;
    resetAppearanceRef: React.MutableRefObject<() => void>;
    submitBtn: ReactElement;
};

export const FinalActions = (props: Props) => {
    const { isLoading, isImageLoading, isDirty, resetAppearanceRef, submitBtn } = props;
    const dic = useDictionary();
    const { switchState: isPopupVisible, switchOn: showPopup, switchOff: closePopup } = useSwitch();

    const onConfirmDiscardChanges = useCallback(() => {
        resetAppearanceRef.current();
        closePopup();
    }, [closePopup, resetAppearanceRef]);

    return (
        <>
            <AddFeedStatusActions>
                {/* TODO: Fixme!
                                dmh: пока оставил дикворд от комментов на Cancel,
                                т.к. на старой ленте нет кнопки Cancel в статусе - может быть и у нас не понадобится */}
                <AddFeedCancelButton
                    kind="ghost-secondary"
                    size="small"
                    isDisabled={isLoading}
                    onClick={isDirty ? showPopup : resetAppearanceRef.current}
                    className="autotest__add-feed-status__cancel"
                >
                    {dic.word('wt_feed__widget_comments__cancel_comment')}
                </AddFeedCancelButton>
                {isImageLoading ? (
                    <Tooltip
                        content={dic.word('wt_feed__timeline_status__img_loading_tooltip')}
                        placement="bottom"
                        trigger="mouseenter"
                        arrow
                    >
                        <div>{submitBtn}</div>
                    </Tooltip>
                ) : (
                    submitBtn
                )}
            </AddFeedStatusActions>
            {/* DISMISS CONFIRMATION MODAL */}
            {isPopupVisible && <ConfirmationPopup onConfirm={onConfirmDiscardChanges} onCancel={closePopup} />}
        </>
    );
};
