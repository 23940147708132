import { Id, Person, FeedRecordComment } from 'types';

export enum EObjectTypes {
    RECORD = 3,
    COMMENT = 6,
}

export type RootObject = {
    rootObjectId: Id;
    rootObjectType: 'blog' | 'activity_record';
};

export type ParentObject = { parentObjectId: number | undefined; parentObjectType?: 'comment' | null };

export type MentionUser = Pick<Person, 'displayName' | 'id'> & { photo?: { src?: string } };

export type FormedComment = Partial<FeedRecordComment> & {
    position: number;
    autocompleteType: null | 'users' | 'cachetags';
};

export type SetFormedComment = (comment: FormedComment) => void;

export type SuggestionProps = {
    comment: FormedComment;
    setComment: SetFormedComment;
    cursorElement?: HTMLInputElement | HTMLTextAreaElement | null;
    phrase?: string | null | undefined;
    left?: string;
    top?: string;
    onClose: () => void;
};

export type CommentsFilter = 'showMostLikedComments' | 'showLatestComments' | 'showEarliestComments';

export type CommentsDirection = 'before' | 'after';

export type ResourceState<Resource> = Record<string, Resource>;
