import { connect } from 'react-redux';

import { State } from '../../types';
import { complain } from '../../page/actions';

import { ContextActions } from './components';

const mapStateToProps = (state: State) => {
    const {
        page: { user, settingsUrl },
    } = state;
    return {
        user,
        settingsUrl,
    };
};

const mapDispatchToProps = {
    complain,
};

export const ContextActionsContainer = connect(mapStateToProps, mapDispatchToProps)(ContextActions);
