import produce from 'immer';
import uniq from 'lodash/uniq';

import { isPublishStatusRecord } from 'types';
import { recordStoreIndex } from 'services/utils/map-records-by-id';

import { FeedState } from '../types';

import { REVOKE_PREMIUM, RevokePremiumActions } from './actions/revokePremium';
import {
    FETCH_FEED_RECORDS_ACTION_TYPES,
    FetchRecordsActions,
    INSERT_FEED_RECORDS_ACTION,
    InsertFeedRecordActions,
    UPDATE_FEED_RECORD_ACTION_TYPES,
    UpdateFeedRecordActions,
    RESTORE_FEED_RECORD_ACTION_TYPES,
    RestoreFeedRecordActions,
    DELETE_FEED_RECORD_ACTION_TYPES,
    DeleteFeedRecordActions,
} from './actions';

const [FETCH_FEED_RECORDS_REQUEST, FETCH_FEED_RECORDS_SUCCESS, FETCH_FEED_RECORDS_FAILURE] =
    FETCH_FEED_RECORDS_ACTION_TYPES;
const [, UPDATE_FEED_RECORD_SUCCESS] = UPDATE_FEED_RECORD_ACTION_TYPES;
const [, DELETE_FEED_RECORD_SUCCESS] = DELETE_FEED_RECORD_ACTION_TYPES;
const [, RESTORE_FEED_RECORD_SUCCESS] = RESTORE_FEED_RECORD_ACTION_TYPES;

export default (
    state: FeedState,
    action:
        | FetchRecordsActions
        | InsertFeedRecordActions
        | UpdateFeedRecordActions
        | DeleteFeedRecordActions
        | RestoreFeedRecordActions
        | RevokePremiumActions,
): FeedState => {
    switch (action.type) {
        case FETCH_FEED_RECORDS_REQUEST:
            return produce(state, (draft) => {
                const { instanceId, reset } = action.payload.pass;
                if (draft.instances[instanceId]) {
                    draft.instances[instanceId].isLoading = true;
                } else {
                    draft.instances[instanceId] = {
                        isLoading: true,
                        ids: [],
                        deletedIds: [],
                    };
                }
                if (reset) {
                    draft.instances[instanceId].wasLastRequest = false;
                }
            });
        case FETCH_FEED_RECORDS_SUCCESS: {
            const {
                payload: {
                    data: { records, boundary, boundaryRecordId },
                },
            } = action;
            return produce(state, (draft) => {
                const { instanceId, reset } = action.payload.pass;
                records.forEach((record) => {
                    const id = recordStoreIndex(record.id);
                    draft.records[id] = record;
                });
                const instance = draft.instances[instanceId];
                if (reset) {
                    instance.ids = [];
                }
                instance.boundary = boundary;
                instance.boundaryRecordId = boundaryRecordId;
                instance.ids.push(...records.map(({ id }) => recordStoreIndex(id)));
                instance.ids = uniq(instance.ids);
                instance.isLoading = false;
                instance.wasLastRequest = records.length < 10;
            });
        }
        case FETCH_FEED_RECORDS_FAILURE:
            return produce(state, (draft) => {
                const {
                    pass: { instanceId },
                    error,
                } = action.payload;
                const instance = draft.instances[instanceId];
                instance.isLoading = false;
                instance.error = error;
            });
        case INSERT_FEED_RECORDS_ACTION: {
            const {
                payload: { records: insertingRecords, pos },
                pass: { instanceId },
            } = action;
            return produce(state, (draft) => {
                const instance = draft.instances[instanceId];
                insertingRecords.forEach((record) => {
                    const id = recordStoreIndex(record.id);
                    draft.records[id] = record;
                    instance.ids.splice(pos, 0, id);
                });
                instance.ids = uniq(instance.ids);
            });
        }

        case UPDATE_FEED_RECORD_SUCCESS: {
            const { records } = state;
            const {
                payload: {
                    data: { post },
                },
            } = action;

            const record = Object.values(records).find((rec) => isPublishStatusRecord(rec) && rec.obj.id === post.id);
            if (record && isPublishStatusRecord(record)) {
                const recordId = record.id;
                return produce(state, (draft) => {
                    const draftRecord = draft.records[recordStoreIndex(recordId)];
                    if (isPublishStatusRecord(draftRecord)) {
                        draftRecord.obj = post;
                    }
                });
            }
            return state;
        }
        case DELETE_FEED_RECORD_SUCCESS: {
            const {
                payload: {
                    pass: { instanceId, recordId },
                },
            } = action;
            return produce(state, (draft) => {
                draft.instances[instanceId].deletedIds.push(recordStoreIndex(recordId));
            });
        }
        case RESTORE_FEED_RECORD_SUCCESS: {
            const {
                payload: {
                    pass: { instanceId, recordId },
                },
            } = action;
            return produce(state, (draft) => {
                const instance = draft.instances[instanceId];
                instance.deletedIds = instance.deletedIds.filter(
                    (deletedId) => deletedId !== recordStoreIndex(recordId),
                );
            });
        }
        case REVOKE_PREMIUM: {
            const authorId = action.payload;
            return produce(state, (draft) => {
                const instance = draft.instances.premiumPosts;
                instance.ids.forEach((id) => {
                    const record = draft.records[id];
                    if (isPublishStatusRecord(record) && record.generator.id === authorId) {
                        record.obj.isPremiumGranted = false;
                    }
                });
            });
        }
        default:
            return state;
    }
};
