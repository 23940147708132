import styled from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';

import { SizeWtPopup3Enum } from '../constants';

import { ContentProps } from './types';

export const ContentWrapper = styled.div<
    Required<Pick<ContentProps, 'size' | 'isMobileFullscreen'>> & { hasDivider: boolean }
>`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    ${({
        theme: {
            divider: { primary: dividerColor },
        },
        hasDivider,
    }) =>
        hasDivider
            ? `
        border-top: 1px solid ${dividerColor};
        border-bottom: 1px solid ${dividerColor};
    `
            : ''}
`;

export const ScrollableWrapper = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
`;

const buildContentStyles = ({
    size,
    withoutPadding,
}: Required<Pick<ContentProps, 'size'>> & Pick<ContentProps, 'withoutPadding'>) => {
    if (withoutPadding === 'all') {
        return 'height: 100%';
    }
    const verticalPaddingTiny = () => (withoutPadding === 'topBottom' ? 0 : 8);

    return matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: `
            padding: ${verticalPaddingTiny()}px 16px;
            height: calc(100% - ${2 * verticalPaddingTiny()}px);
            `,
        [SizeWtPopup3Enum.SMALL]: `
            padding: ${verticalPaddingTiny()}px 32px;
            height: calc(100% - ${2 * verticalPaddingTiny()}px);
            `,
        [SizeWtPopup3Enum.MEDIUM]: `
            padding: ${verticalPaddingTiny()}px 32px;
            height: calc(100% - ${2 * verticalPaddingTiny()}px);
            `,
        [SizeWtPopup3Enum.LARGE]: `
            padding: ${verticalPaddingTiny()}px 48px;
            height: calc(100% - ${2 * verticalPaddingTiny()}px);
            `,
    });
};
export const Content = styled.div<Required<Pick<ContentProps, 'size'>> & Pick<ContentProps, 'withoutPadding'>>`
    ${body14RegularFontStyle};
    ${buildContentStyles};
    box-sizing: border-box;
    width: 100%;

    ${mediaQuery.lt768} {
        ${({ withoutPadding }) =>
            buildContentStyles({
                size: SizeWtPopup3Enum.TINY,
                withoutPadding,
            })};
    } ;
`;

export const Text = styled.p`
    margin: 0;
    padding: 0;
`;
