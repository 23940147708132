import styled, { css } from 'styled-components';

import { reduceBreakpoints } from 'app/styled';
import { AvatarUser } from 'components/avatar-user';
import { Avatar } from 'components/avatar/styled/Avatar.styled';

import { VotersSize } from '../types';

export const VoterAvatarUser = styled(AvatarUser)`
    ${Avatar} {
        border: 2px solid ${({ theme }) => theme.bg.primary};
        border-radius: 8px;
    }

    &:not(:first-child) {
        margin-left: -4px;
        z-index: 1;
    }
`;

export const Voters = styled.div<{ size: VotersSize }>`
    display: flex;
    margin-right: 4px;

    ${({ size }) =>
        reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'small':
                    return css`
                        ${VoterAvatarUser} {
                            &:not(:first-child) {
                                margin-left: -10px;
                            }
                        }
                    `;
                case 'regular':
                default:
                    return '';
            }
        })}
`;
