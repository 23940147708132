import React from 'react';

import { RightGroupProps } from '../types/RightGroup.types';
import { DEFAULT_SIZE_LIST_BOX } from '../constants';
import * as Styled from '../styled/RightGroup.styled';

export const RightGroup = (props: RightGroupProps) => {
    const { text, icon, isDisabled = false, isError = false, size = DEFAULT_SIZE_LIST_BOX } = props;

    const Icon = icon || null;

    if (!text && !icon) {
        return null;
    }

    return (
        <Styled.RightGroup>
            {text && (
                <Styled.RightGroupTextWrapper isDisabled={isDisabled} isError={isError} size={size}>
                    <Styled.RightGroupText isDisabled={isDisabled} isError={isError} size={size}>
                        {text}
                    </Styled.RightGroupText>
                </Styled.RightGroupTextWrapper>
            )}
            {Icon && (
                <Styled.RightGroupIcon isDisabled={isDisabled} isError={isError}>
                    <Icon size={24} />
                </Styled.RightGroupIcon>
            )}
        </Styled.RightGroup>
    );
};
