import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Id, LimexFile } from 'types';
import { ValidationError } from 'services/validation-error';

import { useAsyncFnExternal, UseAsyncFnSetState, UseAsyncFnState } from './useAsyncFnExternal';

export type AttachmentServerResponse =
    | {
          files: LimexFile[];
      }
    | {
          violations: {
              message: string;
          }[];
      };

export type Attachment = LimexFile & {
    errors?: ConstructorParameters<typeof ValidationError>[0];
};

export type AttachmentInput = {
    id?: Id;
    file: File;
};

export type UseAttachmentSettings = {
    uploadFn: (file: File) => Promise<Attachment>;
    editFn: (replacement: AttachmentInput) => Promise<Attachment>;
    removeFn: (attachment: Attachment) => Promise<unknown>;
    cancelFn?: () => void;
};

type Action = 'upload' | 'edit' | 'remove';

function callApiFn({ uploadFn, editFn, removeFn }: UseAttachmentSettings) {
    return async (action: Action, payload?: Attachment | AttachmentInput | File) => {
        if (action === 'upload' && payload instanceof File) {
            return uploadFn(payload);
        }

        if (action === 'edit' && payload && 'id' in payload && 'file' in payload) {
            return editFn(payload);
        }

        if (action === 'remove' && payload && 'id' in payload && 'sizes' in payload) {
            await removeFn(payload);
            return undefined;
        }

        throw new Error();
    };
}

export function useAttachment(settings: UseAttachmentSettings & { initialAttachment?: Attachment }) {
    type State = UseAsyncFnState<Attachment | undefined, unknown>;
    const makeInitialState = () => useAttachmentExternal.makeInitialState(settings.initialAttachment);
    const [state, setState] = useState<State>(makeInitialState);

    return useAttachmentExternal(state, setState, settings);
}

export function useAttachmentExternal(
    state: UseAsyncFnState<Attachment | undefined, unknown>,
    setState: UseAsyncFnSetState<Attachment | undefined, unknown>,
    settings: UseAttachmentSettings,
) {
    const { data: attachment } = state;

    const settingsRef = useRef(settings);
    const attachmentRef = useRef(attachment);

    useLayoutEffect(() => {
        settingsRef.current = settings;
        attachmentRef.current = attachment;
    });

    const callApi = useAsyncFnExternal(setState, callApiFn(settingsRef.current));

    const upload = useCallback(
        async (file: File) => {
            if (attachmentRef.current) {
                const replacement = { id: attachmentRef.current.id, file };
                await callApi('edit', replacement);
                return;
            }

            await callApi('upload', file);
        },
        [callApi],
    );

    const remove = useCallback(async () => {
        if (!attachmentRef.current) {
            return;
        }

        await callApi('remove', attachmentRef.current);
    }, [callApi]);

    const cancel = useCallback(() => {
        settingsRef.current.cancelFn?.();
    }, []);

    const actions = useMemo(
        () => ({
            upload,
            remove,
            cancel,
        }),
        [upload, remove, cancel],
    );

    return {
        state,
        setState,
        actions,
    };
}

useAttachmentExternal.makeInitialState = (initialAttachment?: Attachment) =>
    useAsyncFnExternal.makeInitialState({
        data: initialAttachment,
    });

type UseAttachmentExternalReturn = ReturnType<typeof useAttachmentExternal>;

export type UseAttachmentState = UseAttachmentExternalReturn['state'];

export type SetUseAttachmentState = UseAttachmentExternalReturn['setState'];

export type UseAttachmentActions = UseAttachmentExternalReturn['actions'];
