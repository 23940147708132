import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ProductImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    ${mediaQuery.lt768} {
        gap: 12px;
        && > * {
            height: 27.5vw;
            width: 26.5vw;
            min-height: 88px;
            min-width: 85px;
            max-height: 128px;
            max-width: 128px;
        }
    }
`;

export const SupplementaryProductImageUploadContainer = styled.div`
    height: 128px;
    width: 120px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};
    border-radius: 8px;
`;
