import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { caption12MediumFontStyle } from 'services/typography';

export const FooterContainer = styled.div<{ withPageLayout: boolean }>`
    ${({ withPageLayout }) =>
        withPageLayout
            ? `
        margin: 32px auto 0;
        max-width: 1320px;
        padding: 0 34px;

        ${mediaQuery.lt1200} {
            padding: 0 8px;
        };

        ${mediaQuery.lt960} {
            padding: 0 24px;
        };

        ${mediaQuery.lt768} {
            padding: 0 4px;
        };
    `
            : `
        margin: 32px 0 0;
    `};
`;

export const FooterContent = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: 1px solid ${({ theme }) => theme.divider.altPrimary};
    padding: 32px 0;
    margin: 0 auto;
    gap: 24px;

    ${mediaQuery.lt1400} {
        gap: 20px;
    }
    ${mediaQuery.lt1200} {
        gap: 16px;
    }

    ${mediaQuery.lt960} {
        column-gap: 16px;
        row-gap: 32px;
        grid-template-columns: repeat(2, 1fr);
    }

    ${mediaQuery.lt768} {
        grid-template-columns: 1fr;
        row-gap: 24px;
        column-gap: initial;
        padding: 32px 16px;
    }
`;

export const LogoBlock = styled.div`
    ${mediaQuery.lt960} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    ${mediaQuery.lt768} {
        grid-column-start: initial;
        grid-column-end: initial;
    }
`;

export const ContactsBlock = styled.div`
    ${mediaQuery.lt960} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    ${mediaQuery.lt768} {
        grid-column-start: initial;
        grid-column-end: initial;
    }
`;

export const EmailLine = styled.p`
    ${caption12MediumFontStyle};
    margin: 0 0 16px 0;
    span {
        color: ${({ theme }) => theme.label.secondary};
    }
`;
