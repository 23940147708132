import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CheckIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6757 6.01285C20.0828 6.38604 20.1103 7.01861 19.7372 7.42573L10.1122 17.9257C9.92785 18.1268 9.66941 18.2438 9.39672 18.2498C9.12403 18.2557 8.86076 18.15 8.66789 17.9571L4.29289 13.5821C3.90237 13.1916 3.90237 12.5584 4.29289 12.1679C4.68342 11.7774 5.31658 11.7774 5.70711 12.1679L9.34359 15.8044L18.2628 6.07428C18.636 5.66716 19.2686 5.63966 19.6757 6.01285Z"
        />
    </IconSvg>
);
