import { nameToAction } from '../utils/nameToAction';

export type DeleteResourceAction = {
    type: `cache/DELETE_${string}`;
    payload: { kind: 'request' | 'resource'; id: unknown };
};

export function deleteData<RESOURCE_NAME extends string>(
    kind: 'request' | 'resource',
    typename: RESOURCE_NAME,
    id: unknown,
): DeleteResourceAction {
    return {
        type: `cache/DELETE_${nameToAction(typename)}`,
        payload: { kind, id: `${id}` },
    };
}
