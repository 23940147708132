import styled from 'styled-components';

import { Button as ButtonComponent } from 'components/button2';

export const ActionButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
`;

export const Button = styled(ButtonComponent)<{ isGreedy: boolean }>`
    ${({ isGreedy }) => (isGreedy ? 'flex: 1' : '')}
`;
