import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

import { mediaQuery } from 'app/styled';
import { AvatarCustomSizeValue } from 'components/avatar/types';
import { Avatar as AvatarComponentStyled } from 'components/avatar/styled/Avatar.styled';

const AVATAR_OUTLINE_BORDER = 2;

export const AvatarInput = styled.div<{ hasError: boolean; size: AvatarCustomSizeValue }>`
    position: absolute;
    top: ${({ size }) => `${isNumber(size) ? `-${size / 2}` : 0}px`};
    border-radius: 8px;

    ${mediaQuery.lt768} {
        top: 0;
        left: 0;
    }

    ${AvatarComponentStyled} {
        ${({ theme, hasError }) => `
                 outline: ${AVATAR_OUTLINE_BORDER}px solid ${hasError ? theme.fillIn.negative.high : theme.bg.primary};
        `};
    }
`;
