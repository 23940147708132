import React from 'react';

import { Action, Id } from 'types';
import { ensureNumber } from 'services/utils/ensure-number';
import { notifyError } from 'services/utils/notify-error';
import { api } from 'services/api-builder/api';
import { useDictionary } from 'hooks/useDictionary';
import { useAsyncFn } from 'hooks/useAsyncFn';
import { useLinks } from 'hooks/useLinks';
import { WtPopup3 } from 'components/wt-popup3';

import { Option } from '../../accounts-list-box';

import { ContentWrapper } from './styled';

type Props = {
    close: () => void;
    strategyId: Id;
    subscribeAutofollow: Action<typeof api.subscribeAutofollow>;
    selectedAccount: Option;
    onPayLater: () => void;
};

export const SupportPopup = (props: Props) => {
    const { close, strategyId, subscribeAutofollow, selectedAccount, onPayLater } = props;
    const dic = useDictionary();
    const links = useLinks();

    const [{ isLoading: isSubscribeProcess }, handleSubscribeAutofollow] = useAsyncFn(
        (isFree: boolean, callback?: (sessionUrl: string | undefined | null) => void) => {
            const productId = ensureNumber(strategyId);
            return subscribeAutofollow({
                requestBody: {
                    productId,
                    accountTradeCode: `${selectedAccount.number}`,
                    isFree,
                    redirectSuccessUrl: links.homeProduct.withBaseUrl({
                        productId,
                        productType: 'UsStrategy',
                        isSuccess: true,
                    }),
                    redirectFailUrl: window.location.href,
                },
            })
                .then((result) => {
                    callback?.(result.data?.sessionUrl);
                })
                .catch((error) => {
                    close();
                    notifyError(error);
                });
        },
    );

    return (
        <WtPopup3
            portalTo="popupRoot"
            width={456}
            close={close}
            isMobileFullscreen
            actionButtons={{
                items: [
                    {
                        key: 0,
                        content: dic.word('wt_mplc2__widget_brokerage_popup__support_btn_later'),
                        kind: 'secondary',
                        isProcessing: isSubscribeProcess,
                        onClick: () => {
                            handleSubscribeAutofollow(true, onPayLater);
                        },
                    },
                    {
                        key: 1,
                        content: dic.word('wt_mplc2__widget_brokerage_popup__support_btn_pay'),
                        kind: 'primary',
                        isProcessing: isSubscribeProcess,
                        onClick: () => {
                            handleSubscribeAutofollow(false, (sessionUrl) => {
                                if (sessionUrl) {
                                    location.href = sessionUrl;
                                }
                            });
                        },
                    },
                ],
                direction: 'horizontal',
                align: 'right',
            }}
            title={dic.word('wt_mplc2__widget_brokerage_popup__support_title')}
        >
            <ContentWrapper>{dic.word('wt_mplc2__widget_brokerage_popup__support_text')}</ContentWrapper>
        </WtPopup3>
    );
};
