import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const LightningIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path
            d="M10.8876 8.44375C10.8876 8.44375 15.4141 1.19218 15.5172 1.01093C15.6204 0.829684 15.6423 0.496872 15.3579 0.496872C15.0735 0.496872 8.02194 7.69531 8.02194 7.69531L3.01412 10.6641C3.01412 10.6641 2.67818 10.8703 2.72975 11.1281C2.78131 11.3859 3.00631 11.3969 3.10943 11.3969C3.21256 11.3969 8.55475 11.3687 8.86569 11.3594C9.25162 11.3484 9.15631 11.8969 9.15631 11.8969L5.77506 17.2125C5.77506 17.2125 4.2985 19.2562 4.2985 19.3953C4.2985 19.6 4.54537 19.7016 4.77506 19.5641C4.95318 19.4578 16.4688 9.7 16.9766 9.02968C17.1141 8.84687 17.1844 8.36562 16.6172 8.36562C16.0501 8.36562 11.5329 9.68125 11.5329 9.68125L10.8876 8.44375Z"
            fill="#FEB804"
        />
        <path
            d="M10.0954 7.86718C9.95635 8.05781 9.91885 8.37656 10.2188 8.39531C10.5173 8.4125 16.6173 8.36718 16.6173 8.36718C16.6173 8.36718 12.2595 12.3719 10.9407 13.5672C9.62197 14.7625 5.25479 18.6344 4.96104 18.9078C4.67353 19.1766 4.4126 19.4234 4.30635 19.3891C4.28916 19.3844 4.26729 19.2922 4.67822 18.6156C5.05322 17.9984 8.65322 11.9656 8.79385 11.7375C8.93447 11.5094 9.05791 11.3156 9.12822 11.2094C9.19853 11.1031 9.42666 10.5234 8.95166 10.5234C8.47666 10.5234 3.01416 10.6641 3.01416 10.6641C3.01416 10.6641 6.7876 7.4625 7.87822 6.60156C8.96885 5.74062 15.1501 0.496872 15.3579 0.496872C15.5657 0.496872 10.236 7.675 10.0954 7.86718Z"
            fill="#FFC927"
        />
        <path
            d="M9.91414 11.0547C10.1954 10.65 10.4719 10.6203 10.6391 10.7203C10.8548 10.85 10.8766 11.1484 10.6048 11.5094C10.1766 12.0813 7.02976 16.2922 6.90945 16.4406C6.68132 16.7219 6.34539 16.5984 6.56414 16.1906C6.74695 15.8531 9.62351 11.4734 9.91414 11.0547Z"
            fill="#FFE567"
        />
        <path
            d="M5.4421 10.2328C4.90304 10.5937 4.40147 10.0547 4.76241 9.65937C5.12335 9.26406 7.4921 7.29218 7.97179 6.88437C8.45147 6.47656 11.2515 4.04062 11.4687 3.86562C11.7562 3.63437 11.9343 3.86875 11.753 4.10937C11.5733 4.34999 9.59366 6.32812 8.72022 7.13124C8.04522 7.75312 5.9546 9.88905 5.4421 10.2328Z"
            fill="#FFE567"
        />
    </EmojiIconSvg>
);
