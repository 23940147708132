import { useCallback, useMemo } from 'react';

import { useWebsocketListener } from 'hooks/useWebsocketListener';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCSRFToken } from 'hooks/useCSRFToken';

import { NotificationItem, NotificationsState } from '../types';
import { AddInStoreNotification, ReadInStoreNotifications, ReadNotifications } from '../actions';
import { getHasUnread } from '../utils';

type useWebsocketsProps = {
    addInStoreNotification: AddInStoreNotification;
    readNotifications: ReadNotifications;
    readInStoreNotifications: ReadInStoreNotifications;
    isOpened: NotificationsState['isOpened'];
    unreadCount: NotificationsState['unreadCount'];
};
export const useWebsockets = (props: useWebsocketsProps) => {
    const { readInStoreNotifications, addInStoreNotification, isOpened, readNotifications, unreadCount } = props;

    const csrf = useCSRFToken();
    const currentUser = useCurrentUser();
    const userChannel = currentUser.channel?.user;
    const hasUnread = useMemo(() => getHasUnread(unreadCount), [unreadCount]);

    // NEW
    const socketAddNotification = useCallback(
        (event?: NotificationItem) => {
            if (event) {
                addInStoreNotification(event);
                if (isOpened && event.timestamp) {
                    readNotifications({
                        csrf,
                        readDate: event.timestamp,
                    });
                }
            }
        },
        [isOpened, readNotifications, addInStoreNotification, csrf],
    );

    useWebsocketListener({
        channelName: userChannel,
        eventName: 'new_notification',
        callback: socketAddNotification,
    });

    // READ
    const socketNotificationsRead = useCallback(() => {
        if (!isOpened && hasUnread) {
            readInStoreNotifications();
        }
    }, [readInStoreNotifications, isOpened, hasUnread]);

    useWebsocketListener({
        channelName: userChannel,
        eventName: 'notifications_read',
        callback: socketNotificationsRead,
    });
};
