import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useNonClosableRef } from 'hooks/useUnclosureRef';

export type ValueOrFunction = string | null | ((prevValue: string | null) => string | null);
type ParamNameOrUpdater = string | ((args: { searchParams: URLSearchParams }) => void);
type NavigateParams = {
    replace?: boolean;
    state?: unknown;
    preventScrollReset?: boolean;
};

// Базовый хук для работы с параметрами URL
function useUrlQuery({ replace, state, preventScrollReset }: NavigateParams) {
    const [currentSearchParams] = useSearchParams();
    const ref = useNonClosableRef([currentSearchParams, useNavigate(), useLocation()] as const);

    const updateQuery = useCallback(
        (paramNameOrUpdater: ParamNameOrUpdater, valueOrFunction: ValueOrFunction) => {
            const [searchParams, navigate, location] = ref.current;

            if (typeof paramNameOrUpdater === 'function') {
                // Если первый аргумент - функция, обновляем параметры через неё
                paramNameOrUpdater({ searchParams });
            } else {
                // Иначе обрабатываем как единичное обновление параметра
                const value =
                    typeof valueOrFunction === 'function'
                        ? valueOrFunction(searchParams.get(paramNameOrUpdater))
                        : valueOrFunction;
                if (value === null) {
                    searchParams.delete(paramNameOrUpdater);
                } else {
                    searchParams.set(paramNameOrUpdater, value);
                }
            }

            navigate(`?${searchParams.toString()}${location.hash}`, { replace, state, preventScrollReset });
        },
        [ref, replace, state, preventScrollReset],
    );

    return { searchParams: currentSearchParams, updateQuery };
}

export function useQueryParameter({
    paramName,
    replace,
    state,
    preventScrollReset,
}: NavigateParams & {
    paramName: string;
}) {
    const { searchParams, updateQuery } = useUrlQuery({
        replace,
        state,
        preventScrollReset,
    });
    const setValue = useCallback(
        (valueOrFunction: ValueOrFunction) => {
            updateQuery(paramName, valueOrFunction);
        },
        [paramName, updateQuery],
    );

    return {
        value: searchParams.get(paramName) || undefined,
        setValue,
    };
}

export function useQueryParameters(params?: NavigateParams) {
    const { replace, state, preventScrollReset } = params ?? {};
    const { searchParams, updateQuery } = useUrlQuery({
        replace,
        state,
        preventScrollReset,
    });

    return {
        value: Object.fromEntries(searchParams),
        setValue: updateQuery,
    };
}
