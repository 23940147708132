import React, { Suspense, useCallback, useEffect, useState } from 'react';

import notify from 'services/notify';
import { useDictionary } from 'hooks/useDictionary';
import { useNonClosableRef } from 'hooks/useUnclosureRef';
import { useLinks } from 'hooks/useLinks';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { Support } from 'widgets/navigation-menu/components/support';
import { ExclamationMarkCircleIcon } from 'components/icons2/ExclamationMarkCircleIcon';

import { SubscriptionSuccessPopup } from '../../product-pages/components/subscription-success-popup';
import { useSignAgreement } from '../../product-wizard/components/steps/step0/useSignAgreement';
import { Option } from '../../accounts-list-box';
import { MapStateToPropsType, MapDispatchToPropsType } from '../types';

import { ErrorButtonText } from './styled';
import { SupportPopup } from './SupportPopup';
import { DisclosurePopup } from './DisclosurePopup';
import { ListboxPopup } from './ListboxPopup';

type Props = MapStateToPropsType &
    MapDispatchToPropsType & {
        title: string;
        close: () => void;
        strategyId: number;
        onLoading?: (isLoading: boolean) => void;
    };

export const BrokerageAccountPopupComponent = (props: Props) => {
    const {
        close,
        title,
        selectedStrategyAutofollowAccounts = [],
        selectedStrategyAutofollowAccountsIsLoading,
        selectedStrategyAutofollowAccountsIsReady,
        strategyId,
        getAutofollowAccounts,
        subscribeAutofollow,
        onLoading,
        getAgreements,
        createAgreement,
        createSubscriberAgreement,
        updateAgreementStatus,
        contactSupport,
    } = props;

    const dic = useDictionary();
    const [selectedAccount, setSelectedAccount] = useState<Option | undefined>();

    const [disclosureDone, setDisclosureDone] = useState<boolean>(false);
    const links = useLinks();
    const [successType, setSuccessType] = useState<'payLater' | 'justPayed' | undefined>(undefined);

    const [
        { error: agreementError, isLoading: isAgreementLoading, isSuccess: isAgreementSuccess },
        handleGetAgreements,
    ] = useSignAgreement(getAgreements, createAgreement, createSubscriberAgreement, updateAgreementStatus);

    useEffect(() => {
        if (agreementError) {
            notify.error(dic.word('wt_all__widget_product_wizard__agreement_error'));
        }
    }, [dic, agreementError]);

    useEffect(() => {
        if (isAgreementSuccess) {
            setDisclosureDone(true);
        }
    }, [isAgreementSuccess]);

    const onLoadingRef = useNonClosableRef(onLoading);
    useEffect(() => {
        onLoadingRef.current?.(selectedStrategyAutofollowAccountsIsLoading);
    }, [selectedStrategyAutofollowAccountsIsLoading, onLoadingRef]);

    const [isShowSupport, setIsShowSupport] = useState(false);

    const hideSupport = useCallback(() => {
        setIsShowSupport(false);
        close();
    }, [close]);

    const currentUser = useCurrentUser();
    const [email, setEmail] = useState('');
    const supportName = currentUser?.displayName ?? '';

    const handleSuccess = useCallback(
        (formEmail: string) => {
            setIsShowSupport(false);
            setEmail(formEmail);
            close();
        },
        [close],
    );

    useEffect(() => {
        getAutofollowAccounts({
            productId: strategyId,
            firstName: 'demoUser',
            lastName: 'demoUser',
        }).catch(() => {
            notify.error({
                text: dic.word('wt_all__shared_message__unknown_error_support'),
                icon: { component: ExclamationMarkCircleIcon },
                button: {
                    content: <ErrorButtonText>{dic.word('wt_all__support_popup__header_text')}</ErrorButtonText>,
                    onClick: () => {
                        setIsShowSupport(true);
                    },
                },
                size: 'small',
            });
        });
    }, [getAutofollowAccounts, close, strategyId, dic]);

    if (selectedStrategyAutofollowAccountsIsLoading) {
        return null;
    }

    if (isShowSupport && contactSupport) {
        return (
            <Suspense fallback={null}>
                <Support
                    contactSupport={contactSupport}
                    name={supportName}
                    email={email}
                    onClose={hideSupport}
                    onSuccess={handleSuccess}
                />
            </Suspense>
        );
    }

    if (!disclosureDone && selectedStrategyAutofollowAccountsIsReady && selectedStrategyAutofollowAccounts?.length) {
        return (
            <DisclosurePopup
                close={close}
                isLoading={isAgreementLoading}
                onDisclosureDone={() =>
                    handleGetAgreements('User', typeof strategyId === 'number' ? strategyId : parseInt(strategyId, 10))
                }
            />
        );
    }

    if (!selectedAccount && selectedStrategyAutofollowAccounts && selectedStrategyAutofollowAccountsIsReady) {
        return (
            <ListboxPopup
                title={
                    selectedStrategyAutofollowAccounts.length > 0
                        ? title
                        : dic.word('wt_mplc2__widget_lime_popup__no_accounts')
                }
                close={close}
                toShowLoading={selectedStrategyAutofollowAccountsIsLoading}
                brokerageAccounts={selectedStrategyAutofollowAccounts}
                onSelectAccountDone={setSelectedAccount}
            />
        );
    }

    if (!successType && selectedAccount) {
        return (
            <SupportPopup
                close={close}
                strategyId={strategyId}
                subscribeAutofollow={subscribeAutofollow}
                selectedAccount={selectedAccount}
                onPayLater={() => setSuccessType('payLater')}
            />
        );
    }

    return successType ? (
        <SubscriptionSuccessPopup
            type={successType}
            close={close}
            subscriptionPage={links.homeSubscription({ productId: strategyId, productType: 'UsStrategy' })}
            productType="UsStrategy"
        />
    ) : null;
};
