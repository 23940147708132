import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const ThinkIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path
            d="M9.9375 18.5625C5.57812 18.5625 0.875 15.8281 0.875 9.82813C0.875 3.82813 5.57812 1.09375 9.9375 1.09375C12.3594 1.09375 14.5938 1.89062 16.25 3.34375C18.0469 4.9375 19 7.1875 19 9.82813C19 12.4688 18.0469 14.7031 16.25 16.2969C14.5938 17.75 12.3438 18.5625 9.9375 18.5625Z"
            fill="url(#paint0_radial_125_15457)"
        />
        <path
            d="M9.9375 18.5625C5.57812 18.5625 0.875 15.8281 0.875 9.82813C0.875 3.82813 5.57812 1.09375 9.9375 1.09375C12.3594 1.09375 14.5938 1.89062 16.25 3.34375C18.0469 4.9375 19 7.1875 19 9.82813C19 12.4688 18.0469 14.7031 16.25 16.2969C14.5938 17.75 12.3438 18.5625 9.9375 18.5625Z"
            fill="url(#paint1_linear_125_15457)"
        />
        <path
            d="M17.4204 4.63594C18.2532 5.97969 18.6875 7.57969 18.6875 9.35938C18.6875 12 17.7344 14.2344 15.9375 15.8281C14.2813 17.2813 12.0313 18.0938 9.62505 18.0938C6.80317 18.0938 3.8438 16.9453 2.09692 14.5094C3.77817 17.2688 6.94067 18.5625 9.93755 18.5625C12.3438 18.5625 14.5938 17.75 16.25 16.2969C18.0469 14.7031 19 12.4688 19 9.82813C19 7.8375 18.4579 6.06875 17.4204 4.63594Z"
            fill="#EB8F00"
        />
        <path
            d="M0.993786 11.7344C0.709411 12.0797 1.3141 12.9969 1.42191 13.4641C1.68129 14.5922 1.42191 14.8703 1.4016 15.8734C1.38441 16.9766 1.7141 17.9047 2.55472 18.4969C3.34535 19.0578 4.29847 19.3453 5.51722 18.9859C6.73597 18.6266 7.4766 18.2297 7.5391 17.6297C7.57347 17.3813 7.44222 17.1375 7.2141 17.0313C7.33597 16.9734 7.43285 16.875 7.48754 16.7516C7.77816 16.0578 7.19847 15.9109 7.19847 15.9109C7.27816 15.8156 7.34535 15.7109 7.39847 15.5984C7.57191 15.2531 7.54379 14.7844 7.04379 14.6313C6.8141 14.5609 6.7516 14.5984 6.7516 14.5984C6.9891 14.3547 7.27347 14.1594 7.58754 14.0281C8.42816 13.6672 9.42504 13.4484 9.67347 13.2375C10.0344 12.925 10.0563 12.3234 9.41879 12.1438C8.50316 11.875 6.81879 12.5 5.79379 13.1516C4.76879 13.8031 3.82816 14.1547 3.51566 14.0359C3.20316 13.9172 3.00941 13.7234 2.89066 13.2547C2.67035 12.3828 2.50004 12.0453 2.1266 11.7188C1.81722 11.4531 1.21254 11.4688 0.993786 11.7344Z"
            fill="url(#paint2_radial_125_15457)"
        />
        <path
            d="M10.0296 12.5594C9.92495 11.1281 7.1062 12.0125 5.66714 12.9547C3.87808 14.1266 3.61558 13.9187 3.44058 13.8094C3.22964 13.6781 3.09683 13.5687 2.9937 13.1625C2.76089 12.2437 2.69683 11.9062 2.27808 11.5406C1.92808 11.2172 0.873389 11.225 0.803077 11.7375C0.726514 12.2891 1.03901 12.3922 1.24995 13.4531C1.51089 14.5281 1.4062 15.7156 1.4062 15.7156C1.4062 15.7156 1.8937 14.5828 1.42495 12.8172C1.32964 12.3406 0.876514 11.6109 1.64214 11.6937C2.38276 11.7734 2.4312 12.4188 2.66245 13.3109C2.79058 13.8156 2.92183 14.1125 3.4312 14.2531C3.93745 14.3922 5.10776 13.7422 5.94839 13.2078C6.97808 12.5531 8.50464 11.9812 9.3062 12.2156C9.65308 12.2906 9.88432 12.7391 9.52026 13.0562C8.94214 13.6016 6.7187 13.8469 5.37964 15.2953C5.37964 15.2953 5.54214 15.3547 5.78589 15.2344C6.0312 15.1141 6.94683 14.6609 6.94683 14.6609C7.22026 14.7766 7.35151 15.2312 7.20307 15.4734C6.96245 15.8656 6.02651 16.4078 5.55933 16.3844C5.55933 16.3844 5.55151 16.6234 6.02026 16.5594C6.1687 16.5391 6.78276 16.4047 7.21245 16.0719C7.53276 16.3031 7.44214 16.6969 7.20151 16.9813C6.96245 17.2641 6.37808 17.6297 5.77651 17.6938C5.77651 17.6938 5.71714 17.9219 6.12183 17.9172C6.52651 17.9125 6.96558 17.7063 7.28901 17.4547C7.33745 17.5313 7.38276 17.6984 7.32808 17.9281C7.2437 18.2828 6.76714 18.6422 5.84683 18.8422C4.99839 19.0266 4.04214 19.1563 2.19683 18.1984C2.19683 18.1984 2.88589 19.0938 4.68433 19.2531C6.40933 19.4063 8.42339 18.6422 7.61558 17.1094C7.61558 17.1094 7.79058 16.85 7.81245 16.4938C7.82807 16.25 7.75151 15.9172 7.57183 15.7656C7.74057 15.2688 7.68745 14.925 7.57964 14.7219C7.50776 14.5875 7.32651 14.4562 7.32651 14.4562C7.32651 14.4562 7.68433 14.1328 8.73901 13.8609C9.41558 13.6844 10.0937 13.4344 10.0296 12.5594Z"
            fill="#EB8F00"
        />
        <path
            d="M2.9124 12.8328C2.9124 12.8328 3.66709 14.0609 5.32959 13.1703C5.32959 13.1703 4.30928 14.2406 3.51553 14.0359C2.72178 13.8312 2.9124 12.8328 2.9124 12.8328Z"
            fill="#EB8F00"
        />
        <path
            d="M7.6561 14.9281C7.6561 14.9281 8.04048 14.1031 8.74048 13.8578C8.74048 13.8578 7.60298 13.9547 7.17017 14.4219L7.6561 14.9281Z"
            fill="#EB8F00"
        />
        <path
            d="M12.011 11.1422C11.961 11.1328 11.9141 11.1125 11.875 11.0797C11.1703 10.5141 10.3297 10.1453 9.43753 10.0078C8.54534 9.85313 7.62972 9.9375 6.78128 10.2516C6.7344 10.2703 6.6844 10.2766 6.6344 10.2688C6.52503 10.25 6.43596 10.1688 6.40628 10.0609C6.37659 9.95469 6.41253 9.84219 6.4969 9.77188C8.15315 8.39688 10.6094 8.625 11.9844 10.2813C12.0969 10.4172 12.2 10.5609 12.2938 10.7094C12.3532 10.8016 12.3532 10.9203 12.2938 11.0125C12.2328 11.1078 12.1219 11.1578 12.011 11.1422Z"
            fill="#422B0D"
        />
        <path
            d="M12.6204 4.86093C13.275 4.86093 13.8704 5.41406 13.8704 6.33281C13.8704 7.25156 13.275 7.80312 12.6204 7.80312C11.9657 7.80312 11.3704 7.24999 11.3704 6.33281C11.3704 5.41562 11.9657 4.86093 12.6204 4.86093Z"
            fill="#422B0D"
        />
        <path
            d="M7.20947 3.4375C6.55479 3.4375 5.95947 3.99063 5.95947 4.90781C5.95947 5.825 6.55479 6.37969 7.20947 6.37969C7.86572 6.37969 8.45947 5.82656 8.45947 4.90781C8.45947 3.98906 7.86572 3.4375 7.20947 3.4375Z"
            fill="#422B0D"
        />
        <path
            d="M6.20797 1.66094C6.13765 1.54532 6.12828 1.40157 6.18297 1.27813C6.24234 1.15469 6.35953 1.07032 6.49547 1.05313C7.34703 0.920316 8.21578 1.12188 8.92203 1.61407C9.18609 1.79532 9.42046 2.01407 9.6189 2.26563C9.6314 2.28125 9.64234 2.29844 9.65328 2.31719C9.75953 2.48282 9.72359 2.70157 9.57046 2.825C9.4064 2.96094 9.16578 2.95625 9.00797 2.8125L9.07359 2.70625L8.9939 2.8C8.30953 2.22813 7.45328 1.90157 6.56265 1.875C6.41422 1.875 6.27672 1.79688 6.20172 1.66875L6.20797 1.66094Z"
            fill="#422B0D"
        />
        <path
            d="M14.5314 4.45626C14.6471 4.3922 14.7252 4.27501 14.7408 4.14376C14.7502 4.00782 14.6924 3.87657 14.5845 3.79376C13.908 3.2547 13.0486 2.99688 12.1877 3.07813C11.8689 3.1047 11.5564 3.1797 11.2596 3.30001L11.2033 3.32657C11.0299 3.41876 10.9533 3.62657 11.0252 3.80938C11.1017 4.00782 11.3096 4.12032 11.5174 4.07501V3.95001L11.5392 4.07188C12.4142 3.90938 13.3189 4.04532 14.108 4.45782C14.2377 4.53126 14.3955 4.53126 14.5252 4.45782L14.5314 4.45626Z"
            fill="#422B0D"
        />
        <path
            d="M7.1814 3.92969C7.02047 3.81719 6.77828 3.85313 6.62203 4.07813C6.46578 4.30469 6.51734 4.54219 6.67828 4.65469C6.83922 4.76719 7.0814 4.73125 7.23765 4.50625C7.3939 4.27969 7.34234 4.04219 7.1814 3.92969Z"
            fill="#896024"
        />
        <path
            d="M12.5891 5.26718C12.4282 5.15468 12.186 5.19062 12.0297 5.41562C11.8735 5.64219 11.9251 5.87969 12.086 5.99219C12.2469 6.10469 12.4891 6.06875 12.6454 5.84375C12.8032 5.61719 12.7516 5.37968 12.5891 5.26718Z"
            fill="#896024"
        />
        <defs>
            <radialGradient
                id="paint0_radial_125_15457"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(9.9375 9.82813) scale(8.89995)"
            >
                <stop offset="0.5" stopColor="#FDE030" />
                <stop offset="0.92" stopColor="#F7C02B" />
                <stop offset="1" stopColor="#F4A223" />
            </radialGradient>
            <linearGradient
                id="paint1_linear_125_15457"
                x1="9.9375"
                y1="18.5625"
                x2="9.9375"
                y2="1.09375"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F4A223" />
                <stop offset="0.08" stopColor="#F7C02B" />
                <stop offset="0.54" stopColor="#FDE030" stopOpacity="0" />
            </linearGradient>
            <radialGradient
                id="paint2_radial_125_15457"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(6.4766 13.0491) scale(9.28624 8.72906)"
            >
                <stop offset="0.27" stopColor="#FFF176" />
                <stop offset="1" stopColor="#FFC400" />
            </radialGradient>
        </defs>
    </EmojiIconSvg>
);
