import { connect } from 'react-redux';
import { ReactNode } from 'react';

import { DispatchType, FeedRecord, PostType } from 'types';
import { GlobalStateForTags } from 'widgets/tags/types';

import { getSocialActivityList } from './feed/actions/fetchSocialActivity';
import { selectRecords } from './selector';
import { GlobalStateForFeed } from './types';
import {
    deleteFeedRecord,
    restoreFeedRecord,
    fetchRecords,
    fetchTickerNews,
    publishFeedRecord,
    setToInit,
    updateFeedRecord,
} from './feed/actions';
import { FeedWithContext } from './feed';

export type FeedRenderNoDataPropType = (args: {
    isLoading: boolean;
    records: FeedRecord[];
    boundary?: string | null;
}) => ReactNode | null | undefined;

export type FeedContainerMapStateOwnPropsType = {
    showTickerNews?: boolean;
    showRecommendedUsers?: boolean;
    showAdds?: boolean;
    filterRecords?: (records: FeedRecord[]) => FeedRecord[];
    instanceId: string;
    showAddFeedStatus?: boolean;
    addFeedStatusPostType?: PostType;
    addFeedStatusStub?: string;
    addFeedStatusPlaceholder?: string;
    isAddFeedStatusActive?: boolean;
    onAddFeedStatusActiveChange?: (isActive: boolean) => void;
    renderNoData?: FeedRenderNoDataPropType;
    pinnedRecordShowMore?: boolean;
};

const mapStateToProps = (
    state: GlobalStateForFeed & Partial<GlobalStateForTags>,
    ownProps: FeedContainerMapStateOwnPropsType,
) => {
    const { feed, tags } = state?.widgets || {};
    const { instanceId, showAdds = false, showRecommendedUsers } = ownProps;

    const { instances, communities } = feed;

    const { isLoading = false, error, boundary, deletedIds, wasLastRequest } = instances[instanceId] ?? {};

    const records = selectRecords(feed, instanceId, showAdds);

    return {
        records,
        deletedIds,
        communities,
        allTags: tags?.tags,
        isLoading,
        error,
        showRecommendedUsers,
        boundary,
        wasLastRequest,
    };
};

const mapDispatchToProps = {
    fetchRecords,
    updateFeedRecord,
    deleteFeedRecord,
    restoreFeedRecord,
    publishFeedRecord,
    setToInit,
    fetchTickerNews,
    getSocialActivityList,
};

export type FeedProps = ReturnType<typeof mapStateToProps> &
    DispatchType<typeof mapDispatchToProps> &
    FeedContainerMapStateOwnPropsType;

export const FeedContainer = connect(mapStateToProps, mapDispatchToProps)(FeedWithContext);
