import produce, { Draft } from 'immer';
import stringify from 'fast-json-stable-stringify';

import { ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

import { RequestUpdateThunk, UpdateThunkAction } from './types';
import { updateResource } from './updateResource';

export function createUpdateRequestThunk<
    TYPES_MAP extends object,
    RESOURCE_NAME extends string,
    RESOURCE_TYPE extends { args: unknown },
>(resourcesMap: ResourcesMap<TYPES_MAP>, name: RESOURCE_NAME): RequestUpdateThunk<RESOURCE_TYPE> {
    return (
            matcher: (args: RESOURCE_TYPE['args']) => boolean,
            updater: (resource: Draft<RESOURCE_TYPE>) => void,
            operationId?: string,
        ): UpdateThunkAction =>
        (dispatch, getState) => {
            const {
                cache: { data },
            } = getState();
            const { requests } = data;
            const requestsDataMap = requests[name];
            if (requestsDataMap) {
                Object.values(requestsDataMap).forEach((value) => {
                    if (matcher(value.args)) {
                        const resource: RESOURCE_TYPE | undefined = cacheRead({
                            kind: 'request',
                            cache: data,
                            resourcesMap,
                            typename: name,
                            id: stringify(value.args),
                        });
                        if (resource) {
                            const newValue = produce(resource, (draft: Draft<RESOURCE_TYPE>) => updater(draft));
                            dispatch(
                                updateResource<RESOURCE_NAME, RESOURCE_TYPE>('request', name, newValue, operationId),
                            );
                        }
                    }
                });
            }
        };
}
