import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const Actions = styled.div<{ columns?: number }>`
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(${({ columns = 1 }) => columns}, 1fr);
    grid-gap: 16px;
    ${mediaQuery.lt768} {
        padding-bottom: 16px;
    }
`;
