import React from 'react';

import { ResponsiveMap } from 'app/styled';

import { TagProps, TagSize } from './types';
import * as Styled from './styled';

const initialResponsiveSize: ResponsiveMap<TagSize> = {
    lt960: 'small',
    lt1200: 'medium',
    default: 'large',
};

export const Tag = (props: TagProps) => {
    const {
        text,
        onClick,
        isActive = false,
        isDisable = false,
        style,
        Icon,
        IconRight,
        className,
        size = initialResponsiveSize,
        kind = 'primary',
        close,
        url,
        draggable,
    } = props;

    const hasClose = !!close;
    const onlyIcon = !!(Icon && !text && !IconRight && !hasClose);

    return (
        <Styled.TagWrapper
            as={url ? 'a' : 'div'}
            href={url}
            isActive={isActive}
            isDisable={isDisable}
            className={className}
            onClick={() => onClick?.(isActive)}
            style={style}
            size={size}
            hasClose={hasClose}
            IconRight={IconRight}
            Icon={Icon}
            onlyIcon={onlyIcon}
            kind={kind}
            tabIndex={isDisable ? undefined : 0}
            draggable={draggable}
        >
            {Icon && (
                <Styled.IconWrapper
                    pos="left"
                    size={size}
                    onlyIcon={onlyIcon}
                    isActive={isActive}
                    isDisable={isDisable}
                    kind={kind}
                >
                    <Icon />
                </Styled.IconWrapper>
            )}
            {text && (
                <Styled.Text
                    size={size}
                    Icon={Icon}
                    IconRight={IconRight}
                    hasClose={hasClose}
                    kind={kind}
                    isActive={isActive}
                    isDisable={isDisable}
                >
                    {text}
                </Styled.Text>
            )}
            {IconRight && (
                <Styled.IconWrapper
                    pos="right"
                    size={size}
                    onlyIcon={onlyIcon}
                    isActive={isActive}
                    isDisable={isDisable}
                    kind={kind}
                >
                    <IconRight />
                </Styled.IconWrapper>
            )}
            {hasClose && (
                <Styled.CloseButton onClick={close} aria-hidden="true">
                    <Styled.IconClose
                        size={16}
                        IconRight={IconRight}
                        sizeTag={size}
                        isActive={isActive}
                        isDisable={isDisable}
                        kind={kind}
                    />
                </Styled.CloseButton>
            )}
        </Styled.TagWrapper>
    );
};

export default Tag;
export type { TagProps };
