import React, { useState, useEffect, useCallback } from 'react';
import { Instance } from 'tippy.js';
import { TippyProps } from '@tippy.js/react';

import { Tooltip } from 'components/tooltip';
import { ButtonIcon, ButtonIconKinds, ButtonIconProps } from 'components/button-icon';
import { EllipsisVIcon } from 'components/icons2/EllipsisVIcon';

import * as Styled from './styled';

export { MenuItem } from 'components/menu-item';
export { ButtonIconKinds };

export type OnVisibleChangeArgs = { isVisible: boolean; inTransition: boolean };

type Props = Pick<ButtonIconProps, 'kind' | 'size' | 'icon' | 'onClick'> &
    Pick<TippyProps, 'placement' | 'interactive' | 'appendTo' | 'zIndex'> & {
        tippyContent: TippyProps['content'];
        className?: string;
        hideOnScroll?: boolean;
        buttonClassName?: string;
        buttonContent?: React.ReactChild;
        onVisibleChange?: (partialState: OnVisibleChangeArgs) => void;
        fixedPosition?: boolean;
        disabled?: boolean;
    };
export type { Props as ContextActionsProps };
export const ContextActions = (props: Props) => {
    const {
        tippyContent,
        className,
        onClick,
        kind,
        size,
        icon,
        buttonClassName,
        hideOnScroll = true,
        buttonContent,
        placement,
        // всегда скрывать при клике если явно не задано обратное
        // если понадобится частичный interactive, то можно добавить проп контролируемый проп isVisible
        interactive = false,
        onVisibleChange = () => {
            // nothing
        },
        appendTo = 'parent',
        zIndex,
        fixedPosition,
        disabled,
    } = props;

    const [instance, setInstance] = useState<Instance>();

    useEffect(() => {
        const onScroll = () => hideOnScroll && instance?.hide();

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [instance, hideOnScroll]);

    const [buttonPressed, setButtonPressed] = useState(false);

    const handleShowHide = useCallback(
        ({ isVisible, inTransition }: OnVisibleChangeArgs) => {
            onVisibleChange({ isVisible, inTransition });
            setButtonPressed(!isVisible);
        },
        [onVisibleChange],
    );

    return (
        <Styled.ContextActionsWrapper className={className} tabIndex={-1}>
            <Tooltip
                kind="popover"
                onMount={setInstance}
                content={<Styled.ContentWrapper>{tippyContent}</Styled.ContentWrapper>}
                placement={placement}
                trigger={disabled ? 'manual' : 'click'}
                interactive={interactive}
                onShow={() => handleShowHide({ isVisible: false, inTransition: true })}
                onShown={() => onVisibleChange({ isVisible: true, inTransition: false })}
                onHide={() => handleShowHide({ isVisible: true, inTransition: true })}
                onHidden={() => onVisibleChange({ isVisible: false, inTransition: false })}
                appendTo={appendTo}
                {...(zIndex === undefined ? {} : { zIndex })}
                {...(fixedPosition ? { popperOptions: { positionFixed: true } } : {})}
            >
                <Styled.ButtonIconWrapper tabIndex={-1}>
                    {buttonContent || (
                        <ButtonIcon
                            icon={icon || { component: EllipsisVIcon }}
                            kind={kind}
                            size={size}
                            className={buttonClassName}
                            pressed={buttonPressed}
                            onClick={onClick}
                            disabled={disabled}
                        />
                    )}
                </Styled.ButtonIconWrapper>
            </Tooltip>
        </Styled.ContextActionsWrapper>
    );
};

ContextActions.defaultProps = {
    className: '',
    kind: ButtonIconKinds.FillSecondary,
    size: 'L',
    placement: 'bottom-end',
    onVisibleChange: () => {
        // nothing
    },
};
