import React, { useState } from 'react';

import { DEFAULT_SIZE_WT_POPUP_3 } from '../constants';

import * as Styled from './styled';
import { ContentProps } from './types';

export const Content = (props: ContentProps) => {
    const { size = DEFAULT_SIZE_WT_POPUP_3, children, className, withoutPadding, isMobileFullscreen = false } = props;

    const [hasScroll, setHasScroll] = useState(false);

    return (
        <Styled.ContentWrapper
            size={size}
            isMobileFullscreen={isMobileFullscreen}
            hasDivider={hasScroll}
            className={className}
        >
            <Styled.ScrollableWrapper>
                <Styled.Content
                    size={size}
                    withoutPadding={withoutPadding}
                    ref={(el) => {
                        if (el?.offsetParent && el?.scrollHeight > el?.offsetParent?.clientHeight) {
                            setHasScroll(true);
                        }
                    }}
                >
                    {typeof children === 'string' ? <Styled.Text>{children}</Styled.Text> : children}
                </Styled.Content>
            </Styled.ScrollableWrapper>
        </Styled.ContentWrapper>
    );
};
