import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SelectionControlOptionId, SelectionControlProps } from './types';
import * as Styled from './styled';

const defaultSelectedOption = null;

export const SelectionControl: React.FC<SelectionControlProps> = (props) => {
    const {
        options,
        onSelect,
        size = 'small',
        className,
        selectedOptionId,
        disableUnselected = false,
        isDisabled = false,
        isCarryOn = false,
    } = props;

    const initialSelectedOption = selectedOptionId ?? (disableUnselected ? options[0].id : defaultSelectedOption);
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

    useEffect(() => {
        if (selectedOptionId === undefined) setSelectedOption(defaultSelectedOption);
        else if (selectedOptionId !== selectedOption) setSelectedOption(selectedOptionId);
    }, [selectedOption, selectedOptionId]);

    const handleSelect = useCallback(
        (optionId: SelectionControlOptionId) => {
            setSelectedOption(optionId);
            onSelect(optionId);
        },
        [setSelectedOption, onSelect],
    );

    const renderOptions = useMemo(
        () =>
            options.map((option) => {
                const isSelected = selectedOption === option.id;

                const renderCounter = () =>
                    option.counter ? (
                        <Styled.SelectionControlCounter counterSize={size}>
                            {option.counter}
                        </Styled.SelectionControlCounter>
                    ) : null;

                return (
                    <Styled.SelectionControlButton
                        key={option.id}
                        size={size}
                        kind={isSelected ? 'primary' : 'secondary'}
                        onClick={() => {
                            if (!isSelected) {
                                handleSelect(option.id);
                            } else if (!disableUnselected) handleSelect(defaultSelectedOption);
                        }}
                        className={option.className}
                        title={option.title}
                        icon={option.icon}
                        iconPos={option.iconPos}
                        isDisabled={option?.isDisabled || isDisabled}
                    >
                        <Styled.SelectionControlButtonInner size={size}>
                            {option.counterPos === 'left' && renderCounter()}
                            <span>{option.text}</span>
                            {option.counterPos === 'right' && renderCounter()}
                        </Styled.SelectionControlButtonInner>
                    </Styled.SelectionControlButton>
                );
            }),
        [disableUnselected, handleSelect, options, selectedOption, size, isDisabled],
    );

    return (
        <Styled.SelectionControlWrapper isCarryOn={isCarryOn} className={className}>
            {renderOptions}
        </Styled.SelectionControlWrapper>
    );
};
