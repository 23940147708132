import { UpdateSignalStrategyRequestBody } from '../steps/types';
import { Step1FormValues } from '../steps/signals-trading-strategy/step1/types';
import { Step3FormValues, Step4FormValues } from '../steps/copy-trading-strategies';
import { toData as step1ToData } from '../steps/signals-trading-strategy/step1/toData';
import { toData as step3ToData } from '../steps/copy-trading-strategies/step3/toData';
import { toData as step4ToData } from '../steps/copy-trading-strategies/step4/toData';
import { featureTitles } from '../wizard/constants';

export function getUpdateSignalStrategyData(
    step1FormValues: Step1FormValues,
    step3FormValues: Step3FormValues,
    step4FormValues: Step4FormValues,
): UpdateSignalStrategyRequestBody {
    const { riskLevel, recommendedHoldingPeriod, targetReturnAnnualized } = step1ToData(step1FormValues);
    const { name, description, generalTradingIdea } = step3ToData(step3FormValues);
    const { tradingMethod, riskManagement, cashAndLeverage, additionalInformation } = step4ToData(step4FormValues);
    return {
        minSum: 100,
        riskLevel,
        name,
        description,
        targetReturnAnnualized,
        keyFeatures: [
            { title: featureTitles.recommendedHoldingPeriod, description: recommendedHoldingPeriod },
            { title: featureTitles.generalTradingIdea, description: generalTradingIdea },
            { title: featureTitles.tradingMethod, description: tradingMethod },
            { title: featureTitles.cashAndLeverage, description: cashAndLeverage },
            { title: featureTitles.riskManagement, description: riskManagement },
            { title: featureTitles.additionalInformation, description: additionalInformation },
        ].filter((feature) => feature.description),
    };
}
