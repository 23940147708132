import React, { useEffect } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { FloatingPortal } from '@floating-ui/react';

import { usePageContext } from 'hooks/usePageContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useAsyncFn } from 'hooks/useAsyncFn';
import { LimexIcon } from 'components/icons2/LimexIcon';

import type { ChatWidgetProps } from '../container';
import { useFloatingParams } from '../useFloatingParams';

import { Launcher, Floating, GlobalChatStyles } from './styled';
import { ChatWindow } from './ChatWindow';

export const ChatWidget = (props: ChatWidgetProps) => {
    const { root, refs, floatingStyles, getReferenceProps, getFloatingProps, isMounted, styles } = useFloatingParams();

    const { getHistory, allChatEvents, createChatEvent, isHome = false } = props;
    const { kratosId, isAuth } = useCurrentUser();
    const [{ domainLcid }] = usePageContext();
    const [{ isReady: allChatEventsIsReady }, fetchHistory] = useAsyncFn(getHistory);
    useEffect(() => {
        if (isAuth && kratosId) {
            fetchHistory(kratosId);
        }
    }, [isAuth, fetchHistory, kratosId]);

    return (isHome || domainLcid === 'en') && isAuth ? (
        <>
            <Launcher ref={refs.setReference} {...getReferenceProps()}>
                <LimexIcon />
            </Launcher>
            {root && isMounted && (
                <FloatingPortal root={root}>
                    <Floating ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                        <ChatWindow
                            styles={styles}
                            events={allChatEvents}
                            isReady={allChatEventsIsReady}
                            createChatEvent={createChatEvent}
                        />
                    </Floating>
                </FloatingPortal>
            )}
            <GlobalChatStyles />
        </>
    ) : null;
};
