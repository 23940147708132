import { createSelector } from 'reselect';

import { FeedRecord, FeedRecordType, isPublishStatusRecord, isRecommendationRecord } from 'types';
import { mergeByPath } from 'services/utils/merge';

import { FeedState } from './types';

const filterRecords = (records: FeedRecord[]) =>
    records.filter((record) => {
        const isStatusLike = record.type === FeedRecordType.PUBLISH_STATUS;
        const isRecommendations = isRecommendationRecord(record);

        return isStatusLike || isRecommendations;
    });

const buildAds = (records: FeedRecord[], showAds: boolean): FeedRecord[] => {
    if (!showAds) {
        return records;
    }
    let adsId = 1;
    const perChunk = 4;
    return records.reduce((result: FeedRecord[], record, numberIndex) => {
        if ((numberIndex + 1) % perChunk === 0) {
            const adRecord: FeedRecord = {
                id: `ads${adsId++}`,
                type: FeedRecordType.ADS,
            };
            return [...result, record, adRecord];
        }
        return [...result, record];
    }, []);
};

const buildTickerNews = (records: FeedRecord[]): FeedRecord[] => {
    let tickerNews: FeedRecord[] = [];
    let tickerNewsRecord: FeedRecord | null;
    return records.reduce((acc: FeedRecord[], record) => {
        if (record.type === FeedRecordType.TICKER_NEWS) {
            let oldTickerNewsRecord = null;
            if (tickerNewsRecord) {
                oldTickerNewsRecord = mergeByPath(tickerNewsRecord, 'tickerNews', {
                    records: tickerNews,
                }) as FeedRecord;
                tickerNews = [];
            }
            tickerNewsRecord = { ...record };
            return [...acc, ...(oldTickerNewsRecord ? [oldTickerNewsRecord] : [])];
        }
        if (
            tickerNewsRecord &&
            isPublishStatusRecord(record) &&
            record.tickerNews?.tickerNewsId === tickerNewsRecord.id
        ) {
            tickerNews.push(record);
            return acc;
        }
        if (tickerNewsRecord) {
            tickerNewsRecord = mergeByPath(tickerNewsRecord, 'tickerNews', { records: tickerNews }) as FeedRecord;
            const result = [...acc, tickerNewsRecord];
            tickerNewsRecord = null;
            tickerNews = [];
            return result;
        }
        return [...acc, record];
    }, []);
};

export const selectRecords = createSelector(
    (state: FeedState) => state.records,
    (state: FeedState, instanceId: string) => state.instances[instanceId]?.ids ?? [],
    (_1: unknown, _2: unknown, showAds: boolean) => showAds,
    (recordsMap, ids, showAds) => buildAds(buildTickerNews(filterRecords(ids.map((id) => recordsMap[id]))), showAds),
);

export const selectFeedCheck = createSelector(
    (state: FeedState) => state.records,
    (state: FeedState, instanceId: string) => state.instances[instanceId],
    (recordsMap, { ids, boundary, isLoading }) => ({
        boundary,
        records: ids.map((id) => recordsMap[id]),
        isLoading,
    }),
);
