import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';
import { ComplexItem as ComplexItemComponent } from 'components/complex-item';
import { Title as TitleComplexItem } from 'components/complex-item/styled';
import { ListBoxButtonContent, ListBoxOptionContainer } from 'components/listbox/styled';

export const AccountListBoxWrapper = styled.div`
    margin-top: 16px;

    ${ListBoxButtonContent} {
        flex-shrink: 1;
        overflow: hidden;
    }
`;

export const CheckedTitleWrapper = styled.div`
    display: inline;
    width: 100%;
    position: relative;
`;

export const CheckedTitleAuthor = styled.span`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.primary};
`;

export const CheckedTitleNumber = styled.span`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    &:before {
        content: ' • ';
    }
`;

export const LinkOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    border-top: 1px solid ${({ theme }) => theme.divider.secondary};
    padding-top: 8px;
`;

export const LinkOption = styled(ListBoxOptionContainer)`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.primary};
    padding: 10px 12px;
`;

export const ComplexItem = styled(ComplexItemComponent)`
    ${TitleComplexItem} {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
