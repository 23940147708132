import { css } from 'styled-components';

import { baseStyles } from './base';

export const fillStyles = css`
    ${baseStyles};
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay16};

    &:hover:not(:focus-within) {
        background-color: ${({ theme }) => theme.fillIn.secondary.overlay24};
    }

    &:focus-within {
        background-color: ${({ theme }) => theme.bg.primary};
        box-shadow: ${({ theme }) => theme.focusOutline.accent},
            inset 0 0 0 1px ${({ theme }) => theme.fillIn.primary.mid};
    }
`;
