import React from 'react';

import { IconSvg, IconComponent } from '.';

export const BellIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V4.13135C13.8139 4.26246 14.6258 4.55243 15.4307 5.00006C17.8517 6.34642 18.1163 8.98481 18.3691 11.5048C18.4947 12.7568 18.6173 13.9795 19 15.0001C19.254 15.6774 19.6864 16.0489 20.0843 16.3907C20.5676 16.8059 21 17.1774 21 18.0001C21 19.0001 20 19.0001 20 19.0001H15C15 20.6569 13.6568 22 12 22C10.3432 22 9.00003 20.6569 9 19.0001H4C4 19.0001 3.00001 19.0001 3 18.0001C2.99999 17.0848 3.6139 16.5181 4.1723 16.0027C4.52904 15.6734 4.86312 15.3651 5 15.0001C5.38286 13.9791 5.51146 12.7541 5.64313 11.4999C5.90798 8.977 6.18523 6.336 8.56932 5.00006C9.37411 4.54909 10.186 4.25928 11 4.12943V3C11 2.44772 11.4477 2 12 2ZM10.6 19.0001C10.6 19.7732 11.2268 20.4 12 20.4C12.7732 20.4 13.4 19.7732 13.4 19.0001H10.6ZM18.8039 17.4001C18.3951 17.0348 17.8362 16.4533 17.5019 15.5619C17.1407 14.5986 16.9837 13.5503 16.8758 12.6054C16.8357 12.2538 16.8026 11.9229 16.7707 11.6037C16.7103 10.9991 16.654 10.4363 16.5616 9.8531C16.2897 8.13655 15.8054 7.03922 14.6531 6.39838C12.8636 5.40327 11.1296 5.39944 9.35146 6.39586C8.22468 7.02726 7.73785 8.12413 7.45799 9.85279C7.36338 10.4371 7.30429 11.0018 7.24093 11.6074C7.20745 11.9273 7.17278 12.2586 7.13105 12.6102C7.01883 13.5558 6.85805 14.602 6.49813 15.5619C6.21174 16.3256 5.59106 16.8509 5.02058 17.4001H18.8039Z"
        />
    </IconSvg>
);
