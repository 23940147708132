import React, { useCallback, useMemo } from 'react';

import { StatsYieldDataKey } from 'types/product';
import { InstrumentCard } from 'components/card';
import { formatChartDataByStrategyUsStats } from 'components/chart/utils';
import { DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES } from 'components/chart/constants';
import { ChartSizePeriodCropData } from 'components/chart/types';

import { StrategyUSProductCardProps } from '../types';
import { makeStrategyParameters } from '../utils';
import { COMON_ENTITY } from '../constants';

import GraphicSkeletonSvg from './GraphicSkeleton.svg';

const STATS_YIELD_DATA_KEY: StatsYieldDataKey = 'r365';
const SIZE_CHART: ChartSizePeriodCropData = DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES.year;

export const StrategyUsProductCard = (props: StrategyUSProductCardProps) => {
    const {
        item,
        onClick: onClickProp,
        className: classNameProp,
        isDisableAutoDetectUrl = false,
        ...restProps
    } = props;
    const { name, riskLevel, minSumForAutofollowing, url, stats } = item;

    const chartData = useMemo(() => formatChartDataByStrategyUsStats(stats, STATS_YIELD_DATA_KEY), [stats]);

    const yield365Days = useMemo(() => (stats?.length > 0 ? stats[stats.length - 1]?.r365 : 0), [stats]);

    const parameters = makeStrategyParameters({
        riskLevel,
        minSumForAutofollowing,
        yield365Days,
        minSumForAutofollowingCurrencySign: '$',
    });

    const className = [classNameProp, 'autotest__product-card__strategyUs'].filter((i) => i).join(' ');

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (onClickProp) {
                onClickProp(e);
            } else if (!isDisableAutoDetectUrl && url) {
                open(url, '_blank')?.focus();
            }
        },
        [isDisableAutoDetectUrl, url, onClickProp],
    );

    return (
        <InstrumentCard
            title={name}
            author={COMON_ENTITY}
            parameters={parameters}
            className={className}
            onClick={onClick}
            skeletons={{
                GraphicSkeleton: GraphicSkeletonSvg,
            }}
            chart={{
                data: chartData,
                sizePeriodCropData: SIZE_CHART,
            }}
            {...restProps}
        />
    );
};
