/* eslint-disable camelcase */
import { excludeUndefinedProperties } from 'services/utils/exclude-undefined-properties';

import type { Step1FormValues } from '../steps/user-products/step1/types';
import type { Step2FormValues } from '../steps/user-products/step2/types';
import type { Step3FormValues } from '../steps/user-products/step3/types';
import type { Step4FormValues } from '../steps/user-products/step4/types';
import type { Step5FormValues } from '../steps/user-products/step5/types';
import { CreateProductRequestBody } from '../steps/types';
import { toData as step5ToData } from '../steps/user-products/step5/toData';
import { toData as step4ToData } from '../steps/user-products/step4/toData';
import { toData as step3ToData } from '../steps/user-products/step3/toData';
import { toData as step2ToData } from '../steps/user-products/step2/toData';
import { toData as step1ToData } from '../steps/user-products/step1/toData';

export function getProductData(
    step1FormValues: Step1FormValues,
    step2FormValues: Step2FormValues,
    step3FormValues?: Step3FormValues,
    step4FormValues?: Step4FormValues,
    step5FormValues?: Step5FormValues,
): CreateProductRequestBody {
    const step1Data = step1ToData(step1FormValues);
    const step2Data = step2ToData(step2FormValues);
    const step3Data = step3FormValues ? step3ToData(step3FormValues) : undefined;
    const step4Data = step4FormValues ? step4ToData(step4FormValues) : undefined;
    const step5Data = step5FormValues ? step5ToData(step5FormValues) : undefined;
    return excludeUndefinedProperties({
        name: step1Data.name,
        description: step2Data.description,
        backgroundImageUrl: step2Data.backgroundImageUrl,
        cost: step1Data.cost,
        url: step1Data.accessUrl,
        tags: [],
        accessUrl: step1Data.accessUrl ?? '',
        markets: step1Data.markets,
        programTypes: step1Data.programTypes,
        keyFeatures: step5Data?.keyFeatures ?? [],
        screenshotUrls: step3Data?.screenshotUrls ?? [],
        trial: step1Data.trial,
        urlOfficial: step4Data?.urlOfficial || undefined,
        downloadLink: step4Data?.downloadLink || undefined,
        trialLink: step4Data?.trialLink || undefined,
        supportContact: step4Data?.supportContact || undefined,
    });
}
