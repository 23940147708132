import React from 'react';
import cn from 'classcat';

import * as Styled from './styled';
import { IconedTextProps } from './types';
import { DEFAULT_ICON_POS_PROP } from './constants';

const IconedText = ({
    className: classNameProp,
    icon,
    text,
    title,
    iconPos = DEFAULT_ICON_POS_PROP,
    onClick,
}: IconedTextProps) => {
    const { gap = '4px', component, size, width, height } = icon || {};

    const renderIconImg = () => {
        if (!icon || !component) {
            return null;
        }

        if (typeof component === 'string') {
            return (
                <Styled.Image
                    src={component}
                    width={width ?? size}
                    height={height ?? size}
                    {...icon}
                    gap={gap}
                    iconPos={iconPos}
                />
            );
        }

        return (
            <Styled.IconWrapper>
                <Styled.Icon {...icon} component={component} gap={gap} iconPos={iconPos} />
            </Styled.IconWrapper>
        );
    };

    const className = cn([classNameProp, 'autotest__iconed-text2']);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <Styled.IconedTextWrapper className={className} title={title} onClick={onClick}>
            {iconPos === 'left' && renderIconImg()}
            <Styled.TextWrapper>
                <Styled.Text>{text}</Styled.Text>
            </Styled.TextWrapper>
            {iconPos === 'right' && renderIconImg()}
        </Styled.IconedTextWrapper>
    );
};

export default React.memo(IconedText);
