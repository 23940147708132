import type { Step4FormValues, Step4Data } from './types';

export function toFormValues({
    tradingMethod,
    cashAndLeverage,
    riskManagement,
    additionalInformation,
}: Step4Data): Step4FormValues {
    return {
        tradingMethod,
        cashAndLeverage,
        riskManagement,
        additionalInformation,
    };
}
