import { createSelector } from 'reselect';

import { GlobalStateForCache, ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

export function createSelectResource<TYPES_MAP extends object, RESOURCE_NAME extends string, RESOURCE_TYPE>(
    resourcesMap: ResourcesMap<TYPES_MAP>,
    typename: RESOURCE_NAME,
) {
    return createSelector(
        (state: GlobalStateForCache) => state.cache.data,
        (_: unknown, id: unknown) => id,
        (resources, id) =>
            cacheRead({ kind: 'resource', cache: resources, resourcesMap, typename, id: `${id}` }) as RESOURCE_TYPE,
    );
}
