import React, { useCallback } from 'react';

import { Id } from 'types';
import Dic from 'services/dictionary';
import { ActionBlacklistContainer } from 'widgets/action-blacklist';
import { Button } from 'components/button2';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { useToggleBlacklist } from '../page/hooks';
import { usePageRoot } from '../hooks';

import { Actions } from './styled';
import { BlacklistContent } from './styled/BlacklistPopup';

type Props = {
    userId: Id;
    isBlockedByCurrentUser: boolean;
    displayName: string;
    closePopup?: () => void;
};

export const BlacklistPopup = (props: Props) => {
    const { userId, isBlockedByCurrentUser, displayName, closePopup } = props;

    const pageRoot = usePageRoot();
    const handleToggleBlacklist = useToggleBlacklist(isBlockedByCurrentUser);

    const customRenderForActionBlacklistContainer = useCallback(
        ({ toggleBlacklist, getFollowers, reduceFollowingCount }) => (
            <Button
                size="small"
                onClick={async () => {
                    try {
                        const followers = await getFollowers(userId);
                        const isBlockedUserFollowingUs = followers.payload.data.items.length > 0;
                        await handleToggleBlacklist(toggleBlacklist);
                        if (isBlockedUserFollowingUs) {
                            reduceFollowingCount();
                        }
                    } finally {
                        closePopup?.();
                    }
                }}
            >
                {Dic.word('wt_profile__popup_blacklist__button_block')}
            </Button>
        ),
        [userId, closePopup],
    );

    return (
        <WtPopup3
            size={SizeWtPopup3Enum.LARGE}
            close={closePopup}
            portalTo={pageRoot}
            bgCloseable
            width={464}
            title={Dic.word('wt_profile__popup_blacklist__title_text', { displayName })}
            footerChildren={
                <Actions columns={2}>
                    <ActionBlacklistContainer
                        personId={userId}
                        isInBlacklist={isBlockedByCurrentUser}
                        customRender={customRenderForActionBlacklistContainer}
                    />
                    <Button size="small" kind="outline" onClick={closePopup}>
                        {Dic.word('wt_profile__popup_blacklist__button_cancel')}
                    </Button>
                </Actions>
            }
        >
            <BlacklistContent>{Dic.word('wt_profile__popup_blacklist__content')}</BlacklistContent>
        </WtPopup3>
    );
};
