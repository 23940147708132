import { Action, Id } from 'types';

const REVOKE_PREMIUM = 'widgets/REVOKE_PREMIUM' as const;

const revokePremium = (id: Id) => ({ type: REVOKE_PREMIUM, payload: id });

type RevokePremium = Action<typeof revokePremium>;
type RevokePremiumActions = ReturnType<typeof revokePremium>;

export { REVOKE_PREMIUM, revokePremium, RevokePremium, RevokePremiumActions };
