import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import cc from 'classcat';

import { Avatar, AvatarLink } from 'components/avatar';
import { AvatarImplementationProps } from 'components/avatar/types';

import { AvatarUserProps } from './types';
import { getInitialsParams, getUrlByUser } from './utils';

export const AvatarUser = React.memo((props: AvatarUserProps) => {
    const {
        isDisableAutoDetectUrl = false,
        buildHrefByUser,
        href,
        target,
        rel,
        user,
        isShowInitials = false,
        className,
        ...restAvatarProps
    } = props;

    const theme = useTheme();

    const url = useMemo(() => {
        if (href) {
            return href;
        }

        if (isDisableAutoDetectUrl) {
            return undefined;
        }

        return getUrlByUser({ user, buildUrl: buildHrefByUser });
    }, [user, buildHrefByUser, isDisableAutoDetectUrl, href]);

    const imgSrcAvatar = user?.image ? user.image : null;
    const displayName = user?.displayName;
    const altAvatar = displayName || 'user-avatar';

    const getAvatarProps = (): AvatarImplementationProps => {
        if (isShowInitials) {
            const initialsParams = getInitialsParams({ userFullName: displayName, theme });
            return {
                initials: initialsParams,
                ...restAvatarProps,
            };
        }

        return {
            src: imgSrcAvatar,
            alt: altAvatar,
            ...restAvatarProps,
        };
    };

    const avatarProps = getAvatarProps();

    const avatarClassName = cc(['autotest__avatar-user', className]);

    if (url) {
        return <AvatarLink href={url} target={target} rel={rel} {...avatarProps} className={avatarClassName} />;
    }

    return <Avatar {...avatarProps} className={avatarClassName} />;
});
