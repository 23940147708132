import { LevelRiskNumberValue, LevelRiskTextValue } from './types';
import { MAX_RISK_LEVEL_NUMBER_VALUE, MIN_RISK_LEVEL_NUMBER_VALUE, RISK_LEVEL_NUMBER_VALUE_MAP } from './constants';

export const getIsRightNumberValue = (v: number | undefined | string): v is LevelRiskNumberValue =>
    typeof v === 'number' && v >= MIN_RISK_LEVEL_NUMBER_VALUE && v <= MAX_RISK_LEVEL_NUMBER_VALUE;

type GetLevelRiskValuesByValueResult =
    | {
          num: LevelRiskNumberValue;
          text: LevelRiskTextValue;
      }
    | undefined;
export const getLevelRiskValuesByValue = (val: string | number | undefined): GetLevelRiskValuesByValueResult => {
    if (!val) {
        return undefined;
    }
    const levelRiskNumberValueTextValueArr = Object.entries(RISK_LEVEL_NUMBER_VALUE_MAP);

    const foundedLevelRiskDataNumberValueTextValue = levelRiskNumberValueTextValueArr.find(
        ([levelNumberValue, levelTextValue]) => levelTextValue === val || +levelNumberValue === +val,
    );

    const levelNumberValue: LevelRiskNumberValue | undefined = foundedLevelRiskDataNumberValueTextValue?.[0]
        ? (+foundedLevelRiskDataNumberValueTextValue[0] as LevelRiskNumberValue)
        : undefined;

    const levelTextValue = foundedLevelRiskDataNumberValueTextValue?.[1];

    if (levelNumberValue && levelTextValue) {
        return {
            num: levelNumberValue,
            text: levelTextValue,
        };
    }

    return undefined;
};
