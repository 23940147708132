import React, { useMemo, useRef } from 'react';

import { Person } from 'types';
import { correctedHumanString } from 'services/dictionary';
import { useToggle } from 'hooks/useToogle';

import { SubscribersContainer, TABS } from '../../subscribers';

import { Count, Line, Wrapper } from './styled/Followers';

type Props = {
    followers: Required<Person>['counters']['followers'];
    following: Required<Person>['counters']['following'];
    isBlocked: boolean;
};

const getThisDct = (key: string, count: number | null | undefined) => {
    const [humanlyCount, text] = correctedHumanString(key, [count || 0]).split(' ');
    return (
        <>
            <Count title={count ? `${count}` : ''}>{humanlyCount}</Count> <span>{text}</span>
        </>
    );
};

export const Followers = (props: Props) => {
    const { following, followers, isBlocked } = props;
    const [isPopupShown, togglePopupShown] = useToggle(false);
    const activeTab = useRef<number>(TABS.followers);

    const handleLineClick = (tab: TABS) => () => {
        activeTab.current = tab;
        togglePopupShown();
    };
    const followersHuman = useMemo(
        () => getThisDct('wt_profile__widget_header_user__followers', isBlocked ? 0 : followers),
        [followers, isBlocked],
    );
    const followingHuman = useMemo(
        () => getThisDct('wt_profile__widget_header_user__followings', isBlocked ? 0 : following),
        [following, isBlocked],
    );
    return (
        <Wrapper>
            <Line $isBlocked={isBlocked} onClick={isBlocked ? undefined : handleLineClick(TABS.following)}>
                {followingHuman}
            </Line>{' '}
            <Line $isBlocked={isBlocked} onClick={isBlocked ? undefined : handleLineClick(TABS.followers)}>
                {followersHuman}
            </Line>
            {isPopupShown && <SubscribersContainer defaultTab={activeTab.current} togglePopup={togglePopupShown} />}
        </Wrapper>
    );
};
