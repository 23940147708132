import React, { useCallback, useMemo } from 'react';

import { TagData } from 'types';
import { Tags, TagsProps } from 'components/tags';

type Props = {
    allTags: TagData[];
    selectedIds: number[];
    setSelectedIds: (selectedIds: number[]) => void;
};

export const SelectedTagsLine = (props: Props) => {
    const { allTags, selectedIds, setSelectedIds } = props;

    const handleRemove = useCallback<NonNullable<TagsProps['onCloseTag']>>(
        ({ nonClosedCandidateIds }) => {
            setSelectedIds(nonClosedCandidateIds);
        },
        [setSelectedIds],
    );

    const tagsList = useMemo(
        () => allTags.filter((tagData) => selectedIds.includes(tagData.id)),
        [selectedIds, allTags],
    );

    return (
        <div>
            <Tags tags={tagsList} size="small" kind="outline" onCloseTag={handleRemove} />
        </div>
    );
};
