import styled, { css } from 'styled-components';

import { buttonResetStyle } from 'app/styled';
import { body14RegularFontStyle, caption11MediumFontStyle } from 'services/typography';

export const ListBoxContainer = styled.div`
    position: relative;
    flex-shrink: 0;
`;

export const ListBoxPlaceholderContainer = styled.div<{ hasChildren: boolean }>`
    ${({ hasChildren }) => (hasChildren ? caption11MediumFontStyle : body14RegularFontStyle)};
    color: ${({ theme }) => theme.label.secondary};
`;

export const ListBoxButtonContainer = styled.button<{
    visualState: 'normal' | 'emergency';
}>`
    ${buttonResetStyle};

    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    min-height: 48px;
    border-radius: 8px;
    padding: 6px 16px;

    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.low};

    ${({ visualState }) =>
        visualState === 'emergency' &&
        css`
            box-shadow: ${({ theme }) => theme.focusOutline.negative},
                inset 0 0 0 1px ${({ theme }) => theme.fillIn.negative.mid};

            ${ListBoxPlaceholderContainer} {
                color: ${({ theme }) => theme.fillIn.negative.mid};
            }
        `}

    &:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.high};
    }

    &:focus {
        ${ListBoxPlaceholderContainer} {
            color: ${({ theme }) => theme.fillIn.positive.mid};
        }

        box-shadow: ${({ theme }) => theme.focusOutline.accent},
            inset 0 0 0 1px ${({ theme }) => theme.fillIn.primary.mid};
    }
`;

export const ListBoxOptionsContainer = styled.div`
    position: absolute;
    margin-top: 8px;
    padding-bottom: 38px;
    z-index: 1;
    width: 100%;
`;

export const ListBoxOptionsContainerInner = styled.div`
    border-radius: 8px;
    padding: 8px 0;
    background: ${({ theme }) => theme.bg.popover};
    box-shadow: ${({ theme }) => theme.shadow.popover};
`;

export const ListBoxOptionContainer = styled.button<{ readonly?: boolean }>`
    ${buttonResetStyle}
    ${body14RegularFontStyle}
    padding: 10px 16px;
    display: block;
    width: 100%;
    text-align: left;
    cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};

    ${({ readonly }) =>
        readonly
            ? ''
            : css`
                  &:hover {
                      background-color: ${({ theme }) => theme.fillIn.primary.overlay16};
                  }

                  &:active {
                      color: ${({ theme }) => theme.label.accent};
                      background-color: ${({ theme }) => theme.fillIn.primary.overlay16};
                  }
              `};
`;

export const ListBoxButtonContent = styled.div<{ hasChildren: boolean }>`
    ${body14RegularFontStyle};
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
    display: ${({ hasChildren }) => (hasChildren ? 'block' : 'flex')};
`;

export const ListBoxButtonIconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: -8px;
    color: ${({ theme }) => theme.label.secondaryHigh};
`;
