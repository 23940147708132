import React from 'react';

import { CurrencyCode } from 'types/currency';
import { PaymentPeriod } from 'types/payment';
import { PricePeriodFormatter } from 'widgets/formatting';

import { CardPriceInfoContainer } from './styled';

type CardPriceInfoProps = {
    freePriceWord?: string;
    amount?: number;
    currency?: CurrencyCode;
    period?: PaymentPeriod;
    priceWord?: string;
    formattedPricePeriod?: string | React.ReactElement;
};

export const CardPriceInfo = (props: CardPriceInfoProps) => {
    const {
        amount,
        currency = 'USD',
        period = 'fixed',
        freePriceWord = '',
        priceWord = '',
        formattedPricePeriod,
    } = props;

    return (
        <CardPriceInfoContainer className="autotest__product-card_price_info">
            <span className="autotest__product-card_price_label">{priceWord}</span>
            {formattedPricePeriod ||
                (amount ? (
                    <PricePeriodFormatter price={amount} currency={currency} period={period}>
                        {({ localizedPrice, localizedPeriod }) => (
                            <span>
                                <span className="autotest__product-card_price_cost">{localizedPrice}</span>
                                {localizedPeriod && (
                                    <span className="autotest__product-card_price_period">{` / ${localizedPeriod}`}</span>
                                )}
                            </span>
                        )}
                    </PricePeriodFormatter>
                ) : (
                    <span className="autotest__product-card_price_cost">{freePriceWord}</span>
                ))}
        </CardPriceInfoContainer>
    );
};
