import { useProfilePageContext } from './context';

export const useIsOwner = () => {
    const [{ isOwner }] = useProfilePageContext();
    return isOwner;
};

export const usePageRoot = () => {
    const [{ pageRoot }] = useProfilePageContext();
    return pageRoot;
};
