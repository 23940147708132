import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import { OptionsPositionParamsType } from './types';
import { MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN } from './constants';

const MIN_HEIGHT_OPTIONS_EL = 176;
const DEFAULT_DEBOUNCE_DELAY = 600;
const DEFAULT_DEBOUNCED_PARAM = false;

type useSetPositionOptionsParams = {
    listBoxBtnRef: React.RefObject<HTMLDivElement | null>;
    listOptionsRef: React.RefObject<HTMLDivElement | null>;
    defaultOptionsPositionParams: OptionsPositionParamsType;
};

type SetPositionOptionsArgs = {
    debounced?: boolean;
    debounceDelay?: number;
};

type ResultUseSetPositionOptionsParams = {
    optionsPositions: OptionsPositionParamsType;
    setPositionOptions: (args?: SetPositionOptionsArgs) => void;
};

export const useSetPositionOptions = (params: useSetPositionOptionsParams): ResultUseSetPositionOptionsParams => {
    const { listBoxBtnRef, listOptionsRef, defaultOptionsPositionParams } = params;

    const [optionsPositionParams, setOptionsPositionParams] =
        useState<OptionsPositionParamsType>(defaultOptionsPositionParams);

    const getPositionParams = () => {
        const rect = listBoxBtnRef?.current?.getBoundingClientRect();
        const optionsInnerContainerRef = listOptionsRef?.current?.children?.[0];
        const optionsInnerContainerPadding = 16; // offsetHeight ниже почему-то не работает
        const optionsBlockHeight = optionsInnerContainerRef?.clientHeight
            ? optionsInnerContainerRef?.clientHeight + optionsInnerContainerPadding
            : 0;
        const rectX = rect?.x || 0;
        const rectY = rect?.y || 0;
        const rectBottom = rect?.bottom || 0;

        const getDirection = (): OptionsPositionParamsType['direction'] => {
            let directionResult: OptionsPositionParamsType['direction'];
            if (window.innerHeight > 360) {
                if (rectBottom + MIN_HEIGHT_OPTIONS_EL > window.innerHeight) {
                    directionResult = 'up';
                } else {
                    directionResult = 'down';
                }
            } else {
                directionResult = 'allScreen';
            }

            return directionResult;
        };

        const getTopHeightByDirection = (dir: OptionsPositionParamsType['direction']) => {
            let topResult: OptionsPositionParamsType['top'];
            let heightResult: OptionsPositionParamsType['height'] = 'auto'; // по умолчанию в css стоит auto

            switch (dir) {
                case 'allScreen':
                    topResult = 0;
                    heightResult = window.innerHeight - MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN;
                    break;
                case 'up':
                    topResult = rectY - optionsBlockHeight;

                    if (topResult < 0) {
                        // если выходит за границы
                        topResult = 0;
                    }

                    if (optionsBlockHeight + optionsInnerContainerPadding > rectY) {
                        heightResult = rectY - optionsInnerContainerPadding;
                    }

                    break;
                case 'down':
                default: {
                    topResult = rectBottom;
                    const fromTopToEndOfOptionsBlock = rectBottom + optionsBlockHeight;

                    if (fromTopToEndOfOptionsBlock + MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN >= window.innerHeight) {
                        heightResult = window.innerHeight - rectBottom - MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN;
                    }
                    break;
                }
            }

            return { top: topResult, height: heightResult };
        };

        const direction = getDirection();
        const { top, height } = getTopHeightByDirection(direction);

        return {
            ...defaultOptionsPositionParams,
            top,
            height,
            width: listBoxBtnRef?.current?.clientWidth || 0,
            left: rectX,
            direction,
        };
    };

    const setPositionOptions = (args?: SetPositionOptionsArgs) => {
        const positionParams = getPositionParams();
        const setPositions = () => setOptionsPositionParams(positionParams);

        const debouncedParam = args?.debounced || DEFAULT_DEBOUNCED_PARAM;
        const debounceDelayParam = args?.debounceDelay || DEFAULT_DEBOUNCE_DELAY;

        if (debouncedParam && debounceDelayParam > 0) {
            debounce(() => {
                setPositions();
            }, debounceDelayParam);
        } else {
            setPositions();
        }
    };

    return { optionsPositions: optionsPositionParams, setPositionOptions };
};
