import styled, { css } from 'styled-components';

import { mediaQuery, boxShadowLikeBorder } from 'app/styled';
import { caption10RegularFontStyle } from 'services/typography';

export const Actions = styled.div`
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 200px;
`;

const activeState = ({ isHovered } = { isHovered: false }) => css<{ bgSrc: string | null; isLoading: boolean }>`
    ${({ theme: { fillIn, bg }, bgSrc, isLoading }) => {
        const isActive = !bgSrc || isHovered || isLoading;
        return css`
            background: ${isActive ? `linear-gradient(0deg, ${bg.dialogOverlay}, ${bg.dialogOverlay}),` : ''}
                ${bgSrc ? ` url(${bgSrc}),` : ''} ${fillIn.secondary.muted};
            background-size: cover;
            ${Actions} {
                ${isActive ? 'display: flex' : ''}
            }
        `;
    }};
`;

export const Wrapper = styled.div<{ bgSrc: string | null; isLoading: boolean; hasError: boolean }>`
    ${activeState()}
    background-size: cover;
    width: 100%;
    border-radius: 8px;
    height: 104px;
    position: relative;
    justify-content: center;
    display: flex;
    ${({ theme, hasError }) =>
        hasError ? `box-shadow: ${boxShadowLikeBorder('2px', theme.fillIn.negative.high)};` : ''}
    &:hover {
        ${activeState({ isHovered: true })}
    }
    ${mediaQuery.lt768} {
        height: 80px;
        border-radius: 0;
        margin: 0 -16px;
        width: auto;
    }
`;

export const Buttons = styled.div`
    button + button {
        margin-left: 16px;
    }
`;

export const Tip = styled.div`
    ${caption10RegularFontStyle};
    color: ${({ theme }) => theme.label.inverse};
    text-align: center;
    ${mediaQuery.lt768} {
        display: none;
    }
`;
