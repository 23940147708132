import React from 'react';

import { IconSvg, IconComponent } from '.';

export const UserPlusIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3.2002C9.34898 3.2002 7.19995 5.34923 7.19995 8.0002C7.19995 10.6512 9.34898 12.8002 12 12.8002C14.6509 12.8002 16.8 10.6512 16.8 8.0002C16.8 5.34923 14.6509 3.2002 12 3.2002ZM8.79995 8.0002C8.79995 6.23288 10.2326 4.8002 12 4.8002C13.7673 4.8002 15.2 6.23288 15.2 8.0002C15.2 9.76751 13.7673 11.2002 12 11.2002C10.2326 11.2002 8.79995 9.76751 8.79995 8.0002Z"
        />
        <path d="M8.08253 16.6437C9.20472 16.1133 10.5479 15.8002 12 15.8002C15.7388 15.8002 18.5781 17.8291 19.1068 20.1664C19.0898 20.1807 19.0537 20.2002 19.001 20.2002H10.0003C9.55843 20.2002 9.20003 20.5584 9.20003 21.0002C9.20003 21.442 9.5582 21.8002 10 21.8002H19.001C19.9349 21.8002 20.9292 20.993 20.6721 19.8346C19.9311 16.4952 16.1886 14.2002 12 14.2002C10.3211 14.2002 8.74332 14.5616 7.39882 15.1971C6.99936 15.3859 6.82859 15.8628 7.01739 16.2622C7.20619 16.6617 7.68307 16.8325 8.08253 16.6437Z" />
        <path d="M2.19995 20.0002C2.19995 19.5584 2.55812 19.2002 2.99995 19.2002H4.19995L4.19995 18.0002C4.19995 17.5584 4.55812 17.2002 4.99995 17.2002C5.44178 17.2002 5.79995 17.5584 5.79995 18.0002V19.2002H6.99995C7.44178 19.2002 7.79995 19.5584 7.79995 20.0002C7.79995 20.442 7.44178 20.8002 6.99995 20.8002H5.79995V22.0002C5.79995 22.442 5.44178 22.8002 4.99995 22.8002C4.55812 22.8002 4.19995 22.442 4.19995 22.0002L4.19995 20.8002H2.99995C2.55812 20.8002 2.19995 20.442 2.19995 20.0002Z" />
    </IconSvg>
);
