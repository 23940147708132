import React from 'react';

import Dic from 'services/dictionary';

import { Activity } from '../../../../activity';

import * as Styled from './styled';

type Props = {
    visible: boolean;
};

export const Loading = ({ visible }: Props) => (
    <Styled.LoadingWrapper isVisible={visible}>
        <Activity>
            <Styled.LoadingContent>
                <Styled.LoadingText>{Dic.word('wt_feed__widget_feed__loading')}</Styled.LoadingText>
            </Styled.LoadingContent>
        </Activity>
    </Styled.LoadingWrapper>
);
