/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ReactElementLike } from 'prop-types';
import noop from 'lodash/noop';
import PullScroll from 'hocs/PullScroll';

import { ResponsiveMap } from 'app/styled';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { CloseIcon } from 'components/icons2/CloseIcon';

import {
    Wrapper,
    Field,
    Field2,
    VisualState,
    WtFieldKind,
    WtField2Size,
    FloatingLabel,
    ClearButtonContainer,
    SizeWrapper,
} from './styled2';

const FieldWithPullScroll = PullScroll(Field);
const Field2WithPullScroll = PullScroll(Field2);

export enum Views {
    styled,
    styled2,
}

type CurrentElement = HTMLTextAreaElement | HTMLInputElement;

function getScrollParent(node: HTMLElement | null, isInitial = true): HTMLElement | null {
    if (node === null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight && !isInitial) {
        return node;
    }
    return getScrollParent(node.parentElement, false);
}

export interface Props {
    className?: string;
    tag?: string;
    children?: ReactElementLike;
    active?: boolean;
    usePullScroll?: boolean;
    wrapperClassName?: string;
    fieldProps?: React.HTMLProps<CurrentElement> & {
        ref?: (node: CurrentElement | null) => void;
        rows?: number;
        onChange?: (event: React.FormEvent<CurrentElement>) => void;
    };
    fontSize?: number;
    // @deprecated
    view?: Views;
    noresize?: boolean;
    visualState?: VisualState;
    recalculateHeight?: boolean;
    showClearButton?: boolean;
    onClear?: () => void;
    onPaste?: () => void;
    kind?: WtFieldKind;
    size?: WtField2Size | ResponsiveMap<WtField2Size>;
    floatingLabel?: string;
    infinityHeight?: boolean;
    isAutoFollow?: boolean;
}

export class WtField extends React.PureComponent<Props> {
    public static defaultProps = {
        className: '',
        tag: 'textarea',
        children: '',
        fieldProps: {
            onKeyDown: noop,
        },
        active: false,
        usePullScroll: false,
        fontSize: 13,
        view: Views.styled2,
        noresize: false,
        visualState: 'normal',
        recalculateHeight: true,
        kind: 'primary',
        size: 'large',
        showClearButton: false,
        disabled: false,
        isAutoFollow: false,
    };

    private textInput: CurrentElement | null = null;

    public componentDidUpdate(prevProps: Props) {
        this.recalculateHeight(this.textInput, prevProps.fieldProps?.value !== this.props.fieldProps?.value);

        if (prevProps.onPaste !== this.props.onPaste) {
            if (prevProps.onPaste) {
                this.textInput?.removeEventListener('paste', prevProps.onPaste);
            }
            if (this.props.onPaste) {
                this.textInput?.addEventListener('paste', this.props.onPaste);
            }
        }
    }

    public componentDidMount() {
        this.recalculateHeight(this.textInput);
        if (this.props.onPaste) {
            this.textInput?.addEventListener('paste', this.props.onPaste);
        }
    }

    public componentWillUnmount() {
        if (this.props.onPaste) {
            this.textInput?.removeEventListener('paste', this.props.onPaste);
        }
    }

    private getRef = (node: CurrentElement | null) => {
        this.textInput = node;
        if (this.props.fieldProps?.ref) {
            this.props.fieldProps.ref(node);
        }
    };

    private recalculateHeight(element: CurrentElement | null, textareaValueChanged?: boolean) {
        if (this.props.isAutoFollow) {
            return;
        }
        if (!this.props.recalculateHeight) return;
        if (!element) {
            return;
        }

        // dz: если пользователь вручную изменил высоту - значит ему виднее, как ему удобнее
        const manualHeight = parseInt(element.style.height, 10) || 0;
        if (manualHeight) {
            return;
        }

        const scrollParent = getScrollParent(element) || document.body;
        const savedScrollTop = scrollParent.scrollTop;

        // @ts-ignore
        element.style['min-height'] = 'inherit';
        element.style.height = 'inherit';

        const computed = window.getComputedStyle(element);

        let height =
            parseInt(computed.getPropertyValue('border-top-width'), 10) +
            parseInt(computed.getPropertyValue('padding-top'), 10) +
            element.scrollHeight +
            parseInt(computed.getPropertyValue('padding-bottom'), 10) +
            parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        // dz: считаем, что макмимальная автоматическая выоста - не более половины экрана
        const maxHeight = window.innerHeight / 2;

        // dz: ограничиваем автоматическое увеличение высоты до 400px
        if (!this.props.infinityHeight && height > maxHeight) {
            height = maxHeight;
        }

        // dz: применяем увеличение, только если текста действительно стало больше, чем надо (чтобы при старте не дергаться)
        const { fieldProps, active } = this.props;
        const lineHeight = parseInt(computed.getPropertyValue('line-height'), 10);
        const isHeightNeedToUpdate = fieldProps?.rows ? height > fieldProps.rows * lineHeight : height > 100;
        if (isHeightNeedToUpdate) {
            // dz: ручное изменение высоты влияет на height опцию, мы же в автоматическом режиме будем работать с min-height, чтобы не путаться
            // @ts-ignore
            element.style['min-height'] = `${height}px`;

            if (scrollParent === document.body) {
                /** Желаемый отступ между нижним краем textarea и нижним краем экрана */
                const offset = 104;
                const elementBottom = element.getBoundingClientRect().bottom;

                const diff = (window.innerHeight || 0) - elementBottom - offset;
                /** Проверяем что кнопка публикации ушла за нижний край экрана, также что каретка в textarea находится в конце
                 * (чтобы экран не скроллился вниз при редактировании пользователем текста в середине большого комментария) */
                // arb: работает только при вводе в конце текста
                if (diff < 0 && element.selectionEnd === element.value.length && textareaValueChanged) {
                    window.scrollBy({ top: -diff, behavior: 'auto' });
                }
            } else {
                // arb: если скроллируемый родитель - не сама страница, значит ему надо восстановить положение скролла
                // после сбития высоты через element.style['min-height'] = 'inherit'
                scrollParent.scrollTo(0, savedScrollTop);
            }
        }
    }

    private handleKeyDown = (e: React.KeyboardEvent<CurrentElement>) => {
        const onKeyDown = this.props.fieldProps?.onKeyDown;
        this.recalculateHeight(e.target as HTMLTextAreaElement);
        if (onKeyDown) {
            onKeyDown(e);
        }
    };

    public render() {
        const {
            tag,
            active,
            fontSize,
            fieldProps,
            usePullScroll,
            children,
            view,
            noresize,
            visualState,
            className,
            wrapperClassName,
            kind,
            size,
            floatingLabel,
            showClearButton,
            onClear,
        } = this.props;
        let FieldComponent;
        if (view === Views.styled2) {
            FieldComponent = usePullScroll ? Field2WithPullScroll : Field2;
        } else {
            FieldComponent = usePullScroll ? FieldWithPullScroll : Field;
        }
        let fieldPropsExceptRef: React.HTMLProps<CurrentElement> = {};
        if (fieldProps) {
            const { ref, onKeyDown, disabled, ..._fieldPropsExceptRef } = fieldProps;
            fieldPropsExceptRef = _fieldPropsExceptRef;
        }

        const hasClearButton = showClearButton && fieldProps?.value;

        const fieldComponent = (
            <FieldComponent
                className={className}
                onKeyDown={this.handleKeyDown}
                // sc-props
                usePullScroll={usePullScroll}
                // @ts-ignore
                as={tag}
                active={active}
                fontSize={fontSize}
                visualState={visualState}
                style={noresize ? { resize: 'none' } : undefined}
                kind={kind}
                size={size}
                floatingLabel={floatingLabel}
                hasClearButton={hasClearButton}
                // hoc-props
                getComponentRef={this.getRef}
                // input/textarea-props
                {...fieldPropsExceptRef}
                placeholder={fieldPropsExceptRef.placeholder || floatingLabel}
                {...(usePullScroll ? {} : { ref: this.getRef })}
            />
        );

        return (
            <Wrapper className={wrapperClassName}>
                {this.props.isAutoFollow && tag === 'textarea' ? (
                    <SizeWrapper size={size!} data-replicated-value={fieldProps?.value}>
                        {fieldComponent}
                    </SizeWrapper>
                ) : (
                    fieldComponent
                )}
                {floatingLabel && <FloatingLabel>{floatingLabel}</FloatingLabel>}
                {hasClearButton && (
                    <ClearButtonContainer>
                        <ButtonIcon
                            icon={{ component: CloseIcon }}
                            size="M"
                            kind={ButtonIconKinds.GhostSecondary}
                            onClick={(e) => {
                                e.preventDefault();
                                onClear?.();
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                        />
                    </ClearButtonContainer>
                )}
                {children}
            </Wrapper>
        );
    }
}
