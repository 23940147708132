import { CheckIcon } from 'components/icons2/CheckIcon';

import { MenuContext, MenuData, MenuItemData } from '../interfaces';
import { CountryNotFound } from '../components/country-not-found';

export async function getCountriesMenu({ lcid, getCountries }: MenuContext): Promise<MenuData> {
    const items: MenuItemData[] = (await getCountries()).map(({ countryCode, countryRuName, countryEngName }) => ({
        id: countryCode,
        text(searchString: string) {
            if (!searchString) {
                return lcid === 'ru' ? countryRuName : countryEngName;
            }
            return /[а-яА-Я]/.test(searchString) ? countryRuName : countryEngName;
        },
    }));

    return {
        name: 'countries',
        showCloseButton: false,
        items,
        showFilter: true,
        filterNotFound: CountryNotFound,
        sortByFields: ['selected', 'text'],
        onClick: 'close',
        selectedItemIcon: CheckIcon,
        minHeight: 208,
        maxHeight: 504,
    };
}
