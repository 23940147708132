import { createSelector } from 'reselect';
import stringify from 'fast-json-stable-stringify';

import { AsyncMethod, GlobalStateForCache, PaginationConfig } from '../types';
import { selectorsCache } from '../cache/selectorsCache';

import { EMPTY } from './constants';
import { RequestStateSelectorReturn } from './types';

export function createSelectRequestState<ENDPOINT_NAME extends string, API_METHOD extends AsyncMethod, PAGE_INFO>({
    endpointName,
}: {
    endpointName: ENDPOINT_NAME;
    pagination?: PaginationConfig<API_METHOD, PAGE_INFO>;
}) {
    return createSelector(
        (state: GlobalStateForCache) => state.cache.requests[endpointName],
        (state: GlobalStateForCache) => state.cache.args[endpointName],
        (_: unknown, argsOrRequestName: Parameters<API_METHOD>[0] | string) => argsOrRequestName,
        (requests, argsMap, argsOrRequestName): RequestStateSelectorReturn => {
            const args = typeof argsOrRequestName === 'string' ? argsMap?.named[argsOrRequestName] : argsOrRequestName;
            if (!args) {
                return EMPTY;
            }
            const request = requests?.[stringify(args)] ?? {};
            const memoizedValue = selectorsCache.get([request]);
            if (memoizedValue) {
                return memoizedValue;
            }
            const { error, status = 'empty' } = request;
            const newValue = {
                error,
                isEmpty: status === 'empty',
                isLoading: status === 'loading',
                isReloading: status === 'reloading',
                isError: status === 'error',
                isReady: status === 'ready',
            };
            selectorsCache.set([request], newValue);
            return newValue;
        },
    );
}
