import React, { useEffect } from 'react';

import { FeedRecordObjTypes, Id, RatingReactionAction, UserChoice } from 'types';
import { sendGA } from 'services/analytics-outside/google-analytics';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { ActionPossibility } from 'widgets/action-possibility';
import { Like as LikeUI, LikeSizes } from 'components/like';

import { SendCommentRatingReaction, SendPostRatingReaction } from './actionGenerators';

interface DispatchProps {
    onRatingReaction: SendPostRatingReaction | SendCommentRatingReaction;
}
interface Props extends DispatchProps {
    objId: Id;
    recordId: Id;
    objType: FeedRecordObjTypes;
    userChoice: UserChoice | null;
    count?: number;
    size?: LikeSizes;
    isDisabled?: boolean;
}
export const Like = (props: Props) => {
    const { recordId, objId, objType, count, userChoice, size, isDisabled, onRatingReaction } = props;

    const pressed = userChoice !== null;
    const handleClick = TODOuseActionAfterAuthentication(() => {
        const [action, newChoice]: [RatingReactionAction, UserChoice | null] = pressed
            ? ['unlike', null]
            : ['like', UserChoice.LIKE];
        onRatingReaction({ objectId: objId as number, type: objType, action }, { newChoice, recordId });
        sendGA('feedRecord', [
            `like-${Object.keys(FeedRecordObjTypes)[Object.values(FeedRecordObjTypes).indexOf(objType)]}-click`,
            action,
        ]);
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const action = searchParams.get('mail_action');
        const id = searchParams.get('comment_id');
        if (action === 'comment_like' && id && objId && parseInt(id, 10) === objId && !pressed) {
            onRatingReaction(
                {
                    objectId: objId as number,
                    type: objType,
                    action: 'like',
                },
                { newChoice: UserChoice.LIKE, recordId },
            );
            sendGA('feedRecord', [
                `like-${Object.keys(FeedRecordObjTypes)[Object.values(FeedRecordObjTypes).indexOf(objType)]}-click`,
                'like',
            ]);
        }
    }, []);

    return (
        <ActionPossibility isPossible={!isDisabled}>
            <LikeUI
                pressed={pressed}
                onClick={handleClick}
                count={count}
                size={size}
                disabled={isDisabled}
                className="autotest__feed-item__like-button"
            />
        </ActionPossibility>
    );
};
Like.defaultProps = {
    count: 0,
    size: 'L' as LikeSizes,
};
