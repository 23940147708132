import React from 'react';

import { IconSvg, IconComponent } from '.';

export const TimesIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.43433 5.43451C5.74675 5.12209 6.25328 5.12209 6.5657 5.43451L12 10.8688L17.4343 5.43451C17.7467 5.12209 18.2533 5.12209 18.5657 5.43451C18.8781 5.74693 18.8781 6.25346 18.5657 6.56588L13.1314 12.0002L18.5657 17.4345C18.8781 17.7469 18.8781 18.2535 18.5657 18.5659C18.2533 18.8783 17.7467 18.8783 17.4343 18.5659L12 13.1316L6.5657 18.5659C6.25328 18.8783 5.74675 18.8783 5.43433 18.5659C5.12191 18.2535 5.12191 17.7469 5.43433 17.4345L10.8686 12.0002L5.43433 6.56588C5.12191 6.25346 5.12191 5.74693 5.43433 5.43451Z"
        />
    </IconSvg>
);
