import React, { useMemo, useRef, useEffect, useCallback } from 'react';

import { Id } from 'types';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

import { DEFAULT_SIZE_TABS } from './constants';
import { Tab } from './Tab';
import * as Styled from './styled/Tabs';
import { TabsProps, TabsItem, TabsSize, TabsType } from './props';

export type { TabsProps, TabsItem, TabsSize };

export function Tabs(props: TabsProps) {
    const {
        type: typeProp,
        kind = 'primary',
        size = DEFAULT_SIZE_TABS,
        direction = 'horizontal',
        wide = 'none',
        className = '',
        items,
        selectedItemIndex,
        selectedItemId,
        onItemSelected,
        scrollToActiveElement = true,
        isDisabled = false,
    } = props;
    const tabListRef = useRef<HTMLUListElement | null>(null);

    useHorizontalScroll(tabListRef);

    const handleScrollToActiveElement = useCallback(
        (element: HTMLElement | undefined | null) => {
            if (!scrollToActiveElement) return;
            const scrollToPosX = element?.offsetLeft;
            if (scrollToPosX) tabListRef?.current?.scrollTo?.({ left: scrollToPosX });
        },
        [scrollToActiveElement],
    );

    const tabElementsByIdRef = useRef<Record<Id, HTMLLIElement>>({});
    const tabElementsByIndexRef = useRef<Record<number, HTMLLIElement>>({});

    useEffect(() => {
        if (selectedItemId !== undefined) {
            handleScrollToActiveElement(tabElementsByIdRef.current?.[selectedItemId]);
        }
        if (selectedItemIndex !== undefined) {
            handleScrollToActiveElement(tabElementsByIndexRef.current?.[selectedItemIndex]);
        }
    }, [handleScrollToActiveElement, selectedItemId, selectedItemIndex]);

    const type: TabsType = useMemo(() => {
        if (direction === 'vertical') {
            return 'buttons';
        }

        if (typeProp) {
            return typeProp;
        }

        return 'standard';
    }, [typeProp, direction]);

    return (
        <Styled.TabsWrapper className={className}>
            <Styled.TabsListWrapper type={type} direction={direction} size={size} ref={tabListRef} wide={wide}>
                {items.map((item, index) => {
                    const {
                        title,
                        id,
                        icon,
                        count,
                        href,
                        onClick,
                        iconPos,
                        autotestClass,
                        hidden,
                        isDisabled: isDisabledTab,
                    } = item;

                    if (hidden) return null;

                    const isActive =
                        !isDisabled &&
                        (selectedItemIndex === index || (selectedItemId !== undefined && selectedItemId === id));

                    return (
                        <Tab
                            id={id}
                            key={title || id}
                            type={type}
                            kind={kind}
                            isActive={isActive}
                            onMount={(tabElement, tabItemProps) => {
                                if (tabElement?.current) {
                                    tabElementsByIdRef.current[id] = tabElement?.current;
                                    tabElementsByIndexRef.current[index] = tabElement?.current;
                                }
                                if (tabItemProps.isActive) {
                                    handleScrollToActiveElement(tabElement?.current);
                                }
                            }}
                            icon={icon}
                            title={title}
                            count={count}
                            href={href}
                            autotestClass={autotestClass}
                            size={size}
                            iconPos={iconPos}
                            onClick={(e) => {
                                onItemSelected?.(index, id);
                                onClick?.(e);
                            }}
                            isDisabled={isDisabled || isDisabledTab}
                        />
                    );
                })}
            </Styled.TabsListWrapper>
            {type === 'standard' && <Styled.Divider size={size} isDisabled={isDisabled} />}
        </Styled.TabsWrapper>
    );
}
