import { useCallback, useEffect, useState } from 'react';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLocaleId } from 'hooks/useLocaleId';
import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';

import { MenuData, MenuContext, GeoGenericExporter } from '../interfaces';

import { getUserMenu } from './userMenu';
import { getGuestMenu } from './guestMenu';

export async function getMenuData(context: MenuContext): Promise<MenuData> {
    if (context.user.isAuth) {
        return getUserMenu(context);
    }
    return getGuestMenu(context);
}

type useGetMenuDataParams = {
    getCountries: () => Promise<GeoGenericExporter[]>;
    isMobile?: boolean;
};

export function useGetMenuData(params: useGetMenuDataParams): [MenuData | null, () => void] {
    const { getCountries, isMobile = false } = params;

    const dic = useDictionary();
    const user = useCurrentUser();
    const lcid = useLocaleId();
    const links = useLinks();
    const [items, setItems] = useState<MenuData | null>(null);

    const reset = useCallback(() => {
        getMenuData({
            lcid,
            user,
            getCountries,
            dic,
            links,
            isMobile,
        }).then(setItems);
    }, [dic, getCountries, lcid, user, links, isMobile]);

    useEffect(() => {
        reset();
    }, [reset]);

    return [items, reset];
}
