import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { calcProfitDataInPercent, formatChartYieldToPercent } from 'widgets/profitability-chart/utils';

import { DEFAULT_PROFITABILITY_VALUE } from './constants';
import { useComputedData } from './hooks/useComputedData';
import * as Styled from './styled';
import { ChartData, ChartDataEntry, ChartProps, ChartXYAxisTicks } from './types';

const xYAxisTicks = (value: ChartXYAxisTicks, data: ChartData) => {
    if (Array.isArray(value)) {
        return value;
    }

    if (typeof value === 'function') {
        return value?.(data);
    }

    return undefined;
};

export const Chart: FC<ChartProps> = (props) => {
    const {
        data,
        referenceData,
        gradientOffset: gradientOffsetProp,
        staticColorProfitability = DEFAULT_PROFITABILITY_VALUE,
        tooltipContent,
        showGrid = true,
        showXAxis = true,
        showYAxis = true,
        xAxisTicks: xAxisTicksProp,
        yAxisTicks: yAxisTicksProp,
        xAxisFormatter = (value) => value.toString(),
        yAxisFormatter = (value) => value.toString(),
        lineWidth = 2,
        activeDot = true,
        withoutBgColor = false,
        label,
        className: classNameProp,
        sizePeriodCropData = 'all',
        onChangeComputedChartData,
    } = props;

    const theme = useTheme();

    const className = useMemo(() => [classNameProp, 'autotest__chart'].filter((i) => i).join(' '), [classNameProp]);

    const computedData = useComputedData({ data, sizePeriodCropData, onChangeComputedChartData });

    const combinedData = useMemo(() => {
        if (!referenceData) {
            return computedData;
        }
        const result: (ChartDataEntry & { referenceValue?: number })[] = [];
        const croppedRef: ChartDataEntry[] = [];
        for (let i = 0; i < computedData.length; i++) {
            croppedRef.push({
                date: computedData[i].date,
                value: referenceData?.find(({ date }) => date === computedData[i].date)?.value ?? 0,
            });
        }
        const calculatedRef = formatChartYieldToPercent(calcProfitDataInPercent(croppedRef));
        for (let i = 0; i < computedData.length; i++) {
            result.push({
                date: computedData[i].date,
                value: computedData[i].value,
                referenceValue: calculatedRef?.[i].value,
            });
        }
        return result;
    }, [computedData, referenceData]);

    const xAxisTicks = useMemo(() => xYAxisTicks(xAxisTicksProp, computedData), [computedData, xAxisTicksProp]);

    const yAxisTicks = useMemo(() => xYAxisTicks(yAxisTicksProp, computedData), [computedData, yAxisTicksProp]);

    const gradientOffset = useMemo((): number | undefined => {
        if (typeof gradientOffsetProp === 'function') {
            return gradientOffsetProp(computedData);
        }

        if (typeof gradientOffsetProp === 'number') {
            return gradientOffsetProp;
        }

        return undefined;
    }, [gradientOffsetProp, computedData]);

    const hasGradient = useMemo(() => typeof gradientOffset !== 'undefined', [gradientOffset]);

    const staticChartColor: string = useMemo(() => {
        switch (staticColorProfitability) {
            case 'positive':
                return theme.fillIn.positive.low;
            case 'negative':
                return theme.fillIn.negative.low;
            case 'neutral':
            default:
                return theme.label.tertiary;
        }
    }, [staticColorProfitability, theme]);

    const lastValue = useMemo(() => computedData[computedData.length - 1]?.value, [computedData]);

    return (
        <Styled.ChartWrapper className={className} withoutBgColor={withoutBgColor}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={combinedData}>
                    {hasGradient && (
                        <defs>
                            <linearGradient id="profitGradient" x1="0%" y1="100%" x2="0" y2="0">
                                <stop offset="0%" stopColor={theme.fillIn.negative.low} />
                                <stop offset={`${gradientOffset}%`} stopColor={theme.fillIn.negative.low} />
                                <stop offset={`${gradientOffset}%`} stopColor={theme.fillIn.positive.low} />
                                <stop offset="100%" stopColor={theme.fillIn.positive.low} />
                            </linearGradient>
                        </defs>
                    )}
                    {showXAxis && (
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            ticks={xAxisTicks}
                            tickFormatter={(value) => xAxisFormatter?.(value, computedData)}
                            tickMargin={8}
                            padding={{
                                right: 4,
                            }}
                        />
                    )}
                    {showYAxis && (
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            ticks={yAxisTicks}
                            tickFormatter={(value) => yAxisFormatter?.(value, computedData)}
                            orientation="right"
                            interval={0}
                            padding={{
                                top: 20,
                                bottom: 20,
                            }}
                            domain={['dataMin', 'dataMax']}
                            width={56}
                        />
                    )}
                    {tooltipContent && <Tooltip wrapperStyle={{ outline: 'none' }} content={tooltipContent} />}
                    {showGrid && <CartesianGrid vertical={false} stroke={theme.fillIn.secondary.overlay16} />}
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke={hasGradient ? 'url(#profitGradient)' : staticChartColor}
                        strokeWidth={lineWidth}
                        dot={false}
                        activeDot={
                            activeDot && {
                                fill: theme.fillIn.secondary.mid,
                                stroke: theme.staticColors.white,
                                strokeWidth: 1,
                                r: 4,
                            }
                        }
                    />
                    <Line
                        type="monotone"
                        dataKey="referenceValue"
                        stroke={theme.label.tertiary}
                        strokeWidth={lineWidth}
                        dot={false}
                        activeDot={
                            activeDot && {
                                fill: theme.fillIn.secondary.mid,
                                stroke: theme.staticColors.white,
                                strokeWidth: 1,
                                r: 4,
                            }
                        }
                    />
                    {label && lastValue && (
                        <ReferenceLine
                            y={lastValue}
                            stroke={theme.fillIn.secondary.muted}
                            strokeWidth={2}
                            strokeDasharray="4 4"
                        >
                            <Label content={label} value={lastValue} />
                        </ReferenceLine>
                    )}
                </LineChart>
            </ResponsiveContainer>
        </Styled.ChartWrapper>
    );
};
