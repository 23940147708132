import React from 'react';

import { IconSvg, IconComponent } from '.';

export const DzenIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 11.8178C6.70228 11.7752 8.77444 11.5329 10.1537 10.1537C11.5329 8.77444 11.7752 6.70228 11.8178 3C6.99094 3.09582 3.09582 6.99094 3 11.8178ZM12.3554 3.00508C12.3981 6.70418 12.6408 8.77507 14.0195 10.1537C15.377 11.5113 17.406 11.7674 21 11.8157C20.9042 7.04762 17.1012 3.18931 12.3554 3.00508ZM20.9949 12.3576C17.4041 12.406 15.3764 12.6625 14.0195 14.0195C12.6625 15.3764 12.406 17.4041 12.3576 20.9949C17.0448 20.8118 20.8118 17.0448 20.9949 12.3576ZM11.8157 21C11.7674 17.406 11.5113 15.377 10.1537 14.0195C8.77507 12.6408 6.70418 12.3981 3.00508 12.3554C3.18931 17.1012 7.04764 20.9042 11.8157 21Z"
        />
    </IconSvg>
);
