import { produce } from 'immer';

import { CacheData } from '../types';

export function cacheCommit({ cache, operationId }: { cache: CacheData; operationId?: string }) {
    return produce(cache, (draft) => {
        if (operationId) {
            delete draft.patches[operationId];
        }
    });
}
