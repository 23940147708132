import { Action, PublishStatusRecord } from 'types';
import { Actions } from 'services/create-action-types/backend-client';
import { createThunkActions } from 'services/create-action-types/api-services';
import { BlogService } from 'services/api-services/generated_limex';

import { GlobalStateForFeed } from '../../types';

const [DELETE_FEED_RECORD_ACTION_TYPES, deleteThunk, deleteActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('DELETE_FEED_RECORD', BlogService.deletePost)<{ instanceId: string; recordId: string }>();

function deleteFeedRecord(record: PublishStatusRecord, csrf: string, instanceId: string) {
    const {
        id,
        obj: { id: postId },
    } = record;
    return deleteThunk({
        params: {
            postId: +postId,
            requestBody: {
                csrf,
            },
        },
        pass: { instanceId, recordId: id.toString() },
    });
}

type DeleteFeedRecord = Action<typeof deleteFeedRecord>;
type DeleteFeedRecordActions = Actions<typeof deleteActions>;
export { DELETE_FEED_RECORD_ACTION_TYPES, deleteFeedRecord, DeleteFeedRecord, DeleteFeedRecordActions };
