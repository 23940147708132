import { css } from 'styled-components';

import { Theme } from 'services/theme';
import { buttonMediumFontStyle, buttonSmallFontStyle, caption12RegularFontStyle } from 'services/typography';

import { StateMap, TabsListItemProps } from './index';

export const itemButtons = {
    tabItemStyles: {
        common: css``,
        active: css``,
    },
    anchorStyles: (theme: Theme): StateMap => {
        const { label, fillIn } = theme;
        const ifSecondary =
            (thenVal: string, elseVal?: string) =>
            ({ kind }: TabsListItemProps) =>
                kind === 'secondary' ? thenVal : elseVal || '';

        return {
            common: css`
                padding-top: var(--padding-vertical);
                padding-bottom: var(--padding-vertical);
            `,
            hover: css`
                color: ${ifSecondary(label.secondaryHigh, label.accent)};
                background: ${ifSecondary(fillIn.secondary.overlay16, fillIn.primary.overlay16)};
            `,
            active: css`
                color: ${ifSecondary(label.primary, label.accent)};
                background: ${ifSecondary(fillIn.secondary.overlay32, fillIn.primary.overlay32)};
            `,
            // activeFocused: css`
            //     color: ${ifSecondary(label.primary, label.accent)};
            //     background: ${ifSecondary(fillIn.secondary.overlay32, fillIn.primary.overlay32)};
            //     box-shadow: inset 0 0 0 3px ${fillIn.primary.overlay24};
            // `,
            // inactiveFocused: css`
            //     box-shadow: inset 0 0 0 3px ${fillIn.primary.overlay32};
            // `,
        };
    },
    sizeMap: {
        large: css`
            ${buttonMediumFontStyle};
            --tab-padding-vertical: 0px;
            --padding-vertical: 8px;
            --padding-horizontal: 16px;
            --padding-icon-compensation: 4px;
            --divider-width: 3px;
        `,
        medium: css`
            ${buttonSmallFontStyle};
            --tab-padding-vertical: 0px;
            --padding-vertical: 4px;
            --padding-horizontal: 16px;
            --padding-icon-compensation: 4px;
        `,
        small: css`
            ${caption12RegularFontStyle};
            --tab-padding-vertical: 0px;
            --padding-vertical: 4px;
            --padding-horizontal: 12px;
            --padding-icon-compensation: 2px;
        `,
    },
    tabsContainerStyles: css``,
};
