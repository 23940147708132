import type { Step3FormValues } from './types';

export const defaultValues: Step3FormValues = {
    name: '',
    generalTradingIdea: '',
    description: '',
};

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 100;

export const DESCRIPTION_MIN_LENGTH = 30;
export const DESCRIPTION_MAX_LENGTH = 450;

export const CHECK_FIELDS = ['name', 'description'];
