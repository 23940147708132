import React from 'react';

import { Action } from 'types';
import { api } from 'services/api-builder/api';
import { Profile } from 'services/mp-share-resources/types';

import { SignUpBanner } from './SignUpBanner';
import { CompleteBanner } from './CompleteBanner';
import { getHandleMyConnectedOnboarding } from './getHandleMyConnectedOnboarding';

export const stripeBanners = (
    profile: Profile,
    actions: {
        createMyConnectedAccount: Action<typeof api.createMyConnectedAccount>;
        createMyConnectedOnboarding: Action<typeof api.createMyConnectedOnboarding>;
    },
) => {
    const handleMyConnectedOnboarding = getHandleMyConnectedOnboarding(actions.createMyConnectedOnboarding);
    if (!profile.connectedAccountMetadata) {
        return (
            <SignUpBanner
                onMyConnectedOnboarding={handleMyConnectedOnboarding}
                createMyConnectedAccount={actions.createMyConnectedAccount}
            />
        );
    }
    if (profile.connectedAccountMetadata && !profile.connectedAccountMetadata.detailsSubmitted) {
        return <CompleteBanner onMyConnectedOnboarding={handleMyConnectedOnboarding} />;
    }
    return null;
};
