import styled from 'styled-components';

import { matchBreakpoints } from 'app/styled';
import { body17MediumFontStyle, body14MediumFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';
import { Button as ButtonComponent } from 'components/button2';

import { SnackbarIcon } from './SnackbarIcon.styled';
import { SnackbarType, SnackbarSize } from './types';

const transitionAll = 'transition: all 0.3s linear;';

export const Content = styled.div`
    display: flex;
    align-items: center;
    ${transitionAll};
    ${SnackbarIcon} {
        ${transitionAll};
    }
`;

export const Text = styled.span<{ type: SnackbarType }>`
    color: ${({ type }) => {
        switch (type) {
            case 'warning':
                return tokens.colors.text.base.default.default;
            default:
                return tokens.colors.text.ondark.default.default;
        }
    }};
`;

export const Divider = styled.div`
    content: '';
    border: 1px solid ${tokens.colors.border.disabled.default.default};
    background-color: ${tokens.colors.border.disabled.default.default};
    border-radius: 10px;
    margin: 4px 0 4px 12px;
    ${transitionAll};
`;

const buildSizesWrapper = ({
    size,
    hasIcon,
    hasButton,
    hasOnlyButton,
    hasOnlyIcon,
}: {
    size: SnackbarSize;
    hasIcon: boolean;
    hasButton: boolean;
    hasOnlyButton: boolean;
    hasOnlyIcon: boolean;
}) => {
    const paddingLeftMedium = hasIcon && !hasOnlyIcon ? 20 : 24;
    const paddingRightMedium = hasButton ? 0 : 24;
    const paddingLeftSmall = hasIcon && !hasOnlyIcon ? 12 : 16;
    const paddingRightSmall = hasButton ? 0 : 16;

    return matchBreakpoints(size, {
        regular: `
            ${body17MediumFontStyle};
            ${Content} {
                padding: ${hasOnlyButton ? '0' : `12px ${paddingRightMedium}px 12px ${paddingLeftMedium}px`};
            }
        `,
        small: `
            ${body14MediumFontStyle};
            ${Content} {
                padding: ${hasOnlyButton ? '0' : `10px ${paddingRightSmall}px 10px ${paddingLeftSmall}px`};
            }
        `,
    });
};

export const getBgColorByKind = ({ type }: { type: SnackbarType }) => {
    if (type === 'negative') {
        return tokens.colors.bg.negative.default.default;
    }

    if (type === 'warning') {
        return tokens.colors.bg.warning.default.default;
    }

    return tokens.colors.bg.inverse.default.default;
};

export const Snackbar = styled.div`
    display: inline-block;
`;

export const SnackbarWrapper = styled.div<{
    size: SnackbarSize;
    type: SnackbarType;
    hasIcon: boolean;
    hasButton: boolean;
    hasOnlyButton: boolean;
    hasOnlyIcon: boolean;
}>`
    max-width: calc(100vw - 16px);
    display: flex;
    border-radius: ${tokens.radius.rounded};
    box-shadow: ${({ theme }) => theme.shadow.popover};

    background-color: ${getBgColorByKind};

    ${buildSizesWrapper};
    ${transitionAll};
`;

export const Button = styled(ButtonComponent)`
    align-self: center;
    ${transitionAll};
`;
