import { FeedRecord, isPublishStatusRecord, FeedRecordType } from 'types';

const getDefaultShowFull = (record: FeedRecord) => {
  if (record.type === FeedRecordType.RECOMMENDATIONS) return true;
    if (!isPublishStatusRecord(record)) {
        return false;
    }
    const { obj } = record;
    if (!obj) return true;
    let textLength;
    // TODO позже решить eslint
    // const hasContentTextLengthProperty = Object.prototype.hasOwnProperty.call(obj, 'contentTextLength');
    // скорее всего решит проблему eslint
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('contentTextLength')) {
        textLength = +(obj.contentTextLength || 0);
    } else {
        textLength = +obj.content.length;
    }
    let mediaLength = 0;
    if (obj.media) {
        mediaLength = Object.values(obj.media).reduce((sum, item) => sum + item.count, 0);
    }
    if (mediaLength === 1 && textLength < 300) {
        return true;
    }

    return mediaLength === 0 && textLength < 200;
};

export const getFeedItemContextDefaultValue = (record: FeedRecord, showFull?: boolean) => {
    const { id } = record;
    return {
        id,
        showFull: showFull || getDefaultShowFull(record),
        isEditing: false,
    };
};
