import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { matchPath, PathPatternExtended } from 'services/utils/match-path';
import { useNonClosableRef } from 'hooks/useUnclosureRef';

function matchPaths(routes: PathPatternExtended | PathPatternExtended[], pathname: string) {
    if (Array.isArray(routes)) {
        return routes.map((route) => matchPath(route, pathname)).find((match) => match) ?? null;
    }
    return matchPath(routes, pathname);
}

function getParam(routes: PathPatternExtended | PathPatternExtended[], pathname: string, paramName: string) {
    return matchPaths(routes, pathname)?.params?.[paramName];
}

export function useOnRouteParameterChange(
    route: PathPatternExtended | PathPatternExtended[],
    paramName: string,
    callback: () => void,
    compare: (current: string | undefined, prev: string | undefined) => boolean = (current, prev) => prev === current,
) {
    const callbackRef = useNonClosableRef(callback);
    const compareRef = useNonClosableRef(compare);
    const { pathname } = useLocation();

    const currentValue = getParam(route, pathname, paramName);
    const previousRef = useRef<string | undefined>(currentValue);

    useEffect(() => {
        if (!compareRef.current(previousRef.current, currentValue)) {
            previousRef.current = currentValue;
            callbackRef.current();
        }
    }, [callbackRef, compareRef, currentValue]);

    return currentValue;
}
