import React from 'react';

import { FeedOwnerType, Person } from 'types';
import { FeedRecordGenerator, FeedRecordObj, FeedRecordOptions } from 'types/feed-record';
import { GroupExporter } from 'services/api-services/generated_limex';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLinks } from 'hooks/useLinks';
import { ActionSubscribe } from 'widgets/action-subscribe';
import { UserBlock } from 'components/user-block';
import { ButtonIconKinds } from 'components/button-icon';
import { UserBlockProps } from 'components/user-block/types';

import { useIsOwner } from '../../feed/hooks/useIsOwner';
import { useAllowedActions } from '../hooks/useAllowedActions';
import { useSubscribe } from '../hooks/useSubscribe';
import { useTimeAgo } from '../hooks/useTimeAgo';

import { LockBlock } from './LockBlock';
import { ContextActionsRecord } from './ContextActions';
import * as Styled from './styled';
import { Community, CommunitySeparator } from './styled';

const AVATAR_PROPS: UserBlockProps['avatar'] = { size: { default: 'L', lt480: 'M' } };

interface Props {
    obj: FeedRecordObj;
    isPremium: boolean;
    generator: FeedRecordGenerator;
    options: FeedRecordOptions;
    onDelete: () => void;
    context?: Person | GroupExporter | { type: null };
}

export const Author = (props: Props) => {
    const {
        obj: { author, date, shareUrl, url, id },
        isPremium,
        generator,
        options: { visLevel },
        onDelete,
        context,
    } = props;

    const isFollowable = 'isFollowable' in generator ? generator.isFollowable : true;
    const communityDisplayName = context?.type === FeedOwnerType.COMMUNITY ? context?.displayName : undefined;

    const { subscribed = false } = generator?.data || {};
    const { showSubscribe } = useSubscribe(subscribed ?? false);
    const { handleClickTimeAgo, timeAgo, localeTimeString } = useTimeAgo(date);
    const isOwner = useIsOwner(author);
    const { isAllowedActions } = useAllowedActions(date, author);

    const currentUser = useCurrentUser();
    const links = useLinks();

    const renderCommunity = () => {
        if (!communityDisplayName) {
            return null;
        }

        const communityHref = links.community((context as Person).id);

        return (
            <>
                <CommunitySeparator>•</CommunitySeparator>
                <Community href={communityHref}>{communityDisplayName}</Community>
            </>
        );
    };

    return (
        <Styled.AuthorWrapper>
            <UserBlock
                user={author}
                avatar={AVATAR_PROPS}
                secondLine={
                    <>
                        <Styled.TimeAgoLink
                            href={url}
                            target="_blank"
                            onClick={handleClickTimeAgo}
                            title={localeTimeString}
                        >
                            <Styled.TimeAgo>{timeAgo}</Styled.TimeAgo>
                        </Styled.TimeAgoLink>
                        <LockBlock isPremium={isPremium} visLevel={visLevel} />
                        {renderCommunity()}
                    </>
                }
            />
            <Styled.Actions>
                {!currentUser.isSame(generator.id) && isFollowable && showSubscribe && (
                    <ActionSubscribe
                        data={{ generatorId: generator.id, generatorType: generator.type }}
                        isSubscribed={subscribed ?? false}
                        clickable={!subscribed}
                        buttonProps={{
                            kind: ButtonIconKinds.GhostSecondary,
                            disabled:
                                'isBlockedByCurrentUser' in generator
                                    ? generator.isBlockedByCurrentUser || generator.isBlockedByGivenUser
                                    : false,
                        }}
                    />
                )}
                <ContextActionsRecord
                    shareUrl={shareUrl}
                    canEdit={isOwner && isAllowedActions}
                    canDelete={isOwner}
                    onDelete={onDelete}
                    recordId={id}
                />
            </Styled.Actions>
        </Styled.AuthorWrapper>
    );
};
