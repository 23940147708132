import axios, { CancelTokenSource } from 'axios';

import { Action, Community, FeedOwnerType, Lcid } from 'types';
import { FeedOwner, FeedRecordSource } from 'context/feed';
import { Actions, createThunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';
import { CustomThunkReturnType } from 'services/create-action-types/shared/types';
import { sendGA } from 'services/analytics-outside/google-analytics';
import { ComonRecordExporter, RecommendedUsersContainerExporter } from 'services/api-services/generated_limex';

import { GlobalStateForFeed } from '../../types';

export type FetchRecordsPayloadData = {
    records: (ComonRecordExporter | RecommendedUsersContainerExporter)[];
    boundary: string | null;
    boundaryRecordId: string;
};

type FetchRecordsSettings = {
    owner?: FeedOwner | null;
    source: FeedRecordSource;
    lcid: Lcid;
    tagId?: string;
    searchTagIds?: number[];
    filter: string;
    reset?: boolean;
    pinned?: number;
    withRecommendedUsers?: boolean;
};

export type RawInitialData = FetchRecordsPayloadData & {
    communities?: Community[];
};

const [FETCH_FEED_RECORDS_ACTION_TYPES, thunk, actions] = createThunkActions<'widgets', GlobalStateForFeed>('widgets')(
    'FETCH_FEED_RECORDS',
)<void, FetchRecordsPayloadData, { reset: boolean; instanceId: string }>();

let feedPacksCount = 0;

const cancelMap: Record<string, CancelTokenSource> = {};

function fetchRecords(instanceId: string, settings: FetchRecordsSettings): CustomThunkReturnType<typeof thunk> {
    return (dispatch, getState, extraArgument) => {
        const state = getState();

        const {
            widgets: {
                feed: { instances },
            },
        } = state;

        const {
            owner,
            source,
            lcid,
            // для поиска в общей ленте и из поста
            tagId,
            // для поиска в профиле / инструменте
            searchTagIds = [],
            filter,
            reset = false,
            withRecommendedUsers,
            pinned,
        } = settings;
        const { tags } = state.widgets.tags ?? {};
        const isTimeline = filter === 'post';
        const tag = tags?.find(({ id }) => id === parseInt(tagId ?? '', 10));

        const { boundaryRecordId, boundary, isLoading = false, ids = [] } = instances[instanceId] ?? {};

        const { id: ownerId = 0, type: ownerType = 0 } = owner || {};

        const existRecordIds = reset ? undefined : JSON.stringify(ids.map((id) => parseInt(id.slice(1), 10)));

        if (!reset && (isLoading || boundary === null)) {
            return Promise.resolve();
        }

        if (reset) {
            cancelMap[instanceId]?.cancel();
        }

        sendGA('feedRecords', ['fetch-by-intersection', feedPacksCount + 1]);

        const cancelTokenSource = axios.CancelToken.source();
        cancelMap[instanceId] = cancelTokenSource;

        return thunk({
            request: {
                url: UrlProcessor.page('capi_activity_stream_activities')
                    .params({
                        owner_id: tag?.id ?? ownerId,
                        owner_type: tag?.id ? FeedOwnerType.TAG : ownerType,
                        source: tag?.id || (isTimeline && owner !== null) ? 'timeline' : source,
                    })
                    .url(),
                params: {
                    orderBy: isTimeline ? 'rank' : filter,
                    enableRecommendations: filter === 'date' ? 0 : 1,
                    simpleFeed: 1, // dg: Для новой ленты отключаем загрузку информации о причинах показа записей @since #WTT-9608
                    init: reset ? 1 : undefined,
                    feedContext: 'limex', // dg: Указываем контекст загрузки ленты для кастомной конфигурации @since #WTT-10004
                    reqlcid: lcid, // dg: Загружаем ленту на текущем языке. Актуально для подмерживаемых записей, которые зависит от языка. @since #WTT-10352
                    boundary: reset ? undefined : boundary,
                    tagIds: searchTagIds.length > 0 ? [...searchTagIds].sort((a, b) => a - b).join(',') : undefined,
                    boundaryRecordId: reset ? undefined : boundaryRecordId,
                    existRecordIds: reset ? undefined : JSON.stringify(ids.map((id) => parseInt(id.slice(1), 10))),
                    withRecommendedUsers: withRecommendedUsers ? 1 : 0,
                    pinned,
                },
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                withCredentials: true,
            },
            pass: { instanceId, reset },
            cancelation: { source: cancelTokenSource, outdated: false },
        })(dispatch, getState, extraArgument)
            .then((result) => {
                if (result.payload.data.records.length > 0) {
                    feedPacksCount++;
                    sendGA('feedRecords', ['fetched', feedPacksCount]);
                }
                return result;
            })
            .catch((error) => {
                if (!axios.isCancel(error.payload.error)) {
                    throw error;
                }
            });
    };
}

type FetchRecords = Action<typeof fetchRecords>;
type FetchRecordsActions = Actions<typeof actions>;

export { FETCH_FEED_RECORDS_ACTION_TYPES, fetchRecords, FetchRecords, FetchRecordsActions };
