import React, { useRef, useEffect } from 'react';
import {
    ChatContainer,
    Message,
    MessageInput,
    MessageList,
    Avatar,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import { v4 as uuidv4 } from 'uuid';
import PullScroll from 'hocs/PullScroll';

import { useDictionary } from 'hooks/useDictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useAsyncFn } from 'hooks/useAsyncFn';
import { LimexIcon } from 'components/icons2/LimexIcon';
import { Button } from 'components/button2';

import { ChatEvent } from '../types';
import { ChatWidgetProps } from '../container';

import { ChatWrapper, Header, HeaderSecondary, pullScrollStyles, StyledMainContainer, Content } from './styled';
import AiAvatar from './ai-avatar.png';

type Props = {
    styles: React.CSSProperties;
    events: ChatEvent[];
    isReady: boolean;
    createChatEvent: ChatWidgetProps['createChatEvent'];
};

const ChatWindowImplementation = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const { styles, events, isReady, createChatEvent } = props;
    const messageListRef = useRef<HTMLDivElement & { scrollToBottom:() => void }>(null);
    const dic = useDictionary();
    const { image, kratosId } = useCurrentUser();
    const [{ isLoading: isCreateLoading }, handleCreateChatEvent] = useAsyncFn(createChatEvent);
    useEffect(() => {
        if (isReady) {
            messageListRef.current?.scrollToBottom();
        }
    }, [isReady]);
    return (
        <ChatWrapper style={styles} ref={ref}>
            <Header>
                <LimexIcon size={16} />
                &nbsp; Limex &nbsp;
                <HeaderSecondary>{dic.word('wt__widget_chat__header')}</HeaderSecondary>
            </Header>
            <Content>
                {isReady ? (
                    <StyledMainContainer>
                        <ChatContainer>
                            <MessageList
                                ref={messageListRef}
                                typingIndicator={
                                    isCreateLoading ? (
                                        <TypingIndicator content={dic.word('wt__widget_chat__typing')} />
                                    ) : null
                                }
                            >
                                <Message
                                    key={0}
                                    model={{
                                        message: dic.word('wt__widget_chat__first_message'),
                                        direction: 'incoming',
                                        position: 'normal',
                                    }}
                                >
                                    <Avatar src={AiAvatar} size="sm" />
                                </Message>
                                {events.map((event) => (
                                    <Message
                                        key={event.eventId}
                                        model={{
                                            message: event.params.text,
                                            direction: event.origin === 'ai' ? 'incoming' : 'outgoing',
                                            position: 'normal',
                                        }}
                                    >
                                        <Avatar src={event.origin === 'ai' ? AiAvatar : image || ''} size="sm" />
                                    </Message>
                                ))}
                            </MessageList>
                            <MessageInput
                                placeholder={dic.word('wt__widget_chat__placeholder')}
                                attachButton={false}
                                onSend={(innerHtml, textContent) => {
                                    const sessionId = kratosId || '';
                                    handleCreateChatEvent(sessionId, uuidv4(), 'text', textContent, 'human');
                                }}
                            />
                        </ChatContainer>
                    </StyledMainContainer>
                ) : (
                    <Button kind="ghost-secondary" isProcessing />
                )}
            </Content>
        </ChatWrapper>
    );
});

export const ChatWindow = PullScroll(ChatWindowImplementation, pullScrollStyles);
