import React, { useEffect, useState } from 'react';

import { Community, Id, PostType } from 'types';
import { mediaQuery } from 'app/styled';
import Dic from 'services/dictionary';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { ChevronUpSmallIcon } from 'components/icons2/ChevronUpSmallIcon';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';
import { ChevronDownSmallIcon } from 'components/icons2/ChevronDownSmallIcon';

import * as Styled from './styled';
import { PostTypeMenu } from './PostTypeMenu';
import { PostTypeDisplay } from './PostTypeDisplay';

type PostTypeSelectorProps = {
    isEditing: boolean;
    isFeed: boolean;
    isCommunity: boolean;
    premiumPostsAllowed: boolean;
    type: PostType | null;
    onChangeType: (type: PostType) => void;
    communityId?: Id;
    onChangeCommunityId: (communityId: Id | undefined) => void;
    communities?: Community[];
};

export const PostTypeSelector = ({
    type,
    isEditing,
    isFeed,
    isCommunity,
    onChangeType,
    communities,
    communityId,
    onChangeCommunityId,
    premiumPostsAllowed,
}: PostTypeSelectorProps) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isPopupShown, setIsPopupShown] = useState(false);

    const handleTooltipVisibleChange = ({ isVisible, inTransition }: { isVisible: boolean; inTransition: boolean }) => {
        setIsMenuOpened(isVisible !== inTransition);
    };

    const showMenu = Boolean(isFeed && (premiumPostsAllowed || communities));

    const isMobile = useMatchMedia(mediaQuery.lt768);

    useEffect(() => {
        if (!isMobile) {
            setIsPopupShown(false);
        }
    }, [isMobile]);

    const postTypeDisplay = (
        <PostTypeDisplay
            postType={type}
            communities={communities}
            communityId={communityId}
            isEditing={isEditing}
            isFeed={isFeed}
            isCommunity={isCommunity}
            showMenu={showMenu}
        />
    );

    const menu = (
        <PostTypeMenu
            communities={communities}
            onChangeCommunityId={onChangeCommunityId}
            onChangeType={onChangeType}
            premiumPostsAllowed={premiumPostsAllowed}
            setIsPopupShown={setIsPopupShown}
        />
    );

    if (!showMenu) {
        return postTypeDisplay;
    }

    return (
        <>
            {isMobile ? (
                <Styled.PostTypeSelectorMobileWrapper>
                    <Styled.TextWithIcon
                        onClick={() => setIsPopupShown(true)}
                        text={postTypeDisplay}
                        iconPos="right"
                        icon={{
                            gap: '5px',
                            size: 16,
                            component: ChevronDownSmallIcon,
                        }}
                    />
                </Styled.PostTypeSelectorMobileWrapper>
            ) : (
                <Styled.PostTypeSelectorDesktopWrapper>
                    <Styled.ContextActionsStyled
                        tippyContent={menu}
                        placement="bottom-start"
                        onVisibleChange={handleTooltipVisibleChange}
                        buttonContent={
                            <Styled.TextWithIcon
                                text={postTypeDisplay}
                                iconPos="right"
                                icon={{
                                    gap: '5px',
                                    size: 16,
                                    component: isMenuOpened ? ChevronUpSmallIcon : ChevronDownSmallIcon,
                                }}
                            />
                        }
                    />
                </Styled.PostTypeSelectorDesktopWrapper>
            )}
            {isPopupShown && (
                <WtPopup3
                    size={SizeWtPopup3Enum.SMALL}
                    bgCloseable
                    close={() => setIsPopupShown(false)}
                    title={Dic.word('wt_feed__timeline_status__post_type_select')}
                >
                    {menu}
                </WtPopup3>
            )}
        </>
    );
};
