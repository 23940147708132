import styled from 'styled-components';

import { FeedItemWrapper } from '../../styled';

const ANIMATION_DURATION = 180;
const LOADING_WRAPPER_HEIGHT = 72;

export const LoadingWrapper = styled(FeedItemWrapper)<{ isVisible: boolean }>`
    height: ${({ isVisible }) => (isVisible ? `${LOADING_WRAPPER_HEIGHT}px` : '0')};
    transition: height ${ANIMATION_DURATION}ms;
    overflow: hidden;
`;

export const LoadingContent = styled.div`
    display: flex;
    justify-content: center;
`;

export const LoadingText = styled.span``;
