import React from 'react';

import { IconSvg, IconComponent } from '.';

export const BookmarkIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00508 2.211C7.01096 2.211 6.20508 3.01689 6.20508 4.011V20.2741C6.20508 21.2584 7.39891 21.7472 8.08924 21.0455L12.0019 17.0683L15.9145 21.0455C16.6048 21.7472 17.7987 21.2584 17.7987 20.2741V4.011C17.7987 3.01689 16.9928 2.211 15.9987 2.211H8.00508ZM7.80508 4.011C7.80508 3.90054 7.89462 3.811 8.00508 3.811H15.9987C16.1091 3.811 16.1987 3.90054 16.1987 4.011V19.0529L12.786 15.5839C12.3551 15.1458 11.6487 15.1458 11.2177 15.5839L7.80508 19.0529V4.011Z"
        />
    </IconSvg>
);
