import { URLSearchParamsInit, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

import { useLinks } from 'hooks/useLinks';

import {
    SELECTED_CATALOG_DISPLAY_TYPE_PARAM_KEY,
    SELECTED_PRODUCT_ID_URL_PARAM_KEY,
    SELECTED_PRODUCT_TYPE_URL_PARAM_KEY,
} from './pages/constants';

export type UseProductPagesNavigationReturn = {
    selectedProductType: string | undefined;
    goToProductSignals: (productId: number) => void;
    handleClose: () => void;
    updateUrlAndRedirectForProduct: (type: string, id: number) => void;
    selectedProductId: number | undefined;
};

export function useProductPagesNavigation(
    defaultInit?: URLSearchParamsInit,
    productPagePath?: string,
): UseProductPagesNavigationReturn {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(defaultInit);
    const links = useLinks();
    const selectedProductId = searchParams.get(SELECTED_PRODUCT_ID_URL_PARAM_KEY)
        ? parseInt(searchParams.get(SELECTED_PRODUCT_ID_URL_PARAM_KEY) ?? '', 10)
        : undefined;

    const selectedProductType = searchParams.get(SELECTED_PRODUCT_TYPE_URL_PARAM_KEY) ?? undefined;

    const updateUrlAndRedirectForProduct = useCallback(
        (type: string, id: number) => {
            if (productPagePath) {
                const newSearchParams = new URLSearchParams();
                newSearchParams.set(SELECTED_PRODUCT_TYPE_URL_PARAM_KEY, type ?? '');
                newSearchParams.set(SELECTED_PRODUCT_ID_URL_PARAM_KEY, id?.toString() ?? '');
                const url = new URL(window.location.href);
                url.pathname = productPagePath;
                url.search = newSearchParams.toString();
                window.location.href = url.toString();
            } else {
                searchParams.set(SELECTED_PRODUCT_TYPE_URL_PARAM_KEY, type ?? '');
                searchParams.set(SELECTED_PRODUCT_ID_URL_PARAM_KEY, id?.toString() ?? '');
                setSearchParams(searchParams, { state: 'canGoBack' });
            }
        },
        [productPagePath, searchParams, setSearchParams],
    );

    const handleClose = useCallback(() => {
        if (state === 'canGoBack') {
            navigate(-1);
        } else {
            searchParams.delete(SELECTED_PRODUCT_ID_URL_PARAM_KEY);
            if (searchParams.get(SELECTED_CATALOG_DISPLAY_TYPE_PARAM_KEY) !== 'single') {
                searchParams.delete(SELECTED_PRODUCT_TYPE_URL_PARAM_KEY);
            }
            searchParams.delete('tab');
            setSearchParams(searchParams, { replace: true });
        }
    }, [searchParams, setSearchParams, state, navigate]);

    const goToProductSignals = useCallback(
        (productId: number) => {
            navigate(links.creatorProductsSignals(productId), {
                state: 'canGoBack',
            });
        },
        [links, navigate],
    );

    return {
        selectedProductId,
        selectedProductType,
        updateUrlAndRedirectForProduct,
        handleClose,
        goToProductSignals,
    };
}
