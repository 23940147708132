import { Theme } from './types';

export const lightTheme: Theme = {
    name: 'light',
    bg: {
        primary: '#FFFFFF',
        secondary: '#F4F5F7',
        tertiary: '#EBEFF5',
        dialogOverlay: 'rgba(18, 20, 23, 0.64)',
        popover: 'rgba(255, 255, 255, 1)',
        negative: '#FCE3E3',
        accent: '#DAF5DE',
    },
    label: {
        accent: '#0AA461',
        accentHigh: '#099558',
        accentHighest: '#088750',
        primary: '#272F40',
        secondary: '#7486AA',
        secondaryHigh: '#425276',
        tertiary: '#919CB6',
        tertiaryActive: '#667999',
        placeholder: '#A8B8D2',
        inactive: 'rgba(93, 114, 152, 0.4)',
        positive: '#1B825E',
        negative: '#E83F41',
        negativeHigh: '#D82C30',
        negativeHighest: '#C82424',
        warning: '#D49800',
        inverse: '#FFFFFF',
    },
    fillIn: {
        primary: {
            highest: '#089659',
            high: '#0BAD67',
            mid: '#0EBE72',
            low: '#0CDF83',
            muted: '#EBEEEC',
            overlay8: 'rgba(23, 211, 120, 0.08)',
            overlay16: 'rgba(23, 211, 120, 0.16)',
            overlay24: 'rgba(23, 211, 120, 0.24)',
            overlay32: 'rgba(23, 211, 120, 0.32)',
        },
        secondary: {
            highest: '#272F40',
            high: '#5F7095',
            mid: '#8896AF',
            low: '#B9C6DF',
            muted: '#D9E2F7',
            overlay8: 'rgba(167, 189, 241, 0.08)',
            overlay16: 'rgba(167, 189, 241, 0.16)',
            overlay24: 'rgba(167, 189, 241, 0.24)',
            overlay32: 'rgba(167, 189, 241, 0.32)',
        },
        positive: {
            highest: '#2B6E5A',
            high: '#1B825E',
            mid: '#1C996D',
            low: '#44BD90',
            muted: '#82D9B5',
            overlay8: 'rgba(68, 189, 144, 0.08)',
            overlay16: 'rgba(68, 189, 144, 0.16)',
            overlay24: 'rgba(68, 189, 144, 0.24)',
            overlay32: 'rgba(68, 189, 144, 0.32)',
        },
        negative: {
            highest: '#D63639',
            high: '#E83F41',
            mid: '#FA4D50',
            low: '#FF7A7F',
            muted: '#FFB0B1',
            overlay8: 'rgba(255, 122, 127, 0.08)',
            overlay16: 'rgba(255, 122, 127, 0.16)',
            overlay24: 'rgba(255, 122, 127, 0.24)',
            overlay32: 'rgba(255, 122, 127, 0.32)',
        },
        note: {
            highest: '#E5A605',
            high: '#F2B818',
            mid: '#FFCB30',
            low: '#FFD549',
            muted: '#FFE591',
            overlay8: 'rgba(255, 198, 28, 0.08)',
            overlay16: 'rgba(255, 198, 28, 0.16)',
            overlay24: 'rgba(255, 198, 28, 0.24)',
            overlay32: 'rgba(255, 198, 28, 0.32)',
        },
    },
    divider: {
        primary: '#E6EFFF',
        secondary: '#F3F7FF',
        altPrimary: '#A4AFC7',
        altSecondary: '#D7E1F5',
    },
    staticColors: {
        white: '#FFFFFF',
        whiteAlpha72: 'rgba(255, 255, 255, 0.72)',
        whiteAlpha56: 'rgba(255, 255, 255, 0.56)',
        whiteAlpha24: 'rgba(255, 255, 255, 0.24)',
        whiteAlpha16: 'rgba(255, 255, 255, 0.16)',
        whiteAlpha8: 'rgba(255, 255, 255, 0.08)',
        whiteAlpha4: 'rgba(255, 255, 255, 0.04)',
        secondary: '#4D566B',
        positive: '#4BBF93',
        negative: '#FF6B6E',
        note: '#FFCB30',
        // orange: '#E7721E',
        // violet: '#D25DFF',
        // lavender: '#6C83FF',
        // yellow: '#FFD40E',
    },
    risk: {
        gradient:
            'linear-gradient(269.67deg, #E83F41 0.64%, #E8863F 17.53%, rgba(170, 233, 38, 0.64) 50.36%, #0EBE72 102.45%)',
        levelRisk1: '#1DC271',
        levelRisk2: '#46CD6D',
        levelRisk3: '#61D56C',
        levelRisk4: '#A3E76E',
        levelRisk5: '#B3D035',
        levelRisk6: '#D7B450',
        levelRisk7: '#E87D40',
        levelRisk8: '#E85E41',
        levelRisk9: '#E85341',
        levelRisk10: '#E83F41',
    },
    // // use for box-shadow
    focusOutline: {
        accent: '0px 0px 0px 2px rgba(52, 232, 124, 0.24)',
        positive: '0px 0px 0px 2px rgba(68, 189, 144, 0.16)',
        negative: '0px 0px 0px 2px rgba(237, 31, 36, 0.16)',
    },
    shadow: {
        button: '0px 1px 3px rgba(39, 47, 64, 0.18)',
        accent: '0px 3px 6px -2px rgba(21, 188, 88, 0.3), 0px 8px 12px rgba(21, 188, 88, 0.1), 0px 9px 16px rgba(56, 124, 255, 0.11)',
        popover: '0px 8px 16px rgba(95, 112, 149, 0.32), 0px 16px 40px rgba(95, 112, 149, 0.12)',
        dialog: '0px 12px 16px rgba(112, 128, 164, 0.1), 0px 24px 56px rgba(95, 112, 149, 0.38)',
        cardEasy: '0px 6px 40px rgba(82, 96, 122, 0.12)',
        cardHeavy: '0px 1px 4px rgba(95, 112, 149, 0.12), 0px 6px 16px rgba(95, 112, 149, 0.2)',
        headerWeb: '0px 1px 4px rgba(8, 55, 155, 0.04), 0px 6px 16px rgba(8, 55, 155, 0.04)',
    },
};
