import React, { ChangeEvent, forwardRef, memo, useCallback } from 'react';

import { ErrorMessage, InlineLabel, LeftGroup, Wrapper, TextareaAutosizeWrapper } from 'components/text-input/styled';
import { RightGroup } from 'components/text-input/RightGroup';

import { InputStyled } from './styled';
import { TextAreaProps } from './types';

export const TextArea = memo(
    forwardRef<HTMLTextAreaElement, TextAreaProps>(
        (
            {
                kind = 'fill',
                size = 'large',
                label,
                placeholder,
                rightLabel,
                icon: Icon,
                readonly = false,
                disabled = false,
                error,
                errorHighlight = false,
                value,
                onChange,
                onFocus,
                onBlur,
                onLabelClick,
                onIconClick,
                rows = 1,
                infiniteHeight = false,
                className,
                wrapperRef,
                iconButtonTabIndex,
            },
            ref,
        ) => {
            const handleChange = useCallback(
                (e: ChangeEvent<HTMLTextAreaElement>) => {
                    onChange(e.currentTarget.value);
                },
                [onChange],
            );

            const textarea = (
                <>
                    {label ? <InlineLabel>{label}</InlineLabel> : null}
                    <InputStyled
                        ref={ref}
                        as="textarea"
                        rows={rows}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                        onBlur={onBlur}
                        disabled={disabled || readonly}
                        onFocus={onFocus}
                    />
                </>
            );

            return (
                <div>
                    <Wrapper
                        kind={kind}
                        size={size}
                        error={!!error || errorHighlight}
                        disabled={disabled}
                        hasLabel={Boolean(label)}
                        hasRows={rows > 1}
                        hasText={value.length > 0}
                        className={className}
                        ref={wrapperRef}
                    >
                        <LeftGroup>
                            {rows === 1 || infiniteHeight ? (
                                <TextareaAutosizeWrapper data-replicated-value={value.length > 0 ? `${value} ` : ''}>
                                    {textarea}
                                </TextareaAutosizeWrapper>
                            ) : (
                                textarea
                            )}
                        </LeftGroup>
                        <RightGroup
                            readonly={readonly}
                            rightLabel={rightLabel}
                            disabled={disabled}
                            onLabelClick={onLabelClick}
                            icon={Icon}
                            onIconClick={onIconClick}
                            iconButtonTabIndex={iconButtonTabIndex}
                        />
                    </Wrapper>
                    {error && !disabled && !readonly ? <ErrorMessage>{error}</ErrorMessage> : null}
                </div>
            );
        },
    ),
);

export type { TextAreaProps } from './types';
