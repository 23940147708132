import { connect } from 'react-redux';

import { DispatchType } from 'types';

import { GlobalStateHeaderNotifications } from '../types';
import { setIsOpened } from '../actions';
import { HeaderNotificationsButtonContextActionsConnector as ButtonContextActionsConnector } from '../connectors/ButtonContextActionsConnector';

const mapStateToProps = (state: GlobalStateHeaderNotifications) => ({
    unreadCount: state?.widgets?.notifications?.unreadCount,
    isOpened: state?.widgets?.notifications?.isOpened,
});

const mapDispatchToProps = {
    setIsOpened,
};

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;
export type MapDispatchToPropsType = DispatchType<typeof mapDispatchToProps>;

export const HeaderNotificationsButtonContextActionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonContextActionsConnector);
