import { useMemo } from 'react';

import { FinamProductItem } from 'types/finam-product';
import { ProductItemPriceData } from 'types/product';
import { useDictionary } from 'hooks/useDictionary';
import { useFormattedISO } from 'hooks/useFormattedISO';

export const useArchivedAtParams = (item: FinamProductItem | null) => {
    const { archivedAt } = item || {};
    const dic = useDictionary();
    const format = useFormattedISO();
    return useMemo<
        | {
              price: ProductItemPriceData;
              formattedPricePeriod: string;
          }
        | Record<never, never>
    >(() => {
        if (!archivedAt) return {};
        return {
            price: {
                word: dic.word('wt__product_default_card__status_archived'),
            },
            formattedPricePeriod: format(archivedAt),
        };
    }, [archivedAt, dic, format]);
};
