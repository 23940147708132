import styled from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

import { mediaQuery, reduceBreakpoints } from 'app/styled';

import { Z_INDEX_DIALOG, Z_INDEX_OVERLAY } from '../constants';

import { Props as PopupWindow3 } from './types';

export const Overlay = styled.div<{ zIndex?: number }>`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: ${({ zIndex }) => zIndex ?? Z_INDEX_OVERLAY};
    overflow: auto;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
`;

export const PopupWindow3Wrapper = styled.div<{
    isVisible?: boolean;
}>`
    ${ifNotProp('isVisible', 'display: none;')}
`;

const DIALOG_INDENT = 64;
const WINDOW_TOP_INDENT_MOBILE_FULLSCREEN = 24;
export const DialogWrapper = styled.div<{
    isMobileFullscreen: boolean;
    zIndex?: number;
}>`
    padding: ${DIALOG_INDENT}px;
    z-index: ${({ zIndex }) => (zIndex === undefined ? Z_INDEX_DIALOG : zIndex + 1)};
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(100% - ${DIALOG_INDENT * 2}px);
    height: calc(100% - ${DIALOG_INDENT * 2}px);
    top: 0;
    left: 0;

    ${mediaQuery.lt768} {
        top: unset;
        bottom: 0;
        padding: 0;
        width: 100%;
        ${ifProp(
            'isMobileFullscreen',
            `
            height: 100%;
        `,
            `
            height: 100%;
            max-height: calc(var(--100vh) - ${WINDOW_TOP_INDENT_MOBILE_FULLSCREEN}px);
        `,
        )}
    }
`;

const dialogWidthSizeMap = ({ width }: Pick<PopupWindow3, 'width'>) => {
    if (typeof width === 'number') {
        return `width: ${width}px;`;
    }

    if (width) {
        return reduceBreakpoints(width, (_width) => `width: ${_width}px;`);
    }

    return `
        max-width: 640px;
        min-width: 384px;
     `;
};

export const Dialog = styled.div<Pick<PopupWindow3, 'isMobileFullscreen' | 'width'>>`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.bg.popover};
    z-index: 10001;
    position: relative;
    border-radius: 12px;
    height: auto;
    max-height: 100%;
    min-height: 240px;
    overflow: hidden;
    ${dialogWidthSizeMap};

    ${mediaQuery.lt768} {
        width: 100%;
        min-width: 0;
        align-self: flex-end;
        max-width: unset;

        ${ifProp(
            'isMobileFullscreen',
            `
            border-radius: 0;
            height: 100%;
        `,
            `
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `,
        )}
    }
`;
