/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComonRecordExporter } from '../models/ActivityService/ComonRecordExporter';
import type { RecommendedUsersContainerExporter } from '../models/ActivityService/RecommendedUsersContainerExporter';
import type { RecordExporter } from '../models/shared/RecordExporter';
import type { UserExporter } from '../models/shared/UserExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActivityService {
    /**
     * Персональная лента пользователя
     * @returns any Список записей
     * @throws ApiError
     */
    public static getFeed({
        cursor,
        limit,
        lcid,
        orderBy = 'rank',
        existRecordIds,
        pinned,
    }: {
        /**
         * Курсор берется из предыдущего запроса из поля nextCursor
         */
        cursor?: string;
        /**
         * Количество загружаемых записей. Допустимо значение от 1 до 100. По-умолчанию 10
         */
        limit?: number;
        /**
         * Язык записей. Применяется только для не аутентифицированных пользователей, иначе всегда берется локаль пользователя
         */
        lcid?: 'ru' | 'en';
        /**
         * Сортировка постов ленты. rank - по ранку (по умолчанию), date - по дате добавления. При сортировке по дате, рекомендации отключены.
         */
        orderBy?: 'rank' | 'date';
        /**
         * Cписок id постов через запятую, которые уже были подмешаны в ленту и подлежат исключению
         */
        existRecordIds?: string;
        /**
         * Закрепить пост с указанным id в начале ленты
         */
        pinned?: number;
    }): CancelablePromise<{
        /**
         * Курсор передается в слеующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor?: string | null;
        items?: Array<ComonRecordExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/feed/',
            query: {
                cursor: cursor,
                limit: limit,
                lcid: lcid,
                orderBy: orderBy,
                existRecordIds: existRecordIds,
                pinned: pinned,
            },
        });
    }

    /**
     * Лента социальной активности пользователя (лайки, комментарии)
     * @returns any Список записей
     * @throws ApiError
     */
    public static getSocialActivityList({
        userId,
        types,
        cursor,
        limit,
    }: {
        userId: string;
        /**
         * Типы активностей
         */
        types?: Array<string>;
        /**
         * Курсор берется из предыдущего запроса из поля nextCursor
         */
        cursor?: string;
        /**
         * Количество загружаемых записей. Допустимо значение от 1 до 100. По-умолчанию 10
         */
        limit?: number;
    }): CancelablePromise<{
        items: Array<RecordExporter>;
        /**
         * Курсор передается в слеующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor: string | null;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/social_activity/{userId}/list/',
            path: {
                userId: userId,
            },
            query: {
                types: types,
                cursor: cursor,
                limit: limit,
            },
        });
    }

    /**
     * Получить таймлайн записей блога объекта
     * @returns any Успешное получение записей таймлайна
     * @throws ApiError
     */
    public static getTimeline({
        objectType = 5,
        objectId = 903387249,
        cursor,
        reverse = false,
        limit = 10,
        pinned,
        tagIds,
    }: {
        /**
         *
         * Тип объекта (
         * 1 - Пользователь,
         * 5 - Инструмент,
         * 20 - Тег,
         * 37 - Сообщество,
         * )
         *
         */
        objectType?: 1 | 5 | 20 | 37;
        /**
         * Идентификатор объекта записи в таймлайне
         */
        objectId?: number;
        /**
         * Курсор пагинации (Дата создания записи в ленте)
         */
        cursor?: string;
        /**
         * Сортировка таймлайна от старых к новым записям
         */
        reverse?: boolean;
        /**
         * Количество записей в запросе
         */
        limit?: number;
        /**
         * Закрепить пост с указанным id в начале ленты
         */
        pinned?: number;
        /**
         * ИД тегов через запятую, по которым будет фильтроваться таймлайн
         */
        tagIds?: string;
    }): CancelablePromise<{
        /**
         * Курсор передается в слеующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor?: string | null;
        items?: Array<RecordExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timeline/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            query: {
                cursor: cursor,
                reverse: reverse,
                limit: limit,
                pinned: pinned,
                tagIds: tagIds,
            },
        });
    }

    /**
     * Персональная лента пользователя
     * Аналогично v1 (В отличии от v1, лента формируется в текущем сервисе)
     * @returns any Успешное получение записей ленты
     * @throws ApiError
     */
    public static getApiVFeed({
        cursor,
        limit = 10,
        lcid,
        orderBy = 'rank',
        existRecordIds,
        pinned,
    }: {
        /**
         * Курсор берется из предыдущего запроса из поля nextCursor
         */
        cursor?: string;
        /**
         * Количество загружаемых записей. Допустимо значение от 1 до 100. По-умолчанию 10
         */
        limit?: number;
        /**
         * Язык записей. Применяется только для не аутентифицированных пользователей, иначе всегда берется локаль пользователя
         */
        lcid?: 'ru' | 'en';
        /**
         * Сортировка постов ленты. rank - по ранку (по умолчанию), date - по дате добавления. При сортировке по дате, рекомендации отключены.
         */
        orderBy?: 'rank' | 'date';
        /**
         * Cписок id постов через запятую, которые уже были подмешаны в ленту и подлежат исключению
         */
        existRecordIds?: string;
        /**
         * Закрепить пост с указанным id в начале ленты
         */
        pinned?: number;
    }): CancelablePromise<{
        /**
         * Курсор передается в слеующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor?: string | null;
        items?: Array<RecordExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/feed/',
            query: {
                cursor: cursor,
                limit: limit,
                lcid: lcid,
                orderBy: orderBy,
                existRecordIds: existRecordIds,
                pinned: pinned,
            },
        });
    }

    /**
     * Получить таймлайн записей блога объекта
     * Появилась новая активность 'Рекомендованные пользователи' c type = 25. Активности с этим типом отдаются через каждые 10 постов
     * @returns any Успешное получение записей таймлайна
     * @throws ApiError
     */
    public static getApiVTimeline({
        objectType = 5,
        objectId = 903387249,
        cursor,
        reverse = false,
        limit = 10,
        pinned,
        tagIds,
    }: {
        /**
         *
         * Тип объекта (
         * 1 - Пользователь,
         * 5 - Инструмент,
         * 20 - Тег,
         * 37 - Сообщество,
         * )
         *
         */
        objectType?: 1 | 5 | 20 | 37;
        /**
         * Идентификатор объекта записи в таймлайне
         */
        objectId?: number;
        /**
         * Курсор пагинации (Дата создания записи в ленте)
         */
        cursor?: string;
        /**
         * Сортировка таймлайна от старых к новым записям
         */
        reverse?: boolean;
        /**
         * Количество записей в запросе
         */
        limit?: number;
        /**
         * Закрепить пост с указанным id в начале ленты
         */
        pinned?: number;
        /**
         * ИД тегов через запятую, по которым будет фильтроваться таймлайн
         */
        tagIds?: string;
    }): CancelablePromise<{
        /**
         * Курсор передается в слеующий запрос в параметр cursor для получения следующей пачки записей
         */
        nextCursor?: string | null;
        items?: Array<RecordExporter | RecommendedUsersContainerExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/timeline/{objectType}/{objectId}/',
            path: {
                objectType: objectType,
                objectId: objectId,
            },
            query: {
                cursor: cursor,
                reverse: reverse,
                limit: limit,
                pinned: pinned,
                tagIds: tagIds,
            },
        });
    }

    /**
     * Получить список сообществ
     * @returns any Успешное получение списка сообществ
     * @throws ApiError
     */
    public static getCommunities(): CancelablePromise<{
        /**
         * Список сообществ
         */
        communities?: Array<UserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/communities/',
        });
    }
}
