import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';

import { MenuData, MenuItemData } from '../../interfaces';
import { MenuItem } from '../menu-item';
import { Header } from '../header';

import { ContextMenuFooterWrapper, ContextMenuHeaderWrapper, ContextMenuList } from './styled';

interface ContextMenuContentProps {
    modifiedItems?: Record<string, Partial<MenuItemData>>;
    menuData: MenuData;
    showBackButton?: boolean;
    onItemClick: (name: string, item: MenuItemData) => void;
    onBack?: () => void;
    onClose?: () => void;
    isMobile: boolean;
}

export const ContextMenuContent: FC<ContextMenuContentProps> = memo(
    ({ menuData, onItemClick, onBack, showBackButton, modifiedItems, onClose, isMobile }) => {
        const {
            name,
            headerContent,
            content,
            showCloseButton = false,
            items,
            footerContent,
            showFilter = false,
            filterNotFound: NotFound,
            sortByFields,
            selectedItemIcon,
            mobileOnlyHeader = false,
            minHeight,
            maxHeight,
        } = menuData;

        const handleItemClick = useCallback(
            (item: MenuItemData) => {
                onItemClick(name, item);
            },
            [name, onItemClick],
        );

        const [value, setValue] = useState('');

        const filter = useMemo(
            () => ({
                searchString: value,
                onChange: setValue,
            }),
            [value],
        );

        useEffect(() => {
            setValue('');
        }, [menuData]);

        const displayItems = useMemo(() => {
            if (content || !items || items?.length < 1) {
                return null;
            }

            const mergedItems = items.map((item) => {
                const modifiedItem = modifiedItems?.[item.id] ?? {};
                const mergedItem = {
                    ...item,
                    ...modifiedItem,
                };
                return {
                    ...mergedItem,
                    icon: mergedItem.selected ? selectedItemIcon : mergedItem.icon,
                    text: typeof mergedItem.text === 'function' ? mergedItem.text(value) : mergedItem.text,
                };
            });

            const filteredItems = showFilter
                ? mergedItems.filter(({ text }) => text !== null && text.toLowerCase().startsWith(value.toLowerCase()))
                : mergedItems;

            return sortByFields ? sortBy(filteredItems, sortByFields) : filteredItems;
        }, [items, modifiedItems, selectedItemIcon, showFilter, sortByFields, value, content]);

        const renderContent = () => {
            if (content) {
                return content;
            }

            if (displayItems !== null && displayItems?.length > 0) {
                return displayItems?.map((item) => {
                    const { id, mobile = false } = item;
                    return (
                        <MenuItem
                            key={id}
                            item={item}
                            onClick={handleItemClick}
                            mobile={mobile}
                            menuName={name}
                            isMobile={isMobile}
                        />
                    );
                });
            }
            return NotFound ? <NotFound searchString={value} /> : null;
        };

        return (
            <>
                {headerContent || showFilter ? (
                    <ContextMenuHeaderWrapper mobileOnly={mobileOnlyHeader}>
                        <Header
                            showBackButton={showBackButton}
                            onBack={onBack}
                            headerContent={headerContent}
                            showCloseButton={showCloseButton}
                            onClose={onClose}
                            filter={showFilter ? filter : undefined}
                            isMobile={isMobile}
                        />
                    </ContextMenuHeaderWrapper>
                ) : null}
                <ContextMenuList $minHeight={minHeight} $maxHeight={maxHeight}>
                    {renderContent()}
                </ContextMenuList>
                {footerContent ? <ContextMenuFooterWrapper>{footerContent}</ContextMenuFooterWrapper> : null}
            </>
        );
    },
);
