import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import cc from 'classcat';

import { ImageBlockProps } from '../types';
import * as Styled from '../styled/ImageBlock.styled';

import { Image } from './Image';

export const ImageBlock = forwardRef(
    (props: ImageBlockProps, forwardedRef: ForwardedRef<HTMLDivElement | null | undefined>) => {
        const {
            className: classNameProp,
            borderRadius,
            height,
            width,
            imageRef,
            onClick,
            objectFit = 'cover',
            size,
            isDisableDefaultBg,
            ...imgProps
        } = props;

        const { src } = imgProps;

        const ref = useRef<HTMLDivElement | null>(null);
        const imgRef = useRef<HTMLImageElement | null>(null);

        const className = cc([classNameProp, 'autotest__image-block']);
        const [isSuccessImage, setIsSuccessImage] = useState(false);

        useImperativeHandle<HTMLDivElement | null | undefined, HTMLDivElement | null>(
            forwardedRef,
            () => ref?.current,
            [],
        );

        return (
            <Styled.ImageBlock
                ref={ref}
                className={className}
                height={height}
                width={width}
                onClick={onClick}
                borderRadius={borderRadius}
                objectFit={objectFit}
                size={size}
                isSuccessImage={isSuccessImage}
                isDisableDefaultBg={isDisableDefaultBg}
            >
                {src ? (
                    <Image
                        ref={(el: HTMLImageElement) => {
                            if (imageRef) {
                                imageRef.current = el;
                            }
                            imgRef.current = el;
                        }}
                        {...imgProps}
                        onLoad={(e) => {
                            setIsSuccessImage(true);
                            imgProps?.onLoad?.(e);
                        }}
                        onError={(e) => {
                            setIsSuccessImage(false);
                            imgProps?.onError?.(e);
                        }}
                    />
                ) : null}
            </Styled.ImageBlock>
        );
    },
);
