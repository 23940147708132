import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { mediaQuery } from 'app/styled';
import { WtPopup3 } from 'components/wt-popup3';
import { Dialog } from 'components/wt-popup3/popup-window/styled';

export const TabsWrapper = styled.div`
    padding-top: 16px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.bg.primary};
`;

export const ListWrapper = styled.div<{ isActive: boolean }>`
    display: ${ifProp('isActive', 'block', 'none')};
`;

export const StyledScrollableRows = styled.div<{ hasData: boolean }>`
    margin-top: -8px;
    display: flex;
    flex-direction: column;
    ${({ hasData }) => (hasData ? '' : 'height: 100%;')}
`;

export const TabsContentWrapper = styled.div`
    flex: 1;
`;

export const TabContent = styled.div`
    height: 100%;
`;

export const WtPopup3Styled = styled(WtPopup3)`
    & ${Dialog} {
        height: 668px;
        ${mediaQuery.lt768} {
            height: 100%;
        }
    }
`;
