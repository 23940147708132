import { LevelRiskNumberValue, LevelRiskTextValue } from './types';

export type LevelRiskNumberValueToTextMap = {
    [key in LevelRiskNumberValue]: LevelRiskTextValue;
};
export const RISK_LEVEL_NUMBER_VALUE_MAP: LevelRiskNumberValueToTextMap = {
    1: 'Conservative',
    2: 'Conservatively balanced',
    3: 'Balanced',
    4: 'Balanced-moderate',
    5: 'Moderate',
    6: 'Moderately active',
    7: 'Active',
    8: 'Speculative',
    9: 'Aggressive',
    10: 'Active-aggressive',
};

export const RISK_LEVEL_NUMBER_VALUES: LevelRiskNumberValue[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MIN_RISK_LEVEL_NUMBER_VALUE: LevelRiskNumberValue = Math.min(
    ...RISK_LEVEL_NUMBER_VALUES,
) as LevelRiskNumberValue;
export const MAX_RISK_LEVEL_NUMBER_VALUE: LevelRiskNumberValue = Math.max(
    ...RISK_LEVEL_NUMBER_VALUES,
) as LevelRiskNumberValue;
