import React from 'react';

import { IconComponent, IconSvg } from 'components/icons2';

export const SkeletonIcon: IconComponent = () => (
    <IconSvg width={266} height={157} viewBox="0 0 266 157">
        <rect y="4.002" width="234" height="16" rx="2" />
        <rect y="30.002" width="202" height="8" rx="2" />
        <rect y="56.002" width="266" height="8" rx="2" />
        <rect y="72.002" width="266" height="8" rx="2" />
        <rect y="88.002" width="266" height="8" rx="2" />
        <rect y="104.002" width="266" height="8" rx="2" />
        <rect y="134.002" width="178" height="16" rx="2" />
    </IconSvg>
);
