/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { Marketplace_Web_Private_V1_Products_ChangeProductPriceWebRequest } from '../models/ProductsService/Marketplace_Web_Private_V1_Products_ChangeProductPriceWebRequest';
import type { PagedWebResponse_AuthorProductDto_ILeanProductDto } from '../models/ProductsService/PagedWebResponse_AuthorProductDto_ILeanProductDto';
import type { PagedWebResponse_GetProductsReviewsDto } from '../models/ProductsService/PagedWebResponse_GetProductsReviewsDto';
import type { PagedWebResponse_ILeanProductDto } from '../models/shared/PagedWebResponse_ILeanProductDto';
import type { PagedWebResponse_IRawProductDto } from '../models/ProductsService/PagedWebResponse_IRawProductDto';
import type { WebResponse_AuthorProductDto_IRichProductDto } from '../models/ProductsService/WebResponse_AuthorProductDto_IRichProductDto';
import type { WebResponse_GetRatingCountersWebResponse } from '../models/ProductsService/WebResponse_GetRatingCountersWebResponse';
import type { WebResponse_IRichProductDto } from '../models/shared/WebResponse_IRichProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {
    /**
     * Возвращает OK если данный продукт не архивный и доступен для отображения
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static getProductActive({
        id,
    }: {
        /**
         * Идетификатор продукта
         */
        id: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/products/{id}/active',
            path: {
                id: id,
            },
        });
    }

    /**
     * Получить продукты по запросу
     * @returns PagedWebResponse_IRawProductDto Success
     * @throws ApiError
     */
    public static getProducts({
        ids,
        externalIds,
        productType,
        isArchived,
        isVisible,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Фильтр по идентификаторам продуктов
         */
        ids?: Array<number> | null;
        /**
         * Фильтр по идентификаторам продуктов во внешней системе,
         * ProductType должен быть указан
         */
        externalIds?: Array<string> | null;
        /**
         * Фильтр по типу продукта
         */
        productType?: string | null;
        /**
         * Фильтр по продуктам в архиве, если не указан то выдаем все
         */
        isArchived?: boolean | null;
        /**
         * Фильтр по продуктам по продуктам, доступным пользователю, если не указан, то выдаем все
         */
        isVisible?: boolean | null;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_IRawProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/products',
            query: {
                Ids: ids,
                ExternalIds: externalIds,
                ProductType: productType,
                IsArchived: isArchived,
                IsVisible: isVisible,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Удалить продукт
     * @returns WebResponse_IRichProductDto Success
     * @throws ApiError
     */
    public static deleteProduct({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<WebResponse_IRichProductDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/private/api/v1/products/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * Восстановить продукт
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static updateProductRestore({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/products/restore/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * Сменить цену продукта
     * @returns WebResponse_IRichProductDto Success
     * @throws ApiError
     */
    public static updateProductChangePrice({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
        /**
         * Новая цена
         */
        requestBody?: Marketplace_Web_Private_V1_Products_ChangeProductPriceWebRequest;
    }): CancelablePromise<WebResponse_IRichProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/products/change-price/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Получить собственные продукты автора
     * @returns PagedWebResponse_AuthorProductDto_ILeanProductDto Success
     * @throws ApiError
     */
    public static getMyProductsV2({
        productTypes,
        productModerationStatuses,
        archived,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Список типов продуктов
         */
        productTypes?: Array<string> | null;
        /**
         * Список статусов модерации
         */
        productModerationStatuses?: Array<string> | null;
        /**
         * Архивный ли продукт
         */
        archived?: boolean | null;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_AuthorProductDto_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/my/products',
            query: {
                ProductTypes: productTypes,
                ProductModerationStatuses: productModerationStatuses,
                Archived: archived,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Получить собственный продукт автора
     * @returns WebResponse_AuthorProductDto_IRichProductDto Success
     * @throws ApiError
     */
    public static getApiVMyProduct({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<WebResponse_AuthorProductDto_IRichProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/my/products/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * Получить собственные продукты автора
     * @returns PagedWebResponse_ILeanProductDto Success
     * @throws ApiError
     */
    public static getMyProducts({
        productTypes,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Список типов продуктов
         */
        productTypes?: Array<string> | null;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products',
            query: {
                ProductTypes: productTypes,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Получить отзывы на продукт
     * @returns PagedWebResponse_GetProductsReviewsDto Success
     * @throws ApiError
     */
    public static getApiVMyProductReviews({
        productId,
        showOnlySubscribers,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * ИД продукта
         */
        productId: number;
        /**
         * Выводить отзывы только подписчиков данного продукта
         */
        showOnlySubscribers?: boolean;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_GetProductsReviewsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products/{productId}/reviews',
            path: {
                productId: productId,
            },
            query: {
                ShowOnlySubscribers: showOnlySubscribers,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Получить счетчики отзывов на продукт
     * @returns WebResponse_GetRatingCountersWebResponse Success
     * @throws ApiError
     */
    public static getApiVMyProductRatingsCounters({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
    }): CancelablePromise<WebResponse_GetRatingCountersWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/products/{productId}/ratings/counters',
            path: {
                productId: productId,
            },
        });
    }
}
