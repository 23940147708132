import React, { ForwardedRef, forwardRef, useImperativeHandle, useLayoutEffect, useState } from 'react';
import cc from 'classcat';

import useImageLoaded from 'hooks/useImageLoaded';

import { ImageProps } from '../types';
import * as Styled from '../styled/Image.styled';
import { ALT_IMAGE_DEFAULT } from '../constants';

export const Image = forwardRef((props: ImageProps, forwardedRef: ForwardedRef<HTMLImageElement | null>) => {
    const {
        className: classNameProp,
        src,
        alt = ALT_IMAGE_DEFAULT,
        onError: onErrorImg,
        onLoad: onLoadImg,
        width,
        height,
        isUserDragActive,
        isActiveManifestation = true,
        ...restProps
    } = props;

    const { ref: imgRef, isLoaded, hasError, onLoad, onError } = useImageLoaded();

    const [isDocumentComplete, setIsDocumentComplete] = useState(document.readyState === 'complete');
    const [isErrorImg, setIsErrorImg] = useState(false);

    const className = cc([
        classNameProp,
        'autotest__image',
        {
            'autotest__image-success': isLoaded && !hasError,
            'autotest__image-error': hasError,
        },
    ]);

    useImperativeHandle<HTMLImageElement | null, HTMLImageElement | null>(
        forwardedRef,
        () => {
            if (imgRef) {
                return imgRef?.current;
            }
            return null;
        },
        [],
    );

    useLayoutEffect(() => {
        if (!isDocumentComplete) {
            const documentCompleteHandler = () => setIsDocumentComplete(true);

            window.addEventListener('load', documentCompleteHandler);
            return () => window.removeEventListener('load', documentCompleteHandler);
        }
        return undefined;
    }, [isDocumentComplete]);

    // рендерим изображения только после того когда страница будет полностью готова
    // если нужно чтобы была обертка до загрузки с параметрами высоты и ширины, то используйте ImageBlock
    if (!isDocumentComplete || !src) return null;

    return (
        <Styled.Image
            className={className}
            width={width}
            height={height}
            ref={imgRef}
            src={src}
            alt={alt}
            onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                onLoad(e);
                onLoadImg?.(e);
            }}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                onError(e);
                onErrorImg?.(e);
                setIsErrorImg(true);
            }}
            isErrorImg={isErrorImg}
            loaded={!hasError && isLoaded}
            isUserDragActive={isUserDragActive}
            isActiveManifestation={isActiveManifestation}
            {...restProps}
        />
    );
});
