import { applyPatches, produce } from 'immer';

import { CacheData } from '../types';

import { memoizationCache } from './memoization';

export function cacheRevert({ cache, operationId }: { cache: CacheData; operationId: string }) {
    const patches = cache.patches[operationId];
    if (patches) {
        const revertedState = applyPatches(cache, patches);
        patches.forEach(({ path }) => {
            memoizationCache.invalidate(`${path[1]}:${path[2]}`);
        });
        return produce(revertedState, (draft) => {
            delete draft.patches[operationId];
        });
    }
    return cache;
}
