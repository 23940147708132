import { useMemo } from 'react';

import { useDictionary } from 'hooks/useDictionary';

import { HeaderNotificationsTextObjType } from '../types';

export function useGetDicwordTextObj(): HeaderNotificationsTextObjType {
    const dic = useDictionary();

    return useMemo(
        () => ({
            title: dic.word('wt_menu__widget__header_notifications__title_text'),
            emptyText: dic.word('wt_menu__widget__header_notifications__empty_msg'),
            errorContent: {
                text: dic.word('wt_menu__widget__header_notifications__error__text'),
                actionBtnText: dic.word('wt_menu__widget__header_notifications__error__action_btn_text'),
            },
            settingsBtnText: dic.word('wt_menu__widget__header_notifications__settings_btn_text'),
            groupTitles: {
                unread: dic.word('wt_menu__widget__header_notifications__group_title_unread'),
                read: dic.word('wt_menu__widget__header_notifications__group_title_read'),
            },
            notification: {
                title: {
                    blogpostComment: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__blogpost_comment'),
                    },
                    blogpostLike: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__blogpost_like'),
                    },
                    productComment: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__product_comment'),
                    },
                    commentAnswer: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__comment_answer'),
                    },
                    commentLike: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__comment_like'),
                    },
                    mentionInComment: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__mention_in_comment'),
                    },
                    productAdd: {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__product_add'),
                    },
                    productModerateFall: {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__product_moderate_fall'),
                    },
                    productDeleteFoul: {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__product_delete_foul'),
                    },
                    userSubscription: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__user_subscription'),
                    },
                    monetizationStart: {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__monetization_start'),
                    },
                    strategyArchived: {
                        startPhrase: dic.word('wt_menu__widget__header_notifications__item__title__strategy'),
                        endPhrase: dic.word('wt_menu__widget__header_notifications__item__title__strategy_archived'),
                    },
                    'marketplace/productPublished': {
                        text: dic.word(
                            'wt_menu__widget__header_notifications__item__title__marketplace_product_published',
                        ),
                    },
                    'marketplace/productRejected': {
                        text: dic.parts(
                            'wt_menu__widget__header_notifications__item__title__marketplace_product_rejected',
                        ),
                    },
                    'marketplace/productWithdrawn': {
                        text: dic.word(
                            'wt_menu__widget__header_notifications__item__title__marketplace_product_withdrawn',
                        ),
                    },
                    'marketplace/donationForAuthor': {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__donation_for_author'),
                    },
                    'marketplace/newSignal': {
                        text: dic.word('wt_menu__widget__header_notifications__item__title__marketplace_new_signal'),
                    },
                    'marketplace/youBecameCreator': {
                        text: dic.word(
                            'wt_menu__widget__header_notifications__item__title__marketplace_you_became_creator',
                        ),
                    },
                },
                message: {
                    strategyArchived: dic.word('wt_menu__widget__header_notifications__item__msg__strategy_archived'),
                    monetizationStart: dic.word('wt_menu__widget__header_notifications__item__msg__monetization_start'),
                    'marketplace/productRejected': dic.word(
                        'wt_menu__widget__header_notifications__item__msg__marketplace_product_rejected',
                    ),
                    'marketplace/productWithdrawn': dic.word(
                        'wt_menu__widget__header_notifications__item__msg__marketplace_product_withdrawn',
                    ),
                    'marketplace/youBecameCreator': dic.word(
                        'wt_menu__widget__header_notifications__item__msg__marketplace_you_became_creator',
                    ),
                },
                actionBtnText: {
                    userSubscription: {
                        subscribed: dic.word(
                            'wt_menu__widget__header_notifications__item__action_btn__user_subscription__subscribed',
                        ),
                        notSubscribed: dic.word(
                            'wt_menu__widget__header_notifications__item__action_btn__user_subscription__not_subscribed',
                        ),
                    },
                },
            },
        }),
        [dic],
    );
}
