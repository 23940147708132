import React, { useMemo } from 'react';

import { tokens } from 'services/tokens';

import { SnackbarSize, SnackbarIconProps, SnackbarType } from './types';
import * as Styled from './SnackbarIcon.styled';

type Props = SnackbarIconProps & {
    hasIndent?: boolean;
    size?: SnackbarSize;
    type?: SnackbarType;
};

export const SnackbarIcon = (props: Props) => {
    const { fill, component, hasIndent = true, type = 'default', size = 'regular' } = props;

    const IconComponent = component;

    const iconFill = useMemo(() => {
        if (fill) {
            return fill;
        }

        if (type === 'positive') {
            return tokens.colors.icon.positive.secondary.default;
        }

        if (type === 'warning') {
            return tokens.colors.text.base.default.default;
        }

        return tokens.colors.text.ondark.default.default;
    }, [type, fill]);

    if (!IconComponent) {
        return null;
    }

    return (
        <Styled.SnackbarIcon hasIndent={hasIndent} size={size}>
            <Styled.IconWrapperCentered>
                <IconComponent size={20} fill={iconFill} />
            </Styled.IconWrapperCentered>
        </Styled.SnackbarIcon>
    );
};
