export const addConversionCode = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('data-obct', '');
    script.text = `!(function (_window, _document) {
        const OB_ADV_ID = '005b64bcc5b0a38fa29b9439850f0024fb';
        if (_window.obApi) {
            const toArray = function (object) {
                return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
            };
            _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
            return;
        }
        var api = _window.obApi = function () {
            api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
        };
        api.version = '1.1';
        api.loaded = true;
        api.marketerId = OB_ADV_ID;
        api.queue = [];
        const tag = _document.createElement('script');
        tag.async = true;
        tag.src = '//amplify.outbrain.com/cp/obtp.js';
        tag.type = 'text/javascript';
        const script = _document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(tag, script);
    }(window, document));

    obApi('track', 'PAGE_VIEW');
    `;

    document.body.appendChild(script);
};

export const addTaboolaPixelCode = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
        window._tfa = window._tfa || [];
        window._tfa.push({notify: 'event', name: 'page_view', id: 1559484});
        !function (t, f, a, x) {
             if (!document.getElementById(x)) {
                 t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
             }
        }(document.createElement('script'),
        document.getElementsByTagName('script')[0],
        '//cdn.taboola.com/libtrc/unip/1559484/tfa.js',
        'tb_tfa_script');
    `;

    document.body.appendChild(script);
};
