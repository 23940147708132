import React, { useMemo, useState } from 'react';

import { BrokerageAccount } from 'services/mp-share-resources/types';
import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';
import { ActionButtonItemType, FooterActionButtonsType } from 'components/wt-popup3/footer';
import { Button } from 'components/button2';
import { ImageBlock } from 'components/image';

import { AccountListBoxWidget, Option } from '../../accounts-list-box';

import { ContentWrapper, LoadingWrapper } from './styled';
import accountsIcon from './accounts.png';

type Props = {
    title: string;
    close: () => void;
    toShowLoading: boolean;
    brokerageAccounts: BrokerageAccount[];
    onSelectAccountDone: (selectedAccount: Option) => void;
};

export const ListboxPopup = (props: Props) => {
    const { title, close, toShowLoading, brokerageAccounts, onSelectAccountDone } = props;
    const dic = useDictionary();
    const [selectedAccount, setSelectedAccount] = useState<Option | undefined>();
    const accounts = useMemo(
        () =>
            brokerageAccounts.map((brokerageAccount) => {
                const { id, tradeCode } = brokerageAccount;
                return {
                    accountId: id,
                    tradeCode: tradeCode?.value,
                };
            }),
        [brokerageAccounts],
    );

    const links = useLinks();

    const renderButtons = (): FooterActionButtonsType => {
        const goToSubscriptions: ActionButtonItemType = {
            key: 0,
            content: dic.word('wt_mplc2__widget_lime_popup__buttons_subscriptions'),
            kind: 'secondary',
            href: links.homeSubscriptions(),

            isProcessing: toShowLoading,
            isDisabled: toShowLoading,
        };

        const openNewAccount: ActionButtonItemType = {
            key: 1,
            content: dic.word('wt_mplc2__widget_lime_popup__buttons_signout'),
            kind: accounts.length > 0 ? 'secondary' : 'primary',
            onClick: () => {
                window.open('https://lime.co', '_blank');
            },
            isProcessing: toShowLoading,
            isDisabled: toShowLoading,
        };

        const goToPayment: ActionButtonItemType = {
            key: 2,
            content: dic.word('wt_mplc2__widget_brokerage_popup__buttons_payment'),
            onClick: async () => {
                if (selectedAccount) {
                    onSelectAccountDone(selectedAccount);
                }
            },
            isProcessing: toShowLoading,
            isDisabled: !selectedAccount,
        };

        const buttons: ActionButtonItemType[] =
            accounts.length > 0 ? [openNewAccount, goToPayment] : [goToSubscriptions, openNewAccount];

        return { direction: 'horizontal', align: 'right', items: buttons };
    };

    const content = useMemo(() => {
        if (toShowLoading) {
            return (
                <LoadingWrapper>
                    <Button isProcessing kind="ghost-secondary" />
                </LoadingWrapper>
            );
        }
        return (
            <>
                <span>
                    {accounts.length > 0
                        ? dic.word('wt_mplc2__widget_brokerage_popup__header_text')
                        : dic.word('wt_mplc2__widget_brokerage_popup__header_text_no_accounts')}
                </span>
                {accounts.length > 0 ? (
                    <AccountListBoxWidget accounts={accounts} onChange={setSelectedAccount} />
                ) : (
                    <ImageBlock src={accountsIcon} />
                )}
            </>
        );
    }, [toShowLoading, accounts, dic]);

    return (
        <WtPopup3
            portalTo="popupRoot"
            title={title}
            width={456}
            close={close}
            size={SizeWtPopup3Enum.MEDIUM}
            isMobileFullscreen
            actionButtons={renderButtons()}
            zIndex={1001}
        >
            <ContentWrapper $height={accounts.length > 0 ? '420px' : '400px'}>{content}</ContentWrapper>
        </WtPopup3>
    );
};
