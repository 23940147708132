import React, { FC, memo, ReactElement } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { ArrowLeftIcon } from 'components/icons2/ArrowLeftIcon';
import { CloseIcon } from 'components/icons2/CloseIcon';

import { BackButton, CloseButton, HeaderStyled, MenuHeaderText, SearchInputStyled } from './styled';

interface HeaderProps {
    showBackButton?: boolean;
    onBack?: () => void;
    headerContent?: ReactElement | string;
    showCloseButton?: boolean;
    onClose?: () => void;
    filter?: {
        searchString: string;
        onChange: (searchString: string) => void;
    };
    isMobile: boolean;
}

export const Header: FC<HeaderProps> = memo(
    ({ showBackButton = false, onBack, headerContent = null, showCloseButton = false, onClose, filter, isMobile }) => {
        const dic = useDictionary();
        return (
            <HeaderStyled>
                {showBackButton ? (
                    <BackButton
                        kind="ghost-secondary"
                        size="small"
                        icon={ArrowLeftIcon}
                        onClick={onBack}
                        className={`autotest__navigation__context_menu_${isMobile ? 'mobile_' : ''}buttons_back`}
                    />
                ) : null}
                {filter ? (
                    <SearchInputStyled
                        placeholder={dic.word('wt_all__widget_menu__search_country_placeholder')}
                        value={filter.searchString}
                        onChange={filter.onChange}
                        autoFocus
                        autotestClass={`autotest__navigation__context_menu_${isMobile ? 'mobile_' : ''}search`}
                    />
                ) : (
                    <>
                        {typeof headerContent === 'string' ? (
                            <MenuHeaderText style={{ marginLeft: showBackButton ? '12px' : '16px' }}>
                                {headerContent}
                            </MenuHeaderText>
                        ) : (
                            headerContent
                        )}
                    </>
                )}
                {showCloseButton ? (
                    <CloseButton
                        kind="ghost-secondary"
                        size="small"
                        icon={CloseIcon}
                        onClick={onClose}
                        className={`autotest__navigation__context_menu_${isMobile ? 'mobile_' : ''}buttons_close`}
                    />
                ) : null}
            </HeaderStyled>
        );
    },
);
