import styled, { css } from 'styled-components';

import { buttonResetStyle } from 'app/styled';
import { body14RegularFontStyle, caption12RegularFontStyle } from 'services/typography';
import { Z_INDEX_DIALOG } from 'components/wt-popup3/constants';

export const ListBoxWrapper = styled.div``;

export const ErrorTextWrapper = styled.div`
    display: flex;
    margin-top: 4px;
`;
export const ErrorText = styled.span`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.negative};
`;

export const Floating = styled.div`
    z-index: ${Z_INDEX_DIALOG};
    outline: none;
    display: flex;
    flex-direction: column;
    min-height: 56px;
`;

export const Transition = styled.div`
    outline: none;
    height: 100%;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
`;

export const Options = styled.div`
    border-radius: 8px;
    padding: 8px 0;
    background: ${({ theme }) => theme.bg.popover};
    box-shadow: ${({ theme }) => theme.shadow.popover};
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 1;
    min-height: 0;
`;

export const OptionItemWrapper = styled.div``;

export const OptionButton = styled.button<{ readonly?: boolean }>`
    ${buttonResetStyle}
    ${body14RegularFontStyle}
    padding: 10px 16px;
    display: block;
    width: 100%;
    text-align: left;
    cursor: ${({ readonly }) => (readonly ? 'default' : 'pointer')};

    ${({ readonly }) =>
        readonly
            ? ''
            : css`
                  &:hover {
                      background-color: ${({ theme }) => theme.fillIn.primary.overlay16};
                  }

                  &:active {
                      color: ${({ theme }) => theme.label.accent};
                      background-color: ${({ theme }) => theme.fillIn.primary.overlay16};
                  }
              `};
`;

export const OptionContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;
