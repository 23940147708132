type DefinedKeys<T> = {
    [K in keyof T]: T[K] extends undefined ? never : K;
}[keyof T];

type DefinedProperties<T> = {
    [K in DefinedKeys<T>]: Exclude<T[K], undefined>;
};

/** Создает новый объект и копирует в него свойства исходного объекта, значение которых не равно undefined */
export function excludeUndefinedProperties<T extends Record<string, unknown>>(obj: T): DefinedProperties<T> {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== undefined) {
            acc[key as keyof T] = obj[key] as T[keyof T];
        }
        return acc;
    }, Object.create(null) as T) as DefinedProperties<T>;
}
