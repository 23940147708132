import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const ChartIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path d="M19.375 0.625H0.625V19.375H19.375V0.625Z" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.870313 3.64844H19.375V3.96094H0.870313V3.64844Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.854687 6.66719H19.375V6.97969H0.854687V6.66719Z"
            fill="#B0BEC5"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.8375 9.68594H19.375V9.99844H0.8375V9.68594Z" fill="#B0BEC5" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.821875 12.7047H19.375V13.0172H0.821875V12.7047Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.804688 15.725H19.375V16.0375H0.804688V15.725Z"
            fill="#B0BEC5"
        />
        <path
            d="M6.0501 19.0484H3.42041V8.65311C3.42041 8.44061 3.59229 8.26874 3.80479 8.26874H5.66572C5.87822 8.26874 6.0501 8.44061 6.0501 8.65311V19.0484Z"
            fill="#9CCC65"
        />
        <path
            d="M11.3156 19.0485H8.68433V11.6938C8.68433 11.4813 8.8562 11.3094 9.0687 11.3094H10.9296C11.1421 11.3094 11.314 11.4813 11.314 11.6938V19.0485H11.3156Z"
            fill="#F44336"
        />
        <path
            d="M16.1938 19.0484H14.3329C14.1204 19.0484 13.9485 18.8766 13.9485 18.6641V4.04063C13.9485 3.82813 14.1204 3.65625 14.3329 3.65625H16.1938C16.4063 3.65625 16.5782 3.82813 16.5782 4.04063V18.6641C16.5782 18.8766 16.4063 19.0484 16.1938 19.0484Z"
            fill="#0091EA"
        />
        <path d="M19.375 18.75H1.25V0.625H0.625V19.375H19.375V18.75Z" fill="#B0BEC5" />
        <path
            d="M19.0625 0.9375V19.0625H0.9375V0.9375H19.0625ZM19.375 0.625H0.625V19.375H19.375V0.625Z"
            fill="#B0BEC5"
        />
    </EmojiIconSvg>
);
