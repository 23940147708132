import React, { FC, useLayoutEffect, useState } from 'react';

import { OverlayStyled } from './styled';

export interface Props {
    show: boolean;
}

export const Overlay: FC<Props> = ({ show }) => {
    const [mounted, setMounted] = useState(false);
    const [visible, setVisible] = useState(false);

    useLayoutEffect(() => {
        if (!visible && show) {
            setMounted(true);
            const timeout = setTimeout(() => setVisible(true), 0);
            return () => clearTimeout(timeout);
        }
        if (visible && !show) {
            setVisible(false);
            const timeout = setTimeout(() => {
                setMounted(false);
            }, 300);
            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [mounted, show]);

    return mounted ? <OverlayStyled $visible={visible} /> : null;
};
