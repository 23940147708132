export const MAX_LENGTH = 8000;

export const MAX_THRESH = 7960;

export const MIN_THRESH = 3;

export const defaultValues = {
    cover: undefined,
    description: '',
};
