/* eslint-disable camelcase */
import produce from 'immer';
import axios from 'axios';

import { Action, Id, Lcid } from 'types';
import URLProcessor from 'services/url-processor';
import { Actions, createThunkActions, thunkActions } from 'services/create-action-types/backend-client';
import { CustomThunkReturnType } from 'services/create-action-types/shared/types';
import { withAbortController } from 'services/backend-client';

import { BUSINESS_METRICS_SEARCH_CLICK, SEARCH_TABS } from './constants';
import { GlobalStateSearch, SearchData, SearchResult, SearchTabs } from './interfaces';

let cancelRef: (() => void) | undefined;

export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT' as const;
export const reset = (widgetName: string) => {
    cancelRef?.();
    withAbortController((cancel) => {
        cancelRef = cancel;
    });
    return { type: RESET_SEARCH_RESULT, payload: { pass: { widgetName } } };
};

export type Reset = Action<typeof reset>;
export type ResetActions = ReturnType<typeof reset>;

export const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT' as const;
export const updateSearchResult = (searchResult: SearchResult, widgetName: string) => ({
    type: UPDATE_SEARCH_RESULT,
    payload: { data: { searchResult }, pass: { widgetName } },
});

export type UpdateSearchResult = Action<typeof updateSearchResult>;
export type UpdateSearchResultActions = ReturnType<typeof updateSearchResult>;

const [GET_SEARCH_RESULTS_ACTION_TYPES, getSearchResultsThunk, getSearchResultsActions] = createThunkActions<
    'widgets',
    GlobalStateSearch
>('widgets')('GET_SEARCH_RESULTS')<never, SearchData, { tabs: SearchTabs[]; widgetName: string }>();

function getSearchResults(
    key: string,
    limit: number | null,
    lang: Lcid,
    requestOtherTabs = true,
    widgetName = 'search',
): CustomThunkReturnType<typeof getSearchResultsThunk> {
    cancelRef?.();
    const cancelationWrapper = withAbortController((cancel) => {
        cancelRef = cancel;
    });

    return async (dispatch, getState, extraArgument) => {
        const {
            widgets: {
                search: { activeTab },
            },
        } = getState();
        const otherTabs = SEARCH_TABS.filter((tab) => tab !== activeTab);

        const currentTabUrl = URLProcessor.page('ng_search').queryArgs({
            key,
            lang,
            timestamp: new Date().getTime(),
            entities: activeTab,
        });

        if (limit) {
            currentTabUrl.queryArg('limit', limit);
        }

        const otherTabsUrl = URLProcessor.page('ng_search').queryArgs({
            key,
            lang,
            timestamp: new Date().getTime(),
            entities: otherTabs.join(' '),
        });

        if (limit) {
            otherTabsUrl.queryArg('limit', limit);
        }

        try {
            const [activeTabResults, otherTabsResults] = await Promise.all([
                getSearchResultsThunk({
                    request: {
                        method: 'GET',
                        url: currentTabUrl.path(),
                        withCredentials: true,
                        ...cancelationWrapper,
                    },
                    pass: {
                        tabs: [activeTab],
                        widgetName,
                    },
                })(dispatch, getState, extraArgument),
                requestOtherTabs
                    ? getSearchResultsThunk({
                          request: {
                              method: 'GET',
                              url: otherTabsUrl.path(),
                              withCredentials: true,
                              ...cancelationWrapper,
                          },
                          pass: {
                              tabs: otherTabs,
                              widgetName,
                          },
                      })(dispatch, getState, extraArgument)
                    : null,
            ]);

            return produce(activeTabResults, (draft) => {
                if (!otherTabsResults) {
                    return;
                }
                if (activeTab !== 'instruments') {
                    draft.payload.data.result.instruments = otherTabsResults.payload.data.result.instruments;
                }
                if (activeTab !== 'people') {
                    draft.payload.data.result.people = otherTabsResults.payload.data.result.people;
                }
                if (activeTab !== 'posts') {
                    draft.payload.data.result.posts = otherTabsResults.payload.data.result.posts;
                }
                if (activeTab !== 'products') {
                    draft.payload.data.result.products = otherTabsResults.payload.data.result.products;
                }
            });
        } catch (err) {
            if (
                !axios.isCancel(
                    (err as Extract<GetSearchResultsActions, { type: typeof GET_SEARCH_RESULTS_ACTION_TYPES[2] }>)
                        .payload.error,
                )
            ) {
                throw err;
            }
            return err;
        }
    };
}
type GetSearchResults = Action<typeof getSearchResults>;
type GetSearchResultsActions = Actions<typeof getSearchResultsActions>;

export { GET_SEARCH_RESULTS_ACTION_TYPES, getSearchResults, GetSearchResults, GetSearchResultsActions };

const [RESULT_CLICK_ACTION_TYPES, searchResultClickLogThunk, searchResultClickLogActions] =
    thunkActions('RESULT_CLICK')();

const searchResultClickLog = (payload: { id: Id; type: string; log_id: number; timestamp: number; query: string }) => {
    const url = new URL(BUSINESS_METRICS_SEARCH_CLICK);
    Object.entries(payload).forEach(([key, value]) => url.searchParams.set(key, `${value}`));

    return searchResultClickLogThunk({
        request: {
            method: 'GET',
            url: url.toString(),
            withCredentials: true,
        },
        pass: {
            generatorId: payload.id,
            generatorType: payload.type,
        },
    });
};

type SearchResultClickLog = Action<typeof searchResultClickLog>;
type SearchResultClickLogActions = Actions<typeof searchResultClickLogActions>;

export { RESULT_CLICK_ACTION_TYPES, searchResultClickLog, SearchResultClickLog, SearchResultClickLogActions };
export const UPDATE_SEARCH_TAB = 'widgets/UPDATE_SEARCH_TAB' as const;
export const setActiveTab = (tab: SearchTabs, widgetName: string) => ({
    type: UPDATE_SEARCH_TAB,
    payload: { data: { tab }, pass: { widgetName } },
});

export type SetActiveTab = Action<typeof setActiveTab>;
export type SetActiveTabActions = ReturnType<typeof setActiveTab>;

export type SearchActions =
    | ResetActions
    | UpdateSearchResultActions
    | GetSearchResultsActions
    | SearchResultClickLogActions
    | SetActiveTabActions;
