import React, { useCallback, useState } from 'react';

import { Person } from 'types';
import { numberToHumanString } from 'services/number-to-human-string';
import Dic from 'services/dictionary';
import { useDicMemo } from 'hooks/useDictionary';
import { Tabs } from 'components/tabs';
import { SizeWtPopup3Enum } from 'components/wt-popup3';

import { usePageRoot } from '../../../hooks';
import { useProfilePageContext } from '../../../context';
import { PeopleState, TABS } from '../types';
import { ReceiveSubscribers, ReceiveSubscriptions } from '../actions';

import { PeopleList } from './PeopleList';
import { NoData } from './NoData';
import { StyledScrollableRows, TabsWrapper, WtPopup3Styled, TabsContentWrapper, TabContent } from './styled';

type Props = {
    defaultTab: TABS;
    user: Person;
    togglePopup: () => void;
    subscribers: PeopleState;
    subscriptions: PeopleState;
    receiveSubscriptions: ReceiveSubscriptions;
    receiveSubscribers: ReceiveSubscribers;
};

const PAGE_SIZE = 20;

export const SubscribersComponent = (props: Props) => {
    const tabNames = useDicMemo((dic) => ({
        [TABS.followers]: dic.word('wt_profile__widget_subscribers__followers'),
        [TABS.following]: dic.word('wt_profile__widget_subscribers__following'),
        // [TABS.topics]: 'Teмы',
    }));
    const { defaultTab, user, togglePopup, receiveSubscriptions, receiveSubscribers, subscribers, subscriptions } =
        props;
    const pageRoot = usePageRoot();
    const [{ isOwner }, setContextSlice] = useProfilePageContext();
    const { id: ownerId, counters } = user;
    const { followers = 0, following = 0 } = counters || {};
    const [activeTabId, setActiveTabId] = useState(defaultTab);
    const handleTabChange = (_: number, tabId?: number | string) => {
        if (typeof tabId === 'number') {
            setActiveTabId(tabId);
        }
    };

    const fetchSubscriptions = useCallback(async () => {
        const action = await receiveSubscriptions(ownerId, PAGE_SIZE, subscriptions.nextCursor);
        if ('error' in action.payload) {
            throw action;
        }
    }, [ownerId, receiveSubscriptions, subscriptions.nextCursor]);

    const fetchSubscribers = useCallback(async () => {
        const action = await receiveSubscribers(ownerId, PAGE_SIZE, subscribers.nextCursor);
        if ('error' in action.payload) {
            throw action;
        }
    }, [ownerId, receiveSubscribers, subscribers.nextCursor]);

    const tabItems = useDicMemo(
        (dic) => [
            {
                id: TABS.following,
                title: `${dic.word('wt_profile__widget_subscribers__following')}${
                    following ? ` ${numberToHumanString(following)}` : ''
                }`,
            },
            {
                id: TABS.followers,
                title: `${dic.word('wt_profile__widget_subscribers__followers')}${
                    followers ? ` ${numberToHumanString(followers)}` : ''
                }`,
            },
            // { id: TABS.topics, title: 'Темы' },
        ],
        [followers, following],
    );

    const activateAddFeedStatus = () => {
        togglePopup();
        setContextSlice({ isAddFeedStatusActive: true });
    };

    return (
        <WtPopup3Styled
            size={SizeWtPopup3Enum.LARGE}
            close={togglePopup}
            portalTo={pageRoot}
            bgCloseable
            isMobileFullscreen
            title={tabNames[activeTabId]}
            width={464}
        >
            <StyledScrollableRows
                hasData={Boolean(
                    (activeTabId === TABS.following && following) || (activeTabId === TABS.followers && followers),
                )}
            >
                <TabsWrapper>
                    <Tabs size="small" items={tabItems} selectedItemId={activeTabId} onItemSelected={handleTabChange} />
                </TabsWrapper>
                <TabsContentWrapper>
                    {activeTabId === TABS.following && (
                        <TabContent className="autotest__profile-page__header-popup-following-tab">
                            {following ? (
                                <PeopleList
                                    people={subscriptions.list}
                                    nextCursor={subscriptions.nextCursor}
                                    receivePeople={fetchSubscriptions}
                                    togglePopup={togglePopup}
                                />
                            ) : (
                                <NoData
                                    title={Dic.word('wt_profile__widget_subscribers__no_data_title_subscriptions')}
                                />
                            )}
                        </TabContent>
                    )}
                    {activeTabId === TABS.followers && (
                        <TabContent className="autotest__profile-page__header-popup-followers-tab">
                            {followers ? (
                                <PeopleList
                                    people={subscribers.list}
                                    nextCursor={subscribers.nextCursor}
                                    receivePeople={fetchSubscribers}
                                    togglePopup={togglePopup}
                                />
                            ) : (
                                <NoData
                                    title={Dic.word('wt_profile__widget_subscribers__no_data_title_subscribers')}
                                    action={activateAddFeedStatus}
                                    type={isOwner ? 'feedStatus' : 'showFeed'}
                                />
                            )}
                        </TabContent>
                    )}
                </TabsContentWrapper>
            </StyledScrollableRows>
        </WtPopup3Styled>
    );
};
