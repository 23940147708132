/* eslint-disable @typescript-eslint/no-explicit-any */
import produce, { Draft, produceWithPatches } from 'immer';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

import { traverseByPath } from 'services/utils/traverse-by-path';

import { CacheData, ResourcesMap } from '../types';

import { memoizationCache } from './memoization';

function normalize({
    kind,
    cache,
    resourcesMap,
    typename,
    obj,
}: {
    kind: 'request' | 'resource';
    cache: Draft<CacheData>;
    resourcesMap: ResourcesMap<any>;
    typename: string;
    obj: Draft<any>;
}) {
    const { resources } = resourcesMap[typename];
    if (resources) {
        Object.entries(resources).forEach(([path, nestedType]) => {
            const { getId } = resourcesMap[nestedType as string];
            traverseByPath(obj, path, (item, parent, keyOrIndex) => {
                if (Array.isArray(item)) {
                    const ids: string[] = [];
                    item.forEach((arrayElement) => {
                        if (arrayElement) {
                            normalize({
                                kind: 'resource',
                                cache,
                                resourcesMap,
                                typename: nestedType as string,
                                obj: arrayElement,
                            });
                            ids.push(`${getId(arrayElement)}`);
                        }
                    });
                    parent[keyOrIndex] = ids;
                } else if (item) {
                    normalize({
                        kind: 'resource',
                        cache,
                        resourcesMap,
                        typename: nestedType as string,
                        obj: item,
                    });
                    parent[keyOrIndex] = `${getId(item)}`;
                }
            });
        });
    }
    const cachePart = kind === 'request' ? cache.requests : cache.resources;
    if (!cachePart[typename]) {
        cachePart[typename] = {};
    }
    if (obj) {
        const id = `${resourcesMap[typename].getId(obj)}`;
        memoizationCache.invalidate(`${typename}:${id}`);
        if (cachePart[typename]?.[id]) {
            mergeWith(cachePart[typename][id], obj, (objValue, srcValue) =>
                Array.isArray(objValue) ? srcValue : undefined,
            );
        } else {
            cachePart[typename][id] = obj;
        }
    }
}

export function cacheWrite({
    kind,
    cache,
    obj,
    typename,
    resourcesMap,
    operationId,
}: {
    kind: 'request' | 'resource';
    cache: CacheData;
    obj: any;
    typename: string;
    resourcesMap: ResourcesMap<any>;
    operationId?: string;
}) {
    const updater = (cacheDraft: Draft<CacheData>) => {
        normalize({
            kind,
            cache: cacheDraft,
            resourcesMap,
            typename,
            obj: cloneDeep(obj),
        });
    };
    if (operationId) {
        const [result, _, inversePatches] = produceWithPatches(cache, updater);
        return produce(result, (draft) => {
            draft.patches[operationId] = inversePatches;
        });
    }
    return produce(cache, updater);
}
