import React from 'react';

import { isFinamSignalsProduct } from 'types/finam-product';
import { useGradientSrc } from 'hooks/useGradientSrc';
import { DefaultCard } from 'components/card';

import { ProductCardFinamPropsType } from '../types';
import { useArchivedAtParams } from '../hooks/useArchivedAtParams';
import { useFinamCardSharedProps } from '../utils';

import { useUsSignalTable } from './useUsSignalTable';

export const UsSignalCard = (props: ProductCardFinamPropsType) => {
    const { item } = props;
    const sharedProps = useFinamCardSharedProps(props, 'us-signal');

    const parametersTable = useUsSignalTable(item && isFinamSignalsProduct(item) ? item : undefined);
    const backgroundImageUrl = useGradientSrc(item?.id);
    const archivedAtParams = useArchivedAtParams(item);
    return (
        <DefaultCard
            {...sharedProps}
            formattedPricePeriod={undefined}
            thumbnailSrc={backgroundImageUrl}
            parametersTable={parametersTable}
            {...archivedAtParams}
        />
    );
};
