import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import cn from 'classcat';

import { IconSvg } from 'components/icons2';

import { LimexLogoComponent, LimexLogoProps } from './types';

const BLACK_DEFAULT = '#000000';

export const LimexLogoIcon: LimexLogoComponent = (props: LimexLogoProps) => {
    const { isMonochrome = false, color = 'black', width = 80, height = 28, className: classNameProp } = props;

    const className = cn([classNameProp, 'autotest__limex-icon']);

    const theme = useTheme();

    const defaultFillColorText = theme.label.primary;
    const defaultFillColorIcon = theme.label.accent;
    const whiteDefault = theme.label.inverse;

    const fillColorText = useMemo(() => {
        if (color === 'white') {
            return whiteDefault;
        }

        if (color === 'black' && isMonochrome) {
            return BLACK_DEFAULT;
        }

        return defaultFillColorText;
    }, [color]);

    const fillColorIcon = useMemo(() => {
        if (isMonochrome) {
            return fillColorText;
        }

        return defaultFillColorIcon;
    }, [color, isMonochrome, fillColorText]);

    const emblem = (
        <path
            d="M59.0828 54.6819L45.6604 40.9109C45.2597 40.4083 44.5585 40.2073 43.9575 40.2073C43.3565 40.2073 42.6553 40.4083 42.2547 40.9109L28.8322 54.6819C27.6301 55.9886 27.7303 57.999 29.1327 59.1047C33.4399 62.6228 38.7488 64.3316 43.9575 64.3316C49.1662 64.3316 54.4751 62.6228 58.8825 59.2052C60.2849 58.0995 60.385 55.9886 59.0828 54.6819Z"
            fill={fillColorIcon}
        />
    );

    const text = (
        <>
            <path
                d="M92 25.2301V71.2674C92 71.8705 91.5062 72.3731 90.8148 72.3731H85.1852C84.5926 72.3731 84 71.8705 84 71.2674V25.2301C84.0988 24.627 84.5926 24.1244 85.284 24.1244H90.9136C91.5062 24.1244 92 24.627 92 25.2301Z"
                fill={fillColorText}
            />
            <path
                d="M106.815 40.2073H101.185C100.593 40.2073 100 40.6094 100 41.2125V71.3679C100 71.8705 100.494 72.3731 101.185 72.3731H106.815C107.407 72.3731 108 71.971 108 71.3679V41.2125C108 40.6094 107.506 40.2073 106.815 40.2073Z"
                fill={fillColorText}
            />
            <path
                d="M106.025 28.1451H102.074C100.988 28.1451 100.099 29.0498 100.099 30.1555V34.1762C100.099 35.2819 100.988 36.1866 102.074 36.1866H106.025C107.111 36.1866 108 35.2819 108 34.1762V30.1555C108 29.0498 107.111 28.1451 106.025 28.1451Z"
                fill={fillColorText}
            />
            <path
                d="M168 53.7772V71.0664C168 71.77 167.501 72.2726 166.802 72.2726H161.513C160.814 72.2726 160.315 71.77 160.315 71.0664V54.7824C160.315 52.0684 159.716 49.9576 158.618 48.6508C157.52 47.2436 155.823 46.6405 153.628 46.6405C151.232 46.6405 149.336 47.4446 147.939 49.0529C146.541 50.6612 145.843 52.9731 145.843 55.9886V71.1669C145.843 71.8705 145.344 72.3731 144.645 72.3731H139.355C138.656 72.3731 138.157 71.8705 138.157 71.1669V54.7824C138.157 52.0684 137.559 49.9576 136.461 48.6508C135.363 47.2436 133.666 46.6405 131.47 46.6405C129.075 46.6405 127.179 47.4446 125.781 49.0529C124.384 50.6612 123.685 52.9731 123.685 55.9886V71.1669C123.685 71.8705 123.186 72.3731 122.488 72.3731H117.198C116.499 72.3731 116 71.8705 116 71.1669V41.2125C116 40.5088 116.499 40.0063 117.198 40.0063H122.088C122.687 40.0063 123.286 40.5088 123.286 41.2125V43.7254C124.384 42.5192 125.781 41.514 127.278 40.8104C129.075 40.0063 130.971 39.7047 133.167 39.7047C135.463 39.7047 137.559 40.2073 139.355 41.112C140.752 41.8156 141.85 42.7202 142.848 43.9265C143.248 44.4291 143.946 44.4291 144.346 44.027C145.443 42.8208 146.841 41.9161 148.338 41.2125C150.334 40.3078 152.63 39.8052 155.025 39.8052C158.918 39.8052 162.111 41.0114 164.407 43.3234C166.802 45.5348 168 49.0529 168 53.7772Z"
                fill={fillColorText}
            />
            <path
                d="M202.622 47.3673C201.234 44.9475 199.351 43.0319 196.874 41.7212C194.396 40.4105 191.622 39.7047 188.55 39.7047C185.279 39.7047 182.405 40.4105 179.928 41.7212C177.45 43.1327 175.469 45.0484 174.081 47.5689C172.694 50.0895 172 53.0134 172 56.2398C172 59.567 172.694 62.4909 174.18 65.0114C175.568 67.532 177.55 69.5485 180.126 70.8592C182.604 72.2707 185.478 72.8757 188.649 72.8757C192.514 72.8757 195.784 71.9683 198.459 70.0526C200.541 68.5403 202.126 66.7254 203.216 64.5073C203.216 64.5073 203.315 64.4065 203.315 64.3057C203.315 64.2049 203.414 64.104 203.414 64.0032C203.414 63.4991 203.018 63.0958 202.523 63.0958H196.378C196.081 63.0958 195.784 63.2974 195.586 63.5999C194.099 65.7172 191.919 66.8263 188.847 66.8263C186.468 66.8263 184.387 66.1205 182.703 64.6081C181.117 63.1966 180.225 61.3818 179.928 59.0628H203.514C204.207 59.0628 204.802 58.5587 204.901 57.853C205 57.1472 205 56.3406 205 55.4332C204.703 52.6101 204.009 49.7871 202.622 47.3673ZM180.523 53.6184C179.928 53.6184 179.432 53.0134 179.532 52.4085C180.027 50.5937 180.919 48.9805 182.207 47.8714C183.892 46.4599 185.874 45.7541 188.153 45.7541C190.73 45.7541 192.811 46.4599 194.595 47.9722C196.081 49.1821 196.874 50.6945 197.171 52.6101C197.27 53.2151 196.775 53.7192 196.18 53.7192H180.523V53.6184Z"
                fill={fillColorText}
            />
            <path
                d="M238.8 70.7698L227.934 56.8413C227.735 56.5407 227.735 56.0397 227.934 55.6389L238.8 41.7104C239.299 41.1091 238.8 40.2073 238.003 40.2073H231.523C231.224 40.2073 230.925 40.4077 230.726 40.6081L223.847 48.6245C223.448 49.2257 222.651 49.2257 222.252 48.6245L215.473 40.6081C215.274 40.3075 214.975 40.2073 214.676 40.2073H208.097C207.299 40.2073 206.801 41.1091 207.299 41.7104L218.065 55.7391C218.265 56.0397 218.265 56.5407 218.065 56.9415L207.199 70.87C206.701 71.4713 207.199 72.3731 207.997 72.3731H214.477C214.776 72.3731 215.075 72.1727 215.274 71.9723L222.053 63.3546C222.451 62.7534 223.249 62.7534 223.648 63.3546L230.526 71.9723C230.726 72.2729 231.025 72.3731 231.324 72.3731H237.803C238.8 72.3731 239.299 71.4713 238.8 70.7698Z"
                fill={fillColorText}
            />
        </>
    );

    return (
        <IconSvg width={width} height={height} viewBox="0 0 279 97" className={className}>
            <>
                {text}
                {emblem}
            </>
        </IconSvg>
    );
};
