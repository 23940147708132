import styled from 'styled-components';

import { matchBreakpoints } from 'app/styled';

import { SnackbarSize } from './types';

const buildSizesIcon = ({ size }: { size: SnackbarSize }) =>
    matchBreakpoints(size, {
        regular: `
             height: 24px;
        `,
        small: `
             height: 20px;
        `,
    });

export const SnackbarIcon = styled.div<{ hasIndent: boolean; size: SnackbarSize }>`
    margin-right: ${({ hasIndent }) => (hasIndent ? '8px' : '0')};
    display: flex;

    ${buildSizesIcon};
`;

export const IconWrapperCentered = styled.div`
    display: flex;
    align-items: center;
`;
