import { FeedRecordObj, FeedRecordStats, FeedRecordType, PublishStatusRecord, VisibilityLevels } from 'types';

import { defaultPerson } from '../constants';

export const defaultFeedRecordStats: FeedRecordStats = {
    likes: {
        userChoice: null,
        negative: 0,
        positive: 0,
    },
    commentsCount: 0,
    commentsCountGeneratedAt: '',
    viewsCount: 0,
    sharesCount: 0,
};

export const defaultFeedRecordObj: FeedRecordObj = {
    id: '',
    type: 3,
    blogpostType: 0,
    title: '',
    displayTitle: '',
    lcid: 'en',
    content: '',
    contentTextLength: 0,
    shortContent: '',
    modalPreview: false,
    externalSourceId: null,
    isNews: false,
    externalLink: '',
    url: '',
    shareUrl: '',
    stats: { viewsCount: 0, likes: { userChoice: null, positive: 0, negative: 0 } },
    instruments: [],
    tags: [],
    tagsList: [],
    author: defaultPerson,
    snapshot: '',
    media: [],
    mainPhoto: '',
    date: { created: '' },
    data: { statsUsers: { shareUsers: [], commentUsers: [], topUsers: [], forecastVoters: [], voters: [] } },
    json: '',
    fromMobile: false,
    options: { publish_timeline: null },
    comments: [],
    commentsCount: 0,
    commentsCountGeneratedAt: '',
    isUserCommented: false,
    isPremiumGranted: null,
    keywords: '',
    mentions: [],
};

export const defaultPublishStatusRecord: PublishStatusRecord = {
    id: '',
    type: FeedRecordType.PUBLISH_STATUS,
    obj: defaultFeedRecordObj,
    shareUrl: '',
    url: '',
    generator: defaultPerson,
    date: { created: 0 },
    context: { type: null },
    data: {
        activityId: '',
        rank: '',
        statsUsers: { shareUsers: [], commentUsers: [], topUsers: [], forecastVoters: [], voters: [] },
    },
    comments: [],
    privileges: { canDelete: false, canShare: false },
    stats: defaultFeedRecordStats,
    options: { statusDid: 1, visLevel: VisibilityLevels.NONE },
};
