import styled from 'styled-components';
import { rgba } from 'polished';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, body17BoldFontStyle } from 'services/typography';
import { Button as Btn } from 'components/button2';

export const Content = styled.div`
    position: relative;
    z-index: 2;
`;

export const Wrapper = styled.div<{ $gradientColor: string }>`
    border-radius: 8px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    background: radial-gradient(
            24.55% 84.05% at 3.47% 7.46%,
            ${({ theme }) => rgba(theme.fillIn.secondary.high, 0.4)} 0%,
            ${({ theme }) => rgba(theme.label.primary, 0)} 100%
        ),
        radial-gradient(
            19.93% 130.52% at 99.74% 102.81%,
            ${({ $gradientColor }) => rgba($gradientColor, 0.6)} 0%,
            ${({ theme }) => rgba(theme.label.primary, 0)} 100%
        ),
        ${({ theme }) => theme.label.primary};

    ${mediaQuery.lt960} {
        background: radial-gradient(
                40.63% 46.63% at 3.47% 7.46%,
                ${({ theme }) => rgba(theme.fillIn.secondary.high, 0.52)} 0%,
                ${({ theme }) => rgba(theme.label.primary, 0)} 100%
            ),
            radial-gradient(
                83% 125.92% at 100% 102.99%,
                ${({ $gradientColor }) => rgba($gradientColor, 0.6)} 0%,
                ${({ theme }) => rgba(theme.label.primary, 0)} 100%
            ),
            ${({ theme }) => theme.fillIn.secondary.highest};
        padding: 16px 24px;
    }

    ${mediaQuery.lt768} {
        padding: 32px 28px;
    }
`;

export const TextBlock = styled.div`
    color: #fff;
    margin-bottom: 24px;

    ${mediaQuery.lt960} {
        margin-bottom: 16px;
    }

    ${mediaQuery.lt480} {
        margin-bottom: 44px;
    }
`;

export const HeadingText = styled.p`
    ${body17BoldFontStyle};
    margin: 0 0 4px;

    ${mediaQuery.lt960} {
        margin: 0 0 8px;
    }
`;

export const SecondaryText = styled.p`
    ${body14RegularFontStyle};
    margin: 0;
`;

export const Button = styled(Btn)`
    ${mediaQuery.lt768} {
        width: 100%;
    }
`;
