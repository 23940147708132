import { createGlobalStyle, css } from 'styled-components';

import { scrollHeight, scrollWidth } from 'app/styled';
import detectDevice from 'services/detectDevice';

const appHeight = () => document.documentElement.style.setProperty('--100vh', `${window.innerHeight}px`);
window.addEventListener('resize', appHeight);
appHeight();

export const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        margin: 0;
        font: 400 17px 'Inter var', sans-serif;
        line-height: 24px;
        letter-spacing: -0.01em;
        //font-variation-settings: normal;
        font-feature-settings: "tnum";
        -webkit-font-smoothing: antialiased;
        font-smooth: auto;
        ${({ theme }) => `
            color: ${theme.label.primary};
        `}
        background-color: ${({ theme }) => theme.bg.secondary};
        -webkit-tap-highlight-color: transparent;
    }

    figure {
        margin: 0;
    }

    a {
        text-decoration: none;
        ${({ theme }) => `
            color: ${theme.label.accent};
            &:hover {
                color: ${theme.label.accentHigh};
            }
            &:active {
                color: ${theme.label.accentHighest};
            }
        `}
    }

    body, div, textarea {
        &::-webkit-scrollbar {
            width: ${scrollWidth}px;
            height: ${scrollHeight}px;
        }
        ${
            detectDevice.isFirefox()
                ? css`
                      scrollbar-width: thin;
                      scrollbar-color: ${({ theme }) =>
                          `${theme.fillIn.secondary.mid} ${theme.fillIn.secondary.overlay32}`};
                  `
                : ''
        }


        &::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.fillIn.secondary.mid};
            border-radius: 4px;
            border: 2px solid ${({ theme }) => theme.bg.primary};
        }

        &::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.fillIn.secondary.overlay32};
            border-radius: 4px;
            border: 2px solid ${({ theme }) => theme.bg.primary};
        }
    }
`;
