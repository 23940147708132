import styled, { css } from 'styled-components';

import { Theme } from 'services/theme';

const calcPressed = ({ theme, kind }: { theme: Theme; kind: 'primary' | 'secondary' | 'staticColor' }) => `
        color: ${kind === 'staticColor' ? theme.staticColors.white : theme.label.primary};
        background: ${(() => {
            switch (kind) {
                case 'primary':
                    return theme.fillIn.primary.overlay32;
                case 'secondary':
                    return theme.fillIn.secondary.overlay32;
                default:
                    return theme.fillIn.secondary.highest;
            }
        })()};
    `;

const Button = styled.button<{
    size: 'regular' | 'compact';
    kind: 'primary' | 'secondary' | 'staticColor';
    isProcessing: boolean;
    pressed: boolean;
}>`
    width: ${({ size }) => (size === 'regular' ? '40px' : '32px')};
    height: ${({ size }) => (size === 'regular' ? '40px' : '32px')};
    color: ${({ theme, kind }) => (kind === 'staticColor' ? theme.staticColors.white : theme.label.secondary)};
    background: ${({ theme, kind }) => (kind === 'staticColor' ? theme.bg.dialogOverlay : 'inherit')};
    border-radius: 40px;
    border: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: ${({ theme, kind }) => (kind === 'staticColor' ? theme.staticColors.white : theme.label.secondaryHigh)};
        background: ${({ theme, kind }) => {
            switch (kind) {
                case 'primary':
                    return theme.fillIn.primary.overlay16;
                case 'secondary':
                    return theme.fillIn.secondary.overlay16;
                default:
                    return theme.fillIn.secondary.highest;
            }
        }};
    }
    &:active {
        ${calcPressed}
    }
    ${({ pressed }) =>
        pressed
            ? css`
                  ${calcPressed}
              `
            : ''}
    ${({ isProcessing }) => isProcessing && 'user-select: none;'}
    &:disabled {
        color: ${({ theme }) => theme.label.inactive};
        background: inherit;
        user-select: none;
    }
`;

export { Button };
