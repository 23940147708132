import React, { useImperativeHandle, useMemo, useRef } from 'react';

import { Tooltip } from 'components/tooltip';

import { getIsRightNumberValue, getLevelRiskValuesByValue } from './utils';
import { LevelRiskProps } from './types';
import * as Styled from './styled';

export const LevelRisk = React.forwardRef((props: LevelRiskProps, ref) => {
    const {
        isShowLevelNumberValue = true,
        value: valueProp,
        className,
        tooltip,
        color,
        size = 'small',
        balloon = false,
    } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => rootRef.current);

    const value = useMemo(() => {
        if (typeof valueProp === 'number' && getIsRightNumberValue(valueProp)) {
            return valueProp;
        }

        const valueNum = parseInt(valueProp, 10);
        if (getIsRightNumberValue(valueNum)) {
            return valueNum;
        }

        // для определения числового значения из текстового
        const levelRiskValuesData = getLevelRiskValuesByValue(valueProp);
        const levelNumValue = levelRiskValuesData?.num;
        if (levelNumValue) {
            return levelNumValue;
        }

        return undefined;
    }, [valueProp]);

    const isRightValue = getIsRightNumberValue(value);

    const tooltipTextMap = tooltip?.textMap;
    const tooltipProps = tooltip?.props;
    const tooltipText = isRightValue && tooltipTextMap ? tooltipTextMap[value] : undefined;
    const hasTooltip = isRightValue && !!tooltipText;

    const levelEl = useMemo(
        () => (
            <Styled.LineWrapper
                count={value}
                hasTooltipText={hasTooltip}
                size={size}
                color={color}
                isShowLevelNumberValue={isShowLevelNumberValue}
            >
                <Styled.BoltIcon size={16} />
                {isShowLevelNumberValue ? <Styled.Count size={size}>{value}</Styled.Count> : null}
            </Styled.LineWrapper>
        ),
        [isRightValue, value, tooltipText, size, color, isShowLevelNumberValue],
    );

    return (
        <Styled.LevelRiskWrapper className={className} ref={rootRef}>
            <Styled.Content>
                {hasTooltip ? (
                    <Tooltip
                        content={<Styled.TooltipText>{tooltipText}</Styled.TooltipText>}
                        placement="bottom"
                        arrow
                        appendTo={rootRef?.current || undefined}
                        {...tooltipProps}
                    >
                        {levelEl}
                    </Tooltip>
                ) : (
                    levelEl
                )}
                {isRightValue && <Styled.BalloonArrow count={value} balloon={balloon} color={color} />}
            </Styled.Content>
        </Styled.LevelRiskWrapper>
    );
});
