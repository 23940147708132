import React, { FC, memo, useCallback } from 'react';

import { useLinks } from 'hooks/useLinks';
import { useGetDicwordTextObj } from 'widgets/header-notifications/hooks';
import { HeaderNotificationsUnreadCountCustomRender } from 'widgets/header-notifications';
import { LinkButton } from 'components/button2';

import * as Styled from './styled';

export const MobileNotificationsHeader = memo((props) => {
    const textObj = useGetDicwordTextObj();
    const links = useLinks();

    const { title, settingsBtnText } = textObj;

    const customRender = useCallback(
        ({ unreadCount }) => (
            <Styled.Header
                title={title}
                after={
                    <LinkButton
                        href={links.cabinetNotifications()}
                        kind="ghost-secondary"
                        size="tiny"
                        className="autotest__header-notifications__settings-link"
                    >
                        {settingsBtnText}
                    </LinkButton>
                }
                unreadCount={unreadCount}
            />
        ),
        [title, settingsBtnText],
    );

    return <HeaderNotificationsUnreadCountCustomRender customRender={customRender} />;
});
