import React, { useMemo } from 'react';

import { AvatarCustomSizeValue, AvatarSize } from 'components/avatar/types';

import { VotersProps, VotersSizeValue } from '../types';
import { VOTERS_SIZE_VALUES_MAP } from '../constants';
import * as Styled from '../styled/Voters.styled';

const defaultVotersSize: VotersSizeValue = 'regular';
const defaultVotersSizeValue = VOTERS_SIZE_VALUES_MAP.regular;

const getAvatarSizeByVoteSize = (sizeVoter: VotersSizeValue): AvatarCustomSizeValue => {
    if (sizeVoter === 'small') {
        return VOTERS_SIZE_VALUES_MAP.small;
    }

    return defaultVotersSizeValue;
};

export const Voters = (props: VotersProps) => {
    const { items, size = defaultVotersSize } = props;

    const sizeAvatar: AvatarSize = useMemo(() => {
        if (typeof size === 'string') {
            return getAvatarSizeByVoteSize(size);
        }

        if (typeof size === 'object') {
            const initialSize = { default: defaultVotersSizeValue };
            return Object.entries(size).reduce(
                (prev, [windowSize, votersSizeValue]) => ({
                    ...prev,
                    [windowSize]: getAvatarSizeByVoteSize(votersSizeValue),
                }),
                initialSize,
            );
        }

        return defaultVotersSizeValue;
    }, [size]);

    return (
        <Styled.Voters size={size}>
            {items.map(({ user }) => (
                <Styled.VoterAvatarUser size={sizeAvatar} user={user} key={user.id} />
            ))}
        </Styled.Voters>
    );
};
