import { injectReducer } from 'app/store2';
import { injectTagsReducer, TagsState } from 'widgets/tags';

import { InitialData, FeedState } from './types';
import { FeedContainer } from './container';
import reducer from './reducer';

export const injectFeedReducer = ({ rawInitialState, tags }: { rawInitialState: InitialData; tags?: TagsState }) => {
    injectReducer('widgets.feed', reducer(rawInitialState));
    if (tags) {
        // везде где нужна лента - нужны и теги, обратное неверно
        injectTagsReducer(tags);
    }
};

export type { InitialData, FeedState };

export default FeedContainer;
