import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import { CardContainerProps } from './types';
import { Container } from './Container';

export const CardContainerElement = styled(Container)<
    Pick<
        CardContainerProps,
        'isLoading' | 'withoutBoxShadow' | 'isHeightGrow' | 'isDisable' | 'width' | 'isWidthLimits' | 'onClick' | 'url'
    >
>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    border-radius: 12px;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;
    box-sizing: border-box;
    transition: all 350ms;
    cursor: ${({ isLoading, onClick, url }) => {
        if (isLoading) {
            return 'progress';
        }

        if (onClick || url) {
            return 'pointer';
        }

        return 'default';
    }};
    pointer-events: ${({ isDisable }) => (isDisable ? 'none' : 'auto')};

    ${ifProp('isHeightGrow', 'height: 100%;')};

    ${({ width, isWidthLimits = true }) => {
        if (width) {
            return `width: ${width}px;`;
        }
        if (isWidthLimits) {
            return `
                min-width: 298px;
                max-width: 480px;
            `;
        }
        return '';
    }}

    ${({ withoutBoxShadow, theme: { shadow, bg, label, fillIn, focusOutline } }) => `
        border: 1px solid ${fillIn.secondary.overlay24};
        color: ${label.primary};
        background: ${bg.primary};
        &:hover {
            color: ${label.primary};
            ${withoutBoxShadow ? '' : `box-shadow: ${shadow.cardHeavy}`};
        }
        &:active {
            ${withoutBoxShadow ? '' : `box-shadow: ${shadow.cardEasy}`};
        }
        &:focus {
            ${withoutBoxShadow ? '' : `box-shadow: ${focusOutline.accent}`};
        }
    `}
`;
