import { GeneratorType, Id, Person } from 'types';

import { initialSearchResultState } from './constants';
import type { SearchResult } from './interfaces';

export function getSearchHistory(): SearchResult {
    const searchHistory = localStorage.getItem('searchHistory');
    const initialState = JSON.parse(JSON.stringify(initialSearchResultState));

    if (searchHistory) {
        try {
            return JSON.parse(searchHistory);
        } catch {
            return initialState;
        }
    }
    return initialState;
}

export function hasSearchHistory(tabIndex?: number): boolean {
    const history = getSearchHistory();

    if (tabIndex === undefined) {
        return (
            history.instruments.length > 0 ||
            history.people.length > 0 ||
            history.posts.length > 0 ||
            history.products.length > 0 ||
            history.communities.length > 0
        );
    }

    switch (tabIndex) {
        case 0:
            return history.instruments.length > 0;
        case 1:
            return history.people.length > 0;
        case 2:
            return history.posts.length > 0;
        case 3:
            return history.products.length > 0;
        case 4:
            return history.communities.length > 0;
        default:
            return false;
    }
}

type ResultItem = { id?: Id; productId?: number };

function addToHistoryList(items: ResultItem[], history: ResultItem[], clickedItemId: Id) {
    const item = items.find(({ id, productId }) => (id ?? productId) === clickedItemId);
    const index = history.findIndex(({ id, productId }) => (id ?? productId) === clickedItemId);

    if (index !== -1) {
        history.splice(index, 1);
    }

    if (item) {
        history.unshift(item);
        if (history.length > 10) {
            history.pop();
        }
    }
}

export function addToHistory(searchResult: SearchResult, type: string, id: Id) {
    const history = getSearchHistory();

    if (type === 'instrument') {
        addToHistoryList(searchResult.instruments, history.instruments, id);
    }
    if (type === 'user') {
        addToHistoryList(searchResult.people, history.people, id);
    }
    if (type === 'blogpost') {
        addToHistoryList(searchResult.posts, history.posts, id);
    }
    if (type === 'product') {
        addToHistoryList(searchResult.products, history.products, id);
    }
    if (type === 'community') {
        addToHistoryList(searchResult.communities, history.communities, id);
    }
    localStorage.setItem('searchHistory', JSON.stringify(history));
}

export function updateHistory(
    generatorId: Id,
    generatorType: GeneratorType | string,
    params: {
        subscribeAction?: 'subscribe' | 'unsubscribe';
        personData?: Partial<Person>;
    },
) {
    const history = getSearchHistory();
    const { subscribeAction, personData } = params;
    if (generatorType === GeneratorType.INSTRUMENT || generatorType === 'instrument') {
        const instrument = history.instruments.find(({ id }) => id === generatorId);
        if (instrument) {
            if (subscribeAction) {
                instrument.subscribed = subscribeAction === 'subscribe';
            }
        }
    }

    if (generatorType === GeneratorType.USER || generatorType === 'user') {
        const user = history.people.find(({ id }) => id === generatorId);
        if (user) {
            if (subscribeAction) {
                const subscribed = subscribeAction === 'subscribe';
                user.subscribed = subscribed;
                user.subscribersCount += subscribed ? 1 : -1;
                user.counters.followers += subscribed ? 1 : -1;
            }
            if (personData) {
                Object.assign(user, personData);
            }
        }
    }

    localStorage.setItem('searchHistory', JSON.stringify(history));
}
