const { hasOwnProperty } = Object.prototype;

/** Из https://github.com/facebook/react/blob/v16.8.6/packages/shared/objectIs.js
 * 1 / x === 1 / y обрабатывает сравнение 0 и -0
 * x !== x && y !== y обрабатывает сравнение NaN и NaN
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function is(x: any, y: any) {
    return (
        (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y) // eslint-disable-line no-self-compare
    );
}

type ConfigObject = { [key: string]: ConfigObject | [] | [ConfigObject] | undefined };

/**
 * Сделано на основе https://github.com/facebook/react/blob/v16.8.6/packages/shared/shallowEqual.js
 * Дополнено параметром config, который позволяет задать отдельные поля, которые будут рекурсивно
 * проверены на равенство глубже одного уровня.
 * */
export function customEqual<T extends Record<string, unknown>>(objA: T, objB: T, config?: ConfigObject): boolean {
    if (is(objA, objB)) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (let i = 0; i < keysA.length; i++) {
        if (!hasOwnProperty.call(objB, keysA[i])) {
            return false;
        }
        if (config && hasOwnProperty.call(config, keysA[i])) {
            const configRule = config[keysA[i]];
            const valueA = objA[keysA[i]];
            const valueB = objB[keysA[i]];
            if (Array.isArray(configRule)) {
                if (!Array.isArray(valueA) || !Array.isArray(valueB) || valueA.length !== valueB.length) {
                    return false;
                }
                const compareElementsByEquality = configRule.length === 0;
                for (let j = 0; j < valueA.length; j++) {
                    if (compareElementsByEquality) {
                        if (!is(valueA[j], valueB[j])) {
                            return false;
                        }
                    } else if (!customEqual(valueA[j], valueB[j], configRule[0])) {
                        return false;
                    }
                }
            } else if (!customEqual(objA[keysA[i]] as T, objB[keysA[i]] as T, configRule)) {
                return false;
            }
        } else if (!is(objA[keysA[i]], objB[keysA[i]])) {
            return false;
        }
    }

    return true;
}
