import styled, { keyframes, css } from 'styled-components';

import { reduceBreakpoints } from 'app/styled';
import {
    body15MediumFontStyle,
    caption12MediumFontStyle,
    caption9MediumFontStyle,
    h4MediumFontStyle,
} from 'services/typography';
import { tokens } from 'services/tokens';
import { ImageBlock } from 'components/image';
import { AvatarIcon } from 'components/icons2/AvatarIcon';

import {
    AvatarProps,
    AvatarInitials,
    AvatarShortSizeValue,
    AvatarSize,
    AvatarSizeValue,
    isAvatarFullSizeValue,
    isAvatarCustomSizeValue,
    AvatarAllProps,
} from '../types';
import { getBorderRadiusBySize, getInitialsTextFontBySize } from '../utils';
import { ANIMATION_DURATION, TRANSITION_ALL_CSS, SIZE_FULL_TO_SHORT_MAP } from '../constants';

export const AvatarImage = styled(ImageBlock)`
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    ${TRANSITION_ALL_CSS};
`;

export const InitialsTextWrapper = styled.div`
    display: flex;
    align-content: center;
    place-content: center;
    height: 100%;
`;

export const InitialsText = styled.span<{ color?: string }>`
    display: flex;
    align-items: center;
    color: ${({ color }) => color || 'inherit'};
    user-select: none;
    cursor: inherit;
    ${TRANSITION_ALL_CSS};
`;

const manifestation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }`;

export const AvatarIconWrapper = styled.div<{ centerIcon: boolean; bg?: string }>`
    display: flex;
    align-content: center;
    position: relative;
    height: 100%;
    align-items: ${({ centerIcon }) => (centerIcon ? 'center' : 'flex-end')};
    background-color: ${({ bg }) => bg ?? 'transparent'};
    animation-name: ${manifestation};
    animation-duration: ${ANIMATION_DURATION}ms;
    place-content: center;
    ${TRANSITION_ALL_CSS};

    svg {
        width: inherit;
        animation-name: ${manifestation};
        animation-duration: ${ANIMATION_DURATION}ms;
        ${TRANSITION_ALL_CSS};
    }
`;

export const UserIcon = styled(AvatarIcon)`
    fill: ${({ theme }) => theme.fillIn.secondary.mid};
    width: inherit;
`;

type AvatarStyleParams = {
    height: number;
    width: number;
    initialsTextFont: string;
    borderRadius: number;
    avatarHeight?: number;
};

type AvatarCssMapBySize = {
    [key in AvatarShortSizeValue]: AvatarStyleParams;
} & { [key: number]: { height?: number } };

const avatarCssMapBySize: AvatarCssMapBySize = {
    XL: {
        height: 64,
        width: 64,
        borderRadius: getBorderRadiusBySize(64),
        avatarHeight: 52,
        initialsTextFont: h4MediumFontStyle,
    },
    L: {
        height: 40,
        width: 40,
        borderRadius: getBorderRadiusBySize(40),
        avatarHeight: 32,
        initialsTextFont: body15MediumFontStyle,
    },
    M: {
        height: 32,
        width: 32,
        borderRadius: getBorderRadiusBySize(32),
        avatarHeight: 24,
        initialsTextFont: caption12MediumFontStyle,
    },
    S: {
        height: 20,
        width: 20,
        borderRadius: getBorderRadiusBySize(20),
        avatarHeight: 16,
        initialsTextFont: caption9MediumFontStyle,
    },
};

const rootCssDependantOfSize = ({
    width,
    height,
    borderRadius,
    avatarHeight,
    initialsTextFont,
}: AvatarStyleParams) => css`
    height: ${height}px;
    width: ${width}px;
    border-radius: ${borderRadius}px;

    ${UserIcon} {
        height: ${avatarHeight ? `${avatarHeight}px` : '85%'};
    }

    ${InitialsText} {
        ${initialsTextFont};
    } ;
`;

const buildSizes = ({ size, borderRadius }: { size: AvatarSize; borderRadius: AvatarProps['borderRadius'] }) => {
    const sizeInterceptor = (itemSize: AvatarSizeValue): AvatarStyleParams => {
        if (isAvatarCustomSizeValue(itemSize)) {
            return {
                height: itemSize,
                width: itemSize,
                initialsTextFont: getInitialsTextFontBySize(itemSize),
                borderRadius: borderRadius || getBorderRadiusBySize(itemSize),
            };
        }

        const makeStyleParams = (sizeObj: AvatarStyleParams) => ({
            ...sizeObj,
            borderRadius: borderRadius || sizeObj.borderRadius,
        });

        if (isAvatarFullSizeValue(itemSize)) {
            return makeStyleParams(avatarCssMapBySize[SIZE_FULL_TO_SHORT_MAP[itemSize]]);
        }

        return makeStyleParams(avatarCssMapBySize[itemSize]);
    };

    return reduceBreakpoints(size, (_size) => rootCssDependantOfSize(sizeInterceptor(_size)));
};

export const AvatarColorWrapper = styled.div<
    Pick<AvatarAllProps, 'bg' | 'isStayOverlayBg'> & {
        isSuccessImg: boolean;
        isLoading: boolean;
        isShowAvatarIcon: boolean;
    }
>`
    display: block;
    height: 100%;
    width: 100%;
    background-color: ${({ bg, isStayOverlayBg, isSuccessImg, isLoading, isShowAvatarIcon }) => {
        const defaultColor = tokens.colors.opacity.base['16'];

        if (isLoading || isStayOverlayBg) {
            return defaultColor;
        }

        if (isShowAvatarIcon) {
            return tokens.colors.bg.base.secondary.active;
        }

        if (bg) {
            return bg;
        }

        if (isSuccessImg) {
            return 'transparent';
        }

        return defaultColor;
    }};

    ${TRANSITION_ALL_CSS};
`;

export const Avatar = styled.div<{
    size: AvatarSize;
    borderRadius: AvatarProps['borderRadius'];
    isLoading: boolean;
    bg?: AvatarInitials['bg'];
    onClick?: AvatarProps['onClick'];
    url?: string;
    useWhiteSubstrate: boolean;
}>`
    display: block;
    position: relative;
    overflow: hidden;
    ${({ useWhiteSubstrate, theme }) => (useWhiteSubstrate ? `background-color: ${theme.staticColors.white}` : '')};
    cursor: ${({ onClick, isLoading, url }) => {
        if (isLoading) return 'progress';
        if (onClick || url) return 'pointer';
        return 'inherit';
    }};
    ${buildSizes};
    ${TRANSITION_ALL_CSS};

    &:focus-visible {
        outline: none;
        box-shadow: ${({ theme }) => theme.focusOutline.accent};
    }
`;
