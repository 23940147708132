import styled from 'styled-components';

export const OfferBadgeWrapper = styled.div<{ loaded?: boolean }>`
    left: 16px;
    bottom: 12px;
    position: absolute;
    transition: opacity 1000ms;
    opacity: ${({ loaded }) => (loaded ? '1' : '0')};
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const ContentStretchSpace = styled.div`
    flex-grow: 1;
`;

export const CornerButtonsWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 8px;
`;
