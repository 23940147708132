import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';

import { Id } from 'types';
import { injectReducer } from 'app/store2';
import { FeedContextValue } from 'context/feed';
import { injectReducers } from 'services/api-builder/api';
import { useToggle } from 'hooks/useToogle';
import { injectFeedReducer } from 'widgets/feed';
import { Footer } from 'widgets/footer';
import { UserFormContainer } from 'widgets/user-form';
import { injectWidgetNotificationsReducer } from 'widgets/header-notifications/utils';
import { myProductPopupReducer } from 'widgets/mp-share/my-products/reducer';
import { GlobalStyle } from 'components/global-style';

import { PageRawData, ProfilePageTabsType } from '../types';
import { isTabId } from '../widgets/tabs/types';
import { ProfilePageContextProvider } from '../context';
import { PageFeedContainer } from '../widgets/feed/container';
import { TabIds, TabsWidgetContainer } from '../widgets/tabs';
import { HeaderUserContainer } from '../widgets/header-user';
import { CoverContainer } from '../widgets/cover';
import { injectSubscribersReducer } from '../widgets/subscribers';
import { injectTagsSliderReducer } from '../widgets/tags-slider';

import reducer from './reducer';
import { PageWrapper, SecondLine, HeaderWrapper, ContentWrapper, TabContent } from './styled';
import { useProfileRedirects } from './useProfileRedirects';

type Props = {
    initialTabsData: ProfilePageTabsType;
    profileUserId: Id;
    personalAlias?: string | null;
    feedContext: FeedContextValue;
    currentUserInBLForProfile: boolean;
};

export const ProfilePage = ({
    initialTabsData,
    profileUserId,
    feedContext,
    currentUserInBLForProfile,
    personalAlias,
}: Props) => {
    const { activeTab = 'feed' } = initialTabsData;
    const defaultCurrentTab = activeTab || 'feed';
    const { owner } = feedContext;

    const [currentTab, setCurrentTab] = useState<TabIds>(defaultCurrentTab);
    const [isUserFormVisible, toggleUserForm] = useToggle(false);

    const handleTabChange = useCallback((index: number, tabId: string | number) => {
        if (isTabId(tabId)) {
            setCurrentTab(tabId);
        }
    }, []);

    const handleCloseUserForm = useCallback(() => {
        toggleUserForm(false);
    }, [toggleUserForm]);

    const pageRootRef = useRef(null);
    const [pageRoot, setPageRoot] = useState<HTMLDivElement | null>(null);
    useLayoutEffect(() => {
        setPageRoot(pageRootRef.current);
    }, []);

    const pinnedRecordShowMore = useProfileRedirects({
        pinnedRecordShowMore: feedContext.pinnedRecordShowMore,
        profileUserId,
        personalAlias,
    });

    const isVerifiedAuthor = owner && 'isVerifiedAuthor' in owner ? owner.isVerifiedAuthor ?? false : false;
    const premiumSubscribed =
        owner?.data && 'premiumSubscribed' in owner.data ? owner.data.premiumSubscribed ?? false : false;

    const renderFeed = () => (
        <TabContent>
            <PageFeedContainer
                feedContext={feedContext}
                currentTab={currentTab}
                pinnedRecordShowMore={pinnedRecordShowMore}
            />
        </TabContent>
    );

    const renderContent = () =>
        currentUserInBLForProfile ? null : (
            <>
                <TabsWidgetContainer
                    currentTab={currentTab}
                    onTabChange={handleTabChange}
                    isVerifiedAuthor={isVerifiedAuthor}
                    premiumSubscribed={premiumSubscribed}
                />
                {renderFeed()}
            </>
        );

    return (
        <div ref={pageRootRef}>
            <GlobalStyle />
            <ProfilePageContextProvider profileUserId={profileUserId} pageRoot={pageRoot}>
                <PageWrapper>
                    <CoverContainer toggleUserForm={toggleUserForm} />
                    <SecondLine>
                        <HeaderWrapper>
                            <HeaderUserContainer toggleUserForm={toggleUserForm} />
                        </HeaderWrapper>
                        <ContentWrapper>{renderContent()}</ContentWrapper>
                    </SecondLine>
                </PageWrapper>
                <Footer withPageLayout />
                <UserFormContainer closePopup={handleCloseUserForm} isVisible={isUserFormVisible} />
            </ProfilePageContextProvider>
        </div>
    );
};

export const initPageData = (rawInitialState: PageRawData) => {
    const { page, tags, tagsSlider, notifications } = rawInitialState;

    injectReducer('page', reducer(page));
    if (rawInitialState.feed) {
        injectFeedReducer({ rawInitialState: rawInitialState.feed, tags });
    }
    injectSubscribersReducer();
    injectTagsSliderReducer(tagsSlider);
    injectWidgetNotificationsReducer(notifications);
    injectReducer('widgets.myProductPopupReducer', myProductPopupReducer);
    injectReducers();
};
