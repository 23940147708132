import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const pageRowsGap = {
    default: 24,
    lt1400: 20,
    lt1200: 24,
    lt960: 16,
};

export const PageWrapper = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: min-content;
    justify-content: center;
    box-sizing: border-box;
    grid-gap: ${pageRowsGap.default}px;
    ${mediaQuery.lt1400} {
        grid-gap: ${pageRowsGap.lt1400}px;
    }
    ${mediaQuery.lt1200} {
        grid-gap: ${pageRowsGap.lt1200}px;
    }
    ${mediaQuery.lt960} {
        grid-gap: ${pageRowsGap.lt960}px;
        margin-top: 0;
        grid-template-columns: 1fr;
    }
    ${mediaQuery.lt768} {
        grid-gap: 0;
    }
`;

export const SecondLine = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 20px;
    justify-content: center;
    ${mediaQuery.lt768} {
        grid-template-columns: minmax(0, 1fr);
    }
`;

export const HeaderWrapper = styled.div`
    width: 312px;
    ${mediaQuery.lt1400} {
        width: 268px;
    }
    ${mediaQuery.lt1200} {
        width: 224px;
    }
    ${mediaQuery.lt960} {
        width: 260px;
    }
    ${mediaQuery.lt768} {
        width: 100%;
    }
`;

export const ContentWrapper = styled.div`
    width: 760px;

    ${mediaQuery.lt1400} {
        width: 652px;
    }
    ${mediaQuery.lt1200} {
        width: 544px;
    }
    ${mediaQuery.lt960} {
        width: 444px;
    }
    ${mediaQuery.lt768} {
        width: 100%;
    }
`;

export const TabContent = styled.div``;

export const TagsWrapper = styled.div`
    display: block;
`;
