import { connect } from 'react-redux';

import { State } from '../../types';

import { Feed } from './index';

const mapStateToProps = (state: State) => {
    const {
        page: { user },
    } = state;
    return {
        user,
    };
};

export const PageFeedContainer = connect(mapStateToProps)(Feed);
