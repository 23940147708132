import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

import { mediaQuery } from 'app/styled';
import { useClickOutside } from 'hooks/useClickOutside';
import { useDictionary } from 'hooks/useDictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useLocaleId } from 'hooks/useLocaleId';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { EllipsisVIcon } from 'components/icons2/EllipsisVIcon';
import { UserOutlineIcon } from 'components/icons2/UserOutlineIcon';
import { ANIMATION_DURATION } from 'components/image/constants';
import { ImageBlock } from 'components/image';
import { IconedTextIconProp } from 'components/iconed-text2/types';

import { GeoGenericExporter, MenuItemData } from '../../interfaces';
import { useGetMenuData } from '../../data';
import { ContextMenu } from '../menu';

import * as Styled from './styled';

export interface ContextMenuButtonProps {
    getCountries: () => Promise<GeoGenericExporter[]>;
    onClick: (name: string, item: MenuItemData) => void;
    iconComponent?: IconedTextIconProp['component'];
}

const LANGUAGES = {
    en: 'English (US)',
    pl: 'Polski',
    ru: 'Русский',
    pt: 'Português (Brasil)',
    zh: '中文(简体)',
};

export const ContextMenuButton: FC<ContextMenuButtonProps> = memo((props) => {
    const { onClick, getCountries, iconComponent } = props;

    const lcid = useLocaleId();
    const dic = useDictionary();

    const currentUser = useCurrentUser();
    const { image: srcImgCurrentUser, countryCode, countryName } = currentUser;

    const isMobile = useMatchMedia(mediaQuery.lt768);
    const [menuData, reset] = useGetMenuData({ getCountries, isMobile });

    const [isShow, setIsShow] = useState(false);
    const [isFirstLoadAvatar, setIsFirstLoadAvatar] = useState(true);

    const desktopRef = useRef<HTMLDivElement>(null);
    const mobileRef = useRef<HTMLDivElement>(null);

    const show = useCallback(() => {
        reset();
        setIsShow(true);
    }, [reset]);

    const hide = useCallback(() => {
        setIsShow(false);
    }, []);

    useClickOutside([desktopRef, mobileRef], hide);

    const modifiedItems = useMemo(() => {
        const mainMenu = {
            languages: {
                text: `${dic.word('wt_all__widget_menu__main_language')}: ${LANGUAGES[lcid]}`,
            },
            countries: {
                text: `${dic.word('wt_all__widget_menu__main_country')}: ${countryName}`,
            },
        };

        return {
            guest: mainMenu,
            user: mainMenu,
            languages: {
                [lcid]: {
                    selected: true,
                },
            },
            countries: {
                [countryCode || 'US']: {
                    selected: true,
                },
            },
        };
    }, [countryCode, countryName, dic, lcid]);

    const renderButtonIconComponent = useCallback((): IconedTextIconProp['component'] => {
        if (iconComponent) {
            return iconComponent;
        }

        // TODO какая-то непонятная ситуация мы сюда вставляем компоненты вместо просто иконок

        return currentUser.isAuth
            ? () => (
                  <ImageBlock
                      src={srcImgCurrentUser ?? undefined}
                      onLoad={() => {
                          setTimeout(() => {
                              setIsFirstLoadAvatar(false);
                          }, ANIMATION_DURATION);
                      }}
                      isActiveManifestation={isFirstLoadAvatar}
                      isUserDragActive={false}
                      width={24}
                      height={24}
                      borderRadius={4}
                  />
              )
            : () => (
                  <Styled.IconWrapper>
                      <EllipsisVIcon size={24} />
                      <UserOutlineIcon size={24} />
                  </Styled.IconWrapper>
              );
    }, [iconComponent, currentUser, currentUser.isAuth, srcImgCurrentUser, isFirstLoadAvatar]);

    return menuData ? (
        <>
            <Styled.StyledTippy
                appendTo="parent"
                interactive
                visible={isShow}
                arrow={false}
                placement="bottom-end"
                hideOnClick={false}
                content={
                    <Styled.DesktopWrapper ref={desktopRef}>
                        <ContextMenu
                            data={menuData}
                            modifiedItems={modifiedItems}
                            onClick={onClick}
                            onClose={hide}
                            isMobile={false}
                        />
                    </Styled.DesktopWrapper>
                }
            >
                <Styled.ButtonWrapper>
                    <ButtonIcon
                        icon={{
                            component: renderButtonIconComponent(),
                        }}
                        kind={ButtonIconKinds.GhostSecondary}
                        pressed={isShow}
                        onClick={show}
                        className="autotest__navigation__buttons_context_menu"
                    />
                </Styled.ButtonWrapper>
            </Styled.StyledTippy>
            <Styled.MobileWrapper ref={mobileRef} isShow={isShow}>
                <Styled.GlobalStyle isShow={isShow} />
                <ContextMenu data={menuData} modifiedItems={modifiedItems} onClick={onClick} onClose={hide} isMobile />
            </Styled.MobileWrapper>
        </>
    ) : null;
});
