import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import cn from 'classcat';

import { FinamRichSignalsProduct, FinamRichStrategyProduct } from 'types/finam-product';
import notify from 'services/notify';
import { useDictionary } from 'hooks/useDictionary';
import { ActionButtonItemType } from 'components/wt-popup3/footer';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';
import { CheckOutlineIcon } from 'components/icons2/CheckOutlineIcon';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import type { StrategyEditWizardProps } from '../..';
import { PopupState } from '../steps/types';
import * as LimexCopySteps from '../steps/copy-trading-strategies';
import * as SignalsSteps from '../steps/signals-trading-strategy';
import { ContentWrapper } from '../wizard/styled';

import { getUpdateSignalStrategyData } from './getUpdateSignalStrategyData';
import { getUpdateSignalStrategyFormValues } from './getUpdateSignalStrategyFormValues';
import * as Styled from './styled';
import { getUpdateCopyTradingStrategyFormValues } from './getUpdateCopyTradingStrategyFormValues';
import { getUpdateCopyTradingStrategyData } from './getUpdateCopyTradingStrategyData';

export const StrategyEditWizard: FC<StrategyEditWizardProps> = memo(
    ({
        productItem,
        className: classNameProp,
        updateCopyTradingStrategy,
        isShown,
        onClose,
        checkProfanity,
        getMyProduct,
        updateSignalStrategy,
        updateMySpaceSignalStrategyIsLoading,
        updateMySpaceSignalStrategyError,
        updateMySpaceCopyTradingStrategyError,
        updateMySpaceCopyTradingStrategyIsLoading,
        getAccountsForNewStrategy,
        accountsForNewStrategy,
        accountsForNewStrategyIsReady,
        accountsForNewStrategyIsLoading,
    }) => {
        const dic = useDictionary();

        const [stepIndex, setStepIndex] = useState(0);

        const className = cn(['autotest__product-wizard-widget', classNameProp]);

        const goBack = useCallback(() => {
            setStepIndex((index) => index - 1);
        }, []);

        const goNext = useCallback(async () => {
            setStepIndex((index) => index + 1);
        }, []);

        const limexCopyStep2FormValues = useRef<LimexCopySteps.Step2FormValues>();
        const limexCopyStep3FormValues = useRef<LimexCopySteps.Step3FormValues>();
        const limexCopyStep4FormValues = useRef<LimexCopySteps.Step4FormValues>();

        const signalsStep1FormValues = useRef<SignalsSteps.Step1FormValues>();

        useEffect(() => {
            if (productItem.id) {
                getMyProduct({ id: productItem.id }).then(({ data }) => {
                    switch (data?.productType) {
                        case 'UsStrategy': {
                            const { step2FormValues, step3FormValues, step4FormValues } =
                                getUpdateCopyTradingStrategyFormValues(data as FinamRichStrategyProduct);

                            limexCopyStep2FormValues.current = step2FormValues;
                            limexCopyStep3FormValues.current = step3FormValues;
                            limexCopyStep4FormValues.current = step4FormValues;
                            break;
                        }
                        case 'UsSignal': {
                            const { step1FormValues, step3FormValues, step4FormValues } =
                                getUpdateSignalStrategyFormValues(data as FinamRichSignalsProduct);
                            signalsStep1FormValues.current = step1FormValues;
                            limexCopyStep3FormValues.current = step3FormValues;
                            limexCopyStep4FormValues.current = step4FormValues;

                            break;
                        }
                        default:
                            break;
                    }

                    setStepIndex(1);
                });
            }
        }, [getMyProduct, productItem, productItem.id, productItem.productType]);

        const [popupState, setPopupState] = useState<PopupState>({});
        const { title, steps, actionButtons } = popupState;

        const handlePopupStateChange = useCallback((state: PopupState) => {
            setPopupState(state);
        }, []);

        const stepProps = {
            checkProfanity,
            stepIndex,
            goBack,
            goNext,
            onPopupStateChange: handlePopupStateChange,
            totalSteps: 3,
        };

        const step = (() => {
            switch (stepIndex) {
                case 1:
                    return productItem.productType === 'UsStrategy' ? (
                        <LimexCopySteps.Step2
                            {...stepProps}
                            getBackOfficeAccounts={getAccountsForNewStrategy}
                            backOfficeAccounts={accountsForNewStrategy}
                            accountsForNewStrategyIsReady={accountsForNewStrategyIsReady}
                            accountsForNewStrategyIsLoading={accountsForNewStrategyIsLoading}
                            valuesRef={limexCopyStep2FormValues}
                            hidePrice
                        />
                    ) : (
                        <SignalsSteps.Step1 {...stepProps} valuesRef={signalsStep1FormValues} />
                    );
                case 2:
                    return (
                        <LimexCopySteps.Step3
                            {...stepProps}
                            valuesRef={limexCopyStep3FormValues}
                            descriptionLabel="universe"
                        />
                    );
                case 3:
                    return <LimexCopySteps.Step4 {...stepProps} valuesRef={limexCopyStep4FormValues} />;
                default:
                    return null;
            }
        })();

        const theme = useTheme();

        const handleSuccess = useCallback(() => {
            notify.success({
                text: dic.word('wt_all__widget_product_wizard__notification_success_save'),
                icon: {
                    component: CheckOutlineIcon,
                    fill: theme.staticColors.positive,
                },
            });
            limexCopyStep2FormValues.current = undefined;
            limexCopyStep3FormValues.current = undefined;
            limexCopyStep4FormValues.current = undefined;
            signalsStep1FormValues.current = undefined;
            setStepIndex(1);
            onClose();
        }, [dic, onClose, theme.staticColors.positive]);

        const handleError = useCallback(() => {
            const error = updateMySpaceCopyTradingStrategyError || updateMySpaceSignalStrategyError;
            if (error) {
                const message = error?.message;
                if (message) {
                    notify.error(message);
                } else {
                    notify.error(dic.word('wt_all__widget_product_wizard__notification_cant_save'));
                }
            }
        }, [dic, updateMySpaceCopyTradingStrategyError, updateMySpaceSignalStrategyError]);

        const submitCopyTradingStrategyButton: ActionButtonItemType = useMemo(
            () => ({
                key: 1,
                kind: 'primary',
                content: 'Save',
                onClick: () => {
                    if (
                        limexCopyStep2FormValues.current &&
                        limexCopyStep3FormValues.current &&
                        limexCopyStep4FormValues.current
                    ) {
                        const productData = getUpdateCopyTradingStrategyData(
                            limexCopyStep2FormValues.current,
                            limexCopyStep3FormValues.current,
                            limexCopyStep4FormValues.current,
                        );
                        if (productItem.id) {
                            updateCopyTradingStrategy({ productId: productItem.id, requestBody: productData })
                                .then(handleSuccess)
                                .catch(handleError);
                        }
                    }
                },
                isDisabled: actionButtons?.items[0]?.isDisabled,
                isProcessing: updateMySpaceCopyTradingStrategyIsLoading,
                className: 'autotest__marketplace__product_wizard__btn_next',
            }),
            [
                actionButtons?.items,
                updateMySpaceCopyTradingStrategyIsLoading,
                productItem.id,
                updateCopyTradingStrategy,
                handleSuccess,
                handleError,
            ],
        );

        const submitSignalStrategyButton: ActionButtonItemType = useMemo(
            () => ({
                key: 1,
                kind: 'primary',
                content: 'Save',
                onClick: () => {
                    if (
                        signalsStep1FormValues.current &&
                        limexCopyStep3FormValues.current &&
                        limexCopyStep4FormValues.current
                    ) {
                        const productData = getUpdateSignalStrategyData(
                            signalsStep1FormValues.current,
                            limexCopyStep3FormValues.current,
                            limexCopyStep4FormValues.current,
                        );
                        if (productItem.id) {
                            updateSignalStrategy({ productId: productItem.id, requestBody: productData })
                                .then(handleSuccess)
                                .catch(handleError);
                        }
                    }
                },
                isDisabled: actionButtons?.items[0]?.isDisabled,
                isProcessing: updateMySpaceSignalStrategyIsLoading,
                className: 'autotest__marketplace__product_wizard__btn_next',
            }),
            [
                actionButtons?.items,
                updateMySpaceSignalStrategyIsLoading,
                productItem.id,
                updateSignalStrategy,
                handleSuccess,
                handleError,
            ],
        );

        const popupActionButtons = useMemo(() => {
            if (stepIndex < 3) {
                return actionButtons;
            }
            return {
                direction: 'vertical' as const,
                items: [
                    productItem.productType === 'UsStrategy'
                        ? submitCopyTradingStrategyButton
                        : submitSignalStrategyButton,
                ],
            };
        }, [
            actionButtons,
            productItem.productType,
            stepIndex,
            submitCopyTradingStrategyButton,
            submitSignalStrategyButton,
        ]);

        if (!isShown) {
            return null;
        }

        return (
            <WtPopup3
                title={title}
                size={SizeWtPopup3Enum.SMALL}
                width={464}
                close={onClose}
                steps={steps}
                actionButtons={popupActionButtons}
                className={className}
                isMobileFullscreen
            >
                {stepIndex === 0 ? (
                    <Styled.Loading>
                        <ProcessingIcon />
                    </Styled.Loading>
                ) : (
                    <ContentWrapper>{step}</ContentWrapper>
                )}
            </WtPopup3>
        );
    },
);
