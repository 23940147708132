import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const SheetTopCornIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path d="M13.7266 0.967186H2.625V19.0062H17.4094V4.5L13.7266 0.967186Z" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90747 6.41406C4.90747 6.2473 5.04265 6.11212 5.20941 6.11212H14.7657C14.9324 6.11212 15.0676 6.2473 15.0676 6.41406C15.0676 6.58081 14.9324 6.716 14.7657 6.716H5.20941C5.04265 6.716 4.90747 6.58081 4.90747 6.41406Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90747 8.7C4.90747 8.53324 5.04265 8.39806 5.20941 8.39806H14.7657C14.9324 8.39806 15.0676 8.53324 15.0676 8.7C15.0676 8.86675 14.9324 9.00193 14.7657 9.00193H5.20941C5.04265 9.00193 4.90747 8.86675 4.90747 8.7Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90747 10.9844C4.90747 10.8176 5.04265 10.6824 5.20941 10.6824H14.7657C14.9324 10.6824 15.0676 10.8176 15.0676 10.9844C15.0676 11.1511 14.9324 11.2863 14.7657 11.2863H5.20941C5.04265 11.2863 4.90747 11.1511 4.90747 10.9844Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90747 13.2703C4.90747 13.1036 5.04265 12.9684 5.20941 12.9684H14.7657C14.9324 12.9684 15.0676 13.1036 15.0676 13.2703C15.0676 13.4371 14.9324 13.5722 14.7657 13.5722H5.20941C5.04265 13.5722 4.90747 13.4371 4.90747 13.2703Z"
            fill="#B0BEC5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90747 15.5562C4.90747 15.3895 5.04265 15.2543 5.20941 15.2543H9.29534C9.4621 15.2543 9.59728 15.3895 9.59728 15.5562C9.59728 15.723 9.4621 15.8582 9.29534 15.8582H5.20941C5.04265 15.8582 4.90747 15.723 4.90747 15.5562Z"
            fill="#B0BEC5"
        />
        <path
            d="M17.1015 3.68594L14.4984 1.075C14.1921 0.785938 13.7875 0.625 13.3656 0.625H2.66245C2.42026 0.625 2.22339 0.821875 2.22339 1.06406V18.9359C2.22339 19.1781 2.42026 19.375 2.66245 19.375H17.339C17.5812 19.375 17.7781 19.1781 17.7781 18.9359V4.8875C17.7765 4.43281 17.4328 3.99844 17.1015 3.68594ZM17.1515 18.75H2.84839V1.25H12.9515C13.2828 1.25 13.5531 1.51875 13.5531 1.85156V4.64531H16.2625C16.7531 4.64531 17.1515 4.91562 17.1515 5.53437V18.75Z"
            fill="#6FBFF0"
        />
    </EmojiIconSvg>
);
