const urlHashCommentPrefix = '#comment-';

export function getCommentOptionsFromUrl() {
    const action = new URLSearchParams(window.location.search).get('action');
    const hasHashCommentInUrl = window.location?.hash.includes(urlHashCommentPrefix);
    const hashCommentId = hasHashCommentInUrl ? window.location?.hash.split(urlHashCommentPrefix)?.[1] : undefined;
    return {
        hashCommentId: hashCommentId ? parseInt(hashCommentId, 10) : undefined,
        isHighlight: action === 'highlight',
    };
}
