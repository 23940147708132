import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';

export const FeaturesHeader: FC = () => {
    const dic = useDictionary();
    return (
        <svg width="416" height="120" viewBox="0 0 416 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM8.29484 4.77027C8.44411 4.60742 8.43311 4.35439 8.27027 4.20512C8.10742 4.05584 7.85439 4.06684 7.70511 4.22969L5.23741 6.92173L4.28282 5.96714C4.12661 5.81093 3.87334 5.81093 3.71713 5.96714C3.56092 6.12334 3.56092 6.37661 3.71713 6.53282L4.96713 7.78282C5.04428 7.85997 5.14959 7.90225 5.25867 7.89988C5.36774 7.89751 5.47112 7.85069 5.54484 7.77027L8.29484 4.77027Z"
                fill="#0AA461"
            />
            <text
                fill="#919CB6"
                xmlSpace="preserve"
                style={{ whiteSpace: 'pre' }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
            >
                <tspan x="0" y="28.3636">
                    {dic.word('wt_myspace__widget_product_wizard__text_block_title_1')}
                </tspan>
            </text>
            <rect y="36" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect y="44" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect y="52" width="48" height="4" rx="2" fill="#D9E2F7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M232.5 11C235.261 11 237.5 8.76142 237.5 6C237.5 3.23858 235.261 1 232.5 1C229.739 1 227.5 3.23858 227.5 6C227.5 8.76142 229.739 11 232.5 11ZM234.795 4.77027C234.944 4.60742 234.933 4.35439 234.77 4.20512C234.607 4.05584 234.354 4.06684 234.205 4.22969L231.737 6.92173L230.783 5.96714C230.627 5.81093 230.373 5.81093 230.217 5.96714C230.061 6.12334 230.061 6.37661 230.217 6.53282L231.467 7.78282C231.544 7.85997 231.65 7.90225 231.759 7.89988C231.868 7.89751 231.971 7.85069 232.045 7.77027L234.795 4.77027Z"
                fill="#0AA461"
            />
            <text
                fill="#919CB6"
                xmlSpace="preserve"
                style={{ whiteSpace: 'pre' }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
            >
                <tspan x="226.5" y="28.3636">
                    {dic.word('wt_myspace__widget_product_wizard__text_block_title_2')}
                </tspan>
            </text>
            <rect x="226.5" y="36" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect x="226.5" y="44" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect x="226.5" y="52" width="48" height="4" rx="2" fill="#D9E2F7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 75C8.76142 75 11 72.7614 11 70C11 67.2386 8.76142 65 6 65C3.23858 65 1 67.2386 1 70C1 72.7614 3.23858 75 6 75ZM8.29484 68.7703C8.44411 68.6074 8.43311 68.3544 8.27027 68.2051C8.10742 68.0558 7.85439 68.0668 7.70511 68.2297L5.23741 70.9217L4.28282 69.9671C4.12661 69.8109 3.87334 69.8109 3.71713 69.9671C3.56092 70.1233 3.56092 70.3766 3.71713 70.5328L4.96713 71.7828C5.04428 71.86 5.14959 71.9023 5.25867 71.8999C5.36774 71.8975 5.47112 71.8507 5.54484 71.7703L8.29484 68.7703Z"
                fill="#0AA461"
            />
            <text
                fill="#919CB6"
                xmlSpace="preserve"
                style={{ whiteSpace: 'pre' }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
            >
                <tspan x="0" y="92.3636">
                    {dic.word('wt_myspace__widget_product_wizard__text_block_title_3')}
                </tspan>
            </text>
            <rect y="100" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect y="108" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect y="116" width="48" height="4" rx="2" fill="#D9E2F7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M232.5 75C235.261 75 237.5 72.7614 237.5 70C237.5 67.2386 235.261 65 232.5 65C229.739 65 227.5 67.2386 227.5 70C227.5 72.7614 229.739 75 232.5 75ZM234.795 68.7703C234.944 68.6074 234.933 68.3544 234.77 68.2051C234.607 68.0558 234.354 68.0668 234.205 68.2297L231.737 70.9217L230.783 69.9671C230.627 69.8109 230.373 69.8109 230.217 69.9671C230.061 70.1233 230.061 70.3766 230.217 70.5328L231.467 71.7828C231.544 71.86 231.65 71.9023 231.759 71.8999C231.868 71.8975 231.971 71.8507 232.045 71.7703L234.795 68.7703Z"
                fill="#0AA461"
            />
            <text
                fill="#919CB6"
                xmlSpace="preserve"
                style={{ whiteSpace: 'pre' }}
                fontFamily="Inter"
                fontSize="12"
                fontWeight="500"
                letterSpacing="0em"
            >
                <tspan x="226.5" y="92.3636">
                    {dic.word('wt_myspace__widget_product_wizard__text_block_title_4')}
                </tspan>
            </text>
            <rect x="226.5" y="100" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect x="226.5" y="108" width="189.5" height="4" rx="2" fill="#D9E2F7" />
            <rect x="226.5" y="116" width="48" height="4" rx="2" fill="#D9E2F7" />
        </svg>
    );
};
