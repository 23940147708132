import { createContext, Dispatch, SetStateAction } from 'react';
import noop from 'lodash/noop';

export type ListBoxContextType<ValueType> = {
    isOpened: boolean;
    setIsOpened: Dispatch<SetStateAction<boolean>>;
    currentValue?: ValueType;
    setCurrentValue: (v: ValueType) => void;
    noSelectedText: string;
    visualState: 'normal' | 'emergency';
    onChangeIsOpen?: (value: boolean) => void;
};

// Тут не решить т.к. конкретный тип существует только внутри компонента где этот контекст используется.
// Лучше это решить сделав компонент не generic или убрав контекст.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListBoxContext = createContext<ListBoxContextType<any>>({
    isOpened: false,
    setIsOpened: noop,
    setCurrentValue: noop,
    noSelectedText: '',
    visualState: 'normal',
});
