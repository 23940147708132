import { AxiosError } from 'axios';

import { FileType } from 'types';
import { backendClient, isHTTPError, Response, withAbortController, CancelCallback } from 'services/backend-client';
import UrlProcessor from 'services/url-processor';
import { ValidationError } from 'services/validation-error';
import { AttachmentServerResponse, Attachment, AttachmentInput } from 'hooks/useAttachment';

type AttachmentError = Error & { isAttachmentError: true };

export const uploadAttachment = async (
    { file }: AttachmentInput,
    fileType: FileType,
    csrf: string,
    cancelCb?: CancelCallback,
) => {
    const url = UrlProcessor.page('ng_api_v1_file_upload_image').path();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', `${fileType}`);
    formData.append('csrf', csrf);

    return backendClient
        .post<FormData, Response<AttachmentServerResponse>>(url, formData, {
            ...withAbortController(cancelCb),
        })
        .then(({ data }) => {
            if (!('files' in data)) {
                return handleError(
                    new ValidationError(data.violations.map((violation) => ({ ...violation, field: 'attachment' }))),
                );
            }
            return data.files[0];
        })
        .catch((error: AxiosError<AttachmentServerResponse & { message?: string }>) => {
            const data = error.response?.data;
            if (data && typeof data === 'object' && 'violations' in data) {
                data.message = data.violations[0].message;
            }
            return handleError(error);
        });
};

export const deleteAttachment = (attachment: Attachment, cancelCb?: CancelCallback) => {
    if (!attachment.id) return Promise.reject();
    const url = UrlProcessor.page('ng_marketplace_product_api_image_delete').path();

    return backendClient
        .post(
            url,
            { id: attachment.id },
            {
                ...withAbortController(cancelCb),
            },
        )
        .then(({ data }) => data)
        .catch(handleError);
};

const handleError = (error: unknown) => {
    if (isHTTPError(error)) {
        const { message: errorMessage } = (error as AxiosError<{ message?: string }>).response?.data || {
            message: undefined,
        };

        if (errorMessage) {
            const validationError = new Error() as AttachmentError;
            validationError.isAttachmentError = true;
            validationError.message = errorMessage;

            return Promise.reject(validationError);
        }
    }

    return Promise.reject(error);
};

export function isAttachmentError(error: unknown): error is AttachmentError {
    return !!error && typeof error === 'object' && 'isAttachmentError' in error;
}
