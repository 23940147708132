import { useCallback, useState } from 'react';
import produce from 'immer';

import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { useDictionary } from 'hooks/useDictionary';
import { ActionButtonsProps } from 'components/card/types';

export function useStrategySubscribe(id: number | undefined, actionButtons?: ActionButtonsProps | undefined) {
    const dic = useDictionary();

    const [isShowBrokerageAccountPopup, setIsShowBrokerageAccountPopup] = useState(false);

    const hideBrokerageAccountPopup = useCallback(() => {
        setIsShowBrokerageAccountPopup(false);
    }, []);

    const showBrokerageAccountPopup = TODOuseActionAfterAuthentication(() => {
        setIsShowBrokerageAccountPopup(true);
    });

    const updatedActionButtons = produce(actionButtons, (draft) => {
        if (!draft || !('items' in draft)) {
            return;
        }
        if (!id) {
            draft.items = [];
        }
        const subscribeButton = draft.items[1];
        subscribeButton.content = dic.word('wt_mplc2__widget_products__button_subscribe');
        subscribeButton.onClick = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            showBrokerageAccountPopup();
        };
    });

    return { isShowBrokerageAccountPopup, showBrokerageAccountPopup, hideBrokerageAccountPopup, updatedActionButtons };
}
