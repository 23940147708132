import React from 'react';

import { CardContainerProps } from './types';
import * as Styled from './styled';

export const CardContainer = (props: CardContainerProps) => {
    const {
        children,
        onClick,
        className,
        dataAttributes,
        isLoading = false,
        width,
        isWidthLimits,
        target,
        withoutBoxShadow,
        url,
        isHeightGrow = false,
        isDisable = false,
    } = props;

    return (
        <Styled.CardContainerElement
            onClick={onClick}
            className={className}
            dataAttributes={dataAttributes}
            isLoading={isLoading}
            isHeightGrow={isHeightGrow}
            width={width}
            isWidthLimits={isWidthLimits}
            target={target}
            withoutBoxShadow={withoutBoxShadow}
            url={url}
            isDisable={isDisable}
        >
            {children}
        </Styled.CardContainerElement>
    );
};
