import React, { forwardRef, memo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { useMultipleRefs } from 'hooks/useMultipleRefs';
import { NumberInput } from 'components/number-input';

import { rulesComparer } from '../utils/rulesComparer';

import { FormNumberInputProps } from './types';

export const FormNumberInput = memo(
    forwardRef<HTMLInputElement, FormNumberInputProps>(
        ({ name, rules, hideErrors = false, ...numberInputProps }, forwardedRef) => {
            const {
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
            } = useController<{ [key: string]: number | undefined }>({ name, rules });

            const refFunction = useMultipleRefs(forwardedRef, ref);

            const handleOnChange = useCallback(
                (newValue: number | '') => {
                    onChange(typeof newValue === 'number' ? newValue : undefined);
                },
                [onChange],
            );

            return (
                <NumberInput
                    value={value ?? ''}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    ref={refFunction}
                    error={hideErrors ? undefined : error?.message}
                    errorHighlight={!hideErrors && error !== undefined}
                    {...numberInputProps}
                />
            );
        },
    ),
    rulesComparer,
);
