import styled from 'styled-components';

export const EditorWrapper = styled.div`
    min-height: 106px;
`;

export const EditorFallback = styled.div`
    min-height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
        fill: ${({ theme }) => theme.label.secondary};
    }
`;
