import React, { useMemo } from 'react';

import { Dic } from 'services/dictionary/dictionary';
import { useDictionary } from 'hooks/useDictionary';
import { FinamPricePeriodFormatter } from 'widgets/formatting/finam-price-period-formatter';
import { DefaultCardPropsType } from 'components/card';
import { InstrumentCardProps } from 'components/card/builds/instrument-card/types';
import { CheckOutlineIcon } from 'components/icons2/CheckOutlineIcon';
import { ClockOutlineIcon } from 'components/icons2/ClockOutlineIcon';
import { LockIcon } from 'components/icons2/LockIcon';

import { ProductCardFinamPropsType } from '../types';

const tagIcon = (productStatus?: string | null) => {
    switch (productStatus) {
        case 'Created':
            return ClockOutlineIcon;
        case 'ApprovedByModerator':
            return ClockOutlineIcon;
        case 'Published':
            return CheckOutlineIcon;
        default:
            return null;
    }
};

export const useFinamCardSharedProps = (
    props: ProductCardFinamPropsType,
    autoClass: string,
): DefaultCardPropsType | InstrumentCardProps => {
    const { item, className: classNameProp, onClick: onClickProp, user, ...restProps } = props;
    const dic = useDictionary();

    const { name, backgroundImageUrl, price, productStatus, archivedAt } = item ?? {};
    return useMemo(() => {
        const className = [classNameProp, `autotest__product-card__${autoClass}`].filter((i) => i).join(' ');
        const tagProps = archivedAt
            ? {
                  tagText: dic.word('wt__product_default_card__status_archived'),
                  tagIcon: LockIcon,
              }
            : {
                  tagText: productStatus?.replace(/([A-Z])/g, ' $1'),
                  tagIcon: tagIcon(productStatus),
              };
        return {
            title: name || '',
            className,
            author: user?.displayName,
            authorAvatarSrc: user?.image,
            freePriceWord: Dic.word('wt_all__product_card__default__free_price_word'),
            thumbnailSrc: backgroundImageUrl || '',
            onClick: (e) =>
                item &&
                (productStatus === undefined || productStatus === 'Published' || productStatus === 'Created') &&
                onClickProp?.(e, item),
            productId: item?.id,
            formattedPricePeriod: (
                <FinamPricePeriodFormatter price={price}>
                    {({ localizedPrice, localizedPeriod }) => `${localizedPrice}${localizedPeriod}`}
                </FinamPricePeriodFormatter>
            ),
            ...tagProps,
            ...restProps,
        };
    }, [
        classNameProp,
        autoClass,
        name,
        user?.displayName,
        user?.image,
        backgroundImageUrl,
        item,
        price,
        productStatus,
        archivedAt,
        dic,
        restProps,
        onClickProp,
    ]);
};
