import React from 'react';
import cc from 'classcat';

import { GeneratorType } from 'types/feed-record';
import { ActionPossibility } from 'widgets/action-possibility';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { UserCheckIcon } from 'components/icons2/UserCheckIcon';
import { UserPlusIcon } from 'components/icons2/UserPlusIcon';

import { StyledUserSubscribe, StyledInstrumentSubscribe } from './styled';

type ButtonIconProps = React.ComponentProps<typeof ButtonIcon>;

export interface Props {
    isSubscribed: boolean;
    onClick: () => Promise<void> | undefined;
    generatorType: GeneratorType;
    className: string;
    clickable?: boolean;
    isLoading: boolean;
    buttonProps?: ButtonIconProps;
}
export const SubscribeButton = (props: Props) => {
    const { generatorType, isSubscribed, onClick, className, isLoading, clickable = true, buttonProps } = props;
    const childProps: ButtonIconProps = {
        className: cc([className, `autotest__widget_action_subscribe_${isSubscribed ? '' : 'un'}subscribed`]),
        onClick,
        isProcessing: isLoading,
        ...(buttonProps || {}),
    };

    return (
        <ActionPossibility isPossible={!buttonProps?.disabled}>
            {generatorType === GeneratorType.USER ? (
                <StyledUserSubscribe
                    clickable={clickable}
                    icon={{ component: isSubscribed ? UserCheckIcon : UserPlusIcon }}
                    kind={ButtonIconKinds.FillSecondary}
                    {...childProps}
                />
            ) : (
                <StyledInstrumentSubscribe pressed={isSubscribed} {...childProps} />
            )}
        </ActionPossibility>
    );
};

SubscribeButton.defaultProps = {
    className: '',
    clickable: true,
    buttonProps: {},
};
