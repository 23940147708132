import { useEffect, useMemo } from 'react';

import { useCSRFToken } from 'hooks/useCSRFToken';

import { NotificationsState } from '../types';
import { FetchNotifications, ReadInStoreNotifications, ReadNotifications } from '../actions';
import { getHasUnread } from '../utils';

type UseManageToggleViewNotificationsProps = {
    fetchNotifications: FetchNotifications;
    readInStoreNotifications: ReadInStoreNotifications;
    readNotifications: ReadNotifications;
    readDate: NotificationsState['readDate'];
    unreadCount: NotificationsState['unreadCount'];
    isWasFetchNotifications: NotificationsState['isWasFetchNotifications'];
    isOpened: NotificationsState['isOpened'];
    isError: NotificationsState['isError'];
};

export const useManageToggleViewNotifications = (props: UseManageToggleViewNotificationsProps) => {
    const {
        fetchNotifications,
        readInStoreNotifications,
        readNotifications,
        readDate,
        unreadCount,
        isWasFetchNotifications,
        isOpened,
        isError,
    } = props;

    const csrf = useCSRFToken();
    const hasUnread = useMemo(() => getHasUnread(unreadCount), [unreadCount]);

    useEffect(() => {
        if (isOpened && !isWasFetchNotifications) {
            // если уведомления еще не были получены через fetchNotifications, то получаем уведомления
            fetchNotifications();
        }

        if (!isOpened && !isError && isWasFetchNotifications && hasUnread) {
            // если закрываем (NotificationsList unmounted), то читаем в сторе
            readInStoreNotifications();
        }
    }, [isOpened]);

    useEffect(() => {
        if (isOpened && !isError && readDate && hasUnread && csrf) {
            // если были загружены уведомления без ошибок, то читаем уведомления на бэке
            readNotifications({ csrf, readDate });
        }
    }, [isWasFetchNotifications]);
};
