import { Action, Id } from 'types';
import { Actions } from 'services/create-action-types/backend-client';
import { NotificationService } from 'services/api-services/generated_limex/services/NotificationService';
import { createThunkActions } from 'services/create-action-types/api-services';
import { OnCancel } from 'services/create-action-types/api-services/types';

import { NotificationItem } from './types';

const [FETCH_NOTIFICATIONS_ACTIONS_TYPES, fetchNotificationsThunk, fetchNotificationsActions] = createThunkActions(
    'widgets',
)('HEADER_NOTIFICATIONS_FETCH', NotificationService.getNotificationList)();

type _FetchNotificationsPayloadPassWithSpecialState = {
    combineNotifications?: boolean;
};
type FetchNotificationsActionArgs = {
    cursor?: string | undefined;
    limit?: number | undefined;
    combineNotifications?: boolean; // true = соединяет данные store и данных с запроса получения уведомлений
    onCancel?: OnCancel;
} & _FetchNotificationsPayloadPassWithSpecialState;
const fetchNotifications =
    (args?: FetchNotificationsActionArgs): ReturnType<typeof fetchNotificationsThunk> =>
    (dispatch, getState, extraArgument) =>
        fetchNotificationsThunk({
            params: {
                limit: args?.limit,
                cursor: args?.cursor,
            },
            pass: {
                combineNotifications: args?.combineNotifications,
            },
            onCancel: args?.onCancel,
        })(dispatch, getState, extraArgument);

type FetchNotifications = Action<typeof fetchNotifications>;
type FetchNotificationsActions = Actions<typeof fetchNotificationsActions>;
export { FETCH_NOTIFICATIONS_ACTIONS_TYPES, fetchNotifications, FetchNotifications, FetchNotificationsActions };

const [READ_NOTIFICATIONS_ACTIONS_TYPES, readNotificationsThunk, readNotificationsActions] = createThunkActions(
    'widgets',
)('HEADER_NOTIFICATIONS_READ', NotificationService.readNotifications)();
type ReadNotifications = Action<typeof readNotifications>;
type ReadNotificationsActions = Actions<typeof readNotificationsActions>;

type ReadNotificationsArgs = {
    csrf: string;
    readDate: string;
};
const readNotifications =
    (args?: ReadNotificationsArgs): ReturnType<typeof readNotificationsThunk> =>
    (dispatch, getState, extraArgument) => {
        const csrf = args?.csrf || '';
        const readDate = args?.readDate || '';

        return readNotificationsThunk({
            params: { requestBody: { csrf, readDate } },
        })(dispatch, getState, extraArgument);
    };
export { READ_NOTIFICATIONS_ACTIONS_TYPES, readNotifications, ReadNotifications, ReadNotificationsActions };

const [DELETE_NOTIFICATION_ACTIONS_TYPES, deleteNotificationThunk, deleteNotificationActions] = createThunkActions(
    'widgets',
)('HEADER_NOTIFICATIONS_DELETE', NotificationService.deleteNotification)();

type DeleteNotificationsArgs = {
    id: Id;
    csrf: string;
};
const deleteNotification =
    (args: DeleteNotificationsArgs): ReturnType<typeof deleteNotificationThunk> =>
    (dispatch, getState, extraArgument) => {
        const { id, csrf } = args;

        return deleteNotificationThunk({
            params: {
                notificationId: +id,
                requestBody: {
                    csrf,
                },
            },
            pass: {
                id,
            },
        })(dispatch, getState, extraArgument);
    };
type DeleteNotification = Action<typeof deleteNotification>;
type DeleteNotificationActions = Actions<typeof deleteNotificationActions>;
export { DELETE_NOTIFICATION_ACTIONS_TYPES, deleteNotification, DeleteNotification, DeleteNotificationActions };

// BLOCK WITHOUT REQUEST ACTIONS

// SET_IS_OPENED
const SET_IS_OPENED_ACTION = 'widgets/HEADER_NOTIFICATIONS_SET_IS_OPENED' as const;
const setIsOpened = (payload: boolean) => ({
    type: SET_IS_OPENED_ACTION,
    payload,
});
type SetIsOpened = Action<typeof setIsOpened>;
type SetIsOpenedActions = ReturnType<typeof setIsOpened>;
export { SET_IS_OPENED_ACTION, setIsOpened, SetIsOpened, SetIsOpenedActions };
// UPDATE_UNREAD
const UPDATE_UNREAD_COUNT_ACTION = 'widgets/HEADER_NOTIFICATIONS_UPDATE_UNREAD_COUNT' as const;
const updateUnreadCount = (payload: number) => ({
    type: UPDATE_UNREAD_COUNT_ACTION,
    payload,
});
type UpdateUnreadCount = Action<typeof updateUnreadCount>;
type UpdateUnreadCountActions = ReturnType<typeof updateUnreadCount>;
export { UPDATE_UNREAD_COUNT_ACTION, updateUnreadCount, UpdateUnreadCount, UpdateUnreadCountActions };

// ADD_IN_STORE
const ADD_IN_STORE_NOTIFICATION_ACTION = 'widgets/HEADER_NOTIFICATIONS_ADD_IN_STORE_NOTIFICATION' as const;
const addInStoreNotification = (payload: NotificationItem) => ({
    type: ADD_IN_STORE_NOTIFICATION_ACTION,
    payload,
});
type AddInStoreNotification = Action<typeof addInStoreNotification>;
type AddInStoreNotificationActions = ReturnType<typeof addInStoreNotification>;
export {
    ADD_IN_STORE_NOTIFICATION_ACTION,
    addInStoreNotification,
    AddInStoreNotification,
    AddInStoreNotificationActions,
};

// READ_IN_STORE
const READ_IN_STORE_NOTIFICATIONS_ACTION = 'widgets/HEADER_NOTIFICATIONS_READ_IN_STORE' as const;
const readInStoreNotifications = () => ({
    type: READ_IN_STORE_NOTIFICATIONS_ACTION,
});
type ReadInStoreNotifications = Action<typeof readInStoreNotifications>;
type ReadInStoreNotificationsActions = ReturnType<typeof readInStoreNotifications>;
export {
    READ_IN_STORE_NOTIFICATIONS_ACTION,
    readInStoreNotifications,
    ReadInStoreNotifications,
    ReadInStoreNotificationsActions,
};

export type NotificationsActions =
    | AddInStoreNotificationActions
    | FetchNotificationsActions
    | ReadNotificationsActions
    | ReadInStoreNotificationsActions
    | SetIsOpenedActions
    | UpdateUnreadCountActions
    | DeleteNotificationActions;
