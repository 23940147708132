import styled from 'styled-components';

import { tokens } from 'services/tokens';

export const CardThumbnailBlock = styled.div`
    position: relative;
    background-color: ${tokens.colors.opacity.base['16']};
    flex-grow: 0;
`;

export const CardContentBlock = styled.div`
    padding: 12px 24px 16px 24px;
    flex-grow: 1;
    position: relative;
`;

export const SkeletonWrapper = styled.div`
    display: inline-flex;
    svg {
        fill: ${tokens.colors.opacity.base['16']};
    }
`;
