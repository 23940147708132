import { nameToAction } from '../utils/nameToAction';

export type UpdateArgsAction = {
    type: `cache/UPDATE_${string}_ARGS`;
    payload: { requestName: string; args: unknown };
};

export function updateArgs<RESOURCE_NAME extends string>(
    typename: RESOURCE_NAME,
    requestName: string,
    args: unknown,
): UpdateArgsAction {
    return {
        type: `cache/UPDATE_${nameToAction(typename)}_ARGS`,
        payload: { requestName, args },
    };
}
