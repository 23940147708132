import { injectReducer } from 'app/store2';
import {
    BlogPostExporter,
    CommentExporter,
    ComonUsStrategyExporter,
    MarketplaceProductExporter,
    UserActionExporter,
} from 'services/api-services/generated_limex';

import reducer from './reducer';
import { NotificationsPulledState, NotificationsState } from './types';

export const getHasUnread = (unreadCount: NotificationsState['unreadCount']) => !!(unreadCount && unreadCount > 0);

type getIsShowBadgeArgs =
    | { unreadCount: NotificationsState['unreadCount']; isOpened: boolean }
    | { hasUnread: boolean; isOpened: boolean };

export const getIsShowBadge = (args: getIsShowBadgeArgs) => {
    let hasUnread = false;

    if ('hasUnread' in args) {
        hasUnread = args.hasUnread;
    } else if ('unreadCount' in args) {
        hasUnread = getHasUnread(args.unreadCount);
    }

    const { isOpened } = args;
    // isOpened чтобы сразу сбросить выделение при открытии
    // А также чтобы при срабатывании экшена addInStoreNotification и при открытом состоянии не появлялся зеленый индикатор
    return (hasUnread && !isOpened) || false;
};

export const injectWidgetNotificationsReducer = (pulledState: NotificationsPulledState = {}) => {
    injectReducer('widgets.notifications', reducer(pulledState));
};

export function isMarketplaceProduct(
    itemObj: UserActionExporter['object'] | undefined,
): itemObj is MarketplaceProductExporter {
    return (itemObj && 'image' in itemObj && 'title' in itemObj) || false;
}

export function isComonUsStrategy(
    itemObj: UserActionExporter['object'] | undefined,
): itemObj is ComonUsStrategyExporter {
    return (itemObj && 'name' in itemObj) || false;
}

export function isBlogPost(itemObj: UserActionExporter['object'] | undefined): itemObj is BlogPostExporter {
    return (itemObj && 'displayTitle' in itemObj) || false;
}

export function isComment(itemObj: UserActionExporter['object'] | undefined): itemObj is CommentExporter {
    return (itemObj && 'shortContent' in itemObj) || false;
}
