import type { Step1FormValues } from './types';

export const defaultValues: Step1FormValues = {
    riskLevel: '',
    recommendedHoldingPeriod: '',
    targetReturnAnnualized: undefined,
};

export const MIN_RETURN = 0;
export const MAX_RETURN = 1000000;
