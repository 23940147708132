import { shallowCamelizeKeys } from 'services/utils/replace-keys';

import type { RealTimeInstrumentInfo } from './types';
import { ServerConfigSource } from './types';

let realTimePromise: Promise<RealTimeInstrumentInfo>;

export const realTimeInstrumentInfoLoad = (chartServer: ServerConfigSource) => {
    if (realTimePromise) {
        return realTimePromise;
    }
    realTimePromise = import(/* webpackChunkName: "real-time-instrument-info" */ 'services/real-time-instrument-info');
    return realTimePromise?.then(({ setServer }) => {
        setServer(shallowCamelizeKeys(chartServer));
        return realTimePromise;
    });
};
