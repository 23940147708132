import styled from 'styled-components';

import { body17SemiboldFontStyle, caption12RegularFontStyle } from 'services/typography';

export const NoProducts = styled.div`
    margin-top: 16px;
    min-height: 200px;
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.bg.primary};
    border-radius: 12px;
    text-align: center;
`;

export const Header = styled.div`
    ${body17SemiboldFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    margin-bottom: 8px;
    margin-top: 8px;
`;

export const Text = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.tertiary};
`;
