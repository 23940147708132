import {
    Action,
    FeedRecord,
    FeedRecordInstrument,
    FeedRecordType,
    Id,
    isTickerNewsRecord,
    PublishStatusRecord,
    TickerNewsRecord,
} from 'types';
import { Actions, createThunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';

import { GlobalStateForFeed } from '../../types';

import { insertFeedRecord } from './insertFeedRecord';

type GetInsertPosition = (resords: FeedRecord[], insertingRecords: FeedRecord[]) => number;

function insertAfterFeedPosition(
    state: GlobalStateForFeed,
    insertingRecords: FeedRecord[],
    getInsertPosition: GetInsertPosition,
    instanceId: string,
) {
    const records = Object.values(state.widgets.feed.records);
    const pos = getInsertPosition(records, insertingRecords);
    if (isTickerNewsRecord(insertingRecords[0])) {
        console.log(
            `Feed news ${insertingRecords[0].tickerNews?.instrument?.ticker} - ${pos >= 0 ? 'SUCCESS' : 'SKIP'}`,
        );
    }
    if (pos >= 0) {
        return insertFeedRecord(insertingRecords, pos + 1, instanceId);
    }
    return null;
}
export type InsertAfterFeedPositionActions = NonNullable<ReturnType<typeof insertAfterFeedPosition>>;
let tickerNewsIdCounter = 1;
const TICKER_NEWS_DAYS = 25;
const TICKER_NEWS_LIMIT = 10;
export type FetchTickerNewsPayloadData = {
    records: PublishStatusRecord[];
    isSubscribed: boolean;
    instrument: FeedRecordInstrument;
};

const [FETCH_FEED_TICKER_NEWS_ACTION_TYPES, thunk, actions] = createThunkActions<'widgets', GlobalStateForFeed>()(
    'FETCH_FEED_TICKER_NEWS',
)<void, { records: FetchTickerNewsPayloadData[] }, { instanceId: string }, InsertAfterFeedPositionActions>();

function fetchTickerNews(
    finamIds: number[],
    lcid: string,
    getInsertPosition: GetInsertPosition,
    instanceId: string,
): ReturnType<typeof thunk> {
    return (dispatch, getState, extraArgument) => {
        const request = thunk({
            request: {
                url: UrlProcessor.page('ng_blogpost_instrument_news').path(),
                params: {
                    instruments: finamIds,
                    days: TICKER_NEWS_DAYS,
                    limit: TICKER_NEWS_LIMIT,
                    lcid,
                    feedContext: 'limex', // av: Указываем контекст загрузки ленты для ссылок @since #WTT-10201
                },
                withCredentials: true,
            },
            pass: { instanceId },
        })(dispatch, getState, extraArgument);

        return request.then((action) => {
            action.payload.data?.records?.forEach?.((tickerNews: FetchTickerNewsPayloadData) => {
                const { instrument, isSubscribed, records } = tickerNews;
                const tickerNewsId: Id = `tickerNews${tickerNewsIdCounter++}`;
                const tickerNewsRecord: TickerNewsRecord = {
                    id: tickerNewsId,
                    type: FeedRecordType.TICKER_NEWS,
                    tickerNews: {
                        instrument,
                        isSubscribed,
                    },
                };
                const insertingRecords: (PublishStatusRecord | TickerNewsRecord)[] = [tickerNewsRecord];
                records.forEach((record) => {
                    const recordForInsert: PublishStatusRecord = {
                        ...record,
                        tickerNews: { tickerNewsId },
                    };
                    insertingRecords.push(recordForInsert);
                });
                const insertAction = insertAfterFeedPosition(
                    getState(),
                    insertingRecords,
                    getInsertPosition,
                    instanceId,
                );
                if (insertAction) {
                    dispatch(insertAction);
                }
            });
            return action;
        });
    };
}

type Pop<T extends unknown[]> = T extends [...infer Rest, unknown] ? Rest : never;

type FetchTickerNews = Action<typeof fetchTickerNews>;
type FetchTickerNewsActions = Actions<typeof actions>;

export { FETCH_FEED_TICKER_NEWS_ACTION_TYPES, fetchTickerNews, FetchTickerNews, FetchTickerNewsActions };
