import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { mediaQuery } from 'app/styled';
import {
    body14RegularFontStyle,
    body17MediumFontStyle,
    body17RegularFontStyle,
    caption12MediumFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';
import { LockIcon as LockIconComponent } from 'components/icons2/LockIcon';
import { Button as ButtonComponent } from 'components/button2';

export const Card = styled.div`
    padding-bottom: 50%;
    height: 0;
    position: relative;
    width: 100%;
    display: flex;
    background: ${({ theme }) => css`
        radial-gradient(
            43.58% 80.24% at 98.96% 102.08%,
            ${rgba(theme.fillIn.primary.mid, 0.132)} 0%,
            ${rgba(theme.fillIn.primary.mid, 0)} 100%
        ),
       radial-gradient(
            102.08% 148.98% at -0.69% -2.08%,
            ${rgba(theme.label.placeholder, 0.4)} 0%,
            ${rgba(theme.label.placeholder, 0)} 61.8%,
            ${rgba(theme.label.placeholder, 0)} 69.1%
        ),
      ${theme.bg.secondary};
    `};
    border-radius: 8px;
    justify-content: center;

    &:before {
        display: block;
        content: '';
        padding-bottom: 50%;
    }
`;

export const Wrapper = styled.div`
    top: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 14px 24px;
    //padding: 79px 89px;

    button {
        max-width: 426px;
    }

    ${mediaQuery.lt768} {
        //padding: 45px 82px;
    }

    ${mediaQuery.lt480} {
        //padding: 14px 20px;
    }
`;

export const LockIcon = styled(LockIconComponent)`
    fill: ${({ theme }) => theme.label.secondary};
    margin-bottom: 4px;
`;

export const Text = styled.span`
    ${body17RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};

    ${mediaQuery.lt768} {
        ${caption12RegularFontStyle};
    }
`;

export const SecondaryText = styled.span`
    ${body17MediumFontStyle};
    color: ${({ theme }) => theme.label.accent};

    ${mediaQuery.lt768} {
        ${caption12MediumFontStyle};
    }
`;

export const Button = styled(ButtonComponent)`
    margin-top: 16px;
    width: 100%;

    ${mediaQuery.lt768} {
        margin-top: 8px;
    }
`;

export const PopupContent = styled.div`
    padding: 24px 0 32px;
    ${body14RegularFontStyle};
`;
