import { RefObject, useEffect } from 'react';

type ListenerOptions = Parameters<typeof addEventListener>[2];

type Params = {
    key: string;
    refElement?: RefObject<EventTarget | null>;
    onKeyDown?: (e?: KeyboardEvent) => void;
    onKeyUp?: (e?: KeyboardEvent) => void;
    onKeyDownOpts?: ListenerOptions;
    onKeyUpOpts?: ListenerOptions;
};

/**
 * Automatically add and remove keydown/keyup callbacks for targetKey
 * on some element (window by default). Callback are not added if element === null;
 *
 * @example
 * const textareaRef = useRef(null);
 * useKeyPress({
 *   key: 'Enter',
 *   element: textareaRef.current,
 *   onKeyDown: () => console.log('down'),
 *   onKeyUp: () => console.log('up'),
 * };
 */
export const useKeyPress = ({
    key,
    refElement = { current: window },
    onKeyDown,
    onKeyUp,
    onKeyDownOpts = {},
    onKeyUpOpts = {},
}: Params) => {
    useEffect(() => {
        const element = refElement?.current;
        if (element) {
            const downHandler = (e: Event) => {
                if (e instanceof KeyboardEvent && e.key === key) {
                    onKeyDown?.(e);
                }
            };

            const upHandler = (e: Event) => {
                if (e instanceof KeyboardEvent && e.key === key) {
                    onKeyUp?.(e);
                }
            };

            element.addEventListener('keydown', downHandler, onKeyDownOpts);
            element.addEventListener('keyup', upHandler, onKeyUpOpts);

            return () => {
                element.removeEventListener('keydown', downHandler);
                element.removeEventListener('keyup', upHandler);
            };
        }

        return undefined;
    }, [refElement?.current, key, onKeyDown, onKeyUp]);
};
