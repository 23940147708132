import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { Tabs, TabsItem, TabsProps } from 'components/tabs';
import { LockOutlineIcon } from 'components/icons2/LockOutlineIcon';

import { useIsOwner } from '../../../hooks';
import { isTabId, Props, TabIds } from '../types';

const tabsResponsiveSize: TabsProps['size'] = { default: 'medium', lt768: 'small' };

const MINIMUM_POSTS_COUNT_TO_SHOW_PREMIUM_TAB = 1;

export const TabsWidget = (props: Props) => {
    const { tabs, onTabChange, currentTab, isVerifiedAuthor, premiumSubscribed, ownerId, ownerAlias } = props;
    const ownerAliasUrlParam = `@${ownerAlias}`;
    const blogpostsCount = tabs?.blogpostsCount || 0;
    const premiumBlogpostsCount = tabs?.premiumBlogpostsCount || 0;

    const dic = useDictionary();
    const links = useLinks();

    const isOwner = useIsOwner();

    const navigate = useNavigate();

    const getLink = (itemId: TabIds) => {
        if (ownerAlias) {
            switch (itemId) {
                case 'premiumPosts':
                    return links.profilePremiumByAlias(ownerAliasUrlParam);
                case 'likesAndComments':
                    return links.profileLikesByAlias(ownerAliasUrlParam);
                case 'authorsProducts':
                    return links.profileProductsByAlias(ownerAliasUrlParam);
                case 'feed':
                default:
                    return links.profileByAlias(ownerAliasUrlParam);
            }
        } else {
            switch (itemId) {
                case 'premiumPosts':
                    return links.profilePremiumById(ownerId);
                case 'likesAndComments':
                    return links.profileLikesById(ownerId);
                case 'authorsProducts':
                    return links.profileProductsById(ownerId);
                case 'feed':
                default:
                    return links.profileById({ id: ownerId });
            }
        }
    };

    const handleTabChange: typeof onTabChange = (itemIndex: number, itemId: string | number) => {
        if (isTabId(itemId)) {
            onTabChange?.(itemIndex, itemId);
            navigate(getLink(itemId));
        }
    };

    const tabItems = useMemo(() => {
        let result: Array<TabsItem & { id: TabIds }> = [
            {
                id: 'feed',
                title: `${dic.word('wt_profile__widget_tabs__feed_tab')}${blogpostsCount ? ` ${blogpostsCount}` : ''}`,
                autotestClass: 'autotest__profile-page__tabs-item-posts',
            },
        ];
        if (
            isVerifiedAuthor &&
            (premiumSubscribed ||
                tabs?.activeTab ||
                isOwner ||
                (!isOwner && premiumBlogpostsCount >= MINIMUM_POSTS_COUNT_TO_SHOW_PREMIUM_TAB))
        ) {
            result = [
                ...result,
                {
                    id: 'premiumPosts',
                    title: `${dic.word('wt_profile__widget_tabs__premium_posts_tab')}${
                        premiumBlogpostsCount ? ` ${premiumBlogpostsCount}` : ''
                    }`,
                    icon: LockOutlineIcon,
                    autotestClass: 'autotest__profile-page__premium-tabs-item-posts',
                    count: 'Beta',
                },
            ];
        }

        result = [
            ...result,
            {
                id: 'likesAndComments',
                title: `${dic.word('wt_profile__widget_tabs__likes_comments_tab')}`,
            },
        ];

        result = [
            ...result,
            {
                id: 'authorsProducts',
                title: `${dic.word('wt_profile__widget_products__title_text')}`,
                autotestClass: 'autotest__profile-page__tabs-item-products',
            },
        ];

        // {
        //     id: TabIds.subscriptions,
        //     title: `${dic.word('wt_profile__widget_tabs__subscriptions_tab')}`,
        // },

        return result;
    }, [dic, blogpostsCount, isVerifiedAuthor, premiumSubscribed, tabs?.activeTab, isOwner, premiumBlogpostsCount]);

    const selectedItemIndex = tabItems.findIndex((item) => item.id === currentTab);

    return (
        <Tabs
            items={tabItems}
            size={tabsResponsiveSize}
            onItemSelected={handleTabChange}
            selectedItemIndex={selectedItemIndex}
        />
    );
};
