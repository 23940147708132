import { Action, GeneratorType, Id, Person } from 'types';
import UrlProcessor from 'services/url-processor';
import { Actions, createThunkActions, thunkActions } from 'services/create-action-types/backend-client';
import { SubscribeActions, subscribe } from 'widgets/action-subscribe/actions';
import { updateUser, UpdateUserActions } from 'widgets/feed/actions';
import { updateHistory } from 'widgets/search/utils';

export type BlacklistPayloadData = {
    permission: {
        // тот КТО добавляет в ЧС
        objectId: Id;
        // тот КОГО добавляют в ЧС
        personId: Id;
    };
};

export type BlacklistPassData = {
    userId: Id;
};

const [ADD_BLACKLIST_ACTION_TYPES, addBlacklistThunk, addBlacklistActions] = createThunkActions()('ADD_BLACKLIST')<
    { csrf: string },
    BlacklistPayloadData,
    BlacklistPassData,
    SubscribeActions | UpdateUserActions
>();

type AddBlacklist = Action<typeof addBlacklist>;
type AddBlacklistActions = Actions<typeof addBlacklistActions>;

function addBlacklist(personId: Id, currentUserId: Id): ReturnType<typeof addBlacklistThunk> {
    return (dispatch, getState, extraArgument) => {
        const resultPromise = addBlacklistThunk({
            request: {
                method: 'POST',
                url: UrlProcessor.page('limex_api_user_blacklist_manage').params({ userId: personId }).path(),
                withCredentials: true,
            },
            pass: {
                userId: personId,
            },
        })(dispatch, getState, extraArgument);

        resultPromise.then(() => {
            const updateUserAction = updateUser({ id: personId, isBlockedByCurrentUser: true });
            updateHistory(personId, GeneratorType.USER, {
                personData: { isBlockedByCurrentUser: true },
                subscribeAction: 'unsubscribe',
            });
            dispatch(updateUserAction);
        });

        resultPromise.then(() => {
            const subscribeSuccess = subscribe({
                generatorId: personId,
                generatorType: GeneratorType.USER,
                currentUserId,
                action: 'unsubscribe',
            });
            dispatch(subscribeSuccess);
        });
        return resultPromise;
    };
}

export { ADD_BLACKLIST_ACTION_TYPES, addBlacklist, AddBlacklist, AddBlacklistActions };

const [REMOVE_BLACKLIST_ACTION_TYPES, removeBlacklistThunk, removeBlacklistActions] = thunkActions('REMOVE_BLACKLIST')<
    { csrf: string },
    BlacklistPayloadData,
    { userId: Id },
    UpdateUserActions
>();
type RemoveBlacklist = Action<typeof removeBlacklist>;
type RemoveBlacklistActions = Actions<typeof removeBlacklistActions>;
function removeBlacklist(personId: Id, currentUserId: Id, csrf: string): ReturnType<typeof removeBlacklistThunk> {
    return (dispatch, getState, extraArgument) => {
        const resultPromise = removeBlacklistThunk({
            request: {
                method: 'DELETE',
                url: UrlProcessor.page('limex_api_user_blacklist_manage').params({ userId: personId }).path(),
                data: { csrf },
                withCredentials: true,
            },
            pass: {
                userId: personId,
            },
        })(dispatch, getState, extraArgument);
        resultPromise.then(() => {
            const updateUserAction = updateUser({ id: personId, isBlockedByCurrentUser: false });
            updateHistory(personId, GeneratorType.USER, { personData: { isBlockedByCurrentUser: false } });
            dispatch(updateUserAction);
        });
        return resultPromise;
    };
}
export { REMOVE_BLACKLIST_ACTION_TYPES, removeBlacklist, RemoveBlacklist, RemoveBlacklistActions };

export interface UserPayloadData {
    items: Person[];
}
const [GET_FOLLOWERS, getFollowersThunk, getFollowersActions] = thunkActions('GET_FOLLOWERS')<void, UserPayloadData>();
type GetFollowers = Action<typeof getFollowers>;
type GetFollowersActions = Actions<typeof getFollowersActions>;
function getFollowers(userId: Id): ReturnType<typeof getFollowersThunk> {
    return getFollowersThunk({
        request: {
            method: 'GET',
            url: UrlProcessor.page('api_v1_subscription_get_followers_subscription_users')
                .queryArg('subscriberId', userId)
                .path(),
            withCredentials: true,
        },
    });
}
export { GET_FOLLOWERS, getFollowers, GetFollowers, GetFollowersActions };

const REDUCE_FOLLOWING_COUNT = 'REDUCE_SUBSCRIBED_COUNT' as const;
function reduceFollowingCount() {
    return { type: REDUCE_FOLLOWING_COUNT };
}
type ReduceFollowingCount = Action<typeof reduceFollowingCount>;
type ReduceFollowingCountActions = ReturnType<typeof reduceFollowingCount>;
export { REDUCE_FOLLOWING_COUNT, reduceFollowingCount, ReduceFollowingCount, ReduceFollowingCountActions };
