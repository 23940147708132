import { FeedRecordInstrument, Person, Id } from 'types';
import { guestUser } from 'context/current-user';

import { createMutableContext } from './create-mutable-context';

export type FeedOwner = Person | FeedRecordInstrument;

export type FeedRecordSource = 'feed' | 'timeline';

export type FeedContextValue = {
    /** Контекст для запроса данных ленты */
    source: FeedRecordSource;

    /** определяет, является ли пользолватель разработчиком */
    isDeveloper?: boolean;

    /** определяет, является ли пользователь администратором */
    isBlogAdmin?: boolean;

    /** определяет активна ли реклама в ленте */
    isAdsActive?: boolean;

    /** Владелец данной ленты */
    owner: FeedOwner;

    /** Подпись текущего юзера для отправки статистики */
    websocketUserSign: string;

    /** Id закрепленной записи */
    pinnedRecordId?: Id;

    /** Развернута ли по умолчанию закрепленная запись */
    pinnedRecordShowMore?: boolean;

    /** Сменит ключ у корневого компонента ленты тем сам произойдет ремаунт */
    remountFeed: () => void;
};

const feedDefaultValue: FeedContextValue = {
    source: 'feed',
    owner: guestUser,
    websocketUserSign: '',
    remountFeed: () => {
        // nothing
    },
    isAdsActive: true,
};

const { Provider, hook } = createMutableContext<FeedContextValue>(feedDefaultValue);

export { Provider as FeedContextProvider, hook as useFeedContext };
