import { FinamRichStrategyProduct } from 'types/finam-product';

import { Step2FormValues, Step3FormValues, Step4FormValues } from '../steps/copy-trading-strategies';
import { toFormValues as step2ToFormValues } from '../steps/copy-trading-strategies/step2/toFormValues';
import { toFormValues as step3ToFormValues } from '../steps/copy-trading-strategies/step3/toFormValues';
import { toFormValues as step4ToFormValues } from '../steps/copy-trading-strategies/step4/toFormValues';
import { featureTitles } from '../wizard/constants';

export function getUpdateCopyTradingStrategyFormValues(data: FinamRichStrategyProduct): {
    step2FormValues: Step2FormValues;
    step3FormValues: Step3FormValues;
    step4FormValues: Step4FormValues;
} {
    function getFeature(featureName: keyof typeof featureTitles) {
        return data.keyFeatures?.find(({ title }) => title === featureTitles[featureName])?.description ?? '';
    }

    const step2FormValues = step2ToFormValues({
        price: data.price?.amount,
        riskLevel: data.riskLevel,
        minSum: data.minSum ?? 0,
        accountId: 1,
        recommendedHoldingPeriod: getFeature('recommendedHoldingPeriod'),
    });
    const step3FormValues = step3ToFormValues({
        name: data.name ?? '',
        description: data.description ?? '',
        generalTradingIdea: getFeature('generalTradingIdea'),
    });
    const step4FormValues = step4ToFormValues({
        tradingMethod: getFeature('tradingMethod'),
        cashAndLeverage: getFeature('cashAndLeverage'),
        riskManagement: getFeature('riskManagement'),
        additionalInformation: getFeature('additionalInformation'),
    });
    return {
        step2FormValues,
        step3FormValues,
        step4FormValues,
    };
}
