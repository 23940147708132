import { useEffect, useState } from 'react';
import axios from 'axios';

import { usePageContext } from 'hooks/usePageContext';
import { useLocaleId } from 'hooks/useLocaleId';

import { getAssetIds } from './getAssetIds';
import {
    AdsData,
    AdsBlockProps,
    AssetsForRender,
    HeadlineAsset,
    MainImgAsset,
    IconAsset,
    DescriptionAsset,
    DisplayUrlAsset,
    SponsoredTextAsset,
} from './types';

const axiosClient = axios.create({
    baseURL: 'https://ssp-endpoint1.limex.me/',
    responseType: 'json',
});

type UseFetchAssetsProps = Pick<AdsBlockProps, 'isDisabled' | 'bannerPlaceId' | 'bidfloor'>;

export const useFetchAd = ({ isDisabled, bidfloor, bannerPlaceId }: UseFetchAssetsProps): AssetsForRender | null => {
    const [
        {
            ads: { ip, ua },
        },
    ] = usePageContext();
    const lcid = useLocaleId();

    const [adData, setAdData] = useState<AssetsForRender | null>(null);

    useEffect(() => {
        if (isDisabled) return;
        axiosClient
            .get<AdsData>('', {
                params: {
                    c: 'n',
                    m: 'tag',
                    placementId: bannerPlaceId,
                    ip,
                    ua,
                    domain: window.location.host,
                    page: window.location.pathname,
                    secure: 1,
                    language: lcid,
                    bidfloor,
                    gdpr_consent: 'ALL',
                },
            })
            .then((axiosResponse) => {
                if ('adm' in axiosResponse.data) {
                    const {
                        adm: {
                            link: { url },
                            jstracker,
                            assets,
                            imptrackers = [],
                        },
                        'pricing-cpm': pricingCpm,
                    } = axiosResponse.data;
                    const assetIds = getAssetIds();
                    setAdData({
                        url,
                        jstracker,
                        headline: assets.find(({ id }) => id === assetIds.Title) as HeadlineAsset,
                        mainImg: assets.find(({ id }) => id === assetIds.Main) as MainImgAsset,
                        icon: assets.find(({ id }) => id === assetIds.Icon) as IconAsset,
                        description: assets.find(({ id }) => id === assetIds.Desc) as DescriptionAsset,
                        sponsoredText: assets.find(({ id }) => id === assetIds.Sponsored) as SponsoredTextAsset,
                        displayUrl: assets.find(({ id }) => id === assetIds.DisplayUrl) as DisplayUrlAsset,
                        imptrackers:
                            pricingCpm || pricingCpm === 0
                                ? // eslint-disable-next-line no-template-curly-in-string
                                  imptrackers?.map((imp) => imp?.replace('${AUCTION_PRICE}', `${pricingCpm}`))
                                : imptrackers,
                    });
                }
            });
    }, [bannerPlaceId, bidfloor, ip, isDisabled, lcid, ua]);

    return adData;
};
