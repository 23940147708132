import React, { ComponentProps, useCallback, useState } from 'react';
import { ReactElementLike } from 'prop-types';

import { Id, GeneratorType } from 'types';
import { ApiError } from 'services/api-services/generated_limex/core/ApiError';
import { notifyError } from 'services/utils/notify-error';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useSafeUnmount } from 'hooks/useSafeUnmount';
import { ButtonIcon } from 'components/button-icon';

import { Subscribe } from '../actions';

import { SubscribeButton, Props as RenderProps } from './SubscribeButton';

interface DispatchProps {
    subscribe: Subscribe;
}
interface Props {
    data: {
        generatorId: Id;
        generatorType: GeneratorType;
    };
    isSubscribed: boolean;
    className?: string;
    // две функции пропса:
    // 1) css: cursor: default, в остальном выглядит также
    // 2) эвент клика будет доходить до компонента, но не будет обрабатываться
    clickable?: boolean;
    buttonProps?: ComponentProps<typeof ButtonIcon>;
    customRender?: ((props: RenderProps) => ReactElementLike) | null;
}
export const ActionSubscribe = (props: Props & DispatchProps) => {
    const {
        isSubscribed,
        clickable = true,
        subscribe,
        className = '',
        data: { generatorId, generatorType },
        buttonProps = {},
        customRender: customRenderer = null,
    } = props;

    const currentUser = useCurrentUser();
    const [isLoading, setIsLoading] = useState(false);

    const safeUnmount = useSafeUnmount();

    const subscribeHandler = useCallback(async () => {
        const handleError = (error: ApiError<{ message: string }>) => {
            notifyError(error, 'message');
        };
        const handleSubscribeToggle = async (action: 'subscribe' | 'unsubscribe') => {
            safeUnmount(() => setIsLoading(true));
            await subscribe({
                generatorId,
                generatorType,
                action,
                currentUserId: currentUser.id,
            });
        };
        if (clickable) {
            return handleSubscribeToggle(isSubscribed ? 'unsubscribe' : 'subscribe')
                .then(() => {
                    if (!isSubscribed && generatorType === GeneratorType.USER) {
                        safeUnmount(() => {
                            notify.success({
                                size: window.innerWidth < 678 ? 'small' : 'regular',
                                text: Dic.word('wt__widget_action_subscribe__notification_success_text'),
                                button: {
                                    content: Dic.word('wt__widget_action_subscribe__btn__cancel_text'),
                                    onClick: () => handleSubscribeToggle('unsubscribe')
                                        .catch((error) => {
                                            handleError(error);
                                        })
                                        .finally(() => {
                                            safeUnmount(() => setIsLoading(false));
                                            notify.close();
                                        }),
                                },
                            });
                        });
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => safeUnmount(() => setIsLoading(false)));
        }
    }, [clickable, subscribe, generatorId, generatorType, isSubscribed, currentUser.id]);

    const subscribeAfterRegistration = TODOuseActionAfterAuthentication(subscribeHandler);

    const renderProps: RenderProps = {
        className,
        isSubscribed,
        onClick: subscribeAfterRegistration,
        clickable,
        isLoading,
        generatorType,
        buttonProps,
    };

    if (customRenderer) {
        return customRenderer(renderProps);
    }

    return <SubscribeButton {...renderProps} />;
};

export type { RenderProps };
