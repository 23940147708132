import { FinamUserProduct } from 'types/finam-product';
import { useDicMemo } from 'hooks/useDictionary';

import { ProductTableParameters } from '../types';

export const useUserProductTable = (userProductInfo?: FinamUserProduct): ProductTableParameters => {
    const { trial, markets, programTypes } = userProductInfo ?? {};
    return useDicMemo(
        (dic) => {
            const parameters = [];
            if (markets) {
                parameters.push({
                    name: dic.word('wt_mplc2__menu_widget__items_user_product_market_label'),
                    value: dic.word(`wt_all__widget_myspace_product_wizard__step1_field_market_value_${markets[0]}`),
                });
            }
            if (programTypes) {
                parameters.push({
                    name: dic.word('wt_mplc2__menu_widget__items_user_product_platform_label'),
                    value: dic.word(`wt_all__widget_myspace_product_wizard__step1_field_type_value_${programTypes[0]}`),
                });
            }
            parameters.push({
                name: dic.word('wt_mplc2__menu_widget__items_user_product_demo_label'),
                value: dic.word(
                    trial
                        ? 'wt_mplc2__menu_widget__items_user_product_demo_yes'
                        : 'wt_mplc2__menu_widget__items_user_product_demo_no',
                ),
            });
            return parameters;
        },
        [trial, markets, programTypes],
    );
};
