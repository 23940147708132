import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ICircleIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12C3.6 16.6392 7.36081 20.4 12 20.4ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        />
        <path d="M13.1997 7.3208C13.1997 8.02832 12.646 8.60254 11.918 8.60254C11.2104 8.60254 10.6362 8.02832 10.6362 7.3208C10.6362 6.59277 11.2104 6.02881 11.918 6.02881C12.646 6.02881 13.1997 6.59277 13.1997 7.3208Z" />
        <path d="M14.2354 17.3184H10.2876C9.8877 17.3184 9.55957 17.021 9.55957 16.6006C9.55957 16.2109 9.8877 15.9033 10.2876 15.9033H11.4771V11.5967H10.4619C10.0518 11.5967 9.73389 11.2993 9.73389 10.8789C9.73389 10.4893 10.0518 10.1816 10.4619 10.1816H12.2974C12.7998 10.1816 13.0767 10.5405 13.0767 11.084V15.9033H14.2354C14.6455 15.9033 14.9736 16.2109 14.9736 16.6006C14.9736 17.021 14.6455 17.3184 14.2354 17.3184Z" />
    </IconSvg>
);
