import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';

export const PopupText = styled.span`
    ${body14RegularFontStyle};
`;

export const BtnWrapper = styled.div`
    display: flex;

    ${mediaQuery.lt768} {
        flex-direction: column;
    }

    button {
        &:first-child {
            margin-right: 16px;
        }

        ${mediaQuery.lt768} {
            width: 100%;
            margin-bottom: 12px;
            &:first-child {
                margin-right: unset;
            }
        }
    }
`;
