import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CommentIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6.64286C3 5.18325 4.18325 4 5.64286 4H18.8571C20.3168 4 21.5 5.18325 21.5 6.64286V16.0816C21.5 17.5412 20.3168 18.7245 18.8571 18.7245H12.0881C11.7877 18.7245 11.4996 18.8438 11.2872 19.0562L8.38681 21.9566C7.79219 22.5512 6.77551 22.13 6.77551 21.2892V18.7245H5.64286C4.18325 18.7245 3 17.5412 3 16.0816V6.64286ZM5.64286 5.5102C5.01731 5.5102 4.5102 6.01731 4.5102 6.64286V16.0816C4.5102 16.7072 5.01731 17.2143 5.64286 17.2143H8.28572V19.9219L10.2193 17.9884C10.7149 17.4927 11.3871 17.2143 12.0881 17.2143H18.8571C19.4827 17.2143 19.9898 16.7072 19.9898 16.0816V6.64286C19.9898 6.01731 19.4827 5.5102 18.8571 5.5102H5.64286Z"
        />
    </IconSvg>
);
