import React, { ReactElement, useCallback } from 'react';

import { Id } from 'types';

import * as Styled from './styled';

export type ParameterItem = {
    id?: Id;
    name: string;
    value: string | ReactElement;
};

export type ParametersTableParameters = Array<ParameterItem>;

export type ParametersTableAllProps = {
    isLoading?: boolean;
    parameters?: ParametersTableParameters;
    lengthCardSkeletons?: number;
    className?: string;
};

export type ParametersTableDefaultProps = Required<Pick<ParametersTableAllProps, 'parameters'>> &
    Partial<Omit<ParametersTableAllProps, 'parameters'>>;
export type ParametersTableLoadingProps = Required<Pick<ParametersTableAllProps, 'isLoading'>> &
    Partial<Omit<ParametersTableAllProps, 'isLoading'>>;

export type ParametersTableProps = ParametersTableDefaultProps | ParametersTableLoadingProps;

type RenderParameterItemLineDefaultArgs = ParameterItem & {
    classNameForName: string;
    classNameForValue: string;
    isLoading?: boolean;
    index?: number;
};
type RenderParameterItemLineLoadingArgs = Required<Pick<RenderParameterItemLineDefaultArgs, 'isLoading'>> &
    Partial<Omit<RenderParameterItemLineDefaultArgs, 'isLoading'>>;
type RenderParameterItemLineArgs = RenderParameterItemLineDefaultArgs | RenderParameterItemLineLoadingArgs;

const DEFAULT_LENGTH_CARD_SKELETONS = 3;

export const ParametersTable = (props: ParametersTableProps) => {
    const { className, parameters, isLoading = false, lengthCardSkeletons = DEFAULT_LENGTH_CARD_SKELETONS } = props;

    const renderParameterItemLine = useCallback((lineArgs: RenderParameterItemLineArgs) => {
        const {
            name,
            value,
            classNameForName,
            classNameForValue,
            isLoading: isLoadingParameterItem = false,
            id,
            index,
        } = lineArgs;

        const key = id || `${name}${typeof value === 'string' ? value : index}`;
        return (
            <Styled.ParameterItemRow key={key} isLoading={isLoadingParameterItem}>
                <Styled.ParamName className={classNameForName}>{name}</Styled.ParamName>
                <Styled.ParamValue className={classNameForValue}>{value}</Styled.ParamValue>
            </Styled.ParameterItemRow>
        );
    }, []);

    const renderContent = () => {
        if (isLoading) {
            return Array.from(Array(lengthCardSkeletons).keys()).map((i) =>
                renderParameterItemLine({ isLoading: true, index: i }),
            );
        }

        if (!parameters || parameters?.length < 1) {
            return null;
        }

        return (
            parameters?.map((parameterItem, index) => {
                const { name: paramName, value, id } = parameterItem;

                return renderParameterItemLine({
                    id,
                    name: paramName,
                    value,
                    classNameForName: `autotest__product-card_param_name_${index}`,
                    classNameForValue: `autotest__product-card_param_value_${index}`,
                    index,
                });
            }) || null
        );
    };

    return (
        <Styled.ParametersTableWrapper className={className} isLoading={isLoading}>
            {renderContent()}
        </Styled.ParametersTableWrapper>
    );
};
