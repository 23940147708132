import React from 'react';

import { mediaQuery } from 'app/styled';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { ButtonIconKinds, ButtonIconProps } from 'components/button-icon';
import { StarFillIcon } from 'components/icons2/StarFillIcon';
import { StarIcon } from 'components/icons2/StarIcon';
import { StarFillSmallIcon } from 'components/icons2/StarFillSmallIcon';
import { StarSmallIcon } from 'components/icons2/StarSmallIcon';

import { StyledButtonIcon } from './styled';

type Props = Pick<ButtonIconProps, 'onClick' | 'pressed' | 'className' | 'isProcessing'>;

export const ButtonIconStar = (props: Props) => {
    const { onClick, className, pressed, isProcessing } = props;
    const isLt768 = useMatchMedia(mediaQuery.lt768);
    const icons = isLt768
        ? { filled: StarFillSmallIcon, regular: StarSmallIcon }
        : { filled: StarFillIcon, regular: StarIcon };
    return (
        <StyledButtonIcon
            className={className}
            onClick={onClick}
            icon={{ component: pressed ? icons.filled : icons.regular }}
            isProcessing={isProcessing}
            size="S"
            kind={ButtonIconKinds.GhostPrimary}
        />
    );
};
