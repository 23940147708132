/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFollowersStatisticsExporter } from '../models/StatisticsService/UserFollowersStatisticsExporter';
import type { UserLikesStatisticsExporter } from '../models/StatisticsService/UserLikesStatisticsExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {
    /**
     * Получить статистику по подписчикам
     * @returns UserFollowersStatisticsExporter Успешное получение статистики по подписчикам пользователя
     * @throws ApiError
     */
    public static getStatisticsFollowers({
        filter = '1m',
    }: {
        /**
         *
         * Временной период за который расчитывается статистика:
         * 7d - за последние 7 дней (до 7 значений на каждый день);
         * 1m - за последний месяц (до 31 значения на каждый день);
         * 6m - за последние полгода (до 24 значений на каждую неделю);
         * 1y - за последний год (до 12 значений на каждый месяц);
         * all - за все время (до 60 значений на каждый месяц (за последние 5 лет));
         *
         */
        filter?: '7d' | '1m' | '6m' | '1y' | 'all';
    }): CancelablePromise<UserFollowersStatisticsExporter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/followers/',
            query: {
                filter: filter,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить статистику по лайкам
     * @returns UserLikesStatisticsExporter Успешное получение статистики по лайкам пользователя
     * @throws ApiError
     */
    public static getStatisticsLikes({
        filter = '1m',
    }: {
        /**
         *
         * Временной период за который расчитывается статистика:
         * 7d - за последние 7 дней (до 7 значений на каждый день);
         * 1m - за последний месяц (до 31 значения на каждый день);
         * 6m - за последние полгода (до 24 значений на каждую неделю);
         * 1y - за последний год (до 12 значений на каждый месяц);
         * all - за все время (до 60 значений на каждый месяц (за последние 5 лет));
         *
         */
        filter?: '7d' | '1m' | '6m' | '1y' | 'all';
    }): CancelablePromise<UserLikesStatisticsExporter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/likes/',
            query: {
                filter: filter,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить общую статистику пользователя
     * @returns any Успешное получение общей статистики пользователя
     * @throws ApiError
     */
    public static getStatisticsTotal({
        filter = '1m',
    }: {
        /**
         *
         * Временной период за который расчитывается статистика:
         * 7d - за последние 7 дней;
         * 1m - за последний месяц;
         * 6m - за последние полгода;
         * 1y - за последний год;
         * all - за все время (за последние 5 лет);
         *
         */
        filter?: '7d' | '1m' | '6m' | '1y' | 'all';
    }): CancelablePromise<{
        followers: {
            /**
             * Количество подписчиков за заданный период
             */
            amount: number;
            /**
             * Тренд количества подписчиков за заданный период
             */
            trend: number;
        };
        likes: {
            /**
             * Количество лайков за заданный период
             */
            amount: number;
            /**
             * Тренд количества лайков за заданный период
             */
            trend: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/total/',
            query: {
                filter: filter,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }
}
