import React, { useEffect } from 'react';

import { ValidationError } from 'services/validation-error';
import Dic from 'services/dictionary';
import { useTrackFocus } from 'hooks/useTrackFocus';
import { FormFieldProps, FormFieldValue, Validator } from 'components/form';
import { Textfield, Props as TextfieldProps } from 'components/form/textfield';

import { useValidationErrors, ValidationErrorsTypes } from '../hooks/useValidationErrors';

import { StyledPopupTextarea } from './styled';

type Props = {
    field: FormFieldProps<string>;
    fieldName: string;
    label?: string;
    type?: 'input' | 'textarea';
    validator?: Validator;
    maxLength?: number;
    pattern?: RegExp;
    required?: boolean;
    isVisible?: boolean;
    className?: string;
};

export const TextInput = (props: Props) => {
    const {
        field,
        fieldName,
        label = Dic.word(`wt_all__widget_user_form__field_${fieldName}`),
        type = 'input',
        validator,
        maxLength,
        pattern,
        required,
        // сделать перерасчет высоты textarea, тогда когда она будет на экране
        isVisible = false,
        className,
    } = props;

    const validationErrors = useValidationErrors();

    const validate = (value: FormFieldValue): ValidationError | null => {
        if (typeof value !== 'string') return null;
        let errorType: ValidationErrorsTypes | null = null;
        let _fieldName = fieldName;
        if (maxLength && value.length > maxLength) {
            errorType = 'tooLong';
            _fieldName = 'shared';
        }
        if (pattern && !value.match(pattern)) errorType = 'pattern';
        if (required && !value.length) errorType = 'required';
        if (errorType) {
            const message = validationErrors[_fieldName]?.[errorType];
            return new ValidationError([{ field: fieldName, message }]);
        }
        return null;
    };

    useEffect(() => {
        validator?.(field.value, fieldName, validate);
    }, []);

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: newValue } = e.target as HTMLInputElement | HTMLTextAreaElement;
        validator?.(newValue, fieldName, validate);
        field.onChange(e);
    };

    const { value, errors } = field;
    const [focusRef, isFocus] = useTrackFocus<HTMLInputElement | HTMLTextAreaElement>();

    const passProps: TextfieldProps = {
        size: 'medium',
        kind: 'outline',
        fieldProps: {
            value,
            onChange: handleChange,
            ref: (node) => {
                focusRef.current = node;
            },
        },
        floatingLabel: `${label}${maxLength && isFocus ? ` (${value.length}/${maxLength})` : ''}`,
        visualState: errors ? 'emergency' : 'normal',
        errorMessage: errors?.[0],
        className,
    };

    return type === 'textarea' ? (
        <StyledPopupTextarea {...passProps} recalculateHeight={isVisible} infinityHeight />
    ) : (
        <Textfield {...passProps} recalculateHeight={false} />
    );
};
