import { createElement } from 'react';

import { LanguageIcon } from 'components/icons2/LanguageIcon';
import { GlobeIcon } from 'components/icons2/GlobeIcon';
import { QuestionMarkCircleIcon } from 'components/icons2/QuestionMarkCircleIcon';
import { ICircleIcon } from 'components/icons2/ICircleIcon';
import { UserOutlineIcon } from 'components/icons2/UserOutlineIcon';
import { WalletIcon } from 'components/icons2/WalletIcon';
import { GearIcon } from 'components/icons2/GearIcon';
import { LogoutIcon } from 'components/icons2/LogoutIcon';
import { StarIcon } from 'components/icons2/StarIcon';

import { MobileNotificationsButtonIcon } from '../components/mobile-notifications-button-icon';
import { UserBlockStyled } from '../components/header/styled';
import { MenuContext, MenuData, MenuItemData } from '../interfaces';

import { getNotificationsMobileMenu } from './notificationsMobileMenu';
import { getLanguagesMenu } from './languagesMenu';
import { getCountriesMenu } from './countriesMenu';
import { getExtrasMenu } from './extrasMenu';

export type UserMenuItemId =
    | 'profile'
    | 'creator'
    | 'myspace'
    | 'notifications'
    | 'monetization'
    | 'settings'
    | 'languages'
    | 'countries'
    | 'support'
    | 'extras'
    | 'logout';

const ORDER_ITEMS: { [key in UserMenuItemId]: number } = {
    profile: 1,
    creator: 2,
    myspace: 3,
    notifications: 4,
    monetization: 5,
    settings: 6,
    languages: 7,
    countries: 8,
    support: 9,
    extras: 10,
    logout: 11,
};

export async function getUserMenu(context: MenuContext): Promise<MenuData> {
    const { user, dic, links, isMobile } = context;

    const getItems = (): Array<MenuItemData> => {
        const notifications: MenuItemData | null = isMobile
            ? {
                  id: 'notifications',
                  icon: createElement(MobileNotificationsButtonIcon),
                  text: dic.word('wt_all__widget_menu__main_notifications'),
                  subMenu: () => getNotificationsMobileMenu(),
              }
            : null;

        const items: Array<MenuItemData & { id: UserMenuItemId }> = [
            {
                id: 'profile',
                icon: UserOutlineIcon,
                text: dic.word('wt_all__widget_menu__main_profile'),
                href: links.profileById({ id: user.id }),
            },
            {
                id: 'creator',
                icon: StarIcon,
                text: dic.word('wt_all__widget_menu__main_creator'),
                href: links.creatorRoot(),
            },
            {
                id: 'monetization',
                icon: WalletIcon,
                text: dic.word('wt_all__widget_menu__main_monetization'),
                href: links.cabinetMonetization(),
            },
            {
                id: 'settings',
                icon: GearIcon,
                text: dic.word('wt_all__widget_menu__main_settings'),
                separator: true,
                href: links.cabinetRoot(),
            },
            {
                id: 'languages',
                icon: LanguageIcon,
                text: '',
                subMenu: () => getLanguagesMenu(context),
            },
            {
                id: 'countries',
                icon: GlobeIcon,
                text: '',
                subMenu: () => getCountriesMenu(context),
            },
            {
                id: 'support',
                icon: QuestionMarkCircleIcon,
                text: dic.word('wt_all__widget_menu__main_support'),
            },
            {
                id: 'extras',
                icon: ICircleIcon,
                text: dic.word('wt_all__widget_menu__main_extras'),
                subMenu: () => getExtrasMenu(context),
                separator: true,
            },
            {
                id: 'logout',
                icon: LogoutIcon,
                text: dic.word('wt_all__widget_menu__main_logout'),
            },
        ];

        const isNotNull = (menuItem: MenuItemData | null): menuItem is MenuItemData => menuItem !== null;

        const itemsFilteredSorted: Array<MenuItemData> = [...items, notifications]
            .filter(isNotNull)
            .sort((prev, next) => {
                const getItemOrder = (itemId: string): number => ORDER_ITEMS?.[itemId as UserMenuItemId] || 0;

                const prevItemOrder = getItemOrder(prev.id);
                const nextItemOrder = getItemOrder(next.id);

                return prevItemOrder - nextItemOrder;
            });

        return itemsFilteredSorted;
    };

    const headerContent = createElement(UserBlockStyled, {
        user,
        size: 'xsmall',
        avatar: {
            size: 24,
        },
    });

    return {
        name: 'user',
        headerContent,
        showCloseButton: true,
        onClick: 'close',
        items: getItems(),
    };
}
