import React from 'react';

import { IconSvg, IconComponent } from './index';

export const FBColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
            fill="#0059A5"
        />
        <path
            d="M15.7428 17.064H18.5344L18.9728 14.583H15.7422V13.227C15.7422 12.1963 16.1272 11.2824 17.2292 11.2824H19V9.11722C18.6889 9.08047 18.0308 9 16.7875 9C14.1911 9 12.669 10.1995 12.669 12.9324V14.583H10V17.064H12.669V24C13.375 24 13.733 24 14.2826 24C14.7793 24 15.0625 24 15.7428 24V17.064Z"
            fill="white"
        />
    </IconSvg>
);
