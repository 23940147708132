import React from 'react';

import { IconSvg, IconComponent } from '.';

export const CameraIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 25 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 17.0002C14.7091 17.0002 16.5 15.2093 16.5 13.0002C16.5 10.7911 14.7091 9.0002 12.5 9.0002C10.2908 9.0002 8.49995 10.7911 8.49995 13.0002C8.49995 15.2093 10.2908 17.0002 12.5 17.0002ZM12.5 15.4002C13.8254 15.4002 14.9 14.3257 14.9 13.0002C14.9 11.6747 13.8254 10.6002 12.5 10.6002C11.1745 10.6002 10.1 11.6747 10.1 13.0002C10.1 14.3257 11.1745 15.4002 12.5 15.4002Z"
        />
        <path d="M17.5 11.0002C18.0522 11.0002 18.5 10.5525 18.5 10.0002C18.5 9.44791 18.0522 9.0002 17.5 9.0002C16.9477 9.0002 16.5 9.44791 16.5 10.0002C16.5 10.5525 16.9477 11.0002 17.5 11.0002Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.09995 4.2002H15.9L17.4 6.2002H18.5C20.5986 6.2002 22.2999 7.90151 22.2999 10.0002V16.0002C22.2999 18.0989 20.5986 19.8002 18.5 19.8002H6.49995C4.40127 19.8002 2.69995 18.0989 2.69995 16.0002V10.0002C2.69995 7.90151 4.40127 6.2002 6.49995 6.2002H7.59995L9.09995 4.2002ZM9.89995 5.8002L8.39995 7.8002H6.49995C5.28492 7.8002 4.29995 8.78517 4.29995 10.0002V16.0002C4.29995 17.2152 5.28492 18.2002 6.49995 18.2002H18.5C19.715 18.2002 20.7 17.2152 20.7 16.0002V10.0002C20.7 8.78517 19.715 7.8002 18.5 7.8002H16.6L15.1 5.8002H9.89995Z"
        />
    </IconSvg>
);
