import React from 'react';

import { IconSvg, IconComponent } from '.';

export const UserOutlineIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 3.19995C9.34912 3.19995 7.20009 5.34898 7.20009 7.99995C7.20009 10.6509 9.34912 12.8 12.0001 12.8C14.6511 12.8 16.8001 10.6509 16.8001 7.99995C16.8001 5.34898 14.6511 3.19995 12.0001 3.19995ZM8.80009 7.99995C8.80009 6.23264 10.2328 4.79995 12.0001 4.79995C13.7674 4.79995 15.2001 6.23264 15.2001 7.99995C15.2001 9.76726 13.7674 11.2 12.0001 11.2C10.2328 11.2 8.80009 9.76726 8.80009 7.99995Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 14.2C7.81153 14.2 4.06899 16.4949 3.32797 19.8344C3.06975 20.9981 4.07603 21.8 5.00375 21.8H18.9996C19.9315 21.8 20.9296 20.9945 20.6722 19.8344C19.9312 16.4949 16.1886 14.2 12.0001 14.2ZM5.00375 20.2C4.95009 20.2 4.912 20.1797 4.89374 20.1642C5.42359 17.8277 8.26241 15.8 12.0001 15.8C15.7385 15.8 18.5777 17.8285 19.1067 20.1655C19.0893 20.1802 19.0525 20.2 18.9996 20.2H5.00375Z"
        />
    </IconSvg>
);
