import React, { FC, memo } from 'react';

import { useDictionary } from 'hooks/useDictionary';

import { CountryNotFoundStyled } from './styled';

export interface CountryNotFoundProps {
    searchString: string;
}

export const CountryNotFound: FC<CountryNotFoundProps> = memo(({ searchString }) => {
    const dic = useDictionary();
    const [start, end] = dic.parts('wt_all__widget_menu__search_country_not_found');

    return (
        <CountryNotFoundStyled>
            <div>
                {start}
                <span>{searchString}</span>
                {end}
            </div>
        </CountryNotFoundStyled>
    );
});
