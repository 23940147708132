import styled from 'styled-components';

import { SnackbarWrapper } from 'components/snackbar/styled';

export const Notify = styled.div`
    bottom: 24px;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 50%;
    z-index: 10100;
    ${SnackbarWrapper} {
        margin-left: -100%;
    }
`;
