import { useMemo } from 'react';

import { useDictionary } from 'hooks/useDictionary';

import { STRATEGY_FIELD_PATTERN } from './constants';

export const KEY_FEATURE_DESCRIPTION_MIN_LENGTH = 3;
export const KEY_FEATURE_DESCRIPTION_MAX_LENGTH = 500;

export function useKeyFeatureValidation() {
    const dic = useDictionary();

    return useMemo(
        () => ({
            maxLength: {
                value: KEY_FEATURE_DESCRIPTION_MAX_LENGTH,
                message: dic.word('wt_all__widget_product_wizard__text_block_title_too_long_msg'),
            },
            pattern: {
                value: STRATEGY_FIELD_PATTERN,
                message: dic.word('wt_all__widget_product_wizard__field_special_characters_message'),
            },
            minLength: {
                value: KEY_FEATURE_DESCRIPTION_MIN_LENGTH,
                message: dic.word('wt_all__widget_product_wizard__step2_field_description_min_message', {
                    min: KEY_FEATURE_DESCRIPTION_MIN_LENGTH,
                }),
            },
        }),
        [dic],
    );
}
