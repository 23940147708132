import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Violation } from 'types';
import { Dictionary } from 'services/dictionary';
import notify from 'services/notify';
import { ContactSupport } from 'services/mp-share-resources/subscriptions/actions/contactSupport';
import { useDictionary } from 'hooks/useDictionary';
import { useAsyncFn } from 'hooks/useAsyncFn';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { Content, FormTextAreaStyled, FormTextInputStyled, Header } from './styled';
import { Confirmation } from './Confirmation';

export interface FormValues {
    name: string;
    email: string;
    text: string;
}

export interface Props {
    onClose: () => void;
    onSuccess: (email: string) => void;
    contactSupport: ContactSupport;
    name: string;
    email: string;
}

function maxLength(dic: Dictionary, length: number) {
    return (value: string) =>
        value.length > length
            ? dic.word('wt_all__support_popup__validation_max_length', { exceeded: value.length - length })
            : true;
}

export const Support: FC<Props> = memo(({ onClose, name, email, onSuccess, contactSupport }) => {
    const dic = useDictionary();

    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    const cancelConfirmation = useCallback(() => {
        setIsShowConfirmation(false);
    }, []);

    const form = useForm<FormValues>({
        mode: 'onTouched',
        defaultValues: {
            name,
            email,
            text: '',
        },
    });

    const {
        getValues,
        formState: { isValid, isDirty },
        setError,
        setFocus,
    } = form;

    const handleCancel = useCallback(() => {
        if (isDirty) {
            setIsShowConfirmation(true);
        } else {
            onClose();
        }
    }, [isDirty, onClose]);

    const [{ isLoading, isSuccess, error }, handleSubmit] = useAsyncFn<
        void,
        { status: number; body?: { violations?: Violation[] } },
        Parameters<typeof contactSupport>
    >(contactSupport);

    useEffect(() => {
        if (isSuccess) {
            const { email: formEmail } = getValues();
            onSuccess(formEmail);
        }
    }, [getValues, isSuccess, onSuccess]);

    useEffect(() => {
        if (error?.status === 400) {
            error.body?.violations?.forEach(({ propertyPath, message }) => {
                if (propertyPath === '[name]') {
                    setError('name', { message });
                }
                if (propertyPath === '[email]') {
                    setError('email', { message });
                }
                if (propertyPath === '[text]') {
                    setError('text', { message });
                }
            });
        }
        if (error && error.status !== 400) {
            notify.error(dic.word('wt_all__notification__common_error'));
        }
    }, [error, setError, dic]);

    const handleSend = useCallback(() => {
        handleSubmit(getValues());
    }, [handleSubmit, getValues]);

    return isShowConfirmation ? (
        <Confirmation onCancel={cancelConfirmation} onClose={onClose} />
    ) : (
        <WtPopup3
            title={dic.word('wt_all__support_popup__header_text')}
            size={SizeWtPopup3Enum.SMALL}
            close={handleCancel}
            width={464}
            actionButtons={{
                align: 'right',
                direction: 'horizontal',
                items: [
                    {
                        key: 1,
                        kind: 'ghost-secondary',
                        content: dic.word('wt_all__support_popup__buttons_cancel'),
                        onClick: handleCancel,
                    },
                    {
                        key: 2,
                        kind: 'primary',
                        content: dic.word('wt_all__support_popup__buttons_send'),
                        onClick: handleSend,
                        isDisabled:
                            new URLSearchParams(window.location.search).get('support_disable_validation') === 'true'
                                ? false
                                : !isValid || !isDirty,
                        isProcessing: isLoading,
                    },
                ],
            }}
        >
            <Content>
                <Header>{dic.word('wt_all__support_popup__subheader_text')}</Header>
                <FormProvider {...form}>
                    <FormTextInputStyled
                        name="name"
                        label={dic.word('wt_all__support_popup__name_placeholder')}
                        kind="outline"
                        size="medium"
                        rules={{
                            required: dic.word('wt_all__support_popup__validation_required'),
                            validate: maxLength(dic, 100),
                        }}
                    />
                    <FormTextInputStyled
                        name="email"
                        label={dic.word('wt_all__support_popup__email_placeholder')}
                        kind="outline"
                        size="medium"
                        rules={{
                            required: dic.word('wt_all__support_popup__validation_required'),
                            validate: maxLength(dic, 100),
                            pattern: {
                                value: /^\S+?@\S+?\.\S+?$/,
                                message: dic.word('wt_all__support_popup__validation_email'),
                            },
                        }}
                    />
                    <FormTextAreaStyled
                        name="text"
                        label={dic.word('wt_all__support_popup__text_placeholder')}
                        kind="outline"
                        size="medium"
                        rows={4}
                        infiniteHeight
                        rules={{
                            required: dic.word('wt_all__support_popup__validation_required'),
                            validate: maxLength(dic, 3000),
                        }}
                    />
                </FormProvider>
            </Content>
        </WtPopup3>
    );
});
