import type { Step1FormValues, Step1Data } from './types';

export function toFormValues({
    riskLevel,
    recommendedHoldingPeriod,
    targetReturnAnnualized,
}: Step1Data): Step1FormValues {
    return {
        riskLevel: riskLevel ?? '',
        recommendedHoldingPeriod,
        targetReturnAnnualized,
    };
}
