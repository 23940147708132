import styled from 'styled-components';

import { Tag } from 'components/tag/Tag';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const TagStyled = styled(Tag)<{ isReadonly: boolean }>`
    margin-bottom: 8px;
    margin-right: 8px;
    ${({ isReadonly }) => (isReadonly ? 'pointer-events: none' : '')};
`;
