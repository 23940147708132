import { WrapperProps } from '../../types';

import { fillStyles } from './fill';
import { outlineStyles } from './outline';
import { errorFillStyles, errorOutlineStyles } from './error';
import { disabledFillStyles, disabledOutlineStyles } from './disabled';

const colorsMap = {
    fill: {
        normal: fillStyles,
        error: errorFillStyles,
        disabled: disabledFillStyles,
    },
    outline: {
        normal: outlineStyles,
        error: errorOutlineStyles,
        disabled: disabledOutlineStyles,
    },
};

function getState({ error, disabled }: WrapperProps) {
    if (disabled) {
        return 'disabled';
    }
    return error ? 'error' : 'normal';
}

export const buildColors = (props: WrapperProps) => {
    const state = getState(props);
    return colorsMap[props.kind][state];
};
