import React from 'react';
import { useTheme } from 'styled-components';

import { ButtonProps } from 'components/button2';

import * as Styled from './styled';

type Props = {
    headingText: string;
    secondaryText?: string;
    buttonText?: string;
    onButtonClick?: ButtonProps['onClick'];
    className?: string;
    gradientColor?: string;
};

export const GradientBanner = (props: Props) => {
    const theme = useTheme();
    const {
        onButtonClick,
        headingText,
        secondaryText,
        buttonText,
        className,
        gradientColor = theme.fillIn.primary.mid,
    } = props;

    return (
        <Styled.Wrapper className={className} $gradientColor={gradientColor}>
            <Styled.Content>
                <Styled.TextBlock>
                    <Styled.HeadingText>{headingText}</Styled.HeadingText>
                    {secondaryText && <Styled.SecondaryText>{secondaryText}</Styled.SecondaryText>}
                </Styled.TextBlock>
                {buttonText && (
                    <Styled.Button size="small" onClick={onButtonClick}>
                        {buttonText}
                    </Styled.Button>
                )}
            </Styled.Content>
        </Styled.Wrapper>
    );
};
