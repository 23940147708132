import React, { FC, memo, MutableRefObject, useEffect, useRef } from 'react';
import ClipboardService from 'clipboard';

import { useDictionary } from 'hooks/useDictionary';

import { ClipboardWrapper, ClipboardIcon } from './styled';

interface ClipboardProps {
    currentValue?: string;
    onSuccess?: () => void;
    customRender?: (ref: MutableRefObject<HTMLDivElement | HTMLSpanElement | null>) => ReturnType<typeof Clipboard>;
}
export const Clipboard: FC<ClipboardProps> = memo(({ currentValue = '', onSuccess, customRender = null }) => {
    const dic = useDictionary();
    const clipboardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ClipboardService.isSupported() && clipboardRef.current) {
            const clipboardService = new ClipboardService(clipboardRef.current);
            clipboardService.on('success', () => {
                onSuccess?.();
            });

            return () => clipboardService.destroy();
        }
        return undefined;
    }, [onSuccess]);

    if (!ClipboardService.isSupported()) {
        return null;
    }

    if (customRender) {
        return customRender(clipboardRef);
    }

    return (
        <ClipboardWrapper ref={clipboardRef} data-clipboard-text={currentValue}>
            {dic.word('wt__wt_popup_component__clipboard_label')}
            <ClipboardIcon />
        </ClipboardWrapper>
    );
});
