const promises: { [srcUrl: string]: Promise<null> } = {};

type Opts = {
    appendDelay?: number;
    forceLoad?: boolean;
};

export function loadScript(srcUrl: string, opts: Opts = {}) {
    const { appendDelay = 0, forceLoad } = opts;
    if (!forceLoad && srcUrl in promises) return promises[srcUrl];

    promises[srcUrl] = new Promise<null>((resolve, reject) => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = srcUrl;

        script.onload = () => {
            resolve(null);
        };

        script.onerror = () => {
            reject();
        };

        setTimeout(() => {
            document.body.appendChild(script);
        }, appendDelay);
    });

    return promises[srcUrl];
}
