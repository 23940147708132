import React from 'react';

import { RibbonProps } from './types';
import { ArrowComponent } from './components/ArrowComponent';
import * as Styled from './styled/styled';
import { DEFAULT_SIZE_RIBBON, DEFAULT_TYPE_RIBBON } from './constants';

export const Ribbon = (props: RibbonProps) => {
    const { children, className, type = DEFAULT_TYPE_RIBBON, size = DEFAULT_SIZE_RIBBON } = props;

    const classNameWrapper = ['autotest__ribbon', className].filter((item) => item).join(' ');

    return (
        <Styled.Wrapper className={classNameWrapper} size={size}>
            <ArrowComponent type={type} size={size} />
            <Styled.Text size={size} type={type}>
                {children}
            </Styled.Text>
        </Styled.Wrapper>
    );
};
