import { NotificationNotifyProps } from 'widgets/notify';
import { NotifyType } from 'components/notify/types';

import { NotifyMethods, ShowNotifyProcessorProps } from './processor';

type ShowNotifyProps = Omit<NotificationNotifyProps, 'close' | 'type'> | string;
type ShowNotify = (type: NotifyType) => (handlerNotifyProcessorProps: ShowNotifyProps) => void;

const showNotify: ShowNotify = (notifyType: NotifyType) => (otherNotifyProps) => {
    if (notifyProcessorMethods?.show) {
        const handlerProps: ShowNotifyProcessorProps =
            typeof otherNotifyProps === 'string'
                ? { text: otherNotifyProps, type: notifyType }
                : { ...otherNotifyProps, type: notifyType };

        notifyProcessorMethods.show(handlerProps);
        return;
    }
    window?.WT?.Page?.logger?.error('Add customHandler, please');
};

let notifyProcessorMethods: NotifyMethods | null = null;

export default {
    setCustomHandler(notifyProcessorMethodsProps: NotifyMethods) {
        notifyProcessorMethods = notifyProcessorMethodsProps;
    },

    default(props: ShowNotifyProps) {
        showNotify('default')(props);
    },

    success(props: ShowNotifyProps) {
        showNotify('success')(props);
    },

    warning(props: ShowNotifyProps) {
        showNotify('warning')(props);
    },

    error(props: ShowNotifyProps) {
        showNotify('error')(props);
    },

    close() {
        if (notifyProcessorMethods?.close) {
            notifyProcessorMethods.close();
        }
    },
};
