import React from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { usePageRoot } from '../hooks';

import { ContextWrapper } from './styled/ContextPopup';

type Props = {
    children: React.ReactNode;
    close?: () => void;
};

export const ContextPopup = ({ children, close }: Props) => {
    const pageRoot = usePageRoot();
    const dic = useDictionary();
    return (
        <WtPopup3
            size={SizeWtPopup3Enum.LARGE}
            close={close}
            portalTo={pageRoot}
            bgCloseable
            width={464}
            title={dic.word('wt_profile__popup_context__title_text')}
        >
            <ContextWrapper>{children}</ContextWrapper>
        </WtPopup3>
    );
};
