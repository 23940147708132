import produce from 'immer';
import stringify from 'fast-json-stable-stringify';
import isEqual from 'lodash/isEqual';

import { RequestTypes } from 'services/create-action-types/shared/types';

import { RequestAction, RequestActions, SuccessAction } from '../actions/types';
import { AsyncMethod, Cache, PaginationConfig } from '../types';
import { UpdateResourceAction } from '../actions/updateResource';
import { UpdateArgsAction } from '../actions/updateArgs';
import { nameToAction } from '../utils/nameToAction';

export function createArgsReducer<NAME extends string, API_METHOD extends AsyncMethod, PAGE_DATA>(
    endpointName: NAME,
    ACTION_TYPES: RequestTypes<string, 'cache'>,
    pagination?: PaginationConfig<API_METHOD, PAGE_DATA>,
) {
    return (
        state: Cache['args'] = {},
        action:
            | RequestActions<API_METHOD>
            | UpdateArgsAction
            | UpdateResourceAction<NAME, { args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> }>,
    ): Cache['args'] => {
        const [REQUEST, SUCCESS] = ACTION_TYPES;
        switch (action.type) {
            case REQUEST: {
                const {
                    payload: {
                        params,
                        pass: { requestName },
                    },
                } = action as RequestAction<API_METHOD>;
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = { named: {}, pagination: {} };
                    }
                    if (requestName) {
                        draft[endpointName].named[requestName] = pagination ? pagination.getCommonArgs(params) : params;
                    }
                    if (pagination) {
                        const commonKey = stringify(pagination.getCommonArgs(params));
                        if (!draft[endpointName].pagination[commonKey]) {
                            draft[endpointName].pagination[commonKey] = [];
                        }
                        const newKey = stringify(params);
                        const keys = draft[endpointName].pagination[commonKey];
                        if (!keys.some(({ key }) => key === newKey)) {
                            keys.push({ key: newKey, paginationInfo: undefined });
                        }
                    }
                });
            }
            case SUCCESS: {
                const {
                    payload: { data },
                    meta: {
                        requestAction: {
                            payload: {
                                params,
                                pass: { force },
                            },
                        },
                    },
                } = action as SuccessAction<API_METHOD>;
                return produce(state, (draft) => {
                    if (pagination) {
                        const commonKey = stringify(pagination.getCommonArgs(params));
                        const newKey = stringify(params);
                        const keys = draft[endpointName].pagination[commonKey];
                        const page = keys.find(({ key }) => key === newKey);
                        if (page) {
                            page.paginationInfo = pagination.getPageInfo(data);
                        }
                        if (force) {
                            draft[endpointName].pagination[commonKey] = page ? [page] : [];
                        }
                    }
                });
            }
            case `cache/UPDATE_${nameToAction(endpointName)}_ARGS`: {
                const {
                    payload: { args, requestName },
                } = action as UpdateArgsAction;
                return produce(state, (draft) => {
                    if (!draft[endpointName]) {
                        draft[endpointName] = { named: {}, pagination: {} };
                    }
                    if (requestName) {
                        draft[endpointName].named[requestName] = args;
                    }
                });
            }
            case `cache/UPDATE_${nameToAction(endpointName)}`: {
                const {
                    payload: { kind, value },
                } = action as UpdateResourceAction<
                    NAME,
                    { args: Parameters<API_METHOD>[0]; result: Awaited<ReturnType<API_METHOD>> }
                >;
                return produce(state, (draft) => {
                    if (kind === 'request' && pagination) {
                        const commonKey = stringify(pagination.getCommonArgs(value.args));
                        const newKey = stringify(value.args);
                        const keys = draft[endpointName].pagination[commonKey];
                        const page = keys.find(({ key }) => key === newKey);
                        if (page) {
                            page.paginationInfo = pagination.getPageInfo(value.result);
                        }
                    }
                });
            }
            default:
                return state;
        }
    };
}
