import { nameToAction } from '../utils/nameToAction';

export type DeleteRequestAction = {
    type: `cache/DELETE_${string}`;
    payload: { id: string | string[] };
};

export function deleteRequest<REQUEST_NAME extends string>(
    typename: REQUEST_NAME,
    id: string | string[],
): DeleteRequestAction {
    return {
        type: `cache/DELETE_REQUEST_${nameToAction(typename)}`,
        payload: { id },
    };
}
