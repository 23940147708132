import styled, { css } from 'styled-components';

import { buttonResetStyle, CSSLiteral, reduceBreakpoints } from 'app/styled';
import {
    body14RegularFontStyle,
    body16RegularFontStyle,
    caption11RegularFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';
import { Theme } from 'services/theme';
import { tokens } from 'services/tokens';

import { ListBoxKind } from '../types';
import { ListBoxButtonProps } from '../types/Button.types';

export const Controls = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 4px;
`;

export const Content = styled.div<{ hasTextOrPlaceholder: boolean }>`
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 8px;
`;

export const Text = styled.span<
    {
        hasText: boolean;
    } & Required<Pick<ListBoxButtonProps, 'size'>>
>`
    ${({ size, hasText }) =>
        reduceBreakpoints(size, (_size) => {
            if (_size === 'small' && !hasText) {
                return css`
                    display: none;
                `;
            }

            return css`
                ${_size === 'large' ? body16RegularFontStyle : body14RegularFontStyle}
            `;
        })};
`;

export const Placeholder = styled.span<{ hasLabel: boolean } & Required<Pick<ListBoxButtonProps, 'size'>>>`
    ${({ hasLabel, size }) =>
        reduceBreakpoints(size, (_size) => {
            if (hasLabel && _size === 'small') {
                return css`
                    display: none;
                `;
            }

            return css`
                ${_size === 'large' ? body16RegularFontStyle : body14RegularFontStyle}
            `;
        })};
`;

export const TextAndPlaceholderWrapper = styled.div<
    {
        isDisabled: boolean;
        hasTextOrPlaceholder: boolean;
    } & Required<Pick<ListBoxButtonProps, 'size'>>
>`
    ${body14RegularFontStyle};
    color: ${({ theme, isDisabled }) => (isDisabled ? theme.label.inactive : theme.label.primary)};
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: ${({ hasTextOrPlaceholder }) => (hasTextOrPlaceholder ? '1' : '0')};
    transition: height 360ms, opacity 600ms;
    white-space: nowrap;
`;

export const LabelWrapper = styled.div``;
export const Label = styled.span<
    Required<Pick<ListBoxButtonProps, 'isDisabled' | 'size' | 'isError'>> & {
        hasTextOrPlaceholder: boolean;
        hasLabel: boolean;
        hasTextEl: boolean;
        isOpen: boolean;
    }
>`
    ${({ hasTextOrPlaceholder, hasLabel, size }) => {
        if (hasTextOrPlaceholder && !(size === 'small' && hasLabel)) {
            return reduceBreakpoints(size, (_size) => {
                switch (_size) {
                    case 'large':
                        return caption12RegularFontStyle;
                    default:
                        return caption11RegularFontStyle;
                }
            });
        }

        return reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'large':
                    return body16RegularFontStyle;
                default:
                    return body14RegularFontStyle;
            }
        });
    }};

    display: ${({ hasTextEl, size }) =>
        reduceBreakpoints(size, (_size) => (hasTextEl && _size === 'small' ? 'none' : 'block'))};
    color: ${({ theme, isDisabled, isError }) => {
        if (isDisabled) {
            return theme.label.inactive;
        }

        if (isError) {
            return theme.label.negative;
        }

        return theme.label.secondary;
    }};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IconChevronWrapper = styled.div<Pick<ListBoxButtonProps, 'isDisabled' | 'isError'>>`
    display: flex;
    align-items: center;

    svg {
        color: ${({ theme, isDisabled, isError }) => {
            if (isDisabled) {
                return theme.label.inactive;
            }
            if (isError) {
                return theme.label.negative;
            }

            return theme.label.secondary;
        }};
    }
`;

const getKindCssMap = ({
    theme,
    isError,
    isSkeleton,
    isOpen,
}: {
    theme: Theme;
    isError: boolean;
    isSkeleton: boolean;
    isOpen: boolean;
}): Record<ListBoxKind, CSSLiteral> => {
    const activeFillCss = css`
        background-color: ${theme.bg.primary};
        box-shadow: ${theme.focusOutline.accent}, inset 0 0 0 1px ${theme.fillIn.primary.low};
    `;

    const skeletonCss = css`
        background-color: ${tokens.colors.opacity.base['16']};
    `;

    return {
        fill: isSkeleton
            ? skeletonCss
            : css`
                  background-color: ${isError ? theme.fillIn.negative.overlay16 : theme.fillIn.secondary.overlay16};

                  ${isOpen ? activeFillCss : ''};

                  ${isError || isOpen
                      ? ''
                      : css`
                            &:hover {
                                background-color: ${theme.fillIn.secondary.overlay24};
                            }
                        `}

                  &:focus {
                      box-shadow: ${theme.focusOutline.accent}, inset 0 0 0 1px ${theme.fillIn.primary.low};
                  }

                  &:active {
                      ${activeFillCss};
                  }

                  &:disabled {
                      background-color: ${theme.fillIn.secondary.overlay8};
                      box-shadow: none;
                  }
              `,
        outline: isSkeleton
            ? skeletonCss
            : css`
                  box-shadow: inset 0 0 0 1px ${isError ? theme.fillIn.negative.low : theme.fillIn.secondary.low};

                  ${isError || isOpen
                      ? ''
                      : css`
                            &:hover {
                                box-shadow: inset 0 0 0 1px ${theme.fillIn.secondary.mid};
                            }
                        `}

                  &:focus {
                      box-shadow: ${theme.focusOutline.accent}, inset 0 0 0 1px ${theme.fillIn.primary.low};
                  }

                  &:active {
                      box-shadow: ${theme.focusOutline.accent}, inset 0 0 0 1px ${theme.fillIn.primary.low};
                  }

                  &:disabled {
                      box-shadow: inset 0 0 0 1px ${theme.fillIn.secondary.muted};
                  }
              `,
    };
};

export const ButtonContentWrapper = styled.div`
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-grow: 1;
`;

export const Button = styled.button<
    Required<Pick<ListBoxButtonProps, 'width' | 'kind' | 'size' | 'isError' | 'isOpen' | 'isSkeleton'>>
>`
    ${buttonResetStyle};

    display: flex;
    flex-grow: 1;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    cursor: ${({ disabled, isSkeleton }) => {
        if (isSkeleton) {
            return 'wait';
        }
        if (disabled) {
            return 'not-allowed';
        }

        return 'pointer';
    }};

    width: ${({ width }) => {
        if (width === 'full') {
            return '100%';
        }

        if (typeof width === 'number') {
            return `${width}px`;
        }

        return 'auto';
    }};

    ${({ size }) =>
        reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'small':
                    return css`
                        height: 40px;
                        padding: 8px;
                    `;

                case 'medium':
                    return css`
                        height: 48px;
                        padding: 6px 8px;
                    `;

                // large
                default:
                    return css`
                        height: 56px;
                        padding: 8px;
                    `;
            }
        })}

    ${ButtonContentWrapper} {
        pointer-events: ${({ disabled, isSkeleton }) => (isSkeleton || disabled ? 'none' : 'unset')};
    }

    ${({ kind, theme, isError, isOpen, isSkeleton }) => getKindCssMap({ isSkeleton, theme, isError, isOpen })[kind]};
`;
