import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { ListWrapper } from 'components/list/styled';

export const Wrapper = styled.div`
    margin-top: 12px;

    ${mediaQuery.lt768} {
        height: auto;
    }
    & > ${ListWrapper} {
        height: inherit;
        display: flex;
        overflow: unset;
        flex-direction: column;
        ${mediaQuery.lt768} {
            height: 100%;
        }
    }
`;
