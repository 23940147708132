import React from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';
import { ProcessingIconContainer } from 'components/button2';
import { DEFAULT_ICON_POS_PROP } from 'components/iconed-text2/constants';

import { DEFAULT_KIND_BUTTON_ICON, DEFAULT_SIZE_BUTTON_ICON } from './constants';
import { ButtonIconKinds, ButtonIconProps, SizeButtonIcon } from './types';
import { ButtonIconWrapper, StyledIconedText } from './styled';

export const ButtonIcon = (props: ButtonIconProps) => {
    const {
        text = '',
        icon: iconProp,
        pressed = false,
        disabled = false,
        size = DEFAULT_SIZE_BUTTON_ICON,
        kind = DEFAULT_KIND_BUTTON_ICON,
        onClick,
        onMouseDown,
        className: classNameProp,
        title,
        href,
        isProcessing = false,
    } = props;

    const className = [
        classNameProp,
        'autotest__button-icon',
        disabled && 'autotest__button-icon-disabled',
        pressed && 'autotest__button-icon-pressed',
    ]
        .filter((cn) => cn)
        .join(' ');

    const { position: iconPosition = DEFAULT_ICON_POS_PROP, ...restIconProps } = iconProp || {};

    const icon = iconProp
        ? {
              ...iconProp,
              position: iconPosition,
          }
        : undefined;

    const iconForStyledIconedTextProp =
        iconProp && iconProp?.component
            ? {
                  ...restIconProps,
                  component: iconProp.component,
                  gap: '0',
              }
            : undefined;

    return (
        <ButtonIconWrapper
            as={href ? 'a' : 'button'}
            href={href}
            className={className}
            text={text}
            title={title}
            size={size}
            kind={kind}
            pressed={pressed}
            disabled={disabled}
            isProcessing={isProcessing}
            onClick={onClick}
            onMouseDown={onMouseDown}
            icon={icon}
            tabIndex={0}
        >
            <div tabIndex={-1}>
                {isProcessing && (
                    <ProcessingIconContainer>
                        <ProcessingIcon />
                    </ProcessingIconContainer>
                )}
                <StyledIconedText
                    isProcessing={isProcessing}
                    text={text}
                    iconPos={iconPosition}
                    icon={iconForStyledIconedTextProp}
                />
            </div>
        </ButtonIconWrapper>
    );
};

export { ButtonIconKinds };
export type { SizeButtonIcon as ButtonIconSizes };
export type { ButtonIconProps };
