import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const NotificationsListFallback = styled.div`
    min-height: 264px;
    max-height: 560px;
    width: 320px;

    ${mediaQuery.lt768} {
        height: 100%;
        width: 100%;
        max-height: unset;
        max-width: unset;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
        fill: ${({ theme }) => theme.label.secondary};
    }
`;
