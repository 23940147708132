/* eslint-disable @typescript-eslint/no-explicit-any */
import { traverseByPath } from 'services/utils/traverse-by-path';

import { ResourcesMap } from '../types';

export function traverseResources(
    obj: any,
    resourcesMap: ResourcesMap<any>,
    typename: string,
    callback: (item: any, parent: any, typename: string, keyOrIndex: string | number) => void,
) {
    const { resources } = resourcesMap[typename];
    if (resources) {
        Object.entries(resources).forEach(([path, nestedType]) => {
            traverseByPath(obj, path, (item, parent, keyOrIndex) => {
                callback(item, parent, nestedType as string, keyOrIndex);
            });
        });
    }
}
