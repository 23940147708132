import React from 'react';

import { isFinamUserProduct } from 'types/finam-product';
import { DefaultCard } from 'components/card';

import { ProductCardFinamPropsType } from '../types';
import { useArchivedAtParams } from '../hooks/useArchivedAtParams';
import { useFinamCardSharedProps } from '../utils';

import { useUserProductTable } from './useUserProductTable';

export const UserProductCard = (props: ProductCardFinamPropsType) => {
    const { item } = props;

    const sharedProps = useFinamCardSharedProps(props, 'user-product');

    const parametersTable = useUserProductTable(item && isFinamUserProduct(item) ? item : undefined);
    const archivedAtParams = useArchivedAtParams(item);

    return <DefaultCard {...sharedProps} parametersTable={parametersTable} {...archivedAtParams} />;
};
