import React, { SyntheticEvent } from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { ButtonProps } from './types';
import { ButtonWrapper, ProcessingIconContainer, ButtonContentContainer, ButtonTextContainer } from './styled';

// MARK: - Types

/** A tag name of the element used to render the button */
type ButtonTagName = 'a' | 'button';

/** Props for buttons using an <a /> element */
export type LinkButtonProps = ButtonProps & {
    href: string;
    target?: string;
    download?: boolean;
};

export type SmartButtonProps = ButtonProps | LinkButtonProps;

export type { ButtonProps };

/** Props for polymorphic button implementation component */
type ButtonImplementationProps<TagName extends ButtonTagName> = (ButtonProps & Partial<LinkButtonProps>) & {
    /** A tag name of the button element. Default - "button" */
    tagName: TagName;
};

// MARK: - Common Implementation Component

function ButtonImplementation<TagName extends ButtonTagName>(props: ButtonImplementationProps<TagName>) {
    const {
        className: classNameProp,
        tagName,
        children: text,
        title,
        size = 'medium',
        kind = 'primary',
        type = 'standard',
        nativeType,
        isProcessing,
        isDisabled,
        isPressed,
        icon: Icon,
        iconPos = 'left',
        onClick: onClickProp,
        block,
        href,
        target = '_self',
        download,
    } = props;
    const onClick = isDisabled || isProcessing ? preventDefaultHandler : onClickProp;
    const linkProps = tagName === 'a' ? { href, target, download } : {};
    const className = [
        classNameProp,
        'autotest__button',
        isDisabled && 'autotest__button-disabled',
        isProcessing && 'autotest__button-processing',
        isPressed && 'autotest__button-pressed',
    ]
        .filter((cn) => cn)
        .join(' ');

    return (
        <ButtonWrapper
            as={tagName as unknown as undefined}
            className={className}
            size={size}
            type={nativeType}
            customType={type}
            kind={kind}
            text={text}
            isDisabled={isDisabled}
            isProcessing={isProcessing}
            isPressed={isPressed}
            icon={Icon}
            iconPos={iconPos}
            onClick={onClick}
            block={block}
            title={title}
            {...linkProps}
        >
            <div tabIndex={-1}>
                {isProcessing && (
                    <ProcessingIconContainer>
                        <ProcessingIcon size={24} />
                    </ProcessingIconContainer>
                )}
                <ButtonContentContainer style={{ visibility: isProcessing ? 'hidden' : 'visible' }}>
                    {Icon && <Icon size={24} />}
                    {text && <ButtonTextContainer>{text}</ButtonTextContainer>}
                </ButtonContentContainer>
            </div>
        </ButtonWrapper>
    );
}

// MARK: - Element-specific Components

export const Button = (props: ButtonProps) => <ButtonImplementation {...props} tagName="button" />;

export const LinkButton = (props: LinkButtonProps) => <ButtonImplementation {...props} tagName="a" />;

export const SmartButton = (props: SmartButtonProps) => {
    if ('href' in props) {
        return <LinkButton {...props} />;
    }

    return <Button {...props} />;
};

export { ProcessingIconContainer };

// MARK: - Helpers

function preventDefaultHandler(e: SyntheticEvent) {
    e.preventDefault();
}
