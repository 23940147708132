/*
 * Здесь находятся CSS значения типографии из UI-библиотеки Финама.
 * https://www.figma.com/file/NBk9zzrKfedA7RMqWLlex6/Finam-styles
 *
 * NB! Не нужно добавлять новые значения в этот файл или изменять названия!
 * Если требуемого стиля нет - консультируемся с командой/дизайнерами.
 */

import { taggedTemplateNoop } from 'services/utils/tagged-template-literal';

// For syntax highlighting only.
// NOTE: We don't use here `import {css} from 'styled-components'`,
// because this file is independent from any CSS library.
const css = taggedTemplateNoop;

// MARK: - Headlines

/** Style: Display/Bold */
export const displayBoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.02em;
`;

/** Style: H1/Medium */
export const h1MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.02em;
`;

/** Style: H1/Semi Bold */
export const h1SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.02em;
`;

/** Style: H1 / Bold */
export const h1BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.02em;
`;

/** Style: H1 / Medium・Tnum */
export const h1MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.048em;
`;

/** Style: H1/Semi Bold・Tnum */
export const h1SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.048em;
`;

/** Style: H1/Bold・Tnum */
export const h1BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.048em;
`;

/** Style: H2/Medium */
export const h2MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.018em;
`;

/** Style: H2/Semi Bold */
export const h2SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.018em;
`;

/** Style: H2/Bold */
export const h2BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.018em;
`;

/** Style: H2/Medium・Tnum */
export const h2MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.046em;
`;

/** Style: H2/Semi Bold・Tnum */
export const h2SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.046em;
`;

/** Style: H2/Bold・Tnum */
export const h2BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.046em;
`;

/** Style: H3/Medium */
export const h3MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.016em;
`;

/** Style: H3/Semi Bold */
export const h3SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.016em;
`;

/** Style: H3 / Bold */
export const h3BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.016em;
`;

/** Style: H3/Medium・Tnum */
export const h3MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.044em;
`;

/** Style: H3/Semi Bold・Tnum */
export const h3SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.044em;
`;

/** Style: H3/Bold・Tnum */
export const h3BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.044em;
`;

/** Style: H4/Medium */
export const h4MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.014em;
`;

/** Style: H4/Semi Bold */
export const h4SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.014em;
`;

/** Style: H4 / Bold */
export const h4BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.014em;
`;

/** Style: H4/Medium・Tnum */
export const h4MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
`;

/** Style: H4/Semi Bold・Tnum */
export const h4SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.04em;
`;

/** Style: H4/Bold・Tnum */
export const h4BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.04em;
`;

/** Style: H5/Medium */
export const h5MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.012em;
`;

/** Style: H5/Semi Bold */
export const h5SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.012em;
`;

/** Style: H5 / Bold */
export const h5BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.012em;
`;

/** Style: H5/Medium・Tnum */
export const h5MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.038em;
`;

/** Style: H5/Semi Bold・Tnum */
export const h5SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.038em;
`;

/** Style: H5/Bold・Tnum */
export const h5BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.038em;
`;

// MARK: - Body Text

/** Style: Body 17 / Regular */
export const body17RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
`;

/** Style: Body 17 / Medium */
export const body17MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
`;

/** Style: Body 17/Semi Bold */
export const body17SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
`;

/** Style: Body 17 / Bold */
export const body17BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
`;

/** Style: Body 17/Regular・Tnum */
export const body17RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.034em;
`;

/** Style: Body 17/Medium・Tnum */
export const body17MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.034em;
`;

/** Style: Body 17/Semi Bold・Tnum */
export const body17SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.034em;
`;

/** Style: Body 17/Bold・Tnum */
export const body17BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.034em;
`;

/** Style: Body 16 / Regular */
export const body16RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.008em;
`;

/** Style: Body 16 / Medium */
export const body16MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.008em;
`;

/** Style: Body 16/Semi Bold */
export const body16SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.008em;
`;

/** Style: Body 16 / Bold */
export const body16BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.008em;
`;

/** Style: Body 16/Regular・Tnum */
export const body16RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.028em;
`;

/** Style: Body 16/Medium・Tnum */
export const body16MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.028em;
`;

/** Style: Body 16/Semi Bold・Tnum */
export const body16SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.028em;
`;

/** Style: Body 16/Bold・Tnum */
export const body16BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.028em;
`;

/** Style: Body 15 / Regular */
export const body15RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.006em;
`;

/** Style: Body 15 / Medium */
export const body15MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.006em;
`;

/** Style: Body 15/Semi Bold */
export const body15SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.006em;
`;

/** Style: Body 15 / Bold */
export const body15BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.006em;
`;

/** Style: Body 15/Regular・Tnum */
export const body15RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.022em;
`;

/** Style: Body 15/Medium・Tnum */
export const body15MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.022em;
`;

/** Style: Body 15/Semi Bold・Tnum */
export const body15SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.022em;
`;

/** Style: Body 15/Bold・Tnum */
export const body15BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.022em;
`;

/** Style: Body 14 / Regular */
export const body14RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

/** Style: Body 14 / Medium */
export const body14MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

/** Style: Body 14/Semi Bold */
export const body14SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

/** Style: Body 14 / Bold */
export const body14BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.004em;
`;

/** Style: Body 14/Regular・Tnum */
export const body14RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.016em;
`;

/** Style: Body 14/Medium・Tnum */
export const body14MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.016em;
`;

/** Style: Body 14/Semi Bold・Tnum */
export const body14SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.016em;
`;

/** Style: Body 14/Bold・Tnum */
export const body14BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.016em;
`;

// MARK: - Caption

/** Style: Caption 12 / Regular */
export const caption12RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
`;

/** Style: Caption 12 / Medium */
export const caption12MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
`;

/** Style: Caption 12/Semi Bold */
export const caption12SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
`;

/** Style: Caption 12 / Bold */
export const caption12BoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
`;

/** Style: Caption 12/Regular・Tnum */
export const caption12RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
`;

/** Style: Caption 12/Medium・Tnum */
export const caption12MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
`;

/** Style: Caption 12/Semi Bold・Tnum */
export const caption12SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.01em;
`;

/** Style: Caption 12/Bold・Tnum */
export const caption12BoldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
`;

/** Style: Caption 11 / Regular */
export const caption11RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.005em;
`;

/** Style: Caption 11 / Medium */
export const caption11MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
`;

/** Style: Caption 11/Semi Bold */
export const caption11SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.005em;
`;

/** Style: Caption 11/Regular・Tnum */
export const caption11RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

/** Style: Caption 11/Medium・Tnum */
export const caption11MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

/** Style: Caption 11/Semi Bold・Tnum */
export const caption11SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

/** Style: Caption 10 / Regular */
export const caption10RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
`;

/** Style: Caption 10 / Medium */
export const caption10MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.01em;
`;

/** Style: Caption 10/Semi Bold */
export const caption10SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.01em;
`;

/** Style: Caption 10/Regular・Tnum */
export const caption10RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
`;

/** Style: Caption 10/Medium・Tnum */
export const caption10MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
`;

/** Style: Caption 10/Semi Bold・Tnum */
export const caption10SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
`;

/** Style: Caption 9 / Regular */
export const caption9RegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.016em;
`;

/** Style: Caption 9 / Medium */
export const caption9MediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.016em;
`;

/** Style: Caption 9/Semi Bold */
export const caption9SemiboldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.016em;
`;

/** Style: Caption 9/Regular・Tnum */
export const caption9RegularTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.006em;
`;

/** Style: Caption 9/Medium・Tnum */
export const caption9MediumTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.006em;
`;

/** Style: Caption 9/Semi Bold・Tnum */
export const caption9SemiboldTNumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.006em;
`;

/** Style: Caption 9 / Caps Regular */
export const caption9CapsRegularFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.016em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
`;

/** Style: Caption 9 / Caps Medium */
export const caption9CapsMediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.016em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
`;

/** Style: Caption 9/Caps Bold */
export const caption9CapsBoldFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.016em;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
`;

// MARK: - Button

/** Style: Button / Large */
export const buttonLargeFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.012em;
`;

/** Style: Button / Medium */
export const buttonMediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.008em;
`;

/** Style: Button / Small */
export const buttonSmallFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.004em;
`;

/** Style: Tab / XS */
export const tabXSFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.008em;
    text-align: center;
`;

/** Style: Tab / Large */
export const tabLargeFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: center;
`;

/** Style: Tab / Medium */
export const tabMediumFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.025em;
    text-align: center;
`;

/** Style: Tab / Small */
export const tabSmallFontStyle = css`
    font-family: 'Inter var', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.018em;
    text-align: center;
`;
