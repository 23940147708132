import React from 'react';

import tippyService from 'services/tippy';

import { DEFAULT_APPEND_TO_TOOLTIP, DEFAULT_KIND_TOOLTIP, DEFAULT_VARIANT_TOOLTIP } from './constants';
import * as Styled from './styled';
import { TooltipProps } from './types';

let defaultWasSet = false;
if (!defaultWasSet) {
    tippyService.setDefaults(tippyService.defaultOptions);
    defaultWasSet = true;
}

export const Tooltip = (props: TooltipProps) => {
    const {
        children,
        variant = DEFAULT_VARIANT_TOOLTIP,
        appendTo = DEFAULT_APPEND_TO_TOOLTIP,
        kind = DEFAULT_KIND_TOOLTIP,
        ...otherProps
    } = props;

    const tippyProps = {
        ...otherProps,
        children,
    };

    return (
        <Styled.Tippy $kind={kind} appendTo={appendTo} $variant={variant} arrow={kind === 'tooltip'} {...tippyProps}>
            {children}
        </Styled.Tippy>
    );
};

export type { TooltipProps };
