import React, { ReactNode, useEffect, useMemo } from 'react';

import { createMutableContext } from 'context/create-mutable-context';
import { useCurrentUser } from 'hooks/useCurrentUser';

type ProfilePageContextValueType = {
    isOwner: boolean;
    isAddFeedStatusActive: true | undefined;
    pageRoot: HTMLElement | null;
};

const profilePageContextDefaultValue: ProfilePageContextValueType = {
    isOwner: false,
    isAddFeedStatusActive: undefined,
    pageRoot: null,
};

const { Provider, hook } = createMutableContext<ProfilePageContextValueType>(profilePageContextDefaultValue);

const ContextValueManager = ({ profileUserId, pageRoot, children }: ProfilePageContextProviderProps) => {
    const currentUser = useCurrentUser();

    const contextValue = useMemo(
        () => ({
            isOwner: `${profileUserId}` === `${currentUser.id}`,
            isAddFeedStatusActive: location.search.includes('isAddFeedStatusActive') || undefined,
            pageRoot,
        }),
        [profileUserId, currentUser.id, pageRoot],
    );

    const [, setContextSlice] = hook();

    useEffect(() => {
        setContextSlice(contextValue);
    }, [contextValue]);

    useEffect(() => {
        setContextSlice(contextValue);
    }, [pageRoot]);

    return <>{children}</>;
};

type ProfilePageContextProviderProps = {
    profileUserId: string | number;
    children?: ReactNode;
    pageRoot: HTMLElement | null;
};
export const ProfilePageContextProvider = ({ profileUserId, pageRoot, children }: ProfilePageContextProviderProps) => (
    <Provider initialValue={profilePageContextDefaultValue}>
        <ContextValueManager profileUserId={profileUserId} pageRoot={pageRoot}>
            {children}
        </ContextValueManager>
    </Provider>
);

export { Provider, hook as useProfilePageContext };
