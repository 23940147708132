import { Action, Lcid } from 'types';
import UrlProcessor from 'services/url-processor';
import { Actions, thunkActions } from 'services/create-action-types/backend-client';

const [UPDATE_COUNTRY_ACTION_TYPES, countryThunk, countryActions] = thunkActions('UPDATE_COUNTRY')<
    { countryCode: string },
    void
>();

type UpdateCountryActions = Actions<typeof countryActions>;
type UpdateCountry = Action<typeof updateCountry>;

function updateCountry(countryCode: string, csrf: string): ReturnType<typeof countryThunk> {
    return (dispatch, getState, extraArgument) =>
        countryThunk({
            request: {
                method: 'POST',
                url: UrlProcessor.page('api_v1_user_country').path(),
                withCredentials: true,
                data: { countryCode },
                headers: { 'X-CSRF-Token': csrf },
            },
        })(dispatch, getState, extraArgument).then((result) => {
            window.location.reload();
            return result;
        });
}

export { UPDATE_COUNTRY_ACTION_TYPES, updateCountry, UpdateCountry, UpdateCountryActions };

const [UPDATE_LOCALE_ACTION_TYPES, localeThunk, localeActions] = thunkActions('UPDATE_LOCALE')<
    { lcid: Lcid },
    { user: { lcid: Lcid } }
>();
type UpdateLocaleActions = Actions<typeof localeActions>;
type UpdateLocale = Action<typeof updateLocale>;

function updateLocale(lcid: Lcid, csrf: string): ReturnType<typeof localeThunk> {
    return (dispatch, getState, extraArgument) =>
        localeThunk({
            request: {
                method: 'POST',
                url: UrlProcessor.page('api_v1_user_lcid').path(),
                withCredentials: true,
                data: { lcid },
                headers: { 'X-CSRF-Token': csrf },
            },
        })(dispatch, getState, extraArgument).then((result) => {
            window.location.reload();
            return result;
        });
}

export { UPDATE_LOCALE_ACTION_TYPES, updateLocale, UpdateLocale, UpdateLocaleActions };
