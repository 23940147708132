import React from 'react';

import { SocialIcon, SocialIcons } from 'components/social-icons';
import { LimexLogoIcon } from 'components/limex-logo';
import { LimexEmailLink } from 'components/limex-email';

import { BlockLayout } from './BlockLayout';
import { LinksBlocksDataType, LinksBlocks } from './LinksBlocks';
import * as Styled from './styled/FooterLayout.styled';

export type FooterPropsType = {
    withPageLayout?: boolean; // use that when you insert footer without layout on page. That insert default layout for footer
    contactsTitle: string;
    linksBlocksData: LinksBlocksDataType;
    socialIconsData: SocialIcon[];
};

export const FooterLayout = (props: FooterPropsType) => {
    const { withPageLayout = false, contactsTitle, socialIconsData, linksBlocksData } = props;

    return (
        <Styled.FooterContainer withPageLayout={withPageLayout}>
            <Styled.FooterContent>
                <Styled.LogoBlock>
                    <LimexLogoIcon height={35} width={100} />
                </Styled.LogoBlock>
                <LinksBlocks data={linksBlocksData} />
                <Styled.ContactsBlock>
                    <BlockLayout title={contactsTitle}>
                        <>
                            <Styled.EmailLine>
                                <span>Email:&nbsp;</span>
                                <LimexEmailLink namePart="feedback" />
                            </Styled.EmailLine>
                            <SocialIcons icons={socialIconsData} />
                        </>
                    </BlockLayout>
                </Styled.ContactsBlock>
            </Styled.FooterContent>
        </Styled.FooterContainer>
    );
};
