import { lightTheme } from './light';
import { darkTheme } from './dark';
import { Theme } from './types';

export type { Theme };

export type Themes = { light: Theme; dark: Theme };

export const themes: Themes = { light: lightTheme, dark: darkTheme } as const;

export type ThemeName = keyof typeof themes;
