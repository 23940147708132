import styled, { createGlobalStyle } from 'styled-components';

import { mediaQuery } from 'app/styled';
import { h5MediumFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 302;
    border-radius: 0;
    background-color: ${({ theme }) => theme.bg.primary};

    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    height: 56px;
    align-items: center;
    padding: 0 8px 0 16px;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.divider.primary};
`;

export const HeaderText = styled.div`
    ${h5MediumFontStyle};
`;

export const List = styled.div`
    padding: 8px 0;
`;

export const GlobalStyle = createGlobalStyle`
    ${mediaQuery.lt768} {
        body {
            overflow: hidden;
        }
    }
`;
