import { Violation } from 'types';
import { ApiError } from 'services/api-services/generated_limex/core/ApiError';

interface ValidationFailedException {
    violations: Violation[];
}

export function isLimexApiError(error: unknown): error is ApiError<ValidationFailedException> {
    return (
        error instanceof ApiError &&
        error.status === 400 &&
        Boolean(error.body) &&
        Array.isArray((error as ApiError<ValidationFailedException>)?.body?.violations) &&
        error.body.violations.length
    );
}
