import React from 'react';

import { IconSvg, IconComponent } from '.';

export const QuestionMarkCircleIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M11.7847 13.9858C12.2871 13.9858 12.605 13.6782 12.6152 13.2886V13.1655C12.6255 12.6426 12.9536 12.3042 13.6099 11.8633C14.5532 11.248 15.2095 10.6841 15.2095 9.53564C15.2095 7.92578 13.7637 7.03369 12.0923 7.03369C10.4312 7.03369 9.32373 7.81299 9.03662 8.70508C8.98535 8.86914 8.95459 9.0332 8.95459 9.19727C8.95459 9.64844 9.30322 9.90479 9.67236 9.90479C10.119 9.90479 10.2721 9.71129 10.4761 9.45347C10.5249 9.39168 10.5768 9.32619 10.6362 9.25879C10.9541 8.73584 11.395 8.42822 12.0205 8.42822C12.8716 8.42822 13.415 8.91016 13.415 9.61768C13.415 10.2534 13.0049 10.5713 12.1641 11.1558C11.4771 11.6377 10.9644 12.1504 10.9644 13.0732V13.186C10.9644 13.7192 11.2617 13.9858 11.7847 13.9858Z" />
        <path d="M11.7642 16.9082C12.3486 16.9082 12.8408 16.457 12.8408 15.8726C12.8408 15.2881 12.3589 14.8472 11.7642 14.8472C11.1797 14.8472 10.6875 15.2983 10.6875 15.8726C10.6875 16.4468 11.1899 16.9082 11.7642 16.9082Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20.4 12C20.4 16.6392 16.6392 20.4 12 20.4C7.36081 20.4 3.6 16.6392 3.6 12C3.6 7.36081 7.36081 3.6 12 3.6C16.6392 3.6 20.4 7.36081 20.4 12Z"
        />
    </IconSvg>
);
