import React from 'react';

import { IconSvg, IconComponent } from './index';

export const TelegramColoredIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
            fill="#37AEE2"
        />
        <path
            d="M4.09992 11.027C8.39487 9.05126 11.2589 7.74875 12.6919 7.11944C16.7834 5.32264 17.6335 5.01052 18.1877 5.0001C18.3095 4.99794 18.582 5.02983 18.7586 5.18106C18.9076 5.30876 18.9486 5.48125 18.9683 5.60232C18.9879 5.72339 19.0123 5.99919 18.9929 6.21469C18.7712 8.67437 17.8118 14.6434 17.3237 17.3983C17.1172 18.564 16.7105 18.9548 16.3168 18.9931C15.4613 19.0762 14.8116 18.3961 13.9829 17.8226C12.6862 16.9251 11.9537 16.3664 10.695 15.4907C9.24042 14.4786 10.1834 13.9224 11.0124 13.0133C11.2293 12.7754 14.999 9.15516 15.0719 8.82675C15.0811 8.78568 15.0895 8.63258 15.0034 8.55173C14.9172 8.47089 14.7901 8.49853 14.6983 8.52052C14.5683 8.55169 12.4968 9.9973 8.48389 12.8573C7.89591 13.2836 7.36333 13.4913 6.88616 13.4805C6.36012 13.4685 5.34822 13.1664 4.59598 12.9083C3.67333 12.5916 2.94002 12.4242 3.00388 11.8864C3.03714 11.6063 3.40248 11.3198 4.09992 11.027Z"
            fill="white"
        />
    </IconSvg>
);
