import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LoginIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.60846 11.9104C1.60846 11.4686 1.96663 11.1104 2.40846 11.1104H12.4085C12.8503 11.1104 13.2085 11.4686 13.2085 11.9104C13.2085 12.3523 12.8503 12.7104 12.4085 12.7104H2.40846C1.96663 12.7104 1.60846 12.3523 1.60846 11.9104Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7565 11.9104L9.09684 9.25082C8.78442 8.9384 8.78441 8.43187 9.09683 8.11945C9.40925 7.80703 9.91578 7.80702 10.2282 8.11944L13.3829 11.274C13.7343 11.6255 13.7343 12.1954 13.3829 12.5468L10.0492 15.8805C9.73678 16.1929 9.23025 16.1929 8.91783 15.8805C8.60541 15.5681 8.60541 15.0615 8.91783 14.7491L11.7565 11.9104Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5207 4.49995C10.5207 4.05812 10.8789 3.69995 11.3207 3.69995H19.4915C19.9886 3.69995 20.3915 4.10289 20.3915 4.59995V19.4C20.3915 19.897 19.9886 20.3 19.4915 20.3H11.3207C10.8789 20.3 10.5207 19.9418 10.5207 19.5C10.5207 19.0581 10.8789 18.7 11.3207 18.7H18.7915V5.29995H11.3207C10.8789 5.29995 10.5207 4.94178 10.5207 4.49995Z"
        />
    </IconSvg>
);
