import { connect } from 'react-redux';

import { Person } from 'types';

import { saveUser } from './actions';
import { UserForm } from './components';

const mapStateToProps = (state: {
    page: {
        user: Person;
    };
}) => {
    const {
        page: { user },
    } = state;
    return {
        user,
    };
};

export const UserFormContainer = connect(mapStateToProps, {
    saveUser,
})(UserForm);
