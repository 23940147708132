/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { PagedWebResponse_ILeanProductDto } from '../models/shared/PagedWebResponse_ILeanProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FavoritesService {
    /**
     * Возвращает избранное пользователя
     * @returns PagedWebResponse_ILeanProductDto Success
     * @throws ApiError
     */
    public static getFavorites({
        productType,
        page,
        pageSize,
    }: {
        /**
         * Тип продукта
         */
        productType?: string | null;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/favorites',
            query: {
                ProductType: productType,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Добавляет в избранное пользователя продукт
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static addProductToFavorites({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/favorites/{productId}',
            path: {
                productId: productId,
            },
        });
    }

    /**
     * Удаляет из избранного пользователя продукт
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static removeProductFromFavorites({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/favorites/{productId}',
            path: {
                productId: productId,
            },
        });
    }
}
