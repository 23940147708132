import React, { useCallback } from 'react';
import noop from 'lodash/noop';

import { FeedRecordObjTypes } from 'types';
import { sendGA } from 'services/analytics-outside/google-analytics';
import { numberToHumanString } from 'services/number-to-human-string';
import Dic from 'services/dictionary';
import { useHideComments } from 'hooks/useHideComments';
import { ShareWidget } from 'widgets/share';
import { ActionPossibility } from 'widgets/action-possibility';
import { Tooltip } from 'components/tooltip';

import { LikePost } from '../../like-post';
import { ModerationEditItemButton } from '../../moderation-components/ModerationEditItemButton';
import { ModerationAILabel } from '../../moderation-components/ModerationAILabel';
import { FooterProps } from '../types';
import { useHandleShare } from '../../useHandleShare';
import * as Styled from '../styled';

import { Voters } from './Voters';

const buttonsSize = { default: 'L', lt960: 'M' } as const;

export const Footer = (props: FooterProps) => {
    const { recordId, recordData, obj, stats, onClickComments = noop } = props;
    const {
        id,
        type,
        author,
        stats: {
            likes: { positive, userChoice },
            viewsCount,
        },
        data: {
            statsUsers: { voters },
        },
        isUserCommented,
        shareUrl,
    } = obj;

    const hideComments = useHideComments(author.id);

    const media = obj?.media?.[0]?.source;

    const commentsCount = type === FeedRecordObjTypes.POST ? obj.commentsCount : stats.commentsCount;

    const handleCommentsClick = useCallback(() => {
        onClickComments();
        sendGA('feedRecord', ['comments-icon-click']);
    }, [onClickComments]);

    const handleShare = useHandleShare(id);
    const hasVoters = (voters?.length ?? 0) > 0;

    return (
        <Styled.Footer className="adm__post-activity">
            <Styled.Group>
                {voters && hasVoters && <Voters items={voters} />}
                <LikePost
                    recordId={recordId}
                    objId={id}
                    objType={type}
                    userChoice={userChoice ?? null}
                    count={positive}
                    size={buttonsSize}
                    isDisabled={author.isBlockedByCurrentUser}
                />
                {!hideComments && (
                    <ActionPossibility isPossible={!author.isBlockedByCurrentUser}>
                        <Tooltip
                            content={Dic.word('wt_feed__widget_comments__comments_tooltip')}
                            placement="top"
                            trigger="mouseenter"
                        >
                            <div>
                                <Styled.CommentButton
                                    text={
                                        commentsCount || (
                                            <Styled.CommentText>
                                                {Dic.word('wt_feed__post_comments__comment_label')}
                                            </Styled.CommentText>
                                        )
                                    }
                                    disabled={author.isBlockedByCurrentUser}
                                    onClick={handleCommentsClick}
                                    size={buttonsSize}
                                    pressed={isUserCommented}
                                />
                            </div>
                        </Tooltip>
                    </ActionPossibility>
                )}
                <ShareWidget
                    url={shareUrl}
                    title={obj.title || obj.displayTitle || obj.shortContent}
                    description={obj.shortContent}
                    image={media}
                    buttonText=""
                    onClose={handleShare}
                />
                <ModerationEditItemButton id={obj.id} type="record" />
                <ModerationAILabel recordData={recordData} />
            </Styled.Group>
            <Styled.Group>
                {!!viewsCount && (
                    <Styled.ViewsCount
                        text={numberToHumanString(viewsCount, 1)}
                        size={buttonsSize}
                        className="autotest__feed-item__views-count"
                    />
                )}
                {/* <Styled.BookmarkButton onClick={inDevelopment} /> */}
                {/* <Styled.ShareButton onClick={inDevelopment} /> */}
            </Styled.Group>
        </Styled.Footer>
    );
};
