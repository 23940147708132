import React, { FC, memo } from 'react';
import { connect } from 'react-redux';

import { useDictionary } from 'hooks/useDictionary';
import {
    GetSocialActivityList,
    getSocialActivityList as getSocialActivityListAction,
} from 'widgets/feed/feed/actions/fetchSocialActivity';
import { GlobalStateForFeed, SocialTypes } from 'widgets/feed/types';
import Tag from 'components/tag/Tag';
import { LikeIcon } from 'components/icons2/emoji/LikeIcon';
import { CommentIcon } from 'components/icons2/emoji/CommentIcon';

import { SocialTabsWrapper } from './styled';

interface Props {
    socialTypes: SocialTypes[];
    getSocialActivityList: GetSocialActivityList;
}

const SocialTabs: FC<Props> = memo(({ socialTypes, getSocialActivityList }) => {
    const dic = useDictionary();
    return (
        <SocialTabsWrapper>
            <Tag
                text={dic.word('wt_profile__widget_feed__social_both')}
                size="medium"
                isActive={socialTypes.includes('likes') && socialTypes.includes('comments')}
                onClick={() =>
                    getSocialActivityList({
                        instanceId: 'socialActivity',
                        socialTypes: ['likes', 'comments'],
                        reset: true,
                    })
                }
            />
            <Tag
                text={dic.word('wt_profile__widget_feed__social_likes')}
                size="medium"
                Icon={LikeIcon}
                isActive={socialTypes.includes('likes') && !socialTypes.includes('comments')}
                onClick={() =>
                    getSocialActivityList({
                        instanceId: 'socialActivity',
                        socialTypes: ['likes'],
                        reset: true,
                    })
                }
            />
            <Tag
                text={dic.word('wt_profile__widget_feed__social_comments')}
                size="medium"
                Icon={CommentIcon}
                isActive={!socialTypes.includes('likes') && socialTypes.includes('comments')}
                onClick={() =>
                    getSocialActivityList({
                        instanceId: 'socialActivity',
                        socialTypes: ['comments'],
                        reset: true,
                    })
                }
            />
        </SocialTabsWrapper>
    );
});

export const SocialTabsContainer = connect(
    (state: GlobalStateForFeed) => ({
        socialTypes: state.widgets.feed.instances.socialActivity?.socialTypes ?? ['likes', 'comments'],
    }),
    { getSocialActivityList: getSocialActivityListAction },
)(SocialTabs);
