/* eslint-disable camelcase */
import { Id, Action, Person } from 'types';
import { Actions, thunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';

export type ReceiveSubscriptionsPayloadData = {
    items: Person[];
    nextCursor: number;
    total: number;
};
const [SUBSCRIPTIONS_RECEIVE_ACTION_TYPES, receiveSubscriptionsThunk, receiveSubscriptionsActions] = thunkActions(
    'SUBSCRIPTIONS_RECEIVE',
)<void, ReceiveSubscriptionsPayloadData>();
function receiveSubscriptions(ownerId: Id, pageSize: number, cursor: number) {
    return receiveSubscriptionsThunk({
        request: {
            url: UrlProcessor.page('limex_profile_subscriptions')
                .params({ person_id: ownerId, type: 'following' })
                .path(),
            params: { limit: pageSize, cursor },
        },
    });
}
type ReceiveSubscriptions = Action<typeof receiveSubscriptions>;
type ReceiveSubscriptionsActions = Actions<typeof receiveSubscriptionsActions>;

export { SUBSCRIPTIONS_RECEIVE_ACTION_TYPES, receiveSubscriptions, ReceiveSubscriptions, ReceiveSubscriptionsActions };

const [SUBSCRIBERS_RECEIVE_ACTION_TYPES, receiveSubscribersThunk, receiveSubscribersActions] = thunkActions(
    'SUBSCRIBERS_RECEIVE',
)<void, ReceiveSubscriptionsPayloadData>();
function receiveSubscribers(ownerId: Id, pageSize: number, cursor: number) {
    return receiveSubscribersThunk({
        request: {
            url: UrlProcessor.page('limex_profile_subscriptions')
                .params({ person_id: ownerId, type: 'followers' })
                .path(),
            params: { limit: pageSize, cursor },
        },
    });
}
type ReceiveSubscribers = Action<typeof receiveSubscribers>;
type ReceiveSubscribersActions = Actions<typeof receiveSubscribersActions>;
export { SUBSCRIBERS_RECEIVE_ACTION_TYPES, receiveSubscribers, ReceiveSubscribers, ReceiveSubscribersActions };
