import React, { FC, ComponentType, useMemo, useState } from 'react';

import { FeedRecord, GeneratorType, TickerNewsRecord } from 'types';
import Dic from 'services/dictionary';
import { ensureNumber } from 'services/utils/ensure-number';
import Instrument, { INSTRUMENT_STYLE } from 'widgets/instrument2';
import { ActionSubscribe } from 'widgets/action-subscribe';

import type { FeedItemProps } from '../../FeedItem';

import { StyledActivity, MoreNewsButton, InstrumentWrapper, FeedItemWrapper } from './styled';

type TickerNewsProps = Omit<FeedItemProps, 'record'> & {
    record: TickerNewsRecord;
    FeedItemComponent: ComponentType<FeedItemProps>;
};

export const TickerNews: FC<TickerNewsProps> = ({
    record,
    onRecordShowFull,
    onItemViewedChange,
    FeedItemComponent,
    instanceId,
}) => {
    const { finamId } = record.tickerNews?.instrument || {};
    const { isSubscribed } = record.tickerNews || {};
    const [isExpanded, setIsExpanded] = useState(false);
    const actions = useMemo(() => {
        if (!finamId || !record.tickerNews?.instrument) return null;
        return {
            subscribe: (
                <ActionSubscribe
                    isSubscribed={isSubscribed || false}
                    data={{ generatorId: finamId, generatorType: GeneratorType.INSTRUMENT }}
                />
            ),
        };
    }, [finamId, isSubscribed]);

    if (!record.tickerNews?.instrument || !record.tickerNews.records?.length) return null;
    const { instrument } = record.tickerNews;
    const records = isExpanded ? record.tickerNews.records : record.tickerNews.records.slice(0, 2);
    const { currency, priceChange, price, dcpl, ticker, href, name, logoUrl } = instrument;

    const handleMoreClick = () => setIsExpanded(true);

    return (
        <StyledActivity record={record}>
            <InstrumentWrapper>
                <Instrument
                    style={INSTRUMENT_STYLE.PAINTED4}
                    finamId={finamId}
                    currency={currency}
                    initChangePercent={typeof priceChange === 'number' ? priceChange : parseFloat(priceChange)}
                    initPrice={typeof price === 'number' ? price : parseFloat(price)}
                    initDcpl={dcpl}
                    initTicker={ticker}
                    url={href}
                    logoUrl={logoUrl}
                    name={name}
                    tooltipEnabled={false}
                    autoUpdate
                    actions={actions}
                />
            </InstrumentWrapper>
            {records.map((_record: FeedRecord) => (
                <FeedItemWrapper key={_record.id}>
                    <FeedItemComponent
                        instanceId={instanceId}
                        record={_record}
                        onRecordShowFull={onRecordShowFull}
                        onItemViewedChange={onItemViewedChange}
                    />
                </FeedItemWrapper>
            ))}
            {record.tickerNews.records.length > records.length && (
                <MoreNewsButton onClick={handleMoreClick}>
                    {Dic.word('wt_feed__item_ticker_news__show_more', {
                        count: record.tickerNews.records.length - records.length,
                    })}
                </MoreNewsButton>
            )}
        </StyledActivity>
    );
};
