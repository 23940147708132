import React from 'react';
import cn from 'classcat';

import { Switch } from 'components/switch';

import { SettingItemProps } from '../types/SettingItem.types';
import * as Styled from '../styled/SettingItem.styled';

export const SettingItem = (props: SettingItemProps) => {
    const { title, description, onChange, isChecked = false, isDisabled = false, className: classNameProp } = props;

    const className = cn(['autotest__cookie-popup__setting-item', classNameProp]);

    return (
        <Styled.SettingItemWrapper className={className}>
            <Styled.Header>
                <Styled.Title>{title}</Styled.Title>
                <Styled.SwitchWrapper>
                    <Switch
                        isChecked={isChecked}
                        onChange={() => {
                            onChange(isChecked);
                        }}
                        disabled={isDisabled}
                    />
                </Styled.SwitchWrapper>
            </Styled.Header>

            <Styled.Body>
                <Styled.Description>{description}</Styled.Description>
            </Styled.Body>
        </Styled.SettingItemWrapper>
    );
};
