import styled from 'styled-components';

import { hiddenScroll } from 'app/styled';
import { Input } from 'components/text-input/styled';

export const InputStyled = styled(Input)`
    overscroll-behavior: contain;

    ${hiddenScroll}
`;
