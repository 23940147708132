import React, { FC, useRef, useLayoutEffect, ReactNode } from 'react';

export interface SentinelProps {
    onLoadMore: () => void;
    root?: HTMLElement | null;
    preloadOffset?: number;
}

export interface Props extends SentinelProps {
    isLoading: boolean;
    children: ReactNode;
}

const Sentinel: FC<SentinelProps> = ({ onLoadMore, root = null, preloadOffset = 0 }) => {
    const callbackRef = useRef(onLoadMore);
    useLayoutEffect(() => {
        callbackRef.current = onLoadMore;
    });

    const elementRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const element = elementRef.current;
        if (element) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        callbackRef.current();
                    }
                },
                { root, rootMargin: `0px 0px ${preloadOffset}px 0px` },
            );
            observer.observe(element);
            return () => observer.unobserve(element);
        }
        return undefined;
    }, [preloadOffset, root]);

    return <div ref={elementRef} />;
};

export const InfiniteScroll: FC<Props> = ({ isLoading, onLoadMore, root = null, preloadOffset = 0, children }) => (
    <>
        {children}
        {isLoading ? null : <Sentinel onLoadMore={onLoadMore} root={root} preloadOffset={preloadOffset} />}
    </>
);
