import styled from 'styled-components';

export const FeedWrapper = styled.div`
    margin-top: 16px;
`;

export const SocialTabsWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin: 16px 0;
`;
