/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebResponse_CreateAccountWebResponse } from '../models/UsConnectedAccountService/WebResponse_CreateAccountWebResponse';
import type { WebResponse_CreateOnboardingLinkResponse } from '../models/UsConnectedAccountService/WebResponse_CreateOnboardingLinkResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsConnectedAccountService {
    /**
     * Создает привязанный аккаунт в Stripe
     * @returns WebResponse_CreateAccountWebResponse Success
     * @throws ApiError
     */
    public static createApiVMyConnectedAccount({
        countryCode,
    }: {
        /**
         * Код страны клиента (автора продуктов)
         */
        countryCode?:
            | 'AU'
            | 'AT'
            | 'BE'
            | 'BG'
            | 'CA'
            | 'HR'
            | 'CY'
            | 'CZ'
            | 'DK'
            | 'EE'
            | 'FI'
            | 'FR'
            | 'DE'
            | 'GI'
            | 'GR'
            | 'HK'
            | 'HU'
            | 'IN'
            | 'IE'
            | 'IS'
            | 'IT'
            | 'JP'
            | 'LV'
            | 'LI'
            | 'LT'
            | 'LU'
            | 'MY'
            | 'MT'
            | 'MX'
            | 'NL'
            | 'NZ'
            | 'NO'
            | 'PL'
            | 'PT'
            | 'RO'
            | 'SG'
            | 'SK'
            | 'SI'
            | 'ES'
            | 'SE'
            | 'CH'
            | 'TH'
            | 'AE'
            | 'GB'
            | 'US';
    }): CancelablePromise<WebResponse_CreateAccountWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/connected-account',
            query: {
                countryCode: countryCode,
            },
        });
    }

    /**
     * Создает ссылку на онбординг-процесс для привязанного аккаунта
     * @returns WebResponse_CreateOnboardingLinkResponse Success
     * @throws ApiError
     */
    public static createApiVMyConnectedAccountOnboarding(): CancelablePromise<WebResponse_CreateOnboardingLinkResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/connected-account/onboarding',
        });
    }
}
