import styled, { css } from 'styled-components';
import { ifProp, theme, switchProp } from 'styled-tools';

import { boxShadowLikeBorder, matchBreakpoints, ResponsiveMap } from 'app/styled';
import {
    body16RegularFontStyle,
    body14RegularFontStyle,
    caption12RegularFontStyle,
    caption11RegularFontStyle,
} from 'services/typography';
import detectDevice from 'services/detectDevice';

export type VisualState = 'normal' | 'emergency';

export type WtFieldKind = 'primary' | 'outline';

export type WtField2Size = 'large' | 'medium' | 'small';

export const pullScrollProcess = ({ usePullScroll }: { usePullScroll: boolean }) => {
    if (usePullScroll) {
        return `
            overflow: hidden;
            // to make work PgUp and PgDn buttons
            ${
                detectDevice.isChrome() &&
                `
                overflow: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
            `
            }
        `;
    }
    return '';
};

export const FloatingLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: ${theme('label.secondary')};
    pointer-events: none;
`;

export const ClearButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
`;

const buildSizes = ({ size }: { size: WtField2Size | ResponsiveMap<WtField2Size> }) =>
    matchBreakpoints(size, {
        large: css`
            ${body16RegularFontStyle};
            padding: 16px 12px 16px 16px;
            ${ifProp(
                'floatingLabel',
                css`
                    &:not(:placeholder-shown) {
                        padding-top: 24px;
                        padding-bottom: 8px;
                        & ~ ${FloatingLabel} {
                            ${caption12RegularFontStyle};
                            left: 16px;
                            padding-top: 8px;
                        }
                    }
                `,
            )}
        `,
        medium: css`
            ${body14RegularFontStyle};
            padding: 14px 12px 14px 16px;
            ${ifProp(
                'floatingLabel',
                css`
                    &:not(:placeholder-shown) {
                        padding-top: 22px;
                        padding-bottom: 6px;
                        & ~ ${FloatingLabel} {
                            ${caption11RegularFontStyle};
                            left: 16px;
                            padding-top: 6px;
                        }
                    }
                `,
            )}
        `,
        small: css`
            ${body14RegularFontStyle};
            padding: 10px 12px 10px 16px;
            // floatingLabel not supported for small
            & ~ ${FloatingLabel} {
                display: none;
            }
        `,
    });

const activeCss = css`
    &,
    &:hover {
        background-color: ${theme('bg.secondary')};
        color: ${theme('label.primary')};
        box-shadow: var(--active-box-shadow);
        -webkit-appearance: none;
        & ~ ${FloatingLabel} {
            color: var(--active-floating-label-color);
        }
    }
`;

const borderAndBoxShadow = (borderColor: string, boxShadow: string) => `
    ${boxShadowLikeBorder('1px', borderColor)}, ${boxShadow};
`;

export const wtField2Css = css`
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    caret-color: ${theme('label.accent')};
    border: none;
    outline: none;
    -webkit-appearance: none;

    // ---> identical for Primary and Outline
    &::placeholder {
        color: ${theme('label.placeholder')};
    }
    --active-box-shadow: ${({ theme: _theme }) =>
        borderAndBoxShadow(_theme.fillIn.primary.mid, _theme.focusOutline.accent)};
    & ~ ${FloatingLabel} {
        --active-floating-label-color: ${theme('label.accent')};
    }
    &:focus {
        ${activeCss}
    }
    ${ifProp('active', activeCss)};
    &:disabled {
        color: ${theme('label.inactive')};
    }
    ${ifProp(
        { visualState: 'emergency' },
        css`
            color: ${theme('label.negative')};
            &:hover {
                color: ${theme('label.primary')};
            }
            --active-box-shadow: ${({ theme: _theme }) =>
                borderAndBoxShadow(_theme.fillIn.negative.mid, _theme.focusOutline.negative)};
            & ~ ${FloatingLabel} {
                --active-floating-label-color: ${theme('label.negative')};
            }
        `,
    )}
    // identical for Primary and Outline <---


    ${switchProp('kind', {
        primary: css`
            background: ${theme('fillIn.secondary.overlay16')};
            &:hover {
                background: ${theme('fillIn.secondary.overlay24')};
            }
            ${ifProp(
                { visualState: 'emergency' },
                css`
                    background: ${theme('fillIn.negative.overlay16')};
                    &:hover {
                        background: ${theme('fillIn.negative.overlay16')};
                    }
                `,
            )}
        `,
        outline: css`
            ${({ theme: _theme }) => css`
                box-shadow: ${boxShadowLikeBorder('1px', _theme.fillIn.secondary.low)};
                &:hover {
                    box-shadow: ${boxShadowLikeBorder('1px', _theme.fillIn.secondary.mid)};
                }
                ${ifProp(
                    { visualState: 'emergency' },
                    css`
                        box-shadow: ${boxShadowLikeBorder('1px', _theme.fillIn.negative.mid)};
                    `,
                )}
            `}
        `,
    })}

    ${ifProp(
        'hasClearButton',
        css`
            padding-right: 44px;
        `,
    )}

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const Field = styled.textarea`
    // deprecated
`;

export const Wrapper = styled.div`
    position: relative;

    flex-grow: 1;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Field2 = styled.textarea<{
    getComponentRef: (node: HTMLTextAreaElement | HTMLInputElement | null) => void;
    active: boolean;
    usePullScroll: boolean;
    visualState: VisualState;
    size: WtField2Size | ResponsiveMap<WtField2Size>;
    kind: WtFieldKind;
}>`
    resize: vertical;
    ${pullScrollProcess};
    ${buildSizes};
    ${wtField2Css};
    &:placeholder-shown ~ ${FloatingLabel} {
        display: none;
    }
`;

export const SizeWrapper = styled.div<{ size: WtField2Size | ResponsiveMap<WtField2Size> }>`
    display: grid;

    &:after {
        content: attr(data-replicated-value) ' ';
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        visibility: hidden;
        grid-area: 1 / 1 / 2 / 2;
        ${buildSizes};
        border-radius: 24px;
        min-width: 0;
    }

    & > textarea {
        resize: none;
        overflow: hidden;
        grid-area: 1 / 1 / 2 / 2;
        min-width: 0;
        overflow-wrap: anywhere;
        font-variant-numeric: tabular-nums;
    }
`;
