import styled, { css } from 'styled-components';

import { reduceBreakpoints } from 'app/styled';
import { Theme } from 'services/theme';

import { MenuItemKind, MenuItemProps, StateMap } from '../types';

import { IconedText } from './index';

const getColorTextIconCss = (colorText: string, colorIcon: string) => `
    ${IconedText} {
        color: ${colorText};
        svg {
            fill: ${colorIcon};
            color: ${colorIcon};
        }
    }
`;

const colorMap = ({ fillIn, label }: Theme, kind: MenuItemKind): StateMap => {
    const defaultColor = getColorTextIconCss(label.primary, label.secondary);

    const kindMap: Record<MenuItemKind, StateMap> = {
        primary: {
            default: defaultColor,
            hover: `
                ${defaultColor};
                background: ${fillIn.primary.overlay16};
            `,
            disabled: getColorTextIconCss(label.inactive, label.inactive),
            isSelected: `
                background: ${fillIn.positive.overlay24};
                ${getColorTextIconCss(label.accent, label.accent)};
            `,
            isSelectedHovered: `
                background: ${fillIn.positive.overlay32};
                ${getColorTextIconCss(label.accent, label.accent)};
            `,
        },
        secondary: {
            default: defaultColor,
            hover: `
                ${defaultColor};
                background: ${fillIn.secondary.overlay16}
            `,
            disabled: getColorTextIconCss(label.inactive, label.inactive),
            isSelected: `
                background: ${fillIn.secondary.overlay24};
                ${getColorTextIconCss(label.primary, label.primary)};
            `,
            isSelectedHovered: `
                background: ${fillIn.secondary.overlay32};
                ${getColorTextIconCss(label.primary, label.primary)};
            `,
        },
    };

    return kindMap[kind];
};

type MenuItemWrapperStyledProps = Required<
    Pick<MenuItemProps, 'disabled' | 'isSelected' | 'isHovered' | 'kind' | 'size'>
> &
    Pick<MenuItemProps, 'width'>;

const colors = ({ theme, isSelected, kind, isHovered, disabled }: { theme: Theme } & MenuItemWrapperStyledProps) => {
    const kindMap = colorMap(theme, kind);

    const hoverState = css`
        &:hover {
            ${isSelected ? kindMap.isSelectedHovered : kindMap.hover};
        }
    `;

    if (isSelected && isHovered) {
        return `
            ${kindMap.isSelectedHovered};
        `;
    }

    if (isSelected) {
        return css`
            ${hoverState};
            ${kindMap.isSelected};
        `;
    }

    if (isHovered) {
        return `
            ${kindMap.hover};
        `;
    }

    if (disabled) {
        return kindMap.disabled;
    }

    return css`
        ${kindMap.default};

        ${hoverState};

        :disabled {
            ${kindMap.disabled};
        }
    `;
};

export const MenuItemWrapper = styled.div`
    // нужно чтобы работал скролл при наведении мыши даже на disabled элементах
    pointer-events: auto;
`;

export const MenuItemActionWrapper = styled.div<MenuItemWrapperStyledProps>`
    padding: 0 12px 0 16px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
    width: ${({ width }) => {
        if (width) {
            return typeof width === 'number' ? `${width}px` : width;
        }

        return '100%';
    }};
    // focus outline
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.fillIn.primary.overlay24};
    ${colors};
    ${({ size }) =>
        reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'large':
                    return css`
                        height: 48px;
                    `;
                case 'regular':
                default:
                    return css`
                        height: 40px;
                    `;
            }
        })};
`;

export const ContentWrapper = styled.div`
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
`;
