import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const UmbrelaIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path d="M9.40635 15.0859L9.35791 8.39531L10.2282 8.44375L10.2767 15.4484L9.40635 15.0859Z" fill="#6AA5AD" />
        <path
            d="M7.41568 16.35C6.90475 16.325 6.64381 16.7109 6.70943 17.4328C6.77506 18.1547 7.20006 19.25 8.67818 19.2219C10.3844 19.1891 10.5141 18.0031 10.486 16.6891C10.4672 15.775 10.486 15.0516 10.4376 15.0187C10.3891 14.9859 9.20318 14.9547 9.13756 15.0203C9.07193 15.0859 9.12037 16.5141 9.10475 16.8578C9.08912 17.2016 9.1485 17.8922 8.51412 17.875C8.02193 17.8609 7.95631 17.4484 7.95631 17.2516C7.95787 17.0562 8.07193 16.3828 7.41568 16.35Z"
            fill="#3F7377"
        />
        <path
            d="M8.14382 5.65781L9.77194 2.72812C9.77194 2.72812 6.561 2.33125 3.70944 4.54531C0.0844423 7.36094 0.65788 10.4469 0.65788 10.4469C0.65788 10.4469 2.03913 9.13125 3.16882 9.23125C4.2985 9.33125 5.71413 10.6984 5.71413 10.6984L8.14382 5.65781Z"
            fill="#937EF3"
        />
        <path
            d="M10.1657 3.62656L9.93286 2.72812C9.93286 2.72812 13.1938 2.69219 15.6235 4.28437C19.8 7.02187 19.3391 10.0781 19.3391 10.0781C19.3391 10.0781 18.4329 8.81562 16.4422 8.90469C14.4516 8.99375 13.7235 10.7703 13.7235 10.7703L10.1657 3.62656Z"
            fill="#5547F4"
        />
        <path
            d="M13.7282 10.6438C13.7282 10.6438 13.5469 7.65625 12.8532 6.12031C11.5876 3.31562 9.84538 2.77344 9.84538 2.77344C9.84538 2.77344 7.7485 3.80469 6.64538 6.34688C5.54225 8.88906 5.73131 10.6844 5.73131 10.6844C5.73131 10.6844 7.42819 8.74531 9.8985 9.0125C12.3688 9.27969 13.7282 10.6438 13.7282 10.6438Z"
            fill="#6E62ED"
        />
        <path
            d="M9.23607 2.84843C9.2142 2.7625 9.23295 1.61406 9.22513 1.36718C9.2142 1.00156 9.52513 0.768745 9.80482 0.776558C10.1704 0.787495 10.2986 1.10937 10.3095 1.33437C10.3204 1.55937 10.322 2.82187 10.3204 2.8375C10.3064 2.96875 10.1142 3.09374 9.77357 3.09531C9.52513 3.09531 9.26576 2.96718 9.23607 2.84843Z"
            fill="#6CA4AB"
        />
        <path
            d="M13.7173 11.0297C13.9736 11.0141 14.997 9.25469 16.6032 9.27031C18.7829 9.29219 19.0642 10.425 19.2923 10.3078C19.5439 10.1797 19.1736 9.70937 18.8439 9.36875C18.3782 8.8875 17.6329 8.59688 16.4751 8.67188C14.7892 8.78125 13.7017 10.2766 13.7017 10.2766C13.7017 10.2766 12.2642 8.70781 9.74387 8.70781C7.30637 8.70781 5.73293 10.4422 5.73293 10.4422C5.73293 10.4422 4.93606 9.03281 2.997 8.99844C1.2845 8.97031 0.543871 10.35 0.664184 10.4703C0.784496 10.5906 1.697 9.49688 2.97668 9.55156C4.73606 9.62656 5.35168 11.0969 5.68918 11.1047C6.02512 11.1125 7.33918 9.33906 9.77512 9.38125C12.3907 9.42656 13.4767 11.0453 13.7173 11.0297Z"
            fill="#004DAE"
        />
    </EmojiIconSvg>
);
