import {
    body15MediumFontStyle,
    caption12MediumFontStyle,
    caption9MediumFontStyle,
    h1MediumFontStyle,
    h2MediumFontStyle,
    h3MediumTNumFontStyle,
    h4MediumFontStyle,
} from 'services/typography';

export const getBorderRadiusBySize = (itemSize: number): number => {
    if (itemSize <= 20) {
        return 4;
    }
    if (itemSize <= 32) {
        return 6;
    }
    if (itemSize <= 40) {
        return 8;
    }

    return 12;
};

export const getInitialsTextFontBySize = (itemSize: number): string => {
    if (itemSize <= 20) {
        return caption9MediumFontStyle;
    }
    if (itemSize <= 32) {
        return caption12MediumFontStyle;
    }
    if (itemSize <= 40) {
        return body15MediumFontStyle;
    }
    if (itemSize <= 64) {
        return h4MediumFontStyle;
    }
    if (itemSize <= 88) {
        return h3MediumTNumFontStyle;
    }
    if (itemSize <= 128) {
        return h2MediumFontStyle;
    }

    return h1MediumFontStyle;
};
