import React, { useMemo, ReactElement } from 'react';

import { UserBlockSize } from '../types';
import * as Styled from '../styled/SecondLine.styled';

type SecondLineProps = {
    secondLine?: string | ReactElement;
    size?: UserBlockSize;
    isLoading?: boolean;
};

export const SecondLine = React.memo((props: SecondLineProps) => {
    const { secondLine, size = 'regular', isLoading = false } = props;

    const secondLineEl = useMemo(() => {
        if (isLoading) {
            return '';
        }

        if (typeof secondLine === 'string') {
            return <Styled.TextOneLineTooltip size={size}>{secondLine}</Styled.TextOneLineTooltip>;
        }

        return secondLine;
    }, [isLoading, secondLine, size]);

    return (
        <Styled.SecondLine isLoading={isLoading} size={size} className="autotest__user-block__second-line">
            {secondLineEl}
        </Styled.SecondLine>
    );
});
