import { AllLangsData, Lcid, DicWordParams as Params } from 'types';
import UrlProcessor from 'services/url-processor';
import { getCookie } from 'services/utils/getCookie';
import { sentryClient, SentryError } from 'services/sentry';

type CacheObject = {
    ok: boolean;
    text: string;
    trueJSON: string;
};

function makeParamsHash(key: string, params: Params) {
    let hash = `${key}:${Object.keys(params).length}`;

    // example: _key1_value1_key2_value2
    hash += Object.entries(params)
        .map(([_key, value]) => ['', _key, value].join('_'))
        .join();

    return hash;
}

function normalizeParams(params: Params | undefined) {
    let normalizedParams: Record<string | number, string | number | boolean | undefined> = {};

    if (params) {
        if (params instanceof Array) {
            // make named args
            params.forEach((param, index) => {
                normalizedParams[index] = param;
            });
        } else if (typeof params === 'string') {
            normalizedParams[0] = params;
        } else if (typeof params === 'object') {
            // mars: последний т.к. в js всё объекты
            normalizedParams = params;
        }
    }

    // dg: Приводим аргумент is_male к виду, поддерживаемому сервисом dct
    if (normalizedParams.is_male !== undefined) {
        if (normalizedParams.is_male !== 'f') {
            normalizedParams.is_male = normalizedParams.is_male ? 't' : 'f';
        }
    }

    return normalizedParams;
}

function replaceNamedArgsWithValues(
    templateString: string,
    params: Record<string, string | number | boolean | undefined>,
) {
    let resultString = templateString;

    Object.keys(params)
        .filter((key) => key !== 'ignoreMissing' && key !== 'is_male')
        .forEach((key) => {
            resultString = resultString.replace(new RegExp(`{\\$${key}}`, 'g'), `${params[key]}`);
            resultString = resultString.replace(new RegExp(`\\$${key}(?=:)`, 'g'), `${params[key]}`);
        });

    return resultString;
}

function correctGender(dicValue: string, isMale: boolean | null | undefined) {
    // dz: считаем что все гендерное оформлено как {{он|она}}
    const regexp = /{{([^}]*)\|([^}]*)}}*/g;

    return dicValue.replace(regexp, isMale || isMale == null ? '$1' : '$2');
}

function vsprintf(text: string, replacements: Array<string | number>) {
    let index = 0;
    return text.replace(/%./g, () => `${replacements[index++]}`);
}

function correctDeclension(text: string) {
    const regExp = /((?:\d+)?)(\D*?){(\d*:*\s*)([^{}]*)\|([^{}]*)\|([^{}]*)}/gi;

    return text.replace(regExp, (match, num, delimiter, quantity, one, some, more) => {
        let rightWord;
        let count = parseInt(num, 10);
        if (quantity.trim()) {
            count = parseInt(quantity, 10);
        }
        count %= 100;

        if (count >= 5 && count <= 20) {
            rightWord = more;
        } else {
            count %= 10;
            if (count === 1) {
                rightWord = one;
            } else if (count >= 2 && count <= 4) {
                rightWord = some;
            } else {
                rightWord = more;
            }
        }

        return num + delimiter + rightWord;
    });
}

export class Dictionary {
    private _dictionary?: AllLangsData;

    private lcid: Lcid;

    // ключ строится по makeParamsHash
    private readonly _cache: Record<string, string>;

    private readonly _asyncCache: Record<string, Promise<string>>;

    constructor(lcid?: Lcid) {
        this._cache = {};
        this._asyncCache = {};
        this.lcid = lcid || 'en';
    }

    public addWord(key: string, translations: Record<Lcid, string>) {
        if (!this._dictionary) {
            this._dictionary = { ru: {}, en: {} };
        }
        Object.entries(translations).forEach(([locale, translation]) => {
            const dictionary = this._dictionary;

            if (dictionary) {
                if (!dictionary[locale as Lcid]) {
                    dictionary[locale as Lcid] = {};
                }
                dictionary[locale as Lcid][key] = translation;
            }
        });
    }

    public clone(lcid: Lcid) {
        const newDic = new Dictionary(lcid);
        newDic.setDicwords(this._dictionary);
        return newDic;
    }

    public setDicwords(dicwords: AllLangsData | undefined) {
        if (dicwords) {
            this._dictionary = dicwords;
        }
    }

    public setLcid(lcid: Lcid) {
        this.lcid = lcid;
    }

    private _getDicword(lcid: Lcid, key: string) {
        if (!this._dictionary?.[lcid]) {
            return undefined;
        }

        if (!this._dictionary[lcid][key]) {
            return undefined;
        }

        return this._dictionary[lcid][key];
    }

    public word(key: string, params?: Params, lcid?: Lcid): string {
        if (getCookie('renderDictionaryKeys') === '1') {
            return key;
        }
        let dicValue: string | undefined;
        dicValue = this._getDicword(lcid || this.lcid, key);
        if (typeof dicValue === 'undefined') {
            dicValue = this._getDicword(lcid || this.lcid, key);

            if (typeof dicValue === 'undefined') {
                // dmh: опция - не ходить за диквордом по API если он не найден, а просто вернуть ключ @since WTT-9808
                if (typeof params === 'object' && params && !(params instanceof Array) && params.ignoreMissing) {
                    return key;
                }

                // fetch text from server
                return this.fetchTextSync(key, normalizeParams(params));
            }
        }

        if (!dicValue) {
            return '';
        }

        let resultDicValue: string = dicValue;
        if (!params) {
            return resultDicValue;
        }

        if (params instanceof Array) {
            resultDicValue = vsprintf(resultDicValue, params);
        } else if (typeof params === 'string') {
            resultDicValue = vsprintf(resultDicValue, [params]);
        } else if (typeof params === 'object') {
            // mars: последний т.к. в js всё объекты
            if (Object.hasOwnProperty.call(params, 'is_male')) {
                let isMale = params.is_male;
                if (typeof isMale === 'string') {
                    isMale = isMale !== 'false' && isMale !== 'f'; // если пол женский isMale = false или f, по дефолту будет мужской пол (для строк)
                }
                resultDicValue = correctGender(resultDicValue, isMale);
            }

            resultDicValue = replaceNamedArgsWithValues(resultDicValue, params);
        }

        return correctDeclension(resultDicValue);
    }

    public parts(key: string, params?: Params, lcid?: Lcid) {
        return this.word(key, params, lcid).split('||');
    }

    private fetchTextSync(key: string, params: Params = {}) {
        // calculating params hash
        const paramsHash = makeParamsHash(key, params);

        // lookup in cache
        if (!(paramsHash in this._cache)) {
            // perfoming ajax request
            const xhr = new XMLHttpRequest();
            xhr.open('GET', UrlProcessor.page('dic_text').param('key', key).queryArgs(params).url(), false);
            xhr.send();

            if (xhr.status === 404) {
                return `not-found: ${key}`;
            }

            try {
                // parse JSON & get text
                const data: { result: CacheObject } = JSON.parse(xhr.responseText);

                if (data.result.text?.substring(0, 3) === '???') {
                    console.error(`Не найден дикворд: ${key}`);
                }
                console.log('Дикворд подгружен по сети:', key, data.result);
                sentryClient.captureException(
                    new SentryError('Дикворд подгружен по сети', 'DicwordNotIncluded'),
                    (scope) => {
                        scope.setTag('exception_type', 'DicwordNotIncluded');
                        scope.setExtra('dicword', { key, result: data.result });
                        return scope;
                    },
                );
                // save to cache and return the result
                this._cache[paramsHash] = data.result.text;
            } catch (err) {
                console.error(key, 'Error while parsing DIC service response', err);
                return key;
            }
        }

        return this._cache[paramsHash];
    }

    public fetchTextAsync(key: string, params: Params = {}, callbackSuccess = undefined, callbackError = undefined) {
        // calculating params hash
        const paramsHash = makeParamsHash(key, params);

        // lookup in cache
        if (!(paramsHash in this._asyncCache)) {
            this._asyncCache[paramsHash] = fetch(
                UrlProcessor.page('dic_text').param('key', key).queryArgs(params).url(),
            )
                .then((response) => response.json())
                .then((data) => data.result.text);
        }

        return this._asyncCache[paramsHash].then(callbackSuccess, callbackError);
    }
}

export const Dic = new Dictionary();
export default Dic;
