import { useCallback, useEffect, useRef } from 'react';

export function useClickAndHold(callback: () => void, delay: number, interval: number): [() => void, () => void] {
    const callbackRef = useRef<(() => void) | undefined>(callback);
    const timerRef = useRef<number>(0);

    const hold = useCallback(() => {
        callbackRef.current?.();
        timerRef.current = window.setTimeout(() => {
            clearTimeout(timerRef.current);
            timerRef.current = window.setInterval(() => {
                callbackRef.current?.();
            }, interval);
        }, delay);
    }, [delay, interval]);

    const release = useCallback(() => {
        clearTimeout(timerRef.current);
    }, []);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => () => clearTimeout(timerRef.current), []);

    return [hold, release];
}
