/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import fill from 'lodash/fill';

import { notEmpty } from 'services/utils/not-empty';

import { AsyncMethod, GlobalStateForCache, PaginationConfig, Path, ResourcesMap } from '../types';
import { selectorsCache } from '../cache/selectorsCache';
import { prefixObjectKeys } from '../utils/prefixObjectKeys';

import { createSelectPaginatedRequestData } from './selectPaginatedRequestData';
import { createSelectPaginatedRequestState } from './selectPaginatedRequestState';

export function createSelectPaginatedRequest<
    TYPES_MAP extends object,
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    DATA_PATH extends Path<Awaited<ReturnType<API_METHOD>>> | '',
    PAGE_INFO,
    CUSTOM_PREFIX extends string,
>({
    resourcesMap,
    endpointName,
    dataPath,
    pagination,
    customPrefix,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: ENDPOINT_NAME;
    dataPath?: DATA_PATH;
    pagination: PaginationConfig<API_METHOD, PAGE_INFO>;
    customPrefix?: CUSTOM_PREFIX;
}) {
    const typeKey = { endpointName };
    const selectPaginatedRequestData = createSelectPaginatedRequestData({
        resourcesMap,
        endpointName,
        pagination,
    });
    const selectPaginatedRequestState = createSelectPaginatedRequestState({ endpointName, pagination });
    return (state: GlobalStateForCache, argsOrRequestName: Parameters<API_METHOD>[0] | string) => {
        const pages = selectPaginatedRequestData(state, argsOrRequestName).filter(notEmpty);

        const requestState = selectPaginatedRequestState(state, argsOrRequestName);
        const cached = selectorsCache.get([typeKey, ...pages, requestState]);

        if (cached) {
            return cached;
        }
        const items = flatten(
            pages.map((page) => {
                if (dataPath === undefined || dataPath === '') {
                    return page;
                }
                return get(page, dataPath);
            }),
        );
        if (requestState.totalItems !== undefined) {
            const { length } = items;
            items.length = Math.max(length, requestState.totalItems);
            fill(items, null, length);
        }
        const newValue = prefixObjectKeys(customPrefix ?? endpointName, {
            '': items,
            ...requestState,
        });

        selectorsCache.set([typeKey, ...pages, requestState], newValue);
        return newValue;
    };
}
