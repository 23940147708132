import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body16MediumFontStyle } from 'services/typography';
import { WtPopup3 } from 'components/wt-popup3';
import { ActionButtons } from 'components/wt-popup3/footer/styled';

import { SettingItem as SettingItemComponent } from '../components/SettingItem';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const SettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SettingsDescription = styled.span`
    ${body16MediumFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const SettingItem = styled(SettingItemComponent)`
    padding-bottom: 8px;
    &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid ${({ theme }) => theme.divider.primary};
    }
`;

export const Popup = styled(WtPopup3)`
    ${ActionButtons} {
        ${mediaQuery.lt480} {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
`;
