/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from 'reselect';
import stringify from 'fast-json-stable-stringify';

import { AsyncMethod, GlobalStateForCache, PaginationConfig, ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

export function createSelectPaginatedRequestData<
    TYPES_MAP extends object,
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    PAGE_INFO,
>({
    resourcesMap,
    endpointName,
    pagination,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: ENDPOINT_NAME;
    pagination: PaginationConfig<API_METHOD, PAGE_INFO>;
}) {
    return createSelector(
        (state: GlobalStateForCache) => state.cache.data,
        (state: GlobalStateForCache) => state.cache.args[endpointName],
        (_: unknown, argsOrRequestName: Parameters<API_METHOD>[0] | string) => argsOrRequestName,
        (resources, argsMap, argsOrRequestName): Awaited<ReturnType<API_METHOD>>[] => {
            const args = typeof argsOrRequestName === 'string' ? argsMap?.named[argsOrRequestName] : argsOrRequestName;
            if (!args) {
                return [];
            }
            const pagesArgs = argsMap?.pagination?.[stringify(pagination?.getCommonArgs(args))];

            return (
                pagesArgs?.map(
                    (pageArgs) =>
                        cacheRead({
                            kind: 'request',
                            cache: resources,
                            resourcesMap,
                            typename: endpointName,
                            id: pageArgs.key,
                        })?.result,
                ) ?? []
            );
        },
    );
}
