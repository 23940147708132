// https://git.finam.ru/projects/WT/repos/global-auth/browse/README-API.md

import { Lcid, ILoginResponse } from 'types';
import Deferred from 'services/utils/deferred';
import notify from 'services/notify';
import { Dic } from 'services/dictionary';
import { createTask, taskQ } from 'services/task-q';

import { provideTxGA, provideJWT, resolveJWT, enrichJWT } from './operations/provide-tx-ga';
import { initScript } from './operations/init-script';
import { makeLogin } from './operations/make-login';
import { makeLogout } from './operations/make-logout';
import { handleRegistration } from './operations/handle-registration';
import { gaProviderLogger } from './operations/logger';
import { GUEST_PROVIDER } from './operations/constants';

provideTxGA();

export async function initTxGa(lang: Lcid) {
    initScript(lang);
    const txGlobalAuth = await provideTxGA();
    const loginDefer = new Deferred<boolean>();
    txGlobalAuth.subscribeJWT(async (response) => {
        try {
            const { token, provider = '' } = response || {};
            window.WT?.Page?.logger?.log('GA response:', response);
            if (GUEST_PROVIDER.includes(provider)) {
                resolveJWT({
                    ...response,
                    token: '',
                });
                return;
            }
            if (token) {
                gaProviderLogger.log('Token is', response);
                await makeLogin(token);
                resolveJWT({ ...response });
                // не важно во что резолвится
                loginDefer.resolve(true);
            } else {
                gaProviderLogger.log('No token!!!');
                resolveJWT({
                    ...response,
                    token: '',
                });
                await makeLogout();
            }
        } catch (e) {
            gaProviderLogger.error(e);
            resolveJWT({
                ...response,
                token: '',
                provider: '',
            });
            notify.error(Dic.word('wt_all__notification__common_error'));
            throw e;
        }
    });

    handleRegistration(txGlobalAuth, loginDefer);
}

const addTask = (callback: () => Promise<ILoginResponse | void>) => {
    const task = createTask('GaWindow', () => callback().finally(() => task.finish()));
    taskQ.add(task);
};

export async function login() {
    const txGlobalAuth = await provideTxGA();
    addTask(() => txGlobalAuth.authenticate());
}

export async function registration() {
    const txGlobalAuth = await provideTxGA();
    addTask(() => txGlobalAuth.authenticate({ start: 'registration' }));
}

export async function logout() {
    const txGlobalAuth = await provideTxGA();
    addTask(() => txGlobalAuth.logout());
}

export async function changeCredentials() {
    const txGlobalAuth = await provideTxGA();
    txGlobalAuth.showSettings();
}

export async function setLang(lcid: Lcid) {
    const txGlobalAuth = await provideTxGA();
    txGlobalAuth.setLang(lcid);
}

export { provideTxGA, provideJWT, enrichJWT };
