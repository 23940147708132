export class Deferred<T> {
    private readonly _promise: Promise<T>;

    private _resolve!: (value: T | PromiseLike<T>) => void;

    private _reject!: (reason?: unknown) => void;

    constructor() {
        this._promise = new Promise<T>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    public get promise(): Promise<T> {
        return this._promise;
    }

    public resolve = (value: T | PromiseLike<T>): void => {
        this._resolve(value);
    };

    public reject = (reason?: unknown): void => {
        this._reject(reason);
    };
}

export default Deferred;
