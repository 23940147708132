import { PublishStatusRecord } from 'types';

import { isPremiumPost } from '../../helpers';

import { useIsOwner } from './useIsOwner';

export const useIsPremiumExtend = (record?: PublishStatusRecord) => {
    const { obj } = record || {};
    const isOwner = useIsOwner(record);
    const isPremium = isPremiumPost(obj);
    const isPremiumGranted = obj?.isPremiumGranted || false;
    const isPremiumExtend = !isPremium || (isPremium && (isPremiumGranted || isOwner));
    return { isPremiumExtend, isPremium, isPremiumGranted };
};
