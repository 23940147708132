import {
    BlogPostExporter,
    CommentExporter,
    ComonRecordExporter,
    GroupExporter,
    InstrumentExporter,
    RecommendedUsersContainerExporter,
    UserExporter,
    VotesExporter,
} from 'services/api-services/generated_limex';

import { Person } from '../person';
import { Id } from '../shared';

import { FeedRecordOptions } from './options';
import { FeedRecordType } from './shared';

export * from './obj';
export * from './stats';
export * from './shared';
export * from './options';
export * from './tags';

export type RatingReactionAction = 'unlike' | 'like' | 'dislike';

export type FeedRecordSource = 'feed' | 'timeline';

export type FeedRecordInstrument = InstrumentExporter;

export type FeedRecordComment = CommentExporter & {
    // признак того что пользователь удалил коммент, сохраняется локально
    isLocalDeleted?: boolean;
    repliesCount?: number;
    // количество комментариев/ответов до и после последней запрошенной пачки
    pinnedRepliesAmount?: {
        before?: number;
        after?: number;
        total?: number;
    } | null;
    replies?: FeedRecordComment[];
};

export type FeedRecordObj = Omit<BlogPostExporter, 'id' | 'author' | 'instruments' | 'comments' | 'recommendations'> & {
    id: Id;
    author: Person & {
        subscribed?: boolean;
    };
    instruments: FeedRecordInstrument[];
    comments: FeedRecordComment[];
    recommendations?: Person[];
    mentions: UserExporter[];
};

export enum GeneratorType {
    USER = 1,
    GROUP = 2,
    INSTRUMENT = 5,
    PREMIUM_POST = 36,
}

export type FeedRecordGenerator = Omit<UserExporter, 'id'> & {
    id: Id;
};

interface FeedRecordDataReason {
    latestReasonsData?: Record<string, unknown>;
}

export interface FeedRecordData {
    reason?: FeedRecordDataReason;
}

export type FeedRecordStatsLikes = VotesExporter;

export type FeedRecordStats = ComonRecordExporter['stats'] & {
    viewsCount?: number;
};

export type PublishStatusRecord = Omit<
    ComonRecordExporter,
    'id' | 'type' | 'obj' | 'generator' | 'context' | 'comments' | 'stats' | 'options' | 'recommendations'
> & {
    id: Id;
    type: FeedRecordType.PUBLISH_STATUS;
    obj: FeedRecordObj;
    generator: FeedRecordGenerator;
    context: Person | GroupExporter | { type: null };
    comments: FeedRecordComment[];
    repliesAmount?: Record<string, number> | null;
    pinnedRepliesAmount?: {
        before?: number;
        after?: number;
        total?: number;
    } | null;
    stats: FeedRecordStats;
    options: Omit<ComonRecordExporter['options'], 'visLevel'> & FeedRecordOptions;
    // назначаемое на фронте поле
    publishedId?: Id | null;
    // назначается на фронте, ключ для поиска TickerNewsRecord по id
    tickerNews?: {
        tickerNewsId?: Id;
    };
};

export type TickerNewsRecord = {
    id: Id;
    type: FeedRecordType.TICKER_NEWS;
    tickerNews: {
        instrument?: InstrumentExporter;
        isSubscribed?: boolean;
        records?: PublishStatusRecord[];
    };
};

export type RecommendedUsersRecord = Omit<RecommendedUsersContainerExporter, 'type'> & {
    id: Id;
    type: FeedRecordType.RECOMMENDATIONS;
};

export type AdsRecord = {
    id: Id;
    type: FeedRecordType.ADS;
};

export type FeedRecord = PublishStatusRecord | RecommendedUsersRecord | TickerNewsRecord | AdsRecord;
