import React, { forwardRef } from 'react';

import { ContainerProps } from './types';

export const Container = forwardRef<HTMLDivElement | HTMLAnchorElement, ContainerProps>((props, forwardedRef) => {
    const { children, onClick, className, dataAttributes, target, url } = props;

    const isLink = url || false;

    const el = isLink ? 'a' : 'div';

    const GeneralProps = {
        children,
        className,
        ...dataAttributes,
    };

    const elProps = isLink
        ? {
              ...GeneralProps,
              href: url,
              target,
              onClick,
          }
        : {
              ...GeneralProps,
              onClick,
          };

    return React.createElement(el, {
        ...elProps,
        ref: forwardedRef,
    });
});
