import get from 'lodash/get';

import notify from 'services/notify';
import { Dic } from 'services/dictionary';

// в catch ошибки обычно реально имеют тип any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notifyError = (error: any, fields = 'error.message') => {
    const message = get(error, `body.${fields}`) || Dic.word('server_error');
    notify.error(message);
};
