import React, { FC, memo, ReactElement } from 'react';

import { MapStateToPropsType } from '../containers/UnreadCountContainer';

type RenderProps = MapStateToPropsType;

type UnreadCountCustomRendererConnectorOwnProps = {
    customRender: ((props: RenderProps) => ReactElement) | null;
};

type UnreadCountCustomRendererConnectorProps = MapStateToPropsType & UnreadCountCustomRendererConnectorOwnProps;

export const UnreadCountCustomRenderConnector: FC<UnreadCountCustomRendererConnectorProps> = memo((props) => {
    const { unreadCount, customRender } = props;

    const renderProps: RenderProps = {
        unreadCount,
    };

    if (customRender) {
        return customRender(renderProps);
    }

    return null;
});
