import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { StepProps } from 'widgets/product-wizard/components/steps/types';
import { FormDescription, FormSectionHeadline } from 'components/form';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';

import { ImgWrapper } from './styled';
import { Img } from './components/Img';

export const Step6: FC<StepProps<never>> = (props) => {
    const dic = useDictionary();
    const form = useForm<never>();
    useFormStateHandler({ props, form });
    return (
        <>
            <FormSectionHeadline>{dic.word('wt_all__widget_product_wizard__step7_headline')}</FormSectionHeadline>
            <FormDescription>{dic.word('wt_all__widget_product_wizard__step7_description')}</FormDescription>
            <ImgWrapper>
                <Img />
            </ImgWrapper>
        </>
    );
};
