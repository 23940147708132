import { connect } from 'react-redux';

import { GlobalStateHeaderNotifications } from '../types';
import { UnreadCountCustomRenderConnector } from '../connectors/UnreadCountCustomRenderConnector';

const mapStateToProps = (state: GlobalStateHeaderNotifications) => ({
    unreadCount: state?.widgets?.notifications?.unreadCount,
});

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;

export const UnreadCountCustomRenderContainer = connect(mapStateToProps)(UnreadCountCustomRenderConnector);
