import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import cn from 'classcat';
import flatten from 'lodash/flatten';

import notify from 'services/notify';
import { ApiError } from 'services/api-services/generated_marketplace/core/ApiError';
import { useDictionary } from 'hooks/useDictionary';
import { ActionButtonItemType } from 'components/wt-popup3/footer';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';
import { CheckOutlineIcon } from 'components/icons2/CheckOutlineIcon';

// import { MOCK_PRODUCT } from '../../mock';
// import { isNotProdAndTest } from 'services/utils/env';
import type { ProductWizardProps } from '../..';
import { PopupState } from '../steps/types';
import { Step0FormValues } from '../steps/step0/types';
import * as UserProductSteps from '../steps/user-products';
import * as LimexCopySteps from '../steps/copy-trading-strategies';
import * as SignalsSteps from '../steps/signals-trading-strategy';
import { Step0 } from '../steps/step0';

import { getSignalStrategyData } from './getSignalStrategyData';
import { getProductData } from './getProductData';
import { ContentWrapper } from './styled';
import { getCopyTradingStrategyData } from './getCopyTradingStrategyData';

export const ProductWizard: FC<ProductWizardProps> = memo((props) => {
    const {
        className: classNameProp,
        createProduct,
        createCopyTradingStrategy,
        productWizardCreateCopyTradingStrategyIsLoading,
        isShown,
        onClose,
        onSuccess,
        accountsForNewStrategy = [],
        accountsForNewStrategyIsReady,
        checkProfanity,
        createSignalStrategy,
        productWizardCreateSignalStrategyIsLoading,
        getAgreements,
        createAgreement,
        createSubscriberAgreement,
        updateAgreementStatus,
        productWizardCreateProductIsLoading,
        getAccountsForNewStrategy,
        accountsForNewStrategyIsLoading,
        productType,
    } = props;
    const dic = useDictionary();

    const [stepIndex, setStepIndex] = useState(productType ? 2 : 1);

    const className = cn(['autotest__product-wizard-widget', classNameProp]);

    const goBack = useCallback(() => {
        setStepIndex((index) => index - 1);
    }, []);

    const goNext = useCallback(async () => {
        setStepIndex((index) => index + 1);
    }, []);

    const step0FormValues = useRef<Step0FormValues | undefined>(productType ? { productType } : undefined);
    const step1FormValues = useRef<UserProductSteps.Step1FormValues>();
    const step2FormValues = useRef<UserProductSteps.Step2FormValues>();
    const step3FormValues = useRef<UserProductSteps.Step3FormValues>();
    const step4FormValues = useRef<UserProductSteps.Step4FormValues>();
    const step5FormValues = useRef<UserProductSteps.Step5FormValues>();

    const limexCopyStep1FormValues = useRef<LimexCopySteps.Step1FormValues>();
    const limexCopyStep2FormValues = useRef<LimexCopySteps.Step2FormValues>();
    const limexCopyStep3FormValues = useRef<LimexCopySteps.Step3FormValues>();
    const limexCopyStep4FormValues = useRef<LimexCopySteps.Step4FormValues>();

    const signalsStep1FormValues = useRef<SignalsSteps.Step1FormValues>();

    const [popupState, setPopupState] = useState<PopupState>({});
    const { title, steps, actionButtons } = popupState;

    const handlePopupStateChange = useCallback((state: PopupState) => {
        setPopupState(state);
    }, []);

    const stepProps = {
        checkProfanity,
        stepIndex: productType ? stepIndex - 1 : stepIndex,
        goBack,
        goNext,
        onPopupStateChange: handlePopupStateChange,
        totalSteps: productType ? 4 : 5,
    };

    const step = (() => {
        if (stepIndex === 1) {
            return (
                <Step0
                    {...stepProps}
                    valuesRef={step0FormValues}
                    getAgreements={getAgreements}
                    createAgreement={createAgreement}
                    createSubscriberAgreement={createSubscriberAgreement}
                    updateAgreementStatus={updateAgreementStatus}
                />
            );
        }
        switch (step0FormValues.current?.productType) {
            case 'UsUserProduct':
                switch (stepIndex) {
                    case 2:
                        return <UserProductSteps.Step1 {...stepProps} valuesRef={step1FormValues} />;
                    case 3:
                        return <UserProductSteps.Step2 {...stepProps} valuesRef={step2FormValues} />;
                    case 4:
                        return <UserProductSteps.Step3 {...stepProps} valuesRef={step3FormValues} />;
                    case 5:
                        return <UserProductSteps.Step4 {...stepProps} valuesRef={step4FormValues} />;
                    case 6:
                        return <UserProductSteps.Step5 {...stepProps} valuesRef={step5FormValues} />;
                    case 7:
                        return <UserProductSteps.Step6 {...stepProps} />;
                    default:
                        return null;
                }
            case 'UsStrategy':
                switch (stepIndex) {
                    case 2:
                        return <LimexCopySteps.Step1 {...stepProps} valuesRef={limexCopyStep1FormValues} />;
                    case 3:
                        return (
                            <LimexCopySteps.Step2
                                {...stepProps}
                                backOfficeAccounts={accountsForNewStrategy}
                                getBackOfficeAccounts={getAccountsForNewStrategy}
                                accountsForNewStrategyIsLoading={accountsForNewStrategyIsLoading}
                                accountsForNewStrategyIsReady={accountsForNewStrategyIsReady}
                                valuesRef={limexCopyStep2FormValues}
                            />
                        );
                    case 4:
                        return <LimexCopySteps.Step3 {...stepProps} valuesRef={limexCopyStep3FormValues} />;
                    case 5:
                        return <LimexCopySteps.Step4 {...stepProps} valuesRef={limexCopyStep4FormValues} />;
                    default:
                        return null;
                }
            case 'UsSignal':
                switch (stepIndex) {
                    case 2:
                        return <LimexCopySteps.Step1 {...stepProps} valuesRef={limexCopyStep1FormValues} />;
                    case 3:
                        return <SignalsSteps.Step1 {...stepProps} valuesRef={signalsStep1FormValues} />;
                    case 4:
                        return (
                            <LimexCopySteps.Step3
                                {...stepProps}
                                valuesRef={limexCopyStep3FormValues}
                                descriptionLabel="universe"
                            />
                        );
                    case 5:
                        return <LimexCopySteps.Step4 {...stepProps} valuesRef={limexCopyStep4FormValues} />;
                    default:
                        return null;
                }
            default:
                return null;
        }
    })();

    const theme = useTheme();

    const handleSuccess = useCallback(() => {
        notify.success({
            text: dic.word('wt_all__widget_product_wizard__notification_success_save'),
            icon: {
                component: CheckOutlineIcon,
                fill: theme.staticColors.positive,
            },
        });
        step0FormValues.current = undefined;
        step1FormValues.current = undefined;
        step2FormValues.current = undefined;
        step3FormValues.current = undefined;
        step4FormValues.current = undefined;
        step5FormValues.current = undefined;
        limexCopyStep1FormValues.current = undefined;
        limexCopyStep2FormValues.current = undefined;
        limexCopyStep3FormValues.current = undefined;
        limexCopyStep4FormValues.current = undefined;
        signalsStep1FormValues.current = undefined;
        setStepIndex(1);
        onClose();
        onSuccess?.();
    }, [dic, onClose, onSuccess, theme.staticColors.positive]);

    const handleError = useCallback(
        (error: ApiError) => {
            if (error) {
                const data = error.body?.error?.data;
                if (data && typeof data === 'object') {
                    const message = flatten(Object.values(data)).join(' ');
                    notify.error(message);
                } else {
                    notify.error(dic.word('wt_all__widget_product_wizard__notification_cant_save'));
                }
            }
        },
        [dic],
    );

    const submitUserProductButton: ActionButtonItemType = useMemo(
        () => ({
            key: 1,
            kind: 'primary',
            content: dic.word('wt_all__widget_product_wizard__step7_forward_button_text'),
            onClick: () => {
                if (step1FormValues.current && step2FormValues.current) {
                    const productData = getProductData(
                        step1FormValues.current,
                        step2FormValues.current,
                        step3FormValues.current,
                        step4FormValues.current,
                        step5FormValues.current,
                    );

                    createProduct({ requestBody: productData }).then(handleSuccess).catch(handleError);
                }
            },
            isProcessing: productWizardCreateProductIsLoading,
            className: 'autotest__marketplace__product_wizard__btn_next',
        }),
        [dic, productWizardCreateProductIsLoading, createProduct, handleSuccess, handleError],
    );

    const submitStrategyButton: ActionButtonItemType = useMemo(
        () => ({
            key: 1,
            kind: 'primary',
            content: 'Save',
            onClick: () => {
                if (
                    limexCopyStep1FormValues.current &&
                    limexCopyStep2FormValues.current &&
                    limexCopyStep3FormValues.current
                ) {
                    const productData = getCopyTradingStrategyData(
                        limexCopyStep1FormValues.current,
                        limexCopyStep2FormValues.current,
                        limexCopyStep3FormValues.current,
                        limexCopyStep4FormValues.current,
                    );

                    createCopyTradingStrategy({ requestBody: productData }).then(handleSuccess).catch(handleError);
                }
            },
            isDisabled: actionButtons?.items[0]?.isDisabled,
            isProcessing: productWizardCreateCopyTradingStrategyIsLoading,
            className: 'autotest__marketplace__product_wizard__btn_next',
        }),
        [
            actionButtons?.items,
            productWizardCreateCopyTradingStrategyIsLoading,
            createCopyTradingStrategy,
            handleSuccess,
            handleError,
        ],
    );

    const submitSignalStrategyButton: ActionButtonItemType = useMemo(
        () => ({
            key: 1,
            kind: 'primary',
            content: 'Save',
            onClick: () => {
                if (
                    limexCopyStep1FormValues.current &&
                    signalsStep1FormValues.current &&
                    limexCopyStep3FormValues.current
                ) {
                    const productData = getSignalStrategyData(
                        limexCopyStep1FormValues.current,
                        signalsStep1FormValues.current,
                        limexCopyStep3FormValues.current,
                        limexCopyStep4FormValues.current,
                    );

                    createSignalStrategy({ requestBody: productData }).then(handleSuccess).catch(handleError);
                }
            },
            isDisabled: actionButtons?.items[0]?.isDisabled,
            isProcessing: productWizardCreateSignalStrategyIsLoading,
            className: 'autotest__marketplace__product_wizard__btn_next',
        }),
        [
            actionButtons?.items,
            productWizardCreateSignalStrategyIsLoading,
            createSignalStrategy,
            handleSuccess,
            handleError,
        ],
    );

    const popupActionButtons = useMemo(() => {
        switch (step0FormValues.current?.productType) {
            case 'UsUserProduct':
                if (stepIndex < 7) {
                    return actionButtons;
                }
                return { direction: 'vertical' as const, items: [submitUserProductButton] };
            case 'UsStrategy':
                if (stepIndex < 5) {
                    return actionButtons;
                }
                return { direction: 'vertical' as const, items: [submitStrategyButton] };
            case 'UsSignal':
                if (stepIndex < 5) {
                    return actionButtons;
                }
                return { direction: 'vertical' as const, items: [submitSignalStrategyButton] };
            default:
                return actionButtons;
        }
    }, [actionButtons, stepIndex, submitSignalStrategyButton, submitStrategyButton, submitUserProductButton]);

    if (!isShown) {
        return null;
    }

    return (
        <WtPopup3
            title={title}
            size={SizeWtPopup3Enum.SMALL}
            width={464}
            close={onClose}
            steps={steps}
            actionButtons={popupActionButtons}
            className={className}
            isMobileFullscreen
        >
            <>
                <ContentWrapper>{step}</ContentWrapper>
                {/* Если понадобится мокать продукты для проверки можно разкомменитировать на момент разработки */}
                {/* перед выкладкой закомментить */}
                {/* {isNotProdAndTest() ? null : ( */}
                {/*    <Button */}
                {/*        kind="outline" */}
                {/*        size="small" */}
                {/*        onClick={() => { */}
                {/*            createProduct({ */}
                {/*                params: { */}
                {/*                    requestBody: MOCK_PRODUCT, */}
                {/*                }, */}
                {/*            }); */}
                {/*        }} */}
                {/*    > */}
                {/*        Создать замоканный продукт */}
                {/*    </Button> */}
                {/* )} */}
            </>
        </WtPopup3>
    );
});
