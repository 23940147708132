/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Marketplace_Web_Public_V1_My_UsSignalMe_CreateSignalWebRequest } from '../models/UsSignalMeService/Marketplace_Web_Public_V1_My_UsSignalMe_CreateSignalWebRequest';
import type { Marketplace_Web_Public_V1_My_UsSignalMe_CreateUsSignalsStrategyWebRequest } from '../models/UsSignalMeService/Marketplace_Web_Public_V1_My_UsSignalMe_CreateUsSignalsStrategyWebRequest';
import type { Marketplace_Web_Public_V1_My_UsSignalMe_UpdateUsSignalsStrategyWebRequest } from '../models/UsSignalMeService/Marketplace_Web_Public_V1_My_UsSignalMe_UpdateUsSignalsStrategyWebRequest';
import type { PagedWebResponse_SignalDto } from '../models/UsSignalMeService/PagedWebResponse_SignalDto';
import type { WebResponse_CreateSignalResponse } from '../models/UsSignalMeService/WebResponse_CreateSignalResponse';
import type { WebResponse_MySubscriptionDto } from '../models/shared/WebResponse_MySubscriptionDto';
import type { WebResponse_RichSignalsProductDto } from '../models/shared/WebResponse_RichSignalsProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsSignalMeService {
    /**
     * Создать стратегию signal-me
     * @returns WebResponse_RichSignalsProductDto Success
     * @throws ApiError
     */
    public static createSignalStrategy({
        requestBody,
    }: {
        /**
         * Данные стратегии
         */
        requestBody?: Marketplace_Web_Public_V1_My_UsSignalMe_CreateUsSignalsStrategyWebRequest;
    }): CancelablePromise<WebResponse_RichSignalsProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/us-signal-me/strategies',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Редактировать стратегию signal-me
     * @returns WebResponse_RichSignalsProductDto Success
     * @throws ApiError
     */
    public static updateApiVMyUsSignalMeStrategy({
        productId,
        requestBody,
    }: {
        /**
         * Идентификатор продукта (стратегии)
         */
        productId: number;
        /**
         * Данные стратегии
         */
        requestBody?: Marketplace_Web_Public_V1_My_UsSignalMe_UpdateUsSignalsStrategyWebRequest;
    }): CancelablePromise<WebResponse_RichSignalsProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/us-signal-me/strategies/{productId}',
            path: {
                productId: productId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Архивировать стратегию signal-me
     * @returns WebResponse_RichSignalsProductDto Success
     * @throws ApiError
     */
    public static deleteSignalStrategy({
        productId,
    }: {
        /**
         * Идентификатор продукта (стратегии)
         */
        productId: number;
    }): CancelablePromise<WebResponse_RichSignalsProductDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/us-signal-me/strategies/{productId}',
            path: {
                productId: productId,
            },
        });
    }

    /**
     * Получение торговых сигналов стратегии, на которую осуществлена подписка клиентом
     * @returns PagedWebResponse_SignalDto Success
     * @throws ApiError
     */
    public static getApiVMyUsSignalMe({
        subscriptionId,
        from,
        to,
        tickers,
        isDeleted,
        direction,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор подписки из маркетплейса
         */
        subscriptionId: number;
        /**
         * Время(в utc) начала для создания сигнала сигнала
         */
        from?: string | null;
        /**
         * Время(в utc) окончания для создания сигнала сигнала
         */
        to?: string | null;
        /**
         * Тикеры ценных бумаг
         */
        tickers?: Array<string> | null;
        /**
         * Удален ли сигнал
         */
        isDeleted?: boolean | null;
        /**
         * Направление - покупка/ продажа
         */
        direction?: 'Buy' | 'Sell' | null;
        /**
         * Номер страницы(по дефолту - 1)
         */
        page?: number;
        /**
         * Размер страницы(по дефолту - 20)
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_SignalDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/us-signal-me/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
            query: {
                From: from,
                To: to,
                Tickers: tickers,
                IsDeleted: isDeleted,
                Direction: direction,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Запрос на удаление подписки на сигнальную стратегию
     * @returns WebResponse_MySubscriptionDto Success
     * @throws ApiError
     */
    public static deleteSubscriptionSignalStrategy({
        subscriptionId,
    }: {
        /**
         * Идентификатор подписки
         */
        subscriptionId: number;
    }): CancelablePromise<WebResponse_MySubscriptionDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/us-signal-me/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
        });
    }

    /**
     * Получение торговых сигналов стратегии автора
     * @returns PagedWebResponse_SignalDto Success
     * @throws ApiError
     */
    public static getProductSignals({
        productId,
        from,
        to,
        tickers,
        isDeleted,
        direction,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
        /**
         * Время(в utc) начала для создания сигнала сигнала
         */
        from?: string;
        /**
         * Время(в utc) окончания для создания сигнала сигнала
         */
        to?: string;
        /**
         * Тикеры ценных бумаг
         */
        tickers?: Array<string>;
        /**
         * Удален ли сигнал
         */
        isDeleted?: boolean;
        /**
         * Направление - покупка/ продажа
         */
        direction?: 'Buy' | 'Sell';
        /**
         * Номер страницы(по дефолту - 1)
         */
        page?: number;
        /**
         * Размер страницы(по дефолту - 20)
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_SignalDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/us-signal-me/{productId}/signals',
            path: {
                productId: productId,
            },
            query: {
                From: from,
                To: to,
                Tickers: tickers,
                IsDeleted: isDeleted,
                Direction: direction,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Запрос на создание сигнала по ценной бумаге для us сигнальной стратегии
     * @returns WebResponse_CreateSignalResponse Success
     * @throws ApiError
     */
    public static createApiVMyUsSignalMeSignals({
        requestBody,
    }: {
        /**
         * Данные сигнала
         */
        requestBody?: Marketplace_Web_Public_V1_My_UsSignalMe_CreateSignalWebRequest;
    }): CancelablePromise<WebResponse_CreateSignalResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/us-signal-me/signals',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }
}
