import React, { useCallback } from 'react';

import { ProductItem, TypeProductItem } from 'types/product';

import { getProductTypeByItem, isDefaultProductItem, isStrategyProductItem, isStrategyUsProductItem } from './utils';
import { ProductCardPropsType } from './types';
import { StrategyUsProductCard } from './strategyUS';
import { StrategyProductCard } from './strategy';
import { DefaultProductCard } from './default';

export const ProductCard = React.memo((props: ProductCardPropsType) => {
    const { item, onClick: onClickProp, className: classNameProp, isDisable, ...restProps } = props;

    const type = item ? getProductTypeByItem(item) : null;

    const className = [classNameProp, 'autotest__product-card'].filter((i) => i).join(' ');

    const handleClickItem = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (item) {
                const onClickItemHandler =
                    (event: React.MouseEvent<HTMLElement>) =>
                    (productItem: ProductItem, productType: TypeProductItem) => {
                        onClickProp?.(event, productItem, productType);
                    };
                if (type !== null) {
                    onClickItemHandler(e)(item, type);
                }
            }
        },
        [item, type, onClickProp],
    );

    const onClick = onClickProp && item && !isDisable ? handleClickItem : undefined;

    const productWithoutItemProps = {
        onClick,
        ...restProps,
    };

    if (item && isStrategyUsProductItem(item)) {
        return (
            <StrategyUsProductCard
                item={item}
                className={className}
                isDisableAutoDetectUrl={isDisable}
                {...productWithoutItemProps}
            />
        );
    }

    if (item && isStrategyProductItem(item)) {
        return (
            <StrategyProductCard
                item={item}
                className={className}
                isDisableAutoDetectUrl={isDisable}
                {...productWithoutItemProps}
            />
        );
    }

    if (item && isDefaultProductItem(item)) {
        return (
            <DefaultProductCard
                item={item}
                className={className}
                isDisable={isDisable}
                targetCardLink="_blank"
                {...productWithoutItemProps}
            />
        );
    }

    return <></>;
});

export { DefaultProductCard, StrategyProductCard, StrategyUsProductCard };
