import React, { useCallback, useEffect } from 'react';

import { FeedRecord, isPublishStatusRecord, PostType } from 'types';
import { cookiePrivacySettings } from 'services/cookie-privacy-settings';
import { useFeedContext } from 'hooks/useFeedContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { InfiniteScroll } from 'components/infinite-scroll';

import { AddFeedStatus } from '../../add-status';
import type { FeedProps } from '../../container';
import { usePublisherFn } from '../hooks/usePublisherFn';
import { useStats } from '../hooks/useStats';
import { useFetchRecords } from '../hooks/useFetchRecords';
import { useTickerNews } from '../hooks/useTickerNews';
import { useGallery } from '../hooks/useGallery';

import { addConversionCode, addTaboolaPixelCode } from './addScripts';
import { FeedWrapper, ItemsList } from './styled';
import { ErrorBlock } from './ErrorBlock';
import { FeedItem } from './FeedItem';
import { Loading } from './items/loading';

export const Feed = (props: FeedProps) => {
    const {
        records = [],
        deletedIds,
        showAddFeedStatus = true,
        showTickerNews = true,
        addFeedStatusPostType = PostType.ORDINARY,
        addFeedStatusStub,
        addFeedStatusPlaceholder,
        fetchTickerNews,
        isAddFeedStatusActive,
        onAddFeedStatusActiveChange,
        renderNoData: renderNoDataProp,
        allTags,
        deleteFeedRecord,
        restoreFeedRecord,
        updateFeedRecord,
        publishFeedRecord,
        communities,
        isLoading,
        error,
        instanceId,
        boundary,
        wasLastRequest,
        pinnedRecordShowMore,
    } = props;

    useEffect(() => {
        const isInitPerformanceAndAnalyticsCookies = cookiePrivacySettings.isInitSetting(
            'performanceAndAnalyticsCookies',
        );

        if (isInitPerformanceAndAnalyticsCookies) {
            addConversionCode();
            addTaboolaPixelCode();
        }
    }, []);

    const currentUser = useCurrentUser();
    const [
        { source, owner, websocketUserSign, pinnedRecordId, pinnedRecordShowMore: pinnedRecordShowMoreFromContext },
    ] = useFeedContext();

    const isFeedPage = source === 'feed';
    // dg: Разрешено ли менять тип поста в редакторе. Сейчас разрешено только на странице ленты @since #WTT-10826
    const premiumPostsAllowed = Boolean(
        isFeedPage &&
            owner?.id &&
            currentUser?.isSame(owner.id) &&
            'isVerifiedAuthor' in owner &&
            owner.isVerifiedAuthor,
    );

    const handleCreateFeedRecord = usePublisherFn(publishFeedRecord);

    const { onIntersection, onRecordShowFull } = useStats(
        { userId: currentUser.id, userSign: websocketUserSign, source },
        Boolean(currentUser.isAuth),
    );

    const { onItemViewedChange } = useTickerNews(fetchTickerNews, instanceId, showTickerNews);

    const handleItemViewedChange = useCallback(
        (entry: IntersectionObserverEntry, record: FeedRecord) => {
            if (isPublishStatusRecord(record)) {
                onIntersection(entry, record);
                if (entry.isIntersecting && !record.tickerNews?.tickerNewsId) {
                    onItemViewedChange(record);
                }
            }
        },
        [onIntersection, onItemViewedChange],
    );

    const { handlerOpenGallery, galleryElement } = useGallery();

    const { handleLoadMore, isFeedLengthError, handleReloadPremiumPosts } = useFetchRecords({ source, owner, props });

    const renderNoData = useCallback(
        () => renderNoDataProp?.({ isLoading, records, boundary }),
        [isLoading, isAddFeedStatusActive],
    );

    return (
        <FeedWrapper className="autocheck__feed">
            {showAddFeedStatus && (
                <AddFeedStatus
                    isEditing={false}
                    publisherFn={handleCreateFeedRecord}
                    stub={addFeedStatusStub}
                    placeholder={addFeedStatusPlaceholder}
                    owner={owner}
                    isActive={isAddFeedStatusActive}
                    onActiveChange={onAddFeedStatusActiveChange}
                    premiumPostsAllowed={premiumPostsAllowed}
                    postType={addFeedStatusPostType}
                    communities={communities}
                    allTags={allTags || []}
                    instanceId={instanceId}
                />
            )}
            <InfiniteScroll onLoadMore={handleLoadMore} isLoading={isLoading} preloadOffset={1000}>
                <ItemsList>
                    {renderNoData()}
                    {(isFeedLengthError || error) && !isLoading && <ErrorBlock />}
                    {records.map((record) => {
                        const recordId = record?.id;
                        const pinned =
                            pinnedRecordId !== undefined &&
                            recordId !== undefined &&
                            pinnedRecordId.toString() === recordId.toString();

                        return (
                            <FeedItem
                                key={record.id}
                                record={record}
                                onItemViewedChange={handleItemViewedChange}
                                onRecordShowFull={onRecordShowFull}
                                onClickAnyImgInPost={handlerOpenGallery}
                                pinned={pinned}
                                showFull={pinned && (pinnedRecordShowMore ?? pinnedRecordShowMoreFromContext)}
                                updateFeedRecord={updateFeedRecord}
                                deleteFeedRecord={deleteFeedRecord}
                                restoreFeedRecord={restoreFeedRecord}
                                allTags={allTags}
                                deletedIds={deletedIds}
                                instanceId={instanceId}
                                onPremiumSubscribe={handleReloadPremiumPosts}
                            />
                        );
                    })}
                    {!wasLastRequest && <Loading visible={isLoading} />}
                    {galleryElement}
                </ItemsList>
            </InfiniteScroll>
        </FeedWrapper>
    );
};
