import { connect } from 'react-redux';

import { DispatchType } from 'types';
import { createEvent, fetchHistory } from 'widgets/chat/actions';
import { GlobalStateForChat } from 'widgets/chat/types';
import { selectChatHistory } from 'widgets/chat/selector';

import { ChatWidget } from './components';

const mapStateToProps = (state: GlobalStateForChat) => ({
    allChatEvents: selectChatHistory(state),
});

const mapDispatchToProps = {
    getHistory: fetchHistory,
    createChatEvent: createEvent,
};

export type MapStateToPropsType = ReturnType<typeof mapStateToProps>;
export type MapDispatchToPropsType = DispatchType<typeof mapDispatchToProps>;

export type ChatWidgetProps = MapStateToPropsType & MapDispatchToPropsType & { isHome?: boolean };

export const ChatWidgetContainer = connect(mapStateToProps, mapDispatchToProps)(ChatWidget);
