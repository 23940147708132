import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { useStack } from '../../hooks/useStack';
import { MenuData, MenuItemData, ModifiedItemsMap } from '../../interfaces';
import { ContextMenuContent } from '../content';

import { ContextMenuBase, Loading } from './styled';

export interface ContextMenuProps {
    data: MenuData;
    onClick: (name: string, item: MenuItemData) => void;
    modifiedItems: ModifiedItemsMap;
    onClose: () => void;
    isMobile: boolean;
}

export const ContextMenu: FC<ContextMenuProps> = memo((props) => {
    const { data, onClick, modifiedItems, onClose, isMobile } = props;

    const [current, items, navigate, back, reset] = useStack<MenuData>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data) {
            reset([data]);
        }
    }, [data, reset]);

    const handleClick = useCallback(
        async (name: string, item: MenuItemData) => {
            const subMenu = item?.subMenu;
            if (subMenu) {
                let subMenuData: MenuData | null = null;
                try {
                    setLoading(true);
                    subMenuData = await subMenu();
                } finally {
                    setLoading(false);
                }
                if (subMenuData) {
                    navigate(subMenuData);
                }
            } else {
                onClick(name, item);
                if (current?.onClick === 'close') {
                    onClose();
                } else if (current?.onClick === 'goBack') {
                    back();
                }
            }
        },
        [back, current?.onClick, navigate, onClick, onClose],
    );

    if (!current) {
        return null;
    }

    return (
        <ContextMenuBase>
            <ContextMenuContent
                modifiedItems={modifiedItems[current.name]}
                menuData={current}
                showBackButton={items.length > 1}
                onBack={back}
                onClose={onClose}
                onItemClick={handleClick}
                isMobile={isMobile}
            />
            {loading && (
                <Loading>
                    <ProcessingIcon />
                </Loading>
            )}
        </ContextMenuBase>
    );
});
