import React, { MouseEventHandler, useMemo } from 'react';

import { CurrencyCode } from 'types/currency';
import { PriceDifferenceFormatter } from 'widgets/formatting/price-difference-formatter';
import { PriceFormatter } from 'widgets/formatting';

import * as Styled from './styled/painted4-instrument';

export type Painted4InstrumentProps = {
    ticker: string;
    name: string;
    price: number | null;
    changePercent: number | null;
    currency: CurrencyCode | null;
    dcpl: number;
    percentDcpl: number;
    url: string;
    logoUrl?: string;
    className?: string;
    onClick?: MouseEventHandler;
    actions?: {
        subscribe: React.ReactNode;
    };
    isSuggestion?: boolean;
};

export const Painted4Instrument = (props: Painted4InstrumentProps) => {
    const {
        ticker,
        name,
        price,
        changePercent,
        currency,
        dcpl,
        percentDcpl,
        actions,
        className,
        onClick,
        url,
        logoUrl,
        isSuggestion,
    } = props;

    const isGrow = useMemo(() => Boolean(changePercent && changePercent >= 0), [changePercent]);

    return (
        <Styled.Painted4InstrumentWrapper isGrow={isGrow} className={className} onClick={onClick}>
            <Styled.Side>
                {logoUrl && (
                    <Styled.Logo>
                        <a href={url} target="_blank" rel="noreferrer">
                            <img src={logoUrl} alt={ticker} />
                        </a>
                    </Styled.Logo>
                )}
                <Styled.Names hasLogo={Boolean(logoUrl)}>
                    {isSuggestion ? (
                        <>
                            <Styled.TickerSuggestion>{ticker}</Styled.TickerSuggestion>
                            <Styled.NameSuggestion>{name}</Styled.NameSuggestion>
                        </>
                    ) : (
                        <>
                            <Styled.TickerLink href={url} target="_blank" rel="noreferrer">
                                {ticker}
                            </Styled.TickerLink>
                            <Styled.NameLink href={url} target="_blank" rel="noreferrer" title={name}>
                                {name}
                            </Styled.NameLink>
                        </>
                    )}
                </Styled.Names>
            </Styled.Side>
            <Styled.Side>
                <Styled.Price>
                    {(price && (
                        <PriceFormatter
                            price={Number(price)}
                            currency={currency}
                            minimumFractionDigits={dcpl}
                            maximumFractionDigits={dcpl}
                        >
                            {(localizedPrice) => <Styled.PriceValue>{localizedPrice}</Styled.PriceValue>}
                        </PriceFormatter>
                    )) ||
                        null}
                    {(price && changePercent && (
                        <PriceDifferenceFormatter
                            price={Number(price)}
                            currency={currency}
                            minimumFractionDigits={dcpl}
                            maximumFractionDigits={dcpl}
                            percentMaximumFractionDigits={percentDcpl}
                            percentMinimumFractionDigits={percentDcpl}
                            percentage={Number(changePercent)}
                        >
                            {(priceDiffString) => <Styled.PriceDiff>{priceDiffString}</Styled.PriceDiff>}
                        </PriceDifferenceFormatter>
                    )) ||
                        null}
                </Styled.Price>
                {actions && <Styled.Actions>{actions.subscribe}</Styled.Actions>}
            </Styled.Side>
        </Styled.Painted4InstrumentWrapper>
    );
};

Painted4Instrument.defaultProps = {
    actions: null,
    logoUrl: '',
    className: '',
    onClick: () => {
        // nothing
    },
};
