import React, { FC, memo } from 'react';

import { Community, Id, PostType } from 'types';
import { useDictionary } from 'hooks/useDictionary';
import { GlobeIcon } from 'components/icons2/GlobeIcon';
import { LockIcon } from 'components/icons2/LockIcon';

import { PostTypeWrapper, TruncatedText, TextWithIcon } from './styled';

interface Props {
    showMenu: boolean;
    isFeed: boolean;
    isCommunity: boolean;
    postType: PostType | null;
    isEditing: boolean;
    communities?: Community[];
    communityId?: Id;
}

export const PostTypeDisplay: FC<Props> = memo(
    ({ isEditing, postType, showMenu, isFeed, isCommunity, communities, communityId }) => {
        const dic = useDictionary();

        if (isEditing) {
            return (
                <PostTypeWrapper>
                    <span>{dic.word('wt_feed__add_status__editing')}</span>
                </PostTypeWrapper>
            );
        }

        const { displayName = '', image = '' } = communities?.find(({ id }) => id === communityId) ?? {};
        const communityHeader = (
            <TextWithIcon
                text={<TruncatedText>{displayName}</TruncatedText>}
                icon={
                    image
                        ? {
                              gap: '4px',
                              component: image,
                              size: 16,
                          }
                        : undefined
                }
            />
        );

        if (isCommunity) {
            return communityHeader;
        }

        switch (postType) {
            case PostType.ORDINARY:
                return (
                    <TextWithIcon
                        text={dic.word('wt_feed__timeline_status__post_type_ordinary')}
                        icon={showMenu && isFeed ? { gap: '4px', component: GlobeIcon, size: 16 } : undefined}
                    />
                );

            case PostType.PREMIUM:
                return (
                    <TextWithIcon
                        text={dic.word('wt_feed__timeline_status__post_type_premium')}
                        icon={{ gap: '4px', component: LockIcon, size: 16 }}
                    />
                );
            case PostType.COMMUNITY: {
                return communityHeader;
            }
            default:
                return <span>{dic.word('wt_feed__timeline_status__post_type_select')}</span>;
        }
    },
);
