import { Action } from 'types';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';
import { FeedbackService } from 'services/api-services/generated_limex';

const [CONTACT_SUPPORT_ACTION_TYPES, supportThunk, supportActions] = createThunkActions('widgets')(
    'CONTACT_SUPPORT',
    FeedbackService.createFeedbackForm,
)();

type SupportActions = Actions<typeof supportActions>;
type ContactSupport = Action<typeof contactSupport>;

function contactSupport(
    params: Parameters<typeof supportThunk>[0]['params']['requestBody'],
    recipient?: Parameters<typeof supportThunk>[0]['params']['recipient'],
): ReturnType<typeof supportThunk> {
    return supportThunk({ params: { requestBody: params, recipient } });
}

export { CONTACT_SUPPORT_ACTION_TYPES, contactSupport, ContactSupport, SupportActions };
