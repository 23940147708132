import React from 'react';

import { IconSvg, IconComponent } from 'components/icons2/index';

export const BellActiveIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V4.13135C13.5947 4.22716 14.1884 4.40778 14.779 4.67276C14.2955 4.99615 13.8864 5.42212 13.5829 5.91955C12.1667 5.44192 10.7732 5.59916 9.35146 6.39586C8.22468 7.02726 7.73785 8.12413 7.45799 9.85279C7.36338 10.4371 7.30429 11.0018 7.24093 11.6074C7.20745 11.9273 7.17278 12.2586 7.13105 12.6102C7.01883 13.5558 6.85805 14.602 6.49813 15.5619C6.24329 16.2414 5.73742 16.7203 5.22788 17.2027C5.15866 17.2682 5.0893 17.3339 5.02058 17.4001H18.8039C18.3951 17.0348 17.8362 16.4533 17.5019 15.5619C17.1407 14.5986 16.9837 13.5503 16.8758 12.6054C16.8518 12.3954 16.8304 12.1927 16.8102 11.9956C16.8731 11.9985 16.9364 12 17 12C17.4902 12 17.9598 11.9118 18.3938 11.7505C18.5118 12.9161 18.6424 14.0464 19 15.0001C19.254 15.6774 19.6864 16.0489 20.0843 16.3907C20.5676 16.8059 21 17.1774 21 18.0001C21 19.0001 20 19.0001 20 19.0001H15C15 20.6569 13.6568 22 12 22C10.3432 22 9.00003 20.6569 9 19.0001H4C4 19.0001 3.00001 19.0001 3 18.0001C2.99999 17.0848 3.6139 16.5181 4.1723 16.0027C4.52904 15.6734 4.86312 15.3651 5 15.0001C5.38286 13.9791 5.51146 12.7541 5.64313 11.4999C5.90798 8.977 6.18523 6.336 8.56932 5.00006C9.37411 4.54909 10.186 4.25928 11 4.12943V3C11 2.44772 11.4477 2 12 2ZM10.6 19.0001C10.6 19.7732 11.2268 20.4 12 20.4C12.7732 20.4 13.4 19.7732 13.4 19.0001H10.6Z"
            fill="#7486AA"
        />
        <path
            d="M17.0001 10.4001C18.3256 10.4001 19.4001 9.32558 19.4001 8.0001C19.4001 6.67461 18.3256 5.6001 17.0001 5.6001C15.6746 5.6001 14.6001 6.67461 14.6001 8.0001C14.6001 9.32558 15.6746 10.4001 17.0001 10.4001Z"
            fill="#0EBE72"
        />
    </IconSvg>
);
