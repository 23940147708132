import styled, { css } from 'styled-components';
import { size } from 'polished';

export const ClipboardWrapper = styled.div`
    position: absolute;
    right: 5px;
    top: 25px;
    color: ${({ theme }) => theme.label.secondary};
    font-size: 14px;
    cursor: pointer;
`;

const halfCBIconCss = css`
    content: '';
    background: ${({ theme }) => theme.label.secondary};
    display: inline-block;
    border: 1px ${({ theme }) => theme.bg.secondary} solid;
    border-radius: 2px;
`;
export const ClipboardIcon = styled.span`
    margin-left: 3px;
    &:before {
        ${halfCBIconCss}
        ${size(10)}
        position: absolute;
    }
    &:after {
        ${halfCBIconCss}
        ${size(12)}
        position: relative;
        top: 3px;
        right: -3px;
    }
`;
