import React, { FC } from 'react';

import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import Dic from '../../../services/dictionary/dictionary';

import * as Styled from './styled/PlugBlock';

export type PopupProps = {
    closePopup: () => void;
};

export const Popup: FC<PopupProps> = ({ closePopup }) => (
    <WtPopup3
        width={464}
        bgCloseable
        close={closePopup}
        title={Dic.word('wt_feed__widget__premium_not_granted__popup_title')}
        size={SizeWtPopup3Enum.LARGE}
        actionButtons={{
            direction: 'vertical',
            items: [
                {
                    key: 1,
                    content: Dic.word('wt_feed__widget__premium_not_granted__goto_feed_btn'),
                    onClick: closePopup,
                },
            ],
        }}
    >
        <Styled.PopupContent>{Dic.word('wt_feed__widget__premium_not_granted__free_period')}</Styled.PopupContent>
    </WtPopup3>
);
