import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';
import { FormTextInput } from 'components/form/react-hook-form/form-text-input';
import { FormTextArea } from 'components/form/react-hook-form/form-text-area';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
`;

export const Header = styled.div`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.secondary};
`;

export const FormTextInputStyled = styled(FormTextInput)`
    margin-bottom: 4px;
`;

export const FormTextAreaStyled = styled(FormTextArea)`
    margin-bottom: 4px;
`;
