import { ApiSettings } from './types';

export function getCommonInitialPageData(initApiData: { data: ApiSettings; params: Record<string, string> }) {
    const { data, params } = initApiData;
    const {
        lcid,
        currentUser,
        themeName,
        charts,
        ads,
        channel,
        countryCode,
        countryName,
        googleAnalytics,
        tokens,
        isDeveloper,
        isBlogAdmin,
        websocketUserSign,
        domainLcid,
    } = data;
    const { pinnedRecordId } = params;

    const pinnedRecordShowMore = !!params.full;

    const feedContext = {
        isDeveloper,
        isBlogAdmin,
        source: 'timeline' as const,
        websocketUserSign,
        isAdsActive: ads.active,
        pinnedRecordId,
        pinnedRecordShowMore,
        remountFeed: () => undefined,
    };

    const pageContext = {
        charts,
        ads,
        channel,
        countryCode,
        countryName,
        googleAnalytics,
        tokens,
        domainLcid,
    };

    return {
        feedContext,
        pageContext,
        lcid,
        currentUser,
        themeName,
    };
}
