/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoGenericExporter } from '../models/shared/GeoGenericExporter';
import type { InvitedUserExporter } from '../models/shared/InvitedUserExporter';
import type { MarketplaceUserExporter } from '../models/UserService/MarketplaceUserExporter';
import type { PermissionExporter } from '../models/shared/PermissionExporter';
import type { UserExporter } from '../models/shared/UserExporter';
import type { UserFirstContactExporter } from '../models/UserService/UserFirstContactExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {
    /**
     * Изменить аватар пользователя
     * @returns any Успешное изменение аватара пользователя
     * @throws ApiError
     */
    public static createUserAuthorChangeAvatar({
        requestBody,
    }: {
        requestBody?: {
            /**
             * CSRF токен
             */
            csrf: string;
            /**
             * ID файла, загруженного ранее в хранилище
             */
            fileId: number;
        };
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/author/change-avatar/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Изменить обложку профиля пользователя
     * @returns any Успешное изменение обложки пользователя
     * @throws ApiError
     */
    public static createUserAuthorChangeProfileCover({
        requestBody,
    }: {
        requestBody?: {
            /**
             * CSRF токен
             */
            csrf: string;
            /**
             * ID файла, загруженного ранее в хранилище. Передача без параметра или null удалит обложку пользователя
             */
            fileId: number;
        };
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/author/change-profile-cover/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить черный список пользователей
     * @returns any Успешное получение подписок
     * @throws ApiError
     */
    public static getUserBlacklist({
        cursor = 1,
    }: {
        /**
         * Курсор пиганации
         */
        cursor?: number;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список пользователей в черном списке
         */
        items?: Array<UserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/blacklist/',
            query: {
                cursor: cursor,
            },
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Добавить пользователя в черный список
     * @returns any Успешное добавление в черный список
     * @throws ApiError
     */
    public static createUserBlacklist({
        userId = 954554844,
        requestBody,
    }: {
        /**
         * Идентификатор пользователя, который будет добавлен в черный список
         */
        userId?: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<{
        permission?: PermissionExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/{userId}/blacklist/',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                503: `Ошибка при добавлении пользователя в черный список`,
            },
        });
    }

    /**
     * Удалить пользователя из черного списка
     * @returns any Успешное удаление из черного списка
     * @throws ApiError
     */
    public static deleteUserBlacklist({
        userId = 954554844,
        requestBody,
    }: {
        /**
         * Идентификатор пользователя, который будет удален из черного списока
         */
        userId?: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<{
        permission?: PermissionExporter;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/user/{userId}/blacklist/',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                503: `Ошибка при удалении пользователя из черного списка`,
            },
        });
    }

    /**
     * Добавить пользователя в черный список
     * @returns any Успешное добавление в черный список
     * @throws ApiError
     */
    public static createUserBlacklistAdd({
        userId = 954554844,
        requestBody,
    }: {
        /**
         * Идентификатор пользователя, который будет добавлен в черный список
         */
        userId?: number;
        requestBody?: {
            /**
             * Глобальный CSRF токен
             */
            csrf: string;
        };
    }): CancelablePromise<{
        permission?: PermissionExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/{userId}/blacklist/add/',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                503: `Ошибка при добавлении пользователя в черный список`,
            },
        });
    }

    /**
     * Аутентифицировать пользователя по email и паролю
     * @returns any Успешная получение информации о аутентификации пользователя
     * @throws ApiError
     */
    public static createUserCheckPassword({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Email пользователя
             */
            email?: string;
            /**
             * Пароль пользователя
             */
            password?: string;
        };
    }): CancelablePromise<{
        /**
         * Пользователь аутентифицирован
         */
        match?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/check-password/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_CLIENT_SERVER`,
            },
        });
    }

    /**
     * Изменить страну пользователя/гостя
     * @returns any Успешное изменение страны
     * @throws ApiError
     */
    public static createUserCountry({
        xCsrfToken,
        requestBody,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        requestBody?: {
            /**
             * Код страны, на которую меняем
             */
            countryCode: string;
        };
    }): CancelablePromise<{
        geo?: GeoGenericExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/country/',
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
            },
        });
    }

    /**
     * @deprecated
     * Получить список приглашенных пользователей
     * @returns any Успешное получение списка
     * @throws ApiError
     */
    public static getUserInvite({
        cursor = 1,
    }: {
        /**
         * Курсор пиганации
         */
        cursor?: number;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список приглашенных пользователей
         */
        items?: Array<UserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/invite/',
            query: {
                cursor: cursor,
            },
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Принять приглашенного пользователя
     * @returns void
     * @throws ApiError
     */
    public static createUserInvite({
        requestBody,
    }: {
        requestBody?: {
            /**
             * ИД пригласившего пользователя
             */
            aff: number;
        };
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/invite/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Установить персональное имя пользователю для Url адреса профиля
     * @returns any Успешное применение персонального имени
     * @throws ApiError
     */
    public static createUserPersonalUrl({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Персональное имя пользователя
             */
            alias: string;
        };
    }): CancelablePromise<{
        user?: UserExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/personal-url/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Отправить заданный код авторизации. (для отправки писем из Kratos)
     * @returns any Успешная отправка письма с кодом
     * @throws ApiError
     */
    public static createUserSendAuthCode({
        requestBody,
    }: {
        requestBody?: {
            /**
             * 6-и значный цифровой код, который будет отправлен в письме
             */
            code: number;
            /**
             * Email пользователя, который получит письмо
             */
            email: string;
        };
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/send-auth-code/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_SEND_AUTH_CODE`,
                503: `Ошибка при отправке письма пользователю`,
            },
        });
    }

    /**
     * Получить детальную информацию о пользователях по списку email
     * @returns any Успешное получение информации о пользователе
     * @throws ApiError
     */
    public static createUserBusinessData({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Список email пользователей по которым хотим получить информацию
             */
            emails?: Array<string>;
        };
    }): CancelablePromise<{
        /**
         * Email по которому были получены данные
         */
        email?: string;
        user?: UserExporter;
        firstContact?: UserFirstContactExporter;
        /**
         * Список меток UTM с которыми пользователь пришел впервые
         */
        firstUtm?: any;
        /**
         * Список меток UTM с которыми пользователь пришел (залогинился) в последний раз
         */
        lastUtm?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/business-data/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_CLIENT_SERVER`,
            },
        });
    }

    /**
     * Получить информацию о пользователе по email, включая существование пароля
     * @returns any Успешная получение информации о пользователе
     * @throws ApiError
     */
    public static createUserGetByEmail({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Email пользователя по которому хотим получить информацию
             */
            email?: string;
        };
    }): CancelablePromise<{
        user?: UserExporter;
        /**
         * Имеется ли у пользователя пароль
         */
        hasPassword?: boolean;
        /**
         * Основной email пользователя
         */
        generalEmail?: string;
        /**
         * Все активные email'ы пользователя
         */
        emails?: Array<string>;
        /**
         * Все телефоны пользователя
         */
        phones?: Array<string>;
        /**
         * Идентификатор Facebook для аутентификации через соцсеть
         */
        facebookId?: string;
        /**
         * Идентификатор Google для аутентификации через соцсеть
         */
        googleId?: string;
        /**
         * Идентификатор Apple для аутентификации через соцсеть
         */
        appleId?: string;
        /**
         * Идентификатор LinkedIn для аутентификации через соцсеть
         */
        linkedinId?: string;
        /**
         * Идентификатор TwitterId для аутентификации через соцсеть
         */
        twitterId?: string;
        /**
         * Идентификатор Vkontakte для аутентификации через соцсеть
         */
        vkontakteId?: string;
        /**
         * Kratos ID пользователя
         */
        kratosId?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/get-by-email/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_CLIENT_SERVER`,
            },
        });
    }

    /**
     * Получить информацию о пользователе по ID
     * @returns any Успешная получение информации о пользователе
     * @throws ApiError
     */
    public static createUserGetById({
        requestBody,
    }: {
        requestBody?: {
            /**
             * ID пользователя по которому хотим получить информацию
             */
            id?: number;
        };
    }): CancelablePromise<{
        user?: UserExporter;
        /**
         * Основной email пользователя
         */
        generalEmail?: string;
        /**
         * Все активные email'ы пользователя
         */
        emails?: Array<string>;
        /**
         * Все телефоны пользователя
         */
        phones?: Array<string>;
        /**
         * Идентификатор Facebook для аутентификации через соцсеть
         */
        facebookId?: string;
        /**
         * Идентификатор Google для аутентификации через соцсеть
         */
        googleId?: string;
        /**
         * Идентификатор Apple для аутентификации через соцсеть
         */
        appleId?: string;
        /**
         * Идентификатор LinkedIn для аутентификации через соцсеть
         */
        linkedinId?: string;
        /**
         * Идентификатор TwitterId для аутентификации через соцсеть
         */
        twitterId?: string;
        /**
         * Идентификатор Vkontakte для аутентификации через соцсеть
         */
        vkontakteId?: string;
        /**
         * Kratos ID пользователя
         */
        kratosId?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/get-by-id/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_CLIENT_SERVER`,
                404: `Пользователь не найден`,
            },
        });
    }

    /**
     * Получить информацию о пользователе по ID из соцсети
     * @returns any Успешная получение информации о пользователе
     * @throws ApiError
     */
    public static createUserGetByOidc({
        requestBody,
    }: {
        requestBody?: {
            /**
             * ID из соцсети пользователя, по которому хотим получить информацию
             */
            id?: number;
            /**
             * Имя соцсети
             */
            provider?: 'facebook' | 'google' | 'apple' | 'vkontakte' | 'linkedin';
        };
    }): CancelablePromise<{
        user?: UserExporter;
        /**
         * Основной email пользователя
         */
        generalEmail?: string;
        /**
         * Все активные email'ы пользователя
         */
        emails?: Array<string>;
        /**
         * Все телефоны пользователя
         */
        phones?: Array<string>;
        /**
         * Идентификатор Facebook для аутентификации через соцсеть
         */
        facebookId?: string;
        /**
         * Идентификатор Google для аутентификации через соцсеть
         */
        googleId?: string;
        /**
         * Идентификатор Apple для аутентификации через соцсеть
         */
        appleId?: string;
        /**
         * Идентификатор LinkedIn для аутентификации через соцсеть
         */
        linkedinId?: string;
        /**
         * Идентификатор TwitterId для аутентификации через соцсеть
         */
        twitterId?: string;
        /**
         * Идентификатор Vkontakte для аутентификации через соцсеть
         */
        vkontakteId?: string;
        /**
         * Kratos ID пользователя
         */
        kratosId?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/get-by-oidc/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_CLIENT_SERVER`,
                404: `Пользователь не найден`,
            },
        });
    }

    /**
     * Установить пользователю Kratos ID
     * Связывает пользователя в Limex с профилем в GlobalAuth
     * @returns any Успешная установка связи между пользователем и его профиелем в GlobalAuth
     * @throws ApiError
     */
    public static createUserSetKratosId({
        requestBody,
    }: {
        requestBody?: {
            /**
             * ID пользователя
             */
            userId: number;
            /**
             * Kratos ID пользователя
             */
            kratosId: string;
        };
    }): CancelablePromise<{
        user?: UserExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/set-kratos-id/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_USER_SET_KRATOS_ID`,
            },
        });
    }

    /**
     * Получить информацию о пользователе по Kratos ID
     *
     * В случае отсутствия пользователя в Limex, пользователь будет зарегистрирован.
     * Пользователь будет зарегистрирован на языке, который был передан в HTTP-заголовке Accept-Language.
     * Пользователь будет зарегистрирован в статусе 5 (PENDING) и активирован, если он войдет на сайт Limex.
     * Недостающие данные из HTTP-запроса будут получены в момент входа пользователя в Limex.
     *
     * @returns any Успешная получение информации о пользователе
     * @throws ApiError
     */
    public static createUserGetByKratosId({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Kratos ID пользователя
             */
            kratosId: string;
        };
    }): CancelablePromise<{
        user?: UserExporter;
        /**
         * Email пользователя Kratos. Если несколько - то последний верифицированный. Если нет - то null.
         */
        userEmail?: string | null;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/get-by-kratos-id/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
                403: `У пользователя отсутствует роль ROLE_USER_BY_KRATOS_ID`,
            },
        });
    }

    /**
     * Получить информацию о пользователях по их Kratos ID
     * @returns MarketplaceUserExporter Успешное получение информации о пользователе
     * @throws ApiError
     */
    public static createMarketplaceGetUsersInfoByKratosIds({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Kratos IDs пользователей
             */
            kratosIds: Array<string>;
        };
    }): CancelablePromise<Record<string, MarketplaceUserExporter>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/marketplace/get-users-info-by-kratos-ids/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданы параметры`,
            },
        });
    }

    /**
     * Отключить уведомление об открытии демо-счета
     * Отключение уведомления об открытии демо-счета у текущего пользователя
     * @returns void
     * @throws ApiError
     */
    public static disableDemoAccountNotification(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/marketplace/disable-demo-account-notification/',
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Сменить язык пользователя/гостя
     * @returns any Успешное изменение языка
     * @throws ApiError
     */
    public static createUserLcid({
        xCsrfToken,
        requestBody,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        requestBody?: {
            /**
             * Язык пользователя/гостя (ru - русский, en - английский)
             */
            lcid?: 'ru' | 'en';
        };
    }): CancelablePromise<{
        /**
         * null для гостя
         */
        user: UserExporter | null;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/lcid/',
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
            },
        });
    }

    /**
     * Удалить текущего пользователя (отправить запрос на удаление модераторам)
     * @returns void
     * @throws ApiError
     */
    public static deleteUserCurrent({
        xCsrfToken,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/user/current/',
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить список приглашенных пользователей
     * @returns any Успешное получение приглашенных пользователей
     * @throws ApiError
     */
    public static getInvitedUsersV2({
        filters,
        cursor,
    }: {
        /**
         * Фильтры по типам ссылок (appName из JWT)
         */
        filters?: Array<'limex' | 't2bf' | 'prop_trading' | 'proptrading' | 'prop_trading_wt' | 't2bf_landing'>;
        /**
         * Курсор пиганации
         */
        cursor?: string;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: string | null;
        /**
         * Список приглашенных пользователей
         */
        invitedUsers?: Array<InvitedUserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/user/invite/',
            query: {
                filters: filters,
                cursor: cursor,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }
}
