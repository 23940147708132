export const chooseFile = (accept: string) => (): Promise<FileList> =>
    new Promise((resolve) => {
        const input = document.createElement('input');

        // на ios не работает без добавления в реальный DOM
        document.body.appendChild(input);
        input.style.position = 'absolute';
        input.style.left = '-10000px';

        input.type = 'file';
        input.accept = accept;

        input.onchange = (e: Event) => {
            if (e.target instanceof HTMLInputElement) {
                const files = e.target?.files;
                if (files && files.length > 0) {
                    resolve(files);
                }
            }
            document.body.removeChild(input);
        };

        input.click();
    });

export const chooseImage = chooseFile('image/png, image/jpeg, image/gif');
