import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Id } from 'types';
import { useWindowResize } from 'hooks/useWindowResize';
import { ScrollBlocker } from 'components/scroll-blocker';

import { ListBoxOption } from '../Option';
import { ListBox } from '../ListBox';

import { useSetPositionOptions } from './useSetPositionOptions';
import { DEFAULT_OPTIONS_POSITION_PARAMS, MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN } from './constants';
import { ListBoxComplexProps } from './types';
import * as Styled from './styled';

export type Value = Id;

// value у Option это уникальное значение, используется как id

const ListBoxComplexComponent = <Option extends { value: Value }>(props: ListBoxComplexProps<Option>) => {
    const {
        defaultSelectedValue,
        defaultSelectedOption: defaultSelectedOptionProp,
        options,
        renderOptions: renderOptionsProp,
        renderCheckedOptionElement,
        portalToOptions = document.body,
        onChangeIsOpen: onChangeIsOpenProp,
        onChange,
        pageBlockWhenSwitchOpen = true,
        renderInnerOption,
        className,
        isRoundedOptionsContainer,
        placeholder,
        selectedOption: selectedOptionProp,
        footer,
        hasChevronIcon,
    } = props;

    const defaultSelectedOption = useMemo(() => {
        if (defaultSelectedOptionProp) return defaultSelectedOptionProp;

        return options?.length > 0 && options.filter((opt) => `${opt.value}` === `${defaultSelectedValue}`)[0];
    }, [options, defaultSelectedOptionProp, defaultSelectedValue]);
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultSelectedOption || undefined);

    const [isOpen, setIsOpen] = useState(false);

    const listBoxBtnRef = useRef<HTMLDivElement>(null as null | HTMLDivElement);
    const listOptionsRef = useRef<HTMLDivElement>(null as null | HTMLDivElement);

    const onChangeIsOpen = useCallback(
        (_isOpenValue: boolean) => {
            setIsOpen(_isOpenValue);
            onChangeIsOpenProp?.(_isOpenValue);
            setPositionOptions();
        },
        [onChangeIsOpenProp],
    );

    const lisBoxClassName = useMemo(
        () => ['autotest__list-box-complex', className].filter((cn) => cn).join(' '),
        [className],
    );

    const { optionsPositions, setPositionOptions } = useSetPositionOptions({
        listBoxBtnRef,
        listOptionsRef,
        defaultOptionsPositionParams: DEFAULT_OPTIONS_POSITION_PARAMS,
    });

    useWindowResize(() => {
        setPositionOptions({ debounced: true });
    });

    useEffect(() => {
        setSelectedOption(selectedOptionProp);
    }, [selectedOptionProp]);

    const renderOptions = () => {
        const optsEls: React.ReactElement[] | null =
            options?.length > 0
                ? options.map((_option) => (
                      <ListBoxOption
                          value={_option}
                          key={_option.value}
                          className="autotest__list-box-complex__options__item"
                      >
                          {renderInnerOption(_option)}
                      </ListBoxOption>
                  ))
                : null;

        if (!renderOptionsProp) {
            return optsEls;
        }

        return renderOptionsProp(optsEls);
    };

    return (
        <>
            {pageBlockWhenSwitchOpen && isOpen && <ScrollBlocker />}
            <ListBox<Option | undefined>
                value={selectedOption}
                onChange={(_option) => {
                    setSelectedOption(_option);
                    if (_option) {
                        onChange?.(_option);
                    }
                }}
                onChangeIsOpen={onChangeIsOpen}
                className={lisBoxClassName}
                // noSelectedText={placeholder}
            >
                <Styled.ListBoxButton
                    ref={listBoxBtnRef}
                    className="autotest__list-box-complex__btn"
                    hasChevronIcon={hasChevronIcon}
                >
                    {selectedOption ? (
                        renderCheckedOptionElement(selectedOption)
                    ) : (
                        <Styled.Placeholder>{placeholder}</Styled.Placeholder>
                    )}
                </Styled.ListBoxButton>
                <Styled.Options
                    portalTo={portalToOptions}
                    optionsPositionParams={optionsPositions}
                    ref={listOptionsRef}
                    isOpen={isOpen}
                    isRounded={!!isRoundedOptionsContainer}
                    minimumIndentFromOptionsToEdgeScreen={MINIMUM_INDENT_FROM_OPTIONS_TO_EDGE_SCREEN}
                    className="autotest__list-box-complex__options"
                >
                    {renderOptions()}

                    {footer && <Styled.FooterOptions>{footer}</Styled.FooterOptions>}
                </Styled.Options>
            </ListBox>
        </>
    );
};

const ListBoxComplex = React.memo(ListBoxComplexComponent) as typeof ListBoxComplexComponent;

export { ListBoxComplex };
