import React, { useMemo, useRef } from 'react';
import cc from 'classcat';

import { useIsTextOverflowEllipsis } from 'hooks/useIsTextOverflowEllipsis';

import { UserNameProps } from './types';
import * as Styled from './styled';

export function UserName(props: UserNameProps) {
    const {
        onClick = () => undefined,
        kind = 'default',
        className: classNameProp = '',
        useDct = true,
        url = undefined,
        displayName,
        isVerified = false,
        isVerifiedAuthor = false,
        isLoading = false,
        isTooltipActive: isTooltipActiveProp = true,
        isOneLine = true,
    } = props;

    const className = cc([classNameProp, 'autotest__user-name']);

    const isTooltipActive = isTooltipActiveProp && isOneLine;

    const textRef = useRef<HTMLSpanElement | null>(null);

    const isTextOverflowEllipsis = useIsTextOverflowEllipsis({ ref: textRef, isActive: isOneLine });

    const verifiedIconEl = useMemo(() => {
        if (isLoading) {
            return null;
        }

        return (
            <Styled.VerifiedIcons
                showTooltip={useDct}
                isVerifiedAccount={isVerified}
                isVerifiedAuthor={isVerifiedAuthor}
                isOneLine={isOneLine}
            />
        );
    }, [isLoading, isOneLine, isVerified, isVerifiedAuthor, useDct]);

    const textEl = useMemo(
        () => (
            <Styled.NameText
                $kind={kind}
                ref={textRef}
                isOneLine={isOneLine}
                isLoading={isLoading}
                className="autotest__user-name__text"
            >
                {isLoading ? '' : displayName}
                {!isOneLine && verifiedIconEl}
            </Styled.NameText>
        ),
        [kind, isLoading, isOneLine, displayName, verifiedIconEl],
    );

    const textCommonWrapperEl = useMemo(() => {
        if (url) {
            return (
                <Styled.NameTextLinkWrapper
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="autotest__user-name__text-link"
                    onClick={onClick}
                    isLoading={isLoading}
                >
                    {textEl}
                </Styled.NameTextLinkWrapper>
            );
        }

        return <Styled.NameTextWrapper>{textEl}</Styled.NameTextWrapper>;
    }, [isLoading, onClick, textEl, url]);

    const textCommonWrapperTooltipRichEl = useMemo(() => {
        if (isTextOverflowEllipsis && isTooltipActive) {
            return (
                <Styled.Tooltip content={displayName} placement="top">
                    {textCommonWrapperEl}
                </Styled.Tooltip>
            );
        }

        return textCommonWrapperEl;
    }, [displayName, isTextOverflowEllipsis, isTooltipActive, textCommonWrapperEl]);

    return (
        <Styled.UserNameWrapper isOneLine={isOneLine} className={className} isLoading={isLoading}>
            {textCommonWrapperTooltipRichEl}
            {isOneLine && verifiedIconEl}
        </Styled.UserNameWrapper>
    );
}

export type { UserNameProps };
