import { CreateCopyTradingStrategyRequestBody } from '../steps/types';
import { Step1FormValues, Step2FormValues, Step3FormValues, Step4FormValues } from '../steps/copy-trading-strategies';
import { toData as step1ToData } from '../steps/copy-trading-strategies/step1/toData';
import { toData as step2ToData } from '../steps/copy-trading-strategies/step2/toData';
import { toData as step3ToData } from '../steps/copy-trading-strategies/step3/toData';
import { toData as step4ToData } from '../steps/copy-trading-strategies/step4/toData';

import { featureTitles } from './constants';

export function getCopyTradingStrategyData(
    step1FormValues: Step1FormValues,
    step2FormValues: Step2FormValues,
    step3FormValues: Step3FormValues,
    step4FormValues: Step4FormValues | undefined,
): CreateCopyTradingStrategyRequestBody {
    const { brokerReport } = step1ToData(step1FormValues);
    const { accountId, minSum, riskLevel, price, recommendedHoldingPeriod } = step2ToData(step2FormValues);
    const { name, description, generalTradingIdea } = step3ToData(step3FormValues);
    const {
        tradingMethod = '',
        riskManagement = '',
        cashAndLeverage = '',
        additionalInformation = '',
    } = step4FormValues ? step4ToData(step4FormValues) : {};
    return {
        brokerReport,
        accountId,
        minSum,
        riskLevel,
        price,
        name,
        description,
        backgroundImageUrl: 'https://wt-s3-dev.s3.amazonaws.com/s1/photo35BD/20586874610-0/xsmall.jpeg',
        billingType: 'FixedDonation',
        tags: [],
        // title не переводим потому что на бэк отправляется как введенный пользователем текст
        keyFeatures: [
            { title: featureTitles.recommendedHoldingPeriod, description: recommendedHoldingPeriod },
            { title: featureTitles.generalTradingIdea, description: generalTradingIdea },
            { title: featureTitles.tradingMethod, description: tradingMethod },
            { title: featureTitles.cashAndLeverage, description: cashAndLeverage },
            { title: featureTitles.riskManagement, description: riskManagement },
            { title: featureTitles.additionalInformation, description: additionalInformation },
        ].filter((feature) => feature.description),
    };
}
