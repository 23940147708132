/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUsStrategyRequestDto } from '../models/MyStrategiesService/CreateUsStrategyRequestDto';
import type { OrderByProperty } from '../models/shared/OrderByProperty';
import type { UpdateUsStrategyRequestDto } from '../models/MyStrategiesService/UpdateUsStrategyRequestDto';
import type { UsNewStrategyDtoWebResponse } from '../models/MyStrategiesService/UsNewStrategyDtoWebResponse';
import type { UsStrategyDtoIReadOnlyCollectionWebResponse } from '../models/MyStrategiesService/UsStrategyDtoIReadOnlyCollectionWebResponse';
import type { UsStrategyDtoWebResponse } from '../models/shared/UsStrategyDtoWebResponse';
import type { WebResponse } from '../models/shared/WebResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MyStrategiesService {
    /**
     * Создание новой стратегии
     * @returns UsStrategyDtoWebResponse Success
     * @throws ApiError
     */
    public static createApiVStrategiesMy({
        requestBody,
    }: {
        /**
         * Модель запроса для создания стратегии
         */
        requestBody?: CreateUsStrategyRequestDto;
    }): CancelablePromise<UsStrategyDtoWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/strategies/my',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Возвращает стратегии авторизованного пользователя
     * @returns UsStrategyDtoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getApiVStrategiesMy({
        ids,
        ownerId,
        archived,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Фильтр по id стратегий
         */
        ids?: Array<number>;
        /**
         * Фильтр по GlobalId владельца
         */
        ownerId?: string;
        /**
         * false - только существующие стратегии, true - только стратегии из архива
         */
        archived?: boolean;
        page?: number;
        pageSize?: number;
        orderBy?: Array<OrderByProperty>;
    }): CancelablePromise<UsStrategyDtoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/strategies/my',
            query: {
                Ids: ids,
                OwnerId: ownerId,
                Archived: archived,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Обновление существующей стратегии
     * @returns UsStrategyDtoWebResponse Success
     * @throws ApiError
     */
    public static updateApiVStrategyMy({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор стратегии
         */
        id: number;
        /**
         * Модель запроса для обновления стратегии
         */
        requestBody?: UpdateUsStrategyRequestDto;
    }): CancelablePromise<UsStrategyDtoWebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/strategies/my/{id}',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Архивирование стратегии
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static deleteApiVStrategyMy({
        id,
    }: {
        /**
         * Идентификатор стратегии
         */
        id: number;
    }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/strategies/my/{id}',
            path: {
                id: id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Возвращает данные для создания новой стратегии.
     * @returns UsNewStrategyDtoWebResponse Success
     * @throws ApiError
     */
    public static getAccountsForNewStrategy(): CancelablePromise<UsNewStrategyDtoWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/strategies/my/new',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
