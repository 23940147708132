import React, { FC, memo } from 'react';

import { Community, Id, PostType } from 'types';
import { useDictionary } from 'hooks/useDictionary';
import { LockIcon } from 'components/icons2/LockIcon';
import { GlobeIcon } from 'components/icons2/GlobeIcon';

import { CommunitiesHeader, MenuItemStyled, TruncatedText } from './styled';

interface Props {
    premiumPostsAllowed: boolean;
    onChangeType: (type: PostType) => void;
    onChangeCommunityId: (communityId: Id | undefined) => void;
    setIsPopupShown: (isPopupShown: boolean) => void;
    communities?: Community[];
}

export const PostTypeMenu: FC<Props> = memo(
    ({ premiumPostsAllowed, onChangeType, onChangeCommunityId, setIsPopupShown, communities }) => {
        const dic = useDictionary();

        return (
            <div>
                {premiumPostsAllowed && (
                    <MenuItemStyled
                        text={<TruncatedText>{dic.word('wt_feed__timeline_status__post_type_premium')}</TruncatedText>}
                        icon={{ component: LockIcon }}
                        onClick={() => {
                            onChangeType(PostType.PREMIUM);
                            onChangeCommunityId(undefined);
                            setIsPopupShown(false);
                        }}
                    />
                )}
                <MenuItemStyled
                    text={<TruncatedText>{dic.word('wt_feed__timeline_status__post_type_ordinary')}</TruncatedText>}
                    icon={{ component: GlobeIcon }}
                    onClick={() => {
                        onChangeType(PostType.ORDINARY);
                        onChangeCommunityId(undefined);
                        setIsPopupShown(false);
                    }}
                />
                {communities ? (
                    <CommunitiesHeader>
                        {dic.word('wt_feed__timeline_status__post_type_communities_header')}
                    </CommunitiesHeader>
                ) : null}
                {communities?.map(({ id, displayName, image }) => (
                    <MenuItemStyled
                        key={id}
                        text={<TruncatedText>{displayName}</TruncatedText>}
                        icon={image ? { component: image, size: 24 } : undefined}
                        onClick={() => {
                            onChangeType(PostType.COMMUNITY);
                            onChangeCommunityId(id);
                            setIsPopupShown(false);
                        }}
                    />
                ))}
            </div>
        );
    },
);
