import React, { useMemo, useEffect } from 'react';

import { PublishStatusRecord, TagData } from 'types';
import Dic from 'services/dictionary';
import { useHideComments } from 'hooks/useHideComments';
import { useFeedContext } from 'hooks/useFeedContext';
import { useToggle } from 'hooks/useToogle';
import { GalleryInitFunction } from 'components/gallery/Gallery';

import { useUrlHashIdComment } from '../../../comments/hooks/useUrlHashIdComment';
import { Activity } from '../../../activity';
import { useDeleteRecord } from '../../hooks/useDeleteRecord';
import { DeleteFeedRecord, RestoreFeedRecord, UpdateFeedRecord } from '../../actions';
import { useEditRecord } from '../../hooks/useEditRecord';
import { usePublisherFn } from '../../hooks/usePublisherFn';
import { AddFeedStatus } from '../../../add-status';
import { useIsPremiumExtend } from '../../hooks/useIsPremiumExtend';

import { DeletedRecordWrapper, DeletedRecordRestore, PublishStatusContainer } from './styled';

interface Props {
    record: PublishStatusRecord;
    allTags: TagData[];
    updateFeedRecord?: UpdateFeedRecord;
    deleteFeedRecord?: DeleteFeedRecord;
    restoreFeedRecord?: RestoreFeedRecord;
    onClickAnyImgInPost?: GalleryInitFunction;
    pinned?: boolean;
    highlighted?: boolean;
    instanceId: string;
    deletedIds: string[];
    onPremiumSubscribe?: () => void;
}

export const PublishStatus = (props: Props) => {
    const {
        record,
        onClickAnyImgInPost,
        highlighted,
        deleteFeedRecord,
        restoreFeedRecord,
        updateFeedRecord,
        allTags,
        pinned = false,
        deletedIds,
        instanceId,
        onPremiumSubscribe,
    } = props;
    const { tagsList } = record.obj || {};
    const [showComments, toggleShowComments] = useToggle(false);
    const { isDeleted, onDelete, onRestore } = useDeleteRecord(
        instanceId,
        record,
        deletedIds,
        deleteFeedRecord,
        restoreFeedRecord,
    );
    const { isEditing, setIsEditing } = useEditRecord();
    const [{ owner }] = useFeedContext();
    const { isPremiumExtend } = useIsPremiumExtend(record);
    const hideComments = useHideComments(record?.obj?.author?.id);
    const handleUpdate = usePublisherFn(updateFeedRecord);

    const authorProps = useMemo(() => ({ onDelete }), [record, deleteFeedRecord]);
    const htmlParsedContentProps = useMemo(
        () => ({ onClickAnyImgInPost, onPremiumSubscribe }),
        [onClickAnyImgInPost, onPremiumSubscribe],
    );
    const footerProps = useMemo(() => ({ onClickComments: toggleShowComments }), [toggleShowComments]);

    const { hashCommentId: pinnedCommentId } = useUrlHashIdComment();

    useEffect(() => {
        if (pinned && !!pinnedCommentId) {
            toggleShowComments();
        }
    }, []);

    return (
        <PublishStatusContainer highlighted={highlighted} data-blogpost-id={record.obj?.id}>
            {!isDeleted && !isEditing && (
                <Activity
                    record={record}
                    author={authorProps}
                    title={isPremiumExtend}
                    instruments={isPremiumExtend}
                    htmlParsedContent={htmlParsedContentProps}
                    footer={(isPremiumExtend && footerProps) || undefined}
                    comments={isPremiumExtend && !hideComments && showComments}
                />
            )}
            {isEditing && (
                <AddFeedStatus
                    instanceId={instanceId}
                    isEditing
                    owner={owner}
                    objId={record.obj?.id}
                    initHtml={record.obj?.content}
                    initRawJson={record.obj?.json || ''}
                    publisherFn={handleUpdate}
                    placeholder={Dic.word('wt_feed__timeline_status__real_placeholder')}
                    onActiveChange={(isActive) => {
                        if (!isActive) setIsEditing(false);
                    }}
                    autofocus={false}
                    allTags={allTags}
                    initSelectedTags={tagsList}
                    isActive
                />
            )}
            {isDeleted && (
                <DeletedRecordWrapper>
                    {Dic.word('wt_feed__deleted_record__deleted_post')}
                    &nbsp;
                    <DeletedRecordRestore onClick={onRestore}>
                        {Dic.word('wt_feed__deleted_record__restore')}
                    </DeletedRecordRestore>
                </DeletedRecordWrapper>
            )}
        </PublishStatusContainer>
    );
};
