import { Lcid } from 'types';
import notify from 'services/notify';
import { Dic } from 'services/dictionary';
import { sentryClient, SentryError } from 'services/sentry';

import { resolveTxGA } from './provide-tx-ga';
import { initOpts } from './constants';

export function initScript(lang: Lcid) {
    if (window.TxGlobalAuth) {
        resolveTxGA(window.TxGlobalAuth);
    }
    const { src, env } = initOpts;
    const script = document.createElement('script');
    script.src = src;
    script.onerror = () => {
        notify.error(Dic.word('wt_all__notification__common_error'));
        sentryClient.captureException(new SentryError('Не удалось обратиться к GlobalAuth', 'GlobalAuthNotFound'));
        window.__txGlobalAuthProvider?.txGlobalAuthLoadingDefer?.reject();
    };
    script.onload = () => {
        const txGlobalAuth = window.TxGlobalAuth;
        if (txGlobalAuth) {
            txGlobalAuth
                .init({
                    variant: 'spc',
                    appName: 'limex',
                    txAuth: {
                        spc: {
                            registration: { txUserFlowSource: 0 },
                        },
                    },
                    appVersion: '1.0.0',
                    // provider: ['LIMEX_DEV_KRATOS_TOKEN', GUEST_PROVIDER],
                    lang,
                    env,
                    parseUrl: true,
                })
                .then(() => {
                    resolveTxGA(txGlobalAuth);
                });
        }
    };
    document.head.appendChild(script);
}
