import { css } from 'styled-components';

import { InlineLabel, Input, RightGroupWrapper } from '../base';

const disabledStyles = css`
    cursor: not-allowed;

    & ${InlineLabel}, & ${RightGroupWrapper}, & ${Input}, & ${Input}::placeholder {
        color: ${({ theme }) => theme.label.inactive};
        cursor: not-allowed;
    }
`;

export const disabledFillStyles = css`
    ${disabledStyles};
    background-color: ${({ theme }) => theme.fillIn.secondary.overlay8};
`;

export const disabledOutlineStyles = css`
    ${disabledStyles};
    background-color: transparent;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.muted};
`;
