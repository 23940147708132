/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { PagedWebResponse_IApiKeyDto } from '../models/ApiKeysService/PagedWebResponse_IApiKeyDto';
import type { WebResponse_String } from '../models/shared/WebResponse_String';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiKeysService {
    /**
     * Полчить токен для продукта
     * @returns WebResponse_String Success
     * @throws ApiError
     */
    public static createTokenByProductId({
        productId,
    }: {
        /**
         * Идентификатор продукта
         */
        productId: number;
    }): CancelablePromise<WebResponse_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/api-keys/products/{productId}',
            path: {
                productId: productId,
            },
        });
    }

    /**
     * Показать токен
     * @returns WebResponse_String Success
     * @throws ApiError
     */
    public static createApiVMyApiKeyReveal({
        apiKeyId,
    }: {
        /**
         * Идентификатор ключа доступа
         */
        apiKeyId: number;
    }): CancelablePromise<WebResponse_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/api-keys/reveal/{apiKeyId}',
            path: {
                apiKeyId: apiKeyId,
            },
        });
    }

    /**
     * Удалить ключ досутпа
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static deleteApiVMyApiKey({
        apiKeyId,
    }: {
        /**
         * Идентификатор ключа
         */
        apiKeyId: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/api-keys/{apiKeyId}',
            path: {
                apiKeyId: apiKeyId,
            },
        });
    }

    /**
     * Получить ключи доступа пользователя
     * @returns PagedWebResponse_IApiKeyDto Success
     * @throws ApiError
     */
    public static getApiVMyApiKeys({
        productId,
        isArchived = false,
        page = 1,
        pageSize = 20,
    }: {
        /**
         * Идентификатор продукта
         */
        productId?: number | null;
        /**
         * Признак архивности
         */
        isArchived?: boolean | null;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_IApiKeyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/api-keys',
            query: {
                ProductId: productId,
                IsArchived: isArchived,
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Проверить авторизацию через ApiKey
     * @returns WebResponse_String Success
     * @throws ApiError
     */
    public static createApiVMyApiKeysCheck(): CancelablePromise<WebResponse_String> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/api-keys/check',
        });
    }
}
