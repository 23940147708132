import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { FormDescription, Gap20FormVLayout, VerticalFormInputGroup } from 'components/form';
import { FormTextInput } from 'components/form/react-hook-form';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { StepProps } from '../../types';
import { MAX_URL_LENGTH, MIN_URL_LENGTH } from '../step1/constants';

import { Step4FormValues } from './types';
import { defaultValues } from './constants';
import { toData } from './toData';
import { canSkip } from './canSkip';

export const Step4: FC<StepProps<Step4FormValues>> = memo((props) => {
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_myspace_product_wizard__step4_');
    const form = useForm<Step4FormValues>({
        mode: 'onTouched',
        defaultValues,
    });

    useFormStateHandler({ form, props, toData, canSkip });

    const fields = ['urlOfficial', 'trialLink', 'downloadLink', 'supportContact'];

    return (
        <FormProvider {...form}>
            <Gap20FormVLayout>
                <FormDescription>{dicWord('description')}</FormDescription>
                <VerticalFormInputGroup>
                    {fields.map((field) => (
                        <FormTextInput
                            key={field}
                            name={field}
                            kind="outline"
                            size="medium"
                            label={dicWord(`field_${field}_label`)}
                            className={`autotest__marketplace__product_wizard__${field}`}
                            maxLength={MAX_URL_LENGTH}
                            rules={{
                                minLength: {
                                    value: MIN_URL_LENGTH,
                                    message: dic.word(
                                        'wt_all__widget_product_wizard__step2_field_description_min_message',
                                        { min: MIN_URL_LENGTH },
                                    ),
                                },
                                maxLength: {
                                    value: MAX_URL_LENGTH,
                                    message: dic.word('wt_all__widget_product_wizard__text_block_title_too_long_msg'),
                                },
                                validate(value: string) {
                                    if (value === '') {
                                        return true;
                                    }
                                    const error = dic.word('wt_all__widget_product_wizard__field_url_invalid');
                                    try {
                                        const { protocol, hostname } = new URL(value);
                                        return (protocol === 'http:' || protocol === 'https:') && hostname.includes('.')
                                            ? true
                                            : error;
                                    } catch {
                                        return error;
                                    }
                                },
                            }}
                        />
                    ))}
                </VerticalFormInputGroup>
            </Gap20FormVLayout>
        </FormProvider>
    );
});
