import { PathPattern, matchPath as matchPathBase, ParamParseKey, PathMatch, Params } from 'react-router-dom';

export type PathPatternExtended<Path extends string = string> = PathPattern<Path> & {
    /** Регулярные выражения, которыми дополнительно проверяются извлеченные из url параметры.
     * Если хотя бы один указанный здесь параметр не проходит проверку, возвращается null.
     * Если в регулярном выражении есть capture group (круглые скобки), в параметр подставится то, что внутри группы
     * */
    params?: Record<string, RegExp>;
};

/** Оборачивает matchPath из react-router-dom и добавляет дополнительные проверки на формат параметров */
export function matchPath<ParamKey extends ParamParseKey<Path>, Path extends string>(
    pattern: PathPatternExtended<Path> | Path,
    pathname: string,
): PathMatch<ParamKey> | null {
    const match = matchPathBase(pattern, pathname);
    if (match && match.params && typeof pattern === 'object' && pattern.params) {
        const { params } = match;
        const allParamsMatched = Object.entries(pattern.params).every(([paramKey, regex]) => {
            const value = params[paramKey as ParamParseKey<Path>];
            return value && regex.test(value);
        });
        if (!allParamsMatched) {
            return null;
        }
        const patternRegexes = pattern.params;
        const updatedParams = Object.entries<string | undefined>(params).reduce((acc, [key, value]) => {
            acc[key] = value;
            if (value && patternRegexes[key]) {
                const capture = patternRegexes[key].exec(value)?.[1];
                if (capture !== undefined) {
                    acc[key] = capture;
                }
            }
            return acc;
        }, {} as Record<string, string | undefined>);
        return { ...match, params: updatedParams as Params<ParamKey> };
    }
    return match;
}
