import { CounterShortNameSizeValue, CounterSizeValue } from './types';

export const getShortSizeValueBySizeValue = (_sizeValue: CounterSizeValue): CounterShortNameSizeValue => {
    if (_sizeValue === 'extra-large' || _sizeValue === 'XL') {
        return 'XL';
    }

    if (_sizeValue === 'large' || _sizeValue === 'L') {
        return 'L';
    }

    if (_sizeValue === 'medium' || _sizeValue === 'M') {
        return 'M';
    }

    return 'S';
};
