import React from 'react';

import { MenuItemContainerProps } from '../types';
import * as Styled from '../styled/MenuItemContainer.styled';

export const MenuItemContainer = (props: MenuItemContainerProps) => {
    const {
        disabled = false,
        isSelected = false,
        isHovered = false,
        className = '',
        kind = 'primary',
        href,
        tabIndex,
        width,
        size = 'regular',
        onClick,
        children,
        autotestClass,
    } = props;

    return (
        <Styled.MenuItemWrapper>
            <Styled.MenuItemActionWrapper
                disabled={disabled}
                isSelected={isSelected}
                isHovered={isHovered}
                className={className}
                onClick={onClick}
                kind={kind}
                as={href ? 'a' : 'div'}
                href={href}
                tabIndex={tabIndex || 0}
                width={width}
                size={size}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'Enter') {
                        onClick?.(e);
                    }
                }}
            >
                <Styled.ContentWrapper className={autotestClass}>{children}</Styled.ContentWrapper>
            </Styled.MenuItemActionWrapper>
        </Styled.MenuItemWrapper>
    );
};
