import React, { FC, memo, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Account } from 'services/mp-share-resources/types';
import { useDictionary } from 'hooks/useDictionary';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { useLocaleId } from 'hooks/useLocaleId';
import { VerticalFormInputGroup } from 'components/form';
import { FormListBox, FormNumberInput, FormTextInput } from 'components/form/react-hook-form';

import { StepProps } from '../../types';
import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { KEY_FEATURE_DESCRIPTION_MAX_LENGTH, useKeyFeatureValidation } from '../../../../hooks/useKeyFeatureValidation';

import { toData } from './toData';
import { defaultValues, MAX_RETURN, MIN_RETURN } from './constants';
import { Step1FormValues } from './types';

export const Step1: FC<StepProps<Step1FormValues> & { accounts?: Account[]; hidePrice?: boolean }> = memo((props) => {
    const dic = useDictionary();
    const lcid = useLocaleId();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step2_');
    const form = useForm<Step1FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    useFormStateHandler({
        form,
        props,
        toData,
        title: dicWord('title'),
    });

    const risk = useMemo(
        () => [
            { id: 'Conservative', text: dicWord('risk_conservative') },
            { id: 'Moderate', text: dicWord('risk_moderate') },
            { id: 'Aggressive', text: dicWord('risk_aggressive') },
        ],
        [dicWord],
    );

    const required = dic.word('wt_all__widget_product_wizard__field_required');
    const keyFeatureRules = useKeyFeatureValidation();

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                <div>
                    <FormListBox
                        name="riskLevel"
                        placeholder={dicWord('risk')}
                        kind="inline-label-outline"
                        size="medium"
                        className="autotest__marketplace__product_wizard__risk"
                        options={risk}
                        autotestOptionPrefix="autotest__marketplace__product_wizard__risk_"
                        rules={{ required }}
                    />
                </div>
                <FormTextInput
                    label={dicWord('period')}
                    name="recommendedHoldingPeriod"
                    kind="outline"
                    size="medium"
                    rules={keyFeatureRules}
                    maxLength={KEY_FEATURE_DESCRIPTION_MAX_LENGTH}
                    className="autotest__marketplace__product_wizard__period"
                />
                <FormNumberInput
                    label={dicWord('target')}
                    name="targetReturnAnnualized"
                    kind="outline"
                    size="medium"
                    locale={lcid}
                    rules={{ required, pattern: { value: /\d/, message: required } }}
                    min={MIN_RETURN}
                    max={MAX_RETURN}
                    className="autotest__marketplace__product_wizard__target_return"
                />
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
