import React, { FC } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';

import { Wrapper } from './styled';

export type DesktopLinksProps = {
    isShowShopV2: boolean;
};

export const DesktopLinks: FC<DesktopLinksProps> = (props) => {
    const { isShowShopV2 } = props;

    const dic = useDictionary();
    const links = useLinks();
    return (
        <Wrapper>
            <ButtonIcon
                kind={ButtonIconKinds.GhostPrimary}
                size="M"
                text={dic.word('wt_menu__menu_limex__myspace_text')}
                className="autotest__menu__myspace"
                href={links.homeRoot()}
            />
            <ButtonIcon
                kind={ButtonIconKinds.GhostPrimary}
                size="M"
                text={dic.word('wt_menu__menu_limex__feed_text')}
                className="autotest__menu__feed"
                href={links.feed()}
            />
            <ButtonIcon
                kind={ButtonIconKinds.GhostPrimary}
                size="M"
                text={dic.word('wt_menu__menu_limex__communities_text')}
                className="autotest__menu__communities"
                href={links.communities()}
            />
            <ButtonIcon
                kind={ButtonIconKinds.GhostPrimary}
                size="M"
                text={dic.word('wt_menu__menu_limex__shop_text')}
                className="autotest__menu__marketplace"
                href={isShowShopV2 ? links.shop2() : links.shop()}
            />
        </Wrapper>
    );
};
