import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Lcid } from 'types';
import { mediaQuery } from 'app/styled';
import { login, logout, registration } from 'services/global-auth-provider';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useDictionary } from 'hooks/useDictionary';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMatchMedia } from 'hooks/useMatchMedia';
// import { useLocaleId } from 'hooks/useLocaleId';
import { MenuItemData } from 'widgets/context-menu/interfaces';
import { getIsShowBadge } from 'widgets/header-notifications/utils';
import { HeaderNotificationsConnection } from 'widgets/header-notifications';
import { UserOutlineIcon } from 'components/icons2/UserOutlineIcon';

import * as Styled from './styled';
import { NavigationMenuConnectorProps } from './types';
import { NavigationMenuUI } from './components';

export const NavigationMenuConnector: FC<NavigationMenuConnectorProps> = memo((props) => {
    const {
        // state
        isOpenedNotifications,
        unreadCountNotifications,
        // own state
        initialUnreadCount,
        // dispatch
        updateLocale,
        updateCountry,
        contactSupport,
        updateUnreadCount,
        // own
        isFeed,
        isShowFavorites,
        logoElement,
        isHomePage,
    } = props;

    useEffect(() => {
        if (initialUnreadCount) {
            updateUnreadCount(initialUnreadCount);
        }
    }, []);

    const csrf = useCSRFToken();
    const dic = useDictionary();
    const currentUser = useCurrentUser();
    // const lcid = useLocaleId();
    // const { countryCode } = currentUser;
    // const isShowShopV2 = lcid !== 'ru' && countryCode !== 'RU' && countryCode !== 'BY';
    const isShowShopV2 = false; // пока скрываем для всех shopv2

    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

    const showMobileMenu = useCallback(() => setIsShowMobileMenu(true), []);

    const hideMobileMenu = useCallback(() => setIsShowMobileMenu(false), []);

    const handleRegister = useCallback(() => {
        registration();
    }, []);

    const [isShowSupport, setIsShowSupport] = useState(false);

    const hideSupport = useCallback(() => {
        setIsShowSupport(false);
    }, []);

    const [isShowSuccess, setIsShowSuccess] = useState(false);

    const [email, setEmail] = useState('');

    const isMobile = useMatchMedia(mediaQuery.lt768);

    useEffect(() => {
        if (!isMobile) {
            hideMobileMenu();
        }
    }, [hideMobileMenu, isMobile]);

    const handleSuccess = useCallback((formEmail: string) => {
        setIsShowSupport(false);
        setIsShowSuccess(true);
        setEmail(formEmail);
    }, []);

    const handleCloseSuccess = useCallback(() => {
        setIsShowSuccess(false);
        setEmail('');
    }, []);

    const handleContextMenuClick = useCallback(
        (name: string, item: MenuItemData) => {
            if (name === 'languages') {
                updateLocale(item.id as Lcid, csrf);
            }

            if (name === 'countries') {
                updateCountry(item.id, csrf);
            }

            if (name === 'guest' && item.id === 'register') {
                handleRegister();
            }

            if (name === 'guest' && item.id === 'login') {
                login();
            }

            if (name === 'user' && item.id === 'logout') {
                logout();
            }

            if ((name === 'user' || name === 'guest') && item.id === 'support') {
                setIsShowSupport(true);
            }

            if (item.id === 'support') {
                setIsShowSupport(true);
            }
        },
        [csrf, handleRegister, updateCountry, updateLocale],
    );

    const texts = useMemo(
        () => ({
            registerButton: dic.word('wt_all__navigation__buttons_join'),
        }),
        [dic],
    );

    const isShowBadgeNotifications = useMemo(
        () =>
            getIsShowBadge({
                unreadCount: unreadCountNotifications,
                isOpened: isOpenedNotifications,
            }),
        [unreadCountNotifications, isOpenedNotifications],
    );

    const contextMenuButtonIconComponent = useMemo(
        () =>
            isMobile
                ? () => (
                      <Styled.ButtonBadge isShowBadge={isShowBadgeNotifications}>
                          <UserOutlineIcon />
                      </Styled.ButtonBadge>
                  )
                : undefined,
        [isShowBadgeNotifications, isMobile],
    );

    return (
        <>
            {currentUser.isAuth ? <HeaderNotificationsConnection /> : null}
            <NavigationMenuUI
                isMobile={isMobile}
                withBottomIndentDividerMobile={!isFeed}
                isShowFavorites={isShowFavorites}
                isHomePage={isHomePage}
                contactSupport={contactSupport}
                logoElement={logoElement}
                texts={texts}
                registration={handleRegister}
                currentUser={currentUser}
                handleContextMenuClick={handleContextMenuClick}
                isShowSupport={isShowSupport}
                onClickMobileHamburger={showMobileMenu}
                onCloseSupport={hideSupport}
                onSuccessSupport={handleSuccess}
                onCloseSuccess={handleCloseSuccess}
                email={email}
                onCloseMobileLinks={hideMobileMenu}
                isShowMobileMenu={isShowMobileMenu}
                isShowSuccess={isShowSuccess}
                contextMenuButtonIconComponent={contextMenuButtonIconComponent}
                isShowShopV2={isShowShopV2}
            />
        </>
    );
});
