import { ResponsiveMap } from 'app/styled';

import { AvatarFullSizeValue, AvatarShortSizeValue, AvatarSizeValue } from './types';

export const SIZE_FULL_TO_SHORT_MAP: { [key in AvatarFullSizeValue]: AvatarShortSizeValue } = {
    small: 'S',
    medium: 'M',
    large: 'L',
    'extra-large': 'XL',
    // Новые не добавлять, а уточнить у дизайнера точно ли нужен такой размер и без него никак не обойтись? затем перенаправить на макет аватаров. А если все-таки нужен другой размер аватара, то задавайте числом. Подробнее в сторибуке
};

export const DEFAULT_SIZE: ResponsiveMap<AvatarSizeValue> = {
    lt768: 'M',
    default: 'L',
};

export const EXCLUDE_IMG_ARR_SRC = ['blank_male', 'blank_female'];

export const ANIMATION_DURATION = 600;

export const TRANSITION_ALL_CSS = `
    transition: all ${ANIMATION_DURATION}ms;
`;
