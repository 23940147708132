import { GeneratorType, isTickerNewsRecord, isRecommendationRecord, isPublishStatusRecord } from 'types';
import { mergeByPath } from 'services/utils/merge';
import { SubscribeActions, SubscribePayloadPass, SUBSCRIBE_ACTION_TYPES } from 'widgets/action-subscribe/actions';

import { FeedState } from '../types';

const [SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILURE] = SUBSCRIBE_ACTION_TYPES;

const updateStateSubscribed = (
    state: FeedState,
    pass: SubscribePayloadPass,
    needUpdate: (currentSubscribed: boolean) => boolean,
    updateValueFn: (currentSubscribed: boolean) => boolean,
): FeedState => {
    const { generatorId, generatorType } = pass;
    const newRecords = Object.keys(state.records).reduce((acc, key) => {
        let newRecord = state.records[key];
        if (generatorType === GeneratorType.USER || generatorType === GeneratorType.PREMIUM_POST) {
            if (isPublishStatusRecord(newRecord)) {
                const { generator } = newRecord;
                if (generator) {
                    if (generator.id === generatorId && (generator.type === generatorType || generatorType === GeneratorType.PREMIUM_POST) && generator.data) {
                        if (needUpdate(generator.data.subscribed ?? false)) {
                            newRecord = mergeByPath(newRecord, 'generator.data', {
                                subscribed: updateValueFn(generator.data.subscribed ?? false),
                            });
                        }
                    }
                }
            }
            if (isRecommendationRecord(newRecord)) {
                const users = newRecord?.obj?.recommendations || [];

                if (users) {
                    newRecord = mergeByPath(
                        newRecord,
                        'obj.recommendations',
                        users.map((user) => {
                            const { subscribed = false } = user?.data || {};
                            if (user.id === generatorId && needUpdate(subscribed ?? false)) {
                                return mergeByPath(user, 'data.subscribed', updateValueFn(subscribed ?? false));
                            }
                            return user;
                        }),
                    );
                }
            }
        }
        if (isTickerNewsRecord(newRecord) && generatorType === GeneratorType.INSTRUMENT) {
            const { tickerNews } = newRecord;
            if (tickerNews) {
                if (tickerNews.instrument?.id && tickerNews.instrument.id === generatorId) {
                    if (needUpdate(Boolean(tickerNews.isSubscribed))) {
                        newRecord = mergeByPath(newRecord, 'tickerNews', {
                            isSubscribed: updateValueFn(Boolean(tickerNews.isSubscribed)),
                        });
                    }
                }
            }
        }

        return { ...acc, [key]: newRecord };
    }, {});
    return {
        ...state,
        records: newRecords,
    };
};

export default (state: FeedState, action: SubscribeActions) => {
    switch (action.type) {
        case SUBSCRIBE_REQUEST: {
            const {
                payload: { pass },
            } = action;
            const { action: backendAction } = pass;
            return updateStateSubscribed(
                state,
                pass,
                () => true,
                () => backendAction === 'subscribe',
            );
        }
        case SUBSCRIBE_FAILURE: {
            const {
                payload: { error, pass },
            } = action;
            const { action: backendAction } = pass;
            if (error) {
                return updateStateSubscribed(
                    state,
                    pass,
                    (currentSubscribed) => currentSubscribed === (backendAction === 'subscribe'),
                    (currentSubscribed) => !currentSubscribed,
                );
            }
            return state;
        }
        default:
            return state;
    }
};
