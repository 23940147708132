import { AsyncMethod, GlobalStateForCache, Path, ResourcesMap } from '../types';
import { selectorsCache } from '../cache/selectorsCache';
import { prefixObjectKeys } from '../utils/prefixObjectKeys';

import { createSelectRequestData } from './selectRequestData';
import { createSelectRequestState } from './selectRequestState';

export function createSelectRequest<
    TYPES_MAP extends object,
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    CUSTOM_PREFIX extends string,
>({
    resourcesMap,
    endpointName,
    dataPath,
    customPrefix,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: ENDPOINT_NAME;
    dataPath?: Path<Awaited<ReturnType<API_METHOD>>>;
    customPrefix?: CUSTOM_PREFIX;
}) {
    const typeKey = { endpointName };
    const selectRequestData = createSelectRequestData({ resourcesMap, endpointName, dataPath });
    const selectRequestState = createSelectRequestState({ endpointName });
    return (state: GlobalStateForCache, argsOrRequestName: Parameters<API_METHOD>[0] | string = {}) => {
        const data = selectRequestData(state, argsOrRequestName);
        const requestState = selectRequestState(state, argsOrRequestName);
        const cached = selectorsCache.get(data ? [typeKey, data, requestState] : [typeKey, requestState]);
        if (cached) {
            return cached;
        }
        const newValue = prefixObjectKeys(customPrefix ?? endpointName, { '': data, ...requestState });
        selectorsCache.set(data ? [typeKey, data, requestState] : [typeKey, requestState], newValue);
        return newValue;
    };
}
