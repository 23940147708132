import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LockSmallIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.69995 8.49995C8.69995 6.67742 10.1774 5.19996 11.9999 5.19995C13.8225 5.19994 15.3 6.6774 15.3 8.49995V11.1999H15.5C16.4941 11.1999 17.3 12.0058 17.3 12.9999V17.4999C17.3 18.494 16.4941 19.2999 15.5 19.2999H8.49995C7.50584 19.2999 6.69995 18.494 6.69995 17.4999V12.9999C6.69995 12.0058 7.50584 11.1999 8.49995 11.1999H8.69995V8.49995ZM10.3 11.1999H13.7V8.49995C13.7 7.56106 12.9388 6.79994 11.9999 6.79995C11.0611 6.79996 10.3 7.56107 10.3 8.49995V11.1999Z"
        />
    </IconSvg>
);
