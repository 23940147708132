import { useCallback } from 'react';

import { getCurrentHeight } from 'widgets/navigation-menu/getHeight';

const SCROLL_OFFSET = 16;

/*
    const scrollToPosition = useScrollToPosition();
    scrollToPosition(topPosNum);
 */

export const useScrollToPosition = (behavior: ScrollBehavior = 'smooth') =>
    useCallback((topPosition: number | null) => {
        if (topPosition && topPosition !== 0) {
            const windowScrollToPosition = topPosition - (getCurrentHeight() + SCROLL_OFFSET);

            window.scrollTo({
                top: windowScrollToPosition,
                behavior,
            });
        }
    }, []);
