import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';

export const CardSummaryText = styled.span`
    ${body14RegularFontStyle};
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: 40px;
    min-height: 40px;
`;
