/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { Marketplace_Web_Public_V1_My_LimexCopy_ConfirmRegistrationWebRequest } from '../models/UsLimexCopyService/Marketplace_Web_Public_V1_My_LimexCopy_ConfirmRegistrationWebRequest';
import type { Marketplace_Web_Public_V1_My_LimexCopy_CreateUsStrategyWebRequest } from '../models/UsLimexCopyService/Marketplace_Web_Public_V1_My_LimexCopy_CreateUsStrategyWebRequest';
import type { Marketplace_Web_Public_V1_My_LimexCopy_UpdateUsStrategyWebRequest } from '../models/UsLimexCopyService/Marketplace_Web_Public_V1_My_LimexCopy_UpdateUsStrategyWebRequest';
import type { PagedWebResponse_SignalHistoryDto } from '../models/UsLimexCopyService/PagedWebResponse_SignalHistoryDto';
import type { PagedWebResponse_SyncAttemptDto } from '../models/UsLimexCopyService/PagedWebResponse_SyncAttemptDto';
import type { WebResponse_Array_SyncChangeDto } from '../models/UsLimexCopyService/WebResponse_Array_SyncChangeDto';
import type { WebResponse_Array_UsLeanStatInfoDto } from '../models/UsLimexCopyService/WebResponse_Array_UsLeanStatInfoDto';
import type { WebResponse_ConfirmRegistrationResponse } from '../models/UsLimexCopyService/WebResponse_ConfirmRegistrationResponse';
import type { WebResponse_GetRegistrationResponse } from '../models/UsLimexCopyService/WebResponse_GetRegistrationResponse';
import type { WebResponse_Nullable_LocalDate } from '../models/UsLimexCopyService/WebResponse_Nullable_LocalDate';
import type { WebResponse_RichUsStrategyProductDto } from '../models/shared/WebResponse_RichUsStrategyProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsLimexCopyService {
    /**
     * Синхронизация счета под подписке
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static sync({
        subscriptionId,
    }: {
        /**
         * Идентификатор подписки в Маркетплейсе
         */
        subscriptionId: number;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/limex-copy/sync/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
        });
    }

    /**
     * Предварительная синхронизация счета под подписке
     * @returns WebResponse_Array_SyncChangeDto Success
     * @throws ApiError
     */
    public static dryRunSync({
        subscriptionId,
    }: {
        /**
         * Идентификатор подписки в Маркетплейсе
         */
        subscriptionId: number;
    }): CancelablePromise<WebResponse_Array_SyncChangeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/limex-copy/dry-run-sync/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
        });
    }

    /**
     * Получить историю синхронизаций по подписке
     * @returns PagedWebResponse_SyncAttemptDto Success
     * @throws ApiError
     */
    public static getSyncHistory({
        subscriptionId,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор подписки в Маркетплейсе
         */
        subscriptionId: number;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_SyncAttemptDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/limex-copy/sync-history/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
            query: {
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Получить список подписок ИИР
     * @returns PagedWebResponse_SignalHistoryDto Success
     * @throws ApiError
     */
    public static getApiVMyLimexCopySignalsHistory({
        subscriptionId,
        page,
        pageSize,
    }: {
        /**
         * Идентификатор подписки в Маркетплейсе
         */
        subscriptionId: number;
        /**
         * Номер страницы
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<PagedWebResponse_SignalHistoryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/limex-copy/signals-history/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
            query: {
                Page: page,
                PageSize: pageSize,
            },
        });
    }

    /**
     * Запрос на регистрацию пользователя в LimexCopy
     * @returns WebResponse_GetRegistrationResponse Success
     * @throws ApiError
     */
    public static startRegistration(): CancelablePromise<WebResponse_GetRegistrationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/limex-copy/registration',
        });
    }

    /**
     * Запрос на подтверждение регистрации пользователя
     * @returns WebResponse_ConfirmRegistrationResponse Success
     * @throws ApiError
     */
    public static confirmRegistration({
        requestBody,
    }: {
        /**
         * Данные подтверждения
         */
        requestBody?: Marketplace_Web_Public_V1_My_LimexCopy_ConfirmRegistrationWebRequest;
    }): CancelablePromise<WebResponse_ConfirmRegistrationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/limex-copy/registration',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Создать стратегию Limex Copy
     * @returns WebResponse_RichUsStrategyProductDto Success
     * @throws ApiError
     */
    public static createCopyTradingStrategy({
        requestBody,
    }: {
        /**
         * Данные стратегии
         */
        requestBody?: Marketplace_Web_Public_V1_My_LimexCopy_CreateUsStrategyWebRequest;
    }): CancelablePromise<WebResponse_RichUsStrategyProductDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/limex-copy/strategies',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Обновить стратегию Limex Copy
     * @returns WebResponse_RichUsStrategyProductDto Success
     * @throws ApiError
     */
    public static updateApiVMyLimexCopyStrategy({
        productId,
        requestBody,
    }: {
        /**
         * Идентификатор продукта (стратегии)
         */
        productId: number;
        /**
         * Данные стратегии
         */
        requestBody?: Marketplace_Web_Public_V1_My_LimexCopy_UpdateUsStrategyWebRequest;
    }): CancelablePromise<WebResponse_RichUsStrategyProductDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/limex-copy/strategies/{productId}',
            path: {
                productId: productId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Архивирует стратегию Limex Copy
     * @returns WebResponse_RichUsStrategyProductDto Success
     * @throws ApiError
     */
    public static deleteApiVMyLimexCopyStrategy({
        productId,
    }: {
        /**
         * Идентификатор продукта (стратегии)
         */
        productId: number;
    }): CancelablePromise<WebResponse_RichUsStrategyProductDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/limex-copy/strategies/{productId}',
            path: {
                productId: productId,
            },
        });
    }

    /**
     * Получить статистику по стратегии Limex Copy
     * @returns WebResponse_Array_UsLeanStatInfoDto Success
     * @throws ApiError
     */
    public static getApiVLimexCopyStrategyStats({
        productId,
        from,
        to,
    }: {
        /**
         * Продукт в МП
         */
        productId: number;
        /**
         * Начало интервала выборки доходности
         */
        from?: string | null;
        /**
         * Конец интервала выборки доходности
         */
        to?: string | null;
    }): CancelablePromise<WebResponse_Array_UsLeanStatInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/limex-copy/strategies/{productId}/stats',
            path: {
                productId: productId,
            },
            query: {
                From: from,
                To: to,
            },
        });
    }

    /**
     * Возвращает дату последнего рассчета статистики по стратегии Limex Copy
     * @returns WebResponse_Nullable_LocalDate Success
     * @throws ApiError
     */
    public static getApiVLimexCopyStrategyStatsLastDate({
        accountId,
    }: {
        /**
         * Счет из Limex Copy привязанного к стратегии
         */
        accountId: number;
    }): CancelablePromise<WebResponse_Nullable_LocalDate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/limex-copy/strategies/{accountId}/stats/last-date',
            path: {
                accountId: accountId,
            },
        });
    }
}
