import { matchPath, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { TagData } from 'types';
import { useLinks } from 'hooks/useLinks';

export function useNavigateToTag() {
    const navigate = useNavigate();
    const links = useLinks();
    return useCallback(
        ({ tag }: { tag: TagData }) => {
            const { pathname } = window.location;
            if (matchPath(links.feed(), pathname) || matchPath({ path: links.tag(':tagId'), end: false }, pathname)) {
                navigate({ pathname: links.tag(tag.id), search: '' });
            } else {
                const url = new URL(window.location.href);
                url.pathname = links.tag(tag.id);
                url.search = '';
                window.location.href = url.toString();
            }
        },
        [navigate, links],
    );
}
