import { Action, FeedRecordObj } from 'types';
import { Actions, thunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';

import { PublisherFn } from '../../add-status';

export type UpdateFeedRecordPayloadData = {
    post: FeedRecordObj;
};

const [UPDATE_FEED_RECORD_ACTION_TYPES, thunk, actions] = thunkActions('UPDATE_FEED_RECORD')<
    {
        json: string;
        html: string;
        csrf: string;
        tags: number[];
    },
    UpdateFeedRecordPayloadData
>();

const updateFeedRecord = (...args: Parameters<PublisherFn>) => {
    const [instanceId, html, json, { csrf, id, tags = [] }] = args;
    return thunk({
        request: {
            method: 'POST',
            url: UrlProcessor.page('limex_api_blogpost_manage').param('postId', id).path(),
            data: {
                json,
                html,
                csrf,
                tags,
            },
            withCredentials: true,
        },
    });
};
type UpdateFeedRecord = Action<typeof updateFeedRecord>;
type UpdateFeedRecordActions = Actions<typeof actions>;

export { UPDATE_FEED_RECORD_ACTION_TYPES, updateFeedRecord, UpdateFeedRecord, UpdateFeedRecordActions };
