import {
    FinamProductItem,
    finamProductTypes,
    MarketplaceWebPublicV1CatalogLeanProductDto,
    productTypesList,
} from 'types/finam-product';

export const isStrategy = (item: FinamProductItem) =>
    item?.productType && item.productType === finamProductTypes.Strategy;
export const isUserProduct = (item: FinamProductItem) =>
    item?.productType && item.productType === finamProductTypes.UsUserProduct;
export const isUsSignal = (item: FinamProductItem) =>
    item?.productType && item.productType === finamProductTypes.UsSignal;

export const isProductFinamCardByItem = (item: FinamProductItem) => {
    if (item && (isStrategy(item) || isUserProduct(item) || isUsSignal(item))) {
        return true;
    }
    return false;
};

export const filterValuableProducts = (products: MarketplaceWebPublicV1CatalogLeanProductDto[]) =>
    products
        .filter((product) => productTypesList.includes((product as FinamProductItem).productType))
        .map((product) => product as FinamProductItem);
