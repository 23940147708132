import { connect } from 'react-redux';

import { TagsWidgetComponent } from './components';
import { GlobalStateForTags } from './types';

const mapStateToProps = (state: GlobalStateForTags) => {
    const { tags } = state.widgets;
    return {
        tags: tags?.tags || [],
        subscribedTags: tags?.subscribedTags || [],
    };
};

export const TagsContainer = connect(mapStateToProps)(TagsWidgetComponent);
