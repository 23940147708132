import styled, { keyframes } from 'styled-components';

import { body17SemiboldFontStyle, caption12RegularFontStyle } from 'services/typography';
import { Button as ButtonComponent } from 'components/button2';

const manifestationWrapper = keyframes`
    0% {
        height: 0;
        opacity: 0;
        padding: 0;
    }
    100% {
        opacity: 1;
        height: 265px;
        padding: 36px 0;
    }`;

export const ANIMATION_DURATION = 180;

export const Cover = styled.div`
    margin-bottom: 8px;
`;
export const MainTitle = styled.div`
    ${body17SemiboldFontStyle};
    color: ${({ theme }) => theme.label.secondary};
    margin-bottom: 8px;
    text-align: center;
`;

export const SecondaryTitle = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.tertiary};
    text-align: center;
`;

export const Button = styled(ButtonComponent)`
    margin-top: 16px;
`;

export const NoDataWrapper = styled.div<{ withManifestationAnimate: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.bg.primary};
    padding: 36px 0;
    width: 100%;
    animation-name: ${({ withManifestationAnimate }) => (withManifestationAnimate ? manifestationWrapper : '')};
    animation-duration: ${ANIMATION_DURATION}ms;
    box-sizing: border-box;
    overflow: hidden;
`;
