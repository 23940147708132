import styled from 'styled-components';

import { Slider as SliderComponent } from 'components/slider';
import { Tag as TagComponent } from 'components/tag/Tag';

export const Slider = styled(SliderComponent)`
    .slick-slide > div {
        display: flex;
    }
`;

// arb: important нужен чтобы перекрыть inline-стили, которые накладывает слайдер
export const Tag = styled(TagComponent)`
    display: flex !important;
    width: initial !important;
    user-select: none;
`;
