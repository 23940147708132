import { css } from 'styled-components';

import { Theme } from 'services/theme';
import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';

import { StateMap, TabsListItemProps } from './index';

const bottomLine = (bgColor: string) => css`
    content: '';
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: var(--divider-width);
    background-color: ${bgColor};
    border-radius: calc(var(--divider-width) / 2);
`;

export const itemStandard = {
    tabItemStyles: {
        common: css`
            padding: var(--tab-padding-vertical) 0;
        `,
        active: css<TabsListItemProps>`
            &:after {
                ${({ theme: { fillIn }, kind }) =>
                    bottomLine(kind === 'secondary' ? fillIn.secondary.high : fillIn.primary.mid)};
                z-index: 1;
            }
        `,
    },
    anchorStyles: (theme: Theme): StateMap => {
        const {
            label,
            // fillIn
        } = theme;
        const color = ({ kind }: TabsListItemProps) => (kind === 'secondary' ? label.primary : label.accent);
        return {
            common: css`
                padding-top: var(--padding-vertical);
                padding-bottom: var(--padding-vertical);
            `,
            hover: css`
                color: ${label.tertiaryActive};
            `,
            active: css<TabsListItemProps>`
                color: ${color};
            `,
            // activeFocused: css<TabsListItemProps>`
            //     color: ${color};
            //     outline: none;
            //     border: none;
            //     box-shadow: inset 0 0 0 3px
            //         ${fillIn.primary.overlay24};
            // `,
            // inactiveFocused: css`
            //     outline: none;
            //     border: none;
            //     box-shadow: inset 0 0 0 3px
            //         ${fillIn.primary.overlay32};
            // `,
        };
    },
    sizeMap: {
        large: css`
            ${buttonLargeFontStyle};
            --tab-padding-vertical: 8px;
            --padding-vertical: 8px;
            --padding-horizontal: 16px;
            --padding-icon-compensation: 4px;
            --divider-width: 3px;
        `,
        medium: css`
            ${buttonMediumFontStyle};
            --tab-padding-vertical: 6px;
            --padding-vertical: 6px;
            --padding-horizontal: 8px;
            --padding-icon-compensation: 2px;
            --divider-width: 3px;
        `,
        small: css`
            ${buttonSmallFontStyle};
            --tab-padding-vertical: 4px;
            --padding-vertical: 4px;
            --padding-horizontal: 8px;
            --padding-icon-compensation: 2px;
            --divider-width: 2px;
        `,
    },
    tabsContainerStyles: css`
        width: 100%;
    `,
};
