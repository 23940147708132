import styled, { css } from 'styled-components';

import { labelPrimary, matchBreakpoints, mediaQuery, reduceBreakpoints, ResponsiveMap } from 'app/styled';
import { body14RegularFontStyle, body17RegularFontStyle, h4MediumFontStyle } from 'services/typography';
import { Views, WtField } from 'components/wt-field';

export type FormItemVisualState = 'normal' | 'emergency';

export const Gap20FormVLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${mediaQuery.lt768} {
        gap: 16px;
    }
`;

export const Gap12FormVLayout = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Gap12FormHLayout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;

    ${mediaQuery.lt768} {
        flex-direction: column;
    }
`;

export const FormLabel = styled.label`
    --text-color: ${labelPrimary};

    ${body17RegularFontStyle}

    color: var(--text-color);
    margin-bottom: 12px;
`;

export const FormItemMessage = styled.label<{ visualState?: FormItemVisualState }>`
    ${({ visualState = 'normal', theme }) =>
        ({
            normal: css`
                --text-color: ${theme.label.secondary};
            `,
            emergency: css`
                --text-color: ${theme.label.negative};
            `,
        }[visualState])}

    font-style: normal;
    font-weight: normal;
    white-space: pre-wrap;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-color);
    margin-top: 0;
`;

export const FormField = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    ${FormItemMessage} {
        margin-top: 4px;
    }
`;

export const VerticalFormInputGroup = styled.div<{ gap?: ResponsiveMap<number> }>`
    display: flex;
    flex-direction: column;
    ${({ gap }) =>
        reduceBreakpoints(gap || { default: 12 }, (gapValue) => `gap: ${gapValue}px;`)}
`;

export const HorizontalFormInputGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    ${mediaQuery.lt768} {
        flex-direction: column;
        align-items: stretch;
    }

    gap: 12px;
`;

export const FormTextInput = styled(WtField).attrs(({ tag }) => ({ view: Views.styled2, tag: tag || 'input' }))``;

export const FormTextArea = styled(FormTextInput).attrs({ view: Views.styled2, tag: 'textarea' })``;

export const FormSectionTitle = styled.h4`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.014em;
    color: ${labelPrimary};
    margin: 9px 0 16px 0;
    padding: 0;
`;

export const FormSectionHeadline = styled.h4`
    ${h4MediumFontStyle};
    color: ${({ theme }) => theme.label.primary};
    margin-bottom: 0;
    margin-top: 0;
`;

export const FormDescription = styled.p`
    ${body14RegularFontStyle};
    color: ${({ theme }) => theme.label.primary};
    margin-bottom: 0;
    margin-top: 20px;

    ${mediaQuery.lt768} {
        margin-top: 16px;
    }
`;
