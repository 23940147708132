import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import { mediaQuery, labelSecondary } from 'app/styled';
import { caption12MediumFontStyle } from 'services/typography';
import IconedText2 from 'components/iconed-text2';

export const CoverWrapper = styled.div<{ isOwner: boolean; cover: string | null | undefined }>`
    width: 1320px;
    height: 320px;
    background-color: ${({ theme }) => theme.bg.tertiary};
    -webkit-tap-highlight-color: transparent;
    ${ifProp(
        'cover',
        css`
            background-image: url(${prop('cover')});
            display: flex;
        `,
        css`
            display: ${ifProp('isOwner', 'flex', 'none')};
            cursor: ${ifProp('isOwner', 'pointer')};
        `,
    )}
    background-size: cover;
    border-radius: 8px;
    justify-content: center;
    position: relative;
    ${mediaQuery.lt1400} {
        width: 1132px;
        height: 274px;
    }
    ${mediaQuery.lt1200} {
        width: 944px;
        height: 230px;
    }
    ${mediaQuery.lt960} {
        width: 100%;
        height: 188px;
        border-radius: 0;
    }
    ${mediaQuery.lt480} {
        height: 80px;
    }
`;

export const ContextActionsWrapper = styled.div`
    position: absolute;
    right: 16px;
    top: 12px;
    display: none;
    ${mediaQuery.lt768} {
        display: inline-flex;
    }
`;

export const AddCoverWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const AddCover = styled(IconedText2)`
    display: flex;
    align-items: center;
    color: ${labelSecondary};
    ${caption12MediumFontStyle};
`;

export const AddCoverText = styled.span`
    ${mediaQuery.lt768} {
        display: none;
    }
`;
