import React, { FC, useEffect, useMemo, useRef, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FileType } from 'types';
import type { CancelCallback } from 'services/backend-client';
import { useAttachment } from 'hooks/useAttachment';
import { useCSRFToken } from 'hooks/useCSRFToken';
import { useDictionary } from 'hooks/useDictionary';
import { ImageUpload } from 'components/image-upload';

import type { CoverFieldProps } from './types';
import { getErrorMessage } from './getErrorMessage';
import { deleteAttachment, uploadAttachment } from './api';

export const CoverField: FC<CoverFieldProps> = memo(
    ({
        name,
        infoText,
        productImageType = FileType.SHOP_PRODUCT_COVER,
        rules,
        hideDelete,
        isMobile,
        buttonContainer,
        editButtonText,
    }) => {
        const dic = useDictionary();
        const csrf = useCSRFToken();

        const {
            field: { value, onChange },
            fieldState: { error: fieldError },
        } = useController({
            name,
            rules,
        });

        const { setError, clearErrors, trigger } = useFormContext();

        const cancelFnRef = useRef<(() => void) | undefined>();

        const setCancelFn: CancelCallback = (cancel) => {
            cancelFnRef.current = cancel;
        };

        const { state, setState, actions } = useAttachment({
            uploadFn: (file) => uploadAttachment({ file }, productImageType, csrf, setCancelFn),
            editFn: (input) => uploadAttachment(input, productImageType, csrf, setCancelFn),
            removeFn: (attachment) => deleteAttachment(attachment, setCancelFn),
            cancelFn: () => {
                cancelFnRef.current?.();
            },
            initialAttachment: value,
        });

        const actionsWithClearError = useMemo(
            () => ({
                ...actions,
                cancel: () => {
                    setState((oldState) => ({ ...oldState, error: undefined }));
                    actions.cancel();
                    clearErrors(name);
                    trigger(name);
                },
            }),
            [actions, setState, clearErrors, name, trigger],
        );

        const { data, error, isSuccess } = state;

        const message = getErrorMessage(dic, error);

        useEffect(() => {
            if (message) {
                setError(name, { message });
            } else {
                if (fieldError) {
                    clearErrors(name);
                    trigger(name);
                }
                setState((oldState) => ({ ...oldState, error: undefined }));
            }
        }, [message]);

        useEffect(() => {
            if (isSuccess) {
                onChange(data);
                trigger(name);
            }
        }, [onChange, data, isSuccess]);

        useEffect(() => {
            setState((oldState) => {
                if (oldState.data?.id !== value?.id) {
                    return { ...oldState, data: value };
                }
                return oldState;
            });
        }, [value]);

        return (
            <ImageUpload
                state={state}
                actions={actionsWithClearError}
                infoText={infoText}
                hideDelete={hideDelete}
                isMobile={isMobile}
                buttonContainer={buttonContainer}
                editButtonText={editButtonText}
            />
        );
    },
);
