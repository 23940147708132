import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Action } from 'redux';

import { AnyObject, Func } from 'types';
import { ApiError } from 'services/api-services/generated_limex/core/ApiError';

import { createRequestActionTypes } from '../shared/createRequestActionTypes';

import {
    OnCancel,
    ServiceFailureAction,
    ServiceRequestAction,
    ServiceSuccessAction,
    ServiceThunkAction,
} from './types';

export const createThunkActions =
    <PREFIX extends string, STATE extends AnyObject>(defaultPrefix: PREFIX) =>
    <ApiMethod extends Func, TYPE extends string>(type: TYPE, method: ApiMethod, prefix: PREFIX = defaultPrefix) =>
    <PASS extends AnyObject, EXTRA_ACTIONS extends Action = never>() => {
        const types = createRequestActionTypes(type, prefix);

        const getRequestAction = (
            params: Parameters<ApiMethod>[0],
            pass: PASS,
        ): ServiceRequestAction<ApiMethod, TYPE, PREFIX, PASS> => ({
            type: types[0],
            payload: {
                method,
                params,
                pass,
            },
        });

        const getSuccessAction = (
            requestAction: ServiceRequestAction<ApiMethod, TYPE, PREFIX, PASS>,
            data: Awaited<ReturnType<ApiMethod>>,
            pass: PASS,
        ): ServiceSuccessAction<ApiMethod, TYPE, PREFIX, PASS> => ({
            type: types[1],
            payload: {
                data,
                pass,
            },
            meta: {
                requestAction,
            },
        });

        const getFailureAction = (
            requestAction: ServiceRequestAction<ApiMethod, TYPE, PREFIX, PASS>,
            error: ApiError,
            pass: PASS,
        ): ServiceFailureAction<ApiMethod, TYPE, PREFIX, PASS> => ({
            type: types[2],
            payload: {
                error,
                pass,
            },
            meta: { requestAction },
        });

        type ThunkArgs = {
            params: Parameters<ApiMethod>[0];
            pass?: PASS;
            onCancel?: OnCancel;
        };

        function thunk(args: ThunkArgs): ServiceThunkAction<ApiMethod, TYPE, PREFIX, PASS, STATE, EXTRA_ACTIONS> {
            const { params, pass = {} as PASS, onCancel } = args;

            return (dispatch) => {
                const requestAction = getRequestAction(params, pass);
                dispatch(requestAction);
                const promise = method(params);

                onCancel?.(() => {
                    promise.cancel();
                });

                return promise
                    .then((data: Awaited<ReturnType<ApiMethod>>) => {
                        const successAction = getSuccessAction(requestAction, data, pass);
                        dispatch(successAction);
                        return data;
                    })
                    .catch((error: ApiError) => {
                        Sentry.captureException(error);
                        const failureAction = getFailureAction(requestAction, error, pass);
                        if (!axios.isCancel(error)) {
                            dispatch(failureAction);
                            throw error;
                        }
                    });
            };
        }

        return [types, thunk, [getRequestAction, getSuccessAction, getFailureAction] as const] as const;
    };

export type { Actions } from '../shared/types';
