import type { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AxiosError } from 'axios';

import type { AnyObject } from 'types';
import { ApiError } from 'services/api-services/generated_marketplace/core/ApiError';

import { AsyncMethod, GlobalStateForCache } from '../types';

import { OnErrorCallback, OnFulfilledCallback, OnRequestCallback, RequestOptions, RequestThunk, Thunk } from './types';

export function createMutation<API_METHOD extends AsyncMethod>({
    thunk,
    onRequest,
    onFulfilled,
    onError,
}: {
    thunk: Thunk<API_METHOD>;
    onRequest?: OnRequestCallback<API_METHOD>;
    onFulfilled?: OnFulfilledCallback<API_METHOD>;
    onError?: OnErrorCallback<API_METHOD>;
}): RequestThunk<API_METHOD, GlobalStateForCache, undefined> {
    return (
            args: Parameters<API_METHOD>[0] = {},
            options?: RequestOptions,
        ): ThunkAction<
            Promise<Awaited<ReturnType<API_METHOD>> | undefined>,
            GlobalStateForCache,
            AnyObject,
            AnyAction
        > =>
        (dispatch, getState, extraArgument) => {
            const mutationId = window.crypto.randomUUID();
            const onRequestCheck = onRequest?.({ args, dispatch, getState, mutationId });
            if (onRequestCheck === false) {
                return Promise.resolve(undefined);
            }
            return thunk({
                params: args,
                pass: {
                    requestName: options?.requestName,
                    operationId: mutationId,
                },
            })(dispatch, getState, extraArgument)
                .then((result) => {
                    onFulfilled?.({ args, result, dispatch, getState });
                    return result;
                })
                .catch((error: ApiError | AxiosError) => {
                    onError?.({
                        args,
                        error: {
                            code: error instanceof ApiError ? `${error.status}` : error.code,
                            message: error.message,
                        },
                        dispatch,
                        getState,
                    });
                    throw error;
                });
        };
}
