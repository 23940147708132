import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ArrowPentagonRight: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5641 6.6H4.39492L4.39492 17.4H16.5641L21.6059 12L16.5641 6.6ZM4.39492 5C3.51127 5 2.79492 5.71635 2.79492 6.6V17.4C2.79492 18.2837 3.51127 19 4.39492 19H16.5641C17.0075 19 17.431 18.816 17.7336 18.4919L22.7754 13.0919C23.3494 12.4771 23.3494 11.5229 22.7754 10.9081L17.7336 5.50808C17.431 5.18399 17.0075 5 16.5641 5H4.39492Z"
        />
    </IconSvg>
);
