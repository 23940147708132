import styled, { css } from 'styled-components';
import { theme as themeStyledTool } from 'styled-tools';

import { body14RegularFontStyle } from 'services/typography';

export const SwitchInput = styled.input`
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    width: 40px;
    height: 22px;
    margin: 0;
    vertical-align: top;
    background: ${themeStyledTool('fillIn.secondary.low')};
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.1s;

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 3px;
        top: 3px;

        width: 16px;
        height: 16px;
        background-color: ${themeStyledTool('staticColors.white')};
        border-radius: 50%;

        transform: translateX(0);
        transition: all 0.1s;
    }

    &:checked::after {
        transform: translateX(calc(100% + 2px));
    }

    &:checked {
        background-color: ${themeStyledTool('fillIn.primary.mid')};
    }

    &:focus {
        box-shadow: ${themeStyledTool('focusOutline.accent')};
    }

    &:disabled {
        background-color: ${themeStyledTool('fillIn.secondary.overlay32')};
        user-select: none;
        cursor: default;
    }
`;

export const SwitchLabel = styled.label`
    ${body14RegularFontStyle}
    ${({ theme }) => css`
        color: ${theme.label.primary};
    `}

    margin-left: 16px;
`;

export const LabeledSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
