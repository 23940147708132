import { useCallback, useEffect } from 'react';

import { StatsResult, FeedRecordSource, Id, FeedRecord } from 'types';
import { socket } from 'services/webscokets';
import { sendGA } from 'services/analytics-outside/google-analytics';

import statsViewedProcessor from '../services/stats-viewed';

const sendMethod = (userId: Id, userSign: string, source: FeedRecordSource, stats: StatsResult) => {
    const data = {
        personId: userId,
        ps: userSign,
        method: 'recordStats',
        params: { source, stats },
    };
    socket.publish(data);
    sendGA('feedRecords', ['send-stats', JSON.stringify({ source, stats })]);
};

const getNumberId = (id: Id): number | null => {
    if (typeof id === 'number') {
        return id;
    }

    if (/^\d+$/.test(`${id}`)) {
        return parseInt(`${id}`, 10);
    }

    return null;
};

export const useStats = (
    { userId, userSign, source }: { userId: Id; userSign: string; source: FeedRecordSource },
    isAuth: boolean,
) => {
    useEffect(() => {
        if (!isAuth) {
            return () => {
                // nothing
            };
        }
        statsViewedProcessor.startSend((data: StatsResult) => {
            sendMethod(userId, userSign, source, data);
        });
        return () => statsViewedProcessor.stopSend();
    }, [isAuth]);

    return {
        onIntersection: useCallback(
            (item: IntersectionObserverEntry, record: FeedRecord) => {
                if (!isAuth) {
                    return;
                }

                const id = getNumberId(record.id);

                if (id !== null) {
                    if (item.isIntersecting) {
                        statsViewedProcessor.add(id);
                    } else {
                        statsViewedProcessor.delete(id);
                    }
                }
            },
            [isAuth],
        ),
        onRecordShowFull: useCallback(
            (recordId: Id) => {
                if (!isAuth) {
                    return;
                }

                const id = getNumberId(recordId);

                if (id !== null) {
                    statsViewedProcessor.onRecordShowFull(id);
                }
            },
            [isAuth],
        ),
    };
};
