import thunk from 'redux-thunk';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import set from 'lodash/set';
import get from 'lodash/get';
import {
    applyMiddleware,
    combineReducers,
    compose,
    createStore,
} from 'redux';

const reducers = {
    widgets: {},
};

const composeEnhancers = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    raceLimit: 55,
}) : compose;

export const listenerMiddleware = createListenerMiddleware();

const store = createStore(
    () => {},
    composeEnhancers(applyMiddleware(thunk, listenerMiddleware.middleware)),
);

export default store;

export const injectReducer = (path, reducer) => {
    if (get(reducers, path)) {
        return;
    }

    set(reducers, path, reducer);
    const combinedByTypes = Object.keys(reducers).reduce((result, type) => {
        if (typeof reducers[type] === 'object' && !Object.keys(reducers[type]).length) return result;

        return ({ ...result, [type]: typeof reducers[type] === 'function' ? reducers[type] : combineReducers(reducers[type]) });
    }, {});
    store.replaceReducer(combineReducers(combinedByTypes));
};
