import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle, body16RegularFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';

export const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const DisclosureText = styled.div`
    ${body16RegularFontStyle};
    ${mediaQuery.lt480} {
        ${body14RegularFontStyle}
    }
`;

export const ContentWrapper = styled.div<{ $height?: string }>`
    height: ${({ $height }) => $height ?? '420px'};
    ${mediaQuery.lt768} {
        height: auto;
    }
`;

export const ErrorButtonText = styled.div`
    color: ${tokens.colors.text.onpositive.default.default};
`;
