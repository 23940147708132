import React, { FC } from 'react';

import { MenuItemProps } from 'components/menu-item';

import { FavoriteButton, FavoriteButtonProps } from '../../partials/favorite-button';
import { CardContextActions } from '../../partials/context-actions';

import * as Styled from './styled';

const DEFAULT_PARAMS_FAVORITE_BTN: FavoriteButtonProps = {
    kind: 'staticColor',
};

type ThumbnailActionsBlockProps = {
    favoriteBtn?: Omit<FavoriteButtonProps, 'className'>;
    contextActions?: {
        items: MenuItemProps[];
    };
};

export const ThumbnailActionsBlock: FC<ThumbnailActionsBlockProps> = ({ favoriteBtn, contextActions }) => {
    const hasFavoriteButton = typeof favoriteBtn === 'object' && Object.keys(favoriteBtn)?.length > 0;
    const favoriteButtonProps = hasFavoriteButton && { ...DEFAULT_PARAMS_FAVORITE_BTN, ...favoriteBtn };

    return hasFavoriteButton || contextActions ? (
        <Styled.CornerButtonsWrapper>
            {hasFavoriteButton ? <FavoriteButton {...favoriteButtonProps} /> : null}
            {contextActions ? <CardContextActions {...contextActions} /> : null}
        </Styled.CornerButtonsWrapper>
    ) : null;
};
