import { getCurrentUser } from 'services/wt-page-helper';
import { backendClient } from 'services/backend-client';

import { isJustRegistered } from './constants';

export const makeLogin = async (token: string) => {
    const currentUser = await getCurrentUser();
    if (currentUser?.isAuth) return;
    await backendClient.post(
        '/ng/user/kratos/authenticate/',
        {},
        {
            headers: {
                'X-Auth-Token': token,
            },
        },
    );
    if (!isJustRegistered()) {
        window.location.reload();
    }
};
