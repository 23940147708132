/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioOptimizationObjectivesWebRequest } from '../models/AiPortfoliosService/Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioOptimizationObjectivesWebRequest';
import type { Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioPreferencesWebRequest } from '../models/AiPortfoliosService/Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioPreferencesWebRequest';
import type { Marketplace_Web_Public_V1_My_Portfolios_CreateAIPortfolioWebRequest } from '../models/AiPortfoliosService/Marketplace_Web_Public_V1_My_Portfolios_CreateAIPortfolioWebRequest';
import type { Marketplace_Web_Public_V1_My_Portfolios_GenerateAIPortfolioStructureWebRequest } from '../models/AiPortfoliosService/Marketplace_Web_Public_V1_My_Portfolios_GenerateAIPortfolioStructureWebRequest';
import type { Marketplace_Web_Public_V1_My_Portfolios_UpdateAIPortfolioWebRequest } from '../models/AiPortfoliosService/Marketplace_Web_Public_V1_My_Portfolios_UpdateAIPortfolioWebRequest';
import type { WebResponse_IReadOnlyList_LeanPortfolioDto } from '../models/AiPortfoliosService/WebResponse_IReadOnlyList_LeanPortfolioDto';
import type { WebResponse_LeanPortfolioDto } from '../models/AiPortfoliosService/WebResponse_LeanPortfolioDto';
import type { WebResponse_SetAIPortfolioStatusesResponse } from '../models/AiPortfoliosService/WebResponse_SetAIPortfolioStatusesResponse';
import type { WebResponse_SetAIPortfolioStructureResponse } from '../models/AiPortfoliosService/WebResponse_SetAIPortfolioStructureResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AiPortfoliosService {
    /**
     * Проставить статус AI-портфелям, находящимся в статусе InProgress (информация синхронизируется из BoostedAI)
     * @returns WebResponse_SetAIPortfolioStatusesResponse Success
     * @throws ApiError
     */
    public static updateAiPortfoliosSyncStatus(): CancelablePromise<WebResponse_SetAIPortfolioStatusesResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/ai-portfolios/sync/status',
        });
    }

    /**
     * Проставить структурные свойства AI-портфелям, находящимся в статусе Ready (информация синхронизируется из BoostedAI)
     * @returns WebResponse_SetAIPortfolioStructureResponse Success
     * @throws ApiError
     */
    public static updateAiPortfoliosSyncStructure({
        action,
    }: {
        action?: 'InitialSet' | 'Update';
    }): CancelablePromise<WebResponse_SetAIPortfolioStructureResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/ai-portfolios/sync/structure',
            query: {
                Action: action,
            },
        });
    }

    /**
     * Получить все AI-портфели инструментов, созданные текущим пользователем
     * @returns WebResponse_IReadOnlyList_LeanPortfolioDto Success
     * @throws ApiError
     */
    public static fetchMyAiPortfolios(): CancelablePromise<WebResponse_IReadOnlyList_LeanPortfolioDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/ai-portfolios',
        });
    }

    /**
     * Создать черновик AI-портфеля инструментов
     * Можно создавать черновик AI-портфеля, у которого не заданы никакие атрибуты.
     *
     * Если атрибуты портфеля не были заданы при создании, то их можно будет задать позднее (через PUT-метод), но сделать это необходимо до запуска процесса генерации набора инструментов портфеля
     * @returns WebResponse_LeanPortfolioDto Success
     * @throws ApiError
     */
    public static createAiPortfolioDraft({
        requestBody,
    }: {
        /**
         * Запрос на создание AI-портфеля
         */
        requestBody?: Marketplace_Web_Public_V1_My_Portfolios_CreateAIPortfolioWebRequest;
    }): CancelablePromise<WebResponse_LeanPortfolioDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/ai-portfolios',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Обновить основные атрибуты (Имя, Описание и Вселенная рыночных инструментов) AI-портфеля инструментов
     * @returns WebResponse_LeanPortfolioDto Success
     * @throws ApiError
     */
    public static updateAiPortfolioDraft({
        portfolioId,
        requestBody,
    }: {
        /**
         * Идентификатор AI-портфеля
         */
        portfolioId: number;
        /**
         * Запрос на обновление основных атрибутов AI-портфеля
         */
        requestBody?: Marketplace_Web_Public_V1_My_Portfolios_UpdateAIPortfolioWebRequest;
    }): CancelablePromise<WebResponse_LeanPortfolioDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/ai-portfolios/{portfolioId}',
            path: {
                portfolioId: portfolioId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Удалить AI-портфель инструментов
     * @returns WebResponse_LeanPortfolioDto Success
     * @throws ApiError
     */
    public static deleteAiPortfolio({
        portfolioId,
    }: {
        /**
         * Идентификатор AI-портфеля
         */
        portfolioId: number;
    }): CancelablePromise<WebResponse_LeanPortfolioDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/my/ai-portfolios/{portfolioId}',
            path: {
                portfolioId: portfolioId,
            },
        });
    }

    /**
     * Запустить процесс генерации набора инструментов AI-портфеля
     * @returns WebResponse_LeanPortfolioDto Success
     * @throws ApiError
     */
    public static generateAiPortfolio({
        portfolioId,
        requestBody,
    }: {
        /**
         * Идентификатор AI-портфеля
         */
        portfolioId: number;
        /**
         * Запрос на генерацию набора инструментов AI-портфеля
         */
        requestBody?: Marketplace_Web_Public_V1_My_Portfolios_GenerateAIPortfolioStructureWebRequest;
    }): CancelablePromise<WebResponse_LeanPortfolioDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/ai-portfolios/{portfolioId}/generate',
            path: {
                portfolioId: portfolioId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Провалидировать запрос с настройками генерации набора инструментов AI-портфеля
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static validatePreferencesAiPortfolioDraft({
        requestBody,
    }: {
        /**
         * Запрос с настройками генерации набора инструментов AI-портфеля
         */
        requestBody?: Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioPreferencesWebRequest;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/ai-portfolios/validate/preferences',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Провалидировать запрос с настройками оптимизации AI-портфеля
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static validateOptimizationAiPortfolioDraft({
        requestBody,
    }: {
        /**
         * Запрос с настройками оптимизации AI-портфеля
         */
        requestBody?: Marketplace_Web_Public_V1_My_Portfolios_AIPortfolioOptimizationObjectivesWebRequest;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/my/ai-portfolios/validate/optimization',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }
}
