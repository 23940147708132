import { connect } from 'react-redux';

import { DispatchType } from 'types';
import { FinamProductItemProductType } from 'types/finam-product';
import { ResourcesState } from 'services/mp-share-resources/types';
import { api } from 'services/api-builder/api';

import { ProductWizard } from './components/wizard';

const mapStateToProps = (state: ResourcesState) => ({
    ...api.selectAccountsForNewStrategy(state),
    ...api.selectProductWizardCreateProductState(state),
    ...api.selectProductWizardCreateCopyTradingStrategyState(state),
    ...api.selectProductWizardCreateSignalStrategyState(state),
});

const mapDispatchToProps = {
    checkProfanity: api.fetchCheckProfanity,
    createProduct: api.productWizardCreateProduct,
    createCopyTradingStrategy: api.productWizardCreateCopyTradingStrategy,
    createSignalStrategy: api.productWizardCreateSignalStrategy,
    getAgreements: api.fetchAgreements,
    createAgreement: api.createAgreement,
    createSubscriberAgreement: api.createSubscriberAgreement,
    updateAgreementStatus: api.updateAgreementStatus,
    getAccountsForNewStrategy: api.fetchAccountsForNewStrategy,
};

type OwnProps = {
    isShown: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    className?: string;
    productType?: FinamProductItemProductType;
};

export type ProductWizardProps = ReturnType<typeof mapStateToProps> &
    DispatchType<typeof mapDispatchToProps> &
    OwnProps;

export const ProductWizardContainer = connect(mapStateToProps, mapDispatchToProps)(ProductWizard);
