import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body16SemiboldFontStyle, caption12RegularFontStyle } from 'services/typography';

import { Wrapper as PeopleListWrapper } from './PeopleList';

export const Wrapper = styled(PeopleListWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    ${mediaQuery.lt768} {
        margin: auto;
    }
`;

export const Title = styled.div`
    ${body16SemiboldFontStyle};
`;

export const Text = styled.div`
    ${caption12RegularFontStyle};
    color: ${({ theme }) => theme.label.tertiary};
    margin-top: 4px;
    margin-bottom: 20px;
    max-width: 260px;
    text-align: center;
    white-space: pre-wrap;
    ${mediaQuery.lt768} {
        max-width: 198px;
    }
`;
