import { useCallback } from 'react';

import { loadScript } from 'services/utils/scriptLoader';
import { cookiePrivacySettings } from 'services/cookie-privacy-settings';
import { usePrevious } from 'hooks/usePrevious';

import { useShowFull } from '../feed/hooks/useShowFull';

const TIKTOK_EMBED_URL = 'https://www.tiktok.com/embed.js';
const TIKTOK_REGEX = new RegExp(`src=['"]${TIKTOK_EMBED_URL}['"]`);

type Args = {
    content: string;
};

export const useTiktok = ({ content }: Args) => {
    const { showFull } = useShowFull();

    const prevShowFull = usePrevious(showFull);

    const isInitFunctionalCookies = cookiePrivacySettings.isInitSetting('functionalCookies');

    return useCallback(() => {
        if (content.match(TIKTOK_REGEX)) {
            if (isInitFunctionalCookies) {
                // arb 8.02.23: скрипт тиктока не умеет работать с динамически появляющимся контентом
                // поэтому если из-за showFull теги <blockquote class="tiktok-embed"> отстутсвовали
                // то после их пояления надо заново загрузить скрипт
                loadScript(TIKTOK_EMBED_URL, { forceLoad: prevShowFull === false && showFull });
            } else {
                setTimeout(() => {
                    const elements = [...document.getElementsByClassName('tiktok-embed')];
                    elements.forEach((blockquoteElement) => {
                        const attr = blockquoteElement.getAttribute('cite');
                        if (attr) {
                            blockquoteElement.innerHTML = attr;
                        }
                    });
                }, 10);
            }
        }
    }, [content, showFull, prevShowFull, isInitFunctionalCookies]);
};
