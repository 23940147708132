import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Person } from 'types';
import { mediaQuery } from 'app/styled';
import { TODOuseActionAfterAuthentication } from 'hooks/useActionAfterAuthentication';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { ActionBlacklistContainer, BlacklistRenderProps } from 'widgets/action-blacklist';
import { ShareWidget } from 'widgets/share';
import { EllipsisVIcon } from 'components/icons2/EllipsisVIcon';
import { RoundButton } from 'components/round-button';
import { ContextActions as GenericContextActions } from 'components/context-actions';

import { useIsOwner, usePageRoot } from '../../../hooks';
import { ComplaintPopup } from '../../../popup/ComplaintPopup';
import { BlacklistPopup } from '../../../popup/BlacklistPopup';
import { ContextPopup } from '../../../popup/ContextPopup';
import { Complain } from '../../../page/actions';
import { useToggleBlacklist } from '../../../page/hooks';

import { ActionShare } from './ActionShare';
import { ActionBlacklist } from './ActionBlacklist';
import { ActionComplaint } from './ActionComplaint';
import { ActionSettings } from './ActionSettings';
import * as Styled from './styled';

type Props = {
    user: Person;
    settingsUrl: string;
    complain: Complain;
};

export const ContextActions = (props: Props) => {
    const {
        user: { id, displayName = '', images, isBlockedByCurrentUser = false, shareUrl },
        settingsUrl,
        complain,
    } = props;
    const [shownPopup, setShownPopup] = useState<'blacklist' | 'complaint' | 'context' | 'share' | ''>('');
    const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);
    const handleTooltipVisibleChange = useCallback(
        ({ isVisible }: { isVisible: boolean }) => {
            setIsContextMenuOpened(isVisible);
        },
        [setIsContextMenuOpened],
    );
    const [hasCloseButton, setHasCloseButton] = useState(true);
    const handleToggleBlacklist = useToggleBlacklist(isBlockedByCurrentUser);
    const openContextPopup = () => {
        setHasCloseButton(false);
        setShownPopup('context');
    };
    const handleBlacklistClick = TODOuseActionAfterAuthentication(
        (toggleBlacklist: BlacklistRenderProps['toggleBlacklist']) =>
            isBlockedByCurrentUser ? handleToggleBlacklist(toggleBlacklist) : setShownPopup('blacklist'),
    );
    const handleComplaintClick = TODOuseActionAfterAuthentication(() => {
        setHasCloseButton(true);
        setShownPopup('complaint');
    });
    const closePopup = () => {
        setHasCloseButton(true);
        setShownPopup('');
    };
    const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();
    const isOwner = useIsOwner();
    const pageRoot = usePageRoot();
    const customRenderForShareWidget = useCallback(({ openPopup }) => <ActionShare onClick={openPopup} />, []);
    const customRenderForActionBlackListContainer = useCallback(
        ({ toggleBlacklist }) => (
            <ActionBlacklist
                isBlockedByCurrentUser={isBlockedByCurrentUser}
                onClick={() => handleBlacklistClick(toggleBlacklist)}
            />
        ),
        [isBlockedByCurrentUser, handleBlacklistClick],
    );
    const actions = useMemo(
        () => (
            <>
                <Styled.ShareWidgetWrapper>
                    <ShareWidget
                        url={shareUrl}
                        title={displayName}
                        description={displayName}
                        image={images?.big ? `https:${images.base}${images.big}` : `https:${images?.medium}`}
                        portalTo={pageRoot}
                        customRender={customRenderForShareWidget}
                        onClose={closePopup}
                    />
                </Styled.ShareWidgetWrapper>
                {!isOwner && (
                    <ActionBlacklistContainer
                        personId={id}
                        isInBlacklist={isBlockedByCurrentUser}
                        customRender={customRenderForActionBlackListContainer}
                    />
                )}
                {!isOwner && <ActionComplaint onClick={handleComplaintClick} />}
                {isOwner && <ActionSettings settingsUrl={settingsUrl} />}
            </>
        ),
        [
            shareUrl,
            displayName,
            isOwner,
            id,
            isBlockedByCurrentUser,
            handleComplaintClick,
            settingsUrl,
            handleBlacklistClick,
        ],
    );

    const isMobile = useMatchMedia(mediaQuery.lt768);

    const desktopContextActions = useMemo(() => {
        if (!pageRoot || isMobile) return null;
        return (
            <GenericContextActions
                tippyContent={actions}
                placement="bottom-start"
                onVisibleChange={handleTooltipVisibleChange}
                appendTo={pageRoot}
                interactive
                buttonClassName="autotest__profile-page__header-context-menu"
            />
        );
    }, [pageRoot, isMobile, actions, handleTooltipVisibleChange]);

    useEffect(() => {
        if (!isMobile && shownPopup === 'context') {
            setShownPopup('');
        }
    }, [isMobile, shownPopup]);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <Styled.ContextActionsWidget onClick={stopPropagation}>
            {desktopContextActions}
            {isMobile && (
                <RoundButton size="compact" kind="staticColor" onClick={openContextPopup}>
                    <EllipsisVIcon />
                </RoundButton>
            )}
            {shownPopup && (
                <>
                    {shownPopup === 'context' && <ContextPopup close={closePopup}>{actions}</ContextPopup>}
                    {shownPopup === 'blacklist' && (
                        <BlacklistPopup
                            userId={id}
                            isBlockedByCurrentUser={isBlockedByCurrentUser}
                            displayName={displayName}
                            closePopup={closePopup}
                        />
                    )}
                    {shownPopup === 'complaint' && (
                        <ComplaintPopup complain={complain} suspectPersonId={id} closePopup={closePopup} />
                    )}
                </>
            )}
        </Styled.ContextActionsWidget>
    );
};
