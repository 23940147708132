import React from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { WtPopup3 } from 'components/wt-popup3';

import { ContentWrapper, DisclosureText } from './styled';

type Props = {
    isLoading: boolean;
    onDisclosureDone: () => void;
    close: () => void;
};

export const DisclosurePopup = (props: Props) => {
    const dic = useDictionary();
    const { isLoading, onDisclosureDone, close } = props;
    return (
        <WtPopup3
            portalTo="popupRoot"
            width={456}
            close={close}
            isMobileFullscreen
            actionButtons={{
                items: [
                    {
                        key: 0,
                        content: dic.word('wt_mplc2__widget_brokerage_popup__disclosure_btn'),
                        kind: 'primary',
                        isProcessing: isLoading,
                        onClick: onDisclosureDone,
                    },
                ],
                direction: 'vertical',
            }}
            title={dic.word('wt_mplc2__widget_brokerage_popup__disclosure_title')}
        >
            <ContentWrapper>
                <DisclosureText>{dic.word('wt_mplc2__widget_brokerage_popup__disclosure_content')}</DisclosureText>
            </ContentWrapper>
        </WtPopup3>
    );
};
