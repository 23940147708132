import produce from 'immer';
import uniq from 'lodash/uniq';

import { recordStoreIndex } from 'services/utils/map-records-by-id';

import { FeedState } from '..';

import { GetSocialActivityListActions, GET_SOCIAL_ACTIVITY_LIST_ACTION_TYPES } from './actions/fetchSocialActivity';

const [GET_SOCIAL_ACTIVITY_LIST_REQUEST, GET_SOCIAL_ACTIVITY_LIST_SUCCESS, GET_SOCIAL_ACTIVITY_LIST_FAILURE] =
    GET_SOCIAL_ACTIVITY_LIST_ACTION_TYPES;

export const socialActivityReducer = (state: FeedState, action: GetSocialActivityListActions): FeedState => {
    switch (action.type) {
        case GET_SOCIAL_ACTIVITY_LIST_REQUEST: {
            const {
                payload: {
                    pass: { instanceId, socialTypes, reset },
                },
            } = action;
            return produce(state, (draft) => {
                if (draft.instances[instanceId]) {
                    draft.instances[instanceId].isLoading = true;
                } else {
                    draft.instances[instanceId] = {
                        boundary: '',
                        boundaryRecordId: '',
                        isLoading: true,
                        ids: [],
                        deletedIds: [],
                    };
                }
                if (socialTypes) {
                    draft.instances[instanceId].socialTypes = socialTypes;
                }
                if (reset) {
                    draft.instances[instanceId].wasLastRequest = false;
                }
            });
        }
        case GET_SOCIAL_ACTIVITY_LIST_SUCCESS: {
            const {
                data: { items: records, nextCursor },
                pass: { instanceId, reset },
            } = action.payload;
            const { limit } = action.meta.requestAction.payload.params;

            return produce(state, (draft) => {
                records.forEach((record) => {
                    const id = recordStoreIndex(record.id);
                    draft.records[id] = record;
                });
                const instance = draft.instances[instanceId];
                if (reset) {
                    instance.ids = [];
                }
                instance.ids.push(...records.map(({ id }) => recordStoreIndex(id)));
                instance.ids = uniq(instance.ids);
                instance.boundary = nextCursor;
                instance.isLoading = false;
                instance.wasLastRequest = limit === undefined ? records.length === 0 : records.length < limit;
            });
        }
        case GET_SOCIAL_ACTIVITY_LIST_FAILURE: {
            return produce(state, (draft) => {
                const {
                    pass: { instanceId },
                    error,
                } = action.payload;
                const instance = draft.instances[instanceId];
                instance.isLoading = false;
                if (error.name !== 'CancelError') {
                    instance.error = error;
                }
            });
        }
        default:
            return state;
    }
};
