import React, { useMemo, useState } from 'react';

import { Action } from 'types';
import { api } from 'services/api-builder/api';
import { useDictionary } from 'hooks/useDictionary';
import { ListBox } from 'components/listbox-v2';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { CountryCode } from './types';

type Props = {
    createMyConnectedAccount: Action<typeof api.createMyConnectedAccount>;
    onMyConnectedOnboarding: () => void;
    close: () => void;
};

export const CreateMyConnectedAccountPopup = (props: Props) => {
    const { createMyConnectedAccount, onMyConnectedOnboarding, close } = props;
    const dic = useDictionary();
    const [country, setCountry] = useState<string>();

    const countries: Record<string, string> = useMemo(
        () => JSON.parse(dic.word('wt_myspace__home_page__myproducts_register_popup_countries_obj')),
        [dic],
    );

    const options = useMemo(
        () => Object.entries(countries).map(([value, label]) => ({ value, text: label })),
        [countries],
    );
    return (
        <WtPopup3
            close={close}
            title={dic.word('wt_myspace__home_page__myproducts_register_popup_title')}
            size={SizeWtPopup3Enum.LARGE}
            actionButtons={{
                direction: 'vertical',
                items: [
                    {
                        key: 1,
                        content: dic.word('wt_myspace__home_page__myproducts_register_popup_submit'),
                        isDisabled: !country,
                        onClick: () => {
                            createMyConnectedAccount({ countryCode: country as CountryCode }).then(() =>
                                onMyConnectedOnboarding(),
                            );
                        },
                    },
                ],
            }}
            width={529}
            className="autotest_home_popup_choose_country"
        >
            <ListBox
                value={country}
                options={options}
                onChange={setCountry}
                placeholder={dic.word('wt_myspace__home_page__myproducts_register_popup_countries_plch')}
                width="full"
            />
        </WtPopup3>
    );
};
