import React, { FC, memo, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { useDictionary } from 'hooks/useDictionary';
import { useLocaleId } from 'hooks/useLocaleId';
import {
    FormField,
    FormItemMessage,
    FormLabel,
    FormSectionHeadline,
    Gap20FormVLayout,
    VerticalFormInputGroup,
} from 'components/form';
import { FormTextInput, FormListBox, FormNumberInput, FormLabeledSwitch } from 'components/form/react-hook-form';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { StepProps } from '../../types';

import { PriceComboBoxWrapper } from './styled';
import {
    defaultValues,
    MAX_LENGTH,
    MAX_PRICE,
    MAX_URL_LENGTH,
    MIN_LENGTH,
    MIN_PRICE,
    MIN_URL_LENGTH,
} from './constants';
import { toData } from './toData';
import { MarketNames, Step1FormValues, TypeNames } from './types';

export const Step1: FC<StepProps<Step1FormValues>> = memo((props) => {
    const lcid = useLocaleId();
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__step1_');

    const markets = useMemo(
        () =>
            Object.keys(MarketNames)
                .filter((key) => isNaN(parseFloat(key)))
                .map((typeName) => ({
                    id: typeName,
                    text: dic.word(`wt_all__widget_myspace_product_wizard__step1_field_market_value_${typeName}`),
                })),
        [dic],
    );

    const types = useMemo(
        () =>
            Object.keys(TypeNames)
                .filter((key) => isNaN(parseFloat(key)))
                .map((typeName) => ({
                    id: typeName,
                    text: dic.word(`wt_all__widget_myspace_product_wizard__step1_field_type_value_${typeName}`),
                })),
        [dic],
    );

    const form = useForm<Step1FormValues>({
        mode: 'onTouched',
        defaultValues,
    });

    const { getFieldState, formState } = form;
    const { error: hrefError } = getFieldState('accessUrl', formState);
    const { error: nameError } = getFieldState('name', formState);

    useFormStateHandler({ form, props, toData, checkFields: ['name'] });

    const required = dic.word('wt_all__widget_product_wizard__field_required');

    return (
        <Gap20FormVLayout>
            <FormSectionHeadline>{dicWord('headline')}</FormSectionHeadline>

            <FormProvider {...form}>
                <VerticalFormInputGroup>
                    <FormField>
                        <FormLabel>{dicWord('info_group_title')}</FormLabel>
                        <FormTextInput
                            name="name"
                            kind="outline"
                            size="medium"
                            label={dicWord('field_name_label')}
                            placeholder={dicWord('field_name_example')}
                            className="autotest__marketplace__product_wizard__name"
                            maxLength={MAX_LENGTH}
                            rules={{
                                maxLength: { value: MAX_LENGTH, message: dicWord('field_name_msg_too_long') },
                                minLength: {
                                    value: MIN_LENGTH,
                                    message: dic.word(
                                        'wt_all__widget_product_wizard__step2_field_description_min_message',
                                        { min: MIN_LENGTH },
                                    ),
                                },
                                required,
                            }}
                            hideErrors={nameError?.type === 'required'}
                        />
                    </FormField>
                    <FormField>
                        <FormListBox
                            name="market"
                            placeholder={dicWord('field_market_placeholder')}
                            kind="inline-label-outline"
                            size="medium"
                            className="autotest__marketplace__product_wizard__market"
                            options={markets}
                            autotestOptionPrefix="autotest__marketplace__product_wizard__market_"
                            rules={{ required }}
                            hideErrors
                        />
                    </FormField>
                    <FormField>
                        <FormListBox
                            name="programType"
                            placeholder={dicWord('field_type_placeholder')}
                            kind="inline-label-outline"
                            size="medium"
                            className="autotest__marketplace__product_wizard__type"
                            options={types}
                            autotestOptionPrefix="autotest__marketplace__product_wizard__type_"
                            rules={{ required }}
                            hideErrors
                        />
                    </FormField>
                    <FormTextInput
                        name="accessUrl"
                        kind="outline"
                        size="medium"
                        label={dicWord('field_href_label')}
                        placeholder={dicWord('field_href_example')}
                        className="autotest__marketplace__product_wizard__link"
                        maxLength={MAX_URL_LENGTH}
                        rules={{
                            required,
                            minLength: {
                                value: MIN_URL_LENGTH,
                                message: dic.word(
                                    'wt_all__widget_product_wizard__step2_field_description_min_message',
                                    { min: MIN_URL_LENGTH },
                                ),
                            },
                            maxLength: {
                                value: MAX_URL_LENGTH,
                                message: dic.word('wt_all__widget_product_wizard__text_block_title_too_long_msg'),
                            },
                            validate(value: string) {
                                if (value === '') {
                                    return true;
                                }
                                const error = dic.word('wt_all__widget_product_wizard__field_url_invalid');
                                try {
                                    const { protocol, hostname } = new URL(value);
                                    return (protocol === 'http:' || protocol === 'https:') && hostname.includes('.')
                                        ? true
                                        : error;
                                } catch {
                                    return error;
                                }
                            },
                        }}
                        hideErrors={hrefError?.type === 'required'}
                    />
                </VerticalFormInputGroup>
                <VerticalFormInputGroup>
                    <>
                        <FormField className="autotest__marketplace__product_wizard__price">
                            <PriceComboBoxWrapper>
                                <FormNumberInput
                                    name="cost"
                                    kind="outline"
                                    size="medium"
                                    label={dicWord('field_price_label')}
                                    placeholder="7"
                                    locale={lcid}
                                    min={MIN_PRICE}
                                    max={MAX_PRICE}
                                    rules={{
                                        required,
                                        pattern: { value: /\d/, message: required },
                                    }}
                                    currencySign="$"
                                />
                            </PriceComboBoxWrapper>
                            <FormItemMessage>{dicWord('field_price_msg')}</FormItemMessage>
                        </FormField>
                        <FormField className="autotest__marketplace__product_wizard__trial">
                            <FormLabeledSwitch name="trial" label={dicWord('field_hasTrialPeriod_label')} />
                        </FormField>
                    </>
                </VerticalFormInputGroup>
            </FormProvider>
        </Gap20FormVLayout>
    );
});
