import React from 'react';

import { FinamStrategyProduct } from 'types/finam-product';
import { useDicMemo } from 'hooks/useDictionary';
import { PriceFormatter } from 'widgets/formatting';

import { ProductTableParameters } from '../types';

export const useStrategyTable = (strategyProduct?: FinamStrategyProduct): ProductTableParameters => {
    const { minSum, annualAverageProfit, profitLifetime, riskLevel } = strategyProduct ?? {};

    return useDicMemo(
        (dic) => {
            const parameters = [];
            if (minSum) {
                parameters.push({
                    name: dic.word('wt_all__product_card_finam__strategy_table_min_sum'),
                    value: (
                        <PriceFormatter price={minSum || 0} currency="USD">
                            {(formattedPrice) => formattedPrice}
                        </PriceFormatter>
                    ),
                });
            }
            if (riskLevel) {
                parameters.push({
                    name: dic.word('wt_all__product_card_finam__strategy_table_risk'),
                    value: riskLevel,
                });
            }
            if (profitLifetime !== undefined) {
                parameters.push({
                    name: dic.word('wt_all__product_card_finam__strategy_table_profit_lifetime'),
                    value: `${profitLifetime ? (profitLifetime * 100).toFixed(2) : profitLifetime}%`,
                });
            }
            return parameters;
        },
        [minSum, annualAverageProfit],
    );
};
