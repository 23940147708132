import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 40px;
    ${mediaQuery.lt768} {
        margin-bottom: 18px;
    }
`;
