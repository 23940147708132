import { DefaultTheme } from 'styled-components';
import ColorHash from 'color-hash';

import { AvatarInitials } from 'components/avatar/types';

import { AvatarUserProps } from './types';
import { BG_ALPHA_PERCENT_VALUE, TEXT_COLOR_ALPHA_PERCENT_VALUE } from './constants';

const convertToHslCssString = ({
    hslArr,
    alphaPercentValue,
    colorDefault,
}: {
    hslArr?: [number, number, number] | [];
    alphaPercentValue: number;
    colorDefault: string;
}) => {
    if (hslArr?.length === 3) {
        return `hsl(${hslArr[0]}, ${hslArr[1] * 100}%, ${alphaPercentValue}%)`;
    }

    return colorDefault;
};

type GetInitialsArguments = { userFullName?: string; theme: DefaultTheme };
export const getInitialsParams = ({ userFullName, theme }: GetInitialsArguments): AvatarInitials => {
    const userName = userFullName || '';

    const displayNameSplittedBySpace = userName?.split(' ');
    const initialsFirstSign = displayNameSplittedBySpace?.[0]?.[0] || '';
    const initialsSecondSign = displayNameSplittedBySpace?.[1]?.[0] || '';

    const bgDefault = theme.fillIn.secondary.overlay24;
    const textColorDefault = theme.label.primary;

    let colorInitialsArr: [number, number, number] | [] = [];
    if (userFullName) {
        const colorHash = new ColorHash();
        colorInitialsArr = colorHash.hsl(userFullName);
    }

    const bg = convertToHslCssString({
        hslArr: colorInitialsArr,
        alphaPercentValue: BG_ALPHA_PERCENT_VALUE,
        colorDefault: bgDefault,
    });

    const textColor = convertToHslCssString({
        hslArr: colorInitialsArr,
        alphaPercentValue: TEXT_COLOR_ALPHA_PERCENT_VALUE,
        colorDefault: textColorDefault,
    });

    const text =
        initialsFirstSign !== '' && initialsSecondSign !== '' ? `${initialsFirstSign}${initialsSecondSign}` : '?';

    return {
        bg,
        text,
        textColor,
    };
};

export const getUrlByUser = ({
    user,
    buildUrl,
}: {
    user: AvatarUserProps['user'];
    buildUrl: AvatarUserProps['buildHrefByUser'];
}) => {
    const userUrl = user?.url;
    const userId = user?.id;

    if (buildUrl && userId) {
        return buildUrl(user);
    }

    if (userUrl) {
        return userUrl;
    }

    return undefined;
};
