import { Action } from 'types';
import { api } from 'services/api-builder/api';

export const getHandleMyConnectedOnboarding =
    (createMyConnectedOnboarding: Action<typeof api.createMyConnectedOnboarding>) => () => {
        createMyConnectedOnboarding().then((payload) => {
            if (payload.data?.url) {
                window.location.href = payload.data.url;
            }
        });
    };
