import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ShareWidgetWrapper = styled.div`
    min-width: 240px;
    ${mediaQuery.lt768} {
        display: inline;
    }
`;

export const ContextActionsWidget = styled.div``;
