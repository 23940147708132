import React from 'react';

import { IconSvg } from 'components/icons2';

import { ArrowComponentProps } from '../types/ArrowComponent.types';
import { DEFAULT_SIZE_RIBBON } from '../constants';
import * as Styled from '../styled/ArrowComponent.styled';

export const ArrowComponent = (props: ArrowComponentProps) => {
    const { type = 'right', className, size = DEFAULT_SIZE_RIBBON } = props;
    return (
        <Styled.ArrowWrapper type={type} className={className} size={size}>
            <IconSvg viewBox="0 0 16 32">
                <path
                    d="M9.88145 0C7.67861 0 5.35057 1.36425 4.69508 3.04714L0.247424 14.4659C-0.0823501 15.3125 -0.082599 16.6869 0.247424 17.5341L4.69508 28.9529C5.35061 30.6358 7.67259 32 9.88145 32H16V0H9.88145Z"
                    fill="inherit"
                />
            </IconSvg>
        </Styled.ArrowWrapper>
    );
};
