import styled from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { h3SemiboldFontStyle, h4SemiboldFontStyle, h5MediumFontStyle } from 'services/typography';
import { Button } from 'components/button2';
import { Counter as CounterComponent } from 'components/indicator/counter';

import { SizeWtPopup3Enum } from '../constants';

import { HeaderWtPopup3PropsType } from './types';

const buildSizesHeader = ({ size }: Required<Pick<HeaderWtPopup3PropsType, 'size'>>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: 'padding: 8px 8px 4px;',
        [SizeWtPopup3Enum.SMALL]: 'padding: 16px 24px 4px;',
        [SizeWtPopup3Enum.MEDIUM]: 'padding: 24px 24px 8px;',
        [SizeWtPopup3Enum.LARGE]: 'padding: 32px 40px 8px;',
    });

export const HeaderWrapper = styled.div<Required<Pick<HeaderWtPopup3PropsType, 'size' | 'isMobileFullscreen'>>>`
    ${buildSizesHeader};
    ${mediaQuery.lt768} {
        ${() => buildSizesHeader({ size: SizeWtPopup3Enum.TINY })};
    }
`;

export const Header = styled.div<Required<Pick<HeaderWtPopup3PropsType, 'size' | 'isMobileFullscreen'>>>`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    min-width: 0;
`;

const buildSizesButtonArrowLeft = (size: Required<SizeWtPopup3Enum>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: 'margin: 6px 0',
        [SizeWtPopup3Enum.SMALL]: 'margin: 6px 0;',
        [SizeWtPopup3Enum.MEDIUM]: 'margin: 6px 0;',
        [SizeWtPopup3Enum.LARGE]: 'margin: 8px 0',
    });
export const ButtonArrowLeft = styled(Button)<{ sizeHeader: Required<SizeWtPopup3Enum> }>`
    ${({ sizeHeader }) => buildSizesButtonArrowLeft(sizeHeader)};

    ${mediaQuery.lt768} {
        ${() => buildSizesButtonArrowLeft(SizeWtPopup3Enum.TINY)};
    } ;
`;

const buildSizesTitle = ({ size }: Required<Pick<HeaderWtPopup3PropsType, 'size'>>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: `${h5MediumFontStyle};`,
        [SizeWtPopup3Enum.SMALL]: `${h5MediumFontStyle};`,
        [SizeWtPopup3Enum.MEDIUM]: `${h4SemiboldFontStyle};`,
        [SizeWtPopup3Enum.LARGE]: `${h3SemiboldFontStyle};`,
    });

export const TitleWrapper = styled.div`
    padding: 8px;
    display: flex;
    align-items: flex-start;
    min-width: 0;
`;

export const Title = styled.span<Required<Pick<HeaderWtPopup3PropsType, 'size'>>>`
    ${buildSizesTitle};

    ${mediaQuery.lt768} {
        ${() => buildSizesTitle({ size: SizeWtPopup3Enum.TINY })};
    }
    min-width: 0;
    overflow-wrap: break-word;
`;

const buildSizesCounter = (size: Required<SizeWtPopup3Enum>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: 'margin: 5px 0 5px 8px;',
        [SizeWtPopup3Enum.SMALL]: 'margin: 5px 0 5px 8px;',
        [SizeWtPopup3Enum.MEDIUM]: 'margin: 4px 0 4px 8px;',
        [SizeWtPopup3Enum.LARGE]: 'margin: 6px 0 6px 8px;',
    });
export const Counter = styled(CounterComponent)<{ sizeHeader: Required<SizeWtPopup3Enum> }>`
    ${({ sizeHeader }) => buildSizesCounter(sizeHeader)};

    ${mediaQuery.lt768} {
        ${() => buildSizesCounter(SizeWtPopup3Enum.TINY)};
    } ;
`;

export const CloseButtonStyled = styled(Button)<{ popupSize: SizeWtPopup3Enum }>`
    align-self: start;
    ${({ popupSize }) =>
        matchBreakpoints(popupSize, {
            [SizeWtPopup3Enum.TINY]: 'margin-top: 6px;',
            [SizeWtPopup3Enum.SMALL]: 'margin-top: 6px;',
            [SizeWtPopup3Enum.MEDIUM]: 'margin-top: 6px;',
            [SizeWtPopup3Enum.LARGE]: 'margin-top: 8px;',
        })}
`;
