import type { ProductRisk } from 'services/mp-share-resources/types';

import type { Step2FormValues, Step2Data } from './types';

export function toData({ accountId, minSum, riskLevel, price, recommendedHoldingPeriod }: Step2FormValues): Step2Data {
    return {
        accountId: parseInt(accountId, 10),
        minSum: Math.round(minSum || 0),
        riskLevel: riskLevel as ProductRisk,
        price: price || undefined,
        recommendedHoldingPeriod,
    };
}
