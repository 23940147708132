/* eslint-disable camelcase */
import React, { FC, memo, Suspense, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Id } from 'types';
import { mediaQuery } from 'app/styled';
import { useDictionary } from 'hooks/useDictionary';
import { useKeyPress } from 'hooks/useKeyPress';
import { useClickOutside } from 'hooks/useClickOutside';
import { useDebounce } from 'hooks/useDebounce';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { usePageContext } from 'hooks/usePageContext';
import { SearchInput } from 'components/search-input';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { MagnifierIcon } from 'components/icons2/MagnifierIcon';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { GetSearchResults, Reset, SearchResultClickLog, UpdateSearchResult } from '../actions';
import { DEFAULT_LOG_VALUE } from '../constants';
import { SearchResult, SearchTabs } from '../interfaces';
import { addToHistory, getSearchHistory, hasSearchHistory } from '../utils';

import { SearchResultsFallback } from './search-results/SearchResultsFallback.styled';
import type { SearchResultsProps } from './search-results';
import { DesktopSearchInputWrapper, GlobalStyle, SearchWrapper, StyledTippy } from './styled';

const SearchResults = React.lazy(() =>
    import(/* webpackChunkName: "search-results" */ './search-results').then((module) => ({
        default: module.SearchResults,
    })),
);

interface SearchComponentStateProps {
    searchResult: SearchResult;
    loadingTabs: SearchTabs[];
    activeTab: SearchTabs;
}

interface SearchComponentDispatchProps {
    getSearchResults: GetSearchResults;
    updateSearchResult: UpdateSearchResult;
    searchResultClickLog: SearchResultClickLog;
    reset: Reset;
    setActiveTab: (tab: SearchTabs) => void;
}

export interface SearchComponentProps extends SearchComponentStateProps, SearchComponentDispatchProps {
    visible: boolean;
    onVisibleChanged: (isVisible: boolean) => void;
    onFocusChanged: (isFocus: boolean) => void;
}

export const SearchComponent: FC<SearchComponentProps> = memo(
    ({
        searchResult,
        loadingTabs,
        getSearchResults,
        updateSearchResult,
        searchResultClickLog,
        visible,
        onVisibleChanged,
        onFocusChanged,
        reset,
        activeTab,
        setActiveTab,
    }) => {
        const [{ domainLcid }] = usePageContext();
        const dic = useDictionary();

        const inputRef = useRef<HTMLInputElement>(null);
        const inputWrapperRef = useRef<HTMLLabelElement>(null);
        const resultsRef = useRef(null);

        const [searchInputValue, setSearchInputValue] = useState('');
        const debouncedSearchInputValue = useDebounce(searchInputValue, 500);

        const handleClick = useCallback(
            (id: Id, type: string, log_id: number) => {
                addToHistory(searchResult, type, id);
                searchResultClickLog({
                    id,
                    type,
                    log_id,
                    timestamp: new Date().getTime(),
                    query: searchInputValue,
                });
            },
            [searchResult, searchResultClickLog, searchInputValue],
        );

        useLayoutEffect(() => {
            if (searchInputValue === '' && visible) {
                reset('search');

                const {
                    instruments = [],
                    people = [],
                    posts = [],
                    products = [],
                    communities = [],
                    log = DEFAULT_LOG_VALUE,
                } = getSearchHistory();

                updateSearchResult(
                    {
                        instruments,
                        people,
                        posts,
                        products,
                        communities,
                        log,
                    },
                    'search',
                );
            }
        }, [
            reset,
            searchInputValue,
            updateSearchResult,
            // завязываемся на visible дабы перечитать из LS при каждом открытии
            // таким образом вкладки относитльно LS станут синхронизированы
            visible,
        ]);

        useLayoutEffect(() => {
            if (debouncedSearchInputValue !== '') {
                getSearchResults(debouncedSearchInputValue, null, domainLcid);
            }
        }, [debouncedSearchInputValue, getSearchResults, domainLcid]);

        const { instruments, log, people, posts, products, communities } = searchResult;

        const slashPressed = useCallback((e?: KeyboardEvent) => {
            const focusedElement = document.activeElement as HTMLElement;

            if (
                !(
                    focusedElement?.isContentEditable ||
                    focusedElement?.tagName === 'INPUT' ||
                    focusedElement?.tagName === 'TEXTAREA'
                )
            ) {
                e?.preventDefault?.();
                inputRef.current?.focus?.();
            }
        }, []);

        useKeyPress({ key: '/', onKeyDown: slashPressed });

        const handleSearchChange = useCallback(
            (value: string) => {
                if (value === '' || /[^\s]/.test(value)) {
                    setSearchInputValue(value);
                    if (value !== '') {
                        onVisibleChanged(true);
                    }
                }
            },
            [onVisibleChanged],
        );

        const handleCloseMobileSearch = useCallback(() => {
            onVisibleChanged(false);
        }, [onVisibleChanged]);

        const handleFocus = useCallback(() => {
            if (searchInputValue !== '' || hasSearchHistory()) {
                onVisibleChanged(true);
            }
            onFocusChanged(true);
        }, [onVisibleChanged, searchInputValue, onFocusChanged]);

        const handleBlur = useCallback(() => {
            onFocusChanged(false);
        }, [onFocusChanged]);

        const handleClickOutside = useCallback(() => {
            onVisibleChanged(false);
        }, [onVisibleChanged]);
        useClickOutside([resultsRef, inputRef, inputWrapperRef], handleClickOutside);

        const showSearchResults = useCallback(() => {
            onVisibleChanged(true);
        }, [onVisibleChanged]);

        const showLoading =
            loadingTabs.includes(activeTab) ||
            (searchInputValue !== '' && searchInputValue !== debouncedSearchInputValue);

        const searchResultsProps: Omit<SearchResultsProps, 'inputRef' | 'isMobile'> = {
            instruments,
            log,
            people,
            posts,
            products,
            communities,
            searchInput: searchInputValue,
            onSearchChange: handleSearchChange,
            onClose: handleCloseMobileSearch,
            isLoading: showLoading,
            onClick: handleClick,
            activeTab,
            setActiveTab,
        };

        const isMobile = useMatchMedia(mediaQuery.lt768);
        const isTablet = useMatchMedia(mediaQuery.lt1200);

        const [tippyContentMounted, setTippyContentMounted] = useState(false);

        return (
            <>
                {isMobile ? (
                    <div>
                        <ButtonIcon
                            kind={ButtonIconKinds.GhostSecondary}
                            icon={{ component: MagnifierIcon }}
                            onClick={showSearchResults}
                            pressed={visible}
                        />
                        {visible && (
                            <Suspense
                                fallback={
                                    <SearchResultsFallback>
                                        <ProcessingIcon />
                                    </SearchResultsFallback>
                                }
                            >
                                <SearchResults {...searchResultsProps} ref={resultsRef} inputRef={inputRef} isMobile />
                            </Suspense>
                        )}
                    </div>
                ) : (
                    <div>
                        <SearchWrapper>
                            <StyledTippy
                                appendTo={document.getElementById('navigation-menu')!}
                                placement="bottom-end"
                                arrow={false}
                                interactive
                                hideOnClick={false}
                                trigger="manual"
                                visible={visible}
                                onShow={() => setTippyContentMounted(true)}
                                onHidden={() => setTippyContentMounted(false)}
                                content={
                                    tippyContentMounted ? (
                                        <Suspense
                                            fallback={
                                                <SearchResultsFallback>
                                                    <ProcessingIcon />
                                                </SearchResultsFallback>
                                            }
                                        >
                                            <SearchResults
                                                {...searchResultsProps}
                                                ref={resultsRef}
                                                inputRef={inputRef}
                                                isMobile={false}
                                            />
                                        </Suspense>
                                    ) : (
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        <></>
                                    )
                                }
                            >
                                <DesktopSearchInputWrapper tabIndex={-1}>
                                    {isTablet ? (
                                        <ButtonIcon
                                            kind={ButtonIconKinds.GhostSecondary}
                                            icon={{ component: MagnifierIcon }}
                                            onClick={showSearchResults}
                                            pressed={visible}
                                            className="autotest__navigation__buttons_search"
                                        />
                                    ) : (
                                        <SearchInput
                                            wrapperRef={inputWrapperRef}
                                            ref={inputRef}
                                            loading={showLoading}
                                            placeholder={dic.word('wt_menu__search_limex__placeholder')}
                                            value={searchInputValue}
                                            onChange={handleSearchChange}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            autotestClass="autotest__navigation__search_input"
                                        />
                                    )}
                                </DesktopSearchInputWrapper>
                            </StyledTippy>
                        </SearchWrapper>
                    </div>
                )}

                {visible && <GlobalStyle />}
            </>
        );
    },
);
