/* eslint-disable camelcase */
import React, { ReactNode } from 'react';

import { CurrencyCode } from 'types/currency';
import { Marketplace_Mappings_PriceDto } from 'services/api-services/generated_marketplace';
import { Dictionary } from 'services/dictionary';
import { useDictionary } from 'hooks/useDictionary';

import { PriceFormatter } from './price-formatter';
import { PricePeriodFormatterResult } from './price-period-formatter';

export type FinamPricePeriodFormatterHandler = (result: PricePeriodFormatterResult) => ReactNode;

export type FinamPricePeriodFormatter = {
    price?: Marketplace_Mappings_PriceDto;
    children: FinamPricePeriodFormatterHandler;
};

export function getLocalizedPeriod(payPeriod: string | null | undefined, dic: Dictionary) {
    if (payPeriod === null || payPeriod === undefined || payPeriod[1] === '0') {
        return '';
    }
    const [, num, periodLetter] = payPeriod.split('');
    const count = parseInt(num, 10);
    let periodString = dic.word(`wt_all__widget_formatting__period_${periodLetter}`, {
        count,
    });
    if (periodString.startsWith('1 ')) {
        periodString = periodString.slice(2);
    }
    return periodString ? `/${periodString}` : '';
}

/**
 * Provides a locale-formatted price period information via children render prop.
 *
 * @example
 * <PricePeriodFormatter amount={31} currency="USD" period="P1M">
 *     {({ localizedPrice, localePeriod }) => (
 *         <div>Price: {localizedPrice} / {localePeriod}</div>
 *     )}
 * </PricePeriodFormatter>
 */
export const FinamPricePeriodFormatter = ({ price, children }: FinamPricePeriodFormatter) => {
    const dic = useDictionary();
    const amount = price?.amount ?? 0;
    if (amount === 0) {
        return <>{children({ localizedPrice: dic.word('wt_all__widget_formatting__free'), localizedPeriod: '' })}</>;
    }

    const currency = (price?.currency ?? null) as CurrencyCode | null;

    return (
        <PriceFormatter price={amount} currency={currency}>
            {(localizedPrice) =>
                children({
                    localizedPrice,
                    localizedPeriod: getLocalizedPeriod(price?.payPeriod, dic),
                })
            }
        </PriceFormatter>
    );
};
