import produce from 'immer';

import {
    FeedRecord,
    FeedRecordObj,
    FeedRecordObjTypes,
    FeedRecordStats,
    isPublishStatusRecord,
    PublishStatusRecord,
} from 'types';

import { FeedState } from '../types';
import { index, getCommentsAndPath } from '../helpers';

import {
    FETCH_COMMENTS_ACTION_TYPES,
    CREATE_COMMENT_ACTION_TYPES,
    DELETE_COMMENT_ACTION_TYPES,
    RESTORE_COMMENT_ACTION_TYPES,
    GET_MENTION_LIST_ACTION_TYPES,
    PUBLISHED_COMMENT_MARK_OR_UNMARK,
    commentsFilters,
    CHANGE_COMMENTS_FILTER,
    CommentsActions,
    EDIT_COMMENT_ACTION_TYPES,
    commentsDirections,
} from './actions';

const [, FETCH_COMMENTS_SUCCESS] = FETCH_COMMENTS_ACTION_TYPES;
const [, CREATE_COMMENT_SUCCESS] = CREATE_COMMENT_ACTION_TYPES;
const [, EDIT_COMMENT_SUCCESS] = EDIT_COMMENT_ACTION_TYPES;
const [, DELETE_COMMENT_SUCCESS] = DELETE_COMMENT_ACTION_TYPES;
const [, RESTORE_COMMENT_SUCCESS] = RESTORE_COMMENT_ACTION_TYPES;
const [, GET_MENTION_LIST_SUCCESS] = GET_MENTION_LIST_ACTION_TYPES;

export default (state: FeedState, action: CommentsActions) => {
    switch (action.type) {
        case CHANGE_COMMENTS_FILTER: {
            const {
                payload: {
                    data: { recordId },
                },
            } = action;

            return produce(state, (draft) => {
                const record = draft.records[index(recordId)];
                if (isPublishStatusRecord(record)) {
                    const { path } = getCommentsAndPath(record);
                    const { obj } = record;
                    if (path === 'obj.comments') {
                        obj.comments = [];
                    } else {
                        record.comments = [];
                    }
                    record.repliesAmount = {};
                }
            });
        }
        case FETCH_COMMENTS_SUCCESS: {
            const {
                payload: {
                    pass: { recordId, pinnedCommentId, direction },
                    data: { comments, repliesAmount, pinnedRepliesAmount },
                },
            } = action;

            const record = state.records[index(recordId)];
            if (isPublishStatusRecord(record)) {
                const { comments: oldComments, path } = getCommentsAndPath(record);

                const newComments = comments
                    ? comments.filter((comment) => !oldComments?.find((oldComment) => oldComment.id === comment.id))
                    : [];

                const oldRepliesAmounts = record?.repliesAmount;
                const newRepliesAmounts = Object.fromEntries(
                    Object.entries(repliesAmount ?? {}).filter(([id]) => !oldRepliesAmounts?.[id]),
                );

                return produce(state, (draft) => {
                    const _record = draft.records[index(recordId)];
                    if (isPublishStatusRecord(_record)) {
                        if (path === 'obj.comments') {
                            if (pinnedCommentId && direction === commentsDirections.before) {
                                _record.obj?.comments?.splice(0, 0, ...newComments);
                            } else {
                                _record.obj?.comments?.push(...newComments);
                            }
                        } else if (pinnedCommentId && direction === commentsDirections.before) {
                            _record.comments?.splice(0, 0, ...newComments);
                        } else {
                            _record.comments?.push(...newComments);
                        }

                        _record.repliesAmount = { ...oldRepliesAmounts, ...newRepliesAmounts };
                        _record.pinnedRepliesAmount = pinnedRepliesAmount;
                    }
                });
            }
            return state;
        }
        case CREATE_COMMENT_SUCCESS: {
            const {
                payload: {
                    pass: { recordId, commentsFilter },
                    data: { comment },
                },
            } = action;
            if (!comment) return state;
            const record = state.records[index(recordId)];
            const { comments, path } = getCommentsAndPath(record);
            if (isPublishStatusRecord(record)) {
                const isPost = record.obj?.type === FeedRecordObjTypes.POST;
                const commentsCount = isPost ? record?.obj?.commentsCount : record.stats?.commentsCount;

                const withComments = produce(state, (draft) => {
                    let obj: FeedRecordObj | PublishStatusRecord | undefined;
                    const _record = draft.records[index(recordId)];
                    if (isPublishStatusRecord(_record)) {
                        if (path === 'obj.comments') {
                            obj = _record.obj;
                        } else {
                            obj = _record;
                        }
                    }

                    if (obj) {
                        if (comment.parentId === comment.rootId) {
                            if (commentsFilter === commentsFilters.recent || commentsFilter === commentsFilters.rated) {
                                obj.comments = [comment, ...comments];
                            } else {
                                obj.comments = [...comments, comment];
                            }
                        } else {
                            obj.comments = [...comments, comment];
                        }
                    }
                });

                const withCommentsAndFlag = produce(withComments, (draft) => {
                    const _record = draft.records[index(recordId)];
                    const obj = isPublishStatusRecord(_record) ? _record.obj : undefined;
                    if (obj) obj.isUserCommented = true;
                });

                const withCommentsCount = produce(withCommentsAndFlag, (draft) => {
                    let obj: FeedRecordObj | FeedRecordStats | undefined;
                    const _record = draft.records[index(recordId)];
                    if (isPublishStatusRecord(_record)) {
                        if (isPost) {
                            obj = _record.obj;
                        } else {
                            obj = _record.stats;
                        }
                    }

                    if (obj) {
                        obj.commentsCount = (commentsCount ?? 0) + 1;
                    }
                });

                return produce(withCommentsCount, (draft) => {
                    const _record = draft.records[index(recordId)];
                    if (isPublishStatusRecord(_record)) {
                        _record.publishedId = comment.id;
                    }
                });
            }
            return state;
        }
        case EDIT_COMMENT_SUCCESS: {
            const {
                payload: {
                    pass: { recordId },
                    data: { comment },
                },
            } = action;
            if (!comment) return state;
            const record = state.records[index(recordId)];
            const { comments, path } = getCommentsAndPath(record);

            const commentIndex = comments?.findIndex((_comment) => _comment.id === comment.id);

            return produce(state, (draft) => {
                let obj: FeedRecordObj | PublishStatusRecord | undefined;
                const _record = draft.records[index(recordId)];
                if (isPublishStatusRecord(_record)) {
                    if (path === 'obj.comments') {
                        obj = _record.obj;
                    } else {
                        obj = _record;
                    }
                }
                if (obj?.comments) {
                    obj.comments[commentIndex ?? 0] = comment;
                }
            });
        }
        case DELETE_COMMENT_SUCCESS: {
            const {
                payload: {
                    pass: { recordId, commentId },
                },
            } = action;

            const record = state.records[index(recordId)];
            const { path } = getCommentsAndPath(record);

            return produce(state, (draft) => {
                let obj: FeedRecordObj | PublishStatusRecord | undefined;
                const _record = draft.records[index(recordId)];
                if (isPublishStatusRecord(_record)) {
                    if (path === 'obj.comments') {
                        obj = _record.obj;
                    } else {
                        obj = _record;
                    }
                }
                if (obj?.comments) {
                    obj.comments = [
                        ...obj.comments.map((x) => (x.id === commentId ? { ...x, isLocalDeleted: true } : x)),
                    ];
                }
            });
        }
        case RESTORE_COMMENT_SUCCESS: {
            const {
                payload: {
                    pass: { recordId, commentId },
                },
            } = action;

            const record = state.records[index(recordId)];
            const { path } = getCommentsAndPath(record);

            return produce(state, (draft) => {
                let obj: FeedRecordObj | PublishStatusRecord | undefined;
                const _record = draft.records[index(recordId)];
                if (isPublishStatusRecord(_record)) {
                    if (path === 'obj.comments') {
                        obj = _record.obj;
                    } else {
                        obj = _record;
                    }
                }
                if (obj?.comments) {
                    obj.comments = [
                        ...obj.comments.map((x) => (x.id === commentId ? { ...x, isLocalDeleted: false } : x)),
                    ];
                }
            });
        }
        case GET_MENTION_LIST_SUCCESS: {
            const {
                payload: {
                    data,
                    pass: { recordId },
                },
            } = action;
            return produce(state, (draft) => {
                const record = draft.records[index(recordId)];
                if (isPublishStatusRecord(record)) {
                    if (!record.obj.mentions) record.obj.mentions = [];
                    if (data['mention-list']) {
                        record.obj.mentions = [...data['mention-list']];
                    } else record.obj.mentions = [];
                }
            });
        }
        case PUBLISHED_COMMENT_MARK_OR_UNMARK: {
            const {
                payload: { recordId, objectId, commentId },
            } = action;

            return produce(state, (draft) => {
                let record: PublishStatusRecord | undefined;
                if (recordId) {
                    const _record = draft.records[index(recordId)];
                    record = isPublishStatusRecord(_record) ? _record : undefined;
                } else if (objectId) {
                    const publishStatuses = Object.values(draft.records).filter(isPublishStatusRecord);
                    record = publishStatuses.find(({ obj }) => obj?.id === objectId);
                }
                if (record) {
                    record.publishedId = commentId ?? null;
                }
            });
        }
        default:
            return state ?? { records: {} };
    }
};
