import isObject from 'lodash/isObject';

import { sentryClient, SentryError } from 'services/sentry';
import { StorageProvider } from 'services/storageProvider';

import {
    ACCEPTED_ALL_COOKIE_PRIVACY_SETTINGS,
    COOKIES_KEYS_BY_COOKIE_PRIVACY_SETTINGS_KEYS,
    DEFAULT_COOKIE_PRIVACY_SETTINGS,
    LOCAL_STORAGE_KEY,
    REJECTED_ALL_COOKIE_PRIVACY_SETTINGS,
    REQUIRED_AND_DISABLED_KEYS_COOKIE_PRIVACY_SETTINGS,
    SENTRY_ERROR_NAME,
} from './constants';
import { CookiePrivacySettingsKey, LocalStorageCookiePrivacySettingsState, RequiredDisabledKeys } from './types';

export class CookiePrivacySettings {
    private readonly localStorageKey: string = LOCAL_STORAGE_KEY;

    public readonly defaultCookiePrivacySettings: LocalStorageCookiePrivacySettingsState =
        DEFAULT_COOKIE_PRIVACY_SETTINGS;

    public readonly acceptedAllCookiePrivacySettings: LocalStorageCookiePrivacySettingsState =
        ACCEPTED_ALL_COOKIE_PRIVACY_SETTINGS;

    public readonly rejectedAllCookiePrivacySettings: LocalStorageCookiePrivacySettingsState =
        REJECTED_ALL_COOKIE_PRIVACY_SETTINGS;

    public readonly requiredAndDisabledKeysCookiePrivacySettings: RequiredDisabledKeys =
        REQUIRED_AND_DISABLED_KEYS_COOKIE_PRIVACY_SETTINGS;

    private LSStorage = StorageProvider(localStorage);

    constructor() {
        try {
            const settingsFormLocalStorage = this.getSettingsFromLocalStorage();
            if (settingsFormLocalStorage) {
                const isValidSettings = this.isValidCookieSettings(settingsFormLocalStorage);

                if (isValidSettings) {
                    this.clearUncheckedSettingsCookies();
                } else {
                    this.clearLocalStorage();
                    window.location.reload();
                }
            }
        } catch (error) {
            const errorMessage = `Error initializing cookie privacy settings: ${error}`;
            this.sendAndConsoleError(errorMessage);
            this.clearLocalStorage();
        }
    }

    // сохраняет в local storage настройки и возвращает true при успешно сохранении
    public setSettingsToLocalStorage(args: {
        settings: LocalStorageCookiePrivacySettingsState;
        isReloadPageWhenSuccess?: boolean;
    }): boolean {
        const { settings, isReloadPageWhenSuccess = true } = args;
        try {
            if (!settings) {
                return false;
            }

            const isValidCookieSettings = this.isValidCookieSettings(settings);
            if (!isValidCookieSettings) {
                const errorMessage =
                    'Error cookie privacy settings saving to local storage. Cookie privacy settings is not valid';
                this.sendAndConsoleError(errorMessage);
            }

            const isSameKeysAndValuesLikeInLSData = this.getIsSameKeysAndValuesLikeInLSData(settings);

            if (isSameKeysAndValuesLikeInLSData) {
                return true;
            }

            this.LSStorage.set(this.localStorageKey, settings);
            if (isReloadPageWhenSuccess) {
                window.location.reload();
            }
            return true;
        } catch (error) {
            const errorMessage = `Error saving to local storage: ${error}`;
            this.sendAndConsoleError(errorMessage);
            return false;
        }
    }

    private sendAndConsoleError(errorMessage: string) {
        sentryClient.captureException(new SentryError(errorMessage, SENTRY_ERROR_NAME));
        window?.WT?.Page?.logger?.error(errorMessage);
    }

    // Осуществляет проверку на то, что переданные настройки валидные.
    // Функция возвращает false если переданные настройки не валидны и true если валидны.
    public isValidCookieSettings(_cookieSettings: LocalStorageCookiePrivacySettingsState) {
        if (!isObject(_cookieSettings)) {
            return false;
        }

        const keysValuesArrCookieSettings = Object.entries(_cookieSettings);
        const keysDefaultData = Object.keys(this.defaultCookiePrivacySettings);

        // проверяем на наличие данных
        if (!keysValuesArrCookieSettings || keysValuesArrCookieSettings.length < 1) {
            return false;
        }

        // проверяем что длина значений из local storage такая же как и в DEFAULT_COOKIE_PRIVACY_SETTINGS
        if (keysValuesArrCookieSettings.length !== keysDefaultData.length) {
            return false;
        }

        // проверяем все ли ключи такие же как в DEFAULT_COOKIE_PRIVACY_SETTINGS
        if (
            keysValuesArrCookieSettings.filter(([keyParsedLSData]) => !keysDefaultData.includes(keyParsedLSData))
                .length !== 0
        ) {
            return false;
        }

        const requiredSettings = this.getRequiredSettings();

        let isWrongRequiredCookieValues = false;
        // проверяем все ли обязательные ключи cookie включены
        requiredSettings.forEach((_requiredCookieSettingKey) => {
            if (!_cookieSettings[_requiredCookieSettingKey]) {
                isWrongRequiredCookieValues = true;
            }
        });

        if (isWrongRequiredCookieValues) {
            return false;
        }

        // проверяем все ли значения boolean
        return keysValuesArrCookieSettings.filter(([, _value]) => typeof _value !== 'boolean').length <= 0;
    }

    // Сравнивает переданный объект настроек через аргументы с текущими настройками в localStorage.
    // В случае несоответствия данных или если localStorage пуст, метод выводит ошибку.
    // Возвращает false если настройки в аргументе не совпадают с настройками local storage и true если совпадают
    public getIsSameKeysAndValuesLikeInLSData(_cookieSettings: LocalStorageCookiePrivacySettingsState): boolean {
        const LSData = this.getSettingsFromLocalStorage();
        const isEmptyLSData = LSData === null;

        if (isEmptyLSData) {
            const errorMessage = 'Cookie settings is empty in Local Storage. (isSameKeysAndValues func)';
            this.sendAndConsoleError(errorMessage);
            return false;
        }

        const arrKeyValuesLSData = Object.entries(LSData);

        // массив будет заполняться если значения не совпадают
        const arrLogWrongCompare = arrKeyValuesLSData.reduce<
            Array<{ key: CookiePrivacySettingsKey; valueLS: boolean; valueCookieSetting: boolean }>
        >((acc, [keyLS, valueLS]) => {
            const _key = keyLS as CookiePrivacySettingsKey;
            const valueCookieSetting = _cookieSettings[_key];
            if (valueCookieSetting === valueLS) {
                return acc;
            }

            return [...acc, { key: _key, valueLS, valueCookieSetting }];
        }, []);

        return arrLogWrongCompare.length === 0;
    }

    public getSettingsFromLocalStorage(): LocalStorageCookiePrivacySettingsState | null {
        try {
            return this.LSStorage.get(this.localStorageKey);
        } catch (error) {
            const errorMessage = `Error parsing local storage data:${error}. Clear cookie privacy settings and page will be reload`;
            this.sendAndConsoleError(errorMessage);
            this.clearLocalStorage();
            window.location.reload();
            return null;
        }
    }

    private getAllCookiesKeysArr() {
        // куки с установленным флагом HttpOnly не будут доступны через document.cookie
        const cookies = document.cookie;

        const cookieArray = cookies.split(';');

        return cookieArray.map((cookiePair) => cookiePair.split('=')[0].trim());
    }

    // получение массива обязательных (неизменяемых) ключей кук
    private getRequiredCookiesKeysArr() {
        return this.requiredAndDisabledKeysCookiePrivacySettings.reduce<Array<string>>((acc, key) => {
            const defaultValue = this.defaultCookiePrivacySettings[key];

            if (defaultValue) {
                return [...acc, ...COOKIES_KEYS_BY_COOKIE_PRIVACY_SETTINGS_KEYS[key]];
            }

            return acc;
        }, []);
    }

    // Использует данные из localStorage и массив необходимых ключей
    // для формирования списка ключей кук, которые останутся после очистки.
    // Возвращает либо массив ключей кук, либо сообщение "all"
    private getLeftCookiesKeysArr(): string[] | 'all' {
        const LSStorage = this.getSettingsFromLocalStorage();
        const valuesLSStorage = (LSStorage && Object.values(LSStorage)) || [];

        const isAcceptedAllSettings = valuesLSStorage.filter((_value) => _value).length === valuesLSStorage.length;

        if (isAcceptedAllSettings) {
            return 'all';
        }

        const keysCookiesPrivacySettings = Object.keys(
            COOKIES_KEYS_BY_COOKIE_PRIVACY_SETTINGS_KEYS,
        ) as Array<CookiePrivacySettingsKey>;

        const requiredCookiesKeys = this.getRequiredCookiesKeysArr();

        return keysCookiesPrivacySettings.reduce<Array<string>>((acc, cookieKey) => {
            const LSStorageCurrentItemCookiesNames = COOKIES_KEYS_BY_COOKIE_PRIVACY_SETTINGS_KEYS[cookieKey];

            if (requiredCookiesKeys.includes(cookieKey) || LSStorage) {
                return [...acc, ...LSStorageCurrentItemCookiesNames];
            }

            return acc;
        }, []);
    }

    // Извлекает значение из localStorage конкретной настройки соответствующей переданному ключу
    public getCookiePrivacySettingByKey(key: CookiePrivacySettingsKey) {
        const settings = this.getSettingsFromLocalStorage();

        return settings ? settings?.[key] : undefined;
    }

    // Перебирает все куки и удаляет те, которые не остались в списке разрешенных.
    public clearUncheckedSettingsCookies() {
        // получаем все куки
        const cookieNamesAll = this.getAllCookiesKeysArr();
        // получаем разрешенные массивом или сценарий "all" для разрешения всех кук
        const cookieNamesWillLeft = this.getLeftCookiesKeysArr();

        if (cookieNamesWillLeft === 'all') {
            return true;
        }

        const subStringCookies =
            cookieNamesWillLeft?.length > 0
                ? cookieNamesWillLeft.filter((i) => i.includes('*')).map((i) => i.replace('*', ''))
                : [];

        if (cookieNamesWillLeft?.length > 0) {
            cookieNamesAll.forEach((cookieName) => {
                // содержит ли данная кука (строка) что-то из массива подстрок subStringCookies
                const isIncludedInSubstringCookies = !!(
                    subStringCookies?.length > 0 &&
                    subStringCookies.find((subStringCookie) => cookieName.includes(subStringCookie))
                );

                if (!cookieNamesWillLeft.includes(cookieName) && !isIncludedInSubstringCookies) {
                    // Устанавливаем срок действия на прошедшую дату для удаления куки
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
                }
            });
            return true;
        }

        return false;
    }

    private clearLocalStorage() {
        this.LSStorage.delete(this.localStorageKey);
    }

    public isInitSetting(key: CookiePrivacySettingsKey) {
        const valueSetting = this.getCookiePrivacySettingByKey(key);

        if (valueSetting === undefined) {
            return DEFAULT_COOKIE_PRIVACY_SETTINGS.performanceAndAnalyticsCookies;
        }

        return valueSetting;
    }

    public getDefaultSettings() {
        return this.defaultCookiePrivacySettings;
    }

    public getAcceptedAllSettings() {
        return this.acceptedAllCookiePrivacySettings;
    }

    public getRejectedAllSettings() {
        return this.rejectedAllCookiePrivacySettings;
    }

    public getRequiredSettings() {
        return this.requiredAndDisabledKeysCookiePrivacySettings;
    }
}

export const cookiePrivacySettings = new CookiePrivacySettings();
