import styled from 'styled-components';

import { caption12RegularFontStyle } from 'services/typography';

export const NoAccounts = styled.div`
    margin: -2px -8px;
    background-color: ${({ theme }) => theme.fillIn.negative.overlay16};
    border-radius: 6px;
    padding: 8px;
    display: flex;
    gap: 8px;
    color: ${({ theme }) => theme.label.negative};
    ${caption12RegularFontStyle};
    & > svg {
        flex: none;
        fill: ${({ theme }) => theme.label.negative};
    }
`;

export const CreateAccount = styled.div`
    margin: -10px -16px;
    padding: 10px 16px;
    border-top: 1px solid ${({ theme }) => theme.divider.primary};
`;
