import React, { useMemo, useState } from 'react';

import { mapObject } from 'services/utils/map-object';
import { WtPopup3Props } from 'components/wt-popup3';

import { CookiePopupUIProps } from '../types/CookiePrivacySettingsPopupUI.types';
import * as Styled from '../styled/CookiePrivacySettingsPopupUI.styled';

export const CookiePrivacySettingsPopupUI = (props: CookiePopupUIProps) => {
    const { common, settings, acceptAll, reject, saveSettings } = props;

    const { title: titleCommon, content: contentCommon, btnTexts: commonBtnText } = common;
    const {
        acceptAll: acceptBtnTextCommon,
        customize: customizeBtnTextCommon,
        reject: rejectBtnTextCommon,
    } = commonBtnText;

    const {
        title: titleCustomized,
        onChangeItem,
        description: descriptionCustomized,
        textData,
        values,
        disabledKeys,
        btnTexts: btnTextsSettings,
    } = settings;

    const { acceptAll: acceptBtnTextSettings, save: saveBtnTextSettings } = btnTextsSettings;

    const [isSettingsActive, setIsSettingsActive] = useState(false);

    const actionButtons = useMemo((): WtPopup3Props['actionButtons'] => {
        const acceptBtnData = {
            content: isSettingsActive ? acceptBtnTextSettings : acceptBtnTextCommon,
            key: 'accept',
            onClick: () => {
                acceptAll?.();
            },
        };

        if (isSettingsActive) {
            return {
                direction: 'horizontal',
                align: 'right',
                items: [
                    {
                        content: saveBtnTextSettings,
                        key: 'save',
                        kind: 'secondary',
                        onClick: () => {
                            saveSettings();
                            setIsSettingsActive(false);
                        },
                    },
                    acceptBtnData,
                ],
            };
        }

        return {
            direction: 'horizontal',
            align: 'right',
            items: [
                {
                    content: customizeBtnTextCommon,
                    key: 'customize',
                    kind: 'secondary',
                    onClick: () => {
                        setIsSettingsActive(true);
                    },
                },
                {
                    content: rejectBtnTextCommon,
                    key: 'reject',
                    kind: 'secondary',
                    onClick: () => {
                        reject?.();
                    },
                },
                acceptBtnData,
            ],
        };
    }, [
        acceptAll,
        acceptBtnTextCommon,
        acceptBtnTextSettings,
        customizeBtnTextCommon,
        isSettingsActive,
        reject,
        rejectBtnTextCommon,
        saveBtnTextSettings,
        saveSettings,
    ]);

    const titlePopup = useMemo(() => {
        if (isSettingsActive) {
            return titleCustomized;
        }

        return titleCommon;
    }, [isSettingsActive, titleCommon, titleCustomized]);

    const contentPopup = useMemo(() => {
        if (isSettingsActive && textData && values) {
            return (
                <Styled.Content>
                    <Styled.SettingsDescription>{descriptionCustomized}</Styled.SettingsDescription>
                    <Styled.SettingsWrapper>
                        {mapObject(textData, (keyItemProp, valueItemProp) => {
                            const { title, description } = valueItemProp;
                            const valueItem = values?.[keyItemProp];
                            const isChecked = valueItem || false;
                            const isDisabled = !!(valueItem && disabledKeys?.includes(keyItemProp));

                            if (!title || !description) {
                                return null;
                            }

                            return (
                                <Styled.SettingItem
                                    key={keyItemProp}
                                    title={title}
                                    isChecked={isChecked}
                                    description={description}
                                    isDisabled={isDisabled}
                                    onChange={() => {
                                        if (valueItem !== undefined) {
                                            onChangeItem({ key: keyItemProp, isChecked: !isChecked });
                                        }
                                    }}
                                />
                            );
                        })}
                    </Styled.SettingsWrapper>
                </Styled.Content>
            );
        }

        return typeof contentCommon === 'string' ? <span>{contentCommon}</span> : contentCommon;
    }, [contentCommon, descriptionCustomized, disabledKeys, isSettingsActive, onChangeItem, textData, values]);

    return (
        <Styled.Popup
            title={titlePopup}
            width={530}
            actionButtons={actionButtons}
            isMobileFullscreen={isSettingsActive}
        >
            {contentPopup}
        </Styled.Popup>
    );
};
