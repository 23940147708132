import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

const isTextOverflowing = (element: HTMLSpanElement) => element.scrollWidth > element.clientWidth;

const DELAY = 600;

export type useIsTextOverflowEllipsisArgs = { ref: React.RefObject<HTMLElement>; isActive?: boolean };

export const useIsTextOverflowEllipsis = (args: useIsTextOverflowEllipsisArgs) => {
    const { ref, isActive = true } = args;

    const [isTextOverflowEllipsis, setIsTextOverflowEllipsis] = useState(false);

    const checkOverflow = useCallback(() => {
        if (ref.current && isActive) {
            setIsTextOverflowEllipsis(isTextOverflowing(ref.current));
        }
    }, [ref, isActive]);

    useEffect(() => {
        // Отсрочка для учета всех необходимых вычислений и загрузок
        const timer = setTimeout(() => {
            checkOverflow();
        }, DELAY);

        return () => clearTimeout(timer);
    }, [checkOverflow]);

    useEffect(() => {
        const handleResize = debounce(checkOverflow, DELAY);

        window.addEventListener('resize', handleResize);
        return () => {
            handleResize.cancel();
            window.removeEventListener('resize', handleResize);
        };
    }, [checkOverflow]);

    return isTextOverflowEllipsis;
};
