/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebResponse_UserCreditsEntity } from '../models/CreditsService/WebResponse_UserCreditsEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CreditsService {
    /**
     * Получить количество кредитов для данного пользователя
     * @returns WebResponse_UserCreditsEntity Success
     * @throws ApiError
     */
    public static getCredit({
        userId,
    }: {
        /**
         * Идентификатор пользователя
         */
        userId: string;
    }): CancelablePromise<WebResponse_UserCreditsEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/credits/{userId}',
            path: {
                userId: userId,
            },
        });
    }

    /**
     * Изменить количество кредитов
     * @returns WebResponse_UserCreditsEntity Success
     * @throws ApiError
     */
    public static updateCredit({
        userId,
        countDifference,
    }: {
        /**
         * Идентификатор пользователя
         */
        userId: string;
        /**
         * Количество на которое нужно изменить количество кредитов(может быть отрицательным)
         */
        countDifference?: number;
    }): CancelablePromise<WebResponse_UserCreditsEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/private/api/v1/credits/{userId}',
            path: {
                userId: userId,
            },
            query: {
                countDifference: countDifference,
            },
        });
    }

    /**
     * Получить количество кредитов для данного пользователя
     * @returns WebResponse_UserCreditsEntity Success
     * @throws ApiError
     */
    public static getMyCredits(): CancelablePromise<WebResponse_UserCreditsEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/credits',
        });
    }
}
