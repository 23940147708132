import { connect } from 'react-redux';

import { addBlacklist, removeBlacklist, getFollowers, reduceFollowingCount } from './actions';
import { ActionBlacklist } from './components';
import { BlacklistRenderProps } from './types';

const mapDispatchToProps = {
    addBlacklist,
    removeBlacklist,
    getFollowers,
    reduceFollowingCount,
};

export const ActionBlacklistContainer = connect(null, mapDispatchToProps)(ActionBlacklist);
export type { BlacklistRenderProps };
