import styled, { css } from 'styled-components';

import { mediaQuery } from 'app/styled';
import { Button } from 'components/button2';
import { ButtonBadge as ButtonBadgeComponent } from 'components/button-badge';
import { ButtonIcon } from 'components/button-icon';

export const NavigationMenuWrapper = styled.div<{ withBgColorMobile: boolean }>`
    box-sizing: border-box;
    position: sticky;
    z-index: 302;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: inherit;
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQuery.lt960} {
        padding: 12px 24px;
    }

    ${mediaQuery.lt768} {
        padding: 8px;
        height: 56px;
        background-color: ${({ withBgColorMobile, theme }) => (withBgColorMobile ? theme.bg.primary : 'inherit')};
    }
`;

export const Divider = styled.div<{ withBottomIndentDividerMobile: boolean }>`
    position: sticky;
    top: 64px;
    height: 1px;
    z-index: 3;
    background-color: ${({ theme }) => theme.divider.primary};

    margin-bottom: 22px;

    ${mediaQuery.lt960} {
        margin-bottom: 10px;
    }

    ${mediaQuery.lt768} {
        top: 56px;
        ${({ withBottomIndentDividerMobile }) =>
            withBottomIndentDividerMobile
                ? ''
                : css`
                      margin-bottom: 0;
                  `};
    }
`;

export const DividerCover = styled.div`
    position: relative;
    top: -23px;
    height: 1px;
    background-color: inherit;
    z-index: 4;

    ${mediaQuery.lt960} {
        top: -11px;
    }
`;

export const ContentBox = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1320px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SearchWrapper = styled.div`
    flex: none;
    margin-right: auto;
    margin-left: 0;

    ${mediaQuery.lt1200} {
        margin-left: auto;
        margin-right: 8px;
    }

    ${mediaQuery.lt768} {
        margin-left: 0;
    }
`;

export const NotificationsWrapper = styled.div<{ isHidden?: boolean }>`
    transition: all 360ms;
    margin-right: ${({ isHidden }) => (isHidden ? '0' : '8px')};
    opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
    width: ${({ isHidden }) => (isHidden ? '0' : '40px')};
`;

export const MobileMenuButtonWrapper = styled.div`
    margin-right: 8px;
    margin-left: auto;
`;

export const RegisterButton = styled(Button)`
    margin-left: 8px;
`;

export const FavoritesButtonStyled = styled(ButtonIcon)`
    margin-right: 8px;
`;

export const ButtonBadge = styled(ButtonBadgeComponent)`
    position: unset;
`;
