import React from 'react';

import { IconSvg, IconComponent } from '.';

export const StarIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.965 2.19995C12.2881 2.19995 12.5795 2.39436 12.7036 2.69273L15.0287 8.28301L21.0639 8.76685C21.386 8.79267 21.661 9.00975 21.7608 9.31708C21.8607 9.62441 21.7659 9.96163 21.5204 10.1719L16.9223 14.1107L18.3271 20C18.4021 20.3143 18.2806 20.6429 18.0191 20.8328C17.7577 21.0228 17.4077 21.0368 17.1319 20.8683L11.965 17.7124L6.798 20.8683C6.52223 21.0368 6.1722 21.0228 5.91077 20.8328C5.64934 20.6429 5.52785 20.3143 5.60283 20L7.00764 14.1107L2.40947 10.1719C2.16406 9.96163 2.06922 9.62441 2.16907 9.31708C2.26893 9.00975 2.54388 8.79267 2.86599 8.76685L8.90117 8.28301L11.2263 2.69273C11.3504 2.39436 11.6418 2.19995 11.965 2.19995ZM11.965 5.08312L10.1908 9.34863C10.0756 9.62562 9.81514 9.81487 9.5161 9.83885L4.91113 10.208L8.41963 13.2134C8.64746 13.4086 8.74696 13.7148 8.67736 14.0066L7.60545 18.5003L11.548 16.0922C11.804 15.9359 12.1259 15.9359 12.382 16.0922L16.3245 18.5003L15.2526 14.0066C15.1829 13.7148 15.2824 13.4086 15.5103 13.2134L19.0188 10.208L14.4138 9.83885C14.1148 9.81487 13.8543 9.62562 13.7391 9.34863L11.965 5.08312Z"
        />
    </IconSvg>
);
