import {
    Action,
    FeedOwnerType,
    FeedRecordObj,
    FeedRecordType,
    InstrumentData,
    Person,
    PostType,
    VisibilityLevels,
} from 'types';
import { defaultPublishStatusRecord } from 'types/feed-record/constants';
import { Actions, createThunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';

import { GlobalStateForFeed } from '../../types';
import { PublisherFn } from '../../add-status';

import { insertFeedRecord, InsertFeedRecordActions } from './insertFeedRecord';

export type PublishFeedRecordPayloadData = {
    post: FeedRecordObj;
    community?: Person;
    errorMap?: {
        text: string;
    }[];
    result?: { ok: boolean; exception?: { message: string } };
};
export type PublishRequestData = {
    html: string;
    json: string;
    csrf: string;
    feedId: number | string | null;
    feedType: number | null;
    groupId: number | null;
    postType: string;
    tags: number[];
};

const [PUBLISH_FEED_RECORD_ACTION_TYPES, thunk, publishActions] = createThunkActions<'widgets', GlobalStateForFeed>()(
    'PUBLISH_FEED_RECORD',
)<PublishRequestData, PublishFeedRecordPayloadData, never, InsertFeedRecordActions>();
type PublishFeedRecord = Action<typeof publishFeedRecord>;
type PublishFeedRecordActions = Actions<typeof publishActions>;

function publishFeedRecord(...args: Parameters<PublisherFn>): ReturnType<typeof thunk> {
    const [instanceId, html, json, { csrf, owner, postType, generator, communityId, tags }] = args;

    let data: PublishRequestData = {
        html,
        json,
        csrf,
        feedId: postType === PostType.COMMUNITY ? communityId ?? 0 : owner?.id || 0,
        feedType: postType === PostType.COMMUNITY ? FeedOwnerType.COMMUNITY : owner?.type || 0,
        groupId: null,
        postType: owner?.type === FeedOwnerType.COMMUNITY ? PostType.COMMUNITY : postType || PostType.ORDINARY,
        tags: tags || [],
    };

    if (owner && 'groupId' in owner) {
        data = {
            ...data,
            feedId: owner.id,
            feedType: owner.type,
            groupId: owner.groupId,
        };
    }

    return (dispatch, getState, extraArgument) => {
        let actions = thunk({
            request: {
                method: 'POST',
                url: UrlProcessor.page('ng_limex_blogpost_create').path(),
                data,
                withCredentials: true,
                // TODO: Fixme!
                // dmh: без обоих этих заголовков бек выдает html, игнорируя Accept #WTT-9729
                headers: {
                    'X-Jquery-Json': 'json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            },
        })(dispatch, getState, extraArgument);
        actions = actions.then((action) => {
            if (action.payload.data.result && !action.payload.data.result.ok) {
                const errorMessage = action.payload?.data?.result?.exception?.message;
                const errorMap = action.payload?.data?.errorMap;
                const errorInfo = errorMap?.map((e) => e.text).join(' ') ?? '';
                const error = new Error([errorMessage, errorInfo].filter(Boolean).join(': '));

                return Promise.reject(error);
            }
            return Promise.resolve(action);
        });
        actions.then((action) => {
            const { post: blogpost, community: context } = action.payload.data;
            blogpost.data = {
                statsUsers: { voters: [], topUsers: [], commentUsers: [], shareUsers: [], forecastVoters: [] },
            };
            blogpost.stats.likes = {
                positive: 0,
                negative: 0,
                userChoice: null,
            };

            dispatch(
                insertFeedRecord(
                    [
                        {
                            ...defaultPublishStatusRecord,
                            id: blogpost.id,
                            type: FeedRecordType.PUBLISH_STATUS,
                            obj: blogpost,
                            options: { visLevel: VisibilityLevels.ANY, statusDid: 1 },
                            comments: [],
                            stats: {
                                commentsCount: 0,
                                commentsCountGeneratedAt: blogpost.date.created,
                                sharesCount: 0,
                                viewsCount: 0,
                                likes: {
                                    positive: 0,
                                    negative: 0,
                                    userChoice: null,
                                },
                            },
                            publishedId: blogpost.id,
                            generator,
                            context: context ?? { type: null },
                        },
                    ],
                    0,
                    instanceId,
                ),
            );
        });
        return actions;
    };
}

export { PUBLISH_FEED_RECORD_ACTION_TYPES, publishFeedRecord, PublishFeedRecord, PublishFeedRecordActions };
