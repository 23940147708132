import React, { FC } from 'react';

import { useLocaleId } from 'hooks/useLocaleId';
import { footerSocialIcons, SocialIcons } from 'components/social-icons';
import { LimexEmailLink } from 'components/limex-email';

import { ContextMenuFooterRow, Email, FooterStyled } from './styled';

export const Footer: FC = () => {
    const lcid = useLocaleId();
    return (
        <FooterStyled>
            <ContextMenuFooterRow>
                <Email>
                    <span>Email:</span> <LimexEmailLink namePart="feedback" />
                </Email>
            </ContextMenuFooterRow>
            <ContextMenuFooterRow>
                <SocialIcons icons={footerSocialIcons[lcid]} />
            </ContextMenuFooterRow>
        </FooterStyled>
    );
};
