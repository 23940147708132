/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentExporter } from '../models/shared/InstrumentExporter';
import type { UserExporter } from '../models/shared/UserExporter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {
    /**
     * Получить количество подписок пользователя по пользователям, инструментам, премиум-публикациям и др.
     * @returns any Успешное получение счетчиков
     * @throws ApiError
     */
    public static getActivitySubscriptionCounters(): CancelablePromise<{
        /**
         * Количество подписок на публикации пользователей
         */
        '1'?: number;
        /**
         * Количество подписок на избранные инструменты
         */
        '5'?: number;
        /**
         * Количество подписок на премиум-публикации
         */
        '36'?: number;
        /**
         * Количество подписок на премиум-публикации
         */
        '37'?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/activity/subscription/counters/',
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить сущности подписок пользователя (пользователи, инструменты, премиум-публикации и др.)
     * @returns any Успешное получение подписок
     * @throws ApiError
     */
    public static getSubscriptions({
        generatorType,
        cursor = 1,
    }: {
        /**
         * Тип сущности подписок (1 - Пользователи, 5 - Инструменты, 36 - Премиум-публикации, 37 - Сообщества)
         */
        generatorType: 1 | 5 | 36 | 37;
        /**
         * Курсор пиганации
         */
        cursor?: number;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список сущностей подписок пользователя
         */
        items?: Array<UserExporter> | Array<InstrumentExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/activity/subscription/{generatorType}/',
            path: {
                generatorType: generatorType,
            },
            query: {
                cursor: cursor,
            },
            errors: {
                400: `Неверно передан параметр "generatorType"`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить сущности подписчиков пользователя (пользователи)
     * @returns any Успешное получение подписчиков
     * @throws ApiError
     */
    public static getFollowers({
        subscriberId,
        cursor = 1,
    }: {
        /**
         * ИД вероятного подписчика (фильтр, если он подписчик, то вернется в ответе)
         */
        subscriberId?: number;
        /**
         * Курсор пиганации
         */
        cursor?: number;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список сущностей подписчиков пользователя
         */
        items?: Array<UserExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/activity/followers/',
            query: {
                subscriberId: subscriberId,
                cursor: cursor,
            },
            errors: {
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Подписаться на сущность
     * @returns any Успешная подписка на сущность
     * @throws ApiError
     */
    public static createSubscription({
        generatorId,
        generatorType,
    }: {
        /**
         * Идентификатор сущности на которую подписываемся
         */
        generatorId: number;
        /**
         * Тип сущности на которую подписываемся (1 - Пользователи, 2 - Группа, 5 - Инструменты, 20 - Теги, 36 - Премиум-публикации, 37 - Сообщества)
         */
        generatorType: 1 | 2 | 5 | 20 | 36 | 37;
    }): CancelablePromise<{
        status: {
            subscription: boolean;
        };
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/activity/subscription/{generatorId}/{generatorType}/',
            path: {
                generatorId: generatorId,
                generatorType: generatorType,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Отписаться от сущности
     * @returns any Успешная отписка от сущности
     * @throws ApiError
     */
    public static deleteSubscription({
        generatorId,
        generatorType,
    }: {
        /**
         * Идентификатор сущности от которой отписываемся
         */
        generatorId: number;
        /**
         * Тип сущности от которой отписываемся (1 - Пользователи, 1 - Группа, 5 - Инструменты, 20 - Теги, 36 - Премиум-публикации, 37 - Сообщество)
         */
        generatorType: 1 | 2 | 5 | 20 | 36 | 37;
    }): CancelablePromise<{
        status: {
            subscription: boolean;
        };
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/activity/subscription/{generatorId}/{generatorType}/',
            path: {
                generatorId: generatorId,
                generatorType: generatorType,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Пакетная подписка на сущности. Возможна подписка для незарегистрированных пользователей с сохранением в сессию
     * @returns any Успешная подписка на сущности
     * @throws ApiError
     */
    public static createBatchSubscriptions({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Список объектов
             */
            items?: Array<{
                /**
                 * Идентификатор сущности на которую подписываемся
                 */
                generatorId: number;
                /**
                 * Тип сущности на которую подписываемся (1 - Пользователи, 2 - Группа, 5 - Инструменты, 20 - Теги, 36 - Премиум-публикации)
                 */
                generatorType: number;
            }>;
        };
    }): CancelablePromise<{
        /**
         * Генераторы на которые осуществлена подписка
         */
        success?: Array<{
            /**
             * Идентификатор сущности на которую подписывались
             */
            id: number;
            /**
             * Тип сущности на которую подписывались (1 - Пользователи, 2 - Группа, 5 - Инструменты, 20 - Теги, 36 - Премиум-публикации)
             */
            type: number;
        }>;
        /**
         * Генераторы на которые не удалось подписаться
         */
        fail?: Array<{
            /**
             * Идентификатор сущности на которую подписывались
             */
            id: number;
            /**
             * Тип сущности на которую подписывались (1 - Пользователи, 2 - Группа, 5 - Инструменты, 20 - Теги, 36 - Премиум-публикации)
             */
            type: number;
        }>;
        /**
         * Id пользователя для которого осуществлена подписка, либо Null для анонимного пользователя
         */
        user?: number | null;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/activity/subscription/batch/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
