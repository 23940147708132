import styled from 'styled-components';

import { Chart as ChartComponent } from 'components/chart';

import { SkeletonWrapper } from '../../partials/default-layout/styled';
import { CardThumbnail as CardThumbnailComponent } from '../../partials/thumbnail';

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px 16px;
`;

export const HeaderLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CardTitleBlockWrapper = styled.div``;

export const ChartThumbnailWrapper = styled.div`
    height: 100px;
    transition: all 360ms;
    margin: 0 -4px;
`;

export const ParametersTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px 16px 16px 16px;
    justify-content: flex-end;
`;

export const PriceContainer = styled.div`
    padding: 12px 16px;
`;

export const TagsWrapper = styled.div`
    padding: 12px 16px;
`;

export const ActionButtonsWrapper = styled.div`
    padding: 12px 16px;
`;

export const CardThumbnail = styled(CardThumbnailComponent)`
    height: 100%;
`;

export const GraphicSkeletonWrapper = styled(SkeletonWrapper)`
    display: flex;
    justify-content: flex-end;
`;

export const Chart = styled(ChartComponent)`
    .recharts-wrapper {
        cursor: inherit !important;
    }
`;
