import React from 'react';

import { IconSvg, IconComponent } from '.';

export const PlusIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4953 5C12.9372 5 13.2953 5.35817 13.2953 5.8L13.2953 11.2034L18.6795 11.2034C19.1213 11.2034 19.4795 11.5615 19.4795 12.0034C19.4795 12.4452 19.1213 12.8034 18.6795 12.8034L13.2953 12.8034L13.2953 18.2C13.2953 18.6418 12.9372 19 12.4953 19C12.0535 19 11.6953 18.6418 11.6953 18.2L11.6953 12.8034H6.27949C5.83766 12.8034 5.47949 12.4452 5.47949 12.0034C5.47949 11.5615 5.83766 11.2034 6.27949 11.2034H11.6953L11.6953 5.8C11.6953 5.35817 12.0535 5 12.4953 5Z"
        />
    </IconSvg>
);
