import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { CommentIcon } from 'components/icons2/CommentIcon';
import { CommentFilledIcon } from 'components/icons2/CommentFilledIcon';
import { BookmarkIcon } from 'components/icons2/BookmarkIcon';
import { ShareIcon } from 'components/icons2/ShareIcon';
import { EyeIcon } from 'components/icons2/EyeIcon';

import { postSidePadding } from '../../styled';

export const Footer = styled.div`
    ${postSidePadding};

    margin-top: 14px;
    display: flex;
    justify-content: space-between;
`;

export const CommentButton = styled(ButtonIcon).attrs((props) => ({
    icon: { component: props.pressed ? CommentFilledIcon : CommentIcon },
    kind: ButtonIconKinds.GhostPrimary,
}))`
    margin-left: 8px;
`;

export const CommentText = styled.span`
    ${mediaQuery.lt960} {
        display: none;
    }
`;

export const BookmarkButton = styled(ButtonIcon).attrs({ icon: { component: BookmarkIcon } })`
    margin-right: 8px;
`;

export const ShareButton = styled(ButtonIcon).attrs({ icon: { component: ShareIcon } })``;

export const ViewsCount = styled(ButtonIcon).attrs({
    icon: { component: EyeIcon },
    kind: ButtonIconKinds.GhostPrimary,
})`
    pointer-events: none;
`;

export const Group = styled.div`
    display: flex;
    align-items: center;
`;
