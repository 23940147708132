import styled from 'styled-components';

export const CurrencyIconWrapper = styled.div<{ isActive?: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${({ isActive, theme }) => (isActive ? theme.label.secondaryHigh : theme.label.secondary)};
    }
`;

export const PriceComboBoxWrapper = styled.div`
    display: flex;
    gap: 12px;
    flex: auto;

    & > :first-child {
        flex-grow: 1;
    }
`;
