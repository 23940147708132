import React, { useMemo } from 'react';
import cn from 'classcat';

import { convertResponsiveSize } from 'app/styled';

import { getShortSizeValueBySizeValue } from './utils';
import { DEFAULT_SIZE_COUNTER } from './constants';
import { CounterPropsType, CounterShortNameSizeValue, CounterSizeValue } from './types';
import * as Styled from './styled';

export const Counter = (props: CounterPropsType) => {
    const { children, size: sizeProp = DEFAULT_SIZE_COUNTER, className: classNameProp } = props;

    const size = useMemo(
        () =>
            convertResponsiveSize<CounterSizeValue, CounterShortNameSizeValue>({
                size: sizeProp,
                handler: getShortSizeValueBySizeValue,
                defaultValue: DEFAULT_SIZE_COUNTER,
            }) || DEFAULT_SIZE_COUNTER,
        [sizeProp],
    );

    const className = cn(['autotest__counter', classNameProp]);

    return (
        <Styled.CounterWrapper size={size} className={className}>
            <Styled.Text size={size}>{children}</Styled.Text>
        </Styled.CounterWrapper>
    );
};
