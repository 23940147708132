import React, { useEffect, useState } from 'react';

import { FinamProductItem } from 'types/finam-product';
import { useDictionary } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';
import { WtPopup3 } from 'components/wt-popup3';
import { ImageBlock } from 'components/image';

import { ContentWrapper } from './styled';
import shield from './shield.png';

type Props = {
    type: 'payLater' | 'justPayed';
    productType: FinamProductItem['productType'];
    close: () => void;
    subscriptionPage: string;
    subscriptionsPageLink?: string;
};

export const SubscriptionSuccessPopup = (props: Props) => {
    const { close, type, subscriptionPage, productType, subscriptionsPageLink } = props;
    const dic = useDictionary();
    const links = useLinks();
    const dctPart = type === 'payLater' ? 'pay_later' : 'just_payed';

    const typeName = (() => {
        switch (productType) {
            case 'UsStrategy':
                return 'trading';
            case 'UsSignal':
                return 'signals';
            case 'UsUserProduct':
                return 'product';
            default:
                return 'trading';
        }
    })();

    const buttonType = (() => {
        switch (productType) {
            case 'UsStrategy':
                return 'strategy';
            case 'UsSignal':
                return 'signals';
            case 'UsUserProduct':
                return 'product';
            default:
                return 'trading';
        }
    })();

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowPopup(true));
    }, []);

    return showPopup ? (
        <WtPopup3
            portalTo="popupRoot"
            width={456}
            close={close}
            isMobileFullscreen
            actionButtons={{
                items: [
                    ...(productType === 'UsUserProduct'
                        ? []
                        : [
                              {
                                  key: 0,
                                  content: dic.word('wt_mplc2__widget_brokerage_popup__success_btn_subscriptions'),
                                  kind: 'secondary' as const,
                                  // TODO выпилить отсюда useLinks, пройтись по проекту и передавать сюда нужную ссылку
                                  href: subscriptionsPageLink || links.homeSubscriptions(),
                              },
                          ]),
                    {
                        key: 1,
                        content: dic.word(`wt_mplc2__widget_brokerage_popup__success_btn_${buttonType}`),
                        kind: 'primary',
                        onClick: productType === 'UsUserProduct' ? close : undefined,
                        href: productType === 'UsUserProduct' ? undefined : subscriptionPage,
                    },
                ],
                direction: { default: 'horizontal', lt480: 'vertical' },
                align: 'right',
            }}
            title={dic.word(`wt_mplc2__widget_brokerage_popup__success_title_${dctPart}`)}
        >
            <ContentWrapper>
                {dic.word(`wt_mplc2__widget_brokerage_popup__success_text_${typeName}_${dctPart}`)}
                <ImageBlock src={shield} />
            </ContentWrapper>
        </WtPopup3>
    ) : null;
};
