import styled, { css } from 'styled-components';

import { reduceBreakpoints } from 'app/styled';

import { ImageBlockProps, ImageBlockSize, ImageBlockSizeValue } from '../types';
import { TRANSITION_ALL_CSS } from '../constants';

import { Image } from './Image.styled';

const getStringStyleParams = (styleParams: ImageBlockSizeValue) => {
    const { height, width, borderRadius, objectFit = 'cover' } = styleParams;

    const addingPxString = (param: string | number) => (typeof param === 'number' ? 'px' : '');

    return css`
        ${height ? `height: ${height}${addingPxString(height)}` : ''};
        ${width ? `width: ${width}${addingPxString(width)}` : ''};
        ${borderRadius ? `border-radius: ${borderRadius}${addingPxString(borderRadius)}` : ''};

        ${Image} {
            width: 100%;
            height: 100%;
            object-fit: ${objectFit};
        }
    `;
};

const buildSizes = (size: ImageBlockSize) => {
    if (size) {
        return reduceBreakpoints(size, getStringStyleParams);
    }

    return '';
};

export const ImageBlock = styled.div<
    Pick<ImageBlockProps, 'height' | 'width' | 'onClick' | 'borderRadius' | 'isDisableDefaultBg'> &
        Required<Pick<ImageBlockProps, 'objectFit'>> & {
            size?: ImageBlockSize;
            isSuccessImage: boolean;
        }
>`
    display: inline-block;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
    overflow: hidden;
    background-color: ${({ theme, isSuccessImage, isDisableDefaultBg }) => {
        if (isSuccessImage || isDisableDefaultBg) {
            return 'transparent';
        }

        return theme.fillIn.secondary.overlay24;
    }};

    ${(args) => {
        const { size } = args;

        if (size) {
            return buildSizes(size);
        }

        return getStringStyleParams(args);
    }};

    ${TRANSITION_ALL_CSS};
`;
