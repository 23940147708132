/* eslint-disable @typescript-eslint/no-explicit-any */
import memoize from 'lodash/memoize';

import { Id, Lcid } from 'types';
import UrlProcessor from 'services/url-processor';
import { notEmpty } from 'services/utils/not-empty';
import { mapObject } from 'services/utils/map-object';
import { excludeUndefinedProperties } from 'services/utils/exclude-undefined-properties';
import { useLocaleId } from 'hooks/useLocaleId';
import {
    SELECTED_PRODUCT_ID_URL_PARAM_KEY,
    SELECTED_PRODUCT_TYPE_URL_PARAM_KEY,
} from 'widgets/mp-share/product-pages/pages/constants';

type Func = (...args: any[]) => any;

type WithExtraMethods<T> = {
    [K in keyof T]: T[K] extends Func ? T[K] & { withoutRoot?: T[K]; withBaseUrl: T[K] } : T[K];
};

const buildSearch = (basePath: string, searchObj: Record<string, string | undefined>) => {
    const params = new URLSearchParams(excludeUndefinedProperties(searchObj)).toString();
    return `${basePath}${params.length ? `?${params}` : ''}`;
};

export const getLinks = memoize((lcid: Lcid) => {
    const _links = {
        feed: () => UrlProcessor.page('limex_feed').path(),
        communities: () => UrlProcessor.page('community_list_page').path(),
        community: (communityId: Id) =>
            UrlProcessor.page('feed_community_page')
                // отключаем encode так как иногда сюда приходит строка ":communityId" - это верно
                .param('communityId', communityId, { encode: false })
                .path(),
        tag: (tagId: Id) =>
            UrlProcessor.page('feed_tag_page')
                // отключаем encode так как иногда сюда приходит строка ":tagId" - это верно
                .param('tagId', tagId, { encode: false })
                .path(),
        shop: () => UrlProcessor.page('wt_marketplace_root').path(),
        shopProduct: (type: string, slug: string) =>
            UrlProcessor.page('wt_marketplace_product_item')
                .params({
                    type_alias: type,
                })
                .param('slug', slug, { encode: false })
                .path(),
        shop2: () => UrlProcessor.page('wt_marketplace_v2_root').path(),
        shop2Catalog: (display: string, productType: string) =>
            UrlProcessor.page('wt_marketplace_v2_root').queryArgs({ display, productType }).path(),
        shop2Product: (productId: number, productType: string) =>
            buildSearch(_links.shop2(), {
                [SELECTED_PRODUCT_ID_URL_PARAM_KEY]: `${productId}`,
                [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType,
            }),
        mySpaceRoot: (hash?: string) => ['/home_old', hash].filter(notEmpty).join('#'),
        mySpaceSignals: () => '/home_old/signals',
        mySpaceSubscriptions: () => '/home_old/subscriptions',
        mySpaceSubscription: (productId: Id, productType?: string | null) =>
            buildSearch(`/home_old/subscription/${productId}`, { type: productType ?? undefined }),
        mySpaceFavorite: () => '/home_old/favorite',
        mySpaceOrder: () => '/home_old/order',
        mySpacePaymentSuccess: () => '/home_old/payment/success',
        mySpacePaymentFail: () => '/home_old/payment/failure',
        homeRoot: (productType?: string | null) =>
            buildSearch('/home/', { [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined }),
        homeFavorites: (productType?: string | null) =>
            buildSearch('/home/favorites', { [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined }),
        homeFavorite: (productId: Id, productType?: string | null) =>
            buildSearch(`/home/favorite/${productId}`, {
                [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined,
            }),
        homeSubscriptions: (productType?: string | null) =>
            buildSearch('/home/subscriptions', { [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined }),
        // productType тут должен быть обязательно в navigate, иначе будет показан ErrorPageBlock, но в matchRoute можно без него
        homeSubscription: ({
            productId,
            productType,
            isSuccess,
        }: {
            productId: Id;
            productType?: string | null;
            isSuccess?: boolean;
        }) =>
            buildSearch(`/home/subscription/${productId}`, {
                [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined,
                result: isSuccess ? 'success' : undefined,
            }),
        homeProduct: ({
            productId,
            productType,
            isSuccess,
        }: {
            productId: Id;
            productType?: string | null;
            isSuccess?: boolean;
        }) =>
            buildSearch(`/home/product/${productId}`, {
                [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined,
                result: isSuccess ? 'success' : undefined,
            }),
        creatorRoot: (productType?: string | null) =>
            buildSearch('/creator', {
                [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined,
            }),
        creatorOverview: () => '/creator/overview/',
        creatorMyProducts: () => '/creator/products/',
        creatorMyProductsProductType: (productType: string) =>
            buildSearch('/creator/products/', { [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType }),
        creatorAnalyticsProducts: () => '/creator/analytics/products/',
        creatorAnalyticsSocial: (searchValue?: { analyticsType?: 'followers' | 'likes' }) => {
            const { analyticsType } = searchValue || {};
            return buildSearch('/creator/analytics/social/', {
                'analytics-type': analyticsType,
            });
        },
        creatorMyProduct: (productType: string, productId: Id) => `/creator/products/${productType}/${productId}/`,
        creatorReviews: (productId?: string) =>
            buildSearch('/creator/reviews', { [SELECTED_PRODUCT_ID_URL_PARAM_KEY]: productId }),
        creatorAi: () => '/creator/ai/',
        creatorAiWizard: (portfolioId?: string) => `/creator/ai/wizard/${portfolioId ?? ''}`,
        creatorProductsSignals: (productId: Id) => `/creator/products/${productId}/signals`,
        profileById: ({ id, isAddFeedStatusActive }: { id: Id; isAddFeedStatusActive?: boolean }) =>
            UrlProcessor.page('user_profile_page_personal')
                .param('person_id', id, { encode: false })
                .queryArgs(
                    excludeUndefinedProperties({ isAddFeedStatusActive: isAddFeedStatusActive ? '' : undefined }),
                )
                .path(),
        profileByAlias: (alias: string) =>
            UrlProcessor.page('user_profile_page_personal_url').param('@alias', alias, { encode: false }).path(),
        profilePremiumById: (id: Id) =>
            UrlProcessor.page('user_profile_page_personal_premium').param('person_id', id, { encode: false }).path(),
        profilePremiumByAlias: (alias: string) =>
            UrlProcessor.page('user_profile_page_personal_url_personal_premium')
                .param('@alias', alias, { encode: false })
                .path(),
        pinnedPostById: (personId: Id, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_pinned')
                .param('person_id', personId, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPostFullById: (personId: Id, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_pinned_full')
                .param('person_id', personId, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPremiumPostById: (personId: Id, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_premium_pinned')
                .param('person_id', personId, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPremiumPostFullById: (personId: Id, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_premium_pinned_full')
                .param('person_id', personId, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPostByAlias: (alias: string, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_url_personal_pinned')
                .param('@alias', alias, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPostFullByAlias: (alias: string, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_url_personal_pinned_full')
                .param('@alias', alias, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPremiumPostByAlias: (alias: string, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_url_personal_premium_pinned')
                .param('@alias', alias, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        pinnedPremiumPostFullByAlias: (alias: string, postId: Id) =>
            UrlProcessor.page('user_profile_page_personal_url_personal_premium_pinned_full')
                .param('@alias', alias, { encode: false })
                .param('pinned', postId, { encode: false })
                .path(),
        profileLikesById: (id: Id) =>
            UrlProcessor.page('user_profile_page_personal_likes')
                .params({
                    person_id: id,
                })
                .path(),
        profileLikesByAlias: (alias: string) =>
            UrlProcessor.page('user_profile_page_personal_url_likes').param('@alias', alias, { encode: false }).path(),
        profileProductsById: (id: Id) =>
            UrlProcessor.page('user_profile_page_personal_product')
                .params({
                    person_id: id,
                })
                .path(),
        profileProductsByAlias: (alias: string) =>
            UrlProcessor.page('user_profile_page_personal_url_product')
                .param('@alias', alias, { encode: false })
                .path(),
        cabinetRoot: (productType?: string | null) =>
            buildSearch('/settings/', { [SELECTED_PRODUCT_TYPE_URL_PARAM_KEY]: productType ?? undefined }),
        cabinetMonetization: () => '/settings/monetization',
        cabinetSecurity: () => '/settings/security',
        cabinetSubscriptions: () => '/settings/subscriptions',
        cabinetNotifications: () => '/settings/notifications',
        cabinetBlacklist: () => '/settings/blacklist',
        cabinetReferral: () => '/settings/referral',
        instrument: (ticker: string, id?: Id) => {
            const mainInstrumentLink = UrlProcessor.page('limex_instrument_page').param('cashtag', `$${ticker}`, {
                encode: false,
            });

            const instrumentLink = id ? mainInstrumentLink.queryArg('id', id) : mainInstrumentLink;

            return instrumentLink.path();
        },
        instrumentPinned: (ticker: string, pinned: Id, id?: Id) => {
            const mainInstrumentLink = UrlProcessor.page('limex_instrument_page_pinned')
                .param('cashtag', `$${ticker}`, { encode: false })
                .param('pinned', pinned, { encode: false });

            const instrumentLink = id ? mainInstrumentLink.queryArg('id', id) : mainInstrumentLink;

            return instrumentLink.path();
        },
        instrumentPinnedFull: (ticker: string, pinned: Id, id?: Id) => {
            const mainInstrumentLink = UrlProcessor.page('limex_instrument_page_pinned_full')
                .param('cashtag', `$${ticker}`, { encode: false })
                .param('pinned', pinned, { encode: false });

            const instrumentLink = id ? mainInstrumentLink.queryArg('id', id) : mainInstrumentLink;

            return instrumentLink.path();
        },
        connectLimeAccount: () => 'https://myaccount.lime.co',
        cabinetLimeAccount: () => 'https://tx.lime.co/profile',
        tildaPartnersLimexMeRoot: () => 'https://partners.limex.me/ru/',
        tildaInfoLimexMeRoot: ({ isRuLang }: { isRuLang: boolean }) =>
            `https://www.info.limex.me/${isRuLang ? 'ru/' : ''}`,
        tildaInfoLimexMePrivacyPolicy: () => `https://www.info.limex.me/${lcid}/privacy-policy/`,
        tildaInfoLimexMeDisclaimer: () => `https://info.limex.me/${lcid}/disclaimer/`,
        tildaInfoLimexMeCookiesAndTrackers: () => `https://info.limex.me/${lcid}/cookies-and-trackers/`,
        tildaInfoLimexMeTermsAndConditions: () => `https://www.info.limex.me/${lcid}/terms-and-conditions/`,
        tildaInfoLimexMeCommunityGuidelinesRu: () => 'https://www.info.limex.me/ru/community-guidelines/', // сейчас есть только на русском
        tildaInfoLimexMeFaq: () => `https://www.info.limex.me/${lcid}/faq/`,
        tildaPromoLimexComMonetization: () => 'https://www.promo.limex.com/monetization',
    };

    type KeyLinks = keyof typeof _links;

    // это нужно для работы методов withoutRoot
    // пример:
    // links.cabinetSecurity() // '/settings/security'
    // links.cabinetSecurity.withoutRoot?.() // '/security'
    const withoutRootMapping: Partial<Record<KeyLinks, KeyLinks>> = {
        shopProduct: 'shop',
        cabinetMonetization: 'cabinetRoot',
        cabinetSubscriptions: 'cabinetRoot',
        cabinetNotifications: 'cabinetRoot',
        cabinetSecurity: 'cabinetRoot',
        cabinetBlacklist: 'cabinetRoot',
        cabinetReferral: 'cabinetRoot',
        mySpaceFavorite: 'mySpaceRoot',
        mySpaceOrder: 'mySpaceRoot',
        mySpacePaymentSuccess: 'mySpaceRoot',
        mySpacePaymentFail: 'mySpaceRoot',
        mySpaceSignals: 'mySpaceRoot',
        mySpaceSubscriptions: 'mySpaceRoot',
        mySpaceSubscription: 'mySpaceRoot',
        creatorOverview: 'creatorRoot',
        creatorMyProducts: 'creatorRoot',
        creatorMyProduct: 'creatorRoot',
        creatorReviews: 'creatorRoot',
        creatorAnalyticsProducts: 'creatorRoot',
        creatorAi: 'creatorRoot',
        creatorAiWizard: 'creatorRoot',
        creatorProductsSignals: 'creatorRoot',
    };

    const links: any = _links;

    mapObject(_links, (nameLink) => {
        const linkName = nameLink;
        links[linkName].withBaseUrl = (...args: any[]) => window.location.origin + links[linkName](...args);
        const rootName = withoutRootMapping[nameLink];
        if (!rootName) {
            return;
        }
        links[linkName].withoutRoot = (...args: any[]) => {
            const linkWithRoot = links[linkName](...args);
            const linkRoot = links[rootName]();
            const withoutRoot = linkWithRoot.replace(linkRoot, '');
            return withoutRoot[0] === '/' ? withoutRoot : `/${withoutRoot}`;
        };
    });

    return links as WithExtraMethods<typeof _links>;
});

export const useLinks = () => {
    const lcid = useLocaleId();
    return getLinks(lcid);
};

export type Links = ReturnType<typeof useLinks>;
