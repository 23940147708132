import React, { useCallback, useMemo } from 'react';

import { useDicMemo } from 'hooks/useDictionary';
import { useLinks } from 'hooks/useLinks';

import { useGetControlDataCookiePopupWidget } from './hooks/useGetControlDataCookiePopupWidget';
import { SettingsTextData } from './types';
import { OnChangeItemCustomizedArgs } from './types/CookiePrivacySettingsPopupUI.types';
import { CookiePrivacySettingsPopupUI } from './components/CookiePrivacySettingsPopupUI';

export const CookiePrivacySettingsPopup = () => {
    const { settings, setSettings, saveSettings, rejectAll, acceptAll, isShow, disabledKeys } =
        useGetControlDataCookiePopupWidget();

    const links = useLinks();

    const textDataSettings: SettingsTextData = useDicMemo((dic) => ({
        essentialCookies: {
            title: dic.word('wt_all__widget_cookie_popup__essential_cookies__title'),
            description: dic.word('wt_all__widget_cookie_popup__essential_cookies__description'),
        },
        performanceAndAnalyticsCookies: {
            title: dic.word('wt_all__widget_cookie_popup__performance_cookies__title'),
            description: dic.word('wt_all__widget_cookie_popup__performance_cookies__description'),
        },
        functionalCookies: {
            title: dic.word('wt_all__widget_cookie_popup__functional_cookies__title'),
            description: dic.word('wt_all__widget_cookie_popup__functional_cookies__description'),
        },
        advertisingCookies: {
            title: dic.word('wt_all__widget_cookie_popup__advertising_cookies__title'),
            description: dic.word('wt_all__widget_cookie_popup__advertising_cookies__description'),
        },
    }));

    const cookiePopupTexts = useDicMemo((dic) => ({
        common: {
            title: dic.word('wt_all__widget_cookie_popup__common__title'),
            btnTexts: {
                acceptAll: dic.word('wt_all__widget_cookie_popup__common__btn__accept'),
                customize: dic.word('wt_all__widget_cookie_popup__common__btn__customize'),
                reject: dic.word('wt_all__widget_cookie_popup__common__btn__reject'),
            },
            content: dic.parts('wt_all__widget_cookie_popup__common__content'),
        },
        settings: {
            title: dic.word('wt_all__widget_cookie_popup__settings__title'),
            btnTexts: {
                acceptAll: dic.word('wt_all__widget_cookie_popup__settings__btn__accept'),
                save: dic.word('wt_all__widget_cookie_popup__settings__btn__save'),
            },
        },
    }));

    const {
        common: { title: titleCommon, btnTexts: btnTextsCommon, content: commonContentParts },
        settings: { title: titleSettings, btnTexts: btnTextsSettings },
    } = cookiePopupTexts;

    const contentCommon = useMemo(() => {
        const commonContentPrivacyPolicyLink = links.tildaInfoLimexMePrivacyPolicy();
        const [mainText, linkText, endText] = commonContentParts || [];

        return (
            <div>
                {mainText ? <span>{mainText}</span> : null}
                {commonContentPrivacyPolicyLink && linkText ? (
                    <>
                        {mainText ? <>&nbsp;</> : null}
                        <a href={commonContentPrivacyPolicyLink}>{linkText}</a>
                    </>
                ) : null}

                {endText || null}
            </div>
        );
    }, [commonContentParts, links]);

    const changeItemCustomized = useCallback(
        ({ key, isChecked }: OnChangeItemCustomizedArgs) => {
            if (settings) {
                setSettings({
                    ...settings,
                    [key]: isChecked,
                });
            }
        },
        [settings, setSettings],
    );

    if (!isShow) return null;

    return (
        <CookiePrivacySettingsPopupUI
            reject={rejectAll}
            acceptAll={acceptAll}
            saveSettings={saveSettings}
            common={{
                title: titleCommon,
                content: contentCommon,
                btnTexts: btnTextsCommon,
            }}
            settings={{
                title: titleSettings,
                textData: textDataSettings,
                values: settings,
                disabledKeys,
                onChangeItem: changeItemCustomized,
                btnTexts: btnTextsSettings,
            }}
        />
    );
};
