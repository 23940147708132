import { useCallback } from 'react';
import parseISO from 'date-fns/parseISO';

import { useLocaleId } from 'hooks/useLocaleId';

export const useFormattedISO = (
    options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    },
) => {
    const lcid = useLocaleId();
    return useCallback(
        (dateTime: string | undefined) => {
            if (!dateTime) return null;
            const parsedDate = parseISO(dateTime);
            if (parsedDate.toString() === 'Invalid Date') return null;
            return parsedDate.toLocaleString(lcid, options);
        },
        [options, lcid],
    );
};
