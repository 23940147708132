import type { FeedState } from '..';

import type { RawInitialData } from './actions';

export const createInitialFeedState = (rawInitialData: RawInitialData): FeedState => {
    const { communities } = rawInitialData;
    return {
        records: {},
        instances: {
            feed: {
                ids: [],
                deletedIds: [],
                isLoading: false,
            },
            premiumPosts: {
                ids: [],
                deletedIds: [],
                isLoading: false,
            },
        },
        communities,
    };
};
