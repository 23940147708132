import React, { useLayoutEffect, useRef, useState } from 'react';

import { TooltipV2 } from 'components/tooltip-v2';

import { CardTitleBlockProps } from './types';
import * as Styled from './styled';

export const CardTitleBlock = (props: CardTitleBlockProps) => {
    const { title, marginBottom, lines = 2 } = props;

    const [titleHeight, setTitleHeight] = useState<number | null>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const titleRef = useRef<HTMLHeadingElement>(null);

    useLayoutEffect(() => {
        const node = titleRef.current;
        if (node) {
            setTitleHeight(node.clientHeight);
            setShowTooltip(node.scrollWidth > node.clientWidth || node.scrollHeight > node.clientHeight);
        }
    }, []);

    const titleElement = (
        <Styled.Title ref={titleRef} lines={lines} className="autotest__product-card_title">
            {title}
        </Styled.Title>
    );

    return (
        <Styled.CardTitleBlockWrapper titleHeight={titleHeight} marginBottom={marginBottom}>
            {showTooltip ? (
                <TooltipV2 content={title} placement="top">
                    {titleElement}
                </TooltipV2>
            ) : (
                titleElement
            )}
        </Styled.CardTitleBlockWrapper>
    );
};
