import React, { ReactNode, useCallback, useRef } from 'react';
import * as Sentry from '@sentry/react';

import notify from 'services/notify';
import Dic from 'services/dictionary';

type Props = {
    children?: ReactNode;
};

export const ErrorBoundary = (props: Props) => {
    const { children } = props;
    const lastErrorTime = useRef<number | null>(null);
    const countErrors = useRef(0);

    const fallbackRender = useCallback(({ resetError }: { resetError: () => void }) => {
        if (!lastErrorTime.current || (lastErrorTime.current - new Date().getTime()) / 1000 > 2) {
            countErrors.current = 0;
        }
        if (countErrors.current < 5) {
            resetError();
            notify.error({
                button: {
                    content: Dic.word('wt_all__notification__common_error__btn_update'),
                    onClick: location.reload,
                },
                text: Dic.word('wt_all__notification__common_error'),
            });
        }
        lastErrorTime.current = new Date().getTime();
        countErrors.current++;
        return <>{/* eslint-disable-line react/jsx-no-useless-fragment */}</>;
    }, []);

    return <Sentry.ErrorBoundary fallback={fallbackRender}>{children}</Sentry.ErrorBoundary>;
};
