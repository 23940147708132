import styled, { css } from 'styled-components';

import { matchBreakpoints } from 'app/styled';
import { IconButton, Input, Wrapper } from 'components/text-input/styled';
import { WrapperProps } from 'components/text-input/types';

export const NumberInputStyled = styled(Input)`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const StepperStyled = styled.div`
    display: flex;
    flex-direction: column;

    & svg {
        color: ${({ theme }) => theme.label.placeholder};
    }

    & > button:first-child > svg {
        transform: rotate(-90deg);
    }

    & > button:last-child > svg {
        transform: rotate(90deg);
    }
`;

export const buildSizes = ({ size, hasLabel, hasText, hasRows }: WrapperProps) =>
    matchBreakpoints(size, {
        large: css`
            & ${StepperStyled} {
                margin: -16px -8px -16px 0;
                padding: 4px;
            }

            ${hasText || hasRows ? '&' : '&:focus-within'} {
                & ${StepperStyled} {
                    margin: ${hasLabel ? '-8px -8px -8px 0' : '-16px -8px -16px 0'};
                }
            }
        `,
        medium: css`
            & ${StepperStyled} {
                margin: -12px -8px -12px 0;
                padding: 4px;
            }

            ${hasText || hasRows ? '&' : '&:focus-within'} {
                & ${StepperStyled} {
                    margin: ${hasLabel ? '-6px -8px -6px 0' : '-12px -8px -12px 0'};
                }
            }

            ${IconButton} {
                width: 20px;
                height: 20px;
                overflow: hidden;
                & > svg {
                    margin-left: -2px;
                    margin-top: -2px;
                }
            }
        `,
    });

export const NumberWrapper = styled(Wrapper)`
    ${buildSizes};
`;
