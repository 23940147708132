import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { buttonResetStyle } from 'app/styled';
import { caption12RegularFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import { RoundButton } from 'components/round-button';

import { ImageUploadKind } from './types';

export const ImageUploadText = styled.div`
    font-family: ${caption12RegularFontStyle};
    margin-top: 8px;
    white-space: pre-wrap;
    text-align: center;
`;

export const AddButton = styled(RoundButton)``;

export const ImageUploadWrapperCenter = styled.div<{ isShown: boolean; isMobile?: boolean }>`
    ${buttonResetStyle};
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;

    ${ifProp(
        'isShown',
        css`
            opacity: 0;
            background-color: ${({ theme }) => theme.bg.dialogOverlay};
            ${ifProp(
                'isMobile',
                css`
                    @media (hover: hover) {
                        &:hover {
                            opacity: 1;
                        }
                    }
                    @media not (hover: hover) {
                        & button {
                            display: none;
                        }
                    }
                `,
                css`
                    &:hover {
                        opacity: 1;
                    }
                `,
            )}
        `,
    )}
`;

export const ImageUploadButtons = styled.div`
    display: flex;
    gap: 10px;
`;

export const ImageUploadImagePreview = styled.img`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const kindSpecific = ({ theme, kind }: { theme: Theme; kind: ImageUploadKind }) => {
    const { fillIn, bg, staticColors, label } = theme;
    return {
        dark: css`
            background-color: ${ifProp('isLoading', fillIn.secondary.overlay16, bg.dialogOverlay)};
            ${ImageUploadWrapperCenter} {
                &:hover {
                    ${AddButton} {
                        background-color: ${fillIn.secondary.highest};
                    }
                }
            }
            ${ImageUploadText} {
                color: ${staticColors.white};
            }
        `,
        light: css`
            background-color: ${fillIn.secondary.overlay16};
            ${AddButton} {
                background-color: ${fillIn.secondary.overlay32};
                svg {
                    color: ${fillIn.secondary.high};
                }
            }
            ${ImageUploadText} {
                color: ${label.secondary};
            }
        `,
    }[kind];
};

export const ImageUploadWrapper = styled.div<{
    isLoading: boolean;
    isInErrorState: boolean;
    kind: ImageUploadKind;
}>`
    ${kindSpecific};

    ${ifProp(
        'isInErrorState',
        css`
            border: 1px solid ${({ theme }) => theme.label.negative};
        `,
    )}

    box-sizing: border-box;

    height: 100%;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    position: relative;

    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    cursor: pointer;
`;
