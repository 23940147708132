import { useCallback, useEffect, useMemo } from 'react';

import { ChartData, ChartProps } from '../types';
import { DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES } from '../constants';

type useComputedDataProps = Required<Pick<ChartProps, 'data' | 'sizePeriodCropData'>> &
    Pick<ChartProps, 'onChangeComputedChartData'> & {
        isRunCompute?: boolean;
    };

export const useComputedData = (props: useComputedDataProps) => {
    const { data, sizePeriodCropData, onChangeComputedChartData, isRunCompute = true } = props;

    const cropData = useCallback((size: number) => (data.length > size ? data.slice(-size) : data), [data]);

    const croppedData = useMemo((): ChartData => {
        if (!data || data?.length < 0 || !isRunCompute) {
            return [];
        }

        if (typeof sizePeriodCropData === 'number') {
            return cropData(sizePeriodCropData);
        }

        switch (sizePeriodCropData) {
            case 'week':
                return cropData(DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES.week);
            case 'month':
                return cropData(DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES.month);
            case 'year':
                return cropData(DEFAULT_SIZE_PERIOD_CROP_DATA_VALUES.year);
            case 'all':
            default:
                return data;
        }
    }, [cropData, data, sizePeriodCropData, isRunCompute]);

    useEffect(() => {
        onChangeComputedChartData?.(croppedData, sizePeriodCropData);
    }, [croppedData, sizePeriodCropData, onChangeComputedChartData]);

    return croppedData;
};
