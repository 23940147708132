import React from 'react';

import { EmojiIconSvg, IconComponent } from 'components/icons2';

export const RocketIcon: IconComponent = (props) => (
    <EmojiIconSvg {...props} viewBox="0 0 20 20">
        <path
            d="M0.589111 11.2078L3.14224 8.69219L7.48911 7.92188L7.05942 10.1969L1.18286 12.0031L0.803174 11.8391L0.589111 11.2078Z"
            fill="#CA2C31"
        />
        <path
            d="M3.58437 9.3375L0.8125 11.8562L2.85156 12.85L5.94687 11.2703V10.525L6.57969 8.81875L3.58437 9.3375Z"
            fill="#A02422"
        />
        <path
            d="M10.1437 13.7734L8.80459 14.3547L7.54053 17.0344C7.54053 17.0344 8.65303 19.4984 8.70303 19.4984C8.75303 19.4984 9.38584 19.5484 9.38584 19.5484L11.6358 17.0328L12.2046 12.7359L10.1437 13.7734Z"
            fill="#A02422"
        />
        <path
            d="M8.82803 15.7563C8.82803 15.7563 9.57334 15.6047 10.1046 15.3516C10.6358 15.0984 11.2921 14.7203 11.2921 14.7203L11.0515 16.8188L8.6999 19.4953C8.6999 19.4953 8.60771 19.3813 8.21709 18.5313C7.90615 17.8531 7.79834 17.6109 7.79834 17.6109L8.82803 15.7563Z"
            fill="#CA2C31"
        />
        <path
            d="M4.93446 12.6031C4.93446 12.6031 4.03759 12.5281 3.05477 13.7703C2.1579 14.9047 1.73759 16.7516 1.57509 17.2266C1.41259 17.7016 1.02352 17.7984 1.15321 18.0156C1.2829 18.2328 1.84384 18.2203 2.53602 18.1344C3.22821 18.0484 3.82196 17.8313 3.82196 17.8313C3.82196 17.8313 3.79227 17.9063 3.69227 18.0688C3.65634 18.1266 3.53134 18.2094 3.54071 18.2953C3.56259 18.5 5.31571 18.5047 6.71571 17.0641C8.22821 15.5094 7.49384 14.2344 7.49384 14.2344L4.93446 12.6031Z"
            fill="#F7D74D"
        />
        <path
            d="M5.20459 13.3266C5.20459 13.3266 4.2374 13.3781 3.4374 14.6203C2.6374 15.8625 2.26553 17.3016 2.34209 17.3984C2.41709 17.4953 3.90772 16.9562 4.26553 17.0641C4.51084 17.1391 4.38428 17.3875 4.4499 17.4531C4.50459 17.5078 5.15147 17.6 6.19834 16.4656C7.24678 15.3312 6.99834 13.7375 6.99834 13.7375L5.20459 13.3266Z"
            fill="#FBF0B4"
        />
        <path
            d="M5.67958 11.6312C5.67958 11.6312 5.19365 12.0641 5.02021 12.3125C4.84677 12.5609 4.84677 12.5828 4.85771 12.6578C4.86865 12.7328 5.04833 13.5562 5.79677 14.2781C6.71552 15.1641 7.53584 15.2828 7.65458 15.2828C7.77333 15.2828 8.5624 14.3969 8.5624 14.3969L5.67958 11.6312Z"
            fill="#858585"
        />
        <path
            d="M7.82812 14.2563C7.82812 14.2563 8.61563 14.7734 9.93594 14.3297C11.7406 13.7234 13.0641 12.3672 14.7031 10.6547C16.2844 9.00156 17.6109 6.95 17.6109 6.95L16.6813 3.83594L7.82812 14.2563Z"
            fill="#437687"
        />
        <path
            d="M10.6235 12.5516L10.8407 11.8766L11.3845 11.9531C11.3845 11.9531 11.7985 12.1484 12.1032 12.2906C12.4079 12.4328 12.8001 12.5406 12.8001 12.5406L12.0267 13.1938C12.0267 13.1938 11.6048 13.0344 11.297 12.9C10.9501 12.7484 10.6235 12.5516 10.6235 12.5516Z"
            fill="#3F545F"
        />
        <path
            d="M13.1749 2.52187C13.1749 2.52187 11.6718 3.39375 9.51711 5.43281C7.57492 7.27031 6.13586 8.93281 5.78742 10.3469C5.4968 11.5312 5.65148 12.25 6.31242 13.0266C7.01086 13.8484 7.82961 14.2562 7.82961 14.2562C7.82961 14.2562 8.44523 14.2656 11.014 12.2891C14.2187 9.82187 16.7859 5.69062 16.7859 5.69062L13.1749 2.52187Z"
            fill="#8DAFBF"
        />
        <path
            d="M16.2782 6.5375C16.2782 6.5375 14.9704 5.97969 14.0376 4.67813C13.111 3.38594 13.1845 2.5125 13.1845 2.5125C13.1845 2.5125 13.9595 1.90469 15.7017 1.20938C16.8735 0.742191 18.4689 0.503128 18.9939 0.892191C19.5189 1.28125 18.6345 3.23282 18.6345 3.23282L17.0235 6.35156L16.2782 6.5375Z"
            fill="#D83F22"
        />
        <path
            d="M10.6517 12.5625C10.6517 12.5625 9.52197 11.9859 8.80322 11.1656C7.44385 9.61719 7.1626 7.91719 7.1626 7.91719L7.84541 7.11562C7.84541 7.11562 8.01572 8.92187 9.47354 10.4828C10.4235 11.5 11.4157 11.9656 11.4157 11.9656C11.4157 11.9656 11.2173 12.1328 11.0048 12.2953C10.8688 12.4 10.6517 12.5625 10.6517 12.5625Z"
            fill="#6896A5"
        />
        <path
            d="M17.6109 6.95C17.6109 6.95 18.289 6.13281 18.9312 4.29062C19.8281 1.72187 19.0468 0.943748 19.0468 0.943748C19.0468 0.943748 18.7828 2.16562 17.8656 3.86406C17.1296 5.225 16.239 6.52031 16.239 6.52031C16.239 6.52031 16.7296 6.74062 16.9953 6.81719C17.3296 6.91406 17.6109 6.95 17.6109 6.95Z"
            fill="#A02422"
        />
        <path
            d="M6.2203 10.8844C6.42342 11.0781 6.73124 10.875 6.9328 10.4C7.13592 9.925 7.1328 9.65938 6.97655 9.54688C6.7828 9.40625 6.4578 9.71407 6.31561 9.9875C6.15936 10.2906 6.06717 10.7375 6.2203 10.8844Z"
            fill="#B3E1EE"
        />
        <path
            d="M13.2735 3.14531C13.2735 3.14531 12.0844 4 10.8157 5.1625C9.65159 6.22968 8.87972 7.06406 8.77347 7.25781C8.66097 7.46562 8.6594 7.75937 8.7469 7.90937C8.8344 8.05937 9.02815 8.1375 9.20472 8.00625C9.38128 7.87343 10.6391 6.48281 11.775 5.48906C12.8141 4.57968 13.6157 4.00312 13.6157 4.00312C13.6157 4.00312 13.9407 3.42812 13.8875 3.29687C13.8344 3.16406 13.2735 3.14531 13.2735 3.14531Z"
            fill="#B3E1EE"
        />
        <path
            d="M13.2734 3.14531C13.2734 3.14531 14.1516 2.47188 15.1078 1.99844C15.9969 1.55781 16.8813 1.19063 17.0406 1.50938C17.1922 1.8125 16.2016 2.225 15.3297 2.78125C14.4578 3.3375 13.6188 4.00156 13.6188 4.00156C13.6188 4.00156 13.4906 3.76719 13.4078 3.55C13.3156 3.31406 13.2734 3.14531 13.2734 3.14531Z"
            fill="#ED6A65"
        />
        <path
            d="M13.9984 6.13281C13.128 5.33125 11.9171 5.54688 11.3202 6.25938C10.7077 6.99375 10.753 8.11562 11.4765 8.79062C12.1343 9.40312 13.3577 9.54219 14.0952 8.68281C14.7515 7.92031 14.7109 6.7875 13.9984 6.13281Z"
            fill="#E1E1E1"
        />
        <path
            d="M11.7702 6.54219C11.2531 7.05 11.2812 8.05625 11.8968 8.51563C12.4343 8.91719 13.1968 8.89688 13.6749 8.40782C14.1531 7.91875 14.1531 7.02969 13.7046 6.57032C13.189 6.04375 12.3624 5.96094 11.7702 6.54219Z"
            fill="#3F545F"
        />
        <path
            d="M7.81261 12.0141C7.81261 12.0141 8.7798 11.0328 8.88604 11.1391C8.99229 11.2453 8.97823 11.8406 8.51573 12.5031C8.05167 13.1656 7.03136 14.3453 6.22355 15.1406C5.42667 15.9234 4.30323 16.8188 4.19698 16.8031C4.11417 16.7906 3.77823 16.6234 3.64386 16.4609C3.51261 16.3 3.9048 15.5391 4.06261 15.2875C4.22198 15.0359 7.81261 12.0141 7.81261 12.0141Z"
            fill="#A02524"
        />
        <path
            d="M3.31723 15.9141C3.30473 16.1391 3.64848 16.4672 3.64848 16.4672L8.88598 11.1406C8.88598 11.1406 8.64067 10.8641 7.91879 11.3125C7.19067 11.7656 6.55317 12.3094 5.61254 13.2203C4.3016 14.4922 3.32973 15.6891 3.31723 15.9141Z"
            fill="#CA2C31"
        />
        <path
            d="M2.97813 5.77344C2.80469 5.94688 2.79688 6.225 2.99063 6.38437C3.16251 6.52656 3.44219 6.43437 3.54688 6.30625C3.65157 6.17812 3.63907 5.9 3.50001 5.7625C3.36094 5.62344 3.08438 5.66875 2.97813 5.77344Z"
            fill="white"
        />
        <path
            opacity="0.5"
            d="M6.40942 5.57031C6.27817 5.71562 6.32036 5.93125 6.44223 6.01094C6.56411 6.09062 6.74692 6.08281 6.83598 5.97344C6.91567 5.875 6.92504 5.67812 6.80317 5.55625C6.69692 5.45156 6.49379 5.47656 6.40942 5.57031Z"
            fill="white"
        />
        <path
            d="M8.67973 1.85782C8.67973 1.85782 8.87035 1.31407 8.98285 1.30782C9.09691 1.30157 9.26098 1.85157 9.26098 1.85157C9.26098 1.85157 9.82504 1.85782 9.86254 1.94063C9.91098 2.04688 9.5016 2.40313 9.5016 2.40313C9.5016 2.40313 9.63441 2.93438 9.57191 2.99844C9.5016 3.06875 9.01566 2.78907 9.01566 2.78907C9.01566 2.78907 8.51566 3.1375 8.40785 3.04219C8.3141 2.95938 8.50941 2.39688 8.50941 2.39688C8.50941 2.39688 8.04066 2.05469 8.06566 1.95938C8.1016 1.825 8.67973 1.85782 8.67973 1.85782Z"
            fill="white"
        />
        <path
            d="M15.1579 14.8953C15.3469 15 15.5844 14.9406 15.6719 14.7391C15.7516 14.5594 15.6047 14.3453 15.4719 14.2875C15.3391 14.2297 15.1063 14.3062 15.0219 14.4578C14.9391 14.6078 15.0438 14.8328 15.1579 14.8953Z"
            fill="white"
        />
        <path
            d="M17.8422 10.2875C17.7344 10.1203 17.5015 10.0656 17.35 10.2C17.2031 10.3312 17.239 10.5375 17.3219 10.6422C17.4047 10.7469 17.6265 10.7859 17.7609 10.7C17.8953 10.6141 17.9078 10.3875 17.8422 10.2875Z"
            fill="white"
        />
    </EmojiIconSvg>
);
