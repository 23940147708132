import React from 'react';

import { isFinamStrategyProduct } from 'types/finam-product';
import { useDictionary } from 'hooks/useDictionary';
import { BrokerageAccountPopupWidget } from 'widgets/mp-share/brokerage-account-popup';

import { ProductCardFinamPropsType } from '../types';
import { useArchivedAtParams } from '../hooks/useArchivedAtParams';
import { useFinamCardSharedProps } from '../utils';

import { getGraphUrl } from './getGraphUrl';
import { useStrategyTable } from './useStrategyTable';
import { useStrategySubscribe } from './useStrategySubscribe';
import * as Styled from './styled';

export const StrategyProductCard = (props: ProductCardFinamPropsType) => {
    const dic = useDictionary();
    const sharedProps = useFinamCardSharedProps(props, 'strategy');

    const { item, actionButtons } = props;

    const parametersTable = useStrategyTable(item && isFinamStrategyProduct(item) ? item : undefined);

    const { isShowBrokerageAccountPopup, hideBrokerageAccountPopup, updatedActionButtons } = useStrategySubscribe(
        item?.id,
        actionButtons,
    );

    const backgroundImageUrl =
        item && isFinamStrategyProduct(item) && item.strategyId ? getGraphUrl(item.strategyId) : undefined;

    const archivedAtParams = useArchivedAtParams(item);

    return (
        <>
            <Styled.DefaultCard
                {...sharedProps}
                thumbnailSrc={backgroundImageUrl}
                parametersTable={parametersTable}
                actionButtons={updatedActionButtons}
                price={{
                    word: dic.word('wt_all__product_card_finam__strategy_recommended_contribution'),
                    price: 0,
                    currency: 'USD',
                    period: 'fixed',
                    trialPeriod: { count: 0, type: 'day' },
                }}
                {...archivedAtParams}
            />
            {isShowBrokerageAccountPopup && item?.id ? (
                <BrokerageAccountPopupWidget close={hideBrokerageAccountPopup} strategyId={item.id} />
            ) : null}
        </>
    );
};
