import styled from 'styled-components';

export const ContextActionsWrapper = styled.div`
    display: flex;
    outline: none;
`;

export const ContentWrapper = styled.div`
    padding: 8px 0;
`;

export const ButtonIconWrapper = styled.div`
    outline: none;
`;
