import React from 'react';

import { IconSvg, IconComponent } from '.';

export const EditIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.0002 4.8002C5.33745 4.8002 4.8002 5.33745 4.8002 6.0002V18.0002C4.8002 18.6629 5.33745 19.2002 6.0002 19.2002H18.0002C18.6629 19.2002 19.2002 18.6629 19.2002 18.0002V12.3779C19.2002 11.9361 19.5584 11.5779 20.0002 11.5779C20.442 11.5779 20.8002 11.9361 20.8002 12.3779V18.0002C20.8002 19.5466 19.5466 20.8002 18.0002 20.8002H6.0002C4.4538 20.8002 3.2002 19.5466 3.2002 18.0002V6.0002C3.2002 4.4538 4.4538 3.2002 6.0002 3.2002H11.3086C11.7504 3.2002 12.1086 3.55837 12.1086 4.0002C12.1086 4.44202 11.7504 4.8002 11.3086 4.8002H6.0002Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.3986 12.4825C8.43296 12.3388 8.50648 12.2073 8.611 12.1028L17.1099 3.60389C17.6176 3.09621 18.4407 3.0962 18.9484 3.60389L20.3626 5.0181C20.8703 5.52578 20.8703 6.3489 20.3626 6.85658L11.8637 15.3555C11.7619 15.4573 11.6344 15.5298 11.4948 15.5652L8.69676 16.2754C8.42532 16.3443 8.13762 16.2663 7.93822 16.0696C7.73883 15.873 7.65676 15.5864 7.72186 15.314L8.3986 12.4825ZM11.298 13.6584L19.0191 5.93734L18.0292 4.94739L10.3081 12.6685L11.298 13.6584Z"
        />
    </IconSvg>
);
