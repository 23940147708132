import React, { useCallback } from 'react';
import cc from 'classcat';

import { AvatarProps } from '../types';
import * as Styled from '../styled/AvatarBlock.styled';

import { Avatar } from './Avatar';

export const AvatarBlock = (props: AvatarProps) => {
    const { onClick: onClickProp, isLoading = false, className: classNameProp, ...restProps } = props;
    const hasOnClick = !!onClickProp;
    const className = cc([classNameProp, 'autotest__avatar-block-wrapper']);

    const onClick = useCallback(() => {
        if (!isLoading && onClickProp) {
            onClickProp();
        }
    }, [onClickProp, isLoading]);

    return (
        <Styled.AvatarBlock
            onClick={
                hasOnClick
                    ? () => {
                          onClick();
                      }
                    : undefined
            }
            role={hasOnClick ? 'button' : 'img'}
            isLoading={isLoading}
            className={className}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onClick();
                }
            }}
        >
            <Avatar {...restProps} isLoading={isLoading} tabIndex={hasOnClick && !isLoading ? 0 : -1} />
        </Styled.AvatarBlock>
    );
};
