import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { useDictionary } from 'hooks/useDictionary';
import { CoverField } from 'widgets/product-wizard/components/cover-field';
import {
    FormField,
    FormItemMessage,
    FormLabel,
    FormSectionHeadline,
    Gap20FormVLayout,
    VerticalFormInputGroup,
} from 'components/form';
import { ErrorMessage } from 'components/text-input/styled';
import { FormTextArea } from 'components/form/react-hook-form';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { StepProps } from '../../types';

import { toData } from './toData';
import { CoverTip, ProductCoverUploadContainer } from './styled';
import { defaultValues, MAX_LENGTH, MAX_THRESH, MIN_THRESH } from './constants';
import { Step2FormValues } from './types';

export const Step2: FC<StepProps<Step2FormValues>> = memo((props) => {
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__step2_');

    const form = useForm<Step2FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, formState, getFieldState } = form;

    const { error: coverError } = getFieldState('cover', formState);
    const description = watch('description');

    useFormStateHandler({ form, props, toData, checkFields: ['description'] });

    const required = dic.word('wt_all__widget_product_wizard__field_required');

    return (
        <FormProvider {...form}>
            <Gap20FormVLayout>
                <VerticalFormInputGroup>
                    <FormSectionHeadline>{dicWord('headline')}</FormSectionHeadline>
                    <FormField className="autotest__marketplace__product_wizard__cover">
                        <FormLabel htmlFor="name">{dicWord('field_cover_label')}</FormLabel>
                        <CoverTip>{dicWord('field_cover_tip')}</CoverTip>
                        <ProductCoverUploadContainer className="autotest__marketplace__product_wizard__cover_uploader">
                            <CoverField
                                name="cover"
                                infoText={(() => {
                                    let key = 1;
                                    return dicWord('field_cover_info_text')
                                        .split('\n')
                                        .map((it: string) => <div key={`x${key++}`}>{it}</div>);
                                })()}
                                rules={{ required }}
                            />
                        </ProductCoverUploadContainer>
                        {coverError && coverError.type !== 'required' ? (
                            <ErrorMessage>{coverError.message}</ErrorMessage>
                        ) : null}
                    </FormField>
                    <FormField className="autotest__marketplace__product_wizard__description">
                        <FormLabel>{dicWord('field_description_label')}</FormLabel>
                        <FormTextArea
                            name="description"
                            kind="outline"
                            size="medium"
                            placeholder={dicWord('field_description_placeholder')}
                            className="autotest__marketplace__product_wizard__description_input"
                            rows={2}
                            infiniteHeight
                            rules={{
                                minLength: MIN_THRESH,
                                maxLength: MAX_LENGTH,
                                required: true,
                            }}
                        />
                        {description.length < MIN_THRESH && (
                            <FormItemMessage>
                                {dicWord('field_description_min_message', { min: MIN_THRESH })}
                            </FormItemMessage>
                        )}
                        {description.length >= MAX_THRESH && (
                            <FormItemMessage visualState={description.length > MAX_LENGTH ? 'emergency' : 'normal'}>
                                {dicWord('field_description_max_message', {
                                    length: description.length,
                                    max: MAX_LENGTH,
                                })}
                            </FormItemMessage>
                        )}
                    </FormField>
                </VerticalFormInputGroup>
            </Gap20FormVLayout>
        </FormProvider>
    );
});
