import { notEmpty } from 'services/utils/not-empty';

import { Step3FormValues, Step3Data } from './types';

export function toData(values: Step3FormValues): Step3Data {
    return {
        screenshotUrls: values.promoImages
            .map(({ value }) => value)
            .filter(notEmpty)
            .map(({ sizes }) => sizes.original?.url)
            .filter(notEmpty),
    };
}
