import styled from 'styled-components';

import { body14RegularFontStyle, body16SemiboldFontStyle } from 'services/typography';

export const SettingItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
`;

export const Body = styled.div``;
export const SwitchWrapper = styled.div`
    padding: 1px 0;
`;

export const Title = styled.span`
    ${body16SemiboldFontStyle};
`;

export const Description = styled.span`
    ${body14RegularFontStyle};
`;

export const StrategyLabel = styled.span`
    color: ${({ theme }) => theme.label.secondary};
`;
