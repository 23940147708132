import React, { useCallback } from 'react';

import { Ribbon } from 'components/indicator/ribbon';
import Tag from 'components/tag/Tag';

import { ParametersTable } from '../../partials/parameters-table';
import { CardThumbnail } from '../../partials/thumbnail';
import { DefaultLayout } from '../../partials/default-layout';
import { CardTitleBlock } from '../../partials/title-block';
import { CardPriceInfo } from '../../partials/price-info';
import { CardAuthor } from '../../partials/author';
import { CardSummaryText } from '../../partials/summary-text';
import { ActionButtons } from '../../partials/action-buttons';

import { ThumbnailActionsBlock } from './ThumbnailActionsBlock';
import * as Styled from './styled';
import { DefaultCardPropsType } from './types';

const RELATIVE_HEIGHT_BY_WIDTH_CARD_THUMBNAIL = 50;

export const DefaultCard = (props: DefaultCardPropsType) => {
    const {
        title,
        summary,
        price,
        thumbnailSrc,
        author,
        authorDesc,
        authorAvatarSrc,
        url, // если указать url, то корневой элемент станет <a /> - ссылка. Иначе - <div /> См. components/card/container
        badgeText,
        tagText,
        tagIcon,
        freePriceWord,
        onClick,
        className: classNameProp,
        isLoading = false,
        isHeightGrow = false,
        width,
        isWidthLimits,
        withoutBoxShadow = false,
        dataAttributes,
        targetCardLink,
        actionButtons,
        parametersTable,
        isDisable = false,
        favoriteBtn: favoriteBtnProp,
        formattedPricePeriod,
        contextActions,
    } = props;

    const amount = price?.price || undefined;
    const period = price?.period || undefined;
    const currency = price?.currency || undefined;
    const priceWord = price?.word || undefined;

    const className = [
        classNameProp,
        'card-item',
        'autotest__card',
        'autotest__card-default',
        isLoading && 'autotest__card-loading',
    ]
        .filter((item) => item)
        .join(' ');

    const thumbnailRender = useCallback(
        () => (
            <>
                <CardThumbnail
                    alt={title}
                    src={thumbnailSrc}
                    relativeHeightByWidth={RELATIVE_HEIGHT_BY_WIDTH_CARD_THUMBNAIL}
                    isLoading={isLoading}
                >
                    <ThumbnailActionsBlock contextActions={contextActions} favoriteBtn={favoriteBtnProp} />
                </CardThumbnail>
                <Styled.OfferBadgeWrapper loaded={!isLoading && Boolean(badgeText || tagText || tagIcon)}>
                    {badgeText ? (
                        <Ribbon type="right" size="small">
                            {badgeText}
                        </Ribbon>
                    ) : null}
                    {tagText || tagIcon ? <Tag text={tagText} Icon={tagIcon} size="small" isActive={false} /> : null}
                </Styled.OfferBadgeWrapper>
            </>
        ),
        [title, thumbnailSrc, isLoading, contextActions, favoriteBtnProp, badgeText, tagText, tagIcon],
    );

    const contentRender = useCallback(
        () => (
            <Styled.ContentContainer>
                {title && <CardTitleBlock title={title} />}
                {summary && <CardSummaryText>{summary}</CardSummaryText>}
                {author && <CardAuthor name={author} description={authorDesc} avatarSrc={authorAvatarSrc} />}
                <Styled.ContentStretchSpace />
                {parametersTable && <ParametersTable parameters={parametersTable} />}
                {(price || formattedPricePeriod) && (
                    <CardPriceInfo
                        amount={amount}
                        period={period}
                        currency={currency}
                        freePriceWord={freePriceWord}
                        priceWord={priceWord}
                        formattedPricePeriod={formattedPricePeriod}
                    />
                )}
                {actionButtons && <ActionButtons {...actionButtons} />}
            </Styled.ContentContainer>
        ),
        [
            title,
            summary,
            author,
            authorDesc,
            authorAvatarSrc,
            parametersTable,
            price,
            amount,
            period,
            currency,
            freePriceWord,
            priceWord,
            actionButtons,
            formattedPricePeriod,
        ],
    );

    return (
        <DefaultLayout
            className={className}
            onClick={onClick}
            isLoading={isLoading}
            width={width}
            isWidthLimits={isWidthLimits}
            dataAttributes={dataAttributes}
            withoutBoxShadow={withoutBoxShadow}
            url={url}
            isHeightGrow={isHeightGrow}
            targetCardLink={targetCardLink}
            thumbnailRender={thumbnailRender}
            contentRender={contentRender}
            isDisable={isDisable}
        />
    );
};

export type { DefaultCardPropsType };
