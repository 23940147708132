/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportCsvStatsResponseWebResponse } from '../models/StatsService/ImportCsvStatsResponseWebResponse';
import type { LocalDateNullableWebResponse } from '../models/StatsService/LocalDateNullableWebResponse';
import type { PrivateUsLeanStatInfoDtoIReadOnlyCollectionWebResponse } from '../models/StatsService/PrivateUsLeanStatInfoDtoIReadOnlyCollectionWebResponse';
import type { UsCalcStatsReportWebResponse } from '../models/StatsService/UsCalcStatsReportWebResponse';
import type { UsCalcStatsRequest } from '../models/StatsService/UsCalcStatsRequest';
import type { UsImportAccountStatsResultWebResponse } from '../models/StatsService/UsImportAccountStatsResultWebResponse';
import type { UsImportStatsRequest } from '../models/StatsService/UsImportStatsRequest';
import type { UsLeanStatInfoDtoIReadOnlyCollectionWebResponse } from '../models/StatsService/UsLeanStatInfoDtoIReadOnlyCollectionWebResponse';
import type { UsStatInfo } from '../models/StatsService/UsStatInfo';
import type { UsStatInfoIReadOnlyCollectionWebResponse } from '../models/StatsService/UsStatInfoIReadOnlyCollectionWebResponse';
import type { UsStatInfoWebResponse } from '../models/StatsService/UsStatInfoWebResponse';
import type { WebResponse } from '../models/shared/WebResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatsService {
    /**
     * Загрузить статистику по счету
     * Только для тестирования
     * @returns UsStatInfoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getStat({
        accountId,
    }: {
        accountId: number;
    }): CancelablePromise<UsStatInfoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/stats/{accountId}',
            path: {
                accountId: accountId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Загрузить последнюю статистику по счету
     * Только для тестирования
     * @returns UsStatInfoWebResponse Success
     * @throws ApiError
     */
    public static getStatLast({ accountId }: { accountId: number }): CancelablePromise<UsStatInfoWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/stats/{accountId}/last',
            path: {
                accountId: accountId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Начать импорт статистики
     * Только для тестирования
     * @returns UsImportAccountStatsResultWebResponse Success
     * @throws ApiError
     */
    public static createStatsImport({
        requestBody,
    }: {
        requestBody?: UsImportStatsRequest;
    }): CancelablePromise<UsImportAccountStatsResultWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/stats/import',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Начать расчет статистики
     * Только для тестирования
     * @returns UsCalcStatsReportWebResponse Success
     * @throws ApiError
     */
    public static createStatsCalc({
        requestBody,
    }: {
        requestBody?: UsCalcStatsRequest;
    }): CancelablePromise<UsCalcStatsReportWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/stats/calc',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Сохранить статистику по счетам в базу
     * Валидация не проводится
     * Только для тестирования
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static createStatsStoreAccounts({
        requestBody,
    }: {
        requestBody?: Array<UsStatInfo>;
    }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/stats/store/accounts',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Удалить статистику из базы
     * Только для тестирования
     * @returns WebResponse Success
     * @throws ApiError
     */
    public static deleteStats({ accountId }: { accountId?: number }): CancelablePromise<WebResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/private/api/v1/stats',
            query: {
                accountId: accountId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Загружает доходность из файла для одного счета
     * Формат: .csv; разделитель: ";"<br />
     * Поля: AccountId;Date;Amount;MoneyIn;MoneyOut<br />
     * @returns ImportCsvStatsResponseWebResponse Success
     * @throws ApiError
     */
    public static createStatCsv({
        accountId,
        formData,
    }: {
        /**
         * Идентификатор счета
         */
        accountId: number;
        formData?: {
            statsFile?: Blob;
        };
    }): CancelablePromise<ImportCsvStatsResponseWebResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/private/api/v1/stats/csv/{accountId}',
            path: {
                accountId: accountId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Возвращает доходность стратегиий
     * @returns PrivateUsLeanStatInfoDtoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getStatsStrategies({
        strategiesIds,
    }: {
        strategiesIds?: Array<number>;
    }): CancelablePromise<PrivateUsLeanStatInfoDtoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/private/api/v1/stats/strategies',
            query: {
                StrategiesIds: strategiesIds,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Возвращает доходность стратегии
     * Доходность стратегии посчитана, если посчитана доходность ее счета
     * @returns UsLeanStatInfoDtoIReadOnlyCollectionWebResponse Success
     * @throws ApiError
     */
    public static getStrategyStats({
        strategyId,
        from,
        to,
    }: {
        strategyId: number;
        /**
         * Начало интервала выборки доходности
         */
        from?: string;
        /**
         * Конец интервала выборки доходности
         */
        to?: string;
    }): CancelablePromise<UsLeanStatInfoDtoIReadOnlyCollectionWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/stats/strategy/{strategyId}',
            path: {
                strategyId: strategyId,
            },
            query: {
                From: from,
                To: to,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @returns LocalDateNullableWebResponse Success
     * @throws ApiError
     */
    public static getApiVStatStrategyLastDate({
        strategyId,
    }: {
        strategyId: number;
    }): CancelablePromise<LocalDateNullableWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/stats/strategy/{strategyId}/last-date',
            path: {
                strategyId: strategyId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
