import React, { FC, memo } from 'react';
import { useController } from 'react-hook-form';

import { ListBox } from 'components/listbox';
import { ErrorMessage } from 'components/text-input/styled';

import { rulesComparer } from '../utils/rulesComparer';

import { FormListBoxProps } from './types';

export const FormListBox: FC<FormListBoxProps> = memo(
    ({ name, rules, placeholder, options, autotestOptionPrefix, hideErrors = false, ...listBoxProps }) => {
        const {
            field: { value, onChange, onBlur },
            fieldState: { error },
        } = useController<{ [key: string]: string }>({ name, rules });

        const item = options.find(({ id }) => id === value);

        return (
            <>
                <ListBox<string>
                    value={value}
                    onChange={onChange}
                    visualState={!hideErrors && error ? 'emergency' : 'normal'}
                    {...listBoxProps}
                >
                    <ListBox.Button placeholder={placeholder} onBlur={onBlur}>
                        {item && item.text}
                    </ListBox.Button>
                    <ListBox.Options>
                        {options.map(({ id, text, readonly, autotestSuffix }) => (
                            <ListBox.Option
                                key={id}
                                value={id}
                                className={
                                    autotestOptionPrefix ? `${autotestOptionPrefix}${autotestSuffix || id}` : undefined
                                }
                                readonly={readonly}
                            >
                                {text}
                            </ListBox.Option>
                        ))}
                    </ListBox.Options>
                </ListBox>
                {!hideErrors && error ? <ErrorMessage>{error.message}</ErrorMessage> : null}
            </>
        );
    },
    rulesComparer,
);
