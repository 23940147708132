import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { matchBreakpoints, buttonResetStyle, anchorResetStyle } from 'app/styled';
import { tokens } from 'services/tokens';

import type { ButtonType, ButtonWrapperProps } from '../types';

import { typeStandard } from './typeStandard';
import { typeText } from './typeText';
import { typeAlternative } from './typeAlternative';

const kitByType = (type: ButtonType) =>
    ({
        standard: typeStandard,
        text: typeText,
        alternative: typeAlternative,
    }[type]);

const typeSpecificWrapperStyle = ({ customType }: ButtonWrapperProps) => kitByType(customType).wrapperStyle;

const setVariables = ({ size, customType }: ButtonWrapperProps) =>
    matchBreakpoints(size, kitByType(customType).sizeMap);

const paddingLeftRightStyle = ({ customType }: ButtonWrapperProps) => kitByType(customType).paddingLeftRightStyle;

const colorsStyle = ({ kind, isDisabled, isProcessing, customType, isPressed }: ButtonWrapperProps) => css`
    ${() => {
        const stateMap = kitByType(customType).getStateMap(kind);
        if (isDisabled) {
            return stateMap.disabled;
        }

        if (isProcessing) {
            return stateMap.default;
        }

        return `
            ${stateMap.default};

            &:hover {
                ${stateMap.hover};
            }

            &:focus {
                ${stateMap.focus}
            }

            &:active {
                ${stateMap.active}
            }
            ${isPressed ? stateMap.active : ''}
        `;
    }}
`;

const contentContainerStyle = ({ customType }: ButtonWrapperProps) => kitByType(customType).contentContainerStyle;

// MARK: - Components

export const ButtonContentContainer = styled.span`
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-rows: 1fr;
    gap: 8px;
    align-content: center;
    justify-items: stretch;
    align-items: center;
    white-space: nowrap;
`;

export const ButtonTextContainer = styled.span`
    grid-area: content;
`;

/** The HTML element of a button. Defaults to <button />, but intended to be overridden with "as" prop. */
export const ButtonWrapper = styled.button<ButtonWrapperProps>`
    // Style Resets
    ${buttonResetStyle};
    ${anchorResetStyle};

    ${typeSpecificWrapperStyle};

    ${setVariables};
    ${paddingLeftRightStyle};
    ${colorsStyle};

    padding-top: var(--padding-vertical);
    padding-bottom: var(--padding-vertical);

    & ${ButtonContentContainer} {
        ${contentContainerStyle};
        ${({ size, customType }: ButtonWrapperProps) => matchBreakpoints(size, kitByType(customType).iconGapSizeMap)}
    }

    ${({ isDisabled, isProcessing }) =>
        (isDisabled || isProcessing) &&
        `
        pointer-events: none;
    `}

    &, & * {
        outline: none;
    }

    // Static Styles
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    user-select: none;
    cursor: pointer;

    border-radius: ${tokens.radius.buttonL};

    & svg {
        grid-area: icon;
    }

    ${ifProp(
        'block',
        css`
            flex-grow: 1;
        `,
    )}
`;

export const ProcessingIconContainer = styled.span`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
`;
