import React from 'react';

import useImageLoaded from 'hooks/useImageLoaded';

import * as Styled from './styled';

type CardThumbnailProps = {
    src?: string;
    alt?: string;
    isLoading?: boolean;
    height?: number;
    className?: string;
    relativeHeightByWidth?: number;
    children?: React.ReactElement;
};

export const CardThumbnail = (props: CardThumbnailProps) => {
    const { src, alt, isLoading = false, className, relativeHeightByWidth, height, children } = props;

    const { ref, isLoaded, onLoad } = useImageLoaded();

    const renderContent = () => {
        if (children) {
            return <Styled.ThumbnailChildrenContent>{children}</Styled.ThumbnailChildrenContent>;
        }
        return null;
    };

    return (
        <Styled.CardThumbnailWrapper
            className={className}
            relativeHeightByWidth={relativeHeightByWidth}
            height={height}
        >
            <Styled.ThumbnailImg
                alt={alt}
                src={src}
                ref={ref}
                onLoad={onLoad}
                loaded={!isLoading && isLoaded}
                relativeHeightByWidth={relativeHeightByWidth}
            />
            {renderContent()}
        </Styled.CardThumbnailWrapper>
    );
};
