import React from 'react';

import { FinamSignalsProduct } from 'types/finam-product';
import { useDicMemo } from 'hooks/useDictionary';

import { ProductTableParameters } from '../types';

import * as Styled from './styled';

export const useUsSignalTable = (signalsProduct?: FinamSignalsProduct): ProductTableParameters => {
    const { totalSignalsCount, riskLevel, targetReturnAnnualized, lastSignalsSecurityIds } = signalsProduct ?? {};

    return useDicMemo(
        (dic) => {
            const parameters = [];
            if (targetReturnAnnualized !== undefined) {
                parameters.push({
                    name: dic.word('wt_all__product_card_finam__us_signal_table_target_return'),
                    value: `${targetReturnAnnualized}%`,
                });
            }
            parameters.push({
                name: dic.word('wt_all__product_card_finam__us_signal_table_signals_count'),
                value: `${totalSignalsCount}`,
            });
            parameters.push({
                name: dic.word('wt_all__product_card_finam__us_signal_table_risk'),
                value: riskLevel ?? 'Undefined',
            });
            if (lastSignalsSecurityIds) {
                parameters.push({
                    name: '',
                    value: (
                        <Styled.Tickers>
                            {lastSignalsSecurityIds.map((id) => (
                                <img key={id} src={`https://warhol.scorepriority.com/security/48/${id}.png`} alt="" />
                            ))}
                        </Styled.Tickers>
                    ),
                });
            }

            return parameters;
        },
        [targetReturnAnnualized, totalSignalsCount, lastSignalsSecurityIds, riskLevel],
    );
};
