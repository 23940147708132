import { DefaultCardPropsType } from './builds/default-card/types';
import { CardsSection } from './cards-section';
import { DefaultCard } from './builds/default-card';
import { InstrumentCard } from './builds/instrument-card';
import { CardAuthor } from './partials/author';
import { CardContainer } from './partials/container';
import { DefaultLayout } from './partials/default-layout';
import { ParametersTable } from './partials/parameters-table';
import { CardPriceInfo } from './partials/price-info';
import { CardSummaryText } from './partials/summary-text';
import { CardThumbnail } from './partials/thumbnail';
import { CardTitleBlock } from './partials/title-block';

export {
    CardAuthor as Author,
    CardsSection,
    CardContainer as Container,
    DefaultCard,
    DefaultLayout,
    InstrumentCard,
    ParametersTable,
    CardPriceInfo as PriceInfo,
    CardSummaryText as SummaryText,
    CardThumbnail as Thumbnail,
    CardTitleBlock as TitleBlock,
};
export type { DefaultCardPropsType };

export default DefaultCard;
