/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';

import { notEmpty } from 'services/utils/not-empty';

import { CacheData, ResourcesMap } from '../types';
import { traverseResources } from '../utils/traverseResources';

import { memoizationCache } from './memoization';

export function cacheRead({
    kind,
    cache,
    resourcesMap,
    typename,
    id,
}: {
    kind: 'request' | 'resource';
    cache: CacheData;
    resourcesMap: ResourcesMap<any>;
    typename: string;
    id: string;
}): any {
    const cachePart = kind === 'request' ? cache.requests : cache.resources;
    const rootCacheKey = `${typename}:${id}`;
    const root = cachePart[typename as string]?.[id];
    if (!root) {
        return undefined;
    }
    if (!resourcesMap[typename].resources && !resourcesMap[typename].computedFields) {
        return root;
    }
    const memoized = memoizationCache.get(rootCacheKey);
    if (memoized) {
        return memoized;
    }

    const dependencies: string[] = [];
    let reconstructed = produce(root, (rootDraft: any) => {
        traverseResources(
            rootDraft,
            resourcesMap,
            typename,
            (item: any, parent: any, nestedType: string, keyOrIndex: string | number) => {
                if (Array.isArray(item)) {
                    parent[keyOrIndex] = item
                        .map((arrayElement) => {
                            const nestedResource = cacheRead({
                                kind: 'resource',
                                cache,
                                resourcesMap,
                                typename: nestedType,
                                id: arrayElement,
                            });
                            dependencies.push(`${nestedType as string}:${arrayElement}`);
                            return nestedResource;
                        })
                        .filter(notEmpty);
                } else {
                    const nestedResource = cacheRead({
                        kind: 'resource',
                        cache,
                        resourcesMap,
                        typename: nestedType,
                        id: item,
                    });
                    dependencies.push(`${nestedType as string}:${item}`);
                    parent[keyOrIndex] = nestedResource;
                }
            },
        );
    });
    const { computedFields } = resourcesMap[typename];
    if (computedFields) {
        reconstructed = produce(reconstructed, (draft: any) => {
            const read = (dependencyType: string, dependencyId: unknown) => {
                const dependency = cacheRead({
                    kind: 'resource',
                    cache,
                    resourcesMap,
                    typename: dependencyType,
                    id: `${dependencyId}`,
                });

                if (dependency) {
                    dependencies.push(cachePart[dependencyType as string]?.[`${dependencyId}`]);
                } else {
                    dependencies.push(`${dependencyType as string}:${dependencyId}`);
                }
                return dependency;
            };

            Object.entries(computedFields).forEach(([fieldName, computeFunction]) => {
                draft[fieldName] = computeFunction(
                    reconstructed,
                    read as <K extends string | number | symbol>(typename: K, id: unknown) => any,
                );
            });
        });
    }

    memoizationCache.set(rootCacheKey, reconstructed, dependencies);
    return reconstructed;
}
