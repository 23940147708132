import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'fileapi';
import urlMap from 'url-map';
import store from 'app/store2';
import { FeedContextValue } from 'context/feed';
import { createPage, getCommonInitialPageData, InitialPageSettings, InitPageConfiguration } from 'services/init-page';
import { ComonRecordExporter, RecommendedUsersContainerExporter } from 'services/api-services/generated_limex';
import { ApiSettings } from 'services/init-page/types';
import { NavigationMenu } from 'widgets/navigation-menu';
import { ChatWidgetContainer } from 'widgets/chat';

import { initPageData, ProfilePage } from './page';
import { PageRawData } from './types';
import { TabIds } from './widgets/tabs/types';

type FeedContextExtension = { feedContext: FeedContextValue };

const configuration: InitPageConfiguration = {
    mountPoint: () => document.getElementById('profilePage'),
    urlMap,
};

const page = createPage<PageRawData, FeedContextExtension>('ProfilePage', configuration, (initialSettings) => {
    const { feedContext, data } = initialSettings;

    const feedRecords: (ComonRecordExporter | RecommendedUsersContainerExporter)[] = [];
    const feedBoundary = data?.feed?.boundary || '';
    const feedBoundaryRecordId = data?.feed?.boundaryRecordId || '';

    initPageData({
        ...data,
        feed: {
            records: feedRecords,
            boundary: feedBoundary,
            boundaryRecordId: feedBoundaryRecordId,
        },
    });

    const profileUser = data?.page?.user;
    const initialTabsData = data?.page?.tabs;

    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                <NavigationMenu isShowFavorites />
                <ChatWidgetContainer />
                <ProfilePage
                    feedContext={feedContext}
                    profileUserId={profileUser?.id}
                    personalAlias={profileUser?.personalAlias}
                    currentUserInBLForProfile={Boolean(profileUser?.isBlockedByGivenUser)}
                    initialTabsData={initialTabsData}
                />
            </BrowserRouter>
        </ReduxProvider>
    );
});

const tabsMap: Record<string, TabIds> = {
    feed: 'feed',
    premium: 'premiumPosts',
    likes: 'likesAndComments',
    products: 'authorsProducts',
};

function convertApiSettingsToInitialPageData(initialSettings?: {
    data: ApiSettings;
    params: Record<string, string>;
}): InitialPageSettings<PageRawData, FeedContextExtension> | undefined {
    if (!initialSettings) {
        return undefined;
    }

    const initialPageData = getCommonInitialPageData(initialSettings);

    const { data, params } = initialSettings;
    const { profileFeedTags, notifications, profile, tagsSlider, blogpostsCount, premiumBlogpostsCount } = data;
    const { activeTab = 'feed' } = params;

    return profile && tagsSlider && blogpostsCount !== undefined && premiumBlogpostsCount !== undefined
        ? {
              ...initialPageData,
              feedContext: {
                  ...initialPageData.feedContext,
                  owner: profile,
              },
              data: {
                  notifications,
                  tags: profileFeedTags,
                  feed: { records: [], boundary: '', boundaryRecordId: '' },
                  page: {
                      user: profile,
                      settingsUrl: '/settings/',
                      tabs: {
                          blogpostsCount,
                          premiumBlogpostsCount,
                          activeTab: tabsMap[activeTab],
                      },
                  },
                  tagsSlider,
              },
          }
        : undefined;
}

async function init() {
    const initApiData = window.WT?.initApiPromise ? await window?.WT.initApiPromise : undefined;
    page.saveAndRenderIfNeeded(convertApiSettingsToInitialPageData(initApiData));
}

init();
