import { usePageContext } from 'context/page';

export const useCSRFToken = () => {
    const [
        {
            tokens: { csrf },
        },
    ] = usePageContext();
    return csrf;
};
