import { useCallback } from 'react';
import * as Sentry from '@sentry/browser';

import notify from 'services/notify';
import Dic from 'services/dictionary';
import { CheckOutlineIcon } from 'components/icons2/CheckOutlineIcon';

import { PublisherFn } from '../../add-status';

import { useEditRecord } from './useEditRecord';

export const usePublisherFn = (actionCreator?: (...args: Parameters<PublisherFn>) => Promise<unknown>) => {
    const { isEditing } = useEditRecord();

    return useCallback(
        (...args: Parameters<PublisherFn>) => {
            const result = actionCreator ? actionCreator(...args).then(() => undefined) : Promise.resolve();
            result
                .then(() => {
                    if (isEditing) {
                        notify.success({
                            icon: {
                                component: CheckOutlineIcon,
                            },
                            text: Dic.word('wt_feed__widget__edit_post__success'),
                        });
                    } else {
                        notify.success({
                            icon: {
                                component: CheckOutlineIcon,
                            },
                            text: Dic.word('wt_feed__widget__add_post__success'),
                        });
                    }
                })
                .catch((action) => {
                    const error = action?.payload?.error;
                    if (error) {
                        const message = error?.response?.data?.errors?.[0]?.message;
                        if (message) {
                            Sentry.captureMessage(message);
                            notify.error(message);
                        } else {
                            notify.error(Dic.word('wt_feed__widget_add_status__on_error'));
                        }
                    }
                });

            return result;
        },
        [actionCreator, isEditing],
    );
};
