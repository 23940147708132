/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedWebResponse_ILeanProductDto } from '../models/shared/PagedWebResponse_ILeanProductDto';
import type { WebResponse_Array_ILeanProductDto } from '../models/shared/WebResponse_Array_ILeanProductDto';
import type { WebResponse_IRichProductDto } from '../models/shared/WebResponse_IRichProductDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CatalogService {
    /**
     * Получить каталог продуктов
     * @returns PagedWebResponse_ILeanProductDto Success
     * @throws ApiError
     */
    public static getProductsV2({
        productType,
        authorId,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Тип продукта
         */
        productType?: string | null;
        /**
         * KratosId автора
         */
        authorId?: string | null;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/catalog',
            query: {
                ProductType: productType,
                AuthorId: authorId,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }

    /**
     * Получить подробную информацию по продукту
     * @returns WebResponse_IRichProductDto Success
     * @throws ApiError
     */
    public static getApiVCatalogProduct({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<WebResponse_IRichProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/catalog/product/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * @deprecated
     * Используйте public/api/v2/catalog
     * @returns WebResponse_Array_ILeanProductDto Success
     * @throws ApiError
     */
    public static getProducts({
        productType,
        count,
    }: {
        /**
         * Фильтр по типу продукта
         */
        productType?: string | null;
        /**
         * Ограничить количество результатов
         */
        count?: number | null;
    }): CancelablePromise<WebResponse_Array_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalog',
            query: {
                ProductType: productType,
                Count: count,
            },
        });
    }

    /**
     * Получить подробную информацию по продукту
     * @returns WebResponse_IRichProductDto Success
     * @throws ApiError
     */
    public static getProduct({
        id,
    }: {
        /**
         * Идентификатор продукта
         */
        id: number;
    }): CancelablePromise<WebResponse_IRichProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalog/product/{id}',
            path: {
                id: id,
            },
        });
    }

    /**
     * @deprecated
     * Используйте public/api/v2/catalog
     * @returns PagedWebResponse_ILeanProductDto Success
     * @throws ApiError
     */
    public static getApiVCatalogSearch({
        textSearch,
        productType,
        authorId,
        page,
        pageSize,
        orderBy,
    }: {
        /**
         * Поиск по тексту в продукте
         */
        textSearch?: string | null;
        /**
         * Тип продукта
         */
        productType?: string | null;
        /**
         * KratosId автора
         */
        authorId?: string | null;
        page?: number;
        pageSize?: number;
        orderBy?: Array<string> | null;
    }): CancelablePromise<PagedWebResponse_ILeanProductDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalog/search',
            query: {
                TextSearch: textSearch,
                ProductType: productType,
                AuthorId: authorId,
                Page: page,
                PageSize: pageSize,
                OrderBy: orderBy,
            },
        });
    }
}
