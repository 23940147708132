import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ContextMenuBase = styled.div`
    position: relative;
    width: 320px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg.primary};
    box-shadow: ${({ theme }) => theme.shadow.popover};

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${mediaQuery.lt768} {
        box-shadow: none;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const Loading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg.primary};
    color: ${({ theme }) => theme.label.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
`;
