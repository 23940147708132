import styled from 'styled-components';

const DEFAULT_HEIGHT_VALUE = 149;

export const CardThumbnailWrapper = styled.div<{ relativeHeightByWidth?: number; height?: number }>`
    position: relative;
    display: flex;
    height: ${({ relativeHeightByWidth, height }) =>
        (height && `${height}px`) || (relativeHeightByWidth && '0') || `${DEFAULT_HEIGHT_VALUE}px`};

    ${({ relativeHeightByWidth, height }) =>
        !height &&
        relativeHeightByWidth &&
        `
        padding-bottom: ${relativeHeightByWidth}%;

        &:before {
            content: '';
            display: block;
            padding-bottom: ${relativeHeightByWidth}%;
        }
    `}
`;

export const ThumbnailImg = styled.img<{ loaded?: boolean; relativeHeightByWidth?: number }>`
    flex-basis: 100%;
    flex-grow: 1;
    transition: opacity 1000ms;
    opacity: ${({ loaded }) => (loaded ? '1' : '0')};
    ${({ relativeHeightByWidth }) =>
        relativeHeightByWidth &&
        `
        position:absolute;
        object-fit: cover;
    `};
    width: 100%;
    height: 100%;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
`;

export const ThumbnailChildrenContent = styled.div``;
