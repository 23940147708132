import { ArrayElement, Id } from 'types';
import { Tabs } from 'components/tabs';

import { ProfilePageTabsType } from '../../types';

const tabIds = ['feed', 'premiumPosts', 'likesAndComments', 'authorsProducts'] as const;
export type TabIds = ArrayElement<typeof tabIds>;

export function isTabId(id: string | number): id is TabIds {
    return typeof id === 'string' && (tabIds as readonly string[]).includes(id);
}

export type Props = {
    tabs: ProfilePageTabsType;
    ownerId: Id;
    ownerAlias: string | null;
    onTabChange: React.ComponentProps<typeof Tabs>['onItemSelected'];
    currentTab: TabIds;
    isVerifiedAuthor: boolean;
    premiumSubscribed: boolean;
};
