import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export class SentryError extends Error {
    constructor(message: string, errorName: string) {
        super(message);
        this.name = errorName;
    }
}

const checks = {};

export const sentryClient = {
    init: () => {
        Sentry.init({
            dsn: process.env.SENTRY_DNS,
            integrations: [new Integrations.BrowserTracing()],
            attachStacktrace: true,

            tracesSampleRate: 1.0,
            /** Если ошибка это AxiosError, добавляем ответ от сервера в секцию extra отправляемого события */
            beforeSend(event, hint) {
                const error = hint?.originalException;
                if (axios.isAxiosError(error)) {
                    const { response } = error;
                    if (response) {
                        if (!event.extra) {
                            event.extra = {};
                        }
                        event.extra.response = response;
                    }
                }
                return event;
            },
        });
    },

    setContext: Sentry.setContext,

    captureException: Sentry.captureException,

    SentryError,

    checks,
};

if (window.WT) {
    window.WT.sentryClient = sentryClient;
}
