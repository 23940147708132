import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { UseFormReturn, UseFormSetError } from 'react-hook-form/dist/types/form';
import identity from 'lodash/identity';
import { FieldPath } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import pick from 'lodash/pick';

import { Action } from 'types';
import notify from 'services/notify';
import { api } from 'services/api-builder/api';
import { useDictionary } from 'hooks/useDictionary';
import { useAsyncFn } from 'hooks/useAsyncFn';

import { PopupState, StepProps } from '../components/steps/types';

export async function validateProfanity<T, V extends FieldValues>(
    checkProfanity: Action<typeof api.fetchCheckProfanity>,
    formData: T,
    checkFields: string[],
    setError: UseFormSetError<V>,
    genericError: string,
) {
    try {
        const fields = pick(formData, checkFields) as Record<string, string>;
        const { data, error } = await checkProfanity({ requestBody: fields });
        if (error || !data) {
            notify.error(genericError);
            return false;
        }
        if (data.detectedProfanity) {
            const violations = Object.entries(data.detectedProfanity);

            if (violations.length > 0) {
                Object.entries(data.detectedProfanity).forEach(([propertyPath, message]) => {
                    setError(propertyPath as FieldPath<V>, { message });
                });
                return false;
            }
        }
        return true;
    } catch (error) {
        notify.error(genericError);
        return false;
    }
}

/** Содержит логику передачи информации о состоянии wt-popup3 (title, steps, actionButtons )
 * родительскому компоненту и валидации формы при отправке. */
export function useFormStateHandler<StepData, FormValues extends FieldValues>(options: {
    form: UseFormReturn<FormValues>;
    props: StepProps<FormValues>;
    toData?: (values: FormValues) => StepData;
    canSkip?: (values: FormValues) => boolean;
    isBlocking?: (values: FormValues) => boolean;
    title?: string;
    checkFields?: string[];
    customValidation?: (values: FormValues) => Promise<unknown>;
}) {
    const { form, props, canSkip, toData = identity, isBlocking, title, checkFields, customValidation } = options;
    const { checkProfanity, onPopupStateChange, goBack, goNext, stepIndex, valuesRef, totalSteps } = props;

    const {
        getValues,
        setError,
        formState: { isValid },
        watch,
        reset,
    } = form;

    const dic = useDictionary();

    const [canSkipState, setCanSkipState] = useState(canSkip?.(getValues()) ?? false);
    const [isBlockingState, setIsBlockingState] = useState(isBlocking?.(getValues()) ?? false);

    useEffect(() => {
        if (valuesRef?.current) {
            reset(valuesRef.current, { keepDefaultValues: true });
            setCanSkipState(canSkip?.(valuesRef.current) ?? false);
            setIsBlockingState(isBlocking?.(valuesRef.current) ?? false);
        }
    }, [canSkip, isBlocking, reset, valuesRef]);

    const serverValidation = useCallback(async () => {
        const values = getValues();
        const formData = toData(values);

        if (formData && checkProfanity && checkFields) {
            return validateProfanity(
                checkProfanity,
                formData,
                checkFields,
                setError,
                dic.word('wt_all__notification__common_error'),
            );
        }
        if (customValidation) {
            try {
                await customValidation(values);
                return true;
            } catch {
                return false;
            }
        }
        return false;
    }, [getValues, toData, checkProfanity, checkFields, customValidation, setError, dic]);

    const onSubmit = useCallback(async () => {
        const values = getValues();
        if (canSkip?.(values)) {
            goNext();
            return;
        }
        if ((!checkFields && !customValidation) || (await serverValidation())) {
            goNext();
        }
    }, [getValues, canSkip, checkFields, customValidation, serverValidation, goNext]);

    const [{ isLoading: isProcessing }, handleSubmit] = useAsyncFn(onSubmit);

    const popupState: PopupState = useMemo(() => {
        const values = getValues();
        return {
            title: title ?? dic.word('wt_all__widget_product_wizard__popup_title'),
            steps: {
                textBtnBack: dic.word('wt_all__widget_product_wizard__back_button_text'),
                textStatusSteps:
                    stepIndex < totalSteps + 1
                        ? dic.word('wt_all__widget_product_wizard__steps_count_template_text', {
                              current: stepIndex,
                              from: totalSteps,
                          })
                        : dic.word('wt_all__widget_product_wizard__complete_button_text'),
                onBack: goBack,
                backBtnIsDisabled: stepIndex === 1 || isBlocking?.(values),
            },
            actionButtons: {
                direction: 'vertical',
                items: [
                    {
                        key: 1,
                        kind: canSkipState ? 'ghost-secondary' : 'primary',
                        content: canSkipState
                            ? dic.word('wt_all__widget_product_wizard__skip_button_text')
                            : dic.word('wt_all__widget_product_wizard__next_button_text'),
                        onClick: handleSubmit,
                        isDisabled: !canSkipState && !isValid,
                        isProcessing: isProcessing || isBlockingState,
                        className: 'autotest__marketplace__product_wizard__btn_next',
                    },
                ],
            },
        };
    }, [
        getValues,
        canSkipState,
        dic,
        handleSubmit,
        isValid,
        isBlockingState,
        isProcessing,
        stepIndex,
        goBack,
        isBlocking,
        title,
        totalSteps,
    ]);

    useLayoutEffect(() => {
        onPopupStateChange(popupState);
    }, [onPopupStateChange, popupState]);

    useEffect(() => {
        const { unsubscribe } = watch(() => {
            if (valuesRef) {
                valuesRef.current = getValues();
            }
            setCanSkipState(canSkip?.(getValues()) ?? false);
            setIsBlockingState(isBlocking?.(getValues()) ?? false);
        });
        return () => unsubscribe();
    }, [watch, canSkip, getValues, isBlocking, valuesRef]);
}
