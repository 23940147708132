import styled, { css } from 'styled-components';

import { reduceBreakpoints } from 'app/styled';
import { body14RegularFontStyle, body17RegularFontStyle } from 'services/typography';
import { Theme } from 'services/theme';
import IconedTextComponent from 'components/iconed-text2';
import { TextWrapper as TextWrapperIconedText } from 'components/iconed-text2/styled';

import { MenuItemKind, MenuItemProps, MenuItemSize } from '../types';

const color = (colorText: string, colorIcon: string) => `
    color: ${colorText};
    svg {
        fill: ${colorIcon};
        color: ${colorIcon};
    }
`;

const colorMap = ({ fillIn, label }: Theme, kind: MenuItemKind) => {
    const kindMap = {
        primary: {
            isSelected: `
                ${color(label.accent, label.accent)};
            `,
            isSelectedHovered: `
                ${color(label.accent, label.accent)};
            `,
        },
        secondary: {
            isSelected: `
                ${color(label.primary, label.primary)};
            `,
            isSelectedHovered: `
                ${color(label.primary, label.primary)};
            `,
        },
    };
    return kindMap[kind];
};

type MenuItemWrapperStyledProps = Required<Pick<MenuItemProps, 'isSelected' | 'isHovered' | 'kind' | 'size'>> &
    Pick<MenuItemProps, 'width'>;

const colors = ({ theme, isSelected, kind, isHovered }: { theme: Theme } & MenuItemWrapperStyledProps) => {
    const kindMap = colorMap(theme, kind);

    if (isSelected && isHovered) {
        return `
            ${kindMap.isSelectedHovered};
        `;
    }

    if (isSelected) {
        return css`
            ${kindMap.isSelected}
        `;
    }
    return '';
};

export const IconedText = styled(IconedTextComponent)<
    {
        size: MenuItemSize;
        isHovered: boolean;
        isSelected: boolean;
        kind: MenuItemKind;
    } & Pick<MenuItemProps, 'rightGroup'>
>`
    display: flex;
    align-items: center;
    margin-right: ${({ rightGroup }) => (rightGroup && Object.keys(rightGroup)?.length > 0 ? '12px' : '0')};
    flex-basis: 100%;
    flex-shrink: 1;
    overflow: hidden;
    color: ${({ theme: { label } }) => label.primary};

    ${TextWrapperIconedText} {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${({ size }) =>
        reduceBreakpoints(size, (_size) => {
            switch (_size) {
                case 'large':
                    return `
                        ${body17RegularFontStyle};
                    `;
                case 'regular':
                default:
                    return `
                        ${body14RegularFontStyle};
                    `;
            }
        })};
    ${colors};
`;
