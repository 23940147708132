import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    autoPlacement,
    autoUpdate,
    FloatingPortal,
    offset,
    size,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useTransitionStyles,
} from '@floating-ui/react';
import cn from 'classcat';

import { usePopupRoot } from 'context/popup-root';

import type { ListBoxProps } from '../types/ListBox.types';
import { DEFAULT_KIND_LIST_BOX, DEFAULT_SIZE_LIST_BOX, DEFAULT_WIDTH_LIST_BOX } from '../constants';
import * as Styled from '../styled/ListBox.styled';

import { ListBoxButton } from './Button';

export const ListBox: FC<ListBoxProps> = (props) => {
    const {
        errorText,
        value: valueProp,
        onChange,
        placeholder,
        className: classNameProp,
        options,
        customOptionRender,
        rightGroup,
        isDisabled,
        isSkeleton = false,
        label,
        width = DEFAULT_WIDTH_LIST_BOX,
        kind = DEFAULT_KIND_LIST_BOX,
        size: sizeProp = DEFAULT_SIZE_LIST_BOX,
        maxHeight,
        renderOptionsWrapper,
    } = props;

    const className = useMemo(() => cn(['autotest__list-box', classNameProp]), [classNameProp]);

    const root = usePopupRoot();

    const [isOpen, setIsOpenChange] = useState(false);

    useEffect(() => {
        if (isDisabled) {
            setIsOpenChange(false);
        }
    }, [isDisabled]);

    const { refs, floatingStyles, context } = useFloating<Element>({
        placement: 'bottom-start',
        strategy: 'fixed',
        middleware: [
            offset(8),
            size({
                apply({ rects, elements, availableHeight }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                        maxHeight: `${
                            maxHeight === undefined ? availableHeight - 32 : Math.min(maxHeight, availableHeight - 32)
                        }px`,
                    });
                },
            }),
            autoPlacement({ allowedPlacements: ['bottom-start', 'top-start'] }),
        ],
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpenChange,
    });

    const click = useClick(context, { toggle: true });
    const dismiss = useDismiss(context, { capture: { outsidePress: false } });
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

    const { isMounted, styles } = useTransitionStyles(context, {
        duration: 360,
        initial: { opacity: 0, transform: 'translateY(8px)' },
    });

    const hasOptions = options && options?.length > 0;
    const hasError = !!errorText;

    const selectedOption = useMemo(
        () => (hasOptions && options.find(({ value }) => value === valueProp)) || undefined,
        [hasOptions, options, valueProp],
    );

    const optionsElements = options?.map((argsItem) => {
        const { value, text } = argsItem;

        const onClick = () => {
            onChange(value);
            setIsOpenChange(false);
        };

        const optionItemEl = (
            <Styled.OptionButton key={value} onClick={onClick}>
                <Styled.OptionContent>{text}</Styled.OptionContent>
            </Styled.OptionButton>
        );

        if (customOptionRender) {
            return (
                <Styled.OptionItemWrapper key={value}>
                    {customOptionRender({ value, text, onClick, optionItemEl })}
                </Styled.OptionItemWrapper>
            );
        }

        return <Styled.OptionItemWrapper key={value}>{optionItemEl}</Styled.OptionItemWrapper>;
    });

    return (
        <Styled.ListBoxWrapper className={className}>
            <ListBoxButton
                placeholder={placeholder}
                label={label}
                isOpen={isOpen}
                isDisabled={isDisabled}
                rightGroup={rightGroup}
                width={width}
                kind={kind}
                size={sizeProp}
                isError={hasError && !isSkeleton}
                ref={refs.setReference}
                isSkeleton={isSkeleton}
                {...getReferenceProps()}
            >
                {!isSkeleton && selectedOption?.text}
            </ListBoxButton>
            {hasError && !isSkeleton ? (
                <Styled.ErrorTextWrapper>
                    <Styled.ErrorText className="autotest__list-box__error-text">{errorText}</Styled.ErrorText>
                </Styled.ErrorTextWrapper>
            ) : null}
            {root && isMounted && hasOptions && !isSkeleton && (
                <FloatingPortal root={root}>
                    <Styled.Floating ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                        <Styled.Transition style={styles}>
                            <Styled.Options className="autotest__list-box__options">
                                {renderOptionsWrapper ? renderOptionsWrapper(optionsElements) : optionsElements}
                            </Styled.Options>
                        </Styled.Transition>
                    </Styled.Floating>
                </FloatingPortal>
            )}
        </Styled.ListBoxWrapper>
    );
};
