import { Person } from 'types';

export enum TABS {
    following,
    followers,
    // topics,
}

export type PeopleState = {
    list: Person[];
    nextCursor: number;
};

export type WidgetSubscribersState = {
    subscribers: PeopleState;
    subscriptions: PeopleState;
};
