import styled from 'styled-components';

import { AvatarProps } from '../types';

export const AvatarBlock = styled.div<Pick<AvatarProps, 'isLoading' | 'onClick'>>`
    display: inline-block;
    cursor: ${({ onClick, isLoading }) => {
        if (isLoading) return 'progress';
        if (onClick) return 'pointer';
        return 'inherit';
    }};
`;
