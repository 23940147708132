import styled, { css } from 'styled-components';

import { labelPrimary, labelSecondary, reduceBreakpoints } from 'app/styled';
import {
    body17MediumFontStyle,
    body14MediumFontStyle,
    body14RegularFontStyle,
    caption12RegularFontStyle,
} from 'services/typography';
import { tokens } from 'services/tokens';
import { LevelRisk as LevelRiskComponent } from 'components/level-risk';
import { Content as LevelRiskContent, LineWrapper as LevelRiskLineWrapper } from 'components/level-risk/styled';
import { AvatarRoundedSmartLinkBlock } from 'components/avatar';
import { LevelRiskValue } from 'components/level-risk/types';

import { ComplexItemProps } from './types';

const TRANSITION_DELAY = 360;

export const Details = styled.div<{ isLoading: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    gap: ${({ isLoading }) => (isLoading ? '8px' : '0')};
`;

export const BullWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const BullSymbol = styled.div`
    background-color: ${({ theme }) => theme.label.inactive};
    display: inline-block;
    height: 3px;
    width: 3px;
    border-radius: 3px;
    margin: 0 4px;
    vertical-align: middle;
`;
export const Text = styled.span``;

export const Title = styled.div<{ isLoading: boolean }>`
    flex: initial;
    transition: all 360ms;

    background-color: ${({ isLoading }) => (isLoading ? tokens.colors.opacity.base['16'] : 'transparent')};
    border-radius: ${({ isLoading }) => (isLoading ? '2px' : '0')};
    width: ${({ isLoading }) => (isLoading ? '72px' : 'auto')};

    & ${Text}, & > a,
    & > span {
        text-decoration: none;
        color: ${labelPrimary};
    }
`;

export const SecondLine = styled.div<{ isLoading: boolean }>`
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all 360ms;
    line-height: normal;
    color: ${labelSecondary};
    background-color: ${({ isLoading }) => (isLoading ? tokens.colors.opacity.base['16'] : 'unset')};
    border-radius: ${({ isLoading }) => (isLoading ? '2px' : '0')};
    width: ${({ isLoading }) => (isLoading ? '48px' : 'auto')};

    ${Text}, span {
        white-space: nowrap;
    }
`;

const indentBottomForLevelRisk = 4;

export const LevelRisk = styled(LevelRiskComponent)<{ isLoading: boolean }>`
    position: absolute;
    left: 0;
    z-index: 2;

    display: flex;
    justify-content: center;

    width: 100%;

    transition: top ${TRANSITION_DELAY - 60}ms, opacity ${TRANSITION_DELAY}ms, border-radius ${TRANSITION_DELAY / 2}ms;

    top: ${({ isLoading }) => (isLoading ? '0' : '24px')};
    opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
    border-radius: ${({ isLoading }) => (isLoading ? '50%' : '0')};
    overflow: ${({ isLoading }) => (isLoading ? 'hidden' : 'unset')};

    ${LevelRiskContent} {
        transition: height ${TRANSITION_DELAY}ms;
    }
    ${LevelRiskLineWrapper} {
        transition: height ${TRANSITION_DELAY}ms;
    }
`;

export const AvatarWrapper = styled.div<{ levelRiskValue?: LevelRiskValue; isLoading: boolean }>`
    display: flex;
    position: relative;
    transition: padding ${TRANSITION_DELAY}ms;
    padding: ${({ levelRiskValue, isLoading }) => {
        if (!levelRiskValue || isLoading) return '0';

        const bottom = levelRiskValue ? indentBottomForLevelRisk : 0;
        const leftRight = Number(levelRiskValue) === 10 ? 3 : 0;
        return `0 ${leftRight}px ${bottom}px`;
    }};
`;

export const Avatar = styled(AvatarRoundedSmartLinkBlock)<{ isLoading: boolean }>`
    transition: background-color ${TRANSITION_DELAY}ms;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
`;

type SizeMapProps = Required<Pick<ComplexItemProps, 'size' | 'avatarProps' | 'isLoading'>> &
    Pick<ComplexItemProps, 'onClick'>;

const sizeMapWrapper = ({ size: responsiveSize, avatarProps, isLoading }: SizeMapProps) => {
    const buildAvatarSize = (px: number) =>
        (!avatarProps.size &&
            !avatarProps.size &&
            `
            ${Avatar} {
                width: ${px}px;
                height: ${px}px;
            }
            ${LevelRisk} {
                ${
                    isLoading
                        ? `
                    width: ${px}px;
                    height: ${px}px;

                    ${LevelRiskContent} {
                        height: ${px}px;
                    }
                    ${LevelRiskLineWrapper} {
                        height: ${px}px;
                    }
                `
                        : ''
                }
            }
        `) ||
        '';

    return reduceBreakpoints(responsiveSize, (_size) => {
        switch (_size) {
            case 'small':
                return `
                        gap: 12px;
                        ${buildAvatarSize(40)};

                        ${Title} {
                            min-height: 12px;
                            ${Text}, span, a {
                                ${body14MediumFontStyle};
                            }
                        }

                        ${SecondLine} {
                            min-height: 8px;
                            ${Text}, span, a {
                                ${caption12RegularFontStyle};
                            }
                        }
                    `;
            case 'regular':
            default:
                return `
                        gap: 16px;
                        ${buildAvatarSize(40)};

                        ${Title} {
                            min-height: 16px;
                            ${Text}, span {
                                ${body17MediumFontStyle};
                            }
                        }

                        ${SecondLine} {
                            min-height: 12px;
                            ${Text}, span {
                                ${body14RegularFontStyle};
                            }
                        }
                    `;
        }
    });
};

export const ComplexItemWrapper = styled.div<SizeMapProps>`
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: ${({ isLoading, onClick }) => {
        if (isLoading) {
            return 'progress';
        }

        if (onClick) {
            return 'pointer';
        }

        return 'default';
    }};
    ${sizeMapWrapper};
`;
