import { connect } from 'react-redux';

import { State } from '../../types';

import { TabsWidget } from './components';

const mapStateToProps = (state: State) => {
    const {
        page: { tabs, user },
    } = state;
    return {
        tabs,
        ownerId: user.id,
        ownerAlias: user.personalAlias,
    };
};

const mapDispatchToProps = {};

export const TabsWidgetContainer = connect(mapStateToProps, mapDispatchToProps)(TabsWidget);
