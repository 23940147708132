import { useRef, useEffect } from 'react';

export const useSafeUnmount = <Callback extends () => void>() => {
    const isMounted = useRef(true);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });
    return (callback: Callback) => {
        if (isMounted.current) callback();
    };
};
