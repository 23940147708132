/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AspNetCore_Extensions_WebResponse } from '../models/shared/AspNetCore_Extensions_WebResponse';
import type { Marketplace_Web_Public_V1_My_Profile_EditProfileWebRequest } from '../models/ProfileService/Marketplace_Web_Public_V1_My_Profile_EditProfileWebRequest';
import type { WebResponse_Array_DiagnosticsUserAccount } from '../models/ProfileService/WebResponse_Array_DiagnosticsUserAccount';
import type { WebResponse_DiagnosticsAccountInfo } from '../models/ProfileService/WebResponse_DiagnosticsAccountInfo';
import type { WebResponse_GetPortfolioInfoWebResponse } from '../models/ProfileService/WebResponse_GetPortfolioInfoWebResponse';
import type { WebResponse_GetProfileWebResponse } from '../models/ProfileService/WebResponse_GetProfileWebResponse';
import type { WebResponse_PortfolioInfo } from '../models/ProfileService/WebResponse_PortfolioInfo';
import type { WebResponse_ProfileStatistics } from '../models/ProfileService/WebResponse_ProfileStatistics';
import type { WebResponse_TradeQualityResponse } from '../models/ProfileService/WebResponse_TradeQualityResponse';
import type { WebResponse_TradesPositionsInfo } from '../models/ProfileService/WebResponse_TradesPositionsInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {
    /**
     * Возвращает портфель пользователя
     * @returns WebResponse_GetPortfolioInfoWebResponse Success
     * @throws ApiError
     */
    public static getMyPortfolioV2({
        accountHash,
        returnPositionsInCurrency,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
        /**
         * Возвращать позиции в валюте
         */
        returnPositionsInCurrency?: boolean;
    }): CancelablePromise<WebResponse_GetPortfolioInfoWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/my/profile/info/{accountHash}/portfolio',
            path: {
                accountHash: accountHash,
            },
            query: {
                returnPositionsInCurrency: returnPositionsInCurrency,
            },
        });
    }

    /**
     * Возвращает данные пользователя
     * @returns WebResponse_GetProfileWebResponse Success
     * @throws ApiError
     */
    public static getProfile(): CancelablePromise<WebResponse_GetProfileWebResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile',
        });
    }

    /**
     * Редактирует профиль пользователя
     * @returns AspNetCore_Extensions_WebResponse Success
     * @throws ApiError
     */
    public static updateApiVMyProfile({
        requestBody,
    }: {
        /**
         * Поля для редактирования
         */
        requestBody?: Marketplace_Web_Public_V1_My_Profile_EditProfileWebRequest;
    }): CancelablePromise<AspNetCore_Extensions_WebResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/my/profile',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Регистрирует пользователя в Диагностике и возвращает его счета
     * @returns WebResponse_Array_DiagnosticsUserAccount Success
     * @throws ApiError
     */
    public static getAccounts(): CancelablePromise<WebResponse_Array_DiagnosticsUserAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/accounts',
        });
    }

    /**
     * Возвращает информацию по индикаторам счета
     * @returns WebResponse_DiagnosticsAccountInfo Success
     * @throws ApiError
     */
    public static getAccountInfo({
        accountHash,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
    }): CancelablePromise<WebResponse_DiagnosticsAccountInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/info/{accountHash}',
            path: {
                accountHash: accountHash,
            },
        });
    }

    /**
     * Возвращает портфель пользователя
     * @returns WebResponse_PortfolioInfo Success
     * @throws ApiError
     */
    public static getApiVMyProfileInfoPortfolio({
        accountHash,
        returnPositionsInCurrency,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
        /**
         * Возвращать позиции в валюте
         */
        returnPositionsInCurrency?: boolean;
    }): CancelablePromise<WebResponse_PortfolioInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/info/{accountHash}/portfolio',
            path: {
                accountHash: accountHash,
            },
            query: {
                returnPositionsInCurrency: returnPositionsInCurrency,
            },
        });
    }

    /**
     * Возвращает статистику и графики по счету
     * @returns WebResponse_ProfileStatistics Success
     * @throws ApiError
     */
    public static getApiVMyProfileStatistic({
        accountHash,
        period,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
        /**
         * Период для графиков
         */
        period?: 'All' | 'Week' | 'Month' | 'Quarter' | 'HalfYear' | 'Year';
    }): CancelablePromise<WebResponse_ProfileStatistics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/statistics/{accountHash}',
            path: {
                accountHash: accountHash,
            },
            query: {
                period: period,
            },
        });
    }

    /**
     * Возвращает открытые и закрытые позиции счета
     * @returns WebResponse_TradesPositionsInfo Success
     * @throws ApiError
     */
    public static getApiVMyProfileInfoTradesPositions({
        accountHash,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
    }): CancelablePromise<WebResponse_TradesPositionsInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/info/{accountHash}/trades-positions',
            path: {
                accountHash: accountHash,
            },
        });
    }

    /**
     * Возвращает информацию о качестве торговли
     * @returns WebResponse_TradeQualityResponse Success
     * @throws ApiError
     */
    public static getApiVMyProfileInfoTradeQuality({
        accountHash,
    }: {
        /**
         * Хэш счета
         */
        accountHash: string;
    }): CancelablePromise<WebResponse_TradeQualityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/my/profile/info/{accountHash}/trade-quality',
            path: {
                accountHash: accountHash,
            },
        });
    }
}
