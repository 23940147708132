import React, { ReactNode, useMemo } from 'react';

import { CurrencyCode } from 'types/currency';
import { useLocaleId } from 'hooks/useLocaleId';

import { PriceFormatter } from './price-formatter';

export type PriceDifferenceFormatterProps = {
    price: number;
    percentage: number;
    currency: CurrencyCode | null;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    percentMinimumFractionDigits?: number;
    percentMaximumFractionDigits?: number;
    children: (formattedPrice: string) => ReactNode;
};

/**
 * Provides a locale-formatted price difference string via children render prop.
 *
 * @example
 * <PriceDifferenceFormatter percentage={0.28}>
 *    {(priceDiffString) => <div>Price diff: {priceDiffString}</div>}
 * </PriceDifferenceFormatter>
 */
export const PriceDifferenceFormatter = (props: PriceDifferenceFormatterProps) => {
    const {
        price,
        currency,
        percentage,
        children,
        minimumFractionDigits,
        maximumFractionDigits,
        percentMinimumFractionDigits = minimumFractionDigits,
        percentMaximumFractionDigits = maximumFractionDigits,
    } = props;

    const signChar = percentage > 0 ? '+' : '–';
    const lcid = useLocaleId();

    const percentageStringFormatter = useMemo(
        () =>
            new Intl.NumberFormat(lcid, {
                minimumFractionDigits: percentMinimumFractionDigits,
                maximumFractionDigits: percentMaximumFractionDigits,
            }),
        [lcid, percentMinimumFractionDigits, percentMaximumFractionDigits],
    );

    // проценты представлены колическовм процентов, а не десятичной дробью
    const fractionPercentage = percentage / 100;
    // если цена упала на 100 процентов, стало быть она изменилась на весь price
    const priceAbsChange = Math.abs(
        fractionPercentage === -1 ? price : (price * fractionPercentage) / (1 + fractionPercentage),
    );

    return (
        <PriceFormatter
            minimumFractionDigits={minimumFractionDigits}
            maximumFractionDigits={maximumFractionDigits}
            price={priceAbsChange}
            currency={currency}
        >
            {(localizedPrice) =>
                children(
                    `${signChar} ${localizedPrice} (${percentageStringFormatter.format(fractionPercentage * 100)}%)`,
                )
            }
        </PriceFormatter>
    );
};
