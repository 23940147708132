import { useEffect, RefObject } from 'react';

import { IntersectionOpts, useIntersection } from 'hooks/useIntersection';
import { getCurrentHeight } from 'widgets/navigation-menu/getHeight';

const defaultOpts = {
    intersectionParams: {
        rootMargin: `-${getCurrentHeight()}px 0px 0px 0px`,
        threshold: 0.5,
    },
    deps: [],
};

export const useViewed = (
    itemRef: RefObject<HTMLElement>,
    callBack: (entry: IntersectionObserverEntry) => void,
    reobserveCondition: (observerRef: RefObject<IntersectionObserver>) => boolean,
    deps: unknown[] = [],
    intersectionOpts?: IntersectionOpts,
) => {
    const useIntersectionOpts = {
        ...defaultOpts,
        ...intersectionOpts,
        intersectionParams: {
            ...defaultOpts.intersectionParams,
            ...intersectionOpts?.intersectionParams,
        },
    };

    const { observeIntersection, observerRef } = useIntersection((entries) => {
        callBack(entries[0]);
    }, useIntersectionOpts);

    useEffect(() => {
        if (reobserveCondition(observerRef)) {
            observeIntersection(itemRef.current);
        }

        return () => observerRef?.current?.disconnect();
    }, [observeIntersection, ...deps]);
};
