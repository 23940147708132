import styled from 'styled-components';

import { Avatar as AvatarStyled } from '../../styled/Avatar.styled';
import { AvatarBlock as AvatarComponent } from '../AvatarBlock';
import { AvatarLink as AvatarLinkComponent } from '../AvatarLink';
import { AvatarSmartLinkBlock as AvatarSmartLinkBlockComponent } from '../AvatarSmartLinkBlock';

const roundOff = `
    border-radius: 50%;
    overflow: hidden;
`;

export const Avatar = styled(AvatarComponent)`
    ${roundOff};
`;

export const AvatarLink = styled(AvatarLinkComponent)`
    ${AvatarStyled} {
        ${roundOff};
    }
`;

export const AvatarSmartLinkBlock = styled(AvatarSmartLinkBlockComponent)`
    ${AvatarStyled} {
        ${roundOff};
    }
`;
