import React from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { RoundButtonProps } from './types';
import { Button } from './styled';

/** A tag name of the element used to render the button */
type ButtonTagName = 'a' | 'button';

/** Props for buttons using an <a /> element */
export type LinkRoundButtonProps = RoundButtonProps & {
    href: string;
    target: string;
};

export type { RoundButtonProps };

/** Props for polymorphic button implementation component */
type ButtonImplementationProps<TagName extends ButtonTagName> = (RoundButtonProps & Partial<LinkRoundButtonProps>) & {
    tagName: TagName;
};

function RoundButtonImplementation<TagName extends ButtonTagName>({
    children,
    className = '',
    size = 'regular',
    kind = 'primary',
    pressed = false,
    isProcessing = false,
    tagName,
    disabled = false,
    onClick = () => false,
    href,
    target,
}: ButtonImplementationProps<TagName>) {
    const linkProps = tagName === 'a' ? { href, target } : {};
    return (
        <Button
            as={tagName}
            className={className}
            kind={kind}
            size={size}
            disabled={disabled}
            isProcessing={isProcessing}
            onClick={onClick}
            pressed={pressed}
            {...linkProps}
        >
            {isProcessing ? <ProcessingIcon /> : children}
        </Button>
    );
}

export const RoundButton = (props: RoundButtonProps) => <RoundButtonImplementation {...props} tagName="button" />;

export const LinkRoundButton = (props: LinkRoundButtonProps) => <RoundButtonImplementation {...props} tagName="a" />;
