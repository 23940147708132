/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ComonStrategyExporter } from '../models/MarketplaceService/ComonStrategyExporter';
import type { ComonUsAccountExporter } from '../models/MarketplaceService/ComonUsAccountExporter';
import type { ComonUsPortfolioExporter } from '../models/MarketplaceService/ComonUsPortfolioExporter';
import type { ComonUsStrategyDTOExporter } from '../models/MarketplaceService/ComonUsStrategyDTOExporter';
import type { ComonUsStrategyExporter } from '../models/shared/ComonUsStrategyExporter';
import type { ComonUsStrategySubscriptionDTOExporter } from '../models/MarketplaceService/ComonUsStrategySubscriptionDTOExporter';
import type { ComonUsUserExporter } from '../models/MarketplaceService/ComonUsUserExporter';
import type { MarketplaceProductExporter } from '../models/shared/MarketplaceProductExporter';
import type { PaymentHistoryInfoExporter } from '../models/MarketplaceService/PaymentHistoryInfoExporter';
import type { ProductAddRequestBody } from '../models/MarketplaceService/ProductAddRequestBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketplaceService {
    /**
     * Получить список инструментов на счёту
     * @returns ComonUsPortfolioExporter account portfolio instruments
     * @throws ApiError
     */
    public static getShopAccountInstruments({
        accountId,
    }: {
        /**
         * ID счёта
         */
        accountId: number;
    }): CancelablePromise<ComonUsPortfolioExporter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/account/{accountId}/instruments/',
            path: {
                accountId: accountId,
            },
            errors: {
                401: `access denied`,
                404: `account not found`,
            },
        });
    }

    /**
     * Получить список счётов
     * @returns any accounts list
     * @throws ApiError
     */
    public static getShopAccount(): CancelablePromise<{
        accounts: Array<ComonUsAccountExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/account/',
            errors: {
                401: `access denied`,
            },
        });
    }

    /**
     * Отправить брокерский отчет (shopv2)
     * @returns void
     * @throws ApiError
     */
    public static sendBrokerReport({
        formData,
    }: {
        formData?: {
            /**
             * Файл брокерского отчета
             */
            brokerReport: Blob;
        };
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/broker-report/send/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Создать оплату продукта
     * @returns any Ссылка на страницу оплаты либо ошибка
     * @throws ApiError
     */
    public static createShopPaymentCreate({
        requestBody,
    }: {
        requestBody?: {
            productId: number | null;
        };
    }): CancelablePromise<{
        /**
         * Идентификатор оплаты
         */
        paymentId?: number;
        /**
         * Ссылка оплаты в платежной системе
         */
        link?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/payment/create/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `access denied`,
                404: `product not found`,
            },
        });
    }

    /**
     * Узнать статус оплаты продукта
     * @returns any Получение статуса оплаты
     * @throws ApiError
     */
    public static getShopPaymentStatus({
        paymentId,
    }: {
        /**
         * ID оплаты
         */
        paymentId: number;
    }): CancelablePromise<{
        status?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/payment/{paymentId}/status/',
            path: {
                paymentId: paymentId,
            },
            errors: {
                401: `access denied`,
                404: `payment not found`,
            },
        });
    }

    /**
     * Отменить оплату продукта
     * @returns any Результат отмены оплаты 200 если ок в остальных случаях ошибка
     * @throws ApiError
     */
    public static createShopPaymentCancel({
        xCsrfToken,
        paymentId,
        requestBody,
    }: {
        /**
         * Глобальный CSRF токен
         */
        xCsrfToken: string;
        paymentId: string;
        requestBody?: any;
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/payment/{paymentId}/cancel/',
            path: {
                paymentId: paymentId,
            },
            headers: {
                'X-CSRF-Token': xCsrfToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить список платежей пользователя
     * @returns any payment history items
     * @throws ApiError
     */
    public static getPaymentHistory(): CancelablePromise<{
        history: Array<PaymentHistoryInfoExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/payment/history/',
            errors: {
                401: `access denied`,
            },
        });
    }

    /**
     * Получить список доступных способов оплаты
     * @returns any payment methods
     * @throws ApiError
     */
    public static getPaymentMethods(): CancelablePromise<{
        methods: Array<{
            brand: string;
            exp_month: number;
            exp_year: number;
            last4: string;
            type: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/payment/methods/',
            errors: {
                401: `access denied`,
            },
        });
    }

    /**
     * Получить список продуктов по типу
     * @returns any Успешное получение списка продуктов
     * @throws ApiError
     */
    public static getShopProductItems({
        type,
        cursor,
        limit,
    }: {
        /**
         * Тип продуктов (product - Приложение, service - Услуга, course - Курс)
         */
        type: 'product' | 'service' | 'course' | 'offer';
        /**
         * Курсор пиганации
         */
        cursor?: number | null;
        /**
         * Количество продуктов в выборке
         */
        limit?: number | null;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список продуктов
         */
        items?: Array<MarketplaceProductExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/products/{type}/items/',
            path: {
                type: type,
            },
            query: {
                cursor: cursor,
                limit: limit,
            },
        });
    }

    /**
     * Получить один продукт
     * @returns any A single product
     * @throws ApiError
     */
    public static getShopProductItem({ slug }: { slug: string }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/products/item/{slug}',
            path: {
                slug: slug,
            },
        });
    }

    /**
     * Получить все продукты автора
     * @returns any
     * @throws ApiError
     */
    public static getShopProductAuthor({ userId, limit = 10 }: { userId: number; limit?: number }): CancelablePromise<{
        products?: Array<MarketplaceProductExporter>;
        strategies?: Array<ComonStrategyExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/products/author/{userId}',
            path: {
                userId: userId,
            },
            query: {
                limit: limit,
            },
        });
    }

    /**
     * Получить похожие продукты
     * @returns any
     * @throws ApiError
     */
    public static getShopProductSimilar({ slug, limit = 10 }: { slug: string; limit?: number }): CancelablePromise<{
        products?: Array<MarketplaceProductExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/products/similar/{slug}',
            path: {
                slug: slug,
            },
            query: {
                limit: limit,
            },
        });
    }

    /**
     * Валидировать новый продукт магазина
     * @returns any Успешная валидация продукта магазина
     * @throws ApiError
     */
    public static validateProduct({
        validation,
        requestBody,
    }: {
        /**
         * Шаг валидации
         */
        validation: 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'step6';
        requestBody: ProductAddRequestBody;
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/products/add/validate/',
            query: {
                validation: validation,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданы параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Добавить новый продукт магазина
     * @returns any Успешное добавление продукта магазина
     * @throws ApiError
     */
    public static createProduct({ requestBody }: { requestBody: ProductAddRequestBody }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/products/add/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Список подписок
     * @returns any Список подписок на стратегии пользователя
     * @throws ApiError
     */
    public static getSubscriptions(): CancelablePromise<{
        /**
         * Список подписок на стратегии в формате API Comon5-Us
         */
        subscriptions: Array<ComonUsStrategySubscriptionDTOExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/strategy/subscription/',
            errors: {
                401: `access denied`,
            },
        });
    }

    /**
     * Создать подписку
     * @returns any Ссылка на страницу оплаты либо ошибка
     * @throws ApiError
     */
    public static subscribeToStrategy({
        requestBody,
    }: {
        requestBody?: {
            strategyId: string;
            accountId: string;
        };
    }): CancelablePromise<{
        /**
         * Ссылка на оплату
         */
        url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/strategy/subscription/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `access denied`,
                404: `strategy not found`,
            },
        });
    }

    /**
     * Удалить подписку
     * @returns void
     * @throws ApiError
     */
    public static unsubscribeFromStrategy({
        subscriptionId,
        requestBody,
    }: {
        subscriptionId: number;
        requestBody?: any;
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/shop/strategy/subscription/{subscriptionId}',
            path: {
                subscriptionId: subscriptionId,
            },
            body: requestBody,
            errors: {
                401: `Authentication failed`,
                404: `Subscription not found`,
            },
        });
    }

    /**
     * Получить список стратегий авторизованного пользователя
     * @returns any Успешное получение стратегий
     * @throws ApiError
     */
    public static getMyStrategies({
        ids,
        ownerId,
        archived,
        page,
        pageSize,
    }: {
        /**
         * ИД стратегий
         */
        ids?: Array<number>;
        /**
         * Глобальный ИД владельца в Comon5
         */
        ownerId?: string;
        /**
         * false - только существующие стратегии, true - только стратегии из архива
         */
        archived?: boolean;
        /**
         * Страница
         */
        page?: number;
        /**
         * Размер страницы
         */
        pageSize?: number;
    }): CancelablePromise<{
        /**
         * Список стратегий в формате API Comon5-Us
         */
        strategies: Array<ComonUsStrategyDTOExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/strategy/my/',
            query: {
                ids: ids,
                ownerId: ownerId,
                archived: archived,
                page: page,
                pageSize: pageSize,
            },
            errors: {
                400: `Неверно переданны параметры`,
            },
        });
    }

    /**
     * Получить список стратегий
     * @returns any Успешное получение стратегий
     * @throws ApiError
     */
    public static getShopStrategies({
        cursor = 1,
    }: {
        /**
         * Курсор пиганации
         */
        cursor?: number;
    }): CancelablePromise<{
        /**
         * Курсор на следующую пачку результатов. Если null, то это последние результаты
         */
        nextCursor?: number | null;
        /**
         * Список стратегий
         */
        strategies?: Array<ComonUsStrategyExporter>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/strategy/',
            query: {
                cursor: cursor,
            },
        });
    }

    /**
     * Получить стратегию по ИД стратегии
     * @returns any Успешное получение стратегии
     * @throws ApiError
     */
    public static getShopStrategy({
        strategyId,
    }: {
        /**
         * ИД стратегии (Идентификатор Comon5-Us)
         */
        strategyId: number;
    }): CancelablePromise<{
        strategy: ComonUsStrategyExporter;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/strategy/{strategyId}/',
            path: {
                strategyId: strategyId,
            },
            errors: {
                404: `Отсутствует стратегия с запрошенным ИД`,
            },
        });
    }

    /**
     * Архивировать стратегию ComonUs
     * @returns void
     * @throws ApiError
     */
    public static archiveStrategy({
        strategyId,
    }: {
        /**
         * ИД стратегии (Идентификатор Comon5-Us)
         */
        strategyId: number;
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/shop/strategy/{strategyId}/',
            path: {
                strategyId: strategyId,
            },
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Валидировать новую стратегию ComonUs
     * @returns any Успешное валидация стратегии
     * @throws ApiError
     */
    public static createShopStrategyAddValidate({
        validation,
        requestBody,
    }: {
        /**
         * Шаг валидации
         */
        validation?: 'step1' | 'step2' | 'step3';
        requestBody?: {
            /**
             * Имя стратегии
             */
            name: string;
            /**
             * Описание стратегии
             */
            description: string;
            /**
             * Минимальная сумма
             */
            minSum: number;
            /**
             * Счет
             */
            accountId: number;
        };
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/strategy/add/validate/',
            query: {
                validation: validation,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Добавить новую стратегию ComonUs
     * @returns any Успешное добавление стратегии
     * @throws ApiError
     */
    public static createStrategy({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Имя стратегии
             */
            name: string;
            /**
             * Описание стратегии
             */
            description: string;
            /**
             * Минимальная сумма
             */
            minSum: number;
            /**
             * Счет
             */
            accountId: number;
        };
    }): CancelablePromise<{
        strategy?: ComonUsStrategyExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/strategy/add/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Изменить стратегию ComonUs
     * @returns any Успешное изменение стратегии
     * @throws ApiError
     */
    public static editStrategy({
        strategyId,
        requestBody,
    }: {
        /**
         * ИД стратегии (Идентификатор Comon5-Us)
         */
        strategyId: number;
        requestBody?: {
            /**
             * Описание стратегии
             */
            description: string;
            /**
             * Минимальная сумма
             */
            minSum: number;
        };
    }): CancelablePromise<{
        strategy: ComonUsStrategyExporter;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shop/strategy/{strategyId}/edit/',
            path: {
                strategyId: strategyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Неверно переданны параметры`,
                401: `Не авторизованный пользователь`,
            },
        });
    }

    /**
     * Получить список счётов
     * @returns any comonUs user by token
     * @throws ApiError
     */
    public static getShopUserMe(): CancelablePromise<{
        user?: ComonUsUserExporter;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shop/user/me/',
            errors: {
                401: `access denied`,
            },
        });
    }
}
