import { useState } from 'react';
import type { API, OutputData } from '@editorjs/editorjs';
import type EditorJS from '@editorjs/editorjs';
import { Saver } from '@editorjs/editorjs/types/api';

import { useNonClosableRef } from 'hooks/useUnclosureRef';

const getDataBlock = (api: null | InstanceType<typeof EditorJS> | API, state: OutputData) => {
    if (api) {
        const indexCurrentBlock = api.blocks.getCurrentBlockIndex();

        const block = api.blocks.getBlockByIndex(indexCurrentBlock);

        const indexBlock = state?.blocks.findIndex((el) => el.id === block?.id);

        if (indexBlock >= 0) {
            return state?.blocks[indexBlock]?.data;
        }

        const prevBlock = indexCurrentBlock - 1;

        api?.caret.setToBlock(prevBlock, 'end');

        return state?.blocks[prevBlock]?.data;
    }
    return null;
};

const getCashtagStart = (text: string, focusOffset: number): number | undefined => {
    for (let i = focusOffset - 1; i >= 0; i--) {
        if (text[i] === '$') {
            return i;
        }
        // если как-то вышло что встретили пробел - то это значит, что кештега вовсе нет
        // пример: юзер вводил кештег, а потом постаил пробел не вликнув в выпадающем списке,
        // это значит что юзер захотел чтобы кештег был введен от руки
        if (text[i] === ' ') break;
    }
    return undefined;
};

export const cashtagRegexBase = '[\\w\\d\\-А-Яа-я@]+';

export const useCashtags = (
    editorJs: null | InstanceType<typeof EditorJS>,
    updateHandler: (saver: Saver) => Promise<void> | undefined,
) => {
    const [resetSuggest, setResetSuggest] = useState(false);
    const [searchTicker, setSearchTicker] = useState('');

    const editorJsRef = useNonClosableRef(editorJs);

    const onSetTicker = (ticker: string) => {
        const selection = window.getSelection();
        const focusOffset = selection?.focusOffset;
        if (editorJs) {
            editorJs.save().then((prevState) => {
                const data = getDataBlock(editorJs, prevState);
                const currentBlockId = getCurrentBlock()?.id ?? '';

                if (data) {
                    // мутация prevState
                    data.text = data.text.replace(new RegExp(`\\$${cashtagRegexBase}`, 'g'), (match: string) =>
                        match.toUpperCase(),
                    );
                    if (typeof focusOffset === 'number') {
                        const cashtagStart = getCashtagStart(data.text, focusOffset);
                        if (typeof cashtagStart === 'number') {
                            const tickerToPaste = `$${ticker} `;
                            data.text =
                                data.text.substring(0, cashtagStart) +
                                tickerToPaste +
                                data.text.substring(
                                    cashtagStart + tickerToPaste.length,
                                    data.text.length + tickerToPaste.length,
                                );
                        }
                    }
                    editorJs.blocks.update(currentBlockId, data);

                    updateHandler(editorJs.saver);
                    const updatedBlockIndex = editorJs.blocks.getBlockIndex(currentBlockId);
                    editorJs.caret.setToBlock(updatedBlockIndex, 'end');
                }
            });
        }
    };

    const currentBlockIndex = () => Math.max(editorJsRef.current?.blocks?.getCurrentBlockIndex() || 0, 0);

    const getCurrentBlock = () => editorJsRef.current?.blocks?.getBlockByIndex(currentBlockIndex());

    const insertCashtagSymbol = async (editorNode: HTMLElement | null) => {
        const sel = window.getSelection();
        if (editorJs) {
            const prevState = await editorJs.save();
            const isInEditorNode = sel && sel.type !== 'None' && editorNode?.contains(sel.anchorNode);
            const currentIndex = isInEditorNode ? currentBlockIndex() : prevState?.blocks.length - 1;
            const currentBlock = prevState?.blocks[currentIndex];
            const data = currentBlock?.data;
            const offset = isInEditorNode ? sel.focusOffset : data.text.length;

            if (!currentBlock || currentBlock?.type === 'image') {
                editorJs.blocks.insert('paragraph', { text: '$' }, undefined, currentIndex + 1, true, false);
                editorJs.caret.setToBlock(currentIndex + 1, 'end');
            } else if (currentBlock?.id && data) {
                // мутация prevState
                data.text = `${data.text.substring(0, offset)}$${data.text.substring(offset, data.text.length)}`;
                editorJs.blocks.update(currentBlock.id, data);
                editorJs.caret.setToBlock(currentIndex, 'default', offset + 1);
            } else {
                editorJs.blocks.insert('paragraph', { text: '$' }, undefined, currentIndex, true, true);
                editorJs.caret.setToBlock(currentIndex, 'end');
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const handleCashtagOnEditorChange = (api: API, event: CustomEvent) => {
        const sel = window.getSelection();
        const reset = () => {
            setSearchTicker('');
        };
        if (sel?.type !== 'None') {
            if (!sel?.focusOffset) return reset();
            const currentBlock = getCurrentBlock();
            if (!currentBlock) return reset();
            let inspectString = currentBlock.holder.innerText;
            const cashtagStart = getCashtagStart(inspectString, sel.focusOffset);
            if (!cashtagStart && cashtagStart !== 0) return reset();
            inspectString = inspectString.substring(cashtagStart);
            const cashtag = inspectString.match(new RegExp(`(?<=\\$)${cashtagRegexBase}`))?.[0];
            if (!cashtag) return reset();
            if (event.type === 'block-changed') {
                setSearchTicker(cashtag);
            }
        }
    };

    const cashtagBlockOffset = () => {
        if (!editorJs) return 0;

        const sel = window.getSelection();

        if (sel && sel.rangeCount !== 0) {
            const range = sel.getRangeAt(0).cloneRange();
            const rect = range.getClientRects()[0];

            return rect?.bottom || 0;
        }

        return 0;
    };

    return {
        resetSuggest,
        searchTicker,
        onSetTicker,
        insertCashtagSymbol,
        handleCashtagOnEditorChange,
        cashtagBlockOffset,
    };
};
