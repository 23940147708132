import { useTheme } from 'styled-components';
import React from 'react';
import cc from 'classcat';

import { HeartIcon } from 'components/icons2/HeartIcon';
import { HeartOutlineIcon } from 'components/icons2/HeartOutlineIcon';
import { RoundButton } from 'components/round-button';

import { FavoriteButtonOnClickEventProp, FavoriteButtonProps, FavoriteButtonKind } from './types';
import * as Styled from './styled';

const DEFAULT_TYPE_FAVORITE_BUTTON: FavoriteButtonKind = 'secondary';

export const FavoriteButton = (props: FavoriteButtonProps) => {
    const { onClick: onClickProp, className: classNameProp, ...withoutOnClickAndClassNameProps } = props;

    const theme = useTheme();

    const className = cc([classNameProp, 'autotest__favorite-button']);

    const { isChecked, kind = DEFAULT_TYPE_FAVORITE_BUTTON } = withoutOnClickAndClassNameProps;

    const onClick = (e: FavoriteButtonOnClickEventProp) => {
        e.preventDefault();
        e.stopPropagation();
        onClickProp?.({ e, ...withoutOnClickAndClassNameProps });
    };

    const renderIconBlock = () => {
        let iconEl;
        const fill = theme.label.tertiary;

        if (kind === 'staticColor') {
            iconEl = isChecked ? <HeartIcon /> : <HeartOutlineIcon />;
        } else {
            iconEl = isChecked ? <HeartIcon fill={fill} /> : <HeartOutlineIcon fill={fill} />;
        }

        return <>{iconEl}</>;
    };

    if (!props || (typeof props === 'object' && Object.keys(props)?.length < 1)) {
        return null;
    }

    return (
        <Styled.FavoriteButton className={className}>
            <RoundButton kind={kind} size="compact" onClick={onClick}>
                {renderIconBlock()}
            </RoundButton>
        </Styled.FavoriteButton>
    );
};

export type { FavoriteButtonProps };
