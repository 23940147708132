import React from 'react';
import noop from 'lodash/noop';

import { ButtonIcon, ButtonIconSizes, ButtonIconKinds } from 'components/button-icon';
import { LikeIcon } from 'components/icons2/LikeIcon';
import { LikeFilledIcon } from 'components/icons2/LikeFilledIcon';

interface Props {
    pressed?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    count?: number;
    size?: ButtonIconSizes;
    className?: string;
}
export const Like = (props: Props) => {
    const { pressed, disabled, onClick, count, size, className } = props;

    const iconComponent = pressed ? LikeFilledIcon : LikeIcon;

    return (
        <ButtonIcon
            pressed={pressed}
            disabled={disabled}
            onClick={onClick}
            icon={{ component: iconComponent }}
            text={count || ''}
            size={size}
            kind={ButtonIconKinds.GhostPrimary}
            className={className}
        />
    );
};
Like.defaultProps = {
    pressed: false,
    disabled: false,
    onClick: noop,
    count: 0,
    size: 'L',
};
export type { ButtonIconSizes as LikeSizes };
