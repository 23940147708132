import React from 'react';

import { MenuItem, MenuItemProps } from 'components/menu-item';
import { ContextActions, ContextActionsProps } from 'components/context-actions';

export type CardContextActionsProps = Pick<
    ContextActionsProps,
    'kind' | 'size' | 'icon' | 'onClick' | 'buttonContent'
> & {
    items: MenuItemProps[];
    disabled?: boolean;
};

export const CardContextActions = (props: CardContextActionsProps) => {
    const { items, ...otherProps } = props;
    return (
        <ContextActions
            tippyContent={
                <>
                    {items.map((item) => (
                        <MenuItem key={`${item.text}`} {...item} />
                    ))}
                </>
            }
            placement="bottom-end"
            {...otherProps}
        />
    );
};
