import { css } from 'styled-components';

import { boxShadowLikeBorder } from 'app/styled';
import { tokens } from 'services/tokens';

import { ButtonKind, ButtonWrapperProps, StateMap } from '../types';

import { contentContainerStyle, iconGapSizeMap, sizeMap, paddingLeftRightStyle } from './typeStandard';

/** Button kind-dependent CSS */
const getStateMap = (kind: ButtonKind): StateMap => {
    const {
        colors: {
            button: { bg, label },
            border: borderCommon,
            bg: bgCommon,
            opacity,
        },
        spacing,
    } = tokens;
    const primary = {
        default: `
            background: ${bg.alternative.default.default};
            color: ${label.alternate.default.default};
        `,
        hover: `
            background: ${bg.alternative.default.hover};
            color: ${label.alternate.default.default};
        `,
        focus: `
            background: ${bg.alternative.default.default};
            color: ${label.alternate.default.default};
            box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
        `,
        active: `
            background: ${bg.alternative.default.pressed};
            color: ${label.alternate.default.default};
        `,
        disabled: `
            background: ${bg.alternative.default.disabled};
            color: ${label.alternate.default.disabled};
        `,
    };
    const kindMap: Record<ButtonKind, StateMap> = {
        primary,
        'ghost-primary': primary,
        'ghost-secondary': primary,
        cancel: primary,
        secondary: {
            default: `
                background: ${bgCommon.inverse.secondary.default};
                color: ${label.alternate.default.default};
            `,
            hover: `
                background: ${bgCommon.inverse.secondary.hover};
                color: ${label.alternate.default.default};
            `,
            focus: `
                background: ${bgCommon.inverse.secondary.default};
                color: ${label.alternate.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
            `,
            active: `
                background: ${bgCommon.inverse.secondary.active};
                color: ${label.alternate.default.default};
            `,
            disabled: `
                background: ${bg.alternative.secondary.disabled};
                color: ${label.alternate.secondary.disabled};
            `,
        },
        outline: {
            default: `
                background: none;
                color: ${label.alternate.default.default};
                box-shadow: ${boxShadowLikeBorder('1px', borderCommon.inverse.default.default)};
            `,
            hover: `
                background: ${bgCommon.inverse.secondary.hover};
                color: ${label.alternate.default.default};
                box-shadow: ${boxShadowLikeBorder('1px', opacity.base[16])};
            `,
            focus: `
                color: ${label.alternate.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
            `,
            active: `
                background: ${bgCommon.inverse.secondary.active};
                color: ${label.alternate.default.default};
                box-shadow: ${boxShadowLikeBorder('1px', opacity.base[16])};
            `,
            disabled: `
                background: none;
                color: ${label.alternate.secondary.disabled};
                box-shadow: ${boxShadowLikeBorder('1px', opacity.base[16])};
            `,
        },
        'ondark-secondary': {
            default: `
                background: ${bg.alternative.ondark.secondary};
                color: ${label.ondark.default.default};
            `,
            hover: `
                background: ${bg.alternative.ondark.hover};
                color: ${label.ondark.default.default};
            `,
            focus: `
                background: ${bg.alternative.ondark.secondary};
                color: ${label.ondark.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
            `,
            active: `
                background: ${bg.alternative.ondark.pressed};
                color: ${label.ondark.default.default};
            `,
            disabled: `
                background: ${bg.alternative.ondark.disabled};
                color: ${label.ondark.default.disabled};
            `,
        },
        'ondark-outline': {
            default: `
                background: none;
                color: ${label.ondark.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, borderCommon.ondark.default.default)};
            `,
            hover: `
                background: ${bg.alternative.ondark.hover};
                color: ${label.ondark.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
            focus: `
                background: none;
                color: ${label.ondark.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
            `,
            active: `
                background: ${bg.alternative.ondark.pressed};
                color: ${label.ondark.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
            disabled: `
                background: none;
                color: ${label.ondark.default.disabled};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
        },
        'onlight-outline': {
            default: `
                background: none;
                color: ${label.onlight.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, borderCommon.onlight.secondary.default)};
            `,
            hover: `
                background: ${bg.alternative.onlight.hover};
                color: ${label.onlight.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
            focus: `
                background: none;
                color: ${label.onlight.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.brand.default.focus)};
            `,
            active: `
                background: ${bg.alternative.onlight.pressed};
                color: ${label.onlight.default.default};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
            disabled: `
                background: none;
                color: ${label.onlight.default.disabled};
                box-shadow: ${boxShadowLikeBorder(spacing.divider.height, opacity.base[16])};
            `,
        },
    };
    return kindMap[kind];
};

export const typeAlternative = {
    wrapperStyle: css<ButtonWrapperProps>`
        --padding-icon-compensation: ${({ icon, text }) => (icon && text ? '2px' : '0px')};
    `,
    sizeMap,
    paddingLeftRightStyle,
    getStateMap,
    contentContainerStyle,
    iconGapSizeMap,
};
