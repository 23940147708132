import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ChevronRightIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.3058 5.75165C8.58098 5.50704 9.00237 5.53183 9.24698 5.80702L12.5803 9.55702C12.8048 9.80961 12.8048 10.1902 12.5803 10.4428L9.24698 14.1928C9.00237 14.468 8.58098 14.4928 8.3058 14.2482C8.03061 14.0036 8.00582 13.5822 8.25043 13.307L11.1901 9.99993L8.25043 6.69283C8.00582 6.41765 8.03061 5.99626 8.3058 5.75165Z"
        />
    </IconSvg>
);
