import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14SemiboldFontStyle, caption12SemiboldFontStyle } from 'services/typography';

export const Wrapper = styled.div`
    color: ${({ theme }) => theme.label.tertiary};
    white-space: normal;
    margin-top: 8px;
    ${mediaQuery.lt768} {
        white-space: nowrap;
        margin-top: 0;
    }
`;

export const Line = styled.span<{ $isBlocked: boolean }>`
    white-space: nowrap;
    cursor: ${({ $isBlocked }) => ($isBlocked ? 'text' : 'pointer')};
`;

export const Count = styled.span`
    ${body14SemiboldFontStyle};
    color: ${({ theme }) => theme.label.primary};
    ${mediaQuery.lt768} {
        ${caption12SemiboldFontStyle};
    }
`;
