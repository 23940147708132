import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ContextMenuHeaderWrapper = styled.div<{ mobileOnly?: boolean }>`
    flex: none;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.divider.primary};
    display: ${({ mobileOnly }) => (mobileOnly ? 'none' : 'block')};

    ${mediaQuery.lt768} {
        display: block;
    }
`;

export const ContextMenuList = styled.div<{ $minHeight?: number; $maxHeight?: number }>`
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;

    max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : 'unset')};
    min-height: ${({ $minHeight }) => ($minHeight ? `${$minHeight}px` : 'unset')};
    ${mediaQuery.lt768} {
        max-height: unset;
    }
`;

export const ContextMenuFooterWrapper = styled.div`
    width: 100%;
    box-shadow: 0 -1px 0 0 ${({ theme }) => theme.divider.primary};
`;
