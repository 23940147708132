import React, { useState, useCallback } from 'react';

export const useToggle = (initialValue: boolean): [boolean, (force?: boolean | React.SyntheticEvent) => void] => {
    const [value, setValue] = useState(initialValue);

    const callback = useCallback((force?: boolean | React.SyntheticEvent) => {
        if (typeof force === 'boolean') {
            setValue(force);
        } else {
            setValue((prevValue) => !prevValue);
        }
    }, []);

    return [value, callback];
};
