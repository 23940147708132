import React from 'react';
import PropTypes from 'prop-types';

import { CurrencySigns } from 'services/utils/currencies';
import { useDictionary } from 'hooks/useDictionary';
import { ClockDelayIcon } from 'components/icons2/ClockDelayIcon';
import { Tooltip } from 'components/tooltip';
import { ChartPriceUp } from 'components/icons2/ChartPriceUp';

import { Ticker, TickerWrapperLink, Price, PriceDiff, DelayStyled } from './styled/painted3-instrument';

export const Painted3Instrument = (props) => {
    const { changePercent, ticker, price, dcpl, percentDcpl, as, url, title, onClick, showPrice, className, currency } =
        props;
    const isGrow = changePercent > 0;
    const isZero = changePercent === 0;

    const dic = useDictionary();

    return (
        <TickerWrapperLink as={as} href={url} target="_blank" title={title} onClick={onClick} className={className}>
            <Tooltip
                trigger="mouseenter"
                placement="bottom"
                content={dic.word('wt_all__widget_instruments__msg_market_delayed')}
            >
                <DelayStyled onClick={(e) => e.preventDefault()}>
                    <ClockDelayIcon />
                </DelayStyled>
            </Tooltip>
            <Ticker>{ticker}</Ticker>
            {showPrice && (
                <Price>
                    {price.toFixed(dcpl)}
                    &thinsp;
                    {CurrencySigns[currency] || ''}
                </Price>
            )}
            <PriceDiff isGrow={isGrow} isZero={isZero}>
                <ChartPriceUp size={16} />
                {changePercent.toFixed(percentDcpl).replace('-', '')}%
            </PriceDiff>
        </TickerWrapperLink>
    );
};

Painted3Instrument.defaultProps = {
    onClick: () => {},
    className: '',
    currency: '',
    changePercent: null,
    price: null,
    dcpl: null,
    ticker: '',
    title: '',
    url: '',
    showPrice: true,
    percentDcpl: 2,
    as: 'a',
};

Painted3Instrument.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    percentDcpl: PropTypes.number,
    showPrice: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    changePercent: PropTypes.number,
    ticker: PropTypes.string,
    currency: PropTypes.string,
    price: PropTypes.number,
    dcpl: PropTypes.number,
    as: PropTypes.string,
};
