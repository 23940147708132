import { connect } from 'react-redux';

import { DispatchType } from 'types';
import { api } from 'services/api-builder/api';
import { GlobalStateForCache } from 'services/api-builder/types';

import { GlobalStateForMyProductPopup } from '../my-products/types';
import { closeEditPopup } from '../my-products/actions/popupActions';
import { selectEditProductId } from '../my-products/selectors';

import { StrategyEditWizard } from './components/strategy-edit-wizard';

const mapStateToProps = (state: GlobalStateForCache & GlobalStateForMyProductPopup) => {
    const editProductId = selectEditProductId(state);
    const productItem = api.selectFinamProductItem(state, editProductId);
    return {
        productItem,
        isShown: !!productItem,
        ...api.selectMyProduct(state, { id: editProductId ?? 0 }),
        ...api.selectUpdateMySpaceSignalStrategyState(state),
        ...api.selectUpdateMySpaceCopyTradingStrategyState(state),
        ...api.selectAccountsForNewStrategy(state),
    };
};

const mapDispatchToProps = {
    getMyProduct: api.fetchMyProduct,
    updateCopyTradingStrategy: api.updateMySpaceCopyTradingStrategy,
    updateSignalStrategy: api.updateMySpaceSignalStrategy,
    checkProfanity: api.fetchCheckProfanity,
    getAccountsForNewStrategy: api.fetchAccountsForNewStrategy,
    onClose: closeEditPopup,
};

type OwnProps = {
    className?: string;
};

export type StrategyEditWizardProps = ReturnType<typeof mapStateToProps> &
    DispatchType<typeof mapDispatchToProps> &
    OwnProps;

export const StrategyEditWizardContainer = connect(mapStateToProps, mapDispatchToProps)(StrategyEditWizard);
