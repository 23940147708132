import { ReactElementLike } from 'prop-types';

import { Tags } from 'components/tags';

import { TagsState } from '../types';

type Props = TagsState & {
    children: (data: TagsState, TagsComponent: typeof Tags) => ReactElementLike;
};

export const TagsWidgetComponent = (props: Props) => {
    const { tags, subscribedTags, children } = props;
    return children({ tags, subscribedTags }, Tags);
};
