import { Id, Person } from 'types';

// не импортировать сюда ничего из других файлов виджета ленты!
export const UPDATE_USER = 'widgets/UPDATE_USER_IN_ALL_FEED_PLACES' as const;
type UpdateUserDataParam = Partial<Person> & { id: Id };
export type UpdateUserActions = ReturnType<typeof updateUser>;
export const updateUser = (userData: UpdateUserDataParam) => ({
    type: UPDATE_USER,
    payload: userData,
});
