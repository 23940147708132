import React, { useCallback, useState } from 'react';

import { GeneratorType, Id } from 'types';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import { ActionSubscribe, ActionSubscribeRenderProps } from 'widgets/action-subscribe';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { SizeWtPopup3Enum, WtPopup3 } from 'components/wt-popup3';

import { usePageRoot } from '../../../hooks';

import * as Styled from './styled/SubscribeButton';

type Props = {
    id: Id;
    subscribed: boolean;
    displayName: string;
    premiumSubscribed?: boolean;
    onUnsubscribe?: (id: Id) => void;
};

export const SubscribeButton = (props: Props) => {
    const { id, subscribed, premiumSubscribed = false, displayName, onUnsubscribe } = props;

    const pageRoot = usePageRoot();

    const [isRenderPopup, setIsRenderPopup] = useState(false);
    const closePopup = () => setIsRenderPopup(false);

    const subscribeRender = useCallback(
        (subscribeProps: ActionSubscribeRenderProps) => {
            const { onClick, isSubscribed, className } = subscribeProps;

            const getButtonSubscribeClassName = () =>
                [
                    className,
                    'autotest__profile-page__header-user-subscribe-button',
                    `autotest__profile-page__header-user-subscribe-button-${isSubscribed ? '' : 'not-'}subscribed`,
                ]
                    .filter((c) => c)
                    .join(' ');

            return (
                <>
                    <ButtonIcon
                        className={getButtonSubscribeClassName()}
                        onClick={() => {
                            if (isSubscribed && premiumSubscribed) {
                                setIsRenderPopup(true);
                            } else {
                                onClick();
                            }
                        }}
                        kind={ButtonIconKinds.FillPrimary}
                        pressed={!isSubscribed}
                        text={Dic.word(`wt_profile__widget_header_user__subscribe${isSubscribed ? 'd' : ''}_btn`)}
                    />
                    {isRenderPopup && (
                        // TODO подумать куда перенести чтобы при открытии сделать ремаунт ленты, а не при закрытии
                        <WtPopup3
                            size={SizeWtPopup3Enum.LARGE}
                            portalTo={pageRoot}
                            close={closePopup}
                            bgCloseable
                            width={464}
                            title={Dic.word('wt_profile__widget_header_user__unsubscribe_popup__title_text')}
                            actionButtons={{
                                direction: 'horizontal',
                                align: 'right',
                                items: [
                                    {
                                        key: 1,
                                        kind: 'secondary',
                                        content: Dic.word(
                                            'wt_profile__widget_header_user__unsubscribe_popup__cancel_btn_text',
                                        ),
                                        onClick: closePopup,
                                        className: 'autotest__profile-page__header-user-unsubscribe-cancel-button',
                                    },
                                    {
                                        key: 2,
                                        content: Dic.word(
                                            'wt_profile__widget_header_user__unsubscribe_popup__unsubscribe_btn_text',
                                        ),
                                        onClick: () => {
                                            closePopup();
                                            onClick();
                                            onUnsubscribe?.(id);
                                            // TODO сделать передачу текста в ActionSubscribe после удачного запроса и передача текста при неудачном запросе
                                            // а это потом удалить
                                            notify.success(
                                                `${Dic.word(
                                                    'wt_profile__widget_header_user__unsubscribe_popup__notify_success_partial_text',
                                                )} ${displayName}`,
                                            );
                                        },
                                        className: 'autotest__profile-page__header-user-unsubscribe-confirm-button',
                                    },
                                ],
                            }}
                        >
                            <Styled.PopupText>
                                {Dic.word('wt_profile__widget_header_user__unsubscribe_popup__msg_text')}
                            </Styled.PopupText>
                        </WtPopup3>
                    )}
                </>
            );
        },
        [isRenderPopup, displayName, premiumSubscribed],
    );

    return (
        <ActionSubscribe
            data={{ generatorId: id, generatorType: GeneratorType.USER }}
            isSubscribed={subscribed}
            customRender={subscribeRender}
        />
    );
};
