import React, { FC, memo } from 'react';
import { useController } from 'react-hook-form';

import { ListBox } from 'components/listbox-v2';

import { rulesComparer } from '../utils/rulesComparer';

import { FormListBoxProps } from './types';

export const FormListBox2: FC<FormListBoxProps> = memo(({ name, rules, hideErrors = false, ...listBoxProps }) => {
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController<{ [key: string]: string }>({ name, rules });

    return <ListBox value={value} onChange={onChange} errorText={hideErrors ? error?.message : ''} {...listBoxProps} />;
}, rulesComparer);
