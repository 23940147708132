/* eslint-disable no-bitwise */
import { useCallback, useMemo } from 'react';

import { Id } from 'types';

const cyrb53 = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

function createHex(_hash: number) {
    let hexCode1 = '';
    let hash = _hash;
    const hexValues1 = '0123456789abcdef';

    for (let i = 0; i < 6; i++) {
        hexCode1 += hexValues1.charAt(hash % hexValues1.length);
        hash /= 10;
    }
    return hexCode1;
}

const generateAvatarSrc = (id: Id) => {
    const hash1 = cyrb53(`${id}`);
    const hash2 = cyrb53(`${id}`, 10);
    const deg = hash1 % 130;
    return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='50' width='50' fill='red'><defs><linearGradient id='strategy-gradient-${id}' gradientTransform='rotate(${deg})'><stop offset='0%' stop-color='%23${createHex(
        hash1,
    )}' /><stop offset='100%' stop-color='%23${createHex(
        hash2,
    )}' /></linearGradient></defs><rect height='100%' width='100%' fill='url(%23strategy-gradient-${id})'></rect></svg>`;
};

export const useGenerateGradientSrc = () =>
    useCallback((id: Id | undefined): string => (id ? generateAvatarSrc(id) : ''), []);

export const useGradientSrc = (id: Id | undefined): string => {
    const generate = useGenerateGradientSrc();
    return useMemo(() => generate(id), [generate, id]);
};
