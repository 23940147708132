import styled, { css } from 'styled-components';

import { matchBreakpoints } from 'app/styled';
import {
    body14SemiboldFontStyle,
    body17SemiboldFontStyle,
    caption12SemiboldFontStyle,
    h4MediumFontStyle,
} from 'services/typography';

import { RibbonProps, RibbonType, SizeMapRibbon } from '../types';

const sizeMapWrapper: SizeMapRibbon = {
    large: css`
        height: 32px;
    `,
    big: css`
        height: 28px;
    `,
    medium: css`
        height: 24px;
    `,
    small: css`
        height: 20px;
    `,
};

export const Wrapper = styled.div<Required<Pick<RibbonProps, 'size'>>>`
    display: flex;
    flex-flow: nowrap;
    overflow: hidden;

    ${({ size }) => matchBreakpoints(size, sizeMapWrapper)};
`;

const sizeMapText = (type: RibbonType): SizeMapRibbon => ({
    large: css`
        ${h4MediumFontStyle};
        ${type === 'right'
            ? `
                padding: 2px 0 0 16px;
                border-radius: 6px 0 0 6px;
        `
            : `
                padding: 2px 16px 0 0;
                border-radius: 0 6px 6px 0;
        `};
    `,
    big: css`
        ${body17SemiboldFontStyle};
        ${type === 'right'
            ? `
                padding: 2px 0 0 14px;
                border-radius: 6px 0 0 6px;
        `
            : `
                padding: 2px 14px 0 0;
                border-radius: 0 6px 6px 0;
        `};
    `,
    medium: css`
        ${body14SemiboldFontStyle};
        ${type === 'right'
            ? `
                padding: 2px 0 0 12px;
                border-radius: 6px 0 0 6px;
        `
            : `
                padding: 2px 12px 0 0;
                border-radius: 0 6px 6px 0;
        `};
    `,
    small: css`
        ${caption12SemiboldFontStyle};
        ${type === 'right'
            ? `
                padding: 2px 0 0 10px;
                border-radius: 4px 0 0 4px;
        `
            : `
                padding: 2px 10px 0 0;
                border-radius: 0 4px 4px 0;
        `};
    `,
});

export const Text = styled.span<Required<Pick<RibbonProps, 'size' | 'type'>>>`
    white-space: nowrap;
    box-sizing: border-box;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    ${({ type }) => (type === 'left' ? 'order: 2;' : 'order: 1;')};

    ${({ theme }) => `
        background: ${theme.fillIn.primary.mid};
        color: ${theme.staticColors.white};
    `}

    ${({ size, type }) => matchBreakpoints(size, sizeMapText(type))};
`;
