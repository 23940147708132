import stringify from 'fast-json-stable-stringify';
import get from 'lodash/get';

import { AsyncMethod, GlobalStateForCache, Path, ResourcesMap } from '../types';
import { cacheRead } from '../cache/cacheRead';

export function createSelectRequestData<
    TYPES_MAP extends object,
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
>({
    resourcesMap,
    endpointName,
    dataPath,
}: {
    resourcesMap: ResourcesMap<TYPES_MAP>;
    endpointName: ENDPOINT_NAME;
    dataPath?: Path<Awaited<ReturnType<API_METHOD>>>;
}) {
    return (state: GlobalStateForCache, argsOrRequestName: Parameters<API_METHOD>[0] | string) => {
        const { data } = state.cache;
        const argsMap = state.cache.args[endpointName as string];
        const args = typeof argsOrRequestName === 'string' ? argsMap?.named[argsOrRequestName] : argsOrRequestName;
        if (!args) {
            return undefined;
        }
        const cacheResult = cacheRead({
            kind: 'request',
            cache: data,
            resourcesMap,
            typename: endpointName,
            id: stringify(args),
        })?.result;
        if (dataPath === undefined || dataPath === '') {
            return cacheResult;
        }
        return get(cacheResult, dataPath);
    };
}
