import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { matchBreakpoints, mediaQuery, ResponsiveMap } from 'app/styled';
import {
    buttonMediumFontStyle,
    buttonSmallFontStyle,
    caption10MediumFontStyle,
    caption11MediumFontStyle,
    caption12MediumFontStyle,
} from 'services/typography';
import { Button, ButtonProps } from 'components/button2';
import { Counter } from 'components/indicator/counter';

import { SelectionControlProps, SelectionControlSize } from './types';

export const SelectionControlWrapper = styled.div<Pick<SelectionControlProps, 'isCarryOn'>>`
    display: flex;
    flex-direction: row;
    row-gap: 8px;
    column-gap: 8px;
    flex-wrap: ${({ isCarryOn }) => (isCarryOn ? 'wrap' : 'nowrap')};

    ${mediaQuery.lt480} {
        row-gap: 4px;
        column-gap: 8px;
    }
`;

const sizeMapBtn = {
    large: css`
        ${buttonMediumFontStyle};
        padding: 12px 20px;

        span {
            gap: 8px;

            & > svg {
                width: 24px;
            }
        }
    `,
    medium: css`
        ${buttonSmallFontStyle};
        padding: 8px 20px;
        span {
            gap: 8px;

            & > svg {
                width: 20px;
            }
        }
    `,
    small: css`
        ${caption12MediumFontStyle};
        padding: 8px 12px;
        span {
            gap: 4px;

            & > svg {
                width: 16px;
            }
        }
    `,
    tiny: css`
        ${caption12MediumFontStyle};
        padding: 4px 8px;
        span {
            gap: 4px;

            & > svg {
                width: 16px;
            }
        }
    `,
};

export const SelectionControlButton = styled(Button)<ButtonProps>`
    border-radius: 48px;

    :focus:not(:focus-visible) {
        outline: 0;
        box-shadow: none;
    }

    ${({ size }) => matchBreakpoints(size ?? 'medium', sizeMapBtn)};

    ${switchProp('kind', {
        primary: css`
            background-color: ${({ theme }) => theme.fillIn.secondary.highest};

            &:hover,
            &:focus {
                background-color: ${({ theme }) => theme.fillIn.secondary.highest};
            }
        `,
    })}

    ${({ isDisabled, theme }) =>
        isDisabled
            ? `
        background-color: ${theme.fillIn.secondary.overlay8};
    `
            : ''}}
`;

const sizeMapGap = {
    large: css`
        gap: 8px;
    `,
    medium: css`
        gap: 8px;
    `,
    small: css`
        gap: 4px;
    `,
    tiny: css`
        gap: 4px;
    `,
};

export const SelectionControlButtonInner = styled.div<{
    size?: SelectionControlSize | ResponsiveMap<SelectionControlSize>;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ size }) => matchBreakpoints(size ?? 'small', sizeMapGap)};
`;

const sizeMapCounter = {
    large: css`
        padding: 2px 4px;

        span {
            ${caption12MediumFontStyle};
        }
    `,
    medium: css`
        padding: 1px 4px;

        span {
            ${caption11MediumFontStyle};
        }
    `,
    small: css`
        padding: 0.5px 4px 1.5px;

        span {
            ${caption10MediumFontStyle};
        }
    `,
    tiny: css`
        padding: 0.5px 4px 1.5px;

        span {
            ${caption10MediumFontStyle};
        }
    `,
};

export const SelectionControlCounter = styled(Counter)<{
    counterSize?: SelectionControlSize | ResponsiveMap<SelectionControlSize>;
}>`
    ${({ counterSize }) => matchBreakpoints(counterSize ?? 'small', sizeMapCounter)};
`;
