import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { useDicWordPrefix } from 'hooks/useDicWordPrefix';
import { FileField } from 'widgets/product-wizard/components/file-field';
import { VerticalFormInputGroup } from 'components/form';

import { StepProps } from '../../types';
import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { Label } from '../../styled';

import template from './template.xlsx';
import { toData } from './toData';
import { defaultValues } from './constants';
import { Step1FormValues } from './types';

export const Step1: FC<StepProps<Step1FormValues>> = memo((props) => {
    const dic = useDictionary();
    const dicWord = useDicWordPrefix('wt_all__widget_product_wizard__copy_trading_step1_');
    const form = useForm<Step1FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    useFormStateHandler({
        form,
        props,
        toData,
        title: dicWord('title'),
    });

    const required = dic.word('wt_all__widget_product_wizard__field_required');

    return (
        <FormProvider {...form}>
            <VerticalFormInputGroup>
                <Label>{dicWord('hint')}</Label>
                <FileField
                    name="brokerReport"
                    rules={{ required }}
                    className="autotest__marketplace__product_wizard__broker_report"
                />
                <Label>
                    <a href={template}>{dicWord('link')}</a>
                    {dicWord('download')}
                </Label>
            </VerticalFormInputGroup>
        </FormProvider>
    );
});
