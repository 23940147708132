import styled from 'styled-components';
import { prop } from 'styled-tools';

import { mediaQuery } from 'app/styled';

import { postSidePadding } from '../styled';

export const ActivityWrapper = styled.div`
    padding-top: 24px;
    padding-bottom: 24px;

    ${mediaQuery.lt960} {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    border-radius: 8px;
    background: ${prop('theme.bg.primary')};
`;

export const Title = styled.h1`
    ${postSidePadding};

    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    letter-spacing: -0.014rem;
    word-break: break-word;
    ${mediaQuery.lt960} {
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        padding-left: 12px;
        padding-right: 12px;
    }
`;

export const CommentsFallback = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
`;
