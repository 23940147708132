import React, { memo } from 'react';

import { IconButton, Wrapper } from './styled';
import { SocialIconsProps } from './interfaces';

export const SocialIcons = memo(({ icons, className: wrapperClassName }: SocialIconsProps) => (
    <Wrapper className={wrapperClassName}>
        {icons.map(({ Icon, href, description, className }) => (
            <IconButton
                icon={Icon}
                kind="ghost-secondary"
                href={href}
                key={description}
                aria-label="title"
                className={['autotest__footer__social-icon-link', className].filter((i) => i).join(' ')}
            />
        ))}
    </Wrapper>
));

export type { SocialIcon, SocialIconsData } from './interfaces';
export { footerSocialIcons } from './data';
