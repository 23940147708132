/* eslint-disable @typescript-eslint/no-explicit-any */
class SelectorsCache {
    private root = { value: undefined, map: new WeakMap<object, any>() };

    public set(key: object[], value: any): void {
        let currentNode = this.root;
        for (let i = 0; i < key.length; i++) {
            const obj = key[i];
            let nextNode = currentNode.map.get(obj);
            if (!nextNode) {
                nextNode = { value: undefined, map: new WeakMap<object, any>() };
                currentNode.map.set(obj, nextNode);
            }
            currentNode = nextNode;
        }
        currentNode.value = value;
    }

    public get(key: object[]): any | undefined {
        let currentNode = this.root;
        for (let i = 0; i < key.length; i++) {
            const obj = key[i];
            currentNode = currentNode.map.get(obj);
            if (!currentNode) {
                return undefined;
            }
        }

        return currentNode.value;
    }
}

export const selectorsCache = new SelectorsCache();
