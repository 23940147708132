import React from 'react';

import { FormField } from '..';
import { ErrorMessage } from '../textfield/styled';
import { Props as TextfieldProps } from '../textfield';

import { PopupFormTextArea } from './styled';

export const Textarea = (props: TextfieldProps) => {
    const {
        className,
        errorMessage,
        fieldProps,
        size,
        kind = 'outline',
        floatingLabel,
        visualState,
        noresize = true,
        recalculateHeight = false,
        infinityHeight = false,
    } = props;
    return (
        <FormField>
            <PopupFormTextArea
                className={className}
                fieldProps={fieldProps}
                noresize={noresize}
                size={size}
                kind={kind}
                recalculateHeight={recalculateHeight}
                floatingLabel={floatingLabel}
                visualState={visualState}
                infinityHeight={infinityHeight}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </FormField>
    );
};
