import { AsyncMethod, GlobalStateForCache } from '../types';
import { selectorsCache } from '../cache/selectorsCache';
import { prefixObjectKeys } from '../utils/prefixObjectKeys';

import { createSelectRequestState } from './selectRequestState';

export function createSelectMutationState<
    ENDPOINT_NAME extends string,
    API_METHOD extends AsyncMethod,
    CUSTOM_PREFIX extends string,
>({ endpointName, customPrefix }: { endpointName: ENDPOINT_NAME; customPrefix?: CUSTOM_PREFIX }) {
    const typeKey = { endpointName };
    const selectRequestState = createSelectRequestState({ endpointName });
    return (state: GlobalStateForCache, argsOrRequestName: Parameters<API_METHOD>[0] | string = {}) => {
        const requestState = selectRequestState(state, argsOrRequestName);
        const cached = selectorsCache.get([typeKey, requestState]);
        if (cached) {
            return cached;
        }
        const newValue = prefixObjectKeys(customPrefix ?? endpointName, requestState);
        selectorsCache.set([typeKey, requestState], newValue);
        return newValue;
    };
}
