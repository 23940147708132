import styled from 'styled-components';

import { body14RegularFontStyle } from 'services/typography';
import { Button } from 'components/button2';

export const SearchInputWrapper = styled.label`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    min-width: 0;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.low};

    display: flex;
    align-items: center;

    caret-color: ${({ theme }) => theme.label.accent};

    & > svg {
        flex: none;
        margin-right: 8px;
        color: ${({ theme }) => theme.label.secondary};
    }

    input::placeholder {
        color: ${({ theme }) => theme.label.secondary};
    }

    &:hover {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.fillIn.secondary.mid};
    }

    &:focus-within {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.fillIn.primary.mid}, ${({ theme }) => theme.focusOutline.accent};
        background: ${({ theme }) => theme.bg.primary};

        & > svg {
            color: ${({ theme }) => theme.label.placeholder};
        }

        & > input::placeholder {
            color: ${({ theme }) => theme.label.placeholder};
        }
    }
`;

export const SearchInputStyled = styled.input`
    flex: 1 1 100%;
    min-width: 0;
    box-sizing: border-box;
    padding: 0;
    height: 20px;

    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;

    color: ${({ theme }) => theme.label.primary};
    ${body14RegularFontStyle}
`;

export const ClearButton = styled(Button)`
    flex: none;
    width: 24px;
    margin-left: 8px;
    padding: 0;
`;
