import { css } from 'styled-components';

import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';

import { ButtonKind, ButtonWrapperProps, SizeMap, StateMap } from '../types';

import { contentContainerStyle as contentContainerStyleStandard } from './typeStandard';

const {
    colors: { text, button, border },
    spacing,
} = tokens;

/** Button kind-dependent CSS */
const getStateMap = (kind: ButtonKind): StateMap => {
    const primary = {
        default: `
            color: ${text.brand.default.default};
            --focus-line-display: none;
        `,
        hover: `
            color: ${text.brand.default.hover};
        `,
        focus: `
            color: ${text.brand.default.default};
            --focus-line-display: block;
            --focus-line-color: ${border.brand.default.default};
        `,
        active: `
            color: ${text.brand.default.active};
        `,
        disabled: `
            color: ${button.label.default.default.disabled};
            --focus-line-display: none;
        `,
    };
    const kindMap: Record<ButtonKind, StateMap> = {
        primary,
        secondary: {
            default: `
                color: ${button.label.ghost.default.default};
                --focus-line-display: none;
            `,
            hover: `
                color: ${button.label.ghost.default.hover};
            `,
            focus: `
                color: ${button.label.ghost.default.default};
                --focus-line-display: block;
                --focus-line-color: ${border.brand.default.default};
            `,
            active: `
                color: ${button.label.ghost.default.pressed};
            `,
            disabled: `
                color: ${button.label.default.default.disabled};
                --focus-line-display: none;
            `,
        },
        cancel: {
            default: `
                color: ${button.label.secondary.negative.default};
                --focus-line-display: none;
            `,
            hover: `
                color: ${button.label.secondary.negative.hover};
            `,
            focus: `
                color: ${button.label.secondary.negative.default};
                --focus-line-display: block;
                --focus-line-color: ${border.negative.default.default};
            `,
            active: `
                color: ${button.label.secondary.negative.pressed};
            `,
            disabled: `
                color: ${button.label.default.default.disabled};
                --focus-line-display: none;
            `,
        },
        'ghost-primary': primary,
        'ghost-secondary': primary,
        outline: primary,
        'ondark-secondary': primary,
        'ondark-outline': primary,
        'onlight-outline': primary,
    };
    return kindMap[kind];
};

const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --padding-vertical: ${spacing.m};
        --padding-horizontal: 0;
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --padding-vertical: ${spacing.s};
        --padding-horizontal: 0;
    `,
    small: css`
        ${buttonSmallFontStyle};
        --padding-vertical: ${spacing.xs};
        --padding-horizontal: 0;
    `,
    tiny: css`
        ${buttonSmallFontStyle};
        --padding-vertical: ${spacing['2xs']};
        --padding-horizontal: 0;
    `,
};

export const iconGapSizeMap: SizeMap = {
    large: css``,
    medium: css``,
    small: css`
        gap: 4px;
    `,
    tiny: css`
        gap: 4px;
    `,
};

const paddingLeftRightStyle = () => `
        padding-left: var(--padding-horizontal);
        padding-right: var(--padding-horizontal);
    `;

const focusLine = `
    position: relative;
    &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        bottom: -1.5px;
        background-color: var(--focus-line-color);
        display: var(--focus-line-display);
    }
`;

export const typeText = {
    wrapperStyle: css<ButtonWrapperProps>``,
    sizeMap,
    iconGapSizeMap,
    paddingLeftRightStyle,
    getStateMap,
    contentContainerStyle: (props: ButtonWrapperProps) => `${contentContainerStyleStandard(props)}${focusLine}`,
};
