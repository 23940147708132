import { nameToAction } from '../utils/nameToAction';

export type UpdateResourceAction<RESOURCE_NAME, RESOURCE_TYPE> = {
    type: `cache/UPDATE_${string}`;
    payload: { kind: 'request' | 'resource'; typename: RESOURCE_NAME; value: RESOURCE_TYPE; operationId?: string };
};

export function updateResource<RESOURCE_NAME extends string, RESOURCE_TYPE>(
    kind: 'request' | 'resource',
    typename: RESOURCE_NAME,
    value: RESOURCE_TYPE,
    operationId?: string,
): UpdateResourceAction<RESOURCE_NAME, RESOURCE_TYPE> {
    return {
        type: `cache/UPDATE_${nameToAction(typename)}`,
        payload: { kind, typename, value, operationId },
    };
}
