import React from 'react';

import { IconSvg, IconComponent } from '.';

export const YoutubeIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M20.6203 7.02959C20.5178 6.63697 20.3173 6.27898 20.0386 5.99147C19.76 5.70396 19.413 5.49701 19.0325 5.39133C17.6311 5 12 5 12 5C12 5 6.36887 5 4.96752 5.39133C4.587 5.49701 4.24005 5.70396 3.9614 5.99147C3.68275 6.27898 3.48217 6.63697 3.37975 7.02959C3.11809 8.50421 2.99111 10.0009 3.00048 11.5C2.99111 12.9991 3.11809 14.4958 3.37975 15.9704C3.48217 16.363 3.68275 16.721 3.9614 17.0085C4.24005 17.296 4.587 17.503 4.96752 17.6087C6.36887 18 12 18 12 18C12 18 17.6311 18 19.0325 17.6087C19.413 17.503 19.76 17.296 20.0386 17.0085C20.3173 16.721 20.5178 16.363 20.6203 15.9704C20.8819 14.4958 21.0089 12.9991 20.9995 11.5C21.0089 10.0009 20.8819 8.50421 20.6203 7.02959ZM10.2001 14.2857V8.71429L14.8734 11.5L10.2001 14.2857Z" />
        {/* <path fillRule="evenodd" clipRule="evenodd" d="M14.88 11.5L10.2 8.71301V14.287L14.88 11.5ZM14.8734 11.5L10.2001 8.71429V14.2857L14.8734 11.5Z" fill="white" /> */}
    </IconSvg>
);
