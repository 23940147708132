import { createElement, lazy, Suspense } from 'react';

import { NotificationsListFallback } from 'widgets/header-notifications/components/styled/NotificationsListFallback.styled';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { MobileNotificationsHeader } from '../components/mobile-notifications-header';
import { MenuData } from '../interfaces';

const NotificationsList = lazy(() =>
    import(
        /* webpackChunkName: "notifications" */ 'widgets/header-notifications/containers/NotificationsListContainer'
    ).then((module) => ({
        default: module.HeaderNotificationsListContainer,
    })),
);

export async function getNotificationsMobileMenu(): Promise<MenuData> {
    return {
        name: 'notifications-mobile',
        showCloseButton: false,
        headerContent: createElement(MobileNotificationsHeader),
        content: createElement(
            Suspense,
            { fallback: createElement(NotificationsListFallback, {}, createElement(ProcessingIcon)) },
            createElement(NotificationsList, {
                withoutHeader: true,
                isWillOpenedWithMount: true,
            }),
        ),
    };
}
