import React, { FC, useEffect, memo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import range from 'lodash/range';

import { FileType } from 'types';
import { useDictionary } from 'hooks/useDictionary';
import { CoverField } from 'widgets/product-wizard/components/cover-field';
import {
    FormDescription,
    FormItemMessage,
    FormSectionHeadline,
    Gap12FormVLayout,
    VerticalFormInputGroup,
} from 'components/form';
import { ErrorMessage } from 'components/text-input/styled';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { EmphasizedSection } from '../../../wizard/styled';
import { StepProps } from '../../types';

import { Step3FormValues } from './types';
import { ProductImagesContainer, SupplementaryProductImageUploadContainer } from './styled';
import { Img } from './components/Img';
import { defaultValues, MAX_IMAGES_COUNT, NUM_ITEMS_COLUMNS } from './constants';
import { toData } from './toData';
import { canSkip } from './canSkip';

export const Step3: FC<StepProps<Step3FormValues>> = memo((props) => {
    const dic = useDictionary();
    const form = useForm<Step3FormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { control, watch, formState, getFieldState, reset } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'promoImages',
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change' && name) {
                const index = parseInt(name.split('.')[1], 10);
                const { promoImages } = value;
                if (promoImages) {
                    const { length } = promoImages;
                    const item = promoImages[index];
                    if (index === length - 1 && item?.value) {
                        append({}, { shouldFocus: false });
                    }
                    if (index !== length - 1 && !item?.value) {
                        remove(index);
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, append, remove, reset]);

    useFormStateHandler({ form, props, toData, canSkip });

    return (
        <FormProvider {...form}>
            <EmphasizedSection>
                <FormSectionHeadline>{dic.word('wt_all__widget_product_wizard__step3_headline')}</FormSectionHeadline>
                <Img />
            </EmphasizedSection>
            <Gap12FormVLayout>
                <FormDescription>{dic.word('wt_all__widget_product_wizard__step3_form_description')}</FormDescription>
                <VerticalFormInputGroup>
                    <ProductImagesContainer className="autotest__marketplace__product_wizard__images">
                        {fields.slice(0, MAX_IMAGES_COUNT).map((field, index) => (
                            <SupplementaryProductImageUploadContainer key={field.id}>
                                <CoverField
                                    name={`promoImages.${index}.value`}
                                    productImageType={FileType.SHOP_PRODUCT_IMAGE}
                                />
                            </SupplementaryProductImageUploadContainer>
                        ))}
                        {fields.length < NUM_ITEMS_COLUMNS &&
                            range(0, NUM_ITEMS_COLUMNS - fields.length).map((key) => (
                                <SupplementaryProductImageUploadContainer key={key} />
                            ))}
                    </ProductImagesContainer>
                    <FormItemMessage>
                        {dic.word('wt_all__widget_product_wizard__step3_field_promoImages_message')}
                    </FormItemMessage>
                    {fields.map(({ id }, index) => {
                        const { error } = getFieldState(`promoImages.${index}.value`, formState);
                        return error ? <ErrorMessage key={id}>{error.message}</ErrorMessage> : null;
                    })}
                </VerticalFormInputGroup>
            </Gap12FormVLayout>
        </FormProvider>
    );
});
