import styled from 'styled-components';

import { caption12RegularFontStyle } from 'services/typography';

import { RightGroupProps } from '../types';

export const RightGroupWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const RightGroupText = styled.span<Pick<RightGroupProps, 'icon' | 'disabled'>>`
    ${caption12RegularFontStyle};
    color: ${({ theme, disabled }) => (disabled ? theme.label.inactive : theme.label.secondary)};
    padding: 0 4px;
`;

export const RightGroupIconWrapper = styled.div<Pick<RightGroupProps, 'icon' | 'disabled'>>`
    display: flex;

    svg {
        fill: ${({ theme, icon, disabled }) => {
            if (disabled) {
                return theme.label.inactive;
            }

            return icon?.fill || theme.label.secondary;
        }};
    }
`;
