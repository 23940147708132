import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useCardProps } from 'hooks/mp-share/useCardProps';
import { FinamSliderCard } from 'widgets/products-slider/components/Card';
import { ProductWizardContainer } from 'widgets/mp-share/product-wizard';
import { stripeBanners } from 'widgets/mp-share/my-products/stripeBanners';
import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import * as Styled from '../styled';

import { MyProductsProps } from './types';
import { AddProduct } from './add-product/AddProduct';

export const MyProducts: FC<MyProductsProps> = (props) => {
    const {
        myProducts,
        getMyProducts,
        profile,
        createMyConnectedAccount,
        createMyConnectedOnboarding,
        getProfile,
        myProductsIsLoading,
        profileIsLoading,
        myProductsIsReady,
    } = props;
    const draggingRef = useRef(false);

    const { getCardProps } = useCardProps({
        draggingRef,
        productPagePath: '/home',
    });

    const [productWizardShown, setProductWizardShown] = useState(false);
    const handleCloseProductWizard = useCallback(() => {
        setProductWizardShown(false);
    }, []);
    const openProductWizard = useCallback(() => {
        setProductWizardShown(true);
    }, []);

    useEffect(() => {
        getMyProducts({ archived: false });
    }, [getMyProducts]);

    useEffect(() => {
        getProfile();
    }, [getProfile]);

    if (myProductsIsLoading || profileIsLoading) {
        return (
            <Styled.Loading>
                <ProcessingIcon />
            </Styled.Loading>
        );
    }

    if (myProductsIsReady && myProducts.length === 0) {
        const stripeBanner =
            profile &&
            stripeBanners(profile, {
                createMyConnectedAccount,
                createMyConnectedOnboarding,
            });

        if (stripeBanner) {
            return <Styled.BannerWrapper>{stripeBanner}</Styled.BannerWrapper>;
        }

        return (
            <>
                <AddProduct onClick={openProductWizard} />
                <ProductWizardContainer isShown={productWizardShown} onClose={handleCloseProductWizard} />
            </>
        );
    }

    return (
        <>
            <Styled.Catalog>
                {myProducts.map((item) => (
                    <FinamSliderCard item={item} key={item.id} getCardProps={getCardProps} />
                ))}
            </Styled.Catalog>
            <ProductWizardContainer isShown={productWizardShown} onClose={handleCloseProductWizard} />
        </>
    );
};
