/* eslint-disable class-methods-use-this */
import { Lcid } from 'types';

export class NumberInputFormatter {
    private readonly locale: Lcid;

    private readonly currencySymbol?: string;

    private readonly prefix: string;

    private readonly formatter: Intl.NumberFormat;

    constructor(locale: Lcid, currencySymbol?: string) {
        this.locale = locale;
        this.currencySymbol = currencySymbol;
        this.prefix = currencySymbol ? `${currencySymbol} ` : '';
        this.formatter = new Intl.NumberFormat(locale, {
            style: 'decimal',
        });
    }

    public format(numberString: string): string | null {
        if (numberString === '') {
            return this.prefix;
        }

        const result = /^(?<sign>-)?((?<integer>\d+)|((?<whole>\d+)\.(?<fraction>\d*)))?$/.exec(numberString);
        if (result?.groups) {
            const { sign, integer, whole, fraction } = result.groups;

            if (integer) {
                const formattedInteger = this.formatter.format(parseInt(integer, 10));
                return `${this.prefix}${sign ?? ''}${formattedInteger}`;
            }
            if (whole) {
                const formattedWhole = this.formatter.format(parseInt(whole, 10));
                return `${this.prefix}${sign ?? ''}${formattedWhole}.${fraction ?? ''}`;
            }
            if (sign) {
                return `${this.prefix}${sign}` ?? '';
            }
        }
        return null;
    }

    public parse(formattedString: string): string {
        return formattedString.replace(/[^\d.-]/g, '');
    }

    private getRealPosition(formattedString: string, caretIndex: number): number {
        let count = 0;
        for (let i = 0; i < caretIndex; i++) {
            if (/[\d.-]/g.test(formattedString[i])) {
                count++;
            }
        }
        return count;
    }

    private getFormattedPosition(formattedString: string, caretIndex: number): number {
        let count = 0;
        for (let i = this.prefix.length; i < formattedString.length; i++) {
            if (count === caretIndex) {
                return i;
            }
            if (/[\d.-]/g.test(formattedString[i])) {
                count++;
            }
        }
        return formattedString.length;
    }

    private removeCharactersAtIndex(str: string, index: number, length: number): string {
        return str.slice(0, index) + str.slice(index + length);
    }

    private insertCharactersAtIndex(str: string, index: number, newText: string): string {
        return str.slice(0, index) + newText + str.slice(index);
    }

    private deleteContentBackward(formattedString: string, cursorPosition: number): [string, number] | null {
        const index = this.getRealPosition(formattedString, cursorPosition);
        if (index < 1) {
            return null;
        }
        const parsedValue = this.parse(formattedString);
        const newValue = this.removeCharactersAtIndex(parsedValue, index - 1, 1);
        const newFormattedValue = this.format(newValue);
        if (newFormattedValue === null) {
            return null;
        }

        const newIndex = this.getFormattedPosition(newFormattedValue, index - 1);
        return [newFormattedValue, newIndex];
    }

    private deleteContentForward(formattedString: string, cursorPosition: number): [string, number] | null {
        const index = this.getRealPosition(formattedString, cursorPosition);
        if (index > formattedString.length - 1) {
            return null;
        }
        const parsedValue = this.parse(formattedString);
        const newValue = this.removeCharactersAtIndex(parsedValue, index, 1);
        const newFormattedValue = this.format(newValue);
        if (newFormattedValue === null) {
            return null;
        }
        const newIndex = this.getFormattedPosition(newFormattedValue, index);
        return [newFormattedValue, newIndex];
    }

    private deleteContent(
        formattedString: string,
        startPosition: number,
        endPosition: number,
    ): [string, number] | null {
        const parsedValue = this.parse(formattedString);
        const start = this.getRealPosition(formattedString, Math.min(startPosition, endPosition));
        const end = this.getRealPosition(formattedString, Math.max(startPosition, endPosition));

        if (start < 0 || end >= parsedValue.length) {
            return null;
        }

        const newValue = this.removeCharactersAtIndex(parsedValue, start, end - start);
        const newFormattedValue = this.format(newValue);
        if (newFormattedValue === null) {
            return null;
        }
        const newIndex = this.getFormattedPosition(newFormattedValue, start);
        return [newFormattedValue, newIndex];
    }

    private replaceText(
        formattedString: string,
        startPosition: number,
        endPosition: number,
        newText: string,
    ): [string, number] | null {
        const parsedValue = this.parse(formattedString);
        const parsedNewText = this.parse(newText);
        const start = this.getRealPosition(formattedString, Math.min(startPosition, endPosition));
        const end = this.getRealPosition(formattedString, Math.max(startPosition, endPosition));

        const newValue = this.insertCharactersAtIndex(
            this.removeCharactersAtIndex(parsedValue, start, end - start),
            start,
            parsedNewText,
        );
        const newFormattedValue = this.format(newValue);

        if (newFormattedValue === null) {
            return null;
        }

        const newIndex = this.getFormattedPosition(newFormattedValue, start + parsedNewText.length);
        return [newFormattedValue, newIndex];
    }

    public handleEvents(
        inputType: string,
        data: string | null,
        value: string,
        selectionStart: number | null,
        selectionEnd: number | null,
    ): [string, number] | null {
        if (selectionStart === null || selectionEnd === null) {
            return null;
        }

        if (selectionStart === selectionEnd) {
            if (inputType === 'deleteContentBackward') {
                return this.deleteContentBackward(value, selectionStart);
            }
            if (inputType === 'deleteContentForward') {
                return this.deleteContentForward(value, selectionStart);
            }
            if ((inputType === 'insertText' || inputType === 'insertFromPaste') && data !== null) {
                return this.replaceText(value, selectionStart, selectionEnd, data);
            }
        } else {
            if (
                inputType === 'deleteContentBackward' ||
                inputType === 'deleteContentForward' ||
                inputType === 'deleteByCut'
            ) {
                return this.deleteContent(value, selectionStart, selectionEnd);
            }
            if ((inputType === 'insertText' || inputType === 'insertFromPaste') && data !== null) {
                return this.replaceText(value, selectionStart, selectionEnd, data);
            }
        }

        return null;
    }
}
