import type { Step4FormValues, Step4Data } from './types';

export function toData({
    tradingMethod,
    cashAndLeverage,
    riskManagement,
    additionalInformation,
}: Step4FormValues): Step4Data {
    return {
        tradingMethod,
        cashAndLeverage,
        riskManagement,
        additionalInformation,
    };
}
