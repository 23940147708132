import * as Sentry from '@sentry/browser';

import { Person, Id, Action, UserChoice, isPublishStatusRecord, PublishStatusRecord } from 'types';
import { VoteService } from 'services/api-services/generated_limex';
import { CancelError } from 'services/api-services/generated_limex/core/CancelablePromise';
import { Actions, createThunkActions } from 'services/create-action-types/api-services';
import notify from 'services/notify';
import Dic from 'services/dictionary';
import { CancelActionProcessor } from 'services/create-action-types/api-services/cancelActionProcessor';

import { index } from '../../../helpers';
import { GlobalStateForFeed } from '../../../types';

export type SendLikePayloadPass = {
    newChoice: UserChoice | null;
    recordId: Id;
    currentUser?: Person;
    prevSpecialState?: PublishStatusRecord;
};

const [SEND_RATING_COMMENT_ACTION_TYPES, commentThunk, commentActions] = createThunkActions<
    'widgets',
    GlobalStateForFeed
>('widgets')('FEED_RECORD_SAVE_RATING_COMMENT', VoteService.updateRating)<SendLikePayloadPass>();

const [SEND_RATING_POST_ACTION_TYPES, postThunk, postActions] = createThunkActions<'widgets', GlobalStateForFeed>(
    'widgets',
)('FEED_RECORD_SAVE_RATING_POST', VoteService.updateRating)<SendLikePayloadPass>();

type RequestBody = Parameters<typeof VoteService.updateRating>[0]['requestBody'];

const cancelActionProcessor = new CancelActionProcessor<number>();

export function sendLikeGenerator(
    thunk: typeof postThunk,
    data: RequestBody,
    pass: SendLikePayloadPass,
): ReturnType<typeof postThunk>;
export function sendLikeGenerator(
    thunk: typeof commentThunk,
    data: RequestBody,
    pass: SendLikePayloadPass,
): ReturnType<typeof commentThunk>;
export function sendLikeGenerator(
    thunk: typeof commentThunk | typeof postThunk,
    data: RequestBody,
    pass: SendLikePayloadPass,
): ReturnType<typeof postThunk> | ReturnType<typeof commentThunk> {
    return (async (dispatch, getState, extraArgument) => {
        const { objectId, type, action } = data;
        const { recordId } = pass;
        const onCancel = await cancelActionProcessor.cancelOrBuild(data.objectId);
        const currentRecord = getState().widgets.feed.records[index(recordId)];
        const actions = thunk({
            params: {
                requestBody: {
                    objectId,
                    type,
                    action,
                },
            },
            pass: {
                ...pass,
                prevSpecialState: isPublishStatusRecord(currentRecord) ? currentRecord : undefined,
            },
            onCancel,
        })(dispatch, getState, extraArgument);

        return actions
            .then((result) => {
                if ('message' in result) {
                    const { message } = result;
                    Sentry.captureMessage(message);
                    notify.error(Dic.word(message));
                }
                return result;
            })
            .catch((error) => {
                if (!(error instanceof CancelError)) {
                    notify.error(Dic.word('server_error'));
                    throw error;
                }
                return error;
            })
            .finally(() => {
                cancelActionProcessor.remove(data.objectId);
            });
    }) as ReturnType<typeof postThunk> | ReturnType<typeof commentThunk>;
}

const sendCommentRatingReaction = (data: RequestBody, pass: SendLikePayloadPass) =>
    sendLikeGenerator(commentThunk, data, pass);
type SendCommentRatingReaction = Action<typeof sendCommentRatingReaction>;
type SendCommentRatingReactionActions = Actions<typeof commentActions>;
export {
    SEND_RATING_COMMENT_ACTION_TYPES,
    sendCommentRatingReaction,
    SendCommentRatingReaction,
    SendCommentRatingReactionActions,
};

const sendPostRatingReaction = (data: RequestBody, pass: SendLikePayloadPass) =>
    sendLikeGenerator(postThunk, data, pass);
type SendPostRatingReaction = Action<typeof sendPostRatingReaction>;
type SendPostRatingReactionActions = Actions<typeof postActions>;
export { SEND_RATING_POST_ACTION_TYPES, sendPostRatingReaction, SendPostRatingReaction, SendPostRatingReactionActions };
