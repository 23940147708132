/* eslint-disable react/jsx-pascal-case */
import React, { ReactChild, useCallback, useMemo, useState } from 'react';
import { ReactElementLike } from 'prop-types';
import { Placement } from 'tippy.js';

import { mediaQuery } from 'app/styled';
import { usePopupRoot } from 'context/popup-root';
import Dic from 'services/dictionary';
import { staticAssetURL } from 'services/static-assets';
import getMobileDetect from 'services/detectDevice';
import { useLocaleId } from 'hooks/useLocaleId';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { ContextActionCopyLink } from 'widgets/context-action-copy-link';
import { ShareIcon } from 'components/icons2/ShareIcon';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';
import { MenuItem } from 'components/context-actions';
import { CopyColoredIcon } from 'components/icons2/CopyColoredIcon';
import { WtPopup3, WtPopup3Props } from 'components/wt-popup3';

import * as Styled from './styled';
import { shareServices } from './consts';

type ShareProps = {
    buttonText?: string | boolean;
    url?: string | null;
    title?: string;
    description?: string;
    image?: string | null;
    dropdownPlacement?: Placement;
    customRender?: ({ openPopup }: { openPopup: () => void }) => ReactElementLike;
    onClose?: () => void;
    zIndex?: number;
} & Pick<WtPopup3Props, 'portalTo'>;

const _DefaultButtonContent = ({ buttonText, onClick }: { buttonText?: string | boolean; onClick?: () => void }) => (
    <ButtonIcon
        text={typeof buttonText === 'string' ? buttonText : Dic.word('wt_all__widget_share__default_text')}
        icon={{ component: ShareIcon }}
        kind={ButtonIconKinds.GhostPrimary}
        onClick={onClick}
    />
);

type ShareWidgetTooltipProps = Pick<
    ShareProps,
    'buttonText' | 'customRender' | 'dropdownPlacement' | 'portalTo' | 'zIndex'
> & {
    shareItems: () => ReactChild[];
};

const _ShareWidgetTooltip = ({
    buttonText,
    customRender,
    dropdownPlacement,
    shareItems,
    portalTo,
    zIndex,
}: ShareWidgetTooltipProps) => {
    const buttonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup: () => undefined });

        return <_DefaultButtonContent buttonText={buttonText} />;
    }, [customRender, buttonText]);

    const isMobile = useMatchMedia(mediaQuery.lt768);
    const popupRoot = usePopupRoot();

    return isMobile ? null : (
        <div>
            <Styled.ContextActions
                tippyContent={shareItems()}
                kind={ButtonIconKinds.GhostSecondary}
                placement={dropdownPlacement ?? 'bottom-start'}
                buttonContent={buttonContent}
                hideOnScroll={false}
                appendTo={portalTo === 'popupRoot' ? popupRoot : portalTo ?? undefined}
                zIndex={zIndex}
                fixedPosition={portalTo !== null}
            />
        </div>
    );
};

type ShareWidgetPopupProps = Pick<ShareProps, 'buttonText' | 'customRender' | 'portalTo' | 'onClose'> & {
    shareItems: (closePopup: () => void) => ReactChild[];
};

const _ShareWidgetPopup = ({ buttonText, customRender, portalTo, shareItems, onClose }: ShareWidgetPopupProps) => {
    const [isPopupShown, setIsPopupShown] = useState<null | boolean>(null);
    const closePopup = () => {
        setIsPopupShown(false);
        onClose?.();
    };
    const openPopup = () => setIsPopupShown(true);

    const buttonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup });
        return <_DefaultButtonContent buttonText={buttonText} onClick={openPopup} />;
    }, [customRender, buttonText]);

    const isMobile = useMatchMedia(mediaQuery.lt768);

    return isMobile ? (
        <div>
            {buttonContent}
            {isPopupShown && (
                <WtPopup3
                    close={closePopup}
                    portalTo={portalTo}
                    bgCloseable
                    title={Dic.word('wt_all__widget_share__default_text')}
                >
                    <div>{shareItems(closePopup)}</div>
                </WtPopup3>
            )}
        </div>
    ) : null;
};

export const ShareWidget = (props: ShareProps) => {
    const lcid = useLocaleId();
    const { url, title, description, image, customRender, onClose, buttonText } = props;
    const showDefaultShare = !!navigator.share && getMobileDetect.isMobile();

    const openDefaultShare = useCallback(() => {
        navigator
            ?.share({
                url: url || undefined,
                title,
                text: description,
            })
            .then(() => onClose && onClose())
            .catch((err) => {
                console.warn(err);
                onClose?.();
            });
    }, [description, onClose, title, url]);

    const defaultShareButtonContent = useMemo(() => {
        if (customRender) return customRender({ openPopup: openDefaultShare });

        return <_DefaultButtonContent onClick={openDefaultShare} buttonText={buttonText} />;
    }, [buttonText, customRender, openDefaultShare]);

    const shareItems = useCallback(
        (closePopup?: () => void) => {
            const items: ReactChild[] = [];
            // sob: обход встроенных блокировщиков всплывающих окон в мобильном сафари,
            // которые не позволяют открывать некоторые из ссылок
            const openItemLink = (link: string) => {
                if (getMobileDetect.isMobile() && getMobileDetect.isSafari()) {
                    const safariWindow = window.open();
                    if (safariWindow) safariWindow.location.href = link;
                } else {
                    window.open(link);
                }
            };

            shareServices.forEach((service) => {
                if (!service.ruOnly || (service.ruOnly && lcid === 'ru')) {
                    items.push(
                        <MenuItem
                            className={`autotest__profile-page__header-context-actions-share_${service.title}`}
                            key={service.title}
                            text={service.title}
                            icon={service.icon ? { component: service.icon } : undefined}
                            onClick={() => {
                                openItemLink(
                                    service.link({
                                        url,
                                        title: encodeURIComponent(title || ''),
                                        description: encodeURIComponent(description || ''),
                                        image: image || staticAssetURL('/images/banners/banner_light.png'),
                                    }),
                                );
                                closePopup?.();
                                onClose?.();
                            }}
                        />,
                    );
                }
            });

            items.push(
                <ContextActionCopyLink
                    key="copy"
                    url={url || ''}
                    icon={CopyColoredIcon}
                    text={Dic.word('wt_all__share_dropdown__copy_link')}
                    onClick={() => {
                        closePopup?.();
                        onClose?.();
                    }}
                    className="autotest__profile-page__header-context-actions-share_copy-link"
                />,
            );

            return items;
        },
        [description, image, lcid, title, url],
    );

    return showDefaultShare ? (
        <>
            {/* eslint-disable-line react/jsx-no-useless-fragment */}
            {defaultShareButtonContent}
        </>
    ) : (
        <>
            <_ShareWidgetTooltip shareItems={shareItems} customRender={customRender} {...props} />
            <_ShareWidgetPopup shareItems={shareItems} customRender={customRender} {...props} />
        </>
    );
};
