import React from 'react';

import { IconSvg, IconComponent } from '.';

export const LockIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.13346 4.66647C5.13346 3.08326 6.41691 1.7998 8.00013 1.7998C9.58335 1.7998 10.8668 3.08325 10.8668 4.66647V6.7998H11.3335C11.9962 6.7998 12.5335 7.33706 12.5335 7.9998V13.3331C12.5335 13.9959 11.9962 14.5331 11.3335 14.5331H4.6668C4.00405 14.5331 3.4668 13.9959 3.4668 13.3331V7.9998C3.4668 7.33706 4.00405 6.7998 4.6668 6.7998H5.13346V4.66647ZM6.20013 6.7998H9.80013V4.66647C9.80013 3.67236 8.99424 2.86647 8.00013 2.86647C7.00602 2.86647 6.20013 3.67236 6.20013 4.66647V6.7998Z"
        />
    </IconSvg>
);
