import { useState, useCallback } from 'react';

export const useSwitch = () => {
    const [switchState, setSwitchState] = useState(false);

    const switchOn = useCallback(() => {
        setSwitchState(true);
    }, []);

    const switchOff = useCallback(() => {
        setSwitchState(false);
    }, []);

    return {
        switchState,
        switchOn,
        switchOff,
    };
};
