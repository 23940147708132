import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const ContentWrapper = styled.div`
    height: 420px;
    ${mediaQuery.lt768} {
        height: auto;
    }
`;
