import React from 'react';

import { IconSvg, IconComponent } from '.';

export const ExclamationTriangleIcon: IconComponent = (props) => (
    <IconSvg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0131 4.55629C12.5648 3.72695 11.3792 3.71469 10.9139 4.53458L3.89135 16.9076C3.43731 17.7075 4.01514 18.6999 4.93497 18.6999H18.6456C19.5544 18.6999 20.1334 17.7288 19.7012 16.9293L13.0131 4.55629ZM9.52236 3.74481C10.6082 1.83173 13.3746 1.86034 14.4207 3.79546L21.1088 16.1685C22.1172 18.034 20.7663 20.2999 18.6456 20.2999H4.93497C2.78869 20.2999 1.44043 17.9844 2.49985 16.1178L9.52236 3.74481Z"
        />
        <path d="M12.7897 12.8114C12.7692 13.3344 12.4923 13.6112 12.0104 13.6112C11.4977 13.6112 11.2209 13.3241 11.2106 12.8114L11.0773 7.83828C11.0568 7.31533 11.4464 6.93594 12.0002 6.93594C12.5334 6.93594 12.9435 7.32559 12.923 7.83828L12.7897 12.8114Z" />
        <path d="M13.0871 15.7543C13.0871 16.3388 12.5846 16.7797 12.0104 16.7797C11.4259 16.7797 10.9235 16.3285 10.9235 15.7543C10.9235 15.1801 11.4157 14.7289 12.0104 14.7289C12.5949 14.7289 13.0871 15.1698 13.0871 15.7543Z" />
    </IconSvg>
);
