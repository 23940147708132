import styled from 'styled-components';

import { mediaQuery } from 'app/styled';
import { body14RegularFontStyle } from 'services/typography';

export const SubTitle = styled.div`
    color: ${({ theme: { label } }) => label.secondary};
    ${body14RegularFontStyle};
`;

export const TagsWrapper = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    ${mediaQuery.lt768} {
        margin-top: 12px;
    }
`;
