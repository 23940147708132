let _trackingId = '';

export function initGoogleAnalytics(trackingId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

    document.body.appendChild(script);

    const configScript = document.createElement('script');
    configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${trackingId}');
    `;

    document.body.appendChild(configScript);
    _trackingId = trackingId;
}

/** Метод совместимый со старой сигнатурой */
export function sendGA(id: string, tags: (string | number)[]) {
    const [eventAction, eventLabel, eventValue] = tags;
    window?.gtag?.('event', eventAction, {
        send_to: _trackingId,
        event_category: id,
        event_label: eventLabel,
        value: eventValue,
    });
}

/** Рекомендуемый метод для новых событий */
export function sendGAv4({
    category,
    action,
    label,
    value,
}: {
    category: string;
    action: string;
    label: string;
    value?: string | number;
}) {
    window?.gtag?.('event', action, {
        send_to: _trackingId,
        event_category: category,
        event_label: label,
        value,
    });
}
