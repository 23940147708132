import { Action, GeneratorType, Id } from 'types';
import { Actions } from 'services/create-action-types/backend-client';
import { createThunkActions } from 'services/create-action-types/api-services';
import { SubscriptionService } from 'services/api-services/generated_limex';
import { ensureNumber } from 'services/utils/ensure-number';
import { updateHistory } from 'widgets/search/utils';

type SubscribeArgs = {
    generatorId: Id;
    generatorType: GeneratorType;
    currentUserId: Id;
    action?: 'subscribe' | 'unsubscribe';
};

export type SubscribePayloadPass = Omit<SubscribeArgs, 'csrf'>;

const [SUBSCRIBE_ACTION_TYPES, subscribeThunk, subscribeActions] = createThunkActions('widgets')(
    'SUBSCRIBE',
    SubscriptionService.createSubscription,
)<SubscribePayloadPass>();

const [, unsubscribeThunk] = createThunkActions(
    'widgets',
    // чтобы при переходе от UrlProcessor-а к сервисам сохранить совместимость в редьсерах,
    // здесь надо чтобы SUBSCRIBE_ACTION_TYPES == UNSUBSCRIBE_ACTION_TYPES
)('SUBSCRIBE', SubscriptionService.deleteSubscription)<SubscribePayloadPass>();

function subscribe(data: SubscribeArgs): ReturnType<typeof subscribeThunk | typeof unsubscribeThunk> {
    const { generatorId, generatorType, action, currentUserId } = data;
    const generatorIdNum = ensureNumber(generatorId);

    const thunk = action === 'subscribe' ? subscribeThunk : unsubscribeThunk;

    return (dispatch, getState, extraArgument) =>
        thunk({
            params: {
                generatorId: generatorIdNum,
                generatorType,
            },
            pass: {
                generatorId,
                generatorType,
                action,
                currentUserId,
            },
        })(dispatch, getState, extraArgument).then((success) => {
            if (action && generatorType) {
                updateHistory(generatorId, generatorType, { subscribeAction: action });
            }
            return success;
        });
}

type Subscribe = Action<typeof subscribe>;

type SubscribeActions = Actions<typeof subscribeActions>;

export { subscribe, Subscribe };

export { SUBSCRIBE_ACTION_TYPES, SubscribeActions };
