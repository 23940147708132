import React from 'react';

import Dic from 'services/dictionary';
import { MenuItem } from 'components/context-actions';
import { UserTimesIcon } from 'components/icons2/UserTimesIcon';

type Props = {
    isBlockedByCurrentUser: boolean;
    onClick: () => void;
};

export const ActionBlacklist = (props: Props) => {
    const { isBlockedByCurrentUser, onClick } = props;

    return (
        <MenuItem
            text={Dic.word(
                `wt_profile__widget_header_user__blacklist_${isBlockedByCurrentUser ? 'remove_' : ''}context_item`,
            )}
            icon={{ component: UserTimesIcon }}
            className="autotest__profile-page__header-context-actions-add-to-blacklist"
            onClick={onClick}
        />
    );
};
