import React, { FC, memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useDictionary } from 'hooks/useDictionary';
import { FormDescription, Gap20FormVLayout } from 'components/form';

import { useFormStateHandler } from '../../../../hooks/useFormStateHandler';
import { InfoBlockForm } from '../../../info-block-form';
import { StepProps } from '../../types';

import {
    MAX_ITEMS,
    MAX_LENGTH_DESCRIPTION,
    MAX_LENGTH_TITLE,
    defaultValues,
    MIN_LENGTH_DESCRIPTION,
    MIN_LENGTH_TITLE,
} from './constants';
import { Step5FormValues } from './types';
import { toData } from './toData';
import { FeaturesHeader } from './FeaturesHeader';

export const Step5: FC<StepProps<Step5FormValues>> = memo((props) => {
    const form = useForm<Step5FormValues>({
        mode: 'onChange',
        defaultValues: props.valuesRef?.current ?? defaultValues,
    });

    const dic = useDictionary();
    useFormStateHandler({ form, props, toData });

    return (
        <FormProvider {...form}>
            <Gap20FormVLayout>
                <FeaturesHeader />
                <FormDescription>
                    {dic.word('wt_all__widget_myspace_product_wizard__step5_description')}
                </FormDescription>
                <InfoBlockForm
                    name="keyFeatures"
                    maxItems={MAX_ITEMS}
                    maxLengthTitle={MAX_LENGTH_TITLE}
                    maxLengthDescription={MAX_LENGTH_DESCRIPTION}
                    minLengthTitle={MIN_LENGTH_TITLE}
                    minLengthDescription={MIN_LENGTH_DESCRIPTION}
                    className="autotest__marketplace__product_wizard__info_block1_item_"
                />
            </Gap20FormVLayout>
        </FormProvider>
    );
});
