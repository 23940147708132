import { Id, Action } from 'types';
import { Actions, thunkActions } from 'services/create-action-types/backend-client';
import UrlProcessor from 'services/url-processor';

export type ComplainPayloadData = {
    status?: string;
    error?: string;
};
const [REPORT_SPAM_ACTION_TYPES, thunk, actions] = thunkActions('REPORT_SPAM')<
    { reason: string; description: string; csrf: string },
    ComplainPayloadData
>();

function complain(suspectPersonId: Id, data: { reason: string; description: string; csrf: string }) {
    return thunk({
        request: {
            method: 'POST',
            url: UrlProcessor.page('limex_profile_complain').params({ person_id: suspectPersonId }).path(),
            data,
        },
    });
}
type Complain = Action<typeof complain>;
type ComplainActions = Actions<typeof actions>;

export { REPORT_SPAM_ACTION_TYPES, complain, Complain, ComplainActions };
