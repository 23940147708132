import React, { useCallback, useRef, useState } from 'react';

import { useDictionary } from 'hooks/useDictionary';
import { useViewed } from 'hooks/useViewed';

import { useFetchAd } from './useFetchAd';
import { Placeholder, ImptrackersWrapper, ImptrackerImg } from './styled';
import { AdsBlockProps } from './types';
import { getPlacementId } from './getPlacementId';

export const AdsBlock = (props: AdsBlockProps) => {
    const { isDisabled = false, children, bannerPlaceId = getPlacementId(), bidfloor = 0.5 } = props;

    const dic = useDictionary();

    const adData = useFetchAd({ bidfloor, bannerPlaceId, isDisabled });
    const imptrackers = adData?.imptrackers;

    const adsWrapperRef = useRef(null);
    const [isRenderedImptrackersSwitcher, setIsRenderedImptrackersSwitcher] = useState(false);
    // управление в useViewed

    useViewed(
        adsWrapperRef,
        ({ isIntersecting }) => {
            if (!isRenderedImptrackersSwitcher && isIntersecting) {
                // если в поле видимости, а значит просмотрели рекламу,
                // то рендерим пиксели
                // Сработает единожды
                setIsRenderedImptrackersSwitcher(true);
            }
        },
        () => true,
        [],
        {
            intersectionParams: {
                threshold: 1,
            },
        },
    );
    const renderImptrackersImgs = useCallback(() => {
        if (!isRenderedImptrackersSwitcher || !imptrackers) {
            return null;
        }

        return (
            <ImptrackersWrapper>
                {imptrackers.map((imgPixelUrl: string) => (
                    <ImptrackerImg key={imgPixelUrl} src={imgPixelUrl} alt="" />
                ))}
            </ImptrackersWrapper>
        );
    }, [isRenderedImptrackersSwitcher, imptrackers]);

    if (!adData) {
        return <Placeholder data-text={dic.word('wt_all__widget_ads_block__placeholder')} />;
    }

    return (
        <div ref={adsWrapperRef}>
            {children?.(adData)}
            {renderImptrackersImgs()}
        </div>
    );
};
