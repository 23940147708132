import { backendClient } from 'services/backend-client';
import UrlProcessor from 'services/url-processor';
import { GeoGenericExporter } from 'widgets/context-menu/interfaces';

let countriesList: Promise<GeoGenericExporter[]> | null = null;

export async function getCountries(): Promise<GeoGenericExporter[]> {
    if (!countriesList) {
        countriesList = backendClient
            .get<{ countries: GeoGenericExporter[] }>(UrlProcessor.page('api_v1_dictionary_country').path())
            .then(({ data }) => data.countries);
    }
    return countriesList;
}
