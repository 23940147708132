import React, { FC, memo, ReactElement, useLayoutEffect, useRef, useState } from 'react';
import {
    autoUpdate,
    FloatingPortal,
    offset,
    useFloating,
    useInteractions,
    useTransitionStyles,
    FloatingArrow,
    arrow,
    flip,
    useHover,
    size,
} from '@floating-ui/react';
import cn from 'classcat';

import { usePopupRoot } from 'context/popup-root';
import { tokens } from 'services/tokens';

import {
    ANIMATION_DURATION,
    ANIMATION_OFFSET,
    ARROW_HEIGHT,
    ARROW_OFFSET,
    ARROW_WIDTH,
    CLOSE_DELAY,
    OFFSET,
    OPEN_DELAY,
    SCREEN_EDGE_OFFSET,
} from './constants';
import * as Styled from './styled';
import { TooltipV2Props } from './types';

export const TooltipV2: FC<TooltipV2Props> = memo((props) => {
    const {
        className: classNameProp,
        kind = 'primary',
        children,
        content,
        placement = 'bottom',
        disableFlip,
        disableResize,
        isOpenDefault = false,
        setIsOpen: setIsOpenProp,
        zIndex = 4,
    } = props;

    const className = cn(['autotest__tooltip', classNameProp]);

    const [isOpen, setIsOpen] = useState(isOpenDefault);
    const root = usePopupRoot() ?? document.body;
    const arrowRef = useRef(null);
    const {
        refs,
        floatingStyles,
        context,
        placement: finalPlacement,
    } = useFloating<Element>({
        placement,
        strategy: 'fixed',
        middleware: [
            offset(OFFSET),
            disableFlip ? null : flip({ padding: SCREEN_EDGE_OFFSET }),
            arrow({
                element: arrowRef,
            }),
            disableResize
                ? null
                : size({
                      padding: SCREEN_EDGE_OFFSET,
                      apply({ elements, availableHeight, availableWidth }) {
                          Object.assign(elements.floating.style, {
                              maxWidth: `${availableWidth}px`,
                              maxHeight: `${availableHeight}px`,
                              minWidth: 'min-content',
                          });
                      },
                  }),
        ],
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpenProp || setIsOpen,
    });
    const [side, alignment] = finalPlacement.split('-');
    const axis = side === 'top' || side === 'bottom' ? 'Y' : 'X';
    const direction = side === 'top' || side === 'left' ? '-' : '';
    const staticOffset = alignment && axis === 'Y' ? ARROW_OFFSET : `calc(50% - ${ARROW_WIDTH / 2}px)`;
    const hover = useHover(context, { delay: { open: OPEN_DELAY, close: CLOSE_DELAY } });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    const { isMounted, styles } = useTransitionStyles(context, {
        duration: ANIMATION_DURATION,
        initial: { opacity: 0, transform: `translate${axis}(${direction}${ANIMATION_OFFSET}px)` },
    });
    const ref = useRef<Element>();
    const child = React.cloneElement(React.Children.toArray(children)[0] as ReactElement, {
        ref: (e: Element) => {
            ref.current = e;
            refs.setReference(e);
        },
    });

    const referenceProps = getReferenceProps();

    useLayoutEffect(() => {
        Object.entries(referenceProps).forEach(([name, handler]) => {
            const eventName = name.slice(2).toLowerCase();
            ref.current?.addEventListener(
                eventName as keyof ElementEventMap,
                handler as (this: Element, ev: Event) => unknown,
            );
        });
        return () => {
            Object.entries(referenceProps).forEach(([name, handler]) => {
                const eventName = name.slice(2).toLowerCase();
                ref.current?.removeEventListener(
                    eventName as keyof ElementEventMap,
                    handler as (this: Element, ev: Event) => unknown,
                );
            });
        };
    }, [referenceProps]);

    return (
        <Styled.Tooltip className={className}>
            {child}
            {root && isMounted ? (
                <FloatingPortal root={root}>
                    <Styled.Floating
                        $zIndex={zIndex}
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        <Styled.Transition style={styles} kind={kind}>
                            <FloatingArrow
                                staticOffset={staticOffset}
                                width={ARROW_WIDTH}
                                height={ARROW_HEIGHT}
                                fill={
                                    kind === 'primary'
                                        ? tokens.colors.bg.base.tooltip.default
                                        : tokens.colors.bg.base.default.default
                                }
                                ref={arrowRef}
                                context={context}
                            />
                            {content}
                        </Styled.Transition>
                    </Styled.Floating>
                </FloatingPortal>
            ) : null}
        </Styled.Tooltip>
    );
});
