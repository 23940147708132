import styled from 'styled-components';

import { mediaQuery } from 'app/styled';

export const Loading = styled.div`
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        fill: ${({ theme }) => theme.label.secondary};
    }
`;

export const Catalog = styled.div`
    margin-top: 16px;
    box-sizing: border-box;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    & > * {
        padding-top: 0;
        padding-bottom: 0;
        min-width: 0;
    }

    ${mediaQuery.lt960} {
        grid-template-columns: repeat(1, 1fr);
    }

    ${mediaQuery.lt768} {
        padding-left: 16px;
        padding-right: 16px;
    }

    ${mediaQuery.lt480} {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const BannerWrapper = styled.div`
    margin-top: 16px;
`;
