import React from 'react';

import { IconSvg, IconComponent } from '.';

export const HamburgerIcon: IconComponent = (props) => (
    <IconSvg {...props} viewBox="0 0 24 24">
        <path d="M3.19995 7.60005C3.19995 7.15822 3.55812 6.80005 3.99995 6.80005H20C20.4418 6.80005 20.7999 7.15822 20.7999 7.60005C20.7999 8.04188 20.4418 8.40005 20 8.40005H3.99995C3.55812 8.40005 3.19995 8.04188 3.19995 7.60005Z" />
        <path d="M3.19995 12.0001C3.19995 11.5582 3.55812 11.2001 3.99995 11.2001H20C20.4418 11.2001 20.7999 11.5582 20.7999 12.0001C20.7999 12.4419 20.4418 12.8001 20 12.8001H3.99995C3.55812 12.8001 3.19995 12.4419 3.19995 12.0001Z" />
        <path d="M3.99995 15.6001C3.55812 15.6001 3.19995 15.9583 3.19995 16.4001C3.19995 16.8419 3.55812 17.2001 3.99995 17.2001H20C20.4418 17.2001 20.7999 16.8419 20.7999 16.4001C20.7999 15.9583 20.4418 15.6001 20 15.6001H3.99995Z" />
    </IconSvg>
);
