import styled from 'styled-components';

import { LimexLogoIcon as LimexLogoIconComponent } from 'components/limex-logo';

export const LogoLink = styled.a`
    height: 40px;
    display: block;
    margin-right: 12px;
`;

export const LimexLogoIcon = styled(LimexLogoIconComponent)`
    display: block;
`;

export const LimexLogoIconWrapper = styled.div`
    padding: 4px 0 8px 0;
`;
