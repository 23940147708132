import React, { useCallback } from 'react';
import cc from 'classcat';

import { GeneratorType, Person } from 'types';
import { Dic, correctedHumanString } from 'services/dictionary';
import { ActionSubscribe, ActionSubscribeRenderProps } from 'widgets/action-subscribe';
import { Avatar } from 'components/avatar';
import { ButtonIcon, ButtonIconKinds } from 'components/button-icon';

import { Wrapper, Name, Subscribers, ButtonWrapper } from './styled/User';

type Props = {
    user: Pick<Person, 'id' | 'displayName' | 'url' | 'data'> &
        Partial<Pick<Person, 'isVerified' | 'images' | 'image'>> & {
            counters: { followers: number };
        };
};

const AVATAR_SIZE = { default: 41 };

export const User = (props: Props) => {
    const {
        user: { id, displayName, images, image, counters, url, isVerified, data },
    } = props;

    const { subscribed = false } = data || {};

    const subscribersText = correctedHumanString('wt_profile__widget_header_user__followers', [
        counters?.followers || 0,
    ]);

    const subscribeRender = useCallback((subscribeProps: ActionSubscribeRenderProps) => {
        const { onClick, isSubscribed, className } = subscribeProps;

        const buttonSubscribeClassName = cc([
            className,
            'autotest__profile-page__header-user-subscribe-button',
            `autotest__profile-page__header-user-subscribe-button-${isSubscribed ? '' : 'not-'}subscribed`,
        ]);

        return (
            <ButtonIcon
                className={buttonSubscribeClassName}
                onClick={onClick}
                size="M"
                kind={ButtonIconKinds.FillPrimary}
                pressed={!isSubscribed}
                text={Dic.word(`wt_feed__item_recommendations__subscribe${isSubscribed ? 'd' : ''}_btn`)}
            />
        );
    }, []);

    return (
        <Wrapper>
            <Avatar size={AVATAR_SIZE} src={image ?? images?.medium} />
            <Name url={url} displayName={displayName} isVerified={isVerified} />
            <Subscribers>{subscribersText}</Subscribers>
            <ButtonWrapper>
                <ActionSubscribe
                    data={{ generatorId: id, generatorType: GeneratorType.USER }}
                    isSubscribed={subscribed ?? false}
                    customRender={subscribeRender}
                />
            </ButtonWrapper>
        </Wrapper>
    );
};
